/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useMutation } from 'react-apollo'
import { COLORS, PLUS_BUTTON } from 'assets/styles/globalStyles'
import { SEARCHABLE_DROPDOWN_OPTIONS } from 'utilities'
import { Form, Input, Button, Select, Modal, notification } from 'antd'
import gql from 'graphql-tag'
import { CREATE_PROMPT } from '../query'
import { useTargetAlloc } from '../context'
import RequiredLabel from './requiredLabel'

const { Option } = Select
export const PROMPT_CODES = gql`
  query {
    promptCodes {
      id
      promptName
    }
  }
`

const PromptFieldCard = ({ form, defaultSettings, edit, initialValue, id }) => {
  const { getFieldDecorator } = form

  const [addPromtModal, setAddPromtModal] = useState(false)
  const [newPromtName, setNewPromtName] = useState('')
  const [initalValueArray, setInitalValueArray] = useState([])
  const [newPromptData, setNewPromptData] = useState([])
  const [createNewPrompt, { loading: createNewPromptLoading }] = useMutation(CREATE_PROMPT)

  const { promptData, promptLoading, refetchPromptData } = useTargetAlloc()

  useEffect(() => {
    refetchPromptData()
  }, [id])

  useEffect(() => {
    if (promptData && initialValue) {
      const result = initialValue.filter(o1 => !promptData.promptCodes.some(o2 => o1.id === o2.id))
      if (result.length) {
        setNewPromptData([...promptData.promptCodes, ...result])
      } else {
        setNewPromptData([...promptData.promptCodes])
      }
      const array = []
      initialValue.map(item => array.push(item.id))
      setInitalValueArray(array)
    } else {
      setNewPromptData([...promptData.promptCodes])
    }
  }, [promptData, initialValue, id])

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <Form.Item label="Prompt">
          {getFieldDecorator('prompt', {
            initialValue: edit
              ? initalValueArray
              : defaultSettings?.promptCodes
              ? defaultSettings.promptCodes.map(pt => pt.node.id)
              : [],
            rules: [{ required: true, message: 'Please select prompt' }],
          })(
            <Select
              {...SEARCHABLE_DROPDOWN_OPTIONS}
              mode="multiple"
              placeholder="Please select"
              loading={promptLoading}
            >
              {newPromptData.map(p => (
                <Option value={p.id} key={p.id}>
                  {p.promptName}
                </Option>
              ))}
            </Select>,
          )}
          <Button onClick={() => setAddPromtModal(true)} style={PLUS_BUTTON}>
            <PlusOutlined />
          </Button>
        </Form.Item>
      </div>

      {/* add new Promt */}
      <Modal
        visible={addPromtModal}
        title="Add New Promt"
        onCancel={() => setAddPromtModal(false)}
        zIndex={1001}
        width={800}
        footer={[
          <Button key="prompt-cancel" onClick={() => setAddPromtModal(false)}>
            Cancel
          </Button>,
          <Button
            key="prompt-submit"
            type="primary"
            style={{ backgroundColor: COLORS.submitBlue }}
            loading={createNewPromptLoading}
            onClick={() => {
              if (newPromtName) {
                createNewPrompt({
                  variables: {
                    name: newPromtName,
                  },
                })
                  .then(res => {
                    refetchPromptData()
                    setAddPromtModal(false)
                    notification.success({
                      message: 'New prompt created successfully',
                    })
                  })
                  .catch(err => console.error(err))
              }
            }}
          >
            Create
          </Button>,
        ]}
      >
        <Form.Item label={<RequiredLabel label="Prompt" required />} name="Prompt">
          <Input
            value={newPromtName}
            onChange={e => setNewPromtName(e.target.value)}
            placeholder="Type the new prompt name"
            autoFocus
            required
          />
        </Form.Item>
      </Modal>
    </div>
  )
}

export default PromptFieldCard
