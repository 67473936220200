/* eslint-disable */
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, notification, Switch, Tooltip, Empty } from 'antd'
import { DRAWER, COLORS } from 'assets/styles/globalStyles'
import 'chartjs-plugin-annotation'
import LoadingComponent from 'components/LoadingComponent'
import html2canvas from 'html2canvas'
import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Line } from 'react-chartjs-2'
import _ from 'lodash'
import { FaDownload } from 'react-icons/fa'
import BehaviorSessionTable from './behaviorSessionTable'
import DailyResponseRatePdf from './DailyResponseRatePdf'
import PhaseTable from './PhaseTable'
import RangeDatePicker from '../../components/DatePicker/DatePicker'
import HeaderComponent from 'components/HeaderComponentNew'
import RangeDatePickerForGraph from 'components/DatePicker/DatePickerForGraph'

const { RangePicker } = DatePicker

const parentDiv = { display: 'flex', margin: '5px 30px 5px 0' }

const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }

const dateFormat = 'YYYY-MM-DD'

const color = {
  mastered: '#008000', // green
  baseline: '#FF0000', // red
  therapy: '#FFA500', // orange
  'in-maintenance': '#DC143C', // dark red
}
const status = {
  mastered: 'Master',
  baseline: 'Baseline',
  therapy: 'Therapy',
  'in maintenance': 'In-Maintenance',
  intervention: 'Intervention',
}
const position = {
  mastered: 'center',
  baseline: 'center',
  therapy: 'center',
  'in maintenance': 'top',
  intervention: 'center',
  generalization: 'top',
}
const positionTherapist = {
  mastered: 'left',
  baseline: 'right',
  therapy: 'bottom-left',
  'in maintenance': 'top-left',
  intervention: 'bottom-right',
  generalization: 'top-right',
}
const posMastery = ['top-right', 'top-left', 'bottom-left', 'bottom-right']

const x = {
  master: 0,
  baseline: -30,
  therapy: 0,
  inmaintainence: -10,
}

const y = {
  mastered: 16,
  baseline: 30,
  intervention: 5,
  therapy: 9,
  'in maintenance': 11,
  generalization: 24,
}
const colorsArray = [
  '#774dd7',
  '#028ee6',
  '#00CED1',
  '#2E5894',
  '#3F00FF',
  '#6495ED',
  '#1560bd',
  '#00308F',
  '#48D1CC',
]

export default function ResponseRateGraph({
  graphData,
  dateRange,
  setDateRange,
  therapist,
  selectedTarget,
  loading,
  marker,
  closeDrawer,
  mainTargetTrial,
  behaviorData,
  getNextTarget,
  getPrevTarget,
  nextDisable,
  prevDisable,
  behaviorRefetch,
  mastery,
  sdPeak,
}) {
  let i = 0
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { defaultDateRange, defaultDateDRR } = user
  const [date, setDate] = useState(moment())
  const [showSessionTableDrawer, setShowSessionTableDrawer] = useState(false)
  const [allocatedDate, setAllocatedDate] = useState(null)
  const [lineChartImage, setLineChartImage] = useState(null)
  const [isResponsePdf, setIsResponsePdf] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [phaseLine, setPhaseLine] = useState(true)
  const [phaseData, setPhaseData] = useState([])
  const [markers, setMarker] = useState([])
  const [isTherapist, setIsTherapist] = useState(false)
  const [therapistData, setTherapistData] = useState([])
  const [storyMark, setStoryMarker] = useState([])
  const [masteryMarker, setMasteryMarker] = useState([])
  const annotations = []
  const [anMastery, setAnMastery] = useState([])
  const [clickedPoint, setClickedPoint] = useState(null)
  const [clickedPointMarker, setClickedPointMarker] = useState([])
  const [therapistHiddenIds, setTherapistHiddenIds] = useState([])
  const [therapistHiddenData, setTherapistHiddenData] = useState([])

  useEffect(() => {
    if (marker) {
      setMarker(marker)
    }
  }, [marker])

  const removeDuplicateArrayValues = arr => {
    return [...new Set(arr)]
  }

  useEffect(() => {
    if (therapist) {
      setTherapistData([])
      const phase = []
      let t = []
      let dates = []
      therapist.map(ite => {
        ite.data.map(i => {
          if (i.y[1] !== null) {
            // d.push(i)
            // t.push(i.date)
            dates.push(i.date)
          }
        })
      })

      dates = removeDuplicateArrayValues(dates)
      const sortedDates = dates.sort(
        (a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'),
      )
      therapist.map((item, index) => {
        let d = []

        sortedDates.map(j => {
          d.push({
            x: j,
            y: null,
            key: j,
            date: j,
            frequency: 0,
          })
        })
        item.data.map(it => {
          const idx = d.findIndex(i => i.date === it.date)
          if (idx !== -1) {
            d[idx] = {
              x: it.date,
              y: Number(it.y[0] / 1000).toFixed(0),
              key: it.date,
              date: it.date,
              frequency: it.y[1],
            }
          }
        })
        const sorted = d.sort(
          (a, b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'),
        )
        item.id !== undefined &&
          setTherapistData(prev => [
            ...prev,
            {
              id: item.id,
              key: item.key,
              data: sorted,
            },
          ])
      })

      if (markers) {
        const selectedRangeStart = moment(dateRange[0]).format('YYYY-MM-DD')
        const selectedRangeEnd = moment(dateRange[1]).format('YYYY-MM-DD')
        const temp = []
        markers.dateStatus &&
          markers.dateStatus.edges.length &&
          markers.dateStatus.edges.map(({ node }) => {
            const pos = _.lowerCase(node.targetStatusId?.statusName)
            const obj = {
              axis: 'x',
              value: moment(node.date).format('YYYY-MM-DD'),
              lineStyle: { stroke: COLORS[node.targetStatusId?.colorCode], strokeWidth: 2 },
              legend: node.targetStatusId?.statusName,
              legendPosition: positionTherapist[pos],
              legendOrientation: 'vertical',
              textStyle: {
                fill: COLORS[node.targetStatusId?.colorCode],
                fontSize: 16,
                fontWeight: 'bold',
              },
            }
            if (moment(node.date).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')) {
              temp.push(obj)
            }
          })
        _.forEach(markers, (value, key) => {
          if (value !== undefined) {
            if (key === 'phaseSet' && phaseLine && value.length !== 0) {
              value.map(p => {
                const obj = {
                  axis: 'x',
                  value: moment(p.node.date).format('YYYY-MM-DD'),
                  lineStyle: { stroke: '#000000', strokeWidth: 2 },
                  legend: p.node.content,
                  legendPosition: 'top',
                  textStyle: { fill: 'black', fontSize: 16, fontWeight: 'bold' },
                }
                if (
                  moment(p.node.date).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')
                ) {
                  temp.push(obj)
                }

                return p
              })
            }
            // if (
            //   value &&
            //   (key === 'inmaintainence' ||
            //     key === 'baseline' ||
            //     key === 'therapy' ||
            //     key === 'master')
            // ) {
            //   console.log('true')
            //   const obj = {
            //     axis: 'x',
            //     value: moment(value).format('YYYY-MM-DD'),
            //     lineStyle: { stroke: color[key], strokeWidth: 2 },
            //     legend: status[key],
            //     legendPosition: position[key],
            //   }

            //   // setPhaseData(old=>[...old,obj])
            //   if (t.includes(moment(value).format('YYYY-MM-DD'))) {
            //     temp.push(obj)
            //   }
            // }
          }
        })
        setStoryMarker(temp)
      }
    }
  }, [date, JSON.stringify(therapist), phaseLine, isTherapist])

  useEffect(() => {
    if (mastery) {
      const temp = []
      const ann = []
      const status = mastery?.criteriaType?.statusName
      mastery.criteriaphasetargetsSet.edges.map(({ node }) => {
        const {
          calculationType,
          percentage,
          duration,
          calFrameType,
          calFrameDurationType,
          calFrameDurationValue,
          phaseChangeLabel,
          minimumTrial,
        } = node
        const obj = {
          axis: 'y',
          value: duration,
          lineStyle: { stroke: COLORS.danger, strokeWidth: 3 },
          legend: `Duration ${calculationType} ${duration} over ${calFrameDurationValue} ${calFrameType} ${calFrameDurationType},${status} to ${phaseChangeLabel},Min. Trial:${minimumTrial}`,
          legendPosition: posMastery[Math.floor(Math.random() * posMastery.length)],
          textStyle: { fill: COLORS.danger, fontSize: 16, fontWeight: 'bold' },
        }
        const aObj2 = {
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-1',
          value: duration,
          borderWidth: 3,
          borderColor: 'red',
          text: `Duration ${calculationType} ${duration} over ${calFrameDurationValue} ${calFrameType} ${calFrameDurationType},${status} to ${phaseChangeLabel},Min. Trial:${minimumTrial}`,
          label: {
            content: `Duration ${calculationType} ${duration} over ${calFrameDurationValue} ${calFrameType} ${calFrameDurationType},${status} to ${phaseChangeLabel},Min. Trial:${minimumTrial}`,
            enabled: true,
            position: posMastery[Math.floor(Math.random() * posMastery.length)],
            yAdjust: 15,
          },
        }
        ann.push(aObj2)
        temp.push(obj)
      })
      setMasteryMarker(temp)
      setAnMastery(ann)
    } else {
      setAnMastery([])
      setMasteryMarker([])
    }
  }, [mastery])

  useEffect(() => {
    if (clickedPoint) {
      const temp = []
      temp.push({
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: moment(clickedPoint).format('YYYY-MM-DD'),
        borderColor: '#483D8B',
        borderWidth: 6,
        borderDash: [2, 3],
        backgroundColor: 'grey',
        text: 'Clicked',
        label: {
          content: 'Clicked',
          enabled: true,
          position: 'top',
          // yAdjust: 6,
          rotation: 90,
          backgroundColor: 'white',
          fontColor: 'black',
        },
      })
      setClickedPointMarker(temp)
    } else {
      setClickedPointMarker([])
    }
  }, [clickedPoint])

  useEffect(() => {
    if (therapistData) {
      const hiddenFilter = therapistData.map(x => {
        if (!therapistHiddenIds.includes(x.id)) {
          return x
        } else {
          return {
            ...x,
            data: [],
          }
        }
      })
      setTherapistHiddenData(hiddenFilter)
    }
  }, [therapistData, therapistHiddenIds])

  useEffect(() => {
    if (therapistHiddenData && therapistHiddenData.length && masteryMarker && storyMark) {
      const selectedRangeStart = moment(dateRange[0]).format('YYYY-MM-DD')
      const selectedRangeEnd = moment(dateRange[1]).format('YYYY-MM-DD')
      const tempNew = therapistHiddenData[0].data

      const data = tempNew
      storyMark.forEach(marker => {
        const markerValue = marker.value

        // Check if the marker's value exists in the data array
        const existingData = data.find(item => item.x === markerValue)

        // If the marker's value is not present in the data array, add a new data object
        if (!existingData) {
          const newMarker = {
            x: markerValue,
            y: null, // Set the desired value for y
            key: markerValue,
            date: markerValue,
            target: '',
            plotZero: false,
            record: false,
            total: null, // Set the desired value for total
            correct: null, // Set the desired value for correct
            incorrect: null, // Set the desired value for incorrect
            prompt: null, // Set the desired value for prompt
            type: 'marker',
            targetId: null, // Set the desired value for targetId
          }

          if (moment(markerValue).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')) {
            data.push(newMarker)
          }
        }
      })
      data.sort((a, b) => {
        const dateA = new Date(a.x)
        const dateB = new Date(b.x)

        return dateA - dateB
      })
    }
  }, [therapistHiddenData, masteryMarker, storyMark])

  const commonProperties = {
    height: 550,
    margin: { top: 40, right: 40, bottom: 200, left: 60 },
    animate: true,

    legends: [
      {
        anchor: 'bottom',
        direction: 'column',
        justify: false,
        translateY: 150,
        translateX: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 150,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'square',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        onClick: data => {
          toggle(data)
        },
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ],

    axisLeft: {
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Duration',
      legendOffset: -40,
      legendPosition: 'middle',
    },
    pointLabelYOffset: -12,
    enableGridX: false,
    useMesh: true,
    enableSlices: 'x',
  }

  const toggle = d => {
    if (therapistHiddenIds.includes(d.id)) {
      const f = therapistHiddenIds.filter(id => id !== d.id)
      setTherapistHiddenIds(f)
    } else {
      setTherapistHiddenIds([...therapistHiddenIds, d.id])
    }
  }

  const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
    <g>
      <circle fill="#fff" r={size / 3} strokeWidth={borderWidth} stroke={borderColor} />
    </g>
  )

  const handlePointClick = (element, id) => {
    if (!element.length) return
    const { _index } = element[0]
    const clickedDate = moment(graphData[id].data[_index].date).format(dateFormat)
    setClickedPoint(clickedDate)
    if (clickedDate >= allocatedDate && clickedDate <= moment().format(dateFormat)) {
      setDate(clickedDate)
      setShowSessionTableDrawer(true)
    } else {
      notification.info({
        message: `No session found on ${clickedDate}!`,
        description:
          'Date shuold be not greater then today or not less then the target valid from date!! ',
      })
    }
  }

  useEffect(() => {
    if (selectedTarget && selectedTarget.date) {
      if (moment(selectedTarget.date)) {
        setAllocatedDate(moment(selectedTarget.date).format('YYYY-MM-DD'))
      }
    }
  }, [selectedTarget])

  markers &&
    markers.dateStatus &&
    markers.dateStatus.edges.length &&
    markers.dateStatus.edges.map(({ node }) => {
      const pos = _.lowerCase(node.targetStatusId?.statusName)

      annotations.push({
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: moment(node.date).format('YYYY-MM-DD'),
        borderWidth: 2,
        borderColor: COLORS[node.targetStatusId?.colorCode],
        text: node.targetStatusId?.statusName,
        label: {
          content: node.targetStatusId?.statusName,
          enabled: true,
          position: position[pos],
          xAdjust: 10,
          yAdjust: y[pos],
          backgroundColor: 'white',
          fontColor: COLORS[node.targetStatusId?.colorCode],
          rotation: -180,
        },
      })
    })

  const keys = Object.keys(markers)
  keys.forEach(item => {
    if (item === 'phaseSet' && phaseLine && markers[item]) {
      marker[item].forEach(({ node }) => {
        annotations.push({
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: moment(node.date).format('YYYY-MM-DD'),
          borderColor: '#483D8B',
          borderWidth: 2,
          text: node.content,
          label: {
            content: node.content,
            enabled: true,
            position: 'top',
            yAdjust: 15,
            rotation: 180,
            backgroundColor: 'white',
            fontColor: 'black',
          },
        })
      })
    }
    // else if (markers[item]) {
    //   annotations.push({
    //     drawTime: 'afterDatasetsDraw',
    //     type: 'line',
    //     mode: 'vertical',
    //     scaleID: 'x-axis-0',
    //     value: markers[item],
    //     borderWidth: 2,
    //     borderColor: color[item],
    //     text: status[item],
    //     label: {
    //       content: status[item],
    //       enabled: true,
    //       position: position[item],
    //       xAdjust: x[item],
    //       yAdjust: 12,
    //       rotation: 180,
    //       backgroundColor: 'white',
    //       fontColor: 'black',
    //     },
    //   })
    // }
  })

  const handleData = data => {
    const temp = { ...markers, phaseSet: data }
    setMarker(temp)
  }

  const getChartOptions = () => {
    const markers = isEditable
      ? [...annotations, ...anMastery, ...clickedPointMarker]
      : [...annotations, ...anMastery]

    return {
      responsive: true,
      maintainAspectRatio: false,

      tooltips: {
        mode: 'label',
      },
      annotation: {
        annotations: markers,
      },
      elements: {
        line: {
          fill: false,
        },
      },
      scales: {
        xAxes: [
          {
            id: 'x-axis-0',
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Dates',
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-2',
            gridLines: {
              display: true,
            },
            labels: {
              show: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Frequency',
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              precision: 0,
              // stepSize: 1,
            },
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-1',
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              precision: 0,
              // stepSize: 1,
            },
            scaleLabel: {
              display: true,
              labelString: 'Duration (in seconds)',
            },
          },
        ],
      },
    }
  }
  const getChartData = index => {
    const frequency = []
    const duration = []
    const dates = new Array(graphData[index].data.length).fill(0)
    graphData[index].data.forEach((item, id) => {
      dates[id] = item.date
      const { y } = item
      if (y[1] === null) {
        frequency.push(0)
        duration.push(Number(0 / 1000).toFixed(0))
      } else {
        frequency.push(y[1])
        duration.push(Number(y[0] / 1000).toFixed(0))
      }
      // tempDuration.push()
    })

    const data = {
      labels: dates,
      datasets: [
        {
          label: 'Frequency ',
          type: 'line',
          fill: false,
          borderColor: '#EC932F',
          backgroundColor: '#EC932F',
          radius: 7,
          hitRadius: 8,
          hoverRadius: 8,
          borderWidth: 3,
          hoverBorderWidth: 3,
          pointBorderColor: '#EC932F',
          pointBackgroundColor: 'white',
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: '#EC932F',
          lineTension: 0,
          yAxisID: 'y-axis-2',
          data: frequency,
        },
        {
          type: 'bar',
          label: 'Duration ',
          fill: false,
          backgroundColor: '#a6cee3',
          borderColor: '#a6cee3',
          hoverBackgroundColor: '#a6cee3',
          hoverBorderColor: '#a6cee3',
          yAxisID: 'y-axis-1',
          data: duration,
        },
      ],
    }
    return data
  }

  const getNoZeroData = () => {
    const frequency = []
    const duration = []
    const date = []
    const newdate = []
    graphData.forEach((i, id) => {
      i.data.forEach(item => {
        const { y } = item
        if (y[1] !== null) {
          if (item.plotZero) {
            // frequency.push(y[1])
            // date.push(item.date)
            newdate.push({
              date: item.date,
              data: y,
              [item.date]: y,
            })
            // duration.push(Number(y[0] / 1000).toFixed(0))
          } else if (y[1] > 0) {
            // frequency.push(y[1])
            // date.push(item.date)
            newdate.push({
              date: item.date,
              data: y,
              [item.date]: y,
            })
            // duration.push(Number(y[0] / 1000).toFixed(0))
          }
        }
      })
    })
    annotations.forEach(item => {
      const selectedRangeStart = moment(dateRange[0]).format('YYYY-MM-DD')
      const selectedRangeEnd = moment(dateRange[1]).format('YYYY-MM-DD')
      if (moment(item.value).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')) {
        newdate.push({
          date: item.value,
          data: [0, 0],
          [item.date]: [0, 0],
        })
      }
    })
    const sorted = newdate.sort(
      (a, b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'),
    )
    sorted.forEach(item => {
      date.push(item.date)
      frequency.push(item.data[1])
      duration.push(Number(item.data[0] / 1000).toFixed(0))
    })

    const aggregatedData = date.reduce((acc, currDate, index) => {
      if (acc.has(currDate)) {
        acc.get(currDate).frequency += frequency[index]
        acc.get(currDate).duration += parseInt(duration[index])
      } else {
        acc.set(currDate, {
          frequency: frequency[index],
          duration: parseInt(duration[index]),
        })
      }
      return acc
    }, new Map())

    const uniqueDates = [...aggregatedData.keys()]
    const aggregatedFrequencies = uniqueDates.map(date => aggregatedData.get(date).frequency)
    const aggregatedDurations = uniqueDates.map(date => aggregatedData.get(date).duration)

    const data = {
      labels: uniqueDates,
      datasets: [
        {
          label: 'Frequency ',
          type: 'line',
          fill: false,
          borderColor: '#EC932F',
          backgroundColor: '#EC932F',
          radius: 7,
          hitRadius: 8,
          hoverRadius: 8,
          borderWidth: 3,
          hoverBorderWidth: 3,
          pointBorderColor: '#EC932F',
          pointBackgroundColor: 'white',
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: '#EC932F',
          lineTension: 0,
          yAxisID: 'y-axis-2',
          data: aggregatedFrequencies,
        },
        {
          type: 'bar',
          label: 'Duration ',
          fill: false,
          backgroundColor: '#a6cee3',
          borderColor: '#a6cee3',
          hoverBackgroundColor: '#a6cee3',
          hoverBorderColor: '#a6cee3',
          yAxisID: 'y-axis-1',
          data: aggregatedDurations,
        },
      ],
    }
    return data
  }

  const getLineChartImage = () => {
    const item = document.getElementById('lineGraph')
    if (item) {
      const promises = []
      const ff = html2canvas(item).then(canvas => canvas.toDataURL('image/png'))
      ff.then(res => {
        setLineChartImage(res)
        setIsResponsePdf(true)
      })
    }
  }

  const handleLineChartPdf = () => {
    getLineChartImage()
    setIsResponsePdf(true)
  }

  const hidePdfDrawer = () => {
    setIsResponsePdf(false)
    setLineChartImage(null)
  }
  const handlePhaseLine = checked => {
    setPhaseLine(checked)
  }

  const disabledDate = current => {
    if (allocatedDate) {
      if (current.format(dateFormat) < moment(allocatedDate).format(dateFormat)) {
        return true
      }
    }
    return false
  }

  if (loading) {
    return <LoadingComponent />
  }

  if (graphData === null) {
    return <Empty />
  }

  const handleSwitchChange = checked => {
    setIsChecked(checked)
    setIsEditable(false)
    setIsTherapist(false)
  }
  const handleTherapist = checked => {
    setIsTherapist(checked)
    setIsChecked(false)
    setIsEditable(false)
  }
  const DateChangeDropDown = date => {
    setDateRange(date)
  }

  return (
    <div>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ ...parentDiv }}>
          <span style={parentLabel}>Date:</span>
          <RangeDatePickerForGraph
            date={dateRange}
            disabledDate={disabledDate}
            disableRedux
            rangeType={defaultDateRange}
            setDate={v => {
              setDateRange(v)
              // dispatch({
              //   type: 'user/SET_STATE',
              //   payload: {
              //     defaultDateDRR: v,
              //   },
              // })
            }}
            from="DRR_GRAPH"
          />

          <span
            style={{
              ...parentLabel,
              color: isEditable ? '#1890FF' : '#BDBDBD',
              fontWeight: '600',
            }}
          >
            Edit:
          </span>
          <Switch
            style={{ marginTop: 5 }}
            checked={isEditable}
            onChange={checked => {
              setIsEditable(checked)
              setIsChecked(false)
              setIsTherapist(false)
            }}
          />
          <span
            style={{
              ...parentLabel,
              color: isChecked ? '#1890FF' : '#BDBDBD',
              fontWeight: '600',
            }}
          >
            Discrete:
          </span>
          <Switch style={{ marginTop: 5 }} checked={isChecked} onChange={handleSwitchChange} />
          <span
            style={{
              ...parentLabel,
              color: isChecked ? '#1890FF' : '#BDBDBD',
              fontWeight: '600',
            }}
          >
            PhaseLine:
          </span>
          <Switch style={{ marginTop: 5 }} checked={phaseLine} onChange={handlePhaseLine} />
          <span
            style={{
              ...parentLabel,
              color: isChecked ? '#1890FF' : '#BDBDBD',
              fontWeight: '600',
            }}
          >
            Split By Therapist:
          </span>
          <Switch style={{ marginTop: 5 }} checked={isTherapist} onChange={handleTherapist} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Previous Target">
            <Button disabled={prevDisable} onClick={() => getPrevTarget(selectedTarget)}>
              <LeftOutlined /> Prev
            </Button>
          </Tooltip>
          <Tooltip title="Next Target">
            <Button disabled={nextDisable} onClick={() => getNextTarget(selectedTarget)}>
              Next <RightOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Download Graph">
            <Button onClick={handleLineChartPdf} type="link">
              <FaDownload fontSize="22px" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <span
        style={{
          ...parentLabel,
          color: '#000',
          fontWeight: '600',
          margin: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: 'white',
          padding: 10,
        }}
      >
        Target Valid From : {selectedTarget && moment(selectedTarget?.date).format('YYYY-MM-DD')}
      </span>
      <div id="lineGraph" style={{}}>
        {!isEditable && !isChecked && !isTherapist && graphData && (
          <div key={Math.random()} style={{ height: 500, marginBottom: 10, paddingBottom: 10 }}>
            <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
              <div>
                <Line options={getChartOptions()} data={getNoZeroData()} height={400} />
              </div>
            </div>
          </div>
        )}
        {isChecked &&
          !isTherapist &&
          mainTargetTrial &&
          mainTargetTrial.map(item => {
            let initLength = 0
            let targetedIndex = 0
            const temp = []

            item.forEach((mtt, index) => {
              const objLength = Object.keys(mtt).length
              if (objLength > initLength) {
                initLength = objLength
                targetedIndex = index
              }
              _.forEach(markers, (value, key) => {
                if (value !== undefined) {
                  if (key === 'phaseSet' && phaseLine && value.length !== 0) {
                    value.map(p => {
                      if (moment(p.node.date).format('YYYY-MM-DD') === mtt.day) {
                        const obj = {
                          axis: 'x',
                          value: moment(p.node.date).format('YYYY-MM-DD'),
                          lineStyle: { stroke: '#000000', strokeWidth: 2 },
                          legend: p.node.content,
                          legendPosition: 'top',
                        }
                        temp.push(obj)
                        // setPhaseData(old=>[...old,obj])
                      }
                      return p
                    })
                  }
                  if (
                    value &&
                    (key === 'in-maintenance' ||
                      key === 'baseline' ||
                      key === 'therapy' ||
                      key === 'master') &&
                    moment(value).format('YYYY-MM-DD') === mtt.day
                  ) {
                    const obj = {
                      axis: 'x',
                      value: moment(value).format('YYYY-MM-DD'),
                      lineStyle: { stroke: color[key], strokeWidth: 2 },
                      legend: status[key],
                      legendPosition: position[key],
                    }

                    // setPhaseData(old=>[...old,obj])
                    temp.push(obj)
                  }

                  // console.log("temp in eefect>>>",temp,isChecked);
                  // // setPhaseData(temp)
                  // const y=item[0].year
                  // const d={}
                  // d[y]=temp
                  // phase[index]={
                  //   month:item[0].month,
                  //   year:item[0].year,
                  //   data:temp
                  // }
                }
              })
            })

            const targetedObj = item[targetedIndex]
            const objKeys = Object.keys(targetedObj)
            const tempObjKeys = []
            objKeys.forEach(key => {
              if (key !== 'day') {
                tempObjKeys.push(key)
              }
            })

            return (
              <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
                <div style={{ height: 450 }}>
                  <ResponsiveBar
                    data={item}
                    keys={tempObjKeys}
                    indexBy="day"
                    margin={{ top: 50, right: 130, bottom: 90, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    valueFormat={{ format: '', enabled: false }}
                    colors="hsl(201, 52%, 77%)"
                    borderWidth={0.25}
                    markers={[...temp, ...masteryMarker]}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -45,
                      legend: 'day',
                      legendPosition: 'middle',
                      legendOffset: 60,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                  />
                </div>
              </div>
            )
          })}
        {isEditable &&
          !isTherapist &&
          graphData &&
          graphData.map((item, index) => {
            i += 1
            return (
              <div key={Math.random()} style={{ height: 500, marginBottom: 10, paddingBottom: 10 }}>
                <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
                  <div>
                    <Line
                      options={getChartOptions()}
                      data={getChartData(index)}
                      getElementAtEvent={e => handlePointClick(e, index)}
                      height={400}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        <div id="therapistGraph">
          {isTherapist &&
            !isChecked &&
            !isEditable &&
            therapist !== null &&
            therapistData.length !== 0 && (
              <div
                key={Math.random()}
                style={{
                  height: 550,
                  marginBottom: 30,
                  width: '100%',
                  paddingBottom: 10,
                  backgroundColor: 'white',
                }}
              >
                <div style={{ width: '100%', height: '100%' }}>
                  <ResponsiveLine
                    {...commonProperties}
                    key={Math.random()}
                    curve="monotoneX"
                    data={therapistHiddenData}
                    yScale={{
                      type: 'linear',
                      min: 0,
                      stacked: false,
                      reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisBottom={{
                      orient: 'bottom',
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -45,
                      // format: '%b %d',
                      // tickValues: 'every 3 days',
                    }}
                    colors={colorsArray}
                    lineWidth={3}
                    pointSize={10}
                    // pointSymbol={CustomSymbol}
                    pointBorderWidth={3}
                    pointBorderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.3]],
                    }}
                    enableGridY={true}
                    xScale={{ type: 'point' }}
                    enableGridX={false}
                    useMesh={true}
                    enableSlices={'x'}
                    markers={[...storyMark, ...masteryMarker]}
                    sliceTooltip={({ slice }) => {
                      return (
                        <div
                          style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                          }}
                        >
                          {slice.points.map(point => (
                            <div
                              key={point.id}
                              style={{
                                // color: point.serieColor,
                                display: 'flex',
                                padding: '3px 0',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: point.serieColor,
                                  width: 12,
                                  height: 12,
                                  marginTop: 5,
                                  marginRight: 5,
                                }}
                              ></div>
                              <strong>
                                {point.serieId} (Duration:{point.data.y} Frequency:
                                {point.data.frequency})
                              </strong>
                            </div>
                          ))}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            )}
        </div>
      </div>

      <Drawer
        title={selectedTarget?.targetAllcatedDetails?.targetName}
        placement="right"
        width={DRAWER.widthL2}
        closable
        destroyOnClose
        visible={showSessionTableDrawer}
        onClose={() => {
          if (closeDrawer) {
            closeDrawer()
          }
          setShowSessionTableDrawer(false)
        }}
      >
        <HeaderComponent title={''} showHomePath={false} learnerCard changeLearner={false} />
        <BehaviorSessionTable
          date={date}
          selectedTarget={selectedTarget}
          behaviorData={behaviorData}
          behaviorRefetch={behaviorRefetch}
        />
        <PhaseTable
          date={date}
          phaseData={phaseData}
          setPhaseData={data => handleData(data)}
          selectedTarget={selectedTarget}
        />
      </Drawer>

      <Drawer
        title="Pdf"
        placement="right"
        width={DRAWER.widthL2}
        closable
        onClose={hidePdfDrawer}
        visible={isResponsePdf}
      >
        {lineChartImage && (
          <DailyResponseRatePdf
            dateRange={dateRange}
            peakType={selectedTarget?.peakType}
            type={selectedTarget?.targetAllcatedDetails.targetType?.typeTar}
            targetStatus={selectedTarget?.targetStatus?.statusName}
            lineChartImage={lineChartImage}
            subTitle={sdPeak}
            target={selectedTarget}
          />
        )}
      </Drawer>
    </div>
  )
}
