/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changeUserPassword(input: { current: $oldPassword, new: $newPassword }) {
      status
      message
      user {
        id
        username
      }
    }
  }
`
