/* eslint-disable no-plusplus */
/* eslint-disable no-useless-concat */
import React from 'react'
import { useSelector } from 'react-redux'

const EquivalenceScoreBoard = ({ classIndex }) => {
  const {
    TargetActiveId,
    TargetResponse,
    SdCombinations,
    SelectedOperation,
    MasterSession,
    TargetActiveIndex,
  } = useSelector(state => state.sessionrecording)

  const getTrials = (combId, classId, operation) => {
    // console.log(combId, classId, operation)
    const recordings = TargetResponse[TargetActiveId].equivalence[operation][combId]?.[classId]
    // const recordings = []
    const colorList = []

    if (recordings) {
      for (let k = 0; k < 10; k++) {
        if (recordings[k]?.score === 0) {
          colorList.push('#FF8080')
        } else if (
          recordings[k]?.score === 2 ||
          recordings[k]?.score === 4 ||
          recordings[k]?.score === 8
        ) {
          colorList.push('#FF9C52')
        } else if (recordings[k]?.score === 10) {
          colorList.push('#4BAEA0')
        } else {
          colorList.push('')
        }
      }
    }

    const Trials = []
    for (let i = 0; i < 10; i++) {
      Trials.push(
        <div
          style={{
            display: 'inline-block',
            marginRight: '5px',
            // marginLeft: '8px',
            textAlign: 'center',
          }}
        >
          <span
            style={{
              height: 15,
              display: 'inline-block',
              lineHeight: '12px',
              width: 6,
              // border: '1px solid black',
              backgroundColor: colorList[i] ? colorList[i] : '#c4c4c4',
              // paddingLeft: '20px',
              borderRadius: '2px',
              marginRight: '2px',
            }}
          >
            &nbsp;
          </span>
        </div>,
      )
    }

    return Trials
  }

  const getClass = (index, trialsList) => {
    const combinations = []

    if (SelectedOperation === 'Train') {
      for (let i = 0; i < SdCombinations?.train.edges.length; i++) {
        const combinationNode = SdCombinations?.train.edges[i]
        const comb =
          `${combinationNode.node.stimulus1} ` +
          `${combinationNode.node.sign12} ` +
          `${combinationNode.node.stimulus2} ` +
          `${combinationNode.node.sign23 ? combinationNode.node.sign23 : ''} ` +
          `${combinationNode.node.stimulus3 ? combinationNode.node.stimulus3 : ''} `
        combinations.push(
          <span>
            {getTrials(
              combinationNode.node.id,
              MasterSession.targets.edges[TargetActiveIndex].node.classes.edges[index]?.node.id,
              'train',
            )}{' '}
          </span>,
          // <p>Class {index + 1} - {comb} - {getTrials(combinationNode.node.id, MasterSession.targets.edges[TargetActiveIndex].node.classes.edges[index]?.node.id, 'train')} </p>
        )
      }
    }
    if (SelectedOperation === 'Test') {
      for (let i = 0; i < SdCombinations?.test.edges.length; i++) {
        const combinationNode = SdCombinations?.test.edges[i]
        const comb =
          `${combinationNode.node.stimulus1} ` +
          `${combinationNode.node.sign12} ` +
          `${combinationNode.node.stimulus2} ` +
          `${combinationNode.node.sign23 ? combinationNode.node.sign23 : ''} ` +
          `${combinationNode.node.stimulus3 ? combinationNode.node.stimulus3 : ''} `
        combinations.push(
          <span>
            {getTrials(
              combinationNode.node.id,
              MasterSession.targets.edges[TargetActiveIndex].node.classes.edges[index]?.node.id,
              'test',
            )}{' '}
          </span>,
          // <p>Class {index + 1} - {comb} - {getTrials(combinationNode.node.id, MasterSession.targets.edges[TargetActiveIndex].node.classes.edges[index]?.node.id, 'test')} </p>
        )
      }
    }

    return <div style={{ padding: '2px' }}>{combinations}</div>
  }
  const Classes = []

  Classes.push(getClass(classIndex))

  return <>{Classes}</>
}
export default EquivalenceScoreBoard
