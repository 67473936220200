const actions = {
  SET_STATE: 'tasks/SET_STATE',
  GET_TASKS: 'tasks/GET_TASKS',
  GET_CLOSED_TASKS: 'tasks/GET_CLOSED_TASKS',
  EDIT_TASK: 'tasks/EDIT_TASK',
  CREATE_TASK: 'tasks/CREATE_TASK',
  UPDATE_TASKS_LIST: 'tasks/UPDATE_TASKS_LIST',
  APPEND_TASKS_LIST: 'tasks/APPEND_TASKS_LIST',
  GET_TASKS_DROPDOWNS: 'tasks/GET_TASKS_DROPDOWNS',
  UPDATE_TASK_STATUS: 'tasks/UPDATE_TASK_STATUS',
  UPDATE_TASK_COUNTER: 'tasks/UPDATE_TASK_COUNTER',
}

export default actions
