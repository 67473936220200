/* eslint-disable no-useless-computed-key */
import actions from './actions'

const initialState = {
  loading: false,

}

export default function sessionrecordingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
