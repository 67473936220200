/* eslint-disable no-nested-ternary */
import React from 'react'
import { Form, Input, Button, Select, TreeSelect, InputNumber, Row, Col } from 'antd'
import { SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import { useTargetAlloc } from '../context'

const { Option } = Select

const PROGRESS_TO = [
  'Baseline',
  'Intervention',
  'In-Maintenance',
  'Generalization',
  'Mastered',
  'Next Item in Hierarchy',
  'Previous Item in Hierarchy',
  'Do not progress',
  'Remove from all sessions',
]

const BEHAVIOR_RECORDING_FREQ = 'Behavior Recording Frequency'
const BEHAVIOR_RECORDING_DUR = 'Behavior Recording Duration'

const { TreeNode } = TreeSelect
const MasteryForm = ({
  form,
  edit,
  criteriaTargetObj,
  details,
  closeModal,
  mstSeqObj,
  setMstSeqObj,
}) => {
  const { selectedTab } = useTargetAlloc()
  const { getFieldDecorator, getFieldValue, validateFields } = form

  const getProgessToOptions = statusWise => {
    if (statusWise) {
      const sbd = new Set(
        mstSeqObj.phaseSet
          .filter(it => it.criteriaTypeName !== details.criteriaTypeName)
          .map(it => it.criteriaTypeName),
      )
      return [...sbd]
    }

    const idx = mstSeqObj.phaseSet.indexOf(details)
    const sbd = []

    if (mstSeqObj.phaseSet.length > 1) {
      if (idx === 0) {
        sbd.push('Next Item in Hierarchy')
      } else if (idx === mstSeqObj.phaseSet.length - 1) {
        sbd.push('Previous Item in Hierarchy')
      } else {
        sbd.push('Next Item in Hierarchy', 'Previous Item in Hierarchy')
      }
    }
    sbd.push('Do not progress', 'Remove from all sessions')
    return sbd
  }

  const SubmitForm = e => {
    e.preventDefault()
    validateFields((error, values) => {
      if (!error && details?.key) {
        const tempPhaseSet = mstSeqObj.phaseSet.map(item => {
          if (item.key === details.key) {
            return {
              ...item,
              masterySet: [
                ...item.masterySet,
                {
                  key: Math.random(),
                  calculationType: values.calculationType,
                  percentage: values.percentage,
                  durationCalType: values.durationCalType,
                  calFrameType: values.calFrameType,
                  calFrameDurationType: values.calFrameDurationType,
                  calFrameDurationValue: values.calFrameDurationValue,
                  minimumTrial: values.minimumTrial,
                  progressTo: values.progressTo,
                  phaseChangeLabel: values.phaseChangeLabel,
                  duration: values.duration,
                  frequency: values.frequency,
                },
              ],
            }
          }
          return item
        })
        setMstSeqObj({ ...mstSeqObj, isTargetSpecific: true, phaseSet: tempPhaseSet })
        if (closeModal) {
          closeModal()
        }
      }
    })
  }

  const handleUpdate = e => {
    e.preventDefault()
    validateFields((error, values) => {
      if (!error && criteriaTargetObj?.key) {
        const tempPhaseSet = mstSeqObj.phaseSet.map(item => {
          if (item.key === details.key) {
            const tempMasterySet = item.masterySet.map(mst => {
              if (mst.key === criteriaTargetObj.key) {
                return { ...mst, ...values }
              }
              return mst
            })
            return { ...item, masterySet: tempMasterySet }
          }
          return item
        })
        setMstSeqObj({ ...mstSeqObj, isTargetSpecific: true, phaseSet: tempPhaseSet })
        if (closeModal) {
          closeModal()
        }
      }
    })
  }

  return (
    <Form name="targetForm">
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item label="Calculation Type">
            {getFieldDecorator('calculationType', {
              initialValue: edit ? criteriaTargetObj?.calculationType : '>',
              rules: [{ required: true, message: 'Please provide calculation type' }],
            })(
              <Select placeholder="Calculation Type">
                <Option value=">">If Greater Than</Option>
                <Option value="<">If Less Than</Option>
                <Option value="==">If Equal</Option>
                <Option value=">=">If Greater Than or Equal To</Option>
                <Option value="<=">If Less Than or Equal To</Option>
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          {selectedTab === 'Regular' && (
            <Form.Item label="Response Percentage">
              {getFieldDecorator('percentage', {
                initialValue: edit ? criteriaTargetObj?.percentage : 45,
                rules: [{ required: true, message: 'Please enter percentage' }],
              })(
                <InputNumber
                  placeholder="Enter percentage ex - 45"
                  style={{ width: '100%' }}
                  min={0}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                />,
              )}
            </Form.Item>
          )}
          {selectedTab === BEHAVIOR_RECORDING_FREQ && (
            <Form.Item label="Frequency">
              {getFieldDecorator('frequency', {
                initialValue: edit ? criteriaTargetObj?.frequency : 10,
                rules: [{ required: true, message: 'Please enter fequency' }],
              })(
                <InputNumber
                  placeholder="Enter frequency ex - 10"
                  style={{ width: '100%' }}
                  min={0}
                />,
              )}
            </Form.Item>
          )}
          {selectedTab === BEHAVIOR_RECORDING_DUR && (
            <Form.Item label="Duration (in seconds)">
              {getFieldDecorator('duration', {
                initialValue: edit ? criteriaTargetObj?.duration : 30,
                rules: [{ required: true, message: 'Please enter duration (in seconds)' }],
              })(
                <InputNumber
                  placeholder="Enter percentage ex - 45 sec"
                  style={{ width: '100%' }}
                  min={0}
                />,
              )}
            </Form.Item>
          )}
        </Col>
        <Col span={8}>
          <Form.Item label="Calculation Type">
            {getFieldDecorator('durationCalType', {
              initialValue: edit ? criteriaTargetObj?.durationCalType : 'Per',
              rules: [{ required: true, message: 'Please provide duration calculation type' }],
            })(
              <Select placeholder="Duration Calculation Type">
                <Option value="Per">Per Day</Option>
                <Option value="Average">Average</Option>
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item label="Frame Type">
            {getFieldDecorator('calFrameType', {
              initialValue: edit ? criteriaTargetObj?.calFrameType : 'Cumulative',
              rules: [{ required: true, message: 'Please provide frame type' }],
            })(
              <Select placeholder="Frame Type">
                <Option value="Every">Every</Option>
                <Option value="Consecutive">Consecutive</Option>
                <Option value="Cumulative">Cumulative</Option>
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Frame Value">
            {getFieldDecorator('calFrameDurationValue', {
              initialValue:
                getFieldValue('calFrameType') === 'Every'
                  ? 1
                  : edit
                  ? criteriaTargetObj?.calFrameDurationValue
                  : 5,
              rules: [{ required: true, message: 'Required field' }],
            })(
              <InputNumber
                disabled={getFieldValue('calFrameType') === 'Every'}
                min={1}
                style={{ width: '100%' }}
              />,
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Frame Duration Type">
            {getFieldDecorator('calFrameDurationType', {
              initialValue: edit ? criteriaTargetObj?.calFrameDurationType : 'Day',
              rules: [{ required: true, message: 'Please provide frame type' }],
            })(
              <Select placeholder="Frame Type">
                <Option value="Day">Day</Option>
                {/* <Option value="Week">Week</Option>
                <Option value="Month">Month</Option> */}
                <Option value="Session">Session</Option>
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item label={`Minimum Trials / ${getFieldValue('calFrameDurationType')}`}>
            {getFieldDecorator('minimumTrial', {
              initialValue: edit ? criteriaTargetObj?.minimumTrial || 0 : 10,
              rules: [{ required: true, message: 'Required field' }],
            })(<InputNumber min={0} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Progress To">
            {getFieldDecorator('progressTo', {
              initialValue: edit && criteriaTargetObj?.progressTo,
              rules: [{ required: true, message: 'Please provide destination criteria' }],
            })(
              <TreeSelect placeholder="Please select" treeDefaultExpandAll>
                <TreeNode selectable={false} value="ab" title="Status Select" key="mst_parent_1">
                  {getProgessToOptions(true).map(item => {
                    return <TreeNode value={item} title={item} key={item} />
                  })}
                </TreeNode>
                <TreeNode selectable={false} title="Other Values" key="mst_parent_2">
                  {getProgessToOptions().map(item => {
                    return <TreeNode value={item} title={item} key={item} />
                  })}
                </TreeNode>
              </TreeSelect>,
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Phase Line Label">
        {getFieldDecorator('phaseChangeLabel', {
          initialValue: edit ? criteriaTargetObj?.phaseChangeLabel : getFieldValue('progressTo'),
          rules: [{ required: true, message: 'Please provide phase line label' }],
        })(<Input placeholder="Enter phase line label" />)}
      </Form.Item>

      <div className="d-flex justify-content-center">
        <Button onClick={edit ? handleUpdate : SubmitForm} type="primary" style={SUBMITT_BUTTON}>
          {edit ? 'Update' : 'Add'}
        </Button>
      </div>
    </Form>
  )
}

export default MasteryForm
