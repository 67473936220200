import { motion } from 'framer-motion'
import React from 'react'

const screen = {
  visible: { opacity: 1, x: 0 },
  initial: { opacity: 0, x: 600 },
  exit: { opacity: 0, x: 600, position: 'absolute' },
}

const AnimateFromRight = props => {
  const { children } = props

  return (
    <motion.div
      {...props}
      initial="initial"
      animate="visible"
      exit="exit"
      variants={screen}
      transition={{
        duration: 0.4,
        type: 'spring',
      }}
    >
      {children}
    </motion.div>
  )
}

export default AnimateFromRight
