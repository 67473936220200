/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Table } from 'antd'
import { DRAWER } from 'assets/styles/globalStyles'
import moment from 'moment'
import C1 from 'pages/SessionDataRecordingNew/CustomSessionScreennew'
import React, { useCallback, useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import { useDispatch, useSelector } from 'react-redux'
import {
  GET_BEHAVIOR_SESSION_RECORDINGS,
  GET_SESSION_BEHAVIOR_RECORD,
  GET_TODAYS_SESSION,
} from './query'

const EditableTable = ({ date, peakSd, selectedTarget, behaviorData, behaviorRefetch }) => {
  const [dataSource, setDataSource] = useState([])
  const [allSessions, setAllSessions] = useState([])
  const [visibleSession, setVisibleSession] = useState(false)
  const [behaviorTableData, setBehaviorTableData] = useState([])
  const [refetchingBeData, setRefetchingBeData] = useState(null)
  const [sessionId, setSessionId] = useState(null)

  const dispatch = useDispatch()

  const [
    getSessionRecording,
    { data: sessionData, loading: sessionDataLoading, error: err, refetch: refetchSession },
  ] = useLazyQuery(GET_BEHAVIOR_SESSION_RECORDINGS, {
    fetchPolicy: 'network-only',
  })

  const [getBehaviorData, { data, beLoading, error, refetch: beRefetch }] = useLazyQuery(
    GET_SESSION_BEHAVIOR_RECORD,
    {
      fetchPolicy: 'network-only',
    },
  )

  const student = useSelector(state => state.student)
  const [
    getAllSessionsData,
    {
      data: allSessionData,
      refetch: refetchAllSession,
      error: err2,
      loading: allSessionDataLoading,
    },
  ] = useLazyQuery(GET_TODAYS_SESSION)

  useEffect(() => {
    if (refetchingBeData) {
      const tableDataLength = behaviorTableData.length
      const { end, start, rate } = refetchingBeData
      setBehaviorTableData(prev => [
        ...prev,
        {
          key: tableDataLength + 1,
          trial: `Trial ${tableDataLength + 1}`,
          frequency: 1,
          duration: ((end - start) / 1000).toFixed(),
          rate,
        },
      ])
      setRefetchingBeData(null)
    }
  }, [behaviorTableData.length, refetchingBeData])

  useEffect(() => {
    if (!beLoading && data && data.getSessionDataRecording.edges.length > 0) {
      let totalDur = 0
      const tempDataSource = []
      data.getSessionDataRecording.edges.forEach(ed => {
        ed.node.behaviourRecording.edges.map(({ node }) => {
          if (node.frequency) {
            totalDur += node.end - node.start
            tempDataSource.push({
              key: node.frequency,
              trial: `Trial ${node.frequency}`,
              frequency: 1,
              duration: ((node.end - node.start) / 1000).toFixed(),
              rate: ((node.frequency * 1000) / totalDur).toFixed(3),
            })
          }
        })
      })
      setBehaviorTableData(tempDataSource)
    } else {
      setBehaviorTableData([])
    }
  }, [beLoading, data])

  useEffect(() => {
    getAllSessionsData({
      variables: {
        studentId: student.StudentId,
        date: moment(date).format('YYYY-MM-DD'),
        targets: [selectedTarget.id],
      },
    })
    getSessionRecording({
      variables: {
        studentId: student.StudentId,
        sessiondate: moment(date).format('YYYY-MM-DD'),
        targets: selectedTarget.id,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (err) {
      console.log(err)
    }
    if (err2) {
      console.log(err2)
    }
  }, [err, err2])

  useEffect(() => {
    if (allSessionData && allSessionData.GetStudentSession) {
      const { edges } = allSessionData.GetStudentSession
      setAllSessions(edges)
    }
  }, [allSessionData])

  useEffect(() => {
    if (sessionData && allSessions) {
      const {
        getSessionRecordings: { edges },
      } = sessionData

      const tableData = []
      if (edges.length > 0) {
        edges.forEach(({ node }, index) => {
          const {
            ChildSession: {
              sessions: { sessionName },
            },
            isBehRecording,

            behaviourRecording,
          } = node
          console.log('session>>>---', node)
          if (behaviourRecording.edges.length > 0) {
            const rec = behaviorRecording(behaviourRecording.edges, peakSd, sessionName, index)
            tableData.push({
              ...rec,
              targetId: node.targets?.id,
              childSession: node.ChildSession,
              name: `${sessionName.name}-${node.ChildSession.sessionNo}`,
            })
          }
        })

        const rec = createTotalForTable(tableData)
        tableData.push(rec)
        setDataSource(tableData)

        allSessions.forEach(({ node }) => {
          const check = tableData.filter(item => item.session === node.sessionName.name)
          tableData.push({
            key: Math.random(),
            id: node.id,
            date: node.date,
            frequency: 0,
            duration: 0,
            sessionNameId: node.sessionName?.id,
            session: node.sessionName?.name,
            name: node.sessionName?.name,
          })
        })
      } else {
        const recordedData = noRecordingInstance(allSessions)
        setDataSource(recordedData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData, allSessions])

  const behaviorRecording = useCallback((edges, recordType, sessionName, index) => {
    const { id, name } = sessionName

    let freq = 0
    let duration = 0
    edges.forEach(({ node }) => {
      console.log('node>>', node)
      freq += node.frequency
      duration += Number(node.end) - Number(node.start)
    })

    return {
      key: `${id + index}`,
      sessionNameId: id,
      session: name,
      name,
      frequency: freq,
      duration: Number(Number(duration / 1000).toFixed(0)),
    }
  }, [])

  const noRecordingInstance = useCallback(sessions => {
    const tableData = []

    sessions.forEach(({ node }, index) => {
      tableData.push({
        key: `${node.id + index}`,
        sessionNameId: node.sessionName.id,
        session: node.sessionName.name,
        name: node.sessionName.name,
        duration: 0,
        frequency: 0,
      })
    })
    tableData.push({
      key: tableData.length + 10,
      session: 'Total',
      name: 'Total',
      duration: 0,
      frequency: 0,
    })
    return tableData
  }, [])

  const createTotalForTable = useCallback(tableData => {
    const freq = tableData.map(({ frequency }) => frequency).reduce((a, b) => a + b, 0)
    const dura = tableData.map(({ duration }) => duration).reduce((a, b) => a + b, 0)

    return {
      key: tableData.length + 10,
      session: 'Total',
      name: 'Total',
      duration: dura,
      frequency: freq,
    }
  }, [])

  const handleStartSessionRecording = rowData => {
    console.log('rowdata>>', rowData)

    const { sessionNameId, session } = rowData
    if (session === 'Total') {
      return
    }

    getBehaviorData({
      variables: {
        targets: selectedTarget?.id,
        date: moment(date).format('YYYY-MM-DD'),
        ChildSession_Sessions_SessionName: rowData?.sessionNameId,
      },
    })

    setSessionId(rowData?.sessionNameId)

    const { edges } = allSessionData.GetStudentSession
    const mainSession = edges.find(item => item.node.sessionName.id === sessionNameId)

    if (mainSession) {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SessionId: rowData.childSession ? rowData.childSession.sessions.id : mainSession.node.id,
          SessionDate:
            date !== null ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          NewVersion: rowData.childSession === undefined,
          DefaultChildSession: rowData.childSession ? rowData.childSession : null,
        },
      })
      // dispatch({
      //   type: 'sessionrecording/SET_STATE',
      //   payload: {
      //     SessionId: mainSession.node.id,
      //     SessionDate:
      //       date !== null ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      //   },
      // })
      setVisibleSession(true)
    }
  }

  const handleCloseSessionDrawer = () => {
    refetchSession()
    refetchAllSession()
    setVisibleSession(false)

    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        FetchForSingleTarget: false,
      },
    })
  }

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    }
    if (row.childSession === undefined && row.name !== 'Total') {
      obj.props.colSpan = 0
    }
    return obj
  }

  const columns = [
    {
      title: 'Session',
      key: '1',
      render: row => {
        const { session, name } = row
        if (row.childSession === undefined && name !== 'Total') {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                <Button
                  style={{ fontWeight: 'bold' }}
                  type="primary"
                  onClick={() => handleStartSessionRecording(row)}
                >
                  Start New {name} Session <PlusOutlined />
                </Button>
              </div>
            ),
            props: {
              colSpan: 6,
            },
          }
        }
        return (
          <span
            role="button"
            style={{
              fontWeight: 'bold',
              cursor: 'pointer',
              color: '#4A7AB4',
            }}
            onClick={() => handleStartSessionRecording(row)}
          >
            {name} {''}
          </span>
        )
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: '2',
      render: renderContent,
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: '3',
      render: renderContent,
    },
  ]

  console.log('selectedTarget>>>', dataSource)
  return (
    <>
      {peakSd && (
        <p style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
          <span> {peakSd.toUpperCase()} </span>
        </p>
      )}
      <p style={{ fontSize: 18, fontWeight: 'bold' }}>
        <span>Date: </span> {moment(date).format('YYYY-MM-DD')}
      </p>
      <Drawer
        // eslint-disable-next-line prefer-template
        title={selectedTarget?.targetAllcatedDetails.targetName}
        placement="right"
        width={DRAWER.widthL1}
        closable
        destroyOnClose
        onClose={handleCloseSessionDrawer}
        visible={visibleSession}
      >
        <C1 selectedTarget={selectedTarget.id} />
      </Drawer>

      <Table
        loading={sessionDataLoading || allSessionDataLoading}
        columns={columns}
        dataSource={dataSource}
        bordered
      />
    </>
  )
}
export default EditableTable
