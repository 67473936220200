import { all, put, takeEvery, call } from 'redux-saga/effects'
import { getStudentDetails, getLearnerSessions } from 'services/student'
import actions from './actions'

export function* SOME_FUNCTION({ payload }) {
  yield put({
    type: 'student/SET_STATE',
    payload: {
      Loading: true,
    },
  })
}

export function* STUDENT_DETAILS({ payload }) {
  yield put({
    type: 'student/SET_STATE',
    payload: {
      LearnerSessionLoading: true,
      StudentDetails: null,
      StudentId: payload?.studentId,
    },
  })

  const response = yield call(getStudentDetails, payload)
  if (response && response.data) {
    localStorage.setItem('studentId', JSON.stringify(response.data.student?.id))

    yield put({
      type: 'student/SET_STATE',
      payload: {
        SelectedColor: payload?.selectedColor,
        StudentId: response.data.student?.id,
        StudentName: `${response.data.student?.firstname} ${response.data.student?.lastname || ''}`,
        ShortName: response.data.student?.firstname
          ? `${response.data.student?.firstname[0]}${
              response.data.student?.lastname ? response.data.student?.lastname[0] : ''
            }`.toUpperCase()
          : '',
        isCogActive: response.data.student?.isCogActive,
        isPeakActive: response.data.student?.isPeakActive,
        StudentDetails: response.data.student,
        LearnerSessions: [],
        LearnerChildSessions: [],
      },
    })
  }
}

export function* GET_STUDENT_SESSION({ payload }) {
  yield put({
    type: 'student/SET_STATE',
    payload: {
      LearnerSessionLoading: true,
    },
  })

  if (payload && payload?.studentId) {
    const sessionResponse = yield call(getLearnerSessions, payload)

    if (sessionResponse && sessionResponse.data) {
      yield put({
        type: 'student/SET_STATE',
        payload: {
          LearnerSessions: sessionResponse.data.GetStudentSession.edges,
          LearnerChildSessions: sessionResponse.data.getChildSession.edges,
        },
      })
    }
  }

  yield put({
    type: 'student/SET_STATE',
    payload: {
      LearnerSessionLoading: false,
    },
  })
}
export function* SET_DRAWER_WIDTH({ payload }) {
  yield put({
    type: 'student/SET_STATE',
    payload: {
      DrawerWidth: payload.width,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SOME_FUNCTION, SOME_FUNCTION),
    takeEvery(actions.STUDENT_DETAILS, STUDENT_DETAILS),
    takeEvery(actions.SET_DRAWER_WIDTH, SET_DRAWER_WIDTH),
    takeEvery(actions.GET_STUDENT_SESSION, GET_STUDENT_SESSION),
  ])
}
