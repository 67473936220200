/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { Select, Row, Col, Input, Form } from 'antd'
import { useTargetAlloc } from '../context'

const searchableDropDownOption = {
  showSearch: true,
  optionFilterProp: 'children',
  filterOption: (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}

const StgCard = ({
  form,
  edit = false,
  targetObject,
  isDataRecorded,
  addTargetMode,
  targetName,
  defaultDomain,
  handleTypeChange,
  defaultSettings,
  peakEnable = false,
  activeBehave,
  selectedShortTermGoal,
  setIsCoded,
}) => {
  const { getFieldDecorator } = form
  const {
    useDefaultGoal,
    shortGoalData,
    domainData,
    isDomainLoading,
    targetType,
    targetOptions,
  } = useTargetAlloc()

  let initialDomain = null
  let tempDomain = null
  if (domainData) {
    if (edit && targetObject) {
      tempDomain = targetObject.manualAllocateDomain?.domain || targetObject.domain?.domain
    } else {
      tempDomain = defaultDomain?.domain
    }

    const dt = domainData.domain.edges.filter(item => item.node.domain === tempDomain)
    const br = domainData.domain.edges.filter(
      item =>
        item.node.domain === 'Behavior Management.' || item.node.domain === 'Behavior Management',
    )

    if ((activeBehave === 'SBT' || activeBehave === 'recording') && br?.length > 0) {
      initialDomain = br[0].node.id
    } else if (dt && dt.length > 0) {
      initialDomain = dt[0].node.id
    } else {
      initialDomain = domainData.domain.edges[0]?.node.id
    }
  }
  useEffect(() => {
    if (activeBehave === 'Regular') {
      setIsCoded(true)
    }
  }, [activeBehave])
  return (
    <div>
      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item label="Short Term Goal">
            {getFieldDecorator('stg', {
              initialValue: useDefaultGoal
                ? ''
                : edit
                ? shortGoalData.filter(st => st.node.id === targetObject?.shortTerm?.id)[0]?.node.id
                : shortGoalData.filter(st => st.node.id === selectedShortTermGoal?.id)[0]?.node
                    .id || shortGoalData[0]?.node.id,
              rules: [{ required: !useDefaultGoal, message: 'Please select short term goal' }],
            })(
              <Select disabled={useDefaultGoal} {...searchableDropDownOption}>
                {shortGoalData?.map(({ node }) => {
                  return (
                    <Select.Option key={node.id} value={node.id}>
                      {node.goalName}
                    </Select.Option>
                  )
                })}
              </Select>,
            )}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Domain Name" name="Domain Name">
            {getFieldDecorator('domain', {
              initialValue: initialDomain,
              rules: [
                {
                  required: !(activeBehave === 'SBT' || activeBehave === 'recording'),
                  message: 'Please select a domain',
                },
              ],
            })(
              <Select
                name="domain"
                disabled={
                  activeBehave === 'SBT' ||
                  activeBehave === 'recording' ||
                  (addTargetMode && addTargetMode !== 'manually') ||
                  (edit && isDataRecorded)
                }
                {...searchableDropDownOption}
                loading={isDomainLoading}
              >
                {domainData?.domain.edges.map(({ node }) => (
                  <Select.Option key={node.id} value={node.id}>
                    {node.domain}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item label="Target Name" name="Target Name">
            {getFieldDecorator('name', {
              initialValue: edit ? targetObject?.targetName : targetName,
              rules: [{ required: true, message: 'Please give a target name' }],
            })(<Input name="targetName" placeholder="Enter target name" />)}
          </Form.Item>
        </Col>

        <Col span={12}>
          {edit && (
            <Form.Item label="Target Type" name="Target Type">
              {getFieldDecorator('type', {
                initialValue: edit ? targetObject?.targetType?.id : 'VGFyZ2V0RGV0YWlsVHlwZTo4',
                rules: [{ required: true, message: 'Please select a target type' }],
              })(
                <Select
                  name="targetType"
                  onChange={e => handleTypeChange(e)}
                  // disabled={isDataRecorded}
                  disabled
                  {...searchableDropDownOption}
                >
                  {targetOptions?.types.map(({ id, typeTar }) => {
                    return (
                      <Select.Option key={id} value={id}>
                        {typeTar === 'Peak' ? 'Coded' : typeTar}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>
          )}
          {!edit && (activeBehave === 'SBT' || activeBehave === 'recording') && (
            <Form.Item label="Target Type" name="Target Type">
              {getFieldDecorator('type', {
                initialValue:
                  activeBehave === 'SBT'
                    ? 'VGFyZ2V0RGV0YWlsVHlwZToxMA=='
                    : 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==',
                rules: [{ required: true, message: 'Please select a target type' }],
              })(
                <Select name="targetType" disabled>
                  <Select.Option
                    key="VGFyZ2V0RGV0YWlsVHlwZToxMA=="
                    value="VGFyZ2V0RGV0YWlsVHlwZToxMA=="
                  >
                    Skill Based Treatment
                  </Select.Option>
                  <Select.Option
                    value="VGFyZ2V0RGV0YWlsVHlwZToxMQ=="
                    key="VGFyZ2V0RGV0YWlsVHlwZToxMQ=="
                  >
                    Behavior Recording
                  </Select.Option>
                </Select>,
              )}
            </Form.Item>
          )}
          {!edit &&
            activeBehave === 'Regular' &&
            (peakEnable ? (
              <Form.Item label="Target Type" name="Target Type">
                {getFieldDecorator('type', {
                  initialValue: 'VGFyZ2V0RGV0YWlsVHlwZTo4',
                  rules: [{ required: true, message: 'Please select a target type' }],
                })(
                  <Select name="targetType" disabled {...searchableDropDownOption}>
                    <Select.Option value="VGFyZ2V0RGV0YWlsVHlwZTo4" key="VGFyZ2V0RGV0YWlsVHlwZTo4">
                      Coded
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>
            ) : (
              <Form.Item label="Target Type" name="Target Type">
                {getFieldDecorator('type', {
                  initialValue:
                    defaultSettings?.targetType?.id !== 'VGFyZ2V0RGV0YWlsVHlwZToxMA==' &&
                    defaultSettings?.targetType?.id !== 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==' &&
                    defaultSettings?.targetType?.id !== 'VGFyZ2V0RGV0YWlsVHlwZTo4'
                      ? defaultSettings?.targetType?.id || targetType[0]?.id
                      : targetType[0]?.id,
                  rules: [{ required: true, message: 'Please select a target type' }],
                })(
                  <Select
                    name="targetType"
                    placeholder="Select target type"
                    {...searchableDropDownOption}
                    onChange={e => handleTypeChange(e)}
                  >
                    {targetType?.map(({ id, typeTar }) => {
                      if (typeTar !== 'Peak' && typeTar !== 'Coded') {
                        return (
                          <Select.Option key={id} value={id}>
                            {typeTar}
                          </Select.Option>
                        )
                      }
                      return null
                    })}
                  </Select>,
                )}
              </Form.Item>
            ))}
        </Col>
      </Row>
    </div>
  )
}

export default StgCard
