/* eslint-disable no-plusplus */
/* eslint-disable object-shorthand */
/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
/* eslint-disable */

import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification, message } from 'antd'
import { getLeaders, createLeader, updateLeader, deleteLeader } from 'services/leaders'
import actions from './actions'
import { responsePathAsArray } from 'graphql'

export function* GET_DATA({ payload }) {
  yield put({
    type: 'leaders/SET_STATE',
    payload: {
      LeadersList: [],
      UserProfile: null,
      isUserProfile: false,
      TotalLeaders: 0,
      PageInfo: null,
    },
  })

  yield put({
    type: 'leaders/SET_STATE',
    payload: {
      loading: true,
    },
  })

  yield put({
    type: 'leaders/GET_LEADERS',
    payload: payload,
  })
}

export function* GET_LEADERS({ payload }) {
  const response = yield call(getLeaders, payload)

  if (response) {
    let leaders = []
    leaders = response.data.leads.edges.map(({ node: item }) => ({ ...item, key: Math.random() }))
    leaders.reverse()

    yield put({
      type: 'leaders/SET_STATE',
      payload: {
        LeadersList: leaders,
        TotalLeaders: leaders.length,
        PageInfo: 'I dont know what this is',
      },
    })
  }
  yield put({
    type: 'leaders/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_LEADER({ payload }) {
  yield put({
    type: 'leaders/SET_STATE',
    payload: {
      loadingLeader: true,
      leadUpdated: false,
    },
  })

  const response = yield call(createLeader, payload)
  if (response && response.data) {
    notification.success({
      message: 'Lead Created Successfully',
    })
  }

  yield put({
    type: 'leaders/SET_STATE',
    payload: {
      loadingLeader: false,
      leadUpdated: true,
    },
  })

  const dateGte = yield select(state => state.leaders.dateGte)
  const dateLte = yield select(state => state.leaders.dateLte)
  yield put({
    type: 'leaders/GET_LEADERS',
    payload: {
      dateGte,
      dateLte,
    },
  })
}

export function* EDIT_LEADER({ payload }) {
  console.log('this is payload', payload)
  yield put({
    type: 'leaders/SET_STATE',
    payload: {
      loadingLeader: true,
      leadUpdated: false,
    },
  })

  const dateGte = yield select(state => state.leaders.dateGte)
  const dateLte = yield select(state => state.leaders.dateLte)

  const response = yield call(updateLeader, payload)
  if (response && response.data) {
    notification.success({
      message: 'Lead Update Successfully',
    })

    yield put({
      type: 'leaders/SET_STATE',
      payload: {
        loadingLeader: false,
        leadUpdated: true,
      },
    })

    yield put({
      type: 'leaders/GET_LEADERS',
      payload: {
        dateGte,
        dateLte,
      },
    })
  }
}

export function* DELETE_LEADER({ payload }) {
  const response = yield call(deleteLeader, payload)
  console.log(response, 'repsone')
  if (response && response.data) {
    notification.success({
      message: 'Lead deleted Successfully',
    })
    const dateGte = yield select(state => state.leaders.dateGte)
    const dateLte = yield select(state => state.leaders.dateLte)

    yield put({
      type: 'leaders/GET_LEADERS',
      payload: {
        dateGte,
        dateLte,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    takeEvery(actions.GET_LEADERS, GET_LEADERS),
    takeEvery(actions.CREATE_LEADER, CREATE_LEADER),
    takeEvery(actions.EDIT_LEADER, EDIT_LEADER),
    takeEvery(actions.DELETE_LEADER, DELETE_LEADER),
  ])
}
