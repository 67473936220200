/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Button, Row, Col, Select, InputNumber, Typography } from 'antd'
import { useQuery } from 'react-apollo'
import { SUBMITT_BUTTON, COLORS } from 'assets/styles/globalStyles'
import { TARGET_STATUS } from './query'

const { Text } = Typography

const { Option } = Select

const IncrimentCard = ({ style, title, name, form, init }) => (
  <div
    style={{
      background: '#FFFFFF',
      border: '1px solid #E4E9F0',
      boxShadow: '0px 0px 4px rgba(53, 53, 53, 0.1)',
      borderRadius: 4,
      padding: '18px 19px',
      display: 'flex',
      alignItems: 'center',
      ...style,
    }}
  >
    <Text
      style={{
        fontSize: 16,
        fontWeight: 600,
        color: '#63686E',
        margin: 0,
        lineHeight: '22px',
      }}
    >
      {title}
    </Text>

    <Form.Item style={{ marginLeft: 'auto', marginBottom: 0 }}>
      {form.getFieldDecorator(name, {
        initialValue: init,
        rules: [{ required: true, message: 'Required field' }],
      })(<InputNumber min={0} style={{ width: 160 }} />)}
    </Form.Item>
  </div>
)

const NewMasteryForm = ({ form, obj, listMastery, setListMastery, setOpen }) => {
  const { add, editObj, type, key } = obj
  const { getFieldValue, getFieldDecorator } = form

  const { data, err, loading } = useQuery(TARGET_STATUS)

  const SubmitForm = e => {
    e.preventDefault()
    if (type === 'stimulus' || type === 'step') {
      form.validateFields((error, values) => {
        if (!error) {
          if (listMastery) {
            const tt = listMastery
            tt.push({
              ...values,
              gte: values.resKey === 0 ? 0 : values.responsePercentage,
              lte: values.resKey === 1 ? 100 : values.responsePercentage,
              key: Math.random(),
            })
            setListMastery(tt)
          } else {
            setListMastery(state => {
              const oldArr = state
              const toChangeArr = oldArr && oldArr[key]
              let changedArr = []
              if (toChangeArr) {
                changedArr.push({
                  ...values,
                  gte: values.resKey === 0 ? 0 : values.responsePercentage,
                  lte: values.resKey === 1 ? 100 : values.responsePercentage,
                  key: Math.random(),
                })
              } else {
                changedArr = [
                  {
                    ...values,
                    gte: values.resKey === 0 ? 0 : values.responsePercentage,
                    lte: values.resKey === 1 ? 100 : values.responsePercentage,
                    key: Math.random(),
                  },
                ]
              }
              return [...state, changedArr]
            })
          }
          form.resetFields()
          setOpen()
        }
      })
    } else {
      form.validateFields((error, values) => {
        if (!error) {
          setListMastery(state => {
            return [
              ...state,
              {
                ...values,
                gte: values.resKey === 0 ? 0 : values.responsePercentage,
                lte: values.resKey === 1 ? 100 : values.responsePercentage,
                key: Math.random(),
              },
            ]
          })
          form.resetFields()
          setOpen()
        }
      })
    }
  }

  const updateForm = e => {
    e.preventDefault()
    if (type === 'stimulus' || type === 'step') {
      form.validateFields((error, values) => {
        if (!error) {
          const tt = listMastery.map(item => {
            if (item.key === editObj.key) {
              return {
                ...values,
                gte: values.resKey === 0 ? 0 : values.responsePercentage,
                lte: values.resKey === 1 ? 100 : values.responsePercentage,
                key: item.key,
              }
            }
            return item
          })
          setListMastery(tt)
          form.resetFields()
          setOpen()
        }
      })
    } else {
      form.validateFields((error, values) => {
        if (!error) {
          const tt = listMastery.map(item => {
            if (item.key === editObj.key) {
              return {
                ...values,
                gte: values.resKey === 0 ? 0 : values.responsePercentage,
                lte: values.resKey === 1 ? 100 : values.responsePercentage,
                key: item.key,
              }
            }
            return item
          })
          setListMastery(tt)
          form.resetFields()
          setOpen()
        }
      })
    }
  }

  if (err) {
    return <pre>{JSON.stringify(err, null, 2)}</pre>
  }

  return (
    <div style={{ padding: '0 48px', width: '100%' }}>
      <Form layout="vertical">
        {add ? (
          <Row gutter={[24]}>
            <Col span={12}>
              <Form.Item label="From Status">
                {getFieldDecorator('fromStatus', {
                  rules: [{ required: true, message: 'Please enter From Status' }],
                })(
                  <Select placeholder="From Status" allowClear size="large" loading={loading}>
                    {data.targetStatus &&
                      data.targetStatus.map(question => (
                        <Option key={Math.random()} value={question.id}>
                          {question.statusName}
                        </Option>
                      ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="To Status">
                {getFieldDecorator('toStatus', {
                  rules: [{ required: true, message: 'Please enter To Status' }],
                })(
                  <Select placeholder="To Status" allowClear size="large" loading={loading}>
                    {data.targetStatus &&
                      data.targetStatus.map(question => (
                        <Option key={Math.random()} value={question.id}>
                          {question.statusName}
                        </Option>
                      ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row gutter={[24]}>
            <Col span={12}>
              <Form.Item label="From Status">
                {getFieldDecorator('fromStatus', {
                  initialValue: editObj?.fromStatus,
                  rules: [{ required: true, message: 'Please enter From Status' }],
                })(
                  <Select placeholder="From Status" allowClear size="large" loading={loading}>
                    {data.targetStatus &&
                      data.targetStatus.map(question => (
                        <Option key={Math.random()} value={question.id}>
                          {question.statusName}
                        </Option>
                      ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="To Status">
                {getFieldDecorator('toStatus', {
                  initialValue: editObj?.toStatus,
                  rules: [{ required: true, message: 'Please enter To Status' }],
                })(
                  <Select placeholder="To Status" allowClear size="large" loading={loading}>
                    {data.targetStatus &&
                      data.targetStatus.map(question => (
                        <Option key={Math.random()} value={question.id}>
                          {question.statusName}
                        </Option>
                      ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
        )}

        <div
          style={{
            background: '#FFFFFF',
            border: '1px solid #E4E9F0',
            boxShadow: '0px 0px 4px rgba(53, 53, 53, 0.1)',
            borderRadius: 4,
            padding: '18px 19px',
            display: 'flex',
            marginTop: 0,
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: '#63686E',
              margin: 0,
              lineHeight: '22px',
            }}
          >
            Response %
          </Text>

          <Form.Item style={{ marginLeft: 'auto', marginBottom: 0, marginRight: 5 }}>
            {getFieldDecorator('resKey', {
              initialValue: add ? 1 : editObj.resKey,
              rules: [{ required: true, message: 'Required field' }],
            })(
              <Select style={{ width: 160 }}>
                <Option key={Math.random()} value={0}>
                  {'<='}
                </Option>
                <Option key={Math.random()} value={1}>
                  {'>='}
                </Option>
              </Select>,
            )}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('responsePercentage', {
              initialValue: add ? 0 : editObj.responsePercentage,
              rules: [{ required: true, message: 'Required field' }],
            })(<InputNumber min={0} style={{ width: 160 }} />)}
          </Form.Item>
        </div>

        <div
          style={{
            background: '#FFFFFF',
            border: '1px solid #E4E9F0',
            boxShadow: '0px 0px 4px rgba(53, 53, 53, 0.1)',
            borderRadius: 4,
            padding: '18px 19px',
            display: 'flex',
            marginTop: 20,
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: '#63686E',
              margin: 0,
              lineHeight: '22px',
            }}
          >
            Days
          </Text>

          <Form.Item style={{ marginLeft: 'auto', marginBottom: 0, marginRight: 5 }}>
            {getFieldDecorator('masteryType', {
              initialValue: add ? 'Consecutive' : editObj.masteryType,
              rules: [{ required: true, message: 'Required field' }],
            })(
              <Select
                style={{ width: 160 }}
                onChange={e => (e === 'Daily' ? form.setFieldsValue({ days: 1 }) : e)}
              >
                <Option key={Math.random()} value="Consecutive">
                  Consecutive
                </Option>
                <Option key={Math.random()} value="Cumulative">
                  Cumulative
                </Option>
                <Option key={Math.random()} value="Daily">
                  Daily
                </Option>
              </Select>,
            )}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('days', {
              initialValue: add
                ? form.getFieldValue('masteryType') === 'Daily'
                  ? 1
                  : 0
                : editObj.days,
              rules: [{ required: true, message: 'Required field' }],
            })(
              <InputNumber
                disabled={form.getFieldValue('masteryType') === 'Daily'}
                min={0}
                style={{ width: 160 }}
              />,
            )}
          </Form.Item>
        </div>

        <IncrimentCard
          form={form}
          init={add ? 0 : editObj.minTrial}
          title="Minimum Trials"
          name="minTrial"
          style={{ marginTop: 20 }}
        />
        <div style={{ margin: '20px auto', fontSize: 16 }}>
          <InfoCircleOutlined style={{ color: COLORS.primary }} /> Manual mastery will overwrite the
          default selected mastery criteria
        </div>
        <Form.Item style={{ display: 'flex', justifyContent: 'center', margin: 0, marginTop: 20 }}>
          <Button
            type="primary"
            onClick={add ? SubmitForm : updateForm}
            style={{ ...SUBMITT_BUTTON, margin: 0 }}
          >
            {add ? 'Submit' : 'Update'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Form.create()(NewMasteryForm)
