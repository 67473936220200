/* eslint-disable no-nested-ternary */
/* eslint no-sparse-arrays: "error" */
/* eslint-disable */

/* eslint no-underscore-dangle:0 */
import React, { useState, useEffect } from 'react'
import { ResponsiveLine } from '@nivo/line'

import { Line } from 'react-chartjs-2'
import { Empty } from 'antd'
import 'chartjs-plugin-annotation'
import { useQuery, useLazyQuery } from 'react-apollo'
import { groupBy } from 'lodash'
import moment from 'moment'
import LoadingComponent from '../staffProfile/LoadingComponent'
// import { DRAWER } from '../../../assets/styles/globalStyles'
// import EditableTable from './EditableTable'

import { GET_BEHAVIOR_TEMPLATES } from '../../components/BehaviourData/queries'

const BehaviorFRChart = ({
  decelData,
  selectedStudentId,
  activeTab,
  keyname,
  startDate,
  endDate,
  selectedBehavior,
  createElement,
}) => {
  const [templateData, setTemplateData] = useState([])
  const [chartData, setChartData] = useState([])
  const [chartDrData, setChartDrData] = useState([])

  const [behName, setBehName] = useState('')
  const [isFRdata, setIsFRdata] = useState(false)
  const [clickedElement, setClickedElement] = useState('')
  const [dataRecord, setDataRecord] = useState([])
  const [phaseLines, setPhaseLines] = useState([])

  startDate = moment(startDate).format('YYYY-MM-DD')
  endDate = moment(endDate).format('YYYY-MM-DD')
  // console.log('slected behavior fr', startDate, endDate, selectedStudentId)

  const {
    data: tempData,
    error: templateError,
    loading: templateLoading,
    refetch: refetchTemplates,
  } = useQuery(GET_BEHAVIOR_TEMPLATES, {
    variables: {
      student: selectedStudentId,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setDataRecord([])
    if (tempData && tempData.getBehaviorTemplates.edges) {
      const tempArray = []
      tempData.getBehaviorTemplates.edges.forEach((element, index) => {
        const {
          behavior,
          behaviorType,
          frequencyratebehaviorrecordSet,
          durationbehaviorrecordSet,
          latencybehaviorrecordSet,
          partialintervalbehaviorrecordSet,
          wholeintervalbehaviorrecordSet,
          momentarytimebehaviorrecordSet,
          statusrecordSet,
        } = element.node
        if (behaviorType.includes('FR')) {
          if (frequencyratebehaviorrecordSet) {
            const behaviorRecords = frequencyratebehaviorrecordSet.edges.map(({ node }) => ({
              id: node.id,
              date: node.date,
              startTime: node.startTime,
              endTime: node.endTime,
              duration: node.duration,
              frequency: node.frequency,
              rate: node.rate,
              behaviorType: 'FR',
              tempData,
              behavior: behavior.behaviorName,
            }))

            const grouped = groupBy(behaviorRecords, 'date')

            const data = []
            Object.keys(grouped).forEach(item => {
              let duration = 0
              let frequency = 0
              let rate = 0
              grouped[item].forEach(d => {
                duration += d.duration
                frequency += d.frequency
                rate += d.rate
              })
              data.push({
                date: item,
                frequency,
                duration,
                rate,
                behavior,
              })
            })
            tempArray.push({
              behName: behavior.behaviorName,
              data,
            })
          }
        }
      })
      setDataRecord(tempArray)
    } else {
      setDataRecord([])
    }
  }, [tempData, selectedStudentId])

  const generateLabels = () => {
    const daysCnt = Math.abs(moment(endDate).diff(startDate, 'days'))
    const labels = Array(daysCnt + 1)
      .fill(0)
      .map((itemValue, itemIndex) => {
        return moment(startDate)
          .add(itemIndex, 'day')
          .format('YYYY-MM-DD')
      })
    return labels
  }
  const result = generateLabels()

  useEffect(() => {
    const datafr = []
    const datadr = []
    // console.log("dataFrRecord-->",dataRecord);
    if (dataRecord) {
      dataRecord.forEach((i, index) => {
        const frequency = []
        const duration = []
        setChartData([])
        setChartDrData([])

        result.forEach((item, index) => {
          const x = i.data.find(item1 => item === item1.date)
          if (x) {
            frequency.push({ x: moment(item).format('DD'), y: x.frequency })
            duration.push({ x: moment(item).format('DD'), y: x.duration })
          } else {
            frequency.push({ x: moment(item).format('DD'), y: 0 })
            duration.push({ x: moment(item).format('DD'), y: 0 })
          }
        })

        datafr.push({ id: i.behName, data: frequency })
        datadr.push({ id: i.behName, data: duration })
      })
      setChartData(datafr)
      setChartDrData(datadr)
    } else {
      console.log('else effect')
      setChartData([])
      setChartDrData([])
    }
  }, [dataRecord, startDate, endDate])

  // console.log("data for chart",chartData,chartDrData);
  const elem = document.getElementById('FRChart')
  createElement(elem)
  console.log('Element fr', elem)
  return (
    <div id="FRChart">
      {chartData.length === 0 && chartDrData.length === 0 ? (
        <Empty />
      ) : (
        <>
          <div style={{ height: 300 }}>
            <h3 style={{ padding: '5px' }}>Frequency Recording</h3>
            <ResponsiveLine
              key="FR"
              data={chartData}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: 'point' }}
              yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false,
              }}
              enableGridX={false}
              enableSlices="x"
              yFormat=" >-.2f"
              curve="linear"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Date',
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle',
              }}
              colors={{ scheme: 'category10' }}
              pointSize={10}
              pointColor={{ from: 'color' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 50,
                  itemsSpacing: 0,
                  itemDirection: 'right-to-left',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <div style={{ height: 50 }}> </div>
          <div style={{ height: 300 }}>
            <h3 style={{ padding: '5px', marginTop: '40px' }}>Duration Recording</h3>
            <ResponsiveLine
              key="DR"
              data={chartDrData}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: 'point' }}
              yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false,
              }}
              enableGridX={false}
              enableSlices="x"
              yFormat=" >-.2f"
              curve="linear"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Date',
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle',
              }}
              colors={{ scheme: 'category10' }}
              pointSize={10}
              pointColor={{ from: 'color' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 50,
                  itemsSpacing: 0,
                  itemDirection: 'right-to-left',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default BehaviorFRChart
