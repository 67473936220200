/* eslint-disable import/prefer-default-export */
export const Normal = {
  CORRECT: { newLabel: 'Correct', label: 'Correct', value: 'CORRECT' },
  INCORRECT: { newLabel: 'Incorrect', label: 'Incorrect', value: 'INCORRECT' },
  NO_RESPONSE: { newLabel: 'No Response', label: 'No Response', value: 'ERROR' },
  PROMPT: { newLabel: 'Prompt', label: 'Prompt', value: 'PROMPT' },
}

export const Coded = {
  ZERO: { newLabel: '0', label: '0', value: '0' },
  TWO: { newLabel: '2', label: '2', value: '2' },
  FOUR: { newLabel: '4', label: '4', value: '4' },
  EIGHT: { newLabel: '8', label: '8', value: '8' },
  TEN: { newLabel: '10', label: '10', value: '10' },
}

export const SBT = {
  R1: { newLabel: 'R1', label: 'R1', value: 'INCORRECT' },
  R2: { newLabel: 'R2', label: 'R2', value: 'INCORRECT' },
  NONE: { newLabel: 'None', label: 'None', value: 'ERROR' },
  PROMPT: { newLabel: 'Prompt', label: 'Prompt', value: 'PROMPT' },
  CORRECT: { newLabel: 'Correct', label: 'Correct', value: 'CORRECT' },
}

/* Target Label constants */
export const PeakLabels = [
  { label: '0', newLabel: '0', value: '0' },
  { label: '2', newLabel: '2', value: '2' },
  { label: '4', newLabel: '4', value: '4' },
  { label: '8', newLabel: '8', value: '8' },
  { label: '10', newLabel: '10', value: '10' },
]

export const NormalTargetLabels = [
  { newLabel: 'Correct', label: 'Correct', value: 'Correct' },
  { newLabel: 'Incorrect', label: 'Incorrect', value: 'Incorrect' },
  { newLabel: 'No Response', label: 'No Response', value: 'Error' },
  { newLabel: 'Prompt', label: 'Prompt', value: 'Prompt' },
]
