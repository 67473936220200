/* eslint-disable import/prefer-default-export */

import moment from 'moment'

const convertToHourMinSecondsStr = milliseconds => {
  const duration = moment.duration(milliseconds)
  const seconds = duration.seconds()
  const minutes = duration.minutes()
  const hours = duration.hours()
  let durationStr = ''
  if (hours > 0) {
    durationStr += `${hours}hr`
  }
  if (minutes > 0) {
    durationStr += `${durationStr.length > 0 ? ' ' : ''}${minutes}min`
  }
  if (seconds >= 0) {
    durationStr += `${durationStr.length > 0 ? ' ' : ''}${seconds}sec`
  }
  return durationStr
}

export { convertToHourMinSecondsStr }
