import 'rc-drawer/assets/index.css'
import React from 'react'
import DrawerMenu from 'rc-drawer'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'

const AppMenu = props => {
  const { isMenuTop, isMobileMenuOpen, isMobileView, isLightTheme } = useSelector(
    state => state.settings,
  )
  const dispatch = useDispatch()

  const toggleOpen = () => {
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  const BootstrappedMenu = () => {
    if (isMobileView) {
      return (
        <DrawerMenu
          getContainer={null}
          level={null}
          open={isMobileMenuOpen}
          onMaskClick={toggleOpen}
          onHandleClick={toggleOpen}
          className={isLightTheme ? 'drawer-light' : ''}
        >
          <MenuLeft />
        </DrawerMenu>
      )
    }
    if (isMenuTop) {
      return <MenuTop />
    }
    return <MenuLeft />
  }

  return BootstrappedMenu()
}

export default withRouter(AppMenu)
