const taskCodeDetails = {
  Mand: {
    '1': ['1-a*', '1-b*', '1-c*', '1-d*'].reverse(),
    '2': ['2-a*', '2-b*', '2-c', '2-d', '2-e'].reverse(),
    '3': ['3-a', '3-b', '3-c', '3-d'].reverse(),
    '4': ['4-a*', '4-b', '4-c', '4-d*', '4-e*'].reverse(),
    '5': ['5-a', '5-b', '5-c*', '5-d*', '5-e*'].reverse(),
    '6': ['6-a', '6-b', '6-c', '6-d*', '6-e*'].reverse(),
    '7': ['7-a', '7-b', '7-c', '7-d*', '7-e*', '7-f*', '7-g*'].reverse(),
    '8': ['8-a', '8-b', '8-c', '8-d*', '8-e*', '8-f*'].reverse(),
    '9': ['9-a', '9-b', '9-c', '9-d', '9-e', '9-f*'].reverse(),
    '10': ['10-a', '10-b*', '10-c', '10-d', '10-e', '10-f*'].reverse(),
    '11': ['11-a', '11-b*', '11-c', '11-d', '11-e*'].reverse(),
    '12': ['12-a', '12-b', '12-c', '12-d*', '12-e'].reverse(),
    '13': ['13-a*', '13-b*', '13-c*', '13-d', '13-e', '13-f'].reverse(),
    '14': ['14-a*', '14-b*', '14-c*', '14-d'].reverse(),
    '15': ['15-a', '15-b*', '15-c*', '15-d*', '15-e*'].reverse(),
  },
  Tact: {
    '1': ['1-a'].reverse(),
    '2': [].reverse(),
    '3': ['3-a*', '3-b*'].reverse(),
    '4': ['4-a*', '4-b*'].reverse(),
    '5': ['5-a', '5-b*', '5-c*'].reverse(),
    '6': ['6-a*', '6-b', '6-c*', '6-d*'].reverse(),
    '7': ['7-a*', '7-b*', '7-c', '7-d', '7-e'].reverse(),
    '8': ['8-a*', '8-b', '8-c*', '8-d*'].reverse(),
    '9': ['9-a*', '9-b*', '9-c*', '9-d*', '9-e*'].reverse(),
    '10': ['10-a', '10-b*', '10-c*', '10-d', '10-e'].reverse(),
    '11': [
      '11-a*',
      '11-b*',
      '11-c*',
      '11-d*',
      '11-e*',
      '11-f*',
      '11-g*',
      '11-h*',
      '11-i*',
    ].reverse(),
    '12': ['12-a*', '12-b*', '12-c', '12-d', '12-e', '12-f*', '12-g*'].reverse(),
    '13': ['13-a*', '13-b*', '13-c', '13-d', '13-e', '13-f'].reverse(),
    '14': ['14-a*', '14-b', '14-c', '14-d', '14-e'].reverse(),
    '15': ['15-a*', '15-b*', '15-c*', '15-d*', '15-e*', '15-f*', '15-g*'].reverse(),
  },
  Listener: {
    '1': ['1-a*'].reverse(),
    '2': ['2-a*'].reverse(),
    '3': ['3-a*', '3-b*', '3-c', '3-d', '3-e', '3-f'].reverse(),
    '4': ['4-a', '4-b*', '4-c*', '4-d', '4-e', '4-f*'].reverse(),
    '5': ['5-a*', '5-b', '5-c*', '5-d*', '5-e*'].reverse(),
    '6': ['6-a', '6-b*', '6-c*', '6-d'].reverse(),
    '7': ['7-a*', '7-b*', '7-c*', '7-d*'].reverse(),
    '8': ['8-a*', '8-b', '8-c*', '8-d*'].reverse(),
    '9': ['9-a*', '9-b*', '9-c*', '9-d*'].reverse(),
    '10': ['10-a*', '10-b', '10-c', '10-d*', '10-e*'].reverse(),
    '11': ['11-a*', '11-b', '11-c', '11-d', '11-e', '11-f'].reverse(),
    '12': ['12-a*', '12-b*', '12-c', '12-d*', '12-e*', '12-f'].reverse(),
    '13': ['13-a*', '13-b', '13-c', '13-d', '13-e*'].reverse(),
    '14': ['14-a', '14-b', '14-c*', '14-d*'].reverse(),
    '15': ['15-a*', '15-b*', '15-c*', '15-d*', '15-e*'].reverse(),
  },
  'VP/MTS': {
    '1': ['1-a*', '1-b*'].reverse(),
    '2': ['2-a', '2-b*', '2-c*'].reverse(),
    '3': ['3-a*', '3-b*'].reverse(),
    '4': ['4-a*', '4-b*'].reverse(),
    '5': ['5-a*', '5-b*', '5-c*', '5-d*', '5-e'].reverse(),
    '6': ['6-a*', '6-b*', '6-c*', '6-d*', '6-e*', '6-f', '6-g', '6-h*'].reverse(),
    '7': ['7-a*', '7-b', '7-c', '7-d', '7-e*', '7-f*'].reverse(),
    '8': ['8-a*', '8-b', '8-c*', '8-d*', '8-e*'].reverse(),
    '9': ['9-a', '9-b', '9-c', '9-d', '9-e*'].reverse(),
    '10': ['10-a*', '10-b*', '10-c*', '10-d*', '10-e*'].reverse(),
    '11': ['11-a*', '11-b', '11-c*', '11-d*'].reverse(),
    '12': ['12-a', '12-b*', '12-c', '12-d*'].reverse(),
    '13': ['13-a*', '13-b*', '13-c*', '13-d*'].reverse(),
    '14': ['14-a*', '14-b*', '14-c*', '14-d*'].reverse(),
    '15': ['15-a', '15-b', '15-c', '15-d*'].reverse(),
  },
  Play: {
    '1': ['1-a*', '1-b*', '1-c*', '1-d*'].reverse(),
    '2': ['2-a*', '2-b', '2-c*', '2-d*', '2-e*'].reverse(),
    '3': ['3-a*', '3-b', '3-c*', '3-d', '3-e*'].reverse(),
    '4': ['4-a*', '4-b*', '4-c*', '4-d*', '4-e*'].reverse(),
    '5': ['5-a*', '5-b', '5-c*', '5-d*', '5-e*'].reverse(),
    '6': ['6-a*', '6-b*', '6-c*', '6-d*', '6-e*'].reverse(),
    '7': ['7-a*', '7-b*', '7-c', '7-d*', '7-e*'].reverse(),
    '8': ['8-a', '8-b*', '8-c*', '8-d*'].reverse(),
    '9': ['9-a*', '9-b*', '9-c*', '9-d*'].reverse(),
    '10': ['10-a', '10-b*', '10-c*', '10-d*', '10-e*', '10-f*'].reverse(),
    '11': ['11-a*', '11-b*', '11-c*', '11-d*'].reverse(),
    '12': ['12-a*', '12-b*', '12-c*', '12-d*'].reverse(),
    '13': ['13-a', '13-b*', '13-c*', '13-d*', '13-e'].reverse(),
    '14': ['14-a*', '14-b*', '14-c*', '14-d*'].reverse(),
    '15': ['15-a*', '15-b*', '15-c*', '15-d*', '15-e*'].reverse(),
  },
  Social: {
    '1': ['1-a*', '1-b', '1-c'].reverse(),
    '2': ['2-a*', '2-b*', '2-c*'].reverse(),
    '3': ['3-a', '3-b*', '3-c*'].reverse(),
    '4': ['4-a*', '4-b', '4-c*', '4-d'].reverse(),
    '5': ['5-a', '5-b', '5-c*'].reverse(),
    '6': ['6-a*', '6-b*', '6-c', '6-d', '6-e'].reverse(),
    '7': ['7-a', '7-b*', '7-c', '7-d*'].reverse(),
    '8': ['8-a*', '8-b*', '8-c', '8-d'].reverse(),
    '9': ['9-a*', '9-b', '9-c', '9-d', '9-e*', '9-f*'].reverse(),
    '10': ['10-a', '10-b*', '10-c*', '10-d*', '10-e*'].reverse(),
    '11': ['11-a', '11-b*', '11-c*', '11-d*', '11-e*'].reverse(),
    '12': ['12-a', '12-b', '12-c', '12-d', '12-e*', '12-f'].reverse(),
    '13': ['13-a*', '13-b', '13-c*', '13-d', '13-e*'].reverse(),
    '14': ['14-a*', '14-b*', '14-c*', '14-d*', '14-e*'].reverse(),
    '15': ['15-a', '15-b*', '15-c*', '15-d*', '15-e*', '15-f*', '15-g*'].reverse(),
  },
  Imitation: {
    '1': ['1-a*', '1-b'].reverse(),
    '2': ['2-a', '2-b', '2-c*'].reverse(),
    '3': ['3-a', '3-b', '3-c*', '3-d*'].reverse(),
    '4': ['4-a*', '4-b*', '4-c', '4-d*'].reverse(),
    '5': ['5-a', '5-b', '5-c', '5-d*'].reverse(),
    '6': ['6-a', '6-b', '6-c', '6-d', '6-e'].reverse(),
    '7': ['7-a*', '7-b', '7-c*', '7-d*', '7-e*', '7-f', '7-g'].reverse(),
    '8': ['8-a*', '8-b*', '8-c*', '8-d*', '8-e*', '8-f*', '8-g*'].reverse(),
    '9': ['9-a*', '9-b*', '9-c', '9-d*'].reverse(),
    '10': ['10-a*', '10-b', '10-c', '10-d*', '10-e'].reverse(),
  },
  // Echoic: {
  //   '1': ['1-M'].reverse(),
  //   '2': ['2-M'],
  //   '3': ['3-M'],
  //   '4': ['4-M'],
  //   '5': ['5-M'],
  //   '6': ['6-M'],
  //   '7': ['7-M'],
  //   '8': ['8-M'],
  //   '9': ['9-M'],
  //   '10': ['10-M'],
  // },
  Echoic: {
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
    '6': [],
    '7': [],
    '8': [],
    '9': [],
    '10': [],
  },
  Vocal: { '1': [], '2': [], '3': [], '4': [], '5': [] },
  LRFFC: {
    '6': ['6-a*', '6-b*', '6-c*'].reverse(),
    '7': ['7-a', '7-b', '7-c*', '7-d*', '7-e*', '7-f*'].reverse(),
    '8': ['8-a', '8-b', '8-c', '8-d', '8-e', '8-f'].reverse(),
    '9': ['9-a*', '9-b*', '9-c', '9-d*', '9-e*'].reverse(),
    '10': ['10-a', '10-b', '10-c*', '10-d*', '10-e*'].reverse(),
    '11': ['11-a', '11-b', '11-c*', '11-d', '11-e*', '11-f*'].reverse(),
    '12': ['12-a', '12-b', '12-c', '12-d', '12-e', '12-f'].reverse(),
    '13': ['13-a*', '13-b', '13-c', '13-d', '13-e*', '13-f', '13-g*', '13-h*'].reverse(),
    '14': ['14-a*', '14-b*', '14-c*', '14-d*', '14-e*', '14-f*'].reverse(),
    '15': ['15-a*', '15-b*', '15-c*', '15-d*', '15-e'].reverse(),
  },
  IV: {
    '6': ['6-a', '6-b', '6-c', '6-d'].reverse(),
    '7': ['7-a', '7-b*', '7-c', '7-d*'].reverse(),
    '8': ['8-a', '8-b*', '8-c*', '8-d*'].reverse(),
    '9': ['9-a', '9-b*', '9-c*', '9-d*', '9-e*', '9-f*'].reverse(),
    '10': ['10-a', '10-b', '10-c*', '10-d*', '10-e', '10-f*', '10-g*'].reverse(),
    '11': ['11-a', '11-b', '11-c', '11-d', '11-e', '11-f*', '11-g*', '11-h*'].reverse(),
    '12': ['12-a', '12-b*', '12-c*', '12-d', '12-e', '12-f*', '12-g*'].reverse(),
    '13': ['13-a*', '13-b*', '13-c', '13-d', '13-e*', '13-f*', '13-g*', '13-h*', '13-i*'].reverse(),
    '14': [
      '14-a',
      '14-b',
      '14-c*',
      '14-d*',
      '14-e*',
      '14-f*',
      '14-g*',
      '14-h',
      '14-i',
      '14-j*',
      '14-k*',
    ].reverse(),
    '15': [
      '15-a*',
      '15-b',
      '15-c*',
      '15-d*',
      '15-e*',
      '15-f',
      '15-g*',
      '15-h*',
      '15-i*',
      '15-j*',
      '15-k*',
    ].reverse(),
  },
  Group: {
    '6': ['6-a*', '6-b', '6-c', '6-d*'].reverse(),
    '7': ['7-a*', '7-b', '7-c', '7-d*', '7-e*'].reverse(),
    '8': ['8-a', '8-b', '8-c*', '8-d'].reverse(),
    '9': ['9-a*', '9-b*', '9-c*', '9-d*'].reverse(),
    '10': ['10-a*', '10-b*', '10-c*', '10-d*', '10-e*', '10-f*', '10-g*'].reverse(),
    '11': ['11-a', '11-b', '11-c', '11-d*'].reverse(),
    '12': ['12-a*', '12-b*', '12-c*', '12-d'].reverse(),
    '13': ['13-a*', '13-b*', '13-c', '13-d*'].reverse(),
    '14': ['14-a*', '14-b', '14-c*', '14-d*', '14-e*', '14-f*'].reverse(),
    '15': ['15-a*', '15-b*', '15-c', '15-d*', '15-e*', '15-f*', '15-g*', '15-h*'].reverse(),
  },
  Linguistics: {
    '6': [].reverse(),
    '7': ['7-a', '7-b*', '7-c'].reverse(),
    '8': ['8-a*', '8-b', '8-c*', '8-d'].reverse(),
    '9': ['9-a', '9-b*', '9-c*', '9-d*', '9-e*'].reverse(),
    '10': ['10-a', '10-b*', '10-c', '10-d*'].reverse(),
    '11': ['11-a', '11-b', '11-c', '11-d*'].reverse(),
    '12': ['12-a', '12-b*', '12-c*', '12-d*'].reverse(),
    '13': ['13-a', '13-b', '13-c', '13-d*', '13-e'].reverse(),
    '14': ['14-a*', '14-b*', '14-c*', '14-d*', '14-e'].reverse(),
    '15': ['15-a', '15-b*', '15-c*', '15-d*', '15-e*', '15-f*', '15-g*'].reverse(),
  },
  Math: {
    '11': ['11-a', '11-b*', '11-c*', '11-d*', '11-e*'].reverse(),
    '12': ['12-a*', '12-b*', '12-c', '12-d'].reverse(),
    '13': ['13-a*', '13-b', '13-c', '13-d*'].reverse(),
    '14': ['14-a', '14-b', '14-c', '14-d', '14-e'].reverse(),
    '15': ['15-a*', '15-b*', '15-c*', '15-d*', '15-e*', '15-f*'].reverse(),
  },
  Reading: {
    '11': ['11-a*', '11-b*', '11-c*'].reverse(),
    '12': ['12-a*', '12-b*', '12-c*', '12-d*', '12-e*'].reverse(),
    '13': ['13-a*', '13-b*', '13-c*', '13-d*', '13-e*'].reverse(),
    '14': ['14-a*', '14-b*', '14-c*', '14-d*', '14-e*'].reverse(),
    '15': ['15-a*', '15-b*', '15-c*', '15-d*', '15-e*', '15-f*'].reverse(),
  },
  Writing: {
    '11': ['11-a', '11-b', '11-c*', '11-d', '11-e', '11-f', '11-g'].reverse(),
    '12': ['12-a', '12-b', '12-c', '12-d', '12-e*', '12-f*'].reverse(),
    '13': ['13-a', '13-b*', '13-c*', '13-d'].reverse(),
    '14': ['14-a', '14-b*', '14-c', '14-d'].reverse(),
    '15': ['15-a*', '15-b*', '15-c'].reverse(),
  },
}

export default taskCodeDetails
