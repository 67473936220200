import React, { useEffect, useContext } from 'react'
import TaskItem from './TaskItem'
import LoadingContextProvider, { LoadingContext } from '../context'
import StudentAndTestDetails from '../StudentAndTestDetails'

const TaskGraph = ({
  level1,
  level2,
  level3,
  data,
  onSelectionChange,
  refetch,
  areaId,
  selectedAssessment,
}) => {
  const LoadingC = useContext(LoadingContext)
  const { dataLoading, level, setLevel } = LoadingC
  useEffect(() => {
    if (!dataLoading) {
      const l1 = document.getElementById('level1')
      const l2 = document.getElementById('level2')
      const l3 = document.getElementById('level3')
      setLevel({
        l1,
        l2,
        l3,
      })
    }
  }, [dataLoading])
  const renderBars = (levelText, levelData) => (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
      {/* <h3 className="text-center">Level {levelText}</h3> */}
      <div style={{ display: 'flex' }}>
        {Object.keys(levelData).map(group => (
          <TaskItem key={`${group}-${levelText}`} levelText={levelText} group={levelData[group]} />
        ))}
      </div>
    </div>
  )

  return (
    <>
      <div id="level1">
        <h2>Task Analysis and skills Tracking: Level 1</h2>
        <StudentAndTestDetails
          scoreDetails={data.scoreDetails}
          studentDetails={data.studentDetails}
          onSelectionChange={id => onSelectionChange(id)}
          refetch={() => refetch()}
          areaId={areaId}
          levelNo={1}
          selectedAssessment={selectedAssessment}
        />
        <div className="taskChart">{renderBars(1, level1)}</div>
      </div>
      <div id="level2">
        <h2>Task Analysis and skills Tracking: Level 2</h2>

        <StudentAndTestDetails
          scoreDetails={data.scoreDetails}
          studentDetails={data.studentDetails}
          onSelectionChange={id => onSelectionChange(id)}
          refetch={() => refetch()}
          areaId={areaId}
          levelNo={2}
          selectedAssessment={selectedAssessment}
        />
        <div className="taskChart"> {renderBars(2, level2)}</div>
      </div>
      <div id="level3">
        <h2>Task Analysis and skills Tracking: Level 3</h2>

        <StudentAndTestDetails
          scoreDetails={data.scoreDetails}
          studentDetails={data.studentDetails}
          onSelectionChange={id => onSelectionChange(id)}
          refetch={() => refetch()}
          areaId={areaId}
          levelNo={3}
          selectedAssessment={selectedAssessment}
        />
        <div className="taskChart">{renderBars(3, level3)}</div>
      </div>
    </>
  )
}

export default TaskGraph
