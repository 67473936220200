/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint no-underscore-dangle:0 */
import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import { useQuery, useLazyQuery } from 'react-apollo'
import { groupBy } from 'lodash'
import moment from 'moment'
import { Drawer } from 'antd'
import { DRAWER } from '../../../assets/styles/globalStyles'
import { convertToHourMinSecondsStr } from '../BehaviorReport/utilities'

import EditableTable from './EditableTable'
import LoadingComponent from '../../staffProfile/LoadingComponent'
import { GET_BEHAVIOR_TEMPLATES } from '../../../components/BehaviourData/queries'

const BehaviorWIChart = ({
  decelData,
  selectedStudentId,
  activeTab,
  keyname,
  startDate,
  endDate,
  selectedBehavior,
  setStateOfParent,
}) => {
  const [templateData, setTemplateData] = useState([])
  const [dataRecord, setDataRecord] = useState([])
  const [isWIdata, setIsWIdata] = useState(false)
  const [phaseLines, setPhaseLines] = useState([])
  const [drawer, setDrawer] = useState(false)
  const [pointValue, setPointValue] = useState(null)
  const [clickedElement, setClickedElement] = useState('')
  const [behName, setBehName] = useState('')

  let annotation = []

  startDate = moment(startDate).format('YYYY-MM-DD')
  endDate = moment(endDate).format('YYYY-MM-DD')

  // const [frequencyData,setfrequencyData] =useState([])
  let frequencyData = []
  let iLength = []
  // let isWIdata=false
  const {
    data: tempData,
    error: templateError,
    loading: templateLoading,
    refetch: refetchTemplates,
  } = useQuery(GET_BEHAVIOR_TEMPLATES, {
    variables: {
      student: selectedStudentId,
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (tempData && tempData.getBehaviorTemplates) {
      tempData.getBehaviorTemplates.edges.map((element, index) => {
        const {
          behavior,
          behaviorType,
          statusrecordSet,
          frequencyratebehaviorrecordSet,
          durationbehaviorrecordSet,
          latencybehaviorrecordSet,
          partialintervalbehaviorrecordSet,
          wholeintervalbehaviorrecordSet,
          momentarytimebehaviorrecordSet,
        } = element.node

        if (behaviorType.includes('WI')) {
          if (wholeintervalbehaviorrecordSet) {
            const behaviorRecords = wholeintervalbehaviorrecordSet.edges.map(({ node }) => {
              let count = 0
              for (let i = 0; i < node.intervalChecks.length; i += 1) {
                if (node.intervalChecks[i] === '1') {
                  count += 1
                }
              }

              return {
                id: node.id,
                date: node.date,
                totalObservationTime: node.totalObservationTime,
                observedTime: node.observedTime,
                intervalLength: node.intervalLength,
                intervals: node.intervals,
                frequency: node.frequency,
                recordingType: node.recordingType,
                intervalChecks: node.intervalChecks,
                durationRecords: node.durationRecords,
                behaviorType: 'WI',
                count,
                tempData,
                behavior: behavior.behaviorName,
              }
            })

            if (statusrecordSet) {
              const status = statusrecordSet.edges.map(({ node }) => {
                return {
                  date: moment(node.statusTime).format('YYYY-MM-DD'),
                  status: node.status.statusName,
                }
              })
              setPhaseLines(olddata => [
                ...olddata,
                {
                  status,
                  behavior,
                },
              ])
            }

            const grouped = groupBy(behaviorRecords, 'date')

            const data = []
            Object.keys(grouped).forEach(item => {
              let count = 0
              let intervals = 0
              let frequency = 0
              let intervalLength = 0
              let rTime = 0
              let totalObservationTime = 0
              let performingTime = 0
              grouped[item].forEach(d => {
                count += d.count
                intervals += d.intervals
                frequency += d.frequency
                intervalLength += d.intervalLength
                totalObservationTime += d.totalObservationTime
                rTime += d.observedTime
                performingTime += Math.ceil(totalObservationTime - rTime)
              })
              data.push({
                date: item,
                count,
                intervals,
                frequency,
                intervalLength,
                performingTime,
                percentage: ((count / performingTime) * 100).toFixed(2),
                behavior,
              })
            })
            setDataRecord(olddata => [
              ...olddata,
              {
                behName: behavior.behaviorName,
                data,
              },
            ])
          }
        }
        return templateData.push(element)
      })
    }
  }, [tempData, selectedStudentId, selectedBehavior, startDate, endDate])

  useEffect(() => {
    if (tempData && tempData.getBehaviorTemplates.edges.length !== 0) {
      dataRecord.forEach(data => {
        data.data.map(item1 => {
          if (selectedBehavior && item1.behavior.id === selectedBehavior) {
            setIsWIdata(true)
            setBehName(item1.behavior.behaviorName)
          }
          return item1
        })
      })
    } else {
      setIsWIdata(false)
    }
  }, [dataRecord, tempData])

  useEffect(() => {
    if (pointValue) setClickedElement(pointValue)
  }, [pointValue])

  const getChartOptions = () => ({
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: tooltipItem => {
          return `Behavior:${tooltipItem.yLabel}% Frequency:${
            frequencyData[tooltipItem.index]
          } Performing:${convertToHourMinSecondsStr(iLength[tooltipItem.index] * 1000)}`
        },
      },
    },
    annotation: {
      annotations: annotation,
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          id: 'x-axis-0',
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Dates',
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: true,
          },
          labels: {
            show: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Behavior occurs(in %)',
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  })

  const generateLabels = () => {
    const daysCnt = Math.abs(moment(endDate).diff(startDate, 'days'))
    const labels = Array(daysCnt + 1)
      .fill(0)
      .map((itemValue, itemIndex) => {
        return moment(startDate)
          .add(itemIndex, 'day')
          .format('YYYY-MM-DD')
      })
    return labels
  }
  const result = generateLabels()

  let adata = {}
  phaseLines.forEach(item => {
    if (item.behavior.id === selectedBehavior) {
      adata = item.status.map(i => {
        return {
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: i.date,
          borderWidth: 2,
          borderColor: '#483D8B',
          text: i.status,
          label: {
            content: i.status,
            enabled: true,
            position: 'top',
            rotation: '90',
          },
        }
      })

      annotation = adata
    }
  })

  const getChartData = () => {
    const PI = new Array(result.length).fill(0)
    frequencyData = new Array(result.length).fill(0)
    iLength = new Array(result.length).fill(0)

    result.forEach((item, index) => {
      dataRecord.forEach(data => {
        data.data.map(item1 => {
          if (
            selectedBehavior !== undefined &&
            item1.behavior.id === selectedBehavior &&
            item === item1.date
          ) {
            PI[index] = item1.percentage
            frequencyData[index] = item1.frequency
            iLength[index] = item1.performingTime
          }
          return item1
        })
      })
    })

    const data = {
      labels: result,
      datasets: [
        {
          type: 'bar',
          label: 'Behavior Occurrs ',
          fill: false,
          backgroundColor: '#a6cee3',
          borderColor: '#a6cee3',
          hoverBackgroundColor: '#a6cee3',
          hoverBorderColor: '#a6cee3',
          yAxisID: 'y-axis-1',
          data: PI,
        },
      ],
    }
    return data
  }

  const getElementAtEvent = element => {
    if (!element.length) return

    const { _index } = element[0]

    setPointValue(chartData.labels[_index])
    setDrawer(true)
  }
  const chartData = getChartData()

  const showDrawerHandler = () => {
    setDrawer(true)
  }
  const hideDrawerHandler = () => {
    setDrawer(false)
  }

  useEffect(() => {
    const elem = document.getElementById('WI')
    if (elem) {
      setStateOfParent(elem)
    }
  }, [])
  return (
    <div id="WI">
      {!templateLoading ? (
        isWIdata ? (
          <div style={{ backgroundColor: 'white' }}>
            <div>
              <Line
                options={getChartOptions()}
                data={getChartData()}
                getElementAtEvent={getElementAtEvent}
                height={400}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              margin: '20px auto',
              textAlign: 'center',
              backgroundColor: 'white',
              padding: 5,
            }}
          >
            No Records found,Please Start Recording!!
          </div>
        )
      ) : (
        <LoadingComponent />
      )}

      <Drawer
        title={`Behavior-${behName}`}
        placement="right"
        width={DRAWER.widthL2}
        closable="true"
        onClose={hideDrawerHandler}
        visible={drawer}
      >
        <EditableTable
          behType="WI"
          showDrawer={drawer}
          date={pointValue}
          selectedBaheviorName={behName}
          selectedBehavior={selectedBehavior}
          selectedStudentId={selectedStudentId}
        />
      </Drawer>
    </div>
  )
}

export default BehaviorWIChart
