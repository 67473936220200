import React from 'react'
import { SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import { Form, Select, Input, Button, Row, Col } from 'antd'
import { useTargetAlloc } from '../context'

const { Item } = Form
const { Option } = Select

const statusMap = [
  { id: 'U3RhdHVzVHlwZTox', status: 'Baseline' },
  { id: 'U3RhdHVzVHlwZToz', status: 'Intervention' },
  { id: 'U3RhdHVzVHlwZToxMA==', status: 'Generalization' },
  { id: 'U3RhdHVzVHlwZTo1', status: 'In-Maintenance' },
  { id: 'U3RhdHVzVHlwZTo0', status: 'Mastered' },
]

const EditCriteriaForm = ({
  form,
  edit = false,
  objForEdit,
  closeModal,
  setMstSeqObj,
  mstSeqObj,
}) => {
  const { getFieldDecorator, validateFields } = form

  const { selectedTab, promptData, promptLoading } = useTargetAlloc()

  const handleSubmit = e => {
    e.preventDefault()
    validateFields((errr, values) => {
      if (!errr && objForEdit?.key) {
        const tempPhaseSet = mstSeqObj.phaseSet.map(item => {
          if (item.key === objForEdit.key) {
            return { ...item, ...values }
          }
          return item
        })
        setMstSeqObj({ ...mstSeqObj, isTargetSpecific: true, phaseSet: tempPhaseSet })
        if (closeModal) {
          closeModal()
        }
      }
    })
  }

  const handleAdd = e => {
    e.preventDefault()
    validateFields((errr, values) => {
      if (!errr) {
        const tempPhaseSet = [
          ...mstSeqObj.phaseSet,
          {
            key: Math.random(),
            criteriaType: values.criteriaType,
            criteriaTypeName: statusMap.filter(it => it.id === values.criteriaType)[0]?.status,
            autoLabel: values.autoLabel || '',
            prompt: values.prompt || [],
            masterySet: [],
            stepNo: mstSeqObj.phaseSet.length + 1,
          },
        ]
        setMstSeqObj({ ...mstSeqObj, isTargetSpecific: true, phaseSet: tempPhaseSet })
        if (closeModal) {
          closeModal()
        }
      }
    })
  }

  return (
    <div className="mastery-p">
      <Form>
        <Row gutter={[24]}>
          <Col span={12}>
            <Item label="Criteria Type">
              {getFieldDecorator('criteriaType', {
                initialValue: edit ? objForEdit.criteriaType : 'U3RhdHVzVHlwZTox',
                rules: [{ required: true, message: 'Please select criteria type' }],
              })(
                <Select disabled={edit} placeholder="Select Criteria Type">
                  {statusMap.map(it => (
                    <Option key={it.id} value={it.id}>
                      {it.status}
                    </Option>
                  ))}
                </Select>,
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Label">
              {getFieldDecorator('autoLabel', {
                initialValue: edit ? objForEdit.autoLabel || '' : '',
              })(<Input placeholder="Enter label" />)}
            </Item>
          </Col>
        </Row>
        {selectedTab === 'Regular' && (
          <Item label="Prompt">
            {getFieldDecorator('prompt', {
              initialValue: edit ? objForEdit.prompt : [],
            })(
              <Select loading={promptLoading} placeholder="Select Prompt Codes" mode="multiple">
                {promptData?.promptCodes.map(prompt => {
                  return (
                    <Option key={prompt.id} value={prompt.id}>
                      {prompt.promptName}
                    </Option>
                  )
                })}
              </Select>,
            )}
          </Item>
        )}
      </Form>

      <div style={{ display: 'flex' }}>
        <Button
          onClick={edit ? handleSubmit : handleAdd}
          type="primary"
          style={{ ...SUBMITT_BUTTON, margin: '0 auto' }}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

export default EditCriteriaForm
