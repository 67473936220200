/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
import moment from 'moment'
import react from 'react'
import { getDistance } from 'geolib'

function getCordinatesDistance(userCordinates, defaultCordinates) {
  console.log(userCordinates, defaultCordinates)

  let distance = 0
  // { latitude: 28.438426, longitude: 77.058851 }
  distance = getDistance(userCordinates, defaultCordinates)

  return distance
}

function arrayNotNull(array) {
  if (array !== undefined && array !== null && Array.isArray(array) && array.length > 0) {
    return true
  }
  return false
}

function notNull(data) {
  if (data !== undefined && data !== null && data !== '') {
    return true
  }
  return false
}

const MinuteToHourMinute = minutes => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  const str = `${hours} hour ${remainingMinutes} min`
  return str
}
function dateTimeToUtc(dateTime) {
  return moment(dateTime)
    .local()
    .utc()
    .format('YYYY-MM-DDTHH:mm:ssZ')
}

function timeToUtc(time) {
  return moment(time)
    .local()
    .utc()
    .format('hh:mm a')
}

function dateTimeToDate(dateTime) {
  return moment(dateTime)
    .local()
    .utc()
    .format('YYYY-MM-DD')
}

function capitalize(string) {
  // let finalString = ''
  if (typeof string !== 'string') return ''
  else {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  // return finalString
}

function combineDateAndTime(date, time) {
  const dateText = moment(date)
    .local()
    .utc()
    .format('YYYY-MM-DD')
  const timeText = moment(time)
    .local()
    .utc()
    .format('HH:mm:ssZ')
  return `${dateText}T${timeText}`
}

function combineLocalDateAndTime(date, time) {
  const dateText = moment(date)
    .local()
    .format('YYYY-MM-DD')
  const timeText = moment(time)
    .local()
    .format('HH:mm:ssZ')
  return `${dateText}T${timeText}`
}
function calculateAge(birthday) {
  const a = moment()
  const b = moment(birthday)
  const years = a.diff(b, 'years')
  b.add(years, 'years')
  const months = a.diff(b, 'months')
  const remainingMonths = months % 12

  return years + ' years ' + remainingMonths + ' months'
}

export const calculateAgeFromDates = (birthday, endDate) => {
  const a = moment(moment(endDate).endOf('month'))
  const b = moment(birthday)
  const years = a.diff(b, 'years')
  b.add(years, 'years')
  const months = a.diff(b, 'months')
  const remainingMonths = months % 12

  return years + ' years ' + remainingMonths + ' months'
}

const getDate = node => {
  const startDate = moment(node.dateInitialted)
  const endDate = moment(node.dateEnd)
  // const diff = moment(endDate) - moment(startDate)

  const years = endDate.diff(startDate, 'year')
  startDate.add(years, 'years')

  const months = endDate.diff(startDate, 'months')
  startDate.add(months, 'months')

  const days = endDate.diff(startDate, 'days')

  const date =
    `${moment(node.dateInitialted).format('MMMM DD, YYYY')} - ${endDate.format('MMMM DD, YYYY')}` +
    `, ${years > 0 ? `${years} Year` : ''} ${months > 0 ? `${months} Months` : ''} ${
      days > 0 ? `${days} Days` : ''
    }`
  return date
}

function getSessionName(object) {
  if (object.name) {
    return object.name
  } else return object.sessionName.name
}

function getStartAndEndDate(val) {
  if (val === 'Week') {
    const startDate = moment()
      .subtract(6, 'd')
      .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    return [startDate, endDate]
  } else if (val === 'Month') {
    const startDate = moment()
      .subtract(30, 'd')
      .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    return [startDate, endDate]
  } else if (val === '3 Month') {
    const startDate = moment()
      .subtract(90, 'd')
      .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    return [startDate, endDate]
  } else if (val === '6 Month') {
    const startDate = moment()
      .subtract(180, 'd')
      .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    return [startDate, endDate]
  } else return []
}

function getLast7Dates() {
  const dates = []
  for (let i = 0; i < 7; i++) {
    dates.push(
      moment()
        .subtract(6 - i, 'd')
        .format('DD/MM'),
    )
  }
  return dates
}

const dateToMilliseconds = (date1, date2) => {
  const sDate = moment(date1)
  const eDate = moment(date2)
  const dateDiff = eDate.diff(sDate, 'days')
  const ms = 24 * 60 * 60 * 1000 * dateDiff
  return ms
}

const dateToMillisecondsforcourse = (date1, date2) => {
  const sDate = moment(date1)
  const eDate = moment(date2)
  const dateDiff = eDate.diff(sDate, 'seconds')
  const ms = 1000 * dateDiff
  return ms
}

export function sortByNodeProperty(a, b, sortOrder, property) {
  if (a.node[property] && b.node[property]) {
    if (a.node[property].toLowerCase() < b.node[property].toLowerCase()) {
      if (sortOrder) {
        return -1
      }
      return 1
    }
    if (a.node[property].toLowerCase() > b.node[property].toLowerCase()) {
      if (sortOrder) {
        return 1
      }
      return -1
    }
  }
  return 0
}

export function sortBy2LevelNodeProperty(a, b, sortOrder, property1, property2) {
  if (a.node[property1] && b.node[property1]) {
    if (a.node[property1][property2] && b.node[property1][property2]) {
      if (a.node[property1][property2].toLowerCase() < b.node[property1][property2].toLowerCase()) {
        if (sortOrder) {
          return -1
        }
        return 1
      }
      if (a.node[property1][property2].toLowerCase() > b.node[property1][property2].toLowerCase()) {
        if (sortOrder) {
          return 1
        }
        return -1
      }
    }
  }
  return 0
}

export function sortByNodeDate(a, b, sortOrder, property) {
  if (a.node[property] && b.node[property]) {
    if (sortOrder) {
      return new Date(b.node[property]) - new Date(a.node[property])
    }
    return new Date(a.node[property]) - new Date(b.node[property])
  }
  return 0
}

export function sortByDate(a, b, sortOrder, property) {
  if (a[property] && b[property]) {
    if (sortOrder) {
      return new Date(b[property]) - new Date(a[property])
    }
    return new Date(a[property]) - new Date(b[property])
  }
  return 0
}

export function sortByProperty(a, b, sortOrder, property) {
  if (a[property] && b[property]) {
    if (a[property].toLowerCase() < b[property].toLowerCase()) {
      if (sortOrder) {
        return -1
      }
      return 1
    }
    if (a[property].toLowerCase() > b[property].toLowerCase()) {
      if (sortOrder) {
        return 1
      }
      return -1
    }
  }
  return 0
}

export const SEARCHABLE_DROPDOWN_OPTIONS = {
  showSearch: true,
  optionFilterProp: 'children',
  filterOption: (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}

const reverseArray = arr => {
  const reversedArray = []
  for (let i = arr.length - 1; i >= 0; i--) {
    reversedArray.push(arr[i])
  }
  return reversedArray
}

export {
  arrayNotNull,
  notNull,
  dateTimeToUtc,
  combineLocalDateAndTime,
  timeToUtc,
  capitalize,
  dateTimeToDate,
  combineDateAndTime,
  calculateAge,
  getSessionName,
  getDate,
  getStartAndEndDate,
  getLast7Dates,
  dateToMilliseconds,
  MinuteToHourMinute,
  getCordinatesDistance,
  dateToMillisecondsforcourse,
  reverseArray,
}
