/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
import React from 'react'
import { useSelector } from 'react-redux'

const ScoreBoard = ({ dailyTrails, boxWidth, id, sdKey = '', stepKey = '', sbtStepKey = '' }) => {
  const { TargetResponse } = useSelector(state => state.sessionrecording)
  let object = TargetResponse[id].target
  if (!(sbtStepKey === '')) object = TargetResponse[id].sbtStep[sbtStepKey]
  else if (!(sdKey === '')) object = TargetResponse[id].sd[sdKey]
  else if (!(stepKey === '')) object = TargetResponse[id].step[stepKey]

  const colorList = []
  if (object.length > 0) {
    object.map(item => {
      if (item.trial === 'CORRECT') {
        colorList.push('#4BAEA0')
      }
      if (item.trial === 'ERROR') {
        colorList.push('#4BAEA0')
      }
      if (item.trial === 'INCORRECT') {
        colorList.push('#FF8080')
      }
      if (item.trial === 'PROMPT') {
        colorList.push('#FF9C52')
      }
    })
  }

  const Trials = []
  let i = 0
  for (i = 0; i < dailyTrails; i++) {
    Trials.push(
      <span
        style={{
          height: '15px',
          display: 'inline-block',
          lineHeight: '12px',
          width: boxWidth,
          // border: '1px solid #999999',
          backgroundColor: colorList[i] ? colorList[i] : '#c4c4c4',
          // paddingLeft: '20px',
          borderRadius: '2px',
          marginRight: '5px',
        }}
      >
        &nbsp;
      </span>,
    )
  }

  return <>{Trials}</>
}

export default ScoreBoard
