/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */

import { UnorderedListOutlined } from '@ant-design/icons'
import { Button, Drawer, Form } from 'antd'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import StaffSelect from 'components/StaffSelect'
import React, { useState, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'

const StaffHeaderComponent = ({
  title = '',
  extraRightContent = null,
  extraLeftContent = null,
}) => {
  const dispatch = useDispatch()

  const { StaffsFetched, StaffProfile } = useSelector(state => state.staffs)
  const user = useSelector(state => state.user)
  const [visibleFilter, setVisibleFilter] = useState(false)

  useEffect(() => {
    if (!StaffsFetched) {
      dispatch({
        type: 'staffs/GET_STAFFS',
        payload: {
          isActive: true,
        },
      })
    }
  }, [])

  const onCloseFilter = () => {
    dispatch({
      type: 'student/SET_DRAWER_WIDTH',
      payload: {
        width: DRAWER.learnerL1,
      },
    })

    setVisibleFilter(false)
  }

  const showDrawerFilter = () => {
    setVisibleFilter(true)
  }

  const therapist = user.role === 'therapist' ? user.staffObject : StaffProfile

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        padding: '0px 10px',
        backgroundColor: '#FFF',
        boxShadow: '0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12)',
        marginBottom: '1px',
      }}
    >
      <div style={{ margin: '0px auto', position: 'relative', width: '100%', height: 50 }}>
        <div
          style={{
            padding: '5px 0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>{extraLeftContent}</div>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translateY(-50%) translate(-50%)',
              fontSize: 25,
              lineHeight: '36px',
            }}
          >
            {therapist?.name ? `${therapist?.name}'s ${title}` : 'No therapist found'}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: extraRightContent ? 10 : 0 }}>
              {user?.role === 'school_admin' && (
                <Button onClick={showDrawerFilter} size="large">
                  <UnorderedListOutlined style={{ fontSize: '20px' }} />
                </Button>
              )}
            </div>
            {extraRightContent}
          </div>
        </div>
      </div>
      <Drawer
        visible={visibleFilter}
        onClose={onCloseFilter}
        width="30%"
        title="Select Staff"
        placement="right"
      >
        <StaffSelect />
      </Drawer>
    </div>
  )
}

export default Form.create()(StaffHeaderComponent)
