/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_TARGET_TYPE_OPTIONS = gql`
  query {
    types {
      id
      typeTar
    }
    targetStatus(first: 4) {
      id
      statusName
    }
  }
`

export const SESSION_DATA = gql`
  query($targets_StudentId: ID, $date_Gte: Date, $date_Lte: Date, $session: ID) {
    getSessionDataRecording(
      targets_StudentId: $targets_StudentId
      date_Gte: $date_Gte
      date_Lte: $date_Lte
      ChildSession_Sessions_SessionName: $session
    ) {
      edges {
        node {
          id
          note
          targets {
            id
            peakType
            targetAllcatedDetails {
              id
              targetName
              targetType {
                id
                typeTar
              }
            }
          }
          ChildSession {
            id
            sessionDate
            createdAt
            duration
            feedback
            rating
            sessionNo
            providerSignature {
              signature
              longitude
              latitude
              createdAt
            }
            caregiverSignature {
              signature
              longitude
              latitude
              createdAt
            }
            sessions {
              id
              name
              sessionName {
                id
                name
              }
            }
            frequencyratebehaviorrecordSet {
              edges {
                node {
                  id
                  duration
                  frequency
                  studentBehaviorTemplate {
                    id
                    behavior {
                      behaviorName
                    }
                  }
                }
              }
            }
            mand
            dailyClickDataSet {
              edges {
                node {
                  date
                  data
                  promptedCount
                }
              }
            }
          }
          sessionRecord {
            edges {
              node {
                entryTime
                durationStart
                durationEnd
                promptCode {
                  id
                  promptName
                }
                sd {
                  id
                  sd
                }
                step {
                  id
                  step
                }
                trial
              }
            }
            totalCorrect
            totalIncorrect
            totalError
            totalPrompt
            totalTrial
            totalNr
            physical
            verbal
            gestural
            textual
          }
          peak {
            totalCorrect
            totalError
            totalPrompt
            totalTrial
            edges {
              node {
                id
                durationStart
                durationEnd
                trial {
                  edges {
                    node {
                      id
                      start
                      end
                      sd {
                        id
                        sd
                      }
                      marks
                    }
                  }
                }
              }
            }
          }

          peakEquivalance {
            totalCorrect
            totalError
            totalPrompt
            totalTrial
            edges {
              node {
                id
                score
                durationStart
                durationEnd
                relationTest {
                  id
                  stimulus1
                  sign12
                  stimulus2
                  sign23
                  stimulus3
                }
                codeClass {
                  id
                  name
                  stimuluses {
                    edges {
                      node {
                        id
                        stimulusName
                        option
                      }
                    }
                  }
                }
              }
            }
          }

          durationStart
          durationEnd
          isPeak
          isPeakEquivalance
          isBehReduction
          behReduction {
            totalCorrect
            totalIncorrect
            totalError
            totalPrompt
            totalTrial
            r1
            r2
            physical
            verbal
            gestural
            textual
            edges {
              node {
                id
                prompt {
                  id
                  promptName
                }
                trial
                duration
                r1 {
                  id
                  behaviorName
                }
                r2 {
                  id
                  behaviorName
                }
                r1Count
                r2Count
                trial
                sbtStep {
                  id
                  description
                }
              }
            }
          }
          totalBehRecordingDuration
          isBehRecording
          behaviourRecording {
            edges {
              node {
                id
                start
                end
                frequency
              }
            }
          }
        }
      }
      totalTarget
      edgeCount
      perCorrect
      perError
      perPrompt
      mandCount
      behCount
      toiletData {
        id
        time
        date
      }
      behData {
        id
      }
      mandData {
        id
        data
        promptedCount
        date
      }
    }
  }
`
