/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  Row,
  Col,
  Button,
  Typography,
  Form,
  DatePicker,
  Select,
  Dropdown,
  Tooltip,
  Menu,
  Drawer,
} from 'antd'
import html2canvas from 'html2canvas'
import { FaDownload } from 'react-icons/fa'
import JsPDF from 'jspdf'
import { connect, useSelector, useDispatch } from 'react-redux'
import { gql } from 'apollo-boost'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import Moment from 'moment'
import { useQuery } from 'react-apollo'

import NetworkChart from './NetworkChart'
import { STUDENT_QUERY } from './monthly-reports/monthlyReport.query'
import NetworkPDF from './NetworkPDF'
import SankeyGraph from './sankeyGraph'
import Report from './Report'
import DropSection from './DropSection'
import { GET_PAREA_DOMAIN, GET_STATUS } from './query'

const { Title, Text } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const parentCardStyle = {
  background: COLORS.palleteLight,
  borderRadius: 10,
  padding: '10px',
  margin: '10px 0 0 10px',
  height: 500,
  overflow: 'hidden',
}

const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
  backgroundColor: COLORS.palleteLight,
}

const parentDiv = { display: 'flex', margin: '5px 30px 5px 0' }

const cardStyle = {
  background: COLORS.palleteLight,
  height: '100%',
}
const NetworkSankey = props => {
  const { selectedStudentId, studentName } = props

  const { data: statusData } = useQuery(GET_STATUS, {
    fetchPolicy: 'network-only',
  })

  const { data: pDomain } = useQuery(GET_PAREA_DOMAIN, {
    fetchPolicy: 'network-only',
  })

  const [graphstartdate, setGraphstartdate] = useState(
    Moment(Date.now())
      .subtract(21, 'days')
      .format('YYYY-MM-DD')
      .toString(),
  )
  const [graphenddate, setGraphenddate] = useState(
    Moment(Date.now())
      .format('YYYY-MM-DD')
      .toString(),
  )
  const [targetStatus, setTargetStatus] = useState([])
  const [selectTargetArea, setSelectTargetArea] = useState(0)
  const [programArea, setProgramArea] = useState([])
  const [defaultprogram, setDefaultprogram] = useState('')
  const [domainObj, setDomainObj] = useState([])
  const [selectedprogram, setSelectedprogram] = useState(null)
  const [statusselected, setStatusselected] = useState(null)
  const [selectedprogramName, setselectedprogramName] = useState(null)
  const [statusName, setStatusName] = useState(null)
  const [domainSelected, setDomainSelected] = useState(null)
  const [pdfDrawer, setPdfDrawer] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [element, setElement] = useState(null)
  const [image, setImage] = useState({})

  useEffect(() => {
    if (statusData) {
      setTargetStatus(statusData.targetStatus)
      setSelectTargetArea(statusData.targetStatus[0].id)
      setStatusselected('All')
      setStatusName('All')
    }
  }, [statusData])

  useEffect(() => {
    if (pDomain) {
      const pArea = pDomain.programArea.edges
      const domain = pDomain.domain.edges
      const defprogram = pArea.filter(area => area.node.name === 'ABA')
      setProgramArea(pArea)
      setDomainObj(domain)
      setDefaultprogram(defprogram[0].node.id)
      setSelectedprogram(defprogram[0].node.id)
      setselectedprogramName(defprogram[0].node.name)
    }
  }, [pDomain])

  const ProgramSelected = program => {
    const programName = programArea.filter(area => area.node.id === program)
    setSelectedprogram(program)
    setselectedprogramName(programName[0].node.name)
  }

  const StatusCallback = status => {
    const stName = targetStatus.find(st => st.id === status)
    console.log('status>>', stName, targetStatus)
    setStatusselected(status)
    setStatusName(stName && stName !== undefined ? stName.statusName : null)
  }

  const DateChange = date => {
    setGraphstartdate(
      Moment(date[0])
        .format('YYYY-MM-DD')
        .toString(),
    )
    setGraphenddate(
      Moment(date[1])
        .format('YYYY-MM-DD')
        .toString(),
    )
  }

  const DateChangeDropDown = date => {
    console.log('DropDown', date)
    setGraphstartdate(
      Moment(date[0])
        .format('YYYY-MM-DD')
        .toString(),
    )
    setGraphenddate(
      Moment(date[1])
        .format('YYYY-MM-DD')
        .toString(),
    )
  }

  const setStateOfParent = elem => {
    console.log('leme>>', elem)
    if (elem) {
      setElement(elem)
    }
  }

  const GeneratePDF = () => {
    if (element) {
      const ff = html2canvas(element).then(canvas => {
        return canvas.toDataURL('image')
      })
      ff.then(res => {
        console.log('>>>>', res)
        setImage(prev => ({
          ...prev,
          imageData: res,
        }))
      })
    }
    setPdfDrawer(true)
    setLoadingPdf(true)
  }

  return (
    <>
      <Row>
        <Col sm={24} style={{ padding: 0 }}>
          <div style={filterCardStyle}>
            <div style={parentDiv}>
              <DropSection dateChange={DateChangeDropDown} />
            </div>
            <div style={parentDiv}>
              <Tooltip title="Select Date range">
                <RangePicker
                  style={{
                    marginLeft: 'auto',
                    width: 250,
                  }}
                  defaultValue={[
                    Moment(graphstartdate, 'YYYY-MM-DD'),
                    Moment(graphenddate, 'YYYY-MM-DD'),
                  ]}
                  value={[Moment(graphstartdate, 'YYYY-MM-DD'), Moment(graphenddate, 'YYYY-MM-DD')]}
                  format="YYYY-MM-DD"
                  onChange={DateChange}
                />
              </Tooltip>
            </div>
            <div style={parentDiv}>
              {targetStatus.length > 0 && (
                <Tooltip title="Select Status">
                  <Select
                    placeholder="All"
                    onChange={StatusCallback}
                    style={{
                      width: 170,
                      borderRadius: 4,
                    }}
                    allowClear
                    size="default"
                    showSearch
                    optionFilterProp="name"
                  >
                    {targetStatus &&
                      targetStatus
                        .filter(
                          result =>
                            result.statusName !== 'On Hold' && result.statusName !== 'Deleted',
                        )
                        .map(node => {
                          return (
                            <Option key={node.id} value={node.id} name={node.statusName}>
                              {node.statusName}
                            </Option>
                          )
                        })}
                  </Select>
                </Tooltip>
              )}
              {console.log(`Target status`, targetStatus)}
            </div>
            <div style={parentDiv}>
              {programArea.length > 0 && (
                <Tooltip title="Select Program Area">
                  <Select
                    placeholder="From Status"
                    onChange={ProgramSelected}
                    size="default"
                    defaultValue={defaultprogram}
                    style={{
                      width: 170,
                      borderRadius: 4,
                    }}
                  >
                    {programArea &&
                      programArea.map(dom => (
                        <Option key={dom.node.id} value={dom.node.id}>
                          {dom.node.name}
                        </Option>
                      ))}
                  </Select>
                </Tooltip>
              )}
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <Button type="link" size="large" onClick={GeneratePDF} style={{ padding: '0 8px' }}>
                <FaDownload fontSize="22px" />
              </Button>
            </div>
            <Drawer
              title={`${studentName}'s Network report`}
              placement="right"
              closable="true"
              onClose={() => {
                setLoadingPdf(false)
                setPdfDrawer(false)
              }}
              visible={pdfDrawer}
              width={DRAWER.widthL2}
            >
              <NetworkPDF
                title="Network"
                start={graphstartdate}
                end={graphenddate}
                studentId={selectedStudentId}
                image={image}
                selectedprogram={selectedprogramName}
                statusselected={statusName}
              />
            </Drawer>
          </div>

          <Row align="middle" justify="center">
            <div style={parentCardStyle}>
              <div
                id="networkChart"
                style={{
                  ...cardStyle,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh',
                }}
              >
                {selectedprogram && (
                  <NetworkChart
                    start_date={graphstartdate}
                    end_date={graphenddate}
                    selectedprogram={selectedprogram}
                    statusselected={statusselected}
                    domainSelected={domainSelected}
                    studentIdSelected={selectedStudentId}
                    setStateOfParent={setStateOfParent}
                    programName={selectedprogramName}
                  />
                )}
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Form.create()(NetworkSankey)
