// import { GraphQLClient } from 'graphql-request'
/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */

import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from 'apollo/config'

export async function getData({ isActive = true }) {
  console.log(isActive)
  return apolloClient
    .query({
      query: gql`
        query {
          students(isActive: ${isActive}) {
            edges {
              node {
                id
                firstname
                lastname
                email
                clinicLocation {
                  id
                  location
                }
                category {
                  id
                  category
                }
              }
            }
          }
          programArea(isActive: true) {
            edges {
              node {
                id
                name
                description
                percentageLong
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}

export async function getLearnerDetails(payload) {
  return apolloClient
    .query({
      query: gql`{ 
      student (id:"${payload.learnerId}") {
        id
        firstname
        mobileno
        email
        lastname
        isPeakActive
        isCogActive
        tags{
          edges{
            node{
              id
              name
            }
          }
        }
        caseManager {
          id
          name
          email
          contactNo
        }
        category {
          id
          category
        }
        programArea{
          edges{
            node{
              id
              name
              isActive
            }
          }
        }
        targetAllocateSet{
          edgeCount
        }
        admissionDate
      }
    }`,
    })
    .then(result => result)
    .catch(error => {
      console.log('Learner Details error ===> ', error)
    })
}

export async function getLearnerSessions(payload) {
  return apolloClient
    .query({
      query: gql`{ 
      getChildSession(sessions_Student:"${payload.learnerId}") {
        edges {
          node {
            id
            status
            sessions {
              id
              itemRequired
              duration
              sessionName {
                id
                name
              }
              targets {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
      GetStudentSession(studentId:"${payload.learnerId}") {
        edges {
          node {
            id
            name
            itemRequired
            duration
            sessionName {
              id
              name
            }
            instruction {
              edges {
                node {
                  id
                  instruction
                }
              }
            }
            childsessionSet {
              edges {
                node {
                  id
                  status
                  duration
                }
              }
            }
            sessionHost {
              edges {
                node {
                  id
                  memberName
                  relationship {
                    id
                    name
                  }
                }
              }
            }
            targets {
              edges {
                node {
                  id                  
                }
              }
            }
          }
        }
      }
    }`,
    })
    .then(result => result)
    .catch(error => {
      console.log('Learner Sessions error ===> ', error)
    })
}
