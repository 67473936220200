/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import { Button, DatePicker, Table, Drawer, Select, Tabs, Tooltip, Radio } from 'antd'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import moment from 'moment'
import React, { useEffect, useState, useReducer, useCallback, useMemo } from 'react'
import * as FileSaver from 'file-saver'
import html2canvas from 'html2canvas'
import { FileExcelOutlined, FilePdfOutlined, LoadingOutlined } from '@ant-design/icons'
import { useApolloClient } from 'react-apollo'
import * as XLSX from 'xlsx'

import { filter, groupBy, flatten } from 'lodash'
import RangeDatePicker from 'components/DatePicker/DatePicker'
import BehaviorPDF from './BehaviorPDF'
import BehaviorFRChart from '../behaviorCharts/BehaviorFRChart'
import BehaviorDRChart from '../behaviorCharts/BehaviorDRChart'
import BehaviorLRChart from '../behaviorCharts/BehaviorLRChart'
import BehaviorPIChart from '../behaviorCharts/BehaviorPIChart'
import BehaviorWIChart from '../behaviorCharts/BehaviorWIChart'
import BehaviorMTChart from '../behaviorCharts/BehaviorMTChart'
import ACTIONS from './actions'
import reducer from './reducer'
import './behavior.scss'

import { GET_BEHAVIOR_TEMPLATES, SESSION_DATA } from './query'

const { RangePicker } = DatePicker
const { Option } = Select

const getCount = intervalChecksStr => {
  let count = 0
  for (let i = 0; i < intervalChecksStr.length; i += 1) {
    if (intervalChecksStr[i] === '1') {
      count += 1
    }
  }
  return count
}

const filterCardStyle = {
  backgroundColor: COLORS.palleteLight,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
}

const parentDiv = { display: 'flex', margin: '5px 30px 5px 0' }

const initialState = {
  behaviorList: [],
  loading: true,
  selectedBehavior: undefined,
  startDate: moment().subtract(10, 'days'),
  endDate: moment(),
  componentsKey: Math.random(),
  behaviorData: [],
  behaviorTemplate: [],
  behaviorName: null,
  loadingPdf: false,
  pdfDrawer: false,
  pdfExporting: false,
  image: {},
}

const RECORDING_TYPES = {
  FR: 'Frequency And Rate',
  DR: 'Duration',
  LR: 'Latency',
  PR: 'Partial Interval',
  WR: 'Whole Interval',
  MR: 'Momentary Time Sampling',
  BR: 'Behavior Recording',
  SBT: 'SBT',
}

const baseRecord = {
  get name() {
    return `${this.startTime} - ${this.endTime}`
  },
}

baseRecord.init = function init(record) {
  this.id = Math.random()
  this.startTime = record.startTime
  this.endTime = record.endTime
}
function accumulateInterval(type) {
  const { parent } = this
  const selfData = this[type]
  if (parent && selfData) {
    if (!parent[type]) {
      parent[type] = {}
    }
    const parentData = parent[type]
    parentData.totalObservationTime =
      (parentData.totalObservationTime || 0) + selfData.totalObservationTime
    parentData.frequency = (parentData.frequency || 0) + selfData.frequency
    parentData.intervals = (parentData.intervals || 0) + selfData.intervals
    parentData.intervalLength = (parentData.intervalLength || 0) + selfData.intervalLength
    parentData.observedTime = (parentData.observedTime || 0) + selfData.observedTime
    parentData.count = (parentData.count || 0) + selfData.count
  }
}

function accumulateLR() {
  const type = RECORDING_TYPES.LR
  const { parent } = this
  const selfData = this[type]
  if (parent && selfData) {
    if (!parent[type]) {
      parent[type] = {}
    }
    const parentData = parent[type]
    parentData.latency = (parentData.latency || 0) + selfData.latency
  }
}

function accumulateDR() {
  const type = RECORDING_TYPES.DR
  const { parent } = this
  const selfData = this[type]
  if (parent && selfData) {
    if (!parent[type]) {
      parent[type] = {}
    }
    const parentData = parent[type]
    parentData.duration = (parentData.duration || 0) + selfData.duration
  }
}

function accumulateSBT() {
  const type = RECORDING_TYPES.SBT
  const { parent } = this
  const selfData = this[type]
  if (parent && selfData) {
    if (!parent[type]) {
      parent[type] = {}
    }
    const parentData = parent[type]
    parentData.duration = (parentData.duration || 0) + selfData.duration
    parentData.count = (parentData.count || 0) + selfData.count
  }
}

function accumulateFR() {
  const type = RECORDING_TYPES.FR
  const { parent } = this
  if (parent && this[type]) {
    const selfData = this[type]
    if (!parent[type]) {
      parent[type] = {}
    }
    const parentData = parent[type]
    parentData.duration = (parentData.duration || 0) + selfData.duration
    parentData.frequency = (parentData.frequency || 0) + selfData.frequency
    parentData.rate = (parentData.rate || 0) + selfData.rate
  }
}

function accumulateAll(node) {
  accumulateFR.call(node)
  accumulateDR.call(node)
  accumulateLR.call(node)
  accumulateSBT.call(node)
  accumulateInterval.call(node, RECORDING_TYPES.PR)
  accumulateInterval.call(node, RECORDING_TYPES.WR)
  accumulateInterval.call(node, RECORDING_TYPES.MR)
}

const convertToHourMinSecondsStr = milliseconds => {
  const duration = moment.duration(milliseconds)
  const seconds = duration.seconds()
  const minutes = duration.minutes()
  const hours = duration.hours()
  let durationStr = ''
  if (hours > 0) {
    durationStr += `${hours}hr`
  }
  if (minutes > 0) {
    durationStr += `${durationStr.length > 0 ? ' ' : ''}${minutes}min`
  }
  if (seconds > 0 || milliseconds === 0) {
    durationStr += `${durationStr.length > 0 ? ' ' : ''}${seconds}sec`
  }
  return durationStr
}

const renderDurationInSeconds = text => {
  if (text) {
    return convertToHourMinSecondsStr(text * 1000)
  }
  return text
}

const renderRate = (text, rateUnit) => {
  const formatRateUnit = () => {
    switch (rateUnit) {
      case 'SC':
        return 'sec'
      case 'MN':
        return 'min'
      case 'HR':
        return 'hr'
      case 'DY':
        return 'days'
      default:
        return ''
    }
  }
  return text ? `${Number(text).toFixed(2)} ${formatRateUnit()}` : text
}

const BehaviorReport = ({ selectedStudentId, studentName }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const client = useApolloClient()
  const [view, setView] = useState('date')
  const [selectedBehaviorFilter, setSelectedBehaviorFilter] = useState()
  const [activeTab, setActiveTab] = useState('FR')

  const [frElement, setFrElement] = useState(null)
  const [drElement, setDrElement] = useState(null)
  const [lrElement, setLrElement] = useState(null)
  const [piElement, setPiElement] = useState(null)
  const [wiElement, setWiElement] = useState(null)
  const [mtElement, setMtElement] = useState(null)

  const handleViewChange = e => {
    setView(e.target.value)
  }

  const modifyBehaviorRecords = records => {
    return records.map(record => {
      switch (record.type) {
        case RECORDING_TYPES.SBT: {
          const node = {
            id: Math.random(),
            ...record,
          }
          return node
        }
        case RECORDING_TYPES.BR: {
          const node = {
            id: Math.random(),
            ...record,
            type: RECORDING_TYPES.FR,
            [RECORDING_TYPES.FR]: {
              ...record,
            },
          }
          return node
        }
        case RECORDING_TYPES.FR: {
          const node = Object.create(baseRecord)
          node.init(record)
          node.type = RECORDING_TYPES.FR
          node[RECORDING_TYPES.FR] = {
            ...record,
          }
          return node
        }
        case RECORDING_TYPES.DR: {
          const node = Object.create(baseRecord)
          node.init(record)
          node.type = RECORDING_TYPES.DR
          node[RECORDING_TYPES.DR] = {
            ...record,
          }
          return node
        }
        case RECORDING_TYPES.LR: {
          const node = Object.create(baseRecord)
          node.init(record)
          node.type = RECORDING_TYPES.LR
          node[RECORDING_TYPES.LR] = {
            ...record,
          }
          return node
        }
        case RECORDING_TYPES.PR: {
          return {
            id: Math.random(),
            type: RECORDING_TYPES.PR,
            [RECORDING_TYPES.PR]: {
              ...record,
              count: getCount(record.intervalChecks),
            },
          }
        }
        case RECORDING_TYPES.WR: {
          return {
            id: Math.random(),
            type: RECORDING_TYPES.WR,
            [RECORDING_TYPES.WR]: {
              ...record,
              count: getCount(record.intervalChecks),
            },
          }
        }
        case RECORDING_TYPES.MR: {
          return {
            id: Math.random(),
            type: RECORDING_TYPES.MR,
            [RECORDING_TYPES.MR]: {
              ...record,
              count: getCount(record.intervalChecks),
            },
          }
        }
        default:
          return { id: Math.random() }
      }
    })
  }

  const accumulate = node => {
    if (!node.children) {
      if (node.type === RECORDING_TYPES.FR) {
        accumulateFR.call(node)
      } else if (node.type === RECORDING_TYPES.DR) {
        accumulateDR.call(node)
      } else if (node.type === RECORDING_TYPES.LR) {
        accumulateLR.call(node)
      } else if (node.type === RECORDING_TYPES.SBT) {
        accumulateSBT.call(node)
      } else {
        accumulateInterval.call(node, node.type)
      }
      return
    }
    node.children.forEach(childNode => {
      accumulate(childNode)
    })
    accumulateAll(node)
  }

  const sessionDataList = useMemo(() => {
    if (state.sessionsData) {
      const sessionsData = state.sessionsData
      const childSessions = sessionsData.map(({ node }) => node)
      const sbtRecordings = childSessions.filter(node => node.isBehReduction)
      const behRecordings = childSessions.filter(node => node.isBehRecording)
      const sbtData = []
      sbtRecordings.forEach(node => {
        let r1 = ''
        let r2 = ''
        let r1Id = ''
        let r2Id = ''
        let r1Duration = 0
        let r2Duration = 0
        let r1Count = 0
        let r2Count = 0
        node.behReduction.edges.forEach(({ node: childNode }) => {
          if (childNode.r1) {
            r1Id = childNode.r1.id
            r1 = childNode.r1.behaviorName
            r1Duration += childNode.duration
            r1Count += childNode.r1Count
          }
          if (childNode.r2) {
            r2Id = childNode.r2.id
            r2 = childNode.r2.behaviorName
            r2Duration += childNode.duration
            r2Count += childNode.r2Count
          }
        })
        const dataNode1 = {
          date: node.ChildSession.sessionDate,
          name: node.targets.targetAllcatedDetails.targetName,
          key: Math.random(),
          behavior: {
            id: r1Id,
            behaviorName: r1,
          },
          type: RECORDING_TYPES.SBT,
          [RECORDING_TYPES.SBT]: {
            duration: r1Duration / 1000,
            count: r1Count,
          },
        }
        const dataNode2 = {
          date: node.ChildSession.sessionDate,
          name: node.targets.targetAllcatedDetails.targetName,
          key: Math.random(),
          behavior: {
            id: r2Id,
            behaviorName: r2,
          },
          type: RECORDING_TYPES.SBT,
          [RECORDING_TYPES.SBT]: {
            duration: r2Duration / 1000,
            count: r2Count,
          },
        }
        sbtData.push(dataNode1)
        sbtData.push(dataNode2)
      })
      const behRecordingsData = []
      behRecordings.forEach(node => {
        behRecordingsData.push({
          date: node.ChildSession.sessionDate,
          key: Math.random(),
          name: node.targets.targetAllcatedDetails.targetName,
          duration: node.totalBehRecordingDuration / 1000,
          frequency: node.behaviourRecording.edges.length,
          type: RECORDING_TYPES.BR,
          behavior: {
            id: 'BehaviorRecording',
            behaviorName: 'BehaviorRecording',
          },
        })
      })
      return behRecordingsData.concat(sbtData)
    }

    return []
  }, [state.sessionsData])

  const { behaviorList, loading, selectedBehavior, startDate, endDate, componentsKey } = state

  const dataList = useMemo(() => {
    const data = []
    if (state.behaviorTemplate) {
      state.behaviorTemplate.forEach(({ node }) => {
        node.frequencyratebehaviorrecordSet.edges.forEach(({ node: childNode }) => {
          data.push({
            ...childNode,
            type: RECORDING_TYPES.FR,
            behavior: node.behavior,
          })
        })
        node.durationbehaviorrecordSet.edges.forEach(({ node: childNode }) => {
          data.push({
            ...childNode,
            type: RECORDING_TYPES.DR,
            behavior: node.behavior,
          })
        })
        node.latencybehaviorrecordSet.edges.forEach(({ node: childNode }) => {
          data.push({
            ...childNode,
            type: RECORDING_TYPES.LR,
            behavior: node.behavior,
          })
        })
        node.partialintervalbehaviorrecordSet.edges.forEach(({ node: childNode }) => {
          data.push({
            ...childNode,
            type: RECORDING_TYPES.PR,
            behavior: node.behavior,
          })
        })
        node.wholeintervalbehaviorrecordSet.edges.forEach(({ node: childNode }) => {
          data.push({
            ...childNode,
            type: RECORDING_TYPES.WR,
            behavior: node.behavior,
          })
        })
        node.momentarytimebehaviorrecordSet.edges.forEach(({ node: childNode }) => {
          data.push({
            ...childNode,
            type: RECORDING_TYPES.MR,
            behavior: node.behavior,
          })
        })
      })
    }
    return data.concat(sessionDataList)
  }, [state.behaviorTemplate, sessionDataList])

  const filteredDataList = useMemo(() => {
    return dataList.filter(node => {
      const momentDate = moment(node.date)
      if (momentDate.isSameOrAfter(startDate) && momentDate.isSameOrBefore(endDate)) {
        if (!selectedBehaviorFilter || node.behavior.id === selectedBehaviorFilter) {
          return true
        }
        return false
      }
      return false
    })
  }, [dataList, startDate, endDate, selectedBehaviorFilter])

  const dateView = useMemo(() => {
    const groupedByDate = groupBy(filteredDataList, 'date')
    const tempTableData = []
    Object.keys(groupedByDate).forEach(key => {
      const tempObj = {
        name: key,
        parent: null,
        id: Math.random(),
      }
      const nodes = groupedByDate[key]
      const groupedByBehavior = groupBy(nodes, 'behavior.id')
      tempObj.children = Object.keys(groupedByBehavior).map(childKey => {
        const groupedByRecordingType = groupBy(groupedByBehavior[childKey], 'type')
        const children = Object.keys(groupedByRecordingType).map(grandKey => {
          const modifiedChildren = modifyBehaviorRecords(groupedByRecordingType[grandKey])
          const temp = {
            name: grandKey,
            id: Math.random(),
            children: modifiedChildren,
          }
          modifiedChildren.forEach(val => {
            val.parent = temp
          })
          return temp
        })
        const temp = {
          name: groupedByBehavior[childKey].at(0)?.behavior.behaviorName,
          id: Math.random(),
          children,
        }
        temp.children.forEach(child => {
          child.parent = temp
        })
        return temp
      })
      tempTableData.push(tempObj)

      tempObj.children.forEach(child => {
        child.parent = tempObj
      })
    })
    tempTableData.forEach(node => accumulate(node))
    tempTableData.sort((a, b) => {
      const momentA = moment(a.name)
      const momentB = moment(b.name)
      if (momentA.isBefore(momentB)) {
        return -1
      }
      if (momentA.isSame(momentB)) {
        return 0
      }
      return 1
    })
    return tempTableData
  }, [state.behaviorTemplate, filteredDataList, sessionDataList])

  const behaviorView = useMemo(() => {
    const groupedByDate = groupBy(filteredDataList, 'behavior.id')

    const tempTableData = []
    Object.keys(groupedByDate).forEach(key => {
      const tempObj = {
        name: groupedByDate[key][0]?.behavior.behaviorName,
        parent: null,
        id: Math.random(),
      }
      const nodes = groupedByDate[key]
      const groupedByBehavior = groupBy(nodes, 'date')
      tempObj.children = Object.keys(groupedByBehavior).map(childKey => {
        const groupedByRecordingType = groupBy(groupedByBehavior[childKey], 'type')
        const children = Object.keys(groupedByRecordingType).map(grandKey => {
          const modifiedChildren = modifyBehaviorRecords(groupedByRecordingType[grandKey])
          const temp = {
            name: grandKey,
            id: Math.random(),
            children: modifiedChildren,
          }
          modifiedChildren.forEach(val => {
            val.parent = temp
          })
          return temp
        })
        const temp = {
          name: childKey,
          id: Math.random(),
          children,
        }
        temp.children.forEach(child => {
          child.parent = temp
        })
        return temp
      })
      tempTableData.push(tempObj)

      tempObj.children.forEach(child => {
        child.parent = tempObj
      })
      tempObj.children.sort((a, b) => {
        const momentA = moment(a.name)
        const momentB = moment(b.name)
        if (momentA.isBefore(momentB)) {
          return -1
        }
        if (momentA.isSame(momentB)) {
          return 0
        }
        return 1
      })
    })
    tempTableData.forEach(node => accumulate(node))

    return tempTableData
  }, [state.behaviorTemplate, filteredDataList])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 300,
      render: (text, record, index) => {
        if (!text) {
          return `${index + 1}. Recording`
        }
        return text
      },
    },
    {
      title: 'Frequency & Rate',
      align: 'center',
      width: 210,
      children: [
        {
          title: 'Duration',
          dataIndex: `${RECORDING_TYPES.FR}.duration`,
          align: 'center',
          render: renderDurationInSeconds,
        },
        { title: 'Frequency', dataIndex: `${RECORDING_TYPES.FR}.frequency`, align: 'center' },
        // {
        //   title: 'Rate',
        //   dataIndex: `${RECORDING_TYPES.FR}.rate`,
        //   align: 'center',
        //   render: renderRate,
        // },
      ],
    },
    {
      title: 'Duration',
      align: 'center',
      dataIndex: `${RECORDING_TYPES.DR}.duration`,
      render: renderDurationInSeconds,
      width: 100,
    },
    {
      title: 'Latency',
      align: 'center',
      dataIndex: `${RECORDING_TYPES.LR}.latency`,
      render: renderDurationInSeconds,
      width: 100,
    },
    {
      title: 'Partial Interval',
      align: 'center',
      width: 550,
      children: [
        {
          title: 'Total observation time',
          dataIndex: `${RECORDING_TYPES.PR}.totalObservationTime`,
          align: 'center',
          render: renderDurationInSeconds,
        },
        {
          title: 'Remaining Time',
          dataIndex: `${RECORDING_TYPES.PR}.observedTime`,
          align: 'center',
          render: renderDurationInSeconds,
        },
        {
          title: 'Interval length',
          dataIndex: `${RECORDING_TYPES.PR}.intervalLength`,
          render: renderDurationInSeconds,
          align: 'center',
        },
        {
          title: 'Intervals',
          dataIndex: `${RECORDING_TYPES.PR}.intervals`,
          align: 'center',
        },
        { title: 'Frequency', dataIndex: `${RECORDING_TYPES.PR}.frequency`, align: 'center' },
        { title: 'Count', dataIndex: `${RECORDING_TYPES.PR}.count`, align: 'center' },
      ],
    },
    {
      title: 'Whole Interval',
      align: 'center',
      width: 550,
      children: [
        {
          title: 'Total observation time',
          dataIndex: `${RECORDING_TYPES.WR}.totalObservationTime`,
          align: 'center',
          render: renderDurationInSeconds,
        },
        {
          title: 'Remaining Time',
          dataIndex: `${RECORDING_TYPES.WR}.observedTime`,
          align: 'center',
          render: renderDurationInSeconds,
        },
        {
          title: 'Interval length',
          dataIndex: `${RECORDING_TYPES.WR}.intervalLength`,
          render: renderDurationInSeconds,
          align: 'center',
        },
        {
          title: 'Intervals',
          dataIndex: `${RECORDING_TYPES.WR}.intervals`,
          align: 'center',
        },
        { title: 'Frequency', dataIndex: `${RECORDING_TYPES.WR}.frequency`, align: 'center' },
        { title: 'Count', dataIndex: `${RECORDING_TYPES.WR}.count`, align: 'center' },
      ],
    },
    {
      title: 'Momentary Time Sample',
      align: 'center',
      width: 550,
      children: [
        {
          title: 'Total observation time',
          dataIndex: `${RECORDING_TYPES.MR}.totalObservationTime`,
          align: 'center',
          render: renderDurationInSeconds,
        },
        {
          title: 'Remaining Time',
          dataIndex: `${RECORDING_TYPES.MR}.observedTime`,
          align: 'center',
          render: renderDurationInSeconds,
        },
        {
          title: 'Interval length',
          dataIndex: `${RECORDING_TYPES.MR}.intervalLength`,
          render: renderDurationInSeconds,
          align: 'center',
        },
        {
          title: 'Intervals',
          dataIndex: `${RECORDING_TYPES.MR}.intervals`,
          align: 'center',
        },
        { title: 'Frequency', dataIndex: `${RECORDING_TYPES.MR}.frequency`, align: 'center' },
        { title: 'Count', dataIndex: `${RECORDING_TYPES.MR}.count`, align: 'center' },
      ],
    },
    {
      title: 'SBT',
      align: 'center',
      width: 200,
      children: [
        {
          title: 'Duration',
          align: 'center',
          dataIndex: `${RECORDING_TYPES.SBT}.duration`,
          render: renderDurationInSeconds,
        },
        {
          title: 'Count',
          align: 'center',
          dataIndex: `${RECORDING_TYPES.SBT}.count`,
        },
      ],
    },
  ]

  const totalColumnsWidth = useMemo(() => {
    let width = 0
    columns.forEach(col => {
      if (col.width) {
        width += col.width
      }
    })
    return width
  }, [columns])

  function accessProperty(pathStr) {
    const selectors = pathStr.split('.')
    let val = this
    selectors.forEach(selector => {
      if (val) {
        val = val[selector]
      }
    })
    return val || ''
  }

  const createTableData = (headers, sheetsData) => {
    const [header1, header2] = headers
    const result = []
    const flattenTableRows = (row, level, index) => {
      const excelRow = []
      const padding = new Array(Number(level || 0) * 4).join(' ')
      for (let i = 0; i < header1.length; i += 1) {
        const val1 = header1[i]
        const val2 = header2[i]
        if (val1[0] && val1[1].dataIndex) {
          const text = accessProperty.call(row, val1[1].dataIndex)
          const renderedText = val1[1].render ? val1[1].render(text, row, index) : text
          excelRow.push(`${i === 0 ? padding : ''}${renderedText}`)
        }
        if (val2[0] && val2[1].dataIndex) {
          const text = accessProperty.call(row, val2[1].dataIndex)
          const renderedText = val2[1].render ? val2[1].render(text, row, index) : text
          excelRow.push(`${i === 0 ? padding : ''}${renderedText}`)
        }
      }
      result.push(excelRow)
      if (row.children) {
        row.children.forEach((childRow, ind) => {
          flattenTableRows(childRow, level + 1, ind)
        })
      }
    }
    const selectedView = view === 'date' ? dateView : behaviorView
    selectedView.forEach(dataRow => {
      flattenTableRows(dataRow, 0)
    })
    const ws = XLSX.utils.aoa_to_sheet([header1, header2].concat(result))

    const wb = { Sheets: {}, SheetNames: [] }
    sheetsData.forEach(val => {
      wb.Sheets[val.sheetName] = XLSX.utils.aoa_to_sheet(val.data)
      wb.SheetNames.push(val.sheetName)
    })

    wb.Sheets.Aggregate = ws
    wb.SheetNames.push('Aggregate')

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const blobData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(blobData, `${studentName}-behaviour-report${fileExtension}`)
  }

  const handleCsvExportFR = rowData => {
    const header = ['Date', 'Behavior', 'Start time', 'End Time', 'Duration', 'Frequency', 'Rate']
    const rows = []
    rowData.forEach(([behaviorName, data]) => {
      data.forEach(({ node }) => {
        rows.push([
          node.date,
          behaviorName,
          node.startTime,
          node.endTime,
          renderDurationInSeconds(node.duration),
          node.frequency,
          renderRate(node.rate, node.rateUnit),
        ])
      })
    })
    if (rows.length === 0) {
      return null
    }
    return {
      sheetName: RECORDING_TYPES.FR,
      data: [header, ...rows],
    }
  }

  const handleCsvExportDR = rowData => {
    const header = ['Date', 'Behavior', 'Start time', 'End Time', 'Duration']
    const rows = []
    rowData.forEach(([behaviorName, data]) => {
      data.forEach(({ node }) => {
        rows.push([
          node.date,
          behaviorName,
          node.startTime,
          node.endTime,
          renderDurationInSeconds(node.duration),
        ])
      })
    })
    if (rows.length === 0) {
      return null
    }
    return {
      sheetName: RECORDING_TYPES.DR,
      data: [header, ...rows],
    }
  }

  const handleCsvExportLR = rowData => {
    const header = ['Date', 'Behavior', 'Start time', 'End Time', 'Latency']
    const rows = []
    rowData.forEach(([behaviorName, data]) => {
      data.forEach(({ node }) => {
        rows.push([
          node.date,
          behaviorName,
          node.startTime,
          node.endTime,
          renderDurationInSeconds(node.latency),
        ])
      })
    })
    if (rows.length === 0) {
      return null
    }
    return {
      sheetName: RECORDING_TYPES.LR,
      data: [header, ...rows],
    }
  }

  const handleCsvExportPR = rowData => {
    const header = [
      'Date',
      'Behavior',
      'Total observation time',
      'Interval Length',
      'Intervals',
      'Frequency',
    ]
    const rows = []
    rowData.forEach(([behaviorName, data]) => {
      data.forEach(({ node }) => {
        rows.push([
          node.date,
          behaviorName,
          renderDurationInSeconds(node.totalObservationTime),
          renderDurationInSeconds(node.intervalLength),
          node.intervalLength,
          node.intervals,
          node.frequency,
        ])
      })
    })

    return {
      sheetName: RECORDING_TYPES.PR,
      data: [header, ...rows],
    }
  }

  const getExcelData = () => {
    const data = []
    const { behaviorTemplate } = state
    const FRData = behaviorTemplate.map(({ node }) => [
      node.behavior.behaviorName,
      flatten(node.frequencyratebehaviorrecordSet.edges),
    ])
    const DRData = behaviorTemplate.map(({ node }) => [
      node.behavior.behaviorName,
      flatten(node.durationbehaviorrecordSet.edges),
    ])
    const LRData = behaviorTemplate.map(({ node }) => [
      node.behavior.behaviorName,
      flatten(node.latencybehaviorrecordSet.edges),
    ])
    const PRData = behaviorTemplate.map(({ node }) => [
      node.behavior.behaviorName,
      flatten(node.partialintervalbehaviorrecordSet.edges),
    ])
    const WRData = behaviorTemplate.map(({ node }) => [
      node.behavior.behaviorName,
      flatten(node.wholeintervalbehaviorrecordSet.edges),
    ])
    const MRData = behaviorTemplate.map(({ node }) => [
      node.behavior.behaviorName,
      flatten(node.momentarytimebehaviorrecordSet.edges),
    ])
    data.push(handleCsvExportFR(FRData))
    data.push(handleCsvExportDR(DRData))
    data.push(handleCsvExportLR(LRData))
    data.push({ ...handleCsvExportPR(PRData), sheetName: RECORDING_TYPES.PR })
    data.push({ ...handleCsvExportPR(WRData), sheetName: RECORDING_TYPES.WR })
    data.push({ ...handleCsvExportPR(MRData), sheetName: RECORDING_TYPES.MR })
    return data.filter(val => !!val)
  }

  const exportToCSV = () => {
    const header1 = []
    const header2 = []
    columns.forEach(col => {
      header1.push([col.title, col])
      if (col.children) {
        col.children.forEach((childCol, ind) => {
          header2.push([childCol.title, childCol])
          if (ind !== 0) {
            header1.push(['', null])
          }
        })
      } else {
        header2.push(['', null])
      }
    })
    createTableData([header1, header2], getExcelData())
  }

  useEffect(() => {
    if (selectedStudentId) {
      client
        .query({
          query: GET_BEHAVIOR_TEMPLATES,
          variables: {
            student: localStorage.getItem('studentId'),
          },
        })
        .then(result => {
          dispatch({
            type: ACTIONS.SET_INITIAL_STATE,
            payload: { behaviorTemplatesEdges: result.data.getBehaviorTemplates.edges },
          })
        })
        .catch(error => {
          console.log('Error', error)
        })
    }
  }, [selectedStudentId, client])

  useEffect(() => {
    if (selectedStudentId) {
      const session = 'U2Vzc2lvbk5hbWVUeXBlOjE='
      client
        .query({
          query: SESSION_DATA,
          variables: {
            session,
            targets_StudentId: JSON.parse(localStorage.getItem('studentId')),
            date_Gte: moment(state.startDate).format('YYYY-MM-DD'),
            date_Lte: moment(state.endDate).format('YYYY-MM-DD'),
          },
        })
        .then(result => {
          dispatch({
            type: ACTIONS.SET_SESSION_DATA,
            payload: { sessionsData: result.data.getSessionDataRecording.edges },
          })
        })
        .catch(err => {
          console.log('got error ', err)
        })
    }
  }, [selectedStudentId, state.startDate, state.endDate, client])

  useEffect(() => {
    if (state.pdfExporting) {
      generatePDF()
    }
  }, [state.pdfExporting, generatePDF])

  const loadGraph = id => {
    const behavior = filter(behaviorList, st => {
      return st.id === id
    })

    if (behavior && behavior[0])
      dispatch({
        type: ACTIONS.SELECT_BEHAVIOR,
        payload: {
          selectedBehavior: id,
          componentsKey: Math.random(),
          behaviorName: behavior[0].behaviorName,
        },
      })
  }

  const dateChange = dateRange => {
    dispatch({
      type: ACTIONS.CHANGE_DATE_RANGE,
      payload: { startDate: dateRange[0], endDate: dateRange[1], componentsKey: Math.random() },
    })
  }

  const generatePDF = useCallback(async () => {
    const imgObj = {}
    if (frElement) {
      imgObj.imageFRData = await html2canvas(frElement).then(canvas => canvas.toDataURL('image'))
    }
    if (drElement) {
      imgObj.imageDRData = await html2canvas(drElement).then(canvas => canvas.toDataURL('image'))
    }
    if (lrElement) {
      imgObj.imageLRData = await html2canvas(lrElement).then(canvas => canvas.toDataURL('image'))
    }
    if (piElement) {
      imgObj.imagePIData = await html2canvas(piElement).then(canvas => canvas.toDataURL('image'))
    }
    if (wiElement) {
      imgObj.imageWIData = await html2canvas(wiElement).then(canvas => canvas.toDataURL('image'))
    }
    if (mtElement) {
      imgObj.imageMTData = await html2canvas(mtElement).then(canvas => canvas.toDataURL('image'))
    }
    dispatch({ type: ACTIONS.SET_IMAGE, payload: imgObj })
    dispatch({ type: ACTIONS.OPEN_PDF_DRAWER })
  }, [drElement, frElement, lrElement, mtElement, piElement, wiElement])

  console.log('date view', dateView)

  return (
    <div className="behaviorReport">
      <div style={filterCardStyle}>
        <div style={parentDiv}>
          <Tooltip trigger={['hover']} title="Select Date range">
            <RangeDatePicker
              date={[startDate, endDate]}
              setDate={dateChange}
              from="BEHAVIOR_REPORT"
            />
          </Tooltip>
        </div>
        {activeTab !== 'AG' && (
          <div style={parentDiv}>
            <Tooltip title="Select Behavior">
              <Select
                placeholder="Select Behavior"
                loading={loading}
                showSearch
                allowClear
                optionFilterProp="children"
                value={selectedBehavior}
                onChange={loadGraph}
                style={{ width: '240px' }}
              >
                {behaviorList?.map((behavior, index) => {
                  return (
                    <Option key={`${behavior.id} ${index}`} value={behavior.id}>
                      {behavior.behaviorName}
                    </Option>
                  )
                })}
              </Select>
            </Tooltip>
          </div>
        )}
        {activeTab === 'AG' && (
          <div style={parentDiv}>
            <Tooltip title="Select Behavior">
              <Select
                placeholder="Select Behavior"
                loading={loading}
                showSearch
                allowClear
                optionFilterProp="children"
                value={selectedBehaviorFilter}
                onChange={setSelectedBehaviorFilter}
                style={{ width: '240px' }}
              >
                {behaviorList?.map((behavior, index) => {
                  return (
                    <Option key={`${behavior.id} ${index}`} value={behavior.id}>
                      {behavior.behaviorName}
                    </Option>
                  )
                })}
              </Select>
            </Tooltip>
          </div>
        )}
        <div style={{ marginLeft: 'auto' }}>
          <Button
            disabled={state.pdfExporting}
            onClick={() => dispatch({ type: ACTIONS.START_PDF_EXPORT })}
            style={{ backgroundColor: COLORS.submitBlue, color: 'white', marginRight: '1rem' }}
          >
            {!state.pdfExporting && <FilePdfOutlined />}
            {state.pdfExporting && <LoadingOutlined />} Export to PDF
          </Button>
          <Button
            onClick={exportToCSV}
            style={{ backgroundColor: COLORS.submitBlue, color: 'white' }}
          >
            <FileExcelOutlined />
            Export to Excel
          </Button>
        </div>
        <Drawer
          title={`${studentName}'s Behavior report`}
          placement="right"
          closable="true"
          onClose={() => {
            dispatch({ type: ACTIONS.CLOSE_PDF_DRAWER })
          }}
          visible={state.pdfDrawer}
          width={DRAWER.widthL2}
        >
          <BehaviorPDF
            title="Behavior"
            start={startDate}
            end={endDate}
            studentId={selectedStudentId}
            image={state.image}
            behavior={state.behaviorName}
          />
        </Drawer>
      </div>
      <Tabs
        onChange={setActiveTab}
        type="card"
        className="behaviorData"
        defaultActiveKey="FR"
        activeKey={activeTab}
        style={{ marginTop: 16 }}
      >
        <Tabs.TabPane tab="Frequency and Rate" key="FR">
          {selectedStudentId && (
            <BehaviorFRChart
              keyname={componentsKey}
              startDate={startDate}
              endDate={endDate}
              selectedBehavior={selectedBehavior}
              selectedStudentId={selectedStudentId}
              setStateOfParent={setFrElement}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Duration" key="DR">
          {selectedStudentId && (
            <BehaviorDRChart
              keyname={componentsKey}
              startDate={startDate}
              endDate={endDate}
              selectedBehavior={selectedBehavior}
              selectedStudentId={selectedStudentId}
              setStateOfParent={setDrElement}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Latency" key="LT">
          {selectedStudentId && (
            <BehaviorLRChart
              keyname={componentsKey}
              startDate={startDate}
              endDate={endDate}
              selectedBehavior={selectedBehavior}
              selectedStudentId={selectedStudentId}
              setStateOfParent={setLrElement}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Partial Interval" key="PI">
          {selectedStudentId && (
            <BehaviorPIChart
              keyname={componentsKey}
              startDate={startDate}
              endDate={endDate}
              selectedBehavior={selectedBehavior}
              selectedStudentId={selectedStudentId}
              setStateOfParent={setPiElement}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Whole Interval" key="WI">
          {selectedStudentId && (
            <BehaviorWIChart
              keyname={componentsKey}
              startDate={startDate}
              endDate={endDate}
              selectedBehavior={selectedBehavior}
              selectedStudentId={selectedStudentId}
              setStateOfParent={setWiElement}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Momentary Time sample" key="MT">
          {selectedStudentId && (
            <BehaviorMTChart
              keyname={componentsKey}
              startDate={startDate}
              endDate={endDate}
              selectedBehavior={selectedBehavior}
              selectedStudentId={selectedStudentId}
              setStateOfParent={setMtElement}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Aggregate" key="AG">
          <>
            <Radio.Group
              style={{ margin: '1rem', marginLeft: 'auto' }}
              value={view}
              onChange={handleViewChange}
            >
              <Radio.Button value="date">Date view</Radio.Button>
              <Radio.Button value="behavior">Behavior view</Radio.Button>
            </Radio.Group>
            <Table
              className="behavior__aggregate__table"
              bordered
              dataSource={view === 'date' ? dateView : behaviorView}
              columns={columns}
              rowKey="id"
              scroll={{ x: totalColumnsWidth }}
            />
          </>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default BehaviorReport
