import { Button, Col, Form, Icon, Tag, notification, Row, Select, Switch, TimePicker } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { useSelector } from 'react-redux'
import LoadingComponent from '../../staffProfile/LoadingComponent'
import { ALL_THERAPIST, UPDATE_SHIFT } from './queries'

const TherapistShifting = ({ form }) => {
  const [isForAllTherapist, setIsForAllTherapist] = useState(false)
  const [startTime, setStartTime] = useState(moment())
  const [endTime, setEndTime] = useState(moment().add(360, 'minutes'))

  const reduxUser = useSelector(state => state.user)

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const { data: allTherapist, loading: allTherapistLoading, error: therapistErrors } = useQuery(
    ALL_THERAPIST,
    {
      variables: {
        isActive: true,
      },
    },
  )
  const [updateShift, { loading: isUpdateShiftingLoading }] = useMutation(UPDATE_SHIFT)

  const handleSubmit = e => {
    e.preventDefault()

    const allTherapistIds = []
    if (isForAllTherapist) {
      allTherapist.staffs.edges.map(({ node }) => allTherapistIds.push(node.id))
    }
    form.validateFields((err, values) => {
      if (!err) {
        updateShift({
          variables: {
            therapistIds: isForAllTherapist ? allTherapistIds : values.therapists,
            startTime: values.startTime.format('hh:mm A'),
            endTime: values.endTime.format('hh:mm A'),
            workingDays: values.workingDays,
          },
        })
          .then(response => {
            notification.success({
              message: 'Therapist shift time',
              description: 'Shift details updated successfully.',
            })
          })
          .catch(error => {
            console.error(error)
            notification.error({
              message: 'Therapist shift time',
              description: 'Something went wrong',
            })
          })
      }
    })
  }

  if (allTherapistLoading) return <LoadingComponent />
  if (therapistErrors) return <h3>An error occurred to load Therapists.</h3>

  return (
    <>
      <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
        <Col sm={24} md={24} lg={18}>
          <Form
            style={{ width: '100%' }}
            onSubmit={handleSubmit}
            className="therapistShiftingTab"
            size="small"
          >
            <Row>
              <Col>
                <Form.Item
                  label="For all Therapist"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Switch
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    checked={isForAllTherapist}
                    onChange={setIsForAllTherapist}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  label="Therapist"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="form-label"
                >
                  {form.getFieldDecorator('therapists', {
                    rules: [
                      {
                        // initialValue:,
                        required: !isForAllTherapist,
                        message: 'Please select atleast one Therapist',
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select Therapist"
                      loading={allTherapistLoading}
                      showSearch
                      optionFilterProp="name"
                      disabled={isForAllTherapist}
                      mode="tags"
                    >
                      {allTherapist &&
                        allTherapist.staffs.edges.map(({ node }) => (
                          <Select.Option key={node.id} name={node.name}>
                            {node.name} {node.surname}
                          </Select.Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Working Days"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="form-label"
                >
                  {form.getFieldDecorator('workingDays', {
                    rules: [{ required: true, message: 'Please select atleast one day' }],
                  })(
                    <Select
                      placeholder="Select Working Days"
                      showSearch
                      optionFilterProp="displayText"
                      mode="tags"
                    >
                      {days.map(day => (
                        <Select.Option key={day} name={day}>
                          {day}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  label="Start Time"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="form-label"
                >
                  {form.getFieldDecorator('startTime', {
                    initialValue: startTime,
                    rules: [{ required: true, message: 'Please select start Time' }],
                  })(
                    <TimePicker
                      style={{ width: '100%' }}
                      placeholder="Start Time"
                      format="HH:mm"
                      minuteStep={30}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="End Time"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="form-label"
                >
                  {form.getFieldDecorator('endTime', {
                    initialValue: endTime,
                    rules: [{ required: true, message: 'Please select end Time' }],
                  })(
                    <TimePicker
                      style={{ width: '100%' }}
                      placeholder="End Time"
                      format="HH:mm"
                      minuteStep={30}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                type="primary"
                style={{ backgroundColor: '#0B35B3' }}
                htmlType="submit"
                loading={isUpdateShiftingLoading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default Form.create()(TherapistShifting)
