/* eslint-disable */
import { Line, ResponsiveLine } from '@nivo/line'
import { Button, DatePicker, Drawer, notification, Switch, Tooltip, Empty } from 'antd'
import { RightOutlined, LeftOutlined, TableOutlined } from '@ant-design/icons'
import { DRAWER } from 'assets/styles/globalStyles'
import LoadingComponent from 'components/LoadingComponent'
import html2canvas from 'html2canvas'
import { COLORS } from '../../assets/styles/globalStyles'
import _, { result } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaDownload } from 'react-icons/fa'
import DailyResponseRatePdf from './DailyResponseRatePdf'
import EditableSessionTable from './editableSessionTable'
import PhaseTable from './PhaseTable'
import RangeDatePicker from 'components/DatePicker/DatePicker'
import HeaderComponent from 'components/HeaderComponentNew'
import RangeDatePickerForGraph from 'components/DatePicker/DatePickerForGraph'

const { RangePicker } = DatePicker

const parentDiv = { display: 'flex', margin: '5px 30px 5px 0' }

const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }
const dateFormat = 'YYYY-MM-DD'

const color = {
  master: '#008000', // green
  baseline: '#FF0000', // red
  therapy: '#FFA500', // orange
  inmaintainence: '#DC143C', // dark red
}
const status = {
  master: 'Master',
  baseline: 'Baseline',
  therapy: 'Therapy',
  inmaintainence: 'In-Maintainence',
}
const position = {
  mastered: 'left',
  baseline: 'right',
  therapy: 'bottom-left',
  'in maintenance': 'top-left',
  intervention: 'bottom-right',
  generalization: 'top-right',
}

const posMastery = ['top-right', 'top-left', 'bottom-left', 'bottom-right']

function ResponseRateGraph({
  graphData,
  dateRange,
  setDateRange,
  defaultRange,
  setDefaulRange,
  selectedTarget,
  loading,
  marker,
  story,
  therapist,
  closeDrawer,
  getNextTarget,
  getPrevTarget,
  nextDisable,
  prevDisable,
  from,
  mastery,
  sdPeak,
}) {
  let i = 0
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { defaultDateRange, defaultDateDRR } = user
  const [peakSd, setPeakSd] = useState(null)
  const [date, setDate] = useState(moment())
  const [showSessionTableDrawer, setShowSessionTableDrawer] = useState(false)
  const [allocatedDate, setAllocatedDate] = useState(null)
  const [lineChartImage, setLineChartImage] = useState('')
  const [isResponsePdf, setIsResponsePdf] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [noData, setNoData] = useState(false)
  const [phaseLine, setPhaseLine] = useState(true)
  const [phaseData, setPhaseData] = useState([])
  const [markers, setMarker] = useState([])
  const [temp, setTemp] = useState([])
  const [therapistData, setTherapistData] = useState([])
  const [isTherapist, setIsTherapist] = useState(false)
  const [clickedPoint, setClickedPoint] = useState(null)
  const [hiddenIds, setHiddenIds] = useState([])
  const [storyHiddenIds, setStoryHiddenIds] = useState([])
  const [therapistHiddenIds, setTherapistHiddenIds] = useState([])
  const [therapistHiddenData, setTherapistHiddenData] = useState([])
  const [storyHiddenData, setStoryHiddenData] = useState([])
  const [storyMark, setStoryMarker] = useState([])
  const [masteryMarker, setMasteryMarker] = useState([])
  const [clickedPointMarker, setClickedPointMarker] = useState([])

  const handlePointClick = pt => {
    if (pt && pt.data) {
      const clickedDate = moment(pt.data.date).format(dateFormat)
      setClickedPoint(clickedDate)
      if (clickedDate >= allocatedDate && clickedDate <= moment().format(dateFormat)) {
        setDate(moment(pt.data.date))
        setPeakSd(pt.data.type === 'sd' ? pt.data.target : null)
        setShowSessionTableDrawer(pt.data.type === 'target with children' ? false : true)
        if (pt.data.type === 'target with children') {
          notification.info({
            message: `Please Update Steps or Stimulus!`,
          })
        }
      } else {
        notification.info({
          message: `No session found on ${clickedDate}!`,
          description:
            'Date shuold be not greater then today or not less then the target valid from date!! ',
        })
      }
    }
  }

  useEffect(() => {
    if (selectedTarget && selectedTarget.date) {
      if (moment(selectedTarget.date)) {
        setAllocatedDate(moment(selectedTarget.date).format('YYYY-MM-DD'))
      }
    }
  }, [selectedTarget])

  useEffect(() => {
    if (marker) {
      setMarker(marker)
    }
  }, [marker])

  useEffect(() => {
    if (mastery) {
      const temp = []
      const status = mastery?.criteriaType?.statusName
      mastery.criteriaphasetargetsSet.edges.map(({ node }) => {
        const {
          calculationType,
          percentage,
          calFrameType,
          calFrameDurationType,
          calFrameDurationValue,
          phaseChangeLabel,
          minimumTrial,
        } = node
        const obj = {
          axis: 'y',
          value: percentage,
          lineStyle: { stroke: COLORS.danger, strokeWidth: 3 },
          legend: `Response(%)${calculationType} ${percentage}% over ${calFrameDurationValue} ${calFrameType} ${calFrameDurationType},${status} to ${phaseChangeLabel},Min. Trial:${minimumTrial}`,
          legendPosition: posMastery[Math.floor(Math.random() * posMastery.length)],
          textStyle: { fill: COLORS.danger, fontSize: 12, fontWeight: 'bold' },
        }
        temp.push(obj)
      })
      setMasteryMarker(temp)
    }
  }, [mastery])

  useEffect(() => {
    setPhaseData([])

    if (graphData !== null && graphData.length > 0) {
      let count = 0
      const phase = []
      const clicked = []

      graphData.forEach((item, index) => {
        let renderGraph = false
        item.length > 0 &&
          item.forEach(it => {
            const { data } = it
            data.forEach(da => {
              if (Number(da.y) !== null) {
                renderGraph = true
              }
            })
          })

        if (renderGraph === false) {
          count += 1
        }

        if (item.length > 0 && markers) {
          const temp = []
          markers.dateStatus &&
            markers.dateStatus.edges.length &&
            markers.dateStatus.edges.map(({ node }) => {
              if (
                item.length > 0 &&
                moment(node.date).format('MMM') === item[0].month &&
                moment(node.date).format('YYYY') === item[0].year
              ) {
                const pos = _.lowerCase(node.targetStatusId?.statusName)
                const obj = {
                  axis: 'x',
                  value: moment(node.date).format('DD'),
                  lineStyle: { stroke: COLORS[node.targetStatusId?.colorCode], strokeWidth: 2 },
                  legend: node.targetStatusId?.statusName,
                  legendPosition: position[pos],
                  legendOrientation: 'vertical',

                  textStyle: {
                    fill: COLORS[node.targetStatusId?.colorCode],
                    fontSize: 16,
                    fontWeight: 'bold',
                  },
                }
                temp.push(obj)
                // setPhaseData(old=>[...old,obj])
              }
            })
          _.forEach(markers, (value, key) => {
            if (value !== undefined) {
              if (key === 'phaseSet' && phaseLine && value.length !== 0) {
                value.map(p => {
                  if (
                    item.length > 0 &&
                    moment(p.node.date).format('MMM') === item[0].month &&
                    moment(p.node.date).format('YYYY') === item[0].year
                  ) {
                    const obj = {
                      axis: 'x',
                      value: moment(p.node.date).format('DD'),
                      lineStyle: { stroke: '#000000', strokeWidth: 2 },
                      legend: p.node.content,
                      legendPosition: 'top',
                      textStyle: { fill: 'black', fontSize: 16, fontWeight: 'bold' },
                    }
                    temp.push(obj)
                    // setPhaseData(old=>[...old,obj])
                  }
                  return p
                })
              }
              const y = item.length > 0 && item[0].year
              const d = {}
              d[y] = temp
              phase[index] = {
                month: item[0].month,
                year: item[0].year,
                data: temp,
              }
            }
          })
        }
        if (
          item.length > 0 &&
          clickedPoint &&
          moment(clickedPoint).format('MMM') === item[0].month &&
          moment(clickedPoint).format('YYYY') === item[0].year
        ) {
          const obj = {
            axis: 'x',
            value: moment(clickedPoint).format('DD'),
            lineStyle: {
              stroke: COLORS.lightGreen,
              strokeWidth: 6,
              strokeDasharray: '2,4',
              fill: 'none',
            },
            legend: `Clicked`,
            legendOrientation: 'vertical',
            legendPosition: 'top-right',
            textStyle: { fill: 'green', fontSize: 12, fontWeight: 'bold' },
          }

          // setPhaseData(old=>[...old,obj])

          clicked[index] = {
            month: item[0].month,
            year: item[0].year,
            data: [obj],
          }
        }
      })
      setPhaseData(phase)
      setClickedPointMarker(clicked)
      if (count === graphData.length && !isChecked) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }
  }, [JSON.stringify(graphData), markers, marker, phaseLine, isChecked, clickedPoint])
  let r = 0

  useEffect(() => {
    if (story) {
      setTemp([])
      let t = []
      let uniqDate = []
      let sortedt = []
      let d = []
      let sorted = []

      story.map((item, index) => {
        item.data.map(i => {
          if (i.y !== null) {
            d.push(i)
            t.push(i.date)
          }
        })
        sorted = d.sort(
          (a, b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'),
        )
        sortedt = t.sort((a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'))
        uniqDate = _.uniq(sortedt)
      })

      story.map((item, index) => {
        const result = uniqDate.map((date, index) => {
          const temp = item.data.filter(i => {
            return i.date === date
          })
          return temp.length
            ? temp[0]
            : {
                x: date,
                y: null,
                date,
                key: date,
                target: i.target,
                plotZero: i.plotZero,
                record: i.record,
              }
        })
        setTemp(prev => [
          ...prev,
          {
            id: item.id,
            key: item.key,
            data: result,
          },
        ])
      })
      if (markers) {
        const temp = []
        const selectedRangeStart = moment(dateRange[0]).format('YYYY-MM-DD')
        const selectedRangeEnd = moment(dateRange[1]).format('YYYY-MM-DD')
        markers.dateStatus &&
          markers.dateStatus.edges.length &&
          markers.dateStatus.edges.map(({ node }) => {
            const pos = _.lowerCase(node.targetStatusId?.statusName)
            const obj = {
              axis: 'x',
              value: moment(node.date).format('YYYY-MM-DD'),
              lineStyle: { stroke: COLORS[node.targetStatusId?.colorCode], strokeWidth: 2 },
              legend: node.targetStatusId?.statusName,
              legendPosition: position[pos],
              legendOrientation: 'vertical',
              textStyle: {
                fill: COLORS[node.targetStatusId?.colorCode],
                fontSize: 16,
                fontWeight: 'bold',
              },
            }

            // if (uniqDate.includes(node.date)) {
            //   temp.push(obj)
            // }
            if (moment(node.date).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')) {
              temp.push(obj)
            }
          })
        _.forEach(markers, (value, key) => {
          if (value !== undefined) {
            if (key === 'phaseSet' && phaseLine && value.length !== 0) {
              value.map(p => {
                const obj = {
                  axis: 'x',
                  value: moment(p.node.date).format('YYYY-MM-DD'),
                  lineStyle: { stroke: '#000000', strokeWidth: 2 },
                  legend: p.node.content,
                  legendPosition: 'top',
                  textStyle: { fill: 'black', fontSize: 16, fontWeight: 'bold' },
                }
                if (
                  moment(p.node.date).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')
                ) {
                  temp.push(obj)
                }
                // if (t.includes(moment(p.node.date).format('YYYY-MM-DD'))) {
                //   temp.push(obj)
                // }
                // setPhaseData(old=>[...old,obj])
                return p
              })
            }
            // if (
            //   value &&
            //   (key === 'inmaintainence' ||
            //     key === 'baseline' ||
            //     key === 'therapy' ||
            //     key === 'master')
            // ) {
            //   const obj = {
            //     axis: 'x',
            //     value: moment(value).format('YYYY-MM-DD'),
            //     lineStyle: { stroke: color[key], strokeWidth: 2 },
            //     legend: status[key],
            //     legendPosition: position[key],
            //   }

            //   // setPhaseData(old=>[...old,obj])
            //   if (t.includes(moment(value).format('YYYY-MM-DD'))) {
            //     temp.push(obj)
            //   }
            // }
          }
        })
        setStoryMarker(temp)
      }
    }
  }, [
    dateRange,
    JSON.stringify(graphData),
    JSON.stringify(story),
    phaseLine,
    isChecked,
    clickedPoint,
  ])

  const removeDuplicateArrayValues = arr => {
    return [...new Set(arr)]
  }

  useEffect(() => {
    if (therapist) {
      setTherapistData([])
      const phase = []
      let t = []
      let dates = []
      therapist.map(ite => {
        ite.data.map(i => {
          if (i.y) {
            // d.push(i)
            // t.push(i.date)
            dates.push(i.date)
          }
        })
      })

      dates = removeDuplicateArrayValues(dates)
      const sortedDates = dates.sort(
        (a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'),
      )
      therapist.map((item, index) => {
        let d = []

        sortedDates.map(j => {
          d.push({
            x: j,
            y: null,
            key: j,
            date: j,
          })
        })
        item.data.map(it => {
          const idx = d.findIndex(i => i.date === it.date)
          if (idx !== -1) {
            d[idx] = it
          }
        })
        const sorted = d.sort(
          (a, b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'),
        )
        item.id !== undefined &&
          setTherapistData(prev => [
            ...prev,
            {
              id: item.id,
              key: item.key,
              data: sorted,
            },
          ])
      })
    }
  }, [dateRange, JSON.stringify(therapist), phaseLine, isTherapist])

  useEffect(() => {
    if (therapistData) {
      const hiddenFilter = therapistData.map(x => {
        if (!therapistHiddenIds.includes(x.id)) {
          return x
        } else {
          return {
            ...x,
            data: [],
          }
        }
      })
      // Step 1: Identify unique dates from the data
      const uniqueDates = [
        ...new Set(
          hiddenFilter.flatMap(obj =>
            obj.data
              .filter(dataPoint => dataPoint.x && dataPoint.y !== null)
              .map(dataPoint => dataPoint.x),
          ),
        ),
      ]

      // Step 2 and 3: Add missing data points with y set to null
      const modifiedChartData = hiddenFilter.map(obj => ({
        ...obj,
        data: uniqueDates.map(date => {
          const existingDataPoint = obj.data.find(dataPoint => dataPoint.x === date)
          if (existingDataPoint) {
            return existingDataPoint
          } else {
            return { x: date, y: null }
          }
        }),
      }))
      setTherapistHiddenData(modifiedChartData)
    }
  }, [therapistData, therapistHiddenIds])

  useEffect(() => {
    if (temp) {
      const hiddenFilter = temp.map(x => {
        if (!storyHiddenIds.includes(x.id)) {
          return x
        } else {
          return {
            ...x,
            data: [],
          }
        }
      })

      // Step 1: Identify unique dates from the data
      const uniqueDates = [
        ...new Set(
          hiddenFilter.flatMap(obj =>
            obj.data
              .filter(dataPoint => dataPoint.x && dataPoint.y !== null)
              .map(dataPoint => dataPoint.x),
          ),
        ),
      ]

      // Step 2 and 3: Add missing data points with y set to null
      const modifiedChartData = hiddenFilter.map(obj => ({
        ...obj,
        data: uniqueDates.map(date => {
          const existingDataPoint = obj.data.find(dataPoint => dataPoint.x === date)
          if (existingDataPoint) {
            return existingDataPoint
          } else {
            return { x: date, y: null }
          }
        }),
      }))

      setStoryHiddenData(modifiedChartData)
    }
  }, [temp, storyHiddenIds])

  useEffect(() => {
    if (storyHiddenData && storyHiddenData.length && masteryMarker && storyMark) {
      const selectedRangeStart = moment(dateRange[0]).format('YYYY-MM-DD')
      const selectedRangeEnd = moment(dateRange[1]).format('YYYY-MM-DD')
      const tempNew = storyHiddenData[0].data

      console.log('storyHiddenData', storyHiddenData, storyMark)

      const data = tempNew
      storyMark.forEach(marker => {
        const markerValue = marker.value

        // Check if the marker's value exists in the data array
        const existingData = data.find(item => item.x === markerValue)

        // If the marker's value is not present in the data array, add a new data object
        if (!existingData) {
          const newMarker = {
            x: markerValue,
            y: null, // Set the desired value for y
            key: markerValue,
            date: markerValue,
            target: '',
            plotZero: false,
            record: false,
            total: null, // Set the desired value for total
            correct: null, // Set the desired value for correct
            incorrect: null, // Set the desired value for incorrect
            prompt: null, // Set the desired value for prompt
            type: 'marker',
            targetId: null, // Set the desired value for targetId
          }

          if (moment(markerValue).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')) {
            data.push(newMarker)
          }
        }
      })
      data.sort((a, b) => {
        const dateA = new Date(a.x)
        const dateB = new Date(b.x)

        return dateA - dateB
      })
    }
  }, [storyHiddenData, masteryMarker, storyMark])

  useEffect(() => {
    if (therapistHiddenData && therapistHiddenData.length && masteryMarker && storyMark) {
      const selectedRangeStart = moment(dateRange[0]).format('YYYY-MM-DD')
      const selectedRangeEnd = moment(dateRange[1]).format('YYYY-MM-DD')
      const tempNew = therapistHiddenData[0].data

      const data = tempNew
      storyMark.forEach(marker => {
        const markerValue = marker.value

        // Check if the marker's value exists in the data array
        const existingData = data.find(item => item.x === markerValue)

        // If the marker's value is not present in the data array, add a new data object
        if (!existingData) {
          const newMarker = {
            x: markerValue,
            y: null, // Set the desired value for y
            key: markerValue,
            date: markerValue,
            target: '',
            plotZero: false,
            record: false,
            total: null, // Set the desired value for total
            correct: null, // Set the desired value for correct
            incorrect: null, // Set the desired value for incorrect
            prompt: null, // Set the desired value for prompt
            type: 'marker',
            targetId: null, // Set the desired value for targetId
          }

          if (moment(markerValue).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')) {
            data.push(newMarker)
          }
        }
      })
      data.sort((a, b) => {
        const dateA = new Date(a.x)
        const dateB = new Date(b.x)

        return dateA - dateB
      })
    }
  }, [therapistHiddenData, masteryMarker, storyMark])

  useEffect(() => {
    setHiddenIds([])
    setTherapistHiddenIds([])
    setStoryHiddenIds([])
  }, [isChecked, isTherapist])

  const getLineChartImage = () => {
    const item = isChecked
      ? document.getElementById('lineGraph')
      : document.getElementById('storyGraph')
    if (item) {
      const promises = []
      const ff = html2canvas(item).then(canvas => canvas.toDataURL('image/png'))
      ff.then(res => {
        setLineChartImage(res)
        setIsResponsePdf(true)
      })
    }
  }

  const handleLineChartPdf = () => {
    getLineChartImage()
  }

  const hidePdfDrawer = () => {
    setIsResponsePdf(false)
    setLineChartImage('')
  }

  const disabledDate = current => {
    if (allocatedDate) {
      if (current.format(dateFormat) < moment(allocatedDate).format(dateFormat)) {
        return true
      }
    }
    return false
  }

  if (loading) {
    return <LoadingComponent />
  }

  const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
    <g>
      <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
      {/* <circle
        r={size / 5}
        strokeWidth={borderWidth}
        stroke={borderColor}
        fill={color}
        fillOpacity={0.35}
      /> */}
    </g>
  )

  const colorsArray = [
    '#774dd7',
    '#028ee6',
    '#00CED1',
    '#2E5894',
    '#3F00FF',
    '#6495ED',
    '#1560bd',
    '#00308F',
    '#48D1CC',
  ]

  const commonProperties = {
    height: 550,
    margin: { top: 40, right: 300, bottom: 200, left: 60 },
    animate: true,

    legends: [
      {
        anchor: 'right',
        direction: 'column',
        justify: false,
        translateY: 0,
        translateX: 25,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 10,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 10,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        onClick: data => {
          toggle(data)
        },
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ],

    axisLeft: {
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Percentage (%)',
      legendOffset: -40,
      legendPosition: 'middle',
    },
    pointLabelYOffset: -12,
    enableGridX: false,
    useMesh: true,
    enableSlices: 'x',
  }

  const handleSwitchChange = checked => {
    setIsChecked(checked)
    setIsTherapist(false)
  }
  const handlePhaseLine = checked => {
    setPhaseLine(checked)
  }
  const handleTherapist = checked => {
    setIsTherapist(checked)
    setIsChecked(false)
  }
  const handleData = data => {
    const temp = { ...markers, phaseSet: data }
    setMarker(temp)
  }

  const toggle = d => {
    if (isChecked) {
      if (hiddenIds.includes(d.id)) {
        const f = hiddenIds.filter(id => id !== d.id)
        setHiddenIds(f)
      } else {
        setHiddenIds([...hiddenIds, d.id])
      }
    } else if (isTherapist) {
      if (therapistHiddenIds.includes(d.id)) {
        const f = therapistHiddenIds.filter(id => id !== d.id)
        setTherapistHiddenIds(f)
      } else {
        setTherapistHiddenIds([...therapistHiddenIds, d.id])
      }
    } else {
      if (storyHiddenIds.includes(d.id)) {
        const f = storyHiddenIds.filter(id => id !== d.id)
        setStoryHiddenIds(f)
      } else {
        setStoryHiddenIds([...storyHiddenIds, d.id])
      }
    }
  }
  return (
    <div>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ ...parentDiv }}>
          <span style={parentLabel}>Date:</span>
          <RangeDatePickerForGraph
            date={dateRange}
            disabledDate={disabledDate}
            disableRedux
            rangeType={defaultDateRange}
            setDate={v => {
              setDateRange(v)
            }}
            from="DRR_GRAPH"
          />

          <span
            style={{ ...parentLabel, color: isChecked ? '#1890FF' : '#BDBDBD', fontWeight: '600' }}
          >
            Edit:
          </span>
          <Switch style={{ marginTop: 5 }} checked={isChecked} onChange={handleSwitchChange} />

          <>
            <span
              style={{
                ...parentLabel,
                color: phaseLine ? '#1890FF' : '#BDBDBD',
                fontWeight: '600',
              }}
            >
              ManualPhaseLines:
            </span>
            <Switch style={{ marginTop: 5 }} checked={phaseLine} onChange={handlePhaseLine} />
          </>

          {selectedTarget?.peakType !== 'EQUIVALENCE' && (
            <>
              <span
                style={{
                  ...parentLabel,
                  color: isTherapist ? '#1890FF' : '#BDBDBD',
                  fontWeight: '600',
                }}
              >
                Split By Therapist:
              </span>
              <Switch style={{ marginTop: 5 }} checked={isTherapist} onChange={handleTherapist} />
            </>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Previous Target">
            <Button disabled={prevDisable} onClick={() => getPrevTarget(selectedTarget)}>
              <LeftOutlined /> Prev
            </Button>
          </Tooltip>
          <Tooltip title="Next Target">
            <Button disabled={nextDisable} onClick={() => getNextTarget(selectedTarget)}>
              Next <RightOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Download Graph">
            <Button onClick={handleLineChartPdf} type="link">
              <FaDownload fontSize="22px" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <span
        style={{
          ...parentLabel,
          color: '#000',
          fontWeight: '600',
          margin: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: 'white',
          padding: 10,
        }}
      >
        Target Valid From : {selectedTarget && moment(selectedTarget?.date).format('YYYY-MM-DD')}
      </span>
      {noData ? (
        <div style={{ textAlign: 'center', color: 'black', marginTop: 20 }}>
          No data found please change the date range
        </div>
      ) : null}
      {graphData === null && <Empty />}
      <div id="storyGraph">
        {!isChecked && !isTherapist && story !== null && (
          <div
            key={Math.random()}
            style={{
              height: 550,
              marginBottom: 30,
              width: '100%',
              paddingBottom: 10,
              backgroundColor: 'white',
            }}
          >
            <div style={{ width: '100%', height: '100%' }}>
              <ResponsiveLine
                {...commonProperties}
                key={Math.random()}
                curve="monotoneX"
                data={storyHiddenData}
                // xScale={{
                //     type: 'point',
                //     format: '%Y-%m-%d',
                //     useUTC: false,
                //     precision: 'day',
                // }}
                // xFormat="time:%Y-%m-%d"
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 100,
                  stacked: false,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                  // format: '%b %d',
                  // tickValues: 'every 3 days',
                }}
                colors={{ scheme: 'category10' }}
                lineWidth={3}
                pointSize={10}
                // pointSymbol={CustomSymbol}
                pointBorderWidth={3}
                pointBorderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.3]],
                }}
                enableGridY={true}
                xScale={{ type: 'point' }}
                enableGridX={false}
                useMesh={true}
                enableSlices={'x'}
                markers={[...storyMark, ...masteryMarker]}
                sliceTooltip={({ slice }) => {
                  return (
                    <div
                      style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {slice.points.map(point => (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div
                            key={point.id}
                            style={{
                              // color: point.serieColor,
                              padding: '3px 0',
                              display: 'flex',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: point.serieColor,
                                width: 12,
                                height: 12,
                                marginTop: 5,
                                marginRight: 5,
                                borderRadius: 100,
                              }}
                            ></div>
                            <strong>
                              {point.serieId}: {point.data.y}%, Total Trials: {point.data.total}
                            </strong>
                          </div>
                          <p style={{ fontSize: 12 }}>
                            ( InCorrect:{point.data.incorrect}, Prompt:{point.data.prompt}, Correct:
                            {point.data.correct} )
                          </p>
                        </div>
                      ))}
                    </div>
                  )
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div id="therapistGraph">
        {isTherapist && !isChecked && therapist !== null && therapistData.length !== 0 && (
          <div
            key={Math.random()}
            style={{
              height: 550,
              marginBottom: 30,
              width: '100%',
              paddingBottom: 10,
              backgroundColor: 'white',
            }}
          >
            <div style={{ width: '100%', height: '100%' }}>
              <ResponsiveLine
                {...commonProperties}
                key={Math.random()}
                curve="monotoneX"
                data={therapistHiddenData}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 100,
                  stacked: false,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                  // format: '%b %d',
                  // tickValues: 'every 3 days',
                }}
                colors={{ scheme: 'category10' }}
                lineWidth={3}
                pointSize={10}
                // pointSymbol={CustomSymbol}
                pointBorderWidth={3}
                pointBorderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.3]],
                }}
                enableGridY={true}
                xScale={{ type: 'point' }}
                enableGridX={false}
                useMesh={true}
                enableSlices={'x'}
                markers={[...storyMark, ...masteryMarker]}
                sliceTooltip={({ slice }) => {
                  return (
                    <div
                      style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {slice.points.map(point => (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div
                            key={point.id}
                            style={{
                              // color: point.serieColor,
                              padding: '3px 0',
                              display: 'flex',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: point.serieColor,
                                width: 12,
                                height: 12,
                                marginTop: 5,
                                marginRight: 5,
                                borderRadius: 100,
                              }}
                            ></div>
                            <strong>
                              {point.serieId}: {point.data.y}%, Total Trials: {point.data.total}
                            </strong>
                          </div>
                          <p style={{ fontSize: 12 }}>
                            ( InCorrect:{point.data.incorrect}, Prompt:{point.data.prompt}, Correct:
                            {point.data.correct} )
                          </p>
                        </div>
                      ))}
                    </div>
                  )
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div id="lineGraph">
        {graphData &&
          graphData.map((item, index) => {
            const tempItem = []
            let renderGraph = false
            const dataNormal = []

            item.forEach(it => {
              const { a, data, id, key, month, year } = it
              const tempData = []

              data.forEach(da => {
                if (da.y !== null) {
                  renderGraph = true
                  tempData.push(da)
                  // if (da.plotZero) {
                  //   tempData.push(da)
                  // } else if (!da.plotZero && da.record && da.record === true) {
                  //   tempData.push(da)
                  // }
                  // else if (da.y > 0) {
                  //   tempData.push(da)
                  // }
                }
              })
              const obj = data.map(i => {
                if (i.y === null) {
                  return {
                    ...i,
                    y: '0.00',
                  }
                } else {
                  return i
                }
              })
              dataNormal.push({
                ...it,
                data: obj,
              })

              tempItem.push({
                a,
                data: tempData,
                id,
                key,
                month,
                year,
              })
            })

            let mark = []
            let clicked = []
            if (
              item.length > 0 &&
              phaseData[index]?.month === item[0]?.month &&
              phaseData[index]?.year === item[0]?.year
            ) {
              mark = phaseData[index]?.data
            }
            if (
              item.length > 0 &&
              clickedPointMarker[index]?.month === item[0]?.month &&
              clickedPointMarker[index]?.year === item[0]?.year
            ) {
              clicked = clickedPointMarker[index]?.data
            }
            i += 1
            const hiddenFilter = dataNormal.map(x => {
              if (!hiddenIds.includes(x.id)) {
                return x
              } else {
                return {
                  ...x,
                  data: [],
                }
              }
            })
            return (
              isChecked && (
                <div
                  key={Math.random()}
                  style={{
                    height: 550,
                    marginBottom: 30,
                    width: '100%',
                    paddingBottom: 10,
                    backgroundColor: 'white',
                  }}
                >
                  <div style={{ width: '100%', height: '100%' }}>
                    <ResponsiveLine
                      data={hiddenFilter}
                      key={Math.random()}
                      margin={{ top: 40, right: 300, bottom: 200, left: 60 }}
                      xScale={{ type: 'point' }}
                      yScale={{
                        type: 'linear',
                        min: 0,
                        max: 100,
                        stacked: false,
                        reverse: false,
                      }}
                      yFormat=" >-.2f"
                      axisTop={null}
                      axisRight={null}
                      onClick={point => {
                        handlePointClick(point)
                      }}
                      curve="linear"
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend:
                          item.length > 0 ? `Date (${item[0].month} ${item[0].year})` : 'Date',
                        legendOffset: 36,
                        legendPosition: 'middle',
                      }}
                      axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Percentage (%)',
                        legendOffset: -40,
                        legendPosition: 'middle',
                      }}
                      colors={{ scheme: 'category10' }}
                      // enableSlices='x'
                      // colors={graphData[0].length > 1 ? { scheme: 'category10' } : 'black'}
                      lineWidth={3}
                      pointSize={10}
                      // pointSymbol={CustomSymbol}
                      pointBorderWidth={3}
                      pointBorderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.3]],
                      }}
                      pointLabelYOffset={-12}
                      areaBaselineValue={40}
                      useMesh={true}
                      enableGridX={false}
                      markers={[...mark, ...masteryMarker, ...clicked]}
                      legends={[
                        {
                          anchor: 'right',
                          direction: 'column',
                          justify: false,
                          translateY: 0,
                          translateX: 25,
                          itemsSpacing: 0,
                          itemDirection: 'left-to-right',
                          itemWidth: 10,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 10,
                          symbolShape: 'circle',
                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                          onClick: data => {
                            toggle(data)
                          },
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemTextColor: '#000',
                                itemBackground: '#eee',
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                </div>
              )
            )
          })}
      </div>

      <Drawer
        title={selectedTarget?.targetAllcatedDetails?.targetName}
        placement="right"
        width={DRAWER.widthL2}
        closable
        destroyOnClose
        visible={showSessionTableDrawer}
        onClose={() => {
          if (closeDrawer) {
            closeDrawer()
          }
          setShowSessionTableDrawer(false)
        }}
      >
        <HeaderComponent title={''} showHomePath={false} learnerCard changeLearner={false} />
        <EditableSessionTable date={date} peakSd={peakSd} selectedTarget={selectedTarget} />
        <PhaseTable
          date={date}
          phaseData={phaseData}
          setPhaseData={data => handleData(data)}
          selectedTarget={selectedTarget}
        />
      </Drawer>

      <Drawer
        title={`${graphData &&
          (graphData.length > 0 ? graphData[0].length > 0 && graphData[0][0].id : '')} Pdf`}
        placement="right"
        width={DRAWER.widthL2}
        closable
        onClose={hidePdfDrawer}
        visible={isResponsePdf}
      >
        {lineChartImage && (
          <DailyResponseRatePdf
            dateRange={dateRange}
            peakType={selectedTarget?.peakType}
            type={selectedTarget?.targetAllcatedDetails.targetType?.typeTar}
            targetStatus={selectedTarget?.targetStatus?.statusName}
            lineChartImage={lineChartImage}
            subTitle={sdPeak}
            target={selectedTarget}
          />
        )}
      </Drawer>
    </div>
  )
}

export default ResponseRateGraph
