import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Icon, Popover, Table, Tooltip } from 'antd'
import { includes, reduce } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const BehaviourRecording = ({ behaviourRecording }) => {
  const columns = [
    { title: 'Name', align: 'center', dataIndex: 'name' },
    {
      title: 'Duration',
      align: 'center',
      dataIndex: 'duration',
      render: text => convertToHourMinSecondsStr(text * 1000),
    },
    { title: 'Frequency', align: 'center', dataIndex: 'frequency' },
  ]

  return (
    <Popover
      placement="topLeft"
      title="Behavior Recordings"
      content={
        <Table
          rowKey="id"
          size="small"
          pagination={false}
          columns={columns}
          bordered
          dataSource={behaviourRecording}
        />
      }
    >
      <InfoCircleOutlined style={{ fontSize: '16px', marginInline: '1rem' }} />
    </Popover>
  )
}

const convertToHourMinSecondsStr = milliseconds => {
  const duration = moment.duration(milliseconds)
  const seconds = duration.seconds()
  const minutes = duration.minutes()
  const hours = duration.hours()
  let durationStr = ''
  if (hours > 0) {
    durationStr += `${hours}hr`
  }
  if (minutes > 0) {
    durationStr += `${durationStr.length > 0 ? ' ' : ''}${minutes}min`
  }
  if (seconds >= 0) {
    durationStr += `${durationStr.length > 0 ? ' ' : ''}${seconds}sec`
  }
  return durationStr
}

export const CELL_FORMATS = {
  COUNT: 'count',
  PERCENTAGE: 'percentage',
  COUNT_BY_TOTAL: 'countByTotal',
  BOTH: 'both',
}

const useSessionTableColumns = (
  selectedTargetTypes,
  cellFormat,
  checkedTableColumns,
  onPdfClick,
) => {
  const [width, setWidth] = useState(2420)
  const [visible, setVisible] = useState(null)

  const [columnOptions, setColumnOptions] = useState([])

  useEffect(() => {
    setColumnOptions(columns.slice(1).map(val => val.title))
  }, [selectedTargetTypes, columns])

  const renderCell = useCallback(
    propertyName => (text, row) => {
      // console.log("propertyName",propertyName);
      // console.log("text",text);
      // console.log("row",row);
      if (!text) {
        return text
      }
      const node = propertyName ? row[propertyName] : row
      // console.log("node",node);
      if (!node) {
        return text
      }
      if (cellFormat === CELL_FORMATS.COUNT) {
        return text
      }
      if (cellFormat === CELL_FORMATS.PERCENTAGE) {
        return `${Math.round((Number(text) / Number(node.total)) * 100)}%`
      }
      if (cellFormat === CELL_FORMATS.COUNT_BY_TOTAL) {
        return `${Number(text)} / ${Number(node.total)}`
      }
      return `${text} - ${Math.round((Number(text) / Number(node.total)) * 100)}%`
    },
    [cellFormat],
  )

  const columns = useMemo(() => {
    const tempColumns = [
      {
        title: 'Name - duration',
        dataIndex: 'name',
        excelTitle: 'Name',
        width: 400,
        fixed: 'left',
        key: 'name',
        render: (text, record) => {
          return (
            <Popover
              overlayStyle={{ width: 450 }}
              placement="right"
              content={record.targetNote ? record.targetNote : 'No Target Note found.'}
              title="Target Note"
              visible={visible && visible === record.key}
              trigger="click"
              defaultVisible={false}
              onMouseEnter={() => {
                if (record.type === 'target') {
                  setVisible(record.key)
                }
              }}
              onMouseLeave={() => setVisible(null)}
            >
              <div
                style={{
                  display: 'inline-block',
                  maxWidth: '250px',
                  paddingLeft: '1rem',
                  verticalAlign: 'middle',
                  color: record.children && record.children.length ? 'black' : 'red',
                }}
              >
                {/* {console.log('record__', record)} */}
                {record.name}
                {record.showDuration ? (
                  <b>{` - ${convertToHourMinSecondsStr(record.duration)}`}</b>
                ) : null}
              </div>
            </Popover>
          )
        },
      },
      {
        title: 'Coded',
        width: 320,
        align: 'center',
        key: 'peak',
        children: [
          {
            title: 'Correct',
            dataIndex: 'peak.correct',
            align: 'center',
            render: renderCell('peak'),
          },
          {
            title: 'Incorrect',
            dataIndex: 'peak.incorrect',
            align: 'center',
            render: renderCell('peak'),
          },
          {
            title: 'Prompt',
            dataIndex: 'peak.prompt',
            align: 'center',
            render: renderCell('peak'),
          },
          {
            title: 'Trials',
            dataIndex: 'peak.trial',
            align: 'center',
          },
        ],
      },
      {
        title: 'Relational',
        key: 'relational',
        width: 320,
        children: [
          {
            title: 'Correct',
            dataIndex: 'relational.correct',
            align: 'center',
            render: renderCell('relational'),
          },
          {
            title: 'Incorrect',
            dataIndex: 'relational.incorrect',
            align: 'center',
            render: renderCell('relational'),
          },
          {
            title: 'Prompt',
            dataIndex: 'relational.prompt',
            align: 'center',
            render: renderCell('relational'),
          },
          {
            title: 'Trials',
            dataIndex: 'relational.trial',
            align: 'center',
          },
        ],
      },
      {
        title: 'Skill based treatment',
        width: 480,
        key: 'sbt',
        children: [
          {
            title: 'Correct',
            dataIndex: 'sbt.correct',
            align: 'center',
            render: renderCell('sbt'),
          },
          {
            title: 'Incorrect',
            dataIndex: 'sbt.incorrect',
            align: 'center',
            render: renderCell('sbt'),
          },
          {
            title: 'Prompt',
            dataIndex: 'sbt.prompt',
            align: 'center',
            render: renderCell('sbt'),
          },
          {
            title: 'r1',
            dataIndex: 'r1',
            align: 'center',
            render: (text, row) =>
              row.r1
                ? `${text} - ${row.r1Count} ${convertToHourMinSecondsStr(row.r1Duration)}`
                : '',
          },
          {
            title: 'r2',
            dataIndex: 'r2',
            align: 'center',
            render: (text, row) =>
              row.r2
                ? `${text} - ${row.r2Count} ${convertToHourMinSecondsStr(row.r2Duration)}`
                : '',
          },
          {
            title: 'Trials',
            dataIndex: 'sbt.trial',
            align: 'center',
          },
        ],
      },
      {
        title: 'Regular',
        width: 320,
        key: 'reg',
        children: [
          {
            title: 'Correct',
            dataIndex: 'regular.correct',
            align: 'center',
            render: renderCell('regular'),
          },
          {
            title: 'Incorrect',
            dataIndex: 'regular.incorrect',
            align: 'center',
            render: renderCell('regular'),
          },
          {
            title: 'Prompt',
            dataIndex: 'regular.prompt',
            align: 'center',
            render: renderCell('regular'),
          },
          {
            title: 'Trials',
            dataIndex: 'regular.trial',
            align: 'center',
          },
        ],
      },
      {
        title: 'Total',
        width: 320,
        key: 'total',
        children: [
          {
            title: 'Correct',
            dataIndex: 'correct',
            align: 'center',
            render: renderCell(''),
          },
          {
            title: 'Incorrect',
            dataIndex: 'incorrect',
            align: 'center',
            render: renderCell(''),
          },
          {
            title: 'Prompt',
            dataIndex: 'prompt',
            align: 'center',
            render: renderCell(''),
          },
          {
            title: 'Trials',
            dataIndex: 'trial',
            align: 'center',
          },
        ],
      },
      {
        title: 'Behavior Recording',
        width: 160,
        key: 'behRecord',
        children: [
          {
            title: 'Duration',
            dataIndex: 'behDuration',
            align: 'center',
            render: text => convertToHourMinSecondsStr(Number(text)),
          },
          {
            title: 'Freq',
            dataIndex: 'behFrequency',
            align: 'center',
            render: (text, row) => (row.behDuration ? text : ''),
          },
        ],
      },
      {
        title: 'Mand Data',
        width: 160,
        key: 'mandData',
        children: [
          {
            title: 'P',
            dataIndex: 'mandData.pData',
            align: 'center',
            render: renderCell('mandData'),
          },
          {
            title: 'I',
            dataIndex: 'mandData.iData',
            align: 'center',
            render: renderCell('mandData'),
          },
          {
            title: 'Mand Count',
            dataIndex: 'mandData.mandCount',
            align: 'center',
            render: renderCell('mandData'),
          },
        ],
      },
      {
        title: 'Session report',
        width: 100,
        key: 'sessionReport',
        showOnExcel: false,
        align: 'center',
        render: (text, row) =>
          row.type === 'session' && (
            <span>
              <Button type="link" onClick={() => onPdfClick(row.sessionId)}>
                <Icon type="file-pdf" />
                PDF
              </Button>
            </span>
          ),
      },
    ]
    if (!selectedTargetTypes || selectedTargetTypes.length === 0) {
      return tempColumns
    }

    const discreteTargetTypes = ['peak', 'skill based treatment', 'behavior recording']
    console.log('Session report data', selectedTargetTypes)
    const normalizedSelectedTargetTypes = []
    selectedTargetTypes.forEach(val => {
      if (includes(discreteTargetTypes, val.toLowerCase())) {
        if (!includes(normalizedSelectedTargetTypes, val.toLowerCase())) {
          normalizedSelectedTargetTypes.push(val.toLowerCase())
        }
      } else if (!includes(normalizedSelectedTargetTypes, 'regular')) {
        normalizedSelectedTargetTypes.push('regular')
      }
    })

    const filteredColumns = tempColumns.filter(val => {
      if (val.title === 'Coded' || val.title === 'Relational') {
        return includes(normalizedSelectedTargetTypes, 'peak')
      }
      if (val.title === 'Skill based treatment') {
        return includes(normalizedSelectedTargetTypes, 'skill based treatment')
      }
      if (val.title === 'Regular') {
        return includes(normalizedSelectedTargetTypes, 'regular')
      }
      if (val.title === 'Behavior Recording') {
        return includes(normalizedSelectedTargetTypes, 'behavior recording')
      }
      return true
    })
    setWidth((filteredColumns.length - 2) * 320 + 400 + 320)
    return filteredColumns
  }, [renderCell, selectedTargetTypes, onPdfClick, visible])

  console.log('session', columns, cellFormat)

  const checkedCols = useMemo(() => {
    const tempCols = columns.filter((val, index) => {
      if (index === 0) {
        return true
      }
      return includes(checkedTableColumns, val.title)
    })
    const w = reduce(tempCols, (sum, val) => sum + val.width, 0)
    setWidth(w)
    return tempCols
  }, [columns, checkedTableColumns])

  return { columns: checkedCols, width, columnOptions }
}

export default useSessionTableColumns
