/* eslint-disable no-plusplus */
/* eslint-disable object-shorthand */
/* eslint-disable array-callback-return */
import { all, takeEvery, put, call, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  getClinicStaffs,
  getStaffDropdown,
  createStaff,
  updateStaff,
  staffActiveInactive,
  getStaffProfile,
} from 'services/staffs'
import actions from './actions'

export function* GET_DATA({ payload }) {
  const itemPerPage = 40
  yield put({
    type: 'staffs/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(getClinicStaffs, (payload = { ...payload }))

  if (response) {
    const staffs = formatStaffData(response)
    const pageInfo = response?.data?.staffs?.pageInfo
    yield put({
      type: 'staffs/SET_STATE',
      payload: {
        StaffList: staffs,
        SelectedStaffId: staffs.length > 0 ? staffs[0].id : null,
        StaffsFetched: true,
        loading: false,
        PageInfo: pageInfo,
      },
    })
    if (staffs.length > 0) {
      yield put({
        type: 'staffs/GET_STAFF_PROFILE',
        payload: {
          id: staffs[0].id,
        },
      })
    }

    // if (pageInfo?.hasNextPage) {
    //   let allStaffs = [...staffs]
    //   while (pageInfo.hasNextPage) {
    //     const newResponse = yield call(getClinicStaffs, {
    //       ...payload,
    //       first: itemPerPage,
    //       after: pageInfo.endCursor,
    //     })
    //     const newStaffs = formatStaffData(newResponse)
    //     allStaffs = [...allStaffs, ...newStaffs]
    //     pageInfo = newResponse?.data?.staffs?.pageInfo
    //     yield put({
    //       type: 'staffs/SET_STATE',
    //       payload: {
    //         StaffList: allStaffs,
    //         StaffsFetched: true,
    //         loading: false,
    //       },
    //     })
    //   }
    // }
  }
}

function formatStaffData(staffResponse) {
  const staffs = []
  if (staffResponse.data.staffs.edges.length > 0) {
    for (let i = 0; i < staffResponse.data.staffs.edges.length; i++) {
      if (staffResponse.data.staffs.edges[i].node.tags.edges) {
        const tempTagArr = staffResponse.data.staffs.edges[i].node.tags.edges.map(e => e.node.name)
        staffResponse.data.staffs.edges[i].node.tags = tempTagArr
      }
      staffs.push(staffResponse.data.staffs.edges[i].node)
    }
  }
  return staffs
}

export function* GET_STAFF_DROPDOWNS() {
  const response = yield call(getStaffDropdown)
  if (response && response.data) {
    yield put({
      type: 'staffs/SET_STATE',
      payload: {
        UserRole: response.data.userRole,
        staffPosition: response.data.getStaffPosition,
        clinicLocationList: response.data.schoolLocation.edges,
        staffCredentials: response.data.getStaffCredentials,
      },
    })
  }
}

export function* GET_STAFF_PROFILE({ payload }) {
  const response = yield call(getStaffProfile, payload)

  if (response && response.data) {
    const { staff } = response.data
    if (staff.tags.edges) {
      const tempTagArr = staff.tags.edges.map(e => e.node.name)
      staff.tags = tempTagArr
    } else if (!staff.tags || !staff.tags.length || staff.tags.length === 0) {
      staff.tags = []
    }

    yield put({
      type: 'staffs/SET_STATE',
      payload: {
        StaffProfile: staff,
        SelectedStaffId: staff?.id,
      },
    })
  }
}

export function* CREATE_STAFF({ payload }) {
  const response = yield call(createStaff, payload)

  if (response && response.data) {
    // generating notification
    notification.success({
      message: 'Staff Created Successfully',
    })

    // let token = ''
    // if (!(localStorage.getItem('token') === null) && localStorage.getItem('token')) {
    //   token = JSON.parse(localStorage.getItem('token'))
    // }

    // const headers = {
    //   Accept: 'application/json',
    //   'Access-Control-Allow-Origin': '*',
    //   database: 'india',
    //   Authorization: token ? `JWT ${token}` : '',
    // }
    // payload.data.append('pk', response.data.createStaff.staff.id)
    // axios
    //   .post(`${REACT_APP_API_URL}/staff-docs/`, payload.data, { headers: headers })
    //   .then(res => {
    //     message.success('Upload Successfully.')
    //   })
    //   .catch(err1 => {
    //     message.error('upload Failed.')
    //     return false
    //   })

    yield put({
      type: 'staffs/SET_STATE',
      payload: {
        staffCreated: true,
      },
    })
    yield put({
      type: 'staffs/APPEND_STAFFS_LIST',
      payload: {
        staff: response.data.createStaff.staff,
      },
    })
  }
}

export function* EDIT_STAFF({ payload }) {
  const response = yield call(updateStaff, payload)

  if (response && response.data) {
    notification.success({
      message: 'Staff Updated Successfully',
    })

    const { staff } = response.data.updateStaff
    if (staff.tags.edges.length > 0) {
      const tempTagArr = staff.tags.edges.map(e => e.node.name)
      staff.tags = tempTagArr
    } else {
      staff.tags = []
    }

    yield put({
      type: 'staffs/UPDATE_STAFFS_LIST',
      payload: {
        object: staff,
      },
    })

    yield put({
      type: 'staffs/SET_STATE',
      payload: {
        StaffProfile: staff,
      },
    })
  }
}

export function* UPDATE_STAFF_INFO({ payload }) {
  const result = payload.response.data.updateStaff.staff
  if (result) {
    notification.success({
      message: 'Employee data updated successfullly',
    })

    if (result.tags.edges.length > 0) {
      const tempTagArr = result.tags.edges.map(e => e.node.name)
      result.tags = tempTagArr
    } else {
      result.tags = []
    }

    yield put({
      type: 'staffs/UPDATE_STAFFS_LIST',
      payload: {
        object: result,
      },
    })

    yield put({
      type: 'staffs/SET_STATE',
      payload: {
        StaffProfile: result,
      },
    })
  }
}

export function* STAFF_ACTIVE_INACTIVE({ payload }) {
  const response = yield call(staffActiveInactive, payload)

  if (response && response.data) {
    // generating notification
    if (payload.checked === true) {
      notification.success({
        message: 'Staff Activated Successfully',
      })
    } else {
      notification.success({
        message: 'Staff Deactivated Successfully',
      })
    }

    yield put({
      type: 'staffs/UPDATE_STAFF_ACTIVE_INACTIVE',
      payload: {
        staff: response.data.updateStaff.staff,
      },
    })
  }
}

export function* PAGE_CHANGED({ payload }) {
  yield put({
    type: 'staffs/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // // console.log(payload, 'payload')
  let pageInfo = yield select(state => state.staffs.PageInfo)
  const staffs = yield select(state => state.staffs.StaffList)
  let endCursor = pageInfo?.endCursor

  const status = yield select(state => state.staffs.CurrentStatus)
  const perPage = yield select(state => state.staffs.ItemPerPage)
  let active = null
  if (status === 'all') active = null
  if (status === 'active') active = true
  if (status === 'in-active') active = false

  if (pageInfo?.hasNextPage) {
    let allStaffs = [...staffs]

    const res2 = yield call(getClinicStaffs, {
      isActive: active,
      first: payload.first,
      last: null,
      after: endCursor,
      before: null,
    })

    console.log('res2-->', res2)
    pageInfo = res2?.data?.staffs?.pageInfo
    endCursor = pageInfo?.endCursor
    allStaffs = [...allStaffs, ...formatStaffData(res2)]
    yield put({
      type: 'staffs/SET_STATE',
      payload: {
        loading: false,
        StaffList: allStaffs,
        PageInfo: pageInfo,
      },
    })
  }

  yield put({
    type: 'staffs/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    // GET_DATA(), // run once on app load to fetch menu data
    takeLatest(actions.GET_STAFFS, GET_DATA),
    takeEvery(actions.PAGE_CHANGED, PAGE_CHANGED),
    takeEvery(actions.GET_STAFF_DROPDOWNS, GET_STAFF_DROPDOWNS),
    takeEvery(actions.CREATE_STAFF, CREATE_STAFF),
    takeEvery(actions.EDIT_STAFF, EDIT_STAFF),
    takeEvery(actions.STAFF_ACTIVE_INACTIVE, STAFF_ACTIVE_INACTIVE),
    takeEvery(actions.GET_STAFF_PROFILE, GET_STAFF_PROFILE),
    takeEvery(actions.UPDATE_STAFF_INFO, UPDATE_STAFF_INFO),
  ])
}
