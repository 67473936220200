const actions = {
  SET_STATE: 'coupon/SET_STATE',
  GET_COUPONS: 'coupon/GET_COUPONS',
  PAGE_CHANGED: 'coupon/PAGE_CHANGED',
  CREATE_COUPON: 'coupon/CREATE_COUPON',
  APPEND_COUPON: 'coupon/APPEND_COUPON',
  UPDATE_COUPON: 'coupon/UPDATE_COUPON',
  REPLACE_COUPON: 'coupon/REPLACE_COUPON',
}

export default actions
