import React, { Fragment, useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'components/LayoutComponents/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

const IndexLayout = props => {
  let previousPath = ''
  const { location, prevLocation, children } = props
  const { pathname, search } = location
  const user = useSelector(state => state.user)

  useEffect(() => {
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }, [location, prevLocation])

  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    NProgress.start()
  }

  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }

    console.log('pathname--->', pathname)
    if (/^\/autism_screening(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/developmentalScrening(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/customAssessment\/starter(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/customAssessment\/otherUser(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/customAssessment\/record(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/recordAssessment\/record(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/screening(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/enquiry(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/user(?=\/|$)/i.test(pathname)) {
      return 'login'
    }
    if (/^\/cogniAble_Practo(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/query-registration(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/publicCourse(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    if (/^\/publicCourse\/course(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isLoginLayout = getLayout() === 'login'
  const isPublic = getLayout() === 'public'
  const userRole = user.role

  const BootstrappedLayout = () => {
    // Autism Screening
    if (isPublic) {
      return <Container>{children}</Container>
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
      return <Loader />
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !isUserAuthorized) {
      return <Redirect to="/user/login" />
    }
    // redirect to main dashboard when user on login page and authorized
    // if parent
    if (isLoginLayout && isUserAuthorized && userRole === 'parents') {
      // return <Redirect to="/dashboard/alpha" />
      return <Redirect to="/target/allocation" />
    }
    // if therapist
    if (isLoginLayout && isUserAuthorized && userRole === 'therapist') {
      // return <Redirect to="/dashboard/therapist_admin" />
      return <Redirect to="/target/allocation" />
    }
    // if clinic admin
    if (isLoginLayout && isUserAuthorized && userRole === 'school_admin') {
      // return <Redirect to="/dashboard/clinic_admin" />
      return <Redirect to="/target/allocation" />
    }
    // if Super User
    if (isLoginLayout && isUserAuthorized && userRole === 'superUser') {
      return <Redirect to="/dashboard/clinic_admin" />
    }
    // if Course work user
    if (isLoginLayout && isUserAuthorized && userRole === 'course_admin') {
      return <Redirect to="/course-work/dashboard" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="CogniAble | %s" title="CogniAble Application" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(IndexLayout)
