import COURSEUSER from './actions'

const initialState = {
  users: [],
  isUsersLoading: false,
  isUserSaving: false,
  selectedUser: null,
  isAssignCourseDrawer: false,
  isAssignCourseSaving: false,
  selectedCourse: null,
  selectedModules: [],
  courses: [],
  isCoursesLoading: false,
  userCourses: {},
  isUserCoursesLoading: false,
  fromUserAssigned: false,
  isCreateUserDrawer: false,
  isModuleAvailabilityDrawer: false,
  isAvailabilityLoading: false,
  moduleAvailability: [],
  moduleAvailabilityDates: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case COURSEUSER.SET_STATE:
      return { ...state, ...action.payload }
    case COURSEUSER.APPEND_COURSE_USER: {
      return {
        ...state,
        users: [
          {
            node: action.payload.data,
          },
          ...state.users,
        ],
        userCourses: {
          ...state.userCourses,
          [action.payload.data.id]: [],
        },
        isCreateUserDrawer: false,
      }
    }
    case COURSEUSER.REPLACE_COURSE_USER: {
      const updatedUsers = state.users.map(user => {
        if (user.node.id === action.payload.data.id) {
          return { node: { ...user.node, ...action.payload.data } }
        }
        return user
      })
      return {
        ...state,
        users: updatedUsers,
        selectedUser: null,
        isCreateUserDrawer: false,
      }
    }
    case COURSEUSER.APPEND_COURSE: {
      const { newCourse } = action.payload
      const { selectedUser, userCourses, users } = state
      const userId = selectedUser.id
      const data = userCourses
      const user = users
      const userIdx = users.findIndex(({ node }) => node.id === userId)
      selectedUser.purchasedcoursetemplatesSet.edges.push({
        node: {
          id: newCourse.id,
          courseTemplate: { ...newCourse.courseTemplate },
        },
      })
      user[userIdx].node = {
        ...selectedUser,
      }

      data[userId].push({
        node: {
          ...newCourse,
          courseName: newCourse.courseTemplate.name,
          purchasedCourse: newCourse.id,
          purchasedActive: newCourse.isActive,
        },
      })

      return {
        ...state,
        isAssignCourseDrawer: false,
        selectedCourse: null,
        selectedModules: [],
        fromUserAssigned: false,
        selectedUser: null,
        userCourses: {
          ...state.userCourses,
          ...data,
        },
        users: [...user],
      }
    }
    case COURSEUSER.UPDATE_ASSIGNED_MODULES: {
      const { newAssignedModules } = action.payload
      const userId = state.selectedUser.id
      const data = state.userCourses[userId]

      const selectedCourseData = data.find(
        course => course.node.courseTemplate.id === state.selectedCourse.id,
      )
      const idx = data.findIndex(
        course => course.node.courseTemplate.id === state.selectedCourse.id,
      )
      selectedCourseData.node.assignedModules.edges = newAssignedModules
      data[idx] = { ...selectedCourseData }
      return {
        ...state,
        isAssignCourseDrawer: false,
        selectedCourse: null,
        selectedModules: [],
        fromUserAssigned: false,
        userCourses: {
          ...state.userCourses,
          [userId]: data,
        },
        selectedUser: null,
      }
    }
    case COURSEUSER.UPDATE_ACTIVE_INACTIVE_COURSE: {
      const { isActive } = action.payload
      const userId = state.selectedUser.id
      const data = state.userCourses[userId]

      const selectedCourseData = data.find(
        course => course.node.courseTemplate.id === state.selectedCourse.id,
      )
      const idx = data.findIndex(
        course => course.node.courseTemplate.id === state.selectedCourse.id,
      )
      selectedCourseData.node.purchasedActive = isActive
      data[idx] = { ...selectedCourseData }
      return {
        ...state,
        isAssignCourseDrawer: false,
        selectedCourse: null,
        selectedModules: [],
        fromUserAssigned: false,
        userCourses: {
          ...state.userCourses,
          [userId]: data,
        },
        selectedUser: null,
      }
    }
    default:
      return state
  }
}
