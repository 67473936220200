import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

export async function createClaims(payload) {
  console.log('createClaims')
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createClaims(
          $learner: ID
          $SbrID: String
          $PatLName: String
          $PatFName: String
          $PatBirthdate: Date
          $PatSex: String
          $SbrLName: String
          $SbrFName: String
          $PatAddr: String
          $PatCity: String
          $PatState: String
          $PatZip: String
          $PatRel: String
          $SbrAddr: String
          $SbrCity: String
          $SbrState: String
          $SbrZip: String
          $SbrBirthdate: Date
          $SbrSex: String
          $DiagCodeA: String
          $ICDindicator: String
          $BillTin: String
          $BillTinType: String
          $ClaimRef: String
          $BillName: String
          $BillAddr1: String
          $BillCity: String
          $BillState: String
          $BillZip: String
          $BillNPI: String
          $PayerName: String
          $ServiceFrom1: Date
          $POS1: Date
          $clientMutationId: String
          $ProcCode1: String
          $DiagPtrs1: String
          $Charge1: String
          $Units1: String
        ) {
          createClaims(
            input: {
              SbrID: $SbrID
              learner: $learner
              PatLName: $PatLName
              PatFName: $PatFName
              PatBirthdate: $PatBirthdate
              PatSex: $PatSex
              SbrLName: $SbrLName
              SbrFName: $SbrFName
              PatAddr: $PatAddr
              PatCity: $PatCity
              PatState: $PatState
              PatZip: $PatZip
              PatRel: $PatRel
              SbrAddr: $SbrAddr
              SbrCity: $SbrCity
              SbrState: $SbrState
              SbrZip: $SbrZip
              SbrBirthdate: $SbrBirthdate
              SbrSex: $SbrSex
              DiagCodeA: $DiagCodeA
              ICDindicator: $ICDindicator
              BillTin: $BillTin
              BillTinType: $BillTinType
              ClaimRef: $ClaimRef
              BillName: $BillName
              BillAddr1: $BillAddr1
              BillCity: $BillCity
              BillState: $BillState
              BillZip: $BillZip
              BillNPI: $BillNPI
              PayerName: $PayerName
              ServiceFrom1: $ServiceFrom1
              POS1: $POS1
              clientMutationId: $clientMutationId
              ProcCode1: $ProcCode1
              DiagPtrs1: $DiagPtrs1
              Charge1: $Charge1
              Units1: $Units1
            }
          ) {
            details {
              id
              SbrID
              POS1
              PatSex
            }
          }
        }
      `,
      variables: {
        SbrID: payload.values.SbrID,
        learner: payload.values.learner,
        PatLName: payload.values.PatLName,
        PatFName: payload.values.PatFName,
        PatBirthdate: moment(payload.values.PatBirthdate).format('YYYY-MM-DD'),
        PatSex: payload.values.PatSex,
        SbrLName: payload.values.SbrLName,
        SbrFName: payload.values.SbrFName,
        PatAddr: payload.values.PatAddr,
        PatCity: payload.values.PatCity,
        PatState: payload.values.PatState,
        PatZip: payload.values.PatZip,
        PatRel: payload.values.PatRel,
        SbrAddr: payload.values.SbrAddr,
        SbrCity: payload.values.SbrCity,
        SbrState: payload.values.SbrState,
        SbrZip: payload.values.SbrZip,
        SbrBirthdate: moment(payload.values.SbrBirthdate).format('YYYY-MM-DD'),
        SbrSex: payload.values.SbrSex,
        DiagCodeA: payload.values.DiagCodeA,
        ICDindicator: payload.values.ICDindicator,
        BillTin: payload.values.BillTin,
        BillTinType: payload.values.BillTinType,
        ClaimRef: payload.values.ClaimRef,
        BillName: payload.values.BillName,
        BillAddr1: payload.values.BillAddr1,
        BillCity: payload.values.BillCity,
        BillState: payload.values.BillState,
        BillZip: payload.values.BillZip,
        BillNPI: payload.values.BillNPI,
        PayerName: payload.values.PayerName,
        ServiceFrom1: moment(payload.values.ServiceFrom1).format('YYYY-MM-DD'),
        POS1: moment(payload.values.POS1).format('YYYY-MM-DD'),
        clientMutationId: payload.values.clientMutationId,
        ProcCode1: payload.values.ProcCode1,
        DiagPtrs1: payload.values.DiagPtrs1,
        Charge1: payload.values.Charge1,
        Units1: payload.values.Units1,
      },
    })
    .then(result => result)
    .catch(err => {
      err.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong while Creating Claim ',
          description: item.message,
        })
      })
    })
}

export async function editClaims(payload) {
  console.log('editClaims')
  console.log(payload)

  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateClaims(
          $pk: ID!
          $learner: ID
          $SbrID: String
          $PatLName: String
          $PatFName: String
          $PatBirthdate: Date
          $PatSex: String
          $SbrLName: String
          $SbrFName: String
          $PatAddr: String
          $PatCity: String
          $PatState: String
          $PatZip: String
          $PatRel: String
          $SbrAddr: String
          $SbrCity: String
          $SbrState: String
          $SbrZip: String
          $SbrBirthdate: Date
          $SbrSex: String
          $DiagCodeA: String
          $ICDindicator: String
          $BillTin: String
          $BillTinType: String
          $ClaimRef: String
          $BillName: String
          $BillAddr1: String
          $BillCity: String
          $BillState: String
          $BillZip: String
          $BillNPI: String
          $PayerName: String
          $ServiceFrom1: Date
          $POS1: Date
          $clientMutationId: String
          ProcCode1: String
          DiagPtrs1: String
          Charge1: String
          Units1: String
        ) {
          updateClaims(
            input: {
              pk: $pk
              SbrID: $SbrID
              learner: $learner
              PatLName: $PatLName
              PatFName: $PatFName
              PatBirthdate: $PatBirthdate
              PatSex: $PatSex
              SbrLName: $SbrLName
              SbrFName: $SbrFName
              PatAddr: $PatAddr
              PatCity: $PatCity
              PatState: $PatState
              PatZip: $PatZip
              PatRel: $PatRel
              SbrAddr: $SbrAddr
              SbrCity: $SbrCity
              SbrState: $SbrState
              SbrZip: $SbrZip
              SbrBirthdate: $SbrBirthdate
              SbrSex: $SbrSex
              DiagCodeA: $DiagCodeA
              ICDindicator: $ICDindicator
              BillTin: $BillTin
              BillTinType: $BillTinType
              ClaimRef: $ClaimRef
              BillName: $BillName
              BillAddr1: $BillAddr1
              BillCity: $BillCity
              BillState: $BillState
              BillZip: $BillZip
              BillNPI: $BillNPI
              PayerName: $PayerName
              ServiceFrom1: $ServiceFrom1
              POS1: $POS1
              clientMutationId: $clientMutationId
              ProcCode1: $ProcCode1
              DiagPtrs1: $DiagPtrs1
              Charge1: $Charge1
              Units1: $Units1
            }
          ) {
            details {
              id
              SbrID
              POS1
              PatSex
            }
          }
        }
      `,
      variables: {
        pk: payload.values.id,
        SbrID: payload.values.SbrID,
        learner: payload.values.learner,
        PatLName: payload.values.PatLName,
        PatFName: payload.values.PatFName,
        PatBirthdate: moment(payload.values.PatBirthdate).format('YYYY-MM-DD'),
        PatSex: payload.values.PatSex,
        SbrLName: payload.values.SbrLName,
        SbrFName: payload.values.SbrFName,
        PatAddr: payload.values.PatAddr,
        PatCity: payload.values.PatCity,
        PatState: payload.values.PatState,
        PatZip: payload.values.PatZip,
        PatRel: payload.values.PatRel,
        SbrAddr: payload.values.SbrAddr,
        SbrCity: payload.values.SbrCity,
        SbrState: payload.values.SbrState,
        SbrZip: payload.values.SbrZip,
        SbrBirthdate: moment(payload.values.SbrBirthdate).format('YYYY-MM-DD'),
        SbrSex: payload.values.SbrSex,
        DiagCodeA: payload.values.DiagCodeA,
        ICDindicator: payload.values.ICDindicator,
        BillTin: payload.values.BillTin,
        BillTinType: payload.values.BillTinType,
        ClaimRef: payload.values.ClaimRef,
        BillName: payload.values.BillName,
        BillAddr1: payload.values.BillAddr1,
        BillCity: payload.values.BillCity,
        BillState: payload.values.BillState,
        BillZip: payload.values.BillZip,
        BillNPI: payload.values.BillNPI,
        PayerName: payload.values.PayerName,
        ServiceFrom1: moment(payload.values.ServiceFrom1).format('YYYY-MM-DD'),
        POS1: moment(payload.values.POS1).format('YYYY-MM-DD'),
        clientMutationId: payload.values.clientMutationId,
        ProcCode1: payload.values.ProcCode1,
        DiagPtrs1: payload.values.DiagPtrs1,
        Charge1: payload.values.Charge1,
        Units1: payload.values.Units1,
      },
    })
    .then(result => result)
    .catch(err => {
      err.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong while Edit Claim',
          description: item.message,
        })
      })
    })
}

export async function deleteClaims(payload) {
  console.log('deleteClaims')
  console.log(payload)

  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteClaims($id: ID!) {
          deleteClaims(input: { id: $id }) {
            details {
              id
              SbrID
              POS1
              PatSex
            }
          }
        }
      `,
      variables: {
        id: payload.values.id,
      },
    })
    .then(result => result)
    .catch(err => {
      err.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong while Creating Authentication Code',
          description: item.message,
        })
      })
    })
}
