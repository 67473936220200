import React from 'react'
import { Form, Input } from 'antd'
import TemplateForm from './templateForm'
import { MastSeqProvider } from './context'

const { Item } = Form
const { TextArea } = Input

const Index = ({
  form,
  mstSeqObj,
  manualMastActive,
  setMstSeqObj,
  showAddCriteriaButton = true,
  activeMasteryPhase,
}) => {
  const { getFieldDecorator } = form

  return (
    <div>
      <Item label="Template Description" style={{ marginBottom: '0px' }}>
        {getFieldDecorator('description', {
          initialValue: mstSeqObj?.description || '',
        })(
          <TextArea
            disabled={!manualMastActive}
            autoSize={{ minRows: 2, maxRows: 2 }}
            allowClear
            placeholder="Enter template description"
          />,
        )}
      </Item>
      <TemplateForm
        form={form}
        manualMastActive={manualMastActive}
        mstSeqObj={mstSeqObj}
        setMstSeqObj={setMstSeqObj}
        showAddCriteriaButton={showAddCriteriaButton}
        activeMasteryPhase={activeMasteryPhase}
      />
    </div>
  )
}

export default props => {
  const Comp = Form.create()(Index)
  return (
    <MastSeqProvider>
      <Comp {...props} />
    </MastSeqProvider>
  )
}
