/* eslint-disable no-shadow */
import React, { useState, useEffect, useReducer, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Button, Drawer, Table, Radio, Input, Select, Popconfirm, Checkbox } from 'antd'
import {
  PlusOutlined,
  CloseCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons'
import { useQuery, useMutation } from 'react-apollo'
import _ from 'lodash'
import { COLORS, PRIMARY_ORANGE_BUTTON } from 'assets/styles/globalStyles'
import Authorize from 'components/LayoutComponents/Authorize'
import HeaderComponent from 'components/HeaderComponentNew'
import { AnimatePresence, motion } from 'framer-motion'
import AnimateFromRight from 'components/ScreenChangeAnimation/AnimateFromRight'
import AnimateToLeft from 'components/ScreenChangeAnimation/AnimateToLeft'
import { RiFilterOffLine } from 'react-icons/ri'
import HeaderTitle from 'components/HeaderTitle'
import ClearFilterButton from 'components/Button/ClearFilterButton'
import AddEditPayor from './AddEditPayor'
import { GET_PAYORS, GET_CONTACT_TYPES, GET_PAYOR_PLANS, DELETE_PAYOR } from './query'
// import './style.scss'
import reducer from './reducer'
import ACTIONS from './actions'
import styles from './style.module.scss'
import PayorProvider, { PayorContext } from './context'

const { Option } = Select
const tableFilterStyles = {
  width: 200,
}

const HeaderRightContent = ({ dispatch }) => {
  return (
    <div>
      <Button style={PRIMARY_ORANGE_BUTTON} onClick={() => dispatch({ type: ACTIONS.ADD_PAYOR })}>
        <PlusOutlined /> Add Payor
      </Button>
    </div>
  )
}

const PayorTable = () => {
  const { state, dispatch } = useContext(PayorContext)

  const [payorList, setPayorList] = useState([])
  const [mainData, setMainData] = useState([])
  const [contactTypeList, setContactTypeList] = useState([])
  const [payorPlanList, setPayorPlanList] = useState([])
  const [payorProfile, setPayorProfile] = useState(null)
  const { data: payors, loading, error, refetch: refetchPayors } = useQuery(GET_PAYORS)
  const { data: contactTypes } = useQuery(GET_CONTACT_TYPES)
  const { data: payorPlans } = useQuery(GET_PAYOR_PLANS)

  const [appliedFilters, setFilters] = useState({
    name: '',
    email: '',
    phone: '',
    status: ['all'],
    isActive: ['all'],
    responsibility: ['All'],
  })
  const [appliedSorting, setSorting] = useState({
    order: 'descend',
    columnKey: 'age',
  })

  const [filter, setFilter] = useState({
    status: '',
    name: '',
    email: '',
    phone: '',
    responsibility: '',
  })

  const [deletePayor] = useMutation(DELETE_PAYOR)

  const [isFilterActive, setIsFilterActive] = useState(false)
  const isPayorCreated = useSelector(state => state.payor.isPayorCreated)
  const isPayorEdited = useSelector(state => state.payor.isPayorEdited)

  useEffect(() => {
    if (payors) {
      const nodes = payors.getPayors.edges.map(({ node }) => node)
      setPayorList(nodes)
      setMainData(nodes)
    }
  }, [payors])

  useEffect(() => {
    if (contactTypes) {
      const items = contactTypes.getPayorContactType.map(item => item)
      setContactTypeList(items)
    }
  }, [contactTypes])

  useEffect(() => {
    if (payorPlans) {
      const nodes = payorPlans.payorPlan.edges.map(({ node }) => node)
      const sortedNodes = _.orderBy(nodes, ['company.name', 'plan'])
      setPayorPlanList(sortedNodes)
    }
  }, [payorPlans])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // Handle custom filters using here
    confirm()
    setFilters({
      ...appliedFilters,
      [dataIndex]: selectedKeys[0],
    })
  }

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters()
    const existingFilters = appliedFilters
    if (existingFilters[dataIndex]) delete existingFilters[dataIndex]
    setFilters(existingFilters)
  }

  const handleFilterAndSorting = (pagination, filters, sorter) => {
    setSorting(sorter)
    if (filters) {
      // Handle default filters from here
      if (filters.isActive && filters.isActive.length === 1) {
        onStatusFilterChange(filters.isActive[0])
      }
      if (filters.responsibility && filters.responsibility.length === 1) {
        onResponsibilityFilterChange(filters.responsibility[0])
      }
    }
  }

  const onStatusFilterChange = newStatus => {
    setFilters({ ...appliedFilters, isActive: [newStatus] })
  }

  const onResponsibilityFilterChange = newSelection => {
    setFilters({ ...appliedFilters, responsibility: [newSelection] })
  }

  useEffect(() => {
    filterHandler()
  }, [filter, mainData])

  const filterHandler = () => {
    let filteredList = mainData
    const { name, status, phoneNumber } = filter

    let tempFilterActive = false
    if (name) {
      tempFilterActive = true
      filteredList =
        filteredList &&
        filteredList.filter(item => item.name?.toLowerCase().includes(name.toLowerCase()))
    }
    if (phoneNumber) {
      tempFilterActive = true
      filteredList =
        filteredList &&
        filteredList.filter(
          item =>
            item.phoneNumber && item.phoneNumber.toLowerCase().includes(phoneNumber.toLowerCase()),
        )
    }
    if (status) {
      tempFilterActive = true
      if (status === 'Active') {
        filteredList = filteredList && filteredList.filter(item => item.isActive === true)
      } else {
        filteredList = filteredList && filteredList.filter(item => item.isActive === false)
      }
    }
    setIsFilterActive(tempFilterActive)
    setPayorList(filteredList)
  }

  const resetFilter = () => {
    setFilter({
      status: '',
      name: '',
      email: '',
      phone: '',
      responsibility: '',
    })
  }

  console.log(' im from payors ')
  const columnCommonProps = [appliedFilters, appliedSorting, handleSearch, handleReset]
  const antColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      width: 200,
    },
    {
      title: 'Address',
      render: ({ streetAddress, city, state, country, zipCode }) =>
        `${streetAddress || ''} ${city || ''} ${state || ''} ${country || ''} ${zipCode || ''}`,
    },
    {
      title: 'Medicaid',
      dataIndex: 'Medicaid',
      width: 120,
      align: 'center',
      render: val => <Checkbox checked={val} disabled />,
    },
    {
      title: 'Private Insurance',
      dataIndex: 'private',
      width: 200,
      align: 'center',
      render: val => <Checkbox checked={val} disabled />,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      width: 150,
      render: text => (text ? 'Active' : 'Inactive'),
    },
    {
      title: 'Action',
      width: 100,
      render: row => {
        return (
          <div>
            <>
              <Button
                type="link"
                onClick={() => {
                  dispatch({ type: ACTIONS.EDIT_PAYOR, payload: row })
                  setPayorProfile(row)
                }}
                size="small"
              >
                <EditOutlined style={{ fontWeight: 600 }} />
              </Button>
              <Popconfirm
                title="Are you sure to delete this payor?"
                onConfirm={() => {
                  deletePayor({ variables: { id: row.id } })
                    .then(res => {
                      refetchPayors()
                    })
                    .catch(err => console.error(err))
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ color: COLORS.danger }} size="small">
                  <DeleteOutlined style={{ color: COLORS.danger, fontWeight: 600 }} />
                </Button>
              </Popconfirm>
            </>
          </div>
        )
      },
    },
  ]

  const header = () => (
    <div className={styles.payorFilters}>
      {isFilterActive ? <ClearFilterButton onClick={resetFilter} /> : null}
      <Input
        name="name"
        allowClear
        placeholder="Search Name"
        onChange={e => {
          setFilter({ ...filter, name: e.target.value })
        }}
        value={filter.name}
        style={{ ...tableFilterStyles }}
      />
      <Input
        name="phone"
        allowClear
        placeholder="Search phone"
        onChange={e => {
          setFilter({ ...filter, phoneNumber: e.target.value })
        }}
        value={filter.phoneNumber}
        style={{ ...tableFilterStyles }}
      />
      <Select
        defaultValue=""
        onSelect={value => {
          setFilter({ ...filter, status: value })
        }}
        placeholder="Status"
        style={{ ...tableFilterStyles, marginRight: 0 }}
        value={filter.status}
      >
        <Option key={1} value="">
          All
        </Option>
        <Option key={2} value="Active">
          Active
        </Option>
        <Option key={3} value="Inactive">
          Inactive
        </Option>
      </Select>
    </div>
  )

  return (
    <Authorize roles={['school_admin']} redirect to="/dashboard/beta">
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      <HeaderComponent
        title={
          <HeaderTitle
            showBackButton={state.showBackButton}
            title={state.title}
            onBackClick={() => dispatch({ type: ACTIONS.BACK_CLICK })}
          />
        }
        pathList={['Authorizations', state.title]}
        rightContent={
          <AnimatePresence initial={false}>
            {!state.showBackButton && (
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 200 }}
              >
                <HeaderRightContent dispatch={dispatch} />
              </motion.div>
            )}
          </AnimatePresence>
        }
      />
      <div className={styles.payorTable}>
        <AnimatePresence initial={false}>
          {state.addFormOpen && (
            <AnimateFromRight key="addForm" className={styles.addPayorFormWrapper}>
              <AddEditPayor
                refetchPayors={refetchPayors}
                contactTypes={contactTypeList}
                closeDrawer={() => dispatch({ type: ACTIONS.CLOSE_ADD_FORM })}
              />
            </AnimateFromRight>
          )}
          {!state.editFormOpen && !state.addFormOpen && (
            <AnimateToLeft key="table" className={styles.payorsTable}>
              <Table
                loading={loading}
                bordered={false}
                rowKey={record => record.id}
                columns={antColumns}
                dataSource={payorList}
                pagination={{
                  position: 'bottom',
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100'],
                }}
                scroll={{ x: 1200 }}
                onChange={handleFilterAndSorting}
                title={header}
              />
            </AnimateToLeft>
          )}
          {state.editFormOpen && (
            <AnimateFromRight key="editForm" className={styles.addPayorFormWrapper}>
              <AddEditPayor
                closeDrawer={() => dispatch({ type: ACTIONS.CLOSE_EDIT_FORM })}
                edit
                key={payorProfile?.id}
                payorProfile={payorProfile}
                payors={payorList}
                contactTypes={contactTypeList}
                payorPlans={payorPlanList}
                refetchPayors={refetchPayors}
              />
            </AnimateFromRight>
          )}
        </AnimatePresence>
      </div>
    </Authorize>
  )
}

export default () => (
  <PayorProvider>
    <PayorTable />
  </PayorProvider>
)
