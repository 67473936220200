/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
import { all, takeEvery, put, call, select, takeLatest } from 'redux-saga/effects'
import {
  activeInactiveUserCourse,
  assignCourseToUser,
  changeModuleAvailability,
  createCourseUser,
  getCourseTemplates,
  getCourseUsers,
  getPurchasedCourseByCourseUser,
  getUserCourseModuleValidations,
  updateAssignedCourse,
  updateCourseUser,
} from 'services/courseUser'

import { notification } from 'antd'
import COURSEUSER from './actions'

export function* GET_ALL_COURSES() {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isCoursesLoading: true,
    },
  })

  const response = yield call(getCourseTemplates)
  if (response) {
    yield put({
      type: COURSEUSER.SET_STATE,
      payload: {
        courses: response.getPublicCourses,
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isCoursesLoading: false,
    },
  })
}
export function* GET_COURSE_USERS({ payload }) {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isUsersLoading: true,
    },
  })

  const response = yield call(getCourseUsers, payload)
  if (response) {
    const data = {}
    response.getCourseUsers.edges.map(({ node }) => {
      data[node.id] = []
      return node
    })
    yield put({
      type: COURSEUSER.SET_STATE,
      payload: {
        users: response.getCourseUsers.edges,
        userCourses: data,
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isUsersLoading: false,
    },
  })
}

export function* CREATE_COURSE_USER({ payload }) {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isUserSaving: true,
    },
  })

  const response = yield call(createCourseUser, payload)
  if (response && response.createCourseUser) {
    notification.success({
      message: response.createCourseUser.message,
    })
    yield put({
      type: COURSEUSER.APPEND_COURSE_USER,
      payload: {
        data: response.createCourseUser.courseUserDetails,
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isUserSaving: false,
    },
  })
}

export function* UPDATE_COURSE_USER({ payload }) {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isUserSaving: true,
    },
  })

  const response = yield call(updateCourseUser, payload)
  if (response && response.updateCourseUser) {
    notification.success({
      message: response.updateCourseUser.message,
    })
    yield put({
      type: COURSEUSER.REPLACE_COURSE_USER,
      payload: {
        data: response.updateCourseUser.courseUserDetails,
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isUserSaving: false,
    },
  })
}

export function* ASSIGN_COURSE_TO_USER({ payload }) {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isAssignCourseSaving: true,
    },
  })

  const response = yield call(assignCourseToUser, payload)
  if (response && response.assignCourseToUser) {
    notification.success({
      message: response.assignCourseToUser.message,
    })
    yield put({
      type: COURSEUSER.APPEND_COURSE,
      payload: {
        newCourse: response.assignCourseToUser.purchasedCourses,
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isAssignCourseSaving: false,
    },
  })
}

export function* UPDATE_ASSIGNED_COURSE({ payload }) {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isAssignCourseSaving: true,
    },
  })

  const response = yield call(updateAssignedCourse, payload)
  if (response && response.updateAssignedCourse) {
    notification.success({
      message: response.updateAssignedCourse.message,
    })
    yield put({
      type: COURSEUSER.UPDATE_ASSIGNED_MODULES,
      payload: {
        newAssignedModules: response.updateAssignedCourse.purchasedCourses.assignedModules.edges,
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isAssignCourseSaving: false,
    },
  })
}

export function* ACTIVE_INACTIVE_USER_COURSE({ payload }) {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isAssignCourseSaving: true,
    },
  })

  const response = yield call(activeInactiveUserCourse, payload)
  if (response && response.activeInactiveUserCourse) {
    notification.success({
      message: response.activeInactiveUserCourse.message,
    })
    yield put({
      type: COURSEUSER.UPDATE_ACTIVE_INACTIVE_COURSE,
      payload: {
        isActive: payload.isActive,
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isAssignCourseSaving: false,
    },
  })
}

export function* GET_COURSE_BY_USER({ payload }) {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isUserCoursesLoading: true,
    },
  })
  const response = yield call(getPurchasedCourseByCourseUser, payload)
  if (response) {
    const { userCourses } = yield select(state => state.courseUser)
    yield put({
      type: COURSEUSER.SET_STATE,
      payload: {
        userCourses: {
          ...userCourses,
          [payload.courseUserId]: response.getPurchasedCourseByCourseUser.edges,
        },
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isUserCoursesLoading: false,
    },
  })
}

export function* GET_USER_COURSE_MODULE_VALIDATION_BY_SUPERADMIN({ payload }) {
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isAvailabilityLoading: true,
    },
  })
  const response = yield call(getUserCourseModuleValidations, payload)
  const { moduleAvailabilityDates } = yield select(state => state.courseUser)
  if (response) {
    let obj = {
      ...moduleAvailabilityDates,
    }
    response.getUserCourseModuleValidations.edges.forEach(({ node }) => {
      obj = {
        ...obj,
        [node.module.id]: {
          expireDate: node.expireTime,
          saving: false,
        },
      }
      // yield put({
      //   type: COURSEUSER.SET_STATE,
      //   payload: {
      //     moduleAvailabilityDates: {
      //       ...moduleAvailabilityDates,
      //       [node.module.id]: {
      //         expireDate:node.expireTime,
      //         saving:false,
      //       },
      //     }
      //   },
      // })
    })
    yield put({
      type: COURSEUSER.SET_STATE,
      payload: {
        moduleAvailability: response.getUserCourseModuleValidations.edges,
        moduleAvailabilityDates: obj,
      },
    })
  }
  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      isAvailabilityLoading: false,
    },
  })
}

export function* CHANGE_MODULE_AVAILABILITY({ payload }) {
  const { moduleAvailabilityDates } = yield select(state => state.courseUser)

  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      moduleAvailabilityDates: {
        ...moduleAvailabilityDates,
        [payload.moduleId]: {
          ...moduleAvailabilityDates[payload.moduleId],
          saving: true,
        },
      },
    },
  })
  const response = yield call(changeModuleAvailability, payload)

  yield put({
    type: COURSEUSER.SET_STATE,
    payload: {
      moduleAvailabilityDates: {
        ...moduleAvailabilityDates,
        [payload.moduleId]: {
          ...moduleAvailabilityDates[payload.moduleId],
          saving: false,
        },
      },
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(COURSEUSER.GET_ALL_COURSES, GET_ALL_COURSES),

    takeLatest(COURSEUSER.GET_COURSE_USERS, GET_COURSE_USERS),
    takeLatest(COURSEUSER.CREATE_COURSE_USER, CREATE_COURSE_USER),
    takeLatest(COURSEUSER.UPDATE_COURSE_USER, UPDATE_COURSE_USER),

    takeLatest(COURSEUSER.ASSIGN_COURSE_TO_USER, ASSIGN_COURSE_TO_USER),
    takeLatest(COURSEUSER.UPDATE_ASSIGNED_COURSE, UPDATE_ASSIGNED_COURSE),
    takeLatest(COURSEUSER.ACTIVE_INACTIVE_USER_COURSE, ACTIVE_INACTIVE_USER_COURSE),

    takeLatest(COURSEUSER.GET_COURSE_BY_USER, GET_COURSE_BY_USER),
    takeLatest(
      COURSEUSER.GET_USER_COURSE_MODULE_VALIDATION_BY_SUPERADMIN,
      GET_USER_COURSE_MODULE_VALIDATION_BY_SUPERADMIN,
    ),
    takeLatest(COURSEUSER.CHANGE_MODULE_AVAILABILITY, CHANGE_MODULE_AVAILABILITY),
  ])
}
