/* eslint-disable no-plusplus */
/* eslint-disable object-shorthand */
/* eslint-disable array-callback-return */
import { all, takeEvery, put, call, takeLatest, select } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  createRegistration,
  verifyOtp,
  resendOtp,
  getMarketingServices,
  saveServices,
  getMarketingSearch,
  emailVerify,
  createReviewMarketUser,
  getComments,
  uploadDocs,
  getMarketingUsers,
  approveMarketingUser,
  verifyUserSendOtp,
  verifyUserOtp,
} from 'services/marketingModal'
import actions from './actions'

export function* SUBMIT_FORM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(createRegistration, payload)
  if (response && response.data.addMarketUserInfo && response.data.addMarketUserInfo.status) {
    notification.success({
      message: response.data.addMarketUserInfo.message,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        currentPage: 'otpVerification',
        objectDetails: response.data.addMarketUserInfo.marketingUser,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* VERIFY_OTP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const objectDetails = yield select(state => state.marketingModal.objectDetails)
  if (objectDetails) {
    const response = yield call(verifyOtp, { otp: payload.otp, objId: objectDetails.id })
    if (response && response.data.verifyMarketUser && response.data.verifyMarketUser.status) {
      notification.success({
        message: response.data.verifyMarketUser.message,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          currentPage: 'services',
        },
      })
    }
  } else {
    notification.error({
      message: 'Registered user not found, please register again',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        currentPage: 'signup',
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* RESEND_OTP() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const objectDetails = yield select(state => state.marketingModal.objectDetails)
  if (objectDetails) {
    const response = yield call(resendOtp, { objId: objectDetails.id })
    if (
      response &&
      response.data.resendOtpForVerification &&
      response.data.resendOtpForVerification.status
    ) {
      notification.success({
        message: response.data.resendOtpForVerification.message,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  } else {
    notification.error({
      message: 'Registered user not found, please register again',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        currentPage: 'signup',
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_SERVICES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(getMarketingServices)
  if (response && response.data.usersMarketServices) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        services: response.data.usersMarketServices,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SAVE_SERVICES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const objectDetails = yield select(state => state.marketingModal.objectDetails)
  if (objectDetails) {
    const response = yield call(saveServices, {
      services: payload.values.services,
      objId: objectDetails.id,
    })
    if (
      response &&
      response.data.updateMarketUserInfo &&
      response.data.updateMarketUserInfo.status
    ) {
      notification.success({
        message: response.data.updateMarketUserInfo.message,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          currentPage: 'document',
        },
      })
    }
  } else {
    notification.error({
      message: 'Registered user not found, please register again',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        currentPage: 'signup',
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(getMarketingSearch, payload)
  const oldList = yield select(state => state.marketingModal.searchResults)
  const oldService = yield select(state => state.marketingModal.service)

  if (response && response.data.allMarketingUsersInfo) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        searchResults:
          oldService === payload.text
            ? [...oldList, ...response.data.allMarketingUsersInfo.edges]
            : response.data.allMarketingUsersInfo.edges,
        pageInfo: response.data.allMarketingUsersInfo.pageInfo,
        currentPage: 'searchResult',
        service: payload.text,
        city: payload.city,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* VERIFY_EMAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      verifyEmailLoading: true,
    },
  })
  const response = yield call(emailVerify, payload)
  if (response && response.data.sendOtpForReview.status) {
    notification.success({
      message: response.data.sendOtpForReview.message,
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      verifyEmailLoading: false,
    },
  })
}

export function* SAVE_FEEDBACK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(createReviewMarketUser, payload)
  if (response && response.data.createReviewMarketUser.status) {
    notification.success({
      message: response.data.createReviewMarketUser.message,
    })
    const data = response.data.createReviewMarketUser.marketUserRating
    const { comments, searchResults } = yield select(state => state.marketingModal)
    const idx = searchResults.findIndex(({ node }) => node.id === data.marketUser.id)
    if (idx !== -1) {
      searchResults[idx] = {
        node: {
          ...searchResults[idx].node,
          marketusersratingSet: data.marketUser.marketusersratingSet,
        },
      }
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        searchResults,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_COMMENTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingComments: true,
    },
  })
  const response = yield call(getComments, payload)
  if (response && response.data.marketingUserReviews) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingComments: false,
        comments: response.data.marketingUserReviews,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingComments: false,
    },
  })
}

export function* UPLOAD_DOCS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(uploadDocs, payload)
  if (response && response.data.addMarketingUserDoc.status) {
    notification.success({
      message: 'Registration completed successfully!',
    })
    notification.success({
      message: response.data.addMarketingUserDoc.message,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        currentPage: 'search',
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_ALL_USERS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(getMarketingUsers, payload)
  if (response && response.data.allMarketingUsersInfo) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        AllUsers: response.data.allMarketingUsersInfo.edges,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* APPROVE_USER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      approvedloading: true,
    },
  })

  const { AllUsers } = yield select(state => state.marketingModal)

  const response = yield call(approveMarketingUser, payload)
  if (response && response.data.approveMarketingUser.status) {
    notification.success({
      message: response.data.approveMarketingUser.message,
    })

    const newData = AllUsers.map((item, index) => {
      if (item.node.id === payload.userId) {
        return {
          node: {
            ...item.node,
            isApproved: response.data.approveMarketingUser.marketingUser.isApproved,
          },
        }
      }
      return item
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        approvedloading: false,
        AllUsers: newData,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      approvedloading: false,
    },
  })
}

export function* VERIFY_USER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(verifyUserSendOtp, payload)
  if (
    response &&
    response.data.resendOtpForVerificationByEmail &&
    response.data.resendOtpForVerificationByEmail.status
  ) {
    notification.success({
      message: response.data.resendOtpForVerificationByEmail.message,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showOtp: true,
        verifyEmail: payload.email,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* VERIFY_USER_OTP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const verifyEmail = yield select(state => state.marketingModal.verifyEmail)

  if (verifyEmail) {
    const response = yield call(verifyUserOtp, { otp: payload.otp, email: verifyEmail })
    if (response && response.data.verifyOtpUsingEmail && response.data.verifyOtpUsingEmail.status) {
      notification.success({
        message: response.data.verifyOtpUsingEmail.message,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          objectDetails: response.data.verifyOtpUsingEmail.markUser,
          currentPage: 'services',
        },
      })
    }
  } else {
    notification.error({
      message: 'Registered user not found, please register again',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        currentPage: 'signup',
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBMIT_FORM, SUBMIT_FORM),
    takeLatest(actions.VERIFY_OTP, VERIFY_OTP),
    takeLatest(actions.RESEND_OTP, RESEND_OTP),
    takeLatest(actions.GET_SERVICES, GET_SERVICES),
    takeLatest(actions.SAVE_SERVICES, SAVE_SERVICES),
    takeLatest(actions.SEARCH, SEARCH),
    takeLatest(actions.VERIFY_EMAIL, VERIFY_EMAIL),
    takeLatest(actions.SAVE_FEEDBACK, SAVE_FEEDBACK),
    takeLatest(actions.GET_COMMENTS, GET_COMMENTS),
    takeLatest(actions.UPLOAD_DOCS, UPLOAD_DOCS),
    takeLatest(actions.GET_ALL_USERS, GET_ALL_USERS),
    takeLatest(actions.APPROVE_USER, APPROVE_USER),
    takeLatest(actions.VERIFY_USER, VERIFY_USER),
    takeLatest(actions.VERIFY_USER_OTP, VERIFY_USER_OTP),
  ])
}
