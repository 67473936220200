/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
import { ConsoleSqlOutlined } from '@ant-design/icons'
import { all, put, takeEvery, call, select } from 'redux-saga/effects'
import { getData, getLearnerDetails, getLearnerSessions } from 'services/learnersprogram'
import actions from './actions'

export function* LOAD_DATA() {
  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      Loading: true,
    },
  })

  const user = yield select(state => state.user)
  const std = localStorage.getItem('studentId')
  if (user.role !== 'parents') {
    const response = yield call(getData, { isActive: true })
    if (response && response.data) {
      yield put({
        type: 'learnersprogram/SET_STATE',
        payload: {
          Loading: false,
          Learners: response.data.students.edges,
          LearnersFetched: true,
          CloneLearners: response.data.students.edges,
          ProgramAreas: response.data.programArea.edges,
        },
      })

      if (std === null) {
        if (response.data.students.edges.length > 0) {
          localStorage.setItem('studentId', JSON.stringify(response.data.students.edges[0].node.id))
          yield put({
            type: 'learnersprogram/SET_STATE',
            payload: {
              SelectedLearnerId: response.data.students.edges[0].node.id,
            },
          })
          yield put({
            type: 'student/STUDENT_DETAILS',
            payload: {
              studentId: response.data.students.edges[0].node.id,
            },
          })
        }
      } else {
        yield put({
          type: 'learnersprogram/SET_STATE',
          payload: {
            SelectedLearnerId: JSON.parse(std),
          },
        })
        yield put({
          type: 'student/STUDENT_DETAILS',
          payload: {
            studentId: JSON.parse(std),
          },
        })
      }
    }
  } else {
    yield put({
      type: 'learnersprogram/SET_STATE',
      payload: {
        SelectedLearnerId: JSON.parse(std),
      },
    })
    yield put({
      type: 'student/STUDENT_DETAILS',
      payload: {
        studentId: JSON.parse(std),
      },
    })
  }

  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      Loading: false,
    },
  })
}

export function* CHNAGE_LEARNER({ payload }) {
  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      LearnerDetailsLoading: true,
    },
  })

  const response = yield call(getLearnerDetails, { learnerId: payload.newLearnerId })
  if (response && response.data) {
    yield put({
      type: 'learnersprogram/SET_STATE',
      payload: {
        LearnerDetailsLoading: false,
        LearnerDetails: response.data.student,
        LearnerSessions: [],
        LearnerChildSessions: [],
      },
    })
  }

  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      LearnerDetailsLoading: false,
    },
  })
}

export function* GET_LEARNER_SESSION({ payload }) {
  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      LearnerSessionLoading: true,
    },
  })

  const sessionResponse = yield call(getLearnerSessions, { learnerId: payload.newLearnerId })

  if (sessionResponse && sessionResponse.data) {
    yield put({
      type: 'learnersprogram/SET_STATE',
      payload: {
        LearnerSessionLoading: false,
        LearnerSessions: sessionResponse.data.GetStudentSession.edges,
        LearnerChildSessions: sessionResponse.data.getChildSession.edges,
      },
    })
  }

  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      LearnerSessionLoading: false,
    },
  })
}

export function* LOAD_INACTIVE_LEARNERS() {
  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      Loading: true,
    },
  })
  const response = yield call(getData, { isActive: false })
  if (response && response.data) {
    yield put({
      type: 'learnersprogram/SET_STATE',
      payload: {
        Loading: false,
        InActiveLearners: response.data.students.edges,
        LearnersFetched: true,
      },
    })
  }

  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      Loading: false,
    },
  })
}

export function* CHANGE_LEARNERS_STATUS_FILTER({ payload }) {
  yield put({
    type: 'learnersprogram/SET_STATE',
    payload: {
      LearnersStatusFilter: payload?.learnerStatusFilter || 'active',
    },
  })
  if (payload.learnerStatusFilter === 'inactive' || payload.learnerStatusFilter === 'all') {
    const inActiveLeaners = yield select(state => state.learnersprogram.InActiveLearners)
    if (inActiveLeaners.length === 0) {
      yield put({
        type: 'learnersprogram/SET_STATE',
        payload: {
          Loading: true,
        },
      })
      const response = yield call(getData, { isActive: false })
      if (response && response.data) {
        yield put({
          type: 'learnersprogram/SET_STATE',
          payload: {
            Loading: false,
            InActiveLearners: response.data.students.edges,
            LearnersFetched: true,
          },
        })
      }

      yield put({
        type: 'learnersprogram/SET_STATE',
        payload: {
          Loading: false,
        },
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_DATA, LOAD_DATA),
    takeEvery(actions.CHNAGE_LEARNER, CHNAGE_LEARNER),
    takeEvery(actions.GET_LEARNER_SESSION, GET_LEARNER_SESSION),

    takeEvery(actions.LOAD_INACTIVE_LEARNERS, LOAD_INACTIVE_LEARNERS),
    takeEvery(actions.CHANGE_LEARNERS_STATUS_FILTER, CHANGE_LEARNERS_STATUS_FILTER),
  ])
}
