/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import gql from 'graphql-tag'

export const SESSION_RECORDING_TMEPLATE = gql`
  query($session: ID!, $learner: ID!) {
    sessionDataRecordingTemplateNew(learner: $learner, sessionId: $session) {
      sessionRecordingTemplate
    }
  }
`
export const GET_STUDENT_DETAILS = gql`
  query student($studentId: ID!) {
    student(id: $studentId) {
      id
      firstname
      lastname
      isCogActive
      isPeakActive
    }
  }
`
export const ISACTIVE = gql`
  mutation vbmappIsActive($studentId: ID!) {
    vbmappIsActive(input: { student: $studentId }) {
      status
      msg
    }
  }
`
export const VBMAPP_Activate_Student = gql`
  mutation vbmappActivateStudent($student: ID!, $activate: Boolean) {
    vbmappActivateStudent(input: { student: $student, activate: $activate }) {
      status
      msg
    }
  }
`

export const VBMAPP_Check_Subscription = gql`
  mutation vbmappIsSubscribed($student: ID!) {
    vbmappIsSubscribed(input: { student: $student }) {
      msg
      status
      isSubscribed
      isExpired
      validUpto
    }
  }
`
export const Purchase_VBMAPP_Subscription = gql`
  mutation purchaseVbMappSubscription($student: ID!) {
    purchaseVbMappSubscription(input: { student: $student }) {
      message
      status
      validUpto
      validFrom
    }
  }
`
export const GET_STUDENT = gql`
  query student($std: ID!) {
    student(id: $std) {
      id
      firstname
      lastname
      programArea {
        edges {
          node {
            id
            name
            description
            percentageLong
          }
        }
      }
    }
  }
`
export const UPDATE_STUDENT_PROGRAM_AREA = gql`
  mutation UpdateStudentProgramAreas($id: ID!, $areaList: [ID]) {
    updateStudent(input: { studentData: { id: $id, programArea: $areaList } }) {
      student {
        id
        firstname
        programArea {
          edges {
            node {
              id
              name
              description
              percentageLong
            }
          }
        }
      }
    }
  }
`
export const GET_THERAPY = gql`
  query TherapyPrograms($student: ID!, $program: ID!) {
    programDetails(id: $program) {
      id
      name
      description
      longtermgoalSet(student: $student) {
        edges {
          node {
            id
            percentageCorr
            masterTar
            totalTar
            goalName
            shorttermgoalSet {
              edges {
                node {
                  id
                  goalName
                  percentageCorr
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_STUDENT_SESSION = gql`
  query GetStudentSession($studentId: ID!) {
    GetStudentSession(studentId: $studentId) {
      edges {
        node {
          id
          itemRequired
          duration
          sessionName {
            id
            name
          }
          instruction {
            edges {
              node {
                id
                instruction
              }
            }
          }
          sessionHost {
            edges {
              node {
                id
                memberName
                timeSpent {
                  edges {
                    node {
                      id
                      sessionName {
                        id
                        name
                      }
                      duration
                    }
                  }
                }
                relationship {
                  id
                  name
                }
              }
            }
          }
          targets {
            edges {
              node {
                id
                time
                targetInstr
                date
                targetAllcatedDetails {
                  id
                  targetName
                }
              }
            }
          }
        }
      }
    }
  }
`
