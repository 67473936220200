import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Button, Form, Input, Select, notification, Row, Col } from 'antd'
import { useQuery, useMutation } from 'react-apollo'
import LoadingComponent from 'components/LoadingComponent/index'
import { COLORS } from 'assets/styles/globalStyles'
import { CURRENCY, UPDATE_CLINIC_CURRENCY, SCHOOL_CURRENCY } from './query'

const { Option } = Select

function GeneralInfo({ form }) {
  const schoolId = localStorage.getItem('userId')
  const { data: currencyData, loading: currencyLoading } = useQuery(CURRENCY)
  const { data, error, loading } = useQuery(SCHOOL_CURRENCY, {
    variables: {
      id: schoolId,
    },
    fetchPolicy: 'network-only',
  })

  const [updateInvoiceDetails, { loading: updateInvoiceDetailLoading }] = useMutation(
    UPDATE_CLINIC_CURRENCY,
  )

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        updateInvoiceDetails({
          variables: {
            currency: values.currency,
            billingName: values.billingName,
          },
        })
          .then(res => {
            console.log(res, 'res')
            notification.success({
              message: 'Details updated successfully',
            })
          })
          .catch(errDt => {
            console.log(errDt, 'err')
            notification.error({
              message: 'Something went wrong',
              description: 'Unable to update bank details',
            })
          })
      }
    })
  }

  if (loading) {
    return <LoadingComponent />
  }
  if (error) {
    return <div>Oops! Something went wrong</div>
  }

  return (
    <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Col sm={24} md={24} lg={18}>
        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Form.Item
            label="Billing Name"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ paddingTop: '2em' }}
          >
            {form.getFieldDecorator('billingName', {
              initialValue: data.school.billingName
                ? data.school.billingName
                : data.school.schoolName,
              rules: [{ required: true, message: 'Please provide billing Name' }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Currency" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            {form.getFieldDecorator('currency', {
              initialValue: data.school.currency?.id,
              rules: [{ required: true, message: 'Please provide invoice currency' }],
            })(
              <Select
                loading={currencyLoading}
                defaultActiveFirstOption
                placeholder="Select a currency"
                style={{ width: '100%' }}
              >
                {currencyData?.currency.map(({ id, currency, symbol }) => {
                  return (
                    <Option key={id} value={id}>
                      {`${symbol} - ${currency}`}
                    </Option>
                  )
                })}
              </Select>,
            )}
          </Form.Item>

          <div style={{ display: 'flex' }}>
            <Button
              style={{ backgroundColor: COLORS.submitBlue }}
              loading={updateInvoiceDetailLoading}
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  )
}

export default Form.create()(GeneralInfo)
