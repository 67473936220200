/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { all, put, takeEvery, call, select } from 'redux-saga/effects'
import { createCoupon, getCoupons, updateCoupon } from 'services/coupon'
import actions from './actions'

export function* GET_COUPONS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isCouponLoading: true,
    },
  })
  const response = yield call(getCoupons, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        coupons: response.allCoupon.edges,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isCouponLoading: false,
    },
  })
}

export function* PAGE_CHANGED({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isCouponLoading: true,
    },
  })
  let { first, pageNo } = payload
  const { pageInfo, type } = yield select(state => state.coupon)

  let after = null
  let before = null
  let last = null

  if (pageNo === 1) {
    after = null
    before = null
  }
  if (type === 'next') {
    after = pageInfo?.endCursor
  }
  if (type === 'prev') {
    before = pageInfo?.startCursor
    last = first
    first = null
  }

  const response = yield call(getCoupons, { ...payload, after, before, first, last })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        coupons: response.allCoupon.edges,
        pageInfo: response.allCoupon.pageInfo,
        couponCount: response.allCoupon.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isCouponLoading: false,
    },
  })
}

export function* CREATE_COUPON({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isCouponSaving: true,
    },
  })
  const response = yield call(createCoupon, payload)
  if (response && response.createDiscountcoupon) {
    notification.success({
      message: 'Coupon Created successfully!',
    })
    yield put({
      type: actions.APPEND_COUPON,
      payload: {
        data: response.createDiscountcoupon.discountcoupon,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isCouponSaving: false,
    },
  })
}

export function* UPDATE_COUPON({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isCouponSaving: true,
    },
  })
  const response = yield call(updateCoupon, payload)
  if (response && response.updateDiscountcoupon) {
    notification.success({
      message: 'Coupon updated successfully!',
    })
    yield put({
      type: actions.REPLACE_COUPON,
      payload: {
        data: response.updateDiscountcoupon.discountcoupon,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isCouponSaving: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_COUPONS, GET_COUPONS),
    takeEvery(actions.PAGE_CHANGED, PAGE_CHANGED),
    takeEvery(actions.CREATE_COUPON, CREATE_COUPON),
    takeEvery(actions.UPDATE_COUPON, UPDATE_COUPON),
  ])
}
