/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { Divider, Form, Row, Switch, Select, Icon, Col, Input, notification } from 'antd'
import CKEditor from 'react-ckeditor-component'
import { useTargetAlloc } from '../context'
import './style.scss'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const { TextArea } = Input
const searchableDropDownOption = {
  showSearch: true,
  optionFilterProp: 'children',
  filterOption: (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}

const MiscInfoCard = ({
  form,
  targetVideo,
  edit,
  targetObject,
  activeBehave,
  showSession = true,
  targetInstructions,
  setTargetInstructions,
}) => {
  const { getFieldDecorator } = form
  const dynamicSpan = edit ? 6 : 5

  const { sessData, targetOptionsLoading, sessionNames } = useTargetAlloc()

  return (
    <div>
      <div style={boxCard}>
        <Divider orientation="left">Misc</Divider>
        {activeBehave === 'recording' ? (
          <>
            <Form.Item label="Behaviour Description" name="Behaviour Description">
              {getFieldDecorator('behaviourDescription', {
                initialValue: targetObject?.behaviourDescription || '',
                rules: [
                  {
                    message: 'Please provide behaviour description',
                  },
                ],
              })(
                <TextArea
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder="Give the behaviour description"
                />,
              )}
            </Form.Item>
            <Form.Item label="Reactive Procedure" name="Reactive Procedure">
              {getFieldDecorator('reactiveProcedure', {
                initialValue: targetObject?.reactiveProcedure || '',
                rules: [{ message: 'Please provide reactive procedure' }],
              })(
                <TextArea
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder="Give the reactive procedure"
                />,
              )}
            </Form.Item>
            <Form.Item label="Antecedent Manipulation" name="Antecedent Manipulation">
              {getFieldDecorator('antecedentManipulation', {
                initialValue: targetObject?.antecedentManipulation || '',
                rules: [{ message: 'Please provice antecedent manipulation' }],
              })(
                <TextArea
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder="Give the antecedent manipulation"
                />,
              )}
            </Form.Item>
          </>
        ) : (
          <Form.Item label="Target Instructions" name="Target Instructions">
            <CKEditor
              name="targetInstructions"
              activeClass="p10"
              content={targetInstructions}
              events={{
                change: evt => {
                  setTargetInstructions(evt.editor.getData())
                },
              }}
            />
          </Form.Item>
        )}
        <Form.Item label="Target Video Link">
          {getFieldDecorator('video', {
            initialValue: targetVideo,
            rules: [
              {
                pattern: /^(http(s)?:\/\/)?((w){3}.)?(youtu(be|.be)|(vimeo))?(\.com)?\/.+/,
                message: 'Enter valid url',
              },
            ],
          })(<Input placeholder="Give the video url" />)}
        </Form.Item>
        <Row gutter={[24]} style={{ textAlign: 'left' }} className="misc-switch-boxes">
          {!edit && (
            <>
              <Col span={dynamicSpan}>
                <Form.Item label="Parent Allocation email">
                  {getFieldDecorator('emailSend', {
                    valuePropName: 'checked',
                    initialValue: false,
                  })(
                    <Switch
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={dynamicSpan}>
                <Form.Item label="Case Manager Allocation email">
                  {getFieldDecorator('caseManagerEmail', {
                    valuePropName: 'checked',
                    initialValue: false,
                  })(
                    <Switch
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                    />,
                  )}
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={dynamicSpan}>
            <Form.Item label="Status change email">
              {getFieldDecorator('emailMasterStatus', {
                valuePropName: 'checked',
                initialValue: edit ? targetObject?.emailMasterStatus : false,
              })(
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={dynamicSpan}>
            <Form.Item tooltip="Plot 0 for unrecorded data" label="Plot as Zero">
              {getFieldDecorator('plotZero', {
                valuePropName: 'checked',
                initialValue: edit ? targetObject?.plotZero : false,
              })(
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                />,
              )}
            </Form.Item>
          </Col>
          {!edit && (
            <Col span={4}>
              <Form.Item label="Make values default">
                {getFieldDecorator('makeDefault', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Switch
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                  />,
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
        {showSession && (
          <Form.Item label="Sessions" style={{ marginBottom: 0 }} name="masteryCriteria">
            {getFieldDecorator('sessions', {
              initialValue: edit
                ? targetObject.sessionsAllocated
                : sessData?.learnerSessionSettings
                ? sessData.learnerSessionSettings?.sessions.edges.map(
                    item => item.node.sessionName.id,
                  )
                : [],
            })(
              <Select
                name="session"
                mode="multiple"
                allowClear
                loading={targetOptionsLoading}
                {...searchableDropDownOption}
              >
                {sessionNames?.map(({ id, sessionName }) => {
                  return (
                    <Select.Option key={id} value={sessionName.id}>
                      {sessionName.name}
                    </Select.Option>
                  )
                })}
              </Select>,
            )}
          </Form.Item>
        )}
      </div>
    </div>
  )
}

export default MiscInfoCard
