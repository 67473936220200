/* eslint-disable import/prefer-default-export */
/* eslint-disable object-shorthand */
import { gql } from 'apollo-boost'
import { notification } from 'antd'
import client from 'apollo/config'

export const PROGRAM_AREA = gql`
  query($id: ID!) {
    student(id: $id) {
      id
      programArea(isActive: true) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_LONG_GOALS = gql`
  query($program: ID, $student: ID) {
    longTerm(program: $program, student: $student) {
      edges {
        node {
          id
          goalStatus {
            id
            status
          }
        }
      }
    }
  }
`
export const GET_LONG_GOALS_DETAILS = gql`
  query($program: ID, $goalStatus: ID, $student: ID) {
    longTerm(program: $program, goalStatus: $goalStatus, student: $student) {
      edges {
        node {
          id
          goalStatus {
            id
            status
          }
          program {
            id
            name
          }
          goalName
          dateInitialted
          dateEnd
          responsibility {
            id
            name
          }
          shorttermgoalSet {
            edges {
              node {
                id
                targetAllocateSet {
                  edges {
                    node {
                      id
                      targetStatus {
                        id
                        statusName
                      }
                      manualAllocateDomain {
                        id
                        domain
                      }
                      targetId {
                        id
                        domain {
                          id
                          domain
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_TARGET_ALLOCATES = gql`
  query($id: ID!) {
    longTermDetail(id: $id) {
      id
      shorttermgoalSet {
        edges {
          node {
            id
            targetAllocateSet {
              edges {
                node {
                  id
                  targetStatus {
                    id
                    statusName
                  }
                  manualAllocateDomain {
                    id
                    domain
                  }
                  targetAllcatedDetails {
                    id
                    targetName
                    targetType {
                      id
                      typeTar
                    }
                  }
                  targetId {
                    id
                    domain {
                      id
                      domain
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
