/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
import { all, put, takeEvery, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { clinicDetails, getLearnerSessions, updateMl } from 'services/school'
import actions from './actions'

export function* SOME_FUNCTION({ payload }) {
  yield put({
    type: 'student/SET_STATE',
    payload: {
      Loading: true,
    },
  })
}

export function* SCHOOL_DETAILS({ payload }) {
  yield put({
    type: 'school/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(clinicDetails)
  if (response) {
    yield put({
      type: 'school/SET_STATE',
      payload: {
        loading: false,
        ...response.data.schoolDetail,
      },
    })
  }

  yield put({
    type: 'school/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_ML({ payload }) {
  yield put({
    type: 'school/SET_STATE',
    payload: {
      updateMlLoading: true,
    },
  })

  const response = yield call(updateMl, payload)
  if (response) {
    yield put({
      type: 'school/SET_STATE',
      payload: {
        loading: false,
        updateMlLoading: false,
        ...response.data.changeUserSetting.details,
        id: payload.id,
      },
    })

    notification.success({
      message: 'Status updated successfully',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SOME_FUNCTION, SOME_FUNCTION),
    takeEvery(actions.SCHOOL_DETAILS, SCHOOL_DETAILS),
    takeEvery(actions.UPDATE_ML, UPDATE_ML),
  ])
}
