import actions from './actions'

const initialState = {
  isClinicLoading: false,
  clinics: [],
  selectedClinic: null,
  isClinicSaving: false,

  // pagination
  clinicCount: 800,

  // learners
  isLearnerLoading: false,
  learners: [],
  learnerCount: 0,
  // learnerpagination

  learnerPageInfo: null,
  learnerPrevPageNo: 1,
  learnerPagetype: 'next',
}

export default function clinicReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.REPLACE_CLINIC: {
      return {
        ...state,
        selectedClinic: null,
        clinics: [
          ...state.clinics.map(item => {
            if (item.details.id === action.payload.data.id) {
              return {
                ...item,
                details: { ...item.details, isActive: action.payload.data.user.isActive },
              }
            }
            return item
          }),
        ],
      }
    }
    default:
      return state
  }
}
