/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_DATE_RANGE_SETTINGS = gql`
  query($user: ID) {
    getDateRangeSettings(user: $user) {
      edges {
        node {
          id
          clinic {
            id
          }
          therapist {
            id
          }
          rangeTitle
          rangeDescription
          rangeDuration
          rangeUnit
          isCustomRange
          customGte
          customLte
        }
      }
    }
  }
`
