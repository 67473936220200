/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, { useState } from 'react'
import Authorize from 'components/LayoutComponents/Authorize'
import { Helmet } from 'react-helmet'
import { Button, Row, Col } from 'antd'
import InvoiceList from './invoiceList'
import CustomerList from './customerList'
import BankDetails from './bankDetails'

export default () => {
  const [activeTab, setActiveTab] = useState('0')

  const buttonStyle = {
    height: 40,
    width: 170,
    textAlign: 'left',
    background: 'none',
    border: 'none',
  }

  const activeButtonStyle = {
    height: 40,
    width: 170,
    textAlign: 'left',
    background: '#fff',
    color: '#0b35b3',
    border: '1px solid #0b35b3',
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <Authorize roles={['superUser']} redirect to="/404">
        <Helmet title="Dashboard Alpha" />
        <Row gutter={5}>
          <Col span={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: 20,
                marginTop: 40,
              }}
            >
              <Button
                style={activeTab === '0' ? activeButtonStyle : buttonStyle}
                onClick={() => setActiveTab('0')}
              >
                <i className="fas fa-circle" style={{ paddingRight: 10 }} />
                Invoices
              </Button>
              <Button
                style={activeTab === '1' ? activeButtonStyle : buttonStyle}
                onClick={() => setActiveTab('1')}
              >
                <i className="fas fa-circle" style={{ paddingRight: 10 }} />
                Customer
              </Button>
              <Button
                style={activeTab === '2' ? activeButtonStyle : buttonStyle}
                onClick={() => setActiveTab('2')}
              >
                <i className="fas fa-circle" style={{ paddingRight: 10 }} />
                Bank Details
              </Button>
            </div>
          </Col>
          <Col span={20} style={{ padding: '1rem' }}>
            {activeTab === '0' && <InvoiceList />}

            {activeTab === '1' && <CustomerList />}

            {activeTab === '2' && <BankDetails />}
          </Col>
        </Row>
      </Authorize>
    </div>
  )
}
