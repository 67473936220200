/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircleOutlined, CloseCircleOutlined, DollarOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Row, Table, Tooltip } from 'antd'
import { COLORS } from 'assets/styles/globalStyles'
import * as FileSaver from 'file-saver'
import gql from 'graphql-tag'
import lodash from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import { FaDownload } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import DropSection from './DropSection'
import './table.scss'

const { RangePicker } = DatePicker

const TIME_SHEET_DATA = gql`
  query Stafftimesheet($staffId: ID, $dateGte: Date, $dateLte: Date) {
    timesheets(staffId: $staffId, dateGte: $dateGte, dateLte: $dateLte) {
      edges {
        node {
          id
          title
          start
          end
          location {
            id
            location
          }
          note
          isApproved
          isBillable
          homeSessionLocations
          leaveTimings
          student {
            firstname
            lastname
            gender
          }
        }
      }
    }
  }
`
const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
  backgroundColor: COLORS.palleteLight,
}
const parentDiv = { display: 'flex', margin: '5px 40px 5px 5px' }
const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }

const WorkLog = () => {
  const [selectedDateRange, setSelectedDateRange] = useState([moment().subtract(6, 'd'), moment()])
  console.log(selectedDateRange[0].format('YYYY-MM-DD'))
  console.log(selectedDateRange[1].format('YYYY-MM-DD'))
  const [selectedStaffId, setSelectedStaffId] = useState(null)
  const [sDate, setSDate] = useState(
    moment(Date.now())
      .subtract(21, 'days')
      .format('YYYY-MM-DD')
      .toString(),
  )
  const [eDate, setEDate] = useState(
    moment(Date.now())
      .format('YYYY-MM-DD')
      .toString(),
  )

  const [fetchTimesheet, { data, loading, error, refetch }] = useLazyQuery(TIME_SHEET_DATA)

  const [cardData, setCardData] = useState([])
  const staffs = useSelector(state => state.staffs)
  const [sortedData, setSortedData] = useState(null)

  useEffect(() => {
    if (staffs) {
      setSelectedStaffId(staffs.SelectedStaffId)
    }
  }, [staffs])

  useEffect(() => {
    if (selectedStaffId && selectedDateRange[0] && selectedDateRange[1]) {
      console.log('im aclled selected', selectedStaffId)
      fetchTimesheet({
        variables: {
          staffId: selectedStaffId,
          dateGte: moment(selectedDateRange[0]).format('YYYY-MM-DD'),
          dateLte: moment(selectedDateRange[1]).format('YYYY-MM-DD'),
        },
      })
    }
  }, [selectedStaffId, selectedDateRange])

  useEffect(() => {
    if (data && data?.timesheets) {
      const temp = []
      data.timesheets.edges.map(item => {
        let obj = {}
        obj = {
          ...item.node,
          date: moment(item.node.start).format('YYYY-MM-DD'),
        }
        return temp.push(obj)
      })
      temp.sort((a, b) => new Date(b.start) - new Date(a.start))
      setCardData(temp)
      console.log(cardData)
    }
  }, [data])

  useEffect(() => {
    if (data && data?.timesheets) {
      const group = lodash.groupBy(cardData, item => item.start.split('T')[0])
      const arrayGroup = []
      Object.keys(group).forEach(key => {
        arrayGroup.push([key, group[key]])
      })
      arrayGroup.sort((a, b) => new Date(b[0]) - new Date(a[0]))
      if (arrayGroup.length > 0) {
        setSortedData(arrayGroup)
      }
    }
  }, [cardData, data])

  const exportToCSV = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

    const dataToExport = cardData?.map(item => ({
      Date: item.date,
      Title: item.title,
      'Start Time': item.start,
      'End Time': item.end,
      Note: item.note,
      Location: item.location,
      'Is Approved': item.isApproved,
      'Is Billable': item.isBillable,
    }))

    const ws = XLSX.utils.json_to_sheet(dataToExport)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })

    const startDate = moment(selectedDateRange[0]).format('YYYY_MM_DD')
    const endDate = moment(selectedDateRange[1]).format('YYYY_MM_DD')
    FileSaver.saveAs(excelData, `worklogs_${startDate}-${endDate}.xlsx`)
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Start Time',
      dataIndex: 'start',
      render: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'End Time',
      dataIndex: 'end',
      render: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Tooltip placement="top" title="Approved">
            {record.isApproved ? (
              <CheckCircleOutlined style={{ fontSize: 18, color: COLORS.success }} />
            ) : (
              <CloseCircleOutlined style={{ fontSize: 18, color: COLORS.danger }} />
            )}
          </Tooltip>
          <Tooltip placement="top" title="Billable">
            {record.isBillable ? (
              <DollarOutlined style={{ fontSize: 18, color: COLORS.success }} />
            ) : (
              <DollarOutlined style={{ fontSize: 18, color: 'grey' }} />
            )}
          </Tooltip>
        </div>
      ),
    },
    // {
    //   title: 'Feedback',
    //   dataIndex: '',
    //   align: 'center',
    //   render: (text, record) => {
    //     return (
    //       <Button type="link" onClick={() => showFeedback(record.id)} size="small">
    //         Feedback
    //       </Button>
    //     )
    //   },
    // },
  ]

  const DateChangeDropDown = date => {
    setSDate(
      moment(date[0])
        .format('YYYY-MM-DD')
        .toString(),
    )
    setEDate(
      moment(date[1])
        .format('YYYY-MM-DD')
        .toString(),
    )
  }

  return (
    <>
      <Row>
        <Col sm={24}>
          <Row>
            <Col span={26}>
              <div style={filterCardStyle}>
                <div style={parentDiv}>
                  <DropSection dateChange={v => DateChangeDropDown(v)} />
                </div>
                <div style={parentDiv}>
                  <Tooltip title="Select Date range">
                    <RangePicker
                      style={{
                        width: 250,
                      }}
                      // value={selectedDateRange}
                      value={[moment(sDate, 'YYYY-MM-DD'), moment(eDate, 'YYYY-MM-DD')]}
                      defaultValue={[moment(sDate, 'YYYY-MM-DD'), moment(eDate, 'YYYY-MM-DD')]}
                      onChange={setSelectedDateRange}
                      className="datePicker"
                    />
                  </Tooltip>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    type="link"
                    size="large"
                    disabled={loading || cardData?.length === 0}
                    onClick={exportToCSV}
                    style={{ padding: '0 8px' }}
                  >
                    <FaDownload fontSize="22px" />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ margin: '10px 0 10px 10px' }}>
            <Table
              className="allReportTable"
              columns={columns}
              dataSource={cardData}
              bordered
              loading={loading}
              expandedRowRender={row => {
                console.log('row--->', row)
                return (
                  <>
                    <p style={{ margin: 0 }}>
                      <b>Is Approved: </b>
                      {row.isApproved ? 'Yes' : 'No'}
                    </p>
                    <p style={{ margin: 0 }}>
                      <b>Is Billable: </b>
                      {row.isBillable ? 'Yes' : 'No'}
                    </p>
                    {row.student && (
                      <p style={{ margin: 0 }}>
                        <b>Student: </b>
                        {row.student.firstname}
                      </p>
                    )}
                    {row.homeSessionLocations && (
                      <p style={{ margin: 0 }}>
                        <b>Home Session Location: </b>
                        {row.homeSessionLocations}
                      </p>
                    )}
                    {row.location && (
                      <p style={{ margin: 0 }}>
                        <b>Location: </b>
                        {row.location.location}
                      </p>
                    )}
                    <p style={{ margin: 0, whiteSpace: 'pre' }}>
                      <b>Note: </b>
                      {row.note}
                    </p>
                  </>
                )
              }}
              scroll={{ x: cardData.length, y: '80vh' }}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default WorkLog
