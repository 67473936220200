/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import { LineChartOutlined } from '@ant-design/icons'
import groupObj from '@hunters/group-object'
import { Button, notification, Table } from 'antd'
import { COLORS } from 'assets/styles/globalStyles'
import LoadingComponent from 'components/VBMappReport/LoadingComponent'
import { useLazyQuery } from 'react-apollo'
import * as FileSaver from 'file-saver'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import ResponseGraphComp from 'components/TargetRecordingComponent/targetResponseRate'
import * as XLSX from 'xlsx'
import { RESPONSE_RATE_EQUI, PEAK_EQUIVALENCE } from './query'
import './form.scss'
import './table.scss'

const dateFormat = 'YYYY-MM-DD'

export const ResponseRateEqui = forwardRef((props, ref) => {
  const { studentName, type, status, daysList, dateRange, setDateRange, studentId } = props
  const [tableData, setTableData] = useState([])
  const [visible, setVisible] = useState(false)
  const [selectedTarget, setSelectedTarget] = useState(null)
  const [peakSd, setPeakSd] = useState(null)
  const [peakEquiFilData, setPeakEquiFilData] = useState(null)
  const [nextDisable, setNextDisable] = useState(false)
  const [prevDisable, setPrevDisable] = useState(false)

  const [
    getResponseRateEqui,
    { data: equiData, error: equiError, loading: equiLoading, refetch: getResponseRateEquiRefetch },
  ] = useLazyQuery(RESPONSE_RATE_EQUI, {
    fetchPolicy: 'network-only',
  })

  const [
    getPeakEquiData,
    {
      data: peakEqui,
      error: peakEquiError,
      loading: peakEquiLoading,
      refetch: getPeakEquiDataRefetch,
    },
  ] = useLazyQuery(PEAK_EQUIVALENCE, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && studentId) {
      let start
      let end
      if (dateRange[0].format(dateFormat) < dateRange[1].format(dateFormat)) {
        start = dateRange[0].format(dateFormat)
        end = dateRange[1].format(dateFormat)
      } else {
        start = dateRange[1].format(dateFormat)
        end = dateRange[0].format(dateFormat)
      }

      getResponseRateEqui({
        variables: {
          startDate: start,
          endDate: end,
          studentId,
          equivalence: true,
        },
      })
      getPeakEquiData({
        variables: {
          start,
          end,
          student: studentId,
          sessionName: '',
          equivalence: true,
        },
      })
    }
  }, [dateRange, studentId])

  useEffect(() => {
    if (equiError) {
      notification.error({
        message: 'Something went wrong',
        description: 'Unable to fetch equivalence targets',
      })
    }
    if (peakEquiError) {
      notification.error({
        message: 'Something went wrong',
        description: 'Unable to fetch equivalence targets',
      })
    }
  }, [equiError, peakEquiError])

  useEffect(() => {
    if (equiData) {
      filterData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, status, peakEquiFilData])

  useEffect(() => {
    if (peakEqui) {
      let tempData = []
      const tempClassId = []
      peakEqui.peakBlockWiseReport.forEach(item => {
        tempData.push({
          date: item.date,
          equBlocks: item.equBlocks,
          targetName: item.target?.targetAllcatedDetails.targetName,
          targetId: item.target?.targetAllcatedDetails.id,
          peakType: item.target?.peakType,
          baseline: item.target?.baselineDate,
          master: item.target?.masterDate,
          therapy: item.target?.intherapyDate,
          inmaintainence: item.target?.inmaintainenceDate,
          allocate: item.target?.targetAllcatedDetails.dateBaseline,
        })
      })
      tempData = tempData.filter(item => {
        if (item.targetName === undefined || item.targetName === null || item.equBlocks === null) {
          return false
        }

        if (item.equBlocks.length === 0) {
          return true
        }
        for (let i = 0; i < tempClassId.length; i += 1) {
          if (item.equBlocks[0].id === tempClassId[i]) {
            return false
          }
        }

        tempClassId.push(item.equBlocks[0].id)
        return true
      })

      setPeakEquiFilData(tempData)
    }
  }, [peakEqui])

  const loadData = equiDt => {
    if (equiDt && peakEquiFilData) {
      const tempTable = []
      const groupEquiData = groupObj.group(equiDt, 'targetName')
      const groupPeakEquiDetails = groupObj.group(peakEquiFilData, 'targetName')
      const keys = Object.keys(groupEquiData)
      const expandType = 'Stimulus'
      keys.forEach(target => {
        tempTable.push({
          target,
          key: target,
          type: 'target',
          targetId: groupEquiData[target][0].targetId,
          targetStatusName: groupEquiData[target][0].targetStatusName,
          targetType: groupEquiData[target][0].targetType,
          children: [],
        })
        const tarIdx = tempTable.length - 1
        groupPeakEquiDetails[target?.trim()]?.forEach(item => {
          item.equBlocks.forEach(tt => {
            let stimExist = false
            let stimIdx = -1
            let tempStimulus = tt.codeClass.name
            if (expandType === 'Stimulus') {
              if (
                tt.recType === 'TRAIN' &&
                tt.relationTrain.stimulus1 &&
                tt.relationTrain.stimulus2
              ) {
                tempStimulus = `${tt.relationTrain.stimulus1}${tt.relationTrain.sign12}${tt.relationTrain.stimulus2}`
              } else if (
                tt.recType === 'TRAIN' &&
                tt.relationTrain.stimulus2 &&
                tt.relationTrain.stimulus3
              ) {
                tempStimulus = `${tt.relationTrain.stimulus2}${tt.relationTrain.sign23}${tt.relationTrain.stimulus3}`
              } else if (
                tt.recType === 'TEST' &&
                tt.relationTest.stimulus1 &&
                tt.relationTest.stimulus2
              ) {
                tempStimulus = `${tt.relationTest.stimulus1}${tt.relationTest.sign12}${tt.relationTest.stimulus2}`
              } else if (
                tt.recType === 'TEST' &&
                tt.relationTest.stimulus2 &&
                tt.relationTest.stimulus3
              ) {
                tempStimulus = `${tt.relationTest.stimulus2}${tt.relationTest.sign23}${tt.relationTest.stimulus3}`
              }
            }

            for (let i = 0; i < tempTable[tarIdx].children.length; i += 1) {
              if (tempTable[tarIdx].children[i].target === tempStimulus) {
                stimExist = true
                stimIdx = i
              }
            }
            if (stimExist) {
              if (tempTable[tarIdx].children[stimIdx][item.date]) {
                tempTable[tarIdx].children[stimIdx][item.date].total += 1
                tempTable[tarIdx].children[stimIdx][item.date].correctCount +=
                  tt.score === 10 ? 1 : 0
              } else {
                tempTable[tarIdx].children[stimIdx][item.date] = {
                  total: 1,
                  correctCount: tt.score === 10 ? 1 : 0,
                }
              }
            } else {
              tempTable[tarIdx].children.push({
                target: tempStimulus,
                parentTarget: target,
                parentTargetId: groupEquiData[target][0].targetId,
                key:
                  expandType === 'Stimulus'
                    ? tt.recType === 'TRAIN'
                      ? tt.relationTrain.id
                      : tt.relationTest.id
                    : tt.codeClass.id,

                [item.date]: {
                  total: 1,
                  correctCount: tt.score === 10 ? 1 : 0,
                },
                type: expandType,
              })
            }
            if (tempTable[tarIdx][item.date]) {
              tempTable[tarIdx][item.date].total += 1
              tempTable[tarIdx][item.date].correctCount += tt.score === 10 ? 1 : 0
            } else {
              tempTable[tarIdx][item.date] = {
                total: 1,
                correctCount: tt.score === 10 ? 1 : 0,
              }
            }
          })
        })
      })
      setTableData(tempTable)
    }
  }

  const columns = [
    {
      key: 'targetName',
      title: 'Target',
      fixed: 'left',
      width: '350px',
      dataIndex: 'target',
      render: (text, row) => {
        console.log('text equ', row)
        if (row.type === 'target') {
          if (row.children) {
            return (
              <>
                <div
                  style={{
                    height: '26px',
                    fontSize: '14px',
                    display: 'flex',
                    margin: 'auto 0',
                    width: '100%',
                    color: COLORS.target,
                    fontWeight: '600',
                  }}
                >
                  {text}
                </div>

                <Button
                  type="link"
                  onClick={() =>
                    handleSelectTarget({
                      parentTargetId: row.targetId,
                      parent: true,
                    })
                  }
                >
                  <LineChartOutlined
                    style={{ margin: 'auto 0', fontSize: '26px', color: COLORS.graph }}
                  />
                </Button>
              </>
            )
          }
          return (
            <div
              style={{
                fontSize: '13px',
                height: '26px',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                fontWeight: '600',
                color: COLORS.target,
              }}
            >
              <div style={{ margin: 'auto 0', padding: 0 }}>{text}</div>

              <Button type="link" onClick={() => handleSelectTarget(row)}>
                <LineChartOutlined
                  style={{ margin: 'auto 0', fontSize: '26px', color: COLORS.graph }}
                />
              </Button>
            </div>
          )
        }

        return (
          <div
            style={{
              display: 'flex',
              height: '26px',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '12px',
              textAlign: 'center',
              fontWeight: '600',
              paddingLeft: '20px',
              color: row.type === 'Stimulus' ? COLORS.stimulus : COLORS.steps,
            }}
          >
            <div style={{ margin: 'auto 0', padding: 0 }}>{text}123</div>

            {row.parent ? null : (
              <Button type="link" onClick={() => handleSelectTarget(row)}>
                <LineChartOutlined
                  style={{ fontSize: '26px', margin: 'auto 0', color: COLORS.graph }}
                />
              </Button>
            )}
          </div>
        )
      },
    },
    {
      title: 'Target Status',
      key: 'Target Status',
      dataIndex: 'targetStatusName',
      width: '120px',
      render: text => <span style={{ fontWeight: 600 }}>{text}</span>,
    },
    {
      title: 'Target Type',
      key: 'Target Type',
      dataIndex: 'targetType',
      width: '150px',
      render: text => <span style={{ fontWeight: 600 }}>{text}</span>,
    },
    ...daysList.map(item => {
      return {
        title: `${item.dayDate} ${item.month}`,
        align: 'center',

        children: [
          {
            title: item.day.substring(0, 3),
            align: 'center',
            render: (text, row) => {
              if (row[item.date]) {
                if (row.type === 'target') {
                  return (
                    <span style={{ fontWeight: 600 }}>
                      {Number((row[item.date]?.correctCount / row[item.date]?.total) * 100).toFixed(
                        0,
                      )}
                    </span>
                  )
                }
                return (
                  <span>
                    {Number((row[item.date]?.correctCount / row[item.date]?.total) * 100).toFixed(
                      0,
                    )}
                  </span>
                )
              }
              return null
            },
          },
        ],
      }
    }),
  ]

  const handleSelectTarget = targetName => {
    // getGraphData(targetName)
    console.log('targetName>>>', targetName)
    setSelectedTarget({ id: targetName.parentTargetId })
    if (targetName.parent) {
      setPeakSd(undefined)
    } else {
      setPeakSd(targetName.target)
    }
    setVisible(true)
  }

  const getGraphData = targetName => {
    const graphAxixData = []
    const groupedData = groupObj.group(daysList, 'monthYear')
    let keys = []
    keys = Object.keys(groupedData)

    keys.forEach(monthYear => {
      const tempData = [
        {
          month: groupedData[monthYear][0].month,
          year: groupedData[monthYear][0].year,
          key: `DailyResponseRate ${groupedData[monthYear][0].month} ${groupedData[monthYear][0].year}`,
          color: 'hsl(335, 70%, 50%)',
          data: [],
          parentTarget: targetName?.parentTarget,
          target: targetName?.target,
        },
      ]

      groupedData[monthYear].forEach(item => {
        const targetPer = targetName[item.date]
          ? Number(
              (targetName[item.date]?.correctCount / targetName[item.date]?.total) * 100,
            ).toFixed(0)
          : 0
        tempData[0].data.push({
          x: item.dayDate,
          y: targetPer,
          key: item.dayDate,
        })
      })
      graphAxixData.push(tempData)
    })
  }

  const filterData = () => {
    const typeFilterDataList = []
    const statusFilterDataList = []
    if ((type && type.length > 0) || (status && status.length > 0)) {
      if (type && type.length > 0) {
        type.forEach(targetTypeVal => {
          const flt = equiData.responseRate.filter(item => item.targetType === targetTypeVal)
          typeFilterDataList.push(...flt)
        })
        loadData(typeFilterDataList)
      }
      if (status && status.length > 0) {
        if (type && type.length > 0) {
          status.forEach(statusVal => {
            const flt = typeFilterDataList.filter(item => item.targetStatusName === statusVal)
            statusFilterDataList.push(...flt)
          })
        } else {
          status.forEach(statusVal => {
            const flt = equiData.responseRate.filter(item => item.targetStatusName === statusVal)
            statusFilterDataList.push(...flt)
          })
        }
        loadData(statusFilterDataList)
      }
    } else {
      loadData(equiData.responseRate)
    }
  }

  const getFormattedObj = (data, parentTarget) => {
    let tempObj = {
      target:
        data.type === 'target'
          ? data.target
          : data.type === 'Stimulus'
          ? `${parentTarget}-Stimulus-${data.target}`
          : `${parentTarget}-Step-${data.target}`,
    }
    daysList.forEach(item => {
      if (data[item.date]) {
        tempObj = {
          ...tempObj,
          [`${item.date}`]: Number(
            Number((data[item.date].correctCount / data[item.date].total) * 100).toFixed(0),
          ),
        }
      } else {
        tempObj = {
          ...tempObj,
          [`${item.date}`]: null,
        }
      }
    })

    return tempObj
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  useImperativeHandle(ref, () => ({
    exportToCSV(reportType) {
      if (reportType === 'Equivalence') {
        const filename = '_daily_res_exel_Equivalence'
        const formattedData = []

        for (let i = 0; i < tableData.length; i += 1) {
          const obj = tableData[i]
          const tempObj = getFormattedObj(obj)
          formattedData.push(tempObj)
          if (obj.children) {
            obj.children.forEach(child => {
              formattedData.push(getFormattedObj(child, obj.target))
            })
          }
        }
        const ws = XLSX.utils.json_to_sheet(formattedData)
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const excelData = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(excelData, studentName + filename + fileExtension)
      }
    },
  }))

  const handleCloseLineDrawer = () => {
    getResponseRateEquiRefetch()
    getPeakEquiDataRefetch()
  }

  // const getIdxByTargetId = targetId => {
  //   const index = tableData
  //     .map(function(e) {
  //       return e.targetId
  //     })
  //     .indexOf(targetId)

  //   return index
  // }

  // const checkIdx = index => {
  //   if (index === 0) {
  //     setPrevDisable(true)
  //   } else {
  //     setPrevDisable(false)
  //   }
  //   if (index === tableData.length - 1 || index > tableData.length) {
  //     setNextDisable(true)
  //   } else {
  //     setNextDisable(false)
  //   }
  // }

  const getNextTarget = currTarget => {
    return null
    // if (!nextDisable) {
    //   const index = getIdxByTargetId(currTarget.id) + 1

    //   if (index < tableData.length) {
    //     setSelectedTarget({ id: tableData[index].targetId })
    //     setPeakSd(null)
    //   }
    //   checkIdx(index)
    // }
  }

  const getPrevTarget = currTarget => {
    return null
    // if (!prevDisable) {
    //   const index = getIdxByTargetId(currTarget.id) - 1

    //   if (index > -1) {
    //     setSelectedTarget({ id: tableData[index].targetId })
    //     setPeakSd(null)
    //   }

    //   checkIdx(index)
    // }
  }

  console.log(tableData, 'table data')
  return (
    <div>
      <ResponseGraphComp
        visible={visible}
        setVisible={setVisible}
        selectedTarget={selectedTarget}
        defaultDate={dateRange}
        setDefaultDate={setDateRange}
        refetchData={handleCloseLineDrawer}
        peakSd={peakSd}
        from="peakEqui"
        getNextTarget={getNextTarget}
        getPrevTarget={getPrevTarget}
        nextDisable={nextDisable}
        prevDisable={prevDisable}
      />

      {peakEquiLoading || equiLoading ? (
        <LoadingComponent />
      ) : tableData.length > 0 ? (
        <div
          key={Math.random()}
          className="response-rate-table"
          style={{ margin: '10px 0px 10px 10px' }}
        >
          <Table
            columns={columns}
            dataSource={tableData}
            bordered
            rowKey={e => e.targetId}
            expandIcon={() => {
              return null
            }}
            pagination={false}
            defaultExpandAllRows
            size="middle"
            scroll={{ x: daysList.length * 100 + 300, y: '1000px' }}
          />
        </div>
      ) : (
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          No data found, try to remove filter or change date range
        </div>
      )}
    </div>
  )
})
