import React, { useState } from 'react'
import {
  Divider,
  Form,
  Button,
  Tooltip,
  Icon,
  Table,
  Row,
  Col,
  Popconfirm,
  Tag,
  Modal,
  Collapse,
} from 'antd'
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { COLORS } from 'assets/styles/globalStyles'
import Sortable from 'react-sortablejs'
import LoadingComponent from 'components/LoadingComponent'
import MasterySequence from '../MasterySequence'
import StimulusModal from '../stimulusModal'
import { useTargetAlloc } from '../context'
import SbtStepModal from '../sbtStepModal'

const { Panel } = Collapse

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const cardStyle = {
  padding: '12px',
  marginBottom: 10,
  boxShadow: '0 3px 5px rgba(22, 22, 53, 0.16)',
  cursor: 'pointer',
}

const capitalizeFirstLetter = string => {
  if (string) {
    string = string?.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return ''
}

const SdStepCard = ({
  form,
  stimulusList = [],
  stepList = [],
  sbtStepsList = [],
  setSbtStepsList,
  setStepList,
  setStimulusList,
  sbtStep = false,
  sd = false,
  step = false,
  sbtReinforcersData,
  sbtStatusData,
}) => {
  const { getFieldValue } = form
  const [stimulusObject, setStimulusObject] = useState(null)
  const [stepObject, setStepObject] = useState(null)
  const [addStepModal, setAddStepModal] = useState(false)
  const [addstimulusModal, setAddStimulusModal] = useState(false)
  const [sbtStepObject, setSbtStepObject] = useState(null)
  const [addsbtStepModal, setAddsbtStepModal] = useState(false)

  const {
    mastSeqTemplate,
    promptData,
    targetStatusMapping,
    profileMstLoading,
    promptLoading,
  } = useTargetAlloc()

  const sdDocCol = [
    {
      title: 'File Name',
      dataIndex: 'url',
      render: url => {
        if (url) {
          const tt = String(url).split('/')
          let name = tt[tt.length - 1]
          name = name.replace(/%/gi, ' ')
          return name
        }
        return ''
      },
    },
    {
      title: 'Action',
      align: 'center',
      render: row => (
        <>
          <Tooltip title="View document">
            <a
              style={{
                padding: '0 14px',
                textAlign: 'center',
                display: 'inline-block',
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={row?.url}
            >
              <EyeOutlined style={{ fontSize: 15, fontWeight: 600, color: COLORS.primary }} />
            </a>
          </Tooltip>
        </>
      ),
    },
  ]

  const sbtStepsMastery = [
    {
      title: 'From Status',
      dataIndex: 'fromStatus',
      render: id => {
        if (sbtStatusData?.getSbtStepStatus) {
          const st = sbtStatusData.getSbtStepStatus
          for (let i = 0; i < st.length; i += 1) {
            if (st[i].id === id) {
              return st[i].statusName
            }
          }
        }
        return id
      },
      key: 'fromStatus',
    },
    {
      title: 'To Status',
      dataIndex: 'toStatus',
      render: id => {
        if (sbtStatusData?.getSbtStepStatus) {
          const st = sbtStatusData.getSbtStepStatus
          for (let i = 0; i < st.length; i += 1) {
            if (st[i].id === id) {
              return st[i].statusName
            }
          }
        }
        return id
      },
      key: 'toStatus',
    },
    {
      title: 'Problem Behavior',
      dataIndex: 'noOfProblemBehavior',
      key: 'noOfProblemBehavior',
      render: (text, row) => {
        if (row.lte === 100) {
          return `>= ${text}`
        }
        if (row.gte === 0) {
          return `<= ${text}`
        }
        return text
      },
    },
    {
      title: 'Days',
      dataIndex: 'days',
      key: 'days',
      render: (text, row) =>
        `${text || 0} ${row.masteryType ? ` - ${capitalizeFirstLetter(row.masteryType)}` : ''}`,
    },
    {
      title: 'Min Trials',
      dataIndex: 'minTrial',
      key: 'minTrial',
    },
  ]

  const sbtRow = item => (
    <div>
      <Row gutter={[24, 12]}>
        <Col span={10}>
          <div>
            <b> Status: </b>
            <span>
              {sbtStatusData?.getSbtStepStatus.filter(pr => pr.id === item.status)[0]?.statusName}
            </span>
          </div>
        </Col>
        <Col span={10}>
          <div>
            <b> Mastery Criteria: </b>
            {item.masteryCriteria ? <span>Manual Mastery</span> : <span>Manual Mastery</span>}
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 12]}>
        <Col span={22}>
          <b> Prompt: </b>
          <span>
            {item.prompt.map(pp => {
              const pName = sbtReinforcersData?.getSbtReinforces.filter(pr => pr.id === pp)[0]?.name
              if (pName) {
                return (
                  <Tag color="geekblue" key={pName}>
                    {pName}
                  </Tag>
                )
              }
              return null
            })}
          </span>
        </Col>
      </Row>
      {item.manualMastery && item.manualMastery.length > 0 && (
        <div className="mastery-criteria-table sd-mastery-collapse">
          <Table
            size="small"
            bordered
            loading={promptLoading}
            dataSource={item.manualMastery}
            columns={sbtStepsMastery}
            pagination={false}
            rowKey={record => record.id}
          />
        </div>
      )}
    </div>
  )

  const sdStepRow = item => (
    <div>
      <Row gutter={[24, 12]}>
        <Col span={10}>
          <div>
            <b> Status: </b> <span>{targetStatusMapping[item.status]}</span>
          </div>
        </Col>
        <Col span={10}>
          <div>
            <b> Mastery Criteria: </b>
            <span>
              {mastSeqTemplate.filter(mt => mt.id === item.masteryCriteria)[0]?.name ||
                'Manual Mastery'}
            </span>
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 12]}>
        <Col span={22}>
          <b> Prompt: </b>
          <span>
            {item.prompt.map(pp => {
              const pName = promptData?.promptCodes.filter(pr => pr.id === pp)[0]?.promptName
              if (pName) {
                return (
                  <Tag color="geekblue" key={pName}>
                    {pName}
                  </Tag>
                )
              }
              return null
            })}
          </span>
        </Col>
      </Row>
      <div className="mastery-criteria-table sd-mastery-collapse">
        <Collapse>
          <Panel key="sdSeqPanel" header={<span style={{ fontSize: 14 }}>Mastey Sequence </span>}>
            {profileMstLoading ? (
              <LoadingComponent />
            ) : (
              <MasterySequence
                showAddCriteriaButton={false}
                mstSeqObj={item.manualMastery}
                manualMastActive={false}
              />
            )}
          </Panel>
        </Collapse>
      </div>
    </div>
  )

  const stimulusCard = (renderArray, setFunction, type) => {
    return (
      <Sortable
        key={Math.random()}
        options={{
          store: {
            set(sortable) {
              let i = 0
              const list = []
              for (i = 0; i < sortable.el.childNodes.length; i += 1) {
                list.push(`${sortable.el.childNodes[i].id}`)
              }

              if (list.length === renderArray.length) {
                const nl = list.map(ke => {
                  let temp = {}
                  for (let j = 0; j < renderArray.length; j += 1) {
                    if (ke === String(renderArray[j].key)) {
                      temp = renderArray[j]
                    }
                  }
                  return temp
                })
                setFunction(nl)
              } else {
                setFunction([...renderArray])
              }
            },
          },
        }}
        tag="div"
      >
        {renderArray.map(item => {
          return (
            <div key={item.key} id={item.key} style={cardStyle}>
              <div className="flexCenterJust">
                <span style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>{item.name}</span>
                <div style={{ minWidth: 70 }}>
                  <Button
                    style={{ color: '#46bd84', paddingRight: 0 }}
                    type="link"
                    onClick={() => {
                      if (type === 'sbt') {
                        setSbtStepObject(item)
                        setAddsbtStepModal(true)
                      } else if (type === 'sd') {
                        setStimulusObject(item)
                        setAddStimulusModal(true)
                      } else if (type === 'step') {
                        setStepObject(item)
                        setAddStepModal(true)
                      }
                    }}
                  >
                    <EditOutlined />
                  </Button>
                  <Popconfirm
                    title="Are you sure you don't want this record?"
                    onConfirm={() => {
                      if (type === 'sbt') {
                        setSbtStepsList(state => state.filter(ph => ph.key !== item.key))
                      } else if (type === 'sd') {
                        setStimulusList(state => state.filter(ph => ph.key !== item.key))
                      } else {
                        setStepList(state => state.filter(ph => ph.key !== item.key))
                      }
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{ marginLeft: 'auto', color: COLORS.danger, paddingRight: 0 }}
                      type="link"
                    >
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              </div>
              {type === 'sbt' ? sbtRow(item) : sdStepRow(item)}
              {item.attachments && item.attachments.length > 0 && (
                <div className="mastery-criteria-table" style={{ width: '100%', marginTop: 8 }}>
                  <Table
                    size="small"
                    bordered
                    dataSource={item.attachments}
                    columns={sdDocCol}
                    pagination={false}
                    rowKey={record => record.url}
                  />
                </div>
              )}
            </div>
          )
        })}
      </Sortable>
    )
  }

  return (
    <div>
      {sbtStep && (
        <div style={boxCard}>
          <div>
            <Divider orientation="left">Step</Divider>
            {stimulusCard(sbtStepsList, setSbtStepsList, 'sbt')}
            <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
              <Button
                type="dashed"
                onClick={() => {
                  setAddsbtStepModal(true)
                  setSbtStepObject(null)
                }}
                style={{ width: '60%' }}
              >
                <Icon type="plus" /> Add field
              </Button>
            </Form.Item>
          </div>
        </div>
      )}
      {(sd || step) && (
        <div style={boxCard}>
          {sd && (
            <div>
              <Divider orientation="left">Stimulus</Divider>
              {stimulusCard(stimulusList, setStimulusList, 'sd')}
              {stepList?.length > 0 ? (
                <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
                  <Button type="dashed" disabled style={{ width: '60%' }}>
                    <Icon type="plus" /> Add field
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
                  <Button
                    type="dashed"
                    onClick={() => {
                      setAddStimulusModal(true)
                      setStimulusObject(null)
                      if (setStepList) {
                        setStepList([])
                      }
                    }}
                    style={{ width: '60%' }}
                  >
                    <Icon type="plus" /> Add field
                  </Button>
                </Form.Item>
              )}
            </div>
          )}

          {step && (
            <div>
              <Divider orientation="left">Step</Divider>
              {stimulusCard(stepList, setStepList, 'step')}
              {stimulusList?.length > 0 ? (
                <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
                  <Button type="dashed" disabled style={{ width: '60%' }}>
                    <Icon type="plus" /> Add field
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
                  <Button
                    type="dashed"
                    onClick={() => {
                      setAddStepModal(true)
                      setStepObject(null)
                      if (setStimulusList) {
                        setStimulusList([])
                      }
                    }}
                    style={{ width: '60%' }}
                  >
                    <Icon type="plus" /> Add field
                  </Button>
                </Form.Item>
              )}
            </div>
          )}
        </div>
      )}

      <Modal
        visible={addsbtStepModal}
        title={sbtStepObject ? 'Edit Step' : 'Add New Sbt Step'}
        onCancel={() => {
          setAddsbtStepModal(false)
          setSbtStepObject(null)
          setStimulusObject(null)
        }}
        zIndex={1001}
        footer={null}
        width={1000}
        destroyOnClose
        style={{ top: 10 }}
      >
        <SbtStepModal
          type="sbt"
          setStimulusList={setSbtStepsList}
          stimulusList={sbtStepsList}
          stimulusObject={sbtStepObject}
          closeModal={() => {
            setAddsbtStepModal(false)
            setSbtStepObject(null)
            setStimulusObject(null)
          }}
          defaultValues={{
            status: getFieldValue('status'),
            prompt: getFieldValue('prompt'),
            masteryCriteria: getFieldValue('masteryCriteria'),
          }}
        />
      </Modal>

      <Modal
        visible={addstimulusModal}
        title={stimulusObject ? 'Edit Stimulus' : 'Add New Stimulus'}
        onCancel={() => {
          setAddStimulusModal(false)
          setStimulusObject(null)
        }}
        zIndex={1001}
        footer={null}
        width={1000}
        style={{ top: 10 }}
        destroyOnClose
      >
        <StimulusModal
          type="sd"
          setStimulusList={setStimulusList}
          stimulusList={stimulusList}
          stimulusObject={stimulusObject}
          closeModal={() => {
            setAddStimulusModal(false)
            setStimulusObject(null)
          }}
          defaultValues={{
            status: getFieldValue('status'),
            prompt: getFieldValue('prompt'),
            masteryCriteria: getFieldValue('masteryCriteria'),
          }}
        />
      </Modal>

      <Modal
        visible={addStepModal}
        title={stepObject ? 'Edit Step' : 'Add New Step'}
        onCancel={() => {
          setAddStepModal(false)
          setStepObject(null)
        }}
        zIndex={1001}
        footer={null}
        width={1000}
        style={{ top: 10 }}
        destroyOnClose
      >
        <StimulusModal
          type="step"
          setStimulusList={setStepList}
          stimulusList={stepList}
          stimulusObject={stepObject}
          closeModal={() => {
            setAddStepModal(false)
            setStepObject(null)
          }}
          defaultValues={{
            status: getFieldValue('status'),
            prompt: getFieldValue('prompt'),
            masteryCriteria: getFieldValue('masteryCriteria'),
          }}
        />
      </Modal>
    </div>
  )
}

export default SdStepCard
