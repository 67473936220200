/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */

import {
  AimOutlined,
  AlignLeftOutlined,
  AreaChartOutlined,
  FieldTimeOutlined,
  LayoutOutlined,
  MehOutlined,
  ProfileOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Collapse,
  Form,
  Layout,
  Menu,
  Row,
  Select,
  Typography,
  Breadcrumb,
} from 'antd'
import { gql } from 'apollo-boost'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import StaffSelect from 'components/StaffSelect'
import VBMappReport from 'components/VBMappReport'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import Moment from 'moment'
import React, { useState, useEffect } from 'react'
import StaffHeaderComponent from 'components/StaffHeaderComponent'
import { Helmet } from 'react-helmet'
import { connect, useSelector, useDispatch } from 'react-redux'
import client from '../../apollo/config'
import Appointments from './appointments'
import WorkLog from './WorkLog'

import Attendance from './attendance'
import Behavior from './BehaviorReport/behavior'
import CelerationChartPanel from './celeration-chart-panel.container'
import DailyResponseRate from './dailyResponseRate'
import Goals from './goals'
import IEPReport from './IEPReport'
import Mand from './mand'
import MonthlyReport from './monthly-reports/monthlyReport'
import NetworkSankey from './networkSankey'
import './padding.scss'
import PeakBlockReport from './peakBlockReport'
import ProgressOverview from './progressOverview/index'
import Sankey from './sankey'
import Sessions from './sessionReport/sessions'
import TargetResponseReport from './targetResponseReport/targetResponseReport'
import Timesheet from './timesheet'
import Attendance2 from './oldAttendence'
import HeaderComponent from '../../components/HeaderComponentNew'
import Timesheet2 from './timesheet2'

const { Title, Text } = Typography

const STAFF_LIST = gql`
  query($isActive: Boolean) {
    staffs(isActive: $isActive) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const parentCardStyle = {
  background: COLORS.palleteLight,
  borderRadius: 10,
  padding: '10px',
  margin: '7px 0 0 10px',
  height: 300,
  overflow: 'hidden',
}

const REPORT_PATHS = {
  PROGRESS_OVERVIEW: '/reports/progress_overview',
  DAILY_RESPONSE_REPORT: '/reports/daily_res_rate',
  BEHAVIOR: '/reports/behavior',
  MAND: '/reports/mand',
  SESSIONS: '/reports/sessions',
  GOALS: '/reports/goals',
  IEP_REPORTS: '/reports/iep_report',
  CELER_CHART: '/reports/celer_chart',
  APPOINTMENT_REPORT: '/reports/appointment_report',
  STAFF_ACTIVITY: '/reports/staff_activity',
  ATTENDANCE: '/reports/attendance',
  ATTENDANCE2: '/reports/attendance2',
  TIMESHEET: '/reports/timesheet',
  TIMESHEET2: '/reports/timesheet2',
  WORKLOG: '/reports/worklog',
  MONTHLY_REPORT: '/reports/monthly_report',
  VBMAPP: '/reports/vbmapp',
  PEAK: '/reports/peak_block_report',
  TARGET_RESPONSE: '/reports/target_res_report',
  NETWORK_GRAPH: '/reports/network_graph',
  SANKEY_GRAPH: '/reports/sankey_graph',
}

const REPORT_MAPPING = {
  [REPORT_PATHS.PROGRESS_OVERVIEW]: 'Progress Overview',
  [REPORT_PATHS.DAILY_RESPONSE_REPORT]: 'Daily Response Rate',
  [REPORT_PATHS.BEHAVIOR]: 'Behavior',
  [REPORT_PATHS.MAND]: 'Mand',
  [REPORT_PATHS.SESSIONS]: 'Sessions',
  [REPORT_PATHS.GOALS]: 'Goals',
  [REPORT_PATHS.IEP_REPORTS]: 'Goal Report',
  [REPORT_PATHS.CELER_CHART]: 'Celeration Chart',
  [REPORT_PATHS.APPOINTMENT_REPORT]: 'Appointment',
  [REPORT_PATHS.STAFF_ACTIVITY]: 'Staff Activity',
  [REPORT_PATHS.ATTENDANCE]: 'Attendance',
  [REPORT_PATHS.ATTENDANCE2]: 'Attendance 2',
  [REPORT_PATHS.TIMESHEET]: 'Timesheet',
  [REPORT_PATHS.TIMESHEET2]: 'Timesheet 2',
  [REPORT_PATHS.WORKLOG]: 'WorkLog',
  [REPORT_PATHS.MONTHLY_REPORT]: 'Monthly Report',
  [REPORT_PATHS.VBMAPP]: 'VBMAPP',
  [REPORT_PATHS.PEAK]: 'Coded',
  [REPORT_PATHS.TARGET_RESPONSE]: 'Target Response',
  [REPORT_PATHS.NETWORK_GRAPH]: 'Network Graph',
  [REPORT_PATHS.SANKEY_GRAPH]: 'Sankey Graph',
}

// list to exclude learner's names from report title
const Reports = props => {
  const user = useSelector(state => state.user)
  const { StudentName } = useSelector(state => state.student)
  const learnersprogram = useSelector(state => state.learnersprogram)
  const { Learners, SelectedLearnerId } = learnersprogram

  const dispatch = useDispatch()

  const [visibleFilter, setVisibleFilter] = useState(false)
  const [TabCheck, setTabCheck] = useState('Progress Overview')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [staffs, setStaffs] = useState([])
  const [selectedStaff, setSelectedStaff] = useState({ id: '', name: '' })
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (learnersprogram && learnersprogram.Learners?.length === 0) {
      dispatch({
        type: 'learnersprogram/LOAD_DATA',
      })
    }
    let std = localStorage.getItem('studentId')
    if (std) {
      std = JSON.parse(std)
      dispatch({
        type: 'student/STUDENT_DETAILS',
      })
      dispatch({
        type: 'learnersprogram/SET_STATE',
        payload: {
          SelectedLearnerId: std,
        },
      })
    } else {
      dispatch({
        type: 'student/SET_STATE',
        payload: {
          StudentName: '',
        },
      })
    }
    window.addEventListener('resize', updateWindowDimensions)

    if (user.role === 'school_admin') {
      client
        .query({
          query: STAFF_LIST,
          variables: {
            isActive: true,
          },
          fetchPolicy: 'network-only',
        })
        .then(res => {
          if (res.data?.staffs.edges && res.data.staffs.edges.length > 0) {
            setStaffs(res.data.staffs.edges)
            setSelectedStaff(res.data.staffs.edges[0].node)
          } else {
            setStaffs([])
            setSelectedStaff(null)
          }
        })
    } else {
      setSelectedStaff({ id: user.staffId, name: user.staffName })
    }
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  useEffect(() => {
    if (user.staffName) {
      setSelectedStaff({ id: user.staffId, name: user.staffName })
    }
  }, [user.staffName])

  useEffect(() => {
    if (props.match.path && props.match.path !== '/reports') {
      setTabCheck(REPORT_MAPPING[props.match.path])
    }
  }, [props.match.path])

  useEffect(() => {
    if (TabCheck === 'Staff Activity' && user?.role === 'parents') {
      setTabCheck('Progress Overview')
    }
  }, [user.role])

  const showDrawerFilter = () => {
    setVisibleFilter(true)
  }

  const updateWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
  }
  const std = localStorage.getItem('studentId')

  const { path } = props.match
  if (
    !std &&
    Learners.length < 1 &&
    path !== '/reports/attendance2' &&
    path !== '/reports/timesheet' &&
    path !== '/reports/appointment_report' &&
    path !== '/reports/worklog'
  ) {
    return <p>No learners to display reports</p>
  }

  return (
    <>
      <Helmet title="Reports" />
      <Layout style={{ padding: '0', marginBottom: '30px', backgroundColor: COLORS.bodyGrey }}>
        {TabCheck === 'Timesheet2' ||
        TabCheck == 'WorkLog' ||
        path == '/reports/worklog' ||
        path === '/reports/attendance' ? (
          <StaffHeaderComponent title={TabCheck} />
        ) : (
          <HeaderComponent
            title={TabCheck}
            showHomePath={false}
            learnerCard={
              path !== '/reports/attendance2' &&
              path !== '/reports/timesheet' &&
              path !== '/reports/appointment_report'
            }
            changeLearner={visible}
          />
        )}
        <div
          style={{
            maxWidth: '120rem',
            marginInline: 'auto',
            marginTop: '1rem',
            padding: '0.5rem',
            borderRadius: '0.5rem',
            width: '100%',
          }}
        >
          <div>
            {(path === REPORT_PATHS.PROGRESS_OVERVIEW || path === '/reports') && (
              <ProgressOverview
                showDrawerFilter={showDrawerFilter}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.DAILY_RESPONSE_REPORT && (
              <DailyResponseRate
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                setHeader={val => {
                  setVisible(val)
                }}
              />
            )}
            {path === REPORT_PATHS.BEHAVIOR && (
              <Behavior
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.MAND && (
              <Mand showDrawerFilter={showDrawerFilter} selectedStudentId={SelectedLearnerId} />
            )}
            {path === REPORT_PATHS.SESSIONS && (
              <Sessions studentName={StudentName} showDrawerFilter={showDrawerFilter} />
            )}
            {path === REPORT_PATHS.GOALS && (
              <Goals showDrawerFilter={showDrawerFilter} selectedStudentId={SelectedLearnerId} />
            )}
            {path === REPORT_PATHS.IEP_REPORTS && (
              <IEPReport
                showDrawerFilter={showDrawerFilter}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.STAFF_ACTIVITY && (
              <div>
                <p>Under Development</p>
              </div>
            )}
            {path === REPORT_PATHS.CELER_CHART && (
              <CelerationChartPanel
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.APPOINTMENT_REPORT && (
              <Appointments
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.WORKLOG && (
              <WorkLog
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                selectedStaff={selectedStaff}
              />
            )}
            {path === REPORT_PATHS.ATTENDANCE && (
              <Attendance
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                selectedStaffId={selectedStaff}
              />
            )}
            {path === REPORT_PATHS.ATTENDANCE2 && (
              <Attendance2
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                selectedStaffId={selectedStaff}
              />
            )}
            {path === REPORT_PATHS.TIMESHEET && (
              <Timesheet
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                selectedStaff={selectedStaff}
              />
            )}
            {path === REPORT_PATHS.TIMESHEET2 && (
              <Timesheet2
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
                selectedStaff={selectedStaff}
              />
            )}
            {path === REPORT_PATHS.VBMAPP && (
              <VBMappReport
                selectedStudentId={SelectedLearnerId}
                studentName={StudentName}
                showDrawerFilter={showDrawerFilter}
              />
            )}
            {path === REPORT_PATHS.MONTHLY_REPORT && (
              <MonthlyReport
                showDrawerFilter={showDrawerFilter}
                studentName={StudentName}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.PEAK && (
              <PeakBlockReport
                showDrawerFilter={showDrawerFilter}
                studentName={StudentName}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.TARGET_RESPONSE && (
              <TargetResponseReport
                showDrawerFilter={showDrawerFilter}
                studentName={StudentName}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.NETWORK_GRAPH && (
              <NetworkSankey
                showDrawerFilter={showDrawerFilter}
                studentName={StudentName}
                selectedStudentId={SelectedLearnerId}
              />
            )}
            {path === REPORT_PATHS.SANKEY_GRAPH && (
              <Sankey
                showDrawerFilter={showDrawerFilter}
                studentName={StudentName}
                selectedStudentId={SelectedLearnerId}
              />
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Form.create()(Reports)
