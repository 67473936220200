/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import groupObj from '@hunters/group-object'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Menu,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd'
import { gql } from 'apollo-boost'
import { useQuery } from 'react-apollo'
import { useSelector, useDispatch } from 'react-redux'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import domtoimage from 'dom-to-image'
import * as FileSaver from 'file-saver'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import Moment from 'moment'
import React, { useState, useEffect } from 'react'
import { FaDownload } from 'react-icons/fa'
import * as XLSX from 'xlsx'
import client from '../../apollo/config'
import actionLearners from '../../redux/learners/actions'
import GoalsPdf from './pdf/GoalsPdf'
import './table.scss'
import './styles.scss'
import GoalsPdfSelect from './GoalsPdfSelect'

const { Option } = Select

const columns = [
  {
    title: 'Goal Name',
    dataIndex: 'goalName',
    key: 'goalName',
    render: (text, record) => {
      if (record.isLongTermGoal) {
        return <span style={{ color: COLORS.stimulus }}>{text}</span>
      }
      return text
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Program Area',
    dataIndex: 'program',
    key: 'program',
    width: '160px',
  },
  {
    title: 'Responsibility',
    dataIndex: 'responsibility',
    key: 'responsibility',
    width: '140px',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '140px',
  },
  {
    title: 'Created',
    dataIndex: 'dateInitialted',
    key: 'dateInitialted',
    width: '120px',
  },
  {
    title: 'End',
    dataIndex: 'dateEnd',
    key: 'dateEnd',
    width: '120px',
  },
]
const array = []

const ACTIVE_PROGRAM_AREA = gql`
  query {
    programArea(isActive: true) {
      edges {
        node {
          id
          name
        }
      }
    }
    goalStatus {
      id
      status
    }
  }
`
const FETCH_DATA = gql`
  query longTerm($selectedStudentId: ID!) {
    longTerm(student: $selectedStudentId) {
      edges {
        node {
          id
          goalName
          description
          dateInitialted
          dateEnd
          isDefault
          responsibility {
            id
            name
          }
          program {
            id
            name
            isActive
          }
          goalStatus {
            id
            status
          }
          shorttermgoalSet {
            edges {
              node {
                id
                goalName
                dateInitialted
                dateEnd
                description
                responsibility {
                  id
                  name
                }
                goalStatus {
                  id
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`
const Goals = ({ selectedStudentId }) => {
  const dispatch = useDispatch()

  const learners = useSelector(state => state.learners)
  const { StudentName } = useSelector(state => state.student)

  const [goalStatus, setGoalStatus] = useState([])
  const [statusselected, setStatusselected] = useState(null)
  const [typeSelected, setTypeSelected] = useState('1')
  const [graphData, setGraphData] = useState(null)
  const [barGraphData, setBarGraphData] = useState(null)
  const [barGraphKeys, setBarGraphKeys] = useState([])
  const [tableData, setTableData] = useState([])
  const [allGraphData, setAllGraphData] = useState(null)
  const [allTableData, setAllTableData] = useState([])
  const [data, setData] = useState(null)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [goalsPdf, setGoalsPdf] = useState(false)
  const [goalsPdfSelect, setGoalsPdfSelect] = useState(false)
  const [responsivePie, setResponsivePie] = useState('')
  const [responsiveBar, setResponsiveBar] = useState('')
  const [pdfList, setPdfList] = useState([])
  const [finalTable, setFinalTable] = useState([])
  const [selectedProgramArea, setSelectedProgramArea] = useState([])
  const { data: allData, loading } = useQuery(ACTIVE_PROGRAM_AREA, {
    fetchPolicy: 'network-only',
  })

  const studentId = localStorage.getItem('studentId')
  useEffect(() => {
    if (allData) {
      const len = allData.programArea.edges.length

      const goalsStatus = []
      if (allData.goalStatus && allData.goalStatus.length > 0) {
        allData.goalStatus.map(item => {
          if (item.status !== 'Discontinued') {
            if (goalsStatus.indexOf(item.status) === -1) {
              goalsStatus.push(item.status)
            }
          }
          return goalsStatus
        })
        setGoalStatus(goalsStatus)
      }
    }
  }, [allData])

  useEffect(() => {
    if (studentId) {
      fetchData(studentId)
    }
    if (learners.LearnersList.length <= 0) {
      dispatch({
        type: actionLearners.GET_DATA,
      })
    }
  }, [])

  useEffect(() => {
    if (selectedStudentId) {
      fetchData(studentId)
    }
  }, [selectedStudentId])

  useEffect(() => {
    if (data) {
      const tableDataObj = prepareData(data, typeSelected)
      let filteredTableData = tableDataObj.filter(item => item.status !== 'Discontinued')
      if (statusselected) {
        filteredTableData = tableDataObj.filter(item => item.status === statusselected)
      }
      if (selectedProgramArea.length) {
        filteredTableData = filteredTableData.filter(item =>
          selectedProgramArea.includes(item.programId),
        )
      }
      const groupedData = groupObj.group(filteredTableData, 'status')
      let keys = []
      const gData = []
      keys = Object.keys(groupedData)
      keys.map((status, index) => {
        if (groupedData[status]?.length > 0) {
          gData.push({
            id: status,
            label: status,
            value: groupedData[status].length,
          })
        } else {
          gData.push({ id: status, label: status, value: 0 })
        }
        return gData
      })
      let barGraphData1

      const filteredGraphData = gData
      if (gData)
        barGraphData1 = filteredGraphData.map((item, index) => {
          return { [item.label]: item.value, id: item.label }
        })
      const barGraphKeys1 = Array.from(new Set(filteredGraphData.map(item => item.label)).values())

      setGraphData(filteredGraphData)
      setAllGraphData(gData)
      setBarGraphData(barGraphData1)
      setBarGraphKeys(barGraphKeys1)
      setTableData(filteredTableData)
      setFinalTable(filteredTableData)
      setAllTableData(tableDataObj)
      // setData(data)
      setIsDataLoading(false)
    }
  }, [data, selectedProgramArea, statusselected, typeSelected])

  const handleMultiSelect = e => {
    setSelectedProgramArea(e)
  }

  const filterWithStatus = status => {
    setStatusselected(status)
  }

  const typeCallback = type => {
    setTypeSelected(type.target.value)
  }

  const StatusCallback = status => {
    filterWithStatus(status)
    setStatusselected(status)
  }

  const responsivePieImage = () => {
    const responsivePieCanvas = document.querySelector('#responsivePie')
    domtoimage
      .toPng(responsivePieCanvas)
      .then(dataUrl => {
        setResponsivePie(dataUrl)
      })
      .catch(function(error) {
        console.error('oops, something went wrong!', error)
      })
  }

  const responsiveBarImage = () => {
    const responsiveBarCanvas = document.querySelector('#responsiveBar')
    const ff = html2canvas(responsiveBarCanvas).then(canvas => canvas.toDataURL('image/png'))
    ff.then(res => {
      setResponsiveBar(res)
    }).catch(function(error) {
      console.error('oops, something went wrong!', error)
    })
  }

  const prepareData = (datafrom, selectedType) => {
    const tableDataObj = []

    if (datafrom.longTerm.edges && datafrom.longTerm.edges.length > 0) {
      datafrom.longTerm.edges
        .filter(({ node }) => node.program.isActive)
        .map(item => {
          if (selectedType === '1' && item.node.goalStatus.status !== 'Discontinued') {
            const shorttermGoals = []
            if (item.node.shorttermgoalSet.edges && item.node.shorttermgoalSet.edges.length > 0) {
              item.node.shorttermgoalSet.edges.map(shortTermGoal => {
                if (shortTermGoal.node.goalStatus?.status !== 'Discontinued')
                  shorttermGoals.push({
                    key: shortTermGoal.node.id,
                    goalName: shortTermGoal.node.goalName,
                    description: shortTermGoal.node.description,
                    dateInitialted: shortTermGoal.node.dateInitialted,
                    dateEnd: shortTermGoal.node.dateEnd,
                    responsibility: shortTermGoal.node.responsibility?.name,
                    status: shortTermGoal.node.goalStatus?.status,
                    program: item.node.program?.name,
                    programId: item.node.program?.id,
                    isLongTermGoal: false,
                    isDefault: item.node.isDefault,
                  })
                return shorttermGoals
              })
            }
            if (shorttermGoals && shorttermGoals.length > 0) {
              tableDataObj.push({
                key: item.node.id,
                goalName: item.node.goalName,
                description: item.node.description,
                dateInitialted: item.node.dateInitialted,
                dateEnd: item.node.dateEnd,
                responsibility: item.node.responsibility?.name,
                status: item.node.goalStatus?.status,
                program: item.node.program?.name,
                programId: item.node.program?.id,
                isLongTermGoal: true,
                children: shorttermGoals,
                isDefault: item.node.isDefault,
              })
            } else {
              tableDataObj.push({
                key: item.node.id,
                goalName: item.node.goalName,
                description: item.node.description,
                dateInitialted: item.node.dateInitialted,
                dateEnd: item.node.dateEnd,
                responsibility: item.node.responsibility?.name,
                status: item.node.goalStatus?.status,
                program: item.node.program?.name,
                programId: item.node.program?.id,
                isLongTermGoal: true,
                isDefault: item.node.isDefault,
              })
            }
          } else {
            item.node.shorttermgoalSet.edges.map(shortTermGoal => {
              tableDataObj.push({
                key: shortTermGoal.node.id,
                goalName: shortTermGoal.node.goalName,
                description: shortTermGoal.node.description,
                dateInitialted: shortTermGoal.node.dateInitialted,
                dateEnd: shortTermGoal.node.dateEnd,
                responsibility: shortTermGoal.node.responsibility?.name,
                status: shortTermGoal.node.goalStatus?.status,
                program: item.node.program?.name,
                programId: item.node.program?.id,
                isLongTermGoal: false,
                isDefault: item.node.isDefault,
              })
              return tableDataObj
            })
          }
          return tableDataObj
        })
    }
    return tableDataObj
  }
  const fetchData = selStudent => {
    client
      .query({
        query: FETCH_DATA,
        variables: {
          selectedStudentId: selStudent,
        },
        fetchPolicy: 'network-only',
      })
      .then(result => {
        setData(result.data)
      })
      .catch(error => {
        setIsDataLoading(false)
        notification.error({
          message: 'Failed to fetch data',
        })
      })
  }
  const pxToMm = px => {
    return Math.floor(px / document.getElementById('capture').offsetHeight)
  }

  const exportPDF = () => {
    const input = document.getElementById('capture')
    const inputHeightMm = pxToMm(input.offsetHeight)
    const a4WidthMm = 210
    const a4HeightMm = 297
    const numPages = inputHeightMm <= a4HeightMm ? 1 : Math.floor(inputHeightMm / a4HeightMm) + 1
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      if (inputHeightMm > a4HeightMm) {
        // elongated a4 (system print dialog will handle page breaks)
        const pdf = new JsPDF('p', 'mm', [inputHeightMm + 16, a4WidthMm])
        pdf.addImage(imgData, 'PNG', 0, 0)
        pdf.save(`test.pdf`)
      } else {
        // standard a4
        const pdf = new JsPDF()
        pdf.addImage(imgData, 'PNG', 0, 0)
        pdf.save(`test.pdf`)
      }
    })
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const exportToCSV = () => {
    const filename = '_goals_excel'
    const formattedData = tableData.map(function(e) {
      return {
        Goal_Name: e.goalName,
        Description: e.description,
        Status: e.status,
        Responsibility: e.responsibility,
        Program_Area: e.program,
        Created: e.dateInitialted,
        End: e.dateEnd,
      }
    })

    const ws = XLSX.utils.json_to_sheet(formattedData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelData, StudentName + filename + fileExtension)
  }

  const id = JSON.parse(localStorage.getItem('studentId'))
  const stdIdData = learners?.LearnersList?.filter(i => {
    return i?.id === id ? i : undefined
  })

  const saveStatus = (statusrep, list) => {
    if (statusrep === 'Success') {
      handlePdfDrawerOpen(list)
    }
  }

  const handlePdfDrawerOpen = list => {
    responsivePieImage()
    responsiveBarImage()
    setGoalsPdf(true)
    setPdfList(list)
    setGoalsPdfSelect(false)
  }

  const handlePdfDrawerOpenForSelect = () => {
    setGoalsPdfSelect(true)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => exportToCSV()} type="link" size="small">
          CSV/Excel
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={handlePdfDrawerOpenForSelect} type="link" size="small">
          Export Pdf
        </Button>
      </Menu.Item>
    </Menu>
  )

  const filterCardStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    padding: '0.5rem 1rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    height: 'fit-content',
    overflow: 'hidden',
    alignItems: 'center',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  }

  const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }
  return (
    <>
      <div className="reports-filter-card">
        <div>
          <Tooltip title="Select program Area">
            <Select
              placeholder="Select Program Area"
              onChange={e => handleMultiSelect(e)}
              style={{
                width: 180,
                borderRadius: 4,
              }}
              mode="multiple"
              allowClear
              optionFilterProp="name"
              value={selectedProgramArea}
              loading={loading}
            >
              {allData &&
                allData.programArea.edges.map(({ node }) => (
                  <Option key={node.id} value={node.id} name={node.name}>
                    {node.name}
                  </Option>
                ))}
            </Select>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Select goal status">
            <Select
              placeholder="Select goal status"
              onChange={StatusCallback}
              style={{
                width: 180,
                borderRadius: 4,
              }}
              showSearch
              optionFilterProp="name"
              value={statusselected}
            >
              <Option value={null}>All</Option>
              {goalStatus &&
                goalStatus.map(node => {
                  return (
                    <Option key={node} value={node} name={node}>
                      {node}
                    </Option>
                  )
                })}
            </Select>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Select Goal Type">
            <Radio.Group
              style={{ margin: 'auto 0' }}
              value={typeSelected}
              onChange={typeCallback}
              buttonStyle="solid"
            >
              <Radio.Button value="1">Long Term</Radio.Button>
              <Radio.Button value="2">Short Term</Radio.Button>
            </Radio.Group>
          </Tooltip>
        </div>
        <div style={{ display: 'flex' }}>
          <span style={parentLabel}>Long Term Goal</span>
          <div
            style={{
              background: COLORS.stimulus,
              margin: 'auto 0',
              borderRadius: 10,
              width: 20,
              height: 18,
            }}
          />
        </div>

        <div style={{ marginLeft: 'auto' }}>
          <Dropdown overlay={menu} trigger={['hover']}>
            <Button type="link" style={{ padding: '0 8px' }} size="large">
              <FaDownload fontSize={22} />{' '}
            </Button>
          </Dropdown>
        </div>
      </div>
      <div id="capture" style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
        <div className="reports-graph-card" style={{ flex: 1, height: '400px' }}>
          <div
            role="presentation"
            id="responsivePie"
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
            }}
          >
            {graphData && graphData.length > 0 && (
              <ResponsivePie
                data={graphData}
                margin={{ top: 40, right: 20, bottom: 40, left: 0 }}
                innerRadius={0.5}
                padAngle={2}
                cornerRadius={3}
                colors={{ scheme: 'paired' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabel={function(e) {
                  return `${e.label} (${e.value})`
                }}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate
                motionStiffness={90}
                motionDamping={15}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 7,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={[
                  {
                    anchor: 'right',
                    direction: 'column',
                    translateY: 10,
                    translateX: -1,
                    itemWidth: 100,
                    itemHeight: 25,
                    itemTextColor: '#999',
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ]}
              />
            )}
            {graphData && graphData.length === 0 && <Empty style={{ marginInline: 'auto' }} />}
            {!graphData && <Spin style={{ margin: 'auto' }} />}
          </div>
        </div>
        <div
          className="reports-graph-card"
          key={Math.random()}
          style={{ flex: 1, height: '400px' }}
        >
          <div
            role="presentation"
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
            }}
            id="responsiveBar"
          >
            {graphData && graphData.length > 0 && (
              <ResponsiveBar
                id={Math.random()}
                key={Math.random()}
                data={barGraphData}
                indexBy="id"
                keys={barGraphKeys}
                margin={{ top: 15, right: 20, bottom: 30, left: 60 }}
                padding={0.15}
                colors={{ scheme: 'paired' }}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Number of goals',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate
                motionStiffness={90}
                motionDamping={15}
              />
            )}
            {!graphData && <Spin style={{ margin: 'auto' }} />}
            {graphData && graphData.length === 0 && <Empty style={{ marginInline: 'auto' }} />}
          </div>
        </div>
      </div>
      <div className="reports-table-card">
        <Table
          className="allReportTable"
          columns={columns}
          dataSource={tableData}
          bordered
          loading={isDataLoading}
          scroll={{ x: 1200 }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            position: 'top',
          }}
        />
      </div>
      <Modal
        visible={goalsPdfSelect}
        onCancel={() => setGoalsPdfSelect(false)}
        title="Select Content"
        footer=""
        destroyOnClose
      >
        <GoalsPdfSelect
          closeDrawer={() => setGoalsPdfSelect(false)}
          fromComponent="Goals"
          saveStatus={saveStatus}
        />
      </Modal>

      <Drawer
        width={DRAWER.widthL2}
        title="Goals PDF"
        closable
        onClose={() => setGoalsPdf(false)}
        destroyOnClose
        visible={goalsPdf}
      >
        <GoalsPdf
          data={stdIdData}
          statusselected={statusselected}
          typeSelected={typeSelected}
          responsivePie={responsivePie}
          responsiveBar={responsiveBar}
          tableData={tableData}
          pdfList={pdfList}
          closeDrawer={() => setGoalsPdf(false)}
        />
      </Drawer>
    </>
  )
}

export default Form.create()(Goals)
