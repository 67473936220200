import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import LoadingComponent from 'components/LoadingComponent'
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { useLazyQuery } from 'react-apollo'
import AddCriteriaForm from './addCriteriaForm'
import CriteriaCard from './criteriaCard'
import { useMasterySeq } from './context'
import { GET_TEMPLATE_BY_ID } from './query'

function TemplateForm({ setEditTemplate, edit = false }) {
  const [fetchData, { data: ftData, loading, error, refetch }] = useLazyQuery(GET_TEMPLATE_BY_ID, {
    fetchPolicy: 'network-only',
  })

  const [showForm, setShowForm] = useState(false)
  const { setSelectedTemplate, selectedTemplate, phaseSet, setPhaseSet } = useMasterySeq()

  useEffect(() => {
    if (selectedTemplate) {
      fetchData({
        variables: {
          id: selectedTemplate.id,
        },
      })
    }
  }, [selectedTemplate])

  useEffect(() => {
    if (ftData && ftData.getCriteriaTemplate) {
      setPhaseSet(ftData.getCriteriaTemplate.criteriaphasesSet.edges)
    }
  }, [ftData])

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return (
    <div>
      <div className="template-form-head mastery-p">
        <div className="flexCenterJust" style={{ color: 'black' }}>
          <Button
            type="link"
            style={{ padding: '0 8px 0 0' }}
            onClick={() => setSelectedTemplate(null)}
          >
            <ArrowLeftOutlined style={{ fontSize: 24, color: 'black' }} />
          </Button>
          <div style={{ fontSize: '24px', marginRight: 'auto' }}>{selectedTemplate?.name}</div>
          {edit && (
            <Button
              type="link"
              onClick={e => {
                e.stopPropagation()
                setEditTemplate(selectedTemplate)
              }}
            >
              <EditOutlined />
            </Button>
          )}
        </div>
        <p style={{ fontSize: '12px' }}>{selectedTemplate?.description}</p>
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div>
          {phaseSet.length > 0 && (
            <div>
              {phaseSet.map(item => {
                return (
                  <CriteriaCard
                    edit={edit}
                    refetchDetails={refetch}
                    key={item.node.id}
                    details={item.node}
                  />
                )
              })}
            </div>
          )}
          {edit && (
            <div>
              {showForm ? (
                <AddCriteriaForm onSuccess={res => refetch()} setShowForm={setShowForm} />
              ) : (
                <div className="add-phase-button" style={{ display: 'flex', width: '100%' }}>
                  <Button
                    type="dashed"
                    icon="plus"
                    size="large"
                    onClick={() => setShowForm(true)}
                    style={{ width: '60%', margin: 'auto', backgroundColor: 'transparent' }}
                  >
                    Add Phase Criteria
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TemplateForm
