import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Drawer, Menu } from 'antd'
import Scrollbar from 'react-custom-scrollbars'
import HeaderComponent from 'components/HeaderComponentNew'
import MLTab from 'components/ML/MLTab'
import CustomProfileLayout from './Clinic/CustomLayout'
import DateRangeSetting from './dateRangeSetting'
import PromptSetting from './PromptSetting'
import MandInstruction from './MandInstruction'

const tabs = [
  {
    key: 'date-range-setting',
    title: 'Date Range Setting',
    component: <DateRangeSetting />,
    // iconName: <ProfileOutlined />,
  },
  {
    key: 'prompt-setting',
    title: 'Prompt Setting',
    component: <PromptSetting />,
    // iconName: <ProfileOutlined />,
  },
  {
    key: 'Ml-job',
    title: 'Machine Learning',
    component: <MLTab />,
    // iconName: <ProfileOutlined />,
  },
  {
    key: 'mand',
    title: 'Mand Instructions',
    component: <MandInstruction />,
    // iconName: <ProfileOutlined />,
  },
]

const OthersLanding = props => {
  const [rightComponent, setRightComponent] = useState(<DateRangeSetting />)
  const [panelHeading, setPanelHeading] = useState('Date Range Setting')

  const handleMenuChange = item => {
    const selectedItem = tabs.find(tab => tab.key === item.key)
    setPanelHeading(selectedItem.title)
    if (selectedItem) setRightComponent(selectedItem.component)
  }

  return (
    <>
      <CustomProfileLayout
        leftContent={
          <>
            <Menu
              style={{ height: 'calc(100vh - 175px)' }}
              onSelect={handleMenuChange}
              mode="inline"
              defaultSelectedKeys={['date-range-setting']}
            >
              {tabs.map(tab => {
                if (!tab.submenu) {
                  return (
                    <Menu.Item key={tab.key}>
                      {/* {tab.iconName} */}
                      <span>{tab.title}</span>
                    </Menu.Item>
                  )
                }
                return (
                  <Menu.SubMenu
                    title={
                      <span>
                        {tab.iconName}
                        <span>{tab.title}</span>
                      </span>
                    }
                    key={tab.key}
                    onTitleClick={() => handleMenuChange({ key: tab.key })}
                  >
                    {tab.submenu.map(label => (
                      <Menu.Item key={label[0]}>
                        {label[1]} <span>{label[0]}</span>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                )
              })}
            </Menu>
          </>
        }
        rightContent={
          <>
            <HeaderComponent
              title={panelHeading}
              showHomePath={false}
              pathList={['Other Settings']}
              learnerCard={panelHeading === 'Mand Instructions'}
            />
            <div style={{ minHeight: 360, margin: '0 10px' }}>
              <Scrollbar style={{ height: 'calc(100vh - 150px)' }}>
                <div>{rightComponent}</div>
              </Scrollbar>
            </div>
          </>
        }
      />
    </>
  )
}
export default OthersLanding
