/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-loop-func */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */

import { EditOutlined, LineChartOutlined, ReloadOutlined } from '@ant-design/icons'
import groupObj from '@hunters/group-object'
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Menu,
  Radio,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import LoadingComponent from 'components/VBMappReport/LoadingComponent'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import EditTargetAllocation from 'pages/ProgramBook/TargetAllocation'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLazyQuery, useQuery } from 'react-apollo'
import { FaDownload } from 'react-icons/fa'
import * as XLSX from 'xlsx'
import ResponseGraphComp from 'components/TargetRecordingComponent/targetResponseRate'
import './form.scss'
import AnimateToLeft from 'components/ScreenChangeAnimation/AnimateToLeft'
import _ from 'lodash'
import AnimateFromRight from 'components/ScreenChangeAnimation/AnimateFromRight'
import { AnimatePresence, motion } from 'framer-motion'
import './table.scss'
import RangeDatePicker from 'components/DatePicker/DatePicker'
import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import { ResponseRateEqui } from './dailyResponseRateEqui'
import { PEAK_BLOCKWISE, RESPONSE_RATE, RESPONSE_RATE_FILTER_OPT } from './query'

const { Option } = Select
const { RangePicker } = DatePicker

const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
}

const parentDiv = { display: 'flex' }

const dateFormat = 'YYYY-MM-DD'
const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }

const tableTargetStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  color: COLORS.target,
  fontWeight: '600',
}
const tableTargetBtnStyle = { padding: '0 4px' }
const tableTargetIconStyle = { fontSize: '22px', color: COLORS.graph }

const statusMapping = {
  'Discrete Trial Training': 'DTT',
  Narrative: 'Narrative',
  'Natural Environment Training': 'NET',
  Other: 'Other',
  'Task Analysis': 'Task Analysis',
  'Discrete Trial Training/Natural Environment Training': 'DTT/NET',
  'Precision Teaching': 'Precision Teaching',
  Peak: 'Peak',
  'Time Circle': 'Time Circle',
  'Skill Based Treatment': 'SBT',
  'Behavior Recording': 'Behavior Recording',
}

export default Form.create()(({ studentName, showDrawerFilter, setHeader }) => {
  const studentId = localStorage.getItem('studentId')
  const user = useSelector(state => state.user)
  const { defaultDateRange, defaultDateDRR } = user
  const [dateRange, setDateRange] = useState([(moment().startOf('week'), moment().endOf('week'))])
  const [status, setStatus] = useState([])
  const [type, setType] = useState([])
  const [tableData, setTableData] = useState([])
  const [peakType, setpeakType] = useState('dir/gen')
  const downloadCsvRef = useRef()
  const [selectedTarget, setSelectedTarget] = useState(null)
  const [targetForEdit, setTargetForEdit] = useState(null)
  const [visible, setVisible] = useState(false)
  const [peakSd, setPeakSd] = useState(null)
  const [nextDisable, setNextDisable] = useState(false)
  const [prevDisable, setPrevDisable] = useState(false)
  const [tStatus, SetTStatus] = useState([])
  const [selectedSStatus, setSelectedSStatus] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState(null)
  const [daysList, setDaysList] = useState([])
  const [currentStartDate, setCurrentStartDate] = useState(null)
  const [currentEndDate, setCurrentEndDate] = useState(null)
  const [hasMoreData, setHasMoreData] = useState(false)
  const [loaded, setLoaded] = useState(6)
  const [dayRemains, setDayRemains] = useState(30)

  const [initialLoad, setInitialLoad] = useState(true)

  const [editTargetAllocationDrawer, setEditTargetAllocationDrawer] = useState(false)

  const { data: filterOptions, error: filterOptErr, loading: filterOptLoading } = useQuery(
    RESPONSE_RATE_FILTER_OPT,
    {
      fetchPolicy: 'network-only',
    },
  )

  const [getResponseRate, { data, error, loading, refetch: responseRateRefetch }] = useLazyQuery(
    RESPONSE_RATE,
    {
      fetchPolicy: 'network-only',
    },
  )

  const [
    getPeakBlockData,
    { data: peakBlockData, error: peakError, loading: peakBlockLoading, refetch: peakBlockRefetch },
  ] = useLazyQuery(PEAK_BLOCKWISE, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (error) {
      console.error(error)
    }

    if (peakError) {
      console.error(peakError)
    }
    if (filterOptErr) {
      console.error(filterOptErr)
    }
  }, [error, peakError, filterOptErr])

  useEffect(() => {
    if (filterOptions) {
      const filteredStatus = filterOptions.targetStatus.filter(
        item => item.id !== 'U3RhdHVzVHlwZTo3' && item.id !== 'U3RhdHVzVHlwZTo2',
      )
      SetTStatus(filteredStatus)
    }
  }, [filterOptions])

  const fetchData = (st, ed) => {
    getResponseRate({
      variables: {
        startDate: st,
        endDate: ed,
        studentId,
      },
    })
    getPeakBlockData({
      variables: {
        start: st,
        end: ed,
        student: studentId,
        sessionName: '',
      },
    })
  }

  useEffect(() => {
    // setTableData([])
    if (dateRange[0] && dateRange[1] && studentId) {
      let st = dateRange[0].format(dateFormat)
      let ed = dateRange[1].format(dateFormat)
      if (dateRange[0].format(dateFormat) < dateRange[1].format(dateFormat)) {
        st = dateRange[0].format(dateFormat)
        ed = dateRange[1].format(dateFormat)
      } else {
        st = dateRange[1].format(dateFormat)
        ed = dateRange[0].format(dateFormat)
      }
      const startDate = moment(st).toDate() // Convert moment object to JavaScript Date
      const endDate = moment(ed).toDate()
      const daysDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))
      setTableData([])
      setDaysList([])
      setLoaded(6)
      setDayRemains(30)

      if (daysDifference > 6) {
        ed = new Date(dateRange[0])
        ed.setDate(ed.getDate() + 6)
        setHasMoreData(true)
      }
      const start = moment(st)
      let end = moment(ed)
      setCurrentStartDate(start)
      setCurrentEndDate(end)
    }
  }, [dateRange, studentId])

  useEffect(() => {
    if (currentStartDate && currentEndDate) {
      const days = []
      let day = currentStartDate
      while (day <= currentEndDate) {
        days.push(day.toDate())
        day = day.clone().add(1, 'd')
      }
      const temp = []
      days.forEach(dateStr => {
        temp.push({
          monthYear: moment(dateStr)
            .format('MMM')
            .concat(moment(dateStr).format('YYYY')),
          date: moment(dateStr).format('YYYY-MM-DD'),
          month: moment(dateStr).format('MMM'),
          dayDate: moment(dateStr).format('DD'),
          day: moment(dateStr).format('dddd'),
          year: moment(dateStr).format('YYYY'),
        })
      })
      setDaysList(prev => {
        const dataArray = _.unionBy([...prev, ...temp], 'date')

        return dataArray.sort((a, b) => moment(a.date) - moment(b.date))
      })

      fetchData(
        moment(currentStartDate).format(dateFormat),
        moment(currentEndDate).format(dateFormat),
      )
    }
  }, [currentStartDate, currentEndDate])

  useEffect(() => {
    if (data && peakBlockData) {
      loadData(data.responseRate)
    }
  }, [data, peakBlockData])

  useEffect(() => {
    if (visible) {
      setHeader(false)
    } else {
      setHeader(true)
    }
  }, [visible])

  useEffect(() => {
    if (data) {
      filterData()
    }
  }, [type, status, selectedSStatus])

  useEffect(() => {
    if (data && !data.responseRate.length && hasMoreData && !tableData.length) {
      loadMoreData(7)
    }
  }, [data])

  const loadMoreData = val => {
    const newStartDate = new Date(currentEndDate)
    newStartDate.setDate(newStartDate.getDate() + 1) // Add 1 day to the previous end date
    let newEndDate = new Date(newStartDate)
    newEndDate.setDate(newStartDate.getDate() + val) // Add 14 days to the new start date
    if (moment(newEndDate).isAfter(dateRange[1])) {
      newEndDate = dateRange[1]
      setHasMoreData(false)
    }
    setCurrentStartDate(moment(newStartDate))
    setCurrentEndDate(moment(newEndDate))
    // Convert moment object to JavaScript Date
    const endDate = moment(newEndDate).toDate()
    const lastDate = moment(dateRange[1]).toDate()
    const lastdifference = Math.ceil((lastDate - endDate) / (1000 * 60 * 60 * 24))
    setDayRemains(lastdifference)
  }

  const loadData = data => {
    let tempPeakStimulus = []
    const tempBlockId = []

    peakBlockData.peakBlockWiseReport.forEach(item => {
      tempPeakStimulus.push({
        date: item.date,
        blocks: item.blocks,
        mastery: item.target?.mastery,
        target: item.target?.targetAllcatedDetails.targetName,
        peakType: item.target?.peakType,
        baseline: item.target?.baselineDate,
        master: item.target?.masterDate,
        therapy: item.target?.intherapyDate,
        inmaintainence: item.target?.inmaintainenceDate,
        allocate: item.target?.targetAllcatedDetails.dateBaseline,
        lastModified: item.target?.time,
        lastUpdatedBy: item.target?.lastUpdatedBy,
      })
    })

    tempPeakStimulus = tempPeakStimulus.filter(item => item.peakType !== 'EQUIVALENCE')
    tempPeakStimulus = tempPeakStimulus.filter(item => {
      if (item.blocks?.length > 0) {
        const tempBlock = item.blocks[0].id
        for (let i = 0; i < tempBlockId.length; i += 1) {
          if (tempBlockId[i] === tempBlock) {
            return false
          }
        }
        tempBlockId.push(tempBlock)
      }
      return true
    })

    if (data) {
      const tempData = []
      if (data.length > 0) {
        const groupedData = groupObj.group(data, 'targetName')
        const peakGroup = groupObj.group(tempPeakStimulus, 'target') // data of peak targets
        const keys = Object.keys(groupedData) // array of target that are going to be visible in report
        keys.forEach((target, index) => {
          if (groupedData[target].length > 0) {
            tempData.push({
              target,
              key: target,
              targetId: groupedData[target][0]?.targetId,
              type: 'target',
              lastModified: data.target?.time,
              lastUpdatedBy: data.target?.lastUpdatedBy,
              children: [],
            })

            const lastIdx = tempData.length - 1

            // iterating over all the data we have for a particular target
            groupedData[target]?.forEach(data => {
              tempData[lastIdx] = {
                ...tempData[lastIdx],
                targetStatusName: data.targetStatusName,
                targetType: data.targetType,
                lastModified: data.target?.time,
                lastUpdatedBy: data.target?.lastUpdatedBy,

                [`${data.sessionDate}`]:
                  data.targetType === 'Coded' ? data.perPeakCorrect : data.perTar,
              }

              if (data.targetType === 'Behavior Recording') {
                const { behRecording } = data
                if (behRecording && behRecording.length > 0) {
                  const temDue = []
                  for (let i = 0; i < behRecording.length; i += 1) {
                    const ele = behRecording[i]
                    const temDuration = Number(ele.end) - Number(ele.start)
                    temDue.push(temDuration)
                  }
                  const temTotalDuration = Number(temDue.reduce((a, b) => a + b, 0) / 1000).toFixed(
                    0,
                  )
                  const temFrequency = behRecording.length
                  tempData[lastIdx] = {
                    ...tempData[lastIdx],
                    [`${data.sessionDate}`]: [temTotalDuration, temFrequency],
                  }
                }
              }

              if (data.targetType === 'Skill Based Treatment') {
                const sessionRecord = data.sbtStepsRecording
                let tempPerStepR1 = 0
                let tempPerStepR2 = 0
                sessionRecord.forEach(item => {
                  let isExist = false
                  let childIdx = -1
                  for (let i = 0; i < tempData[lastIdx].children.length; i += 1) {
                    if (tempData[lastIdx].children[i].target === item.sbtStepDescription) {
                      isExist = true
                      childIdx = i
                      break
                    }
                  }
                  tempPerStepR1 += item.perStepR1
                  tempPerStepR2 += item.perStepR2
                  if (isExist) {
                    tempData[lastIdx].children[childIdx] = {
                      ...tempData[lastIdx].children[childIdx],
                      [`${data.sessionDate}`]: [item.perStepR1, item.perStepR2],
                    }
                  } else {
                    let c = ''
                    const index = _.findIndex(
                      data.target.sbtSteps.edges,
                      ({ node }) => node.description === item.sbtStepDescription,
                    )
                    c =
                      index !== -1
                        ? data.target.sbtSteps.edges[index].node.status.statusName
                        : 'Deleted'
                    tempData[lastIdx].children.push({
                      target: item.sbtStepDescription,
                      key: Math.random(),
                      targetStatusName: c,
                      type: 'sd',
                      parentTargetType: data.targetType,
                      parentTarget: target,
                      sbtStepName: [data.r1Name, data.r2Name],
                      parentTargetId: data.targetId,
                      [`${data.sessionDate}`]: [item.perStepR1, item.perStepR2],
                    })
                  }
                })

                tempData[lastIdx] = {
                  ...tempData[lastIdx],
                  sbtStepName: [data.r1Name, data.r2Name],
                  [`${data.sessionDate}`]: [tempPerStepR1, tempPerStepR2],
                }
              }

              if (data.targetType === 'Coded') {
                target = target.trim()
                let trialCount = 0
                const childrenObj = []
                for (let k = 0; k < peakGroup[target]?.length; k += 1) {
                  if (peakGroup[target][k].date === data.sessionDate) {
                    peakGroup[target][k].blocks?.forEach(blockItem => {
                      blockItem.trial?.edges.forEach(trialObj => {
                        let stimulusIdx = -1
                        let stimulusExist = false
                        for (let w = 0; w < childrenObj.length; w += 1) {
                          if (childrenObj[w].sd === trialObj?.node.sd.sd) {
                            stimulusExist = true
                            stimulusIdx = w
                          }
                        }
                        if (stimulusExist) {
                          childrenObj[stimulusIdx].trialCount +=
                            trialObj?.node.marks !== null ? 1 : 0
                          childrenObj[stimulusIdx].marks += trialObj?.node.marks === 10 ? 1 : 0
                        } else {
                          let c = ''
                          const index = _.findIndex(
                            peakGroup[target][k].mastery.edges,
                            ({ node }) => node.sd.sd === trialObj?.node.sd.sd,
                          )
                          c =
                            index !== -1
                              ? peakGroup[target][k].mastery.edges[index].node.status.statusName
                              : 'Deleted'
                          childrenObj.push({
                            key: trialObj?.node.id,
                            targetId: trialObj.node.id,
                            sd: trialObj?.node.sd.sd,
                            parentTarget: data,
                            parentTargetType: data.targetType,
                            targetStatusName: c,
                            trialCount: trialObj?.node.marks !== null ? 1 : 0,
                            marks: trialObj?.node.marks === 10 ? 1 : 0,
                          })
                        }
                      })
                    })
                  }
                }
                childrenObj.forEach(cc => {
                  let childIndex = -1
                  let childExist = false
                  for (let bb = 0; bb < tempData[lastIdx].children.length; bb += 1) {
                    if (cc.sd === tempData[lastIdx].children[bb].target) {
                      childExist = true
                      childIndex = bb
                    }
                  }
                  if (childExist) {
                    tempData[lastIdx].children[childIndex] = {
                      ...tempData[lastIdx].children[childIndex],
                      [`${data.sessionDate}`]: Number(
                        Number((cc.marks / cc.trialCount) * 100).toFixed(0),
                      ),
                    }
                  } else {
                    tempData[lastIdx].children.push({
                      key: cc.key,
                      targetId: cc.targetId,
                      target: cc.sd,
                      type: 'sd',
                      targetStatusName: cc.targetStatusName,
                      parentTarget: data.targetName,
                      parentTargetId: data.targetId,
                      [`${data.sessionDate}`]: Number(
                        Number((cc.marks / cc.trialCount) * 100).toFixed(0),
                      ),
                    })
                  }
                })
              }

              if (data.sessionRecord?.length > 0) {
                const { sessionRecord } = data
                sessionRecord.forEach(sessionData => {
                  let StimulusType = 'sd'
                  if (sessionData.sd && sessionData.sd !== '') {
                    StimulusType = 'sd'
                  } else if (sessionData.step && sessionData.step !== '') {
                    StimulusType = 'step'
                  }
                  let isExist = false
                  let childIdx = -1

                  for (let i = 0; i < tempData[lastIdx].children.length; i += 1) {
                    if (tempData[lastIdx].children[i].target === sessionData[StimulusType]) {
                      isExist = true
                      childIdx = i
                      break
                    }
                  }
                  if (isExist) {
                    tempData[lastIdx].children[childIdx] = {
                      ...tempData[lastIdx].children[childIdx],
                      [`${data.sessionDate}`]:
                        StimulusType === 'sd' ? sessionData.perSd : sessionData.perStep,
                    }
                  } else {
                    let c = ''
                    const index = _.findIndex(data.target.mastery.edges, ({ node }) => {
                      return StimulusType === 'sd'
                        ? node.sd && node.sd.sd === sessionData[StimulusType]
                        : node.step && node.step.step === sessionData[StimulusType]
                    })
                    c =
                      index !== -1
                        ? data.target.mastery.edges[index].node.status.statusName
                        : 'Deleted'
                    tempData[lastIdx].children.push({
                      target: sessionData[StimulusType],
                      key: Math.random(),
                      targetStatusName: c,
                      type: StimulusType,
                      parentTarget: target,
                      parentTargetId: data.targetId,
                      [`${data.sessionDate}`]:
                        StimulusType === 'sd' ? sessionData.perSd : sessionData.perStep,
                    })
                  }
                })
              }
            })
          }
        })
      }
      tempData.forEach(item => {
        if (item.children.length === 0) {
          delete item.children
        }
      })

      const tempArray = [...tableData, ...tempData]
      const mergedArray = Object.values(
        tempArray.reduce((mergedData, obj) => {
          const { key: id } = obj
          if (mergedData[id]) {
            mergedData[id] = { ...mergedData[id], ...obj }
          } else {
            mergedData[id] = { id, ...obj }
          }
          return mergedData
        }, {}),
      )

      setTableData(mergedArray)
      return mergedArray
    }
  }
  const columns = [
    {
      key: 'targetName',
      title: 'Target',
      fixed: 'left',
      width: '400px',
      dataIndex: 'target',
      render: (text, row) => {
        if (row.type === 'target') {
          const child = []
          child.push(row)
          if (row.children) {
            child.push(...row.children)
            return (
              <div style={tableTargetStyle}>
                <div>{text}</div>
                {user.role !== 'parents' && (
                  <Button
                    style={{ ...tableTargetBtnStyle, marginLeft: 'auto' }}
                    type="link"
                    onClick={() => {
                      setTargetForEdit(row.targetId)
                      setEditTargetAllocationDrawer(true)
                    }}
                  >
                    <EditOutlined style={{ fontSize: 18, color: COLORS.target }} />
                  </Button>
                )}
                <Button style={tableTargetBtnStyle} type="link" onClick={() => handleparent(child)}>
                  <LineChartOutlined style={tableTargetIconStyle} />
                </Button>
              </div>
            )
          }
          return (
            <div style={tableTargetStyle}>
              <div>{text}</div>
              {user?.role !== 'parents' && (
                <Button
                  style={{ ...tableTargetBtnStyle, marginLeft: 'auto' }}
                  type="link"
                  onClick={() => {
                    setTargetForEdit(row.targetId)
                    setEditTargetAllocationDrawer(true)
                  }}
                >
                  <EditOutlined style={{ fontSize: 18, color: COLORS.target }} />
                </Button>
              )}
              <Button style={tableTargetBtnStyle} type="link" onClick={() => handleparent(child)}>
                <LineChartOutlined style={tableTargetIconStyle} />
              </Button>
            </div>
          )
        }

        return (
          <div
            style={{
              ...tableTargetStyle,
              paddingLeft: '16px',
              color: row.type === 'sd' ? COLORS.stimulus : COLORS.steps,
            }}
          >
            <div>{text}</div>
            {row.type === 'target' ? null : (
              <Button style={tableTargetBtnStyle} type="link" onClick={() => handleChild(row)}>
                <LineChartOutlined style={tableTargetIconStyle} />
              </Button>
            )}
          </div>
        )
      },
    },

    {
      title: 'Target Status',
      dataIndex: 'targetStatusName',
      width: '120px',
      fixed: 'left',
      align: 'center',
      render: text => <span style={{ fontWeight: 600 }}>{text}</span>,
    },
    {
      title: 'Target Type',
      dataIndex: 'targetType',
      align: 'center',
      fixed: 'left',
      width: '120px',
      render: (text, row) => {
        if (text && text.length > 22) {
          return (
            <Tooltip title={text}>
              <span>{statusMapping[text]}</span>
            </Tooltip>
          )
        }
        if (row.parentTargetType === 'Skill Based Treatment') {
          return (
            <div>
              <div
                style={{ borderBottom: '1px solid #e8e8e8', marginTop: '-6px', paddingBottom: 3 }}
              >
                {row.sbtStepName && row.sbtStepName[0]}
              </div>
              <div style={{ marginBottom: '-6px', paddingTop: 3 }}>
                {row.sbtStepName && row.sbtStepName[1]}
              </div>
            </div>
          )
        }
        return <span>{text}</span>
      },
    },
    {
      title: 'Last Modified',
      width: '200px',
      align: 'center',
      render: (text, row) => {
        return row.type === 'target' ? (
          <span style={{ fontWeight: 600 }}>
            {moment(row.lastModified).format('YYYY-MM-DD h:mm:ss a')}
            {row.lastUpdatedBy ? (
              <p style={{ fontWeight: 300 }}> by {row.lastUpdatedBy.firstName}</p>
            ) : (
              <p style={{ fontWeight: 500 }}> by System</p>
            )}
          </span>
        ) : (
          ''
        )
      },
    },
    ...daysList.map(item => {
      return {
        title: `${item.dayDate} ${item.month}`,
        align: 'center',
        key: Math.random(),
        children: [
          {
            title: item.day.substring(0, 3),
            align: 'center',
            dataIndex: item.date,
            key: Math.random(),

            render: (text, row) => {
              if (row.type === 'target') {
                if (
                  row.targetType === 'Skill Based Treatment' ||
                  row.targetType === 'Behavior Recording'
                ) {
                  if (text?.length === 2) {
                    return (
                      <div style={{ fontWeight: 600 }}>
                        <div
                          style={{
                            borderBottom: '1px solid #e8e8e8',
                            marginTop: '-6px',
                            paddingBottom: 3,
                          }}
                        >
                          {isNaN(text[0]) ? '' : text[0]}
                        </div>
                        <div style={{ marginBottom: '-6px', paddingTop: 3 }}>
                          {isNaN(text[1]) ? '' : text[1]}
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div>
                      <div style={{ borderBottom: '1px solid #e8e8e8' }} />
                      <div />
                    </div>
                  )
                }
                return <span style={{ fontWeight: '600' }}>{isNaN(text) ? '' : text}</span>
              }

              if (row.parentTargetType && row.parentTargetType === 'Skill Based Treatment') {
                if (text?.length === 2) {
                  return (
                    <div>
                      <div
                        style={{
                          borderBottom: '1px solid #e8e8e8',
                          marginTop: '-6px',
                          paddingBottom: 3,
                        }}
                      >
                        {isNaN(text[0]) ? '' : text[0]}
                      </div>
                      <div style={{ marginBottom: '-6px', paddingTop: 3 }}>
                        {isNaN(text[1]) ? '' : text[1]}
                      </div>
                    </div>
                  )
                }
                return (
                  <div>
                    <div style={{ borderBottom: '1px solid #e8e8e8' }} />
                    <div />
                  </div>
                )
              }

              return <span style={{ padding: '16px 0' }}>{isNaN(text) ? '' : text}</span>
            },
          },
        ],
      }
    }),
  ]

  const filterData = () => {
    let filteredData = loadData(data.responseRate)
    if (type && type.length > 0) {
      filteredData = filteredData.filter(item => type.includes(item.targetType))
    }
    if (status && status.length > 0) {
      filteredData = filteredData.filter(item => status.includes(item.targetStatusName))
    }
    if (selectedSStatus && selectedSStatus.length > 0) {
      filteredData = filteredData.filter(item => {
        if (item.children && item.children.length > 0) {
          item.children = item.children.filter(i => selectedSStatus.includes(i.targetStatusName))
          if (item.children.length > 0) {
            return item
          }
        }
      })
    }
    setTableData(filteredData)
  }

  const handleparent = target => {
    setSelectedTarget({ id: target[0].targetId })
    setVisible(true)
    setPeakSd(null)
    setSelectedRowKeys(target[0].key)
    const index = getIdxByTargetId(target[0].targetId)
    checkIdx(index)
  }

  const handleChild = child => {
    setVisible(true)
    setPeakSd(child.target)
    setSelectedTarget({ id: child.parentTargetId })
    setSelectedRowKeys(child.key)

    const index = getIdxByTargetId(child.parentTargetId)
    checkIdx(index)
  }

  const getFormattedObj = (dt, parentTarget) => {
    let tempObj = {
      Target:
        dt.type === 'target'
          ? dt.target
          : dt.type === 'sd'
          ? `${parentTarget}-Stimulus-${dt.target}`
          : `${parentTarget}-Step-${dt.target}`,
      [`Target Status`]: dt.targetStatusName,
    }
    daysList.forEach(item => {
      if (dt[item.date]) {
        tempObj = {
          ...tempObj,
          [`${item.date}`]: dt[item.date],
        }
      } else {
        tempObj = {
          ...tempObj,
          [`${item.date}`]: null,
        }
      }
    })

    return tempObj
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const exportToCSV = () => {
    const filename = '_daily_response_rate_excel'
    const formattedData = []

    for (let i = 0; i < tableData.length; i += 1) {
      const obj = tableData[i]
      const tempObj = getFormattedObj(obj)
      formattedData.push(tempObj)
      if (obj.children) {
        obj.children.forEach(child => {
          formattedData.push(getFormattedObj(child, obj.target))
        })
      }
    }

    const ws = XLSX.utils.json_to_sheet(formattedData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelData, studentName + filename + fileExtension)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button
          onClick={() => {
            if (peakType === 'dir/gen') {
              exportToCSV()
            } else {
              downloadCsvRef.current?.exportToCSV('Equivalence')
            }
          }}
          type="link"
          size="small"
        >
          CSV/Excel
        </Button>
      </Menu.Item>
    </Menu>
  )

  const handleCloseLineDrawer = () => {
    // responseRateRefetch()
    // peakBlockRefetch()
  }

  const getIdxByTargetId = targetId => {
    const index = tableData
      .map(function(e) {
        return e.targetId
      })
      .indexOf(targetId)

    return index
  }

  const checkIdx = index => {
    if (index === 0) {
      setPrevDisable(true)
    } else {
      setPrevDisable(false)
    }
    if (index === tableData.length - 1 || index > tableData.length) {
      setNextDisable(true)
    } else {
      setNextDisable(false)
    }
  }

  const getNextTarget = currTarget => {
    if (!nextDisable) {
      const index = getIdxByTargetId(currTarget.id) + 1

      if (index < tableData.length) {
        setSelectedTarget({ id: tableData[index].targetId })
        setPeakSd(null)
      }
      checkIdx(index)
    }
  }

  const getPrevTarget = currTarget => {
    if (!prevDisable) {
      const index = getIdxByTargetId(currTarget.id) - 1

      if (index > -1) {
        setSelectedTarget({ id: tableData[index].targetId })
        setPeakSd(null)
      }

      checkIdx(index)
    }
  }

  const handleCloseEditTargetDetails = () => {
    setEditTargetAllocationDrawer(false)
  }

  const onSuccessTargetAllocation = res => {
    if (res && res.data.updateTargetAllocate2) {
      const temp = tableData.map(item => {
        const tarObj = res.data.updateTargetAllocate2.target
        if (item.targetId === tarObj.id) {
          return {
            ...item,
            target: tarObj.targetAllcatedDetails.targetName,
            targetStatusName: tarObj.targetStatus.statusName,
          }
        }
        return item
      })
      setTableData(temp)
    }
    setEditTargetAllocationDrawer(false)
  }

  const onSelectChange = selectedKeys => {
    setSelectedRowKeys(selectedKeys)
  }

  const menuItem = (
    <Menu
      onClick={e => {
        let count = e.key === '2' ? 15 : e.key === '3' ? 30 : 7
        setLoaded(count)
        loadMoreData(count)
      }}
    >
      <Menu.Item key="1">Next 7 days</Menu.Item>
      <Menu.Item key="2" disabled={!(dayRemains > 7)}>
        Next 15 days
      </Menu.Item>
      <Menu.Item key="3" disabled={!(dayRemains > 15)}>
        Next 30 days
      </Menu.Item>
    </Menu>
  )
  return (
    <div>
      {!visible && (
        <AnimateToLeft>
          <div style={filterCardStyle}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
              }}
            >
              <div style={parentDiv}>
                <Tooltip trigger={['hover']} title="Select Date range">
                  <RangeDatePicker
                    date={dateRange}
                    setDate={setDateRange}
                    rangeType={defaultDateRange}
                    from="DRR"
                  />
                </Tooltip>
              </div>
              <div style={parentDiv}>
                <Tooltip title="Target Type">
                  <Select
                    mode="multiple"
                    style={{
                      width: 180,
                      borderRadius: 4,
                      overflowY: 'scroll',
                      height: 32,
                    }}
                    disabled={loading || peakBlockLoading}
                    allowClear
                    onChange={v => {
                      setType(v)
                    }}
                    placeholder="ALL"
                  >
                    {filterOptions &&
                      filterOptions.types.map(({ id, typeTar }) => (
                        <Option key={id} value={typeTar}>
                          {typeTar === 'Peak' ? 'Coded' : typeTar}
                        </Option>
                      ))}
                  </Select>
                </Tooltip>
              </div>
              <div style={parentDiv}>
                <Tooltip title="Target Status">
                  <Select
                    style={{
                      width: 180,
                      borderRadius: 4,
                      overflowY: 'scroll',
                      height: 32,
                    }}
                    value={status}
                    disabled={loading || peakBlockLoading}
                    onChange={v => setStatus(v)}
                    allowClear
                    mode="multiple"
                    placeholder="ALL"
                  >
                    {filterOptions &&
                      tStatus.map(({ id, statusName }) => (
                        <Option key={id} value={statusName}>
                          {statusName}
                        </Option>
                      ))}
                  </Select>
                </Tooltip>
              </div>
              <div style={parentDiv}>
                <Tooltip title="Step or Stimulus Status">
                  <Select
                    style={{
                      width: 180,
                      borderRadius: 4,
                      overflowY: 'scroll',
                      height: 32,
                    }}
                    value={selectedSStatus}
                    disabled={loading || peakBlockLoading}
                    onChange={v => setSelectedSStatus(v)}
                    allowClear
                    mode="multiple"
                    placeholder="ALL"
                  >
                    {filterOptions &&
                      tStatus.map(({ id, statusName }) => (
                        <Option key={id} value={statusName}>
                          {statusName}
                        </Option>
                      ))}
                  </Select>
                </Tooltip>
              </div>

              <div style={{ marginLeft: 'auto' }}>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button style={{ padding: '0 8px' }} type="link" size="large">
                    <FaDownload fontSize="22" />{' '}
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div style={{ ...parentDiv, marginTop: '1rem', width: '80%' }}>
              <span style={parentLabel}>Target</span>
              <div
                style={{
                  background: COLORS.target,
                  borderRadius: 10,
                  width: '20px',
                  margin: 'auto 0',
                  marginRight: '10px',
                  height: 20,
                }}
              />
              <span style={parentLabel}>Stimulus</span>
              <div
                style={{
                  background: COLORS.stimulus,
                  borderRadius: 10,
                  width: '20px',
                  margin: 'auto 0',
                  marginRight: '10px',
                  height: 20,
                }}
              />
              <span style={parentLabel}>Steps</span>
              <div
                style={{
                  background: COLORS.steps,
                  borderRadius: 10,
                  width: '20px',
                  margin: 'auto 0',
                  height: 20,
                }}
              />
            </div>
            {hasMoreData && !loading && !peakBlockLoading && (
              <div
                style={{
                  ...parentDiv,
                  marginTop: '1rem',
                  width: '20%',
                  justifyContent: 'flex-end',
                }}
              >
                <Dropdown overlay={menuItem}>
                  <Button>
                    <ReloadOutlined /> Load More
                  </Button>
                </Dropdown>
              </div>
            )}
          </Row>

          <Row>
            <Col span={24}>
              {peakType === 'dir/gen' ? (
                loading || peakBlockLoading ? (
                  <LoadingComponent />
                ) : tableData.length > 0 ? (
                  <div
                    key={Math.random()}
                    className="response-rate-table"
                    style={{ marginTop: '1rem' }}
                  >
                    <Table
                      className="allReportTable"
                      columns={columns}
                      dataSource={tableData}
                      bordered
                      expandIcon={record => {
                        return null
                      }}
                      rowClassName={record => (record.key === selectedRowKeys ? 'active-row' : '')}
                      pagination={false}
                      defaultExpandAllRows
                      loading={loading}
                      scroll={{ x: daysList.length * 100 + 300, y: 480 }}
                    />
                  </div>
                ) : (
                  <div style={{ margin: '20px auto', textAlign: 'center' }}>
                    No data found, try to remove filter or change date range
                  </div>
                )
              ) : (
                <ResponseRateEqui
                  studentName={studentName}
                  type={type}
                  status={status}
                  daysList={daysList}
                  ref={downloadCsvRef}
                  dateRange={dateRange}
                  studentId={studentId}
                  setDateRange={setDateRange}
                />
              )}
            </Col>
          </Row>

          <Drawer
            className="target-allocation-drawer"
            title="Edit Target Allocation"
            placement="right"
            closable
            width={DRAWER.widthL1}
            onClose={() => setEditTargetAllocationDrawer(false)}
            visible={editTargetAllocationDrawer}
            destroyOnClose
          >
            <EditTargetAllocation
              formType="edit"
              id={targetForEdit}
              key={Math.random()}
              onSuccessTargetAllocation={onSuccessTargetAllocation}
              closeDrawer={() => handleCloseEditTargetDetails()}
            />
          </Drawer>
        </AnimateToLeft>
      )}

      <AnimatePresence initial={false}>
        <AnimateFromRight>
          {visible && selectedTarget && (
            <ResponseGraphComp
              visible={visible}
              setVisible={setVisible}
              selectedTarget={selectedTarget}
              defaultDate={dateRange}
              peakSd={peakSd}
              setDefaultDate={setDateRange}
              refetchData={handleCloseLineDrawer}
              getNextTarget={getNextTarget}
              getPrevTarget={getPrevTarget}
              nextDisable={nextDisable}
              prevDisable={prevDisable}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={key => setSelectedRowKeys(key)}
            />
          )}
        </AnimateFromRight>
      </AnimatePresence>
    </div>
  )
})
