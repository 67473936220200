/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-boolean-value */
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Menu,
  notification,
  Row,
  Table,
  Tooltip,
  Modal,
} from 'antd'
import { gql } from 'apollo-boost'
import { COLORS } from 'assets/styles/globalStyles'
import LoadingComponent from 'components/VBMappReport/LoadingComponent'
import * as FileSaver from 'file-saver'
import lodash from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import { FaDownload } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { useGeolocated } from 'react-geolocated'
import useTimer from '../../components/Timer/timer'
import './form.scss'
import './table.scss'
import './behavior.scss'
import {
  dateTimeToUtc,
  timeToUtc,
  dateTimeToDate,
  MinuteToHourMinute,
  getCordinatesDistance,
} from '../../utilities'
import WebcamCapture from 'pages/TherapistAttendanceByClinic/WebcamCapture'

const { RangePicker } = DatePicker

const filterCardStyle = {
  backgroundColor: COLORS.palleteLight,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  width: '80%',
  margin: 'auto',
  height: 'fit-content',
  overflow: 'hidden',
}

const tableFilterCardStyle = {
  borderRadius: 10,
  padding: '10px',
  margin: '0 0 -2px 10px',
  height: 35,
  overflow: 'hidden',
}

const timeRow = {
  display: 'flex',
  justifyContent: 'space-around',
  padding: '5px 10px',
}
const workHowers = {
  display: 'flex',
  justifyContent: 'center',
}

const text = {
  fontSize: '18px',
  fontWeight: 'bold',
}

const buttonContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  padding: '20px 0px',
}

const parentDiv = { display: 'flex', margin: '5px 40px 5px 0' }
const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }

const GET_STAFF_ATTENDANCE_SETTINGS = gql`
  query {
    getStaffAttendenceSettings {
      id
      longitude
      latitude
      locationSpecificAttendence
      distance
    }
  }
`
const ATTENDANCE = gql`
  query($dateGte: Date!, $dateLte: Date!, $clinic: ID!) {
    attendanceReportByClinic(dateGte: $dateGte, dateLte: $dateLte, clinic: $clinic) {
      staff {
        id
        name
      }
      data {
        date
        hours
      }
    }
  }
`
const therapistDashboard = gql`
  query therapistDashboard {
    lastStatus(first: 1) {
      edges {
        node {
          id
          checkIn
          checkOut
          status
          # checkInSelfie
          # checkOutSelfie
        }
      }
    }
    attendence: attendances(first: 1) {
      edges {
        node {
          id
          checkIn
          checkOut
          checkInLatitude
          checkInLongitude
          # checkInSelfie
          # checkOutSelfie
          status
        }
      }
    }
    timesheet: timesheets(first: 1) {
      edges {
        node {
          id
          start
          end
          title
          note
          location {
            id
            location
          }
          status {
            id
            statusName
          }
        }
      }
    }
  }
`

const therapistAttendanceReport = gql`
  query($dateGte: Date!, $dateLte: Date!, $therapist: ID!) {
    attendanceReport(dateGte: $dateGte, dateLte: $dateLte, therapist: $therapist) {
      staff {
        id
        name
      }
      date
      hours
      mins
    }
  }
`
const GET_SHIFTING = gql`
  query($therapistId: ID!) {
    staff(id: $therapistId) {
      id
      name
      shift {
        startTime
        endTime
        days {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

const therapistCheckInOut = gql`
  mutation startEnd(
    $checkInLongitude: String
    $checkInLatitude: String
    $checkIn: DateTime!
    $checkOut: DateTime!
    $status: Int!
    $pk: ID
  ) # $checkInSelfie: String
  # $checkOutSelfie: String
  {
    checkInOut(
      input: {
        longitude: $checkInLongitude
        latitude: $checkInLatitude
        checkIn: $checkIn
        checkOut: $checkOut
        status: $status
        pk: $pk
        # checkInSelfie: $checkInSelfie
        # checkOutSelfie: $checkOutSelfie
      }
    ) {
      details {
        id
        checkIn
        checkOut
        checkInLocation
        checkOutLocation
        # checkInSelfie
        # checkOutSelfie
        status
      }
    }
  }
`

const getDatesBetween = (startDate, endDate) => {
  const now = startDate.clone()
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'))
    now.add(1, 'days')
  }
  return dates
}

export default props => {
  console.log(props, 'props')
  //   const { selectedStaffId } = props
  const { isGeolocationAvailable, isGeolocationEnabled, coords } = useGeolocated()

  const selectedStaffId = localStorage.getItem('therapistId')

  const [date, setDate] = useState([moment().subtract(21, 'd'), moment()])
  const [columns, setColumns] = useState()
  const [filteredTableData, setFilteredTableData] = useState()
  const [lastAttendance, setLastAttendance] = useState(null)
  const [TherapistEndTime, setTherapistEndTime] = useState('')
  const [FinalEndtime, setFinalEndtime] = useState('')
  const [attendanceSetting, setAttendanceSetting] = useState(null)
  const [long, setLong] = useState('')
  const [lat, setLat] = useState('')
  const [Id, setId] = useState('')
  const [selfieUrl, setSelfieUrl] = useState(null)
  const [isSelfieModal, setIsSelfieModal] = useState(false)
  const [attendanceType, setAttendanceType] = useState(null)

  const currentdate = moment().format('YYYY-MM-DD')
  const endTime = '06:00 PM'

  const startTime = moment().format()
  const startTime1 = moment().format()
  // console.log("time",time);
  const EndTime = moment(`${currentdate} ${TherapistEndTime}`, 'YYYY-MM-DD hh:mm A')

  const { data, loading, error } = useQuery(GET_STAFF_ATTENDANCE_SETTINGS, {
    fetchPolicy: 'network-only',
  })
  const { data: getShiftData, error: shiftErrors, loading: isShiftingDataLoading } = useQuery(
    GET_SHIFTING,
    {
      variables: {
        therapistId: selectedStaffId,
      },
      fetchPolicy: 'network-only',
    },
  )
  const [getAttendance, { data: attendance, loading: attLoading, error: attError }] = useLazyQuery(
    ATTENDANCE,
  )
  const staff = useSelector(state => state.user.staffObject)
  const { data: dashboard, loading: dashLoading, error: dataerror, refetch } = useQuery(
    therapistDashboard,
  )
  console.log('dashboard>>>>', dashboard, EndTime, dateTimeToUtc(EndTime))
  const [
    getstaffAttendanceReport,
    {
      data: staffAttendanceReportData,
      loading: staffAttendanceReportDataLoading,
      error: staffAttendanceDataReportError,
    },
  ] = useLazyQuery(therapistAttendanceReport, {
    fetchPolicy: 'network-only',
  })
  const [staffData, setStaffData] = useState(null)
  const [
    postStartEndWork,
    {
      data: therapistCheckInOutData,
      loading: therapistCheckInOutLoading,
      error: therapistCheckInOuterror,
    },
  ] = useMutation(therapistCheckInOut)

  console.log('therapistCheckInOutData', therapistCheckInOutData)

  useEffect(() => {
    if (isGeolocationAvailable) {
      if (isGeolocationEnabled) {
        if (coords) {
          setLat(coords.latitude)
          setLong(coords.longitude)
        }
      } else {
        console.log('Geolocation is not enabled.')
      }
    } else {
      console.log('Geolocation is not available.')
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, coords])
  useEffect(() => {
    if (dashboard) {
      console.log('dashboard length>>>', dashboard.lastStatus.edges.length)
    }
    if (dashboard && dashboard.lastStatus.edges.length !== 0) {
      setLastAttendance(dashboard.lastStatus.edges[0])
      setFinalEndtime(dashboard.lastStatus.edges[0].node.checkOut)
      setId(dashboard.lastStatus.edges[0].node.id)
    }
  }, [dashboard])

  useEffect(() => {
    if (data && data.getStaffAttendenceSettings) {
      console.log('data==>', data)
      setAttendanceSetting(data.getStaffAttendenceSettings)
    }
  }, [data])

  useEffect(() => {
    if (getShiftData && getShiftData.staff.shift && getShiftData.staff.shift.endTime) {
      setTherapistEndTime(getShiftData.staff.shift.endTime)
    }
  }, [getShiftData])

  console.log('lastStatus', lastAttendance)
  // console.log('lastAttendance.node.checkIn', lastAttendance?.node.checkIn)

  // const timeToUtc = TherapistEndTime => {
  //   return moment(TherapistEndTime)
  //     .local()
  //     .utc()
  //     .format('hh:mm a')
  // }

  const handleSubmitWork = (status, checkInLongitude, checkInLatitude, checkIn, checkOut, pk) => {
    let latitude = lat
    let longitude = long

    if (attendanceSetting && attendanceSetting.locationSpecificAttendence) {
      if (attendanceSetting.longitude && attendanceSetting.latitude) {
        const defaultCordinates = {
          latitude: JSON.parse(attendanceSetting.latitude),
          longitude: JSON.parse(attendanceSetting.longitude),
        }
        const distance = getCordinatesDistance(
          { latitude: lat, longitude: long },
          defaultCordinates,
        )
        console.log('distance==>', distance, lastAttendance)
        if (distance <= attendanceSetting.distance) {
          latitude = defaultCordinates.latitude
          longitude = defaultCordinates.longitude
        } else {
          return notification.error({
            message: 'Sorry, You are out of the center region.',
          })
        }
      } else {
        return notification.error({
          message: 'Add your center location.',
          description:
            'Please add your center location from the attendance settings since your location specific setting is enabled but unable to find location coordinates and distance.',
        })
      }
    }
    // const checkInSelfie = status === 0 ? selfieUrl : lastAttendance.node.checkInSelfie
    // const checkOutSelfie = status === 1 ? selfieUrl : null
    postStartEndWork({
      variables: {
        checkInLongitude,
        checkInLatitude,
        checkIn: dateTimeToUtc(checkIn),
        checkOut: dateTimeToUtc(checkOut),
        status,
        pk,
        // checkInSelfie,
        // checkOutSelfie,
      },
    })
      .then(res => {
        refetch()
        const message = status === 0 ? 'Your Workday Started' : 'Your Workday Ended'
        notification.success({
          message: 'Workday',
          description: message,
        })
        setAttendanceType(null)
      })
      .catch(error => {
        console.log(error)
        notification.error({
          message: 'Something went wrong',
          description: `Unable to ${status === 0 ? 'start' : 'end'} workday`,
        })
      })
    setIsSelfieModal(false)
  }

  const SubmitStartWork = () => {
    handleSubmitWork(0, long, lat, moment(), EndTime)
  }

  const SubmitEndWork = () => {
    handleSubmitWork(1, long, lat, lastAttendance?.node.checkIn, moment(), Id)
  }

  const clinicId = localStorage.getItem('userId')
  const role = localStorage.getItem('role')
  const activeday = localStorage.getItem('startWorkDay')

  useEffect(() => {
    if (clinicId && role === '"school_admin"') {
      getAttendance({
        variables: {
          dateGte: date[0].format('YYYY-MM-DD'),
          dateLte: date[1].format('YYYY-MM-DD'),
          clinic: clinicId,
        },
      })
    }
  }, [date, clinicId])

  useEffect(() => {
    if (staff !== null) {
      if (role === '"therapist"') {
        getstaffAttendanceReport({
          variables: {
            dateGte: date[0].format('YYYY-MM-DD'),
            dateLte: date[1].format('YYYY-MM-DD'),
            therapist: selectedStaffId,
          },
        })
      }
    }
  }, [staff, date, selectedStaffId])

  useEffect(() => {
    if (staffAttendanceReportData) {
      const finalData = []
      const att = []
      const attobj = { data: null, staff: null }
      if (staffAttendanceReportData?.attendanceReport.length > 0) {
        staffAttendanceReportData?.attendanceReport.map(item => {
          att.push({
            date: item.date,
            hours: item.hours,
            mins: item.mins,
          })
        })
        attobj.data = att
        attobj.staff = staffAttendanceReportData?.attendanceReport[0].staff
        finalData.push(attobj)
        setStaffData(finalData)
      }
    }
  }, [staffAttendanceReportData, date, staff])

  useEffect(() => {
    if (attendance) {
      formatTableData(attendance)
    }
  }, [staff, date])

  useEffect(() => {
    if (staffAttendanceReportData) {
      const finalData = []
      const att = []
      const attobj = { data: null, staff: null }
      if (staffAttendanceReportData?.attendanceReport.length > 0) {
        staffAttendanceReportData?.attendanceReport.map(item => {
          att.push({
            date: item.date,
            hours: item.hours,
            mins: item.mins,
          })
        })
        attobj.data = att
        attobj.staff = staffAttendanceReportData?.attendanceReport[0].staff
        finalData.push(attobj)
        setStaffData(finalData)
      }
    }
  }, [staffAttendanceReportData, date, staff])

  useEffect(() => {
    if (attendance) {
      formatTableData(attendance)
    }
  }, [attendance, selectedStaffId])

  useEffect(() => {
    if (staffAttendanceReportData) {
      staffFormatTableData(staffData)
    }
  }, [staffAttendanceReportData, staffData])

  const staffFormatTableData = data => {
    if (data?.length > 0) {
      const dates = getDatesBetween(date[0], date[1])
      const tableData = []
      data.map((report, index) => {
        const rowData = {}
        rowData.key = index
        rowData.name = report.staff.name
        dates.forEach(date => {
          rowData[report.date] = report.hours
          if (report.data.length > 0) {
            report.data.map(item => {
              if (item.date === date) rowData[item.date] = MinuteToHourMinute(item.mins)
            })
          }
          if (!(date in rowData)) rowData[date] = 0
        })
        tableData.push(rowData)
      })

      console.log(tableData, 'tableData')

      moment.updateLocale(moment.locale(), { invalidDate: 'field' })
      const finalGroup = tableData.map(item => {
        const groupedData = lodash.groupBy(
          Object.entries(
            lodash.groupBy(Object.entries(item), item => moment(item[0]).format('MMM')),
          ),
          item => moment(item[1][0][0]).format('YYYY'),
        )
        return groupedData
      })

      setFilteredTableData(tableData)
      filterColumns(finalGroup)
    }
  }

  const formatTableData = ({ attendanceReportByClinic }) => {
    if (attendanceReportByClinic.length > 0) {
      const dates = getDatesBetween(date[0], date[1])
      const tableData = []
      attendanceReportByClinic.map((report, index) => {
        console.log(
          '=============',
          report.staff.id,
          selectedStaffId,
          report.staff.id === selectedStaffId,
        )
        if (report.staff.id === selectedStaffId) {
          const rowData = {}
          rowData.key = index
          rowData.name = report.staff.name
          dates.forEach(date => {
            if (report.data.length > 0) {
              report.data.map(item => {
                if (item.date === date) rowData[item.date] = MinuteToHourMinute(item.mins)
              })
            }
            if (!(date in rowData)) rowData[date] = 0
          })
          tableData.push(rowData)
        }
      })

      moment.updateLocale(moment.locale(), { invalidDate: 'field' })
      const finalGroup = tableData.map(item => {
        const groupedData = lodash.groupBy(
          Object.entries(
            lodash.groupBy(Object.entries(item), item => moment(item[0]).format('MMM')),
          ),
          item => moment(item[1][0][0]).format('YYYY'),
        )
        return groupedData
      })
      setFilteredTableData(
        lodash.uniqBy(tableData, item => {
          const { key, ...rest } = item
          return JSON.stringify(rest)
        }),
      )

      if (Object.keys(Object.assign({}, finalGroup)).length) {
        filterColumns(finalGroup)
      }
    }
  }

  const filterColumns = groupedData => {
    const columns = [
      {
        title: 'Name',
        width: 180,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
      },
    ]
    console.log(groupedData)
    const aa = Object.assign({}, groupedData)
    console.log(aa)
    const a = []
    const entries = Object.entries(aa[0])
    entries.pop()
    console.log(entries)
    entries.map(items => {
      items[1].map(item => {
        item[1].map(i => {
          a.push(i)
        })
      })
    })
    const columnLen = a.length

    a.forEach((date, index) => {
      columns.push({
        title: moment(date[0]).format('DD MMM'),
        key: index,
        dataIndex: date[0],
        width: 80,
        align: 'center',
        children: [
          {
            key: index,
            title: moment(date[0]).format('ddd'),
            dataIndex: date[0],
            align: 'center',
            width: columnLen > 5 ? 80 : '',
          },
        ],
      })
    })
    setColumns(columns)
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const exportToCSV = () => {
    const filename = `Attendance_report_${date}`
    const excel = [...filteredTableData]
    delete excel?.key
    delete excel?.undefined
    const ws = XLSX.utils.json_to_sheet(excel)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelData, filename + fileExtension)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => exportToCSV()} type="link" size="small">
          CSV/Excel
        </Button>
      </Menu.Item>
    </Menu>
  )

  console.log('attendanceType: ', attendanceType, attendanceType && attendanceType === 'start')
  return (
    <>
      {/* { role === "\"school_admin\"" ? } */}
      {role === '"school_admin"' ? (
        <Row>
          <Col sm={24}>
            <Row>
              <Col span={26}>
                <div style={filterCardStyle}>
                  <div style={parentDiv}>
                    <Tooltip title="Selecrt Date range">
                      <RangePicker
                        style={{
                          marginLeft: 'auto',
                          width: 250,
                        }}
                        size="default"
                        value={date}
                        onChange={val => setDate(val)}
                      />
                    </Tooltip>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Dropdown overlay={menu} trigger={['hover']}>
                      <Button type="link" size="large">
                        <FaDownload />{' '}
                      </Button>
                    </Dropdown>
                  </div>
                </div>
                {/* <Row>
                  <div style={tableFilterCardStyle}>
                    <Col span={1} style={{ display: 'block', width: '4%' }}>
                      {selectedFilters.year}
                    </Col>
                    <Col span={1} style={{ display: 'block', width: '3%' }}>
                      <div>
                        <Dropdown overlay={yearMenu}>
                          <a className="ant-dropdown-link">
                            <Icon type="caret-down" />
                          </a>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col span={1} style={{ display: 'block', width: '3%' }}>
                      {selectedFilters.month}
                    </Col>
                    <Col span={1} style={{ display: 'block', width: '3%' }}>
                      <div>
                        <Dropdown overlay={monthMenu}>
                          <a className="ant-dropdown-link">
                            <Icon type="caret-down" />
                          </a>
                        </Dropdown>
                      </div>
                    </Col>
                  </div>
                </Row> */}
                {attLoading ? (
                  <LoadingComponent />
                ) : (
                  <div style={{ margin: '10px 0 10px 10px', width: '80%' }}>
                    <Table
                      columns={columns}
                      dataSource={filteredTableData}
                      bordered
                      loading={attLoading}
                      scroll={{ x: 'fit-content' }}
                      size="middle"
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '50'],
                        position: 'top',
                      }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <div className="space-align-container">
              <div className="space-align-block">
                <Row>
                  <div style={{ display: 'inline-block', width: '100%' }}>
                    <div style={{ float: 'left' }}>
                      <p style={text}>Start Time:</p>

                      <div style={text}>
                        {lastAttendance
                          ? moment(lastAttendance.node.checkIn)
                              .local()
                              .format('hh:mm A')
                          : '--:--'}
                      </div>
                    </div>
                    <div style={{ float: 'right' }}>
                      <p style={text}>End Time</p>
                      <div style={text}>
                        {lastAttendance && lastAttendance.node.status !== 0
                          ? moment(lastAttendance.node.checkOut)
                              .local()
                              .format('hh:mm A')
                          : '--:--'}
                      </div>
                    </div>
                  </div>
                </Row>

                <Row>
                  {console.log(lastAttendance)}

                  {!isShiftingDataLoading &&
                  getShiftData &&
                  getShiftData.staff.shift &&
                  getShiftData.staff.shift.endTime &&
                  (!lastAttendance || lastAttendance.node?.status === 1) ? (
                    <Button
                      size="large"
                      type="primary"
                      style={{ width: '50%' }}
                      onClick={() => {
                        setAttendanceType('start')
                        setIsSelfieModal(true)
                      }}
                    >
                      Start WorkDay
                    </Button>
                  ) : (
                    getShiftData &&
                    !isShiftingDataLoading &&
                    getShiftData.staff.shift === null && (
                      <div>Your shift end time is not available.</div>
                    )
                  )}
                  {lastAttendance && lastAttendance.node?.status === 0 && (
                    <Button
                      size="large"
                      type="primary"
                      style={{ width: '50%' }}
                      onClick={() => {
                        setAttendanceType('end')
                        setIsSelfieModal(true)
                      }}
                    >
                      End WorkDay
                    </Button>
                  )}
                </Row>
              </div>
            </div>
          </Col>
          {/* <Col sm={8} style={workHowers}>
            <Row>
              {staffAttendanceReportData?.attendanceReport[0]?.date ===
              moment().format('YYYY-MM-DD') ? (
                <div>
                  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    {`Today Working:-  ${staffAttendanceReportData?.attendanceReport[0].hours} Hr`}
                  </div>
                </div>
              ) : (
                <p style={text}>ToDay You Not Started</p>
              )}
            </Row>
          </Col> */}
        </Row>
      )}
      {role !== '"school_admin"' ? (
        <Row>
          <Col sm={24}>
            <Row>
              <Col span={26}>
                <div style={filterCardStyle}>
                  <div style={parentDiv}>
                    <span style={parentLabel}>Date :</span>
                    <RangePicker
                      style={{
                        marginLeft: 'auto',
                        width: 250,
                      }}
                      size="default"
                      value={date}
                      onChange={val => setDate(val)}
                    />
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Dropdown overlay={menu} trigger={['hover']}>
                      <Button type="link" size="large">
                        <FaDownload />{' '}
                      </Button>
                    </Dropdown>
                  </div>
                </div>
                {/* <Row>
                  <div style={tableFilterCardStyle}>
                    <Col span={1} style={{ display: 'block', width: '4%' }}>
                      {selectedFilters.year}
                    </Col>
                    <Col span={1} style={{ display: 'block', width: '3%' }}>
                      <div>
                        <Dropdown overlay={yearMenu}>
                          <a className="ant-dropdown-link">
                            <Icon type="caret-down" />
                          </a>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col span={1} style={{ display: 'block', width: '3%' }}>
                      {selectedFilters.month}
                    </Col>
                    <Col span={1} style={{ display: 'block', width: '3%' }}>
                      <div>
                        <Dropdown overlay={monthMenu}>
                          <a className="ant-dropdown-link">
                            <Icon type="caret-down" />
                          </a>
                        </Dropdown>
                      </div>
                    </Col>
                  </div>
                </Row> */}
                {attLoading ? (
                  <LoadingComponent />
                ) : (
                  <div style={{ margin: 'auto', marginTop: 10, width: '80%' }}>
                    <Table
                      columns={columns}
                      dataSource={filteredTableData}
                      bordered
                      loading={attLoading}
                      scroll={{ x: 'fit-content' }}
                      size="middle"
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
      <Modal
        title="Attendance"
        centered
        visible={isSelfieModal}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setIsSelfieModal(false)
              setAttendanceType(null)
            }}
          >
            Cancel
          </Button>,
          <Button
            loading={therapistCheckInOutLoading}
            type="primary"
            style={{
              backgroundColor:
                attendanceType && attendanceType === 'end' ? COLORS.success : COLORS.primary,
            }}
            onClick={() => {
              if (attendanceType && attendanceType === 'start') {
                SubmitStartWork()
              }
              if (attendanceType && attendanceType === 'end') {
                SubmitEndWork()
              }
            }}
          >
            {attendanceType && attendanceType === 'start'
              ? 'Start Workday'
              : attendanceType && attendanceType === 'end'
              ? 'End Workday'
              : 'ok'}
          </Button>,
        ]}
        onCancel={() => setIsSelfieModal(false)}
      >
        <WebcamCapture setSelfieUrl={setSelfieUrl} />
      </Modal>
    </>
  )
}
