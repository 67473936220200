/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getData() {
  return apolloClient
    .query({
      query: gql`
        query {
          getUserEnrollCourses {
            id
            isPaid
            status
            progressPercentage
            courseUser {
              id
              name
              email
              phoneNo
            }
            courseTemplate {
              id
              name
              description
              thumbnailImage
              aboutCourse
              payableAmount
              isActive
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => console.log(error))
}

export async function getCourseTemplate(payload) {
  return apolloClient
    .query({
      query: gql`
        query($courseId: ID!) {
          getUserEnrollCourses(courseTemplate: $courseId) {
            id
            isPaid
            status
            progressPercentage
            courseUser {
              id
              name
              email
              phoneNo
            }
            onTest {
              id
            }
            onModule {
              id
            }
            onContent {
              id
            }
            assignedModules {
              edges {
                node {
                  id
                  name
                  description
                  coursemodulescontentSet {
                    edges {
                      node {
                        id
                        sNo
                        name
                        isVideo
                        video
                        isHtml
                        description
                        htmlContent
                      }
                    }
                  }
                  coursemodulestestsSet {
                    edges {
                      node {
                        id
                        title
                        aboutTest
                        testquestionSet {
                          edges {
                            node {
                              id
                              title
                              questionNo
                              questionText
                              testquestionoptionSet {
                                edges {
                                  node {
                                    id
                                    option
                                    score
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            userscoursemodulesprogressSet {
              edges {
                node {
                  userCourse {
                    id
                    courseUser {
                      id
                      email
                      name
                    }
                  }
                  id
                  module {
                    id
                    name
                    __typename
                  }
                  content {
                    id
                    name
                    video
                    isVideo
                    isHtml
                    description
                    htmlContent
                    __typename
                  }
                  test {
                    id
                    title
                    __typename
                  }
                  status
                  contentTestTrack
                  createdAt
                  updatedAt
                  __typename
                }
                __typename
              }
              __typename
            }
            courseTemplate {
              id
              name
              isPayable
              payableAmount
              isActive
              description
              currency {
                id
                currency
              }
              aboutCourse
              isActive
              coursetemplatemodulesSet {
                edges {
                  node {
                    id
                    name
                    description
                    coursemodulescontentSet {
                      edges {
                        node {
                          id
                          sNo
                          name
                          isVideo
                          video
                          isHtml
                          description
                          htmlContent
                        }
                      }
                    }
                    coursemodulestestsSet {
                      edges {
                        node {
                          id
                          title
                          aboutTest
                          testquestionSet {
                            edges {
                              node {
                                id
                                title
                                questionNo
                                questionText
                                testquestionoptionSet {
                                  edges {
                                    node {
                                      id
                                      option
                                      score
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              userscoursemodulesprogressSet {
                edges {
                  node {
                    id
                    userCourse {
                      id
                      courseUser {
                        id
                        email
                        name
                      }
                    }
                    module {
                      id
                      name
                    }
                    content {
                      id
                      name
                      video
                      isVideo
                      isHtml
                      htmlContent
                      description
                    }
                    test {
                      id
                      title
                    }
                    status
                    contentTestTrack
                    createdAt
                    updatedAt
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        courseId: payload.courseId,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => console.log(error))
}

export async function updateCourseModuleProgress(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation(
          $userCourse: ID!
          $courseTemplate: ID!
          $courseModule: ID!
          $moduleContent: ID
          $status: String!
          $contentTestTrack: JSONString
        ) {
          createUpdateCourseModuleProgress(
            input: {
              userCourse: $userCourse
              courseTemplate: $courseTemplate
              courseModule: $courseModule
              status: $status
              moduleContent: $moduleContent
              contentTestTrack: $contentTestTrack
            }
          ) {
            status
            message
            details {
              id
              userCourse {
                id
                progressPercentage
              }
              module {
                id
                name
              }
              content {
                id
                name
                video
                isVideo
                isHtml
                htmlContent
              }
              test {
                id
                title
              }
              status
              contentTestTrack
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        moduleContent: payload.selectedContentId,
        userCourse: payload.userCourse,
        courseTemplate: payload.courseTemplate,
        courseModule: payload.selectedModuleId,
        status: payload.status,
        contentTestTrack: payload.json ? JSON.stringify(payload.json) : null,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function updateCourseProgress(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation(
          $userCourse: ID!
          $courseModule: ID!
          $moduleContent: ID
          $moduleTest: ID
          $status: String!
        ) {
          updateUserTrackData(
            input: {
              userCourse: $userCourse
              courseModule: $courseModule
              courseStatus: $status
              moduleContent: $moduleContent
              moduleTest: $moduleTest
            }
          ) {
            status
            message
            details {
              id
              status
              courseUser {
                id
              }
              courseTemplate {
                id
              }
              onModule {
                id
              }
              onContent {
                id
              }
              onTest {
                id
              }
            }
          }
        }
      `,
      variables: {
        moduleContent: payload.moduleContent ? payload.moduleContent : null,
        userCourse: payload.userCourse,
        courseModule: payload.courseModule,
        status: payload.status,
        moduleTest: payload.moduleTest ? payload.moduleTest : null,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function updateTestQuestionResponse(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($userCourse: ID!, $courseTest: ID!, $questionId: ID!, $answerId: ID!) {
          addUserCourseTestResponse(
            input: {
              userCourse: $userCourse
              courseTest: $courseTest
              question: $questionId
              answer: $answerId
            }
          ) {
            status
            message
            response {
              id
              question {
                id
                questionText
              }
              answer {
                id
                option
                question {
                  id
                  questionText
                }
              }
              moduleTest {
                id
                title
              }
            }
          }
        }
      `,
      variables: {
        courseTest: payload.courseTest,
        userCourse: payload.userCourse,
        questionId: payload.questionId,
        answerId: payload.answerId,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getTestResponses(payload) {
  return apolloClient
    .query({
      query: gql`
        query getUserCourseTestResponse($courseId: ID!, $testId: ID!) {
          getUserCourseTestResponse(userCourse: $courseId, testId: $testId) {
            id
            userCourse {
              id
              courseTemplate {
                id
                name
              }
            }
            question {
              id
              questionText
            }
            answer {
              id
              option
            }
          }
        }
      `,
      variables: {
        courseId: payload.courseId,
        testId: payload.testId,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => console.log(error))
}

export async function updateCourseModuleTestProgress(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation(
          $userCourse: ID!
          $courseTemplate: ID!
          $courseModule: ID!
          $moduleTest: ID
          $status: String!
        ) {
          createUpdateCourseModuleProgress(
            input: {
              userCourse: $userCourse
              courseTemplate: $courseTemplate
              courseModule: $courseModule
              status: $status
              moduleTest: $moduleTest
            }
          ) {
            status
            message
            details {
              id
              module {
                id
                name
              }
              userCourse {
                id
                progressPercentage
              }
              content {
                id
                name
                video
                isVideo
                isHtml
                htmlContent
              }
              test {
                id
                title
              }
              status
              contentTestTrack
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        userCourse: payload.userCourse,
        courseTemplate: payload.courseTemplate,
        courseModule: payload.selectedModuleId,
        status: payload.status,
        moduleTest: payload.selectedTestId,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getUserPublicCourseTemplates(payload) {
  return apolloClient
    .query({
      query: gql`
        query getPublicCourses($courseUser: ID!) {
          getPublicCourses {
            id
            name
            isPayable
            isActive
            description
            payableAmount
            thumbnailImage
            aboutCourse
            purchasedcoursetemplatesSet(courseUser: $courseUser) {
              edges {
                node {
                  id
                  isPaid
                  status
                  progressPercentage
                }
              }
            }
            currency {
              id
              currency
              symbol
            }
          }
        }
      `,
      variables: {
        courseUser: payload.courseUser,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      console.log('THE RESULT', result)
      return result.data
    })
    .catch(error => {
      console.log('get public Templates ERORR ', JSON.stringify(error))
    })
}

export async function addUpdateUserCourseModuleValidation(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation addUpdateUserCourseModuleValidation(
          $userCourse: ID!
          $moduleId: ID!
          $status: String
        ) {
          addUpdateUserCourseModuleValidation(
            input: { userCourse: $userCourse, moduleId: $moduleId, status: $status }
          ) {
            message
            status
            userCourseModuleValidation {
              id
              expireTime
              module {
                id
                name
              }
              status
            }
          }
        }
      `,
      variables: {
        userCourse: payload.userCourse,
        moduleId: payload.selectedModuleId,
        status: payload.status,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getUserCourseModuleValidations(payload) {
  return apolloClient
    .query({
      query: gql`
        query getUserCourseModuleValidations($userCourse: ID!) {
          getUserCourseModuleValidations(userCourseId: $userCourse) {
            edges {
              node {
                id
                userCourse {
                  id
                }
                status
                module {
                  id
                  name
                }
                expireTime
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      console.log('THE RESULT', result)
      return result.data
    })
    .catch(error => {
      console.log('get public Templates ERORR ', JSON.stringify(error))
    })
}
