import actions from './actions'

const initialState = {
  loading: false,
  ActiveLearnerId: "U3R1ZGVudFR5cGU6MTYyOQ==",
  LearnerDetails: null,
  SelectedTab: 'Progress',
  SelectedDate: 'Week',
  SelectedBox: 1,
  InterventionCount: 0,
  MasterCount: 0,
  TotalCount: 0,
  AppointmentCount: 0,
  AppointmentList: [],
  TotalList: [],
  InterventionId: "U3RhdHVzVHlwZToz",
  MasteredId: "U3RhdHVzVHlwZTo0",
  Baseline: "U3RhdHVzVHlwZTox",
  InMaintainence: "U3RhdHVzVHlwZTo1",
  OnHold: "U3RhdHVzVHlwZTo2",
  Deleted: "U3RhdHVzVHlwZTo3",
  Generalization: "U3RhdHVzVHlwZToxMA==",
  WeekSessions: [],
  WeekSessionLoading: false,
  Appointments: [],
  AppointmentsLoading: false,
  Tasks: [],
  TasksLoading: false,

}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
