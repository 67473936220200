/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getData(payload) {
  return apolloClient
    .query({
      query: gql`{
        cogniableAreas{
          id,
          name,
        }
        students (isActive:true) {
          edges {
            node {
              id
              firstname
            }
          }
        }
        getCogniableAssessments(student: "${payload.studentId}", last: 15, isActive: true){
          edges{
            node{
              id,
              date,
              score,
              status,
              name,
              notes,  
              logoutStudent {
              id
              name
              dob
              email
              sex
              phone
              address
            }            
              student{
                id,
                firstname
              }  
            }
          }
        }
      }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong loading Data',
          description: item.message,
        })
      })
    })
}

export async function getFirstQuestion(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation GetFirstQuestion($pk: ID!) {
          getCogQuestion(input: { pk: $pk }) {
            question {
              id
              age
              question
              area {
                id
                name
              }
              options {
                edges {
                  node {
                    id
                    name
                    description
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        pk: payload.pk,
      },
    })
    .then(result => result)
    .catch(error => {
      if (error.graphQLErrors) {
        error.errors.map(item => {
          return notification.error({
            message: 'Somthing went wrong',
            description: item.message,
          })
        })
      }
    })
}

export async function createAssessment(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation CreateAssessment($studentId: ID!, $name: String!, $note: String) {
          startCogniableAssess(input: { student: $studentId, name: $name, notes: $note }) {
            details {
              id
              date
              score
              status
              name
              notes
              paymentSet(isPaid: true) {
                edges {
                  node {
                    id
                    isPaid
                    preliminaryAssessment {
                      id
                      date
                      name
                      status
                    }
                  }
                }
              }
              logoutStudent {
                id
                name
                dob
                email
                sex
                phone
                address
              }
              student {
                id
                firstname
              }
            }
          }
        }
      `,
      variables: {
        studentId: payload.studentId,
        name: payload.values.title,
        note: payload.values.note,
      },
    })
    .then(result => result)
    .catch(error => {
      if (error.graphQLErrors) {
        error.errors?.map(item => {
          return notification.error({
            message: 'Somthing went wrong',
            description: item.message,
          })
        })
      } else {
        error.map(item => {
          return notification.error({
            message: 'Somthing went wrong',
            description: item.message,
          })
        })
      }
    })
}

// assessment object id = Q29nbmlhYmxlQXNzZXNzbWVudFR5cGU6NTc=

export async function getAssessmentObject(payload) {
  return apolloClient
    .query({
      query: gql`{
        cogniableAreas{
          id,
          name,
        }
        getCogniableAssessDetail(id: "${payload.objectId}")
          { 
            id,
            date,
            score,
            status,
            name,
            notes,
            student{
              id,
              firstname
            }
            logoutStudent {
              id
              name
              dob
              email
              sex
              phone
              address
            }
            paymentSet(isPaid: true) {
              edges {
                node {
                  id
                  isPaid
                  preliminaryAssessment {
                      id
                      date
                      name
                      status
                    }
                }
              }
            }
            assessmentQuestions{
              edges{
                node{
                  id,
                  question{
                    id,
                    age
                    question
                    area {
                      id 
                      name
                    }
                    options{
                      edges{
                        node{
                          id
                          name
                          description
                        }
                      }
                    }
                  },
                  answer{
                    id,
                    name,
                    description
                  }
                }
              }
            }
            assessmentAreas{
              edges{
                node{
                  id,
                  response,
                  age
                  area{
                    id,
                    name,
                  }
                }
              }
            }
          }
        }`,
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong loading Data',
          description: item.message,
        })
      })
    })
}

export async function recordResponse(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation RecordResponse($objectId: ID!, $questionId: ID!, $answerId: ID) {
          recordCogQuestion(input: { pk: $objectId, question: $questionId, answer: $answerId }) {
            nextQuestion {
              id
              age
              question
              area {
                id
                name
              }
              options {
                edges {
                  node {
                    id
                    name
                    description
                  }
                }
              }
            }
            details {
              id
              date
              score
              status
              name
              notes
              logoutStudent {
                id
                name
                dob
                email
                sex
                phone
                address
              }
              student {
                id
                firstname
              }
              paymentSet(isPaid: true) {
                edges {
                  node {
                    id
                    isPaid
                    preliminaryAssessment {
                      id
                      date
                      name
                      status
                    }
                  }
                }
              }
              assessmentQuestions {
                edges {
                  node {
                    id
                    question {
                      id
                      age
                      question
                      area {
                        id
                        name
                      }
                      options {
                        edges {
                          node {
                            id
                            name
                            description
                          }
                        }
                      }
                    }
                    answer {
                      id
                      name
                      description
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        objectId: payload.objectId,
        questionId: payload.questionId,
        answerId: payload.answerId ? payload.answerId : null,
      },
    })
    .then(result => result)
    .catch(error => {
      if (error.graphQLErrors) {
        error.errors.map(item => {
          return notification.error({
            message: 'Somthing went wrong',
            description: item.message,
          })
        })
      } else {
        error.map(item => {
          return notification.error({
            message: 'Somthing went wrong',
            description: item.message,
          })
        })
      }
    })
}

export async function recordAreaResponse(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation RecordAreaResponse($objectId: ID!, $areaId: ID!, $response: String!) {
          recordCogniableAssessResult(
            input: { pk: $objectId, areas: [{ area: $areaId, response: $response }] }
          ) {
            details {
              id
              date
              score
              status
              name
              notes
              logoutStudent {
                id
                name
                dob
                email
                sex
                phone
                address
              }
              student {
                id
                firstname
              }
              assessmentAreas {
                edges {
                  node {
                    id
                    response
                    age
                    area {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        objectId: payload.objectId,
        areaId: payload.areaId,
        response: payload.response,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}

export async function endAssessment(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation EndAssessment($objectId: ID!, $status: String!) {
          updateCogAssessment(input: { pk: $objectId, status: $status }) {
            details {
              id
              date
              score
              status
              name
              notes
              student {
                id
                firstname
              }
              assessFrom
              paymentSet(isPaid: true) {
                edges {
                  node {
                    id
                    isPaid
                    preliminaryAssessment {
                      id
                      date
                      name
                      status
                    }
                  }
                }
              }
              logoutStudent {
                id
                name
                dob
                email
                sex
                phone
                address
              }
              assessmentQuestions {
                edges {
                  node {
                    id
                    question {
                      id
                      age
                      question
                      area {
                        id
                        name
                      }
                      options {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                    answer {
                      id
                      name
                      description
                    }
                  }
                }
              }
              assessmentAreas {
                edges {
                  node {
                    id
                    response
                    age
                    area {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        objectId: payload.objectId,
        score: payload.score,
        status: payload.status,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => {
      console.log('error>>>', error)
      error?.graphQLErrors?.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}

export async function endQuestionsAssessment(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation EndQuestionsAssessment($objectId: ID!, $status: String!) {
          updateCogAssessment(input: { pk: $objectId, status: $status }) {
            details {
              id
              date
              score
              status
              name
              notes
              student {
                id
                firstname
              }
              paymentSet(isPaid: true) {
                edges {
                  node {
                    id
                    isPaid
                    preliminaryAssessment {
                      id
                      date
                      name
                      status
                    }
                  }
                }
              }
              logoutStudent {
                id
                name
                dob
                email
                sex
                phone
                address
              }
              assessmentQuestions {
                edges {
                  node {
                    id
                    question {
                      id
                      age
                      question
                      area {
                        id
                        name
                      }
                      options {
                        edges {
                          node {
                            id
                            name
                            description
                          }
                        }
                      }
                    }
                    answer {
                      id
                      name
                      description
                    }
                  }
                }
              }
              assessmentAreas {
                edges {
                  node {
                    id
                    response
                    age
                    area {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        objectId: payload.objectId,
        status: payload.status,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}

export async function editQuestions(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation EditQuestions($objectId: ID!, $qusId: ID!, $ansId: ID!) {
          updateCogniableAssessment(input: { pk: $objectId, question: $qusId, answer: $ansId }) {
            status
            nextQuestion {
              id
              age
              question
              area {
                id
                name
              }
              options {
                edges {
                  node {
                    id
                    name
                    description
                  }
                }
              }
            }
            details {
              id
              date
              score
              assessmentQuestions {
                edges {
                  node {
                    id
                    question {
                      id
                      age
                      question
                      area {
                        id
                        name
                      }
                      options {
                        edges {
                          node {
                            id
                            name
                            description
                          }
                        }
                      }
                    }
                    answer {
                      id
                      name
                      description
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        objectId: payload.resObjectId,
        qusId: payload.qusId,
        ansId: payload.resultId,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}

export async function makeAssessmentInactive(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation EndAssessment($objectId: ID!) {
          updateCogAssessment(input: { pk: $objectId, isActive: false }) {
            details {
              id
              date
              score
              status
              name
            }
          }
        }
      `,
      variables: {
        objectId: payload.assessmentId,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}
export async function getDevelopmentalReport(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation GetCogniableReport($pk: String!) {
          cogniableAssessmentReport(input: { pk: $pk }) {
            status
            data
            file
          }
        }
      `,
      variables: {
        pk: payload.pk,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}
