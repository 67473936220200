/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_MASTERY_SEQ_TEMPLATE = gql`
  query($clinicId: ID!) {
    getCriteriaSequenceTemplate(clinicId: $clinicId) {
      id
      name
      templateType
      description
    }
  }
`

export const GET_CRITERIA_PHASE_BY_ID = gql`
  query($id: ID!) {
    getCriteriaPhase(id: $id) {
      id
      criteriaType {
        id
        statusName
      }
      prompts {
        edges {
          node {
            id
            promptName
          }
        }
      }
      autoLabel
      criteriaTemplate {
        id
        name
      }
    }
  }
`

export const DELETE_MASTERY_SEQ_TEMPLATE = gql`
  mutation($pk: ID!) {
    deleteCriteriaTemplate(input: { pk: $pk }) {
      status
      msg
    }
  }
`

export const GET_TEMPLATE_BY_ID = gql`
  query($id: ID!) {
    getCriteriaTemplate(id: $id) {
      id
      templateType
      criteriaphasesSet {
        edges {
          node {
            id
            criteriaType {
              id
              statusName
            }
            prompts {
              edges {
                node {
                  id
                  promptName
                }
              }
            }
            autoLabel
            stepNo
            criteriaTemplate {
              id
              name
            }
            createdAt
            updatedAt
            criteriaphasetargetsSet {
              edges {
                node {
                  id
                  calculationType
                  calFrameType
                  calFrameDurationType
                  duration
                  calFrameDurationValue
                  frequency
                  minimumTrial
                  durationCalType
                  percentage
                  progressTo
                  phaseChangeLabel
                }
              }
            }
          }
        }
      }
    }
  }
`

export const DELETE_CRTIERA_PHASE = gql`
  mutation($pk: ID!) {
    deleteCriteriaPhase(input: { pk: $pk }) {
      status
      msg
    }
  }
`

export const CREATE_CRITERIA_PHASE_TARGET = gql`
  mutation(
    $criteriaPhase: ID!
    $calculationType: String!
    $percentage: Int
    $calFrameType: String!
    $calFrameDurationType: String!
    $calFrameDurationValue: Int!
    $minimumTrial: Int!
    $progressTo: String!
    $phaseChangeLabel: String!
    $duration: Int
    $frequency: Int
    $durationCalType: String!
  ) {
    createCriteriaPhaseTarget(
      input: {
        calculationType: $calculationType
        calFrameType: $calFrameType
        durationCalType: $durationCalType
        calFrameDurationType: $calFrameDurationType
        calFrameDurationValue: $calFrameDurationValue
        percentage: $percentage
        minimumTrial: $minimumTrial
        progressTo: $progressTo
        criteriaPhase: $criteriaPhase
        phaseChangeLabel: $phaseChangeLabel
        duration: $duration
        frequency: $frequency
      }
    ) {
      status
      details {
        id
        calculationType
        calFrameType
        durationCalType
        calFrameDurationType
        calFrameDurationValue
        percentage
        minimumTrial
        frequency
        duration
        progressTo
        criteriaPhase {
          id
          criteriaType {
            id
            statusName
          }
          criteriaTemplate {
            id
            name
            templateType
          }
        }
      }
    }
  }
`

export const UPDATE_CRITERIA_PHASE_TARGET = gql`
  mutation(
    $pk: String!
    $calculationType: String
    $percentage: Int
    $calFrameType: String
    $calFrameDurationType: String
    $calFrameDurationValue: Int
    $minimumTrial: Int
    $progressTo: String
    $phaseChangeLabel: String
    $duration: Int
    $frequency: Int
  ) {
    updateCriteriaPhaseTarget(
      input: {
        pk: $pk
        calculationType: $calculationType
        calFrameType: $calFrameType
        calFrameDurationType: $calFrameDurationType
        calFrameDurationValue: $calFrameDurationValue
        percentage: $percentage
        minimumTrial: $minimumTrial
        progressTo: $progressTo
        phaseChangeLabel: $phaseChangeLabel
        duration: $duration
        frequency: $frequency
      }
    ) {
      status
      details {
        id
        calculationType
        calFrameType
        durationCalType
        calFrameDurationType
        calFrameDurationValue
        percentage
        minimumTrial
        progressTo
        criteriaPhase {
          id
          criteriaType {
            id
            statusName
          }
          criteriaTemplate {
            id
            name
            templateType
          }
        }
      }
    }
  }
`

export const DELETE_CRITERIA_PHASE_TARGET = gql`
  mutation($pk: ID!) {
    deleteCriteriaPhaseTarget(input: { pk: $pk }) {
      status
      msg
    }
  }
`

export const CREATE_MASTERY_SEQ_TEMPLATE = gql`
  mutation($name: String!, $templateType: String!, $description: String, $clinicId: ID!) {
    createCriteriaSequenceTempate(
      input: {
        name: $name
        description: $description
        clinicId: $clinicId
        templateType: $templateType
      }
    ) {
      status
      details {
        id
        name
        templateType
        school {
          id
          schoolName
          email
        }
        createdBy {
          id
          firstName
          email
        }
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const UPDATE_MASTERY_SEQ_TEMPLATE = gql`
  mutation($id: ID!, $name: String, $description: String) {
    updateCriteriaSequenceTempate(input: { id: $id, name: $name, description: $description }) {
      status
      details {
        id
        name
        templateType
        school {
          id
          schoolName
          email
        }
        createdBy {
          id
          firstName
          email
        }
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const CREATE_CRITERIA_PHASE = gql`
  mutation(
    $criteriaType: ID!
    $criteriaTemplate: ID!
    $stepNo: Int!
    $prompts: [ID]
    $autoLabel: String
  ) {
    createCriteriaPhase(
      input: {
        criteriaType: $criteriaType
        criteriaTemplate: $criteriaTemplate
        stepNo: $stepNo
        prompts: $prompts
        autoLabel: $autoLabel
      }
    ) {
      status
      details {
        id
        criteriaType {
          id
          statusName
        }
        prompts {
          edges {
            node {
              id
              promptName
            }
          }
        }
        autoLabel
        stepNo
        criteriaTemplate {
          id
          name
        }
        createdAt
        updatedAt
        criteriaphasetargetsSet {
          edges {
            node {
              id
              calculationType
              calFrameType
              calFrameDurationType
              duration
              durationCalType
              calFrameDurationValue
              frequency
              minimumTrial
              percentage
              progressTo
              phaseChangeLabel
            }
          }
        }
      }
    }
  }
`

export const UPDATE_CRITERIA_PHASE = gql`
  mutation($id: ID!, $prompts: [ID], $autoLabel: String) {
    updateCriteriaPhase(input: { id: $id, prompts: $prompts, autoLabel: $autoLabel }) {
      status
      details {
        id
        criteriaType {
          id
          statusName
        }
        stepNo
        autoLabel
        criteriaTemplate {
          id
          name
        }
        prompts {
          edges {
            node {
              id
              promptName
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_PROMPTS = gql`
  query {
    promptCodes {
      id
      promptName
    }
    targetStatus {
      id
      statusName
    }
  }
`
