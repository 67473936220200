import React from 'react'
import { Button, Form, Input, notification } from 'antd'
import { useMutation, useQuery } from 'react-apollo'
import { COLORS } from 'assets/styles/globalStyles'
import LoadingComponent from '../staffProfile/LoadingComponent'
import { CONTACT_DETAILS, UPDATE_CLINIC_DASH } from './query'

const ContactDetails = ({ form }) => {
  const {
    data: schoolData,
    error: schoolDataError,
    loading: schoolDataLoading,
  } = useQuery(CONTACT_DETAILS, { fetchPolicy: 'no-cache' })

  const [updateDetails, { loading: updateDetailsLoading }] = useMutation(UPDATE_CLINIC_DASH)

  const handelSubmit = e => {
    e.preventDefault()
    form.validateFields((error, values) => {
      if (!error) {
        updateDetails({
          variables: {
            email: values.email,
            address: values.address,
            contactNo: values.phone,
          },
        })
          .then(res => {
            notification.success({
              message: 'Contact details updated sucessfully',
            })
          })
          .catch(er => {
            console.error(er, 'error')
            notification.error({
              message: 'Something went wrong',
            })
          })
      }
    })
  }

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Form className="profileForm" onSubmit={handelSubmit} style={{ paddingTop: '1em' }}>
          {schoolDataLoading && <LoadingComponent />}
          {schoolDataError && 'Opps their something is wrong'}
          {schoolData && (
            <div>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Form.Item
                    label="Email"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ paddingRight: '24px', width: '52%' }}
                    className="form-label"
                  >
                    {form.getFieldDecorator('email', {
                      initialValue: schoolData.schoolDetail.email,
                      rules: [{ required: true, message: 'Please give the clinic email!' }],
                    })(<Input placeholder="Type clinic email" />)}
                  </Form.Item>

                  <Form.Item
                    label="Phone Number"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="form-label"
                    style={{ width: '48%' }}
                  >
                    {form.getFieldDecorator('phone', {
                      initialValue: schoolData.schoolDetail.contactNo,
                      rules: [{ required: true, message: 'Please give phone number!' }],
                    })(<Input placeholder="Type clinic phone number" />)}
                  </Form.Item>
                </div>
                <Form.Item
                  style={{ marginBottom: 24 }}
                  label="Address"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="form-label"
                >
                  {form.getFieldDecorator('address', {
                    initialValue: schoolData.schoolDetail.address,
                    rules: [
                      {
                        required: true,
                        message: 'Please give the clinic address!',
                      },
                    ],
                  })(<Input placeholder="Type clinic address" />)}
                </Form.Item>
              </div>

              <Form.Item style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: COLORS.submitBlue }}
                  loading={updateDetailsLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </div>
  )
}

export default Form.create()(ContactDetails)
