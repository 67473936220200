import React, { useState, useEffect, useRef } from 'react'
import { useQuery, useLazyQuery } from 'react-apollo'
import Highlighter from 'react-highlight-words'
import Timer from 'react-compound-timer'
import { Table, Button } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

import { groupBy } from 'lodash'
import moment from 'moment'

import { GET_BEHAVIOR_TEMPLATES } from '../../../components/BehaviourData/queries'

const EditableFRTable = ({
  behType,
  showDrawer,
  date,
  selectedBehavior,
  selectedStudentId,
  selectedBaheviorName,
}) => {
  const [templateData, setTemplateData] = useState([])
  const [dataRecord, setDataRecord] = useState([])
  const [phaseLines, setPhaseLines] = useState([])
  const [columns, setColumns] = useState()

  const [
    getTemplates,
    { data: tempData, error: templateError, loading: templateLoading, refetch: refetchTemplates },
  ] = useLazyQuery(GET_BEHAVIOR_TEMPLATES)

  useEffect(() => {
    if (selectedStudentId !== undefined) {
      getTemplates({
        variables: {
          student: selectedStudentId,
        },
        fetchPolicy: 'network-only',
      })
    }
  }, [selectedStudentId])
  useEffect(() => {
    if (tempData && tempData.getBehaviorTemplates) {
      tempData.getBehaviorTemplates.edges.map((element, index) => {
        const {
          behavior,
          behaviorType,
          frequencyratebehaviorrecordSet,
          durationbehaviorrecordSet,
          latencybehaviorrecordSet,
          partialintervalbehaviorrecordSet,
          wholeintervalbehaviorrecordSet,
          momentarytimebehaviorrecordSet,
          statusrecordSet,
        } = element.node
        let behaviorRecords
        let column
        if (behType === 'FR') {
          behaviorRecords = frequencyratebehaviorrecordSet.edges.map(({ node }) => ({
            id: node.id,
            date: node.date,
            startTime: node.startTime,
            endTime: node.endTime,
            duration: node.duration,
            frequency: node.frequency,
            environment: node.environment?.id,
            rate: node.rate,
            behaviorType: 'FR',
            tempData,
            behavior: behavior.behaviorName,
          }))

          column = [
            behaviorRecordColumns[0],
            behaviorRecordColumns[1],
            behaviorRecordColumns[2],
            behaviorRecordColumns[3],
            behaviorRecordColumns[5],
            behaviorRecordColumns[6],
            behaviorRecordColumns[11],
          ]
          setColumns(column)
        }
        if (behType === 'DR') {
          behaviorRecords = durationbehaviorrecordSet.edges.map(({ node }) => ({
            id: node.id,
            date: node.date,
            startTime: node.startTime,
            endTime: node.endTime,
            duration: node.duration,
            environment: node.environment?.id,
            behaviorType: 'DR',
            tempData,
            behavior: behavior.behaviorName,
          }))

          column = [
            behaviorRecordColumns[0],
            behaviorRecordColumns[1],
            behaviorRecordColumns[2],
            behaviorRecordColumns[3],
            behaviorRecordColumns[11],
          ]
          setColumns(column)
        }
        if (behType === 'LR') {
          behaviorRecords = latencybehaviorrecordSet.edges.map(({ node }) => ({
            id: node.id,
            date: node.date,
            startTime: node.startTime,
            endTime: node.endTime,
            environment: node.environment?.id,
            latency: node.latency,
            behaviorType: 'LR',
            tempData,
            behavior: behavior.behaviorName,
          }))
          column = [
            behaviorRecordColumns[0],
            behaviorRecordColumns[1],
            behaviorRecordColumns[2],
            behaviorRecordColumns[4],
            behaviorRecordColumns[11],
          ]
          setColumns(column)
        }
        if (behType === 'PI') {
          behaviorRecords = partialintervalbehaviorrecordSet.edges.map(({ node }) => {
            let count = 0
            for (let i = 0; i < node.intervalChecks.length; i += 1) {
              if (node.intervalChecks[i] === '1') {
                count += 1
              }
            }

            return {
              id: node.id,
              date: node.date,
              totalObservationTime: node.totalObservationTime,
              observedTime: node.observedTime,
              intervalLength: node.intervalLength,
              intervals: node.intervals,
              frequency: node.frequency,
              environment: node.environment?.id,
              recordingType: node.recordingType,
              intervalChecks: node.intervalChecks,
              durationRecords: node.durationRecords,
              behaviorType: 'PI',
              count,
              tempData,
              behavior: behavior.behaviorName,
            }
          })

          column = [
            behaviorRecordColumns[0],
            behaviorRecordColumns[7],
            behaviorRecordColumns[8],
            behaviorRecordColumns[9],
            behaviorRecordColumns[10],
            behaviorRecordColumns[5],
            behaviorRecordColumns[12],
            behaviorRecordColumns[11],
          ]
          setColumns(column)
        }
        if (behType === 'WI') {
          behaviorRecords = wholeintervalbehaviorrecordSet.edges.map(({ node }) => {
            let count = 0
            for (let i = 0; i < node.intervalChecks.length; i += 1) {
              if (node.intervalChecks[i] === '1') {
                count += 1
              }
            }

            return {
              id: node.id,
              date: node.date,
              totalObservationTime: node.totalObservationTime,
              observedTime: node.observedTime,
              intervalLength: node.intervalLength,
              intervals: node.intervals,
              frequency: node.frequency,
              environment: node.environment?.id,
              recordingType: node.recordingType,
              intervalChecks: node.intervalChecks,
              durationRecords: node.durationRecords,
              behaviorType: 'WI',
              count,
              tempData,
              behavior: behavior.behaviorName,
            }
          })

          column = [
            behaviorRecordColumns[0],
            behaviorRecordColumns[7],
            behaviorRecordColumns[8],
            behaviorRecordColumns[9],
            behaviorRecordColumns[10],
            behaviorRecordColumns[5],
            behaviorRecordColumns[12],
            behaviorRecordColumns[11],
          ]
          setColumns(column)
        }
        if (behType === 'MT') {
          behaviorRecords = momentarytimebehaviorrecordSet.edges.map(({ node }) => {
            let count = 0
            for (let i = 0; i < node.intervalChecks.length; i += 1) {
              if (node.intervalChecks[i] === '1') {
                count += 1
              }
            }

            return {
              id: node.id,
              date: node.date,
              totalObservationTime: node.totalObservationTime,
              observedTime: node.observedTime,
              intervalLength: node.intervalLength,
              intervals: node.intervals,
              frequency: node.frequency,
              rangeMin: node.rangeMin,
              rangeMax: node.rangeMax,
              environment: node.environment?.id,
              recordingType: node.recordingType,
              intervalChecks: node.intervalChecks,
              durationRecords: node.durationRecords,
              behaviorType: 'MT',
              count,
              tempData,
              behavior: behavior.behaviorName,
            }
          })

          column = [
            behaviorRecordColumns[0],
            behaviorRecordColumns[7],
            behaviorRecordColumns[8],
            behaviorRecordColumns[9],
            behaviorRecordColumns[10],
            behaviorRecordColumns[5],
            behaviorRecordColumns[12],
            behaviorRecordColumns[11],
          ]
          setColumns(column)
        }

        if (statusrecordSet) {
          const status = statusrecordSet.edges.map(({ node }) => {
            return {
              date: moment(node.statusTime).format('YYYY-MM-DD'),
              status: node.status.statusName,
            }
          })
          setPhaseLines(olddata => [
            ...olddata,
            {
              status,
              behavior,
            },
          ])
        }
        const grouped = groupBy(behaviorRecords, 'date')
        const data = []
        Object.keys(grouped).forEach(item => {
          //   let duration = 0
          //   let frequency = 0
          //   let rate = 0
          //   grouped[item].forEach(d => {
          //     duration += d.duration
          //     frequency += d.frequency
          //     rate += d.rate
          //   })
          data.push({
            date: item,
            data: grouped[item],
            behavior,
          })
        })
        setDataRecord(olddata => [
          ...olddata,
          {
            behName: behavior.behaviorName,
            behId: behavior.id,
            data,
          },
        ])

        return templateData.push(element)
      })
    }
  }, [tempData, selectedStudentId, selectedBehavior])

  const getRateUnit = rateUnit => {
    console.log(rateUnit)
    let x
    switch (rateUnit) {
      case 'SC':
        x = 'seconds'
        break
      case 'MN':
        x = 'minutes'
        break
      case 'HR':
        x = 'hours'
        break
      case 'DY':
        x = 'days'
        break
      default:
        x = 'seconds'
    }
    return x
  }

  const behaviorRecordColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text, row) => text,
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      align: 'center',
      colSpan: 1,
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      align: 'center',
      colSpan: 1,
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      align: 'center',
      colSpan: 1,
    },
    {
      title: 'Latency',
      dataIndex: 'latency',
      align: 'center',
      colSpan: 1,
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      align: 'center',
      colSpan: 1,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      align: 'right',
      colSpan: 1,
      render: (text, record) => {
        return `${record.rate.toFixed(2)} ${getRateUnit(record.rateUnit)}`
      },
    },
    {
      title: 'Total Observation Time',
      dataIndex: 'totalObservationTime',
      align: 'center',
      colSpan: 1,
      render: (text, record) => {
        return `${text} min`
      },
    },
    {
      title: 'Remaining Time',
      dataIndex: 'observedTime',
      align: 'center',
      colSpan: 1,
      render: (text, record) => {
        const min = Math.floor(text / 60)
        const sec = text % 60
        if (min > 0) {
          return `${min} min ${sec} sec`
        }
        return `${sec} sec`
      },
    },
    {
      title: 'Length of Interval',
      dataIndex: 'intervalLength',
      align: 'center',
      colSpan: 1,
    },
    {
      title: 'Intervals',
      dataIndex: 'intervals',
      align: 'center',
      colSpan: 1,
    },
    {
      title: 'Action(s)',
      dataIndex: '',
      align: 'center',
      render: obj => (
        <a
          type="link"
          href="#/LearnerBehavior"
          style={{ color: 'blue' }}
          onClick={() => {
            console.log('clicked-->', obj)
          }}
        >
          <EditOutlined />
        </a>
      ),
    },
    {
      title: 'Count',
      dataIndex: 'count',
      align: 'center',
      colSpan: 1,
    },
  ]
  const getData = () => {
    const dataSource = []
    if (dataRecord) {
      dataRecord.forEach(item => {
        if (item.behName.toLowerCase() === selectedBaheviorName.toLowerCase()) {
          item.data.forEach(data => {
            if (data.date === date) {
              dataSource.push(...data.data)
            }
          })
        }
      })
    }
    return dataSource
  }

  return (
    <div>
      <Table
        loading={templateLoading}
        columns={columns}
        dataSource={getData()}
        pagination="true"
        bordered
      />
    </div>
  )
}

export default EditableFRTable
