/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-else-return */
/* eslint-disable eqeqeq */
import { FilterOutlined, LineChartOutlined } from '@ant-design/icons'
import { ResponsivePie } from '@nivo/pie'

import { ResponsiveBar } from '@nivo/bar'

import {
  Button,
  DatePicker,
  Select,
  Drawer,
  Modal,
  Dropdown,
  Popover,
  Icon,
  Menu,
  Tooltip,
  Table,
  Form,
  Checkbox,
} from 'antd'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useQuery, useLazyQuery } from 'react-apollo'
import { FaDownload } from 'react-icons/fa'
import * as XLSX from 'xlsx'
import '../form.scss'
import './style.scss'
import { countBy, groupBy, flatten, reduce, includes, flattenDeep } from 'lodash'
import CheckboxGroup from 'antd/lib/checkbox/Group'
import RangeDatePicker from 'components/DatePicker/DatePicker'
import { GET_STUDENT_SESSION_NAME, TARGET_ALLOCATIONS_OPTIONS } from '../query'
import { SESSION_DATA, GET_TARGET_TYPE_OPTIONS } from './query'
import SessionReportPdf from './sessionReportPdf'
import '../table.scss'
import useSessionTableColumns, { CELL_FORMATS } from './useSessionTableColumns'
import { convertToHourMinSecondsStr, getCellFormatter } from './utils'

const { RangePicker } = DatePicker
const { Option } = Select

const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
  alignItems: 'center',
}

const parentDiv = { display: 'flex', marginRight: 20 }

const DEFAULT_DATE_DIFF_IN_DAYS = 7
const CODED = 'VGFyZ2V0RGV0YWlsVHlwZTo4'
const SBT = 'VGFyZ2V0RGV0YWlsVHlwZToxMA=='
const BEH_RECORDING = 'VGFyZ2V0RGV0YWlsVHlwZToxMQ=='

const SessionReport = ({ studentName }) => {
  const [dateRange, setDateRange] = useState([
    moment().subtract(DEFAULT_DATE_DIFF_IN_DAYS, 'days'),
    moment(),
  ])
  const [session, setSession] = useState('U2Vzc2lvbk5hbWVUeXBlOjE=')
  const studentId = JSON.parse(localStorage.getItem('studentId') || '')
  const [lineDrawer, setLineDrawer] = useState(false)
  const [barGraphData, setBarGraphData] = useState([])
  const [currentRow, setCurrentRow] = useState(null)
  const [reportPdfDrawer, setReportPdfDrawer] = useState(false)
  const [pointValue, setPointValue] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [barData, setBarData] = useState([])
  const [keys, setKeys] = useState([])
  const [targetType, setTargetType] = useState([])
  const [tType, setTType] = useState('all')
  const [filteredData, setFiltered] = useState([])
  const [selectSessionId, setSelectSessionId] = useState()
  const [table, setTable] = useState([])
  const [selectedTargetTypes, setSelectedTargetTypes] = useState([])
  const [cellFormat, setCellFormat] = useState(CELL_FORMATS.COUNT)
  const [checkedTableColumns, setCheckedTableColumns] = useState(null)

  const { data: sessionNames, error: sessionNamesError, loading: sessionNamesLoading } = useQuery(
    GET_STUDENT_SESSION_NAME,
    {
      variables: {
        studentId,
      },
      fetchPolicy: 'network-only',
    },
  )

  const {
    data: targetTypeOptionsData,
    error: targetTypeOptionsError,
    loading: targetTypeOptionsLoading,
  } = useQuery(GET_TARGET_TYPE_OPTIONS, {
    fetchPolicy: 'network-only',
  })

  const { data: dt, error: er, loading: ld } = useQuery(SESSION_DATA, {
    variables: {
      targets_StudentId: studentId,
      session,
      date_Gte: dateRange.at(0)?.format('YYYY-MM-DD'),
      date_Lte: dateRange.at(1)?.format('YYYY-MM-DD'),
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: targetOptions,
    error: targetOptionsError,
    loading: targetOptionsLoading,
  } = useQuery(TARGET_ALLOCATIONS_OPTIONS, {
    fetchPolicy: 'network-only',
  })

  const setAndOpenPDFDrawer = useCallback(id => {
    setSelectSessionId(id)
    setReportPdfDrawer(true)
  }, [])

  const { columns: tableColumns, width, columnOptions } = useSessionTableColumns(
    selectedTargetTypes,
    cellFormat,
    checkedTableColumns,
    setAndOpenPDFDrawer,
  )
  // console.log('selectedTargetTypes,__', selectedTargetTypes)
  // console.log('cellFormat,__', cellFormat)
  // console.log('checkedTableColumns,__', checkedTableColumns)
  // console.log('setAndOpenPDFDrawer,__', setAndOpenPDFDrawer)

  // console.log('tableColumns__', tableColumns)
  // console.log('width__', width)
  // console.log('columnOptions__', columnOptions)

  useEffect(() => {
    if (targetOptions && targetOptions.types) {
      const tempType = targetOptions.types.filter(
        node =>
          node.id !== 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==' && node.id !== 'VGFyZ2V0RGV0YWlsVHlwZToxMA==',
      )
      setTargetType(tempType)
    }
  }, [targetOptions])

  useEffect(() => {
    setCheckedTableColumns(columnOptions)
  }, [columnOptions])

  /**
   * Extract necessary fields into node for furthur processing.
   * @param {node} sessionDataNode edge node from getSessionDataRecording query
   * @returns node in same format.
   */
  const normalizeTarget = sessionDataNode => {
    const node = sessionDataNode
    let targetNode = {
      key: node.id,
      targetName: node.targets.targetAllcatedDetails.targetName,
      sessionId: node.ChildSession.id,
      sessionDate: node.ChildSession.sessionDate,
      sessionDuration: node.ChildSession.duration,
      sessionNo: node.ChildSession.sessionNo,
      sessionName: node.ChildSession.sessions.sessionName.name,
      targetType: 'regular',
      type: 'target',
      targetNote: node.note,
      duration: (node.durationEnd || 0) - (node.duractionStart || 0),
      data: node.sessionRecord,
      targetTypeFilter: node.targets.targetAllcatedDetails.targetType.typeTar,
    }
    let trial = 0
    let correct = 0
    let incorrect = 0
    let prompt = 0
    let target = node.sessionRecord
    if (node?.targets?.targetAllcatedDetails?.targetType?.id === CODED) {
      target = node.peak
      targetNode.targetType = 'peak'
      targetNode.data = node.peak
    } else if (node.isPeakEquivalance) {
      target = node.peakEquivalance
      targetNode.targetType = 'relational'
      targetNode.data = node.peakEquivalance
    } else if (node?.targets?.targetAllcatedDetails?.targetType?.id === SBT) {
      targetNode.targetType = 'sbt'
      targetNode.data = node.behReduction
      target = node.behReduction
    } else if (node?.targets?.targetAllcatedDetails?.targetType?.id === BEH_RECORDING) {
      let freq = 0
      let duration = 0
      node.behaviourRecording.edges.forEach(({ node: nd }) => {
        duration += Math.abs(Number(nd.end) - Number(nd.start))
        freq += nd.frequency
      })
      targetNode.data = node.behaviourRecording
      targetNode.targetType = 'behRecording'

      targetNode.behDuration = duration
      targetNode.behFrequency = freq
    }
    trial += target.totalTrial
    correct += target.totalCorrect
    incorrect += target.totalIncorrect != undefined ? target.totalIncorrect : target.totalError || 0
    prompt += target.totalPrompt
    // data which will be rendered in table contains under section like
    // peak {
    //    correct: 0
    //    incorrect: 0
    // ...}
    targetNode = {
      ...targetNode,
      [targetNode.targetType]: {
        correct,
        incorrect,
        prompt,
        trial,
        get total() {
          return this.trial
        },
      },
    }
    // console.log('sessionDataNode>>>', node)
    // console.log('targetNode>>>>', targetNode)
    return targetNode
  }

  /**
   * Helper function to create data node.
   * @returns data node with required properties.
   */
  const createEmptyNodeObj = () => {
    return {
      correct: 0,
      incorrect: 0,
      prompt: 0,
      trial: 0,
      duration: 0,
      get total() {
        return this.trial
      },
    }
  }
  // let p=0;
  const createEmptyMandObj = () => {
    // dt?.getSessionDataRecording?.mandData.filter(item=>
    //   p+=item.promptedCount
    //   )
    return {
      pData: 0,
      iData: 0,
      mandCount: 0,
    }
  }

  /**
   * Accumulates values into targetNode.
   * @param {Object} targetNode Node in which values are accumulated.
   * @param {Object} srcNode Node from which values are added
   * @param {Boolean} addDur Flag to add duration or not.
   */
  const addValues = (targetNode, srcNode, addDur = false) => {
    // console.log('addValues>>>>', targetNode, srcNode)
    targetNode.correct += srcNode.correct
    targetNode.incorrect += srcNode.incorrect
    targetNode.prompt += srcNode.prompt
    targetNode.trial += srcNode.trial
    if (addDur) {
      targetNode.duration += srcNode.duration || 0
    }
  }
  let PTotal = 0
  let ITotal = 0
  let MandcountTotal = 0
  let Name = '2022-00-00'

  const addValuesMand = (parentNode, node, Manddata, InitalValue) => {
    // console.log('parentNode', parentNode)
    // console.log('node', node)
    // console.log('Manddata', Manddata)
    // console.log('InitalValue', InitalValue)
    // console.log('parentNode date', parentNode.name)
    if (InitalValue) {
      if (parentNode.name === Name) {
        // console.log('parentNode', true)
      } else {
        Name = parentNode.name
        PTotal = 0
        ITotal = 0
        MandcountTotal = 0
        // console.log('parentNode', false)
      }
    }
    let Prom = 0
    let Idata = 0
    let Total = 0
    if (InitalValue) {
      Manddata.edges.map(item => {
        PTotal += item.node.promptedCount
        ITotal += item.node.data
        return 0
      })
      MandcountTotal += Manddata.edges.length

      // console.log('trueeeeeeeeeee')
      // console.log('22parentNode__', parentNode.mandData)
      // console.log('11parentNode__', parentNode)
      // console.log('22Manddata__', Manddata)
      // console.log('22node__', node)
      // console.log(parentNode.mandData)
      parentNode.mandData.pData = PTotal
      parentNode.mandData.iData = ITotal
      parentNode.mandData.mandCount = MandcountTotal
    } else {
      // console.log('Falseeeeeeeeee')
      // console.log("node.key.split[0]",node.key.split(' ')[0]);
      // console.log("parentNode.key",parentNode.key);
      // // if(parentNode.key===node.key.split[0])
      // PTotal = 0
      // ITotal = 0
      // MandcountTotal = 0
    }
    // console.log('PTotal', PTotal)
    // console.log('ITotal', ITotal)
    // console.log('MandcountTotal', MandcountTotal)

    const xx = Manddata?.edges.filter(({ node }, index) => {
      Prom += node.promptedCount
      Idata += node.data
      Total = index + 1
      return Prom + Idata
    })

    if (Manddata?.edges !== []) {
      parentNode.iData = Idata
      parentNode.pData = Prom
      parentNode.mandCount = Total
    }
    // console.log('parentNode__', parentNode)
    // console.log('Manddata__', Manddata)
    // console.log('node__', node)

    // console.log(Prom)
    // console.log(Idata)
    // console.log(Total)
    // console.log('parentNodeZZZ', parentNode)
  }

  /**
   * Returns count of correct, incorrect, trial, prompt from array of objects
   * with proprty key.
   * Ex [{trial: 'INCORRECT'}, {trial: 'CORRECT'}]
   * above array will result in object {INCORRECT: 1, CORRECT: 1}
   * @param {Array<Trial>} data array of object which contains trial property.
   * @param {string} key values will be counted by this key.
   * @returns an object with count of different values for key in object of array.
   */
  const countTrialResponses = (data, key = 'trial') => {
    const trialCount = countBy(data, key)
    const responseObj = {
      correct: trialCount.CORRECT || 0,
      incorrect: (trialCount.INCORRECT || 0) + (trialCount.ERROR || 0),
      prompt: trialCount.PROMPT || 0,
      get total() {
        return this.trial
      },
    }
    responseObj.trial = data.length
    return responseObj
  }

  /**
   * Create Stimulus string for targets.
   * @param {stimulus options} stimuluses options of stimuluses
   * @param {record} relationTest value of trial
   * @returns Stimulus string from equation like A = B
   */
  const createStimuliStr = (stimuluses, relationTest) => {
    const { stimulus1, sign12, stimulus2, sign23, stimulus3 } = relationTest

    if (stimulus1 && sign12 && stimulus2) {
      const text1 = stimuluses.find(val => val.option === stimulus1)?.stimulusName
      const text2 = stimuluses.find(val => val.option === stimulus2)?.stimulusName
      // console.log('session text some ', text1, sign12, text2)
      return `${text1} ${sign12} ${text2}`
    }

    const text1 = stimuluses.find(val => val.option === stimulus2)?.stimulusName
    const text2 = stimuluses.find(val => val.option === stimulus3)?.stimulusName
    return `${text1} ${sign23} ${text2}`
  }

  /**
   * Utility function to remove empty children property so that
   * antd doesn't show checkbox for expanding row.
   * @param node node from which empty children property will be set to null
   */
  const removeEmptyChildren = node => {
    if (node?.children?.length == 0) {
      node.children = null
    }
  }

  const handleSbtTarget = node => {
    if (!node.data.edges) {
      return
    }
    const steps = node.data.edges.map(({ node }) => node)
    let totalDuration = 0
    steps.forEach(step => {
      totalDuration += step.duration
    })
    const groupBySteps = groupBy(steps, 'sbtStep.id')
    node.children = []
    Object.keys(groupBySteps).forEach((key, index) => {
      if (key === 'undefined') {
        return
      }
      const trialCount = countTrialResponses(groupBySteps[key])
      let r1 = ''
      let r2 = ''
      let r1Count = 0
      let r2Count = 0
      let r1Duration = 0
      let r2Duration = 0
      const prompts = groupBy(groupBySteps[key], 'prompt.id')
      groupBySteps[key].forEach(node => {
        if (node.r1) {
          r1 = node.r1.behaviorName
          r1Duration += node.duration
        }
        if (node.r2) {
          r2 = node.r2.behaviorName
          r2Duration += node.duration
        }
        r1Count += node.r1Count || 0
        r2Count += node.r2Count || 0
      })
      node.r1Duration = (node.r1Duration || 0) + r1Duration
      node.r2Duration = (node.r2Duration || 0) + r2Duration
      if (!node.r1) {
        node.r1 = r1
      }
      if (!node.r2) {
        node.r2 = r2
      }
      node.r1Count = (node.r1Count || 0) + r1Count
      node.r2Count = (node.r2Count || 0) + r2Count
      const promptNodes = []
      Object.keys(prompts).forEach((key, ind) => {
        if (key === 'undefined') {
          return
        }
        promptNodes.push({
          name: prompts[key][0].prompt.promptName,
          key: `prompt ${Math.random()}`,
          className: 'sessionTableRow__prompt',
          type: 'prompt',
          [node.targetType]: {
            prompt: prompts[key].length,
            total: trialCount.prompt,
          },
        })
      })
      node.children.push({
        key: `${node.key} ${Math.random()}`,
        name: groupBySteps[key].length > 0 ? groupBySteps[key][0].sbtStep.description : '-',
        [node.targetType]: trialCount,
        trial: groupBySteps[key].length,
        type: 'step',
        r1,
        r2,
        r1Count,
        r2Count,
        className: 'sessionTableRow__steps',
        children: promptNodes.length > 0 ? promptNodes : null,
      })
    })
    node.duration = totalDuration
    removeEmptyChildren(node)
  }

  const handlePeakEquivalance = node => {
    if (!node.data.edges) {
      return
    }
    const trials = node.data.edges.map(({ node }) => node)
    const groupByStimulus = groupBy(trials, 'codeClass.id')
    const totalDuration = reduce(
      trials,
      (sum, curr) => {
        return sum + calculateDuration(curr)
      },
      0,
    )
    node.children = []
    Object.keys(groupByStimulus).forEach(key => {
      if (key === 'undefined') {
        return
      }
      const val = groupByStimulus[key]
      let correct = 0
      let incorrect = 0
      let prompt = 0
      let trial = 0
      let name = ''
      const prompts = {}
      val.forEach(({ relationTest, codeClass, score }) => {
        if (score == 0) {
          incorrect += 1
        } else if (score == 10) {
          correct += 1
        } else {
          prompts[score] = (prompts[score] || 0) + 1
          prompt += 1
        }
        trial += 1
        if (!name && relationTest) {
          name = createStimuliStr(
            codeClass.stimuluses.edges.map(({ node }) => node),
            relationTest,
          )
        }
      })
      const outerKey = key
      if (name) {
        node.children.push({
          type: 'stimulus',
          key: `${key} ${node.key} ${Math.random()}`,
          className: 'sessionTableRow__stimulus',
          name,
          [node.targetType]: {
            correct,
            incorrect,
            prompt,
            trial,
            get total() {
              return this.trial
            },
          },
          children: Object.keys(prompts).map(key => ({
            key: `${key} ${node.key} ${outerKey}`,
            name: `${key} - Prompt`,
            className: 'sessionTableRow__prompt',
            type: 'prompt',
            [node.targetType]: {
              prompt: prompts[key],
              total: prompt,
            },
          })),
        })
      }
    })
    node.duration = totalDuration
    removeEmptyChildren(node)
  }

  const handlePeakTarget = node => {
    if (!node.data.edges) {
      return
    }
    const trials = flatten(node.data.edges.map(({ node }) => node.trial.edges)).map(
      ({ node }) => node,
    )
    const totalDuration = reduce(
      trials,
      (sum, curr) => {
        return sum + ((curr.end || 0) - (curr.start || 0))
      },
      0,
    )

    const groupBySd = groupBy(trials, 'sd.id')
    node.children = []
    Object.keys(groupBySd).forEach(key => {
      const val = groupBySd[key]
      const prompts = {}
      let correct = 0
      let incorrect = 0
      let prompt = 0
      let trial = 0
      let name = ''
      val.forEach(({ sd, marks }) => {
        if (marks == 0) {
          incorrect += 1
        } else if (marks == 10) {
          correct += 1
        } else {
          prompts[marks] = (prompts[marks] || 0) + 1
          prompt += 1
        }
        trial += 1
        name = sd.sd
      })

      const targetKey = key
      node.children.push({
        type: 'steps',
        key: `${key} ${node.key} ${Math.random()}`,
        name,
        className: 'sessionTableRow__steps',
        [node.targetType]: {
          correct,
          incorrect,
          prompt,
          trial,
          get total() {
            return this.trial
          },
        },
        children: Object.keys(prompts).map((key, ind) => ({
          key: `${key} ${ind} ${node.key} ${targetKey}`,
          name: `${key} - Prompt`,
          type: 'prompt',
          className: 'sessionTableRow__prompt',
          [node.targetType]: {
            prompt: prompts[key],
            total: prompt,
          },
        })),
      })
    })
    node.duration = totalDuration
    removeEmptyChildren(node)
  }

  const calculateDuration = node => {
    return (node.durationEnd || 0) - (node.durationStart || 0)
  }

  const handleRegularTarget = node => {
    if (!node.data.edges) {
      return
    }
    const nodes = node.data.edges.map(({ node }) => node)
    const totalDuration = reduce(
      nodes,
      (sum, curr) => {
        return sum + calculateDuration(curr)
      },
      0,
    )
    let steps = null
    let stimulus = null
    if (nodes.length && nodes[0].sd) {
      stimulus = groupBy(nodes, 'sd.id')
    } else if (nodes.length && nodes[0].step) {
      steps = groupBy(nodes, 'step.id')
    }
    if (stimulus) {
      node.children = Object.keys(stimulus).map((key, ind) => {
        const trialCount = countTrialResponses(stimulus[key])
        const promptsCode = groupBy(stimulus[key], 'promptCode.id')

        const promptNodes = []
        Object.keys(promptsCode).forEach(key => {
          if (key !== 'undefined' && promptsCode[key].length > 0) {
            promptNodes.push({
              key: `${promptsCode[key][0].id} ${Math.random()}`,
              name: promptsCode[key][0].promptCode.promptName,
              className: 'sessionTableRow__prompt',
              type: 'prompt',
              [node.targetType]: {
                prompt: promptsCode[key].length,
                get total() {
                  return trialCount.prompt
                },
              },
            })
          }
        })

        return {
          key: `${key} ${ind} ${node.key}`,
          name: stimulus[key][0].sd.sd,
          type: 'stimulus',
          className: 'sessionTableRow__stimulus',
          [node.targetType]: trialCount,
          children: promptNodes,
        }
      })
    }
    if (steps) {
      node.children = Object.keys(steps).map((key, ind) => {
        const trialCount = countTrialResponses(steps[key])
        const promptsCode = groupBy(steps[key], 'promptCode.id')

        const promptNodes = []
        Object.keys(promptsCode).forEach((key, index) => {
          if (key !== 'undefined' && promptsCode[key].length > 0) {
            promptNodes.push({
              name: promptsCode[key][0].promptCode.promptName,
              key: `${key} ${Math.random()}`,
              className: 'sessionTableRow__prompt',
              type: 'prompt',
              [node.targetType]: {
                prompt: promptsCode[key].length,
                get total() {
                  return trialCount.prompt
                },
              },
            })
          }
        })
        return {
          key: `${key} ${ind} ${node.key}`,
          type: 'steps',
          className: 'sessionTableRow__steps',
          name: steps[key][0].step.step,
          [node.targetType]: trialCount,
          children: promptNodes,
        }
      })
    }
    node.duration = totalDuration
  }

  const processTargetNode = (parentNode, node, Manddata) => {
    addValuesMand(parentNode.mandData, node, Manddata, false)
    if (node.targetType === 'peak') {
      addValues(parentNode.peak, node.peak)
      handlePeakTarget(node)
    } else if (node.targetType === 'relational') {
      addValues(parentNode.relational, node.relational)
      handlePeakEquivalance(node)
    } else if (node.targetType === 'sbt') {
      addValues(parentNode.sbt, node.sbt)
      handleSbtTarget(node)
    } else if (node.targetType === 'regular') {
      addValues(parentNode.regular, node.regular)
      handleRegularTarget(node)
    } else if (node.targetType === 'behRecording') {
      parentNode.behDuration = (parentNode.behDuration || 0) + node.behDuration
      parentNode.behFrequency = (parentNode.behFrequency || 0) + node.behFrequency
    }

    if (node.targetType != 'behRecording') {
      addValues(parentNode, node[node.targetType])
    }
    if (node[node.targetType].trial > 0 || node.targetType === 'behRecording') {
      parentNode.children.push({
        ...node,
        className: 'sessionTableRow__target',
        showDuration: true,
        name: `${parentNode.children.length + 1}. ${node.targetName}`,
      })
    }
  }

  const processSessionNode = (parentNode, session) => {
    const sessionNode = createEmptyNodeObj()
    sessionNode.peak = createEmptyNodeObj()
    sessionNode.relational = createEmptyNodeObj()
    sessionNode.sbt = createEmptyNodeObj()
    sessionNode.regular = createEmptyNodeObj()
    sessionNode.mandData = createEmptyMandObj()
    sessionNode.mandCount = 0
    sessionNode.children = []
    sessionNode.showDuration = true
    const { targets, sessionData } = session

    // console.log('parentNode', parentNode)
    // console.log('session***', session)
    // console.log('targets', targets)
    // console.log('sessionData', sessionData.dailyClickDataSet)
    targets.forEach(target => {
      processTargetNode(sessionNode, target, sessionData.dailyClickDataSet)
    })
    sessionNode.key = `${parentNode.key} ${targets[0].sessionId}`
    sessionNode.name = `${targets[0].sessionName} ${targets[0].sessionNo}`
    sessionNode.sessionId = `${targets[0].sessionId}`
    sessionNode.type = 'session'
    sessionNode.duration = targets[0].sessionDuration
    sessionNode.className = 'sessionTableRow__session'
    addValues(parentNode.peak, sessionNode.peak)
    addValues(parentNode.relational, sessionNode.relational)
    addValues(parentNode.sbt, sessionNode.sbt)
    addValues(parentNode.regular, sessionNode.regular)
    addValues(parentNode, sessionNode, true)
    addValuesMand(parentNode, sessionNode, sessionData.dailyClickDataSet, true)
    // if (sessionNode.behDuration > 0) {
    //   if (!parentNode.behDuration) {
    //     parentNode.behDuration = sessionNode.behDuration
    //   } else {
    //     parentNode.behDuration += sessionNode.behDuration
    //   }
    // }
    const sessionBehavior = []
    if (session.sessionData) {
      const behData = session.sessionData.frequencyratebehaviorrecordSet.edges.map(
        ({ node }) => node,
      )

      let totalBehDuration = 0
      let totalFrequency = 0

      behData.forEach(val => {
        const behNode = {
          id: val.id,
          duration: val.duration * 1000,
          showDuration: true,
          type: 'sessionBehavior',
          key: `${parentNode.key} ${val.id}`,
          frequency: val.frequency,
          name: val.studentBehaviorTemplate.behavior.behaviorName,
          behDuration: val.duration * 1000,
          behFrequency: val.frequency,
        }
        totalBehDuration += val.duration * 1000
        totalFrequency += val.frequency
        sessionBehavior.push(behNode)
      })

      if (sessionBehavior.length > 0) {
        sessionNode.children.push({
          key: `${sessionNode.sessionId} behavior`,
          name: 'Session Behavior Recording',
          type: 'target',
          className: 'sessionTableRow__target',
          duration: totalBehDuration,
          behDuration: totalBehDuration,
          behFrequency: totalFrequency,
          showDuration: true,
          children: sessionBehavior,
        })
      }
    }
    // console.log('sessionNode*****', sessionNode)
    parentNode.behDuration = (parentNode.behDuration || 0) + sessionNode.behDuration
    parentNode.behFrequency = (parentNode.behFrequency || 0) + sessionNode.behFrequency
    if (
      sessionNode.trial > 0 ||
      sessionNode.behDuration > 0 ||
      sessionBehavior.length > 0 ||
      sessionNode?.mandData?.mandCount > 0
    ) {
      parentNode.children.push(sessionNode)
    }
  }

  const filteredTableData = useMemo(() => {
    if (!dt) {
      return null
    }
    const filteredData = dt.getSessionDataRecording.edges.filter(({ node }) => {
      const targetType = node.targets.targetAllcatedDetails.targetType.typeTar
      // console.log('target Type', targetType, selectedTargetTypes)

      return selectedTargetTypes.length === 0 || includes(selectedTargetTypes, targetType)
    })
    return filteredData
  }, [dt, selectedTargetTypes, session])

  // console.log('filteredDataxx', filteredData)
  // console.log('dtxx', dt)
  // console.log('selectedTargetTypesxx', selectedTargetTypes)

  const childSessionCompareFn = (a, b) => {
    const aMoment = moment(a.ChildSession.createdAt)
    const bMoment = moment(b.ChildSession.createdAt)
    if (aMoment.isSame(bMoment)) {
      return 0
    }
    if (aMoment.isBefore(bMoment)) {
      return -1
    } else {
      return 1
    }
  }

  useEffect(() => {
    if (!filteredTableData) {
      return
    }
    // sorting targets according to child session
    const allTargets = filteredTableData.map(({ node }) => node).sort(childSessionCompareFn)
    // console.log(allTargets)
    const groupedTargetsByDate = groupBy(allTargets, 'ChildSession.sessionDate')
    // console.log('groupedTargetsByDate', groupedTargetsByDate)
    Object.keys(groupedTargetsByDate).forEach(key => {
      const targets = groupedTargetsByDate[key].map(normalizeTarget)
      const childSessions = groupedTargetsByDate[key].map(node => node.ChildSession)
      const groupedBySession = groupBy(targets, 'sessionId')
      const groupedSessionData = groupBy(childSessions, 'id')
      groupedTargetsByDate[key] = Object.keys(groupedBySession).map(key => ({
        key,
        targets: groupedBySession[key],
        sessionData: groupedSessionData[key].length && groupedSessionData[key][0],
      }))
    })
    const tableData = []
    Object.keys(groupedTargetsByDate).forEach(key => {
      const sessions = groupedTargetsByDate[key]
      const dataObj = {
        // ritik: 'RRR',
        mandData: createEmptyMandObj(),
        type: `sessionDateWise`,
        // mandCount: dt?.getSessionDataRecording?.mandCount
        //   ? +dt?.getSessionDataRecording?.mandCount
        //   : 0,
        name: `${key}`,
        key: `${key}`,
        children: [],
        duration: 0,
        className: 'sessionTableRow__dateWise',
        showDuration: true,
        peak: createEmptyNodeObj(),
        relational: createEmptyNodeObj(),
        sbt: createEmptyNodeObj(),
        regular: createEmptyNodeObj(),
        correct: 0,
        incorrect: 0,
        prompt: 0,
        trial: 0,
        get total() {
          return this.trial
        },
      }
      // console.log('xxxxx', sessions)
      // console.log('xxxxxxx', groupedTargetsByDate)
      sessions.forEach(session => {
        processSessionNode(dataObj, session)
      })
      tableData.push(dataObj)
    })
    setTable(tableData)
  }, [filteredTableData, dt, tType, session])

  useEffect(() => {
    if (lineDrawer === true) {
      setTType('all')
    }
  }, [lineDrawer])
  useEffect(() => {
    setIsModal(false)
    if (dt && tType !== 'all' && tType !== '' && currentRow) {
      const gData = []
      let filtered = []

      if (tType === 'EQUIVALENCE') {
        filtered = dt.getSessionDataRecording.edges.filter(
          ({ node }) =>
            node.targets?.peakType === tType &&
            node.ChildSession.sessionDate === currentRow.sessionDate,
        )
      } else {
        filtered = dt.getSessionDataRecording.edges.filter(
          ({ node }) =>
            node.targets?.targetAllcatedDetails?.targetType?.typeTar === tType &&
            node.ChildSession.sessionDate === currentRow.sessionDate,
        )
      }
      let correct = 0
      let incorrect = 0
      let prompt = 0
      setFiltered(filtered)
      filtered.forEach(({ node }) => {
        if (tType === 'EQUIVALENCE' && node.targets.peakType === 'EQUIVALENCE') {
          correct += node.peakEquivalance.totalCorrect
          incorrect += node.peakEquivalance.totalError
          prompt += node.peakEquivalance.totalPrompt
        }
        if (tType === 'Peak' && node.targets.peakType !== 'EQUIVALENCE') {
          correct += node.peak.totalCorrect
          incorrect += node.peak.totalError
          prompt += node.peak.totalPrompt
        } else {
          correct += node.sessionRecord.totalCorrect
          incorrect += node.sessionRecord.totalIncorrect
          prompt += node.sessionRecord.totalPrompt
        }
      })
      gData.push({
        id: 'Correct',
        key: Math.random(),
        label: ' Correct',
        value: correct,
        // value: Math.round((totalCorrect * 100) / total),
      })
      gData.push({
        id: 'InCorrect',
        key: Math.random(),
        label: 'InCorrect',
        value: incorrect,
      })
      gData.push({
        id: 'Prompt',
        key: Math.random(),
        label: ' Prompt',
        value: prompt,
      })
      setBarGraphData(gData)
    } else if (dt && tType === 'all' && currentRow) {
      generateGraphData(currentRow)
    }
  }, [dt, tType, currentRow, session])

  const generateGraphData = row => {
    let total = getTotal(row)
    total = total === 0 ? 1 : total

    const gData = []
    const correct = Number.isNaN(row.correctCount) ? 0 : row.correctCount
    const incorrect = Number.isNaN(row.errorCount) ? 0 : row.errorCount
    const prompt = Number.isNaN(row.promptCount) ? 0 : row.promptCount
    const pCorrect = Number.isNaN(row.peakCorrect) ? 0 : row.peakCorrect
    const pIncorrect = Number.isNaN(row.peakError) ? 0 : row.peakError
    const pPrompt = Number.isNaN(row.peakPrompt) ? 0 : row.peakPrompt
    const equCorrect = Number.isNaN(row.peakEquCorrect) ? 0 : row.peakEquCorrect
    const equIncorrect = Number.isNaN(row.peakEquError) ? 0 : row.peakEquError
    const equPrompt = Number.isNaN(row.peakEquPrompt) ? 0 : row.peakEquPrompt
    const sbtCorrect = Number.isNaN(row.sbtCorrect) ? 0 : row.sbtCorrect
    const sbtIncorrect = Number.isNaN(row.sbtPrompt) ? 0 : row.sbtPrompt
    const sbtPrompt = Number.isNaN(row.sbtError) ? 0 : row.sbtError
    const totalCorrect = correct + pCorrect + equCorrect + sbtCorrect
    const totalIncorrect = incorrect + pIncorrect + equIncorrect + sbtIncorrect
    const totalPrompt = prompt + pPrompt + equPrompt + sbtPrompt

    const data = {
      name: 'nivo',
      color: 'hsl(355, 70%, 50%)',
      children: [
        {
          name: 'Correct',
          label: 'Correct',
          color: 'hsl(274, 70%, 50%)',
          children: [
            {
              name: 'correct',
              label: 'correct',
              color: 'hsl(54, 70%, 50%)',
              loc: correct,
            },
            {
              name: 'peakCorrect',
              label: 'peakCorrect',
              color: 'hsl(322, 70%, 50%)',
              loc: pCorrect,
            },
            {
              name: 'peakEquCorrect',
              label: 'peakEquCorrect',
              color: 'hsl(314, 70%, 50%)',
              loc: equCorrect,
            },
            {
              name: 'SBTCorrect',
              label: 'SBTCorrect',

              color: 'hsl(234, 70%, 50%)',
              loc: sbtCorrect,
            },
          ],
        },
        {
          name: 'InCorrect',
          label: 'InCorrect',

          color: 'hsl(274, 70%, 50%)',
          children: [
            {
              name: 'inCorrect',
              label: 'inCorrect',
              color: 'hsl(54, 70%, 50%)',
              loc: incorrect,
            },
            {
              name: 'peakInCorrect',
              label: 'peakInCorrect',
              color: 'hsl(322, 70%, 50%)',
              loc: pIncorrect,
            },
            {
              name: 'peakEquInCorrect',
              label: 'peakEquInCorrect',
              color: 'hsl(314, 70%, 50%)',
              loc: equIncorrect,
            },
            {
              name: 'SBTInCorrect',
              label: 'SBTInCorrect',
              color: 'hsl(234, 70%, 50%)',
              loc: sbtIncorrect,
            },
          ],
        },
        {
          name: 'Prompt',
          label: 'Prompt',
          color: 'hsl(274, 70%, 50%)',
          children: [
            {
              name: 'prompt',
              label: 'prompt',
              color: 'hsl(54, 70%, 50%)',
              loc: prompt,
            },
            {
              name: 'peakPrompt',
              label: 'peakPrompt',
              color: 'hsl(322, 70%, 50%)',
              loc: pPrompt,
            },
            {
              name: 'peakEquPrompt',
              label: 'peakEquPrompt',
              color: 'hsl(314, 70%, 50%)',
              loc: equPrompt,
            },
            {
              name: 'sbtPrompt',
              label: 'peakEquPrompt',
              color: 'hsl(234, 70%, 50%)',
              loc: sbtPrompt,
            },
          ],
        },
      ],
    }

    gData.push({
      id: 'Correct',
      key: Math.random(),
      label: ' Correct',
      value: totalCorrect,
      // value: Math.round((totalCorrect * 100) / total),
    })
    gData.push({
      id: 'Incorrect',
      key: Math.random(),
      label: ' Incorrect',
      value: totalIncorrect,
      // value: Math.round((totalIncorrect * 100) / total),
    })
    gData.push({
      id: 'Prompt',
      key: Math.random(),
      label: ' Prompt',
      value: totalPrompt,
      // value: Math.round((totalPrompt * 100) / total),
    })
    // gData.push

    setBarGraphData(gData)
    setCurrentRow(row)
    setLineDrawer(true)
  }
  const DateChangeDropDown = date => {
    setDateRange(date)
  }

  function formatDuration(ms) {
    const duration = moment.duration(ms)
    let returnVal = ''
    if (duration.asHours() > 1) {
      returnVal =
        Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(':mm:ss')
    } else {
      returnVal = '00:'.concat(moment.utc(duration.asMilliseconds()).format('mm:ss'))
    }
    return returnVal
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const flattenDataNode = (node, stack, excelData) => {
    if (!node.children) {
      const lastRow = excelData.length > 0 ? excelData[excelData.length - 1] : null
      if (!lastRow || lastRow.type !== node.type) {
        excelData.push({ type: node.type })
      }
      excelData.push(node)
      return [node]
    }
    const { children } = node
    const copyNode = { ...node, children: null }
    const flattenedChildren = []
    const lastRow = excelData.length > 0 ? excelData[excelData.length - 1] : null
    if (!lastRow || lastRow.type !== copyNode.type) {
      excelData.push({ type: copyNode.type })
      stack.push(copyNode.type)
    }
    excelData.push(copyNode)

    children.forEach(child => flattenedChildren.push(flattenDataNode(child, stack, excelData)))
    stack.pop()
    return flattenDeep([copyNode, flattenedChildren])
  }

  const flattenTableHeaders = tableColumns => {
    const headerRow1 = []
    const headerRow2 = []
    tableColumns.forEach(col => {
      if (col.showOnExcel && !col.showOnExcel) {
        return
      }
      let { title } = col
      if (col.excelTitle) {
        title = col.excelTitle
      }
      if (col.children) {
        headerRow1.push(title)
        col.children.forEach((val, ind) => {
          headerRow2.push({ dataIndex: val.dataIndex, title: `${val.title}` })
          if (ind != 0) {
            headerRow1.push('')
          }
        })
      } else {
        headerRow1.push(title)
        headerRow2.push({ title: '', dataIndex: col.dataIndex })
      }
    })
    return [headerRow1, headerRow2]
  }

  function accessProperty(path) {
    let val = this
    const subPath = path.split('.')
    subPath.forEach(p => {
      if (val) {
        val = val[p]
      }
    })
    return val
  }

  const exportToCSV = () => {
    const filename = '_sessions_excel'
    const excelData = []
    const stack = []
    flattenDataNode({ children: table }, stack, excelData)

    const formatCell = getCellFormatter(cellFormat)
    let [headerRow1, heads] = flattenTableHeaders(tableColumns)
    heads = [
      { title: '', dataIndex: 'name' },
      { title: '', dataIndex: 'duration' },
      ...heads.slice(1),
    ]
    headerRow1 = ['Name', 'Duration', ...headerRow1.slice(1)]

    let exData = []
    excelData.forEach(node => {
      const row = []
      if (!node.name && node.type != 'sessionDateWise') {
        exData.push([''])
        exData.push([node.type])
      } else {
        heads.forEach(col => {
          const prop = accessProperty.call(node, col.dataIndex || '')
          if (
            col.dataIndex === 'duration' ||
            (col.dataIndex === 'behDuration' && node.showDuration && !Number.isNaN(prop))
          ) {
            if (prop == '0') {
              row.push('')
            } else {
              row.push(convertToHourMinSecondsStr(prop))
            }
          } else if (col.dataIndex === 'r1') {
            const val = prop
              ? `${prop} - ${node.r1Count} ${convertToHourMinSecondsStr(node.r1Duration || 0)}`
              : ''
            row.push(val)
          } else if (col.dataIndex === 'r2') {
            const val = prop
              ? `${prop} - ${node.r2Count} ${convertToHourMinSecondsStr(node.r2Duration || 0)}`
              : ''
            row.push(val)
          } else if (
            col.title === 'Correct' ||
            col.title === 'Incorrect' ||
            col.title === 'Prompt'
          ) {
            if (!col.dataIndex.includes('.')) {
              row.push(formatCell(prop, node))
            } else {
              row.push(formatCell(prop, accessProperty.call(node, col.dataIndex.split('.')[0])))
            }
          } else if (Number.isNaN(prop)) {
            row.push('')
          } else {
            row.push(prop)
          }
        })
        exData.push(row)
      }
    })
    exData = exData.filter(row => row.length > 1 || row[0] !== undefined)

    // const table = document.querySelectorAll('.sessionTable .ant-table-fixed')
    // const t = table.length > 0 && table[0]
    const headerRow = heads.map(col => col.title)

    const spacedXData = []
    exData.forEach(row => {
      if (!row.name) {
        spacedXData.push(['undefined'])
      }
      spacedXData.push(row)
    })
    const ws = XLSX.utils.aoa_to_sheet([headerRow1, headerRow, ...exData.slice(2)])

    const wb = {
      Sheets: { data: ws },
      SheetNames: ['data'],
    }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const blobData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(blobData, studentName + filename + fileExtension)
  }

  const getTotal = row => {
    return (
      row.correctCount +
      row.errorCount +
      row.promptCount +
      row.peakCorrect +
      row.peakPrompt +
      row.peakError +
      row.peakEquCorrect +
      row.peakEquError +
      row.peakEquPrompt +
      row.sbtCorrect +
      row.sbtError +
      row.sbtPrompt
    )
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => exportToCSV()} type="link" size="small">
          CSV/Excel
        </Button>
      </Menu.Item>
    </Menu>
  )

  const generateModal = ({ point }) => {
    const key = []
    const data = []
    const row = currentRow
    const correct = Number.isNaN(row.correctCount) ? 0 : row.correctCount
    const incorrect = Number.isNaN(row.errorCount) ? 0 : row.errorCount
    const prompt = Number.isNaN(row.promptCount) ? 0 : row.promptCount
    const pCorrect = Number.isNaN(row.peakCorrect) ? 0 : row.peakCorrect
    const pIncorrect = Number.isNaN(row.peakError) ? 0 : row.peakError
    const pPrompt = Number.isNaN(row.peakPrompt) ? 0 : row.peakPrompt
    const equCorrect = Number.isNaN(row.peakEquCorrect) ? 0 : row.peakEquCorrect
    const equIncorrect = Number.isNaN(row.peakEquError) ? 0 : row.peakEquError
    const equPrompt = Number.isNaN(row.peakEquPrompt) ? 0 : row.peakEquPrompt
    const sbtCorrect = Number.isNaN(row.sbtCorrect) ? 0 : row.sbtCorrect
    const sbtIncorrect = Number.isNaN(row.sbtPrompt) ? 0 : row.sbtPrompt
    const sbtPrompt = Number.isNaN(row.sbtError) ? 0 : row.sbtError

    const totalCorrect = correct + pCorrect + equCorrect + sbtCorrect
    const totalIncorrect = incorrect + pIncorrect + equIncorrect + sbtIncorrect
    if (point.id === 'Prompt') {
      key.push('Gestural')
      key.push('Physical')
      key.push('Verbal')
      key.push('Textual')
      key.push('Prompt')
      key.push('PeakPrompt')
      key.push('PeakEquPrompt')
      key.push('SBTPrompt')

      data.push(
        {
          name: 'Prompt',
          Prompt: 0,
          PeakPrompt: 0,
          PeakEquPrompt: 0,
          SBTPrompt: 0,
          Gestural: row.gestural,
          Physical: row.physical,
          Verbal: row.verbal,
          Textual: row.textual,
        },
        {
          name: 'PeakPrompt',
          Prompt: 0,
          PeakPrompt: pPrompt,
          PeakEquPrompt: 0,
          SBTPrompt: 0,
          Gestural: 0,
          Physical: 0,
          Verbal: 0,
          Textual: 0,
        },
        {
          name: 'PeakEquPrompt',
          Prompt: 0,
          PeakPrompt: 0,
          PeakEquPrompt: equPrompt,
          SBTPrompt: 0,
          Gestural: 0,
          Physical: 0,
          Verbal: 0,
          Textual: 0,
        },
        {
          name: 'SBTPrompt',
          Prompt: 0,
          PeakPrompt: 0,
          PeakEquPrompt: 0,
          SBTPrompt: sbtPrompt,
          Gestural: 0,
          Physical: 0,
          Verbal: 0,
          Textual: 0,
        },
      )
    }
    if (point.id === 'Correct') {
      key.push('Correct')
      key.push('PeakCorrect')
      key.push('PeakEquCorrect')
      key.push('SBTCorrect')
      data.push({
        name: 'Correct',
        Correct: correct,
        PeakCorrect: pCorrect,
        PeakEquCorrect: equCorrect,
        SBTCorrect: sbtCorrect,
      })
    }
    if (point.id === 'Incorrect') {
      key.push('InCorrect')
      key.push('PeakInCorrect')
      key.push('PeakEquInCorrect')
      key.push('SBTInCorrect')
      data.push({
        name: 'InCorrect',
        InCorrect: incorrect,
        PeakInCorrect: pIncorrect,
        PeakEquInCorrect: equIncorrect,
        SBTInCorrect: sbtIncorrect,
      })
    }

    const dataBar = [
      {
        country: 'AD',
        'hot dog': 97,
        'hot dogColor': 'hsl(316, 70%, 50%)',
        burger: 163,
        burgerColor: 'hsl(264, 70%, 50%)',
        sandwich: 22,
        sandwichColor: 'hsl(201, 70%, 50%)',
        kebab: 65,
        kebabColor: 'hsl(84, 70%, 50%)',
        fries: 144,
        friesColor: 'hsl(171, 70%, 50%)',
        donut: 84,
        donutColor: 'hsl(329, 70%, 50%)',
      },
      {
        country: 'AE',
        'hot dog': 135,
        'hot dogColor': 'hsl(175, 70%, 50%)',
        burger: 82,
        burgerColor: 'hsl(291, 70%, 50%)',
        sandwich: 149,
        sandwichColor: 'hsl(59, 70%, 50%)',
        kebab: 185,
        kebabColor: 'hsl(140, 70%, 50%)',
        fries: 54,
        friesColor: 'hsl(122, 70%, 50%)',
        donut: 190,
        donutColor: 'hsl(191, 70%, 50%)',
      },
    ]

    setBarData(data)
    setKeys(key)
    setIsModal(true)
  }

  const generatePromptData = ({ point }) => {
    let gestural = 0
    let verbal = 0
    let textual = 0
    let physical = 0
    const key = []
    const data = []

    filteredData.forEach(({ node }) => {
      gestural += node.sessionRecord.gestural
      verbal += node.sessionRecord.verbal
      textual += node.sessionRecord.textual
      physical += node.sessionRecord.physical
    })
    key.push('Gestural')
    key.push('Physical')
    key.push('Verbal')
    key.push('Textual')
    data.push({
      name: 'Prompt',
      Prompt: 0,
      PeakPrompt: 0,
      PeakEquPrompt: 0,
      SBTPrompt: 0,
      Gestural: gestural,
      Physical: physical,
      Verbal: verbal,
      Textual: textual,
    })

    // }
    setBarData(data)
    setKeys(key)

    setIsModal(true)
  }

  console.log('table===>', table)
  return (
    <div>
      <div style={filterCardStyle}>
        <div style={parentDiv}>
          <Tooltip trigger={['hover']} title="Select Date range">
            <RangeDatePicker date={dateRange} setDate={DateChangeDropDown} from="SESSION_REPORT" />
          </Tooltip>
        </div>
        <div style={parentDiv} data-testid="session-session-select">
          <Tooltip title="Session">
            <Select
              style={{ width: 220, borderRadius: 4 }}
              value={!sessionNamesLoading ? session : 'Morning'}
              disabled={ld}
              onChange={v => setSession(v)}
              placeholder="ALL"
            >
              <Option key="all" value="">
                All
              </Option>
              {sessionNames &&
                sessionNames.getActiveSessionNames.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Tooltip>
        </div>
        <div style={parentDiv} data-testid="session_target_type_select">
          <Tooltip title="Target Type">
            <Select
              mode="multiple"
              style={{
                width: 300,
              }}
              disabled={targetTypeOptionsLoading || ld}
              loading={targetOptionsLoading}
              allowClear
              onChange={v => {
                setSelectedTargetTypes(v)
              }}
              // onChange={v => {
              //   setType(v)
              // }}
              placeholder="Target Type"
            >
              {targetTypeOptionsData &&
                targetTypeOptionsData.types.map(({ id, typeTar }) => (
                  <Option key={id} value={typeTar}>
                    {typeTar === 'Peak' ? 'Coded' : typeTar}
                  </Option>
                ))}
              {/* <Option value={null}>All</Option> */}
            </Select>
          </Tooltip>
        </div>

        <div style={parentDiv} data-testid="session-cell-format-select">
          <Tooltip title="Change cell format">
            <Select
              style={{ width: 200 }}
              placeholder="Cell Format"
              defaultValue={CELL_FORMATS.COUNT}
              onChange={val => setCellFormat(val)}
            >
              <Option value={CELL_FORMATS.COUNT}>Count</Option>
              <Option value={CELL_FORMATS.PERCENTAGE}>Percentage</Option>
              <Option value={CELL_FORMATS.BOTH}>Count - Percentage</Option>
              <Option value={CELL_FORMATS.COUNT_BY_TOTAL}>Count / Total</Option>
            </Select>
          </Tooltip>
        </div>
        <div style={parentDiv} data-testid="session-column-filter">
          <Popover
            title="Show / Hide Columns"
            placement="rightBottom"
            content={
              <div className="sessionReport_columnOptions">
                <Checkbox
                  indeterminate={
                    checkedTableColumns?.length > 0 &&
                    checkedTableColumns?.length < columnOptions?.length
                  }
                  checked={checkedTableColumns?.length == columnOptions?.length}
                  onChange={e => {
                    if (e.target.checked) {
                      setCheckedTableColumns(columnOptions)
                    } else {
                      setCheckedTableColumns([])
                    }
                  }}
                >
                  All
                </Checkbox>
                <CheckboxGroup
                  className="columnOptions"
                  options={columnOptions}
                  onChange={setCheckedTableColumns}
                  value={checkedTableColumns}
                />
              </div>
            }
          >
            <Button>
              <FilterOutlined />
            </Button>
          </Popover>
        </div>

        <div className="sessionReport__colorCodes">
          <div className="stimulus">Stimulus</div>
          <div className="steps">Steps</div>
          <div className="prompt">Prompt</div>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Dropdown overlay={menu} trigger={['hover']}>
            <Button type="link" size="large" style={{ padding: '0 8px' }}>
              <FaDownload fontSize={22} />{' '}
            </Button>
          </Dropdown>
        </div>
      </div>

      <Table
        className="sessionTable"
        loading={ld}
        columns={tableColumns}
        bordered
        rowClassName={record => record.className}
        expandRowByClick
        dataSource={table}
        scroll={{ y: 'calc(100vh - 320px)', x: width }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
        }}
      />
      {/* {console.log('session table data', table)} */}

      <Drawer
        closable
        width="80%"
        title="Report Pdf"
        onClose={() => setReportPdfDrawer(false)}
        visible={reportPdfDrawer}
      >
        <SessionReportPdf selectSessionId={selectSessionId} />
      </Drawer>

      <Drawer
        visible={lineDrawer}
        onClose={() => setLineDrawer(false)}
        width={DRAWER.widthL2}
        title={`${currentRow?.sessionDate}: ${currentRow?.sessions.sessionName.name} Session - Response Percentage  Graph`}
      >
        <>
          <div style={{ ...parentDiv, display: 'flex' }}>
            <Tooltip title="Session">
              <Select
                style={{ width: 220, borderRadius: 4 }}
                value={tType}
                disabled={targetOptionsLoading}
                showSearch
                onChange={v => setTType(v)}
                placeholder="ALL"
              >
                <Option key="all" value="all">
                  All
                </Option>

                <Option key="peq" value="EQUIVALENCE">
                  Peak Equivalence
                </Option>
                {targetType?.map(({ id, typeTar }) => {
                  return (
                    <Option key={id} value={typeTar}>
                      {typeTar}
                    </Option>
                  )
                })}
              </Select>
            </Tooltip>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <div style={{ height: 400, marginBottom: 0, width: 500 }}>
              <ResponsivePie
                data={barGraphData}
                margin={{ top: 40, right: 10, bottom: 80, left: 20 }}
                innerRadius={0.5}
                padAngle={0.9}
                activeOuterRadiusOffset={3}
                onClick={point => {
                  setPointValue(point)

                  if (tType === 'all') {
                    generateModal({ point, currentRow })
                  } else if (point.id === 'Prompt' && tType !== 'EQUIVALENCE' && tType !== 'Peak') {
                    generatePromptData({ point })
                  } else {
                    setIsModal(false)
                  }
                }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: 'Correct',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'Prompt',
                    },
                    id: 'lines',
                  },
                ]}
              />
            </div>
            {isModal && (
              <div
                style={{
                  borderWidth: 1,
                  borderRadius: 5,
                  borderStyle: 'solid',
                  padding: 5,
                  borderColor: 'grey',
                  margin: 'auto',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <h4>
                    {pointValue.id}(Target Type:{tType})
                  </h4>
                </div>
                <div style={{ height: 300, width: 300, marginLeft: 0 }}>
                  <ResponsiveBar
                    data={barData}
                    key={Math.random()}
                    keys={keys}
                    indexBy="name"
                    margin={{ top: 50, right: 10, bottom: 80, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'nivo' }}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: 'Propmt',
                        },
                        id: 'dots',
                      },
                      {
                        match: {
                          id: 'Correct',
                        },
                        id: 'lines',
                      },
                    ]}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -45,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Count',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      </Drawer>
    </div>
  )
}

export default SessionReport
