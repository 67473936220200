/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { GREY_ICON_BUTTON, COLORS } from 'assets/styles/globalStyles'
import { MoreOutlined, CaretRightFilled } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import { useTargetAlloc } from '../context'
import { useMastSeq } from './context'

const btnStyle = {
  ...GREY_ICON_BUTTON,
  fontSize: 16,
}

const CriteriaCard = ({
  details,
  setMstSeqObj,
  manualMastActive,
  mstSeqObj,
  activeCard = false,
}) => {
  const { selectedTab, promptData, targetStatusData } = useTargetAlloc()
  const [masterySet, setMasterySet] = useState([])
  useEffect(() => {
    if (details && details.masterySet) {
      setMasterySet(details.masterySet)
    } else {
      setMasterySet([])
    }
  }, [details])

  const {
    setSelectedCriterPhase,
    setAddPhaseTargetModal,
    setCriteriaTargetObj,
    setCriteriaEditModal,
  } = useMastSeq()

  const calculationTypeMapping = (calType, durationCalType) => {
    let valueType =
      selectedTab === 'Regular'
        ? `response(%) per day`
        : selectedTab === 'Behavior Recording Duration'
        ? `duration per day`
        : `frequency per day`

    if (durationCalType === 'Average') {
      valueType =
        selectedTab === 'Regular'
          ? 'average response(%)'
          : selectedTab === 'Behavior Recording Duration'
          ? 'average duration'
          : 'average frequency'
    }

    switch (calType) {
      case '>':
        return `If ${valueType} is Greater Than`
      case '<':
        return `If ${valueType} is Less Than`
      case '>=':
        return `If ${valueType} is Greater Than or Equal to`
      case '<=':
        return `If ${valueType} is Less Than or Equal to`
      case '==':
        return `If ${valueType} is Equal to`
      default:
        return ''
    }
  }

  const getCalValue = node => {
    const valueType =
      selectedTab === 'Regular'
        ? `${node.percentage}%`
        : selectedTab === 'Behavior Recording Duration'
        ? node.duration
        : node.frequency

    return valueType
  }

  const getTitle = node => {
    return `${calculationTypeMapping(node.calculationType, node.durationCalType)} ${getCalValue(
      node,
    )} over ${node.calFrameDurationValue} ${node.calFrameType} ${node.calFrameDurationType}${
      node.calFrameDurationValue !== 1 ? 's' : ''
    }, ${node.progressTo === 'Previous Item in Hierarchy' ? 'auto-regress' : 'auto-progress'} to ${
      node.progressTo
    }`
  }
  return (
    <div id={details.key} className="form-card">
      <div
        className="mastery-card-header flexCenterJust"
        style={{
          borderBottom: masterySet.length > 0 ? '1px solid #ddd' : 'none',
          paddingBottom: masterySet.length > 0 ? 12 : 0,
        }}
      >
        <div style={{ marginRight: 32 }}>
          {activeCard ? <CaretRightFilled style={{ color: '#46bd84' }} /> : <MoreOutlined />}{' '}
          {
            targetStatusData?.targetStatus.filter(item => item.id === details.criteriaType)[0]
              ?.statusName
          }{' '}
          Criteria &nbsp;&nbsp;&nbsp;
          {details.prompt.length > 0 && (
            <span style={{ color: '#777' }}>
              Prompt:{' '}
              {details.prompt.map((pt, index) => {
                const ptName = promptData.promptCodes.filter(it => it.id === pt)[0].promptName
                if (index === details.prompt.length - 1) {
                  return <span key={`${pt}-span`}>{ptName}</span>
                }
                return <span key={`${pt}-span`}>{ptName}, </span>
              })}
            </span>
          )}
        </div>
        {manualMastActive && (
          <div style={{ minWidth: 'fit-content' }}>
            <Popconfirm
              title="Are you sure to delete this phase ?"
              onConfirm={() => {
                if (details?.key) {
                  const tempPhaseSet = mstSeqObj.phaseSet.filter(it => it.key !== details.key)
                  setMstSeqObj({ ...mstSeqObj, isTargetSpecific: true, phaseSet: tempPhaseSet })
                }
              }}
            >
              <Button style={btnStyle} type="link" icon="delete" />
            </Popconfirm>
            <Button
              style={btnStyle}
              type="link"
              onClick={() => {
                setSelectedCriterPhase(details)
                setCriteriaEditModal(true)
              }}
              icon="edit"
            />
            <Button
              style={btnStyle}
              type="link"
              icon="plus"
              onClick={() => {
                setSelectedCriterPhase(details)
                setAddPhaseTargetModal(true)
              }}
            />
          </div>
        )}
      </div>

      {masterySet.length > 0 && (
        <div>
          {masterySet.map(item => {
            return (
              <div
                key={item.key}
                style={{ padding: '12px 0', borderBottom: '1px solid #ddd', width: '100%' }}
                className="flexCenterJust"
              >
                <div style={{ color: COLORS.btnPrimaryColor }}>
                  <span>{getTitle(item)}</span>
                </div>
                {manualMastActive && (
                  <div style={{ minWidth: 'fit-content' }}>
                    <Popconfirm
                      title="Are you sure to delete this phase target ?"
                      onConfirm={e => {
                        e.stopPropagation()
                        if (item.key) {
                          const tempPhaseSet = mstSeqObj.phaseSet.map(it => {
                            const tempMasterySet = it.masterySet.filter(ms => ms.key !== item.key)
                            return { ...it, masterySet: tempMasterySet }
                          })

                          setMstSeqObj({
                            ...mstSeqObj,
                            isTargetSpecific: true,
                            phaseSet: tempPhaseSet,
                          })
                        }
                      }}
                    >
                      <Button style={btnStyle} type="link" icon="delete" />
                    </Popconfirm>
                    <Button
                      style={btnStyle}
                      type="link"
                      icon="edit"
                      onClick={() => {
                        setSelectedCriterPhase(details)
                        setCriteriaTargetObj(item)
                      }}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CriteriaCard
