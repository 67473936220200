import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import Sortable from 'react-sortablejs'
import CriteriaCard from './criteriaCard'
import EditCriteriaForm from './editCriteriaForm'
import { useMastSeq } from './context'
import CreateMastTargetForm from './createMastTargetForm'

const TemplateForm = ({
  form,
  setMstSeqObj,
  manualMastActive,
  mstSeqObj,
  showAddCriteriaButton,
  activeMasteryPhase,
}) => {
  const [addCriteriaModel, setAddCriteriaModel] = useState(false)
  const {
    selectedCriterPhase,
    setSelectedCriterPhase,
    addPhaseTargetModal,
    setAddPhaseTargetModal,
    criteriaTargetObj,
    setCriteriaTargetObj,
    criteriaEditModal,
    setCriteriaEditModal,
  } = useMastSeq()

  const handleClose = () => {
    setSelectedCriterPhase(null)
    setAddPhaseTargetModal(false)
    setCriteriaEditModal(false)
    setCriteriaTargetObj(null)
  }

  return (
    <div>
      {mstSeqObj.phaseSet?.length > 0 && (
        <div>
          {manualMastActive ? (
            <Sortable
              key={Math.random()}
              options={{
                store: {
                  set(sortable) {
                    const list = []
                    for (let i = 0; i < sortable.el.childNodes.length; i += 1) {
                      list.push(`${sortable.el.childNodes[i].id}`)
                    }

                    if (list.length === mstSeqObj.phaseSet.length) {
                      const nl = list.map(ke => {
                        let temp = {}
                        for (let i = 0; i < mstSeqObj.phaseSet.length; i += 1) {
                          if (ke === String(mstSeqObj.phaseSet[i].key)) {
                            temp = mstSeqObj.phaseSet[i]
                          }
                        }
                        return temp
                      })
                      setMstSeqObj({ ...mstSeqObj, isTargetSpecific: true, phaseSet: nl })
                    } else {
                      setMstSeqObj(mstSeqObj)
                    }
                  },
                },
              }}
              tag="div"
            >
              {mstSeqObj?.phaseSet.map(item => {
                return (
                  <CriteriaCard
                    key={item.key}
                    details={item}
                    form={form}
                    manualMastActive={manualMastActive}
                    mstSeqObj={mstSeqObj}
                    setMstSeqObj={setMstSeqObj}
                    activeCard={
                      item.id && activeMasteryPhase?.id && item.id === activeMasteryPhase.id
                    }
                  />
                )
              })}
            </Sortable>
          ) : (
            mstSeqObj?.phaseSet.map(item => {
              return (
                <CriteriaCard
                  key={item.key}
                  details={item}
                  form={form}
                  manualMastActive={manualMastActive}
                  mstSeqObj={mstSeqObj}
                  setMstSeqObj={setMstSeqObj}
                  activeCard={
                    item.id && activeMasteryPhase?.id && item.id === activeMasteryPhase.id
                  }
                />
              )
            })
          )}
        </div>
      )}
      {showAddCriteriaButton && (
        <div style={{ display: 'flex' }}>
          <Button
            type="dashed"
            icon="plus"
            disabled={!manualMastActive}
            onClick={() => {
              setAddCriteriaModel(true)
            }}
            style={{ width: '60%', margin: '0 auto' }}
          >
            Add Criteria
          </Button>
        </div>
      )}

      <Modal
        title="Edit Criteria"
        visible={criteriaEditModal}
        onCancel={() => setCriteriaEditModal(false)}
        footer={null}
        width={900}
        zIndex={1003}
        destroyOnClose
      >
        <EditCriteriaForm
          form={form}
          edit
          objForEdit={selectedCriterPhase}
          manualMastActive={manualMastActive}
          mstSeqObj={mstSeqObj}
          setMstSeqObj={setMstSeqObj}
          closeModal={() => {
            setCriteriaEditModal(false)
          }}
        />
      </Modal>

      <Modal
        title="Add Criteria Phase Target"
        visible={addPhaseTargetModal}
        onCancel={() => handleClose()}
        footer={null}
        width={900}
        zIndex={1002}
        destroyOnClose
      >
        <CreateMastTargetForm
          details={selectedCriterPhase}
          form={form}
          closeModal={() => handleClose()}
          mstSeqObj={mstSeqObj}
          setMstSeqObj={setMstSeqObj}
        />
      </Modal>

      <Modal
        title="Edit Criteria Phase Target"
        visible={!!criteriaTargetObj}
        onCancel={() => handleClose()}
        footer={null}
        width={900}
        zIndex={1002}
        destroyOnClose
      >
        <CreateMastTargetForm
          details={selectedCriterPhase}
          form={form}
          edit
          criteriaTargetObj={criteriaTargetObj}
          closeModal={() => handleClose()}
          mstSeqObj={mstSeqObj}
          setMstSeqObj={setMstSeqObj}
        />
      </Modal>

      <Modal
        title="Add Criteria"
        visible={addCriteriaModel}
        width={800}
        zIndex={1002}
        footer={null}
        onCancel={() => setAddCriteriaModel(false)}
        destroyOnClose
      >
        <EditCriteriaForm
          form={form}
          closeModal={() => {
            setAddCriteriaModel(false)
          }}
          manualMastActive={manualMastActive}
          mstSeqObj={mstSeqObj}
          setMstSeqObj={setMstSeqObj}
        />
      </Modal>
    </div>
  )
}

export default TemplateForm
