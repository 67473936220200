/* eslint-disable no-nested-ternary */
import { Col, Divider, Row, Typography } from 'antd'
import { COLORS } from 'assets/styles/globalStyles'
import React, { useState } from 'react'

const { Text } = Typography

const BEHAVIOR_RECORDING_FREQ = 'Behavior Recording Frequency'
const BEHAVIOR_RECORDING_DUR = 'Behavior Recording Duration'

const PhaseExplanation = ({ formData, templateType }) => {
  const getCalValue = () => {
    const valueType =
      templateType === 'Regular'
        ? `${formData.responsePercentage}%`
        : templateType === 'Behavior Recording Duration'
        ? formData.duration
        : formData.frequency

    return valueType
  }

  const calculationTypeMapping = () => {
    let valueType =
      templateType === 'Regular'
        ? `response(%) per day`
        : templateType === 'Behavior Recording Duration'
        ? `duration per day`
        : `frequency per day`

    if (formData.durationCalType === 'Average') {
      valueType =
        templateType === 'Regular'
          ? 'average response(%)'
          : templateType === 'Behavior Recording Duration'
          ? 'average duration'
          : 'average frequency'
    }

    switch (formData.calculationType) {
      case '>':
        return `If ${valueType} is Greater Than`
      case '<':
        return `If ${valueType} is Less Than`
      case '>=':
        return `If ${valueType} is Greater Than or Equal to`
      case '<=':
        return `If ${valueType} is Less Than or Equal to`
      case '==':
        return `If ${valueType} is Equal to`
      default:
        return ''
    }
  }

  return (
    <>
      <Row>
        <Text strong style={{ color: COLORS.primary }}>
          Mastery Criteria
        </Text>
        <Col span={24} style={{ marginTop: 5 }}>
          {/* <div style={{ marginTop: 5 }}>
            <Text strong>Calculation Type: </Text>
            <Text>{formData.calculationType}</Text>
          </div>
          {templateType === 'Regular' ? (
            <div>
              <Text strong>Response Percentage: </Text>
              <Text>{formData.responsePercentage}</Text>
            </div>
          ) : (
            ''
          )}
          {templateType === BEHAVIOR_RECORDING_FREQ ? (
            <div>
              <Text strong>Frequency: </Text>
              <Text>{formData.frequency}</Text>
            </div>
          ) : (
            ''
          )}
          {templateType === BEHAVIOR_RECORDING_DUR ? (
            <div>
              <Text strong>Duration: </Text>
              <Text>{formData.duration}</Text>
            </div>
          ) : (
            ''
          )}

          <div>
            <Text strong>Calculation Type: </Text>
            <Text>{formData.durationCalType}</Text>
          </div>
          <div>
            <Text strong>Frame Type: </Text>
            <Text>{formData.calFrameType}</Text>
          </div>
          <div>
            <Text strong>Frame Value: </Text>
            <Text>{formData.calFrameDurationValue}</Text>
          </div>
          <div>
            <Text strong>Frame Duration Type: </Text>
            <Text>{formData.calFrameDurationType}</Text>
          </div>
          <div>
            <Text strong>Minimum Trials/Day: </Text>
            <Text>{formData.minimumTrial}</Text>
          </div>
          <div>
            <Text strong>Progress to: </Text>
            <Text>{formData.progressTo}</Text>
          </div>
          <div>
            <Text strong>Phase Line Label: </Text>
            <Text>Intervention</Text>
          </div> */}

          <div style={{ marginTop: 5 }}>
            <div>
              <Text>
                {` ${calculationTypeMapping()} ${getCalValue()} over ${
                  formData.calFrameDurationValue
                } ${formData.calFrameType} ${formData.calFrameDurationType}, and the
            target completes a minimum of ${formData.minimumTrial} trials each ${
                  formData.calFrameDurationType
                }, the system will automatically
            progress the target to the ${formData.progressTo} phase.`}
              </Text>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default PhaseExplanation
