/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { Range, getTrackBackground } from 'react-range'
import {
  Drawer,
  Button,
  Table,
  Popover,
  Row,
  Col,
  Select,
  Input,
  Modal,
  Dropdown,
  Menu,
  Empty,
  notification,
  Form,
} from 'antd'
import {
  PlusOutlined,
  PlayCircleOutlined,
  DeleteOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons'
import { gql } from 'apollo-boost'
import moment from 'moment'

const { Option } = Select
const { TextArea } = Input

const VIDEO_CLIP = gql`
  query videoClip($videoId: ID, $subClass: ID) {
    videoClip(videoId: $videoId, subClass: $subClass) {
      id
      startTime
      endTime
      feature {
        id
        name
      }
      stimulus {
        id
        name
      }
      category {
        id
        name
      }
      response {
        id
        name
      }
      note
    }
  }
`
const ANNOTATE_INIT = gql`
  query AnnotateInit($subId: ID!) {
    vfeature(subId: $subId) {
      id
      name
    }
    vstimulus(subId: $subId) {
      id
      name
    }
    vcategory(subId: $subId) {
      id
      name
    }
    vresponse(subId: $subId) {
      id
      name
    }
  }
`
const CREATE_ANNOTATION = gql`
  mutation createAnnotation(
    $videoId: ID!
    $subId: ID
    $startTime: Float
    $endTime: Float
    $selfeature: ID
    $selstimulus: ID
    $selcategory: ID
    $selresponse: ID
    $selcomment: String
    $clipId: ID
  ) {
    createAnnotation(
      input: {
        videoId: $videoId
        subId: $subId
        startTime: $startTime
        endTime: $endTime
        feature: $selfeature
        stimulus: $selstimulus
        category: $selcategory
        response: $selresponse
        note: $selcomment
        clipId: $clipId
      }
    ) {
      annotationList {
        id
        startTime
        endTime
        feature {
          id
          name
        }
        stimulus {
          id
          name
        }
        category {
          id
          name
        }
        response {
          id
          name
        }
        note
      }
    }
  }
`
const DELETE_ANNOTATION = gql`
  mutation deleteAnnotation($pk: ID) {
    deleteAnnotation(input: { pk: $pk }) {
      status
      msg
    }
  }
`

const TableTr = ({
  videoObject,
  playAnnotation,
  subdata,
  form,
  startEndVideoTime,
  videoDuration,
  dataIns,
  j,
}) => {
  const [values, setValues] = useState([0, 0])
  const [open, setOpen] = useState(false)
  const [deletepop, setDeletepop] = useState(false)
  const [editobjId, setEditobjId] = useState('')
  const [feature, setFeature] = useState([])
  const [stimulus, setStimulus] = useState([])
  const [category, setCategory] = useState([])
  const [response, setResponse] = useState([])
  const [selfeature, setSelfeature] = useState('')
  const [selstimulus, setSelstimulus] = useState('')
  const [selcategory, setSelcategory] = useState('')
  const [selresponse, setSelresponse] = useState('')
  const [colorList, setColorList] = useState(['#ccc', '#548BF4', '#ccc'])
  const [selcomment, setSelcomment] = useState('')
  const [addRange, setAddRange] = useState(false)
  const [playpop, setPlaypop] = useState(false)
  const [annotationList, setAnnotationList] = useState([])
  const [clipIdByIn, setClipIdByIn] = useState([])
  const [rangeKey, setRangeKey] = useState(Math.random())
  const [cloneAnnotationList, setCloneAnnotationList] = useState([])
  const [visible, setVisible] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [selectedAnnotation, setSelectedAnnotation] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const canvasRef = useRef()

  const { data, loading, error } = useQuery(VIDEO_CLIP, {
    variables: { videoId: videoObject?.id, subClass: subdata.id },
    fetchPolicy: 'network-only',
  })

  const [creAnnotation] = useMutation(CREATE_ANNOTATION)
  const [delAnnotation] = useMutation(DELETE_ANNOTATION)
  const { data: annotateInit, loading: annotLoading } = useQuery(ANNOTATE_INIT, {
    variables: { subId: subdata.id },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data) {
      const clipId = []
      const resList = []
      let color = ['#ccc', '#548BF4', '#ccc']
      if (annotationList.length === 0) {
        setAnnotationList(data.videoClip)
        setCloneAnnotationList(data.videoClip)
      }
      if (data.videoClip.length > 0) {
        setAddRange(true)
        color.splice(color.indexOf('#548BF4'), 1, '#17c671')

        for (let i = 0; i < data.videoClip.length; i += 1) {
          clipId.push(data.videoClip[i].id)
          clipId.push(data.videoClip[i].id)
          const start = parseFloat(data.videoClip[i].startTime)
          resList.push(start)
          resList.push(parseFloat(data.videoClip[i].endTime))
          if (i > 0) color = color.slice(0, color.length - 1).concat(['#ccc', '#17c671', '#ccc'])
        }
      } else {
        resList.push(0)
        resList.push(0)
      }
      setClipIdByIn(clipId)
      setValues(resList)
      setColorList(color)
    }
  }, [data])

  useEffect(() => {
    if (annotateInit) {
      setFeature(annotateInit.vfeature)
      setCategory(annotateInit.vcategory)
      setResponse(annotateInit.vresponse)
      setStimulus(annotateInit.vstimulus)
    }
  }, [annotateInit])

  const callEditApi = () => {}

  const rangeValChange = val => {
    const newval = values
    console.log('Current val????', val)
    console.log('State val????', values)
    values.forEach((item, index) => {
      if (index !== 0 && index !== 1) {
        newval[index] = val[index]
      }
    })

    let currentlyEndit = 0
    const color = colorList
    val.forEach(item => {
      if (val[item] !== values[item]) {
        currentlyEndit = item
      }
    })
    console.log('currentlyEndit???', currentlyEndit, newval)
    if (currentlyEndit % 2) {
      color[currentlyEndit] = '#548BF4'
    }
    //  else {
    //   colorList[currentlyEndit + 1] = '#548BF4'
    // }
    console.log('Current????', currentlyEndit, color)
    console.log('Current edit????', clipIdByIn[currentlyEndit])
    console.log('Clip IDS????', clipIdByIn)
    setValues(newval.length === 2 ? val : newval.sort((a, b) => a - b))
    setAddRange(false)
    setEditobjId(clipIdByIn[currentlyEndit])
    setOpen(false)
    setColorList(color)
    startEndVideoTime(val)
  }

  const addRangeBlock = val => {
    if (parseFloat(values[values.length - 1] + 10) < videoDuration) {
      setAddRange(false)
      setColorList(colorList.slice(0, colorList.length - 1).concat(['#ccc', '#548BF4', '#ccc']))
      setValues(
        values.concat([
          parseFloat(values[values.length - 1]) + 10,
          parseFloat(values[values.length - 1]) + 10,
        ]),
      )
    }
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const showEditDrawer = annotation => {
    setSelectedAnnotation(annotation)
    setVisibleEdit(true)
    setEditMode(true)
    setOpen(true)
  }

  const onEditClose = () => {
    setVisibleEdit(false)
    setSelectedAnnotation(null)
    setEditMode(false)
  }

  const editAnnotation = () => {
    form.validateFields(
      ['feature', 'stimulus', 'category', 'response', 'comments'],
      async (err, val) => {
        console.log('values edit>>', val)
        creAnnotation({
          variables: {
            videoId: videoObject.id,
            subId: subdata?.id,
            clipId: selectedAnnotation?.id,
            startTime: selectedAnnotation?.startTime,
            endTime: selectedAnnotation?.endTime,
            selfeature: val.feature,
            selstimulus: val.stimulus,
            selcategory: val.category,
            selresponse: val.response,
            selcomment: val.comments,
          },
        }).then(result => {
          setAnnotationList(result.data.createAnnotation.annotationList)
          setOpen(false)
          setVisibleEdit(false)
        })
      },
    )
  }

  const createAnnotation = () => {
    form.validateFields(
      ['feature', 'stimulus', 'category', 'response', 'comments'],
      async (err, val) => {
        console.log('values', val)
        creAnnotation({
          variables: {
            videoId: videoObject.id,
            subId: subdata?.id,
            startTime: values[values.length - 2],
            endTime: values[values.length - 1],
            selfeature: val.feature,
            selstimulus: val.stimulus,
            selcategory: val.category,
            selresponse: val.response,
            selcomment: val.comments,
          },
        }).then(result => {
          setAddRange(true)
          console.log('colorAfter===>', colorList, colorList.indexOf('#548BF4'))
          if (colorList.indexOf('#548BF4') !== -1)
            colorList.splice(colorList.indexOf('#548BF4'), 1, '#17c671')

          console.log('colorAfter===>', colorList)

          // setColorList(color)
          setOpen(false)
          setAnnotationList(result.data.createAnnotation.annotationList)
        })
      },
    )
  }

  const playAnnotationData = (start, end) => {
    playAnnotation(start, end)
  }

  const afterDeleteRemoveRange = id => {
    const filteredList = annotationList.filter(item => item.id === id)
    const filteredSecond = annotationList.filter(item => item.id !== id)
    const newValues = []
    const newColorlist = ['#ccc']
    console.log('annotation List>>>----', annotationList, filteredList, filteredSecond)
    console.log('values>>>>---', values, id)
    if (filteredList[0]) {
      for (let i = 0; i < values.length - 1; i += 2) {
        console.log(
          'delete>>>--',
          parseInt(filteredList[0].startTime, 10),
          parseInt(values[i], 10),
          parseInt(filteredList[0].endTime, 10),
          parseInt(values[i + 1], 10),
          parseInt(filteredList[0].startTime, 10) === parseInt(values[i], 10) &&
            parseInt(filteredList[0].endTime, 10) === parseInt(values[i + 1], 10),
        )
        if (
          parseInt(filteredList[0].startTime, 10) === parseInt(values[i], 10) &&
          parseInt(filteredList[0].endTime, 10) === parseInt(values[i + 1], 10)
        ) {
          console.log('Value to delete')
        } else {
          console.log('values[i]>>>---', values[i], values[i + 1], i, colorList[i])
          newValues.push(parseFloat(values[i]))
          newValues.push(parseFloat(values[i + 1]))
          newColorlist.push(colorList[i + 1])
          newColorlist.push(colorList[i + 2])
        }
      }
    }

    // colorList.splice(-1,1)
    // colorList.splice(-1,1)

    if (filteredSecond.length > 0) {
      console.log(
        'new Values>>---',
        newValues,
        newColorlist.findIndex(i => i === '#548BF4'),
      )

      setAnnotationList(filteredSecond)
      setAddRange(!newColorlist.includes('#548BF4'))
      setValues(newValues.length > 0 ? newValues.sort((a, b) => a - b) : [0, 0])
      setColorList(newValues.length === 2 ? ['#ccc', '#17c671', '#ccc'] : newColorlist)
    } else {
      setAnnotationList(filteredSecond)
      setAddRange(false)
      setValues([0, 0])
      setColorList(['#ccc', '#548BF4', '#ccc'])
    }
  }

  const deleteAnnotation = objId => {
    delAnnotation({
      variables: {
        pk: objId,
      },
    }).then(result => {
      if (result.errors) {
        notification.error({
          message: 'Annotation',
          description: `${result.errors[0].message}`,
        })
      } else {
        notification.success({
          message: 'Annotation',
          description: `${result.data.deleteAnnotation.msg}`,
        })
        let found = false
        cloneAnnotationList.forEach(item => {
          if (item.id === objId) {
            found = true
          }
        })

        afterDeleteRemoveRange(objId)
      }
    })
    setDeletepop(false)
    setVisibleEdit(false)
    setSelectedAnnotation(null)
  }

  const addAnnotation = () => {
    setOpen(!open)
  }

  if (!videoDuration) {
    return 'Loading...'
  }
  const STEP = 0.1
  const MIN = 0
  const MAX = videoDuration

  const getNum = num => num.toString().padStart(2, 0)
  const formatTime = seconds => {
    if (seconds === 0) {
      return '00:00'
    }
    if (seconds < 60) {
      return `00: ${moment.duration(seconds, 'seconds').seconds()}`
    }
    const sec = moment.duration(seconds, 'seconds').seconds()
    const min = moment.duration(seconds, 'seconds').minutes()
    const hr = moment.duration(seconds, 'seconds').hours()
    return `${getNum(hr)}:${getNum(min)}:${getNum(sec)}`
  }
  const handleMenuClick = e => {
    deleteAnnotation(e.key)
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      {/* {annotationList.length === 0 && <Empty />} */}
      {annotationList &&
        annotationList.map(number => (
          <Menu.Item style={{ zIndex: 100000 }} key={number.id}>
            {formatTime(number.startTime)} - {formatTime(number.endTime)}
            {number.feature && (
              <>
                <br />
                F: {number.feature.name}
              </>
            )}
            {number.category && (
              <>
                <br />
                C: {number.category.name}
              </>
            )}
            {number.stimulus && (
              <>
                <br />
                S: {number.stimulus.name}
              </>
            )}
            {number.response && (
              <>
                <br />
                R: {number.response.name}
              </>
            )}
          </Menu.Item>
        ))}
    </Menu>
  )

  const handlePlayMenuClick = e => {
    const list = annotationList.filter(item => item.id === e.key)
    console.log('play menu click>>>>', annotationList, e.key, list)
    playAnnotationData(list[0].startTime, list[0].endTime)
  }
  const menuPlay = (
    <Menu onClick={handlePlayMenuClick}>
      {/* {annotationList.length === 0 && <Empty />} */}
      {annotationList &&
        annotationList.map(number => (
          <Menu.Item style={{ zIndex: 100000 }} key={number.id}>
            {formatTime(number.startTime)} - {formatTime(number.endTime)}
            {number.feature && (
              <>
                <br />
                F: {number.feature.name}
              </>
            )}
            {number.category && (
              <>
                <br />
                C: {number.category.name}
              </>
            )}
            {number.stimulus && (
              <>
                <br />
                S: {number.stimulus.name}
              </>
            )}
            {number.response && (
              <>
                <br />
                R: {number.response.name}
              </>
            )}
          </Menu.Item>
        ))}
    </Menu>
  )
  const handleEditMenuClick = e => {
    console.log(e)
    const list = annotationList.filter(item => item.id === e.key)
    console.log('Edit menu click>>>>', annotationList, e.key, list)
    setOpen(true)
    showEditDrawer(list[0])
  }

  console.log('feature-->', colorList, values)

  const menuEdit = (
    <Menu onClick={handleEditMenuClick}>
      {/* {annotationList.length === 0 && <Empty />} */}
      {annotationList &&
        annotationList.map(number => (
          <Menu.Item style={{ zIndex: 100000 }} key={number.id}>
            {formatTime(number.startTime)} - {formatTime(number.endTime)}
            {number.feature && (
              <>
                <br />
                F: {number.feature.name}
              </>
            )}
            {number.category && (
              <>
                <br />
                C: {number.category.name}
              </>
            )}
            {number.stimulus && (
              <>
                <br />
                S: {number.stimulus.name}
              </>
            )}
            {number.response && (
              <>
                <br />
                R: {number.response.name}
              </>
            )}
          </Menu.Item>
        ))}
    </Menu>
  )

  return (
    <>
      <tr>
        {parseInt(j, 10) === 0 ? (
          <td
            rowSpan={dataIns?.subclassCount}
            style={{ fontSize: 15, color: '#000', fontWeight: 'bold' }}
          >
            {dataIns?.name}
          </td>
        ) : (
          <td rowSpan={dataIns?.subclassCount} style={{ fontSize: 15, color: '#000' }}>
            {' '}
          </td>
        )}
        <td style={{ fontSize: 15 }}>
          {/* {dataIns.subclassCount} */}
          <Dropdown overlay={menuEdit} disabled={annotationList.length === 0}>
            <Button
              style={{
                border: 'none',
                backgroundColor: 'white',
                color: 'black',
                padding: '10px',
                margin: 0,
              }}
              onClick={showDrawer}
            >
              {subdata?.name}
            </Button>
          </Dropdown>
        </td>
        <td>
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }} key={rangeKey}>
            <Range
              allowOverlap
              key={rangeKey}
              values={values.sort((a, b) => a - b)}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={rangeValChange}
              renderTrack={({ props, children }) => {
                // console.log('children???', children, colorList)
                return (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: '8px',
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: '100%',
                        width: '100%',
                        borderRadius: '4px',
                        background: getTrackBackground({
                          values,
                          colors: colorList,
                          min: MIN,
                          max: MAX,
                        }),
                        alignSelf: 'center',
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )
              }}
              renderThumb={({ index, props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '100%',
                    width: '5px',
                    borderRadius: '4px',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 2px 6px #AAA',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-28px',
                      color: '#fff',
                      width: 'max-content',
                      // pointerEvents: addRange ? index < values.length ? "none" : "auto" : index < values.length-2 ? "none" : "auto",
                      fontWeight: 'bold',
                      fontSize: '14px',
                      fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                      padding: '4px',
                      borderRadius: '4px',
                      backgroundColor: colorList[index % 2 ? index : index + 1],
                    }}
                  >
                    {formatTime(values[index])}
                  </div>
                  <div
                    style={{
                      height: '16px',
                      width: '5px',
                      // pointerEvents: addRange ? index < values.length ? "none" : "auto" : index < values.length-2 ? "none" : "auto",
                      backgroundColor: isDragged
                        ? colorList[index % 2 ? index : index + 1]
                        : '#CCC',
                    }}
                  />
                </div>
              )}
            />
          </div>
        </td>
        <td>
          <div style={{ display: 'flex' }}>
            <Button
              disabled={!addRange}
              onClick={addRangeBlock}
              style={{ fontWeight: 'bold' }}
              icon="plus"
            >
              Range
            </Button>
            <Button
              disabled={addRange}
              id={`popover-${subdata.id}`}
              onClick={addAnnotation}
              style={{ fontWeight: 'bold' }}
              icon="plus"
            >
              Save
            </Button>
            <Dropdown overlay={menu} disabled={annotationList.length === 0}>
              <Button
                id={`delete-${subdata.id}`}
                onClick={e => setDeletepop(!deletepop)}
                style={{ fontWeight: 'bold' }}
                icon="delete"
              >
                Delete <ArrowDownOutlined />
              </Button>
            </Dropdown>

            <Dropdown overlay={menuPlay} disabled={annotationList.length === 0}>
              <Button
                id={`play-${subdata.id}`}
                onClick={e => setPlaypop(!playpop)}
                style={{ fontWeight: 'bold' }}
                icon="playcircle"
              >
                Play
              </Button>
            </Dropdown>
          </div>
        </td>
      </tr>

      <Modal
        visible={open}
        title="Annotation"
        onOk={() => {
          if (editMode) {
            editAnnotation()
          } else {
            createAnnotation()
          }
        }}
        onCancel={() => {
          setOpen(!open)
        }}
        destroyOnClose
      >
        {editMode && (
          <Button
            style={{ width: '100%' }}
            type="danger"
            onClick={e => deleteAnnotation(selectedAnnotation.id)}
          >
            Delete This Annotation
          </Button>
        )}
        {annotLoading && 'Loading..'}
        {!annotLoading && (
          <Form>
            <Row>
              <Form.Item label="Feature:">
                {form.getFieldDecorator('feature', {
                  initialValue:
                    selectedAnnotation && selectedAnnotation.feature
                      ? selectedAnnotation.feature.id
                      : undefined,
                })(
                  <Select
                    placeholder="Select Feauture"
                    showSearch
                    allowClear
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    {feature &&
                      feature.map(number => <Option value={number.id}>{number.name}</Option>)}
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="Stimulus:">
                {form.getFieldDecorator('stimulus', {
                  initialValue:
                    selectedAnnotation && selectedAnnotation.stimulus
                      ? selectedAnnotation.stimulus.id
                      : undefined,
                })(
                  <Select
                    placeholder="Stimulus"
                    showSearch
                    allowClear
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    {stimulus &&
                      stimulus.map(number => <Option value={number.id}>{number.name}</Option>)}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="Category:">
                {form.getFieldDecorator('category', {
                  initialValue:
                    selectedAnnotation && selectedAnnotation.category
                      ? selectedAnnotation.category.id
                      : undefined,
                })(
                  <Select
                    placeholder="Category"
                    showSearch
                    allowClear
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    {category &&
                      category.map(number => <Option value={number.id}>{number.name}</Option>)}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="Response:">
                {form.getFieldDecorator('response', {
                  initialValue:
                    selectedAnnotation && selectedAnnotation.response
                      ? selectedAnnotation.response.id
                      : undefined,
                })(
                  <Select
                    placeholder="Response"
                    showSearch
                    allowClear
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    {response &&
                      response.map(number => <Option value={number.id}>{number.name}</Option>)}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="Comments:">
                {form.getFieldDecorator('comments', {
                  initialValue:
                    selectedAnnotation && selectedAnnotation.note
                      ? selectedAnnotation.note
                      : undefined,
                })(<TextArea />)}
              </Form.Item>
            </Row>{' '}
            <br></br>
          </Form>
        )}
      </Modal>
    </>
  )
}

export default Form.create()(TableTr)
