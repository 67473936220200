/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Button, Form, notification, Input, Row, Col } from 'antd'
import { useQuery, useMutation } from 'react-apollo'
import { useDispatch } from 'react-redux'
import LoadingComponent from 'components/LoadingComponent'
import { COLORS } from 'assets/styles/globalStyles'
import { UPDATE_CLINIC_BANK_DETAILS, SCHOOL_CURRENCY } from './query'

function BankDetails({ form }) {
  const dispatch = useDispatch()
  const schoolId = localStorage.getItem('userId')
  const { data, error, loading, refetch } = useQuery(SCHOOL_CURRENCY, {
    variables: {
      id: schoolId,
    },
    fetchPolicy: 'network-only',
  })

  const [updateInvoiceDetails, { loading: updateInvoiceDetailLoading }] = useMutation(
    UPDATE_CLINIC_BANK_DETAILS,
  )

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        updateInvoiceDetails({
          variables: {
            bankName: values.bankName,
            accountHolderName: values.accountHolderName,
            bankAccountNo: values.accountNo,
            ifscCode: values.ifscCode,
            gst: values.gst,
          },
        })
          .then(res => {
            notification.success({
              message: 'Details updated successfully',
            })
            dispatch({
              type: 'school/SET_STATE',
              payload: {
                ...res.data.updateClinic.school,
              },
            })
          })
          .catch(errDt => console.log(errDt, 'err'))
      }
    })
  }

  if (loading) {
    return <LoadingComponent />
  }
  if (error) {
    return <div>Oops! Something went wrong</div>
  }

  return (
    <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Col sm={24} md={24} lg={18}>
        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Form.Item
            label="Bank Name"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ paddingTop: '2em' }}
          >
            {form.getFieldDecorator('bankName', {
              initialValue: data.school.bankName,
              rules: [{ required: true, message: 'Please provide bank name' }],
            })(<Input style={{ width: '100%' }} />)}
          </Form.Item>
          <div style={{ display: 'flex' }}>
            <Form.Item
              label="A/C No"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '50%', paddingRight: '1em' }}
            >
              {form.getFieldDecorator('accountNo', {
                initialValue: data.school.bankAccountNo,
                rules: [{ required: true, message: 'Please provide account number' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>
            <Form.Item
              label="IFSC Code"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: '50%' }}
            >
              {form.getFieldDecorator('ifscCode', {
                initialValue: data.school.ifscCode,
                rules: [{ required: true, message: "Please provide bank's IFSC code" }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>
          </div>
          <Form.Item label="A/C Holder's Name" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            {form.getFieldDecorator('accountHolderName', {
              initialValue: data.school.accountHolderName,
              rules: [{ required: true, message: 'Please provide account holder name' }],
            })(<Input style={{ width: '100%' }} />)}
          </Form.Item>
          <Form.Item label="GST" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            {form.getFieldDecorator('gst', {
              initialValue: data.school.gst,
            })(<Input style={{ width: '100%' }} />)}
          </Form.Item>

          <div style={{ display: 'flex' }}>
            <Button
              style={{ backgroundColor: COLORS.submitBlue }}
              loading={updateInvoiceDetailLoading}
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  )
}

export default Form.create()(BankDetails)
