/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  Button,
  Drawer,
  notification,
  Table,
  Menu,
  Popconfirm,
  Dropdown,
  Select,
  Icon,
  Input,
  DatePicker,
  Tooltip,
} from 'antd'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import JsPDF from 'jspdf'
import {
  CloudDownloadOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
  EditOutlined,
  MailOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { useQuery, useMutation } from 'react-apollo'
import moment from 'moment'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import UpdateInvoiceStatus from 'pages/ClinicProfile/Invoices/updateInvoiceStatus'
import DropSection from 'pages/reports/DropSection'
import SendPaymentLinks from './sendPaymentLinks'
import EditInvoice from './editInvoice'
import PreviewInvoice from '../allClinicData/viewInvoice'
import './invoices.scss'
import { GET_INVOICES, DELETE_INVOICE, GET_INVOICE_STATUS_LIST } from './query'

const dateFormate = 'YYYY-MM-DD'
const { RangePicker } = DatePicker
const { Option } = Select

export default () => {
  const [isPreviewInvoice, setPreviewInvoice] = useState(false)
  const [isEditInvoice, setEditInvoice] = useState(false)
  const [selectedInvoiceId, setSelectedInvoiceId] = useState()
  const [data, setData] = useState()
  const [deleteInvoiceId, setDeleteInvoiceId] = useState()
  const [currentInvoice, setCurrentInvoice] = useState(null)
  const [payReminderDrawer, setPayReminderDrawer] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [payReminderData, setPayReminderData] = useState([])
  const [applyFilteredList, setApplyFilteredList] = useState(null)

  // invoice filer
  const [from, setFrom] = useState(moment().subtract(7, 'd'))
  const [to, setTo] = useState(moment())
  const [month, setMonth] = useState()
  const [filterStatus, setFilterStatus] = useState('')
  const [filterCustomer, setFilterCustomer] = useState('')
  const [filterMonth, setFilterMonth] = useState('')
  const [invoiceStatusDrawer, setInvoiceStatusDrawer] = useState(false)

  const { data: invoiceStatusList } = useQuery(GET_INVOICE_STATUS_LIST)

  const { data: invoiceData, error: invoiceError, loading: invoiceLoading, refetch } = useQuery(
    GET_INVOICES,
    {
      variables: {
        from: from
          ? moment(from).format(dateFormate)
          : month
          ? moment(month)
              .startOf('month')
              .format(dateFormate)
          : undefined,
        to: to
          ? moment(to).format(dateFormate)
          : month
          ? moment(month)
              .endOf('month')
              .format(dateFormate)
          : undefined,
        status: filterStatus,
        allclinics: true,
      },
    },
  )

  const [deleteInvoice, { loading: deleteInvoiceLoading }] = useMutation(DELETE_INVOICE)

  useEffect(() => {
    if (invoiceData) {
      const dataList = [...invoiceData.getInvoices.edges]
      const arrengedData = dataList.map(({ node }) => {
        return {
          ...node,
          key: node.id,
          status: node.status.statusName,
          colorCode: node.status.colorCode,
          statusId: node.status.id,
        }
      })
      arrengedData.reverse()
      setData(arrengedData)
    }
  }, [invoiceData])

  useEffect(() => {
    if (invoiceError) {
      notification.error({
        message: 'Something went wrong',
        description: 'Unable to fetch invoices data',
      })
    }
  }, [invoiceError])

  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
      width: 100,
    },
    {
      title: 'Clinic',
      dataIndex: 'clinic.schoolName',
      width: 160,
    },
    {
      title: 'Issue Month',
      dataIndex: 'issueMonth',
      render: (text, row) => {
        return <span>{moment(row.issueDate).format('MMMM')}</span>
      },
    },
    {
      title: 'Issue Date',
      dataIndex: 'issueDate',
      width: 100,
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      width: 100,
    },
    {
      title: 'Amount',
      dataIndex: 'total',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, row) => {
        const color = COLORS[row.colorCode]
        return (
          <Button
            onClick={() => {
              setCurrentInvoice(row)
              setInvoiceStatusDrawer(true)
            }}
            type="link"
            style={{ color, fontSize: 16, padding: 0 }}
          >
            {text}
          </Button>
        )
      },
      width: 100,
    },
    {
      title: 'Action',
      width: 160,
      render: row => {
        return (
          <div>
            <Button
              onClick={() => {
                setCurrentInvoice(row)
                setSelectedInvoiceId(row.key)
                setPreviewInvoice(true)
              }}
              type="link"
            >
              <FilePdfOutlined style={{ fontWeight: 600 }} />
            </Button>

            {row.status !== 'Paid' && (
              <>
                <Button
                  type="link"
                  onClick={() => {
                    setEditInvoice(true)
                    setCurrentInvoice(row)
                  }}
                >
                  <EditOutlined style={{ fontWeight: 600 }} />
                </Button>
                <Popconfirm
                  title="Are you sure to delete this invoice?"
                  onConfirm={() => {
                    deleteInvoice({ variables: { id: row.id } })
                      .then(res => {
                        notification.success({
                          message: 'Delete invoice sucessfully',
                        })
                        refetch()
                        setDeleteInvoiceId(null)
                      })
                      .catch(err => {
                        notification.error({
                          message: 'opps error on delete invoice',
                        })
                        setDeleteInvoiceId(null)
                      })
                    setDeleteInvoiceId(row.id)
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" style={{ color: COLORS.danger }}>
                    <DeleteOutlined style={{ color: COLORS.danger, fontWeight: 600 }} />
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>
        )
      },
    },
  ]

  let filteredList = data || []
  filteredList = filteredList.filter(
    item => item.status && item.status.toLowerCase().includes(filterStatus.toLowerCase()),
  )

  if (filterCustomer) {
    filteredList = filteredList.filter(
      item =>
        item.clinic?.schoolName &&
        item.clinic.schoolName.toLowerCase().includes(filterCustomer.toLowerCase()),
    )
  }
  if (filterMonth) {
    filteredList = filteredList.filter(
      item =>
        item.issueDate &&
        moment(item.issueDate)
          .format('MMMM')
          .toLowerCase()
          .includes(filterMonth.toLowerCase()),
    )
  }

  const statusGrouped = data && data.length > 0 ? [...new Set(data.map(item => item.status))] : []

  const exportPDF = () => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'landscape' // portrait or landscape

    const doc = new JsPDF(orientation, unit, size)

    doc.setFontSize(10)

    const title = 'Invoice List'
    const headers = [['Invoice No', 'Amount', 'Clinic', 'Status', 'Date']]

    const data1 = filteredList.map(e => [e.invoiceNo, e.amount, e.clinic, e.status, e.date])

    const content = {
      startY: 50,
      head: headers,
      body: data1,
    }

    doc.text(title, 10, 10)
    doc.autoTable(content)
    doc.save('invoices.pdf')
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = () => {
    const fileName = 'invoice_excel'
    const formattedData = filteredList.map(function(e) {
      return {
        InvoiceNo: e.invoiceNo,
        Amount: e.amount,
        Clinic: e.clinic,
        Status: e.status,
        Date: e.date,
      }
    })

    const ws = XLSX.utils.json_to_sheet(formattedData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data1 = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data1, fileName + fileExtension)
  }

  const handleMenuActions = e => {
    console.log('selectedRowKeys', selectedRowKeys)
    const names = []
    filteredList.map(item =>
      selectedRowKeys.map(key =>
        key === item.key
          ? names.push({
              key: item.key,
              linkGenerated: item.linkGenerated,
              invNo: item.invoiceNo,
              name: item.clinic?.schoolName,
              email: item.email,
              amount: item.amount,
              status: item.status,
            })
          : null,
      ),
    )

    if (e.key === 'payReminder' && names.length) {
      setPayReminderDrawer(true)
      setPayReminderData(names)
    } else {
      notification.warning({
        message: 'Invoice Not Selected!',
        description: 'Please select at least one Invoice.',
      })
    }
  }

  const handleMultiSelectChange = e => {
    console.log('Press change', e)
    let ll
    if (e.length === 0) {
      setApplyFilteredList(null)
    } else {
      if (e.includes('ALL')) {
        ll = filteredList
      } else {
        ll = filteredList.filter(item => e.includes(item.statusId))
      }
      setApplyFilteredList(ll)
    }
  }

  const onSelectChange = key => {
    setSelectedRowKeys(key)
  }

  const onDateChange = value => {
    console.log('value', value)
    setFrom(value[0])
    setTo(value[1])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const actions = (
    <Menu onClick={e => handleMenuActions(e)}>
      <Menu.Item key="payReminder">
        <MailOutlined /> Send Reminder
      </Menu.Item>
    </Menu>
  )

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button onClick={() => exportPDF()} type="link" size="small">
          PDF
        </Button>
      </Menu.Item>
      <Menu.Item key="1">
        <Button onClick={() => exportToCSV()} type="link" size="small">
          CSV/Excel
        </Button>
      </Menu.Item>
    </Menu>
  )

  const filterHeader = (
    <div
      style={{
        minHeight: '20px',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Dropdown overlay={actions}>
          <Button style={{ marginRight: 16 }}>
            Actions <Icon type="down" />
          </Button>
        </Dropdown>
        <div>
          <Tooltip title="Select Status">
            <Select
              placeholder="Select Status"
              onChange={e => handleMultiSelectChange(e)}
              style={{
                width: 155,
                marginRight: 5,
              }}
              mode="multiple"
              allowClear
              defaultValue={['ALL']}
            >
              <Option value="ALL">All</Option>
              {invoiceStatusList?.invoiceStatusList.map((item, index) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.statusName}
                  </Option>
                )
              })}
            </Select>
          </Tooltip>
        </div>
        <div>
          <Input
            placeholder="Search Clinic"
            value={filterCustomer}
            onChange={e => setFilterCustomer(e.target.value)}
            style={{ width: 170, marginRight: 5 }}
          />
        </div>
        <div>
          <Input
            placeholder="Search By Month"
            value={filterMonth}
            onChange={e => setFilterMonth(e.target.value)}
            style={{ width: 170, marginRight: 5 }}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <DropSection dateChange={onDateChange} />
        </div>
        <RangePicker
          defaultValue={[from, to]}
          value={[from, to]}
          onChange={onDateChange}
          style={{ width: 260 }}
        />
      </div>
      <div style={{ marginLeft: 'auto' }}>
        {filterStatus || filterCustomer ? (
          <Button
            type="link"
            style={{ color: '#FEBB27' }}
            onClick={() => {
              setFilterStatus('')
              setFilterCustomer('')
            }}
            size="small"
          >
            Clear Filters
            <CloseCircleOutlined />
          </Button>
        ) : null}
        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="link" size="large">
            <CloudDownloadOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  )

  return (
    <div style={{ marginTop: 10 }}>
      <Helmet title="Dashboard Alpha" />
      <div className="table-outer-border">
        <Table
          columns={columns}
          dataSource={applyFilteredList || filteredList}
          loading={invoiceLoading}
          title={() => {
            return filterHeader
          }}
          rowKey="id"
          rowSelection={rowSelection}
          size="middle"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ['20', '30', '50', '100'],
            position: 'bottom',
          }}
        />
      </div>

      <Drawer
        visible={isPreviewInvoice}
        width={DRAWER.widthL2}
        className="change-invo-drawer"
        onClose={() => setPreviewInvoice(false)}
      >
        <PreviewInvoice invoiceId={currentInvoice?.id} />
      </Drawer>
      <Drawer
        title={`Edit Invoice - ${currentInvoice?.invoiceNo}`}
        visible={isEditInvoice}
        width={DRAWER.widthL2}
        onClose={() => setEditInvoice(false)}
        destroyOnClose
      >
        <EditInvoice
          rowData={currentInvoice}
          refetchInvoices={refetch}
          closeDrawer={() => setEditInvoice(false)}
        />
      </Drawer>

      <Drawer
        title="Send Invoices"
        visible={payReminderDrawer}
        width={DRAWER.widthL2}
        onClose={() => setPayReminderDrawer(false)}
        destroyOnClose
      >
        <SendPaymentLinks
          selectedRowKeys={selectedRowKeys}
          payReminderData={payReminderData}
          refetchInvoices={refetch}
          closeDrawer={() => setPayReminderDrawer(false)}
        />
      </Drawer>

      <Drawer
        title={`Add Payment - ${currentInvoice?.invoiceNo}`}
        visible={invoiceStatusDrawer}
        width={DRAWER.widthL2}
        destroyOnClose
        onClose={() => setInvoiceStatusDrawer(null)}
      >
        <UpdateInvoiceStatus
          invoiceId={currentInvoice?.id}
          invoiceObj={currentInvoice}
          closeDrawer={() => setInvoiceStatusDrawer(null)}
          refetchInvoices={refetch}
        />
      </Drawer>
    </div>
  )
}
