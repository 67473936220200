/* eslint-disable react/jsx-indent */
import React from 'react'
import { Form, Input, Button, notification } from 'antd'
import { useMutation } from 'react-apollo'
import { CREATE_STAFF_POSITION } from './query'

const CreatePositionForm = ({ form, onSuccess }) => {
  const [createStaffPosition] = useMutation(CREATE_STAFF_POSITION)

  const handleCreatePosition = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        createStaffPosition({
          variables: {
            title: values.positionTitle,
          },
        })
          .then(res => {
            notification.success({
              message: 'Success',
              description: 'Position created successfully',
            })
            onSuccess()
          })
          .catch(error => {
            notification.error({
              message: 'Error',
              description: error.message,
            })
          })
      }
    })
  }

  return (
    <Form onSubmit={handleCreatePosition}>
      <Form.Item label="Position title">
        {form.getFieldDecorator('positionTitle')(<Input placeholder="Position title" />)}
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Submit and Create
      </Button>
    </Form>
  )
}

export default Form.create()(CreatePositionForm)
