const actions = {
  // Template Actions
  SET_DATA: 'recordAssessments/SET_DATA',
  SET_STUDENT_DATA: 'recordAssessments/SET_STUDENT_DATA',
  SET_VIEWER_EMAIL: 'recordAssessments/SET_VIEWER_EMAIL',
  SET_STAFF_EMAIL: 'recordAssessments/SET_STAFF_EMAIL',
  GET_TEMPLATES: 'recordAssessments/GET_TEMPLATES',
  GET_STUDENT_TEMPLATES: 'recordAssessments/GET_STUDENT_TEMPLATES',
  CREATE_TEMPLATE: 'recordAssessments/CREATE_TEMPLATE',
  EDIT_TEMPLATE: 'recordAssessments/EDIT_TEMPLATE',
  DELETE_TEMPLATE: 'recordAssessments/DELETE_TEMPLATE',
  // Template's Assessment Actions
  CREATE_TEMPLATE_ASSESSMENT: 'recordAssessments/CREATE_TEMPLATE_ASSESSMENT',
  DELETE_TEMPLATE_ASSESSMENT: 'recordAssessments/DELETE_TEMPLATE_ASSESSMENT',
  APPEND_TEMPLATE_ASSESSMENT: 'recordAssessments/APPEND_TEMPLATE_ASSESSMENT',
  POP_TEMPLATE_ASSESSMENT: 'recordAssessments/POP_TEMPLATE_ASSESSMENT',
  // Template Module Actions
  CREATE_MODULE: 'recordAssessments/CREATE_MODULE',
  DELETE_MODULE: 'recordAssessments/DELETE_MODULE',
  EDIT_MODULE: 'recordAssessments/EDIT_MODULE',
  APPEND_MODULE: 'recordAssessments/APPEND_MODULE',
  POP_MODULE: 'recordAssessments/POP_MODULE',
  REPLACE_MODULE: 'recordAssessments/REPLACE_MODULE',
  // Template SubModule Actions
  CREATE_SUBMODULE: 'recordAssessments/CREATE_SUBMODULE',
  DELETE_SUBMODULE: 'recordAssessments/DELETE_SUBMODULE',
  EDIT_SUBMODULE: 'recordAssessments/EDIT_SUBMODULE',
  APPEND_SUBMODULE: 'recordAssessments/APPEND_SUBMODULE',
  POP_SUBMODULE: 'recordAssessments/POP_SUBMODULE',
  REPLACE_SUBMODULE: 'recordAssessments/REPLACE_SUBMODULE',
  // Question Actions
  CREATE_QUESTION: 'recordAssessments/CREATE_QUESTION',
  DELETE_QUESTION: 'recordAssessments/DELETE_QUESTION',
  EDIT_QUESTION: 'recordAssessments/EDIT_QUESTION',
  APPEND_QUESTION: 'recordAssessments/APPEND_QUESTION',
  POP_QUESTION: 'recordAssessments/POP_QUESTION',
  REPLACE_QUESTION: 'recordAssessments/REPLACE_QUESTION',
  // Assessment Responses
  GET_ASSESSMENT_RESPONSES: 'recordAssessments/GET_ASSESSMENT_RESPONSES',
  SET_ASSESSMENT_RESPONSES: 'recordAssessments/SET_ASSESSMENT_RESPONSES',
  // Save Response
  SAVE_ASSESSMENT_RESPONSE: 'recordAssessments/SAVE_ASSESSMENT_RESPONSE',
  APPEND_ASSESSMENT_RESPONSE: 'recordAssessments/APPEND_ASSESSMENT_RESPONSE',
  // Last Results
  GET_LAST_ASSESSMENT_RESULTS: 'recordAssessments/GET_LAST_ASSESSMENT_RESULTS',
  APPEND_LAST_ASSESSMENT_RESULTS: 'recordAssessments/APPEND_LAST_ASSESSMENT_RESULTS',

  GET_ALL_SUPER_TEMPLATES: 'recordAssessments/GET_ALL_SUPER_TEMPLATES',
  ASSIGN_CLINICS: 'recordAssessments/ASSIGN_CLINICS',
  GENERATE_QR_CODE: 'recordAssessments/GENERATE_QR_CODE',
  SET_STATE: 'recordAssessments/SET_STATE',
  CREATE_STUDENT_ASSESSMENT_OBJECT: 'recordAssessments/CREATE_STUDENT_ASSESSMENT_OBJECT',
  GET_TEMPLATE_BY_ID: 'recordAssessments/GET_TEMPLATE_BY_ID',
  GET_TEMPLATE_RECORDS: 'recordAssessments/GET_TEMPLATE_RECORDS',
  GET_TEMPLATE: 'recordAssessments/GET_TEMPLATE',
  SAVE_CONSENT_FORM: 'recordAssessments/SAVE_CONSENT_FORM',
  UPDATE_ASSESSMENT_STATUS: 'recordAssessments/UPDATE_ASSESSMENT_STATUS',
  ADD_PARENT_INFO: 'recordAssessments/ADD_PARENT_INFO',
  PAUSE_ASSESSMENT: 'recordAssessments/PAUSE_ASSESSMENT',
  ASSIGN_STAFFS: 'recordAssessments/ASSIGN_STAFFS',
  ASSIGN_RECORDING_TO_THERAPIST: 'recordAssessments/ASSIGN_RECORDING_TO_THERAPIST',
  GET_CENTERES: 'recordAssessment/GET_CENTERES',
  GET_TEMPLATE_DETAILS: 'recordAssessments/GET_TEMPLATE_DETAILS',
  GET_TEACHER_COUNT: 'recordAssessments/GET_TEACHER_COUNT',
  GET_PARENT_COUNT: 'recordAssessments/GET_PARENT_COUNT',
  GET_LANGUAGE: 'recordAssessments/GET_LANGUAGE',
}

export default actions
