import React from 'react'
import { Divider } from 'antd'
import AttachmentFormItem from './attachmentFormItem'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const AttachmentsCard = props => {
  return (
    <div style={boxCard}>
      <Divider orientation="left">Attach Documents</Divider>
      <AttachmentFormItem {...props} />
    </div>
  )
}

export default AttachmentsCard
