import actions from './actions'

const initialState = {
  ProgramAreaId: '',
  ProgramArea: null,
  CogniableAssessmentId: '',
  StudentName: '',
  ShortName: '',
  StudentDetails: null,
  StudentId: null,
  LearnerSessionLoading: false,
  LearnerSessions: [],
  LearnerChildSessions: [],
  DrawerWidth: '30%',
  activeTab: 'home',
  SelectedColor: null,
}

export default function goalsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
