import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, DatePicker, Drawer, notification, Select, Table, Tooltip } from 'antd'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import SessionFeedbackForm from 'pages/sessionFeedback'
import React, { useEffect, useState } from 'react'
import { FaDownload } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import DropSection from 'pages/reports/DropSection'
import {
  GET_APPOINTMENTS,
  GET_APPOINTMENT_STATUSES,
  GET_LEARNERS,
  GET_THERAPIST,
  UPDATE_APPOINTMENT_STATUS,
} from './query'
import './style.scss'
import '../../pages/reports/table.scss'

const { RangePicker } = DatePicker
const { Option } = Select

const childLabel = { fontSize: '15px', color: '#000' }

const AppointmentReport = () => {
  const [selectedLearner, setSelectedLearner] = useState()
  const [selectedTherapist, setSelectedTherapist] = useState()
  const [appointmentList, setAppointmentList] = useState([])
  const [feedbackDrawer, setFeedbackDrawer] = useState(false)
  const [selectedAppointmentId, setSelectedAppointmentId] = useState()
  const [copyAppointmentList, setCopyAppointmentList] = useState([])
  const user = useSelector(state => state.user)
  const [sDate, setSDate] = useState(
    moment(Date.now())
      .subtract(21, 'days')
      .format('YYYY-MM-DD')
      .toString(),
  )
  const [eDate, setEDate] = useState(
    moment(Date.now())
      .format('YYYY-MM-DD')
      .toString(),
  )
  let pendingS = 0
  let completeS = 0
  let cancelledS = 0

  const { data: allLearners, loading: isLearnerLoading } = useQuery(GET_LEARNERS)
  const { data: allTherapist, loading: isTherapistLoading } = useQuery(GET_THERAPIST)
  const { data: allAppointment, loading: isAppointmentLoading, refetchAllAppointment } = useQuery(
    GET_APPOINTMENTS,
    {
      variables: {
        dateFrom: moment(sDate).format('YYYY-MM-DD'),
        dateTo: moment(eDate).format('YYYY-MM-DD'),
        studentId: selectedLearner,
        therapistId: user.role === 'therapist' ? user.staffObject?.id : selectedTherapist,
      },
      fetchPolicy: 'network-only',
    },
  )
  const { data: allAppointmentStatus, loading: allAppointmentStatusLoading } = useQuery(
    GET_APPOINTMENT_STATUSES,
  )
  const [
    updateAppointmentStatus,
    { data: updateAppointmentStatusData, error: updateAppointmentStatusError },
  ] = useMutation(UPDATE_APPOINTMENT_STATUS)

  const dispatch = useDispatch()

  useEffect(() => {
    const therapistId = localStorage.getItem('current_therapist')
    if (therapistId !== null && therapistId !== undefined) {
      setSelectedTherapist(JSON.parse(therapistId))
      localStorage.removeItem('current_therapist')
    }
  }, [])

  useEffect(() => {
    if (allAppointment) {
      const processedAppointments = allAppointment.appointments.edges.map(({ node }) => ({
        id: node.id,
        studentName: node.student ? `${node?.student?.firstname} ${node?.student?.lastname}` : '',
        therapistName: `${node?.therapist?.name} ${node?.therapist?.surname}`,
        startTime: moment(node.start).format('YYYY-MM-DD HH:mm'),
        endTime: moment(node.end).format('YYYY-MM-DD HH:mm'),
        isApproved: node.isApproved,
        title: node.title,
        purposeAssignment: node.purposeAssignment ?? 'N/A',
        status: node?.appointmentStatus?.id,
        note: node.note,
        location: node?.location?.location ?? 'N/A',
      }))
      // setStatusScore --> endTime,startTime, allAppointmentStatus
      setAppointmentList(processedAppointments)
      setCopyAppointmentList(processedAppointments)
    }
  }, [allAppointment])

  const appointmentColumns = [
    {
      title: '#',
      sorter: (a, b) => a.index - b.index,
      dataIndex: 'index',
      align: 'center',
      width: 100,
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Learner Name',
      dataIndex: 'studentName',
    },
    {
      title: 'Therapist Name',
      dataIndex: 'therapistName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (text, record) => (
        <Select
          placeholder="Select Status"
          loading={allAppointmentStatusLoading}
          value={text}
          onChange={value => saveAppointmentStatus(record.id, value)}
          style={{ width: 100 }}
        >
          {allAppointmentStatus &&
            allAppointmentStatus.appointmentStatuses.map(node => (
              <Option key={node.id} value={node.id}>
                {node.appointmentStatus}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      title: 'Feedback',
      dataIndex: '',
      align: 'center',
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => showFeedback(record.id)} size="small">
            Feedback
          </Button>
        )
      },
    },
  ]

  const exportToCSV = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

    const dataToExport = appointmentList.map(item => ({
      Title: item.title,
      'Student Name': item.studentName,
      'Therapist Name': item.therapistName,
      'Start Time': item.startTime,
      'End Time': item.endTime,
      'Purpose Assignment': item.purposeAssignment,
      Note: item.note,
      Location: item.location,
      'Is Approved': item.isApproved,
      Status: allAppointmentStatus.appointmentStatuses?.filter(node => node.id === item.status)?.[0]
        ?.appointmentStatus,
    }))

    const ws = XLSX.utils.json_to_sheet(dataToExport)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })

    const startDate = moment(sDate).format('YYYY_MM_DD')
    const endDate = moment(eDate).format('YYYY_MM_DD')
    FileSaver.saveAs(excelData, `Appointments_${startDate}-${endDate}.xlsx`)
  }

  const showFeedback = id => {
    setFeedbackDrawer(true)

    setSelectedAppointmentId(id)
    dispatch({
      type: 'feedback/SET_STATE',
      payload: {
        AppointmnetId: id,
      },
    })
  }

  const saveAppointmentStatus = (appointmentId, newStatus) => {
    updateAppointmentStatus({
      variables: {
        appointmentId,
        newStatus,
      },
    })
  }

  useEffect(() => {
    if (updateAppointmentStatusData)
      notification.success({ message: 'Status updated successfully.' })
  }, [updateAppointmentStatusData])

  useEffect(() => {
    if (updateAppointmentStatusError)
      notification.error({ message: 'An error occurred to update status.' })
  }, [updateAppointmentStatusError])

  const parentDiv = { display: 'flex', margin: '5px 30px 5px 0' }

  const filterCardStyle = {
    background: COLORS.palleteLight,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px 10px',
    margin: 0,
    height: 'fit-content',
    overflow: 'hidden',
  }
  const DateChangeDropDown = date => {
    setSDate(
      moment(date[0])
        .format('YYYY-MM-DD')
        .toString(),
    )
    setEDate(
      moment(date[1])
        .format('YYYY-MM-DD')
        .toString(),
    )
  }

  const convertMinToHours = min => {
    const hours = Math.trunc(min / 60)
    const minutes = min % 60
    return `${hours} Hr : ${minutes} Min`
  }

  const handleStatusSelect = e => {
    if (e) {
      setAppointmentList(copyAppointmentList.filter(item => item.status === e))
    } else {
      setAppointmentList(copyAppointmentList)
    }
    // const dummyList = JSON.parse(appointmentList)
  }

  return (
    <div className="appointmentReport">
      <div style={filterCardStyle}>
        <div style={parentDiv}>
          <DropSection dateChange={v => DateChangeDropDown(v)} />
        </div>
        <div style={parentDiv}>
          <Tooltip title="Select Date range">
            <RangePicker
              style={{
                width: 250,
                borderRadius: 4,
                marginRight: 20,
              }}
              value={[moment(sDate, 'YYYY-MM-DD'), moment(eDate, 'YYYY-MM-DD')]}
              defaultValue={[moment(sDate, 'YYYY-MM-DD'), moment(eDate, 'YYYY-MM-DD')]}
              onChange={DateChangeDropDown}
              className="datePicker"
            />
          </Tooltip>
        </div>
        <div style={parentDiv}>
          <Tooltip title="Select Learner">
            <Select
              placeholder="Select Learner"
              loading={isLearnerLoading}
              showSearch
              allowClear
              optionFilterProp="children"
              value={selectedLearner}
              onChange={setSelectedLearner}
              style={{ width: '200px', marginRight: '10px' }}
            >
              {allLearners?.students?.edges?.map(({ node: { id, firstname, lastname } }) => (
                <Option key={id} value={id}>
                  {`${firstname} ${lastname}`}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </div>
        {user.role === 'school_admin' && (
          <div style={parentDiv}>
            <Tooltip title="Select Therapist">
              <Select
                placeholder="Select Therapist"
                loading={isTherapistLoading}
                showSearch
                allowClear
                optionFilterProp="children"
                value={selectedTherapist}
                onChange={setSelectedTherapist}
                style={{ width: '200px' }}
              >
                {allTherapist?.staffs?.edges?.map(({ node: { id, name, surname } }) => (
                  <Option key={id} value={id}>
                    {`${name} ${surname}`}
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </div>
        )}
        <div style={parentDiv}>
          <Tooltip title="Select Status">
            <Select
              placeholder="Select Status"
              showSearch
              allowClear
              optionFilterProp="children"
              onChange={e => handleStatusSelect(e)}
              style={{ width: '200px' }}
            >
              <Option key="QXBwb2ludG1lbnRTdGF0dXNUeXBlOjM=">Complete</Option>
              <Option key="QXBwb2ludG1lbnRTdGF0dXNUeXBlOjI=">Pending</Option>
              <Option key="QXBwb2ludG1lbnRTdGF0dXNUeXBlOjQ=">Cancelled</Option>
            </Select>
          </Tooltip>
        </div>

        <Button
          type="link"
          size="large"
          disabled={isAppointmentLoading || appointmentList.length === 0}
          onClick={exportToCSV}
          style={{ padding: '0 8px', marginLeft: 'auto' }}
        >
          <FaDownload fontSize="22px" />
        </Button>
      </div>

      {appointmentList.map(data => {
        const { status, startTime, endTime } = data
        // Pending
        if (status === 'QXBwb2ludG1lbnRTdGF0dXNUeXBlOjI=') {
          const date1 = moment(startTime)
          const date2 = moment(endTime)
          const diff = date2.diff(date1, 'minutes')
          pendingS += diff
        }
        // complete
        if (status === 'QXBwb2ludG1lbnRTdGF0dXNUeXBlOjM=') {
          const date1 = moment(startTime)
          const date2 = moment(endTime)
          const diff = date2.diff(date1, 'minutes')
          completeS += diff
        }
        // cancelled
        if (status === 'QXBwb2ludG1lbnRTdGF0dXNUeXBlOjQ=') {
          const date1 = moment(startTime)
          const date2 = moment(endTime)
          const diff = date2.diff(date1, 'minutes')
          cancelledS += diff
        }
        return null
      })}
      <div style={{ backgroundColor: '#fff' }}>
        <div
          style={{
            display: 'flex',
            width: '50%',
            margin: 'auto',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <span style={{ ...childLabel, color: 'grey' }}>
            <ExclamationCircleOutlined /> Pending : {convertMinToHours(pendingS)}
          </span>
          <span style={{ ...childLabel, color: 'green' }}>
            <CheckCircleOutlined /> Completed : {convertMinToHours(completeS)}
          </span>
          <span style={{ ...childLabel, color: 'red' }}>
            <CloseCircleOutlined /> Cancelled : {convertMinToHours(cancelledS)}
          </span>
        </div>
      </div>
      <div style={{ padding: '0 0 10px 10px' }}>
        <Table
          className="allReportTable"
          loading={isAppointmentLoading}
          rowKey={record => record.id}
          columns={appointmentColumns}
          dataSource={appointmentList.map((item, index) => ({ ...item, index: index + 1 }))}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ['20', '30', '50', '100'],
            // position: 'top',
          }}
          bordered
          expandedRowRender={row => (
            <>
              <p style={{ margin: 0 }}>
                <b>Is Approved: </b>
                {row.isApproved ? 'Yes' : 'No'}
              </p>
              <p style={{ margin: 0 }}>
                <b>Purpose Assignment: </b>
                {row.purposeAssignment}
              </p>
              {/* <p style={{ margin: 0 }}>
                <b>Location: </b>
                {row.location}
              </p> */}
              <p style={{ margin: 0 }}>
                <b>Note: </b>
                {row.note}
              </p>
            </>
          )}
          scroll={{ x: appointmentList.length, y: '80vh' }}
        />
      </div>

      <Drawer
        title="Give Session Feedback"
        placement="right"
        width={DRAWER.widthL3}
        closable
        onClose={() => setFeedbackDrawer(false)}
        visible={feedbackDrawer}
      >
        <SessionFeedbackForm appointmentId={selectedAppointmentId} key={selectedAppointmentId} />
      </Drawer>
    </div>
  )
}

export default AppointmentReport
