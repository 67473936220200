/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { all, put, takeEvery, call, select } from 'redux-saga/effects'
import { getClinics, updateClinic, getClinicLearners } from 'services/clinic'
import actions from './actions'

export function* GET_CLINICS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isClinicLoading: true,
    },
  })

  const response = yield call(getClinics, { ...payload })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        clinics: response.clinicAllDetails,
        totalClinicCount: response.allClinics.totalCount,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isClinicLoading: false,
    },
  })
}
export function* UPDATE_CLINIC({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isClinicSaving: true,
    },
  })
  const response = yield call(updateClinic, payload)
  if (response && response.updateSchool) {
    notification.success({
      message: 'Clinic updated successfully!',
    })
    yield put({
      type: actions.REPLACE_CLINIC,
      payload: {
        data: response.updateSchool.details,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isClinicSaving: false,
    },
  })
}

export function* GET_CLINIC_LEARNERS_PAGE_CHANGED({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  let { first, pageNo } = payload
  const { learnerPageInfo: pageInfo, learnerPagetype: type } = yield select(state => state.clinics)

  let after = null
  let before = null
  let last = null

  if (pageNo === 1) {
    after = null
    before = null
  }
  if (type === 'next') {
    after = pageInfo?.endCursor
  }
  if (type === 'prev') {
    before = pageInfo?.startCursor
    last = first
    first = null
  }

  const response = yield call(getClinicLearners, { ...payload, after, before, first, last })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        learners: response.students.edges,
        learnerPageInfo: response.students.pageInfo,
        learnerCount: response.students.clinicTotal,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CLINICS, GET_CLINICS),
    takeEvery(actions.UPDATE_CLINIC, UPDATE_CLINIC),
    takeEvery(actions.GET_CLINIC_LEARNERS_PAGE_CHANGED, GET_CLINIC_LEARNERS_PAGE_CHANGED),
  ])
}
