/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const STUDENT_QUERY = gql`
  query StudentDetails($studentId: ID!) {
    student(id: $studentId) {
      id
      firstname
      lastname
      currentAddress
      dob
      email
      parentMobile
      createdAt
    }
  }
`

export const ss = gql`
  query {
    goalStatus {
      id
      status
    }
  }
`
// goals
export const GOALS_DETAILS = gql`
  query LongTermGoalProgress($studentId: ID!, $status: [ID]!, $start: Date!, $end: Date!) {
    goalsLongProgressReport(student: $studentId, status: $status, start: $start, end: $end) {
      masteryDays
      dateMastered
      goal {
        id
        goalName
        dateInitialted
        program {
          id
          name
          domain {
            edges {
              node {
                id
                domain
              }
            }
          }
        }
        dateEnd
        goalStatus {
          id
          status
        }
        isDefault
        shorttermgoalSet {
          edges {
            node {
              id
              goalName
              dateInitialted
              dateEnd
              masterDate
              goalStatus {
                id
                status
              }
              targetAllocateSet {
                edges {
                  node {
                    id
                    targetStatus {
                      id
                      statusName
                    }
                    masterDate
                    intherapyDate
                    targetAllcatedDetails {
                      id
                      targetName
                      dateBaseline
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_GOAL_NEW_DETAILS = gql`
  query($student: ID!) {
    longTerm(student: $student) {
      edges {
        node {
          id
          goalName
          description
          dateInitialted
          dateEnd
          masterDate
          student {
            id
            firstname
          }
          responsibility {
            id
            name
          }
          goalStatus {
            id
            status
          }
          shorttermgoalSet {
            edges {
              node {
                id
                goalName
                dateInitialted
                dateEnd
                masterDate
                goalStatus {
                  id
                  status
                }
                targetAllocateSet {
                  edges {
                    node {
                      id
                      date
                      mastery {
                        edges {
                          node {
                            id
                            sd {
                              id
                              sd
                            }
                            step {
                              id
                              step
                            }
                            status {
                              id
                              statusName
                            }
                            createdAt
                            targetsdstepstatuschangeSet {
                              edges {
                                node {
                                  id
                                  sdStep {
                                    id
                                    sd {
                                      id
                                      sd
                                    }
                                    step {
                                      id
                                      step
                                    }
                                  }
                                  createdAt
                                  updatedAt
                                  toStatus {
                                    id
                                    statusName
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      targetStatus {
                        id
                        statusName
                      }
                      masterDate
                      intherapyDate
                      targetAllcatedDetails {
                        id
                        targetName
                        dateBaseline
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_TARGET_DAILY_RESPONSE = gql`
  query getTargetDailyResponse($targetId: ID!, $start: Date!, $end: Date!) {
    getCorrectPercentage(target: $targetId, start: $start, end: $end) {
      date
      correctPercent
    }
  }
`

// Mand progress
export const GET_MAND_REPORT = gql`
  query getMandReport($studentId: ID!, $start: Date!, $end: Date!) {
    mandReport(studentId: $studentId, startDate: $start, endDate: $end) {
      total
      measurments
      data {
        date
        formattedDate
        count
      }
    }
  }
`

// behaviour template
export const GET_TEMPLATE_REPORT = gql`
  query getMandReport($studentId: ID!, $start: Date!, $end: Date!) {
    getTemplateForReports(student: $studentId, start: $start, end: $end) {
      template {
        id
        behaviorDef
        behaviorDescription
        student {
          id
          firstname
        }
        behavior {
          id
          behaviorName
        }
      }
    }
  }
`
export const DOMAIN = gql`
  query domainMastered($studentId: ID, $start: Date, $end: Date) {
    domainMastered(
      studentId: $studentId
      dateGte: $start
      dateLte: $end
      targetStatus: "U3RhdHVzVHlwZTo0"
    ) {
      target {
        id
        targetId {
          id
          domain {
            id
            domain
          }
        }
        targetAllcatedDetails {
          id
          targetName
          dateBaseline
        }
      }
    }
  }
`
export const DOMAIN_INTERVENTION = gql`
  query domainMastered($studentId: ID, $start: Date, $end: Date) {
    domainMastered(
      studentId: $studentId
      dateGte: $start
      dateLte: $end
      targetStatus: "U3RhdHVzVHlwZToz"
    ) {
      target {
        id
        targetId {
          id
          domain {
            id
            domain
          }
        }
        targetAllcatedDetails {
          id
          targetName
          dateBaseline
        }
      }
    }
  }
`
