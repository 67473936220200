/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Layout, Button } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import styles from './style.module.scss'

const LoginLayout = ({ children }) => {
  const [backgroundNumber, setBackgroundNumber] = useState(1)
  const [backgroundEnabled, setBackgroundEnabled] = useState(false)

  const changeBackground = () => {
    setBackgroundNumber(backgroundNumber === 5 ? 1 : backgroundNumber + 1)
    setBackgroundEnabled(true)
  }

  const toggleBackground = () => {
    setBackgroundEnabled(!backgroundEnabled)
  }

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Layout.Content>
        <div className={styles.customBackgroung}>
          <div align="left" className={styles.customLayout}>
            <img
              src="resources/images/HeaderLogo.png"
              alt="HeaderLogo"
              style={{ height: '35px' }}
            />
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(LoginLayout)
