/* eslint-disable prefer-template */
/* eslint-disable  react-hooks/rules-of-hooks */
/* eslint-disable no-array-constructor */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'

import gql from 'graphql-tag'
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Font } from '@react-pdf/renderer'
import { Typography, Row, Layout, Col, Tabs, Card, Table, Tag, Select, Button } from 'antd'
import moment from 'moment'
import { calculateAge } from '../../../utilities'
import { COLORS } from 'assets/styles/globalStyles'
import PdfHeader from '../pdf/PdfHeader/PdfHeader'
const LOGO = gql`
  query {
    schoolDetail {
      logo
      schoolName
      address
    }
  }
`

const dateFormat = 'YYYY-MM-DD'
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const page = {
  padding: '20px 15px',
}
const section = {
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}

const ReportPdf = ({
  textBoxObj,
  start,
  learnerDetails,
  goalsDetails,
  behaviorTemplates,
  goalsImages,
  longTerm,
  shortTerm,
  target,
}) => {
  console.log('goalsImages>>>', goalsImages, longTerm, shortTerm, target)
  const { data, error, loading } = useQuery(LOGO, { fetchPolicy: 'no-cache' })
  const [logo, setLogo] = useState()
  useEffect(() => {
    if (data) {
      console.log('logo-->', data)
      setLogo(data.schoolDetail)
    }
  }, [data])

  return (
    <PDFViewer style={{ margin: '0 auto', width: '900px', height: '750px' }}>
      <Document>
        <Page size="A4" wrap={true} style={page} scale={1}>
          <View
            style={{
              display: 'flex',
              textAlign: 'center',
              backgroundColor: COLORS.palleteLight,
              padding: '8px',
            }}
          >
            <Text style={{ margin: 'auto' }}>Monthly Report</Text>
            {/* <Image src={logo}></Image> */}
          </View>
          <View style={{ ...section, justifyContent: 'space-between' }}>
            <View style={{ fontSize: '11px', padding: '15px 0' }}>
              <Text style={{ marginBottom: '5px' }}>
                Name: {learnerDetails?.firstname} {learnerDetails?.lastname}
              </Text>
              <Text style={{ marginBottom: '5px' }}>Date of Birth: {learnerDetails?.dob}</Text>
              <Text style={{ marginBottom: '5px' }}>
                Age: {learnerDetails?.dob && calculateAge(learnerDetails?.dob)}
              </Text>
              <Text style={{ marginBottom: '5px' }}>
                Location: {learnerDetails?.currentAddress}
              </Text>
              <Text style={{ marginBottom: '5px' }}>
                Month: {monthNames[moment(start).format('MM') - 1]} {moment(start).format('YYYY')}
              </Text>
            </View>
            <View style={{ width: '20%', alignSelf: 'center' }}>
              {/* NEED TO SOLVED CORS ERROR FROM BACKEND TO SHOW CLINIC LOGO & Albi Implemented temporary code for now.  */}
              {logo && logo.logo && (
                <Image src={logo.logo} style={{ width: '100%', alignSelf: 'center' }} />
              )}
            </View>
            {/* <View
          style={{
            textAlign: 'center',
            width: '250px',
            alignSelf: 'center',
            marginLeft: '20px',
            height: 'auto',
          }}
        >
          <Text
            style={{
              fontSize: 15,
              marginBottom: '4px',
              width: '100%',
              alignSelf: 'flex-start',
              textAlign: 'left',
            }}
          >
            {logo && logo.schoolName}
          </Text>
          <Text
            style={{
              marginBottom: '4px',
              fontSize: 12,
              width: '100%',
              alignSelf: 'flex-start',
              textAlign: 'left',
            }}
          >
            { logo && logo.address}
          </Text>
          </View> */}
          </View>

          <View
            style={{
              display: 'flex',
              textAlign: 'center',
              backgroundColor: COLORS.palleteLight,
              padding: 8,
            }}
          >
            <Text style={{ margin: 'auto' }}>Progress Overview</Text>
          </View>
          <View style={{ fontSize: '11px', padding: '15px 0', color: 'black' }}>
            <Text>{ReactHtmlParser(textBoxObj.progressOverview)}</Text>
            <View>
              {goalsImages.progressMasterGraph && (
                <Image
                  src={goalsImages.progressMasterGraph}
                  style={{
                    width: '100%',
                    alignSelf: 'center',
                    margin: '10px auto',
                  }}
                ></Image>
              )}
            </View>
            <View>
              {goalsImages.progressInterventionGraph && (
                <Image
                  src={goalsImages.progressInterventionGraph}
                  style={{
                    width: '100%',
                    alignSelf: 'center',
                    margin: '10px auto',
                  }}
                ></Image>
              )}
            </View>
          </View>
        </Page>
        <Page size="A4" wrap={true} style={page} scale={1}>
          <View
            style={{
              display: 'flex',
              textAlign: 'center',
              marginTop: '10px',
              backgroundColor: COLORS.palleteLight,
              padding: 8,
            }}
          >
            <Text style={{ margin: 'auto' }}>Goals</Text>
          </View>
          <View style={{ fontSize: '11px', padding: '15px 0', color: 'black' }}>
            <Text style={{ marginBottom: '5px' }}>{ReactHtmlParser(textBoxObj.goals)}</Text>
          </View>
          <View>
            {goalsImages.goalGraph && (
              <Image
                src={goalsImages.goalGraph}
                style={{
                  width: '100%',
                  alignSelf: 'center',
                  margin: '10px auto',
                }}
              ></Image>
            )}
          </View>
          <View style={{ fontSize: '11px' }}>
            <View>
              <View
                style={{
                  textAlign: 'left',
                  marginTop: '10px',
                  backgroundColor: COLORS.palleteLight,
                  padding: 8,
                }}
              >
                <Text style={{ margin: 'auto' }}>Long Term Goals</Text>
              </View>
              {Object.keys(longTerm).length !== 0 ? (
                Object.keys(longTerm).map((item, itemIndex) => {
                  return (
                    <>
                      <View key={Math.random()} style={{ borderBottom: '1px solid black' }}>
                        <Text style={{ marginTop: '5px' }}>
                          {item} Long Term Goals : {longTerm[item].length}
                        </Text>
                        {longTerm[item].length !== 0 &&
                          longTerm[item].map(lg => {
                            return (
                              <>
                                <View
                                  style={{
                                    color: 'black',
                                    border: '2px solid',
                                    borderRadius: '5px',
                                    margin: '5px',
                                    padding: '3px',
                                    borderColor: COLORS.palleteLight,
                                  }}
                                >
                                  <Text style={{ margin: 2 }}>
                                    <b>Long Term Goal :</b> {lg?.goal.goalName}
                                  </Text>
                                  <Text style={{ margin: 0 }}>
                                    <Text>Status:</Text> {item} {'  '}
                                    <Text>Initiated Date:</Text>
                                    {'  '}
                                    {moment(lg?.goal.dateInitialted).format(dateFormat)},{'  '}
                                    <Text>Mastered Date:</Text>
                                    {lg?.dateMastered}
                                    {'  '}
                                    <Text>DateEnd:</Text>
                                    {lg?.goal.dateEnd}
                                  </Text>
                                </View>
                              </>
                            )
                          })}
                      </View>
                    </>
                  )
                })
              ) : (
                <Text>No Short-Term Goal Found!!</Text>
              )}
            </View>
          </View>
          <View style={{ fontSize: '11px' }}>
            <View>
              <View
                style={{
                  textAlign: 'left',
                  marginTop: '10px',
                  backgroundColor: COLORS.palleteLight,
                  padding: 8,
                }}
              >
                <Text style={{ margin: 'auto' }}>Short Term Goals</Text>
              </View>
              {Object.keys(shortTerm).length !== 0 ? (
                Object.keys(shortTerm).map((item, itemIndex) => {
                  return (
                    <>
                      <View key={Math.random()} style={{ borderBottom: '1px solid black' }}>
                        <Text style={{ marginTop: '5px' }}>
                          {item} Short Term Goals : {shortTerm[item].length}
                        </Text>
                        {shortTerm[item].length !== 0 &&
                          shortTerm[item].map(lg => {
                            return (
                              <>
                                <View
                                  style={{
                                    margin: '5px',
                                    padding: '3px',
                                    borderColor: COLORS.palleteLight,
                                  }}
                                >
                                  <Text style={{ margin: 0 }}>
                                    <Text>Short Term Goal :</Text> {lg?.goal.goalName}
                                  </Text>
                                  <Text style={{ margin: 0 }}>
                                    <Text>Status:</Text> {item}
                                    {'   '}
                                    <Text>Initiated Date:</Text>
                                    {'  '}
                                    {moment(lg?.goal.dateInitialted).format(dateFormat)}
                                    {'   '}
                                    <Text>Mastered Date:</Text>
                                    {lg?.dateMastered}
                                    {'   '}
                                    <Text>DateEnd:</Text>
                                    {lg?.goal.dateEnd}
                                  </Text>
                                </View>
                              </>
                            )
                          })}
                      </View>
                    </>
                  )
                })
              ) : (
                <Text>No Short-Term Goal Found!!</Text>
              )}
            </View>
          </View>
          <View style={{ fontSize: '11px' }}>
            <View>
              <View
                style={{
                  textAlign: 'left',
                  marginTop: '10px',
                  backgroundColor: COLORS.palleteLight,
                  padding: 8,
                }}
              >
                <Text style={{ margin: 'auto' }}>Targets</Text>
              </View>
              {Object.keys(target).length !== 0 ? (
                Object.keys(target).map((item, itemIndex) => {
                  return (
                    <>
                      <View key={Math.random()} style={{ borderBottom: '1px solid black' }}>
                        <Text>
                          {item} Targets : {target[item].length}
                        </Text>
                        {target[item].length !== 0 &&
                          target[item].map(lg => {
                            return (
                              <>
                                <View
                                  style={{
                                    margin: '5px',
                                    padding: '3px',
                                  }}
                                >
                                  <Text style={{ margin: 0 }}>
                                    <Text>Target :</Text>{' '}
                                    {lg?.goal.targetAllcatedDetails?.targetName}
                                  </Text>
                                  <Text style={{ margin: 0 }}>
                                    <Text>Status:</Text> {item}
                                    {'  '}
                                    <Text>Initiated Date:</Text>{' '}
                                    {moment(lg?.goal.targetAllcatedDetails?.dateBaseline).format(
                                      dateFormat,
                                    )}
                                    <Text>Mastered Date:</Text>
                                    {'  '}
                                    {lg?.goal.masterDate}
                                    {'  '}
                                    <Text>DateEnd:</Text>
                                    {lg?.goal.dateEnd}
                                  </Text>
                                </View>
                              </>
                            )
                          })}
                      </View>
                    </>
                  )
                })
              ) : (
                <Text>No targets Found!!</Text>
              )}
            </View>
          </View>
        </Page>
        <Page size="A4" wrap={true} style={page} scale={1}>
          <View wrap={false}>
            <View
              style={{
                display: 'flex',
                textAlign: 'center',
                marginTop: '10px',
                backgroundColor: COLORS.palleteLight,
                padding: 8,
              }}
            >
              <Text style={{ margin: 'auto' }}>Behavior Report</Text>
            </View>
            <View style={{ fontSize: '11px', padding: '10px 0', color: 'black' }}>
              <Text>{textBoxObj.behaviour}</Text>
            </View>
          </View>
          <View>
            <Text style={{ borderBottom: '1px solid black' }}>Frequency and Rate Recording</Text>
            <View>
              {goalsImages.behaviorFR && (
                <Image
                  src={goalsImages.behaviorFR}
                  style={{
                    width: '100%',
                    alignSelf: 'center',
                    margin: '10px auto',
                  }}
                ></Image>
              )}
            </View>
            <Text style={{ borderBottom: '1px solid black' }}>Duration Recording</Text>

            <View>
              {goalsImages.behaviorDR && (
                <Image
                  src={goalsImages.behaviorDR}
                  style={{
                    width: '100%',
                    alignSelf: 'center',
                    margin: '10px auto',
                  }}
                ></Image>
              )}
            </View>
          </View>
        </Page>
        <Page size="A4" wrap={true} style={page} scale={1}>
          <View wrap={false}>
            <View
              style={{
                display: 'flex',
                marginTop: '10px',
                textAlign: 'center',
                backgroundColor: COLORS.palleteLight,
                padding: 8,
              }}
            >
              <Text style={{ margin: 'auto' }}>Mand Report</Text>
            </View>
            <View style={{ fontSize: '11px', padding: '15px 0', color: 'black' }}>
              <Text style={{ marginBottom: '5px' }}>{textBoxObj.mand}</Text>
            </View>
          </View>
          <View>
            <View>
              {goalsImages.mandGraph && (
                <Image
                  src={goalsImages.mandGraph}
                  style={{
                    width: '100%',
                    alignSelf: 'center',
                    margin: '10px auto',
                  }}
                ></Image>
              )}
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default ReportPdf
