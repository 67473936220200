/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */

import React, { useState, useEffect } from 'react'
import { FileTextOutlined } from '@ant-design/icons'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import moment from 'moment'
import './styles.scss'
import ProgBar from './progBar'

const getDate = (dateIn, dateEnd) => {
  const startDate = moment(dateIn)
  const endDate = moment(dateEnd)

  const years = endDate.diff(startDate, 'year')
  startDate.add(years, 'years')

  const months = endDate.diff(startDate, 'months')
  startDate.add(months, 'months')

  const days = endDate.diff(startDate, 'days')

  const duration = `${years > 0 ? `, ${years} Year` : ''} ${months > 0 ? `${months} Months` : ''} ${
    days > 0 ? `${days} Days` : ''
  }`

  const date =
    `${moment(dateIn).format('MMMM DD YYYY')} - ${endDate.format('MMMM DD YYYY')}` +
    `${duration === '  ' || duration === ' ' ? ' ' : `, ${duration}`}`

  return date
}

function LtgCard({ goalName, dateInitialted, dateEnd, targetData }) {
  return (
    <div className="ltg-card" style={{ display: 'flex' }}>
      <div style={{ width: 35, height: '100%' }}>
        <FileTextOutlined
          style={{ marginTop: 6, fontSize: 20, fontWeight: 600, color: COLORS.primary }}
        />
      </div>
      <div>
        <div style={{ fontSize: 15 }}>{goalName}</div>
        <span>{getDate(dateInitialted, dateEnd)}</span>
        <div>{targetData.length > 0 ? <ProgBar targetData={targetData} /> : 'No target found'}</div>
      </div>
    </div>
  )
}

export default LtgCard
