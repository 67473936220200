const actions = {
  SET_STATE: 'iisaassessment/SET_STATE',
  LOAD_DATA: 'iisaassessment/LOAD_DATA',
  CREATE_ASSESSMENT: 'iisaassessment/CREATE_ASSESSMENT',
  LOAD_ASSESSMENT_OBJECT: 'iisaassessment/LOAD_ASSESSMENT_OBJECT',
  LOAD_ASSESSMENT_REPORT: 'iisaassessment/LOAD_ASSESSMENT_REPORT',
  RECORD_RESPONSE: 'iisaassessment/RECORD_RESPONSE',
  MAKE_INACTIVE: 'iisaassessment/MAKE_INACTIVE',
  
}

export default actions
