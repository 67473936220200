/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
import { all, takeEvery, put, call, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import {
  getAllTemplates,
  createTemplate,
  deleteTemplate,
  saveAssignClinics,
  generateQRCode,
  createTemplateAssessment,
  deleteTemplateAssessment,
  createModule,
  deleteModule,
  editModule,
  createSubModule,
  deleteSubModule,
  editSubModule,
  createQuestion,
  deleteQuestion,
  editQuestion,
  fetchAssessmentResponses,
  saveAssessmentResponse,
  getPastAssessmentResults,
  getAllSuperTemplates,
  createStudentAssessmentObject,
  getTemplateById,
  getTemplateRecords,
  getTemplateType,
  saveConsentForm,
  updateAssessmentStatus,
  addParentInfo,
  pauseAssessment,
  saveAssignStaffs,
  assignRecordingToTherapist,
  getCenters,
  editTemplate,
  getDetails,
  getTeacherCount,
  getParentCount,
  getLanguage,
} from '../../services/RecordAssessments/service'

export function* GET_ALL_TEMPLATES({ payload }) {
  yield put({
    type: actions.SET_DATA,
    payload: {
      templatesLoading: true,
    },
  })
  const response = yield call(getAllTemplates, payload)

  if (response) {
    const detailsLoading = response.map(temp => ({ id: temp.id, loading: false }))

    yield put({
      type: actions.SET_DATA,
      payload: {
        templatesLoading: false,
        templates: response,
        detailsLoading,
      },
    })
  } else {
    yield put({
      type: actions.SET_DATA,
      payload: {
        templatesLoading: false,
      },
    })
  }
}

export function* GET_ALL_SUPER_TEMPLATES({ payload }) {
  yield put({
    type: actions.SET_DATA,
    payload: {
      templatesLoading: true,
    },
  })
  const response = yield call(getAllSuperTemplates)
  if (response) {
    const detailsLoading = response.map(temp => ({ id: temp.id, loading: false }))

    yield put({
      type: actions.SET_DATA,
      payload: {
        templatesLoading: false,
        templates: response,
        detailsLoading,
      },
    })
  }
}

export function* ASSIGN_CLINICS({ payload }) {
  const response = yield call(saveAssignClinics, payload)
  if (response) {
    yield call(GET_ALL_SUPER_TEMPLATES, { payload })
    // const templates = yield select(state => state.recordAssessments.templates)
    // console.log('templates--->', templates)

    yield put({
      type: actions.SET_DATA,
      payload: {
        templatesLoading: false,
        assignModal: false,
      },
    })
  }
}

export function* ASSIGN_STAFFS({ payload }) {
  const response = yield call(saveAssignStaffs, payload)
  if (response) {
    const School = yield select(state => state.school)
    const user = yield select(state => state.user)

    yield call(GET_ALL_TEMPLATES, {
      payload: {
        clinicId: user.role === 'therapist' ? user.staffObject?.school?.id : School.id,
        therapistId: user.role === 'therapist' ? user.staffObject?.id : null,
      },
    })
  }
}

export function* ASSIGN_RECORDING_TO_THERAPIST({ payload }) {
  const response = yield call(assignRecordingToTherapist, payload)
  if (response) {
    console.log('recording to therapist----', response)
    const School = yield select(state => state.school)
    const user = yield select(state => state.user)
    if (user.role !== 'therapist') {
      notification.success({
        message: 'Assessment Recording is Successfully Assigned to Therapist.',
      })
    }
  }
}

export function* GET_STUDENT_ALL_TEMPLATES({ payload }) {
  const response = yield call(getAllTemplates, payload)
  if (response) {
    yield put({
      type: actions.SET_STUDENT_DATA,
      payload: {
        studentTemplatesLoading: false,
        studentTemplates: response,
      },
    })
  }
}

export function* CREATE_TEMPLATE({ payload }) {
  const response = yield call(createTemplate, payload)
  if (response) {
    // yield call(GET_ALL_TEMPLATES, { payload: { email: payload.createdBy } })
    yield call(GET_ALL_SUPER_TEMPLATES, { payload })
  }
}

export function* EDIT_TEMPLATE({ payload }) {
  const response = yield call(editTemplate, payload)
  if (response) {
    // yield call(GET_ALL_TEMPLATES, { payload: { email: payload.createdBy } })
    yield call(GET_ALL_SUPER_TEMPLATES, { payload })
  }
}

export function* DELETE_TEMPLATE({ payload }) {
  const response = yield call(deleteTemplate, payload.id)
  if (response) {
    yield call(GET_ALL_SUPER_TEMPLATES, { payload })
  }
}

export function* CREATE_TEMPLATE_ASSESSMENT({ payload }) {
  const response = yield call(createTemplateAssessment, payload)
  if (response) {
    yield put({
      type: actions.APPEND_TEMPLATE_ASSESSMENT,
      payload: {
        data: response,
        templateID: payload.templateID,
      },
    })
  }
}

export function* DELETE_TEMPLATE_ASSESSMENT({ payload }) {
  const response = yield call(deleteTemplateAssessment, payload)
  if (response) {
    yield put({
      type: actions.POP_TEMPLATE_ASSESSMENT,
      payload: {
        assessmentID: payload.id,
        templateID: payload.templateID,
      },
    })
  }
}

export function* CREATE_MODULE({ payload }) {
  const response = yield call(createModule, payload)
  if (response) {
    yield put({
      type: actions.APPEND_MODULE,
      payload: {
        templateID: payload.templateID,
        data: response,
      },
    })
  }
}

export function* DELETE_MODULE({ payload }) {
  const response = yield call(deleteModule, payload)
  if (response) {
    yield put({
      type: actions.POP_MODULE,
      payload: {
        templateID: payload.templateID,
        moduleID: payload.moduleID,
      },
    })
  }
}

export function* EDIT_MODULE({ payload }) {
  const response = yield call(editModule, payload)
  if (response) {
    yield put({
      type: actions.REPLACE_MODULE,
      payload: {
        ...payload,
        data: response,
      },
    })
  }
}

export function* CREATE_SUBMODULE({ payload }) {
  const response = yield call(createSubModule, payload)
  if (response) {
    yield put({
      type: actions.APPEND_SUBMODULE,
      payload: {
        templateID: payload.templateID,
        moduleID: payload.moduleID,
        data: response,
      },
    })
  }
}

export function* DELETE_SUBMODULE({ payload }) {
  const response = yield call(deleteSubModule, payload)
  if (response) {
    yield put({
      type: actions.POP_SUBMODULE,
      payload: {
        templateID: payload.templateID,
        moduleID: payload.moduleID,
        submoduleID: payload.submoduleID,
      },
    })
  }
}

export function* EDIT_SUBMODULE({ payload }) {
  const response = yield call(editSubModule, payload)
  if (response) {
    yield put({
      type: actions.REPLACE_SUBMODULE,
      payload: {
        ...payload,
        data: response,
      },
    })
  }
}

export function* CREATE_QUESTION({ payload }) {
  const response = yield call(createQuestion, payload)
  if (response) {
    yield put({
      type: actions.APPEND_QUESTION,
      payload: {
        templateID: payload.templateID,
        moduleID: payload.moduleID,
        addQuestionTo: payload.addQuestionTo,
        data: response,
      },
    })
  }
}

export function* DELETE_QUESTION({ payload }) {
  const response = yield call(deleteQuestion, payload)
  if (response) {
    yield put({
      type: actions.POP_QUESTION,
      payload: {
        templateID: payload.templateID,
        moduleID: payload.moduleID,
        deleteFrom: payload.deleteFrom,
        questionID: payload.questionID,
      },
    })
  }
}

export function* EDIT_QUESTION({ payload }) {
  const response = yield call(editQuestion, payload)
  if (response) {
    yield put({
      type: actions.REPLACE_QUESTION,
      payload: {
        ...payload,
        data: response,
      },
    })
  }
}

export function* GET_ASSESSMENT_RESPONSES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      responseLoading: true,
    },
  })
  const response = yield call(fetchAssessmentResponses, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        lastActiveQuestionId: null,
      },
    })
    yield put({
      type: actions.SET_ASSESSMENT_RESPONSES,
      payload: {
        assessmentID: payload.assessmentID,
        data: response,
      },
    })

    yield call(GET_LAST_ASSESSMENT_RESULTS, { payload: { assessmentID: payload.assessmentID } })
  }
}

export function* SAVE_ASSESSMENT_RESPONSE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      answerSaving: true,
    },
  })
  const response = yield call(saveAssessmentResponse, payload)
  if (response) {
    yield put({
      type: actions.APPEND_ASSESSMENT_RESPONSE,
      payload: {
        ...payload,
        data: response,
      },
    })
  }
}

export function* GET_LAST_ASSESSMENT_RESULTS({ payload }) {
  const response = yield call(getPastAssessmentResults, payload)
  if (response) {
    yield put({
      type: actions.APPEND_LAST_ASSESSMENT_RESULTS,
      payload: {
        assessmentID: payload.assessmentID,
        data: response,
      },
    })
  }
}

export function* GENERATE_QR_CODE({ payload }) {
  const response = yield call(generateQRCode, payload)
  console.log(response)
  if (response) {
    const templates = yield select(state => state.recordAssessments.templates)
    if (templates.length > 0) {
      // do something
      templates.map(template => {
        if (template.assignTemplateDetails.id === response.clinicAssessment.id) {
          template.assignTemplateDetails = {
            ...template.assignTemplateDetails,
            qrCode: response.clinicAssessment.qrCode,
            assessUrl: response.clinicAssessment.assessUrl,
          }
        }
        return template
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          templates,
        },
      })
    }
  }
}

export function* CREATE_STUDENT_ASSESSMENT_OBJECT({ payload }) {
  console.log(payload)
  const response = yield call(createStudentAssessmentObject, {
    assignTemplateId: payload.templateId,
    center: payload.center,
    name: payload.values.name,
    lastName: payload.values.lastName,
    fatherName: payload.values.fatherName,
    motherName: payload.values.motherName,
    age: payload.age,
    dob: payload.dob,
    address: payload.values.address,
    email: payload.values.email,
    sex: payload.values.gender,
    phone: payload.values.phone,
    clinicId: payload.clinicId,
    userType: payload.userType,
    performingUser1: payload.performingUser1,
    teachingType: payload.teachingType,
    maritalStatus: payload.maritalStatus,
    educationalQualifications: payload.educationalQualifications,
    teachingExperience: payload.teachingExperience,
    studyLevel: payload.studyLevel,
    language: payload.language,
  })
  console.log(response)
  if (response) {
    // yield call(updateAssessmentStatus, { id: response.studentAssessment.id, status: 'Inprogress' })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStudentAssessment: false,
        recordingTemplateAssessment: response.studentAssessment,
        studentAssessment: response.studentAssessment,
        selectedTemplate: response.studentAssessment.assessment,
        assessmentSaved: true,
      },
    })
  }
}
const getAllQuestions = studentAssess => {
  const { userType, assessment, paymentSet, needPayment } = studentAssess
  const temp = []
  assessment.submoduleSet.forEach(module => {
    if (module.questionSet?.length > 0) {
      module.questionSet.forEach(question =>
        temp.push({
          ...question,
          moduleType: module.moduleType,
          moduleID: module.id,
          moduleTitle: module.title,
          description: module.description,
        }),
      )
    } else {
      module.submoduleSet.forEach(subModule => {
        subModule.questionSet.forEach(question =>
          temp.push({
            ...question,
            moduleID: module.id,
            moduleType: module.moduleType,
            moduleTitle: module.title,
            description: module.description,
            subModuleID: subModule.id,
            subModuleTitle: subModule.title,
          }),
        )
      })
    }
  })
  // return temp

  return userType && userType !== 'THERAPIST'
    ? temp.filter(item => item.moduleType !== 'PCR')
    : temp

  // return userType === 'PARENTS' || userType === 'CAREGIVERS'
  //   ? temp.filter(item => item.moduleType !== 'PCRFSP')
  //   : userType === 'SINGLE_PARENT' || userType === 'SINGLE_CAREGIVER'
  //   ? temp.filter(item => item.moduleType !== 'PCR')
  //   : temp
}
export function* GET_TEMPLATE_BY_ID({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      assessmentLoading: true,
    },
  })
  const response = yield call(getTemplateById, payload)
  console.log('getTemplateById put>>>', response)

  yield put({
    type: actions.SET_STATE,
    payload: {
      selectedTemplateId: payload.templateId,
    },
  })
  if (response) {
    console.log('getTemplateById>>>', response)
    const questions = getAllQuestions(response)
    yield put({
      type: actions.SET_STATE,
      payload: {
        studentAssessment: response,
        saveConsentInfo: false,
        statusUpdateLoading: false,
        allQuestions: questions,
        currModuleType: {
          name: questions.length && questions[0].moduleTitle ? questions[0].moduleTitle : '',
          description: questions.length && questions[0].description ? questions[0].description : '',
        },
        prevModuleType: {
          name: questions.length && questions[0].moduleTitle ? questions[0].moduleTitle : '',
          description: questions.length && questions[0].description ? questions[0].description : '',
        },
      },
    })
    let answeredBy = null
    const {
      userType,
      fatherPerforming,
      motherPerforming,
      caregiver1Performing,
      caregiver2Performing,
      status,
      performingUser2,
      performingUser1,
    } = response
    if (userType === 'PARENTS') {
      if (
        (motherPerforming &&
          motherPerforming === 'COMPLETED' &&
          (fatherPerforming === 'INPROGRESS' ||
            fatherPerforming === 'PENDING' ||
            !fatherPerforming)) ||
        ((fatherPerforming === 'INPROGRESS' || fatherPerforming === 'PENDING') && !motherPerforming)
      ) {
        answeredBy = 'father'
      } else if (
        (fatherPerforming &&
          fatherPerforming === 'COMPLETED' &&
          (motherPerforming === 'INPROGRESS' ||
            motherPerforming === 'PENDING' ||
            !motherPerforming)) ||
        ((motherPerforming === 'INPROGRESS' || motherPerforming === 'PENDING') && !fatherPerforming)
      ) {
        answeredBy = 'mother'
      } else if (status === 'COMPLETED') {
        if (performingUser2 === 'MOTHER') answeredBy = 'mother'
        if (performingUser2 === 'FATHER') answeredBy = 'father'
      }
    }
    if (userType === 'CAREGIVERS') {
      if (
        (caregiver1Performing &&
          caregiver1Performing === 'COMPLETED' &&
          (caregiver2Performing === 'INPROGRESS' ||
            caregiver2Performing === 'PENDING' ||
            !caregiver2Performing)) ||
        ((caregiver2Performing === 'INPROGRESS' || caregiver2Performing === 'PENDING') &&
          !caregiver1Performing)
      ) {
        answeredBy = 'caregiver2'
      } else if (
        (caregiver2Performing &&
          caregiver2Performing === 'COMPLETED' &&
          (caregiver1Performing === 'INPROGRESS' ||
            caregiver1Performing === 'PENDING' ||
            !caregiver1Performing)) ||
        ((caregiver1Performing === 'INPROGRESS' || caregiver1Performing === 'PENDING') &&
          !caregiver2Performing)
      ) {
        answeredBy = 'caregiver1'
      } else if (status === 'COMPLETED') {
        if (performingUser2 === 'CAREGIVER1') answeredBy = 'caregiver1'
        if (performingUser2 === 'CAREGIVER2') answeredBy = 'caregiver2'
      }
    }
    if (userType && (userType === 'THERAPIST' || userType.includes('SINGLE')) && performingUser1) {
      console.log('performing User---->', userType, performingUser1)
      answeredBy = performingUser1.toLowerCase()
    }
    console.log('answeredBY---->', answeredBy)
    yield call(GET_ASSESSMENT_RESPONSES, {
      payload: {
        assessmentID: response.id,
        answeredBy,
      },
    })

    // WRITE PROCESSING LOGIC HERE
  }
}

export function* GET_TEMPLATE_RECORDS({ payload }) {
  const response = yield call(getTemplateRecords, payload)
  if (response) {
    console.log(response)

    yield put({
      type: actions.SET_STATE,
      payload: {
        studentTemplates: response,
        studentTemplatesLoading: false,
      },
    })

    // WRITE PROCESSING LOGIC HERE
  }
}

export function* GET_TEMPLATE_TYPE({ payload }) {
  const response = yield call(getTemplateType, payload)
  if (response) {
    console.log(response)

    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedTemplate: response.length ? { ...response[0] } : null,
      },
    })

    // WRITE PROCESSING LOGIC HERE
  }
}

export function* SAVE_CONSENT_FORM({ payload }) {
  const response = yield call(saveConsentForm, payload)
  if (response) {
    console.log(response)
    yield call(GET_TEMPLATE_BY_ID, {
      payload: {
        assessmentID: response.consent.studentAssessment.id,
        templateID: response.consent.studentAssessment.assessment.id,
      },
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedTemplateConsent: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        saveConsentInfo: false,
      },
    })
  }
}

export function* UPDATE_ASSESSMENT_STATUS({ payload }) {
  yield put({
    type: 'recordAssessments/SET_STATE',
    payload: {
      statusUpdateLoading: true,
    },
  })
  const response = yield call(updateAssessmentStatus, payload)
  if (response) {
    console.log(response)

    yield call(GET_TEMPLATE_BY_ID, {
      payload: {
        assessmentID: response.studentAssessment.id,
        templateID: response.studentAssessment.assessment.id,
      },
    })
  }
}

export function* ADD_PARENT_INFO({ payload }) {
  console.log(payload)
  const response = yield call(addParentInfo, payload)
  console.log(response)
  if (response) {
    console.log('done>>')

    // yield call(updateAssessmentStatus, { id: response.studentAssessment.id, status: 'Inprogress' })
    yield put({
      type: actions.SET_STATE,
      payload: {
        saveParentInfo: false,
        studentAssessment: response.studentAssessment,
        recordingTemplateAssessment: response.studentAssessment,
        addedParentInfo: response.studentAssessment,
        parentSaved: true,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        saveParentInfo: false,
      },
    })
  }
}

export function* PAUSE_ASSESSMENT({ payload }) {
  console.log(payload)
  const response = yield call(pauseAssessment, payload)
  console.log(response)
  if (response) {
    console.log('done>>')
  }
}

export function* GET_ALL_CENTERS({ payload }) {
  console.log(payload)
  yield put({
    type: 'recordAssessments/SET_STATE',
    payload: {
      centerLoading: true,
    },
  })
  const response = yield call(getCenters, payload)
  console.log(response)
  if (response) {
    console.log('done>>centeres', response)
    const centers = response.map(item => ({ id: item.id, label: item.centerName, ...item }))
    yield put({
      type: 'recordAssessments/SET_STATE',
      payload: {
        centerLoading: false,
        centers,
      },
    })
  } else {
    yield put({
      type: 'recordAssessments/SET_STATE',
      payload: {
        centerLoading: true,
      },
    })
  }
}
const mapQuestions = questions => {
  return questions.filter(question => !question.isDeleted)
}

const mapSubModules = subModules => {
  subModules = subModules.filter(submodule => !submodule.isDeleted)
  return subModules.map(submodule => {
    return {
      ...submodule,
      questionSet: mapQuestions(submodule.questionSet),
    }
  })
}
export function* GET_TEMPLATE_DETAILS({ payload }) {
  const detailsLoading = yield select(state => state.recordAssessments.detailsLoading)
  const filterIdx = detailsLoading.findIndex(item => item.id === payload.templateID)
  if (filterIdx !== -1) detailsLoading[filterIdx].loading = true
  console.log('filter', filterIdx, detailsLoading)
  yield put({
    type: 'recordAssessments/SET_STATE',
    payload: {
      detailsLoading,
    },
  })
  const response = yield call(getDetails, payload)
  console.log('GET_TEMPLATE_DETAILS put>>>', response)

  if (response) {
    const templates = yield select(state => state.recordAssessments.templates)
    const tempIdx = templates.findIndex(item => item.id === payload.templateID)
    if (tempIdx !== -1) {
      const submoduleSet = response[0].submoduleSet.filter(module => !module.isDeleted)

      templates[tempIdx].submoduleSet = submoduleSet.map(module => {
        return {
          ...module,
          questionSet: mapQuestions(module.questionSet),
          submoduleSet: mapSubModules(module.submoduleSet),
        }
      })
    }
    if (filterIdx !== -1) detailsLoading[filterIdx].loading = false

    yield put({
      type: 'recordAssessments/SET_STATE',
      payload: {
        detailsLoading,
        templates,
      },
    })
  }
}

export function* GET_TEACHER_COUNT({ payload }) {
  console.log(payload)
  yield put({
    type: 'recordAssessments/SET_STATE',
    payload: {
      isTeacherCountLoading: true,
    },
  })
  const response = yield call(getTeacherCount, payload)
  console.log(response)
  if (response) {
    yield put({
      type: 'recordAssessments/SET_STATE',
      payload: {
        isTeacherCountLoading: false,
        teacherCount: JSON.parse(response),
      },
    })
  }
  yield put({
    type: 'recordAssessments/SET_STATE',
    payload: {
      isTeacherCountLoading: false,
    },
  })
}

export function* GET_PARENT_COUNT({ payload }) {
  console.log(payload)
  yield put({
    type: 'recordAssessments/SET_STATE',
    payload: {
      isParentCountLoading: true,
    },
  })
  const response = yield call(getParentCount, payload)
  console.log(response)
  if (response) {
    yield put({
      type: 'recordAssessments/SET_STATE',
      payload: {
        isParentCountLoading: false,
        parentCount: JSON.parse(response),
      },
    })
  } else {
    yield put({
      type: 'recordAssessments/SET_STATE',
      payload: {
        isParentCountLoading: false,
        parentCount: null,
      },
    })
  }
}

export function* GET_LANGUAGE({ payload }) {
  yield put({
    type: 'recordAssessments/SET_STATE',
    payload: {
      languageLoading: true,
    },
  })
  const response = yield call(getLanguage, payload)
  console.log(response)
  if (response) {
    yield put({
      type: 'recordAssessments/SET_STATE',
      payload: {
        languageLoading: false,
        languageList: response,
      },
    })
  }
  yield put({
    type: 'recordAssessments/SET_STATE',
    payload: {
      languageLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TEMPLATES, GET_ALL_TEMPLATES),
    takeEvery(actions.GET_STUDENT_TEMPLATES, GET_STUDENT_ALL_TEMPLATES),
    takeEvery(actions.CREATE_TEMPLATE, CREATE_TEMPLATE),
    takeEvery(actions.DELETE_TEMPLATE, DELETE_TEMPLATE),
    takeEvery(actions.EDIT_TEMPLATE, EDIT_TEMPLATE),

    takeEvery(actions.CREATE_TEMPLATE_ASSESSMENT, CREATE_TEMPLATE_ASSESSMENT),
    takeEvery(actions.DELETE_TEMPLATE_ASSESSMENT, DELETE_TEMPLATE_ASSESSMENT),
    takeEvery(actions.CREATE_MODULE, CREATE_MODULE),
    takeEvery(actions.DELETE_MODULE, DELETE_MODULE),
    takeEvery(actions.EDIT_MODULE, EDIT_MODULE),
    takeEvery(actions.CREATE_SUBMODULE, CREATE_SUBMODULE),
    takeEvery(actions.DELETE_SUBMODULE, DELETE_SUBMODULE),
    takeEvery(actions.EDIT_SUBMODULE, EDIT_SUBMODULE),
    takeEvery(actions.CREATE_QUESTION, CREATE_QUESTION),
    takeEvery(actions.DELETE_QUESTION, DELETE_QUESTION),
    takeEvery(actions.EDIT_QUESTION, EDIT_QUESTION),
    takeEvery(actions.GET_ASSESSMENT_RESPONSES, GET_ASSESSMENT_RESPONSES),
    takeEvery(actions.SAVE_ASSESSMENT_RESPONSE, SAVE_ASSESSMENT_RESPONSE),
    takeEvery(actions.GET_LAST_ASSESSMENT_RESULTS, GET_LAST_ASSESSMENT_RESULTS),

    takeEvery(actions.GET_ALL_SUPER_TEMPLATES, GET_ALL_SUPER_TEMPLATES),
    takeEvery(actions.ASSIGN_CLINICS, ASSIGN_CLINICS),
    takeEvery(actions.GENERATE_QR_CODE, GENERATE_QR_CODE),
    takeEvery(actions.CREATE_STUDENT_ASSESSMENT_OBJECT, CREATE_STUDENT_ASSESSMENT_OBJECT),
    takeEvery(actions.GET_TEMPLATE_BY_ID, GET_TEMPLATE_BY_ID),
    takeLatest(actions.GET_TEMPLATE_RECORDS, GET_TEMPLATE_RECORDS),
    takeEvery(actions.GET_TEMPLATE, GET_TEMPLATE_TYPE),
    takeEvery(actions.SAVE_CONSENT_FORM, SAVE_CONSENT_FORM),
    takeEvery(actions.UPDATE_ASSESSMENT_STATUS, UPDATE_ASSESSMENT_STATUS),
    takeEvery(actions.ADD_PARENT_INFO, ADD_PARENT_INFO),
    takeEvery(actions.PAUSE_ASSESSMENT, PAUSE_ASSESSMENT),
    takeEvery(actions.ASSIGN_STAFFS, ASSIGN_STAFFS),
    takeEvery(actions.ASSIGN_RECORDING_TO_THERAPIST, ASSIGN_RECORDING_TO_THERAPIST),
    takeEvery(actions.GET_CENTERES, GET_ALL_CENTERS),

    takeEvery(actions.GET_TEMPLATE_DETAILS, GET_TEMPLATE_DETAILS),
    takeEvery(actions.GET_TEACHER_COUNT, GET_TEACHER_COUNT),
    takeLatest(actions.GET_PARENT_COUNT, GET_PARENT_COUNT),
    takeLatest(actions.GET_LANGUAGE, GET_LANGUAGE),
  ])
}
