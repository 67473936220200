/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Popover, Button, notification } from 'antd'
import { useQuery, useMutation, useLazyQuery } from 'react-apollo'
import moment from 'moment'
import LoadingComponent from './LoadingComponent'
import { COLOR_UPDATE, GET_VBMAPP_SCORE } from './queries'
import { LoadingContext } from './context'
import { calculateTestingAge, get4Block } from './helperFunction'

const StudentAndTestDetails = ({
  scoreDetails,
  studentDetails,
  color,
  setColor,
  onSelectionChange,
  selectedAssessment,
  refetch,
  areaId,
  levelNo,
}) => {
  const LoadingC = useContext(LoadingContext)
  const { dataLoading, setDataLoading, setLevel } = LoadingC
  const [totalScore, setTotalScore] = useState([])
  const [updateColor] = useMutation(COLOR_UPDATE, {
    fetchPolicy: 'no-cache',
  })
  const [
    VBMAPPMilestoneScore,
    {
      data: assessmenttotalScore,
      error: assessmenttotalScoreErrors,
      loading: isAssessmentTotalScoreLoading,
      refetch: refetchScoreData,
    },
  ] = useLazyQuery(GET_VBMAPP_SCORE, { fetchPolicy: 'network-only' })

  useEffect(() => {
    console.log('areaId>>>', areaId, levelNo, areaId === 'VmJtYXBwQXJlYTo1' && selectedAssessment)
    if (areaId === 'VmJtYXBwQXJlYTo1' && selectedAssessment) {
      console.log('levelNo>>', levelNo)
      VBMAPPMilestoneScore({
        variables: {
          studentID: studentDetails.id,
          assessment: selectedAssessment,
          levelNo,
        },
      })
    }
    if (areaId === 'VmJtYXBwQXJlYTox' && selectedAssessment) {
      VBMAPPMilestoneScore({
        variables: {
          studentID: studentDetails.id,
          assessment: selectedAssessment,
        },
      })
    }
  }, [areaId, selectedAssessment, levelNo])

  useEffect(() => {
    if (isAssessmentTotalScoreLoading) {
      setDataLoading(isAssessmentTotalScoreLoading)
      const student = document.getElementById('testDetails')
      setLevel(level => ({
        ...level,
        student,
      }))
    } else {
      setDataLoading(false)
    }
  }, [isAssessmentTotalScoreLoading])

  useEffect(() => {
    if (
      assessmenttotalScore &&
      scoreDetails &&
      (areaId === 'VmJtYXBwQXJlYTox' || areaId === 'VmJtYXBwQXJlYTo1')
    ) {
      const score = []
      const finalScore = [1, 2, 3, 4].map(index => ({
        key: index,
        testTitle: '',
        id: '',
        score: '',
        date: '',
        color: 'trasparant',
        tester: '',
      }))

      assessmenttotalScore.vbmappAssessmentTotalScores &&
        assessmenttotalScore.vbmappAssessmentTotalScores.forEach(item => {
          score.push({
            id: item?.assessment.id,
            testTitle: `Test #${item?.assessment?.testNo}`,
            score: item?.total,
            testNo: item?.assessment?.testNo,
            date: moment(item?.assessment?.createdDate).format('YYYY-MM-DD'),
          })
        })
      scoreDetails.reverse().forEach((testdetails, index) => {
        const f = score.filter(i => i.id === testdetails.id)
        const temp = f.map(j => {
          return {
            ...j,
            color: testdetails.color,
            tester: testdetails.tester,
          }
        })
        if (temp.length) {
          let pos = Math.floor(temp[0].testNo % 4)
          if (pos === 0) {
            finalScore[3] = { ...temp[0] }
          } else {
            finalScore[pos - 1] = { ...temp[0] }
          }
        }
      })
      setTotalScore(finalScore)
    } else {
      const finalelseScore = [1, 2, 3, 4].map(index => ({
        key: index,
        testTitle: '',
        id: '',
        score: '',
        date: '',
        color: 'trasparant',
        tester: '',
        testNo: '',
      }))
      if (scoreDetails.length) {
        const block = get4Block(scoreDetails[0].testNo)
        scoreDetails.forEach((testdetails, index) => {
          if (block.includes(testdetails.testNo)) {
            let pos = Math.floor(testdetails.testNo % 4)
            if (pos === 0) {
              finalelseScore[3] = { ...testdetails }
            } else {
              finalelseScore[pos - 1] = { ...testdetails }
            }
          }
        })
      }

      setTotalScore(scoreDetails)
    }
  }, [assessmenttotalScore])

  const colors = ['#FF9C52', '#3E7BFA', '#5F6CAF', '#4BAEA0', '#FF8080', '#7480FF', '#AAAAAA']
  const RenderColor = k => {
    const onColorChangeHandle = item => {
      updateColor({
        variables: {
          pk: k.id,
          color: item,
        },
      })
        .then(res => {
          refetchScoreData()
          notification.success({
            message: 'Color updated Sucessfully',
          })
          refetch()
        })
        .catch(ert => console.error(ert))
    }

    const content = (
      <div>
        {colors.map(item => {
          // const { backgroundColor, borderColor } = renderStatus(item)
          return (
            <div key={item} style={{ textAlign: 'center' }}>
              <Button
                style={{
                  boxShadow: '5px 5px 10px 2px #ddd',
                  marginBottom: '5px',
                  padding: 5,
                  width: '40px',
                  backgroundColor: `${item}`,
                }}
                onClick={e => {
                  console.log('in status change')
                  onColorChangeHandle(item)
                  // return setColor({
                  //   ...color,
                  //   [k]: item,
                  // })
                }}
              />
            </div>
          )
        })}
      </div>
    )

    return (
      <span>
        <Popover
          trigger="click"
          content={content}
          placement="bottom"
          // visible={visible}
          // onVisibleChange={(val) => setVisible(val)}
        >
          <Button
            style={{
              border: `1px solid white`,
            }}
          >
            <span className="colorBlock" style={{ backgroundColor: k.color, width: 40 }} />
          </Button>
        </Popover>
      </span>
    )
  }

  return (
    <div id="testDetails">
      <Row className="studentAndScoreDetails">
        <Col sm={24} md={12}>
          <table cellSpacing="0">
            <tbody>
              <tr>
                <th>Child&apos;s Name:</th>
                <td colSpan={studentDetails.ageAtTest.length}>{studentDetails.name}</td>
              </tr>
              <tr>
                <th>Date of Birth:</th>
                <td colSpan={studentDetails.ageAtTest.length}>{studentDetails.dob}</td>
              </tr>
              <tr>
                <th>Age at Testing</th>
                {totalScore.map(testAge => (
                  <td key={Math.random()} style={{ minWidth: '50px' }}>
                    {testAge.date !== ''
                      ? calculateTestingAge(testAge.date, studentDetails.dob)
                      : ''}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </Col>
        <Col sm={24} md={12}>
          {isAssessmentTotalScoreLoading && <LoadingComponent />}
          {!isAssessmentTotalScoreLoading && (
            <table cellSpacing="0">
              <tbody>
                <tr>
                  <th>Key:</th>
                  <th>Score</th>
                  <th>Date</th>
                  <th>Color</th>
                  <th>Tester</th>
                </tr>

                {totalScore.map(testDetails => {
                  const k = testDetails.testTitle
                  return (
                    <>
                      <tr key={Math.random()}>
                        <th>
                          <Button
                            type="link"
                            size="large"
                            onClick={() => {
                              onSelectionChange(testDetails.id)
                            }}
                            style={{
                              marginRight: '8px',
                              fontWeight: 'bold',
                              padding: '0 8px',
                            }}
                          >
                            {testDetails.testTitle}
                          </Button>
                        </th>
                        <td>{testDetails.score}</td>
                        <td>{testDetails.date}</td>
                        <td>
                          {RenderColor(testDetails)}
                          {/* {color ? (
                        RenderColor(testDetails)
                      ) : (
                        <span
                          className="colorBlock"
                          style={{ backgroundColor: testDetails.color }}
                        />
                      )} */}

                          {/* <input
                        type="color"
                        id="color"
                        style={{
                          border:'none'
                        }}
                        name="color"
                        value={color[k]}
                        onChange={e => setColor({
                          ...color,
                          [k]:e.target.value,})
                        }
                      /> */}
                        </td>
                        <td>{testDetails.tester}</td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default StudentAndTestDetails
