/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
import moment from 'moment'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  createChildSession,
  createFirstTragetAndTrialInstance,
  createNewTargetInstance,
  createNewTargetPeakAutomaticInstance,
  createTargetBlock,
  finishChildSession,
  getChildSessionData,
  getCombinationsByCode,
  getTargets,
  getTodaysSession,
  recordBehaviorFrequency,
  recordBehaviorReductionTrial,
  recordBehaviorTotalTime,
  recordBlockTrial,
  recordEquivalenceTarget,
  recordEquivalenceTargetUpdate,
  recordTargetCorrectTrial,
  recordTargetStepTrial,
  recordTargetStimulusTrial,
  resetTargetData,
  updateBehaviorReductionTargetTrial,
  updateBlockTrial,
  updateChildSessionDuration,
  updateTargetBlock,
  updateTargetEndTime,
  getSessionTemplatesNew,
  updateTargetTrial,
  runSessionEndMasteryCriteria,
  updateBehaviorFrequency,
  deleteBehaviorFrequency,
  resetSdStepSbtstep,
  resetBlock,
  resetTrial,
  updateTargetNote,
} from 'services/sessionrecording'
import { TargetTypes, PeakTypes, TargetSatus } from 'constants/index'
import actions from './actions'

const debug = false
const peakId = TargetTypes.PEAK
const behaviorRecordingId = TargetTypes.BEHAVIOR_RECORDING
const taskAnalysisId = TargetTypes.TASK_ANALYSIS
const equivalence = PeakTypes.EQUIVALENCE
const masteredStatusId = TargetSatus.MASTERED

// create peak stimulus block
function createStimulusList(list) {
  const len = list.length
  const k = 0
  const newList = []
  if (len > 0) {
    for (let i = 0; i < 10; i += 1) {
      newList.push({
        sd: list[0].node,
        // sd: list[k].node,
        // sdIndex: k,
        position: i,
        recordedData: false,
        marksRecorded: false,
        response: {},
      })
    }
  }
  // return shuffle(newList)
  return newList
}

// create video url by video id
function getVideoUrl(url) {
  // const videoId = url.substring(url.lastIndexOf('/') + 1);
  let finalUrl = ''
  let splitList = []
  let videoId = ''
  splitList = url.split('/')
  if (splitList.length > 3) {
    if (url.split('/')[2] === 'www.youtube.com' || url.split('/')[2].includes('youtu')) {
      finalUrl = url
    } else {
      videoId = url.split('/')[3]
      finalUrl = `https://player.vimeo.com/video/${videoId}/`
    }
  }
  if (debug) console.log(videoId)
  // return videoId
  return finalUrl
}

function resetCorrectIncorrectButtons() {
  const element = document.getElementById('correctResponseButton')

  // If it isn't "undefined" and it isn't "null", then it exists.
  if (typeof element !== 'undefined' && element !== null) {
    document.getElementById('correctResponseButton').style.color = 'gray'
    document.getElementById('correctResponseButton').style.borderColor = '#e4e9f0'
    document.getElementById('incorrectResponseButton').style.color = 'gray'
    document.getElementById('incorrectResponseButton').style.borderColor = '#e4e9f0'
    document.getElementById('noResponseButton').style.color = 'gray'
    document.getElementById('noResponseButton').style.borderColor = '#e4e9f0'
    document.getElementById('promptResponseButton').style.color = 'gray'
    document.getElementById('promptResponseButton').style.borderColor = '#e4e9f0'
  } else {
    console.log('Buttons does not not exits')
  }
}

function resetCodedButtons() {
  console.log('peak reset buttons')
  const element = document.getElementById('peakResponseButtonZero')

  // If it isn't "undefined" and it isn't "null", then it exists.
  if (typeof element !== 'undefined' && element !== null) {
    document.getElementById('peakResponseButtonZero').style.color = 'gray'
    document.getElementById('peakResponseButtonZero').style.borderColor = '#e4e9f0'
    document.getElementById('peakResponseButtonTwo').style.color = 'gray'
    document.getElementById('peakResponseButtonTwo').style.borderColor = '#e4e9f0'
    document.getElementById('peakResponseButtonFour').style.color = 'gray'
    document.getElementById('peakResponseButtonFour').style.borderColor = '#e4e9f0'
    document.getElementById('peakResponseButtonEight').style.color = 'gray'
    document.getElementById('peakResponseButtonEight').style.borderColor = '#e4e9f0'
    document.getElementById('peakResponseButtonTen').style.color = 'gray'
    document.getElementById('peakResponseButtonTen').style.borderColor = '#e4e9f0'
  } else {
    console.log('Buttons does not not exits')
  }
}

function resetStartTime() {
  document.getElementById('updateStartTrialTimeInStore').click()
}

function updateSessionClockTime() {
  // updatig current clock time to store
  document.getElementById('updateSessionCurrentTimeInStore').click()
}

function resetPeakButtons() {
  const getButton0 = document.getElementById('peakResponseButtonZero')
  const getButton2 = document.getElementById('peakResponseButtonTwo')
  const getButton4 = document.getElementById('peakResponseButtonFour')
  const getButton8 = document.getElementById('peakResponseButtonEight')
  const getButton10 = document.getElementById('peakResponseButtonTen')

  if (typeof getButton0 !== 'undefined' && getButton0 !== null) {
    document.getElementById('peakResponseButtonZero').style.backgroundColor = '#e4e9f0'
  }
  if (typeof getButton2 !== 'undefined' && getButton2 !== null) {
    document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#e4e9f0'
  }
  if (typeof getButton4 !== 'undefined' && getButton4 !== null) {
    document.getElementById('peakResponseButtonFour').style.backgroundColor = '#e4e9f0'
  }
  if (typeof getButton8 !== 'undefined' && getButton8 !== null) {
    document.getElementById('peakResponseButtonEight').style.backgroundColor = '#e4e9f0'
  }
  if (typeof getButton10 !== 'undefined' && getButton10 !== null) {
    document.getElementById('peakResponseButtonTen').style.backgroundColor = '#e4e9f0'
  }
}

function getMaxCount(masterSession, targetIndex) {
  let count = 1
  count = masterSession.targets.edges[targetIndex].node.targetAllcatedDetails.DailyTrials
  return count
}

export function* MOVE_TO_NEXT_TARGET() {
  const MasterSession = yield select(state => state.sessionrecording.MasterSession)
  const TargetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  const TargetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)

  // document.getElementById(MasterSession.targets.edges[TargetActiveIndex + 1].node.id).click()
  // document.getElementsByClassName('targetElements')[TargetActiveIndex + 1].style.border =
  //   '2px solid #bae7ff'
  // document.getElementsByClassName('targetElements')[TargetActiveIndex].style.border = 'none'

  // updating start trial time
  resetStartTime()
  // updatig current clock time to store
  updateSessionClockTime()
  resetCorrectIncorrectButtons()
  // updating previous target end time
  yield put({
    type: 'sessionrecording/TARGET_UPDATE',
    payload: {
      targetId: TargetActiveId,
    },
  })

  // Updating target index and target id to store
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetActiveIndex: TargetActiveIndex + 1,
      TargetActiveId: MasterSession.targets.edges[TargetActiveIndex + 1].node.id,
      PeakTrialCount: 1,
      PeakBlockIndex: 0,
      StepActiveIndex: 0,
      StimulusActiveIndex: 0,
      EquiTrialCount: 1,
      ResponseLoading: false,
    },
  })

  // reset peak button style
  resetPeakButtons()

  // creating new target skills model instance
  yield put({
    type: 'sessionrecording/CREATE_NEW_TARGET_INSTANCE',
    payload: {
      targetId: MasterSession.targets.edges[TargetActiveIndex + 1].node.id,
      targetIndex: TargetActiveIndex + 1,
    },
  })

  // load video
  // this.getVideoUrl(TargetActiveIndex + 1)
}

export function* UpdateDuration() {
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const sessionDuration = yield select(state => state.sessionrecording.CurrentSessionTime)
  // updating session duration
  yield call(updateChildSessionDuration, { id: childSessionId, duration: sessionDuration })
}

export function* GET_DATA({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      loading: true,
      InitiaModel: true,
      MasterSession: null,
      ChildSession: null,
      TargetResponse: {},
      RecordingType: 'Target',
      SessionStatus: 'Pending',
      StepActiveIndex: 0,
      StepActiveId: '',
      StimulusActiveIndex: 0,
      StimulusActiveId: '',
      TargetActiveIndex: 0,
      TargetActiveId: '',
      Count: 1,
      CorrectCount: 0,
      IncorrectCount: 0,
      FetchForSingleTarget: !!payload.targetId,
      TrialStartTime: 0,
      Disabled: true,
      CurrentSessionTime: 0,
      Frequency: 0,
      TotalDuration: 0,
      TotalBehDuration: 0,
      SBTStepActiveIndex: 0,
      SBTStepActiveId: '',
    },
  })

  const response = yield call(getTargets, payload)
  let videoAvailable = false
  let videoUrl = ''

  if (response && response.data) {
    const targetResponse = {}
    let targetId = ''
    let stimulusId = ''
    let stepId = ''
    let sbtStepId = ''
    let i = 0
    let modifiedSession = null

    if (response.data.getsession.targets.edgeCount > 0) {
      modifiedSession = {
        ...response.data.getsession,
        targets: { ...response.data.getsession.targets, edges: [] },
      }
      for (let z = 0; z < response.data.getsession.targets.edges.length; z++) {
        const nodeItem = response.data.getsession.targets.edges[z]
        if (
          nodeItem.node.activeSdStepCount > 0 &&
          nodeItem.node.sd.edges.length > nodeItem.node.activeSdStepCount
        ) {
          const newMasteryList = []
          const newSdList = []
          const removedMasteryList = []
          const removedSdList = []

          nodeItem.node.mastery.edges.map((nodeMastery, masteryIndex) => {
            if (nodeMastery.node.status.id !== masteredStatusId) {
              newMasteryList.push(nodeMastery)
              const sdObject = nodeItem.node.sd.edges.find(
                x => x.node.id === nodeMastery.node.sd.id,
              )
              if (sdObject) newSdList.push(sdObject)
            } else {
              removedMasteryList.push(nodeMastery)
              const sdObject = nodeItem.node.sd.edges.find(
                x => x.node.id === nodeMastery.node.sd.id,
              )
              if (sdObject) removedSdList.push(sdObject)
            }
          })

          if (newSdList.length > nodeItem.node.activeSdStepCount) {
            modifiedSession.targets.edges[z] = {
              ...nodeItem,
              node: {
                ...nodeItem.node,
                sd: { edges: newSdList.slice(0, nodeItem.node.activeSdStepCount) },
                mastery: { edges: newMasteryList.slice(0, nodeItem.node.activeSdStepCount) },
              },
            }
          } else if (newSdList.length === nodeItem.node.activeSdStepCount) {
            modifiedSession.targets.edges[z] = {
              ...nodeItem,
              node: {
                ...nodeItem.node,
                sd: { edges: newSdList },
                mastery: { edges: newMasteryList },
              },
            }
          } else {
            const difference = nodeItem.node.activeSdStepCount - newSdList.length
            if (difference > 0) {
              modifiedSession.targets.edges[z] = {
                ...nodeItem,
                node: {
                  ...nodeItem.node,
                  sd: { edges: [...newSdList, ...removedSdList.reverse().slice(0, difference)] },
                  mastery: {
                    edges: [
                      ...newMasteryList,
                      ...removedMasteryList.reverse().slice(0, difference),
                    ],
                  },
                },
              }
            }
          }
        } else if (
          nodeItem.node.activeSdStepCount > 0 &&
          nodeItem.node.steps.edges.length > nodeItem.node.activeSdStepCount
        ) {
          const newMasteryList = []
          const newStepList = []
          const removedMasteryList = []
          const removedStepList = []

          nodeItem.node.mastery.edges.map(nodeMastery => {
            if (nodeMastery.node.status.id !== masteredStatusId) {
              newMasteryList.push(nodeMastery)
              const stepObject = nodeItem.node.steps.edges.find(
                x => x.node.id === nodeMastery.node.step?.id,
              )
              if (stepObject) newStepList.push(stepObject)
            } else {
              removedMasteryList.push(nodeMastery)
              const stepObject = nodeItem.node.steps.edges.find(
                x => x.node.id === nodeMastery.node.step?.id,
              )
              if (stepObject) removedStepList.push(stepObject)
            }
          })

          if (newStepList.length > nodeItem.node.activeSdStepCount) {
            modifiedSession.targets.edges[z] = {
              ...nodeItem,
              node: {
                ...nodeItem.node,
                steps: { edges: newStepList.slice(0, nodeItem.node.activeSdStepCount) },
                mastery: { edges: newMasteryList.slice(0, nodeItem.node.activeSdStepCount) },
              },
            }
          } else if (newStepList.length === nodeItem.node.activeSdStepCount) {
            modifiedSession.targets.edges[z] = {
              ...nodeItem,
              node: {
                ...nodeItem.node,
                steps: { edges: newStepList },
                mastery: { edges: newMasteryList },
              },
            }
          } else {
            const difference = nodeItem.node.activeSdStepCount - newStepList.length
            if (difference > 0) {
              modifiedSession.targets.edges[z] = {
                ...nodeItem,
                node: {
                  ...nodeItem.node,
                  steps: {
                    edges: [...newStepList, ...removedStepList.reverse().slice(0, difference)],
                  },
                  mastery: {
                    edges: [
                      ...newMasteryList,
                      ...removedMasteryList.reverse().slice(0, difference),
                    ],
                  },
                },
              }
            }
          }
        } else {
          modifiedSession.targets.edges[z] = nodeItem
        }

        // console.log('modifiedSession ==> ', modifiedSession)
      }
    }

    if (response.data.getsession.targets.edgeCount > 0 && modifiedSession) {
      // comment this block if you don't want to perform session according to status
      // for session according to status target start
      if (!payload.targetId) {
        if (response.data.getsessionTargetsToExclude.length > 0) {
          const newEdgeCount =
            modifiedSession.targets.edgeCount - response.data.getsessionTargetsToExclude.length
          modifiedSession.targets.edgeCount = newEdgeCount
          for (let r = 0; r < response.data.getsessionTargetsToExclude.length; r += 1) {
            modifiedSession.targets.edges.splice(
              modifiedSession.targets.edges.findIndex(
                e => e.node.id === response.data.getsessionTargetsToExclude[r],
              ),
              1,
            )
          }
        }
        // if (response.data.getsessionTargetsToExclude.length > 0) {
        //   const newEdgeCount =
        //     response.data.getsession.targets.edgeCount -
        //     response.data.getsessionTargetsToExclude.length
        //   response.data.getsession.targets.edgeCount = newEdgeCount
        //   for (let r = 0; r < response.data.getsessionTargetsToExclude.length; r += 1) {
        //     response.data.getsession.targets.edges.splice(
        //       response.data.getsession.targets.edges.findIndex(
        //         e => e.node.id === response.data.getsessionTargetsToExclude[r],
        //       ),
        //       1,
        //     )
        //   }
        // }
      }
      // for session according to status target end
      // const targets = response.data.getsession.targets
      const targets = modifiedSession.targets

      targetId = targets.edges[0].node.id

      // Load first video url
      if (targets.edges[0].node.videos.edges.length > 0) {
        if (debug) {
          console.log('====> video exists')
        }
        videoUrl = getVideoUrl(targets.edges[0].node.videos.edges[0].node.url)
        videoAvailable = true
      }
      // End video url

      if (targets.edges[0].node.sd && targets.edges[0].node.sd.edges.length > 0) {
        stimulusId = targets.edges[0].node.sd.edges[0].node.id
      } else if (targets.edges[0].node.steps && targets.edges[0].node.steps.edges.length > 0) {
        stepId = targets.edges[0].node.steps.edges[0].node.id
      } else if (
        targets.edges[0].node.sbtSteps &&
        targets.edges[0].node.sbtSteps.edges.length > 0
      ) {
        sbtStepId = targets.edges[0].node.sbtSteps.edges[0].node.id
      }

      for (i = 0; i < targets.edgeCount; i += 1) {
        targetResponse[targets.edges[i].node.id] = {}
        targetResponse[targets.edges[i].node.id].target = []

        if (targets.edges[i].node.sd && targets.edges[i].node.sd.edges.length > 0) {
          let j = 0
          targetResponse[targets.edges[i].node.id].sd = {}
          for (j = 0; j < targets.edges[i].node.sd.edges.length; j += 1) {
            targetResponse[targets.edges[i].node.id].sd[
              targets.edges[i].node.sd.edges[j].node.id
            ] = []
          }
        }
        if (targets.edges[i].node.steps && targets.edges[i].node.steps.edges.length > 0) {
          let k = 0
          targetResponse[targets.edges[i].node.id].step = {}
          for (k = 0; k < targets.edges[i].node.steps.edges.length; k += 1) {
            targetResponse[targets.edges[i].node.id].step[
              targets.edges[i].node.steps.edges[k].node.id
            ] = []
          }
        }
        if (targets.edges[i].node.sbtSteps && targets.edges[i].node.sbtSteps.edges.length > 0) {
          let k = 0
          targetResponse[targets.edges[i].node.id].sbtStep = {}
          for (k = 0; k < targets.edges[i].node.sbtSteps.edges.length; k += 1) {
            targetResponse[targets.edges[i].node.id].sbtStep[
              targets.edges[i].node.sbtSteps.edges[k].node.id
            ] = []
          }
        }

        // for target type behavior recording
        if (targets.edges[i].node.targetAllcatedDetails.targetType.id === behaviorRecordingId) {
          targetResponse[targets.edges[i].node.id].behaviorRecording = []
          targetResponse[targets.edges[i].node.id].behaviorRecordingTime = 0
        }

        // start of peak
        if (targets.edges[i].node.targetAllcatedDetails.targetType.id === peakId) {
          if (debug) console.log('found peak')

          if (targets.edges[i].node.peakType === equivalence) {
            targetResponse[targets.edges[i].node.id].equivalence = { train: {}, test: {} }

            const combinationResponse = yield call(getCombinationsByCode, {
              code: targets.edges[i].node.eqCode ? targets.edges[i].node.eqCode : '1A',
            })

            if (combinationResponse && combinationResponse.data) {
              if (debug) console.log('cobinationResponse', combinationResponse)
              const combTrain = combinationResponse.data.getPeakEquCodes.edges[0]?.node.train
              const combTest = combinationResponse.data.getPeakEquCodes.edges[0]?.node.test
              if (combTrain) {
                if (debug) console.log('cobinationResponse ==> Train')
                for (let o = 0; o < combTrain.edges.length; o += 1) {
                  targetResponse[targets.edges[i].node.id].equivalence.train[
                    combTrain.edges[o].node.id
                  ] = {}

                  for (let p = 0; p < targets.edges[i].node.classes.edges.length; p += 1) {
                    targetResponse[targets.edges[i].node.id].equivalence.train[
                      combTrain.edges[o].node.id
                    ][targets.edges[i].node.classes.edges[p].node.id] = []
                  }
                }
              }

              if (combTest) {
                if (debug) console.log('cobinationResponse ==> Test')
                for (let o = 0; o < combTest.edges.length; o += 1) {
                  targetResponse[targets.edges[i].node.id].equivalence.test[
                    combTest.edges[o].node.id
                  ] = {}

                  for (let p = 0; p < targets.edges[i].node.classes.edges.length; p += 1) {
                    targetResponse[targets.edges[i].node.id].equivalence.test[
                      combTest.edges[o].node.id
                    ][targets.edges[i].node.classes.edges[p].node.id] = []
                  }
                }
              }
            }
          } else if (targets.edges[i].node.sd) {
            targetResponse[targets.edges[i].node.id].peak = {}
            const peakBlocksCount = targets.edges[i].node.peakBlocks
            for (let j = 0; j < peakBlocksCount; j += 1) {
              targetResponse[targets.edges[i].node.id].peak[j] = {
                block: createStimulusList(targets.edges[i].node.sd.edges),
                blockId: null,
                blockStart: null,
                blockEnd: null,
              }
            }
          }
          // targetResponse[targets.edges[i].node.id]['peak'] = createStimulusList(targets.edges[i].node.sd.edges)
        }
        // end of peak
      }
    }

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        // MasterSession: response.data.getsession,
        MasterSession: modifiedSession,
        PromptCodesList: response.data.promptCodes,
        TargetResponse: targetResponse,
        TargetActiveId: targetId,
        StimulusActiveId: stimulusId,
        StepActiveId: stepId,
        SBTStepActiveId: sbtStepId,
        VideoAvailable: videoAvailable,
        VideoUrl: videoUrl,
      },
    })

    let sessionEditAble = true
    const { NewVersion, DefaultChildSession } = yield select(state => state.sessionrecording)

    // if child session exist
    if (
      response.data.getChildSession &&
      response.data.getChildSession.edges.length > 0 &&
      !NewVersion
    ) {
      let childSession = response.data.getChildSession.edges[0].node
      if (DefaultChildSession) {
        childSession = response.data.getChildSession.edges.filter(
          item => item.node.id === DefaultChildSession.id,
        )[0]?.node
      }

      let sessionStatus = 'Paused'

      if (childSession.status === 'COMPLETED') {
        sessionStatus = 'Completed'
        sessionEditAble = false
      }
      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          ChildSession: childSession,
          SessionStatus: sessionStatus,
          EditAfterSessionCompleted: sessionEditAble,
          Disabled: sessionEditAble,
          InitiaModel: sessionEditAble,
          TrialStartTime: childSession.duration ? childSession.duration : 0,
        },
      })

      if (debug) console.log('====> now checking child session recording')

      // if data recording present
      const childResponse = yield call(getChildSessionData, {
        id: childSession.id,
        date: childSession.sessionDate,
        targetId: payload.targetId,
      })

      if (childResponse && childResponse.data) {
        if (debug) console.log('====> found child session recording', childResponse)
        // updating targets response in store
        childResponse.data.getSessionRecordings.edges.map(item => {
          targetResponse[item.node.targets.id] = {
            ...targetResponse[item.node.targets.id],
            skillsId: item.node.id,
            startTime: item.node.durationStart,
            endTime: item.node.durationEnd,
            targetNote: item.node.note,
          }

          // check for behavior reduction recording
          if (item.node.isBehReduction) {
            if (debug) console.log('====> check for behavior reduction recording', item)
            // write logic for sbt recording updation on local object
            if (item.node.behReduction.edges.length > 0) {
              item.node.behReduction.edges.map(recordingItem => {
                if (
                  targetResponse[item.node.targets.id].sbtStep &&
                  targetResponse[item.node.targets.id].sbtStep[recordingItem.node.sbtStep.id]
                ) {
                  targetResponse[item.node.targets.id].sbtStep[recordingItem.node.sbtStep.id] = [
                    ...targetResponse[item.node.targets.id].sbtStep[recordingItem.node.sbtStep.id],
                    recordingItem.node,
                  ]
                }
              })
            }
          }

          // check for behavior recording type recordings
          if (item.node.isBehRecording) {
            if (debug) console.log('====> check for behavior recording', item)
            targetResponse[item.node.targets.id].behaviorRecordingTime =
              item.node.totalBehRecordingDuration
            item.node.behaviourRecording.edges.map(recordingItem => {
              if (targetResponse[item.node.targets.id].behaviorRecording) {
                targetResponse[item.node.targets.id].behaviorRecording = [
                  ...targetResponse[item.node.targets.id].behaviorRecording,
                  recordingItem.node,
                ]
              }
            })
          }

          // check for equivalence recording
          if (item.node.isPeakEquivalance) {
            if (debug) console.log('====> check for equivalence recording', item)
            item.node.peakEquivalance.edges.map((equiNode, index) => {
              if (equiNode.node.recType === 'TRAIN') {
                // console.log(targetResponse[item.node.targets.id].equivalence.train[equiNode.node.relationTrain.id], equiNode.node.relationTrain.id, equiNode.node.codeClass.id)
                targetResponse[item.node.targets.id].equivalence.train[
                  equiNode.node.relationTrain.id
                ][equiNode.node.codeClass.id] = [
                  ...targetResponse[item.node.targets.id].equivalence.train[
                    equiNode.node.relationTrain.id
                  ][equiNode.node.codeClass.id],
                  equiNode.node,
                ]
              }

              if (equiNode.node.recType === 'TEST') {
                targetResponse[item.node.targets.id].equivalence.test[
                  equiNode.node.relationTest.id
                ][equiNode.node.codeClass.id] = [
                  ...targetResponse[item.node.targets.id].equivalence.test[
                    equiNode.node.relationTest.id
                  ][equiNode.node.codeClass.id],
                  equiNode.node,
                ]
              }
            })
          }

          // check for peak recording
          if (
            item.node.targets?.targetAllcatedDetails?.targetType.id === peakId &&
            item.node.targets.peakType !== equivalence &&
            item.node.peak.edges.length > 0
          ) {
            if (debug) console.log('====> check for peak recording', item)
            item.node.peak.edges.map((peakBlock, index) => {
              if (targetResponse[item.node.targets.id].peak[index]) {
                targetResponse[item.node.targets.id].peak[index] = {
                  ...targetResponse[item.node.targets.id].peak[index],
                  blockId: peakBlock.node.id,
                  blockStart: peakBlock.node.durationStart,
                  blockEnd: peakBlock.node.durationEnd,
                }

                if (peakBlock.node.trial.edges.length > 0) {
                  peakBlock.node.trial.edges.map((trialObject, trialIndex) => {
                    targetResponse[item.node.targets.id].peak[index].block[trialIndex] = {
                      ...targetResponse[item.node.targets.id].peak[index].block[trialIndex],
                      recordedData: true,
                      response: trialObject.node,
                      marksRecorded: trialObject.node.marks !== null ? true : false,
                    }
                  })
                }
              }
            })
          }
          // end check for peak recording

          // checking for target Correct / Incorrect data recording
          if (item.node.sessionRecord.edges.length > 0) {
            if (debug) console.log('====> check for Correct recording', item)
            item.node.sessionRecord.edges.map(recordingItem => {
              if (debug)
                console.log('====> check for Correct recording recordingItem', recordingItem)
              if (recordingItem.node.sd) {
                if (
                  targetResponse[item.node.targets.id].sd &&
                  targetResponse[item.node.targets.id].sd[recordingItem.node.sd.id]
                ) {
                  targetResponse[item.node.targets.id].sd[recordingItem.node.sd.id] = [
                    ...targetResponse[item.node.targets.id].sd[recordingItem.node.sd.id],
                    recordingItem.node,
                  ]
                }
              } else if (recordingItem.node.step) {
                if (
                  targetResponse[item.node.targets.id].step &&
                  targetResponse[item.node.targets.id].step[recordingItem.node.step.id]
                ) {
                  targetResponse[item.node.targets.id].step[recordingItem.node.step.id] = [
                    ...targetResponse[item.node.targets.id].step[recordingItem.node.step.id],
                    recordingItem.node,
                  ]
                }
              } else {
                if (targetResponse[item.node.targets.id].target) {
                  targetResponse[item.node.targets.id].target = [
                    ...targetResponse[item.node.targets.id].target,
                    recordingItem.node,
                  ]
                }
              }
            })
          }
          // end of correct / incorrect data recording
        })

        // Updating other info according to recordings like Count, IncorrectCount, CorrectCount, TargetActiveIndex, TargetActiveId

        let currentCount = 1
        let currentCorrectCount = 0
        let currentIncorrectCount = 0
        let targetIndex = 0
        // let targetId = ''
        let stimulusIndex = 0
        // let stimulusId = ''
        let stepIndex = 0
        // let stepId = ''
        let sbtStepIndex = 0

        let peakBlockIndex = 0
        let blockTrial = 1
        let totalClockTime = 0
        let totalBehTime = 0
        let frequencyCount = 0

        const edgeLength = childResponse.data.getSessionRecordings.edges.length
        if (edgeLength > 0) {
          if (debug) console.log('====> inside last object')
          const lastObject = childResponse.data.getSessionRecordings.edges[edgeLength - 1].node
          // Updating TargetActiveIndex, TargetActiveId
          if (modifiedSession.targets.edgeCount > 0) {
            // for peak block index and trial count
            if (lastObject.peak.edges.length > 0) {
              peakBlockIndex = lastObject.peak.edges.length - 1
              const lastEdge = lastObject.peak.edges[peakBlockIndex]
              // blockTrial = lastEdge.node.trial.edges.length + 1

              lastEdge.node.trial.edges.map(peakTrialNode => {
                if (peakTrialNode.node.marks) {
                  if (blockTrial < 10) blockTrial += 1
                }
              })
            }
            // end of peak block and trial

            modifiedSession.targets.edges.map((item, index) => {
              if (item.node.id === lastObject.targets.id) {
                targetIndex = index
                targetId = lastObject.targets.id

                if (item.node.sd && item.node.sd.edges.length > 0) {
                  stimulusId = item.node.sd.edges[0].node.id
                } else {
                  stimulusId = ''
                }
                if (item.node.steps && item.node.steps.edges.length > 0) {
                  stepId = item.node.steps.edges[0].node.id
                } else {
                  stepId = ''
                }
                if (item.node.sbtSteps && item.node.sbtSteps.edges.length > 0) {
                  sbtStepId = item.node.sbtSteps.edges[0].node.id
                } else {
                  sbtStepId = ''
                }

                // Load last recorded target video url
                if (modifiedSession.targets.edges[targetIndex].node.videos.edges.length > 0) {
                  if (debug) {
                    console.log('====> video exists')
                  }
                  videoUrl = getVideoUrl(
                    modifiedSession.targets.edges[targetIndex].node.videos.edges[0].node.url,
                  )
                  videoAvailable = true
                }
                // End video url

                const lastObjectEdgeCount = lastObject.sessionRecord.edges.length
                // updating stimulus or step index and id to store
                if (lastObjectEdgeCount > 0) {
                  const lastObjectLastEntry =
                    lastObject.sessionRecord.edges[lastObjectEdgeCount - 1].node
                  if (lastObjectLastEntry.sd) {
                    stimulusId = lastObjectLastEntry.sd.id
                    item.node.sd.edges.map((sdItem, sdIndex) => {
                      if (sdItem.node.id === stimulusId) {
                        stimulusIndex = sdIndex
                      }
                    })
                  } else if (lastObjectLastEntry.step) {
                    stepId = lastObjectLastEntry.step.id
                    item.node.steps.edges.map((stepsItem, stepsIndex) => {
                      if (stepsItem.node.id === stepId) {
                        stepIndex = stepsIndex
                      }
                    })
                  }
                }

                if (lastObject.isBehReduction) {
                  const lastBReductionObjectEdgeCount = lastObject.behReduction.edges.length
                  // updating stimulus or step index and id to store
                  if (lastBReductionObjectEdgeCount > 0) {
                    const lastObjectLastEntry =
                      lastObject.behReduction.edges[lastBReductionObjectEdgeCount - 1].node
                    if (lastObjectLastEntry.sbtStep) {
                      sbtStepId = lastObjectLastEntry.sbtStep.id
                      item.node.sbtSteps.edges.map((stepsItem, stepsIndex) => {
                        if (stepsItem.node.id === sbtStepId) {
                          sbtStepIndex = stepsIndex
                        }
                      })
                    }
                  }
                }
              }
            })
          }

          // for behavior recording frequency, clock time, total behavior count
          if (targetResponse[targetId].behaviorRecording?.length > 0) {
            totalClockTime = targetResponse[targetId].behaviorRecordingTime
            targetResponse[targetId].behaviorRecording.map(recordingItem => {
              if (recordingItem.frequency > frequencyCount) frequencyCount = recordingItem.frequency
              totalBehTime += recordingItem.end - recordingItem.start
            })
          }
          // end of behavior recording frequency, clock time, total behavior count

          // updating Count, CorrectCount & IncorrectCount
          if (!(sbtStepId === '')) {
            if (debug) console.log('SBT step Recording exits ==> recorded trials ccount => ')
            if (targetResponse[targetId].sbtStep[sbtStepId].length > 0) {
              // currentCount = targetResponse[targetId].step[stepId].length + 1
              const recordedTrials = targetResponse[targetId].sbtStep[sbtStepId].length
              if (debug)
                console.log(
                  'SBT step Recording exits ==> recorded trials ccount => ',
                  recordedTrials,
                )
              const dT =
                modifiedSession.targets.edges[targetIndex].node.targetAllcatedDetails.DailyTrials
              if (recordedTrials >= dT) currentCount = recordedTrials
              else currentCount = recordedTrials + 1
            }
          } else if (stimulusId !== '') {
            if (
              targetResponse[targetId].sd[stimulusId] &&
              targetResponse[targetId].sd[stimulusId].length > 0
            ) {
              // check target daily trials here and compare with trials recorded and if recorded trials equal to daily
              // currentCount = targetResponse[targetId].sd[stimulusId].length + 1
              const recordedTrials = targetResponse[targetId].sd[stimulusId].length
              const dT =
                modifiedSession.targets.edges[targetIndex].node.targetAllcatedDetails.DailyTrials
              if (recordedTrials >= dT) currentCount = recordedTrials
              else currentCount = recordedTrials + 1

              targetResponse[targetId].sd[stimulusId].map(recordingItem => {
                if (recordingItem.trial === 'CORRECT') {
                  currentCorrectCount += 1
                }
                if (
                  recordingItem.trial === 'ERROR' ||
                  recordingItem.trial === 'PROMPT' ||
                  recordingItem.trial === 'INCORRECT'
                ) {
                  currentIncorrectCount += 1
                }
              })
            }
          } else if (!(stepId === '')) {
            if (
              targetResponse[targetId].step[stepId] &&
              targetResponse[targetId].step[stepId].length > 0
            ) {
              // currentCount = targetResponse[targetId].step[stepId].length + 1
              const recordedTrials = targetResponse[targetId].step[stepId].length
              const dT =
                modifiedSession.targets.edges[targetIndex].node.targetAllcatedDetails.DailyTrials
              if (recordedTrials >= dT) currentCount = recordedTrials
              else currentCount = recordedTrials + 1
              // modifiedSession.targets.edges[targetIndex].node
              targetResponse[targetId].step[stepId].map(recordingItem => {
                if (recordingItem.trial === 'CORRECT') {
                  currentCorrectCount += 1
                }
                if (
                  recordingItem.trial === 'ERROR' ||
                  recordingItem.trial === 'PROMPT' ||
                  recordingItem.trial === 'INCORRECT'
                ) {
                  currentIncorrectCount += 1
                }
              })
            }
          } else if (targetResponse[targetId].target.length > 0) {
            // currentCount = targetResponse[targetId].target.length + 1
            const recordedTrials = targetResponse[targetId].target.length
            const dT =
              modifiedSession.targets.edges[targetIndex].node.targetAllcatedDetails.DailyTrials
            if (recordedTrials >= dT) currentCount = recordedTrials
            else currentCount = recordedTrials + 1
            targetResponse[targetId].target.map(recordingItem => {
              if (recordingItem.trial === 'CORRECT') {
                currentCorrectCount += 1
              }
              if (
                recordingItem.trial === 'ERROR' ||
                recordingItem.trial === 'PROMPT' ||
                recordingItem.trial === 'INCORRECT'
              ) {
                currentIncorrectCount += 1
              }
            })
          }

          // if(lastObject.sessionRecord.edges.length > 0){
          //     currentCount = lastObject.sessionRecord.edges.length + 1
          //     lastObject.sessionRecord.edges.map(recordingItem => {
          //         if (recordingItem.node.trial === 'CORRECT'){
          //             currentCorrectCount += 1
          //         }
          //         if (recordingItem.node.trial === 'ERROR'){
          //             currentIncorrectCount += 1
          //         }
          //         if (recordingItem.node.trial === 'PROMPT'){
          //             currentIncorrectCount += 1
          //         }
          //     })
          // }
        }

        yield put({
          type: 'sessionrecording/SET_STATE',
          payload: {
            TargetResponse: targetResponse,
            Count: currentCount,
            CorrectCount: currentCorrectCount,
            IncorrectCount: currentIncorrectCount,
            TargetActiveIndex: targetIndex,
            TargetActiveId: targetId,
            StepActiveIndex: stepIndex,
            StepActiveId: stepId,
            StimulusActiveIndex: stimulusIndex,
            StimulusActiveId: stimulusId,
            VideoAvailable: videoAvailable,
            VideoUrl: videoUrl,
            PeakTrialCount: blockTrial,
            // PeakBlockIndex: peakBlockIndex,
            // PeakTrialCount: 1,
            PeakBlockIndex: 0,
            Frequency: frequencyCount,
            TotalDuration: totalClockTime,
            TotalBehDuration: totalBehTime,
            SBTStepActiveId: sbtStepId,
            SBTStepActiveIndex: sbtStepIndex,
          },
        })
      }
    }
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// not in use
export function* GET_CHILD_SESSION_DATA({ payload }) {
  const response = yield call(getChildSessionData, payload)
  if (response && response.data) {
    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        CorrectCount: 0,
      },
    })
  }
}

export function* START_SESSION({ payload }) {
  const currentTime = moment().format('YYYY-MM-DDTHH:mm:ss.sssZ')
  const masterSessionId = yield select(state => state.sessionrecording.MasterSession.id)
  const sDate = yield select(state => state.sessionrecording.SessionDate)
  const peakAutomatic = yield select(state => state.sessionrecording.PeakAutomatic)
  const newVersion = yield select(state => state.sessionrecording.NewVersion)
  const sessionDuration = yield select(state => state.sessionrecording.MasterSession.duration)

  // creating child session object
  const response = yield call(createChildSession, {
    masterSessionId,
    SessionDate: sDate,
    startLat: payload.startLat,
    startLong: payload.startLong,
    currentTime,
    newVersion,
    sessionDuration,
  })

  if (response && response.data && response.data.startSession) {
    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        ChildSession: response.data.startSession.details,
        SessionStatus: 'InProgress',
        ResponseLoading: true,
      },
    })

    // selecting master session
    const masterSession = yield select(state => state.sessionrecording.MasterSession)
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)

    if (masterSession.targets.edgeCount > 0) {
      if (
        masterSession.targets.edges[0].node.targetAllcatedDetails.targetType.id === peakId &&
        peakAutomatic &&
        masterSession.targets.edges[0].node.peakType !== equivalence
      ) {
        const targetId = masterSession.targets.edges[0]?.node.id
        const responseTarget = yield call(createNewTargetPeakAutomaticInstance, {
          childId: response.data.startSession.details.id,
          statusId: masterSession.targets.edges[0].node.targetStatus.id,
          targetId,
          start: 0,
        })
        // updating target instance and skill id to store for future api calls
        if (responseTarget && responseTarget.data) {
          targetResponse[targetId] = {
            ...targetResponse[targetId],
            skillsId: responseTarget.data.peakBlockAutomatic.skill.id,
            startTime: responseTarget.data.peakBlockAutomatic.skill.durationStart,
            endTime: responseTarget.data.peakBlockAutomatic.skill.durationEnd,
          }

          if (responseTarget.data.peakBlockAutomatic.skill.peak.edges.length > 0) {
            // updating automatic created peak blocks id to store
            const blockCount = masterSession.targets.edges[targetActiveIndex].node.peakBlocks
            responseTarget.data.peakBlockAutomatic.skill.peak.edges.forEach((nodeItem, index) => {
              if (index < blockCount) {
                targetResponse[targetId].peak[index] = {
                  ...targetResponse[targetId].peak[index],
                  blockId: nodeItem.node.id,
                  blockStart: nodeItem.node.durationStart,
                }

                // updating automatic created peak block trials to store
                if (nodeItem.node.trial.edges.length > 0) {
                  nodeItem.node.trial.edges.map((trailNode, trialIndex) => {
                    targetResponse[targetId].peak[index].block[trialIndex] = {
                      ...targetResponse[targetId].peak[index].block[trialIndex],
                      recordedData: true,
                      response: trailNode.node,
                      sd: trailNode.node.sd,
                    }
                  })
                }
              }
            })
          }

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              TargetResponse: targetResponse,
              PeakTrialCount: 1,
              PeakBlockIndex: 0,
              ResponseLoading: false,
            },
          })
        }
      } else {
        // create first target instance in system
        const result = yield call(createFirstTragetAndTrialInstance, {
          childId: response.data.startSession.details.id,
          targetId: masterSession.targets.edges[0].node.id,
          targetStatusId: masterSession.targets.edges[0].node.targetStatus.id,
        })
        // updating response in redux store
        if (result && result.data) {
          targetResponse[masterSession.targets.edges[0].node.id] = {
            ...targetResponse[masterSession.targets.edges[0].node.id],
            skillsId: result.data.sessionRecording.details.id,
            startTime: result.data.sessionRecording.details.durationStart,
            endTime: result.data.sessionRecording.details.durationEnd,
          }

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              TargetResponse: targetResponse,
              ResponseLoading: false,
            },
          })

          // if target type peak then create block Object
          if (
            masterSession.targets.edges[0].node.targetAllcatedDetails.targetType.id === peakId &&
            masterSession.targets.edges[0].node.peakType !== equivalence
          ) {
            const blockResponse = yield call(createTargetBlock, {
              skillsId: result.data.sessionRecording.details.id,
              startTime: result.data.sessionRecording.details.durationStart,
            })
            if (blockResponse && blockResponse.data) {
              // updating created block details on redux store
              targetResponse[masterSession.targets.edges[0].node.id].peak[0] = {
                ...targetResponse[masterSession.targets.edges[0].node.id].peak[0],
                blockId: blockResponse.data.peakBlock.block.id,
                blockStart: blockResponse.data.peakBlock.block.durationStart,
              }

              yield put({
                type: 'sessionrecording/SET_STATE',
                payload: {
                  TargetResponse: targetResponse,
                  PeakTrialCount: 1,
                  PeakBlockIndex: 0,
                  ResponseLoading: false,
                },
              })
            }
          }
          // end of peak block create code
        }
      }
    }
  }
}

export function* PAUSE_SESSION({ payload }) {
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  // updating session duration

  if (targetResponse[targetActiveId].behaviorRecording) {
    const element = document.getElementById('updateBehaviorRecordingTotalTime')
    // If it isn't "undefined" and it isn't "null", then it exists.
    if (typeof element !== 'undefined' && element !== null) {
      element.click()
    } else {
      console.log('Buttons does not not exits')
    }
  }

  const response = yield call(updateChildSessionDuration, {
    id: childSessionId,
    duration: payload.duration,
  })
  if (response && response.data && response.data.changeSessionStatus) {
    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        ChildSession: response.data.changeSessionStatus.details,
        SessionStatus: 'Paused',
        InitiaModel: true,
      },
    })
  }
}

export function* RESUME_SESSION({ payload }) {
  // const response = yield call(getTargets)

  // if (response && response.data) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      SessionStatus: 'InProgress',
    },
  })
  // }
}

export function* END_SESSION({ payload }) {
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  // updating session duration
  const currentTime = moment().format('YYYY-MM-DDTHH:mm:ss.sssZ')

  if (targetResponse[targetActiveId].behaviorRecording) {
    const element = document.getElementById('updateBehaviorRecordingTotalTime')
    // If it isn't "undefined" and it isn't "null", then it exists.
    if (typeof element !== 'undefined' && element !== null) {
      element.click()
    } else {
      console.log('Buttons does not not exits')
    }
  }

  // updating session duration
  const response = yield call(finishChildSession, {
    id: childSessionId,
    duration: payload.duration,
    currentTime,
    endLat: payload.endLat,
    endLong: payload.endLong,
  })

  if (response && response.data && response.data.changeSessionStatus) {
    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        ChildSession: response.data.changeSessionStatus.details,
        SessionStatus: 'Completed',
        END_SESSION: false,
      },
    })
  }
}

export function* OPEN_END_SESSION_MODAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      EndModel: true,
    },
  })
}

export function* TARGET_CORRECT({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  try {
    // selecting child session id for creating child session
    const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
    const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
    const trialStartTime = yield select(state => state.sessionrecording.TrialStartTime)
    const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
    const masterSession = yield select(state => state.sessionrecording.MasterSession)
    const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
    const count = yield select(state => state.sessionrecording.Count)
    // selecting status id of the target
    const sessionStatusId = masterSession.targets.edges[targetActiveIndex].node.targetStatus.id
    // updating session duration
    const response = yield call(recordTargetCorrectTrial, {
      childId: childSessionId,
      statusId: sessionStatusId,
      targetId: targetActiveId,
      start: trialStartTime,
      end: currentSessionTime,
      promptId: payload.promptId,
      response: payload.response,
      note: payload.note,
    })
    if (response && response.data && response.data.sessionRecording) {
      const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
      targetResponse[targetActiveId].target = []
      response.data.sessionRecording.details.sessionRecord.edges.map(item => {
        targetResponse[targetActiveId].target = [
          ...targetResponse[targetActiveId].target,
          item.node,
        ]
      })

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse: targetResponse,
        },
      })

      if (
        count <
        masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.DailyTrials
      ) {
        yield put({
          type: 'sessionrecording/SET_STATE',
          payload: {
            Count: count + 1,
          },
        })
        resetStartTime()
        resetCorrectIncorrectButtons()
      } else if (
        count >=
          masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.DailyTrials &&
        targetActiveIndex + 1 < masterSession.targets.edges.length
      ) {
        yield put({
          type: 'sessionrecording/MOVE_TO_NEXT_TARGET',
        })
      } else {
        yield put({
          type: 'sessionrecording/OPEN_END_SESSION_MODAL',
        })
      }

      // update session duration
      yield put({
        type: 'sessionrecording/UPDATE_DURATION',
      })
    }
  } catch (error) {
    console.log('error', error)
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* TARGET_UPDATE({ payload }) {
  try {
    // selecting child session id for creating child session
    const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    const currentBlockIndex = yield select(state => state.sessionrecording.PeakBlockIndex)

    // getting skillsId for target
    const skillsId = targetResponse[payload.targetId].skillsId
    const previousTargetTime = targetResponse[payload.targetId].endTime

    // for behavior recording total time update
    if (targetResponse[payload.targetId].behaviorRecording) {
      const element = document.getElementById('updateBehaviorRecordingTotalTime')
      // If it isn't "undefined" and it isn't "null", then it exists.
      if (typeof element !== 'undefined' && element !== null) {
        element.click()
      } else {
        console.log('Buttons does not not exits')
      }
    }
    // end of behavior recording total time update

    if (previousTargetTime === 0 || previousTargetTime === null) {
      const response = yield call(updateTargetEndTime, {
        skillsId,
        endTime: currentSessionTime,
      })
      if (response && response.data) {
        // updating target end time in redux store
        targetResponse[payload.targetId].endTime = response.data.updateTargetRec.details.durationEnd

        yield put({
          type: 'sessionrecording/SET_STATE',
          payload: {
            TargetResponse: targetResponse,
          },
        })
      }

      // checking and updating peak block end time
      if (targetResponse[payload.targetId].peak) {
        const blockId = targetResponse[payload.targetId].peak[currentBlockIndex].blockId
        const previousBlockTime = targetResponse[payload.targetId].peak[currentBlockIndex].blockEnd
        if (previousBlockTime === 0) {
          const blockResponse = yield call(updateTargetBlock, {
            skillsId,
            blockId,
            endTime: currentSessionTime,
          })
          if (blockResponse && blockResponse.data) {
            targetResponse[payload.targetId].peak[currentBlockIndex].blockEnd =
              blockResponse.data.peakBlock.block.durationEnd

            yield put({
              type: 'sessionrecording/SET_STATE',
              payload: {
                TargetResponse: targetResponse,
              },
            })
          }
        }
      }
      // end of check
    }
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  } catch (error) {
    console.log('error', error)
  }
}

export function* CREATE_NEW_TARGET_INSTANCE({ payload }) {
  // selecting child session id for creating child session
  const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  const peakAutomatic = yield select(state => state.sessionrecording.PeakAutomatic)
  const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)

  // selecting status id of the target
  const sessionStatusId = masterSession.targets.edges[payload.targetIndex].node.targetStatus.id
  const targetObject = masterSession.targets.edges[payload.targetIndex].node
  let stimulusId = ''
  let stepId = ''
  let sbtStepId = ''

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })

  if (targetObject.sd.edges.length > 0) {
    stimulusId = targetObject.sd.edges[payload.index ? payload.index : 0].node.id
  } else if (targetObject.steps.edges.length > 0) {
    stepId = targetObject.steps.edges[payload.index ? payload.index : 0].node.id
  } else if (targetObject.sbtSteps.edges.length > 0) {
    sbtStepId = targetObject.sbtSteps.edges[payload.index ? payload.index : 0]?.node.id
  }

  // new target class one id
  if (masterSession.targets.edges[payload.targetIndex].node.peakType === equivalence) {
    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        SelectedClassId:
          masterSession.targets.edges[payload.targetIndex].node.classes.edges[0]?.node.id,
      },
    })
  }
  try {
    if (!targetResponse[payload.targetId].skillsId) {
      if (
        masterSession.targets.edges[payload.targetIndex].node.targetAllcatedDetails.targetType
          .id === peakId &&
        peakAutomatic &&
        masterSession.targets.edges[payload.targetIndex].node.peakType !== equivalence
      ) {
        const response = yield call(createNewTargetPeakAutomaticInstance, {
          childId: childSessionId,
          statusId: sessionStatusId,
          targetId: payload.targetId,
          start: currentSessionTime,
        })
        // updating target instance and skill id to store for future api calls
        if (response && response.data) {
          targetResponse[payload.targetId] = {
            ...targetResponse[payload.targetId],
            skillsId: response.data.peakBlockAutomatic.skill.id,
            startTime: response.data.peakBlockAutomatic.skill.durationStart,
            endTime: response.data.peakBlockAutomatic.skill.durationEnd,
          }

          if (response.data.peakBlockAutomatic.skill.peak.edges.length > 0) {
            // updating automatic created peak blocks id to store
            const blockCount = masterSession.targets.edges[targetActiveIndex].node.peakBlocks
            response.data.peakBlockAutomatic.skill.peak.edges.forEach((nodeItem, index) => {
              if (index < blockCount) {
                targetResponse[payload.targetId].peak[index] = {
                  ...targetResponse[payload.targetId].peak[index],
                  blockId: nodeItem.node.id,
                  blockStart: nodeItem.node.durationStart,
                }
                // updating automatic created peak block trials to store
                if (nodeItem.node.trial.edges.length > 0) {
                  nodeItem.node.trial.edges.map((trailNode, trialIndex) => {
                    targetResponse[payload.targetId].peak[index].block[trialIndex] = {
                      ...targetResponse[payload.targetId].peak[index].block[trialIndex],
                      recordedData: true,
                      response: trailNode.node,
                      sd: trailNode.node.sd,
                      marksRecorded: false,
                    }
                  })
                }
              }
            })
          }

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              TargetResponse: targetResponse,
              Count: 1,
              CorrectCount: 0,
              IncorrectCount: 0,
              StepActiveIndex: payload.index ? payload.index : 0,
              StepActiveId: stepId,
              StimulusActiveIndex: payload.index ? payload.index : 0,
              StimulusActiveId: stimulusId,
              Frequency: 0,
              TotalDuration: 0,
              TotalBehDuration: 0,
              SBTStepActiveId: sbtStepId,
              SBTStepActiveIndex: payload.index ? payload.index : 0,
            },
          })
        }
      } else {
        const response = yield call(createNewTargetInstance, {
          childId: childSessionId,
          statusId: sessionStatusId,
          targetId: payload.targetId,
          start: currentSessionTime,
        })
        if (response && response.data) {
          // updating target end time in redux store
          targetResponse[payload.targetId] = {
            ...targetResponse[payload.targetId],
            skillsId: response.data.sessionRecording.details.id,
            startTime: response.data.sessionRecording.details.durationStart,
            endTime: response.data.sessionRecording.details.durationEnd,
          }
          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              TargetResponse: targetResponse,
              Count: 1,
              CorrectCount: 0,
              IncorrectCount: 0,
              StepActiveIndex: payload.index ? payload.index : 0,
              StepActiveId: stepId,
              StimulusActiveIndex: payload.index ? payload.index : 0,
              StimulusActiveId: stimulusId,
              Frequency: 0,
              TotalDuration: 0,
              TotalBehDuration: 0,
              SBTStepActiveIndex: payload.index ? payload.index : 0,
              SBTStepActiveId: sbtStepId,
            },
          })

          // if target type peak then create block Object
          if (
            masterSession.targets.edges[payload.targetIndex].node.targetAllcatedDetails.targetType
              .id === peakId &&
            masterSession.targets.edges[payload.targetIndex].node.peakType !== equivalence
          ) {
            const blockResponse = yield call(createTargetBlock, {
              skillsId: response.data.sessionRecording.details.id,
              startTime: response.data.sessionRecording.details.durationStart,
            })
            if (blockResponse && blockResponse.data) {
              // updating created block details on redux store
              targetResponse[payload.targetId].peak[0] = {
                ...targetResponse[payload.targetId].peak[0],
                blockId: blockResponse.data.peakBlock.block.id,
                blockStart: blockResponse.data.peakBlock.block.durationStart,
              }

              yield put({
                type: 'sessionrecording/SET_STATE',
                payload: {
                  TargetResponse: targetResponse,
                  PeakTrialCount: 1,
                  PeakBlockIndex: 0,
                },
              })
            }
          }
          // end of peak block create code
        }
      }
    } else {
      let currentCount = 1
      let currentCorrectCount = 0
      let currentIncorrectCount = 0

      const peakBlockIndex = 0
      let blockTrial = 1
      // updating Count, CorrectCount & IncorrectCount
      let totalClockTime = 0
      let totalBehTime = 0
      let frequencyCount = 0

      if (
        masterSession.targets.edges[payload.targetIndex].node.targetAllcatedDetails.targetType
          .id === peakId &&
        masterSession.targets.edges[payload.targetIndex].node.peakType !== equivalence
      ) {
        // if peak first block instance not present then create one
        if (!targetResponse[payload.targetId].peak[0].blockId) {
          const blockResponse = yield call(createTargetBlock, {
            skillsId: targetResponse[payload.targetId].skillsId,
            startTime: targetResponse[payload.targetId].startTime,
          })
          if (blockResponse && blockResponse.data) {
            // updating created block details on redux store
            targetResponse[payload.targetId].peak[0] = {
              ...targetResponse[payload.targetId].peak[0],
              blockId: blockResponse.data.peakBlock.block.id,
              blockStart: blockResponse.data.peakBlock.block.durationStart,
            }

            yield put({
              type: 'sessionrecording/SET_STATE',
              payload: {
                TargetResponse: targetResponse,
              },
            })
          }
        }
        // end of check and create first peak block instance

        if (targetResponse[payload.targetId].peak) {
          targetResponse[payload.targetId].peak[0].block.map(item => {
            // if (item.recordedData === true) {
            //   blockTrial += 1
            // }
            if (item.marksRecorded === true) {
              if (blockTrial < 10) blockTrial += 1
            }
          })
        }
      }

      // for behavior recording frequency, clock time, total behavior count
      if (targetResponse[payload.targetId].behaviorRecording) {
        totalClockTime = targetResponse[payload.targetId].behaviorRecordingTime
        targetResponse[payload.targetId].behaviorRecording.map(recordingItem => {
          if (recordingItem.frequency > frequencyCount) frequencyCount = recordingItem.frequency
          totalBehTime += recordingItem.end - recordingItem.start
        })
      }
      // end of behavior recording frequency, clock time, total behavior count

      if (!(sbtStepId === '')) {
        if (debug) console.log('SBT step Recording exits ==> recorded trials ccount => ')
        if (targetResponse[payload.targetId].sbtStep[sbtStepId].length > 0) {
          // currentCount = targetResponse[payload.targetId].step[stepId].length + 1
          const recordedTrials = targetResponse[payload.targetId].sbtStep[sbtStepId].length
          // if (debug) console.log("SBT step Recording exits ==> recorded trials ccount => ", recordedTrials)
          const dT =
            masterSession.targets.edges[payload.targetIndex].node.targetAllcatedDetails.DailyTrials
          if (recordedTrials >= dT) currentCount = recordedTrials
          else currentCount = recordedTrials + 1
        }
      }

      if (targetResponse[payload.targetId].sd) {
        if (
          targetResponse[payload.targetId].sd[
            targetObject.sd.edges[payload.index ? payload.index : 0].node.id
          ].length > 0
        ) {
          // currentCount =
          //   targetResponse[payload.targetId].sd[
          //     targetObject.sd.edges[payload.index ? payload.index : 0].node.id
          //   ].length + 1
          const recordedTrials =
            targetResponse[payload.targetId].sd[
              targetObject.sd.edges[payload.index ? payload.index : 0].node.id
            ].length
          const dT =
            masterSession.targets.edges[payload.targetIndex].node.targetAllcatedDetails.DailyTrials
          if (recordedTrials >= dT) currentCount = recordedTrials
          else currentCount = recordedTrials + 1

          targetResponse[payload.targetId].sd[
            targetObject.sd.edges[payload.index ? payload.index : 0].node.id
          ].map(recordingItem => {
            if (recordingItem.trial === 'CORRECT') {
              currentCorrectCount += 1
            }
            if (
              recordingItem.trial === 'ERROR' ||
              recordingItem.trial === 'PROMPT' ||
              recordingItem.trial === 'INCORRECT'
            ) {
              currentIncorrectCount += 1
            }
          })
        }
      } else if (targetResponse[payload.targetId].step) {
        if (
          targetResponse[payload.targetId].step[
            targetObject.steps.edges[payload.index ? payload.index : 0].node.id
          ].length > 0
        ) {
          // currentCount =
          //   targetResponse[payload.targetId].step[
          //     targetObject.steps.edges[payload.index ? payload.index : 0].node.id
          //   ].length + 1

          const recordedTrials =
            targetResponse[payload.targetId].step[
              targetObject.steps.edges[payload.index ? payload.index : 0].node.id
            ].length
          const dT =
            masterSession.targets.edges[payload.targetIndex].node.targetAllcatedDetails.DailyTrials
          if (recordedTrials >= dT) currentCount = recordedTrials
          else currentCount = recordedTrials + 1

          targetResponse[payload.targetId].step[
            targetObject.steps.edges[payload.index ? payload.index : 0].node.id
          ].map(recordingItem => {
            if (recordingItem.trial === 'CORRECT') {
              currentCorrectCount += 1
            }
            if (
              recordingItem.trial === 'ERROR' ||
              recordingItem.trial === 'PROMPT' ||
              recordingItem.trial === 'INCORRECT'
            ) {
              currentIncorrectCount += 1
            }
          })
        }
      } else if (targetResponse[payload.targetId].target.length > 0) {
        // currentCount = targetResponse[payload.targetId].target.length + 1
        const recordedTrials = targetResponse[payload.targetId].target.length
        const dT =
          masterSession.targets.edges[payload.targetIndex].node.targetAllcatedDetails.DailyTrials
        if (recordedTrials >= dT) currentCount = recordedTrials
        else currentCount = recordedTrials + 1
        targetResponse[payload.targetId].target.map(recordingItem => {
          if (recordingItem.trial === 'CORRECT') {
            currentCorrectCount += 1
          }
          if (
            recordingItem.trial === 'ERROR' ||
            recordingItem.trial === 'PROMPT' ||
            recordingItem.trial === 'INCORRECT'
          ) {
            currentIncorrectCount += 1
          }
        })
      }

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          Count: currentCount,
          CorrectCount: currentCorrectCount,
          IncorrectCount: currentIncorrectCount,
          StepActiveIndex: payload.index ? payload.index : 0,
          StepActiveId: stepId,
          StimulusActiveIndex: payload.index ? payload.index : 0,
          StimulusActiveId: stimulusId,
          PeakTrialCount: blockTrial,
          PeakBlockIndex: peakBlockIndex,
          Frequency: frequencyCount,
          TotalDuration: totalClockTime,
          TotalBehDuration: totalBehTime,
          SBTStepActiveIndex: payload.index ? payload.index : 0,
          SBTStepActiveId: sbtStepId,
        },
      })
    }
  } catch (error) {
    console.log('error', error)
  }
  // update session duration
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
  yield put({
    type: 'sessionrecording/UPDATE_DURATION',
  })
}

export function* UPDATE_TARGET_TRIAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  try {
    // selecting target response object from store
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
    const count = yield select(state => state.sessionrecording.Count)
    // update target trial query

    const response = yield call(updateTargetTrial, {
      object: payload.object,
      response: payload.response,
      promptId: payload.promptId,
      note: payload.note,
    })
    if (response && response.data) {
      // updating target end time in redux store
      if (
        targetResponse[targetActiveId].target[count - 1].id === response.data.updateTrial.details.id
      ) {
        targetResponse[targetActiveId].target[count - 1] = response.data.updateTrial.details
      }

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse: targetResponse,
        },
      })
    }
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  } catch (error) {
    console.log('error', error)
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

// not in use
export function* SET_PREVIOUS_COUNTS({ payload }) {
  // selecting target response object from store
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  // console.log(childSessionId, sessionStatusId, payload.targetId, currentSessionTime)
  const targetObject = masterSession.targets.edges[payload.targetIndex].node

  let stepId = ''
  let stimulusId = ''
  let currentCount = 1
  let currentCorrectCount = 0
  let currentIncorrectCount = 0

  if (targetObject.sd.edges.length > 0) {
    stimulusId = targetObject.sd.edges[0].node.id
  } else if (targetObject.steps.edges.length > 0) {
    stepId = targetObject.steps.edges[0].node.id
  }

  // updating Count, CorrectCount & IncorrectCount

  if (targetResponse[payload.targetId].sd) {
    if (targetResponse[payload.targetId].sd[targetObject.sd.edges[0].node.id].length > 0) {
      currentCount =
        targetResponse[payload.targetId].sd[targetObject.sd.edges[0].node.id].length + 1
      targetResponse[payload.targetId].sd[targetObject.sd.edges[0].node.id].map(recordingItem => {
        if (recordingItem.trial === 'CORRECT') {
          currentCorrectCount += 1
        }
        if (recordingItem.trial === 'ERROR') {
          currentIncorrectCount += 1
        }
        if (recordingItem.trial === 'PROMPT') {
          currentIncorrectCount += 1
        }
      })
    }
  } else if (targetResponse[payload.targetId].step) {
    if (targetResponse[payload.targetId].step[targetObject.steps.edges[0].node.id].length > 0) {
      currentCount =
        targetResponse[payload.targetId].step[targetObject.steps.edges[0].node.id].length + 1
      targetResponse[payload.targetId].step[targetObject.steps.edges[0].node.id].map(
        recordingItem => {
          if (recordingItem.trial === 'CORRECT') {
            currentCorrectCount += 1
          }
          if (recordingItem.trial === 'ERROR') {
            currentIncorrectCount += 1
          }
          if (recordingItem.trial === 'PROMPT') {
            currentIncorrectCount += 1
          }
        },
      )
    }
  } else if (targetResponse[payload.targetId].target.length > 0) {
    currentCount = targetResponse[payload.targetId].target.length + 1
    targetResponse[payload.targetId].target.map(recordingItem => {
      if (recordingItem.trial === 'CORRECT') {
        currentCorrectCount += 1
      }
      if (recordingItem.trial === 'ERROR') {
        currentIncorrectCount += 1
      }
      if (recordingItem.trial === 'PROMPT') {
        currentIncorrectCount += 1
      }
    })
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      Count: currentCount,
      CorrectCount: currentCorrectCount,
      IncorrectCount: currentIncorrectCount,
      StepActiveIndex: 0,
      StepActiveId: stepId,
      StimulusActiveIndex: 0,
      StimulusActiveId: stimulusId,
    },
  })
}

export function* CHANGE_STIMULUS({ payload }) {
  // selecting target response object from store
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const { MasterSession, TargetActiveIndex } = yield select(state => state.sessionrecording)
  const maxCount = getMaxCount(MasterSession, TargetActiveIndex)
  let currentCount = 1
  let currentCorrectCount = 0
  let currentIncorrectCount = 0

  // updating Count, CorrectCount & IncorrectCount

  if (targetResponse[payload.targetId].sd[payload.stimulusId].length > 0) {
    currentCount = targetResponse[payload.targetId].sd[payload.stimulusId].length + 1
    targetResponse[payload.targetId].sd[payload.stimulusId].map(recordingItem => {
      if (recordingItem.trial === 'CORRECT') {
        currentCorrectCount += 1
      }
      if (
        recordingItem.trial === 'ERROR' ||
        recordingItem.trial === 'PROMPT' ||
        recordingItem.trial === 'INCORRECT'
      ) {
        currentIncorrectCount += 1
      }
    })
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      Count: currentCount <= maxCount ? currentCount : maxCount,
      CorrectCount: currentCorrectCount,
      IncorrectCount: currentIncorrectCount,
      StimulusActiveIndex: payload.stimulusIndex,
      StimulusActiveId: payload.stimulusId,
    },
  })
}

export function* TARGET_SD_CORRECT({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  try {
    // selecting child session id for creating child session
    const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
    const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
    const trialStartTime = yield select(state => state.sessionrecording.TrialStartTime)
    const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
    const masterSession = yield select(state => state.sessionrecording.MasterSession)
    const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
    const count = yield select(state => state.sessionrecording.Count)
    const stimulusActiveIndex = yield select(state => state.sessionrecording.StimulusActiveIndex)
    // selecting status id of the target
    const sessionStatusId = masterSession.targets.edges[targetActiveIndex].node.targetStatus.id
    // updating session duration
    const response = yield call(recordTargetStimulusTrial, {
      childId: childSessionId,
      statusId: sessionStatusId,
      targetId: targetActiveId,
      start: trialStartTime,
      end: currentSessionTime,
      promptId: payload.promptId,
      response: payload.response,
      sdId: payload.sdId,
      note: payload.note,
      sdStepStatus: payload.sdStepStatusId,
    })
    if (response && response.data && response.data.sessionRecording) {
      const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
      targetResponse[targetActiveId].sd[payload.sdId] = []
      response.data.sessionRecording.details.sessionRecord.edges.map(item => {
        if (item.node.sd.id === payload.sdId) {
          targetResponse[targetActiveId].sd[payload.sdId] = [
            ...targetResponse[targetActiveId].sd[payload.sdId],
            item.node,
          ]
        }
      })

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse: targetResponse,
        },
      })

      if (
        count <
        masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.DailyTrials
      ) {
        yield put({
          type: 'sessionrecording/SET_STATE',
          payload: {
            Count: count + 1,
          },
        })
        resetStartTime()
        resetCorrectIncorrectButtons()
      } else if (
        count ===
        masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.DailyTrials
      ) {
        if (
          stimulusActiveIndex <
          masterSession.targets.edges[targetActiveIndex].node.sd.edges.length - 1
        ) {
          resetStartTime()
          resetCorrectIncorrectButtons()

          yield put({
            type: 'sessionrecording/CHANGE_STIMULUS',
            payload: {
              stimulusId:
                masterSession.targets.edges[targetActiveIndex].node.sd.edges[
                  stimulusActiveIndex + 1
                ].node.id,
              stimulusIndex: stimulusActiveIndex + 1,
              targetId: targetActiveId,
            },
          })
        } else if (targetActiveIndex + 1 < masterSession.targets.edges.length) {
          yield put({
            type: 'sessionrecording/MOVE_TO_NEXT_TARGET',
          })
        } else {
          yield put({
            type: 'sessionrecording/OPEN_END_SESSION_MODAL',
          })
        }
      }
    }
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  } catch (error) {
    console.log('error', error)
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* UPDATE_TARGET_SD_TRIAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  try {
    // selecting target response object from store
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
    const count = yield select(state => state.sessionrecording.Count)
    // update target trial query

    const response = yield call(updateTargetTrial, {
      object: payload.object,
      response: payload.response,
      promptId: payload.promptId,
      note: payload.note,
    })
    if (response && response.data) {
      // updating target end time in redux store
      if (
        targetResponse[targetActiveId].sd[payload.object.sd.id][count - 1].id ===
        response.data.updateTrial.details.id
      ) {
        targetResponse[targetActiveId].sd[payload.object.sd.id][count - 1] =
          response.data.updateTrial.details
      }

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse: targetResponse,
        },
      })
    }
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  } catch (error) {
    console.log('error', error)
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* CHANGE_STEP({ payload }) {
  // selecting target response object from store
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const { MasterSession, TargetActiveIndex } = yield select(state => state.sessionrecording)
  const maxCount = getMaxCount(MasterSession, TargetActiveIndex)

  let currentCount = 1
  let currentCorrectCount = 0
  let currentIncorrectCount = 0

  // updating Count, CorrectCount & IncorrectCount

  if (targetResponse[payload.targetId].step[payload.stepId].length > 0) {
    currentCount = targetResponse[payload.targetId].step[payload.stepId].length + 1
    targetResponse[payload.targetId].step[payload.stepId].map(recordingItem => {
      if (recordingItem.trial === 'CORRECT') {
        currentCorrectCount += 1
      }
      if (
        recordingItem.trial === 'ERROR' ||
        recordingItem.trial === 'PROMPT' ||
        recordingItem.trial === 'INCORRECT'
      ) {
        currentIncorrectCount += 1
      }
    })
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      Count: currentCount <= maxCount ? currentCount : maxCount,
      CorrectCount: currentCorrectCount,
      IncorrectCount: currentIncorrectCount,
      StepActiveIndex: payload.stepIndex,
      StepActiveId: payload.stepId,
    },
  })
}

export function* TARGET_STEP_CORRECT({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })

  try {
    // selecting child session id for creating child session
    const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
    const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
    const trialStartTime = yield select(state => state.sessionrecording.TrialStartTime)
    const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
    const masterSession = yield select(state => state.sessionrecording.MasterSession)
    const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
    const count = yield select(state => state.sessionrecording.Count)
    const stepActiveIndex = yield select(state => state.sessionrecording.StepActiveIndex)
    const taskAnaylsisStepStatusWiseMovement = yield select(
      state => state.sessionrecording.TaskAnaylsisStepStatusWiseMovement,
    )
    // selecting status id of the target
    const sessionStatusId = masterSession.targets.edges[targetActiveIndex].node.targetStatus.id
    // updating session duration
    const response = yield call(recordTargetStepTrial, {
      childId: childSessionId,
      statusId: sessionStatusId,
      targetId: targetActiveId,
      start: trialStartTime,
      end: currentSessionTime,
      promptId: payload.promptId,
      response: payload.response,
      stepId: payload.stepId,
      note: payload.note,
      sdStepStatus: payload.sdStepStatusId,
    })
    if (response && response.data && response.data.sessionRecording) {
      const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
      targetResponse[targetActiveId].step[payload.stepId] = []
      response.data.sessionRecording.details.sessionRecord.edges.map(item => {
        if (item.node.step.id === payload.stepId) {
          targetResponse[targetActiveId].step[payload.stepId] = [
            ...targetResponse[targetActiveId].step[payload.stepId],
            item.node,
          ]
        }
      })

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse: targetResponse,
        },
      })

      if (
        count <
        masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.DailyTrials
      ) {
        yield put({
          type: 'sessionrecording/SET_STATE',
          payload: {
            Count: count + 1,
          },
        })
        resetStartTime()
        resetCorrectIncorrectButtons()
      } else if (
        count ===
        masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.DailyTrials
      ) {
        if (
          stepActiveIndex <
          masterSession.targets.edges[targetActiveIndex].node.steps.edges.length - 1
        ) {
          if (
            masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.targetType
              .id === taskAnalysisId
          ) {
            const item = masterSession.targets.edges[targetActiveIndex].node
            let statusId = ''
            item.mastery.edges.map(
              masteryItem =>
                masteryItem.node.step?.id === item.steps.edges[stepActiveIndex].node.id &&
                (statusId = masteryItem.node.status?.id),
            )
            // add new automatic logic for TA step movement
            if (taskAnaylsisStepStatusWiseMovement) {
              if (statusId && statusId === masteredStatusId) {
                resetStartTime()
                resetCorrectIncorrectButtons()

                yield put({
                  type: 'sessionrecording/CHANGE_STEP',
                  payload: {
                    stepId:
                      masterSession.targets.edges[targetActiveIndex].node.steps.edges[
                        stepActiveIndex + 1
                      ].node.id,
                    stepIndex: stepActiveIndex + 1,
                    targetId: targetActiveId,
                  },
                })
              } else if (targetActiveIndex + 1 < masterSession.targets.edges.length) {
                yield put({
                  type: 'sessionrecording/MOVE_TO_NEXT_TARGET',
                })
              } else {
                yield put({
                  type: 'sessionrecording/OPEN_END_SESSION_MODAL',
                })
              }
            } else {
              resetStartTime()
              resetCorrectIncorrectButtons()

              yield put({
                type: 'sessionrecording/CHANGE_STEP',
                payload: {
                  stepId:
                    masterSession.targets.edges[targetActiveIndex].node.steps.edges[
                      stepActiveIndex + 1
                    ].node.id,
                  stepIndex: stepActiveIndex + 1,
                  targetId: targetActiveId,
                },
              })
            }
          } else {
            resetStartTime()
            resetCorrectIncorrectButtons()

            yield put({
              type: 'sessionrecording/CHANGE_STEP',
              payload: {
                stepId:
                  masterSession.targets.edges[targetActiveIndex].node.steps.edges[
                    stepActiveIndex + 1
                  ].node.id,
                stepIndex: stepActiveIndex + 1,
                targetId: targetActiveId,
              },
            })
          }
        } else if (targetActiveIndex + 1 < masterSession.targets.edges.length) {
          yield put({
            type: 'sessionrecording/MOVE_TO_NEXT_TARGET',
          })
        } else {
          yield put({
            type: 'sessionrecording/OPEN_END_SESSION_MODAL',
          })
        }
      }
    }

    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  } catch (error) {
    console.log('error', error)
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* UPDATE_TARGET_STEP_TRIAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  try {
    // selecting target response object from store
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
    const count = yield select(state => state.sessionrecording.Count)
    // update target trial query

    const response = yield call(updateTargetTrial, {
      object: payload.object,
      response: payload.response,
      promptId: payload.promptId,
      note: payload.note,
    })
    if (response && response.data) {
      // updating target end time in redux store
      if (
        targetResponse[targetActiveId].step[payload.object.step.id][count - 1].id ===
        response.data.updateTrial.details.id
      ) {
        targetResponse[targetActiveId].step[payload.object.step.id][count - 1] =
          response.data.updateTrial.details
      }

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse: targetResponse,
        },
      })
    }
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  } catch (error) {
    console.log('error', error)
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

// PEAK APIS
export function* UPDATE_BLOCK_ENDTIME({ payload }) {
  try {
    // selecting child session id for creating child session
    const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    const currentBlockIndex = yield select(state => state.sessionrecording.PeakBlockIndex)

    // getting skillsId for target
    const skillsId = targetResponse[payload.targetId].skillsId

    // checking and updating peak block end time
    if (targetResponse[payload.targetId].peak) {
      const blockId = targetResponse[payload.targetId].peak[currentBlockIndex].blockId
      const previousBlockTime = targetResponse[payload.targetId].peak[currentBlockIndex].blockEnd
      if (previousBlockTime === 0 || previousBlockTime === null) {
        if (debug) {
          console.log('updated session block time')
        }
        const blockResponse = yield call(updateTargetBlock, {
          skillsId,
          blockId,
          endTime: currentSessionTime,
        })
        if (blockResponse && blockResponse.data) {
          targetResponse[payload.targetId].peak[currentBlockIndex].blockEnd =
            blockResponse.data.peakBlock.block.durationEnd

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              TargetResponse: targetResponse,
            },
          })
        }
      }
    }
    // end of check
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  } catch (error) {
    console.log('error', error)
  }
}

export function* CREATE_NEW_BLOCK({ payload }) {
  try {
    // selecting child session id for creating child session
    const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    // const currentBlockIndex = yield select(state => state.sessionrecording.PeakBlockIndex)

    // getting skillsId for target
    const skillsId = targetResponse[payload.targetId].skillsId

    // checking and updating peak block end time
    if (targetResponse[payload.targetId].peak) {
      const blockId = targetResponse[payload.targetId].peak[payload.blockIndex].blockId
      let trailCount = 1
      if (!blockId) {
        const blockResponse = yield call(createTargetBlock, {
          skillsId,
          startTime: currentSessionTime,
        })
        if (blockResponse && blockResponse.data) {
          // updating created block details on redux store
          targetResponse[payload.targetId].peak[payload.blockIndex] = {
            ...targetResponse[payload.targetId].peak[payload.blockIndex],
            blockId: blockResponse.data.peakBlock.block.id,
            blockStart: blockResponse.data.peakBlock.block.durationStart,
          }

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              TargetResponse: targetResponse,
              PeakTrialCount: 1,
            },
          })
        }
      } else {
        targetResponse[payload.targetId].peak[payload.blockIndex].block.map(item => {
          // if (item.recordedData === true) {
          //   trailCount += 1
          // }
          if (item.marksRecorded === true) {
            if (trailCount < 10) trailCount += 1
          }
        })
        yield put({
          type: 'sessionrecording/SET_STATE',
          payload: {
            PeakTrialCount: trailCount,
          },
        })
      }
    }
    // end of check
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  } catch (error) {
    console.log('error', error)
  }
}

export function* RECORD_BLOCK_TRIAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting child session id for creating child session
  const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
  const startTime = yield select(state => state.sessionrecording.TrialStartTime)
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const targetId = yield select(state => state.sessionrecording.TargetActiveId)
  const currentBlockIndex = yield select(state => state.sessionrecording.PeakBlockIndex)
  const trialCount = yield select(state => state.sessionrecording.PeakTrialCount)
  const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  // let blockId;

  // if(payload.actionFrom === 'createTargetTrial') {
  //   blockId = payload.blockId
  // } else {
  //   blockId = targetResponse[targetId].peak[currentBlockIndex].blockId
  // }
  const blockId = targetResponse[targetId].peak[currentBlockIndex].blockId

  // checking and updating peak block end time
  if (blockId) {
    const trialResponse = yield call(recordBlockTrial, {
      blockId,
      startTime,
      endTime: currentSessionTime,
      marks: payload.marks,
      promptId: payload.promptId,
      sd: payload.sd,
      sdStatus: payload.sdStatusId,
    })
    if (trialResponse && trialResponse.data) {
      // updating created block details on redux store
      targetResponse[targetId].peak[currentBlockIndex].block[trialCount - 1] = {
        ...targetResponse[targetId].peak[currentBlockIndex].block[trialCount - 1],
        recordedData: true,
        response: trialResponse.data.peakBlockTrials.trial,
        marksRecorded: trialResponse.data.peakBlockTrials.trial.marks !== null ? true : false,
      }

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse: targetResponse,
        },
      })

      // move to next trial

      if (trialCount < 10) {
        if (
          targetResponse[targetId].peak[currentBlockIndex].block[trialCount - 1].recordedData ===
          true
        ) {
          resetStartTime()

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              PeakTrialCount: trialCount + 1,
            },
          })

          const getButton0 = document.getElementById('peakResponseButtonZero')
          const getButton2 = document.getElementById('peakResponseButtonTwo')
          const getButton4 = document.getElementById('peakResponseButtonFour')
          const getButton8 = document.getElementById('peakResponseButtonEight')
          const getButton10 = document.getElementById('peakResponseButtonTen')

          if (
            targetResponse[targetId].peak[currentBlockIndex]?.block &&
            targetResponse[targetId].peak[currentBlockIndex]?.block[trialCount]?.response?.marks ===
              0
          ) {
            if (typeof getButton0 !== 'undefined' && getButton0 != null) {
              document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
            }
          } else {
            if (typeof getButton0 !== 'undefined' && getButton0 != null) {
              document.getElementById('peakResponseButtonZero').style.backgroundColor = '#e4e9f0'
            }
          }

          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount]?.response?.marks ===
            2
          ) {
            if (typeof getButton2 !== 'undefined' && getButton2 != null) {
              document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
            }
          } else {
            if (typeof getButton2 !== 'undefined' && getButton2 != null) {
              document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#e4e9f0'
            }
          }
          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount]?.response?.marks ===
            4
          ) {
            if (typeof getButton4 !== 'undefined' && getButton4 != null) {
              document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
            }
          } else {
            if (typeof getButton4 !== 'undefined' && getButton4 != null) {
              document.getElementById('peakResponseButtonFour').style.backgroundColor = '#e4e9f0'
            }
          }

          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount]?.response?.marks ===
            8
          ) {
            if (typeof getButton8 !== 'undefined' && getButton8 != null) {
              document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
            }
          } else {
            if (typeof getButton8 !== 'undefined' && getButton8 != null) {
              document.getElementById('peakResponseButtonEight').style.backgroundColor = '#e4e9f0'
            }
          }
          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount]?.response?.marks ===
            10
          ) {
            if (typeof getButton10 !== 'undefined' && getButton10 != null) {
              document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
            }
          } else {
            if (typeof getButton10 !== 'undefined' && getButton10 != null) {
              document.getElementById('peakResponseButtonTen').style.backgroundColor = '#e4e9f0'
            }
          }

          // if (
          //   targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks === 0
          // ) {
          //   if (typeof getButton0 !== 'undefined' && getButton0 != null) {
          //     document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
          //   }
          // }
          // if (
          //   targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks === 2
          // ) {
          //   if (typeof getButton2 !== 'undefined' && getButton2 != null) {
          //     document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
          //   }
          // }
          // if (
          //   targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks === 4
          // ) {
          //   if (typeof getButton4 !== 'undefined' && getButton4 != null) {
          //     document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
          //   }
          // }
          // if (
          //   targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks === 8
          // ) {
          //   if (typeof getButton8 !== 'undefined' && getButton8 != null) {
          //     document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
          //   }
          // }
          // if (
          //   targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks ===
          //   10
          // ) {
          //   if (typeof getButton10 !== 'undefined' && getButton10 != null) {
          //     document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
          //   }
          // }

          // if (!PeakAutomatic) {
          //   this.peakSelectedStimulusIndexReset(trialCount + 1)
          // }
        }
      } else if (trialCount === 10) {
        if (
          currentBlockIndex <
          masterSession.targets.edges[targetActiveIndex].node.peakBlocks - 1
        ) {
          updateSessionClockTime()
          yield put({
            type: 'sessionrecording/UPDATE_BLOCK_ENDTIME',
            payload: {
              blockIndex: currentBlockIndex,
              targetId,
            },
          })

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              PeakBlockIndex: currentBlockIndex + 1,
            },
          })

          yield put({
            type: 'sessionrecording/CREATE_NEW_BLOCK',
            payload: {
              blockIndex: currentBlockIndex + 1,
              targetId,
            },
          })
        } else if (targetActiveIndex + 1 < masterSession.targets.edges.length) {
          yield put({
            type: 'sessionrecording/MOVE_TO_NEXT_TARGET',
          })
        } else {
          yield put({
            type: 'sessionrecording/OPEN_END_SESSION_MODAL',
          })
        }
      }
    }
  }
  // end of check
  // update session duration
  yield put({
    type: 'sessionrecording/UPDATE_DURATION',
  })
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* UPDATE_BLOCK_TRIAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting child session id for creating child session
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const targetId = yield select(state => state.sessionrecording.TargetActiveId)
  const currentBlockIndex = yield select(state => state.sessionrecording.PeakBlockIndex)
  const trialCount = yield select(state => state.sessionrecording.PeakTrialCount)
  const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)

  const blockId = targetResponse[targetId].peak[currentBlockIndex].blockId

  // checking and updating peak block end time
  if (blockId) {
    const trialResponse = yield call(updateBlockTrial, {
      responseId: payload.responseId,
      marks: payload.marks,
      promptId: payload.promptId,
      sd: payload.sd,
    })
    if (trialResponse && trialResponse.data) {
      // updating created block details on redux store
      targetResponse[targetId].peak[currentBlockIndex].block[trialCount - 1] = {
        ...targetResponse[targetId].peak[currentBlockIndex].block[trialCount - 1],
        recordedData: true,
        response: trialResponse.data.peakBlockUpdateTrial.trial,
        sd: trialResponse.data.peakBlockUpdateTrial.trial.sd,
        marksRecorded: trialResponse.data.peakBlockUpdateTrial.trial.marks !== null ? true : false,
      }

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse: targetResponse,
          ResponseLoading: false,
        },
      })

      // move to next trial

      if (trialCount < 10) {
        if (
          targetResponse[targetId].peak[currentBlockIndex].block[trialCount - 1].recordedData ===
          true
        ) {
          resetStartTime()

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              PeakTrialCount: trialCount + 1,
            },
          })

          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks === 0
          ) {
            document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
          }
          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks === 2
          ) {
            document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
          }
          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks === 4
          ) {
            document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
          }
          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks === 8
          ) {
            document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
          }
          if (
            targetResponse[targetId].peak[currentBlockIndex].block[trialCount].response?.marks ===
            10
          ) {
            document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
          }

          // if (!PeakAutomatic) {
          //   this.peakSelectedStimulusIndexReset(trialCount + 1)
          // }
        }
      } else if (trialCount === 10) {
        if (
          currentBlockIndex <
          masterSession.targets.edges[targetActiveIndex].node.peakBlocks - 1
        ) {
          updateSessionClockTime()
          yield put({
            type: 'sessionrecording/UPDATE_BLOCK_ENDTIME',
            payload: {
              blockIndex: currentBlockIndex,
              targetId,
            },
          })

          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              PeakBlockIndex: currentBlockIndex + 1,
            },
          })

          yield put({
            type: 'sessionrecording/CREATE_NEW_BLOCK',
            payload: {
              blockIndex: currentBlockIndex + 1,
              targetId,
            },
          })
        } else if (targetActiveIndex + 1 < masterSession.targets.edges.length) {
          yield put({
            type: 'sessionrecording/MOVE_TO_NEXT_TARGET',
          })
        } else {
          yield put({
            type: 'sessionrecording/OPEN_END_SESSION_MODAL',
          })
        }
      }
    }
  }
  // end of check
  // update session duration
  yield put({
    type: 'sessionrecording/UPDATE_DURATION',
  })
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* GET_SD_COMBINATIONS({ payload }) {
  const response = yield call(getCombinationsByCode, payload)
  if (response && response.data) {
    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        SdCombinations: response.data.getPeakEquCodes.edges[0]?.node,
        ActiveCombination: response.data.getPeakEquCodes.edges[0]?.node.train.edges[0]?.node.id,
      },
    })
  }
}

export function* EQUIVALENCE_RESPONSE({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  const trialStartTime = yield select(state => state.sessionrecording.TrialStartTime)
  const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
  // selecting status id of the target
  const targetStatusId = masterSession.targets.edges[targetActiveIndex].node.targetStatus.id
  // updating session duration
  const response = yield call(recordEquivalenceTarget, {
    childId: childSessionId,
    statusId: targetStatusId,
    targetId: targetActiveId,
    start: trialStartTime,
    end: currentSessionTime,
    response: payload.response,
    operation: payload.operation,
    classId: payload.classId,
    combinationTest: payload.combinationTest,
    combinationTrain: payload.combinationTrain,
  })

  const selectedOperation = yield select(state => state.sessionrecording.SelectedOperation)
  const activeCombination = yield select(state => state.sessionrecording.ActiveCombination)
  const selectedClassId = yield select(state => state.sessionrecording.SelectedClassId)
  const equiTrialCount = yield select(state => state.sessionrecording.EquiTrialCount)
  const sdCombinations = yield select(state => state.sessionrecording.SdCombinations)

  // const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)

  if (response && response.data && response.data.sessionRecording) {
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    // targetResponse[targetActiveId].equivalence[selectedOperation === 'Train' ? 'train': 'test'][activeCombination][selectedClassId] = []
    response.data.sessionRecording.details.peakEquivalance.edges.map(item => {
      let operation = 'train'
      if (selectedOperation === 'Test') operation = 'test'
      targetResponse[targetActiveId].equivalence[operation][activeCombination][selectedClassId] = [
        ...targetResponse[targetActiveId].equivalence[operation][activeCombination][
          selectedClassId
        ],
        item.node,
      ]
    })

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResponse: targetResponse,
      },
    })
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })

    // automatic trial, combination, class, test/train and target move code
    if (equiTrialCount < 10) {
      resetPeakButtons()
      resetStartTime()
      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          EquiTrialCount: equiTrialCount + 1,
        },
      })
    } else if (selectedOperation === 'Train') {
      let previousCombIndex = 0

      sdCombinations.train.edges.map((nodeItem, index) => {
        if (nodeItem.node.id === activeCombination) {
          previousCombIndex = index
        }
      })

      if (previousCombIndex + 1 < sdCombinations.train.edges.length) {
        // change active combination
        const newCombId = sdCombinations.train.edges[previousCombIndex + 1]?.node.id
        const trialsLength =
          targetResponse[targetActiveId].equivalence.train[newCombId][selectedClassId].length
        if (trialsLength < 10) {
          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              EquiTrialCount: trialsLength + 1,
              ActiveCombination: newCombId,
            },
          })
        } else {
          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              EquiTrialCount: 10,
              ActiveCombination: newCombId,
            },
          })
        }
      } else {
        // check for change class
        let previosClassIndex = 0
        masterSession.targets.edges[targetActiveIndex].node.classes.edges.map(
          (classNode, classIndex) => {
            if (classNode.node.id === selectedClassId) {
              previosClassIndex = classIndex
            }
          },
        )

        if (
          previosClassIndex + 1 <
          masterSession.targets.edges[targetActiveIndex].node.classes.edges.length
        ) {
          // chnage class
          const newClassId =
            masterSession.targets.edges[targetActiveIndex].node.classes.edges[previosClassIndex + 1]
              ?.node.id
          const trialsLengthCheck =
            targetResponse[targetActiveId].equivalence.train[activeCombination][newClassId].length
          if (trialsLengthCheck < 10) {
            yield put({
              type: 'sessionrecording/SET_STATE',
              payload: {
                SelectedClassId: newClassId,
                EquiTrialCount: trialsLengthCheck + 1,
              },
            })
          } else {
            yield put({
              type: 'sessionrecording/SET_STATE',
              payload: {
                SelectedClassId: newClassId,
                EquiTrialCount: 10,
              },
            })
          }
        } else {
          // check for change test/train
          const comId = sdCombinations.test.edges[0]?.node.id
          const firstClassId =
            masterSession.targets.edges[targetActiveIndex].node.classes.edges[0]?.node.id
          const trialsLengthOper =
            targetResponse[targetActiveId].equivalence.test[comId][selectedClassId]?.length
          if (trialsLengthOper < 10) {
            yield put({
              type: 'sessionrecording/SET_STATE',
              payload: {
                SelectedOperation: 'Test',
                EquiTrialCount: trialsLengthOper + 1,
                ActiveCombination: comId,
                SelectedClassId: firstClassId,
              },
            })
          } else {
            yield put({
              type: 'sessionrecording/SET_STATE',
              payload: {
                SelectedOperation: 'Test',
                EquiTrialCount: 10,
                ActiveCombination: comId,
                SelectedClassId: firstClassId,
              },
            })
          }
        }
      }
    } else if (selectedOperation === 'Test') {
      let previousCombIndex = 0

      sdCombinations.test.edges.map((nodeItem, index) => {
        if (nodeItem.node.id === activeCombination) {
          previousCombIndex = index
        }
      })

      if (previousCombIndex + 1 < sdCombinations.test.edges.length) {
        // change active combination
        const newCombId = sdCombinations.test.edges[previousCombIndex + 1]?.node.id
        const trialsLength =
          targetResponse[targetActiveId].equivalence.test[newCombId][selectedClassId].length
        if (trialsLength < 10) {
          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              EquiTrialCount: trialsLength + 1,
              ActiveCombination: newCombId,
            },
          })
        } else {
          yield put({
            type: 'sessionrecording/SET_STATE',
            payload: {
              EquiTrialCount: 10,
              ActiveCombination: newCombId,
            },
          })
        }
      } else {
        // check for change class
        let previosClassIndex = 0
        masterSession.targets.edges[targetActiveIndex].node.classes.edges.map(
          (classNode, classIndex) => {
            if (classNode.node.id === selectedClassId) {
              previosClassIndex = classIndex
            }
          },
        )

        if (
          previosClassIndex + 1 <
          masterSession.targets.edges[targetActiveIndex].node.classes.edges.length
        ) {
          // chnage class
          const newClassId =
            masterSession.targets.edges[targetActiveIndex].node.classes.edges[previosClassIndex + 1]
              ?.node.id
          const trialsLengthCheck =
            targetResponse[targetActiveId].equivalence.test[activeCombination][newClassId].length
          if (trialsLengthCheck < 10) {
            yield put({
              type: 'sessionrecording/SET_STATE',
              payload: {
                SelectedClassId: newClassId,
                EquiTrialCount: trialsLengthCheck + 1,
              },
            })
          } else {
            yield put({
              type: 'sessionrecording/SET_STATE',
              payload: {
                SelectedClassId: newClassId,
                EquiTrialCount: 10,
              },
            })
          }
        }
        // move to next target
        else if (targetActiveIndex + 1 < masterSession.targets.edges.length) {
          yield put({
            type: 'sessionrecording/MOVE_TO_NEXT_TARGET',
          })
        } else {
          yield put({
            type: 'sessionrecording/OPEN_END_SESSION_MODAL',
          })
        }
      }
    }
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* EQUIVALENCE_RESPONSE_UPDATE({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  const trialStartTime = yield select(state => state.sessionrecording.TrialStartTime)
  const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
  // selecting status id of the target
  const targetStatusId = masterSession.targets.edges[targetActiveIndex].node.targetStatus.id
  // updating session duration
  const response = yield call(recordEquivalenceTargetUpdate, {
    objectId: payload.trialRecordedId,
    response: payload.response,
  })

  const selectedOperation = yield select(state => state.sessionrecording.SelectedOperation)
  const activeCombination = yield select(state => state.sessionrecording.ActiveCombination)
  const selectedClassId = yield select(state => state.sessionrecording.SelectedClassId)
  const equiTrialCount = yield select(state => state.sessionrecording.EquiTrialCount)
  // const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)

  if (response && response.data && response.data.updatePeakEquivalanceTrial) {
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    // targetResponse[targetActiveId].equivalence[selectedOperation === 'Train' ? 'train': 'test'][activeCombination][selectedClassId] = []
    const updatedObject = response.data.updatePeakEquivalanceTrial.details
    let operation = 'train'
    if (selectedOperation === 'Test') operation = 'test'

    targetResponse[targetActiveId].equivalence[operation][activeCombination][selectedClassId][
      equiTrialCount - 1
    ] = updatedObject

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResponse: targetResponse,
      },
    })
    // update session duration
    yield put({
      type: 'sessionrecording/UPDATE_DURATION',
    })
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* RECORD_SBT_TRIAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  const trialStartTime = yield select(state => state.sessionrecording.TrialStartTime)
  const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
  const count = yield select(state => state.sessionrecording.Count)
  const sbtStepActiveIndex = yield select(state => state.sessionrecording.SBTStepActiveIndex)
  // selecting status id of the target
  const targetStatusId = masterSession.targets.edges[targetActiveIndex].node.targetStatus.id
  const targetObject = masterSession.targets.edges[targetActiveIndex].node
  let sbtStatusId = ''
  if (targetObject && targetObject.sbtSteps.edges.length > 0) {
    const step = targetObject.sbtSteps.edges.filter(item => {
      return item.node.id === payload.sbtStepId
    })
    if (step && step.length > 0) sbtStatusId = step[0].node.status.id
  }

  // updating session duration
  const response = yield call(recordBehaviorReductionTrial, {
    childId: childSessionId,
    statusId: targetStatusId,
    targetId: targetActiveId,
    duration: Math.round(currentSessionTime - trialStartTime),
    promptId: payload.promptId,
    response: payload.response,
    sbtStepId: payload.sbtStepId,
    r1: payload.r1,
    r2: payload.r2,
    reinforceId: payload.reinforceId,
    sbtStatusId,
  })
  if (response && response.data && response.data.recordBehaviourReductionRecording) {
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
    // targetResponse[targetActiveId].step[payload.stepId] = []

    if (
      response.data.recordBehaviourReductionRecording.behaviorReduction &&
      response.data.recordBehaviourReductionRecording.behaviorReduction[0]?.sbtStep.id ===
        payload.sbtStepId
    ) {
      targetResponse[targetActiveId].sbtStep[payload.sbtStepId] = [
        ...targetResponse[targetActiveId].sbtStep[payload.sbtStepId],
        response.data.recordBehaviourReductionRecording.behaviorReduction[0],
      ]
    }

    // response.data.recordBehaviourReductionRecording.details.behReduction.edges.map(item => {
    //   if (item.node.step.id === payload.stepId) {
    //     targetResponse[targetActiveId].step[payload.stepId] = [
    //       ...targetResponse[targetActiveId].step[payload.stepId],
    //       item.node,
    //     ]
    //   }
    // })

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResponse: targetResponse,
      },
    })

    if (
      count < masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.DailyTrials
    ) {
      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          Count: count + 1,
        },
      })
      resetStartTime()
      resetCorrectIncorrectButtons()
    } else if (
      count ===
      masterSession.targets.edges[targetActiveIndex].node.targetAllcatedDetails.DailyTrials
    ) {
      if (
        sbtStepActiveIndex <
        masterSession.targets.edges[targetActiveIndex].node.sbtSteps.edges.length - 1
      ) {
        resetStartTime()
        resetCorrectIncorrectButtons()

        yield put({
          type: 'sessionrecording/CHANGE_SBT_STEP',
          payload: {
            sbtStepId:
              masterSession.targets.edges[targetActiveIndex].node.sbtSteps.edges[
                sbtStepActiveIndex + 1
              ].node.id,
            sbtStepIndex: sbtStepActiveIndex + 1,
            targetId: targetActiveId,
          },
        })
      } else if (targetActiveIndex + 1 < masterSession.targets.edges.length) {
        yield put({
          type: 'sessionrecording/MOVE_TO_NEXT_TARGET',
        })
      } else {
        yield put({
          type: 'sessionrecording/OPEN_END_SESSION_MODAL',
        })
      }
    }
  }

  // update session duration
  yield put({
    type: 'sessionrecording/UPDATE_DURATION',
  })
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* UPDATE_SBT_TRIAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting target response object from store
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  const count = yield select(state => state.sessionrecording.Count)
  // update target trial query

  const response = yield call(updateBehaviorReductionTargetTrial, {
    objectId: payload.object.id,
    promptId: payload.promptId,
    response: payload.response,
    r1: payload.r1,
    r2: payload.r2,
    reinforceId: payload.reinforceId,
  })
  if (response && response.data) {
    // updating target end time in redux store
    if (
      targetResponse[targetActiveId].sbtStep[payload.sbtStepId][count - 1].id ===
      response.data.updateBehaviourReductionTrial.details.id
    ) {
      targetResponse[targetActiveId].sbtStep[payload.sbtStepId][count - 1] =
        response.data.updateBehaviourReductionTrial.details
    }

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResponse: targetResponse,
      },
    })
  }
  // update session duration
  yield put({
    type: 'sessionrecording/UPDATE_DURATION',
  })
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* CHANGE_SBT_STEP({ payload }) {
  // selecting target response object from store
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const { MasterSession, TargetActiveIndex } = yield select(state => state.sessionrecording)
  const maxCount = getMaxCount(MasterSession, TargetActiveIndex)

  let currentCount = 1
  const currentCorrectCount = 0
  const currentIncorrectCount = 0

  // updating Count, CorrectCount & IncorrectCount

  if (targetResponse[payload.targetId].sbtStep[payload.sbtStepId].length > 0) {
    // write check for trials with daily trials
    currentCount = targetResponse[payload.targetId].sbtStep[payload.sbtStepId].length + 1
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      Count: currentCount <= maxCount ? currentCount : maxCount,
      CorrectCount: currentCorrectCount,
      IncorrectCount: currentIncorrectCount,
      SBTStepActiveIndex: payload.sbtStepIndex,
      SBTStepActiveId: payload.sbtStepId,
    },
  })
}

export function* RECORD_BEHAVIOR_FREQUENCY({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  const trialStartTime = yield select(state => state.sessionrecording.TrialStartTime)
  const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
  const count = yield select(state => state.sessionrecording.Count)
  const stepActiveIndex = yield select(state => state.sessionrecording.StepActiveIndex)
  // selecting status id of the target
  const targetStatusId = masterSession.targets.edges[targetActiveIndex].node.targetStatus.id
  // updating session duration
  const response = yield call(recordBehaviorFrequency, {
    childId: childSessionId,
    statusId: targetStatusId,
    targetId: targetActiveId,
    isBehRecording: true,
    start: Math.round(payload.start),
    end: Math.round(payload.end),
    frequency: payload.frequency,
    totalbehRecordingTime: Math.round(payload.totalbehRecordingTime),
  })
  if (response && response.data && response.data.recordBehaviourReductionRecording) {
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)

    if (
      response.data.recordBehaviourReductionRecording.behaviorRecording &&
      response.data.recordBehaviourReductionRecording.behaviorRecording[0]
    ) {
      targetResponse[targetActiveId].behaviorRecordingTime =
        response.data.recordBehaviourReductionRecording.details.totalBehRecordingDuration
      targetResponse[targetActiveId].behaviorRecording = [
        ...targetResponse[targetActiveId].behaviorRecording,
        response.data.recordBehaviourReductionRecording.behaviorRecording[0],
      ]
    }

    // response.data.recordBehaviourReductionRecording.details.behReduction.edges.map(item => {
    //   if (item.node.step.id === payload.stepId) {
    //     targetResponse[targetActiveId].step[payload.stepId] = [
    //       ...targetResponse[targetActiveId].step[payload.stepId],
    //       item.node,
    //     ]
    //   }
    // })

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResponse: targetResponse,
      },
    })
  }

  // update session duration
  yield put({
    type: 'sessionrecording/UPDATE_DURATION',
  })
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* UPDATE_BEHAVIOR_FREQUENCY({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  const BehEditTrialCount = yield select(state => state.sessionrecording.BehEditTrialCount)
  // updating session duration
  const response = yield call(updateBehaviorFrequency, {
    objectId: payload.objectId,
    start: Math.round(payload.start),
    end: Math.round(payload.end),
    frequency: payload.frequency,
  })
  if (response && response.data && response.data.updateBehaviourRecordingTrial) {
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)

    if (response.data.updateBehaviourRecordingTrial.details) {
      targetResponse[targetActiveId].behaviorRecording[BehEditTrialCount - 1] =
        response.data.updateBehaviourRecordingTrial.details
    }

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResponse: targetResponse,
      },
    })
  }

  // update session duration
  yield put({
    type: 'sessionrecording/UPDATE_DURATION',
  })
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* DELETE_BEHAVIOR_FREQUENCY({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const targetActiveId = yield select(state => state.sessionrecording.TargetActiveId)
  const trialStartTime = yield select(state => state.sessionrecording.TrialStartTime)
  const currentSessionTime = yield select(state => state.sessionrecording.CurrentSessionTime)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  const targetActiveIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
  const count = yield select(state => state.sessionrecording.Count)
  const stepActiveIndex = yield select(state => state.sessionrecording.StepActiveIndex)
  // selecting status id of the target
  const targetStatusId = masterSession.targets.edges[targetActiveIndex].node.targetStatus.id
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const objId = targetResponse[targetActiveId].behaviorRecording[payload.objectIndex]?.id
  const skillId = targetResponse[targetActiveId].skillsId
  // updating session duration
  if (objId) {
    const response = yield call(deleteBehaviorFrequency, {
      objectId: objId,
      skillId,
    })

    if (response.data.activeInactiveTrialRecordedData.status) {
      targetResponse[targetActiveId].behaviorRecording.splice(payload.objectIndex, 1)
    }

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResponse: targetResponse,
      },
    })
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* RECORD_BEHAVIOR_TOTAL_TIME({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: true,
    },
  })
  // selecting child session id for creating child session
  const childSessionId = yield select(state => state.sessionrecording.ChildSession.id)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  // selecting status id of the target
  const targetStatusId = masterSession.targets.edges[payload.targetIndex].node.targetStatus.id
  // updating session duration
  const response = yield call(recordBehaviorTotalTime, {
    childId: childSessionId,
    statusId: targetStatusId,
    targetId: payload.targetId,
    isBehRecording: true,
    totalbehRecordingTime: payload.TotalTime,
  })
  if (response && response.data && response.data.recordBehaviourReductionRecording) {
    const targetResponse = yield select(state => state.sessionrecording.TargetResponse)

    if (response.data.recordBehaviourReductionRecording.details) {
      targetResponse[payload.targetId].behaviorRecordingTime =
        response.data.recordBehaviourReductionRecording.details.totalBehRecordingDuration
    }

    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResponse: targetResponse,
      },
    })
  }

  // update session duration
  yield put({
    type: 'sessionrecording/UPDATE_DURATION',
  })
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      ResponseLoading: false,
    },
  })
}

export function* GET_TODAYS_SESSION({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TodaysSessionLoading: true,
    },
  })

  const response = yield call(getTodaysSession, payload)
  if (response && response.data?.GetStudentSession) {
    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TodaysSession: response.data.GetStudentSession.edges,
        TodaysSessionLoading: false,
        ActiveStudent: payload.studentId,
      },
    })
    // let data = response.data.GetStudentSession.edges.map(({ node }) => node)
    // data = data.filter(x => x.targets.edges.length > 0)
    // const response2 = yield call(getSessionTemplatesNew, { ...payload, data })
    // if (response2) {
    //   const flt = {}
    //   response2.forEach((item, index) => {
    //     if (item) {
    //       flt[item.sessionId] = item.data?.sessionDataRecordingTemplateNew?.sessionRecordingTemplate
    //     }
    //   })
    //   yield put({
    //     type: 'sessionrecording/SET_STATE',
    //     payload: {
    //       SessionTemplates: flt,
    //       SessionTemplatesLoading: false,
    //     },
    //   })
    // }
  }
}

export function* RESET_TARGET_DATA({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetResetLoading: true,
    },
  })

  // calculate skillId from target id
  const targetResponse = yield select(state => state.sessionrecording.TargetResponse)
  const activeTargetIndex = yield select(state => state.sessionrecording.TargetActiveIndex)
  const masterSession = yield select(state => state.sessionrecording.MasterSession)
  const peakAutomatic = yield select(state => state.sessionrecording.PeakAutomatic)
  const skillsId = targetResponse[payload.targetId].skillsId

  if (skillsId) {
    const response = yield call(resetTargetData, { skillsId, isAutomatic: peakAutomatic })
    if (response && response.data?.activeInactiveTargetRecordedData) {
      // reset targetResponse Object

      // const targetResponse = {}
      let targetId = ''
      let stimulusId = ''
      let stepId = ''
      let sbtStepId = ''
      const i = 0

      targetId = payload.targetId
      // calculate target object through target id
      const targetObject = masterSession.targets.edges[activeTargetIndex].node

      if (targetObject.sd && targetObject.sd.edges.length > 0) {
        stimulusId = targetObject.sd.edges[0].node.id
      } else if (targetObject.steps && targetObject.steps.edges.length > 0) {
        stepId = targetObject.steps.edges[0].node.id
      } else if (targetObject.sbtSteps && targetObject.sbtSteps.edges.length > 0) {
        sbtStepId = targetObject.sbtSteps.edges[0].node.id
      }

      // targetResponse[targetId] = {}

      targetResponse[targetId].target = []

      if (targetObject.sd && targetObject.sd.edges.length > 0) {
        let j = 0
        targetResponse[targetId].sd = {}
        for (j = 0; j < targetObject.sd.edges.length; j += 1) {
          targetResponse[targetId].sd[targetObject.sd.edges[j].node.id] = []
        }
      }
      if (targetObject.steps && targetObject.steps.edges.length > 0) {
        let k = 0
        targetResponse[targetId].step = {}
        for (k = 0; k < targetObject.steps.edges.length; k += 1) {
          targetResponse[targetId].step[targetObject.steps.edges[k].node.id] = []
        }
      }
      if (targetObject.sbtSteps && targetObject.sbtSteps.edges.length > 0) {
        let k = 0
        targetResponse[targetId].sbtStep = {}
        for (k = 0; k < targetObject.sbtSteps.edges.length; k += 1) {
          targetResponse[targetId].sbtStep[targetObject.sbtSteps.edges[k].node.id] = []
        }
      }

      // for target type behavior recording
      if (targetObject.targetAllcatedDetails.targetType.id === behaviorRecordingId) {
        targetResponse[targetId].behaviorRecording = []
        targetResponse[targetId].behaviorRecordingTime = 0
      }

      // start of peak
      if (targetObject.targetAllcatedDetails.targetType.id === peakId) {
        if (debug) console.log('found peak')

        if (targetObject.peakType === equivalence) {
          targetResponse[targetId].equivalence = { train: {}, test: {} }

          const combinationResponse = yield call(getCombinationsByCode, {
            code: targetObject.eqCode ? targetObject.eqCode : '1A',
          })

          if (combinationResponse && combinationResponse.data) {
            if (debug) console.log('cobinationResponse', combinationResponse)
            const combTrain = combinationResponse.data.getPeakEquCodes.edges[0]?.node.train
            const combTest = combinationResponse.data.getPeakEquCodes.edges[0]?.node.test
            if (combTrain) {
              if (debug) console.log('cobinationResponse ==> Train')
              for (let o = 0; o < combTrain.edges.length; o += 1) {
                targetResponse[targetId].equivalence.train[combTrain.edges[o].node.id] = {}

                for (let p = 0; p < targetObject.classes.edges.length; p += 1) {
                  targetResponse[targetId].equivalence.train[combTrain.edges[o].node.id][
                    targetObject.classes.edges[p].node.id
                  ] = []
                }
              }
            }

            if (combTest) {
              if (debug) console.log('cobinationResponse ==> Test')
              for (let o = 0; o < combTest.edges.length; o += 1) {
                targetResponse[targetId].equivalence.test[combTest.edges[o].node.id] = {}

                for (let p = 0; p < targetObject.classes.edges.length; p += 1) {
                  targetResponse[targetId].equivalence.test[combTest.edges[o].node.id][
                    targetObject.classes.edges[p].node.id
                  ] = []
                }
              }
            }
          }
        } else if (targetObject.sd) {
          const peakBlocksCount = targetObject.peakBlocks
          if (peakAutomatic) {
            for (let j = 0; j < peakBlocksCount; j += 1) {
              // updating automatic created peak block trials to store
              if (targetResponse[targetId].peak[j]?.block.length > 0) {
                for (let q = 0; q < targetResponse[targetId].peak[j]?.block.length; q++) {
                  targetResponse[targetId].peak[j].block[q] = {
                    ...targetResponse[targetId].peak[j].block[q],
                    response: {
                      ...targetResponse[targetId].peak[j].block[q].response,
                      marks: null,
                    },
                    marksRecorded: false,
                  }
                }
              }
            }
          } else {
            for (let j = 0; j < peakBlocksCount; j += 1) {
              targetResponse[targetId].peak[j] = {
                ...targetResponse[targetId].peak[j],
                block: createStimulusList(targetObject.sd.edges),
              }
            }
          }
        }
      }
      // end of peak

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResetLoading: false,
          TargetResponse: targetResponse,
          StimulusActiveId: stimulusId,
          StepActiveId: stepId,
          SBTStepActiveId: sbtStepId,
          StepActiveIndex: 0,
          StimulusActiveIndex: 0,
          Count: 1,
          CorrectCount: 0,
          IncorrectCount: 0,
          Frequency: 0,
          TotalDuration: 0,
          TotalBehDuration: 0,
          SBTStepActiveIndex: 0,
          PeakBlockIndex: 0,
          PeakTrialCount: 1,
        },
      })
    }
  } else {
    yield put({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TargetResetLoading: false,
      },
    })
  }
}

export function* RESET_SD_SBT_STEP_DATA({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetResetLoading: true,
    },
  })

  const { sdId, stepId, sbtStepId, targetId } = payload
  const { TargetResponse } = yield select(state => state.sessionrecording)
  // get skillId
  const skillsId = TargetResponse[targetId].skillsId

  if (skillsId) {
    const response = yield call(resetSdStepSbtstep, { skillsId, sdId, stepId, sbtStepId })
    if (
      response &&
      response.data.activeInactiveSdStepRecording &&
      response.data.activeInactiveSdStepRecording.status === 'success'
    ) {
      if (sdId !== '') {
        TargetResponse[targetId].sd[sdId] = []
      } else if (stepId !== '') {
        TargetResponse[targetId].step[stepId] = []
      } else if (sbtStepId !== '') {
        TargetResponse[targetId].sbtStep[sbtStepId] = []
      }

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResetLoading: false,
          TargetResponse,
          Count: 1,
          CorrectCount: 0,
          IncorrectCount: 0,
          PeakBlockIndex: 0,
          PeakTrialCount: 1,
        },
      })
    }
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetResetLoading: false,
    },
  })
}

export function* RESET_BLOCK({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetResetLoading: true,
    },
  })

  const { targetId, blockIndex } = payload
  const { TargetResponse, PeakAutomatic, MasterSession, TargetActiveIndex } = yield select(
    state => state.sessionrecording,
  )
  // get skillId
  const skillsId = TargetResponse[targetId].skillsId
  const blockId = TargetResponse[targetId].peak[blockIndex].blockId
  const targetObject = MasterSession.targets.edges[TargetActiveIndex].node

  if (skillsId && blockId) {
    const response = yield call(resetBlock, { skillsId, blockId, isAutomatic: PeakAutomatic })
    if (
      response &&
      response.data.activeInactiveTrialRecordedData &&
      response.data.activeInactiveTrialRecordedData.status === 'success'
    ) {
      if (PeakAutomatic) {
        // updating automatic created peak block trials to store
        if (TargetResponse[targetId].peak[blockIndex]?.block.length > 0) {
          for (let i = 0; i < TargetResponse[targetId].peak[blockIndex]?.block.length; i++) {
            TargetResponse[targetId].peak[blockIndex].block[i] = {
              ...TargetResponse[targetId].peak[blockIndex].block[i],
              response: {
                ...TargetResponse[targetId].peak[blockIndex].block[i].response,
                marks: null,
              },
              marksRecorded: false,
            }
          }
        }
      } else {
        TargetResponse[targetId].peak[blockIndex] = {
          ...TargetResponse[targetId].peak[blockIndex],
          block: createStimulusList(targetObject.sd.edges),
        }
      }
      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResetLoading: false,
          TargetResponse,
          PeakTrialCount: 1,
        },
      })
    }
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetResetLoading: false,
    },
  })
}

export function* RESET_TRIAL({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetResetLoading: true,
    },
  })

  const { targetId, trialIndex, type } = payload
  const {
    TargetResponse,
    PeakAutomatic,
    MasterSession,
    TargetActiveIndex,
    TargetActiveId,
    StimulusActiveId,
    StepActiveId,
    SBTStepActiveId,
    PeakBlockIndex,
    PeakTrialCount,
  } = yield select(state => state.sessionrecording)
  let trialId = ''
  // get skillId
  const skillsId = TargetResponse[targetId].skillsId
  const targetObject = MasterSession.targets.edges[TargetActiveIndex].node

  if (type === 'Normal') {
    const object = TargetResponse[TargetActiveId].target[trialIndex]
    if (object) trialId = object.id
  } else if (type === 'Normal-sd') {
    const object = TargetResponse[TargetActiveId].sd[StimulusActiveId][trialIndex]
    if (object) trialId = object.id
  } else if (type === 'Normal-step') {
    const object = TargetResponse[TargetActiveId].step[StepActiveId][trialIndex]
    if (object) trialId = object.id
  } else if (type === 'Normal-sbtStep') {
    const object = TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId][trialIndex]
    if (object) trialId = object.id
  } else if (type === 'Coded') {
    const object = TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[trialIndex]
    if (object) trialId = object.response.id
  }

  if (skillsId && trialId) {
    const response = yield call(resetTrial, { skillsId, trialId, isAutomatic: PeakAutomatic })
    let newCount = 1
    if (
      response &&
      response.data.activeInactiveTrialRecordedData &&
      response.data.activeInactiveTrialRecordedData.status === 'success'
    ) {
      if (type === 'Normal') {
        TargetResponse[TargetActiveId].target.splice(trialIndex, 1)
        newCount = TargetResponse[TargetActiveId].target.length
        resetCorrectIncorrectButtons()
      } else if (type === 'Normal-sd') {
        TargetResponse[TargetActiveId].sd[StimulusActiveId].splice(trialIndex, 1)
        newCount = TargetResponse[TargetActiveId].sd[StimulusActiveId].length
        resetCorrectIncorrectButtons()
      } else if (type === 'Normal-step') {
        TargetResponse[TargetActiveId].step[StepActiveId].splice(trialIndex, 1)
        newCount = TargetResponse[TargetActiveId].step[StepActiveId].length
        resetCorrectIncorrectButtons()
      } else if (type === 'Normal-sbtStep') {
        TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId].splice(trialIndex, 1)
        newCount = TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId].length
      } else if (type === 'Coded') {
        if (PeakAutomatic) {
          TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[trialIndex] = {
            ...TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[trialIndex],
            marksRecorded: false,
            response: {
              ...TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[trialIndex].response,
              marks: null,
            },
          }
        } else {
          TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[trialIndex] = {
            ...TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[trialIndex],
            marksRecorded: false,
            response: {},
            recordedData: false,
          }
        }
        resetCodedButtons()
      }

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResetLoading: false,
          TargetResponse,
          Count: newCount + 1,
          PeakTrialCount: trialIndex + 1,
        },
      })
    }
  }

  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetResetLoading: false,
    },
  })
}

export function* SAVE_TARGET_NOTE({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetNoteLoading: true,
    },
  })
  const { targetNote } = payload
  const { TargetResponse, TargetActiveId } = yield select(state => state.sessionrecording)
  // get skillId
  const skillsId = TargetResponse[TargetActiveId].skillsId

  if (skillsId) {
    const response = yield call(updateTargetNote, { skillsId, targetNote })
    if (
      response &&
      response.data.updateSessionTargetNote &&
      response.data.updateSessionTargetNote.details
    ) {
      TargetResponse[TargetActiveId].targetNote = response.data.updateSessionTargetNote.details.note

      yield put({
        type: 'sessionrecording/SET_STATE',
        payload: {
          TargetResponse,
          TargetNoteLoading: false,
        },
      })
    }
  }
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      TargetNoteLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SESSION, GET_DATA),
    takeEvery(actions.GET_CHILD_SESSION_DATA, GET_CHILD_SESSION_DATA),
    takeEvery(actions.START_SESSION, START_SESSION),
    takeEvery(actions.PAUSE_SESSION, PAUSE_SESSION),
    takeEvery(actions.RESUME_SESSION, RESUME_SESSION),
    takeEvery(actions.END_SESSION, END_SESSION),
    takeEvery(actions.TARGET_CORRECT, TARGET_CORRECT),
    takeEvery(actions.TARGET_UPDATE, TARGET_UPDATE),
    takeEvery(actions.CREATE_NEW_TARGET_INSTANCE, CREATE_NEW_TARGET_INSTANCE),
    takeEvery(actions.UPDATE_TARGET_TRIAL, UPDATE_TARGET_TRIAL),
    takeEvery(actions.SET_PREVIOUS_COUNTS, SET_PREVIOUS_COUNTS),
    takeEvery(actions.CHANGE_STIMULUS, CHANGE_STIMULUS),
    takeEvery(actions.TARGET_SD_CORRECT, TARGET_SD_CORRECT),
    takeEvery(actions.UPDATE_TARGET_SD_TRIAL, UPDATE_TARGET_SD_TRIAL),
    takeEvery(actions.CHANGE_STEP, CHANGE_STEP),
    takeEvery(actions.TARGET_STEP_CORRECT, TARGET_STEP_CORRECT),
    takeEvery(actions.UPDATE_TARGET_STEP_TRIAL, UPDATE_TARGET_STEP_TRIAL),
    takeEvery(actions.UPDATE_DURATION, UpdateDuration),
    // PEAK Direct
    takeEvery(actions.UPDATE_BLOCK_ENDTIME, UPDATE_BLOCK_ENDTIME),
    takeEvery(actions.CREATE_NEW_BLOCK, CREATE_NEW_BLOCK),
    takeEvery(actions.RECORD_BLOCK_TRIAL, RECORD_BLOCK_TRIAL),
    takeEvery(actions.UPDATE_BLOCK_TRIAL, UPDATE_BLOCK_TRIAL),
    // PEAK Equivalence
    takeEvery(actions.GET_SD_COMBINATIONS, GET_SD_COMBINATIONS),
    takeEvery(actions.EQUIVALENCE_RESPONSE, EQUIVALENCE_RESPONSE),
    takeEvery(actions.EQUIVALENCE_RESPONSE_UPDATE, EQUIVALENCE_RESPONSE_UPDATE),

    // automatic target move
    takeEvery(actions.MOVE_TO_NEXT_TARGET, MOVE_TO_NEXT_TARGET),
    takeEvery(actions.OPEN_END_SESSION_MODAL, OPEN_END_SESSION_MODAL),

    // Behavior Reduction
    takeEvery(actions.RECORD_SBT_TRIAL, RECORD_SBT_TRIAL),
    takeEvery(actions.UPDATE_SBT_TRIAL, UPDATE_SBT_TRIAL),
    takeEvery(actions.CHANGE_SBT_STEP, CHANGE_SBT_STEP),

    // Behavior Recording
    takeEvery(actions.RECORD_BEHAVIOR_FREQUENCY, RECORD_BEHAVIOR_FREQUENCY),
    takeEvery(actions.UPDATE_BEHAVIOR_FREQUENCY, UPDATE_BEHAVIOR_FREQUENCY),
    takeEvery(actions.DELETE_BEHAVIOR_FREQUENCY, DELETE_BEHAVIOR_FREQUENCY),
    takeEvery(actions.RECORD_BEHAVIOR_TOTAL_TIME, RECORD_BEHAVIOR_TOTAL_TIME),

    takeEvery(actions.GET_TODAYS_SESSION, GET_TODAYS_SESSION),

    // for reset target data
    takeEvery(actions.RESET_TARGET_DATA, RESET_TARGET_DATA),
    takeEvery(actions.RESET_SD_SBT_STEP_DATA, RESET_SD_SBT_STEP_DATA),
    takeEvery(actions.RESET_BLOCK, RESET_BLOCK),
    takeEvery(actions.RESET_TRIAL, RESET_TRIAL),
    takeEvery(actions.SAVE_TARGET_NOTE, SAVE_TARGET_NOTE),
  ])
}
