/* eslint-disable no-plusplus */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { createClaims, editClaims, deleteClaims } from 'services/claim'
import actions from './actions'

export function* CREATE_CLAIM({ payload }) {
  console.log('CREATE_CLAIM')
  console.log(payload)
  const response = yield call(createClaims, payload)
  console.log(response)
  if (response && response.data) {
    notification.success({
      message: 'Claim Created Successfully',
    })
  }
}

export function* EDIT_CLAIM({ payload }) {
  console.log('EDIT_CLAIM')
  console.log(payload)
  const response = yield call(editClaims, payload)
  console.log(response)
  if (response && response.data) {
    notification.success({
      message: 'Claim Updated Successfully',
    })
  }
}

export function* DELETE_CLAIM({ payload }) {
  console.log('DELETE_CLAIM')
  console.log(payload)
  const response = yield call(deleteClaims, payload)
  console.log(response)
  if (response && response.data) {
    notification.success({
      message: 'Claim Delete Successfully',
    })
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_CLAIM, CREATE_CLAIM),
    takeEvery(actions.EDIT_CLAIM, EDIT_CLAIM),
    takeEvery(actions.DELETE_CLAIM, DELETE_CLAIM),
  ])
}
