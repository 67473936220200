import actions from './actions'

const initialState = {
  isTicketsLoading: false,
  tickets: [],
  isTicketSaving: false,
  selectedTicket: null,
  isTicketDrawer: false,

  // pagination
  ticketCount: 800,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',
}

export default function ticketsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.REPLACE_TICKET: {
      return {
        ...state,
        selectedTicket: null,
        isTicketDrawer: false,
        tickets: [
          ...state.tickets.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    case actions.REPLACE_CLOSE_TICKET:
      return {
        ...state,
        tickets: [
          ...state.tickets.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { ...item, isActive: action.payload.data.isActive }
            }
            return item
          }),
        ],
      }
    default:
      return state
  }
}
