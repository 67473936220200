/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */

import React, { useEffect, useState } from 'react'
import { Button, Col, Form, notification, Row, Select, Switch, Tooltip } from 'antd'
import { useMutation } from 'react-apollo'
import { SEARCHABLE_DROPDOWN_OPTIONS } from 'utilities'
import moment from 'moment'
import LoadingComponent from 'components/LoadingComponent'
import { COLORS } from 'assets/styles/globalStyles'
import { PeakLabels, NormalTargetLabels } from 'constants/RecordingLabels'
import AntdTag from './antdTag'
import MasterySequence from './MasterySequence'
import StudentCard from './FormCards/studentCard'
import AttachmentsCard from './FormCards/attachmentsCard'
import MiscInfoCard from './FormCards/miscInfoCard'
import NumberCard from './FormCards/numberCard'
import ClassCard from './FormCards/classCard'
import PhaseCard from './FormCards/phaseCard'
import StgCard from './FormCards/stgCard'
import PromptFieldCard from './FormCards/promptFieldCard'
import DynamicSdStep from './FormCards/dynamicSdStep'
import SubmitButtonCard from './FormCards/submitButtonCard'
import { CREATE_TARGET } from './query'
import { useTargetAlloc } from './context'
import LabelBlock from './LabelBlock'
import '../style.scss'

const { Option } = Select

const dateFormat = 'YYYY-MM-DD'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

function capitalizeFirstLetter(string) {
  if (string) {
    string = string?.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return ''
}

function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

// activeBehave = 'normal'
export default Form.create()(props => {
  const {
    form,
    selectedTargetId = null,
    targetName,
    defaultDomain,
    targetVideo,
    targetInstr,
    selectedTargetCategory = null,
    peakEnable = false,
    equivalenceEnable = false,
    equivalenceObject = null,
    selectedShortTermGoal,
    editable = false,
    closeDrawer,
    onSuccessTargetAllocation,
    addTargetMode,
    editStudent = false,
    setTargetFormType,
    showBackButton,
  } = props

  const { getFieldValue, getFieldDecorator } = form
  const [dailyTrials, setDailyTrials] = useState(0)
  const [peakBlockCount, setPeakBlockCount] = useState(1)
  const [sessionConsecutiveDays, setSessionConsecutiveDays] = useState(0)
  const [targetInstructions, setTargetInstructions] = useState(
    IsJsonString(targetInstr) ? JSON.parse(targetInstr) : targetInstr,
  )
  const [defaultSettings, setDefaultSettings] = useState(null)

  const [phaseList, setPhaseList] = useState([])

  const [stimulusList, setStimulusList] = useState([])
  const [stepList, setStepList] = useState([])
  const [activeCount, setActiveCount] = useState(0)
  const [inSequence, setInSequence] = useState(true)

  const [classObjects, setClassObjects] = useState([])
  const [classKey, setClassKey] = useState(0)

  const [targetAtt, setTargetAtt] = useState([])
  const [tagArray, setTagArray] = useState([])
  const [isCoded, setIsCoded] = useState(false)
  const [addStepwhenTT, setAddStepwhenTT] = useState(false)
  const [isEditLabels, setIsEditLabels] = useState(false)
  const [targetLabels, setTargetLabels] = useState([])

  const {
    useDefaultGoal,
    selectedStudent,
    targetStatusLoading,
    targetOptions,
    targetOptionsLoading,
    isDomainLoading,
    settingData,
    settingLoading,
    shortGoalLoading,
    mastSeqTemplate,
    manualMastActive,
    defaultShortTermGoalIdForABA,
    setManualMastActive,
    mstSeqObj,
    setMstSeqObj,
    fetchMasteryData,
    profileMstLoading,
    selectedProgram,
    selectedTab,
    targetMasSeq,
  } = useTargetAlloc()

  const [allocateTarget, { loading: allocateTargetLoading }] = useMutation(CREATE_TARGET)

  useEffect(() => {
    if (equivalenceEnable && equivalenceObject) {
      const tempClass = []
      equivalenceObject.classes?.edges?.forEach(({ node }, idx) => {
        let tt = {}
        node.stimuluses?.edges?.forEach((it, index) => {
          tt = {
            ...tt,
            [`stimulus${idx}${it.node.option}`]: it.node.stimulusName,
          }
        })
        tempClass.push({
          id: node.id,
          k: idx,
          name: node.name,
          ...tt,
        })
      })
      setClassKey(tempClass.length)
      setClassObjects(tempClass)
    }
  }, [equivalenceEnable, equivalenceObject])

  useEffect(() => {
    if (settingData && editable === false) {
      if (settingData.getAllocateTargetSettings.edges?.length > 0) {
        setDailyTrials(settingData.getAllocateTargetSettings.edges[0].node.dailyTrials || 5)
        setSessionConsecutiveDays(
          settingData.getAllocateTargetSettings.edges[0].node.consecutiveDays || 25,
        )
        setDefaultSettings({
          dailyTrials: settingData.getAllocateTargetSettings.edges[0].node.dailyTrials,
          consecutiveDays: settingData.getAllocateTargetSettings.edges[0].node.consecutiveDays,
          status: settingData.getAllocateTargetSettings.edges[0].node.status,
          masteryCriteria: settingData.getAllocateTargetSettings.edges[0].node.masteryCriteria,
          targetType: settingData.getAllocateTargetSettings.edges[0].node.targetType,
          promptCodes: settingData.getAllocateTargetSettings.edges[0].node.promptCodes.edges,
        })
      } else {
        setDailyTrials(5)
        setSessionConsecutiveDays(25)
      }
    }
  }, [settingData])

  useEffect(() => {
    const mstId = getFieldValue('masteryCriteria')
    if (mstId && !manualMastActive) {
      fetchMasteryData(mstId)
    }
  }, [getFieldValue('masteryCriteria')])

  useState(() => {
    if (defaultSettings) {
      if (defaultSettings?.targetType?.id === 'VGFyZ2V0RGV0YWlsVHlwZTo1') {
        setAddStepwhenTT(true)
      }
    }
  }, [setDefaultSettings])

  useEffect(() => {
    if (isCoded) {
      setDailyTrials(10)
    }
  }, [setIsCoded, isCoded])

  useEffect(() => {
    if (isEditLabels) {
      if (getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4') {
        setTargetLabels([...PeakLabels])
      } else {
        setTargetLabels([...NormalTargetLabels])
      }
    } else {
      setTargetLabels([])
    }
  }, [isEditLabels])

  const onChangeNumber = (type, num) => {
    if (type === 'sdt') setDailyTrials(num)
    else if (type === 'scd') setSessionConsecutiveDays(num)
    else if (type === 'spbc') {
      setPeakBlockCount(num)
    }
  }

  const alphaList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
  let selectedAlpha = ['A', 'B']
  const initialKeyValue = []

  if (equivalenceObject?.target?.maxSd) {
    selectedAlpha = []
  }

  for (let i = 0; i < equivalenceObject?.target?.maxSd; i += 1) {
    selectedAlpha.push(alphaList[i])
  }

  if (equivalenceObject?.classes?.edges.length > 0) {
    equivalenceObject?.classes?.edges.forEach((item, index) => {
      initialKeyValue.push(index)
    })
  }

  const stepSdResponse = (type, docs) => {
    const tempS = type === 'sd' ? stimulusList : stepList
    const msg = ''
    const resp = tempS.map(st => {
      let manualCriteriaTemplate = null
      if (st.manualMastery.isTargetSpecific && st.manualMastery.isTargetSpecific === true) {
        manualCriteriaTemplate = {
          description: st.manualMastery.description,
          templateType: selectedTab,
          criteriaPhases: st.manualMastery.phaseSet.map((item, idx) => {
            return {
              criteriaType: item.criteriaType,
              prompts: item.prompt,
              autoLabel: item.autoLabel,
              stepNo: idx + 1,
              criteriaPhaseTargets: item.masterySet.map(mp => ({
                calculationType: mp.calculationType,
                percentage: mp.percentage,
                calFrameType: mp.calFrameType,
                durationCalType: mp.durationCalType,
                calFrameDurationType: mp.calFrameDurationType,
                calFrameDurationValue: mp.calFrameDurationValue,
                minimumTrial: mp.minimumTrial,
                progressTo: mp.progressTo,
                phaseChangeLabel: mp.phaseChangeLabel,
                duration: mp.duration,
                frequency: mp.frequency,
              })),
            }
          }),
        }
      }

      st.attachments.forEach(dc => {
        docs.push({
          sd: type === 'sd' ? st.name : '',
          step: type === 'step' ? st.name : '',
          url: dc.url,
        })
      })
      if (st.stimulusUrl) {
        docs.push({
          sd: type === 'sd' ? st.name : '',
          step: type === 'step' ? st.name : '',
          url: st.stimulusUrl,
        })
      }

      return {
        [type]: st.name,
        mastery: null,
        status: st.status,
        prompts: st.prompt,
        manualMastery: [],
        criteriaTemplate: st.manualMastery.isTargetSpecific ? null : st.masteryCriteria,
        manualCriteriaTemplate,
      }
    })

    if (msg) {
      return { status: false, msg, data: resp, docs }
    }
    return { status: true, msg: '', data: resp, docs }
  }

  const handleSubmit = e => {
    if (equivalenceObject?.target?.maxSd) {
      selectedAlpha = []
    }
    for (let i = 0; i < equivalenceObject?.target?.maxSd; i += 1) {
      selectedAlpha.push(alphaList[i])
    }

    e.preventDefault()
    form.validateFieldsAndScroll((error, values) => {
      let validationMessage = null
      const classes = []
      classObjects.forEach(({ k: key }, index) => {
        const sampleStimulus = []
        selectedAlpha.forEach((alpha, alphaIndex) => {
          sampleStimulus.push({
            option: alpha,
            stimulusName: values[`stimulus${key}${alpha}`],
          })
        })
        classes.push({
          name: `Class ${index + 1}`,
          stimuluses: sampleStimulus,
        })
      })

      let targetType = ''
      if (values.type === 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==') {
        targetType = 'behavior'
      } else if (values.type === 'VGFyZ2V0RGV0YWlsVHlwZToxMA==') {
        targetType = 'sbt'
      } else if (values.type === 'VGFyZ2V0RGV0YWlsVHlwZTo4') {
        targetType = 'peak'
      } else if (values.type === 'VGFyZ2V0RGV0YWlsVHlwZTo1') {
        targetType = 'task'
      } else {
        targetType = 'regular'
      }
      let docsResponse = []

      let stimulusResponse =
        targetType === 'task' || values.category === 'Equivalence'
          ? { status: false, msg: '', data: [], docs: [] }
          : stepSdResponse('sd', docsResponse)

      validationMessage = stimulusResponse.msg
      docsResponse = stimulusResponse.docs
      stimulusResponse = stimulusResponse.data

      let stepResponse =
        targetType === 'task'
          ? stepSdResponse('step', docsResponse)
          : { status: false, msg: '', data: [], docs: [] }

      if (!validationMessage) {
        validationMessage = stepResponse.msg
      }
      if (targetType === 'task') {
        docsResponse = stepResponse.docs
      }
      stepResponse = stepResponse.data

      targetAtt?.forEach(item => {
        docsResponse.push({
          sd: '',
          step: '',
          url: item.url,
        })
      })
      if (values.tarUrl) {
        docsResponse.push({
          sd: '',
          step: '',
          url: values.tarUrl,
        })
      }

      const phaseResponse = phaseList.map(item => ({
        date: item.date.format(dateFormat),
        content: item.note,
      }))

      let manualCriteriaTemplate = null
      if (manualMastActive) {
        manualCriteriaTemplate = {
          description: mstSeqObj.description,
          templateType: selectedTab,
          criteriaPhases: mstSeqObj.phaseSet.map((item, idx) => {
            return {
              criteriaType: item.criteriaType,
              prompts: item.prompt,
              autoLabel: item.autoLabel,
              stepNo: idx + 1,
              criteriaPhaseTargets: item.masterySet.map(mp => ({
                calculationType: mp.calculationType,
                percentage: mp.percentage,
                calFrameType: mp.calFrameType,
                durationCalType: mp.durationCalType,
                calFrameDurationType: mp.calFrameDurationType,
                calFrameDurationValue: mp.calFrameDurationValue,
                minimumTrial: mp.minimumTrial,
                progressTo: mp.progressTo,
                phaseChangeLabel: mp.phaseChangeLabel,
                duration: mp.duration,
                frequency: mp.frequency,
              })),
            }
          }),
        }
      }

      if (!error) {
        // Other custom validations
        if (useDefaultGoal && !defaultShortTermGoalIdForABA) {
          validationMessage = 'No default short term goal found.'
        } else if (!selectedStudent?.id) {
          validationMessage = 'Student not found, Please try again.'
        } else if (addTargetMode !== 'manually' && !selectedTargetId) {
          validationMessage = 'Target not found.'
        } else if (peakBlockCount < 0) {
          validationMessage = 'Peak block count should be greater than 0'
        } else if (dailyTrials < 0) {
          validationMessage = 'Daily trials should be greater than or equal to 0'
        } else if (!values.masteryCriteria && !manualCriteriaTemplate) {
          validationMessage = 'Please select mastery criteria or provide manual mastery condition'
        } else if (
          targetType === 'peak' &&
          values.category === 'Equivalence' &&
          classes.length === 0
        ) {
          validationMessage = 'At least one class is required for type PEAK with Relational.'
        } else if (
          targetType === 'peak' &&
          stimulusResponse.length === 0 &&
          values.category !== 'Equivalence'
        ) {
          validationMessage =
            'At least one Stimulus required for type Coded with Generalization/Direct.'
        } else if (targetType === 'task' && stepResponse.length === 0) {
          validationMessage = 'At least one step is required for type Task Analysis.'
        }
        // else if (!targetInstructions) {
        //   validationMessage = 'Target Instruction is mandatory.'
        // }
        if (validationMessage) {
          notification.info({ message: validationMessage })
        } else {
          allocateTarget({
            variables: {
              studentId: selectedStudent.id,
              programArea: selectedProgram,
              shortTerm: useDefaultGoal ? defaultShortTermGoalIdForABA : values.stg,
              targetId: selectedTargetId,
              targetStatus: values.status,
              targetInstr: targetInstructions ? targetInstructions : '',
              date: moment(values.validFrom).format('YYYY-MM-DD'),
              masteryCriteria: null,
              targetName: values.name,
              consecutiveDays: sessionConsecutiveDays,
              targetType: values.type,
              sd: stimulusResponse,
              steps: stepResponse,
              video: values.video ? [values.video] : [],
              peakBlocks: targetType === 'peak' ? peakBlockCount : 0,
              peakType:
                targetType === 'peak' && values.category !== 'Equivalence'
                  ? 'Direct'
                  : values.category || equivalenceEnable
                  ? 'Equivalence'
                  : values.category,
              equiCode:
                targetType === 'peak' && values.category === 'Equivalence' ? values.equiCode : null,
              domain: values.domain,
              makeDefault: values.makeDefault,
              dailyTrials,
              classes: targetType === 'peak' && values.category === 'Equivalence' ? classes : [],
              targetDocs: docsResponse,
              prompts: values.prompt,
              phaset: phaseResponse,
              r1: null,
              r2: null,
              sbtSteps: [],
              recordingType: [],
              trialDuration: 0,
              behaviourDescription: null,
              reactiveProcedure: null,
              antecedentManipulation: null,
              emailMasterStatus: values.emailMasterStatus,
              emailSend: values.emailSend,
              tag: tagArray || [],
              sessionAllocation: values.sessions,
              plotZero: values.plotZero,
              caseManagerEmail: values.caseManagerEmail,
              criteriaTemplate: manualMastActive ? null : values.masteryCriteria,
              manualCriteriaTemplate,
              recordingBehavior: null,
              activeSdStepCount: activeCount,
              isSdStepRandomize: !inSequence,
              recordingLabels: targetLabels,
            },
            errorPolicy: 'all',
          })
            .then(res => {
              if (res.data.createTargetAllocate3) {
                notification.success({
                  message: 'Target allocated successfully',
                })
                form.resetFields()
                if (onSuccessTargetAllocation) {
                  onSuccessTargetAllocation()
                }
                if (closeDrawer) {
                  closeDrawer()
                }
              }
            })
            .catch(err => console.error(err))
        }
      }
    })
  }

  const handleTargetLable = labelObj => {
    const tempLabel = targetLabels?.filter(item => item.value !== labelObj.value)
    setTargetLabels([...tempLabel, labelObj])
  }

  if (shortGoalLoading || settingLoading || isDomainLoading) {
    return <LoadingComponent />
  }

  const handleTypeChange = e => {
    if (e === 'VGFyZ2V0RGV0YWlsVHlwZTo1') {
      setAddStepwhenTT(true)
    }
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <StudentCard
          setTargetFormType={setTargetFormType}
          showBackButton={showBackButton}
          form={form}
          editStudent={editStudent}
        />
        <div style={boxCard}>
          {/* short goal and domain name */}
          <StgCard
            form={form}
            addTargetMode={addTargetMode}
            targetName={targetName}
            defaultSettings={defaultSettings}
            peakEnable={peakEnable}
            defaultDomain={defaultDomain}
            selectedShortTermGoal={selectedShortTermGoal}
            activeBehave="Regular"
            handleTypeChange={handleTypeChange}
            setIsCoded={setIsCoded}
          />

          {/* Peak */}
          {getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4' || equivalenceEnable ? (
            <Row gutter={[24]}>
              <Col span={!isCoded ? 8 : 12}>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    initialValue: defaultSettings?.status?.id || targetOptions?.targetStatus[5]?.id,
                    rules: [{ required: true, message: 'Please select a target status' }],
                  })(
                    <Select
                      loading={targetOptionsLoading || targetStatusLoading}
                      {...SEARCHABLE_DROPDOWN_OPTIONS}
                    >
                      {targetOptions?.targetStatus
                        .filter(
                          ({ id }) => !(id === 'U3RhdHVzVHlwZTo3' || id === 'U3RhdHVzVHlwZTo2'),
                        )
                        .map(({ id, statusName }) => {
                          return (
                            <Select.Option key={id} value={id}>
                              {statusName}
                            </Select.Option>
                          )
                        })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                {equivalenceEnable ? (
                  <Form.Item label="Category">
                    {getFieldDecorator('category', {
                      initialValue: 'Equivalence',
                      rules: [{ required: true, message: 'Please select a category' }],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        disabled
                        placeholder="Select a category"
                        {...SEARCHABLE_DROPDOWN_OPTIONS}
                      >
                        <Option key="4" value="Equivalence">
                          Relational
                        </Option>
                      </Select>,
                    )}
                  </Form.Item>
                ) : (
                  <>
                    {!isCoded && (
                      <Form.Item label="Category">
                        {getFieldDecorator('category', {
                          initialValue:
                            (selectedTargetCategory &&
                              capitalizeFirstLetter(selectedTargetCategory)) ||
                            'Direct',
                          rules: [{ required: true, message: 'Please select a category' }],
                        })(
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Select a category"
                            {...SEARCHABLE_DROPDOWN_OPTIONS}
                          >
                            <Option key="1" value="Direct">
                              Direct
                            </Option>
                            <Option key="2" value="Generalization">
                              Generalization
                            </Option>
                            <Option key="3" value="Transformation">
                              Transformation
                            </Option>
                            <Option key="4" value="Equivalence">
                              Relational
                            </Option>
                          </Select>,
                        )}
                      </Form.Item>
                    )}
                  </>
                )}
              </Col>
              <Col span={8}>
                <Form.Item label="Tag" name="Tag">
                  <AntdTag
                    changeTagsHandler={e => setTagArray(e)}
                    closeable="true"
                    tagArray={tagArray}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    initialValue: defaultSettings?.status?.id || targetOptions?.targetStatus[5]?.id,
                    rules: [{ required: true, message: 'Please select a target status' }],
                  })(
                    <Select
                      loading={targetOptionsLoading || targetStatusLoading}
                      {...SEARCHABLE_DROPDOWN_OPTIONS}
                    >
                      {targetOptions?.targetStatus
                        .filter(
                          ({ id }) => !(id === 'U3RhdHVzVHlwZTo3' || id === 'U3RhdHVzVHlwZTo2'),
                        )
                        .map(({ id, statusName }) => {
                          return (
                            <Select.Option key={id} value={id}>
                              {statusName}
                            </Select.Option>
                          )
                        })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tag">
                  <AntdTag
                    changeTagsHandler={e => setTagArray(e)}
                    closeable="true"
                    tagArray={tagArray}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* prompt */}
          <PromptFieldCard form={form} defaultSettings={defaultSettings} />
          {getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4' ? (
            <Row gutter={[24]}>
              <Col span={!isCoded ? 8 : 12}>
                <Tooltip placement="right" title="Coded Blocks">
                  <Button style={{ backgroundColor: COLORS.submitBlue, color: '#FFF' }}>i</Button>
                </Tooltip>
                <NumberCard
                  required
                  label="Coded Blocks"
                  number={peakBlockCount}
                  maxValue={100}
                  setNumber={num => onChangeNumber('spbc', num)}
                />
              </Col>
              {!isCoded && (
                <Col span={8}>
                  <NumberCard
                    number={dailyTrials}
                    label="Daily Trials"
                    required
                    form={form}
                    maxValue={999}
                    setNumber={num => onChangeNumber('sdt', num)}
                  />
                  <Tooltip placement="right" title="DailyTrials">
                    <Button style={{ backgroundColor: COLORS.submitBlue, color: '#FFF' }}>i</Button>
                  </Tooltip>
                </Col>
              )}

              <Col span={!isCoded ? 8 : 12}>
                <NumberCard
                  label="Consecutive Days"
                  number={sessionConsecutiveDays}
                  form={form}
                  setNumber={num => onChangeNumber('scd', num)}
                />
              </Col>
            </Row>
          ) : (
            <Row gutter={[24]}>
              <Col span={12}>
                <NumberCard
                  required
                  label="Daily Trials"
                  number={dailyTrials}
                  form={form}
                  setNumber={num => onChangeNumber('sdt', num)}
                />
              </Col>
              <Col span={12}>
                <NumberCard
                  label="Consecutive Days"
                  number={sessionConsecutiveDays}
                  form={form}
                  setNumber={num => onChangeNumber('scd', num)}
                />
              </Col>
            </Row>
          )}
        </div>
        {/* mastery criteria */}
        <div style={boxCard}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              marginBottom: 12,
            }}
          >
            <Form.Item
              label="Mastery Criteria"
              style={{ marginBottom: 0, width: '30%' }}
              name="masteryCriteria"
            >
              {getFieldDecorator('masteryCriteria', {
                initialValue: mastSeqTemplate[0]?.id,
                rules: [
                  { required: !manualMastActive, message: 'Please select a Mastery Criteria' },
                ],
              })(
                <Select
                  disabled={manualMastActive}
                  name="masteryCriteria"
                  loading={targetOptionsLoading}
                  {...SEARCHABLE_DROPDOWN_OPTIONS}
                >
                  {mastSeqTemplate.map(({ id, name }) => {
                    return (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item>
              <Switch
                checkedChildren="Manual Mastery"
                unCheckedChildren="Default Mastery"
                checked={manualMastActive}
                onChange={e => {
                  if (e) {
                    form.resetFields('masteryCriteria')
                  } else if (mastSeqTemplate[0]?.id) {
                    form.setFieldsValue({ masteryCriteria: mastSeqTemplate[0]?.id })
                  }
                  setManualMastActive(e)
                }}
              />
            </Form.Item>
          </div>
          {profileMstLoading ? (
            <LoadingComponent />
          ) : (
            <MasterySequence
              manualMastActive={manualMastActive}
              mstSeqObj={mstSeqObj}
              setMstSeqObj={setMstSeqObj}
            />
          )}
        </div>
        {/* phase cards */}
        <PhaseCard phaseList={phaseList} setPhaseList={setPhaseList} />
        {/* Peak */}
        {getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4' ? (
          getFieldValue('category') === 'Equivalence' ||
          getFieldValue('category') === 'EQUIVALENCE' ? (
            <ClassCard
              setClassKey={setClassKey}
              setClassObjects={setClassObjects}
              classKey={classKey}
              classObjects={classObjects}
              equivalenceObject={equivalenceObject}
              form={form}
            />
          ) : (
            <DynamicSdStep
              stimulusList={stimulusList}
              setStimulusList={setStimulusList}
              form={form}
              sd
              activeCount={activeCount}
              setActiveCount={setActiveCount}
              inSequence={inSequence}
              setInSequence={setInSequence}
            />
          )
        ) : // Task Analysis
        getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo1' ? (
          <DynamicSdStep
            form={form}
            stepList={stepList}
            setStepList={setStepList}
            checkIsStep={addStepwhenTT}
            step
            defaultSettings={defaultSettings}
            activeCount={activeCount}
            setActiveCount={setActiveCount}
            inSequence={inSequence}
            setInSequence={setInSequence}
          />
        ) : (
          <DynamicSdStep
            stimulusList={stimulusList}
            setStimulusList={setStimulusList}
            form={form}
            sd
            activeCount={activeCount}
            setActiveCount={setActiveCount}
            inSequence={inSequence}
            setInSequence={setInSequence}
          />
        )}
        {/* Attachments */}
        <AttachmentsCard form={form} targetAtt={targetAtt} setTargetAtt={setTargetAtt} />
        {/* misc */}
        <MiscInfoCard
          form={form}
          targetVideo={targetVideo}
          targetInstructions={targetInstructions}
          setTargetInstructions={setTargetInstructions}
          activeBehave="Regular"
        />
        {/* Target recording labels */}
        <Switch
          checkedChildren="Recording labels"
          unCheckedChildren="Recording labels"
          onChange={() => setIsEditLabels(!isEditLabels)}
        />

        {isEditLabels && (
          <LabelBlock
            form={form}
            type={getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4' ? 'Peak' : 'Normal'}
            handleTargetLable={handleTargetLable}
          />
        )}
        {/* submit cancel btn */}
        <SubmitButtonCard
          form={form}
          closeDrawer={closeDrawer}
          submitLoading={allocateTargetLoading}
        />
      </Form>
    </div>
  )
})
