import actions from './actions'

const initialState = {
  loading: false,
  currentPage: 'search', // options: 'search', 'searchResult', 'signup', 'otpVerification', 'services', 'document'
  objectDetails: null,
  services: [],
  searchResults: [],
  verifyEmailLoading: false,
  pageInfo: null,
  service: '',
  city: '',
  showOtp: false,
  loadingComments: false,
  comments: [],
  commentsPageInfo: null,
  AllUsers: [],
  verifyEmail: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
