import { Document, Image, Page, PDFViewer, Text, View } from '@react-pdf/renderer'
import React from 'react'
import PdfHeader from './PdfHeader/PdfHeader'

const sectionMain = {
  width: '100%',
  border: '1px solid black',
  color: 'black',
  height: '100%',
}

const section = {
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}

const optionFlexSection = {
  width: '100%',
  display: 'flex',
  color: 'black',
  flexDirection: 'row',
  padding: '5px 8px',
  textAlign: 'left',
}

const dateSection = {
  width: '40%',
  color: 'black',
  fontSize: 12,
  alignSelf: 'flex-start',
  textAlign: 'left',
  fontWeight: '500',
  padding: 2,
}

const GoalsPdf = ({
  data,
  statusselected,
  typeSelected,
  responsivePie,
  responsiveBar,
  tableData,
  pdfList,
}) => {
  const status = statusselected === null ? 'ALL' : statusselected
  const type = typeSelected === '1' ? 'Long Term' : 'Short Term'
  return (
    <>
      <PDFViewer style={{ margin: 'auto', width: '98%', height: '1200px' }}>
        <Document>
          <Page
            size="A4"
            style={{
              flexDirection: 'row',
              backgroundColor: '#fff',
              padding: 10,
            }}
          >
            <View style={{ width: '100%', margin: 'auto' }}>
              <View style={sectionMain}>
                <PdfHeader data={data} type="Goals" />

                <View style={{ ...section, padding: 0 }}>
                  <View
                    style={{
                      width: '60%',
                      height: '100%',
                    }}
                  >
                    <View style={{ ...optionFlexSection, paddingBottom: 0 }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}>Goal Status :</Text>
                      <Text style={{ ...dateSection, width: '60%' }}> {status}</Text>
                    </View>
                    <View style={{ ...optionFlexSection, paddingBottom: 0 }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Goal Type :</Text>
                      <Text style={{ ...dateSection, width: '60%' }}> {type}</Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {pdfList.includes('PieChart') && responsivePie ? (
                    <Image style={{ width: '80%', height: '200px' }} src={responsivePie} />
                  ) : (
                    <Text>{null}</Text>
                  )}
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {pdfList.includes('Histogram') && responsiveBar ? (
                    <Image style={{ width: '80%', height: '200px' }} src={responsiveBar} />
                  ) : (
                    <Text>{null}</Text>
                  )}
                </View>
              </View>
            </View>
          </Page>
          {pdfList.includes('Table') && (
            <Page size="A4">
              <View
                style={{
                  width: '100%',
                  // height: 'auto',
                  border: '1px solid #000',
                  marginTop: '10px',
                  padding: '5px',
                }}
              >
                <View
                  style={{
                    ...section,
                    // height: 'auto',
                    padding: 0,
                    justifyContent: 'space-between',
                    borderBottomColor: '#E8E8E8',
                    borderTop: '1px solid #E8E8E8',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10px',
                      padding: '5px',
                      width: '15%',
                      borderRight: '1px solid #E8E8E8',
                      borderLeft: '1px solid #E8E8E8',
                      textAlign: 'center',
                      fontWeight: 'extrabold',
                    }}
                  >
                    Goal Name
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      padding: '5px',
                      width: '25%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Description
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      padding: '5px',
                      width: '15%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Program Area
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      padding: '5px',
                      width: '13%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Responsibility
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      padding: '5px',
                      width: '12%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Status
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      padding: '5px',
                      width: '10%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Created
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      padding: '5px',
                      width: '10%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    End
                  </Text>
                </View>
                {tableData.map(item => (
                  <View
                    key={item.key}
                    style={{
                      ...section,
                      height: 'auto',
                      padding: '0',
                      justifyContent: 'space-between',
                      borderBottomColor: '#E8E8E8',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '8px',
                        width: '15%',
                        borderRight: '1px solid #E8E8E8',
                        borderLeft: '1px solid #E8E8E8',
                        padding: '5px',
                      }}
                    >
                      {item.goalName}
                    </Text>
                    <Text
                      style={{
                        fontSize: '8px',
                        width: '25%',
                        borderRight: '1px solid #E8E8E8',
                        padding: '5px',
                      }}
                    >
                      {item.description}
                    </Text>
                    <Text
                      style={{
                        fontSize: '8px',
                        width: '15%',
                        borderRight: '1px solid #E8E8E8',
                        padding: '5px',
                      }}
                    >
                      {item.program}
                    </Text>
                    <Text
                      style={{
                        fontSize: '8px',
                        width: '13%',
                        borderRight: '1px solid #E8E8E8',
                        padding: '5px',
                      }}
                    >
                      {item.responsibility}
                    </Text>
                    <Text
                      style={{
                        fontSize: '8px',
                        width: '12%',
                        borderRight: '1px solid #E8E8E8',
                        padding: '5px',
                      }}
                    >
                      {item.status}
                    </Text>
                    <Text
                      style={{
                        fontSize: '8px',
                        width: '10%',
                        borderRight: '1px solid #E8E8E8',
                        padding: '5px',
                      }}
                    >
                      {item.dateInitialted}
                    </Text>
                    <Text
                      style={{
                        fontSize: '8px',
                        width: '10%',
                        borderRight: '1px solid #E8E8E8',
                        padding: '5px',
                      }}
                    >
                      {item.dateEnd}
                    </Text>
                  </View>
                ))}
              </View>
            </Page>
          )}
        </Document>
      </PDFViewer>
    </>
  )
}

export default GoalsPdf
