import Loader from 'components/LayoutComponents/Loader'
import { ConnectedRouter } from 'connected-react-router'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import invCom from 'pages/Invoices'
import React from 'react'
import Loadable from 'react-loadable'
import { Redirect, Route, Switch } from 'react-router-dom'
import PushNotification from 'pages/PushNotification'
import PrintTableInvoice from './pages/allClinicData/printableInvoice'
import clinicDash from './pages/Dashboard/Clinic'
import payorCom from './pages/payor'
import annotationDashBoard from './pages/annotationdashBoard'
import Reportss from './pages/reports'

// import DownloadReport from './pages/IISA/StartAssessment/downloadReport'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Annotation test routes
  {
    path: '/annotation/add-video',
    component: loadable(() => import('pages/AnnotationVideos/AddVideo')),
    exact: true,
  },
  {
    path: '/annotation/upload-video',
    component: loadable(() => import('pages/AnnotationVideos/UploadVideo')),
    exact: true,
  },
  {
    path: '/annotation/annotated-video',
    component: loadable(() => import('pages/AnnotationVideos/AnnotatedVideo')),
    exact: true,
  },

  // System Pages
  {
    path: '/ASD/Assessment',
    component: loadable(() => import('pages/ASD/Assessment')),
    exact: true,
  },
  {
    path: '/ASD/Assessment/list',
    component: loadable(() => import('pages/ASD/AssessmentList')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/signup',
    component: loadable(() => import('pages/user/signup')),
    exact: true,
  },
  {
    path: '/user/phone',
    component: loadable(() => import('pages/user/phone')),
    exact: true,
  },
  {
    path: '/user/forgotpass',
    component: loadable(() => import('pages/user/forgot/changepass')),
    exact: true,
  },
  {
    path: '/user/otpVerification',
    component: loadable(() => import('pages/user/otpVerification')),
    exact: true,
  },

  // Autism Screening
  {
    path: '/screening/qr-code-reader',
    component: loadable(() => import('./pages/screeningData/ScreeningQr')),
  },
  {
    path: '/screening/starter/:clinicId/:couponCode',
    component: loadable(() => import('./pages/screeningData/ScreeningStarter')),
  },
  {
    path: '/autism_screening/:clinicId',
    component: loadable(() => import('pages/screeningData')),
    exact: true,
  },
  {
    path: '/autism_screening/resume/:clinicId/:autismId',
    component: loadable(() => import('pages/screeningData')),
    exact: true,
  },
  {
    path: '/autism_screening/:clinicId/:cogniableId',
    component: loadable(() => import('pages/screeningData')),
    exact: true,
  },
  {
    path: '/autism_screening/result/:clinicId/:learnerId',
    component: loadable(() => import('pages/screeningData/ScreeningResult')),
    exact: true,
  },
  {
    path: '/autism_screening/result/:learnerId',
    component: loadable(() => import('pages/screeningData/ScreeningResult')),
    exact: true,
  },
  {
    path: '/autism_screening',
    component: loadable(() => import('pages/screeningData')),
    exact: true,
  },
  {
    path: '/autism_screening/qrCode/:clinicId',
    component: loadable(() => import('pages/screeningData')),
    exact: true,
  },

  // Practor Screening
  {
    path: '/cogniAble_Practo',
    component: loadable(() => import('pages/practo/Index')),
    exact: true,
  },
  {
    path: '/query-registration',
    component: loadable(() => import('pages/MarketingModal')),
    exact: true,
  },
  // Dashboards
  {
    path: '/dashboard/clinic_admin',
    // component: loadable(() => import('pages/Dashboard/Clinic')),
    component: clinicDash,
    exact: true,
  },
  {
    path: '/dashboard/therapist_admin',
    component: loadable(() => import('pages/Dashboard/Therapist')),
    exact: true,
  },
  {
    path: '/dashboard/alpha/',
    component: loadable(() => import('pages/Dashboard/Parent')),
    exact: true,
  },
  // Partners
  {
    path: '/partners/viewlearners',
    component: loadable(() => import('pages/learners/view_learners')),
    // component: Learners,
    exact: true,
  },
  {
    path: '/partners/viewlearners/:id',
    component: loadable(() => import('pages/learners/UpdateProfile/UpdateProfile')),
    exact: true,
  },
  {
    path: '/partners/addlearners',
    component: loadable(() => import('pages/learners/createLearner')),
    // component: Learners,
    exact: true,
  },
  // Enquiry
  {
    path: '/enquiry/:id',
    component: loadable(() => import('pages/enquiry/CreateEnquiry')),
  },
  {
    path: '/enquiries',
    component: loadable(() => import('pages/enquiry/getEnquiry')),
  },
  // Add LEADS HERE
  {
    path: '/partners/viewleads',
    component: loadable(() => import('pages/leads/view_leads')),
    exact: true,
  },
  // The expense component.
  // {
  //   path: '/partners/viewexpenses',
  //   component: loadable(() => import('pages/expenses/view_expense')),
  //   exact: true,
  // },
  // The assets component
  // {
  //   path: '/partners/viewasset',
  //   component: loadable(() => import('pages/assets_pages/view_asset')),
  //   exact: true,
  // },
  {
    path: '/partners/viewstaffs',
    component: loadable(() => import('pages/staffs/view_staffs')),
    exact: true,
  },
  {
    path: '/partners/addstaff',
    component: loadable(() => import('pages/staffs/createStaff')),
    exact: true,
  },
  {
    path: '/partners/viewstaffs/:id',
    component: loadable(() => import('pages/staffs/view_staffs/UpdateProfile/UpdateProfile')),
    exact: true,
  },
  {
    path: '/partners/staffMenuManagement',
    component: loadable(() => import('pages/StaffMenu')),
    exact: true,
  },

  // {
  //   path: '/screenningData',
  //   component: loadable(() => import('pages/screenAssessment')),
  //   exact: true,
  // },
  {
    path: '/screenningReport',
    component: loadable(() => import('pages/screeningReport')),
    exact: true,
  },
  {
    path: '/target/allocation',
    component: loadable(() => import('pages/ProgramBook')),
    exact: true,
  },
  // Course

  {
    path: '/appointmentData/',
    component: loadable(() => import('pages/appointmentdata/index')),
    // component: AppData,
    exact: true,
  },

  {
    path: '/family/',
    component: loadable(() => import('views/family/NEWtry')),
    exact: true,
  },

  // Tutorials videos
  {
    path: '/tutorials/step1',
    component: loadable(() => import('pages/tutorials/step1')),
    exact: true,
  },

  // clinic specific video tutorials
  {
    path: '/clinicTutorial/step1',
    component: loadable(() => import('pages/clinicTutorial/step3')),
    exact: true,
  },
  {
    path: '/clinicTutorial/step2',
    component: loadable(() => import('pages/clinicTutorial/step2')),
    exact: true,
  },

  // PEAK
  {
    path: '/genAssessment/',
    component: loadable(() => import('pages/GeneralAssessment')),
    exact: true,
  },
  {
    // path: '/customAssessment',
    path: '/recordAssessment',
    component: loadable(() => import('pages/CustomTemplateAssessments')),
  },
  {
    path: '/customAssessment/starter/:templateID/:clinicID/',
    component: loadable(() =>
      import('pages/CustomTemplateAssessments/containers/RecordAssessment/Starter'),
    ),
    // exact: true,
  },
  {
    path: '/customAssessment/otherUser/:templateID/:studentAssessment/',
    component: loadable(() =>
      import('pages/CustomTemplateAssessments/containers/RecordAssessment/Starter'),
    ),
    // exact: true,
  },
  {
    path: '/customAssessment/record/:templateID/:assessmentID/',
    component: loadable(() =>
      import('pages/CustomTemplateAssessments/containers/RecordAssessment/RecordAssessment'),
    ),
    // exact: true,
  },
  // {
  //   path: '/recordAssessment/',
  //   component: loadable(() => import('pages/RecordAssessment/')),
  //   exact: true,
  // },
  // {
  //   path: '/recordAssessment/report/:assessmentId/:studentAssessmentId',
  //   component: loadable(() => import('pages/RecordAssessment/report')),
  //   exact: true,
  // },
  // {
  //   path: '/recordAssessment/:assessmentId',
  //   component: loadable(() => import('pages/RecordAssessment/assessmentList1')),
  //   exact: true,
  // },
  // {
  //   path: '/recordAssessment/:assessmentId/:studentAssessmentId',
  //   component: loadable(() => import('pages/RecordAssessment/assessmentList1')),
  //   exact: true,
  // },
  // {
  //   path: '/recordAssessment/new/:assessmentId',
  //   component: loadable(() => import('pages/RecordAssessment/new')),
  //   exact: true,
  // },
  // {
  //   path: '/recordAssessment/module/:activeAssessment/:moduleId',
  //   component: loadable(() => import('pages/RecordAssessment/modulePage')),
  //   exact: true,
  // },

  // staff

  // clinic
  // {
  //   path: '/clinicProfile',
  //   component: loadable(() => import('pages/ClinicProfile')),
  //   exact: true,
  // },

  // community, doctors & commitments(need UI Improvement)
  // {
  //   path: '/parent/community',
  //   component: loadable(() => import('pages/community')),
  //   exact: true,
  // },
  {
    path: '/chat',
    component: loadable(() => import('pages/chat')),
    exact: true,
  },
  {
    path: '/doctor',
    component: loadable(() => import('pages/doctor')),
    exact: true,
  },

  // Assessments

  {
    path: '/developmentalScrening/starter/:clinicId',
    component: loadable(() => import('pages/cogniableAssessment/StartAssessment')),
    exact: true,
  },
  {
    path: '/developmentalScrening',
    component: loadable(() => import('pages/cogniableAssessment/StartAssessment')),
    exact: true,
  },
  {
    path: '/developmentalScrening/start',
    component: loadable(() => import('pages/cogniableAssessment/leftArea')),
    exact: true,
  },
  {
    path: '/developmentalScreening/start',
    component: loadable(() => import('pages/cogniableAssessment/leftArea')),
    exact: true,
  },
  {
    path: '/developmentalScrening/start/:clinicId',
    component: loadable(() => import('pages/cogniableAssessment/leftArea')),
    exact: true,
  },
  {
    path: '/developmentalScrening/start/:clinicId/:studentId',
    component: loadable(() => import('pages/cogniableAssessment/leftArea')),
    exact: true,
  },

  {
    path: '/clinic-curriculum',
    component: loadable(() => import('pages/ClinicCurriculum')),
    exact: true,
  },
  {
    path: '/targetsAllocationToSession/',
    component: loadable(() => import('pages/Sessions')),
    // component: loadable(() => import('pages/LearnersProgram/urlSessions')),
    exact: true,
  },
  {
    path: '/sessionrecording',
    component: loadable(() => import('pages/SessionDataRecordingNew')),
    exact: true,
  },
  {
    path: '/sessionsummary',
    component: loadable(() => import('pages/session_summary')),
    exact: true,
  },

  {
    path: '/viewTask',
    component: loadable(() => import('pages/Tasks/view_Task')),
    // component: tastCom,
    exact: true,
  },
  {
    path: '/viewTickets',
    component: loadable(() => import('pages/Tickets')),
    // component: tastCom,
    exact: true,
  },
  {
    path: '/addCoupon',
    component: loadable(() => import('pages/Coupon')),
    // component: tastCom,
    exact: true,
  },

  {
    path: '/screening-reports',
    component: loadable(() => import('pages/screeningReport/SuperAdminReports')),
    exact: true,
  },

  {
    path: '/marketing-reports',
    component: loadable(() => import('pages/MarketingModal/ApproveUser')),
    exact: true,
  },

  {
    path: '/invoices',
    component: invCom,
    exact: true,
  },

  {
    path: '/printInvoice/',
    component: PrintTableInvoice,
    exact: true,
  },

  // Therapist Urls
  // Program
  // {
  //   path: '/therapistStudent',
  //   component: loadable(() => import('pages/tharepist_students')),
  //   exact: true,
  // },
  // need to check for both path /therapistStudent and /LearnerAssessments
  {
    path: '/therapistStudent',
    component: loadable(() => import('pages/LearnersProgram')),
    exact: true,
  },

  {
    path: '/LearnerAssessments',
    component: loadable(() => import('pages/LearnersProgram/urlAssessments')),
    exact: true,
  },
  {
    path: '/LearnerMand',
    component: loadable(() => import('pages/tharepist_students/urlMand')),
    exact: true,
  },
  {
    path: '/LearnerMeal',
    component: loadable(() => import('pages/tharepist_students/urlMeal')),
    exact: true,
  },
  {
    path: '/LearnerMedical',
    component: loadable(() => import('pages/tharepist_students/urlMedical')),
    exact: true,
  },
  {
    path: '/LearnerBehavior',
    component: loadable(() => import('pages/BehaviourData')),
    exact: true,
  },
  {
    path: '/behavior_recording/:id',
    component: loadable(() => import('pages/BehaviorRecording/index')),
    exact: true,
  },
  {
    path: '/LearnerABC',
    component: loadable(() => import('pages/abcData')),
    exact: true,
  },
  {
    path: '/LearnerToilet',
    component: loadable(() => import('pages/tharepist_students/urlToilet')),
    exact: true,
  },
  // Program Daily Vitals
  {
    path: '/therapistStudentDailyVitals',
    component: loadable(() => import('pages/tharepist_students/ProgramDailyVitals')),
    exact: true,
  },
  // Program Sessions
  // {
  //   path: '/therapistStudentSessions',
  //   component: loadable(() => import('pages/tharepist_students/ProgramSession')),
  //   exact: true,
  // },

  {
    path: '/workdone',
    component: loadable(() => import('pages/WorkDone/index')),
  },

  // VB-MAPP urls
  {
    path: '/therapy/vbmapps/new',
    component: loadable(() => import('pages/vbmapps/new')),
    exact: true,
  },
  {
    path: '/therapy/vbmapps/list',
    component: loadable(() => import('pages/vbmapps')),
    exact: true,
  },
  {
    path: '/therapy/vbmapps/milestonesGroups',
    component: loadable(() => import('pages/vbmapps/RecordingArea')),
    exact: true,
  },
  {
    path: '/therapy/vbmapps/barriersGroups',
    component: loadable(() => import('pages/vbmapps/RecordingArea')),
    exact: true,
  },
  {
    path: '/therapy/vbmapps/eesaGroups',
    component: loadable(() => import('pages/vbmapps/RecordingArea')),
    exact: true,
  },
  {
    path: '/therapy/vbmapps/taskGroups',
    component: loadable(() => import('pages/vbmapps/RecordingArea')),
    exact: true,
  },
  {
    path: '/therapy/vbmapps/transitionGroups',
    component: loadable(() => import('pages/vbmapps/RecordingArea')),
    exact: true,
  },
  // ASSESSMENT NEW urls

  {
    path: '/prefereditems',
    component: loadable(() => import('pages/prefereditems')),
    exact: true,
  },

  //  ///////////
  {
    path: [
      '/reports',
      '/reports/progress_overview',
      '/reports/daily_res_rate',
      '/reports/behavior',
      '/reports/mand',
      '/reports/sessions',
      '/reports/goals',
      '/reports/celer_chart',
      '/reports/appointment_report',
      '/reports/staff_activity',
      '/reports/attendance',
      '/reports/attendance2',
      '/reports/timesheet',
      '/reports/timesheet2',
      '/reports/worklog',
      '/reports/monthly_report',
      '/reports/vbmapp',
      '/reports/peak_block_report',
      '/reports/target_res_report',
      '/reports/network_graph',
      '/reports/sankey_graph',
      '/reports/iep_report',
    ],
    // component: loadable(() => import('pages/reports/index')),
    component: Reportss,
    exact: true,
  },
  // {
  //   path: '/therapist/timesheet',
  //   component: loadable(() => import('pages/reports/')),
  //   exact: true,
  // },
  {
    path: '/sessionTrack',
    component: loadable(() => import('pages/SessionTrack')),
    exact: true,
  },
  {
    path: '/activitylog',
    component: loadable(() => import('pages/activity/ActivityList')),
    exact: true,
  },
  {
    path: '/leaveRequests',
    component: loadable(() => import('pages/activity/LeaveRequests')),
    exact: true,
  },
  {
    path: '/leaveReport',
    component: loadable(() => import('pages/activity/LeaveReport')),
    exact: true,
  },
  {
    path: '/therapist/activitylog',
    component: loadable(() => import('pages/activity/TherapistActivityList')),
    exact: true,
  },
  {
    path: '/allClinicData',
    component: loadable(() => import('pages/allClinicData')),
    exact: true,
  },
  {
    path: '/clinicSubscription',
    component: loadable(() => import('pages/allClinicData/clinicSubscription')),
    exact: true,
  },
  // Payors
  {
    path: '/payors',
    // component: loadable(() => import('pages/payor')),
    component: payorCom,
    exact: true,
  },
  {
    path: '/claim/view_claim',
    // component: loadable(() => import('pages/Claims/ClaimsReport')),
    component: loadable(() => import('pages/Claims/claimsComp')),
    // component: claimCom,
    exact: true,
  },
  {
    path: '/insurance-reports',
    component: loadable(() => import('pages/Claims/ClaimsReport')),
    exact: true,
  },
  // authorization
  {
    path: '/authorizations',
    component: loadable(() => import('pages/authorization')),
  },
  // Service codes
  {
    path: '/authorization-codes/*',
    component: loadable(() => import('pages/authorizationCodes')),
    exact: true,
  },
  {
    path: '/clinicSupportTicket',
    component: loadable(() => import('pages/support_ticket/index')),
    exact: true,
  },
  {
    path: '/clinicInvoice',
    component: loadable(() => import('pages/ClinicProfile/Invoices')),
    exact: true,
  },
  {
    path: '/clinicVideoTutorials',
    component: loadable(() => import('pages/ClinicProfile/VideoTutorial')),
    exact: true,
  },
  {
    path: '/book-appointment',
    component: loadable(() => import('pages/BookAppointment')),
    exact: true,
  },
  {
    path: '/appointments',
    component: loadable(() => import('pages/appointmentForTherapist')),
    exact: true,
  },
  {
    path: '/prescription',
    component: loadable(() => import('pages/Prescription')),
    exact: true,
  },
  {
    path: '/staffLeave',
    component: loadable(() => import('pages/StaffLeave')),
    exact: true,
  },
  {
    path: '/iisaAssessment',
    component: loadable(() => import('pages/IISA')),
    exact: true,
  },
  {
    path: '/startIISA',
    component: loadable(() => import('pages/IISA/StartAssessment')),
    exact: true,
  },
  {
    path: '/startIisaAssessment',
    component: loadable(() => import('pages/IISA/StartAssessment/start')),
    exact: true,
  },
  {
    path: '/upload',
    component: loadable(() => import('pages/documentUpload')),
    exact: true,
  },
  {
    path: '/exceluploads',
    component: loadable(() => import('pages/excel_uploads')),
    exact: true,
  },
  {
    path: '/visionModalUploads',
    component: loadable(() => import('pages/visionModalUpload')),
    exact: true,
  },
  {
    path: '/attendance',
    component: loadable(() => import('pages/therapistAttendance/attendance')),
    exact: true,
  },
  {
    path: '/mljob/assessments',
    component: loadable(() => import('pages/MLJob/Assessments')),
    exact: true,
  },
  {
    path: '/mljob/recommendation',
    component: loadable(() => import('pages/MLJob/recommendation')),
  },
  {
    path: '/mljob/graphModal',
    component: loadable(() => import('pages/MLJob/GraphModal')),
    exact: true,
  },

  {
    path: '/intervention/IISCAQuestionaire',
    component: loadable(() => import('pages/IISCAQuestionaire')),
    exact: true,
  },
  {
    path: '/intervention/IISCAQuestionaire/IISCAAssign/:id',
    component: loadable(() => import('pages/IISCAQuestionaire/IISCAAssign')),
    exact: true,
  },
  {
    path: '/ACT',
    component: loadable(() => import('pages/ACT')),
    exact: true,
  },
  // {
  //   path: '/forms',
  //   component: loadable(() => import('pages/Forms')),
  //   exact: true,
  // },
  {
    path: '/reportforms',
    component: loadable(() => import('pages/ReportForm')),
    exact: true,
  },
  {
    path: '/templates',
    component: loadable(() => import('pages/templates')),
    exact: true,
  },
  // Annotation DashBoard
  {
    path: '/annotationdashboard',
    component: annotationDashBoard,
    // exact: true,
  },
  {
    path: '/edit_annotation/:id',
    component: annotationDashBoard,
    // exact: true,
  },
  {
    path: '/courseWork',
    component: loadable(() => import('pages/courseWork')),
    exact: true,
  },
  {
    path: '/publicCourse',
    component: loadable(() => import('pages/coursePublic')),
    exact: true,
  },
  {
    path: '/publicCourse/user/:courseId',
    component: loadable(() => import('pages/coursePublic/courseDetails')),
    exact: true,
  },
  {
    path: '/publicCourse/:courseId',
    component: loadable(() => import('pages/coursePublic/courseDetails')),
    exact: true,
  },
  {
    path: '/push_notification',
    component: PushNotification,
  },
  // Course work
  {
    path: '/course-work/dashboard',
    component: loadable(() => import('pages/CourseContent')),
    exact: true,
  },
  {
    path: '/course-work/:courseId',
    component: loadable(() => import('pages/CourseContent/Coursework')),
    exact: true,
  },
  {
    path: '/learner-attendance',
    component: loadable(() => import('pages/LearnerAttendance')),
    exact: true,
  },
  {
    path: '/learner-attendance-report',
    component: loadable(() => import('pages/reports/learnerAttendanceReport')),
    exact: true,
  },
  {
    path: '/therapist-attendance',
    component: loadable(() => import('pages/TherapistAttendanceByClinic')),
    exact: true,
  },
  {
    path: '/clinic-dashboard',
    component: loadable(() => import('pages/Dashboard/Clinic/DashboardTab')),
    exact: true,
  },
]

const Router = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/user/login" />} />
          {routes.map(route => (
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          ))}
          {/* <Route component={} path='/Registration/' /> */}
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
    </ConnectedRouter>
  )
}

export default Router
