import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Drawer, Menu } from 'antd'
import Scrollbar from 'react-custom-scrollbars'
import { FilterOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import HeaderComponent from 'components/HeaderComponentNew'
import CustomProfileLayout from '../../pages/Dashboard/Clinic/CustomLayout'
import Assessments from '../../pages/LearnersProgram/LearnerAssessments'
import LearnerSelect from '../LearnerSelect'
import PeakAutomaticTab from './PeakAutomaticTab'
import LearnerGoals from '../../pages/LearnersProgram/LearnerGoals'

const WrapperDiv = styled.div`
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`
const tabs = [
  {
    key: 'VBMAPP',
    title: 'VBMAPP',
    component: <Assessments />,
    // iconName: <ProfileOutlined />,
  },
  {
    key: 'Program-Areas',
    title: 'Program Areas',
    component: <LearnerGoals />,
    // iconName: <ProfileOutlined />,
  },
]

const AssessmentsTab = props => {
  const dispatch = useDispatch()
  const selectedLearnerId = useSelector(state => state.learnersprogram.SelectedLearnerId)
  const learners = useSelector(state => state.learnersprogram.Learners)
  const user = useSelector(state => state.user)
  const student = useSelector(state => state.student)
  const [studentName, setStudentName] = useState('')
  const [visibleFilter, setVisibleFilter] = useState(false)

  const [rightComponent, setRightComponent] = useState(<Assessments />)
  const [panelHeading, setPanelHeading] = useState('VBMAPP')

  useEffect(() => {
    dispatch({
      type: 'learnersprogram/LOAD_DATA',
    })
  }, [])

  useEffect(() => {
    if (learners.length !== 0 && student.StudentName.length === 0) {
      let selectedStu
      if (localStorage.getItem('studentId')) {
        const stuId = JSON.parse(localStorage.getItem('studentId'))
        selectedStu = learners.filter(item => item.node.id === stuId)
      } else {
        // eslint-disable-next-line prefer-destructuring
        selectedStu = learners
      }
      setStudentName(selectedStu[0].node.firstname)
    }
  }, [learners])

  const handleMenuChange = item => {
    const selectedItem = tabs.find(tab => tab.key === item.key)
    setPanelHeading(selectedItem.title)
    if (selectedItem) setRightComponent(selectedItem.component)
  }

  return (
    <>
      <CustomProfileLayout
        leftContent={
          <>
            <Menu
              style={{ height: 'calc(100vh - 175px)' }}
              onSelect={handleMenuChange}
              mode="inline"
              defaultSelectedKeys={['VBMAPP']}
            >
              {tabs.map(tab => {
                if (!tab.submenu) {
                  return (
                    <Menu.Item key={tab.key}>
                      {/* {tab.iconName} */}
                      <span>{tab.title}</span>
                    </Menu.Item>
                  )
                }
                return (
                  <Menu.SubMenu
                    title={
                      <span>
                        {tab.iconName}
                        <span>{tab.title}</span>
                      </span>
                    }
                    key={tab.key}
                    onTitleClick={() => handleMenuChange({ key: tab.key })}
                  >
                    {tab.submenu.map(label => (
                      <Menu.Item key={label[0]}>
                        {label[1]} <span>{label[0]}</span>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                )
              })}
            </Menu>
          </>
        }
        rightContent={
          <>
            <HeaderComponent
              title={panelHeading}
              showHomePath={false}
              learnerCard
              pathList={['Assessments']}
            />
            <div style={{ minHeight: 360, margin: '0 10px' }}>
              <Scrollbar style={{ height: 'calc(100vh - 150px)' }}>
                <div>{rightComponent}</div>
              </Scrollbar>
            </div>
          </>
        }
      />
      {/* <WrapperDiv>
        <PeakAutomaticTab />
      </WrapperDiv>
      <WrapperDiv>
        <LearnerGoals />
      </WrapperDiv> */}
    </>
  )
}
export default AssessmentsTab
