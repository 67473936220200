/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable radix */
/* eslint-disable no-plusplus */

import React, { useState, useEffect } from 'react'
import { notification, Empty } from 'antd'
import { ResponsiveBar } from '@nivo/bar'
import { useQuery } from 'react-apollo'
import groupObj from '@hunters/group-object'
import moment from 'moment'
import { CREATE_BARCHART } from './query'

const BarChart = props => {
  const { start_date, end_date, selectedprogram, domainSelected, statusselected, domainId } = props

  const [GraphData, setGraphData] = useState([])
  const [barKeys, setBarKeys] = useState([])
  const studentId = localStorage.getItem('studentId')
  const { data, loading, error, refetch } = useQuery(CREATE_BARCHART, {
    fetchPolicy: 'network-only',
    variables: {
      studentId,
      start_date,
      end_date,
      selectedprogram,
      statusselected,
      activeDomain: true,
      domainId,
    },
  })

  useEffect(() => {
    if (data) {
      const baseline = 'U3RhdHVzVHlwZTox'
      const intherapy = 'U3RhdHVzVHlwZToz'
      const mastered = 'U3RhdHVzVHlwZTo0'
      const inmaintainence = 'U3RhdHVzVHlwZTo1'

      const domainData = []
      data.domainMastered.target.map(item => {
        return domainData.push({
          domain: item.targetId ? item.targetId.domain.domain : item.manualAllocateDomain.domain,
          target: item.targetAllcatedDetails.targetName,
          dateBaseline: item.targetAllcatedDetails.dateBaseline,
          intherapyDate: item.intherapyDate,
          inmaintainence: item.inmaintainenceDate,
          masterDate: item.masterDate,
        })
      })

      let groupedData = groupObj.group(domainData, 'domain')
      let domains = Object.keys(groupedData)
      if (domainSelected) {
        domains = []
        domains.push(domainSelected)
      }
      let gData = []

      // Graph for Baseline targets
      if (statusselected === baseline) {
        for (let i = 0; i < domains.length; i++) {
          const domainStr = domains[i]
          let count = 0
          let length = 0
          if (groupedData[domains[i]]) {
            for (let j = 0; j < groupedData[domains[i]].length; j++) {
              let dateBaseline = moment(groupedData[domains[i]][j].dateBaseline)
              let currentDate = moment()
              let diff = parseInt((currentDate - dateBaseline) / (1000 * 3600 * 24))
              count += diff
              length += 1
            }
          }
          gData.push({
            id: domainStr,

            [domainStr]: parseInt((count / length).toFixed()),
          })
        }
        let tt = []
        tt = gData.map(item => item.id)
        setBarKeys(tt)
        setGraphData(gData)
      }
      // Graph for Intherapy Targets
      if (statusselected === intherapy) {
        for (let i = 0; i < domains.length; i++) {
          const domainStr = domains[i]
          let count = 0
          let length = 0
          if (groupedData[domains[i]]) {
            for (let j = 0; j < groupedData[domains[i]].length; j++) {
              let desiredDate = groupedData[domains[i]][j].intherapyDate
              if (desiredDate) {
                desiredDate = moment(desiredDate)
              } else {
                desiredDate = moment(groupedData[domains[i]][j].dateBaseline)
              }
              let currentDate = moment()
              let diff = parseInt((currentDate - desiredDate) / (1000 * 3600 * 24))
              count += diff
              length += 1
            }
          }
          gData.push({
            id: domainStr,

            [domainStr]: parseInt((count / length).toFixed()),
          })
        }
        let tt = []
        tt = gData.map(item => item.id)

        setBarKeys(tt)
        setGraphData(gData)
      }
      // Graph for inmaintainence Targets
      if (statusselected === inmaintainence) {
        for (let i = 0; i < domains.length; i++) {
          let domainStr = domains[i]
          let count = 0
          let length = 0
          if (groupedData[domains[i]]) {
            for (let j = 0; j < groupedData[domains[i]].length; j++) {
              let desiredDate = groupedData[domains[i]][j].inmaintainence
              if (desiredDate) {
                desiredDate = moment(desiredDate)
              } else {
                desiredDate = moment(groupedData[domains[i]][j].dateBaseline)
              }
              let currentDate = moment()
              let diff = parseInt((currentDate - desiredDate) / (1000 * 3600 * 24))
              count += diff
              length += 1
            }
          }
          gData.push({
            id: domainStr,

            [domainStr]: parseInt((count / length).toFixed()),
          })
        }
        let tt = []
        tt = gData.map(item => item.id)
        setBarKeys(tt)
        setGraphData(gData)
      }
      // Graph for Mastered Targets
      if (statusselected === mastered) {
        for (let i = 0; i < domains.length; i++) {
          let domainStr = domains[i]
          let count = 0
          let length = 0
          if (groupedData[domains[i]]) {
            for (let j = 0; j < groupedData[domains[i]].length; j++) {
              let desiredDate = groupedData[domains[i]][j].masterDate
              if (desiredDate) {
                desiredDate = moment(desiredDate)
              } else {
                desiredDate = moment(groupedData[domains[i]][j].dateBaseline)
              }
              let currentDate = moment()
              let diff = parseInt((currentDate - desiredDate) / (1000 * 3600 * 24))
              count += diff
              length += 1
            }
          }
          gData.push({
            id: domainStr,

            [domainStr]: parseInt((count / length).toFixed()),
          })
        }
        let tt = []
        tt = gData.map(item => item.id)
        setBarKeys(tt)
        setGraphData(gData)
      }
    }
  }, [data])

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      {GraphData && GraphData.length === 0 ? (
        <Empty style={{ marginTop: '100px' }} />
      ) : (
        <ResponsiveBar
          data={GraphData}
          keys={barKeys}
          margin={{ top: 20, right: 10, bottom: 60, left: 60 }}
          padding={0.15}
          colors={{ scheme: 'paired' }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
          animate
          motionStiffness={90}
          motionDamping={15}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -19,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          tooltip={({ id, value }) => (
            <>
              <strong style={{ color: 'black' }}>
                {id}:{'  '}
              </strong>
              <span style={{ color: '#6b7280' }}>{value}</span>
            </>
          )}
          theme={{
            tooltip: {
              container: {
                background: '#fff',
              },
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Number of days',
            legendPosition: 'middle',
            legendOffset: -45,
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
        />
      )}
    </div>
  )
}

export default BarChart
