import React from 'react'
import TaskGraph from './TaskGraph'

const TaskAnalysisTab = ({ data, onSelectionChange, refetch, areaId, selectedAssessment }) => (
  <>
    <div className="taskTab" id="taskAnalysis">
      <TaskGraph
        level1={data.level1}
        level2={data.level2}
        level3={data.level3}
        data={data}
        onSelectionChange={id => onSelectionChange(id)}
        refetch={() => refetch()}
        areaId={areaId}
        selectedAssessment={selectedAssessment}
      />
    </div>
  </>
)

export default TaskAnalysisTab
