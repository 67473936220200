/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getClinics(payload) {
  return apolloClient
    .query({
      query: gql`
        query clinicAllDetails(
          $first: Int
          $isActive: Boolean
          $offset: Int
          $email: String
          $schoolName: String
          $contactNo: String
        ) {
          allClinics(
            schoolName_Icontains: $schoolName
            contactNo_Icontains: $contactNo
            email_Icontains: $email
            isActive: $isActive
          ) {
            totalCount
          }
          clinicAllDetails(
            isActive: $isActive
            first: $first
            offset: $offset
            schoolName: $schoolName
            email: $email
            contactNo: $contactNo
          ) {
            invoice
            totalLearners
            activeLearners
            lastMonthActiveLearners
            researchParticipent
            activeDays
            peak
            vbmapp
            cogniable
            lastLogin
            status
            details {
              id
              schoolName
              isActive
              email
              contactNo
              contactNo2
              address
              user {
                id
                firstName
                isActive
              }
              country {
                id
                name
                isActive
                dbName
              }
              currency {
                id
                currency
                symbol
              }
              staffSet {
                edges {
                  node {
                    id
                    name
                    surname
                    contactNo
                    email
                    dateOfJoining
                    gender
                    user {
                      id
                      lastLogin
                    }
                    dob
                    localAddress
                    isActive
                    designation
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function updateClinic(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation UpdateSchool($pk: ID!, $isActive: Boolean) {
          updateSchool(input: { pk: $pk, isActive: $isActive }) {
            details {
              id
              schoolName
              isActive
              user {
                id
                isActive
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getClinicLearners(payload) {
  return apolloClient
    .query({
      query: gql`
        query getLearners(
          $schoolId: ID!
          $isActive: Boolean
          $first: Int
          $last: Int
          $after: String
          $before: String
          $firstname: String
          $lastname: String
          $email: String
        ) {
          students(
            school: $schoolId
            isActive: $isActive
            first: $first
            last: $last
            after: $after
            before: $before
            firstname_Icontains: $firstname
            lastname_Icontains: $lastname
            email_Icontains: $email
          ) {
            clinicTotal
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                firstname
                lastname
                dob
                email
                mobileno
                parentMobile
                gender
                isActive
                admissionDate
                createdAt
                isPeakActive
                isCogActive
                researchParticipant
                parent {
                  id
                  dateJoined
                  lastLogin
                }
                assessmentCharges {
                  edges {
                    node {
                      id
                      date
                      assessType
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}
