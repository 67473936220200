const actions = {
  SET_STATE: 'payors/SET_STATE',
  GET_PAYORS: 'payors/GET_PAYORS',
  GET_FILTERED_PAYORS: 'payors/GET_FILTERED_PAYORS',
  GET_PAYOR_CONTACT_TYPE: 'payors/GET_PAYOR_CONTACT_TYPE',
  APPEND_PAYORS_LIST: 'payors/APPEND_PAYORS_LIST',
  UPDATE_PAYORS_LIST: 'payors/UPDATE_PAYORS_LIST',
  CREATE_PAYOR: 'payors/CREATE_PAYOR',
  EDIT_PAYOR: 'payors/EDIT_PAYOR',
  PAYOR_ACTIVE_INACTIVE: 'payors/PAYOR_ACTIVE_INACTIVE',
  SET_PAYOR_CREATED: 'payors/SET_PAYOR_CREATED',
  SET_PAYOR_EDITED: 'payors/SET_PAYOR_EDITED',
  UPDATE_PAYOR_ACTIVE_INACTIVE: 'payors/UPDATE_PAYOR_ACTIVE_INACTIVE',
}

export default actions
