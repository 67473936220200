import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import {
  Button,
  Card,
  Input,
  List,
  Modal,
  Skeleton,
  Form,
  Popconfirm,
  notification,
  Switch,
  Radio,
  Tag,
} from 'antd'
import { useSelector } from 'react-redux'

import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import LoadingComponent from 'components/LoadingComponent'
import CreateCenterForm from './CreateCenterForm'
import { CLINIC_MENTAL_HEALTH_CENTER, ACTIVE_INACTIVE_CENTER } from './query'

const SMHPCenter = ({ form }) => {
  const [editDrawer, setEditDrawer] = useState(false)
  const [editableObj, setEditableObj] = useState(null)
  const [createDrawer, setCreateDrawer] = useState(false)
  const [isActiveCenter, setIsActiveCenter] = useState(true)
  const { id: clinicID } = useSelector(state => state.school)

  const { data, loading, refetch } = useQuery(CLINIC_MENTAL_HEALTH_CENTER, {
    variables: { isActive: isActiveCenter, clinicID },
    fetchPolicy: 'network-only',
  })
  const [activateCenter] = useMutation(ACTIVE_INACTIVE_CENTER)

  useEffect(() => {
    refetch()
  }, [isActiveCenter])

  const activationHandler = (id, isActive) => {
    activateCenter({
      variables: { centerID: id, isActive },
    })
      .then(res => {
        notification.success({
          message: 'Updated Successfully',
        })
        refetch()
      })
      .catch(err => {
        notification.error({
          message: 'Something went wrong.',
        })
      })
  }

  const onCreateSuccess = () => {
    setCreateDrawer(false)
    refetch()
  }

  const onEditDrawerClose = () => {
    setEditDrawer(false)
    setEditableObj(null)
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'center',
        }}
      >
        <Radio.Group
          size="medium"
          buttonStyle="solid"
          value={isActiveCenter ? 'active' : 'in-active'}
          style={{ margin: 'auto 10px' }}
          onChange={() => {
            setIsActiveCenter(!isActiveCenter)
          }}
        >
          <Radio.Button value="active">Active</Radio.Button>
          <Radio.Button value="in-active">In-active</Radio.Button>
        </Radio.Group>
        <Button
          type="primary"
          style={{ backgroundColor: '#0B35B3' }}
          onClick={() => setCreateDrawer(true)}
        >
          Add Center
          <PlusOutlined />
        </Button>
      </div>
      {!loading && (
        <Card style={{ marginTop: 20 }}>
          <List
            style={{ zoom: 1.1 }}
            size="small"
            loading={loading}
            itemLayout="horizontal"
            dataSource={data?.getClinicMentalHealthCenter}
            renderItem={item => (
              <List.Item
                key={item.id}
                actions={[
                  item.isDefault && <Tag color="green">Default</Tag>,
                  <Button
                    type="link"
                    onClick={() => {
                      setEditDrawer(true)
                      setEditableObj(item)
                    }}
                  >
                    <EditOutlined />
                  </Button>,
                  <Popconfirm
                    title={`Are you sure to ${
                      item.isActive ? 'inactivate' : 'activate'
                    } this Center?`}
                    onConfirm={() => activationHandler(item.id, !item.isActive)}
                    okText="Yes"
                    cancelText="No"
                    disabled={item.isDefault}
                  >
                    <Switch checked={item.isActive} disabled={item.isDefault} />
                  </Popconfirm>,
                ]}
              >
                <Skeleton avatar title={false} loading={loading} active>
                  <List.Item.Meta title={`${item.centerName} - ${item.centerCode}`} />
                </Skeleton>
              </List.Item>
            )}
          />
        </Card>
      )}

      <Modal
        title="Edit Center"
        width={600}
        visible={editDrawer}
        onCancel={onEditDrawerClose}
        footer={null}
      >
        <div>
          <CreateCenterForm center={editableObj} onSuccess={onEditDrawerClose} />
        </div>
      </Modal>
      <Modal
        title="Add New Center"
        width={600}
        onCancel={() => setCreateDrawer(false)}
        visible={createDrawer}
        footer={null}
      >
        <CreateCenterForm onSuccess={onCreateSuccess} />
      </Modal>
    </div>
  )
}

export default Form.create()(SMHPCenter)
