/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
import { notification } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import apolloClient from '../../apollo/config'
import {
  GET_ALL_ASSESSMENT_TEMPLATES,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  CREATE_TEMPLATE_ASSESSMENT,
  DELETE_TEMPLATE_ASSESSMENT,
  CREATE_MODULE,
  DELETE_MODULE,
  EDIT_MODULE,
  CREATE_SUBMODULE,
  CREATE_QUESTION,
  DELETE_QUESTION,
  EDIT_QUESTION,
  GET_ASSESSMENT_RESPONSES,
  SAVE_ASSESSMENT_RESPONSE,
  GET_PAST_ASSESSMENTS_RESULTS,
  GET_ALL_SUPER_ASSESSMENT_TEMPLATES,
  ASSIGN_TEMPLETE_TO_CLINICS,
  GENERATE_QR_CODE_FOR_CLINIC,
  CREATE_STUDENT_ASSESSMENT_OBJECT,
  GET_ASSESSMENT_BY_ID,
  GET_ASSESSMENT_RECORDS,
  GET_ASSESSMENT_TYPE,
  SAVE_CONSENT,
  UPDATE_STATUS,
  ADD_PARENT_INFO,
  SEND_EMAIL_ON_PAUSE,
  ASSIGN_TEMPLETE_TO_STAFFS,
  ASSIGN_RECORDING_TO_THERAPIST,
  CENTERES,
  EDIT_TEMPLATE,
  GET_ASSESSMENT_DETAILS,
  GET_TEACHER_COUNT,
  GET_PARENT_COUNT,
  GET_LANGUAGE,
} from './query.js'

export async function getAllTemplates(payload) {
  return apolloClient
    .query({
      query: GET_ALL_ASSESSMENT_TEMPLATES,
      variables: {
        clinicId: payload?.clinicId,
        therapistId: payload?.therapistId,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => mapTemplates(result.data.allClinicAssessments))
    .catch(err => console.log(err))
}

export async function getAllSuperTemplates() {
  return apolloClient
    .query({
      query: GET_ALL_SUPER_ASSESSMENT_TEMPLATES,
      fetchPolicy: 'network-only',
    })
    .then(result => mapTemplates2(result.data.allSuperuserAssessments))
    .catch(err => console.log(err))
}

export async function saveAssignClinics(payload) {
  return apolloClient
    .mutate({
      mutation: ASSIGN_TEMPLETE_TO_CLINICS,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      if (payload.isActive) {
        notification.success({
          message: 'Template assigned successfully!',
        })
      }
      return result
    })
    .catch(err => console.log(err))
}

export async function saveAssignStaffs(payload) {
  return apolloClient
    .mutate({
      mutation: ASSIGN_TEMPLETE_TO_STAFFS,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      notification.success({
        message: 'Template successfully assigned to Therapists!',
      })
      return result.data.assignTemplateRecordingToStaff
    })
    .catch(err => console.log(err))
}

export async function createTemplate(payload) {
  return apolloClient
    .mutate({
      mutation: CREATE_TEMPLATE,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      notification.success({
        message: 'Template added successfully!',
      })
      return result
    })
    .catch(err => console.log(err))
}

export async function editTemplate(payload) {
  return apolloClient
    .mutate({
      mutation: EDIT_TEMPLATE,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      notification.success({
        message: 'Template edited successfully!',
      })
      return result
    })
    .catch(err => console.log(err))
}

export async function deleteTemplate(payload) {
  return apolloClient
    .mutate({
      mutation: DELETE_TEMPLATE,
      variables: {
        id: payload,
      },
    })
    .then(result => {
      notification.success({
        message: 'Template deleted successfully',
      })
      return result
    })
    .catch(err => console.log(err))
}

export async function createTemplateAssessment(payload) {
  return apolloClient
    .mutate({
      mutation: CREATE_TEMPLATE_ASSESSMENT,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      notification.success({
        message: 'Assessment added successfully',
      })
      return result.data.studentAssessmentCreateUpdateMutation.studentAssessment
    })
    .catch(err => console.log(err))
}

export async function deleteTemplateAssessment(payload) {
  return apolloClient
    .mutate({
      mutation: DELETE_TEMPLATE_ASSESSMENT,
      variables: {
        id: payload.id,
      },
    })
    .then(result => {
      notification.success({
        message: 'Assessment deleted successfully',
      })
      return result.data
    })
    .catch(err => console.log(err))
}

export async function createModule(payload) {
  return apolloClient
    .mutate({
      mutation: CREATE_MODULE,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      notification.success({
        message: 'Module Created Successfully',
      })
      return result.data.SubModuleCreateMutation.submodule
    })
    .catch(err => console.log(err))
}

export async function deleteModule(payload) {
  return apolloClient
    .mutate({
      mutation: DELETE_MODULE,
      variables: {
        moduleID: payload.moduleID,
      },
    })
    .then(result => {
      notification.success({
        message: 'Module Deleted Successfully',
      })
      return result.data.SubModuleDeleteMutation
    })
    .catch(err => console.log(err))
}

export async function editModule(payload) {
  return apolloClient
    .mutate({
      mutation: EDIT_MODULE,
      variables: {
        id: payload.moduleID,
        title: payload.title,
        description: payload.description,
        moduleType: payload.moduleType,
      },
    })
    .then(result => {
      notification.success({
        message: 'Module Edited Successfully',
      })
      return result.data.SubModuleUpdateMutation.submodule
    })
    .catch(err => console.log(err))
}

export async function createSubModule(payload) {
  return apolloClient
    .mutate({
      mutation: CREATE_SUBMODULE,
      variables: {
        moduleID: payload.moduleID,
        title: payload.title,
        description: payload.description,
        subModuleType: payload.subModuleType,
      },
    })
    .then(result => {
      notification.success({
        message: 'SubModule Created Successfully',
      })
      return result.data.SubModuleCreateMutation.submodule
    })
    .catch(err => console.log(err))
}

export async function deleteSubModule(payload) {
  return apolloClient
    .mutate({
      mutation: DELETE_MODULE,
      variables: {
        moduleID: payload.submoduleID,
      },
    })
    .then(result => {
      notification.success({
        message: 'SubModule Deleted Successfully',
      })
      return result.data.SubModuleDeleteMutation
    })
    .catch(err => console.log(err))
}

export async function editSubModule(payload) {
  return apolloClient
    .mutate({
      mutation: EDIT_MODULE,
      variables: {
        id: payload.submoduleID,
        title: payload.title,
        description: payload.description,
        subModuleType: payload.subModuleType,
      },
    })
    .then(result => {
      notification.success({
        message: 'SubModule Edited Successfully',
      })
      return result.data.SubModuleUpdateMutation.submodule
    })
    .catch(err => console.log(err))
}

export async function createQuestion(payload) {
  return apolloClient
    .mutate({
      mutation: CREATE_QUESTION,
      variables: {
        submoduleID: payload.addQuestionTo.id,
        title: payload.title,
        text: payload.text,
        options: payload.options,
        allowText: payload.allowText,
        questionNo: payload.questionNo,
        optionalQuestionText: payload.optionalQuestionText,
      },
    })
    .then(result => {
      notification.success({
        message: 'Question Created Successfully',
      })
      return result.data.QuestionCreateMutation.question
    })
    .catch(err => console.log(err))
}

export async function deleteQuestion(payload) {
  return apolloClient
    .mutate({
      mutation: DELETE_QUESTION,
      variables: {
        questionID: payload.questionID,
      },
    })
    .then(result => {
      notification.success({
        message: 'Question Deleted Successfully',
      })
      return result.data.QuestionDeleteMutation
    })
    .catch(err => console.log(err))
}

export async function editQuestion(payload) {
  return apolloClient
    .mutate({
      mutation: EDIT_QUESTION,
      variables: {
        questionId: payload.questionID,
        title: payload.title,
        text: payload.text,
        options: payload.options,
        allowText: payload.allowText,
        questionNo: payload.questionNo,
        optionalQuestionText: payload.optionalQuestionText,
      },
    })
    .then(result => {
      notification.success({
        message: 'Question Edited Successfully',
      })
      return result.data.QuestionUpdateMutation.question
    })
    .catch(err => console.log(err))
}

export async function fetchAssessmentResponses(payload) {
  return apolloClient
    .query({
      query: GET_ASSESSMENT_RESPONSES,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data.studentQuestionResponses)
    .catch(err => console.log(err))
}

export async function saveAssessmentResponse(payload) {
  return apolloClient
    .mutate({
      mutation: SAVE_ASSESSMENT_RESPONSE,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.questionResponseCreateUpdateMutation.questionResponse)
    .catch(err => console.log(err))
}

export async function getPastAssessmentResults(payload) {
  return apolloClient
    .query({
      query: GET_PAST_ASSESSMENTS_RESULTS,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.studentLastAssessmentResults)
    .catch(err => console.log(err))
}

export async function generateQRCode(payload) {
  return apolloClient
    .mutate({
      mutation: GENERATE_QR_CODE_FOR_CLINIC,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      if (result.data.generateCustomAssessmentQr.status) {
        notification.success({
          message: 'QR Code Generated Successfully',
        })
      }
      return result.data.generateCustomAssessmentQr
    })
    .catch(err => console.log(err))
}

export async function createStudentAssessmentObject(payload) {
  return apolloClient
    .mutate({
      mutation: CREATE_STUDENT_ASSESSMENT_OBJECT,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      if (result.data.createCustomAssessment) {
        notification.success({
          message: 'Assessment Started Successfully',
        })
      }
      return result.data.createCustomAssessment
    })
    .catch(err => console.log(err))
}

// need to update this query
export async function getTemplateById(payload) {
  return apolloClient
    .query({
      query: GET_ASSESSMENT_BY_ID,
      fetchPolicy: 'no-cache',
      variables: {
        assessmentID: payload.assessmentID,
        language: payload.language,
      },
    })
    .then(result => result.data.studentAssessment)
    .catch(err => console.log(err))
}

export async function getTemplateType(payload) {
  return apolloClient
    .query({
      query: GET_ASSESSMENT_TYPE,
      fetchPolicy: 'network-only',
      variables: {
        templateID: payload.templateID,
      },
    })
    .then(result => result.data.allAssessments)
    .catch(err => console.log(err))
}
export async function getTemplateRecords(payload) {
  return apolloClient
    .query({
      query: GET_ASSESSMENT_RECORDS,
      fetchPolicy: 'network-only',
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.studentAssessments)
    .catch(err => console.log(err))
}

export async function saveConsentForm(payload) {
  return apolloClient
    .mutate({
      mutation: SAVE_CONSENT,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.saveConsentForm)
    .catch(err => console.log(err))
}

export async function updateAssessmentStatus(payload) {
  return apolloClient
    .mutate({
      mutation: UPDATE_STATUS,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.updateCustomAssessment)
    .catch(err => console.log(err))
}

export async function addParentInfo(payload) {
  return apolloClient
    .mutate({
      mutation: ADD_PARENT_INFO,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.addParentAssessmentInfo)
    .catch(err => console.log(err))
}

export async function pauseAssessment(payload) {
  return apolloClient
    .mutate({
      mutation: SEND_EMAIL_ON_PAUSE,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.sendEmailForResumeAssessment)
    .catch(err => console.log(err))
}

export async function assignRecordingToTherapist(payload) {
  return apolloClient
    .mutate({
      mutation: ASSIGN_RECORDING_TO_THERAPIST,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data.assignThrapistToSeeRecordings
    })
    .catch(err => console.log(err))
}

export async function getCenters(payload) {
  return apolloClient
    .query({
      query: CENTERES,
      fetchPolicy: 'network-only',
      variables: {
        clinicId: payload.clinicID,
      },
    })
    .then(result => result.data.getClinicMentalHealthCenter)
    .catch(err => console.log(err))
}

export async function getTeacherCount(payload) {
  return apolloClient
    .mutate({
      mutation: GET_TEACHER_COUNT,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.totalTeachersDemographicData.data)
    .catch(err => console.log(err))
}
export async function getParentCount(payload) {
  return apolloClient
    .mutate({
      mutation: GET_PARENT_COUNT,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.totalParentsDemographicDistribution.data)
    .catch(err => console.log(err))
}
export async function getDetails(payload) {
  return apolloClient
    .query({
      query: GET_ASSESSMENT_DETAILS,
      fetchPolicy: 'network-only',
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.allAssessments)
    .catch(err => console.log(err))
}
export async function getLanguage(payload) {
  return apolloClient
    .query({
      query: GET_LANGUAGE,
      fetchPolicy: 'network-only',
      variables: {
        ...payload,
      },
    })
    .then(result => result.data.getCustomassessmentLanguages)
    .catch(err => console.log(err))
}
const mapTemplates = templates => {
  return templates.map(template => {
    const { assessment } = template
    if (template.id) {
      assessment.assignTemplateDetails = {
        id: template.id,
        qrCode: template.qrCode,
        assessUrl: template.assessUrl,
      }
    }
    assessment.showAssessmentTo = template.showAssessmentTo
    // assessment.submoduleSet = assessment.submoduleSet.filter(module => !module.isDeleted)
    // assessment.studentassessmentSet = assessment.studentassessmentSet.filter(
    //   assessment => !assessment.isDeleted,
    // )
    // assessment.studentassessmentSet = sortList(assessment?.studentassessmentSet)

    assessment.filterStudentAssessment = assessment?.filterStudentAssessment?.filter(
      assessment => !assessment.isDeleted,
    )
    assessment.filterStudentAssessment = sortList(assessment?.filterStudentAssessment)

    assessment.submoduleSet = []
    assessment.viewReport = template.viewReport
    console.log('from mapTemplates assessment ===>  ', assessment)
    return assessment
  })
}

const mapTemplates2 = templates => {
  return templates.map(template => {
    template.submoduleSet = []

    template.filterStudentAssessment = template?.filterStudentAssessment?.filter(
      assessment => !assessment.isDeleted,
    )
    template.filterStudentAssessment = sortList(template?.filterStudentAssessment)

    // template.submoduleSet = template.submoduleSet.map(module => {
    //   return {
    //     ...module,
    //     questionSet: mapQuestions(module.questionSet),
    //     submoduleSet: mapSubModules(module.submoduleSet),
    //   }
    // })
    console.log('from mapTemplates template ===>  ', template)
    return template
  })
}

const mapQuestions = questions => {
  return questions.filter(question => !question.isDeleted)
}

const mapSubModules = subModules => {
  subModules = subModules.filter(submodule => !submodule.isDeleted)
  return subModules.map(submodule => {
    return {
      ...submodule,
      questionSet: mapQuestions(submodule.questionSet),
    }
  })
}

const sortList = data => {
  if (!data) return []
  // eslint-disable-next-line new-cap
  return _.sortBy(data, d => new moment(d.createdAt).format('DD.MM.YYYY HH:mm')).reverse()
}
