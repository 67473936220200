/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import {
  Divider,
  Form,
  Button,
  Tooltip,
  Icon,
  Table,
  Row,
  Col,
  Popconfirm,
  Switch,
  Tag,
  Modal,
  Collapse,
  Input,
  Card,
} from 'antd'
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { COLORS } from 'assets/styles/globalStyles'
import Sortable from 'react-sortablejs'
import LoadingComponent from 'components/LoadingComponent'
import { GET_TEMPLATE_BY_ID } from '../query'
import MasterySequence from '../MasterySequence'
import StimulusModal from '../stimulusModal'
import { useTargetAlloc } from '../context'
import SbtStepModal from '../sbtStepModal'
import NumberCard from './numberCard'
import './style.scss'
import RequiredLabel from './requiredLabel'

const { Panel } = Collapse

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const cardStyle = {
  padding: '12px',
  marginBottom: 10,
  boxShadow: '0 3px 5px rgba(22, 22, 53, 0.16)',
  cursor: 'pointer',
}

function DynamicSdStep({
  edit,
  form,
  targetDocs = [],
  stimulusList = [],
  stepList = [],
  sbtStepsList = [],
  setSbtStepsList,
  setStepList,
  setStimulusList,
  sd = false,
  step = false,
  activeCount,
  setActiveCount,
  inSequence,
  setInSequence,
  checkIsStep,
  defaultSettings,
  setDeletedSteps,
  activeMasteryPhase,
  setDeletedStimulus,
  deletedSteps,
  deletedStimulus,
  deletedStimulusOrStepsData,
  setDeletedStimulusOrStepsData,
  deletedAttachments,
  setDeletedAttachments,
}) {
  const dSpan = sd && step ? 6 : 8
  const type = sd && step ? 'both' : sd ? 'sd' : 'step'
  const isDefaulttt =
    defaultSettings && defaultSettings?.targetType?.id === 'VGFyZ2V0RGV0YWlsVHlwZTo1'
  const { getFieldValue } = form
  const [stimulusObject, setStimulusObject] = useState(null)
  const [stepObject, setStepObject] = useState(null)
  const [addStepModal, setAddStepModal] = useState(false)
  const [addstimulusModal, setAddStimulusModal] = useState(false)
  const [sbtStepObject, setSbtStepObject] = useState(null)
  const [addsbtStepModal, setAddsbtStepModal] = useState(false)
  const [isDefaultTypeTask, setIsDefaultTypeTask] = useState(
    defaultSettings && defaultSettings?.targetType?.id === 'VGFyZ2V0RGV0YWlsVHlwZTo1',
  )

  const [masterySeqObj, setMasterySeqObj] = useState({
    description: '',
    isTargetSpecific: true,
    templateType: 'Regular',
    phaseSet: [],
  })

  const {
    mastSeqTemplate,
    promptData,
    targetStatusMapping,
    mstSeqObj,
    targetMasSeq,
    fetchMasteryData,
    from,
    setFrom,
  } = useTargetAlloc()

  const [
    fetchData,
    { data: profileMst, loading: profileMstLoading, error: profileMstError, refetch },
  ] = useLazyQuery(GET_TEMPLATE_BY_ID, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (mastSeqTemplate.length && mastSeqTemplate[0].id) {
      fetchData({
        variables: {
          id: mastSeqTemplate[0].id,
        },
      })
    }
  }, [mastSeqTemplate])

  useEffect(() => {
    if (profileMst && profileMst.getCriteriaTemplate) {
      const { getCriteriaTemplate } = profileMst
      const tempPhaseSet = getCriteriaTemplate.criteriaphasesSet.edges.map(item => {
        const tempMast = item.node.criteriaphasetargetsSet.edges.map(mst => {
          return { ...mst.node, id: null, key: Math.random() }
        })
        return {
          key: Math.random(),
          autoLabel: item.node.autoLabel,
          criteriaType: item.node.criteriaType.id,
          criteriaTypeName: item.node.criteriaType.statusName,
          stepNo: item.node.stepNo,
          prompt: item.node.prompts.edges.map(pr => pr.node.id),
          masterySet: tempMast,
        }
      })
      setMasterySeqObj({
        isTargetSpecific: false,
        description: getCriteriaTemplate.description,
        type: getCriteriaTemplate.templateType,
        phaseSet: tempPhaseSet,
      })
    }
  }, [profileMst])

  const typeOfTarget = form.getFieldValue('type')

  const sdDocCol = [
    {
      title: 'File Name',
      dataIndex: 'url',
      render: url => {
        if (url) {
          const tt = String(url).split('/')
          let name = tt[tt.length - 1]
          name = name.replace(/%/gi, ' ')
          return name
        }
        return ''
      },
    },
    {
      title: 'Action',
      align: 'center',
      render: row => (
        <>
          <Tooltip title="View document">
            <a
              style={{
                padding: '0 14px',
                textAlign: 'center',
                display: 'inline-block',
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={row?.url}
            >
              <EyeOutlined style={{ fontSize: 15, fontWeight: 600, color: COLORS.primary }} />
            </a>
          </Tooltip>
        </>
      ),
    },
  ]

  const [count, setCount] = useState(0)
  const [stepCount, setStepCount] = useState(0)

  const addRemoveItem = (renderArray, setFunction, rTpe, rCount) => {
    let tempL = []
    if (rCount < renderArray.length) {
      tempL = renderArray.splice(0, rCount)
    } else {
      let k = renderArray.reduce((prevVal, currVal) => Math.max(prevVal, currVal.key), 0)
      const diff = rCount - renderArray.length
      tempL = renderArray

      for (let i = 0; i < diff; i += 1) {
        tempL.push({
          key: k + 1,
          attachments: [],
          manualMastery: { ...masterySeqObj, isTargetSpecific: false },
          masteryCriteria: mastSeqTemplate[0]?.id,
          name: '',
          prompt: getFieldValue('prompt'),
          status: 'U3RhdHVzVHlwZTox',
          [rTpe]: '',
          tarUrl: '',
        })
        k += 1
      }
    }
    setFunction([...tempL])
  }

  const handleCount = num => {
    setCount(num)
    if (activeCount > num) {
      setActiveCount(num)
    }
    if ((type === 'both' || type === 'sd') && setStimulusList) {
      if (num) {
        addRemoveItem(stimulusList, setStimulusList, 'sd', num)
      } else if (num === 0) {
        setStimulusList([])
      }
    }
  }

  const handleStepCount = num => {
    setStepCount(num)
    if (activeCount > num) {
      setActiveCount(num)
    }
    if ((type === 'both' || type === 'step') && setStepList) {
      if (num) {
        addRemoveItem(stepList, setStepList, 'step', num)
      } else if (num === 0) {
        setStepList([])
      }
    }
  }

  useEffect(() => {
    if (stimulusList.length !== count) {
      setCount(stimulusList.length)
    }
  }, [stimulusList.length])

  useEffect(() => {
    if (stepList.length !== stepCount) {
      setStepCount(stepList.length)
    }
  }, [stepList.length])

  const handleDeletedAttachments = (attachments, rType, name) => {
    const tempAtt = []
    if (rType === 'sd') {
      attachments.forEach(att => {
        tempAtt.push({
          step: '',
          sd: name,
          url: att.url,
        })
      })
    } else {
      attachments.forEach(att => {
        tempAtt.push({
          step: name,
          sd: '',
          url: att.url,
        })
      })
    }

    setDeletedAttachments([...deletedAttachments, ...tempAtt])
  }

  const renderSdList = (renderArray, setFunction, rType) => (
    <Sortable
      key={Math.random()}
      options={{
        store: {
          set(sortable) {
            let i = 0
            const list = []
            for (i = 0; i < sortable.el.childNodes.length; i += 1) {
              list.push(`${sortable.el.childNodes[i].id}`)
            }

            if (list.length === renderArray.length) {
              const nl = list.map(ke => {
                let temp = {}
                for (let j = 0; j < renderArray.length; j += 1) {
                  if (ke === String(renderArray[j].key)) {
                    temp = renderArray[j]
                  }
                }
                return temp
              })
              setFunction(nl)
            } else {
              setFunction([...renderArray])
            }
          },
        },
      }}
      tag="div"
    >
      {renderArray.map(item => {
        return (
          <div key={item.key} id={item.key} style={cardStyle}>
            <div className="sd-mastery-collapse">
              <Collapse>
                <Panel
                  key="sdSeqPanel"
                  header={
                    <Row
                      onClick={e => e.stopPropagation()}
                      type="flex"
                      justify="space-between"
                      align="middle"
                    >
                      <Col span={21}>
                        <Form.Item
                          label={
                            <RequiredLabel
                              required
                              label={rType === 'sd' ? 'Stimulus Name' : 'Step Name'}
                            />
                          }
                          key={rType === 'sd' ? `stimulus_${item.key}` : `step_${item.key}`}
                        >
                          <Input
                            onBlur={e => {
                              const v = e.target.value
                              handleNameChange(item, setFunction, v, rType)
                            }}
                            disabled={item?.id && edit}
                            required
                            // required={typeOfTarget === 'VGFyZ2V0RGV0YWlsVHlwZTo4'}
                            defaultValue={item.name}
                            placeholder={`Provide ${rType === 'sd' ? 'stimulus' : 'step'} name`}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <div align="right" style={{ minWidth: 70 }}>
                          <Button
                            style={{ color: '#46bd84' }}
                            type="link"
                            onClick={e => {
                              if (rType === 'sd') {
                                setStimulusObject(item)
                                setAddStimulusModal(true)
                              } else {
                                setStepObject(item)
                                setAddStepModal(true)
                              }
                            }}
                          >
                            <EditOutlined />
                          </Button>
                          <Popconfirm
                            title="Are you sure you don't want this record?"
                            onConfirm={() => {
                              setFunction(state => state.filter(ph => ph.key !== item.key))
                              if (rType === 'sd' && count === activeCount) {
                                setActiveCount(activeCount - 1)
                              }
                              if (rType === 'step' && stepCount === activeCount) {
                                setActiveCount(activeCount - 1)
                              }
                              if (item.id) {
                                if (rType === 'sd') {
                                  if (item?.attachments?.length > 0) {
                                    handleDeletedAttachments(item.attachments, rType, item.name)
                                  }
                                  setDeletedStimulus(e => [...e, item.id])
                                } else {
                                  if (item?.attachments?.length > 0) {
                                    handleDeletedAttachments(item.attachments, rType, item.name)
                                  }
                                  setDeletedSteps(e => [...e, item.id])
                                }
                              }
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              style={{ marginLeft: 'auto', color: COLORS.danger }}
                              type="link"
                            >
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </div>
                      </Col>
                    </Row>
                  }
                >
                  <div>
                    <Row gutter={[12, 6]}>
                      <Col span={10}>
                        <div>
                          <b> Status: </b> <span>{targetStatusMapping[item.status]}</span>
                        </div>
                      </Col>
                      <Col span={12}>
                        <b> Prompt: </b>
                        <span>
                          {item?.prompt?.map(pp => {
                            const pName = promptData?.promptCodes.filter(pr => pr.id === pp)[0]
                              ?.promptName
                            if (pName) {
                              return (
                                <Tag color="geekblue" key={pName}>
                                  {pName}
                                </Tag>
                              )
                            }
                            return null
                          })}
                        </span>
                      </Col>
                    </Row>
                    <Row gutter={[0, 6]}>
                      <Col span={22}>
                        <div>
                          <b> Mastery Criteria: </b>
                          <span>
                            {mastSeqTemplate.filter(mt => mt.id === item.masteryCriteria)[0]
                              ?.name || 'Manual Mastery'}
                          </span>
                        </div>
                      </Col>
                    </Row>

                    {profileMstLoading ? (
                      <LoadingComponent />
                    ) : (
                      <MasterySequence
                        showAddCriteriaButton={false}
                        mstSeqObj={item.manualMastery}
                        manualMastActive={false}
                        activeMasteryPhase={item.activeMasteryPhase}
                      />
                    )}
                    {item.attachments && item.attachments.length > 0 && (
                      <div
                        className="mastery-criteria-table"
                        style={{ width: '100%', marginTop: 8 }}
                      >
                        <Table
                          size="small"
                          bordered
                          dataSource={item.attachments}
                          columns={sdDocCol}
                          pagination={false}
                          rowKey={record => record.url}
                        />
                      </div>
                    )}
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        )
      })}
    </Sortable>
  )

  const handleDeleteStimulus = (item, rType) => {
    setActiveCount(activeCount + 1)
    const tt = []
    if (item && rType) {
      targetDocs?.forEach(({ node }) => {
        if (node?.[rType] && node?.[rType]?.[rType] === item?.[rType]?.[rType]) {
          tt.push({ type: rType, key: node.id, url: node.url, id: node.id })
        }
      })
    }
    if (rType === 'sd') {
      const filteredData = deletedStimulusOrStepsData?.filter(({ node }) => node.id !== item.id)
      const filteredDeletedStimulusIds = deletedStimulus?.filter(
        stimulusId => stimulusId !== item.id,
      )

      const tempPrompts = item.prompts.edges?.map(({ node }) => node.id)

      const mmt = item.criteriaTemplate.criteriaphasesSet.edges.map(i => {
        const tempMast = i.node.criteriaphasetargetsSet.edges.map(mst => {
          return { ...mst.node, key: Math.random() }
        })
        return {
          id: i.node.id,
          key: Math.random(),
          autoLabel: i.node.autoLabel,
          criteriaType: i.node.criteriaType.id,
          criteriaTypeName: i.node.criteriaType.statusName,
          stepNo: i.node.stepNo,
          prompt: i.node.prompts.edges.map(pr => pr.node.id),
          masterySet: tempMast,
        }
      })
      setStimulusList([
        ...stimulusList,
        {
          id: item.id,
          key: item.id,
          name: item?.[rType]?.[rType],
          activeMasteryPhase: item.criteriaPhase
            ? { id: item.criteriaPhase.id, stepNo: item.criteriaPhase.stepNo }
            : null,
          manualMastery: {
            isTargetSpecific: item.criteriaTemplate.isSdSpecific,
            id: item.criteriaTemplate.id,
            description: item.criteriaTemplate?.description,
            phaseSet: mmt,
          },
          masteryCriteria: item.criteriaTemplate?.isSdSpecific ? null : item.criteriaTemplate?.id,
          sd: item.sd,
          step: item.step,
          status: item.status?.id,
          attachments: tt,
          behPrompt: item?.behPrompts?.edges || [],
          prompt: tempPrompts || [],
        },
      ])
      setDeletedStimulusOrStepsData(filteredData)
      setDeletedStimulus(filteredDeletedStimulusIds)
    } else if (rType === 'step') {
      const filteredData = deletedStimulusOrStepsData?.filter(({ node }) => node.id !== item.id)
      const filteredDeletedStepsIds = deletedSteps?.filter(stepId => stepId !== item.id)
      const tempPrompts = item.prompts.edges?.map(({ node }) => node.id)
      const mmt = item.criteriaTemplate.criteriaphasesSet.edges.map(i => {
        const tempMast = i.node.criteriaphasetargetsSet.edges.map(mst => {
          return { ...mst.node, key: Math.random() }
        })
        return {
          id: i.node.id,
          key: Math.random(),
          autoLabel: i.node.autoLabel,
          criteriaType: i.node.criteriaType.id,
          criteriaTypeName: i.node.criteriaType.statusName,
          stepNo: i.node.stepNo,
          prompt: i.node.prompts.edges.map(pr => pr.node.id),
          masterySet: tempMast,
        }
      })
      setStepList([
        ...stepList,
        {
          id: item.id,
          key: item.id,
          name: item?.[rType]?.[rType],
          activeMasteryPhase: item.criteriaPhase
            ? { id: item.criteriaPhase.id, stepNo: item.criteriaPhase.stepNo }
            : null,
          manualMastery: {
            isTargetSpecific: item.criteriaTemplate.isStepSpecific,
            id: item.criteriaTemplate.id,
            description: item.criteriaTemplate?.description,
            phaseSet: mmt,
          },
          masteryCriteria: item.criteriaTemplate?.isStepSpecific ? null : item.criteriaTemplate?.id,
          sd: item.sd,
          step: item.step,
          status: item.status?.id,
          attachments: tt,
          behPrompt: item?.behPrompts?.edges || [],
          prompt: tempPrompts || [],
        },
      ])
      setDeletedStimulusOrStepsData(filteredData)
      setDeletedSteps(filteredDeletedStepsIds)
    }
  }

  const renderDeletedSdList = (renderArray, rType) => (
    <div>
      {renderArray?.map(({ node }) => (
        <div key={node.id}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px',
              border: '1px solid #ececec',
            }}
          >
            <p style={{ marginBottom: 0 }}>{node?.[rType]?.[rType]}</p>
            <Button type="link" onClick={() => handleDeleteStimulus(node, rType)}>
              activate
            </Button>
          </div>
        </div>
      ))}
    </div>
  )

  // function debounce(func, timeout = 300) {
  //   let timer
  //   return (...args) => {
  //     clearTimeout(timer)
  //     timer = setTimeout(() => {
  //       func.apply(this, args)
  //     }, timeout)
  //   }
  // }

  // const handleNameChange2 = debounce((item, setFunction, v, rType) => {
  //   setFunction(state =>
  //     state.map(it => {
  //       if (it.key === item.key) {
  //         return { ...it, name: v, [rType]: v }
  //       }
  //       return it
  //     }),
  //   )
  // }, 2000)

  const handleNameChange = (item, setFunction, v, rType) => {
    setFunction(state =>
      state.map(it => {
        if (it.key === item.key) {
          return { ...it, name: v, [rType]: v }
        }
        return it
      }),
    )
  }

  return (
    <div className="misc-switch-boxes">
      {(sd || step) && (
        <div style={boxCard}>
          {type === 'both' && <Divider orientation="left">Stimulus / Step</Divider>}
          {type === 'sd' && <Divider orientation="left">Stimulus</Divider>}
          {type === 'step' && <Divider orientation="left">Step</Divider>}
          <Row gutter={[24]}>
            {(type === 'both' || type === 'sd') && (
              <Col span={dSpan}>
                <NumberCard
                  required={typeOfTarget === 'VGFyZ2V0RGV0YWlsVHlwZTo4'}
                  label="Stimulus Count"
                  number={count}
                  disabled={stepCount > 0}
                  maxValue={100}
                  typeCard="sd"
                  setActiveCount={() => setActiveCount(activeCount + 1)}
                  setNumber={num => handleCount(num)}
                />
              </Col>
            )}
            {(type === 'both' || type === 'step') && (
              <Col span={dSpan}>
                <NumberCard
                  required
                  label="Step Count"
                  number={stepCount}
                  typeCard="step"
                  disabled={count > 0}
                  maxValue={100}
                  setActiveCount={() => setActiveCount(activeCount + 1)}
                  setNumber={num => handleStepCount(num)}
                />
              </Col>
            )}
            <Col span={dSpan}>
              <NumberCard
                required={typeOfTarget === 'VGFyZ2V0RGV0YWlsVHlwZTo4' || type === 'step'}
                label={`Active ${
                  type === 'both'
                    ? stepList.length > 0
                      ? 'Step'
                      : 'Stimulus'
                    : type === 'sd'
                    ? 'Stimulus'
                    : 'Step'
                } Count`}
                number={activeCount}
                maxValue={stepList.length > 0 ? stepCount : count}
                setNumber={num => {
                  if (stepList.length > 0) {
                    if (num <= stepCount) {
                      setActiveCount(num)
                    }
                  } else if (num <= count) {
                    setActiveCount(num)
                  }
                }}
              />
            </Col>
            <Col span={dSpan}>
              <Form.Item label="In Sequence">
                <Switch
                  checked={inSequence}
                  onChange={setInSequence}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                />
              </Form.Item>
            </Col>
          </Row>
          {sd && <div>{renderSdList(stimulusList, setStimulusList, 'sd')}</div>}
          {step && <div>{renderSdList(stepList, setStepList, 'step')}</div>}
          <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
            <Button
              type="dashed"
              onClick={() => {
                if (stepCount > 0 || checkIsStep || isDefaulttt) {
                  setAddStepModal(true)
                  setStepObject(null)
                  if (setStimulusList) {
                    setStimulusList([])
                  }
                } else {
                  setAddStimulusModal(true)
                  setStimulusObject(null)
                  if (setStepList) {
                    setStepList([])
                  }
                }
              }}
              style={{ width: '60%' }}
            >
              <Icon type="plus" /> Add{' '}
              {stepCount > 0 || checkIsStep || isDefaulttt ? 'Step' : 'Stimulus'}
            </Button>
          </Form.Item>
          {deletedStimulusOrStepsData?.length > 0 && (
            <>
              {type === 'both' && <Divider orientation="left">Deleted Stimulus / Step</Divider>}
              {type === 'sd' && <Divider orientation="left">Deleted Stimulus</Divider>}
              {type === 'step' && <Divider orientation="left">Deleted Step</Divider>}
            </>
          )}
          {sd && <div>{renderDeletedSdList(deletedStimulusOrStepsData, 'sd')}</div>}
          {step && <div>{renderDeletedSdList(deletedStimulusOrStepsData, 'step')}</div>}
        </div>
      )}

      <Modal
        visible={addsbtStepModal}
        title={sbtStepObject ? 'Edit Step' : 'Add New Sbt Step'}
        onCancel={() => {
          setAddsbtStepModal(false)
          setSbtStepObject(null)
          setStimulusObject(null)
        }}
        zIndex={1001}
        footer={null}
        width={1000}
        destroyOnClose
        style={{ top: 10 }}
      >
        <SbtStepModal
          type="sbt"
          setStimulusList={setSbtStepsList}
          stimulusList={sbtStepsList}
          stimulusObject={sbtStepObject}
          closeModal={() => {
            setAddsbtStepModal(false)
            setSbtStepObject(null)
            setStimulusObject(null)
          }}
          defaultValues={{
            status: getFieldValue('status'),
            prompt: getFieldValue('prompt'),
            masteryCriteria: getFieldValue('masteryCriteria'),
          }}
        />
      </Modal>

      <Modal
        visible={addstimulusModal}
        title={stimulusObject ? 'Edit Stimulus' : 'Add New Stimulus'}
        onCancel={() => {
          setAddStimulusModal(false)
          setStimulusObject(null)
        }}
        zIndex={1001}
        footer={null}
        width={1000}
        style={{ top: 10 }}
        destroyOnClose
      >
        <StimulusModal
          type="sd"
          setStimulusList={setStimulusList}
          stimulusList={stimulusList}
          stimulusObject={stimulusObject}
          closeModal={() => {
            setAddStimulusModal(false)
            setStimulusObject(null)
          }}
          defaultValues={{
            status: 'U3RhdHVzVHlwZTox',
            prompt: getFieldValue('prompt'),
            masteryCriteria: mastSeqTemplate[0]?.id,
          }}
          setActiveCount={() => setActiveCount(activeCount + 1)}
          activeMasteryPhase={activeMasteryPhase}
        />
      </Modal>

      <Modal
        visible={addStepModal}
        title={stepObject ? 'Edit Step' : 'Add New Step'}
        onCancel={() => {
          setAddStepModal(false)
          setStepObject(null)
        }}
        zIndex={1001}
        footer={null}
        width={1000}
        style={{ top: 10 }}
        destroyOnClose
      >
        <StimulusModal
          type="step"
          setStimulusList={setStepList}
          stimulusList={stepList}
          stimulusObject={stepObject}
          closeModal={() => {
            setAddStepModal(false)
            setStepObject(null)
          }}
          defaultValues={{
            status: 'U3RhdHVzVHlwZTox',
            prompt: getFieldValue('prompt'),
            masteryCriteria: mastSeqTemplate[0]?.id,
          }}
          setActiveCount={() => setActiveCount(activeCount + 1)}
        />
      </Modal>
    </div>
  )
}

export default DynamicSdStep
