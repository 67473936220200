import React from 'react'
import BarrierQuestion from './BarrierQuestion'

const BarriersGraph = ({ questions, scoreDetails }) => (
  <div className="barriersChart">
    {questions.map(question => (
      <BarrierQuestion
        key={question.queNo}
        question={question}
        scoreDetails={scoreDetails.sort((a, b) => {
          return a.testNo - b.testNo
        })}
      />
    ))}
  </div>
)

export default BarriersGraph
