/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-closing-tag-location */

import React from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

const DirectScoreBoard = ({ trails, boxWidth, boxHeight }) => {
  const dispatch = useDispatch()
  const {
    TargetResponse,
    TargetActiveId,
    MasterSession,
    TargetActiveIndex,
    PeakBlockIndex,
  } = useSelector(state => state.sessionrecording)

  const showBlockTrail = (blockIndex, trailIndex) => {
    if (TargetResponse[TargetActiveId].peak[blockIndex]?.block[trailIndex].marksRecorded) {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          PeakBlockIndex: blockIndex,
          PeakTrialCount: trailIndex + 1,
        },
      })
      if (TargetResponse[TargetActiveId].peak[blockIndex].block[trailIndex].response.marks === 0) {
        document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
      }
      if (TargetResponse[TargetActiveId].peak[blockIndex].block[trailIndex].response.marks === 2) {
        document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
      }
      if (TargetResponse[TargetActiveId].peak[blockIndex].block[trailIndex].response.marks === 4) {
        document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
      }
      if (TargetResponse[TargetActiveId].peak[blockIndex].block[trailIndex].response.marks === 8) {
        document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
      }
      if (TargetResponse[TargetActiveId].peak[blockIndex].block[trailIndex].response.marks === 10) {
        document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
      }
    }
  }

  const getBlock = (j, trialsList) => {
    const colorList = []

    for (let k = 0; k < trialsList.length; k++) {
      if (trialsList[k].response?.marks === 0) {
        colorList.push('#FF8080')
      } else if (
        trialsList[k].response?.marks === 2 ||
        trialsList[k].response?.marks === 4 ||
        trialsList[k].response?.marks === 8
      ) {
        colorList.push('#FF9C52')
      } else if (trialsList[k].response?.marks === 10) {
        colorList.push('#4BAEA0')
      } else {
        colorList.push('#c4c4c4')
      }
    }

    const Trials = []
    for (let i = 0; i < trialsList.length; i++) {
      Trials.push(
        <Button key={i} style={{ border: 'none', padding: 0 }} onClick={() => showBlockTrail(j, i)}>
          <div
            style={{
              display: 'inline-block',
              marginRight: '5px',
              textAlign: 'center',
            }}
          >
            <span
              style={{
                height: '15px',
                display: 'inline-block',
                lineHeight: '12px',
                width: boxWidth,
                backgroundColor: colorList[i] ? colorList[i] : '#c4c4c4',
                borderRadius: '2px',
                marginRight: '2px',
              }}
            >
              &nbsp;
            </span>
          </div>
        </Button>,
      )
    }
    return <div style={{ padding: '2px 10px' }}>{Trials}</div>
  }

  const Blocks = []
  Blocks.push(getBlock(PeakBlockIndex, TargetResponse[TargetActiveId].peak[PeakBlockIndex].block))

  return <>{Blocks}</>
}

export default DirectScoreBoard
