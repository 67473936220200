/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-concat */
/* eslint-disable consistent-return */

import React, { Component, useEffect } from 'react'
import { Tabs, Button, Icon, notification, Radio, Spin, Collapse } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Coded } from 'constants/RecordingLabels'
import EquivalenceScoreBoard from './EquivalenceScoreBoard'

const { TabPane } = Tabs
const { Panel } = Collapse
const recordingButtonStyle = {
  padding: '8px auto',
  width: '52px',
  height: '52px',
  backgroundColor: '#EDEDED',
  border: '1px solid #E1E1E1',
  boxSizing: 'border-box',
  borderRadius: '3px',
  marginTop: '10px',
  marginLeft: '5px',
  marginRight: '5px',
}

const RecordingBlock = () => {
  const dispatch = useDispatch()
  const {
    MasterSession,
    TargetActiveIndex,
    ActiveCombination,
    TargetActiveId,
    TargetResponse,
    EquiTrialCount,
    SelectedClassId,
    SelectedOperation,
    EditAfterSessionCompleted,
    SdCombinations,
    ResponseLoading,
  } = useSelector(state => state.sessionrecording)

  useEffect(() => {
    dispatch({
      type: 'sessionrecording/GET_SD_COMBINATIONS',
      payload: {
        code: MasterSession.targets.edges[TargetActiveIndex].node.eqCode
          ? MasterSession.targets.edges[TargetActiveIndex].node.eqCode
          : '1A',
      },
    })

    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        SelectedOperation: 'Train',
        SelectedClassId:
          MasterSession.targets.edges[TargetActiveIndex].node.classes.edges[0]?.node.id,
      },
    })
  }, [])

  const getLabel = label => {
    let temp = label

    const filtered = MasterSession.targets.edges[
      TargetActiveIndex
    ].node.recordingLabels?.edges.filter(
      ({ node }) => node.label.toLowerCase() === label.toLowerCase(),
    )
    if (filtered.length) {
      temp = filtered[0].node.newLabel.length ? filtered[0].node.newLabel : filtered[0].node.label
    }
    return temp
  }

  const updateSessionClockTime = () => {
    // updatig current clock time to store
    document.getElementById('updateSessionCurrentTimeInStore').click()
  }

  const updateStartTrialClockTime = () => {
    // updatig trial start time to store
    document.getElementById('updateStartTrialTimeInStore').click()
  }

  const resetZero = () => {
    const getButton0 = document.getElementById('peakResponseButtonZero')
    if (typeof getButton0 != 'undefined' && getButton0 != null) {
      document.getElementById('peakResponseButtonZero').style.color = 'gray'
      document.getElementById('peakResponseButtonZero').style.backgroundColor = '#e4e9f0'
    }
  }

  const resetTwo = () => {
    const getButton2 = document.getElementById('peakResponseButtonTwo')
    if (typeof getButton2 != 'undefined' && getButton2 != null) {
      document.getElementById('peakResponseButtonTwo').style.color = 'gray'
      document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#e4e9f0'
    }
  }

  const resetFour = () => {
    const getButton4 = document.getElementById('peakResponseButtonFour')
    if (typeof getButton4 != 'undefined' && getButton4 != null) {
      document.getElementById('peakResponseButtonFour').style.color = 'gray'
      document.getElementById('peakResponseButtonFour').style.backgroundColor = '#e4e9f0'
    }
  }

  const resetEight = () => {
    const getButton8 = document.getElementById('peakResponseButtonEight')
    if (typeof getButton8 != 'undefined' && getButton8 != null) {
      document.getElementById('peakResponseButtonEight').style.color = 'gray'
      document.getElementById('peakResponseButtonEight').style.backgroundColor = '#e4e9f0'
    }
  }

  const resetTen = () => {
    const getButton10 = document.getElementById('peakResponseButtonTen')
    if (typeof getButton10 != 'undefined' && getButton10 != null) {
      document.getElementById('peakResponseButtonTen').style.color = 'gray'
      document.getElementById('peakResponseButtonTen').style.backgroundColor = '#e4e9f0'
    }
  }

  const goToNextTrial = () => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    let text = 'train'
    if (SelectedOperation === 'Test') text = 'test'

    if (EquiTrialCount < 10) {
      if (
        EquiTrialCount <=
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId].length
      ) {
        updateStartTrialClockTime()
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            EquiTrialCount: EquiTrialCount + 1,
          },
        })

        // setting button color
        if (
          TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
            EquiTrialCount
          ]?.score === 0
        ) {
          document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
        }
        if (
          TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
            EquiTrialCount
          ]?.score === 2
        ) {
          document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
            EquiTrialCount
          ]?.score === 4
        ) {
          document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
            EquiTrialCount
          ]?.score === 8
        ) {
          document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
            EquiTrialCount
          ]?.score === 10
        ) {
          document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
        }
      } else {
        notification.warning({
          message: 'Warning!!',
          description: 'Record ' + EquiTrialCount + ' Trial first',
        })
      }
    }
  }

  const goToPreviousTrial = () => {
    if (EquiTrialCount > 1) {
      resetZero()
      resetTwo()
      resetFour()
      resetEight()
      resetTen()

      updateStartTrialClockTime()

      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          EquiTrialCount: EquiTrialCount - 1,
        },
      })

      let text = 'train'
      if (SelectedOperation === 'Test') text = 'test'

      // setting button color
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
          EquiTrialCount - 2
        ]?.score === 0
      ) {
        document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
          EquiTrialCount - 2
        ]?.score === 2
      ) {
        document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
          EquiTrialCount - 2
        ]?.score === 4
      ) {
        document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
          EquiTrialCount - 2
        ]?.score === 8
      ) {
        document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][
          EquiTrialCount - 2
        ]?.score === 10
      ) {
        document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
      }
    }
  }

  const responseZero = combId => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()

    updateSessionClockTime()
    document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
    let text = 'train'
    if (SelectedOperation === 'Test') text = 'test'

    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId].length <
        EquiTrialCount
      ) {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 0,
          },
        })
      } else {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE_UPDATE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 0,
            trialRecordedId:
              TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][
                EquiTrialCount - 1
              ]?.id,
          },
        })
      }
    }
  }

  const responseTwo = combId => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    updateSessionClockTime()
    document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'

    let text = 'train'
    if (SelectedOperation === 'Test') text = 'test'
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId].length <
        EquiTrialCount
      ) {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 2,
          },
        })
      } else {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE_UPDATE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 2,
            trialRecordedId:
              TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][
                EquiTrialCount - 1
              ]?.id,
          },
        })
      }
    }
  }

  const responseFour = combId => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    updateSessionClockTime()
    document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
    let text = 'train'
    if (SelectedOperation === 'Test') text = 'test'
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId].length <
        EquiTrialCount
      ) {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 4,
          },
        })
      } else {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE_UPDATE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 4,
            trialRecordedId:
              TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][
                EquiTrialCount - 1
              ]?.id,
          },
        })
      }
    }
  }

  const responseEight = combId => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    updateSessionClockTime()
    document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
    let text = 'train'
    if (SelectedOperation === 'Test') text = 'test'
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId].length <
        EquiTrialCount
      ) {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 8,
          },
        })
      } else {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE_UPDATE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 8,
            trialRecordedId:
              TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][
                EquiTrialCount - 1
              ]?.id,
          },
        })
      }
    }
  }

  const responseTen = combId => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    updateSessionClockTime()
    document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'

    let text = 'train'
    if (SelectedOperation === 'Test') text = 'test'
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId].length <
        EquiTrialCount
      ) {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 10,
          },
        })
      } else {
        dispatch({
          type: 'sessionrecording/EQUIVALENCE_RESPONSE_UPDATE',
          payload: {
            operation: SelectedOperation,
            classId: SelectedClassId,
            combinationTest: SelectedOperation === 'Test' ? combId : '',
            combinationTrain: SelectedOperation === 'Train' ? combId : '',
            response: 10,
            trialRecordedId:
              TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][
                EquiTrialCount - 1
              ]?.id,
          },
        })
      }
    }
  }

  const callback = e => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    updateStartTrialClockTime()

    let text = 'train'
    if (SelectedOperation === 'Test') text = 'test'

    const trialsLength =
      TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][e].length
    if (trialsLength < 10) {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SelectedClassId: e,
          EquiTrialCount: trialsLength + 1,
        },
      })
    } else {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SelectedClassId: e,
          EquiTrialCount: 10,
        },
      })

      // setting button color
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][9]
          ?.score === 0
      ) {
        document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][9]
          ?.score === 2
      ) {
        document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][9]
          ?.score === 4
      ) {
        document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][9]
          ?.score === 8
      ) {
        document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][ActiveCombination][SelectedClassId][9]
          ?.score === 10
      ) {
        document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
      }
    }
  }

  const chnageOperation = type => {
    let text = 'train'
    let comId = ''
    if (type === 'Test') {
      text = 'test'
      comId = SdCombinations.test.edges[0]?.node.id
    } else {
      comId = SdCombinations.train.edges[0]?.node.id
    }

    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    updateStartTrialClockTime()

    const trialsLength =
      TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId]?.length
    if (trialsLength < 10) {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SelectedOperation: type,
          EquiTrialCount: trialsLength + 1,
          ActiveCombination: comId,
        },
      })
    } else {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SelectedOperation: type,
          EquiTrialCount: 10,
          ActiveCombination: comId,
        },
      })

      // setting button color
      if (
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId] &&
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId][9]?.score === 0
      ) {
        document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId] &&
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId][9]?.score === 2
      ) {
        document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId] &&
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId][9]?.score === 4
      ) {
        document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId] &&
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId][9]?.score === 8
      ) {
        document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId] &&
        TargetResponse[TargetActiveId].equivalence[text][comId][SelectedClassId][9]?.score === 10
      ) {
        document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
      }
    }
  }

  const changeCombination = combId => {
    let text = 'train'
    if (SelectedOperation === 'Test') {
      text = 'test'
    }

    if (combId) {
      resetZero()
      resetTwo()
      resetFour()
      resetEight()
      resetTen()
      updateStartTrialClockTime()

      const trialsLength =
        TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId].length
      if (trialsLength < 10) {
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            EquiTrialCount: trialsLength + 1,
            ActiveCombination: combId,
          },
        })
      } else {
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            EquiTrialCount: 10,
            ActiveCombination: combId,
          },
        })

        // setting button color
        if (
          TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][9]?.score === 0
        ) {
          document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
        }
        if (
          TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][9]?.score === 2
        ) {
          document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][9]?.score === 4
        ) {
          document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][9]?.score === 8
        ) {
          document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].equivalence[text][combId][SelectedClassId][9]?.score === 10
        ) {
          document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
        }
      }
    }
  }

  const getFullName = (nodeItem, stimulus) => {
    let finalText = ''
    nodeItem.node.stimuluses.edges.map(edgeNode => {
      if (edgeNode.node.option === stimulus) {
        finalText = edgeNode.node.stimulusName
      }
    })
    return finalText
  }

  const getDescriptions = (nodeItem, index) => {
    if (SelectedOperation === 'Train') {
      const combinations = []
      SdCombinations?.train.edges.map((combinationNode, combinationIndex) => {
        combinations.push({
          id: combinationNode.node.id,
          relation:
            `${combinationNode.node.stimulus1} ` +
            `${combinationNode.node.sign12} ` +
            `${combinationNode.node.stimulus2} ` +
            `${combinationNode.node.sign23 ? combinationNode.node.sign23 : ''} ` +
            `${combinationNode.node.stimulus3 ? combinationNode.node.stimulus3 : ''} `,
          description:
            `${getFullName(nodeItem, combinationNode.node.stimulus1)} ` +
            `${combinationNode.node.sign12} ` +
            `${getFullName(nodeItem, combinationNode.node.stimulus2)} ` +
            `${combinationNode.node.sign23 ? combinationNode.node.sign23 : ''} ` +
            `${
              combinationNode.node.stimulus3
                ? getFullName(nodeItem, combinationNode.node.stimulus3)
                : ''
            } `,
        })
      })

      if (combinations.length > 0) {
        return (
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <Collapse
              accordion
              destroyInactivePanel={true}
              onChange={e => changeCombination(e)}
              activeKey={ActiveCombination}
              key={ActiveCombination}
            >
              {combinations.map(item => (
                <Panel header={item.description} key={item.id} extra={item.relation}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <EquivalenceScoreBoard classIndex={index} />
                    </div>
                    <div style={{ fontSize: 12 }}>
                      {EquiTrialCount <= 1 ? (
                        <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                          <Icon type="left" />
                        </Button>
                      ) : (
                        <Button style={{ border: 'none' }} onClick={goToPreviousTrial}>
                          <Icon type="left" />
                        </Button>
                      )}
                      Trial {EquiTrialCount} / 10
                      {EquiTrialCount >= 10 ? (
                        <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                          <Icon type="right" />
                        </Button>
                      ) : (
                        <Button style={{ border: 'none' }} onClick={goToNextTrial}>
                          <Icon type="right" />
                        </Button>
                      )}
                    </div>
                  </div>
                  {ResponseLoading ? (
                    <Spin>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.ZERO.label)}</Button>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.TWO.label)}</Button>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.FOUR.label)}</Button>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.EIGHT.label)}</Button>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.TEN.label)}</Button>
                    </Spin>
                  ) : (
                    <>
                      <Button
                        id="peakResponseButtonZero"
                        style={recordingButtonStyle}
                        onClick={() => responseZero(item.id)}
                      >
                        {getLabel(Coded.ZERO.label)}
                      </Button>
                      <Button
                        id="peakResponseButtonTwo"
                        style={recordingButtonStyle}
                        onClick={() => responseTwo(item.id)}
                      >
                        {getLabel(Coded.TWO.label)}
                      </Button>
                      <Button
                        id="peakResponseButtonFour"
                        style={recordingButtonStyle}
                        onClick={() => responseFour(item.id)}
                      >
                        {getLabel(Coded.FOUR.label)}
                      </Button>
                      <Button
                        id="peakResponseButtonEight"
                        style={recordingButtonStyle}
                        onClick={() => responseEight(item.id)}
                      >
                        {getLabel(Coded.EIGHT.label)}
                      </Button>
                      <Button
                        id="peakResponseButtonTen"
                        style={recordingButtonStyle}
                        onClick={() => responseTen(item.id)}
                      >
                        {getLabel(Coded.TEN.label)}
                      </Button>
                    </>
                  )}
                </Panel>
              ))}
            </Collapse>
          </div>
        )
      }
    }

    if (SelectedOperation === 'Test') {
      const combinations = []
      SdCombinations?.test.edges.map((combinationNode, combinationIndex) => {
        combinations.push({
          id: combinationNode.node.id,
          relation:
            `${combinationNode.node.stimulus1} ` +
            `${combinationNode.node.sign12} ` +
            `${combinationNode.node.stimulus2} ` +
            `${combinationNode.node.sign23 ? combinationNode.node.sign23 : ''} ` +
            `${combinationNode.node.stimulus3 ? combinationNode.node.stimulus3 : ''} `,
          description:
            `${getFullName(nodeItem, combinationNode.node.stimulus1)} ` +
            `${combinationNode.node.sign12} ` +
            `${getFullName(nodeItem, combinationNode.node.stimulus2)} ` +
            `${combinationNode.node.sign23 ? combinationNode.node.sign23 : ''} ` +
            `${
              combinationNode.node.stimulus3
                ? getFullName(nodeItem, combinationNode.node.stimulus3)
                : ''
            } `,
        })
      })

      if (combinations.length > 0) {
        return (
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <Collapse
              accordion
              destroyInactivePanel={true}
              onChange={e => changeCombination(e)}
              activeKey={ActiveCombination}
              key={ActiveCombination}
            >
              {combinations.map(item => (
                <Panel header={item.description} key={item.id} extra={item.relation}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <EquivalenceScoreBoard classIndex={index} />
                    </div>
                    <div style={{ fontSize: 12 }}>
                      {EquiTrialCount <= 1 ? (
                        <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                          <Icon type="left" />
                        </Button>
                      ) : (
                        <Button style={{ border: 'none' }} onClick={goToPreviousTrial}>
                          <Icon type="left" />
                        </Button>
                      )}
                      Trial {EquiTrialCount} / 10
                      {EquiTrialCount >= 10 ? (
                        <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                          <Icon type="right" />
                        </Button>
                      ) : (
                        <Button style={{ border: 'none' }} onClick={goToNextTrial}>
                          <Icon type="right" />
                        </Button>
                      )}
                    </div>
                  </div>
                  {ResponseLoading ? (
                    <Spin tip="Loading...">
                      <Button style={recordingButtonStyle}>{getLabel(Coded.ZERO.label)}</Button>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.TWO.label)}</Button>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.FOUR.label)}</Button>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.EIGHT.label)}</Button>
                      <Button style={recordingButtonStyle}>{getLabel(Coded.TEN.label)}</Button>
                    </Spin>
                  ) : (
                    <>
                      <Button
                        id="peakResponseButtonZero"
                        style={recordingButtonStyle}
                        onClick={() => responseZero(item.id)}
                      >
                        {getLabel(Coded.ZERO.label)}
                      </Button>
                      <Button
                        id="peakResponseButtonTwo"
                        style={recordingButtonStyle}
                        onClick={() => responseTwo(item.id)}
                      >
                        {getLabel(Coded.TWO.label)}
                      </Button>
                      <Button
                        id="peakResponseButtonFour"
                        style={recordingButtonStyle}
                        onClick={() => responseFour(item.id)}
                      >
                        {getLabel(Coded.FOUR.label)}
                      </Button>
                      <Button
                        id="peakResponseButtonEight"
                        style={recordingButtonStyle}
                        onClick={() => responseEight(item.id)}
                      >
                        {getLabel(Coded.EIGHT.label)}
                      </Button>
                      <Button
                        id="peakResponseButtonTen"
                        style={recordingButtonStyle}
                        onClick={() => responseTen(item.id)}
                      >
                        {getLabel(Coded.TEN.label)}
                      </Button>
                    </>
                  )}
                </Panel>
              ))}
            </Collapse>
          </div>
        )
      }
    }
  }

  return (
    <>
      <div className="peak-block-div">
        <div style={{ display: 'block', marginBottom: '5px' }}>
          <span style={{ float: 'right' }}>
            Code :{' '}
            {MasterSession.targets.edges[TargetActiveIndex].node.eqCode
              ? MasterSession.targets.edges[TargetActiveIndex].node.eqCode
              : 'N/A'}
          </span>

          <Radio.Group
            value={SelectedOperation}
            onChange={e => chnageOperation(e.target.value)}
            buttonStyle="solid"
            style={{ float: 'left' }}
          >
            <Radio.Button value="Train">Train</Radio.Button>
            <Radio.Button value="Test">Test</Radio.Button>
          </Radio.Group>
        </div>
        <br />
        <div style={{ display: 'block', marginTop: '10px' }}>
          <Tabs
            destroyInactiveTabPane={true}
            onChange={e => callback(e)}
            activeKey={SelectedClassId}
            key={SelectedClassId}
          >
            {MasterSession.targets.edges[TargetActiveIndex].node.classes.edges.map(
              (nodeItem, nodeIndex) => (
                <TabPane tab={nodeItem.node.name} key={nodeItem.node.id}>
                  {getDescriptions(nodeItem, nodeIndex)}
                </TabPane>
              ),
            )}
          </Tabs>
        </div>
        <br />
      </div>
    </>
  )
}

export default RecordingBlock
