/* eslint-disable no-unused-expressions */
import React from 'react'
import { Form, Divider, Button, Icon, Input } from 'antd'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const ClassCard = ({
  edit,
  form,
  setClassObjects,
  setClassKey,
  classKey,
  classObjects,
  equivalenceObject,
}) => {
  const alphaList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
  let selectedAlpha = ['A', 'B']
  const initialKeyValue = []

  if (equivalenceObject?.target?.maxSd) {
    selectedAlpha = []
  }

  for (let i = 0; i < equivalenceObject?.target?.maxSd; i += 1) {
    selectedAlpha.push(alphaList[i])
  }

  if (equivalenceObject?.classes?.edges.length > 0) {
    equivalenceObject?.classes?.edges.forEach((item, index) => {
      initialKeyValue.push(index)
    })
  }

  const add = type => {
    if (type === 'class') {
      setClassObjects(state => {
        return [
          ...state,
          {
            k: classKey,
            [`stimulus${classKey}A`]: '',
            [`stimulus${classKey}B`]: '',
          },
        ]
      })

      setClassKey(k => k + 1)
    }
  }

  const remove = (k, index, type) => {
    if (type === 'class') {
      if (classObjects.length === 0) {
        return
      }
      const tempClass = classObjects.filter(item => item.k !== k)
      setClassObjects(tempClass)
    }
  }

  const { getFieldDecorator } = form
  const classFormItems = classObjects.map((item, index) => (
    <>
      <Form.Item
        label={
          <>
            <span>Class {index + 1}</span>
            <span style={{ float: 'right', right: 0 }}>
              {classObjects.length > 0 ? (
                <Icon
                  style={{ marginRight: 10 }}
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  onClick={() => remove(item.k, index, 'class')}
                />
              ) : null}
            </span>
          </>
        }
        key={item.k}
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        {selectedAlpha.map((alpha, alphaIndex) => (
          <Form.Item
            required={false}
            key={`stimulus-${item.k}-${alpha}`}
            style={{
              display: 'inline-block',
              width: 'calc(100% - 12px)',
            }}
          >
            {getFieldDecorator(`stimulus${item.k}${alpha}`, {
              initialValue: item[`stimulus${item.k}${alpha}`],
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: "Please input Stimulus's name or delete this field.",
                },
              ],
            })(<Input placeholder={`Stimulus ${alpha}`} />)}
          </Form.Item>
        ))}
      </Form.Item>
    </>
  ))

  return (
    <div>
      <div style={boxCard}>
        <Divider orientation="left">Class</Divider>
        {classFormItems}
        <Form.Item label="Relational Code">
          {getFieldDecorator('equiCode', {
            initialValue: equivalenceObject?.code || null,
          })(<Input disabled={equivalenceObject?.code} />)}
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
          <Button type="dashed" onClick={() => add('class')} style={{ width: '60%' }}>
            <Icon type="plus" /> Add Class
          </Button>
        </Form.Item>
      </div>
    </div>
  )
}

export default ClassCard
