import React, { useState, useEffect } from 'react'
import { Button, Collapse, Input } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { sortBy } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const StaffSelect = ({ selectStaff }) => {
  const [filteredStaff, setFilteredStaff] = useState([])
  const [searchText, setSearchText] = useState()
  const Staffs = useSelector(state => state.staffs.StaffList)
  const dispatch = useDispatch()

  useEffect(() => {
    if (searchText) {
      const filteredStaffList = Staffs.filter(x =>
        x.name.toLowerCase().includes(searchText.toLowerCase()),
      )
      setFilteredStaff(sortBy(filteredStaffList, data => data.name))
    } else {
      setFilteredStaff(sortBy(Staffs, data => data.name))
    }
  }, [searchText, Staffs])

  return (
    <>
      <div style={{ padding: '0px 5px' }}>
        <Input
          placeholder="Search Staff by name"
          onChange={e => setSearchText(e.target.value)}
          style={{ marginRight: 16, marginBottom: 5, backgroundColor: '#f9f9f9' }}
        />
        <div style={{ overflow: 'auto' }}>
          {filteredStaff.map(nodeItem => (
            <div
              key={nodeItem.id}
              style={{
                display: 'flex',
                height: 22,
                background: 'rgb(243 243 243)',
                padding: '21px 16px',
                margin: '8px 0',
                borderRadius: '4px',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon style={{ color: '#777' }} icon={faUser} />
              <Button
                type="link"
                onClick={() => {
                  dispatch({
                    type: 'staffs/GET_STAFF_PROFILE',
                    payload: nodeItem,
                  })
                  console.log('node item', nodeItem)
                }}
              >
                {nodeItem.name} {nodeItem.surname || ''}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default StaffSelect
