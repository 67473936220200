import actions from './actions'

const initialState = {
  currency: [],
  loadingCurrency: false,
  courses: [],
  adminCourse: {},
  adminCourseLoading: false,
  activeTab: 'user',
  publicCourses: [],
  courseCollapsed: [],
  moduleCollapsed: [],
  contentCollapsed: [],
  testCollapsed: [],
  courseLoading: false,
  coursesLoading: false,
  courseSaving: false,
  addCourseDrawer: false,
  addModuleDrawer: false,
  selectedCourse: null,
  selectedModule: null,
  selectedContent: null,
  selectedTest: null,
  selectedQuestion: null,
  moduleSaving: false,
  contentSaving: false,
  addContentDrawer: false,
  addTestDrawer: false,
  testSaving: false,
  addQuestionDrawer: false,
  questionSaving: false,
  users: [],
  isUserLoading: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.APPEND_COURSE_TEMPLATE: {
      const { courseCollapsed } = state
      courseCollapsed.push({
        id: action.payload.id,
        name: action.payload.name,
        isCollapsed: false,
      })
      return { ...state, courseCollapsed, courses: [...state.courses, { ...action.payload }] }
    }
    case actions.REPLACE_COURSE_TEMPLATE:
      return {
        ...state,
        selectedCourse: null,
        courses: [
          ...state.courses.map(item => {
            if (item.id === action.payload.id) {
              return action.payload
            }
            return item
          }),
        ],
      }

    case actions.APPEND_COURSE_MODULE: {
      const course = state.selectedCourse
      const { moduleCollapsed } = state
      moduleCollapsed.push({
        id: action.payload.id,
        name: action.payload.name,
        isCollapsed: false,
      })
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      course.coursetemplatemodulesSet.edges.push({ node: { ...action.payload } })
      const allCourse = state.courses
      allCourse[tempIndex] = course

      return {
        ...state,
        courses: allCourse,
        moduleCollapsed,
        selectedCourse: null,
        selectedModule: null,
      }
    }
    case actions.REPLACE_COURSE_MODULE: {
      const course = state.selectedCourse
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const newModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        ({ node }) => node.id === action.payload.id,
      )

      const allCourse = state.courses
      allCourse[tempIndex].coursetemplatemodulesSet.edges[newModIndex] = {
        node: { ...action.payload },
      }
      return {
        ...state,
        courses: allCourse,
        selectedCourse: null,
        selectedModule: null,
      }
    }
    case actions.POP_COURSE_MODULE: {
      const course = state.selectedCourse
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const newModules = course.coursetemplatemodulesSet.edges.filter(
        ({ node }) => node.id !== action.payload.id,
      )
      const allCourse = state.courses
      allCourse[tempIndex].coursetemplatemodulesSet.edges = newModules
      return {
        ...state,
        courses: allCourse,
        selectedCourse: null,
        selectedModule: null,
      }
    }
    case actions.APPEND_MODULE_CONTENT: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const { contentCollapsed } = state
      contentCollapsed.push({
        id: action.payload.id,
        name: action.payload.name,
        isCollapsed: false,
      })
      module.coursemodulescontentSet.edges.push({ node: { ...action.payload } })
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.id === state.selectedModule.id,
      )
      course.coursetemplatemodulesSet.edges[tempModIndex] = module
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      allCourse[tempIndex] = course
      return {
        ...state,
        courses: allCourse,
        addContentDrawer: false,
        selectedCourse: null,
        selectedModule: null,
        selectedContent: null,
        contentCollapsed,
      }
    }

    case actions.REPLACE_MODULE_CONTENT: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const contIndex = module.coursemodulescontentSet.edges.findIndex(
        temp => temp.node.id === action.payload.id,
      )
      module.coursemodulescontentSet.edges[contIndex] = { node: { ...action.payload } }
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.id === module.id,
      )
      course.coursetemplatemodulesSet.edges[tempModIndex] = { node: { ...module } }
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      allCourse[tempIndex] = course
      return {
        ...state,
        courses: allCourse,
        addContentDrawer: false,
        selectedCourse: null,
        selectedModule: null,
        selectedContent: null,
      }
    }

    case actions.POP_MODULE_CONTENT: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const newCont = module.coursemodulescontentSet.edges.filter(
        temp => temp.node.id !== action.payload.id,
      )
      module.coursemodulescontentSet.edges = newCont
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.node.id === module.id,
      )
      course.coursetemplatemodulesSet.edges[tempModIndex] = { node: { ...module } }
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      allCourse[tempIndex] = course
      return {
        ...state,
        courses: allCourse,
        selectedCourse: null,
        selectedModule: null,
        selectedContent: null,
      }
    }
    case actions.APPEND_MODULE_TEST: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const { testCollapsed } = state
      testCollapsed.push({
        id: action.payload.id,
        name: action.payload.name,
        isCollapsed: false,
      })
      module.coursemodulestestsSet.edges.push({ node: { ...action.payload } })
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.id === state.selectedModule.id,
      )
      course.coursetemplatemodulesSet.edges[tempModIndex] = { node: { ...module } }
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      allCourse[tempIndex] = course
      return {
        ...state,
        courses: allCourse,
        addTestDrawer: false,
        selectedCourse: null,
        selectedModule: null,
        selectedTest: null,
        testCollapsed,
      }
    }

    case actions.REPLACE_MODULE_TEST: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const contIndex = module.coursemodulestestsSet.edges.findIndex(
        temp => temp.node.id === action.payload.id,
      )
      module.coursemodulestestsSet.edges[contIndex] = { node: { ...action.payload } }
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.id === module.id,
      )
      // course.coursetemplatemodulesSet.edges[tempModIndex] = { node: { ...module } }
      // const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      // allCourse[tempIndex] = course

      console.log('all Courses--->', allCourse)
      return {
        ...state,
        courses: allCourse,
        addTestDrawer: false,
        selectedCourse: null,
        selectedModule: null,
        selectedTest: null,
      }
    }

    case actions.POP_MODULE_TEST: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const newCont = module.coursemodulestestsSet.edges.filter(
        temp => temp.node.id !== action.payload.id,
      )
      module.coursemodulestestsSet.edges = newCont
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.node.id === module.id,
      )
      course.coursetemplatemodulesSet.edges[tempModIndex] = { node: { ...module } }
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      allCourse[tempIndex] = course
      return {
        ...state,
        courses: allCourse,
        selectedCourse: null,
        selectedModule: null,
        selectedTest: null,
      }
    }
    case actions.APPEND_TEST_QUESTION: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const test = state.selectedTest

      test.testquestionSet.edges.push({ node: { ...action.payload } })
      const testIndex = module.coursemodulestestsSet.edges.findIndex(
        temp => temp.node.id === test.id,
      )
      module.coursemodulestestsSet.edges[testIndex] = { node: { ...test } }
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.node.id === state.selectedModule.id,
      )
      course.coursetemplatemodulesSet.edges[tempModIndex] = { node: { ...module } }
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      allCourse[tempIndex] = course
      return {
        ...state,
        courses: allCourse,
        addQuestionDrawer: false,
        selectedCourse: null,
        selectedModule: null,
        selectedContent: null,
        selectedTest: null,
      }
    }
    case actions.REPLACE_TEST_QUESTION: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const test = state.selectedTest
      const queIndex = test.testquestionSet.edges.findIndex(
        temp => temp.node.id === action.payload.id,
      )
      test.testquestionSet.edges[queIndex] = { node: { ...action.payload } }
      const testIndex = module.coursemodulestestsSet.edges.findIndex(
        temp => temp.node.id === test.id,
      )
      module.coursemodulestestsSet.edges[testIndex] = { node: { ...test } }
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.node.id === state.selectedModule.id,
      )
      course.coursetemplatemodulesSet.edges[tempModIndex] = { node: { ...module } }
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      allCourse[tempIndex] = course
      return {
        ...state,
        courses: allCourse,
        addQuestionDrawer: false,
        selectedCourse: null,
        selectedModule: null,
        selectedContent: null,
        selectedTest: null,
        selectedQuestion: null,
      }
    }
    case actions.POP_TEST_QUESTION: {
      const course = state.selectedCourse
      const module = state.selectedModule
      const test = state.selectedTest
      const questions = test.testquestionSet.edges.filter(
        temp => temp.node.id !== action.payload.id,
      )
      test.testquestionSet.edges = questions
      const testIndex = module.coursemodulestestsSet.edges.findIndex(
        temp => temp.node.id === test.id,
      )
      module.coursemodulestestsSet.edges[testIndex] = { node: { ...test } }
      const tempModIndex = course.coursetemplatemodulesSet.edges.findIndex(
        temp => temp.node.id === state.selectedModule.id,
      )
      course.coursetemplatemodulesSet.edges[tempModIndex] = { node: { ...module } }
      const tempIndex = state.courses.findIndex(temp => temp.id === state.selectedCourse.id)
      const allCourse = state.courses
      allCourse[tempIndex] = course
      return {
        ...state,
        courses: allCourse,
        selectedCourse: null,
        selectedModule: null,
        selectedContent: null,
        selectedTest: null,
        selectedQuestion: null,
      }
    }
    default:
      return state
  }
}
