/* eslint-disable no-plusplus */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  getData,
  getSessions,
  getAppointments,
  getTasks,
} from 'services/learnerDashboard'
import actions from './actions'
import { getStartAndEndDate } from '../../utilities'


export function* GET_DATA({ payload }) {
  const selectedDate = yield select(state => state.learnerDashboard.SelectedDate)
  const dates = getStartAndEndDate(selectedDate)
  // api call for areas
  const response = yield call(getData, { studentId: localStorage.getItem('studentId'), startDate: dates[0], endDate: dates[1] })

  if (response && response.data) {
    console.log(response)
    const data = response.data.targetAllocates
    yield put({
      type: actions.SET_STATE,
      payload: {
        InterventionCount: data.interventionCount,
        MasterCount: data.masteredCount,
        TotalCount: data.totalCount,
        TotalList: data.edges,
        loading: false,

      },
    })
  }

}

export function* LOAD_SESSIONS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      WeekSessionLoading: true
    },
  })
  const dates = getStartAndEndDate('Week')
  // api call for areas
  const response = yield call(getSessions, { studentId: localStorage.getItem('studentId'), startDate: dates[0], endDate: dates[1] })

  if (response.data) {
    console.log("LOAD_SESSIONS response", response)

    yield put({
      type: actions.SET_STATE,
      payload: {
        WeekSessions: response.data.studentDateRangeSessionData,
        WeekSessionLoading: false
      },
    })
  }

}

export function* LOAD_APPOINTMENTS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      AppointmentsLoading: true
    },
  })
  // api call for areas
  const response = yield call(getAppointments, { studentId: localStorage.getItem('studentId') })

  if (response.data) {
    console.log("LOAD_APPOINTMENTS response", response)

    yield put({
      type: actions.SET_STATE,
      payload: {
        Appointments: response.data.appointments.edges,
        AppointmentsLoading: false
      },
    })
  }

}

export function* LOAD_TASKS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      TasksLoading: true
    },
  })
  // api call for areas
  const response = yield call(getTasks, { studentId: localStorage.getItem('studentId') })

  if (response.data) {
    console.log("LOAD_Task response", response)

    yield put({
      type: actions.SET_STATE,
      payload: {
        Tasks: response.data.tasks.edges,
        TasksLoading: false
      },
    })
  }

}


export default function* rootSaga() {
  yield all([
    // GET_DATA(), // run once on app load to fetch menu data
    takeEvery(actions.LOAD_DATA, GET_DATA),
    takeEvery(actions.LOAD_SESSIONS, LOAD_SESSIONS),
    takeEvery(actions.LOAD_APPOINTMENTS, LOAD_APPOINTMENTS),
    takeEvery(actions.LOAD_TASKS, LOAD_TASKS),
  ])
}
