import React, { useState } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { COLORS } from 'assets/styles/globalStyles'
import moment from 'moment'
import { Form, Divider, Button, Icon, Popconfirm, Modal } from 'antd'
import PhaseModal from '../phaseModal'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const cardStyle = {
  padding: '12px',
  marginBottom: 10,
  boxShadow: '0 3px 5px rgba(22, 22, 53, 0.16)',
}

const PhaseCard = ({ phaseList, setPhaseList }) => {
  const [addPhaseModal, setAddPhaseModal] = useState(false)
  const [phaseObject, setphaseObject] = useState(null)

  const phaseCard = item => {
    return (
      <div key={item.key} style={cardStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>{item.note}</span>
          <div style={{ minWidth: 70 }}>
            <Button
              style={{ color: '#46bd84', paddingRight: 0 }}
              type="link"
              onClick={() => {
                setphaseObject(item)
                setAddPhaseModal(true)
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Are you sure you don't want this record?"
              onConfirm={() => {
                setPhaseList(state => state.filter(ph => ph.key !== item.key))
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                style={{ marginLeft: 'auto', color: COLORS.danger, paddingRight: 0 }}
                type="link"
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        </div>
        <p style={{ fontSize: 12 }}>{moment(item.date).format('YYYY-MM-DD')}</p>
      </div>
    )
  }

  return (
    <div>
      <div style={boxCard}>
        <Divider orientation="left">Phase Change</Divider>
        {phaseList.map(item => phaseCard(item))}
        <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
          <Button
            type="dashed"
            onClick={() => {
              setAddPhaseModal(true)
              setphaseObject(null)
            }}
            style={{ width: '60%' }}
          >
            <Icon type="plus" /> Add Field
          </Button>
        </Form.Item>
      </div>

      <Modal
        visible={addPhaseModal}
        title={phaseObject ? 'Edit Phase' : 'Add New Phase'}
        onCancel={() => {
          setAddPhaseModal(false)
          setphaseObject(null)
        }}
        zIndex={1001}
        footer={null}
        width={1000}
        destroyOnClose
      >
        <PhaseModal
          setPhaseList={setPhaseList}
          phaseList={phaseList}
          phaseObject={phaseObject}
          closeModal={() => {
            setAddPhaseModal(false)
            setphaseObject(null)
          }}
        />
      </Modal>
    </div>
  )
}

export default PhaseCard
