/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { GREY_ICON_BUTTON, COLORS } from 'assets/styles/globalStyles'
import { useMutation } from 'react-apollo'
import { MoreOutlined } from '@ant-design/icons'
import { Form, Button, Modal, Popconfirm, notification } from 'antd'
import CreateChildForm from './CreateChildForm'
import EditCriteriaForm from './editCriteriaForm'
import { useMasterySeq } from './context'
import { DELETE_CRTIERA_PHASE, DELETE_CRITERIA_PHASE_TARGET } from './query'

const btnStyle = {
  ...GREY_ICON_BUTTON,
  fontSize: 16,
}

const CALCULATION_TYPE_MAPPING = {
  '>': `If response(%) is Greater Than`,
  '<': 'If response(%) is  Less Than',
  '==': 'If response(%) is  Equal',
  '>=': 'If response(%) is  Greater Than or Equal To',
  '<=': 'If response(%) is  Less Than or Equal To',
}

function CriteriaCard({ edit, details, refetchDetails }) {
  const [masterySet, setMasterySet] = useState([])
  const [selectedCriterPhase, setSelectedCriterPhase] = useState(null)
  const [addPhaseTargetModal, setAddPhaseTargetModal] = useState(false)
  const [criteriaTargetObj, setCriteriaTargetObj] = useState(null)
  const [criteriaEditModal, setCriteriaEditModal] = useState(false)
  const [deleteCriteriaPhase, { loading: deleteCriteriaPhaseLoading }] = useMutation(
    DELETE_CRTIERA_PHASE,
  )

  const {
    promptCodes,
    selectedTemplate: { templateType },
  } = useMasterySeq()

  const [deleteCriteriaPhaseTarget] = useMutation(DELETE_CRITERIA_PHASE_TARGET)

  useEffect(() => {
    if (details && details.criteriaphasetargetsSet) {
      setMasterySet(details.criteriaphasetargetsSet.edges)
    } else {
      setMasterySet([])
    }
  }, [details])

  const handleClose = () => {
    setSelectedCriterPhase(null)
    setAddPhaseTargetModal(false)
    setCriteriaEditModal(false)
    setCriteriaTargetObj(null)
  }

  const calculationTypeMapping = (calType, durationCalType) => {
    let valueType =
      templateType === 'Regular'
        ? `response(%) per day`
        : templateType === 'Behavior Recording Duration'
        ? `duration per day`
        : `frequency per day`

    if (durationCalType === 'Average') {
      valueType =
        templateType === 'Regular'
          ? 'average response(%)'
          : templateType === 'Behavior Recording Duration'
          ? 'average duration'
          : 'average frequency'
    }

    switch (calType) {
      case '>':
        return `If ${valueType} is Greater Than`
      case '<':
        return `If ${valueType} is Less Than`
      case '>=':
        return `If ${valueType} is Greater Than or Equal to`
      case '<=':
        return `If ${valueType} is Less Than or Equal to`
      case '==':
        return `If ${valueType} is Equal to`
      default:
        return ''
    }
  }

  const getCalValue = node => {
    const valueType =
      templateType === 'Regular'
        ? `${node.percentage}%`
        : templateType === 'Behavior Recording Duration'
        ? node.duration
        : node.frequency

    return valueType
  }

  const getTitle = node => {
    return `${calculationTypeMapping(node.calculationType, node.durationCalType)} ${getCalValue(
      node,
    )} over ${node.calFrameDurationValue} ${node.calFrameType} ${node.calFrameDurationType}${
      node.calFrameDurationValue !== 1 ? 's' : ''
    }, ${node.progressTo === 'Previous Item in Hierarchy' ? 'auto-regress' : 'auto-progress'} to ${
      node.progressTo
    }`
  }

  return (
    <div className="form-card">
      <div
        className="mastery-card-header flexCenterJust"
        style={{
          borderBottom: masterySet.length > 0 ? '1px solid #ddd' : 'none',
          paddingBottom: masterySet.length > 0 ? 12 : 0,
        }}
      >
        <div style={{ marginRight: 32 }}>
          <MoreOutlined /> {details.criteriaType.statusName} Criteria &nbsp;&nbsp;&nbsp;
          {details.prompts.edges.length > 0 && (
            <span style={{ color: '#777' }}>
              Prompt:{' '}
              {details.prompts.edges.map(({ node: pt }, index) => {
                const ptName = promptCodes?.promptCodes?.filter(it => it.id === pt.id)[0]
                  ?.promptName
                if (index === details.prompts.edges.length - 1) {
                  return <span key={`${details.stepNo}-${pt.id}-span`}>{ptName}</span>
                }
                return <span key={`${details.stepNo}-${pt.id}-span`}>{ptName}, </span>
              })}
            </span>
          )}
        </div>
        {edit && (
          <div>
            <Popconfirm
              title="Are you sure to delete this phase ?"
              onConfirm={() => {
                deleteCriteriaPhase({
                  variables: {
                    pk: details.id,
                  },
                })
                  .then(res => {
                    refetchDetails()
                    notification.success({
                      message: 'Criteria Phase deleted successfully.',
                    })
                  })
                  .catch(e => console.error(e))
              }}
            >
              <Button
                loading={deleteCriteriaPhaseLoading}
                style={{ ...btnStyle, color: COLORS.danger }}
                type="link"
                icon="delete"
              />
            </Popconfirm>
            <Button
              type="link"
              onClick={() => {
                setSelectedCriterPhase(details)
                setCriteriaEditModal(true)
              }}
              icon="edit"
            />
            <Button
              type="link"
              icon="plus"
              onClick={() => {
                setAddPhaseTargetModal(true)
              }}
            />
          </div>
        )}
      </div>

      {masterySet.length > 0 && (
        <div>
          {masterySet.map(item => {
            return (
              <div
                key={item.node.id}
                style={{ padding: '12px 0', borderBottom: '1px solid #ddd', width: '100%' }}
                className="flexCenterJust"
              >
                <Button
                  type="link"
                  onClick={() => {
                    if (edit) {
                      setCriteriaTargetObj(item.node)
                    }
                  }}
                >
                  {getTitle(item.node)}
                </Button>
                {edit && (
                  <div>
                    <Popconfirm
                      title="Are you sure to delete this phase target ?"
                      onConfirm={e => {
                        e.stopPropagation()
                        deleteCriteriaPhaseTarget({
                          variables: {
                            pk: item.node.id,
                          },
                        })
                          .then(res => {
                            refetchDetails()
                            notification.success({
                              message: 'Criteria Phase Target delete successfully.',
                            })
                          })
                          .catch(er => console.error(er))
                      }}
                    >
                      <Button style={btnStyle} type="link" icon="delete" />
                    </Popconfirm>
                    <Button
                      style={btnStyle}
                      type="link"
                      icon="edit"
                      onClick={() => {
                        setCriteriaTargetObj(item.node)
                      }}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}

      <Modal
        title="Edit Criteria"
        visible={criteriaEditModal}
        onCancel={() => setCriteriaEditModal(false)}
        footer={null}
        width={1000}
      >
        <EditCriteriaForm
          edit
          objForEdit={selectedCriterPhase}
          onSuccess={() => {
            refetchDetails()
          }}
          closeModal={() => {
            setCriteriaEditModal(false)
          }}
        />
      </Modal>

      <Modal
        title="Add Criteria Phase Target"
        visible={addPhaseTargetModal}
        onCancel={() => handleClose()}
        footer={null}
        width={1000}
      >
        <CreateChildForm
          details={details}
          onSuccess={() => {
            refetchDetails()
          }}
          closeModal={() => handleClose()}
        />
      </Modal>

      <Modal
        title="Edit Criteria Phase Target"
        visible={!!criteriaTargetObj}
        onCancel={() => handleClose()}
        footer={null}
        width={1200}
      >
        <CreateChildForm
          details={details}
          edit
          onSuccess={() => {
            refetchDetails()
          }}
          criteriaTargetObj={criteriaTargetObj}
          closeModal={() => handleClose()}
        />
      </Modal>
    </div>
  )
}

export default Form.create()(CriteriaCard)
