/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */

import React, { useState, useEffect } from 'react'
import { useLazyQuery } from 'react-apollo'
import LoadingComponent from 'components/LoadingComponent'
import { GET_LONG_GOALS_DETAILS } from './query'
import './styles.scss'
import LtgCard from './ltgCard'

function Index({ statusId, selectedProgram, selectedStudent, filterType }) {
  const [
    getLongGoal,
    { data: lgGoals, loading: lgGoalsLoading, error: lgGoalsError },
  ] = useLazyQuery(GET_LONG_GOALS_DETAILS)

  const [renderData, setRenderData] = useState(null)

  useEffect(() => {
    if (selectedStudent && selectedProgram && statusId) {
      getLongGoal({
        variables: {
          program: selectedProgram.id,
          student: selectedStudent,
          goalStatus: statusId,
        },
      })
    }
  }, [statusId, selectedProgram, selectedStudent])

  useEffect(() => {
    if (lgGoals && lgGoals.longTerm) {
      console.log(lgGoals)
      const tempRty = []

      lgGoals.longTerm.edges.forEach(({ node }) => {
        console.log(node, 'node node')
        const tempData = []
        node.shorttermgoalSet?.edges.forEach(({ node: st }) => {
          st.targetAllocateSet?.edges.forEach(({ node: tt }) => {
            let exist = false
            let idx = -1

            let dm

            if (filterType === 'd') {
              dm = tt.manualAllocateDomain?.domain?.domain || tt.targetId?.domain?.domain
              dm = dm || 'None'
            } else {
              dm = tt.targetStatus.statusName
            }

            for (let i = 0; i < tempData.length; i += 1) {
              if (tempData[i].text === dm) {
                exist = true
                idx = i
              }
            }

            if (exist) {
              tempData[idx].value += 1
            } else {
              tempData.push({
                text: dm,
                key: tt.targetStatus?.id,
                value: 1,
              })
            }
          })
        })

        tempRty.push({
          key: Math.random(),
          tt: tempData,
          goalName: node.goalName,
          dateInitialted: node.dateInitialted,
          dateEnd: node.dateEnd,
        })
      })
      setRenderData(tempRty)
    }
  }, [lgGoals, filterType])

  return (
    <div style={{ width: '100%' }}>
      {lgGoalsLoading ? (
        <LoadingComponent />
      ) : (
        renderData &&
        renderData.map(item => {
          return (
            <LtgCard
              goalName={item.goalName}
              dateInitialted={item.dateInitialted}
              dateEnd={item.dateEnd}
              targetData={item.tt}
              filterType={filterType}
            />
          )
        })
      )}
    </div>
  )
}

export default Index
