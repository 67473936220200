import React from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useSelector } from 'react-redux'
import { Button, Spin, notification, Row, Col } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import { CLINIC_QR, GENERATE_QR_CODE } from '../query'

const QrCode = () => {
  const { data, loading, error, refetch } = useQuery(CLINIC_QR)
  const school = useSelector(state => state.school)

  const [generateQR, { loading: generateQRLoading }] = useMutation(GENERATE_QR_CODE)

  const downloadQrCode = url => {
    const fileName = `${school?.schoolName.replace(' ', '-') || 'clinic'}-QrCode.png`
    saveAs(url, fileName)
  }

  const generateQRCode = () => {
    const variables = { clinicId: school?.id }
    generateQR({
      variables,
    })
      .then(res => {
        notification.success({ message: 'QR code generated successfully.' })
        refetch()
      })
      .catch(err => console.error(err))
  }

  if (error) {
    console.log('error', error)
  }

  return (
    <Spin spinning={loading} tip="Loading">
      <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Col
          sm={24}
          md={24}
          lg={14}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {data && data.getCouponQrCode.qrCode && (
            <>
              <img src={data?.getCouponQrCode?.qrCode} alt="qrCode" style={{ width: '200px' }} />
              <Button type="primary" onClick={() => downloadQrCode(data?.getCouponQrCode?.qrCode)}>
                <DownloadOutlined /> Download QR Code
              </Button>
            </>
          )}

          {!data?.getCouponQrCode?.qrCode && (
            <>
              <Button type="primary" loading={generateQRLoading} onClick={() => generateQRCode()}>
                <DownloadOutlined /> Generate QR Code
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Spin>
  )
}

export default QrCode
