/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import './styles.scss'
import { Collapse } from 'antd'
import TargetCard from './targetCard'
import CollapseComp from './collapseComp'
import { GET_TARGET_ALLOCATES } from './query'

const { Panel } = Collapse

const clr1 = [
  '#7C63D9',
  '#439AE0',
  '#BF4E99',
  '#F9A437',
  '#4CBB9A',
  '#90ED7D',
  '#B3B2B2',
  '#7CB5EC',
  '#E2C033',
  '#91C7BA',
  '#91C7BA',
]

function Index({ programArea, selectedStudent, longGoalId, filterType }) {
  const [
    getTargetAllocates,
    { data: targetData, error: targetError, loading: targetLoading },
  ] = useLazyQuery(GET_TARGET_ALLOCATES)

  const [renderData, setRenderData] = useState(null)

  useEffect(() => {
    getTargetAllocates({
      variables: {
        id: 'TG9uZ1Rlcm1UeXBlOjU5Nw==',
      },
    })
  }, [])

  useEffect(() => {
    if (targetData && targetData.longTermDetail) {
      const tempData = []
      targetData.longTermDetail.shorttermgoalSet.edges.forEach(({ node: st }) => {
        st.targetAllocateSet.edges.forEach(({ node: tt }) => {
          let exist = false
          let idx = -1

          let dm

          if (filterType === 'd') {
            dm = tt.manualAllocateDomain?.domain?.domain || tt.targetId?.domain?.domain
            dm = dm || 'None'
          } else {
            dm = tt.targetStatus.statusName
          }

          for (let i = 0; i < tempData.length; i += 1) {
            if (tempData[i].text === dm) {
              exist = true
              idx = i
            }
          }

          if (exist) {
            tempData[idx].value += 1
            tempData[idx].targetData.push(tt)
          } else {
            tempData.push({
              text: dm,
              key: tt.targetStatus?.id,
              value: 1,
              targetData: [tt],
            })
          }
        })
      })

      setRenderData(tempData)
    }
  }, [targetData])

  return (
    <div
      style={{
        borderRight: '2px solid #e8e8e8',
        width: 'fit-content',
        padding: '12px',
      }}
    >
      <div
        style={{
          width: '100%',
          padding: 16,
        }}
      >
        <Collapse
          className="target-screen"
          style={{ border: 'none', padding: '0' }}
          defaultActiveKey={['1']}
        >
          {renderData?.map((item, idx) => {
            return (
              <Panel
                style={{ border: 'none' }}
                header={
                  <div
                    style={{
                      margin: '8px',
                      color: 'black',
                      fontSize: 15,
                      borderLeft: `6px solid ${clr1[idx]}`,
                      paddingLeft: 10,
                    }}
                  >
                    <div>
                      {item.value} {item.text}
                    </div>
                  </div>
                }
                key={idx + 1}
              >
                {item.targetData.map(tt => {
                  return <TargetCard targetData={tt} key={Math.random()} />
                })}
              </Panel>
            )
          })}
        </Collapse>
      </div>
    </div>
  )
}

export default Index
