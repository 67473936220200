/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */

import React, { useState, useEffect } from 'react'
import { useLazyQuery } from 'react-apollo'
import { Collapse, Switch, Tooltip } from 'antd'
import LoadingComponent from 'components/LoadingComponent'
import { GET_LONG_GOALS } from './query'
import './styles.scss'
import PanelCard from './panelCard'

const { Panel } = Collapse

const prgClr = [
  '#7CB5EC',
  '#F9A437',
  '#B3B2B2',
  '#4CBB9A',
  '#90ED7D',
  '#E2C033',
  '#BF4E99',
  '#91C7BA',
]
const goalsClr = ['#4CBB9A', '#F9A437', '#90ED7D', '#BF4E99']

const Index = ({ programArea, selectedStudent, idx }) => {
  const [
    getLongGoal,
    { data: lgGoals, loading: lgGoalsLoading, error: lgGoalsError },
  ] = useLazyQuery(GET_LONG_GOALS)

  const [filterType, setFilterType] = useState('d')
  const [renderData, setRenderData] = useState(null)

  useEffect(() => {
    if (programArea && selectedStudent) {
      getLongGoal({
        variables: {
          program: programArea.id,
          student: selectedStudent,
        },
      })
    }
  }, [programArea])

  useEffect(() => {
    if (lgGoals && lgGoals.longTerm) {
      console.log(lgGoals)
      const tempData = []

      lgGoals.longTerm.edges.forEach(({ node }) => {
        let exist = false
        let idx = -1

        for (let i = 0; i < tempData.length; i += 1) {
          if (tempData[i].status === node.goalStatus?.status) {
            exist = true
            idx = i
          }
        }

        if (exist) {
          tempData[idx].data += 1
        } else {
          tempData.push({
            status: node.goalStatus?.status,
            id: node.goalStatus?.id,
            data: 1,
          })
        }
      })
      setRenderData(tempData)
    }
  }, [lgGoals])

  return (
    <div
      style={{
        width: '100%',
        padding: 0,
        zIndex: 4,
        color: '#444',
        backgroundColor: '#fff',
        marginBottom: 16,
      }}
    >
      {lgGoalsLoading ? (
        <LoadingComponent />
      ) : (
        lgGoals?.longTerm && (
          <div
            style={{
              margin: '0 auto 12px',
              color: 'black',
              fontSize: 24,
              paddingLeft: 10,
              borderLeft: `10px solid ${goalsClr[idx]}`,
              display: 'flex',
              justifyContent: 'space-between',
            }}
            className="target-screen"
          >
            <div>
              {lgGoals.longTerm?.edges.length} Goals - {programArea.name}
            </div>
            <div>
              <Tooltip title={filterType === 's' ? 'Status' : 'Domain'}>
                <Switch
                  defaultChecked
                  checked={filterType === 'd'}
                  onChange={e => (e ? setFilterType('d') : setFilterType('s'))}
                  checkedChildren={<span>D</span>}
                  unCheckedChildren={<span>S</span>}
                />
              </Tooltip>
            </div>
          </div>
        )
      )}
      <Collapse className="target-screen" style={{ border: 'none', padding: '0' }}>
        {renderData &&
          renderData.map((item, index) => {
            return (
              <Panel
                style={{ border: 'none' }}
                header={
                  <div
                    style={{
                      margin: '8px',
                      color: 'black',
                      fontSize: 15,
                      borderLeft: `6px solid ${prgClr[index]}`,
                      paddingLeft: 10,
                    }}
                  >
                    <div>
                      {item.data} {item.status}
                    </div>
                  </div>
                }
                key={index + 1}
              >
                <PanelCard
                  statusId={item.id}
                  selectedProgram={programArea}
                  selectedStudent={selectedStudent}
                  filterType={filterType}
                />
              </Panel>
            )
          })}
      </Collapse>
    </div>
  )
}

export default Index
