/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_ALL_ASSESSMENT_TEMPLATES = gql`
  query allClinicAssessments($clinicId: ID!, $therapistId: ID) {
    allClinicAssessments(clinic: $clinicId, therapistId: $therapistId) {
      id
      isActive
      qrCode
      assessUrl
      viewReport
      clinic {
        id
        schoolName
      }
      showAssessmentTo {
        edges {
          node {
            id
            staffId
            name
            surname
          }
        }
      }
      assessment {
        id
        title
        isActive
        isDeleted
        assessmentType
        needPayment
      }
    }
  }
`

export const GET_ALL_SUPER_ASSESSMENT_TEMPLATES = gql`
  query allSuperuserAssessments {
    allSuperuserAssessments {
      id
      title
      isActive
      isDeleted
      assessmentType
      needPayment
      clinicassessmentsSet {
        edges {
          node {
            id
            clinic {
              id
              schoolName
            }
            isActive
          }
        }
      }
      # submoduleSet {
      #   id
      #   title
      #   isDeleted
      #   description
      #   createdAt
      #   lastModified
      #   moduleType
      #   submoduleSet {
      #     id
      #     title
      #     isDeleted
      #     subModuleType
      #     description
      #     isDeleted
      #     createdAt
      #     lastModified
      #     questionSet {
      #       id
      #       title
      #       text
      #       allowText
      #       createdAt
      #       isDeleted
      #       questionNo
      #       questionoptionSet {
      #         id
      #         value
      #         colorCoding {
      #           id
      #           name
      #           colorCode
      #         }
      #         scoringCriteria
      #         question {
      #           id
      #           title
      #           text
      #           isDeleted
      #         }
      #         scoreValue
      #       }
      #     }
      #   }
      #   questionSet {
      #     id
      #     title
      #     text
      #     allowText
      #     createdAt
      #     isDeleted
      #     questionNo
      #     questionoptionSet {
      #       id
      #       value
      #       colorCoding {
      #         id
      #         name
      #         colorCode
      #       }
      #       scoringCriteria
      #       question {
      #         id
      #         title
      #         text
      #         isDeleted
      #       }
      #       scoreValue
      #     }
      #   }
      # }
    }
  }
`
export const CREATE_TEMPLATE = gql`
  mutation($title: String!, $createdBy: String!, $assessmentType: String, $needPayment: Boolean) {
    AssessmentCreateMutation(
      input: {
        createdBy: $createdBy
        title: $title
        submodules: []
        assessmentType: $assessmentType
        needPayment: $needPayment
      }
    ) {
      assessment {
        id
        title
        assessmentType
        needPayment
        createdBy {
          username
          email
        }
        submoduleSet {
          id
          title
        }
      }
    }
  }
`
export const EDIT_TEMPLATE = gql`
  mutation($id: String!, $title: String!, $assessmentType: String, $needPayment: Boolean) {
    AssessmentUpdateMutation(
      input: { id: $id, title: $title, assessmentType: $assessmentType, needPayment: $needPayment }
    ) {
      assessment {
        id
        title
        assessmentType
        needPayment
        createdBy {
          username
          email
        }
        submoduleSet {
          id
          title
        }
      }
    }
  }
`

export const ASSIGN_TEMPLETE_TO_CLINICS = gql`
  mutation($templateId: ID!, $isActive: Boolean!, $clinics: [ID]!) {
    ActiveInactiveAssessmentForClinic(
      input: { assessmentId: $templateId, clinics: $clinics, activate: $isActive }
    ) {
      message
      status
    }
  }
`
export const DELETE_TEMPLATE = gql`
  mutation AssessmentDeleteMutation($id: ID!) {
    AssessmentDeleteMutation(input: { id: $id }) {
      status
    }
  }
`
export const CREATE_TEMPLATE_ASSESSMENT = gql`
  mutation studentAssessmentCreateUpdateMutation($student: ID!, $templateID: ID!) {
    studentAssessmentCreateUpdateMutation(input: { student: $student, assessment: $templateID }) {
      studentAssessment {
        id
        title
        score
        questionResponded
        totalQuestion
        createdAt
        isDeleted
        assessment {
          id
          title
          isActive
        }
      }
    }
  }
`
export const DELETE_TEMPLATE_ASSESSMENT = gql`
  mutation studentAssessmentDeleteMutation($id: ID!) {
    studentAssessmentDeleteMutation(input: { id: $id }) {
      status
    }
  }
`
export const CREATE_MODULE = gql`
  mutation($templateID: ID, $title: String!, $description: String, $moduleType: String) {
    SubModuleCreateMutation(
      input: {
        assessment: $templateID
        title: $title
        description: $description
        moduleType: $moduleType
      }
    ) {
      submodule {
        id
        title
        description
        createdAt
        isDeleted
        moduleType
        submoduleSet {
          id
          title
        }
        questionSet {
          id
          title
        }
      }
    }
  }
`
export const DELETE_MODULE = gql`
  mutation($moduleID: ID!) {
    SubModuleDeleteMutation(input: { id: $moduleID }) {
      status
    }
  }
`
export const EDIT_MODULE = gql`
  mutation(
    $id: String!
    $title: String!
    $description: String
    $moduleType: String
    $subModuleType: String
  ) {
    SubModuleUpdateMutation(
      input: {
        id: $id
        title: $title
        description: $description
        moduleType: $moduleType
        subModuleType: $subModuleType
      }
    ) {
      submodule {
        id
        title
        description
        moduleType
        subModuleType
      }
    }
  }
`
export const CREATE_SUBMODULE = gql`
  mutation($moduleID: ID, $title: String!, $description: String, $subModuleType: String) {
    SubModuleCreateMutation(
      input: {
        parentSubmodule: $moduleID
        title: $title
        description: $description
        subModuleType: $subModuleType
      }
    ) {
      submodule {
        id
        title
        description
        createdAt
        isDeleted
        subModuleType
        submoduleSet {
          id
          title
        }
        questionSet {
          id
          title
        }
      }
    }
  }
`
export const CREATE_QUESTION = gql`
  mutation(
    $submoduleID: ID!
    $title: String
    $text: String!
    $options: [OptionInput]
    $allowText: Boolean!
    $questionNo: Int
    $optionalQuestionText: [OptionalQuestionInput]
  ) {
    QuestionCreateMutation(
      input: {
        submodule: $submoduleID
        title: $title
        text: $text
        options: $options
        allowText: $allowText
        questionNo: $questionNo
        optionalQuestionText: $optionalQuestionText
      }
    ) {
      question {
        id
        title
        text
        allowText
        isDeleted
        createdAt
        questionNo
        optionalTexts {
          edges {
            node {
              id
              questionText
              language {
                id
                language
              }
            }
          }
        }
        questionoptionSet {
          id
          value
          optionalTexts {
            edges {
              node {
                id
                optionText
                language {
                  id
                  language
                }
              }
            }
          }
          colorCoding {
            id
            name
            colorCode
          }
          scoringCriteria
          question {
            id
            title
            text
            isDeleted
          }
          scoreValue
        }
      }
    }
  }
`
export const DELETE_QUESTION = gql`
  mutation($questionID: String!) {
    QuestionDeleteMutation(input: { id: $questionID }) {
      status
    }
  }
`
export const EDIT_QUESTION = gql`
  mutation(
    $questionId: ID!
    $title: String
    $text: String!
    $options: [OptionInput]
    $allowText: Boolean!
    $questionNo: Int
    $optionalQuestionText: [OptionalQuestionInput]
  ) {
    QuestionUpdateMutation(
      input: {
        id: $questionId
        title: $title
        text: $text
        options: $options
        allowText: $allowText
        questionNo: $questionNo
        optionalQuestionText: $optionalQuestionText
      }
    ) {
      question {
        id
        title
        text
        allowText
        questionNo
        optionalTexts {
          edges {
            node {
              id
              questionText
              language {
                id
                language
              }
            }
          }
        }
        questionoptionSet {
          id
          value
          scoringCriteria
          optionalTexts {
            edges {
              node {
                id
                optionText
                language {
                  id
                  language
                }
              }
            }
          }
          colorCoding {
            id
            name
            colorCode
          }
          scoreValue
        }
      }
    }
  }
`
export const GET_ASSESSMENT_RESPONSES = gql`
  query studentQuestionResponses($assessmentID: ID!, $answeredBy: String) {
    studentQuestionResponses(studentAssessment: $assessmentID, answeredBy: $answeredBy) {
      id
      question {
        id
        questionNo
        submodule {
          id
        }
      }
      answer {
        id
        value
        scoreValue
        colorCoding {
          id
          colorCode
          name
        }
      }
      answeredBy
      answerText
    }
  }
`
export const SAVE_ASSESSMENT_RESPONSE = gql`
  mutation questionResponseCreateUpdateMutation(
    $assessmentID: ID!
    $questionID: ID!
    $optionID: ID
    $answerText: String
    $answeredBy: String
  ) {
    questionResponseCreateUpdateMutation(
      input: {
        studentAssessment: $assessmentID
        question: $questionID
        answer: $optionID
        answerText: $answerText
        answeredBy: $answeredBy
      }
    ) {
      questionResponse {
        id
        question {
          id
          submodule {
            id
          }
        }
        answer {
          id
          value
          scoreValue
          colorCoding {
            id
            colorCode
            name
          }
        }
        answerText
      }
    }
  }
`
export const GET_PAST_ASSESSMENTS_RESULTS = gql`
  query studentLastAssessmentResults($assessmentID: ID) {
    studentLastAssessmentResults(studentAssessment: $assessmentID) {
      id
      title
      score
    }
  }
`
export const GENERATE_QR_CODE_FOR_CLINIC = gql`
  mutation($assignTemplateId: ID!) {
    generateCustomAssessmentQr(input: { clinicAssessmentId: $assignTemplateId }) {
      status
      msg
      clinicAssessment {
        id
        assessment {
          id
        }
        qrCode
        assessUrl
      }
    }
  }
`

export const CENTERES = gql`
  query getClinicMentalHealthCenter($clinicId: ID!) {
    getClinicMentalHealthCenter(clinic: $clinicId, isActive: true) {
      id
      centerName
      centerCode
      address
      isDefault
      clinic {
        id
        schoolName
      }
      isActive
    }
  }
`
export const GET_TEACHER_COUNT = gql`
  mutation totalTeachersDemographicData($clinicID: ID!, $templateID: ID!, $centerID: ID) {
    totalTeachersDemographicData(
      input: { clinicId: $clinicID, templateId: $templateID, center: $centerID }
    ) {
      message
      status
      data
      __typename
    }
  }
`
export const GET_PARENT_COUNT = gql`
  mutation parentsDemograpicDistribution($clinicID: ID!, $templateID: ID!, $centerID: ID) {
    totalParentsDemographicDistribution(
      input: { clinicId: $clinicID, templateId: $templateID, center: $centerID }
    ) {
      message
      status
      data
      __typename
    }
  }
`
export const CREATE_STUDENT_ASSESSMENT_OBJECT = gql`
  mutation CreateCustomAssessment(
    $assignTemplateId: ID!
    $name: String!
    $dob: Date
    $address: String
    $email: String!
    $sex: String!
    $phone: String
    $clinicId: ID!
    $userType: String
    $performingUser1: String
    $teachingType: String
    $maritalStatus: String
    $educationalQualifications: String
    $teachingExperience: String
    $studyLevel: String
    $center: ID
    $lastName: String
    $fatherName: String
    $motherName: String
    $age: Float
    $language: ID
  ) {
    createCustomAssessment(
      input: {
        assessment: $assignTemplateId
        name: $name
        lastname: $lastName
        fatherName: $fatherName
        motherName: $motherName
        age: $age
        dob: $dob
        address: $address
        email: $email
        sex: $sex
        phone: $phone
        clinicId: $clinicId
        userType: $userType
        teachingType: $teachingType
        performingUser1: $performingUser1
        maritalStatus: $maritalStatus
        educationalQualifications: $educationalQualifications
        teachingExperience: $teachingExperience
        studyLevel: $studyLevel
        center: $center
        language: $language
      }
    ) {
      studentAssessment {
        id
        center {
          id
          centerName
        }
        customLearner {
          id
          name
          dob
          address
          email
          phone
          sex
          studyLevel
          fatherName
          motherName
          age
        }
        userType
        performingUser1
        performingUser2
        therapistInfo {
          id
          name
          dob
          sex
          phone
          email
          maritalStatus
          teachingExperience
          educationalQualifications
          teachingType
        }
        therapistPerforming
        fatherInfo {
          id
          name
          dob
          sex
          phone
          email
        }
        fatherPerforming
        motherInfo {
          id
          name
          dob
          sex
          phone
          email
        }
        motherPerforming
        caregiver1 {
          id
          name
          dob
          sex
          phone
          email
        }
        caregiver1Performing
        caregiver2 {
          id
          name
          dob
          sex
          phone
          email
        }
        caregiver2Performing
        consentformSet {
          edges {
            node {
              isAccepted
              performingUser
              studentAssessment {
                id
              }
            }
          }
        }
        clinic {
          id
          schoolName
        }
        isDeleted
        status
        pauseQuestionId
        paymentSet {
          edges {
            node {
              id
              customAssessment {
                id
              }
              isPaid
            }
          }
        }
        assessment {
          id
          title
          isActive
          isDeleted
          assessmentType
          needPayment
          studentassessmentSet {
            id
            title
            score
            questionResponded
            totalQuestion
            createdAt
            isDeleted
            assessment {
              id
              title
              isActive
              __typename
            }
            __typename
          }
          submoduleSet {
            id
            title
            moduleType
            subModuleType
            isDeleted
            description
            isDeleted
            createdAt
            lastModified
            submoduleSet {
              id
              title
              isDeleted
              moduleType
              subModuleType
              description
              isDeleted
              createdAt
              lastModified
              questionSet {
                id
                title
                text
                allowText
                createdAt
                isDeleted
                questionoptionSet {
                  id
                  value
                  colorCoding {
                    id
                    name
                    colorCode
                    __typename
                  }
                  scoringCriteria
                  question {
                    id
                    title
                    text
                    isDeleted
                    __typename
                  }
                  scoreValue
                  __typename
                }
                __typename
              }
              __typename
            }
            questionSet {
              id
              title
              text
              allowText
              createdAt
              isDeleted
              questionoptionSet {
                id
                value
                colorCoding {
                  id
                  name
                  colorCode
                  __typename
                }
                scoringCriteria
                question {
                  id
                  title
                  text
                  isDeleted
                  __typename
                }
                scoreValue
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
      }
    }
  }
`

// UPDATE THIS API - AS THIS IS NOT AVAVILABE RIGHT NOW
export const GET_ASSESSMENT_BY_ID = gql`
  query studentAssessment($assessmentID: ID!, $language: ID) {
    studentAssessment(id: $assessmentID) {
      id
      title
      score
      questionResponded
      totalQuestion
      createdAt
      isDeleted
      updatedAt
      timeoutOn
      completedAt
      customLearner {
        id
        name
        dob
        phone
        email
        sex
        fatherName
        motherName
        age
      }
      userType
      performingUser1
      performingUser2
      status
      therapistInfo {
        id
        name
        dob
        sex
        phone
        email
      }
      therapistPerforming
      fatherInfo {
        id
        name
        dob
        sex
        phone
        email
      }
      fatherPerforming
      motherInfo {
        id
        name
        dob
        sex
        phone
        email
      }
      motherPerforming
      caregiver1 {
        id
        name
        dob
        sex
        phone
        email
      }
      caregiver1Performing
      caregiver2 {
        id
        name
        dob
        sex
        phone
        email
      }
      caregiver2Performing
      consentformSet {
        edges {
          node {
            isAccepted
            performingUser
            studentAssessment {
              id
            }
          }
        }
      }
      paymentSet {
        edges {
          node {
            id
            customAssessment {
              id
            }
            isPaid
          }
        }
      }
      assessment {
        id
        title
        isActive
        needPayment
        assessmentType
        submoduleSet {
          id
          title
          moduleType
          subModuleType
          isDeleted
          description
          isDeleted
          createdAt
          lastModified
          submoduleSet {
            id
            title
            moduleType
            subModuleType
            isDeleted
            description
            isDeleted
            createdAt
            lastModified
            questionSet {
              id
              title
              text
              questionNo
              allowText
              createdAt
              isDeleted

              optionalTexts(language: $language) {
                edges {
                  node {
                    id
                    questionText
                    language {
                      id
                      language
                    }
                  }
                }
              }
              questionoptionSet {
                id
                value
                colorCoding {
                  id
                  name
                  colorCode
                  __typename
                }
                optionalTexts(language: $language) {
                  edges {
                    node {
                      id
                      optionText
                      language {
                        id
                        language
                      }
                    }
                  }
                }
                scoringCriteria
                question {
                  id
                  title
                  text
                  isDeleted
                  __typename
                }
                scoreValue
                __typename
              }
              __typename
            }
            __typename
          }
          questionSet {
            id
            title
            text
            questionNo
            allowText
            createdAt
            isDeleted
            optionalTexts(language: $language) {
              edges {
                node {
                  id
                  questionText
                  language {
                    id
                    language
                  }
                }
              }
            }
            questionoptionSet {
              id
              value
              optionalTexts(language: $language) {
                edges {
                  node {
                    id
                    optionText
                    language {
                      id
                      language
                    }
                  }
                }
              }
              colorCoding {
                id
                name
                colorCode
                __typename
              }
              scoringCriteria
              question {
                id
                title
                text
                isDeleted
                __typename
              }
              scoreValue
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`
export const GET_ASSESSMENT_PERFORMER = gql`
  query studentAssessment($assessmentID: ID!) {
    studentAssessment(id: $assessmentID) {
      id
      title
      score
      questionResponded
      totalQuestion
      updatedAt
      timeoutOn
      completedAt
      assessment {
        id
        assessmentType
      }
      customLearner {
        id
        name
        dob
        phone
        email
        sex
        address
        studyLevel
        fatherName
        motherName
        age
      }
      userType
      performingUser1
      performingUser2
      status
      therapistInfo {
        id
        name
        dob
        sex
        address
        phone
        email
        maritalStatus
        teachingExperience
        educationalQualifications
        teachingType
      }

      __typename
    }
    __typename
  }
`
export const GET_ASSESSMENT_RECORDS = gql`
  query getAssessmentRecords(
    $clinic: ID!
    $assessment: ID
    $therapistId: ID
    $startDate: Date
    $endDate: Date
    $centerID: ID
    $email: String
    $name: String
  ) {
    studentAssessments(
      clinic: $clinic
      assessment: $assessment
      therapistId: $therapistId
      startDate: $startDate
      endDate: $endDate
      center: $centerID
      email: $email
      name: $name
    ) {
      id
      title
      score
      questionResponded
      totalQuestion
      createdAt
      isDeleted
      status
      completedAt
      updatedAt
      timeoutOn
      completedAt
      center {
        id
        centerName
      }
      allowedStaff {
        edges {
          node {
            id
            name
            email
          }
        }
      }
      customLearner {
        id
        name
        dob
        phone
        email
        sex
        studyLevel
        fatherName
        motherName
        age
      }
      userType
      performingUser1
      performingUser2
      therapistInfo {
        id
        name
        dob
        sex
        address
        phone
        email
        maritalStatus
        teachingExperience
        educationalQualifications
        teachingType
      }
      therapistPerforming
      fatherInfo {
        id
        name
        dob
        sex
        phone
        email
      }
      fatherPerforming
      motherInfo {
        id
        name
        dob
        sex
        phone
        email
      }
      motherPerforming
      caregiver1 {
        id
        name
        dob
        sex
        phone
        email
      }
      caregiver1Performing
      caregiver2 {
        id
        name
        dob
        sex
        phone
        email
      }
      caregiver2Performing
      consentformSet {
        edges {
          node {
            id
            isAccepted
            performingUser
            studentAssessment {
              id
            }
          }
        }
      }
      paymentSet {
        edges {
          node {
            id
            customAssessment {
              id
            }
            discountcouponId {
              id
              coupon
              discountAmount
            }
            isPaid
          }
        }
      }
      assessment {
        id
        title
        isActive
        status
        assessmentType
        needPayment
        submoduleSet {
          id
          title
          moduleType
          __typename
        }
      }
    }
  }
`

export const GET_ASSESSMENT_TYPE = gql`
  query allAssessments($templateID: ID!) {
    allAssessments(id: $templateID) {
      id
      title
      assessmentType
      needPayment
    }
  }
`
export const GET_ASSESSMENT_DETAILS = gql`
  query allAssessments($templateID: ID!) {
    allAssessments(id: $templateID) {
      id
      title
      assessmentType
      needPayment
      submoduleSet {
        id
        title
        isDeleted
        description
        createdAt
        moduleType
        lastModified
        submoduleSet {
          id
          title
          isDeleted
          description
          isDeleted
          createdAt
          lastModified
          questionSet {
            id
            title
            text
            allowText
            createdAt
            isDeleted
            questionNo
            optionalTexts {
              edges {
                node {
                  id
                  questionText
                  language {
                    id
                    language
                  }
                }
              }
            }
            questionoptionSet {
              id
              value
              colorCoding {
                id
                name
                colorCode
              }
              optionalTexts {
                edges {
                  node {
                    id
                    optionText
                    language {
                      id
                      language
                    }
                  }
                }
              }
              scoringCriteria
              question {
                id
                title
                text
                isDeleted
              }
              scoreValue
            }
          }
        }
        questionSet {
          id
          title
          text
          allowText
          createdAt
          isDeleted
          questionNo
          optionalTexts {
            edges {
              node {
                id
                questionText
                language {
                  id
                  language
                }
              }
            }
          }
          questionoptionSet {
            id
            value
            optionalTexts {
              edges {
                node {
                  id
                  optionText
                  language {
                    id
                    language
                  }
                }
              }
            }
            colorCoding {
              id
              name
              colorCode
            }
            scoringCriteria
            question {
              id
              title
              text
              isDeleted
            }
            scoreValue
          }
        }
      }
    }
  }
`
export const SAVE_CONSENT = gql`
  mutation saveConsentForm(
    $studentAssessment: ID!
    $isAccepted: Boolean!
    $performingUser: String!
  ) {
    saveConsentForm(
      input: {
        studentAssessment: $studentAssessment
        isAccepted: $isAccepted
        performingUser: $performingUser
      }
    ) {
      consent {
        id
        isAccepted
        performingUser
        studentAssessment {
          id
          assessment {
            id
            assessmentType
          }
        }
      }
    }
  }
`
export const UPDATE_STATUS = gql`
  mutation updateCustomAssessment(
    $id: ID
    $status: String
    $fatherPerforming: String
    $motherPerforming: String
    $caregiver1Performing: String
    $caregiver2Performing: String
    $therapistPerforming: String
  ) {
    updateCustomAssessment(
      input: {
        id: $id
        status: $status
        fatherPerforming: $fatherPerforming
        motherPerforming: $motherPerforming
        caregiver1Performing: $caregiver1Performing
        caregiver2Performing: $caregiver2Performing
        therapistPerforming: $therapistPerforming
      }
    ) {
      studentAssessment {
        id
        customLearner {
          id
          name
          dob
          address
          email
          phone
          sex
          fatherName
          motherName
          age
        }
        assessment {
          id
        }
        clinic {
          id
          schoolName
        }
        fatherPerforming
        motherPerforming
        therapistPerforming
        caregiver1Performing
        caregiver2Performing
        isDeleted
        status
        pauseQuestionId
      }
    }
  }
`

export const SEND_EMAIL_ON_PAUSE = gql`
  mutation sendEmailForResumeAssessment($email: String, $url: String) {
    sendEmailForResumeAssessment(input: { email: $email, url: $url }) {
      msg
      status
    }
  }
`

export const ADD_PARENT_INFO = gql`
  mutation addParentInfo(
    $id: ID
    $name: String!
    $dob: Date!
    $sex: String!
    $phone: String!
    $email: String!
    $address: String
    $performingUser1: String
    $performingUser2: String
    $relation: ID
    $language: ID
  ) {
    addParentAssessmentInfo(
      input: {
        id: $id
        name: $name
        dob: $dob
        address: $address
        email: $email
        sex: $sex
        phone: $phone
        performingUser1: $performingUser1
        performingUser2: $performingUser2
        relation: $relation
        language: $language
      }
    ) {
      studentAssessment {
        id
        updatedAt
        timeoutOn
        completedAt
        customLearner {
          id
          name
          dob
          address
          email
          phone
          sex
          fatherName
          motherName
          age
        }
        userType
        performingUser1
        performingUser2
        therapistInfo {
          id
          name
          dob
          sex
          phone
          email
        }
        therapistPerforming
        fatherInfo {
          id
          name
          dob
          sex
          phone
          email
        }
        fatherPerforming
        motherInfo {
          id
          name
          dob
          sex
          phone
          email
        }
        motherPerforming
        caregiver1 {
          id
          name
          dob
          sex
          phone
          email
        }
        caregiver1Performing
        caregiver2 {
          id
          name
          dob
          sex
          phone
          email
        }
        caregiver2Performing
        consentformSet {
          edges {
            node {
              id
              isAccepted
              performingUser
              studentAssessment {
                id
              }
            }
          }
        }
        clinic {
          id
          schoolName
        }
        isDeleted
        status
        pauseQuestionId
        assessment {
          id
          title
          isActive
          isDeleted
          assessmentType
          needPayment
          studentassessmentSet {
            id
            title

            score
            questionResponded
            totalQuestion
            createdAt
            isDeleted
            assessment {
              id
              title
              isActive
              __typename
            }
            __typename
          }
          submoduleSet {
            id
            title
            moduleType
            subModuleType
            isDeleted
            description
            isDeleted
            createdAt
            lastModified
            submoduleSet {
              id
              title
              isDeleted
              description
              isDeleted
              createdAt
              lastModified
              questionSet {
                id
                title
                text
                allowText
                createdAt
                isDeleted
                questionoptionSet {
                  id
                  value
                  colorCoding {
                    id
                    name
                    colorCode
                    __typename
                  }
                  scoringCriteria
                  question {
                    id
                    title
                    text
                    isDeleted
                    __typename
                  }
                  scoreValue
                  __typename
                }
                __typename
              }
              __typename
            }
            questionSet {
              id
              title
              text
              allowText
              createdAt
              isDeleted
              questionoptionSet {
                id
                value
                colorCoding {
                  id
                  name
                  colorCode
                  __typename
                }
                scoringCriteria
                question {
                  id
                  title
                  text
                  isDeleted
                  __typename
                }
                scoreValue
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
      }
    }
  }
`

export const ASSIGN_TEMPLETE_TO_STAFFS = gql`
  mutation assignTemplateRecordingToStaff(
    $assessmentId: ID!
    $showTemplateTo: [ID]
    $showRecordingsTo: [ID]
  ) {
    assignTemplateRecordingToStaff(
      input: {
        clinicAssessmentId: $assessmentId
        showTemplateTo: $showTemplateTo
        showRecordingsTo: $showRecordingsTo
      }
    ) {
      message
      status
      clinicAssessment {
        id
        showAssessmentTo {
          edges {
            node {
              id
              name
              email
            }
          }
        }
        showRecordingsTo {
          edges {
            node {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`

export const ASSIGN_RECORDING_TO_THERAPIST = gql`
  mutation assignThrapistToSeeRecordings($studentAssessmentId: ID!, $allowedTherapists: [ID]) {
    assignThrapistToSeeRecordings(
      input: { studentAssessmentId: $studentAssessmentId, allowedTherapists: $allowedTherapists }
    ) {
      message
      status
      studentAssessment {
        id
        allowedStaff {
          edges {
            node {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`
export const GET_LANGUAGE = gql`
  query getCustomAssessmentLanguages($isActive: Boolean) {
    getCustomassessmentLanguages(isActive: $isActive) {
      id
      language
      createdAt
      updatedAt
    }
  }
`
export const GET_ASSESSMENT_USER_LANGUAGE = gql`
  query studentAssessment($assessmentID: ID!) {
    studentAssessment(id: $assessmentID) {
      id
      title
      assessment {
        id
        title
        isActive
        isDeleted
        assessmentType
      }
      customLearner {
        id
        name
        dob
        phone
        email
        sex
        fatherName
        motherName
        age
        __typename
      }
      userType
      performingUser1
      performingUser2
      status
      therapistInfo {
        id
        name
        dob
        sex
        phone
        email
        language {
          id
          language
        }
        __typename
      }
      therapistPerforming
      fatherInfo {
        id
        name
        dob
        sex
        phone
        email
        language {
          id
          language
        }
        __typename
      }
      fatherPerforming
      motherInfo {
        id
        name
        dob
        sex
        phone
        email
        language {
          id
          language
        }
        __typename
      }
      motherPerforming
      caregiver1 {
        id
        name
        dob
        sex
        phone
        email
        language {
          id
          language
        }
        __typename
      }
      caregiver1Performing
      caregiver2 {
        id
        name
        dob
        sex
        phone
        email
        language {
          id
          language
        }
        __typename
      }
      caregiver2Performing
      __typename
    }
    __typename
  }
`
