/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react'
import { Button, Progress, Switch, Icon, notification } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from 'react-apollo'
import apolloClient from '../../apollo/config'
import { GET_STUDENT, UPDATE_STUDENT_PROGRAM_AREA } from './query'

const StudentDrawer = props => {
  const std = JSON.parse(localStorage.getItem('studentId'))

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const learnersprogram = useSelector(state => state.learnersprogram)
  const student = useSelector(state => state.student)
  const { StudentId } = student
  const { ProgramAreas } = learnersprogram
  const { data, loading } = useQuery(GET_STUDENT, {
    variables: {
      std,
    },
    fetchPolicy: 'network-only',
  })

  const [updateStudent] = useMutation(UPDATE_STUDENT_PROGRAM_AREA)

  const [studentProgramAreas, setStudentProgramAreas] = useState([])
  const [showBuildGoalsCard, setShowBuildGoalsCard] = useState(false)
  const [selectedArea, setSelectedArea] = useState('')

  useEffect(() => {
    if (data) {
      setStudentProgramAreas(data.student.programArea.edges)
    }
  }, [data])

  const validateArea = id => {
    let checked = false

    studentProgramAreas.map(nodeItem => {
      if (nodeItem.node.id === id) checked = true
    })

    return checked
  }

  const generateNotification = text => {
    notification.warning({
      message: 'Warning',
      description: text,
    })
  }

  // when user select program area card
  const showDrawr = node => {
    // console.log('===> selected program area : ', node )
    // setting student selected program area to store
    dispatch({
      type: 'student/SET_STATE',
      payload: {
        ProgramAreaId: node.id,
      },
    })

    setShowBuildGoalsCard(true)
    setSelectedArea(node.name)

    window.location.href = '/#/target/allocation'
  }

  const selectProgramArea = node => {
    const checked = validateArea(node.node.id)

    if (checked) {
      showDrawr(node.node)
    } else {
      generateNotification('This program area is not activated')
    }
  }

  const activeInactiveProgram = (isActive, areaId) => {
    const areasIds = []
    if (isActive) {
      studentProgramAreas.map(item => {
        areasIds.push(item.node.id)
      })
      areasIds.push(areaId)
    } else {
      studentProgramAreas.map(item => {
        if (item.node.id !== areaId) areasIds.push(item.node.id)
      })
    }

    updateStudent({
      variables: {
        id: std,
        areaList: areasIds,
      },
    })
      .then(result => {
        if (isActive) {
          notification.success({
            message: 'Success',
            description: 'Program Area Activated Successfully',
          })
        } else {
          notification.success({
            message: 'Success',
            description: 'Program Area Deactivated Successfully',
          })
        }

        setStudentProgramAreas(result.data.updateStudent.student.programArea.edges)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const getPercentageByProgramArea = programId => {
    let TotalTarget = 0
    let MasterTarget = 0

    if (StudentId) {
      apolloClient
        .query({
          query: gql`
            query TherapyPrograms($student: ID!, $program: ID!) {
              programDetails(id: $program) {
                id
                name
                description
                longtermgoalSet(student: $student) {
                  edges {
                    node {
                      id
                      percentageCorr
                      masterTar
                      totalTar
                      goalName
                      shorttermgoalSet {
                        edges {
                          node {
                            id
                            goalName
                            percentageCorr
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            student: StudentId,
            program: programId,
          },
        })
        .then(result => {
          // console.log(result)

          result.data.programDetails.longtermgoalSet.edges.map((longNode, longIndex) => {
            TotalTarget += longNode.node.totalTar
            MasterTarget += longNode.node.masterTar
          })
        })
        .catch(error => console.error(error))
    }

    if (TotalTarget === 0 || MasterTarget === 0) return 0
    else return (MasterTarget / TotalTarget) * 100
  }

  const tdStyle = {
    border: '1px solid #dddddd',
    padding: 8,
    textAlign: 'center',
  }

  return (
    <>
      <table style={{ width: '100%', paddingTop: '2em', backgroundColor: 'white', marginTop: 20 }}>
        {!loading &&
          ProgramAreas.map((nodeItem, index) => (
            <tr>
              <td style={{ ...tdStyle, width: 200 }}>
                <Button type="link" onClick={() => selectProgramArea(nodeItem)}>
                  <p style={{ display: 'block', marginTop: '5px', marginBottom: '-5px' }}>
                    {nodeItem.node.name}
                  </p>
                </Button>
              </td>
              <td style={{ ...tdStyle, width: 100 }}>
                <Switch
                  checkedChildren={<Icon type="check" />}
                  checked={validateArea(nodeItem.node.id)}
                  unCheckedChildren={<Icon type="close" />}
                  onChange={event => {
                    activeInactiveProgram(event, nodeItem.node.id)
                  }}
                />
              </td>
              <td style={tdStyle}>
                <div style={{ lineHeight: '22px' }}>{nodeItem.node.description}</div>
              </td>
              {/* <td style={{ ...tdStyle, width: 150 }}>
                <Progress
                  width={50}
                  type="dashboard"
                  percent={getPercentageByProgramArea(nodeItem.node.id)}
                />
              </td> */}
            </tr>
          ))}
      </table>
    </>
  )
}

export default StudentDrawer
