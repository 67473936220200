const actions = {
  SET_STATE: 'iisca/SET_STATE',

  SET_STATE_ANSWER_1: 'iisca/SET_STATE_ANSWER_1',
  LOAD_DATA: 'iisca/LOAD_DATA',
  LOAD_USER_DATA: 'iisca/LOAD_USER_DATA',
  LOAD_QUESTION_1_DATA: 'iisca/LOAD_QUESTION_1_DATA',
  LOAD_QUESTION_2_DATA: 'iisca/LOAD_QUESTION_2_DATA',
  UPDATE_QUESTION_1_ANSWER: 'iisca/UPDATE_QUESTION_1_ANSWER',
  SAVE_QUESTIONARE: 'iisca/SAVE_QUESTIONARE',
  LOAD_SITUATION_DATA: 'iisca/LOAD_SITUATION_DATA',
  LOAD_BEHAVIOR_DATA: 'iisca/LOAD_BEHAVIOR_DATA',
}

export default actions
