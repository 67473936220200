/* eslint-disable no-nested-ternary */
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, Modal, notification } from 'antd'
import { COLORS } from 'assets/styles/globalStyles'
import LoadingComponent from 'components/LoadingComponent'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { useSelector } from 'react-redux'
import './clinicProfile.scss'

const GET_STAFF_CREDENTIALS = gql`
  query {
    getStaffCredentials {
      id
      name
      goals
      targetAllocation
      shortGoal
      clinic {
        id
        schoolName
      }
    }
  }
`

const UPDATE_STAFF_CREDENTIALS = gql`
  mutation($id: ID!, $goals: Boolean, $targets: Boolean, $shortGoal: Boolean) {
    updateStaffCredentials(
      input: { pk: $id, goals: $goals, targetAllocation: $targets, shortGoal: $shortGoal }
    ) {
      details {
        id
        name
        goals
        targetAllocation
        shortGoal
        clinic {
          id
          schoolName
        }
      }
    }
  }
`
const CREATE_STAFF_CREDENTIALS = gql`
  mutation($name: String!) {
    createStaffCredentials(input: { name: $name }) {
      details {
        id
        name
        goals
        targetAllocation
        shortGoal
        clinic {
          id
          schoolName
        }
      }
    }
  }
`
const DELETE_STAFF_CREDENTIALS = gql`
  mutation($id: ID!) {
    deleteStaffCredentials(input: { id: $id }) {
      status
      message
    }
  }
`
const Headstyle = {
  fontSize: '16px',
  color: 'black',
  marginRight: '10px',
  minWidth: '100px',
}

const Credentials = () => {
  const reduxUser = useSelector(state => state.user)
  const [createCredModal, setCreateCredModal] = useState(false)

  const { data, loading, error, refetch } = useQuery(GET_STAFF_CREDENTIALS)
  const [updateCred] = useMutation(UPDATE_STAFF_CREDENTIALS)

  const [createCred, { loading: createCredLoading }] = useMutation(CREATE_STAFF_CREDENTIALS)
  const [deleteCred, { loading: deleteCredLoading }] = useMutation(DELETE_STAFF_CREDENTIALS)

  const [cred, setCred] = useState(null)
  const [credName, setCredName] = useState('')
  const [ltg, setLtg] = useState(false)
  const [stgNew, setStgNew] = useState(false)
  const [trgt, setTrgt] = useState(false)

  console.log(data, loading, error, 'datata')

  useEffect(() => {
    if (data) {
      setCred(data.getStaffCredentials)
    }
  }, [data])

  const options = [
    { label: 'Long Goal', value: 'longGoal' },
    { label: 'Short Goal', value: 'shortGoal' },
    { label: 'Target', value: 'target' },
  ]

  function onChange(id, name, checkedValues) {
    const tar = checkedValues.filter(item => item === 'target')
    const gol = checkedValues.filter(item => item === 'longGoal')
    const stg = checkedValues.filter(item => item === 'shortGoal')

    updateCred({
      variables: {
        id,
        goals: gol.length === 1,
        targets: tar.length === 1,
        shortGoal: stg.length === 1,
      },
    })
      .then(res => {
        console.log(res, 'res')
      })
      .catch(err => console.error(err))

    console.log('checked = ', checkedValues)
  }

  const tdStyle = { border: '1px solid #dddddd', padding: 8, textAlign: 'center' }

  const handleCreateCred = () => {
    if (credName && credName !== '') {
      createCred({
        variables: {
          name: credName,
        },
      })
        .then(res => {
          console.log(res.data.createStaffCredentials, 'res')
          refetch()
          setCredName('')
          setCreateCredModal(false)
        })
        .catch(err => console.log(err))
    } else {
      notification.warning({
        message: 'Please give a valid name',
      })
    }
  }

  const handleDeleteCred = id => {
    if (id) {
      deleteCred({
        variables: {
          id,
        },
      })
        .then(res => {
          console.log(res)
          if (res.data.deleteStaffCredentials.status) {
            refetch()
            notification.success({
              message: 'Staff Credential deleted successfully.',
            })
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <div className="miscConfigTab">
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
            {/* <h4 className="m-0">Credentials</h4> */}
            <Button
              type="primary"
              style={{ backgroundColor: '#E58425', border: 'none' }}
              onClick={() => setCreateCredModal(true)}
            >
              <PlusOutlined />
              Add Credentials
            </Button>
          </div>
          <table style={{ paddingTop: '2em', backgroundColor: 'white', width: '100%' }}>
            <tbody>
              {cred?.map(item => {
                const dft = []
                if (item.goals) {
                  dft.push('longGoal')
                }
                if (item.shortGoal) {
                  dft.push('shortGoal')
                }
                if (item.targetAllocation) {
                  dft.push('target')
                }

                return (
                  <tr key={item.id}>
                    <td style={{ ...tdStyle, width: '70%' }}>
                      <p
                        style={{
                          fontSize: 16,
                          display: 'block',
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        {item.name}
                      </p>
                    </td>
                    {/* <td style={{ ...tdStyle, width: '100%' }}>
                      <Checkbox.Group
                        className="cred-styles"
                        options={options}
                        defaultValue={dft}
                        onChange={e => onChange(item.id, item.name, e)}
                      />
                    </td> */}
                    <td style={{ ...tdStyle, width: '30%' }}>
                      <Button type="link" onClick={() => handleDeleteCred(item.id)}>
                        <DeleteOutlined
                          style={{ color: COLORS.danger, fontWeight: 600, fontSize: 20 }}
                        />
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}

      <Modal
        visible={createCredModal}
        title="Title"
        onCancel={() => setCreateCredModal(false)}
        footer={[
          <div>
            <Button type="danger" onClick={() => setCreateCredModal(false)}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={handleCreateCred}
              loading={createCredLoading}
            >
              Create
            </Button>
          </div>,
        ]}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            background: '#fff',
          }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
              <div style={Headstyle}>Name: </div>
              <Input
                value={credName}
                onChange={e => setCredName(e.target.value)}
                allowClear
                required
                style={{ width: '240px' }}
                placeholder="Cred Name"
              />
            </div>
            {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
              <div style={Headstyle}>Long Goal: </div>
              <Checkbox
                className="cred-styles"
                checked={ltg}
                defaultChecked
                onChange={e => setLtg(e.target.checked)}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
              <div style={Headstyle}>Short Goal: </div>
              <Checkbox
                className="cred-styles"
                checked={stgNew}
                defaultChecked
                onChange={e => setStgNew(e.target.checked)}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
              <div style={Headstyle}>Target: </div>
              <Checkbox
                className="cred-styles"
                checked={trgt}
                defaultChecked
                onChange={e => setTrgt(e.target.checked)}
              />
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Credentials
