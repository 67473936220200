/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const IER_REPORTS = gql`
  mutation($id: ID!) {
    vbmappIepReport(input: { pk: $id }) {
      status
      data
      file
    }
  }
`

export const GET_TARGET = gql`
  mutation($id: ID!, $areaId: ID!) {
    vbmappTargetSuggest(input: { pk: $id, area: $areaId }) {
      targets {
        id
        domain {
          id
          domain
        }
        targetArea {
          id
          Area
        }
        targetInstr
        video
        targetMain {
          id
          targetName
        }
      }
    }
  }
`

export const GET_BEHAVIOUR = gql`
  query($studentId: ID) {
    getBehaviour(studentId: $studentId) {
      edges {
        node {
          id
          behaviorName
          studentId {
            id
            firstname
          }
        }
      }
    }
  }
`

export const TARGET_ALLOCATIONS_OPTIONS = gql`
  query {
    targetStatus {
      id
      statusName
    }
    types {
      id
      typeTar
    }
    promptCodes {
      id
      promptName
    }
    masteryCriteria {
      id
      name
      masteryType
    }
    domain {
      edges {
        node {
          id
          domain
        }
      }
    }
    goalsProgramArea {
      id
      name
    }
  }
`

export const GET_TARGET_STEP = gql`
  query($text: String) {
    targetStep(first: 8, step_Icontains: $text) {
      edges {
        node {
          id
          step
        }
      }
    }
  }
`

export const GET_TARGET_SD = gql`
  query($text: String) {
    targetSd(first: 8, sd_Icontains: $text) {
      edges {
        node {
          id
          sd
        }
      }
    }
  }
`

export const SETTING = gql`
  query($studentId: ID!) {
    getAllocateTargetSettings(student: $studentId) {
      edges {
        node {
          id
          dailyTrials
          consecutiveDays
          student {
            id
            firstname
          }
          targetType {
            id
            typeTar
          }
          masteryCriteria {
            id
            name
            masteryType
          }
          status {
            id
            statusName
          }
          promptCodes {
            edges {
              node {
                id
                promptName
              }
            }
          }
        }
      }
    }
  }
`

export const SHORT_TERM_GOALS = gql`
  query($studentId: ID!, $program: ID) {
    shortTerm(longTerm_Student: $studentId, longTerm_Program: $program, isDefault: false) {
      edges {
        node {
          id
          goalName
          longTerm {
            id
            program {
              id
              name
              isActive
            }
          }
        }
      }
    }
  }
`

export const DEFAULT_SHORT_TERM_GOALS = gql`
  query($studentId: ID!, $program: ID) {
    shortTerm(longTerm_Student: $studentId, longTerm_Program: $program, isDefault: true) {
      edges {
        node {
          id
          goalName
          longTerm {
            id
            program {
              id
              name
              isActive
            }
          }
        }
      }
    }
  }
`

export const TARGET_STATUS = gql`
  query {
    targetStatus {
      id
      statusName
    }
  }
`

export const PROMPT_CODES = gql`
  query {
    promptCodes {
      id
      promptName
    }
  }
`

export const BEH_PROMPT_CODES = gql`
  query {
    getBehPrompts {
      id
      name
    }
  }
`

export const CREATE_TARGET = gql`
  mutation(
    $studentId: ID
    $shortTerm: ID
    $programArea: ID
    $targetName: String!
    $targetId: ID
    $targetStatus: ID
    $targetInstr: String
    $date: Date
    $masteryCriteria: ID
    $dailyTrials: Int
    $consecutiveDays: Int
    $targetType: ID
    $video: [String]
    $makeDefault: Boolean
    $peakBlocks: Int
    $peakType: String
    $equiCode: String
    $domain: ID
    $prompts: [ID]
    $targetDocs: [TargetDocsInput]
    $sd: [SdMasteryInput]
    $steps: [StepMasteryInput]
    $classes: [ClassesInput]
    $phaset: [PhaseInput]
    $r1: ID
    $r2: ID
    $recordingType: [ID]
    $trialDuration: Int
    $sbtSteps: [SBTStepDataInput]
    $behaviourDescription: String
    $reactiveProcedure: String
    $antecedentManipulation: String
    $emailSend: Boolean
    $caseManagerEmail: Boolean
    $emailMasterStatus: Boolean
    $tag: [String]
    $sessionAllocation: [ID]
    $plotZero: Boolean
    $criteriaTemplate: ID
    $manualCriteriaTemplate: ManualCriteriaTemplateInput
    $recordingBehavior: ID
    $activeSdStepCount: Int
    $isSdStepRandomize: Boolean
    $recordingLabels: [RecordingLabelsInput]
  ) {
    createTargetAllocate3(
      input: {
        makeDefault: $makeDefault
        criteriaTemplate: $criteriaTemplate
        manualCriteriaTemplate: $manualCriteriaTemplate
        targetData: {
          targetId: $targetId
          studentId: $studentId
          shortTerm: $shortTerm
          targetStatus: $targetStatus
          objective: ""
          date: $date
          targetInstr: $targetInstr
          goodPractices: ""
          precaution: ""
          gernalizationCriteria: ""
          masteryCriteria: $masteryCriteria
          targetName: $targetName
          DailyTrials: $dailyTrials
          consecutiveDays: $consecutiveDays
          targetType: $targetType
          prompts: $prompts
          sd: $sd
          steps: $steps
          videos: $video
          peakBlocks: $peakBlocks
          peakType: $peakType
          eqCode: $equiCode
          domain: $domain
          R1: $r1
          R2: $r2
          sbtStepData: $sbtSteps
          recordingType: $recordingType
          TrialDuration: $trialDuration
          behaviourDescription: $behaviourDescription
          reactiveProcedure: $reactiveProcedure
          antecedentManipulation: $antecedentManipulation
          emailMasterStatus: $emailMasterStatus
          tag: $tag
          plotZero: $plotZero
          recordingBehavior: $recordingBehavior
          activeSdStepCount: $activeSdStepCount
          isSdStepRandomize: $isSdStepRandomize
        }
        targetDocs: $targetDocs
        classes: $classes
        phaset: $phaset
        programArea: $programArea
        emailSend: $emailSend
        caseManagerEmail: $caseManagerEmail
        sessionAllocation: $sessionAllocation
        recordingLabels: $recordingLabels
      }
    ) {
      targetName
      target {
        id
        targetStatus {
          id
          statusName
        }
        targetAllcatedDetails {
          id
          targetName
          targetType {
            id
            typeTar
          }
        }
        targetId {
          id
          domain {
            id
            domain
          }
        }
        manualAllocateDomain {
          id
          domain
        }
        date
        tag {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const UPDATE_TARGET = gql`
  mutation(
    $pk: ID!
    $targetName: String!
    $shortTerm: ID
    $targetStatus: ID
    $targetInstr: String
    $date: Date
    $masteryCriteria: ID
    $dailyTrials: Int
    $consecutiveDays: Int
    $targetType: ID
    $video: [String]
    $peakBlocks: Int
    $peakType: String
    $equiCode: String
    $domain: ID
    $prompts: [ID]
    $targetDocs: [TargetDocsInput]
    $sd: [SdMasteryInput]
    $steps: [StepMasteryInput]
    $classes: [ClassesInput]
    $phaset: [PhaseInput]
    $r1: ID
    $r2: ID
    $sbtSteps: [SBTStepDataInput]
    $recordingType: [ID]
    $trialDuration: Int
    $behaviourDescription: String
    $reactiveProcedure: String
    $antecedentManipulation: String
    $emailMasterStatus: Boolean
    $tag: [String]
    $plotZero: Boolean
    $criteriaTemplate: ID
    $manualCriteriaTemplate: ManualCriteriaTemplateInput
    $recordingBehavior: ID
    $activeSdStepCount: Int
    $isSdStepRandomize: Boolean
    $sessionAllocation: [ID]
    $deletedSdStep: [ID]
    $recordingLabels: [RecordingLabelsInput]
  ) {
    updateTargetAllocate3(
      input: {
        pk: $pk
        sessionAllocation: $sessionAllocation
        targetData: {
          shortTerm: $shortTerm
          targetStatus: $targetStatus
          objective: ""
          date: $date
          targetInstr: $targetInstr
          goodPractices: ""
          precaution: ""
          gernalizationCriteria: ""
          masteryCriteria: $masteryCriteria
          targetName: $targetName
          DailyTrials: $dailyTrials
          consecutiveDays: $consecutiveDays
          targetType: $targetType
          prompts: $prompts
          sd: $sd
          steps: $steps
          videos: $video
          peakBlocks: $peakBlocks
          peakType: $peakType
          eqCode: $equiCode
          domain: $domain
          R1: $r1
          R2: $r2
          sbtStepData: $sbtSteps
          recordingType: $recordingType
          TrialDuration: $trialDuration
          behaviourDescription: $behaviourDescription
          reactiveProcedure: $reactiveProcedure
          antecedentManipulation: $antecedentManipulation
          emailMasterStatus: $emailMasterStatus
          tag: $tag
          plotZero: $plotZero
          recordingBehavior: $recordingBehavior
          activeSdStepCount: $activeSdStepCount
          isSdStepRandomize: $isSdStepRandomize
          deletedSdStep: $deletedSdStep
        }
        phaset: $phaset
        targetDocs: $targetDocs
        criteriaTemplate: $criteriaTemplate
        manualCriteriaTemplate: $manualCriteriaTemplate
        classes: $classes
        recordingLabels: $recordingLabels
      }
    ) {
      targetName
      target {
        id
        targetStatus {
          id
          statusName
        }
        recordingBehavior {
          id
          behaviorName
        }
        deletedMastery {
          edges {
            node {
              id
              sd {
                id
                sd
              }
            }
          }
        }

        targetAllcatedDetails {
          id
          targetName
          targetType {
            id
            typeTar
          }
        }
        targetId {
          id
          domain {
            id
            domain
          }
        }
        manualAllocateDomain {
          id
          domain
        }
        date

        sessionsAllocated {
          id
          name
          sessionName {
            id
            name
          }
        }
        tag {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_DOMAINS = gql`
  query getDomains {
    domain {
      edges {
        node {
          id
          domain
        }
      }
    }
  }
`

export const GET_LEARNER_SESSIONS = gql`
  query($studentId: ID!) {
    learnerSessionSettings(learnerId: $studentId) {
      learner {
        id
        email
      }
      sessions {
        edges {
          node {
            id
            sessionName {
              id
              name
            }
            isActive
          }
        }
      }
    }
  }
`

export const GET_SESSION_NAMES = gql`
  query($studentId: ID!, $date: Date) {
    GetStudentSession(studentId: $studentId, date: $date) {
      edges {
        node {
          id
          name
          isCustom
          isActive
          sessionName {
            id
            name
          }
        }
      }
    }
  }
`

export const IS_TARGET_RECORDED = gql`
  query($targetId: ID) {
    checkIsTargetRecorded(targetId: $targetId)
  }
`

export const GET_ALLOCATED_TARGET = gql`
  query($id: ID!) {
    targetAllocate(id: $id) {
      id
      date
      lastUpdatedBy {
        id
        firstName
        lastName
      }
      targetStatusDateSet {
        edges {
          node {
            id
            date
            updatedAt
            time
            userId {
              id
              firstName
              lastName
            }
          }
        }
      }
      targetStatus {
        id
        statusName
      }
      sessionsAllocated {
        id
        sessionName {
          id
          name
        }
      }
      recordingBehavior {
        id
        behaviorName
      }
      shortTerm {
        id
        goalName
        isDefault
        longTerm {
          id
          program {
            id
            name
          }
        }
      }
      objective
      targetId {
        id
        domain {
          id
          domain
        }
      }
      steps {
        edges {
          node {
            id
            step
          }
        }
      }
      targetInstr
      masteryCriteria {
        id
        name
      }
      sendEmail
      isManual
      activeSdStepCount
      isSdStepRandomize
      tag {
        edges {
          node {
            id
            name
          }
        }
      }
      sessionsAllocated {
        id
        name
        sessionName {
          id
          name
        }
      }
      manualAllocateDomain {
        id
        domain
      }
      videos {
        edges {
          node {
            id
            url
          }
        }
      }
      r1 {
        id
        behaviorName
      }
      r2 {
        id
        behaviorName
      }
      criteriaTemplate {
        id
        name
        description
        templateType
        isTargetSpecific
        criteriaphasesSet {
          edges {
            node {
              id
              criteriaType {
                id
                statusName
              }
              autoLabel
              stepNo
              prompts {
                edges {
                  node {
                    id
                    promptName
                  }
                }
              }
              criteriaphasetargetsSet {
                edges {
                  node {
                    id
                    calculationType
                    percentage
                    calFrameType
                    calFrameDurationType
                    calFrameDurationValue
                    minimumTrial
                    durationCalType
                    duration
                    frequency
                    progressTo
                    phaseChangeLabel
                  }
                }
              }
            }
          }
        }
      }
      criteriaPhase {
        id
        stepNo
      }
      sbtSteps {
        edges {
          node {
            id
            description
            reinforce {
              edges {
                node {
                  id
                  name
                  isDefault
                }
              }
            }
            status {
              id
              statusName
            }
            isActive
            mastery {
              id
              masteryType
              noOfProblemBehavior
              gte
              lte
              consecutiveDays
              minTrial
              fromStatus {
                id
                statusName
              }
              toStatus {
                id
                statusName
              }
            }
          }
        }
      }
      phaset {
        edges {
          node {
            id
            date
            content
          }
        }
      }
      peakBlocks
      peakType
      targetDocs {
        edges {
          node {
            id
            sd {
              id
              sd
            }
            step {
              id
              step
            }
            url
          }
        }
      }
      classes {
        edges {
          node {
            id
            name
            stimuluses {
              edges {
                node {
                  id
                  option
                  stimulusName
                }
              }
            }
          }
        }
      }
      eqCode
      mastery {
        edges {
          node {
            id
            sd {
              id
              sd
            }
            step {
              id
              step
            }
            status {
              id
              statusName
            }
            mastery {
              id
              name
            }
            prompts {
              edges {
                node {
                  id
                  promptName
                }
              }
            }
            behPrompts {
              edges {
                node {
                  id
                  name
                }
              }
            }
            criteriaPhase {
              id
              stepNo
            }
            criteriaTemplate {
              id
              name
              description
              templateType
              isSdSpecific
              isStepSpecific
              criteriaphasesSet {
                edges {
                  node {
                    id
                    criteriaType {
                      id
                      statusName
                    }
                    autoLabel
                    stepNo
                    prompts {
                      edges {
                        node {
                          id
                          promptName
                        }
                      }
                    }
                    criteriaphasetargetsSet {
                      edges {
                        node {
                          id
                          calculationType
                          percentage
                          calFrameType
                          calFrameDurationType
                          calFrameDurationValue
                          durationCalType
                          minimumTrial
                          duration
                          frequency
                          progressTo
                          phaseChangeLabel
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      deletedMastery {
        edges {
          node {
            id
            sd {
              id
              sd
            }
            step {
              id
              step
            }
            status {
              id
              statusName
            }
            mastery {
              id
              name
            }
            prompts {
              edges {
                node {
                  id
                  promptName
                }
              }
            }
            behPrompts {
              edges {
                node {
                  id
                  name
                }
              }
            }
            criteriaPhase {
              id
              stepNo
            }
            criteriaTemplate {
              id
              name
              description
              templateType
              isSdSpecific
              isStepSpecific
              criteriaphasesSet {
                edges {
                  node {
                    id
                    criteriaType {
                      id
                      statusName
                    }
                    autoLabel
                    stepNo
                    prompts {
                      edges {
                        node {
                          id
                          promptName
                        }
                      }
                    }
                    criteriaphasetargetsSet {
                      edges {
                        node {
                          id
                          calculationType
                          percentage
                          calFrameType
                          calFrameDurationType
                          calFrameDurationValue
                          durationCalType
                          minimumTrial
                          duration
                          frequency
                          progressTo
                          phaseChangeLabel
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      targetDocs {
        edges {
          node {
            id
            url
          }
        }
      }
      recordingType {
        edges {
          node {
            id
            name
          }
        }
      }
      targetAllcatedDetails {
        id
        time
        targetType {
          id
          typeTar
        }
        trialDuration
        targetName
        DailyTrials
        consecutiveDays
        promptCodes {
          edges {
            node {
              id
              promptName
            }
          }
        }
      }
      plotZero
      behaviourDescription
      reactiveProcedure
      antecedentManipulation
      baselineDate
      domainName
      default
      recordingLabels {
        edges {
          node {
            id
            label
            value
            newLabel
          }
        }
      }
    }
  }
`

export const STUDENT_NAME = gql`
  query($id: ID!) {
    student(id: $id) {
      id
      firstname
      lastname
    }
  }
`

export const RECORDING_TYPE = gql`
  query {
    recordingType {
      id
      name
    }
  }
`

export const GET_SBT = gql`
  query {
    getSbtDefaultSteps {
      id
      description
      orderNo
      reinforce {
        edges {
          node {
            id
            name
            isDefault
          }
        }
      }
      status {
        id
        statusName
      }
      isActive
      mastery {
        id
        masteryType
        noOfProblemBehavior
        gte
        lte
        consecutiveDays
        minTrial
        fromStatus {
          id
          statusName
        }
        toStatus {
          id
          statusName
        }
      }
    }
  }
`

export const GET_SBT_STEP_STATUS = gql`
  query {
    getSbtStepStatus {
      id
      statusName
    }
  }
`

export const GET_SBT_STEP_REINFORCERS = gql`
  query {
    getSbtReinforces {
      id
      name
      isDefault
    }
  }
`

export const CREATE_PROMPT = gql`
  mutation($name: String!) {
    createPrompt(input: { name: $name }) {
      promptCode {
        id
        promptName
        school {
          id
          schoolName
        }
      }
    }
  }
`

export const CREATE_BEHAVIOR = gql`
  mutation createBehaviour($student: ID!, $name: String!, $definition: String) {
    createBehaviour(input: { student: $student, name: $name, definition: $definition }) {
      details {
        id
        behaviorName
      }
    }
  }
`

export const GET_MASTERY_SEQ_TEMPLATE = gql`
  query($clinicId: ID) {
    getCriteriaSequenceTemplate(clinicId: $clinicId) {
      id
      name
      templateType
      description
    }
  }
`

export const GET_TEMPLATE_BY_ID = gql`
  query($id: ID!) {
    getCriteriaTemplate(id: $id) {
      id
      description
      templateType
      criteriaphasesSet {
        edges {
          node {
            id
            criteriaType {
              id
              statusName
            }
            prompts {
              edges {
                node {
                  id
                  promptName
                }
              }
            }
            autoLabel
            stepNo
            criteriaTemplate {
              id
              name
            }
            createdAt
            updatedAt
            criteriaphasetargetsSet {
              edges {
                node {
                  id
                  calculationType
                  calFrameType
                  calFrameDurationType
                  duration
                  calFrameDurationValue
                  frequency
                  durationCalType
                  minimumTrial
                  percentage
                  progressTo
                  phaseChangeLabel
                }
              }
            }
          }
        }
      }
    }
  }
`
