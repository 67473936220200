import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { COLORS } from 'assets/styles/globalStyles'
import { CloudUploadOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  Form,
  Button,
  Row,
  Tooltip,
  Popconfirm,
  notification,
  Col,
  Input,
  Table,
  Upload,
} from 'antd'
import { REACT_APP_API_URL } from 'apollo/REACT_APP_URL'

function AttachmentFormItem({ form, targetAtt, type = 'target', setTargetAtt, label }) {
  const tarAttachment = {
    name: 'file',
    multiple: true,
    action: `${REACT_APP_API_URL}/target-files/`,
    onChange(info) {
      const { status } = info.file
      if (status === 'done' && info.file?.response) {
        const fileList = info.file.response?.fileUrl[0]
        setTargetAtt(state => [...state, { type, key: uuidv4(), url: fileList }])
        notification.success({ message: `${info.file.name} file uploaded successfully.` })
      } else if (status === 'error') {
        notification.error({ message: `File upload failed.` })
      }
    },
    onRemove(info) {
      if (info && info.response && info.response.fileUrl?.length > 0) {
        const deletedFile = info.response.fileUrl[0]
        const tt = targetAtt.filter(data => data.url !== deletedFile)
        setTargetAtt(tt)
      }
    },
  }

  const docsCol = [
    {
      title: 'File Name',
      dataIndex: 'url',
      render: url => {
        if (url) {
          const tt = String(url).split('/')
          let name = tt[tt.length - 1]
          name = name.replace(/%/gi, ' ')
          return name
        }
        return ''
      },
    },
    {
      title: 'Action',
      render: row => (
        <>
          <Tooltip title="View document">
            <a
              style={{
                padding: '0 14px',
                textAlign: 'center',
                display: 'inline-block',
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={row?.url}
            >
              <EyeOutlined style={{ fontSize: 15, fontWeight: 600, color: COLORS.primary }} />
            </a>
          </Tooltip>
          {row.id && (
            <Popconfirm
              trigger="click"
              title="Are you sure to remove this file?"
              onConfirm={() => {
                const t1 = targetAtt.filter(it => it.url !== row.url)
                setTargetAtt(t1)
              }}
            >
              <Button type="link">
                <DeleteOutlined style={{ fontWeight: 600, color: COLORS.danger }} />
              </Button>
            </Popconfirm>
          )}
        </>
      ),
      align: 'center',
    },
  ]

  return (
    <div>
      <Form.Item disabled label={label}>
        <Upload.Dragger {...tarAttachment}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p className="ant-upload-drag-icon" style={{ padding: '0 24px', marginBottom: 0 }}>
              <CloudUploadOutlined />
            </p>
            <div>
              <p className="ant-upload-text">Click/Drag and drop to upload</p>
              <p className="ant-upload-hint">JPEG, PNG, Pdf, etc</p>
            </div>
          </div>
        </Upload.Dragger>
        <Row>
          <Col style={{ textAlign: 'center' }}>OR</Col>
        </Row>
        <Row>
          <Col>
            {form.getFieldDecorator('tarUrl')(
              <Input placeholder="File link ex: Google drive /Dropbox" />,
            )}
          </Col>
        </Row>
      </Form.Item>

      {targetAtt && targetAtt.length > 0 && (
        <div
          className="mastery-criteria-table"
          style={{ width: '100%', marginLeft: 'auto', marginBottom: 15 }}
        >
          <Table
            size="small"
            bordered
            dataSource={targetAtt}
            columns={docsCol}
            pagination={false}
            rowKey={record => record.key}
          />
        </div>
      )}
    </div>
  )
}

export default AttachmentFormItem
