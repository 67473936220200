import React, { useState, useEffect } from 'react'
import { Button, Collapse, Input, Tag, Menu, Dropdown, Drawer, Tabs, Table, Select } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import LoadingComponent from 'components/LoadingComponent'
import GoalComp from 'components/TargetScreen/goalsComp'
import TargetCardArea from 'components/TargetScreen/targetCardArea'
import './styles.scss'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import SessionInstructionDrawer from 'pages/parent/ParentDashboard/SessionInstructionDrawer'
import { COLORS, DRAWER, HEADER_COLOR } from 'assets/styles/globalStyles'
import Scrollbars from 'react-custom-scrollbars'
import Highlighter from 'react-highlight-words'
import LEARNERS_ACTIONS from '../../redux/learnersprogram/actions'

const { Panel } = Collapse
const { Option } = Select

const Avatar = ({ name, bgColor }) => {
  const initial = name
    .trim()
    .split(' ')
    .map(word => word[0])
    .join('')
    .slice(0, 2)

  return (
    <div
      style={{
        padding: '0.5rem',
        borderRadius: '2rem',
        backgroundColor: bgColor,
        height: '2.5rem',
        width: '2.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '1rem',
      }}
    >
      <span>{initial}</span>
    </div>
  )
}

const LearnerSelect = ({ setStudent }) => {
  const dispatch = useDispatch()
  const {
    Learners,
    LearnersFetched,
    SelectedLearnerId,
    Loading,
    LearnerDetails,
    InActiveLearners,
    LearnersStatusFilter,
  } = useSelector(state => state.learnersprogram)
  const {
    StudentId,
    StudentDetails,
    LearnerSessions,
    DrawerWidth,
    activeTab,
    ProgramAreaId,
    ProgramArea,
  } = useSelector(state => state.student)
  const [selectedStudentId, setSelectedStudentId] = useState(
    localStorage.getItem('studentId') ? JSON.parse(localStorage.getItem('studentId')) : null,
  )

  const [selectedSession, setSelectedSession] = useState(null)
  const [sessionInstrDrawer, setSessionInstrDrawer] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [activeKey, setActiveKey] = useState('details')

  useEffect(() => {
    if (!LearnersFetched) {
      dispatch({
        type: 'learnersprogram/LOAD_DATA',
      })
    }
    if (selectedStudentId && !StudentDetails) {
      dispatch({
        type: 'student/STUDENT_DETAILS',
        payload: {
          studentId: selectedStudentId,
        },
      })
    } else if (Learners.length > 0 && !StudentDetails) {
      dispatch({
        type: 'student/STUDENT_DETAILS',
        payload: {
          studentId: Learners[0].node.id,
        },
      })
    }
  }, [Learners])

  const studentChanged = id => {
    setActiveKey('details')
    if (setStudent) {
      setStudent(id)
    } else if (id) {
      localStorage.setItem('fetchFor', 'student')
      localStorage.setItem('studentId', JSON.stringify(id))
      const clr = HEADER_COLOR[Math.floor(Math.random() * HEADER_COLOR.length)]

      setSelectedStudentId(id)

      dispatch({
        type: 'learnersprogram/SET_STATE',
        payload: {
          SelectedLearnerId: id,
        },
      })
      dispatch({
        type: 'learnersprogram/CHNAGE_LEARNER',
        payload: {
          newLearnerId: id,
          prevLearnerId: SelectedLearnerId,
        },
      })
      dispatch({
        type: 'student/STUDENT_DETAILS',
        payload: {
          selectedColor: clr,
          studentId: id,
        },
      })
    }
  }

  const searchLearner = text => {
    setSearchText(text.toLowerCase())
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="/#/appointmentData/">
          Create Appointment
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="/#/target/allocation">
          Target Allocate
        </a>
      </Menu.Item>
    </Menu>
  )

  const barExtend = (
    <Dropdown overlay={menu}>
      <PlusOutlined style={{ fontSize: 20, cursor: 'pointer', color: COLORS.primary }} />
    </Dropdown>
  )

  const sessionColumns = [
    {
      title: 'Session Name',
      key: 'name',
      render: obj => obj.name || obj.sessionName?.name,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setSelectedSession(record)
            setSessionInstrDrawer(true)

            dispatch({
              type: 'sessionrecording/SET_STATE',
              payload: {
                SessionId: record.id,
                SessionDate: moment().format('YYYY-MM-DD'),
              },
            })
          }}
        >
          {record.childsessionSet.edges.length > 0 &&
            record.childsessionSet.edges[0].node.status === 'PROGRESS' &&
            'Resume Session'}
          {record.childsessionSet.edges.length > 0 &&
            record.childsessionSet.edges[0].node.status === 'COMPLETED' &&
            'See Session'}
          {record.childsessionSet.edges.length > 0 &&
            record.childsessionSet.edges[0].node.status === 'PENDING' &&
            'Start Session'}
          {record.childsessionSet.edges.length === 0 && 'Start Session'}
        </Button>
      ),
    },
  ]

  let sessionData = []
  if (LearnerSessions) {
    const data = LearnerSessions.map(({ node }) => node)
    sessionData = data.filter(x => x.targets.edges.length > 0)
  }

  const handleLearnerStatusFilterChange = value => {
    dispatch({
      type: LEARNERS_ACTIONS.CHANGE_LEARNERS_STATUS_FILTER,
      payload: {
        learnerStatusFilter: value,
      },
    })
  }

  let filteredLearnersList = Learners

  if (LearnersStatusFilter === 'inactive') {
    filteredLearnersList = InActiveLearners
  } else if (LearnersStatusFilter === 'all') {
    filteredLearnersList = Learners.concat(InActiveLearners)
  }

  return (
    <>
      {Loading && !StudentDetails ? (
        <LoadingComponent />
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: DrawerWidth === '30%' ? '100%' : '48%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease 0s',
              }}
            >
              <div style={{ padding: '0 0.3rem' }}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '1rem',
                    backgroundColor: 'white',
                    borderRadius: '0.2rem',
                    padding: '1rem',
                    gap: '1rem',
                  }}
                >
                  <Input.Search
                    placeholder="Search learner by name"
                    onChange={e => searchLearner(e.target.value.trimStart())}
                    value={searchText}
                    allowClear
                    style={{
                      backgroundColor: '#f9f9f9',
                    }}
                  />
                  <Select
                    onSelect={handleLearnerStatusFilterChange}
                    defaultValue="active"
                    placeholder="Status"
                    value={LearnersStatusFilter}
                    style={{ width: 130 }}
                  >
                    <Option value="active">Active</Option>
                    <Option value="inactive">In-Active</Option>
                    <Option value="all">All</Option>
                  </Select>
                </div>
              </div>

              <Scrollbars style={{ height: 'calc(100vh - 180px)' }} autoHide>
                <div className="learner-collapse">
                  <Collapse
                    accordion
                    defaultActiveKey={[StudentId]}
                    expandIcon={() => <span />}
                    onChange={e => e && studentChanged(e)}
                  >
                    {!Loading &&
                      filteredLearnersList
                        ?.filter(({ node }) => {
                          if (!searchText) {
                            return true
                          }
                          const name = `${node.firstname} ${node.lastname || ''}`.toLowerCase()
                          return name.includes(searchText)
                        })
                        .map((nodeItem, irt) => (
                          <Panel
                            style={{
                              backgroundColor:
                                SelectedLearnerId === nodeItem.node.id ? '#ccfbf1' : null,
                            }}
                            header={
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'start',
                                  alignItems: 'center',
                                  gap: '1rem',
                                }}
                              >
                                <Avatar
                                  name={`${nodeItem.node.firstname} ${nodeItem.node.lastname ||
                                    ''}`}
                                  bgColor="#f1f1f1"
                                />
                                <div style={{ fontWeight: 'bold', color: '#334155', fontSize: 16 }}>
                                  <Highlighter
                                    searchWords={[searchText]}
                                    textToHighlight={`${nodeItem.node.firstname} ${nodeItem.node
                                      .lastname || ''}`}
                                  />
                                </div>
                              </div>
                            }
                            key={nodeItem.node.id}
                          >
                            <div key={nodeItem.node.id}>
                              <Tabs
                                onChange={key => {
                                  setActiveKey(key)
                                  if (key === 'session') {
                                    dispatch({
                                      type: 'student/GET_STUDENT_SESSION',
                                      payload: {
                                        studentId: nodeItem.node.id,
                                      },
                                    })
                                  }
                                }}
                                activeKey={activeKey}
                                tabBarExtraContent={barExtend}
                              >
                                <Tabs.TabPane key="details" tab="Details">
                                  <div style={{ fontSize: 16, padding: 8 }}>
                                    <div style={{ marginBottom: 8 }}>
                                      <b>Email : </b>&nbsp; {StudentDetails?.email}
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                      <b>Assessments : </b> &nbsp;
                                      {StudentDetails?.isVbmappActive && (
                                        <a rel="noopener noreferrer" href="/#/cogniableAssessment">
                                          <Tag color="#87d068">Vmbapp</Tag>
                                        </a>
                                      )}
                                    </div>
                                    {LearnerDetails?.caseManager ? (
                                      <div style={{ marginBottom: 8, display: 'flex' }}>
                                        <b>Case Manager : </b> &nbsp; &nbsp;
                                        <div>
                                          {LearnerDetails?.caseManager?.name}, &nbsp;
                                          {LearnerDetails?.caseManager?.email}
                                          <br />
                                          {LearnerDetails?.caseManager?.contactNo}
                                        </div>
                                      </div>
                                    ) : (
                                      <p>No case manager assigned</p>
                                    )}
                                  </div>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="session" tab="Session">
                                  <div style={{ paddingTop: 6 }}>
                                    <Table
                                      loading={false}
                                      columns={sessionColumns}
                                      dataSource={sessionData}
                                      rowKey={record => record.id}
                                      size="small"
                                      bordered
                                      pagination={false}
                                    />
                                  </div>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="programs" tab="Programs">
                                  <div style={{ padding: 8 }}>
                                    {StudentDetails?.programArea.edges.map((areaNode, index) => (
                                      <div key={areaNode.node.id}>
                                        {areaNode.node.isActive && (
                                          <div style={{ fontSize: 16, fontWeight: 600 }}>
                                            {index + 1} .
                                            <Button
                                              type="link"
                                              onClick={() => {
                                                dispatch({
                                                  type: 'student/SET_STATE',
                                                  payload: {
                                                    activeTab: 'goal',
                                                    ProgramAreaId: areaNode.node.id,
                                                    ProgramArea: areaNode.node,
                                                  },
                                                })
                                                dispatch({
                                                  type: 'student/SET_DRAWER_WIDTH',
                                                  payload: {
                                                    width: DRAWER.learnerL2,
                                                  },
                                                })
                                              }}
                                            >
                                              {areaNode.node.name}
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </Tabs.TabPane>
                              </Tabs>
                            </div>
                          </Panel>
                        ))}
                    {Loading && <LoadingComponent />}
                  </Collapse>
                </div>
              </Scrollbars>
            </div>

            <div
              style={{
                width: DrawerWidth === '55%' ? '50%' : '0%',
                overflow: 'hidden',
                margin: '0 auto',
              }}
            >
              {activeTab === 'goal' && (
                <div>
                  <Button
                    type="link"
                    onClick={() =>
                      dispatch({
                        type: 'student/SET_DRAWER_WIDTH',
                        payload: {
                          width: DRAWER.learnerL1,
                        },
                      })
                    }
                  >
                    <ArrowLeftOutlined />
                  </Button>
                  <GoalComp
                    programAreas={ProgramArea ? [ProgramArea] : []}
                    selectedStudent={selectedStudentId}
                  />
                </div>
              )}
            </div>

            <div style={{ width: activeTab === 'target' ? '58%' : '0%', overflow: 'hidden' }}>
              {activeTab === 'goal' && (
                <div>
                  <Button
                    type="link"
                    onClick={() =>
                      dispatch({
                        type: 'student/SET_STATE',
                        payload: {
                          activeTab: 'goal',
                        },
                      })
                    }
                  >
                    <ArrowLeftOutlined />
                  </Button>
                  <TargetCardArea
                    programAreas={ProgramAreaId}
                    selectedStudent={selectedStudentId}
                  />
                </div>
              )}
            </div>

            <Drawer
              width={500}
              placement="right"
              title="Session Preview"
              closable
              onClose={() => setSessionInstrDrawer(false)}
              visible={sessionInstrDrawer}
            >
              <SessionInstructionDrawer session={selectedSession} />
            </Drawer>
          </div>
        </>
      )}
    </>
  )
}

export default LearnerSelect
