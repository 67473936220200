/* eslint-disable no-plusplus */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { createAuthorization } from 'services/authorization'
import actions from './actions'

export function* CREATE_AUTHORIZATION({ payload }) {
  console.log('CREATE_AUTHORIZATION')
  console.log(payload)
  const response = yield call(createAuthorization, payload)
  console.log(response)
  if (response && response.data) {
    notification.success({
      message: 'Authorization Created Successfully',
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_AUTHORIZATION, CREATE_AUTHORIZATION)])
}
