/* eslint-disable no-unused-expressions */
import {
  Button,
  DatePicker,
  Dropdown,
  Menu,
  notification,
  Select,
  Radio,
  Tabs,
  Tooltip,
} from 'antd'
import { COLORS } from 'assets/styles/globalStyles'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery, useQuery } from 'react-apollo'
import { FaDownload } from 'react-icons/fa'
import RangeDatePicker from 'components/DatePicker/DatePicker'

import { TARGET_EQUI_RESPONSE_RATE, TARGET_RESPONSE_RATE, GET_STUDENT_SESSION_NAME } from '../query'
import { TargetResponseEqui } from './targetResponseEqui'
import { TargetResponsePeakBlock } from './targetResponsePeakBlock'
import '../form.scss'
import '../table.scss'

const { TabPane } = Tabs
const { Option } = Select

const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
  backgroundColor: COLORS.palleteLight,
}

const dateFormat = 'YYYY-MM-DD'
const parentDiv = { display: 'flex', margin: '5px 30px 5px 0' }
const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }

function TargetResponseReport({ studentName }) {
  const studentId = localStorage.getItem('studentId')
  const [dateRange, setDateRange] = useState([
    moment(Date.now()).subtract(10, 'days'),
    moment(Date.now()),
  ])

  const [targetResponseData, setTargetResponseData] = useState(null)
  const [targetResponsePeakEquiData, setTargetResponsePeakEquiData] = useState(null)
  const [peakType, setpeakType] = useState('dir/gen')
  const [equiTrainTest, setEquiTrainTest] = useState('all')
  const [activeEquiTab, setActiveEquiTab] = useState('Class')
  const [session, setSession] = useState('U2Vzc2lvbk5hbWVUeXBlOjE=')
  const downloadCsvRef = useRef()

  const { data: sessionNames, error: sessionNamesError, loading: sessionNamesLoading } = useQuery(
    GET_STUDENT_SESSION_NAME,
    {
      variables: {
        studentId: JSON.parse(studentId),
      },
    },
  )

  const [getResponseRate, { data: dt, loading: ld, error: er }] = useLazyQuery(TARGET_RESPONSE_RATE)
  const [
    getEquiResponseRate,
    { data: equiData, loading: equiLoading, error: equiError },
  ] = useLazyQuery(TARGET_EQUI_RESPONSE_RATE)

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button
          onClick={() => {
            if (peakType === 'dir/gen') {
              downloadCsvRef.current?.exportToCSV('Block')
            } else {
              downloadCsvRef.current?.exportToCSV(activeEquiTab)
            }
          }}
        >
          CSV/Excel
        </Button>
      </Menu.Item>
    </Menu>
  )

  const DateChangeDropDown = date => {
    setDateRange(date)
  }

  const start = dateRange[0]
  const end = dateRange[1]

  const days = []
  let day = start

  while (day <= end) {
    days.push(day.toDate())
    day = day.clone().add(1, 'd')
  }

  const daysList = []
  days.forEach(dateStr => {
    daysList.push({
      monthYear: moment(dateStr)
        .format('MMM')
        .concat(moment(dateStr).format('YYYY')),
      date: moment(dateStr).format('YYYY-MM-DD'),
      month: moment(dateStr).format('MMM'),
      dayDate: moment(dateStr).format('DD'),
      day: moment(dateStr).format('dddd'),
      year: moment(dateStr).format('YYYY'),
    })
  })

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && studentId) {
      let st
      let ed
      if (dateRange[0].format(dateFormat) < dateRange[1].format(dateFormat)) {
        st = dateRange[0].format(dateFormat)
        ed = dateRange[1].format(dateFormat)
      } else {
        st = dateRange[1].format(dateFormat)
        ed = dateRange[0].format(dateFormat)
      }

      getResponseRate({
        variables: {
          student: studentId,
          start: st,
          end: ed,
          sessionName: session,
        },
      })

      getEquiResponseRate({
        variables: {
          student: studentId,
          start: st,
          end: ed,
          sessionName: session,
          equivalence: true,
        },
      })
    }
  }, [dateRange, studentId, session])

  console.log(dt)
  useEffect(() => {
    setTargetResponseData([])
    if (dt) {
      let tempData = []
      const tempBlockId = []

      tempData = dt.targetWiseReportDatewise.filter(item => {
        if (item.target === null) {
          return false
        }

        const targetType = item.target.targetAllcatedDetails.targetType.typeTar
        if (targetType === 'Peak') {
          for (let i = 0; i < tempBlockId.length; i += 1) {
            if (item.blocks[0]?.id === tempBlockId[i]) {
              return false
            }
          }
          tempBlockId.push(item.blocks[0]?.id)
        } else {
          if (item.trials?.length > 0) {
            for (let i = 0; i < tempBlockId.length; i += 1) {
              if (item.trials[0]?.id === tempBlockId[i]) {
                return false
              }
            }
          }
          tempBlockId.push(item.trials[0]?.id)
        }
        return true
      })
      console.log(tempData, ' temp datatat')
      setTargetResponseData(tempData)
    }
    if (er) {
      notification.error({
        message: 'Something went wrong',
        description: 'Unable to fetch data',
      })
    }
  }, [dt, er])
  console.log(dt, er, 'yo')
  useEffect(() => {
    if (equiData) {
      let tempData = []
      const tempClassId = []

      equiData.targetWiseReportDatewise.forEach(item => {
        tempData.push({
          date: item.date,
          equBlocks: item.equBlocks,
          targetName: item.target?.targetAllcatedDetails.targetName,
          targetId: item.target?.targetAllcatedDetails.id,
          peakType: item.target?.peakType,
        })
      })

      tempData = tempData.filter(item => {
        if (item.targetName === undefined || item.targetName === null || item.equBlocks === null) {
          return false
        }

        if (item.equBlocks.length === 0) {
          return false
        }
        for (let i = 0; i < tempClassId.length; i += 1) {
          if (item.equBlocks[0].id === tempClassId[i]) {
            return false
          }
        }

        tempClassId.push(item.equBlocks[0].id)
        return true
      })

      if (equiTrainTest === 'TRAIN' || equiTrainTest === 'TEST') {
        for (let i = 0; i < tempData.length; i += 1) {
          tempData[i].equBlocks = tempData[i].equBlocks.filter(item => {
            return item.recType === equiTrainTest
          })
        }
      }

      setTargetResponsePeakEquiData(tempData)
    } else if (equiError) {
      notification.error({
        message: 'Something went wrong',
        description: 'Unable to fetch target response equivalence data',
      })
    }
  }, [equiData, equiError, equiTrainTest])

  const peakEquitabbarExt = (
    <Radio.Group
      size="small"
      value={equiTrainTest}
      style={{ margin: 'auto 0' }}
      onChange={e => {
        setEquiTrainTest(e.target.value)
      }}
      buttonStyle="solid"
    >
      <Radio.Button value="all">All</Radio.Button>
      <Radio.Button value="TRAIN">TRAIN</Radio.Button>
      <Radio.Button value="TEST">TEST</Radio.Button>
    </Radio.Group>
  )

  return (
    <div>
      <div style={filterCardStyle}>
        <div style={parentDiv}>
          <Tooltip trigger={['hover']} title="Select Date range">
            <RangeDatePicker date={dateRange} setDate={DateChangeDropDown} from="TARGET_RESPONSE" />
          </Tooltip>
        </div>

        <div style={parentDiv}>
          <Tooltip title="Session">
            <Select
              style={{ width: 220, borderRadius: 4 }}
              value={!sessionNamesLoading ? session : 'Morning'}
              disabled={ld}
              onChange={v => setSession(v)}
              placeholder="ALL"
            >
              <Option key="all" value="">
                All
              </Option>
              {sessionNames &&
                sessionNames.getActiveSessionNames.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Tooltip>
        </div>

        {/* <div style={parentDiv}>
          <Tooltip title="Report Type">
            <Radio.Group
              size="small"
              value={peakType}
              style={{ margin: 'auto 0' }}
              onChange={e => {
                setpeakType(e.target.value)
              }}
              buttonStyle="solid"
            >
              <Radio.Button value="dir/gen">Coded</Radio.Button>
              <Radio.Button value="equi">Relational</Radio.Button>
            </Radio.Group>
          </Tooltip>
        </div> */}
        <div style={parentDiv}>
          <span style={parentLabel}>Target</span>
          <div
            style={{
              background: '#2874A6',
              borderRadius: 10,
              width: '20px',
              margin: 'auto 0',
              marginRight: '10px',
              height: 20,
            }}
          />
          <span style={parentLabel}>Stimulus</span>
          <div
            style={{
              background: '#f080b8',
              borderRadius: 10,
              width: '20px',
              margin: 'auto 0',
              marginRight: '10px',
              height: 20,
            }}
          />
          <span style={parentLabel}>Steps</span>
          <div
            style={{
              background: '#f0b880',
              borderRadius: 10,
              width: '20px',
              margin: 'auto 0',
              marginRight: '10px',
              height: 20,
            }}
          />
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{ padding: '0 8px' }} type="link" size="large">
              <FaDownload fontSize="22px" />
            </Button>
          </Dropdown>
        </div>
      </div>

      {peakType === 'dir/gen' ? (
        <TargetResponsePeakBlock
          dates={daysList}
          data={targetResponseData}
          dataLoading={ld}
          ref={downloadCsvRef}
          studentName={studentName}
        />
      ) : (
        <Tabs
          style={{ marginTop: 10, marginLeft: 10 }}
          defaultActiveKey={activeEquiTab}
          type="card"
          tabBarExtraContent={peakEquitabbarExt}
          onChange={setActiveEquiTab}
        >
          <TabPane tab="Class" key="Class">
            <TargetResponseEqui
              dates={daysList}
              peakEquiData={targetResponsePeakEquiData}
              peakEquiLoading={equiLoading}
              studentName={studentName}
              expandType="Class"
              ref={downloadCsvRef}
            />
          </TabPane>

          <TabPane tab="Stimulus" key="Stimulus">
            <TargetResponseEqui
              dates={daysList}
              peakEquiData={targetResponsePeakEquiData}
              peakEquiLoading={equiLoading}
              studentName={studentName}
              expandType="Stimulus"
              ref={downloadCsvRef}
            />
          </TabPane>
        </Tabs>
      )}
    </div>
  )
}

export default TargetResponseReport
// 04775da2277b8e715542da005625a4b8
