/* eslint-disable import/prefer-default-export */

import gql from 'graphql-tag'
import reqwest from 'reqwest'
import { REACT_APP_API_URL } from 'apollo/REACT_APP_URL'

const API_URL = `${REACT_APP_API_URL}/graphql`

export const CREATE_PAYOR = gql`
  mutation createPayor(
    $name: String!
    $phoneNumber: String
    $faxNumber: String
    $Medicaid: Boolean
    $private: Boolean
    $payorPlan: [PayorPlanInput]
    $city: String
    $state: String
    $streetAddress: String
    $country: String
    $zipCode: String
  ) {
    createPayor(
      input: {
        name: $name
        phoneNumber: $phoneNumber
        faxNumber: $faxNumber
        Medicaid: $Medicaid
        private: $private
        payorPlan: $payorPlan
        city: $city
        state: $state
        streetAddress: $streetAddress
        country: $country
        zipCode: $zipCode
      }
    ) {
      details {
        id
        name
        email
        phoneNumber
        faxNumber
        city
        state
        payorplanSet {
          edges {
            node {
              id
              plan
            }
          }
        }
      }
    }
  }
`

export const UPDATE_PAYOR = gql`
  mutation updatePayor(
    $pk: ID!
    $name: String!
    $phoneNumber: String
    $faxNumber: String
    $payorPlan: [PayorPlanInput]
    $Medicaid: Boolean
    $private: Boolean
    $city: String
    $state: String
    $streetAddress: String
    $country: String
    $zipCode: String
  ) {
    updatePayor(
      input: {
        pk: $pk
        name: $name
        phoneNumber: $phoneNumber
        faxNumber: $faxNumber
        city: $city
        state: $state
        Medicaid: $Medicaid
        private: $private
        streetAddress: $streetAddress
        country: $country
        zipCode: $zipCode
        payorPlan: $payorPlan
      }
    ) {
      details {
        id
        name
        email
        phoneNumber
        faxNumber
        city
        state
        payorplanSet {
          edges {
            node {
              id
              plan
            }
          }
        }
      }
    }
  }
`

export const GET_PAYORS = gql`
  query {
    getPayors {
      edges {
        node {
          id
          name
          phoneNumber
          faxNumber
          city
          country
          zipCode
          streetAddress
          state
          Medicaid
          private
          isActive
          payorplanSet {
            edges {
              node {
                id
                plan
              }
            }
          }
          docs {
            edges {
              node {
                path
              }
            }
          }
        }
      }
    }
  }
`

export const GET_BCBA_STAFF = gql`
  query($credentialsName: String) {
    staffs(credentialsName: $credentialsName) {
      edges {
        node {
          id
          idNo
          name
          surname
        }
      }
    }
  }
`

export async function uploadPayorDocument(payload) {
  const { payorId, fileList } = payload
  const formData = new FormData()
  formData.append('pk', payorId)
  fileList.forEach((file, index) => {
    formData.append('file', file, `file${index}`)
  })

  return reqwest({
    url: `${API_URL.replace('graphql', '')}authorization-docs/`,
    method: 'post',
    processData: false,
    data: formData,
  })
}

export const GET_CONTACT_TYPES = gql`
  query {
    getPayorContactType {
      id
      name
    }
  }
`

export const GET_PAYOR_PLANS = gql`
  query {
    payorPlan {
      edges {
        node {
          id
          plan
        }
      }
    }
  }
`

export const DELETE_PAYOR = gql`
  mutation deletePayor($id: ID!) {
    deletePayor(input: { id: $id }) {
      status
      msg
    }
  }
`
