/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-concat */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-var */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */

import { Table, Button, Icon } from 'antd'
import LoadingComponent from 'components/LoadingComponent'
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

const ScoreBoard = () => {
  const dispatch = useDispatch()
  const { loading, TargetActiveId, TargetResponse } = useSelector(state => state.sessionrecording)

  const calculateRate = (data, frequency) => {
    let totalDur = 0
    for (let i = 0; i < frequency; i++) {
      totalDur += data[i].end - data[i].start
    }
    return ((frequency * 1000) / totalDur).toFixed(3)
  }

  const deleteTrail = record => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        isBehaviorEdit: false,
        BehEditTrialCount: 1,
      },
    })
    dispatch({
      type: 'sessionrecording/DELETE_BEHAVIOR_FREQUENCY',
      payload: {
        isBehaviorEdit: false,
        BehEditTrialCount: 1,
        objectIndex: record.key - 1,
      },
    })
  }

  const editTrail = record => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        isBehaviorEdit: true,
        BehEditTrialCount: record.key,
      },
    })
  }

  if (loading) {
    return <LoadingComponent />
  }

  const dataSource = []
  let totalDur = 0

  const recording = TargetResponse[TargetActiveId]?.behaviorRecording
  if (recording.length > 0) {
    recording.map((item, index) => {
      if (item.frequency) {
        totalDur += item.end - item.start
        dataSource.push({
          key: index + 1,
          trial: index + 1,
          frequency: item.frequency,
          duration: ((item.end - item.start) / 1000).toFixed(),
          rate: ((item.frequency * 1000) / totalDur).toFixed(3),
        })
      }
    })
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'trial',
      key: 'trial',
      width: 50,
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Action',
      key: 'action',

      render: record => (
        <span>
          <Button style={{ border: 'none', padding: 0 }} onClick={() => editTrail(record)}>
            <Icon type="edit" />
          </Button>
          <Button
            style={{ border: 'none', color: 'red', padding: 0, marginLeft: 20 }}
            onClick={() => deleteTrail(record)}
          >
            <Icon type="delete" />
          </Button>
        </span>
      ),
    },
  ]

  return (
    <div className="behavior-scoreboard">
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        pagination={{ pageSize: 5 }}
        scroll={{ y: 240 }}
      />
    </div>
  )
}

export default ScoreBoard
