/* eslint-disable no-restricted-globals */
/* eslint-disable  no-lonely-if */
/* eslint-disable object-shorthand */
/* eslint-disable operator-assignment */
import { Button, Col, Form, Input, Row } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberCard from './NumberCard'
import './style.scss'

const ManualBehaviorRecording = ({ form }) => {
  const [frequency, setFrequency] = useState(0)
  const [hour, setHour] = useState(0)
  const [min, setMin] = useState(0)
  const [sec, setSec] = useState(0)
  const [isEdit, setIsEdit] = useState(false)

  const dispatch = useDispatch()
  const { Frequency, ResponseLoading } = useSelector(state => state.sessionrecording)

  const handleSubmit = e => {
    e.preventDefault()
    let endTime = 0
    if (hour > 0) endTime = endTime + hour * 60 * 60 * 1000
    if (min > 0) endTime = endTime + min * 60 * 1000
    if (sec > 0) endTime = endTime + sec * 1000
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        Frequency: Frequency + frequency,
      },
    })

    dispatch({
      type: 'sessionrecording/RECORD_BEHAVIOR_FREQUENCY',
      payload: {
        frequency: frequency,
        totalbehRecordingTime: 0,
        start: 0,
        end: endTime,
      },
    })

    setFrequency(0)
    setHour(0)
    setMin(0)
    setSec(0)
  }

  const onChangeNumber = num => {
    if (num) setFrequency(num)
  }

  const handleNumberChange = (e, type) => {
    const number = parseInt(e.target.value || 0, 10)
    if (isNaN(number)) console.log('not a number')
    else {
      if (type === 'H' && number < 24) setHour(number)
      else if (type === 'M' && number < 60) setMin(number)
      else if (type === 'S' && number < 60) setSec(number)
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    style: { textAlign: 'center' },
  }

  return (
    <div className="behavior-recording-div" style={{ marginBottom: 10 }}>
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="vertical">
        <Row>
          <Col sm={9}>
            <Form.Item
              label={
                <>
                  <span style={{ color: 'red', fontSize: 14 }}>*</span>
                  <span>Frequency</span>
                </>
              }
            >
              <NumberCard
                number={frequency}
                form={form}
                maxValue={26}
                setNumber={num => onChangeNumber(num)}
                style={{ float: 'left' }}
              />
            </Form.Item>
          </Col>
          <Col sm={15}>
            <Form.Item label="Duration" style={{ textAlign: 'left' }}>
              <Input
                onChange={e => handleNumberChange(e, 'H')}
                value={hour}
                addonAfter="H"
                type="number"
                max={23}
                style={{ width: 100 }}
              />
              <Input
                onChange={e => handleNumberChange(e, 'M')}
                value={min}
                addonAfter="M"
                type="number"
                max={59}
                style={{ width: 100 }}
              />
              <Input
                onChange={e => handleNumberChange(e, 'S')}
                value={sec}
                addonAfter="S"
                type="number"
                max={59}
                style={{ width: 100 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            type="success"
            loading={ResponseLoading}
            style={
              isEdit
                ? { width: 120, border: '1.5px solid orange', color: 'orange' }
                : { width: 120, border: '1.5px solid green', color: 'green' }
            }
            htmlType="submit"
          >
            {isEdit ? 'Update' : 'Save'}
          </Button>
        </div>
      </Form>
    </div>
  )
}

const FrequencyRate = Form.create({ name: 'time_related_controls' })(ManualBehaviorRecording)

export default FrequencyRate
