import React, { useEffect, useState, useContext } from 'react'
import { Select, Tooltip, DatePicker } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useQuery } from 'react-apollo'
import { GET_DATE_RANGE_SETTINGS } from './query'

const { Option } = Select
const { RangePicker } = DatePicker

const RangeDatePickerForGraph = ({
  date = [moment().subtract(12, 'days'), moment()],
  setDate,
  disabledDate,
  from,
  disableRedux = false,
}) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { graphRangeType } = user

  const [defaultRange, setdefaultRange] = useState(graphRangeType)
  const getQuater = moment(moment()).quarter()

  const { data, loading, error, refetch } = useQuery(GET_DATE_RANGE_SETTINGS, {
    variables: {
      user: user?.role === 'therapist' ? user?.staffObject?.school?.user?.id : user?.id,
    },
  })

  //   useEffect(() => {
  //     if (data) {
  //       if (from) {
  //         const dateFiltered = data.getDateRangeSettings.edges.filter(
  //           ({ node }) => node.rangeTitle === from,
  //         )
  //         setDefaultDate(...dateFiltered)

  //         if (dateFiltered.length) {
  //           const {
  //             isCustomRange,
  //             rangeDuration,
  //             rangeUnit,
  //             customGte,
  //             customLte,
  //           } = dateFiltered[0].node
  //           if (isCustomRange) {
  //             setDate([moment(customGte), moment(customLte)])
  //             // dispatch({
  //             //   type: 'user/SET_STATE',
  //             //   payload: {
  //             //     defaultDateDRR: [moment(customGte), moment(customLte)],
  //             //   },
  //             // })
  //           } else {
  //             const dateFrom = moment().subtract(rangeDuration, rangeUnit.toLowerCase())
  //             setDate([dateFrom, moment()])
  //             // dispatch({
  //             //   type: 'user/SET_STATE',
  //             //   payload: {
  //             //     defaultDateDRR: [dateFrom, moment()],
  //             //   },
  //             // })
  //           }
  //         } else {
  //           setdefaultRange(
  //             defaultDateRange !== 'Custom' && defaultDateRange !== 'Default'
  //               ? defaultDateRange
  //               : 'Custom',
  //           )
  //           dispatch({
  //             type: 'user/SET_STATE',
  //             payload: {
  //               defaultDateRange:
  //                 defaultDateRange !== 'Custom' && defaultDateRange !== 'Default'
  //                   ? defaultDateRange
  //                   : 'Custom',
  //             },
  //           })
  //         }
  //       }
  //     }
  //   }, [data])

  useEffect(() => {
    const filtered = generateRanges.filter(item => item.label === defaultRange)
    if (filtered.length > 0) {
      setDate([filtered[0].start, filtered[0].end])
      // dispatch({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     defaultDateDRR: [filtered[0].start, filtered[0].end],
      //   },
      // })
    }
  }, [defaultRange])

  const handleChange = value => {
    dispatch({
      type: 'user/SET_STATE',
      payload: {
        graphRangeType: value,
      },
    })

    setdefaultRange(value)
  }

  const generateRanges = [
    {
      label: 'Today',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    {
      label: 'Custom',
      start: date && moment(date[0]).startOf('day'),
      end: date && moment(date[1]).endOf('day'),
    },
    {
      label: 'Yesterday',
      start: moment()
        .add(-1, 'day')
        .startOf('day'),
      end: moment()
        .add(-1, 'day')
        .endOf('day'),
    },
    {
      label: 'This week',
      start: moment().startOf('week'),
      end: moment().endOf('week'),
    },
    {
      label: 'Last week',
      start: moment()
        .add(-1, 'week')
        .startOf('week'),
      end: moment()
        .add(-1, 'week')
        .endOf('week'),
    },
    {
      label: 'This month',
      start: moment().startOf('month'),
      end: moment().endOf('month'),
    },
    {
      label: 'Last month',
      start: moment()
        .add(-1, 'month')
        .startOf('month'),
      end: moment()
        .add(-1, 'month')
        .endOf('month'),
    },
    {
      label: 'Q1',
      start: moment(
        moment()
          .year()
          .toString(),
        'YYYY',
      )
        .quarter(1)
        .startOf('quarter'),
      end: moment(
        moment()
          .year()
          .toString(),
        'YYYY',
      )
        .quarter(1)
        .endOf('quarter'),
    },
    {
      label: 'Q2',
      start:
        getQuater < 2
          ? moment((moment().year() - 1).toString(), 'YYYY')
              .quarter(2)
              .startOf('quarter')
          : moment(
              moment()
                .year()
                .toString(),
              'YYYY',
            )
              .quarter(2)
              .startOf('quarter'),
      end:
        getQuater < 2
          ? moment((moment().year() - 1).toString(), 'YYYY')
              .quarter(2)
              .endOf('quarter')
          : moment(
              moment()
                .year()
                .toString(),
              'YYYY',
            )
              .quarter(2)
              .endOf('quarter'),
    },
    {
      label: 'Q3',
      start:
        getQuater < 3
          ? moment((moment().year() - 1).toString(), 'YYYY')
              .quarter(3)
              .startOf('quarter')
          : moment(
              moment()
                .year()
                .toString(),
              'YYYY',
            )
              .quarter(3)
              .startOf('quarter'),
      end:
        getQuater < 3
          ? moment((moment().year() - 1).toString(), 'YYYY')
              .quarter(3)
              .endOf('quarter')
          : moment(
              moment()
                .year()
                .toString(),
              'YYYY',
            )
              .quarter(3)
              .endOf('quarter'),
    },
    {
      label: 'Q4',
      start: moment()
        .add(-1, 'month')
        .startOf('month'),
      end: moment()
        .add(-1, 'month')
        .endOf('month'),
    },
    {
      label: 'This Year',
      start: moment().subtract(1, 'year'),
      end: moment(),
    },
  ]
  return (
    <>
      <Tooltip title="Select Range">
        <Select
          value={defaultRange}
          style={{
            width: 160,
            borderRadius: 4,
          }}
          onChange={handleChange}
          allowClear={false}
        >
          {/* {from && defaultDate && (
            <Option value="Default">Default {defaultDate?.node?.rangeTitle}</Option>
          )} */}
          <Option value="Custom">Custom</Option>
          <Option value="Today">Today</Option>
          <Option value="This week">This week</Option>
          <Option value="Last week">Last week</Option>
          <Option value="This month">This month</Option>
          <Option value="Last month">Last month</Option>
          <Option value="Q1">First Quarter(Q1)</Option>
          <Option value="Q2">Second Quarter(Q2)</Option>
          <Option value="Q3">Third Quarter(Q3)</Option>
          <Option value="Q4">Fourth Quarter(Q4)</Option>
          <Option value="This Year">This Year</Option>
        </Select>
      </Tooltip>
      <RangePicker
        style={{
          marginLeft: 'auto',
          width: 250,
        }}
        disabledDate={disabledDate}
        disabled={defaultRange !== 'Custom'}
        value={date}
        onChange={v => {
          setDate(v)
        }}
      />
    </>
  )
}

export default RangeDatePickerForGraph
