import React, { useState } from 'react'
import { useMutation } from 'react-apollo'
import { Button, Form, Input, notification, Row, Col } from 'antd'
import { COLORS } from 'assets/styles/globalStyles'
import { CHANGE_PASSWORD } from './query'

const ChangePasswordForm = ({ form }) => {
  const [confirmDirty, setConfirmDirty] = useState(false)

  const [changePassword] = useMutation(CHANGE_PASSWORD)

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        changePassword({
          variables: {
            oldPassword: values.oldPassword,
            newPassword: values.password,
          },
        })
          .then(result => {
            notification.success({
              message: 'Success!',
              description: result.data.changeUserPassword.message,
            })
          })
          .catch(error => {
            notification.error({
              message: 'Somthing went wrong',
            })
          })
      }
    })
  }

  const handleConfirmBlur = e => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!')
    } else {
      callback()
    }
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  return (
    <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Col sm={24} md={24} lg={18}>
        <Form onSubmit={handleSubmit} style={{ paddingTop: '2em', width: '100%' }}>
          <Form.Item
            label="Current Password"
            hasFeedback
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="form-label"
            style={{ marginBottom: 5 }}
          >
            {form.getFieldDecorator('oldPassword', {
              rules: [
                {
                  required: true,
                  message: 'Please input your current password!',
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item
            label="New Password"
            hasFeedback
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 5 }}
            className="form-label"
          >
            {form.getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            hasFeedback
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 5 }}
            className="form-label"
          >
            {form.getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={handleConfirmBlur} />)}
          </Form.Item>
          <Form.Item style={{ display: 'flex' }}>
            <Button type="primary" style={{ backgroundColor: COLORS.submitBlue }} htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}
export default Form.create()(ChangePasswordForm)
