/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Form, Button, Icon, notification } from 'antd'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 6 },
  },
}

const DynamicFields = ({
  label,
  showOnlyFirstLabel = true,
  form,
  required,
  fieldName,
  values,
  setValues,
  formItem,
  renderLabel = null,
  maxLimit = null,
}) => {
  const add = () => {
    if (maxLimit && values.length >= maxLimit) {
      notification.warn({
        message: `You can add upto ${maxLimit} ${label.toLowerCase()} only`,
      })
    } else {
      setValues(prevVals => [...prevVals, ''])
    }
  }

  const remove = index => {
    const tempVals = values.filter((val, ind) => ind !== index)
    setValues(tempVals)
  }

  const getLabel = index => {
    if (showOnlyFirstLabel) {
      return index === 0 ? label : ''
    }
    if (renderLabel && typeof renderLabel === 'function') {
      return renderLabel(index)
    }
    return label
  }

  return (
    <>
      {values.map((element, index) => (
        <Form.Item
          {...(index === 0 || !showOnlyFirstLabel ? formItemLayout : formItemLayoutWithOutLabel)}
          label={getLabel(index)}
          key={index}
        >
          {form.getFieldDecorator(`${fieldName}[${index}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            initialValue: element,
            rules: [
              {
                required,
                whitespace: true,
                message:
                  index === 0 && required
                    ? `Atleast one ${label.toLowerCase()} is needed`
                    : `Please input ${label.toLowerCase()} name or delete this field.`,
              },
            ],
          })(formItem)}
          {values.length > 1 || !required ? (
            <Icon
              className="dynamic-delete-button ml-4"
              type="minus-circle-o"
              onClick={() => remove(index)}
            />
          ) : null}
        </Form.Item>
      ))}
      <Form.Item type="flex" justify="center">
        <Button type="dashed" onClick={add} style={{ minWidth: '20rem' }}>
          <Icon type="plus" /> {`Add ${label.toLowerCase()}`}
        </Button>
      </Form.Item>
    </>
  )
}

export default DynamicFields
