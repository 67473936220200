import React, { useState, useEffect } from 'react'
import { Button, Form, notification, Tabs, Popconfirm, Menu } from 'antd'
import { useMutation, useQuery } from 'react-apollo'
import Scrollbar from 'react-custom-scrollbars'
import HeaderComponent from 'components/HeaderComponentNew'
import LoadingComponent from 'components/LoadingComponent'
import CustomProfileLayout from '../Dashboard/Clinic/CustomLayout'

import './clinicProfile.scss'
import {
  ACTIVATE_DOMAIN,
  ACTIVATE_PROGRAM_AREA,
  ACTIVATE_TARGET,
  ACTIVATE_TARGET_AREA,
  LOAD_CURRICULUM_DATA,
} from './query'

const { TabPane } = Tabs

const DisabledCurriculum = () => {
  const [programAreas, setProgramAreas] = useState([])
  const [domains, setDomains] = useState([])
  const [targetAreas, setTargetAreas] = useState([])
  const [targets, setTargets] = useState([])
  const [status, setStatus] = useState('Program Areas')

  const { data, loading, error: err } = useQuery(LOAD_CURRICULUM_DATA, {
    fetchPolicy: 'network-only',
  })

  const [activateProgramAreaData] = useMutation(ACTIVATE_PROGRAM_AREA)
  const [activateDomainData] = useMutation(ACTIVATE_DOMAIN)
  const [activateTargetAreaData] = useMutation(ACTIVATE_TARGET_AREA)
  const [activateTargetData] = useMutation(ACTIVATE_TARGET)

  useEffect(() => {
    if (data) {
      setProgramAreas(data.programArea.edges)
      setDomains(data.domain.edges)
      setTargetAreas(data.targetArea.edges)
      setTargets(data.target.edges)
    }
  }, [data])

  const activateProgramArea = id => {
    activateProgramAreaData({
      variables: {
        objectId: id,
      },
    })
      .then(result => {
        if (result.data.disableProgramArea.status) {
          const newList = programAreas.filter(item => item.node.id !== id)
          setProgramAreas(newList)
          notification.success({
            message: 'Program Area Activated',
          })
        }
      })
      .catch(error => {
        if (error.graphQLErrors) {
          error.errors.map(item => {
            return notification.error({
              message: 'Somthing went wrong',
              description: item.message,
            })
          })
        }
      })
  }

  const activateDomain = id => {
    activateDomainData({
      variables: {
        objectId: id,
      },
    })
      .then(result => {
        if (result.data.disableDomain.status) {
          const newList = domains.filter(item => item.node.id !== id)
          setDomains(newList)
          notification.success({
            message: 'Domain Activated',
          })
        }
      })
      .catch(error => {
        if (error.graphQLErrors) {
          error.errors.map(item => {
            return notification.error({
              message: 'Somthing went wrong',
              description: item.message,
            })
          })
        }
      })
  }

  const activateTargetArea = id => {
    activateTargetAreaData({
      variables: {
        objectId: id,
      },
    })
      .then(result => {
        if (result.data.disableTargetArea.status) {
          const newList = targetAreas.filter(item => item.node.id !== id)
          setTargetAreas(newList)
          notification.success({
            message: 'Target Area Activated',
          })
        }
      })
      .catch(error => {
        if (error.graphQLErrors) {
          error.errors.map(item => {
            return notification.error({
              message: 'Somthing went wrong',
              description: item.message,
            })
          })
        }
      })
  }

  const activateTarget = id => {
    activateTargetData({
      variables: {
        objectId: id,
      },
    })
      .then(result => {
        if (result.data.disableTarget.status) {
          const newList = targets.filter(item => item.node.id !== id)
          setTargets(newList)

          notification.success({
            message: 'Target Activated',
          })
        }
      })
      .catch(error => {
        if (error.graphQLErrors) {
          error.errors.map(item => {
            return notification.error({
              message: 'Somthing went wrong',
              description: item.message,
            })
          })
        }
      })
  }

  const tabs = [
    {
      key: 'Program Areas',
      title: 'Program Areas',
    },
    {
      key: 'Domains',
      title: 'Domains',
    },
    {
      key: 'Target Areas',
      title: 'Target Areas',
    },
    {
      key: 'Targets',
      title: 'Targets',
    },
  ]
  // const handleMenuChange = item => {
  //   setStatus(item.key)
  // }

  const [panelHeading, setPanelHeading] = useState('Program Areas')

  const handleMenuChange = item => {
    const selectedItem = tabs.find(tab => tab.key === item.key)
    setPanelHeading(selectedItem.title)
    setStatus(item.key)
    // if (selectedItem) setRightComponent(selectedItem.component)
  }

  const childDivStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '10px',
    border: '1px solid #efefef',
    marginTop: '2px',
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <>
      <CustomProfileLayout
        leftContent={
          <>
            <Menu
              onSelect={handleMenuChange}
              mode="inline"
              style={{ width: '250px' }}
              defaultSelectedKeys={['Program Areas']}
            >
              {tabs.map(tab => {
                return (
                  <Menu.Item key={tab.key}>
                    <span>{tab.title}</span>
                  </Menu.Item>
                )
              })}
            </Menu>
          </>
        }
        rightContent={
          <>
            <HeaderComponent title={panelHeading} showHomePath={false} pathList={['Archive']} />
            <div style={{ minHeight: 360, margin: '0 10px' }}>
              <Scrollbar style={{ height: 'calc(100vh - 150px)' }}>
                <div style={{ paddingTop: 20 }}>
                  {status === 'Program Areas' && (
                    <>
                      {programAreas.length === 0 ? (
                        <div>No Program Area Found!</div>
                      ) : (
                        programAreas.map(item => (
                          <div key={item.id} style={childDivStyle}>
                            <p style={{ fontSize: 16, fontWeight: 'bold', margin: 0 }}>
                              {item.node.name}
                            </p>
                            <Popconfirm
                              title="Are you sure you want to do this"
                              onConfirm={() => activateProgramArea(item.node.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button style={{ border: 'none', padding: 0, color: '#0B35B3' }}>
                                Activate Now
                              </Button>
                            </Popconfirm>
                          </div>
                        ))
                      )}
                    </>
                  )}
                  {status === 'Domains' && (
                    <>
                      {domains.length === 0 ? (
                        <div>No Domains Found!</div>
                      ) : (
                        domains.map(item => (
                          <div key={item.id} style={childDivStyle}>
                            <p style={{ fontSize: 16, fontWeight: 'bold', margin: 0 }}>
                              {item.node.domain}
                            </p>
                            <Popconfirm
                              title="Are you sure you want to do this"
                              onConfirm={() => activateDomain(item.node.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button style={{ border: 'none', padding: 0, color: '#0B35B3' }}>
                                Activate
                              </Button>
                            </Popconfirm>
                          </div>
                        ))
                      )}
                    </>
                  )}
                  {status === 'Target Areas' && (
                    <>
                      {targetAreas.length === 0 ? (
                        <div>No Target Area Found!</div>
                      ) : (
                        targetAreas.map(item => (
                          <div key={item.id} style={childDivStyle}>
                            <p style={{ fontSize: 16, fontWeight: 'bold', margin: 0 }}>
                              {item.node.Area}
                            </p>
                            <Popconfirm
                              title="Are you sure you want to do this"
                              onConfirm={() => activateTargetArea(item.node.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button style={{ border: 'none', padding: 0, color: '#0B35B3' }}>
                                Activate
                              </Button>
                            </Popconfirm>
                          </div>
                        ))
                      )}
                    </>
                  )}
                  {status === 'Targets' && (
                    <>
                      {targets.length === 0 ? (
                        <div>No Targets Found!</div>
                      ) : (
                        targets.map(item => (
                          <div key={item.id} style={childDivStyle}>
                            <p style={{ fontSize: 16, fontWeight: 'bold', margin: 0 }}>
                              {item.node.targetMain.targetName}
                            </p>
                            <Popconfirm
                              title="Are you sure you want to do this"
                              onConfirm={() => activateTarget(item.node.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button style={{ border: 'none', padding: 0, color: '#0B35B3' }}>
                                Activate
                              </Button>
                            </Popconfirm>
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>
              </Scrollbar>
            </div>
          </>
        }
      />
    </>
  )
}

export default Form.create()(DisabledCurriculum)
