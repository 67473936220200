/* eslint-disable react/no-unused-state */

import React, { useState, useEffect } from 'react'
import { Button, Tag, Menu, Dropdown, Popover, Drawer, Tabs, Table } from 'antd'
import { connect, useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { TiInfoLarge } from 'react-icons/ti'
import { PlusOutlined, EyeOutlined } from '@ant-design/icons'
import SessionInstructionDrawer from 'pages/parent/ParentDashboard/SessionInstructionDrawer'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import './styles.scss'

const colors = [
  '#87d068',
  '#2db7f5',
  '#87d068',
  '#2db7f5',
  '#87d068',
  '#2db7f5',
  '#87d068',
  '#2db7f5',
  '#87d068',
  '#2db7f5',
  '#87d068',
  '#2db7f5',
  '#87d068',
  '#2db7f5',
  '#87d068',
]

const EyeComponent = props => {
  const { showDrawerFilter, layout, mini } = props
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { StudentDetails, StudentId, LearnerSessionLoading, LearnerSessions } = useSelector(
    state => state.student,
  )
  const { Learners, LearnersFetched } = useSelector(state => state.learnersprogram)

  const [selectedSession, setSelectedSession] = useState(null)
  const [sessionInstrDrawer, setSessionInstrDrawer] = useState(false)
  const [selectedStudentId, setSelectedStudentId] = useState(
    localStorage.getItem('studentId') ? JSON.parse(localStorage.getItem('studentId')) : null,
  )
  const [visible, setVisible] = useState(false)
  const [activeTab, setActiveTab] = useState('home')

  useEffect(() => {
    if (Learners) {
      if (!LearnersFetched) {
        dispatch({
          type: 'learnersprogram/LOAD_DATA',
        })
      } else if (selectedStudentId) {
        dispatch({
          type: 'student/STUDENT_DETAILS',
          payload: {
            studentId: selectedStudentId,
          },
        })
      } else if (Learners.length > 0) {
        dispatch({
          type: 'student/STUDENT_DETAILS',
          payload: {
            studentId: Learners[0].node.id,
          },
        })
      }
    }
  }, [Learners])

  const getOverlayContent = () => {
    const menu = (
      <Menu>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/#/appointmentData/">
            Create Appointment
          </a>
        </Menu.Item>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/#/target/allocation">
            Target Allocate
          </a>
        </Menu.Item>
        <Menu.Item>
          <a rel="noopener noreferrer" href="/#/targetsAllocationToSession/">
            Session Planning
          </a>
        </Menu.Item>

        <Menu.Item>
          <a rel="noopener noreferrer" href="/#/reports/progress_overview">
            Reports
          </a>
        </Menu.Item>
      </Menu>
    )

    const barExtend = (
      <Dropdown overlay={menu}>
        <PlusOutlined style={{ fontSize: 20, cursor: 'pointer', color: COLORS.primary }} />
      </Dropdown>
    )

    const sessionColumns = [
      {
        title: 'Session Name',
        render: obj => obj.name || obj.sessionName?.name,
      },
      {
        title: 'Action',
        align: 'center',
        render: (text, record) => (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              this.setState({
                selectedSession: record,
                sessionInstrDrawer: true,
              })
              dispatch({
                type: 'sessionrecording/SET_STATE',
                payload: {
                  SessionId: record.id,
                  SessionDate: moment().format('YYYY-MM-DD'),
                },
              })
            }}
          >
            {record.childsessionSet.edges.length > 0 &&
              record.childsessionSet.edges[0].node.status === 'PROGRESS' &&
              'Resume Session'}
            {record.childsessionSet.edges.length > 0 &&
              record.childsessionSet.edges[0].node.status === 'COMPLETED' &&
              'See Session'}
            {record.childsessionSet.edges.length > 0 &&
              record.childsessionSet.edges[0].node.status === 'PENDING' &&
              'Start Session'}
            {record.childsessionSet.edges.length === 0 && 'Start Session'}
          </Button>
        ),
      },
    ]

    let sessionData = []
    if (LearnerSessions) {
      const data = LearnerSessions.map(({ node }) => node)
      sessionData = data.filter(x => x.targets.edges.length > 0)
    }

    const otherAssess = [
      {
        title: 'Vbmapp',
        href: '/#/therapy/vbmapps/list',
      },
      {
        title: 'ISAA',
        href: '/#/iisaAssessment',
      },
      {
        title: 'Assess Learner',
        href: '/#/therapy/assess/list',
      },
      {
        title: 'Assessment Scores',
        href: '/#/genAssessment',
      },
    ]

    return (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            transition: 'all 0.3s ease 0s',
          }}
        >
          <div className="learner-header" style={{ overflow: 'auto' }}>
            <div style={{ minHeight: 280, height: 'fit-content' }}>
              <Tabs tabBarExtraContent={barExtend}>
                <Tabs.TabPane key="details" tab="Details">
                  <div style={{ fontSize: 16, padding: 8 }}>
                    <div style={{ marginBottom: 8 }}>
                      <b>Email : </b>&nbsp; {StudentDetails?.email}
                    </div>
                    <div style={{ marginBottom: 8, display: 'flex' }}>
                      <b>
                        <span style={{ whiteSpace: 'nowrap' }}>Assessments : </span>
                      </b>
                      &nbsp;
                      <div>
                        {StudentDetails?.isPeakActive && (
                          <a rel="noopener noreferrer" href="/#/peak">
                            <Tag color={colors[0]}>Peak</Tag>
                          </a>
                        )}
                        {StudentDetails?.isCogActive && (
                          <a rel="noopener noreferrer" href="/#/cogniableAssessment">
                            <Tag color={colors[1]}>CogniAble</Tag>
                          </a>
                        )}
                        {/* {StudentDetails?.isVbmappActive && (
                        <a rel="noopener noreferrer" href="/#/cogniableAssessment">
                        <Tag color={colors[2]}>Vmbapp</Tag>
                        </a>
                      )} */}
                        {otherAssess.map((item, index) => {
                          return (
                            <a rel="noopener noreferrer" key={item.href} href={item.href}>
                              <Tag color={colors[index + 2]}>{item.title}</Tag>
                            </a>
                          )
                        })}
                      </div>
                    </div>
                    {StudentDetails?.caseManager ? (
                      <>
                        <div style={{ marginBottom: 8, display: 'flex' }}>
                          <b>
                            <span style={{ whiteSpace: 'nowrap' }}>Case Manager : </span>
                          </b>
                          &nbsp; &nbsp;
                          <div>
                            {StudentDetails?.caseManager?.name}, &nbsp;
                            {StudentDetails?.caseManager?.contactNo}
                            <br />
                            {StudentDetails?.caseManager?.email}
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>No case manager assigned</p>
                    )}
                  </div>
                </Tabs.TabPane>
                {/* <Tabs.TabPane key="session" tab="Session">
                  <div style={{ paddingTop: 6 }}>
                    <Table
                      loading={LearnerSessionLoading}
                      columns={sessionColumns}
                      dataSource={sessionData}
                      rowKey="id"
                      size="small"
                      bordered
                      pagination={false}
                    />
                  </div>
                </Tabs.TabPane> */}
                <Tabs.TabPane key="programs" tab="Programs">
                  <div style={{ padding: 8 }}>
                    {StudentDetails?.programArea?.edges.map((areaNode, index) => (
                      <div key={areaNode.node.id}>
                        {areaNode.node.isActive && (
                          <div style={{ fontSize: 16, fontWeight: 600 }}>
                            {index + 1} .
                            <Button
                              type="link"
                              onClick={() => {
                                dispatch({
                                  type: 'student/SET_STATE',
                                  payload: {
                                    ProgramAreaId: areaNode.node.id,
                                    ProgramArea: areaNode.node,
                                  },
                                })
                                window.location.href = '/#/target/allocation'
                              }}
                            >
                              {areaNode.node.name}
                            </Button>
                            <Button
                              onClick={() => {
                                dispatch({
                                  type: 'student/SET_STATE',
                                  payload: {
                                    ProgramAreaId: areaNode.node,
                                    ProgramArea: areaNode.node,
                                    activeTab: 'goal',
                                  },
                                })
                                dispatch({
                                  type: 'student/SET_DRAWER_WIDTH',
                                  payload: {
                                    width: DRAWER.learnerL2,
                                  },
                                })
                                handleVisibleChange(false)
                                showDrawerFilter()
                              }}
                              style={{ padding: 0 }}
                              type="link"
                            >
                              <EyeOutlined />
                            </Button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleVisibleChange = e => {
    setVisible(e)
  }

  return (
    <div>
      <Popover
        visible={visible}
        placement={layout === 'profile' ? 'rightTop' : 'bottom'}
        onVisibleChange={handleVisibleChange}
        content={getOverlayContent()}
      >
        {mini ? (
          <Button style={{ color: '#777777' }} type="link">
            <EyeOutlined style={{ fontSize: 18, fontWeight: 600 }} />
          </Button>
        ) : (
          <Button style={{ padding: 0 }} type="link">
            <TiInfoLarge style={{ fontSize: 24, marginTop: 7, fontWeight: 600 }} />
          </Button>
        )}
      </Popover>
      <Drawer
        width={500}
        placement="right"
        title="Session Preview"
        closable
        onClose={() => setSessionInstrDrawer(false)}
        visible={sessionInstrDrawer}
      >
        <SessionInstructionDrawer session={selectedSession} />
      </Drawer>
    </div>
  )
}

export default EyeComponent
