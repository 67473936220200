import gql from 'graphql-tag'

export const STUDENT_QUERY = gql`
  query StudentDetails($studentId: ID!) {
    student(id: $studentId) {
      id
      firstname
      lastname
      currentAddress
      dob
      email
      parentMobile
      createdAt
    }
  }
`
export const COLOR_UPDATE = gql`
  mutation vbmappUpdatecolorAssessment($pk: ID!, $color: String) {
    vbmappUpdatecolorAssessment(input: { pk: $pk, color: $color }) {
      status
      details {
        id
        testNo
        date
        color
        student {
          id
          firstname
          __typename
        }
      }
    }
  }
`

export const GET_VBMAPP_REPORT = gql`
  fragment studentAndScoreDetails on VbmappMasterRecord {
    id
    color
    date
    createdDate
    testNo
    student {
      id
      firstname
      lastname
      dob
    }
    user {
      id
      username
      firstName
      lastName
    }
  }

  fragment milestoneInfo on VbmappGetAllReportPayload {
    data {
      total
      details {
        masterRecord {
          ...studentAndScoreDetails
        }
        records {
          edges {
            node {
              id
              date
              questionNum
              score
              groups {
                id
                groupName
              }
            }
          }
        }
      }
    }
  }

  fragment barrierInfo on VbmappGet4ReportPayload {
    data {
      total
      details {
        masterRecord {
          ...studentAndScoreDetails
        }
        records {
          edges {
            node {
              id
              date
              questionNum
              score
            }
          }
        }
      }
    }
  }

  fragment transitionAssessmentInfo on VbmappGet4ReportPayload {
    data {
      total
      details {
        masterRecord {
          ...studentAndScoreDetails
        }
        records {
          edges {
            node {
              id
              date
              questionNum
              score
            }
          }
        }
      }
    }
  }

  fragment taskAnalysisInfo on VbmappGet4ReportPayload {
    data {
      total
      details {
        masterRecord {
          ...studentAndScoreDetails
        }
        records {
          edges {
            node {
              id
              date
              questionNum
              score
              code
              groups {
                id
                groupName
              }
            }
          }
        }
      }
    }
  }

  mutation(
    $milestonesArea: ID!
    $barriersArea: ID!
    $transitionAssessmentArea: ID!
    $taskAnalysisArea: ID!
    $assessmentId: ID!
  ) {
    milestones: vbmappGetAllReport(input: { master: $assessmentId, area: $milestonesArea }) {
      ...milestoneInfo
    }
    barriers: vbmappGet4Report(input: { master: $assessmentId, area: $barriersArea }) {
      ...barrierInfo
    }
    transitionAssessment: vbmappGet4Report(
      input: { master: $assessmentId, area: $transitionAssessmentArea }
    ) {
      ...transitionAssessmentInfo
    }
    taskAnalysis: vbmappGet4Report(input: { master: $assessmentId, area: $taskAnalysisArea }) {
      ...taskAnalysisInfo
    }
  }
`

export const GET_VBMAPP_AREAS = gql`
  {
    vbmappAreas {
      id
      areaName
    }
  }
`

export const GET_VBMAPP_ASSESMENTS = gql`
  query vbmappGetAssessments($studentId: ID!) {
    vbmappGetAssessments(student: $studentId) {
      edges {
        node {
          id
          testNo
          date
        }
      }
    }
  }
`
export const getTotalScoreByLevel = gql`
  query vbmappAssessmentLevelTotalScores(
    $studentID: ID!
    $assessment: ID!
    $group: ID!
    $questionNumbers: [Int]!
  ) {
    vbmappAssessmentLevelTotalScores(
      student: $studentID
      assessment: $assessment
      questionNumbers: $questionNumbers
      groupId: $group
    ) {
      total
      testNo
      __typename
    }
  }
`
export const GET_VBMAPP_SCORE = gql`
  query vbmappAssessmentTotalScores($studentID: ID!, $assessment: ID!, $levelNo: Int) {
    vbmappAssessmentTotalScores(student: $studentID, assessment: $assessment, levelNo: $levelNo) {
      total
      assessment {
        id
        testNo
        date
        createdDate
        student {
          id
          firstname
          lastname
          dob
        }
      }
    }
  }
`
