/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Drawer,
  Table,
  Tooltip,
  notification,
  Popconfirm,
  Divider,
  Form,
  Icon,
  Button,
  DatePicker,
  Select,
  Input,
  Modal,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { DRAWER, COLORS } from 'assets/styles/globalStyles'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useQuery, useMutation } from 'react-apollo'
import { CREATE_PHASE, GET_BEHAVIOR_TEMPLATES } from './query'
import _ from 'lodash'
import PhaseModal from 'pages/ProgramBook/TargetAllocation/phaseModal'
const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const card = {
  padding: '12px',
  marginBottom: 10,
  boxShadow: '0 3px 5px rgba(22, 22, 53, 0.16)',
}

const { TextArea } = Input
const { Option } = Select

const PhaseTable = props => {
  const { setPhaseData, selectedBehavior, form, date } = props
  console.log('props>>>', props)
  const student = useSelector(state => state.student)
  const { StudentId } = student
  const [dataSource, setDataSource] = useState([])

  const [addPhaseModal, setAddPhaseModal] = useState(false)
  const [phaseList, setPhaseList] = useState([])
  const [phaseObject, setphaseObject] = useState(null)
  const [submit, setSubmit] = useState(false)

  const [updatePhase, { data, loading, error }] = useMutation(CREATE_PHASE)
  const { data: tempData, loading: tempLoading, error: tempError, refetch } = useQuery(
    GET_BEHAVIOR_TEMPLATES,
    {
      variables: {
        student: StudentId,
      },
    },
  )
  useEffect(() => {
    if (tempData) {
      console.log('selected Target>>>')
      const tempPhase = []
      tempData.getBehaviorTemplates?.edges.forEach(({ node }) => {
        if (node.phase.edges.length) {
          node.phase.edges.forEach(item => {
            tempPhase.push({
              key: item.node.id,
              date: moment(item.node.date),
              content: item.node.content,
              templateName: node.templateName,
              tempId: node.id,
            })
          })
        }
      })
      const filtered = tempPhase.filter(
        item => moment(item.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'),
      )
      console.log('filtered>>>', filtered, date, tempPhase)
      setPhaseList(tempPhase)
    }
  }, [tempData])

  // useEffect(()=>{

  //   if(phaseList){
  //     console.log("phaseline>>>>>",phaseList);
  //     if(submit){
  //       handleSubmit(submit)
  //     }

  //   }

  // },[phaseList])

  useEffect(() => {
    if (phaseList) {
      //   console.log('phaseData>>>', phaseData)
      console.log('phaseList>>>', phaseList)
    }
  }, [phaseList])

  const phaseCard = item => {
    return (
      <div key={Math.random()} style={card}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ fontSize: 12, color: 'black', fontWeight: '600' }}>
            Template : {item.templateName}
          </p>
          <p style={{ fontSize: 12, color: 'black', fontWeight: '600' }}>
            Date : {moment(item.date).format('YYYY-MM-DD')}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ fontSize: 12 }}>{item.content}</span>
          <div style={{ minWidth: 70 }}>
            <Button
              style={{ color: '#46bd84', paddingRight: 0 }}
              type="link"
              onClick={() => {
                setphaseObject(item)
                setAddPhaseModal(true)
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Are you sure you don't want this record?"
              onConfirm={() => {
                const temp = phaseList.filter(ph => ph.key !== item.key)
                setPhaseList(temp)
                handleDelete(item)
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                style={{ marginLeft: 'auto', color: COLORS.danger, paddingRight: 0 }}
                type="link"
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    )
  }

  const handleDelete = item => {
    console.log('item>>', item, phaseList)
    const f = phaseList.filter(i => i.tempId === item.tempId)
    // updatePhase({
    //   variables: {
    //     pk: v.template,
    //     studentId: StudentId,
    //     behavior: selectedBehavior,
    //     phase: phaseResponse,
    //   },
    // })
    //   .then(res => {
    //     console.log('res===>', res.data.updateTargetAllocate2?.target?.phaset?.edges)
    //     notification.success({
    //       message: 'Phase Change updated successfully',
    //     })
    //     setAddPhaseModal(false)
    //   })
    //   .catch(er => console.error(er))
  }

  const handlePhaseSubmit = e => {
    form.validateFields(['date', 'template', 'note'], (customErr, v) => {
      if (!customErr) {
        console.log('values>>>', v, phaseObject)
        if (phaseObject) {
          console.log('phaseObject>>>', phaseObject, phaselist)
        } else {
          const filterTemp = []
          phaseList.forEach(item => {
            if (item.tempId === v.template) {
              filterTemp.push({
                date: moment(item.date).format('YYYY-MM-DD'),
                content: item.content,
              })
            }
          })
          const phaseResponse = [
            ...filterTemp,
            {
              date: moment(v.date).format('YYYY-MM-DD'),
              content: v.note,
            },
          ]
          updatePhase({
            variables: {
              pk: v.template,
              studentId: StudentId,
              behavior: selectedBehavior,
              phase: phaseResponse,
            },
          })
            .then(res => {
              console.log('res===>', res.data.updateTargetAllocate2?.target?.phaset?.edges)
              notification.success({
                message: 'Phase Change updated successfully',
              })
              setAddPhaseModal(false)
            })
            .catch(er => console.error(er))
        }
      }
    })
  }

  return (
    <div>
      <div style={boxCard}>
        <Divider orientation="left">Phase Change</Divider>
        {phaseList.length ? (
          phaseList.map(item => phaseCard(item))
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>
            No PhaseLines Found!!
          </div>
        )}
        <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
          <Button
            type="dashed"
            onClick={() => {
              setAddPhaseModal(true)
              setphaseObject(null)
            }}
            style={{ width: '60%' }}
          >
            <Icon type="plus" /> Add Field
          </Button>
        </Form.Item>
      </div>
      {/* <Table columns={phaseColumns} dataSource={dataSource} bordered /> */}

      <Modal
        visible={addPhaseModal}
        title="Add New Phase"
        onCancel={() => {
          setAddPhaseModal(false)
          setphaseObject(null)
        }}
        zIndex={1001}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={e => handlePhaseSubmit(e)}
            // loading={createBehLoading}
            style={{ backgroundColor: COLORS.submitBlue }}
          >
            {phaseObject ? 'Edit' : 'Create'}
          </Button>,
        ]}
        width={700}
        destroyOnClose
      >
        <Form layout="vertical">
          <Form.Item label="Date" name="Date">
            {form.getFieldDecorator('date', {
              initialValue: phaseObject
                ? phaseObject
                  ? moment(phaseObject.date)
                  : moment(date)
                : moment(),
              rules: [{ required: true, message: 'Please provide date' }],
            })(
              <DatePicker
                format="YYYY-MM-DD"
                //   disabledDate={disabledDate}
                style={{ width: '40%' }}
                placeholder="Date"
              />,
            )}
          </Form.Item>
          <Form.Item label="Template:">
            {form.getFieldDecorator('template', {
              initialValue: phaseObject && phaseObject.tempId,
              rules: [{ required: true, message: 'Please Select Template' }],
            })(
              <Select
                placeholder="Select Template"
                showSearch
                loading={tempLoading}
                disabled={phaseObject}
              >
                {tempData &&
                  tempData.getBehaviorTemplates.edges.map(({ node }) => (
                    <Option value={node.id}>{node.templateName}</Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="Note" key="note">
            {form.getFieldDecorator(`note`, {
              initialValue: phaseObject ? phaseObject.content : '',
              rules: [{ required: true, message: 'Please give note or delete this field.' }],
            })(
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} rows={2} placeholder="Note" />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Form.create()(PhaseTable)
