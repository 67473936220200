/* eslint-disable import/prefer-default-export */
import React, { useContext, useState } from 'react'

const Context = React.createContext()

export const useMastSeq = () => {
  return useContext(Context)
}

export const MastSeqProvider = ({ children }) => {
  const [selectedMasterySet, setSelectedMasterySet] = useState(null)
  const [selectedCriterPhase, setSelectedCriterPhase] = useState(null)
  const [addPhaseTargetModal, setAddPhaseTargetModal] = useState(false)
  const [criteriaTargetObj, setCriteriaTargetObj] = useState(null)
  const [criteriaEditModal, setCriteriaEditModal] = useState(false)

  const value = {
    selectedMasterySet,
    setSelectedMasterySet,
    selectedCriterPhase,
    setSelectedCriterPhase,
    addPhaseTargetModal,
    setAddPhaseTargetModal,
    criteriaTargetObj,
    setCriteriaTargetObj,
    criteriaEditModal,
    setCriteriaEditModal,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}
