import React, { useEffect, useState } from 'react'
import {
  List,
  Button,
  Skeleton,
  Card,
  Input,
  notification,
  Row,
  Col,
  Modal,
  Popconfirm,
  Switch,
  Radio,
} from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-apollo'
import './form.css'
import LoadingComponent from '../staffProfile/LoadingComponent'

const SCHOOL_LOCARIONS = gql`
  query($isActive: Boolean) {
    schoolLocation(isActive: $isActive) {
      edges {
        node {
          id
          location
          isActive
          schoolSet {
            edges {
              node {
                id
                schoolName
              }
            }
          }
        }
      }
    }
  }
`

const CREATE_LOCATION = gql`
  mutation clinicLocation($location: String!) {
    clinicLocation(input: { location: $location }) {
      location {
        id
        location
        isActive
      }
      __typename
    }
  }
`

const UPDATE_LOCATION = gql`
  mutation($id: ID!, $location: String!, $isActive: Boolean) {
    updateLocation(input: { id: $id, location: $location, isActive: $isActive }) {
      location {
        id
        location
        isActive
      }
    }
  }
`

const Location = () => {
  const [newLocationName, setNewLocationName] = useState('')
  const [updateLocationId, setUpdateLocationId] = useState()
  const [updateLocationName, setUpdateLocationName] = useState()
  const [isAddLocationDrawerOpened, setIsAddLocationDrawerOpened] = useState(false)
  const [isEditLocationDrawerOpened, setIsEditLocationDrawerOpened] = useState(false)
  const [isActiveLocation, setIsActiveLocation] = useState(true)
  const { data, loading, error, refetch } = useQuery(SCHOOL_LOCARIONS, {
    variables: { isActive: isActiveLocation },
  })

  useEffect(() => {
    refetch()
  }, [isActiveLocation, refetch])

  const [
    createLocation,
    { data: createLocationData, error: createLoactionError, loading: createLocationLoading },
  ] = useMutation(CREATE_LOCATION, {
    variables: {
      location: newLocationName,
    },
    update(cache, { data: updateData }) {
      const schoolLocation = cache.readQuery({ query: SCHOOL_LOCARIONS })
      cache.writeQuery({
        query: SCHOOL_LOCARIONS,
        data: {
          schoolLocation: {
            edges: [
              { node: updateData.clinicLocation.location },
              ...schoolLocation.schoolLocation.edges,
            ],
          },
        },
      })
    },
  })

  const [
    updateLocation,
    { data: updateLocationData, error: updateLoactionError, loading: updateLocationLoading },
  ] = useMutation(UPDATE_LOCATION)

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Location data loading faild',
      })
    }
  }, [error])

  useEffect(() => {
    if (createLocationData) {
      setNewLocationName('')
      setIsAddLocationDrawerOpened(false)
      refetch()
      notification.success({
        message: 'New location created sucessfully',
      })
    }
    if (createLoactionError) {
      notification.error({
        message: 'New location create failed',
      })
    }
  }, [createLocationData, createLoactionError])

  useEffect(() => {
    if (updateLocationData) {
      notification.success({
        message: 'Location update sucessfully',
      })
      setIsEditLocationDrawerOpened(false)
      setUpdateLocationId(null)
      setUpdateLocationName(null)
    }
    if (updateLoactionError) {
      notification.error({
        message: updateLoactionError.message,
      })
    }
  }, [updateLocationData, updateLoactionError])

  const handleAddNewLocation = () => {
    if (newLocationName.length > 0) {
      createLocation()
    } else {
      notification.info({
        message: 'Please type the location address',
      })
    }
  }

  const handleUpdateLocation = () => {
    if (updateLocationName.length > 0) {
      updateLocation({
        variables: {
          id: updateLocationId,
          location: updateLocationName,
        },
      })
    } else {
      notification.info({
        message: 'Please type the location address',
      })
    }
  }

  const handleUpdateIsActiveLocation = item => {
    if (item) {
      updateLocation({
        variables: {
          id: item.id,
          location: item.location,
          isActive: !item.isActive,
        },
      }).then(res => {
        refetch()
      })
    }
  }

  const switchHandler = e => {
    console.log(e, 'switch handler')
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'right',
        }}
      >
        <div>
          <Radio.Group
            size="medium"
            buttonStyle="solid"
            value={isActiveLocation ? 'active' : 'in-active'}
            style={{ margin: 'auto 10px' }}
            onChange={() => {
              setIsActiveLocation(!isActiveLocation)
            }}
          >
            <Radio.Button value="active">Active</Radio.Button>
            <Radio.Button value="in-active">In-active</Radio.Button>
          </Radio.Group>
          <Button
            type="primary"
            style={{ marginBottom: 10, backgroundColor: '#0B35B3' }}
            onClick={() => setIsAddLocationDrawerOpened(true)}
          >
            Add Location
            <PlusOutlined />
          </Button>
        </div>
      </div>
      <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Col sm={24} md={24} lg={24}>
          {loading && <LoadingComponent />}
          {!loading && (
            <Card style={{ marginTop: 20 }}>
              <List
                style={{ zoom: 1.1 }}
                size="small"
                loading={loading}
                itemLayout="horizontal"
                dataSource={data?.schoolLocation.edges}
                renderItem={item => (
                  <List.Item
                    key={item.node.id}
                    actions={[
                      <Button
                        type="link"
                        onClick={() => {
                          setIsEditLocationDrawerOpened(true)
                          setUpdateLocationId(item.node.id)
                          setUpdateLocationName(item.node.location)
                        }}
                        disabled={!item.node.schoolSet.edges[0].node}
                      >
                        <EditOutlined />
                      </Button>,
                      <Popconfirm
                        title={`Are you sure to ${
                          item.node.isActive ? 'inactivate' : 'activate'
                        } this location?`}
                        okText="Yes"
                        cancelText="No"
                        disabled={!item.node.schoolSet.edges[0].node}
                        onConfirm={() => handleUpdateIsActiveLocation(item.node)}
                      >
                        <Switch
                          checked={item.node.isActive}
                          onClick={() => switchHandler(item.node)}
                        />
                      </Popconfirm>,
                    ]}
                  >
                    <Skeleton avatar title={false} loading={loading} active>
                      <List.Item.Meta title={item.node.location} />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Card>
          )}
        </Col>
      </Row>

      <Modal
        title="Update Location"
        width={600}
        visible={isEditLocationDrawerOpened}
        onCancel={() => setIsEditLocationDrawerOpened(false)}
        footer={null}
      >
        <div>
          <div style={{ marginTop: 15 }}>
            <Input
              size="large"
              placeholder="Type new location name"
              value={updateLocationName}
              onChange={e => setUpdateLocationName(e.target.value)}
            />
            <div style={{ marginTop: 20, textAlign: 'end' }}>
              <Button
                type="primary"
                style={{ backgroundColor: '#0B35B3' }}
                onClick={handleUpdateLocation}
                size="large"
                loading={updateLocationLoading}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Add new Location"
        width={600}
        onCancel={() => setIsAddLocationDrawerOpened(false)}
        visible={isAddLocationDrawerOpened}
        footer={null}
      >
        <div>
          <div style={{ marginTop: 15 }}>
            <Input
              size="large"
              placeholder="Type new location name"
              value={newLocationName}
              onChange={e => setNewLocationName(e.target.value)}
            />
            <div style={{ marginTop: 20, textAlign: 'end' }}>
              <Button
                type="primary"
                style={{ backgroundColor: '#0B35B3' }}
                onClick={handleAddNewLocation}
                size="large"
                loading={createLocationLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Location
