/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export const CLINIC_QUERY = gql`
  query {
    clinicAllDetails(isActive: true) {
      status
      details {
        id
        schoolName
        isActive
        email
      }
    }
  }
`

export const CURRENCY = gql`
  query {
    currency {
      id
      currency
      symbol
    }
  }
`

export async function createCoupon(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createDiscountcoupon(
          $discountAmount: Float!
          $coupon: String!
          $currency: String!
          $validFrom: DateTime!
          $validTo: DateTime!
          $clinic: ID
          $isClinicDefault: Boolean
        ) {
          createDiscountcoupon(
            input: {
              discountAmount: $discountAmount
              coupon: $coupon
              currency: $currency
              validFrom: $validFrom
              validTo: $validTo
              clinic: $clinic
              isClinicDefault: $isClinicDefault
            }
          ) {
            discountcoupon {
              id
              coupon
              discountAmount
              validFrom
              validTo
              createAt
              currency
              isActive
              updateAt
              clinic {
                id
                schoolName
              }
              isClinicDefault
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function updateCoupon(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateDiscountcoupon(
          $id: ID!
          $discountAmount: Float
          $coupon: String
          $currency: String
          $validFrom: DateTime
          $validTo: DateTime
          $clinic: ID
          $isClinicDefault: Boolean
        ) {
          updateDiscountcoupon(
            input: {
              pk: $id
              discountAmount: $discountAmount
              coupon: $coupon
              currency: $currency
              validFrom: $validFrom
              validTo: $validTo
              clinic: $clinic
              isClinicDefault: $isClinicDefault
            }
          ) {
            discountcoupon {
              id
              coupon
              discountAmount
              validFrom
              validTo
              createAt
              currency
              isActive
              updateAt
              clinic {
                id
                schoolName
              }
              isClinicDefault
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}

export async function getCoupons(payload) {
  return apolloClient
    .query({
      query: gql`
        query allCoupon(
          $isActive: Boolean
          $first: Int
          $last: Int
          $after: String
          $before: String
          $coupon: String
        ) {
          allCoupon(
            isActive: $isActive
            first: $first
            last: $last
            after: $after
            before: $before
            coupon_Icontains: $coupon
          ) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                coupon
                discountAmount
                validFrom
                validTo
                createAt
                currency
                isActive
                updateAt
                clinic {
                  id
                  schoolName
                }
                isClinicDefault
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result.data)
    .catch(error => console.log(error))
}
