import { all, call, put, takeEvery } from 'redux-saga/effects'
// import { push } from 'react-router-redux';
// import { notification } from 'antd'
import {
  GetStudentNameById,
  GetUserDetailsByUsername,
  login,
  logout,
  RefreshToken,
  StaffIdFromUserId,
  StudentIdFromUserId,
} from 'services/user'
import { clinicDetails } from 'services/school'
import { getStudentDetails } from 'services/student'
// import { GraphQLClient } from 'graphql-request'
import actions from './actions'

export function* LOGIN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(login, payload)
  console.log('payload123', payload)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: response.tokenAuth.user.id,
        authorized: true,
        loading: false,
        firstLogin: response.tokenAuth.firstLogin || '',
        practroRedirect: payload.practroRedirect,
        role:
          response.tokenAuth.user.groups.edges.length > 0
            ? response.tokenAuth.user.groups.edges[0].node.name
            : '',
      },
    })

    if (response.tokenAuth.user.groups.edges.length > 0) {
      if (response.tokenAuth.user.groups.edges[0].node.name === 'parents') {
        localStorage.setItem(
          'studentId',
          JSON.stringify(response.tokenAuth.user.studentsSet.edges[0].node.id),
        )
        localStorage.setItem(
          'userId',
          JSON.stringify(response.tokenAuth.user.studentsSet.edges[0].node.parent.id),
        )
        yield put({
          type: 'user/SET_STATE',
          payload: {
            studentId: response.tokenAuth.user.studentsSet.edges[0].node.id,
            parentName: response.tokenAuth.user.studentsSet.edges[0].node.parentName,
          },
        })

        const result4 = yield call(getStudentDetails, {
          student: response.tokenAuth.user.studentsSet.edges[0].node.id,
        })

        if (result4) {
          yield put({
            type: 'student/SET_STATE',
            payload: {
              StudentId: result4.data.student?.id,
              StudentName: result4.data.student?.firstname,
              isCogActive: result4.data.student?.isCogActive,
              isPeakActive: result4.data.student?.isPeakActive,
              StudentDetails: result4.data.student,
            },
          })
        }
      }

      if (response.tokenAuth.user.groups.edges[0]?.node.name === 'therapist') {
        localStorage.setItem('userId', JSON.stringify(response.tokenAuth.user.id))
        if (response.tokenAuth.user.staffSet?.edges.length > 0) {
          localStorage.setItem(
            'therapistId',
            JSON.stringify(response.tokenAuth.user.staffSet.edges[0].node.id),
          )

          yield put({
            type: 'user/SET_STATE',
            payload: {
              staffId: response.tokenAuth.user.staffSet.edges[0].node.id,
              staffName: response.tokenAuth.user.staffSet.edges[0].node.name,
              staffCountry: response.tokenAuth.user.staffSet.edges[0].node.country,
              staffState: response.tokenAuth.user.staffSet.edges[0].node.state,
              staffObject: response.tokenAuth.user.staffSet.edges[0].node,
              credentials: response.tokenAuth.user.staffSet.edges[0].node.credentials,
            },
          })
        }
      }

      if (response.tokenAuth.user.groups.edges[0].node.name === 'school_admin') {
        const result4 = yield call(clinicDetails)
        if (result4) {
          localStorage.setItem('userId', JSON.stringify(result4.data.schoolDetail.id))
          localStorage.setItem('schoolId', JSON.stringify(result4.data.schoolDetail.id))
          yield put({
            type: 'user/SET_STATE',
            payload: {
              clinicName: result4.data.schoolDetail.schoolName,
              clinicCountry: result4.data.schoolDetail.country?.name,
            },
          })

          yield put({
            type: 'school/SET_STATE',
            payload: {
              ...result4.data.schoolDetail,
            },
          })
        }
      }

      if (response.tokenAuth.user.groups.edges[0].node.name === 'course_admin') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            courseUser: response.tokenAuth.user,
          },
        })
        yield put({
          type: 'settings/SET_STATE',
          payload: {
            isMenuVisible: false,
          },
        })
      }
    }

    yield put({
      type: 'menu/GET_DATA',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      // role:JSON.parse(localStorage.getItem('role')),
    },
  })

  const response = yield call(RefreshToken)

  if (response && response.refreshToken) {
    const result = yield call(GetUserDetailsByUsername, response.refreshToken.payload.username)

    if (result && result.data.getuser?.edges.length > 0) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          id: result.data.getuser.edges[0].node.id,
          authorized: true,
          loading: false,
          role: result.data.getuser.edges[0].node.groups.edges[0].node.name,
          // role: result.data.getuser.edges[0].node.groups[0].node.id,
        },
      })

      if (result.data.getuser.edges[0].node.groups.edges[0]?.node.name === 'parents') {
        const result2 = yield call(StudentIdFromUserId, result.data.getuser.edges[0].node.id)

        if (result2 && result2.data.students?.edges.length > 0) {
          yield put({
            type: 'user/SET_STATE',
            payload: {
              studentId: result2.data.students.edges[0].node.id,
              parentName: result2.data.students.edges[0].node.parentName,
            },
          })
        }
      }

      if (result.data.getuser.edges[0].node.groups.edges[0].node.name === 'therapist') {
        const result3 = yield call(StaffIdFromUserId, result.data.getuser.edges[0].node.id)

        if (result3 && result3.data.staffs?.edges.length > 0) {
          yield put({
            type: 'user/SET_STATE',
            payload: {
              staffId: result3.data.staffs.edges[0].node.id,
              staffName: result3.data.staffs.edges[0].node.name,
              staffCountry: result3.data.staffs.edges[0].node.country,
              staffState: result3.data.staffs.edges[0].node.state,
              staffObject: result3.data.staffs.edges[0].node,
              credentials: result3.data.staffs.edges[0].node?.credentials,
            },
          })
        }
      }

      if (result.data.getuser.edges[0].node.groups.edges[0].node.name === 'school_admin') {
        const result4 = yield call(clinicDetails)

        if (result4 && result4.data?.schoolDetail) {
          yield put({
            type: 'user/SET_STATE',
            payload: {
              clinicName: result4.data.schoolDetail.schoolName,
              clinicCountry: result4.data.schoolDetail.country?.name,
            },
          })

          yield put({
            type: 'school/SET_STATE',
            payload: {
              ...result4.data.schoolDetail,
            },
          })
        }
      }

      if (result.data.getuser.edges[0].node.groups.edges[0].node.name === 'course_admin') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            courseUser: result.data.getuser.edges[0].node,
          },
        })
        yield put({
          type: 'settings/SET_STATE',
          payload: {
            isMenuVisible: false,
          },
        })
      }
    }
  } else {
    localStorage.clear()
    LOGOUT()
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      loading: false,
      role: '',
    },
  })
  yield put({
    type: 'family/SET_STATE',
    payload: {
      familyMembers: [],
      loading: false,
    },
  })
  yield call(logout)
  window.location.reload()
  localStorage.clear()
}

export function* GET_STUDENT_NAME() {
  const response = yield call(GetStudentNameById, localStorage.getItem('studentId'))
  if (response && response.data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        studentName: response.data.student.firstname,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.GET_STUDENT_NAME, GET_STUDENT_NAME),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
