/* eslint-disable no-plusplus */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { getQuestionareChoices, getQuestions, saveQuestionareAnswer } from 'services/IISCA'
import actions from './actions'

export function* GET_QUESTIONARE_CHOICES_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      Questionaire: [],
    },
  })

  const response = yield call(getQuestionareChoices, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        Questionaire: response.data.getQuestionare,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_QUESTION_1_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      Question1: [],
    },
  })

  const response = yield call(getQuestions, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        Question1: response.data.getQuestion,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_QUESTION_2_DATA({ payload }) {
  console.log('-----------GET_QUESTION_2_DATA--------------')
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      Question2: [],
    },
  })

  const response = yield call(getQuestions, payload)
  console.log('-----------getQuestions--------------')
  console.log(response)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        Question2: response.data.getQuestion,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_QUESTION_1_ANSWER(payload) {
  console.log('UPDATE_QUESTION_1_ANSWER-----------')
  console.log(payload)
  const {
    payload: { currentAnswer },
  } = payload
  console.log(currentAnswer)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      Question1Answer: { currentAnswer },
    },
  })
}

export function* SAVE_QUESTIONARE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      createFormLoading: true,
    },
  })

  const response = yield call(saveQuestionareAnswer, payload)

  if (response) {
    notification.success({
      message: 'Success!!',
      description: 'Assessment Created Successfully!',
    })
  }
}

export function* SAVE_USER_DATA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      SelectedUser: payload,
    },
  })

  // const response = yield call(getQuestionareChoices, payload)

  // if (response) {
  //   yield put({
  //     type: actions.SET_STATE,
  //     payload: {
  //       SelectedUser: response.data.getQuestionare,
  //     },
  //   })
  // }

  // yield put({
  //   type: actions.SET_STATE,
  //   payload: {
  //     loading: false,
  //   },
  // })
}

export function* SAVE_SITUATION_DATA({ payload }) {
  console.log(payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      Situation: payload.data,
    },
  })
}

export function* SAVE_BEHAVIOR_DATA({ payload }) {
  console.log('SAVE_BEHAVIOR_DATA===================================================000')
  console.log(payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      Behavior: payload.data,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_DATA, GET_QUESTIONARE_CHOICES_DATA),
    takeEvery(actions.LOAD_QUESTION_1_DATA, GET_QUESTION_1_DATA),
    takeEvery(actions.LOAD_QUESTION_2_DATA, GET_QUESTION_2_DATA),
    takeEvery(actions.UPDATE_QUESTION_1_ANSWER, UPDATE_QUESTION_1_ANSWER),
    takeEvery(actions.SAVE_QUESTIONARE, SAVE_QUESTIONARE),
    takeEvery(actions.LOAD_USER_DATA, SAVE_USER_DATA),
    takeEvery(actions.LOAD_SITUATION_DATA, SAVE_SITUATION_DATA),
    takeEvery(actions.LOAD_BEHAVIOR_DATA, SAVE_BEHAVIOR_DATA),
  ])
}
