/* eslint-disable prefer-template */
/* eslint-disable  react-hooks/rules-of-hooks */
/* eslint-disable no-array-constructor */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import { STUDENT_QUERY } from './queries'
import gql from 'graphql-tag'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer'
import { Typography, Row, Layout, Col, Tabs, Card, Table, Tag, Select, Button } from 'antd'
import moment from 'moment'
import LoadingComponent from 'components/LoadingComponent'
import { calculateAge } from '../../utilities'
import { COLORS } from 'assets/styles/globalStyles'
const LOGO = gql`
  query {
    schoolDetail {
      logo
      schoolName
      address
    }
  }
`

const dateFormat = 'YYYY-MM-DD'
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const page = {
  padding: '20px 15px',
}
const section = {
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}

const NetworkPDF = ({ title, start, end, studentId, image }) => {
  console.log('goalsImages>>>', image, start, end, studentId)
  const { data, error, loading } = useQuery(LOGO, { fetchPolicy: 'no-cache' })
  const { data: stData } = useQuery(STUDENT_QUERY, {
    query: STUDENT_QUERY,
    variables: {
      studentId,
    },
    fetchPolicy: 'network-only',
  })
  const [logo, setLogo] = useState()
  const [learnerDetails, setLearnerDetails] = useState()
  useEffect(() => {
    if (data) {
      console.log('logo-->', data)
      setLogo(data.schoolDetail)
    }
  }, [data])

  useEffect(() => {
    if (stData) {
      console.log('stdata>>', stData)
      setLearnerDetails(stData.student)
    }
  }, [stData])

  return (
    // <PDFViewer style={{ margin: '0 auto', width: '900px', height: '750px' }}>
    <PDFDownloadLink
      document={
        <Document>
          <Page size="A4" wrap={true} style={page} scale={1}>
            <View
              style={{
                display: 'flex',
                textAlign: 'center',
                backgroundColor: COLORS.palleteLight,
                padding: '8px',
              }}
            >
              <Text style={{ margin: 'auto' }}>{title} Report</Text>
              {/* <Image src={logo}></Image> */}
            </View>
            <View style={{ ...section, justifyContent: 'space-between' }}>
              <View style={{ fontSize: '11px', padding: '5px 0' }}>
                <Text style={{ marginBottom: '5px' }}>
                  Name: {learnerDetails?.firstname} {learnerDetails?.lastname}
                </Text>
                <Text style={{ marginBottom: '5px' }}>Date of Birth: {learnerDetails?.dob}</Text>
                <Text style={{ marginBottom: '5px' }}>
                  Age: {learnerDetails?.dob && calculateAge(learnerDetails?.dob)}
                </Text>
                <Text style={{ marginBottom: '5px' }}>
                  Location: {learnerDetails?.currentAddress}
                </Text>
                {/* <Text style={{ marginBottom: '5px' }}>From Date: {start}</Text>
            <Text style={{ marginBottom: '5px' }}>End Date: {end}</Text> */}
              </View>
              <View style={{ width: '20%', alignSelf: 'center', backgroundColor: 'yellow' }}>
                {/* NEED TO SOLVED CORS ERROR FROM BACKEND TO SHOW CLINIC LOGO & Albi Implemented temporary code for now.   */}
                {logo && logo.logo && (
                  <Image src={logo.logo} style={{ width: '100%', alignSelf: 'center' }} />
                )}
              </View>
            </View>
            <View>
              <View>
                {image.img && image.title !== 'Task Analysis' && (
                  <>
                    <Text>{image.title}</Text>
                    <Image
                      src={image.img}
                      style={{
                        width: '100%',
                        height: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    />
                  </>
                )}
                {image.img && image.title === 'Task Analysis' && (
                  <>
                    <Text>{image.title}</Text>
                    {/* <Image
                      src={image.student}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image> */}
                    <Image
                      src={image.l1}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                    <Image
                      src={image.l2}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                    <Image
                      src={image.l3}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                  </>
                )}
              </View>
            </View>
          </Page>
        </Document>
      }
      fileName={'VBMAPPReport.pdf'}
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <LoadingComponent />
        ) : (
          <div style={{ margin: 'auto', textAlign: 'center' }}>
            <Button type="primary">Download pdf</Button>
          </div>
        )
      }
    </PDFDownloadLink>

    // </PDFViewer>
  )
}

export default NetworkPDF
