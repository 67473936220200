/* eslint-disable  */
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Button,
  Form,
  notification,
  DatePicker,
  Empty,
  Input,
  Drawer,
  Tabs,
  Card,
  Tooltip,
} from 'antd'
import './style.scss'
import html2canvas from 'html2canvas'
import gql from 'graphql-tag'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { groupBy } from 'lodash'
import { saveAs } from 'file-saver'
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  ImageRun,
  TextRun,
} from 'docx'
import * as fs from 'fs'
import moment from 'moment'
import { ResponsivePie } from '@nivo/pie'
import groupObj from '@hunters/group-object'
import CKEditor from 'react-ckeditor-component'
import '../table.scss'
import {
  STUDENT_QUERY,
  GOALS_DETAILS,
  GET_GOAL_NEW_DETAILS,
  GET_MAND_REPORT,
  GET_TEMPLATE_REPORT,
  GET_TARGET_DAILY_RESPONSE,
  DOMAIN,
  DOMAIN_INTERVENTION,
} from './monthlyReport.query'
import { FaDownload } from 'react-icons/fa'

import BehaviorGenFRChart from '../BehaviorGenFRChart'
import BehaviorGenDRChart from '../BehaviorGenDRChart'
import ReportPdf from '../monthly-reports/monthlyReportPdf'
import client from '../../../apollo/config'
import { calculateAge, calculateAgeFromDates } from '../../../utilities'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import { useMutation, useQuery } from 'react-apollo'
import Scrollbars from 'react-custom-scrollbars'
import LoadingComponent from 'components/LoadingComponent'

const { RangePicker, MonthPicker } = DatePicker
const { TextArea } = Input
const { TabPane } = Tabs

const RT = gql`
  mutation(
    $student: ID!
    $start: Date!
    $end: Date!
    $progress: String!
    $goal: String!
    $behavior: String!
    $mand: String!
    $longGoals: JSONString!
    $shortGoals: JSONString!
    $targets: JSONString!
    $progressOverviewMaster: JSONString!
    $progressOverviewIntervention: JSONString!
  ) {
    generateMonthlyReport(
      input: {
        student: $student
        dateGte: $start
        dateLte: $end
        progressDescription: $progress
        goalsDescription: $goal
        behaviourDescription: $behavior
        mandDescription: $mand
        longGoals: $longGoals
        shortGoals: $shortGoals
        targets: $targets
        progressOverviewMaster: $progressOverviewMaster
        progressOverviewIntervention: $progressOverviewIntervention
      }
    ) {
      report
    }
  }
`

const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '10px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
  backgroundColor: COLORS.palleteLight,
}
const parentLabel = { fontSize: '15px', color: '#000', marginRight: '6px' }
const graphHead = {
  textAlign: 'center',
  padding: 5,
  backgroundColor: COLORS.palleteLight,
  color: 'black',
  fontSize: 25,
  lineHeight: '22px',
}
const dateFormat = 'YYYY-MM-DD'
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const LOGO = gql`
  query {
    schoolDetail {
      logo
      schoolName
      address
    }
  }
`
function Goals({ selectedStudentId, studentName }) {
  const localStudentId = localStorage.getItem('studentId')
  const [learnerDetails, setLearnerDetails] = useState(null)
  const [goalsDetails, setGoalsDetails] = useState(null)
  const [overViewGraphData, setOverViewGraphData] = useState([])
  const [targetGraphData, setTargetGraphData] = useState({})
  const [mandGraphData, setMandGraphData] = useState([])
  const [progressivePieData, setProgrssivePieData] = useState(null)
  const [progressiveInterventionPieData, setProgrssiveInterventionPieData] = useState(null)
  const [graphData, setGraphData] = useState([])
  const [longTermGoal, setLongTermGoal] = useState([])
  const [shortTermGoal, setShortTermGoal] = useState([])
  const [targets, setTargets] = useState([])
  const [eleFR, setEleFR] = useState(null)
  const [eleDR, setEleDR] = useState(null)
  const [loadingGoalReport, setLoadingGoalReport] = useState(true)
  const [loadingProgressOverview, setLoadingProgressOverview] = useState(true)
  const [loadingMand, setLoadingMand] = useState(true)
  const [loadingPage, setLoadingPage] = useState(true)

  const [behaviorTemplates, setbehaviorTemplates] = useState([])
  const [loadingPdf, setloadingPdf] = useState(false)
  const [textBoxObj, setTextBoxObj] = useState({
    progressOverview: '',
    goals: '',
    behaviour: '',
    mand: '',
  })
  const [pdfDrawer, setPdfDrawer] = useState(false)
  const [goalsImages, setGoalsImages] = useState({})
  const [start, setStart] = useState(
    moment()
      .subtract(1, 'M')
      .startOf('M')
      .format('YYYY-MM-DD'),
  )
  const [end, setEnd] = useState(
    moment()
      .subtract(1, 'M')
      .endOf('M')
      .format('YYYY-MM-DD'),
  )
  const [pdfDataStatus, setPdfDataStatus] = useState({
    progress: false,
    goals: false,
    mand: false,
    behavior: false,
  })
  const { data, error, loading } = useQuery(LOGO, { fetchPolicy: 'no-cache' })
  const [logo, setLogo] = useState()
  useEffect(() => {
    if (data) {
      console.log('logo-->', data)
      setLogo(data.schoolDetail)
    }
  }, [data])

  const [generateMonthlyReport, { loading: generateMonthlyReportLoading }] = useMutation(RT)

  useEffect(() => {
    fetchStudentDetails(localStudentId)
    fetchStudentData(localStudentId)
    // console.log("Test =++++++++> ", moment()
    //   .subtract(1, 'M')
    //   .startOf('M')
    //   .format('YYYY-MM'))
  }, [])

  useEffect(() => {
    fetchStudentDetails(selectedStudentId)
    fetchStudentData(selectedStudentId)
    setLoadingPage(true)
  }, [selectedStudentId])

  useEffect(() => {
    fetchStudentData(localStudentId)
    setLoadingPage(true)
  }, [start])

  useEffect(() => {
    if (localStudentId) {
      fetchMonthDescriptions(localStudentId, start)
    }
  }, [start, localStudentId])

  useEffect(() => {
    if (localStudentId && learnerDetails) {
      fetchProgressDetails(localStudentId)
      fetchProgressInterventionDetails(localStudentId)
      // setLoadingPage(true)
    }
  }, [start, localStudentId, learnerDetails])

  const fetchStudentDetails = studentId => {
    // GET STUDENT DETAILS
    if (studentId) {
      client
        .query({
          query: STUDENT_QUERY,
          variables: {
            studentId,
          },
        })
        .then(result => {
          if (result && result.data) {
            setLearnerDetails(result.data.student)
            setLoadingPage(false)
          }
        })
        .catch(error => {
          error.graphQLErrors.map(item => {
            return notification.error({
              message: 'Somthing went wrong',
              description: item.message,
            })
          })
        })
    }
  }

  const fetchMonthDescriptions = (studentId, date) => {
    // GET Month Descriptions
    if (studentId && date) {
      client
        .mutate({
          mutation: gql`
            mutation monthlyReport($studentId: ID!, $date: Date!) {
              monthlyReportDescription(input: { student: $studentId, date: $date }) {
                description {
                  mandDescription
                  progressDescription
                  goalsDescription
                  behaviourDescription
                }
              }
            }
          `,
          variables: {
            studentId,
            date: moment(date).format('YYYY-MM'),
          },
          fetchPolicy: 'no-cache',
        })
        .then(result => {
          if (result) {
            if (result.data) {
              setTextBoxObj({
                progressOverview:
                  result.data.monthlyReportDescription.description?.progressDescription,
                goals: result.data.monthlyReportDescription.description?.goalsDescription,
                behaviour: result.data.monthlyReportDescription.description?.behaviourDescription,
                mand: result.data.monthlyReportDescription.description?.mandDescription,
              })
            } else {
              setTextBoxObj({
                progressOverview: '',
                goals: '',
                behaviour: '',
                mand: '',
              })
            }
            setLoadingPage(false)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const [updateDesLoading, setUpdateDesLoading] = useState(false)

  const updateMonthDescriptions = (studentId, date) => {
    // GET Month Descriptions
    if (studentId && date) {
      setUpdateDesLoading(true)
      client
        .mutate({
          mutation: gql`
            mutation monthlyReportUpdate(
              $studentId: ID!
              $date: Date!
              $progress: String
              $mand: String
              $behavior: String
              $goal: String
            ) {
              UpdateMonthlyReportDescription(
                input: {
                  student: $studentId
                  date: $date
                  progressDescription: $progress
                  mandDescription: $mand
                  goalsDescription: $goal
                  behaviourDescription: $behavior
                }
              ) {
                description {
                  mandDescription
                  progressDescription
                  goalsDescription
                  behaviourDescription
                }
              }
            }
          `,
          variables: {
            studentId,
            date: moment(date).format('YYYY-MM'),
            progress: textBoxObj.progressOverview,
            goal: textBoxObj.goals,
            mand: textBoxObj.mand,
            behavior: textBoxObj.behaviour,
          },
          fetchPolicy: 'no-cache',
        })
        .then(result => {
          if (result && result.data) {
            notification.success({
              message: 'Descriptions Updated Successfully!',
            })
            setUpdateDesLoading(false)
          }
        })
        .catch(error => {
          console.log('Error ========> ', error)
        })
    }
  }

  const isDateInRange = selectedDate => {
    const selected = moment(selectedDate)
    const st = moment(start)
    const ed = moment(end)
    return selected.isBetween(st, ed) || selected.isSame(st) || selected.isSame(ed)
  }

  const isGoalInProgress = (goalStartDate, goalEndDate) => {
    const goalStart = moment(goalStartDate)
    const goalEnd = moment(goalEndDate)
    const selectedStart = moment(start)
    const selectedEnd = moment(end)

    return goalStart.isSameOrBefore(selectedEnd) && goalEnd.isSameOrAfter(selectedStart)
  }

  const fetchStudentData = studentId => {
    // GET GOALS DETAILS
    if (studentId) {
      client
        // .query({
        //   query: GOALS_DETAILS,
        //   variables: {
        //     studentId,
        //     status: [
        //       'R29hbFN0YXR1c1R5cGU6Mg==',
        //       'R29hbFN0YXR1c1R5cGU6Mw==',
        //       'R29hbFN0YXR1c1R5cGU6NQ==',
        //       'R29hbFN0YXR1c1R5cGU6Ng==',
        //       'R29hbFN0YXR1c1R5cGU6NA==',
        //     ],
        //     start,
        //     end,
        //   },
        // })
        .query({
          query: GET_GOAL_NEW_DETAILS,
          variables: {
            student: studentId,
          },
        })
        .then(result => {
          let gData = []
          let created = 0
          let mastered = 0
          let inProgress = 0
          let createdst = 0
          let masteredst = 0
          let inProgressst = 0
          let createdTarget = 0
          let masteredTarget = 0
          let inProgressTarget = 0
          let tempLong = []
          let tempShort = []
          let tempTarget = []

          if (result && result.data.longTerm.edges.length !== 0) {
            let targetGraphResponse = {}
            result.data.longTerm.edges.forEach(item => {
              if (item.node.dateInitialted && isDateInRange(item.node.dateInitialted)) {
                created += 1
                tempLong.push({
                  status: 'Created',
                  goal: item.node,
                })
              }
              if (
                item.node.goalStatus.id === 'R29hbFN0YXR1c1R5cGU6Mg==' &&
                isGoalInProgress(item.node.dateInitialted, item.node.dateEnd)
              ) {
                inProgress += 1
                tempLong.push({
                  status: 'Inprogress',
                  goal: item.node,
                })
              }

              if (item.dateMastered !== undefined && isDateInRange(item.dateMastered)) {
                mastered += 1
                tempLong.push({
                  status: 'Mastered',
                  goal: item.node,
                })
              }
              console.log('longGoal===>', item.node.goalName)
              item.node.shorttermgoalSet.edges.forEach(i => {
                console.log('shortGoal===>', i)
                if (i.node.dateInitialted && isDateInRange(i.node.dateInitialted)) {
                  createdst += 1
                  tempShort.push({
                    status: 'Created',
                    goal: i.node,
                  })
                }
                if (
                  i.node.goalStatus.id === 'R29hbFN0YXR1c1R5cGU6Mg==' &&
                  isGoalInProgress(i.node.dateInitialted, i.node.dateEnd)
                ) {
                  inProgressst += 1
                  tempShort.push({
                    status: 'Inprogress',
                    goal: i.node,
                  })
                }
                if (
                  i.node.goalStatus.id === 'R29hbFN0YXR1c1R5cGU6NA==' &&
                  i.node.masterDate &&
                  isDateInRange(i.node.masterDate)
                ) {
                  masteredst += 1
                  tempShort.push({
                    status: 'Mastered',
                    goal: i.node,
                  })
                }
                i.node.targetAllocateSet.edges.forEach(tt => {
                  if (
                    tt.node.targetAllcatedDetails.dateBaseline &&
                    isDateInRange(
                      moment(tt.node.targetAllcatedDetails.dateBaseline).format('YYYY-MM-DD'),
                    )
                  ) {
                    createdTarget += 1
                    tempTarget.push({
                      status: 'Created',
                      goal: tt.node,
                    })
                  }
                  if (
                    tt.node.targetStatus.id === 'U3RhdHVzVHlwZToz' &&
                    tt.node.targetAllcatedDetails.dateBaseline &&
                    moment(tt.node.targetAllcatedDetails.dateBaseline).isSameOrBefore(end)
                  ) {
                    inProgressTarget += 1
                    tempTarget.push({
                      status: 'InProgress',
                      goal: tt.node,
                    })
                  }
                  if (
                    tt.node.targetStatus.id === 'U3RhdHVzVHlwZTo0' &&
                    isDateInRange(tt.node.masterDate)
                  ) {
                    masteredTarget += 1
                    tempTarget.push({
                      status: 'Mastered',
                      goal: tt.node,
                    })
                  }
                })
              })
            })

            gData.push({
              Goal: 'Long Term Goals',
              Created: created,
              InProgress: inProgress,
              Mastered: mastered,
            })
            gData.push({
              Goal: 'Short Term Goals',
              Created: createdst,
              InProgress: inProgressst,
              Mastered: masteredst,
            })
            gData.push({
              Goal: 'Targets',
              Created: createdTarget,
              InProgress: inProgressTarget,
              Mastered: masteredTarget,
            })
            const groupLong = groupBy(tempLong, 'status')
            const groupShort = groupBy(tempShort, 'status')
            const groupTarget = groupBy(tempTarget, 'status')

            setLongTermGoal(groupLong)
            setShortTermGoal(groupShort)
            setTargets(groupTarget)
            setGraphData(gData)
            setGoalsDetails(result.data.goalsLongProgressReport)
          } else {
            setGraphData([])
            setLongTermGoal([])
            setShortTermGoal([])
            setTargets([])
          }
          setLoadingGoalReport(false)
        })
        .catch(error => {
          console.log(error)
        })

      // GET MAND REPORT DETAILS
      client
        .query({
          query: GET_MAND_REPORT,
          variables: {
            studentId,
            start,
            end,
          },
        })
        .then(result => {
          if (result && result.data.mandReport) {
            const mandGData = []
            result.data.mandReport.map(mandItem => {
              const gData = []
              mandItem.data.map(dateItem => {
                gData.push({ x: moment(dateItem.formattedDate).format('DD'), y: dateItem.count })
              })
              mandGData.push({ id: mandItem.measurments, data: gData })
            })
            setMandGraphData(mandGData)
          } else {
            setMandGraphData([])
          }
          // loadingMand(false)
          setLoadingMand(false)
        })
        .catch(error => {
          if (error) {
            // console.log(error, 'errorr')
            error.graphQLErrors.map(item => {
              return notification.error({
                message: 'Somthing went wrong',
                description: item.message,
              })
            })
          }
        })

      // GET BEHAVIOR TEMPLATES
      client
        .query({
          query: GET_TEMPLATE_REPORT,
          variables: {
            studentId,
            start,
            end,
          },
        })
        .then(result => {
          if (result && result.data) {
            setbehaviorTemplates(result.data.getTemplateForReports)
          }
        })
        .catch(error => {
          error.graphQLErrors.map(item => {
            return notification.error({
              message: 'Somthing went wrong',
              description: item.message,
            })
          })
        })
      // PROGRESS OVERVIEW
    }
  }

  const fetchProgressDetails = studentId => {
    const createdAt = moment(learnerDetails.createdAt).format(dateFormat)
    client
      .query({
        query: DOMAIN,
        variables: {
          studentId,
          start,
          end,
        },
        fetchPolicy: 'network-only',
      })
      .then(result => {
        const domainData = []
        const data = []
        if (result.data.domainMastered.target) {
          result.data.domainMastered.target.map(item => {
            return domainData.push({
              domain: item.targetId ? item.targetId.domain.domain : 'Others',
              target: item.targetId ? item.targetId.domain.domain : 'Others',
            })
          })
          let groupedData = groupObj.group(domainData, 'domain')
          let keys = Object.keys(groupedData)
          for (let k = 0; k < keys.length; k += 1) {
            if (groupedData[keys[k]]?.length > 0) {
              data.push({
                id: keys[k],
                label: keys[k],
                value: groupedData[keys[k]].length,
              })
            }
          }
        }
        setProgrssivePieData(data)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const fetchProgressInterventionDetails = studentId => {
    const createdAt = moment(learnerDetails.createdAt).format(dateFormat)
    client
      .query({
        query: DOMAIN_INTERVENTION,
        variables: {
          studentId,
          start,
          end,
        },
        fetchPolicy: 'network-only',
      })
      .then(result => {
        const domainData = []
        const data = []
        if (result.data.domainMastered.target) {
          result.data.domainMastered.target.map(item => {
            return domainData.push({
              domain: item.targetId ? item.targetId.domain.domain : 'Others',
              target: item.targetId ? item.targetId.domain.domain : 'Others',
            })
          })
          let groupedData = groupObj.group(domainData, 'domain')
          let keys = Object.keys(groupedData)
          for (let k = 0; k < keys.length; k += 1) {
            if (groupedData[keys[k]]?.length > 0) {
              data.push({
                id: keys[k],
                label: keys[k],
                value: groupedData[keys[k]].length,
              })
            }
          }
        }
        setProgrssiveInterventionPieData(data)
        setLoadingProgressOverview(false)
      })
      .catch(error => {
        error.graphQLErrors.map(item => {
          return notification.error({
            message: 'Somthing went wrong',
            description: item.message,
          })
        })
      })
  }
  const handleMonthChange = val => {
    console.log('valmonth change>>', val)
    if (val) {
      const month = moment(val, 'YYYY-MM-DD').format('YYYY-MM')
      const currentMonth = moment().format('YYYY-MM')
      if (month === currentMonth) {
        setStart(val.format('YYYY-MM-DD'))
        setEnd(moment().format('YYYY-MM-DD'))
      } else {
        setStart(val.format('YYYY-MM-DD'))
        setEnd(
          moment(val)
            .endOf('M')
            .format('YYYY-MM-DD'),
        )
      }
      setGoalsImages({})
      setPdfDataStatus({ progress: false, goals: false, mand: false, behavior: false })
    }
  }

  function disabledDate(current) {
    return current && current >= moment().endOf('M')
  }

  const handleGenerateReport = () => {
    if (localStudentId && start && end) {
      generateMonthlyReport({
        variables: {
          student: JSON.parse(localStudentId),
          start: moment(start).format('YYYY-MM'),
          end: moment(end).format('YYYY-MM'),
          progress: textBoxObj.progressOverview ? textBoxObj.progressOverview : '',
          goal: textBoxObj.goals ? textBoxObj.goals : '',
          mand: textBoxObj.mand ? textBoxObj.mand : '',
          behavior: textBoxObj.behaviour ? textBoxObj.behaviour : '',
          longGoals: JSON.stringify(longTermGoal),
          shortGoals: JSON.stringify(shortTermGoal),
          targets: JSON.stringify(targets),
          progressOverviewMaster: JSON.stringify(progressivePieData),
          progressOverviewIntervention: JSON.stringify(progressiveInterventionPieData),
        },
      })
        .then(res => {
          window.location.href = `${res.data.generateMonthlyReport.report}`
          // notification.success({
          //   message: 'Your report is available a following link',
          // })
        })
        .catch(err => {
          console.error(err, 'fg')
        })
    }
  }

  const onEditorPO = evt => {
    let tempTextBox = textBoxObj
    tempTextBox['progressOverview'] = evt.editor.getData()
    setTextBoxObj(tempTextBox)
  }

  const onEditorGoals = evt => {
    let tempTextBox = textBoxObj
    tempTextBox['goals'] = evt.editor.getData()
    setTextBoxObj(tempTextBox)
  }

  const onEditorBehavior = evt => {
    let tempTextBox = textBoxObj
    tempTextBox['behaviour'] = evt.editor.getData()
    setTextBoxObj(tempTextBox)
  }

  const onEditorMand = evt => {
    let tempTextBox = textBoxObj
    tempTextBox['mand'] = evt.editor.getData()
    setTextBoxObj(tempTextBox)
  }

  if (loadingPage) {
    return <LoadingComponent />
  }

  // console.log('targets===>', targets)
  const getStimulus = target => {
    const temp = []
    const { mastery, targetAllcatedDetails } = target

    mastery.edges.length && (
      <>
        {mastery.edges.forEach(({ node }) => {
          let obj = {
            type: node.sd ? 'sd' : 'step',
            name: node.sd ? node.sd.sd : node.step.step,
            status: node.status ? node?.status?.statusName : '',
            createdAt: node.createdAt,
            masterDate: targetAllcatedDetails.dateBaseline,
          }
          if (node.targetsdstepstatuschangeSet.edges.length) {
            const len = node.targetsdstepstatuschangeSet.edges.length
            const { node: item } = node.targetsdstepstatuschangeSet.edges[0]
            obj = {
              ...obj,
              // createdAt: item.updatedAt,
              masterDate:
                node.targetsdstepstatuschangeSet.edges[len - 1].node.toStatus &&
                node.targetsdstepstatuschangeSet.edges[
                  len - 1
                ].node.toStatus.statusName.toLowerCase() === 'mastered'
                  ? node.targetsdstepstatuschangeSet.edges[len - 1].node.createdAt
                  : null,
              status: node.targetsdstepstatuschangeSet.edges[len - 1].node.toStatus
                ? node.targetsdstepstatuschangeSet.edges[len - 1].node.toStatus.statusName
                : '',
            }
          }
          temp.push(obj)
        })}
      </>
    )
    return temp.map(item => (
      <div
        style={{
          color: 'black',
          border: '2px solid',
          borderRadius: '5px',
          margin: '5px',
          padding: '3px',
          borderColor: item.type === 'sd' ? '#f1b9d5' : '#f2d1b0',
          backgroundColor: item.type === 'sd' ? '#f1b9d5' : '#f2d1b0',
        }}
        key={Math.random()}
        className="LongTermGoal"
      >
        <p
          style={{
            margin: 0,
            fontSize: 14,
          }}
        >
          {item.type === 'sd' ? <b>Stimulus :</b> : <b>Step :</b>}
          {'  '}
          {item.name}
        </p>
        <div
          style={{
            margin: 0,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div className="details">
            <b>Status:{'  '}</b> {item.status}
          </div>
          <div className="details">
            <b>Initiated Date:{'  '}</b>

            {moment(item.createdAt).format(dateFormat)}
          </div>
          {item.status && item.status.toLowerCase() === 'mastered' && item.masterDate && (
            <div className="details">
              <b>Master Date:{'  '}</b>

              {moment(item.masterDate).format(dateFormat)}
            </div>
          )}
        </div>
      </div>
    ))
  }

  return (
    <div style={{ marginBottom: '100px' }}>
      <Row>
        <Col sm={24}>
          <div style={filterCardStyle}>
            <span style={{ float: 'left' }}>
              <Tooltip title="Select Month">
                <MonthPicker
                  defaultValue={moment(start)}
                  disabledDate={disabledDate}
                  onChange={val => handleMonthChange(val)}
                />
              </Tooltip>
            </span>
            <span>
              <Button
                type="link"
                size="large"
                style={{ padding: '0 8px', marginRight: 5 }}
                loading={generateMonthlyReportLoading}
                // style={{ marginRight: 5 }}
                onClick={() => handleGenerateReport()}
              >
                <FaDownload fontSize="22px" />
              </Button>
              <Button
                loading={updateDesLoading}
                onClick={() => updateMonthDescriptions(localStudentId, start)}
              >
                Save
              </Button>
            </span>
          </div>
        </Col>
        <Drawer
          title={`${studentName}'s Monthly report - ${
            monthNames[moment(start).format('MM') - 1]
          } ${moment(start).format('YYYY')}`}
          placement="right"
          closable="true"
          onClose={() => {
            setloadingPdf(false)
            setPdfDrawer(false)
          }}
          visible={pdfDrawer}
          width={DRAWER.widthL2}
        >
          <ReportPdf
            textBoxObj={textBoxObj}
            goalsDetails={goalsDetails}
            longTerm={longTermGoal}
            shortTerm={shortTermGoal}
            target={targets}
            behaviorTemplates={behaviorTemplates}
            goalsImages={goalsImages}
            learnerDetails={learnerDetails}
            start={start}
          />
        </Drawer>

        <Col sm={24}>
          <div
            id="HTMLtoPDF"
            style={{
              border: '1px solid #f2f2f2',
              padding: 10,
              width: '850px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <p style={graphHead}>Monthly Report</p>
            {learnerDetails && (
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ fontSize: 22, lineHeight: '24px' }}>
                  <img src={logo?.logo} width="100px" height="100px" alt="Logo" />
                </div>
                <div style={{ marginLeft: '5%' }} className="Heading">
                  <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                    <strong>Name: </strong>{' '}
                    <span>
                      {learnerDetails?.firstname} {learnerDetails?.lastname}
                    </span>
                  </p>
                  <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                    <strong>DOB: </strong>
                    <span>{learnerDetails?.dob}</span>
                  </p>
                  <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                    <strong>Age: </strong>
                    <span>
                      {learnerDetails?.dob && calculateAgeFromDates(learnerDetails?.dob, start)}
                    </span>
                  </p>
                  <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                    <strong> Location: </strong>
                    <span>
                      {learnerDetails?.currentAddress &&
                        learnerDetails?.currentAddress.charAt(0).toUpperCase() +
                          learnerDetails?.currentAddress.slice(1)}
                    </span>
                  </p>

                  <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                    <strong>Month: </strong>
                    <span>
                      {monthNames[moment(start).format('MM') - 1]} {moment(start).format('YYYY')}
                    </span>
                  </p>
                </div>
              </div>
            )}

            <Tabs type="card">
              <TabPane tab="Progress Overview" key="1">
                {loadingProgressOverview ? (
                  <LoadingComponent />
                ) : (
                  <div style={{ padding: 5 }}>
                    <CKEditor
                      name="progressOverview"
                      activeClass="p10"
                      content={textBoxObj['progressOverview']}
                      events={{
                        change: onEditorPO,
                      }}
                    />
                    <div
                      role="presentation"
                      id="progressMasterGraph"
                      style={{
                        width: '100%',
                        height: '300px',
                        backgroundColor: '#fff',
                        padding: '5px',
                        marginTop: '5px',
                      }}
                    >
                      <h4 style={{ padding: '5px' }}>Mastered Targets</h4>
                      {progressivePieData && progressivePieData.length === 0 ? (
                        <>
                          <Empty style={{ margin: '80px auto' }} />
                        </>
                      ) : (
                        ''
                      )}
                      {progressivePieData && (
                        // <p>hello</p>
                        <ResponsivePie
                          data={progressivePieData}
                          margin={{ top: 30, right: 0, bottom: 50, left: 0 }}
                          innerRadius={0.5}
                          padAngle={2}
                          cornerRadius={3}
                          colors={{ scheme: 'paired' }}
                          borderWidth={1}
                          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                          radialLabel={function(e) {
                            return e.label + ' (' + e.value + ')'
                          }}
                          radialLabelsSkipAngle={10}
                          radialLabelsTextXOffset={6}
                          radialLabelsTextColor="#333333"
                          radialLabelsLinkOffset={0}
                          radialLabelsLinkDiagonalLength={16}
                          radialLabelsLinkHorizontalLength={24}
                          radialLabelsLinkStrokeWidth={1}
                          radialLabelsLinkColor={{ from: 'color' }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor="#333333"
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          defs={[
                            {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: 'rgba(255, 255, 255, 0.3)',
                              size: 4,
                              padding: 1,
                              stagger: true,
                            },
                            {
                              id: 'lines',
                              type: 'patternLines',
                              background: 'inherit',
                              color: 'rgba(255, 255, 255, 0.3)',
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10,
                            },
                          ]}
                          legends={[
                            {
                              anchor: 'right',
                              direction: 'column',
                              translateY: 10,
                              translateX: -1,
                              itemWidth: 100,
                              itemHeight: 25,
                              itemTextColor: '#999',
                              symbolSize: 18,
                              symbolShape: 'circle',
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemTextColor: '#000',
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                      )}
                    </div>
                    <div
                      role="presentation"
                      id="progressInterventionGraph"
                      style={{
                        width: '100%',
                        height: '300px',
                        backgroundColor: '#fff',
                        padding: '5px',
                        marginTop: '10px',
                      }}
                    >
                      <h4 style={{ padding: '5px' }}>Intervention Targets</h4>
                      {progressiveInterventionPieData &&
                      progressiveInterventionPieData.length === 0 ? (
                        <>
                          <Empty style={{ margin: '80px auto' }} />
                        </>
                      ) : (
                        ''
                      )}
                      {progressiveInterventionPieData && (
                        // <p>hello</p>
                        <ResponsivePie
                          data={progressiveInterventionPieData}
                          margin={{ top: 40, right: 0, bottom: 50, left: 0 }}
                          innerRadius={0.5}
                          padAngle={2}
                          cornerRadius={3}
                          colors={{ scheme: 'paired' }}
                          borderWidth={1}
                          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                          radialLabel={function(e) {
                            return e.label + ' (' + e.value + ')'
                          }}
                          radialLabelsSkipAngle={10}
                          radialLabelsTextXOffset={6}
                          radialLabelsTextColor="#333333"
                          radialLabelsLinkOffset={0}
                          radialLabelsLinkDiagonalLength={16}
                          radialLabelsLinkHorizontalLength={24}
                          radialLabelsLinkStrokeWidth={1}
                          radialLabelsLinkColor={{ from: 'color' }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor="#333333"
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          defs={[
                            {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: 'rgba(255, 255, 255, 0.3)',
                              size: 4,
                              padding: 1,
                              stagger: true,
                            },
                            {
                              id: 'lines',
                              type: 'patternLines',
                              background: 'inherit',
                              color: 'rgba(255, 255, 255, 0.3)',
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10,
                            },
                          ]}
                          legends={[
                            {
                              anchor: 'right',
                              direction: 'column',
                              translateY: 10,
                              translateX: -1,
                              itemWidth: 100,
                              itemHeight: 25,
                              itemTextColor: '#999',
                              symbolSize: 18,
                              symbolShape: 'circle',
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemTextColor: '#000',
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                )}
              </TabPane>
              <TabPane tab="Goals Graph" key="2">
                {loadingGoalReport ? (
                  <LoadingComponent />
                ) : (
                  <div style={{ padding: 5 }}>
                    <CKEditor
                      name="goals"
                      activeClass="p10"
                      content={textBoxObj['goals']}
                      events={{
                        change: onEditorGoals,
                      }}
                      key={Math.random()}
                    />
                    <div
                      role="presentation"
                      id="goalGraph"
                      style={{
                        width: '100%',
                        backgroundColor: '#fff',
                        padding: '5px',
                        marginTop: '5px',
                      }}
                      key={Math.random()}
                    >
                      {graphData.length === 0 ? (
                        <>
                          <Empty style={{ margin: '80px auto' }} />
                        </>
                      ) : (
                        <div style={{ height: 450 }} key={Math.random()}>
                          <ResponsiveBar
                            data={graphData}
                            keys={['Created', 'InProgress', 'Mastered']}
                            indexBy="Goal"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            groupMode="grouped"
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            valueFormat={{ format: '', enabled: false }}
                            colors={{ scheme: 'nivo' }}
                            defs={[
                              {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true,
                              },
                              {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                              },
                            ]}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: 'Goals',
                              legendPosition: 'middle',
                              legendOffset: 32,
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: 'Count',
                              legendPosition: 'middle',
                              legendOffset: -40,
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            legends={[
                              {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                  {
                                    on: 'hover',
                                    style: {
                                      itemOpacity: 1,
                                    },
                                  },
                                ],
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                    {longTermGoal &&
                      Object.keys(longTermGoal).map((item, itemIndex) => {
                        return (
                          <>
                            <div key={Math.random()}>
                              <Card
                                style={{
                                  boxShadow: '5px 5px 10px 2px #ddd',
                                  backgroundColor: '#fff',
                                  marginTop: '10px',
                                }}
                                key={Math.random()}
                              >
                                <h5>
                                  <span style={{ fontWeight: 'bolder' }}>
                                    {' '}
                                    {item} Long Term Goals :{' '}
                                  </span>{' '}
                                  {longTermGoal[item].length}
                                </h5>
                                <Scrollbars
                                  style={{ height: 640, width: '100%' }}
                                  key={Math.random()}
                                >
                                  {longTermGoal[item].length !== 0 &&
                                    longTermGoal[item].map(lg => {
                                      return (
                                        <>
                                          <div
                                            style={{
                                              color: 'black',
                                              border: '2px solid',
                                              borderRadius: '5px',
                                              margin: '5px',
                                              padding: '3px',
                                              borderColor: COLORS.palleteLight,
                                            }}
                                            key={Math.random()}
                                            className="LongTermGoal"
                                          >
                                            <p style={{ margin: 0 }}>
                                              <b>Long Term Goal :</b> {lg?.goal.goalName}
                                            </p>
                                            <div
                                              style={{
                                                margin: 0,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <div className="details">
                                                <b>Status:{'  '}</b> {item}
                                              </div>
                                              <div className="details">
                                                <b>Initiated Date:{'  '}</b>

                                                {moment(lg?.goal.dateInitialted).format(dateFormat)}
                                              </div>
                                              <div className="details">
                                                <b>DateEnd:{'  '}</b>
                                                {lg?.goal.dateEnd}
                                              </div>
                                              {lg.goal.goalStatus.id ===
                                                'R29hbFN0YXR1c1R5cGU6NA==' && lg.goal.masterDate ? (
                                                <div className="details">
                                                  <b>MasterDate:{'  '}</b>
                                                  {lg?.goal?.masterDate}
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                </Scrollbars>
                              </Card>
                            </div>
                          </>
                        )
                      })}
                    <hr />
                    {shortTermGoal &&
                      Object.keys(shortTermGoal).map((item, itemIndex) => {
                        return (
                          <>
                            <div key={Math.random()}>
                              <Card
                                style={{
                                  boxShadow: '5px 5px 10px 2px #ddd',
                                  backgroundColor: '#fff',
                                  marginBottom: '10px',
                                }}
                              >
                                <h5>
                                  <span style={{ fontWeight: 'bolder' }}>
                                    {' '}
                                    {item} Short Term Goals :{' '}
                                  </span>{' '}
                                  {shortTermGoal[item].length}
                                </h5>
                                <Scrollbars style={{ height: 640, width: '100%' }}>
                                  {shortTermGoal[item].length !== 0 &&
                                    shortTermGoal[item].map(lg => {
                                      return (
                                        <>
                                          <div
                                            style={{
                                              color: 'black',
                                              border: '2px solid',
                                              borderRadius: '5px',
                                              margin: '5px',
                                              padding: '3px',
                                              borderColor: COLORS.palleteLight,
                                            }}
                                            key={Math.random()}
                                            className="LongTermGoal"
                                          >
                                            <p style={{ margin: 0 }}>
                                              <b>Short Term Goal :</b> {lg?.goal.goalName}
                                            </p>
                                            <div
                                              style={{
                                                margin: 0,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <div className="details">
                                                <b>Status:{'  '}</b> {item}
                                              </div>
                                              <div className="details">
                                                <b>Initiated Date:{'  '}</b>

                                                {moment(lg?.goal.dateInitialted).format(dateFormat)}
                                              </div>
                                              <div className="details">
                                                <b>DateEnd:{'  '}</b>
                                                {lg?.goal.dateEnd}
                                              </div>
                                              {lg.goal.goalStatus.id ===
                                                'R29hbFN0YXR1c1R5cGU6NA==' && lg.goal.masterDate ? (
                                                <div className="details">
                                                  <b>MasterDate:{'  '}</b>
                                                  {lg?.goal?.masterDate}
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                </Scrollbars>
                              </Card>
                            </div>
                          </>
                        )
                      })}

                    {targets &&
                      Object.keys(targets).map((item, itemIndex) => {
                        return (
                          <>
                            <div key={Math.random()}>
                              <Card
                                style={{
                                  boxShadow: '5px 5px 10px 2px #ddd',
                                  backgroundColor: '#fff',
                                  marginBottom: '10px',
                                }}
                              >
                                <h5>
                                  <span style={{ fontWeight: 'bolder' }}>{item} Targets : </span>
                                  {targets[item].length}
                                </h5>
                                <Scrollbars style={{ height: 640, width: '100%' }}>
                                  {targets[item].length !== 0 &&
                                    targets[item].map(lg => {
                                      return (
                                        <>
                                          <div
                                            style={{
                                              color: 'black',
                                              border: '2px solid',
                                              borderRadius: '5px',
                                              margin: '5px',
                                              padding: '3px',
                                              borderColor: COLORS.palleteLight,
                                            }}
                                            key={Math.random()}
                                            className="LongTermGoal"
                                          >
                                            <p style={{ margin: 0 }}>
                                              <b>Target :</b>{' '}
                                              {lg?.goal.targetAllcatedDetails?.targetName}
                                            </p>
                                            <div
                                              style={{
                                                margin: 0,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <div className="details">
                                                <b>Status:{'  '}</b> {item}
                                              </div>
                                              <div className="details">
                                                <b>Initiated Date:{'  '}</b>{' '}
                                                {moment(
                                                  lg?.goal.targetAllcatedDetails?.dateBaseline,
                                                ).format(dateFormat)}
                                              </div>
                                              {lg?.goal.masterDate && (
                                                <div className="details">
                                                  <b>Mastered Date:{'  '}</b>
                                                  {lg?.goal.masterDate}
                                                </div>
                                              )}
                                              {/* <div className="details">
                                                <b>DateEnd:{'  '}</b>
                                                {lg?.goal.dateEnd}
                                              </div> */}
                                            </div>
                                            {getStimulus(lg?.goal)}
                                          </div>
                                        </>
                                      )
                                    })}
                                </Scrollbars>
                              </Card>
                            </div>
                          </>
                        )
                      })}
                  </div>
                )}
              </TabPane>
              <TabPane tab="Behavior Report" key="3">
                <div style={{ padding: 5 }}>
                  <CKEditor
                    name="behaviour"
                    activeClass="p10"
                    content={textBoxObj['behaviour']}
                    events={{
                      change: onEditorBehavior,
                    }}
                  />

                  <Tabs type="card" style={{ marginTop: 10 }}>
                    <TabPane tab="Frequency And Rate Recording" key="1">
                      <div style={{ height: 800 }} id="FR">
                        <BehaviorGenFRChart
                          startDate={start}
                          endDate={end}
                          selectedStudentId={selectedStudentId}
                          createElement={elem => setEleFR(elem)}
                        />
                      </div>
                    </TabPane>
                    <TabPane tab="Duration Recording" key="2">
                      <div className="MandProgressTitle">Duration Recording</div>
                      <div style={{ height: 400 }}>
                        <BehaviorGenDRChart
                          startDate={start}
                          endDate={end}
                          selectedStudentId={selectedStudentId}
                          createElement={elem => setEleDR(elem)}
                        />
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </TabPane>
              <TabPane tab="Mand Progress" key="4">
                {loadingMand ? (
                  <LoadingComponent />
                ) : (
                  <div style={{ padding: 5 }}>
                    <CKEditor
                      name="mand"
                      activeClass="p10"
                      content={textBoxObj['mand']}
                      events={{
                        change: onEditorMand,
                      }}
                    />
                    <div id="mandGraph">
                      {mandGraphData.length === 0 ? (
                        <>
                          <Empty style={{ margin: '80px auto' }} />
                        </>
                      ) : (
                        <div>
                          <div className="MandProgressTitle">Mand Progress</div>

                          <div style={{ height: 400 }}>
                            <ResponsiveLine
                              key="mand"
                              data={mandGraphData}
                              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                              xScale={{ type: 'point' }}
                              yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                stacked: false,
                                reverse: false,
                              }}
                              enableGridX={false}
                              enableSlices="x"
                              yFormat=" >-.2f"
                              curve="linear"
                              axisTop={null}
                              axisRight={null}
                              axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Date',
                                legendOffset: 36,
                                legendPosition: 'middle',
                              }}
                              axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'count',
                                legendOffset: -40,
                                legendPosition: 'middle',
                              }}
                              colors={{ scheme: 'category10' }}
                              pointSize={10}
                              pointColor={{ from: 'color' }}
                              pointBorderWidth={2}
                              pointBorderColor={{ from: 'serieColor' }}
                              pointLabelYOffset={-12}
                              useMesh
                              legends={[
                                {
                                  anchor: 'bottom-right',
                                  direction: 'column',
                                  justify: false,
                                  translateX: 100,
                                  translateY: 50,
                                  itemsSpacing: 0,
                                  itemDirection: 'right-to-left',
                                  itemWidth: 80,
                                  itemHeight: 20,
                                  itemOpacity: 0.75,
                                  symbolSize: 12,
                                  symbolShape: 'circle',
                                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                  effects: [
                                    {
                                      on: 'hover',
                                      style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                      },
                                    },
                                  ],
                                },
                              ]}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Form.create()(Goals)
