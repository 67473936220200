import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useSelector } from 'react-redux'
import {
  Button,
  Card,
  Input,
  List,
  Modal,
  Skeleton,
  Form,
  Switch,
  Popconfirm,
  notification,
  message,
  Radio,
} from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { COLORS } from 'assets/styles/globalStyles'
import LoadingComponent from 'components/LoadingComponent'
import _ from 'lodash'
import {
  CREATE_SESSION_PREFERRED_ITEM,
  SESSION_PREFERRED_ITEM,
  UPDATE_SESSION_PREFERRED_ITEM,
  SESSION_PREFERRED_INACTIVE_ITEM,
} from './query'
import './Style.scss'

const PreferredItem = () => {
  const student = useSelector(state => state.student)
  const { StudentId } = student

  const { data: pItems, error: PitemError, loading: PItemLoading, refetch } = useQuery(
    SESSION_PREFERRED_ITEM,
    {
      variables: {
        student: StudentId,
      },
      fetchPolicy: 'network-only',
    },
  )
  const {
    data: pInActiveItems,
    error: PInActiveitemError,
    loading: PInActiveItemLoading,
    refetch: refetchInActive,
  } = useQuery(SESSION_PREFERRED_INACTIVE_ITEM, {
    variables: {
      student: StudentId,
    },
    fetchPolicy: 'network-only',
  })
  const [createItem] = useMutation(CREATE_SESSION_PREFERRED_ITEM)
  const [editItem] = useMutation(UPDATE_SESSION_PREFERRED_ITEM)

  const [prefItem, setPrefItem] = useState('')
  const [addPItemModal, setAddPItemModal] = useState(false)
  const [addPItemError, setAddPItemError] = useState('')
  const [editableObj, setEditableObj] = useState(null)
  const [editDrawer, setEditDrawer] = useState(false)
  const [isActiveItem, setIsActiveItem] = useState(true)
  const [isCreate, setIsCreate] = useState(false)

  useEffect(() => {}, [pItems, isActiveItem])

  const handleCreatePItemName = () => {
    if (prefItem.length !== 0) {
      const includeItem = _.findIndex(
        pItems.sessionPreferredItems.edges,
        ({ node }) => node.item.toLowerCase() === prefItem.toLowerCase(),
      )
      if (includeItem !== -1) {
        setAddPItemError('Item is Already Exist')
      } else if (StudentId) {
        setAddPItemError('')
        if (editableObj) {
          const { id, isActive } = editableObj
          editItem({
            variables: {
              id,
              item: prefItem,
              studentId: StudentId,
              isActive,
            },
          })
            .then(res => {
              notification.success({
                message: 'Item Edited successfully',
              })
              refetch()
              setAddPItemModal(false)
              setIsCreate(false)
            })
            .catch(err => console.error(err))
        } else {
          createItem({
            variables: {
              item: prefItem,
              studentId: StudentId,
            },
          })
            .then(res => {
              notification.success({
                message: 'New Item created successfully',
              })
              refetch()
              setIsCreate(false)
              setAddPItemModal(false)
            })
            .catch(err => console.error(err))
        }
      }
    } else {
      setAddPItemError('Please Enter Preferred Item Name!!')
    }
  }

  const handleInactiveActive = e => {
    const { id, isActive, item } = editableObj
    editItem({
      variables: {
        id,
        item,
        studentId: StudentId,
        isActive: !isActive,
      },
    })
      .then(res => {
        notification.success({
          message: `Item ${!isActive ? 'activated' : 'inActivated'} successfully`,
        })
        refetch()
        refetchInActive()
        setAddPItemModal(false)
        setIsCreate(false)
      })
      .catch(err => console.error(err))
  }

  const switchHandler = item => {
    if (item) {
      setEditableObj(item)
    } else {
      message.warning("It's not editable.")
    }
  }

  if (PItemLoading) {
    return <LoadingComponent />
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'right',
          marginTop: 20,
        }}
      >
        <div>
          <Radio.Group
            size="medium"
            buttonStyle="solid"
            value={isActiveItem ? 'active' : 'in-active'}
            style={{ margin: 'auto 10px' }}
            onChange={() => {
              setIsActiveItem(!isActiveItem)
            }}
          >
            <Radio.Button value="active">Active</Radio.Button>
            <Radio.Button value="in-active">In-active</Radio.Button>
          </Radio.Group>
          <Button
            type="primary"
            style={{ marginBottom: 10, backgroundColor: '#0B35B3' }}
            onClick={() => {
              setAddPItemModal(true)
              setIsCreate(true)
            }}
          >
            Add Preferred Item
            <PlusOutlined />
          </Button>
        </div>
      </div>
      {PItemLoading && <LoadingComponent />}
      {/* // && pItems && pItems.sessionPreferredItems.edges.length > 0 ? */}
      {!PItemLoading ? (
        <Card className="preferred-card" style={{ marginTop: 20, padding: 10 }}>
          <List
            // style={{ zoom: 1.1 }}
            size="small"
            loading={PItemLoading}
            itemLayout="horizontal"
            dataSource={
              isActiveItem
                ? pItems.sessionPreferredItems.edges
                : pInActiveItems.sessionPreferredItems.edges
            }
            renderItem={({ node }) => {
              return (
                <List.Item
                  key={node.id}
                  actions={[
                    <Button
                      type="link"
                      // disabled={!item.school}
                      onClick={() => {
                        setAddPItemModal(true)
                        setEditableObj(node)
                      }}
                    >
                      <EditOutlined />
                    </Button>,
                    <Popconfirm
                      title={`Are you sure to ${
                        node.isActive ? 'inactivate' : 'activate'
                      } this preferredItem?`}
                      onConfirm={handleInactiveActive}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Switch
                        checked={node.isActive}
                        onClick={val => {
                          switchHandler(node)
                        }}
                      />
                    </Popconfirm>,
                  ]}
                >
                  <Skeleton avatar title={false} loading={PItemLoading} active>
                    <List.Item.Meta title={node?.item} />
                  </Skeleton>
                </List.Item>
              )
            }}
          />
        </Card>
      ) : (
        <Card>No item Found!!</Card>
      )}
      <Modal
        visible={addPItemModal}
        title="Add New preferred Name"
        onCancel={() => {
          setAddPItemModal(false)
          setIsCreate(false)
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              handleCreatePItemName()
            }}
            // loading={createBehLoading}
            style={{ backgroundColor: COLORS.submitBlue }}
          >
            {isCreate ? 'Create' : 'Edit'}
          </Button>,
        ]}
        destroyOnClose
      >
        <Input
          required
          placeholder="Enter preferred Item name"
          autoFocus
          defaultValue={isCreate ? '' : editableObj && editableObj.item}
          onChange={e => {
            setAddPItemError('')
            setPrefItem(e.target.value)
          }}
        />
        <div style={{ color: 'red' }}>{addPItemError}</div>
      </Modal>
    </div>
  )
}

export default Form.create()(PreferredItem)
