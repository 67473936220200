/* eslint-disable react-hooks/exhaustive-deps */
import { Form, notification, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import HeaderComponent from 'components/HeaderComponentNew'
import Scrollbar from 'react-custom-scrollbars'
import './clinicProfile.scss'
import BankDetails from './Invoices/bankDetails'
import GeneralInfoProfile from './Invoices/generalInfoProfile'
import OtherPaymentMethods from './Invoices/otherPaymentMethods'
import CustomProfileLayout from '../Dashboard/Clinic/CustomLayout'

const tabs = [
  {
    key: 'Genral-Info',
    title: 'General Info',
    component: <GeneralInfoProfile />,
    // iconName: <ProfileOutlined />,
  },
  {
    key: 'BankDetails',
    title: 'Bank Details',
    component: <BankDetails />,
    // iconName: <FieldTimeOutlined />,
  },
  {
    key: 'Other-Methods',
    title: 'Other Methods',
    component: <OtherPaymentMethods />,
    // iconName: <ProfileOutlined />,
  },
]

const InvoiceRelated = ({ form }) => {
  const [rightComponent, setRightComponent] = useState(<GeneralInfoProfile />)
  const [panelHeading, setPanelHeading] = useState('General Info')

  const handleMenuChange = item => {
    const selectedItem = tabs.find(tab => tab.key === item.key)
    setPanelHeading(selectedItem.title)
    if (selectedItem) setRightComponent(selectedItem.component)
  }

  return (
    <CustomProfileLayout
      leftContent={
        <>
          <Menu
            style={{ height: 'calc(100vh - 175px)' }}
            onSelect={handleMenuChange}
            mode="inline"
            defaultSelectedKeys={['Genral-Info']}
          >
            {tabs.map(tab => {
              if (!tab.submenu) {
                return (
                  <Menu.Item key={tab.key}>
                    {/* {tab.iconName} */}
                    <span>{tab.title}</span>
                  </Menu.Item>
                )
              }
              return (
                <Menu.SubMenu
                  title={
                    <span>
                      {tab.iconName}
                      <span>{tab.title}</span>
                    </span>
                  }
                  key={tab.key}
                  onTitleClick={() => handleMenuChange({ key: tab.key })}
                >
                  {tab.submenu.map(label => (
                    <Menu.Item key={label[0]}>
                      {label[1]} <span>{label[0]}</span>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )
            })}
          </Menu>
        </>
      }
      rightContent={
        <>
          <HeaderComponent
            title={panelHeading}
            showHomePath={false}
            pathList={['Payment Details']}
          />
          <div style={{ padding: '0px 24px', minHeight: 360, margin: '0 10px' }}>
            <Scrollbar style={{ height: 'calc(100vh - 150px)' }}>
              <div>{rightComponent}</div>
            </Scrollbar>
          </div>
        </>
      }
    />
  )
}

export default Form.create()(InvoiceRelated)
