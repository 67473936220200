import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-apollo'
import { Breadcrumb, notification, Row, Col } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import apolloClient from 'apollo/config'
import LoadingComponent from 'components/LoadingComponent'
import { COLORS } from 'assets/styles/globalStyles'
import './style.scss'
import Clock from './Clock'

import RecordingArea from './RecordingArea'
import BehaviorRecordingScoreBoard from './BehaviorRecording/ScoreBoard'

const behaviorRecordingId = 'VGFyZ2V0RGV0YWlsVHlwZToxMQ=='

const GET_USER_SETTING = gql`
  query GetUserSettings($id: ID!) {
    userSettings(user: $id) {
      edges {
        node {
          id
          peakAutomaticBlocks
        }
      }
    }
  }
`

const DataRecording = ({ selectedTarget }) => {
  const [redirect, setRedirect] = useState(false)
  const dispatch = useDispatch()
  const {
    SessionId,
    SessionDate,
    MasterSession,
    loading,
    TargetActiveId,
    TargetActiveIndex,
  } = useSelector(state => state.sessionrecording)
  const { id } = useSelector(state => state.user)
  const { data, loading: settLoading } = useQuery(GET_USER_SETTING, {
    variables: { id },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data && data.userSettings.edges.length > 0) {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          PeakAutomatic: false,
          // PeakAutomatic: result.data.userSettings.edges[0]?.node.peakAutomaticBlocks,
        },
      })
    }

    if (SessionId === '') {
      setRedirect(true)
    } else {
      dispatch({
        type: 'sessionrecording/LOAD_SESSION',
        payload: {
          masterSessionId: SessionId,
          date: SessionDate !== null ? SessionDate : moment().format('YYYY-MM-DD'),
          targetId: selectedTarget,
        },
      })
    }
  }, [data])

  if (redirect) {
    return <Redirect to="/" />
  }

  if (loading) return <LoadingComponent />

  return (
    MasterSession && (
      <div className="session-data-recording">
        <div style={{ backgroundColor: COLORS.grayBackground, width: '100%', padding: 20 }}>
          <div className="body-max-width">
            <Row gutter={[15, 15]}>
              <Col sm={16}>
                <RecordingArea key={TargetActiveId} from="CustomSession" />
              </Col>
              <Col sm={8}>
                <Clock />

                {MasterSession?.targets.edges[TargetActiveIndex].node.targetAllcatedDetails
                  .targetType.id === behaviorRecordingId && (
                  <BehaviorRecordingScoreBoard key={TargetActiveIndex} />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  )
}

export default DataRecording
