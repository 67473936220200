/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { Form, Button, Select, Row, Col, Input, notification, Modal, Switch, Icon } from 'antd'
import LoadingComponent from 'components/LoadingComponent'
import { useQuery, useLazyQuery } from 'react-apollo'
import { SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import NewMasteryForm from './newMasteryForm'
import { useTargetAlloc } from './context'
import { GET_SBT_STEP_REINFORCERS, GET_SBT_STEP_STATUS, GET_TEMPLATE_BY_ID } from './query'
import SbtMasteryForm from './sbtMasteryForm'
import MasterySequence from './MasterySequence'
import AttachmentFormItem from './FormCards/attachmentFormItem'
import '../style.scss'

const { Option } = Select

const searchableDropDownOption = {
  showSearch: true,
  optionFilterProp: 'children',
  filterOption: (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}

const StimulusModal = ({
  form,
  type,
  stimulusList,
  setStimulusList,
  stimulusObject,
  closeModal,
  defaultValues,
  activeMasteryPhase,
  setActiveCount,
}) => {
  const {
    mastSeqTemplate,
    targetOptions,
    targetOptionsLoading,
    promptData,
    promptLoading,
    selectedTab,
  } = useTargetAlloc()

  const edit = !!stimulusObject
  const { getFieldDecorator, getFieldValue } = form
  const [stimulusModal, setStimulusModal] = useState({ show: false })
  const [listStimulusMastery, setListStimulusMastery] = useState([])
  const [stimulusAtt, setStimulusAtt] = useState([])
  const [sbtMasteryModal, setSbtMasteryModal] = useState(null)
  const [manualMastActive, setManualMastActive] = useState(false)

  const [masterySeqObj, setMasterySeqObj] = useState({
    description: '',
    isTargetSpecific: true,
    templateType: selectedTab,
    phaseSet: [],
  })

  const [
    fetchData,
    { data: profileMst, loading: profileMstLoading, error: profileMstError, refetch },
  ] = useLazyQuery(GET_TEMPLATE_BY_ID, {
    fetchPolicy: 'network-only',
  })

  const {
    data: sbtReinforcersData,
    loading: sbtReinforcersLoading,
    error: sbtReinfError,
  } = useQuery(GET_SBT_STEP_REINFORCERS)

  const { data: sbtStatusData, loading: sbtStatusLoading, error: sbtStatusError } = useQuery(
    GET_SBT_STEP_STATUS,
  )

  useEffect(() => {
    if (edit && stimulusObject) {
      setListStimulusMastery(stimulusObject.manualMastery)
      setStimulusAtt(stimulusObject.attachments)
      setManualMastActive(stimulusObject.manualMastery?.isTargetSpecific)
      setMasterySeqObj(stimulusObject.manualMastery)
      if (stimulusObject.manualMastery?.isTargetSpecific) {
        form.setFieldsValue({ masteryCriteria: undefined })
      }
    }
  }, [edit, stimulusObject])

  useEffect(() => {
    if (sbtReinfError) {
      console.error(sbtReinfError)
    }
    if (sbtStatusError) {
      console.error(sbtStatusError)
    }
  }, [sbtReinfError, sbtStatusError])

  useEffect(() => {
    const mstId = getFieldValue('masteryCriteria')
    if (mstId && !manualMastActive) {
      fetchData({
        variables: {
          id: mstId,
        },
      })
    }
    if (edit && manualMastActive && stimulusObject) {
      setMasterySeqObj(stimulusObject.manualMastery)
    }
  }, [getFieldValue('masteryCriteria'), manualMastActive])

  useEffect(() => {
    if (profileMst && profileMst.getCriteriaTemplate) {
      const { getCriteriaTemplate } = profileMst
      const tempPhaseSet = getCriteriaTemplate.criteriaphasesSet.edges.map(item => {
        const tempMast = item.node.criteriaphasetargetsSet.edges.map(mst => {
          return { ...mst.node, id: null, key: Math.random() }
        })
        return {
          key: Math.random(),
          autoLabel: item.node.autoLabel,
          criteriaType: item.node.criteriaType.id,
          criteriaTypeName: item.node.criteriaType.statusName,
          stepNo: item.node.stepNo,
          prompt: item.node.prompts.edges.map(pr => pr.node.id),
          masterySet: tempMast,
        }
      })
      setMasterySeqObj({
        isTargetSpecific: manualMastActive,
        description: getCriteriaTemplate.description,
        type: getCriteriaTemplate.templateType,
        phaseSet: tempPhaseSet,
      })
    }
  }, [profileMst, manualMastActive])

  const SubmitForm = e => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let validationMessage = ''
        const uniqSd = stimulusList.filter(stm => stm.name === values.stimulus)
        const uniqStep = stimulusList.filter(stm => stm.name === values.step)

        if (type === 'sd' && uniqSd.length > 0) {
          validationMessage = 'Stimulus name must be unique within a target'
        }
        if (type === 'step' && uniqStep.length > 0) {
          validationMessage = 'Step name must be unique within a target'
        }

        if (validationMessage) {
          notification.info({ message: validationMessage })
        } else {
          const k = stimulusList.reduce((prevVal, currVal) => Math.max(prevVal, currVal.key), 0)
          setActiveCount()
          setStimulusList(state => [
            ...state,
            {
              ...values,
              masteryCriteria: manualMastActive ? null : values.masteryCriteria,
              name: type === 'sd' ? values.stimulus : values.step,
              manualMastery: masterySeqObj,
              attachments: values.tarUrl
                ? [...stimulusAtt, { type, url: values.tarUrl }]
                : stimulusAtt,
              key: k + 1,
            },
          ])
          if (closeModal) {
            closeModal()
          }
        }
      }
    })
  }

  const updateForm = e => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let validationMessage = ''
        const uniqSd = stimulusList.filter(stm => stm.name === values.stimulus)
        const uniqStep = stimulusList.filter(stm => stm.name === values.step)

        if (type === 'sd' && uniqSd.length > 1) {
          validationMessage = 'Stimulus name must be unique within a target'
        }
        if (type === 'step' && uniqStep.length > 1) {
          validationMessage = 'Step name must be unique within a target'
        }

        if (validationMessage) {
          notification.info({ message: validationMessage })
        } else {
          setStimulusList(state =>
            state.map(item => {
              if (item.key === stimulusObject.key) {
                return {
                  ...item,
                  ...values,
                  masteryCriteria: manualMastActive ? null : values.masteryCriteria,
                  manualMastery: masterySeqObj,
                  attachments: values.tarUrl
                    ? [...stimulusAtt, { type, url: values.tarUrl }]
                    : stimulusAtt,
                }
              }
              return item
            }),
          )
          if (closeModal) {
            closeModal()
          }
        }
      }
    })
  }

  return (
    <div>
      <Form className="green-calendar-icon" name="sdStepForm" style={{ padding: '0 12px' }}>
        <Row gutter={[24]}>
          {/* name */}
          {!edit && (
            <Col span={12}>
              <Form.Item label={type === 'sd' ? 'Stimulus Name' : 'Step Name'} key="stimulus">
                {getFieldDecorator(type === 'sd' ? 'stimulus' : 'step', {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input Stimulus's name or delete this field.",
                    },
                  ],
                })(
                  <Input
                    placeholder={type === 'sd' ? 'Provide stimulus name' : 'Provide step name'}
                    style={{ width: '100%' }}
                  />,
                )}
              </Form.Item>
            </Col>
          )}

          {/* status */}
          {edit ? (
            <Col span={24}>
              <Form.Item label={type === 'sd' ? 'Stimulus Status' : 'Step Status'} key="sdStatus">
                {getFieldDecorator('status', {
                  initialValue: stimulusObject.status,
                  rules: [{ required: true, message: 'Please Select Status.' }],
                })(
                  <Select
                    loading={targetOptionsLoading}
                    style={{ width: '100%' }}
                    placeholder="Select status"
                    {...searchableDropDownOption}
                  >
                    {type === 'sbt'
                      ? sbtStatusData?.getSbtStepStatus.map(({ id, statusName }) => (
                          <Option key={id} value={id}>
                            {statusName}
                          </Option>
                        ))
                      : targetOptions?.targetStatus
                          .filter(
                            item =>
                              item.id !== 'U3RhdHVzVHlwZTo3' && item.id !== 'U3RhdHVzVHlwZTo2',
                          )
                          .reverse()
                          .map(({ id, statusName }) => (
                            <Select.Option key={id} value={id}>
                              {statusName}
                            </Select.Option>
                          ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          ) : (
            <Col span={12}>
              <Form.Item label={type === 'sd' ? 'Stimulus Status' : 'Step Status'} key="sdStatus">
                {getFieldDecorator('status', {
                  initialValue: defaultValues?.status,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please Select Status or delete this field.',
                    },
                  ],
                })(
                  <Select
                    loading={targetOptionsLoading}
                    style={{ width: '100%' }}
                    placeholder="Select status"
                    {...searchableDropDownOption}
                  >
                    {type === 'sbt'
                      ? sbtStatusData?.getSbtStepStatus.map(({ id, statusName }) => (
                          <Option key={id} value={id}>
                            {statusName}
                          </Option>
                        ))
                      : targetOptions?.targetStatus
                          .filter(
                            item =>
                              item.id !== 'U3RhdHVzVHlwZTo3' && item.id !== 'U3RhdHVzVHlwZTo2',
                          )
                          .reverse()
                          .map(({ id, statusName }) => (
                            <Select.Option key={id} value={id}>
                              {statusName}
                            </Select.Option>
                          ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          )}
        </Row>

        {edit ? (
          <Form.Item label={type === 'sbt' ? 'Reinforcer' : 'Prompt'} key="sdPromt">
            {getFieldDecorator('prompt', {
              rules: [{ required: true }],
              initialValue: stimulusObject.prompt,
            })(
              <Select
                mode="multiple"
                placeholder={`Please select ${type === 'sbt' ? 'reinforcer' : 'prompt'}`}
                loading={promptLoading}
              >
                {type === 'sbt'
                  ? sbtReinforcersData?.getSbtReinforces.map(p => (
                      <Option value={p.id} key={p.id}>
                        {p.name}
                      </Option>
                    ))
                  : promptData?.promptCodes.map(p => (
                      <Option value={p.id} key={p.id}>
                        {p.promptName}
                      </Option>
                    ))}
              </Select>,
            )}
          </Form.Item>
        ) : (
          <Form.Item label={type === 'sbt' ? 'Reinforcer' : 'Prompt'} key="sdPrompt">
            {getFieldDecorator('prompt', {
              initialValue: defaultValues?.prompt,
              rules: [{ required: true }],
            })(
              <Select
                mode="multiple"
                placeholder={`Please select ${type === 'sbt' ? 'reinforcer' : 'prompt'}`}
                select
                loading={promptLoading}
              >
                {type === 'sbt'
                  ? sbtReinforcersData?.getSbtReinforces.map(p => (
                      <Option value={p.id} key={p.id}>
                        {p.name}
                      </Option>
                    ))
                  : promptData?.promptCodes.map(p => (
                      <Option value={p.id} key={p.id}>
                        {p.promptName}
                      </Option>
                    ))}
              </Select>,
            )}
          </Form.Item>
        )}

        {type === 'sbt' ? (
          <Row gutter={[24]} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Col span={17} />
            <Col span={7}>
              <Button
                type="default"
                style={{
                  background: '#34A770',
                  color: 'white',
                  marginBottom: 12,
                }}
                onClick={() => {
                  setSbtMasteryModal({ show: true, add: true, type: 'sbt' })
                }}
              >
                <Icon type="plus" /> Add manual mastery
              </Button>
            </Col>
          </Row>
        ) : (
          // mastery criteria

          <div>
            <div
              className="flexCenterJust"
              style={{ width: '100%', marginBottom: 12, alignItems: 'flex-end' }}
            >
              {edit ? (
                <Form.Item
                  label="Mastery Criteria"
                  style={{ marginBottom: 0, width: '60%' }}
                  key="sdMC"
                >
                  {getFieldDecorator('masteryCriteria', {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: manualMastActive ? undefined : stimulusObject?.masteryCriteria, // stimulusObject?.masteryCriteria,
                    rules: [
                      {
                        required: !manualMastActive,
                        whitespace: !manualMastActive,
                        message: 'Please select mastery sequence template.',
                      },
                    ],
                  })(
                    <Select
                      disabled={manualMastActive}
                      name="masteryCriteria"
                      loading={targetOptionsLoading}
                      {...searchableDropDownOption}
                    >
                      {mastSeqTemplate.map(({ id, name }) => {
                        return (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        )
                      })}
                    </Select>,
                  )}
                </Form.Item>
              ) : (
                <Form.Item
                  label="Mastery Criteria"
                  style={{ marginBottom: 0, width: '60%' }}
                  key="sdMC"
                >
                  {getFieldDecorator('masteryCriteria', {
                    initialValue: defaultValues?.masteryCriteria,
                    rules: [
                      {
                        required: !manualMastActive,
                        whitespace: !manualMastActive,
                        message: 'Please provide mastery sequence template',
                      },
                    ],
                  })(
                    <Select
                      disabled={manualMastActive}
                      name="masteryCriteria"
                      loading={targetOptionsLoading}
                      {...searchableDropDownOption}
                    >
                      {mastSeqTemplate.map(({ id, name }) => {
                        return (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        )
                      })}
                    </Select>,
                  )}
                </Form.Item>
              )}

              <Form.Item>
                <Switch
                  checkedChildren="Manual Mastery"
                  unCheckedChildren="Default Mastery"
                  checked={manualMastActive}
                  onChange={e => {
                    if (e) {
                      form.resetFields('masteryCriteria')
                    } else if (mastSeqTemplate[0]?.id) {
                      form.setFieldsValue({ masteryCriteria: mastSeqTemplate[0]?.id })
                    }
                    setManualMastActive(e)
                  }}
                />
              </Form.Item>
            </div>
            {profileMstLoading ? (
              <LoadingComponent />
            ) : (
              <MasterySequence
                manualMastActive={manualMastActive}
                mstSeqObj={masterySeqObj}
                setMstSeqObj={setMasterySeqObj}
                activeMasteryPhase={activeMasteryPhase}
              />
            )}
          </div>
        )}

        {type !== 'sbt' && (
          <div>
            <AttachmentFormItem
              form={form}
              type={type}
              setTargetAtt={setStimulusAtt}
              targetAtt={stimulusAtt}
              label="Attachments"
            />
          </div>
        )}

        <Form.Item style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
          <Button
            onClick={edit ? updateForm : SubmitForm}
            type="primary"
            htmlType="submit"
            style={{ ...SUBMITT_BUTTON, margin: 0 }}
          >
            {edit ? 'Update' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>

      {/* Modal for Stimulus Mastery Condition */}
      <Modal
        visible={stimulusModal?.show}
        title="Manual Mastery Criteria"
        onCancel={() => setStimulusModal({ ...stimulusModal, show: false })}
        zIndex={1001}
        width={800}
        destroyOnClose
        footer={null}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <NewMasteryForm
            obj={stimulusModal}
            listMastery={listStimulusMastery}
            setListMastery={setListStimulusMastery}
            setOpen={() => setStimulusModal({ ...stimulusModal, show: false })}
          />
        </div>
      </Modal>

      {/* Modal for Behavior sbt step Mastery Condition */}
      <Modal
        visible={sbtMasteryModal?.show}
        title="Behaviour Reduction Mastery Criteria"
        onCancel={() => setSbtMasteryModal({ ...sbtMasteryModal, show: false })}
        zIndex={1001}
        width={800}
        destroyOnClose
        footer={null}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SbtMasteryForm
            obj={sbtMasteryModal}
            listMastery={listStimulusMastery}
            setListMastery={setListStimulusMastery}
            setOpen={() => setSbtMasteryModal({ ...sbtMasteryModal, show: false })}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Form.create()(StimulusModal)
