/* eslint-disable */
import { Document, Image, Page, PDFViewer, Text, View } from '@react-pdf/renderer'
import gql from 'graphql-tag'
import moment from 'moment'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import { calculateAge } from '../../utilities'
import LoadingComponent from 'components/LoadingComponent'

const sectionMain = {
  width: '100%',
  border: '1px solid black',
  color: 'black',
  height: '100%',
}

const section = {
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}

const dateSection = {
  width: '40%',
  color: 'black',
  fontSize: 12,
  alignSelf: 'flex-start',
  textAlign: 'left',
  fontWeight: '500',
  padding: 2,
  // height: '60px',
}

const optionFlexSection = {
  width: '100%',
  display: 'flex',
  color: 'black',
  flexDirection: 'row',
  padding: '5px 8px',
  textAlign: 'left',
  // height: '20px',
}

const LOGO = gql`
  query {
    schoolDetail {
      logo
      schoolName
      address
    }
  }
`

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const DailyResponseRatePdf = ({
  lineChartImage,
  dateRange,
  peakType,
  type,
  targetStatus,
  target,
  subTitle,
}) => {
  const startDate = moment(dateRange[0]).format('YYYY/MM/DD')
  const endDate = moment(dateRange[1]).format('YYYY/MM/DD')
  const targetType = peakType === 'dir/gen' ? 'DIR/GEN' : 'EQUI'

  const learnerDetails = useSelector(state => state.student.StudentDetails)

  const { data: dt, error: er, loading: ld } = useQuery(LOGO, {
    fetchPolicy: 'no-cache',
  })
  const [logo, setLogo] = useState()
  console.log('selceted Target>>', target, subTitle)
  useEffect(() => {
    if (dt) {
      setLogo(dt.schoolDetail)
    }
  }, [dt])

  if (ld) {
    return <LoadingComponent />
  }

  return (
    <>
      <PDFViewer style={{ margin: 'auto', width: '98%', height: '1200px' }}>
        <Document>
          <Page
            size="A4"
            style={{
              flexDirection: 'row',
              backgroundColor: '#fff',
              padding: 10,
            }}
            wrap={true}
          >
            <View style={{ width: '100%', height: '100%', margin: 'auto' }}>
              <View style={sectionMain}>
                {/* <PdfHeader dataLog={data} /> */}
                <View style={{ ...section, padding: '0' }}>
                  <View
                    style={{
                      display: 'flex',
                      textAlign: 'center',

                      padding: '8px',
                    }}
                  >
                    <Text style={{ margin: 'auto' }}>Daily Response Report</Text>
                  </View>
                </View>
                <View
                  style={{
                    ...section,
                    height: '100px',
                    padding: '0',
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      width: '60%',
                      height: '100%',
                    }}
                  >
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}>Name:</Text>
                      <Text style={{ ...dateSection, width: '80%' }}>
                        {' '}
                        : {learnerDetails?.firstname} {learnerDetails.lastName}
                      </Text>
                    </View>
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}>Age: </Text>
                      <Text style={{ ...dateSection, width: '80%' }}> : {learnerDetails?.dob}</Text>
                    </View>
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Date of Birth: </Text>
                      <Text style={{ ...dateSection, width: '80%' }}>
                        {' '}
                        : {learnerDetails?.dob && calculateAge(learnerDetails?.dob)}
                      </Text>
                    </View>
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> email: </Text>
                      <Text style={{ ...dateSection, width: '80%' }}>
                        {' '}
                        : {learnerDetails.email}
                      </Text>
                    </View>
                    <View
                      style={{ ...optionFlexSection, paddingBottom: '0', marginBottom: '10px' }}
                    >
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Mobile: </Text>
                      <Text style={{ ...dateSection, width: '80%' }}>
                        {' '}
                        : {learnerDetails.mobileno}
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: '20%', alignSelf: 'center', marginRight: 20 }}>
                    {logo && (
                      <Image src={logo.logo} style={{ width: '100%', alignSelf: 'center' }} />
                    )}
                  </View>
                </View>

                <View style={{ ...section, height: '100px', padding: '0' }}>
                  <View
                    style={{
                      width: '60%',
                      height: '80%',
                    }}
                  >
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}>Date:</Text>
                      <Text style={{ ...dateSection, width: '80%' }}>
                        : {startDate} - {endDate}
                      </Text>
                    </View>
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Report Type: </Text>
                      <Text style={{ ...dateSection, width: '80%' }}> : {targetType}</Text>
                    </View>
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Type: </Text>
                      <Text style={{ ...dateSection, width: '80%' }}> : {type}</Text>
                    </View>
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Target Status : </Text>
                      <Text style={{ ...dateSection, width: '80%' }}> : {targetStatus}</Text>
                    </View>
                    <View style={{ ...optionFlexSection, paddingBottom: '0' }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}>
                        {' '}
                        Target Valid From :{' '}
                      </Text>
                      <Text style={{ ...dateSection, width: '80%' }}> : {target?.date}</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '100%',
                    marginTop: 10,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {target.targetAllcatedDetails?.targetName}
                    {subTitle && `(${subTitle})`}
                  </Text>
                  <View
                    style={{
                      margin: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    <Image style={{ width: '100%' }} src={lineChartImage} />
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  )
}

export default DailyResponseRatePdf
