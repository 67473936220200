import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import {
  Button,
  Card,
  Input,
  List,
  Modal,
  Skeleton,
  Form,
  Popconfirm,
  notification,
  Switch,
  Radio,
  Row,
  Col,
} from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import LoadingComponent from 'components/LoadingComponent'
import CreatePositionForm from './CreatePositionForm'
import { GET_POSITIONS, ACTIVATE_STAFF_POSITION, UPDATE_STAFF_POSITION } from './query'

const StaffPositions = ({ form }) => {
  const [editDrawer, setEditDrawer] = useState(false)
  const [editableObj, setEditableObj] = useState(null)
  const [createDrawer, setCreateDrawer] = useState(false)
  const [isActivePosition, setIsActivePosition] = useState(true)

  const { data, loading, refetch } = useQuery(GET_POSITIONS, {
    variables: { isActive: isActivePosition },
  })
  const [activatePosition] = useMutation(ACTIVATE_STAFF_POSITION)
  const [updateStaffPosition] = useMutation(UPDATE_STAFF_POSITION)

  useEffect(() => {
    refetch()
  }, [isActivePosition])

  const editPositionHandler = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        updateStaffPosition({
          variables: {
            pk: editableObj.id,
            title: values.title,
          },
        })
          .then(res => {
            notification.success({
              message: 'Success',
              description: 'Position updated successfully',
            })
            onEditDrawerClose()
            refetch()
          })
          .catch(error => {
            notification.error({
              message: 'Error',
              description: error.message,
            })
          })
      }
    })
  }

  const activationHandler = (id, isActive) => {
    activatePosition({
      variables: { pk: id, isActive },
    })
      .then(res => {
        notification.success({
          message: 'Updated Successfully',
        })
        refetch()
      })
      .catch(err => {
        notification.error({
          message: 'Something went wrong.',
        })
      })
  }

  const onCreateSuccess = () => {
    setCreateDrawer(false)
    refetch()
  }

  const onEditDrawerClose = () => {
    setEditDrawer(false)
    setEditableObj(null)
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'center',
        }}
      >
        <Radio.Group
          size="medium"
          buttonStyle="solid"
          value={isActivePosition ? 'active' : 'in-active'}
          style={{ margin: 'auto 10px' }}
          onChange={() => {
            setIsActivePosition(!isActivePosition)
          }}
        >
          <Radio.Button value="active">Active</Radio.Button>
          <Radio.Button value="in-active">In-active</Radio.Button>
        </Radio.Group>
        <Button
          type="primary"
          style={{ backgroundColor: '#0B35B3' }}
          onClick={() => setCreateDrawer(true)}
        >
          Add Position
          <PlusOutlined />
        </Button>
      </div>
      <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Col sm={24} md={24} lg={24}>
          {!loading && (
            <Card style={{ marginTop: 20 }}>
              <List
                style={{ zoom: 1.1 }}
                size="small"
                loading={loading}
                itemLayout="horizontal"
                dataSource={data?.getStaffPosition}
                renderItem={item => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Button
                        type="link"
                        onClick={() => {
                          setEditDrawer(true)
                          setEditableObj(item)
                        }}
                      >
                        <EditOutlined />
                      </Button>,
                      <Popconfirm
                        title={`Are you sure to ${
                          item.isActive ? 'inactivate' : 'activate'
                        } this Position?`}
                        onConfirm={() => activationHandler(item.id, !item.isActive)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Switch checked={item.isActive} />
                      </Popconfirm>,
                    ]}
                  >
                    <Skeleton avatar title={false} loading={loading} active>
                      <List.Item.Meta title={item.title} />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Card>
          )}
        </Col>
      </Row>

      <Modal
        title="Edit Position"
        width={600}
        visible={editDrawer}
        onCancel={onEditDrawerClose}
        footer={null}
      >
        <div>
          <Form onSubmit={editPositionHandler} style={{ marginTop: 15 }}>
            {form.getFieldDecorator('title', {
              initialValue: editableObj?.title,
            })(<Input size="large" placeholder="Position tite" />)}

            <div style={{ marginTop: 20, textAlign: 'end' }}>
              <Button
                type="primary"
                style={{ backgroundColor: '#0B35B3' }}
                size="large"
                htmlType="submit"
              >
                Update
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Add New Position"
        width={600}
        onCancel={() => setCreateDrawer(false)}
        visible={createDrawer}
        footer={null}
      >
        <CreatePositionForm onSuccess={onCreateSuccess} />
      </Modal>
    </div>
  )
}

export default Form.create()(StaffPositions)
