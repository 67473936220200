/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */

import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Menu,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd'
import 'jspdf-autotable'
import Moment from 'moment'
import { useQuery } from 'react-apollo'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import html2canvas from 'html2canvas'
import { FaDownload } from 'react-icons/fa'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import ProgressOverView from '../pdf/attendanceExport'
import actionLearners from '../../../redux/learners/actions'
import BarChart from '../BarChart'
import '../behavior.scss'
import PieChart from '../PieChart'
import Report from '../Report'
import '../styles.scss'
import DomainTargetGraph from './DomainTargetGraph'
import DropSection from '../DropSection'
import GoalsPdfSelect from '../GoalsPdfSelect'
import AggregateGraph from './AggregateGraph'
import { ACTIVE_PROGRAM_AREA, GET_TARGET_STATUS } from './query'

const { Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const parentCardStyle = {
  background: 'white',
  borderRadius: '0.5px',
  padding: '1px',
  height: 380,
  marginTop: '1px',
  overflow: 'hidden',
  flex: 1,
  minWidth: '470px',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0 2px 8px 0',
}

const cardStyle = {
  display: 'flex',
  width: '100%',
  height: '100%',
  overflowY: 'hidden',
  overflowX: 'hidden',
}

export const DRAWER = {
  widthL1: '80%',
  widthL2: '60%',
  widthL3: '50%',
  widthL4: '40%',
}

const ProgressOverview = ({ selectedStudentId }) => {
  const dispatch = useDispatch()
  const { StudentName, StudentDetails } = useSelector(state => state.student)
  const { CloneLearners } = useSelector(state => state.learnersprogram)
  let report = useRef()
  const graphContainerRef = useRef()
  const [graphstartdate, setGraphstartdate] = useState(
    Moment(Date.now())
      .subtract(21, 'days')
      .format('YYYY-MM-DD')
      .toString(),
  )
  const [graphenddate, setGraphenddate] = useState(
    Moment(Date.now())
      .format('YYYY-MM-DD')
      .toString(),
  )
  const [targetStatus, setTargetStatus] = useState([])
  const [programArea, setProgramArea] = useState([])
  const [defaultprogram, setDefaultprogram] = useState('')
  const [domainObj, setDomainObj] = useState([])
  const [key, setKey] = useState(0)
  const [selectedprogram, setSelectedprogram] = useState(null)
  const [statusselected, setStatusselected] = useState(null)
  const [domainSelected, setDomainSelected] = useState(null)
  const [barKey, setBarKey] = useState(20)
  const [prescriptionPdf, setPrescriptionPdf] = useState(false)
  const [loadingGeneratePdf, setLoadingGeneratePdf] = useState(false)
  const [domainId, setDomainId] = useState(undefined)
  const [selectedProgramArea, setSelectedProgramArea] = useState(null)
  const [domains, setDomains] = useState([])

  const [currentGraphIndex, setCurrentGraphIndex] = useState(0)
  const [goalsPdfSelect, setGoalsPdfSelect] = useState(false)
  const [pdfList, setPdfList] = useState([])
  const [allImageObj, setAllImageObj] = useState({})

  const [current, setCurrent] = useState(0)

  const { data: allStatus } = useQuery(GET_TARGET_STATUS, {
    fetchPolicy: 'network-only',
  })

  const { data: allProgramArea, loading: pAreaLoading } = useQuery(ACTIVE_PROGRAM_AREA, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    dispatch({
      type: actionLearners.GET_DATA,
    })
  }, [])

  useEffect(() => {
    if (allStatus) {
      setTargetStatus(allStatus.targetStatus)
      setStatusselected(allStatus.targetStatus[0].id)
    }
  }, [allStatus])

  useEffect(() => {
    if (allProgramArea) {
      const pArea = allProgramArea.programArea.edges
      const domain = allProgramArea.domain.edges
      const defaultp = pArea.filter(area => area.node.name === 'ABA')
      setProgramArea(pArea)
      setDomainObj(domain)
      setDefaultprogram(defaultp[0].node.id)
      setSelectedprogram(defaultp[0].node.id)
      setSelectedProgramArea(defaultp[0].node)
    }
  }, [allProgramArea])

  useEffect(() => {
    if (selectedprogram && allProgramArea.programArea) {
      const temp = allProgramArea.programArea.edges.filter(
        ({ node }) => node.id === selectedprogram,
      )
      setDomainId(undefined)
      setDomainSelected(null)
      if (temp.length) {
        setSelectedProgramArea(temp[0].node)
      }
    }
  }, [selectedprogram])

  useEffect(() => {
    scrollToGraph(currentGraphIndex)
  }, [currentGraphIndex])

  const getCurrentGraphIndex = () => {
    const scrollDiv = graphContainerRef.current
    const currentPos = scrollDiv?.scrollLeft
    const childPositions = []
    Array.from(scrollDiv.children).forEach((child, index, arr) => {
      let pos = child.offsetLeft - scrollDiv.parentElement.clientWidth / 2 + child.clientWidth / 2
      if (index === 0) {
        pos = 0
      } else if (index === arr.length - 1) {
        pos = child.offsetLeft - (scrollDiv.parentElement.clientWidth - child.clientWidth)
      }
      childPositions.push(Math.max(pos, 0))
    })
    const selectedChild = childPositions.findIndex(pos => {
      if (Math.abs(pos - currentPos) <= 15) {
        return true
      }
      return false
    })
    return selectedChild
  }

  const nextGraph = () => {
    const scrollDiv = graphContainerRef.current
    const numberOfGraphs = scrollDiv?.children.length
    const selectedChild = getCurrentGraphIndex()
    if (selectedChild < numberOfGraphs - 1) {
      setCurrentGraphIndex(selectedChild + 1)
      scrollToGraph(selectedChild + 1)
    }
  }

  const prevGraph = () => {
    const selectedChild = getCurrentGraphIndex()
    if (selectedChild > 0) {
      setCurrentGraphIndex(selectedChild - 1)
      scrollToGraph(selectedChild - 1)
    }
  }

  const onChange = curr => {
    setCurrent(curr)
  }

  const noLearnerSelected = () => {
    return (
      <>
        <Row>
          <Col sm={24}>
            <div style={parentCardStyle}>
              <Title style={{ fontSize: 20, lineHeight: '27px', textAlign: 'center' }}>
                Select any learner from the list
              </Title>
            </div>
          </Col>
        </Row>
      </>
    )
  }

  const ProgramSelected = program => {
    setSelectedprogram(program)
  }

  const DomainChange = domain => {
    console.log('domain', domain)
    const filtered = allProgramArea.domain.edges.filter(({ node }) => node.id === domain)
    if (filtered.length) {
      setDomainSelected(filtered[0].node.domain)
    }
    setDomainId(domain)
  }

  const StatusCallback = status => {
    setStatusselected(status)
  }

  const DateChange = date => {
    setGraphstartdate(
      Moment(date[0])
        .format('YYYY-MM-DD')
        .toString(),
    )
    setGraphenddate(
      Moment(date[1])
        .format('YYYY-MM-DD')
        .toString(),
    )
  }

  const DateChangeDropDown = date => {
    setGraphstartdate(
      Moment(date[0])
        .format('YYYY-MM-DD')
        .toString(),
    )
    setGraphenddate(
      Moment(date[1])
        .format('YYYY-MM-DD')
        .toString(),
    )
  }

  const scrollToGraph = graphIndex => {
    if (graphContainerRef.current) {
      const viewChild = graphContainerRef.current.children[graphIndex]
      const val =
        viewChild.offsetLeft -
        graphContainerRef.current.parentElement.clientWidth / 2 +
        viewChild.clientWidth / 2
      graphContainerRef.current.scrollTo(val, 0)
    }
  }
  const getActiveGraphStyle = graphIndex => {
    return {
      boxShadow: currentGraphIndex === graphIndex ? 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px' : '',
    }
  }
  const handlePdfDrawerOpen = async list => {
    const imgObj = {}
    const aggregateData = document.querySelector('#aggregateGraph')
    const reportData = document.querySelector('#reportTable')
    const graph = document.querySelector('#domainTargetGraph')
    const barChartCanvas = document.querySelector('#barChart')
    const pieChart = document.querySelector('#pieChart')

    if (aggregateData) {
      imgObj.aggregateData = await html2canvas(aggregateData).then(canvas =>
        canvas.toDataURL('image'),
      )
    }
    if (graph) {
      imgObj.graph = await html2canvas(graph).then(canvas => canvas.toDataURL('image'))
    }
    if (barChartCanvas) {
      imgObj.barChartCanvas = await html2canvas(barChartCanvas).then(canvas =>
        canvas.toDataURL('image'),
      )
    }
    if (reportData) {
      imgObj.reportData = await html2canvas(reportData).then(canvas => canvas.toDataURL('image'))
    }
    if (pieChart) {
      imgObj.pieChart = await html2canvas(pieChart).then(canvas => canvas.toDataURL('image'))
    }

    setPrescriptionPdf(true)
    setPdfList(list)
    setAllImageObj(imgObj)
    setLoadingGeneratePdf(false)
    setGoalsPdfSelect(false)
  }

  const saveStatus = (statusrep, list) => {
    if (statusrep === 'Success') {
      handlePdfDrawerOpen(list)
    }
  }

  const handlePdfDrawerOpenForSelect = () => {
    setGoalsPdfSelect(true)
  }

  const exportToCSV = () => {
    report.exportToCSV(StudentName)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => exportToCSV()} type="link" size="small">
          CSV/Excel
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => handlePdfDrawerOpenForSelect()} type="link" size="small">
          Export PDF
        </Button>
      </Menu.Item>
    </Menu>
  )

  const stdIdData = StudentDetails

  return (
    <>
      <div sm={24} style={{ padding: 0 }}>
        <div className="reports-filter-card">
          <div>
            <DropSection dateChange={DateChangeDropDown} from="PROGRESS_OVERVIEW" />
          </div>
          <div>
            <Tooltip title="Select Date range">
              <RangePicker
                style={{
                  marginLeft: 'auto',
                  width: 240,
                }}
                defaultValue={[
                  Moment(graphstartdate, 'YYYY-MM-DD'),
                  Moment(graphenddate, 'YYYY-MM-DD'),
                ]}
                value={[Moment(graphstartdate, 'YYYY-MM-DD'), Moment(graphenddate, 'YYYY-MM-DD')]}
                format="YYYY-MM-DD"
                onChange={DateChange}
              />
            </Tooltip>
          </div>
          <div>
            {targetStatus.length > 0 && (
              <Tooltip title="Select Status">
                <Select
                  placeholder="Status"
                  onChange={StatusCallback}
                  style={{
                    width: 160,
                    borderRadius: 4,
                  }}
                  size="default"
                  defaultValue={statusselected}
                  showSearch
                  optionFilterProp="name"
                >
                  {targetStatus &&
                    targetStatus
                      .filter(
                        result =>
                          result.statusName !== 'On Hold' && result.statusName !== 'Deleted',
                      )
                      .map(node => {
                        return (
                          <Option key={node.id} value={node.id} name={node.statusName}>
                            {node.statusName}
                          </Option>
                        )
                      })}
                </Select>
              </Tooltip>
            )}
          </div>
          <div>
            {programArea.length > 0 && (
              <Tooltip title="Select Program Area">
                <Select
                  placeholder="From Status"
                  onChange={ProgramSelected}
                  size="default"
                  defaultValue={defaultprogram}
                  style={{
                    width: 160,
                    borderRadius: 4,
                  }}
                >
                  {programArea &&
                    programArea.map(dom => (
                      <Option key={dom.node.id} value={dom.node.id}>
                        {dom.node.name}
                      </Option>
                    ))}
                </Select>
              </Tooltip>
            )}
          </div>
          <div>
            <Tooltip title="Select Domain">
              <Select
                style={{
                  width: 160,
                  borderRadius: 4,
                }}
                placeholder="ALL"
                onChange={DomainChange}
                size="default"
                value={domainId}
                loading={pAreaLoading}
                optionFilterProp="name"
                showSearch
              >
                {selectedProgramArea && selectedProgramArea.domain.edges.length && (
                  <Option key="all" id="all" value={null} name="All">
                    All
                  </Option>
                )}
                {selectedProgramArea &&
                  selectedProgramArea.domain.edges.map(({ node }) => (
                    <Option key={node.id} id={node.id} value={node.id} name={node.domain}>
                      {node.domain}
                    </Option>
                  ))}
              </Select>
            </Tooltip>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Dropdown overlay={menu} style={{ padding: 0 }} trigger={['hover']}>
              <Button type="link" size="large" style={{ padding: '0 8px' }}>
                <FaDownload fontSize="22px" />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div style={{ position: 'relative', marginTop: '1rem' }}>
          <div
            // onScroll={e => console.log(e)}
            ref={graphContainerRef}
            className="graphsContainer"
          >
            <div className="reports-graph-card" style={getActiveGraphStyle(0)}>
              <div id="pieChart" style={{ ...cardStyle, minWidth: '700px' }}>
                {selectedprogram && statusselected && (
                  <PieChart
                    key={key}
                    start_date={graphstartdate}
                    end_date={graphenddate}
                    selectedprogram={selectedprogram}
                    statusselected={statusselected}
                    domainSelected={domainSelected}
                    studentIdSelected={selectedStudentId}
                    domainObj={domainObj}
                    domainId={domainId}
                  />
                )}
              </div>
            </div>
            <div className="reports-graph-card" style={getActiveGraphStyle(1)}>
              <div id="barChart" style={{ ...cardStyle, minWidth: '700px' }}>
                {selectedprogram && statusselected && (
                  <BarChart
                    key={barKey}
                    start_date={graphstartdate}
                    end_date={graphenddate}
                    selectedprogram={selectedprogram}
                    statusselected={statusselected}
                    domainSelected={domainSelected}
                    studentIdSelected={selectedStudentId}
                    domainId={domainId}
                  />
                )}
              </div>
            </div>
            <div
              className="reports-graph-card"
              id="domainTargetGraph"
              style={{ maxWidth: '100vw', ...getActiveGraphStyle(2) }}
            >
              {selectedprogram && statusselected && (
                <DomainTargetGraph
                  key={key}
                  startDate={graphstartdate}
                  endDate={graphenddate}
                  selectedProgram={selectedprogram}
                  statusSelected={statusselected}
                  domainSelected={domainSelected}
                  studentIdSelected={selectedStudentId}
                  domainId={domainId}
                />
              )}
            </div>
            <div
              className="reports-graph-card"
              id="domainTargetGraph"
              style={{ maxWidth: '100vw', ...getActiveGraphStyle(2) }}
            >
              {selectedprogram && statusselected && (
                <AggregateGraph
                  domainObj={domainObj}
                  key={key}
                  startDate={graphstartdate}
                  endDate={graphenddate}
                  selectedProgram={selectedprogram}
                  statusSelected={statusselected}
                  domainSelected={domainSelected}
                  studentIdSelected={selectedStudentId}
                  domainId={domainId}
                />
              )}
            </div>
          </div>
          <Tooltip title="Prev Graph">
            <Button type="link" className="slider-btn prev-slide-btn" onClick={prevGraph}>
              <LeftOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Next Graph">
            <Button type="link" className="slider-btn next-slide-btn" onClick={nextGraph}>
              <RightOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="behaviorReport reports-table-card">
        {selectedprogram && statusselected && (
          <div id="reportTable">
            <Report
              ref={instance => {
                report = instance
              }}
              start_date={graphstartdate}
              end_date={graphenddate}
              selectedprogram={selectedprogram}
              statusselected={statusselected}
              domainSelected={domainSelected}
              studentIdSelected={selectedStudentId}
              domainId={domainId}
            />
          </div>
        )}
      </div>
      <Modal
        visible={goalsPdfSelect}
        onCancel={() => setGoalsPdfSelect(false)}
        title="Select Content"
        footer=""
        destroyOnClose
        // confirmLoading={loadingGeneratePdf}
      >
        <GoalsPdfSelect
          closeDrawer={() => setGoalsPdfSelect(false)}
          fromComponent="ProgressOverview"
          saveStatus={saveStatus}
          loadingGeneratePdf={loadingGeneratePdf}
        />
      </Modal>
      <Drawer
        width={DRAWER.widthL2}
        title="Progress Overview PDF"
        closable
        onClose={() => setPrescriptionPdf(false)}
        destroyOnClose
        visible={prescriptionPdf}
      >
        <ProgressOverView
          data={stdIdData}
          graphstartdate={graphstartdate}
          graphenddate={graphenddate}
          targetStatus={targetStatus}
          statusselected={statusselected}
          programArea={programArea}
          selectedprogram={selectedprogram}
          domainObj={domainObj}
          domainSelected={domainSelected}
          studentIdSelected={selectedStudentId}
          pdfList={pdfList}
          allImgObj={allImageObj}
          closeDrawer={() => setPrescriptionPdf(false)}
          domainId={domainId}
        />
      </Drawer>
    </>
  )
}

export default Form.create()(ProgressOverview)
