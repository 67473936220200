import actions from './actions'

const initialState = {
  ItemCopied: false,
  Type: '',
  LongGoal: null,
  ShortGoal: null,
  AllocatedTarget: null,
  ProgramArea: null,
  isDefault: null,
  std1: null,
  std2: null,
  PasteLoading: false,
  Paste: null,
  PasteError: false,
}

export default function CopyTargetReduces(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
