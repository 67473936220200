const actions = {
  SET_STATE: 'learnerDashboard/SET_STATE',
  LOAD_DATA: 'learnerDashboard/LOAD_DATA',
  LOAD_SESSIONS: 'learnerDashboard/LOAD_SESSIONS',
  LOAD_APPOINTMENTS: 'learnerDashboard/LOAD_APPOINTMENTS',
  LOAD_TASKS: 'learnerDashboard/LOAD_TASKS',

}

export default actions
