/* eslint-disable no-restricted-globals */
import React from 'react'
import { Typography, Input, Button, Form, Tooltip } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import RequiredLabel from './requiredLabel'

const { Text } = Typography

const isNumeric = str => {
  if (typeof str === 'number') return true
  if (typeof str !== 'string') return false // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ) // ...and ensure strings of whitespace fail
}

const NumberCard = ({
  title,
  unit = '',
  number,
  label,
  required = false,
  disabled = false,
  setNumber,
  addonAfter,
  maxValue = 999,
  minValue = 0,
  style,
  setActiveCount,
  typeCard,
}) => {
  const onChange = type => {
    if (isNumeric(number)) {
      if (type === 'i' && (number + 1 < maxValue || number + 1 === maxValue)) {
        if (typeCard === 'sd' || typeCard === 'step') setActiveCount()
        setNumber(number + 1)
      } else if (type === 'd' && (number - 1 > minValue || number - 1 === minValue))
        setNumber(number - 1)
    } else if (type === 'i') {
      setNumber(0)
      if (typeCard === 'sd' || typeCard === 'step') setActiveCount()
    } else if (type === 'd') setNumber(0)
  }

  const setNumberValue = num => {
    if (isNaN(num)) {
      setNumber('')
    } else if (num <= maxValue && num >= minValue) {
      setNumber(num)
    }
  }

  return (
    <div style={{ float: 'left' }}>
      <Form.Item label={<RequiredLabel required={required} label={label} />}>
        <div
          style={{
            margin: 0,
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '14px',
            ...style,
          }}
        >
          <div>
            <Text style={{ fontSize: '14px' }}>{title}</Text>
          </div>

          <div>
            <Button
              disabled={disabled}
              style={{ marginLeft: 'auto', marginRight: 10 }}
              onClick={() => onChange('d')}
            >
              <MinusOutlined />
            </Button>
            <Input
              disabled={disabled}
              type="number"
              value={number}
              max={maxValue}
              style={{ width: 120, marginRight: 10 }}
              onChange={e => setNumberValue(parseInt(e.target.value, 10))}
              addonAfter={addonAfter}
            />
            <Button disabled={disabled} onClick={() => onChange('i')}>
              <PlusOutlined />
            </Button>
          </div>
        </div>
      </Form.Item>
    </div>
  )
}

export default NumberCard
