/* eslint-disable react/destructuring-assignment */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { Collapse, Input, Tag } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import LoadingComponent from 'components/LoadingComponent'
import './styles.scss'
import Scrollbars from 'react-custom-scrollbars'
import Highlighter from 'react-highlight-words'

const { Panel } = Collapse

const Avatar = ({ name, bgColor }) => {
  const initial = name
    .trim()
    .split(' ')
    .map(word => word[0])
    .join('')
    .slice(0, 2)

  return (
    <div
      style={{
        padding: '0.5rem',
        borderRadius: '2rem',
        backgroundColor: bgColor,
        height: '2.5rem',
        width: '2.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '1rem',
      }}
    >
      <span>{initial}</span>
    </div>
  )
}

const UsingProps = ({ setStudent, closeDrawer }) => {
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()
  const {
    Learners,
    LearnersFetched,
    CloneLearners,
    SelectedLearnerId,
    Loading,
    LearnerDetails,
  } = useSelector(state => state.learnersprogram)
  const { StudentDetails } = useSelector(state => state.student)

  useEffect(() => {
    if (!LearnersFetched) {
      dispatch({
        type: 'learnersprogram/LOAD_DATA',
      })
    }
  }, [])

  const studentChanged = stdId => {
    if (stdId && setStudent) {
      const st = Learners.filter(({ node }) => node.id === stdId)
      if (st.length > 0) {
        setStudent(st[0].node)
      }
    }
    if (closeDrawer) {
      closeDrawer()
    }
  }

  const searchLearner = text => {
    const searchedLearnersList = []
    if (CloneLearners.length > 0) {
      CloneLearners.map(itemNode => {
        if (itemNode.node.firstname.toLowerCase().includes(text.toLowerCase())) {
          searchedLearnersList.push(itemNode)
        }
      })
    }

    dispatch({
      type: 'learnersprogram/SET_STATE',
      payload: {
        Learners: searchedLearnersList,
      },
    })
  }
  const filteredLearnersList = Learners
  return (
    <>
      {Loading ? (
        <LoadingComponent />
      ) : (
        <div style={{ padding: 0 }}>
          <div style={{ padding: '0 0.3rem' }}>
            <div
              style={{
                display: 'flex',
                marginBottom: '1rem',
                backgroundColor: 'white',
                borderRadius: '0.2rem',
                padding: '1rem',
                gap: '1rem',
              }}
            >
              <Input.Search
                placeholder="Search learner by name"
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                allowClear
                style={{
                  backgroundColor: '#f9f9f9',
                }}
              />
            </div>
          </div>
          <Scrollbars style={{ height: 'calc(100vh - 180px)' }} autoHide>
            <div className="learner-collapse">
              <Collapse
                accordion
                expandIcon={() => <span />}
                onChange={e => e && studentChanged(e)}
              >
                {!Loading &&
                  filteredLearnersList
                    ?.filter(({ node }) => {
                      if (!searchText) {
                        return true
                      }
                      const name = `${node.firstname} ${node.lastname || ''}`.toLowerCase()
                      return name.includes(searchText)
                    })
                    .map((nodeItem, irt) => (
                      <Panel
                        style={{
                          backgroundColor:
                            SelectedLearnerId === nodeItem.node.id ? '#ccfbf1' : null,
                        }}
                        header={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              gap: '1rem',
                            }}
                          >
                            <Avatar
                              name={`${nodeItem.node.firstname} ${nodeItem.node.lastname || ''}`}
                              bgColor="#f1f1f1"
                            />
                            <div style={{ fontWeight: 'bold', color: '#334155', fontSize: 16 }}>
                              <Highlighter
                                searchWords={[searchText]}
                                textToHighlight={`${nodeItem.node.firstname} ${nodeItem.node
                                  .lastname || ''}`}
                              />
                            </div>
                          </div>
                        }
                        key={nodeItem.node.id}
                      >
                        <div key={nodeItem.node.id} style={{ height: 160 }}>
                          <div style={{ fontSize: 16, padding: 8 }}>
                            <div style={{ marginBottom: 8 }}>
                              <b>Email : </b>&nbsp; {StudentDetails?.email}
                            </div>
                            <div style={{ marginBottom: 8 }}>
                              <b>Assessments : </b> &nbsp;
                              {StudentDetails?.isPeakActive && (
                                <a rel="noopener noreferrer" href="/#/peak">
                                  <Tag color="#2db7f5">Peak</Tag>
                                </a>
                              )}
                              {StudentDetails?.isCogActive && (
                                <a rel="noopener noreferrer" href="/#/cogniableAssessment">
                                  <Tag color="#87d068">CogniAble</Tag>
                                </a>
                              )}
                              {StudentDetails?.isVbmappActive && (
                                <a rel="noopener noreferrer" href="/#/cogniableAssessment">
                                  <Tag color="#87d068">Vmbapp</Tag>
                                </a>
                              )}
                            </div>
                            {LearnerDetails?.caseManager ? (
                              <div style={{ marginBottom: 8, display: 'flex' }}>
                                <b>Case Manager : </b> &nbsp; &nbsp;
                                <div>
                                  {LearnerDetails?.caseManager?.name}, &nbsp;
                                  {LearnerDetails?.caseManager?.email}
                                  <br />
                                  {LearnerDetails?.caseManager?.contactNo}
                                </div>
                              </div>
                            ) : (
                              <p>No case manager assigned</p>
                            )}
                          </div>
                        </div>
                      </Panel>
                    ))}
              </Collapse>
            </div>
          </Scrollbars>
        </div>
      )}
    </>
  )
}

export default UsingProps
