const actions = {
  SET_STATE: 'learnersprogram/SET_STATE',
  LOAD_DATA: 'learnersprogram/LOAD_DATA',
  LOAD_INACTIVE_LEARNERS: 'learnersprogram/LOAD_INACTIVE_LEARNERS',
  CHANGE_LEARNERS_STATUS_FILTER: 'learnersprogram/CHANGE_LEARNERS_STATUS_FILTER',
  CHNAGE_LEARNER: 'learnersprogram/CHNAGE_LEARNER',
  GET_LEARNER_SESSION: 'learnersprogram/GET_LEARNER_SESSION',
}

export default actions
