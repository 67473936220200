/* eslint-disable no-shadow */
/* eslint-disable  no-lonely-if */
/* eslint-disable operator-assignment */
/* eslint-disable object-shorthand */
/* eslint-disable no-restricted-globals */
import { Button, Col, Form, Input, Row, Icon } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberCard from './NumberCard'
import './style.scss'

const EditTrial = ({ form }) => {
  const [frequency, setFrequency] = useState(0)
  const [hour, setHour] = useState(0)
  const [min, setMin] = useState(0)
  const [sec, setSec] = useState(0)
  const [objId, setObjId] = useState('')

  const dispatch = useDispatch()
  const {
    BehEditTrialCount,
    TargetActiveId,
    TargetResponse,
    Frequency,
    ResponseLoading,
  } = useSelector(state => state.sessionrecording)

  useEffect(() => {
    calculateInitialValues(BehEditTrialCount)
  }, [])

  const calculateInitialValues = count => {
    const recording = TargetResponse[TargetActiveId]?.behaviorRecording
    if (recording[count - 1]) {
      const trails = recording[count - 1]
      const freq = trails.frequency
      const objId = trails.id
      const duration = trails.end - trails.start
      let sec = 0
      let min = 0
      let hour = 0

      if (duration > 0) {
        sec = Math.round((duration / 1000) % 60)
        min = Math.round((duration / 60000) % 60)
        hour = Math.round((duration / (60 * 60000)) % 24)
      }

      setFrequency(freq)
      setHour(hour)
      setMin(min)
      setSec(sec)
      setObjId(objId)
    }
  }

  const changeTrail = type => {
    let newCount = 1
    if (type === 'prev') newCount = BehEditTrialCount - 1
    if (type === 'next') newCount = BehEditTrialCount + 1

    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        BehEditTrialCount: newCount,
      },
    })

    calculateInitialValues(newCount)
  }

  const handleSubmit = e => {
    e.preventDefault()
    let endTime = 0
    if (hour > 0) endTime = endTime + hour * 60 * 60 * 1000
    if (min > 0) endTime = endTime + min * 60 * 1000
    if (sec > 0) endTime = endTime + sec * 1000
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        Frequency: Frequency + frequency,
      },
    })
    dispatch({
      type: 'sessionrecording/UPDATE_BEHAVIOR_FREQUENCY',
      payload: {
        frequency: frequency,
        objectId: objId,
        start: 0,
        end: endTime,
      },
    })
  }

  const onChangeNumber = num => {
    if (num) setFrequency(num)
  }

  const handleNumberChange = (e, type) => {
    const number = parseInt(e.target.value || 0, 10)
    if (isNaN(number)) console.log('not a number')
    else {
      if (type === 'H' && number < 24) setHour(number)
      else if (type === 'M' && number < 60) setMin(number)
      else if (type === 'S' && number < 60) setSec(number)
    }
  }

  const closeEdit = () => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        isBehaviorEdit: false,
      },
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    style: { textAlign: 'center' },
  }

  return (
    <div className="behavior-recording-div" style={{ marginBottom: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <span>&nbsp;</span>
        <div style={{ fontSize: 12 }}>
          {BehEditTrialCount === 1 ? (
            <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
              <Icon type="left" />
            </Button>
          ) : (
            <Button style={{ border: 'none' }} onClick={() => changeTrail('prev')}>
              <Icon type="left" />
            </Button>
          )}
          Trial {BehEditTrialCount} / {TargetResponse[TargetActiveId]?.behaviorRecording.length}
          {BehEditTrialCount === TargetResponse[TargetActiveId]?.behaviorRecording.length ? (
            <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
              <Icon type="right" />
            </Button>
          ) : (
            <Button style={{ border: 'none' }} onClick={() => changeTrail('next')}>
              <Icon type="right" />
            </Button>
          )}
        </div>
        <Button style={{ border: 'none' }} onClick={() => closeEdit()}>
          X
        </Button>
      </div>
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="vertical">
        <Row>
          <Col sm={9}>
            <Form.Item
              label={
                <>
                  <span style={{ color: 'red', fontSize: 14 }}>*</span>
                  <span>Frequency</span>
                </>
              }
            >
              <NumberCard
                number={frequency}
                form={form}
                maxValue={26}
                setNumber={num => onChangeNumber(num)}
                style={{ float: 'left' }}
              />
            </Form.Item>
          </Col>
          <Col sm={15}>
            <Form.Item label="Duration" style={{ textAlign: 'left' }}>
              <Input
                onChange={e => handleNumberChange(e, 'H')}
                value={hour}
                addonAfter="H"
                type="number"
                max={23}
                style={{ width: 100 }}
              />
              <Input
                onChange={e => handleNumberChange(e, 'M')}
                value={min}
                addonAfter="M"
                type="number"
                max={59}
                style={{ width: 100 }}
              />
              <Input
                onChange={e => handleNumberChange(e, 'S')}
                value={sec}
                addonAfter="S"
                type="number"
                max={59}
                style={{ width: 100 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            type="success"
            loading={ResponseLoading}
            style={{ width: 120, border: '1.5px solid orange', color: 'orange' }}
            htmlType="submit"
          >
            Update
          </Button>
        </div>
      </Form>
    </div>
  )
}

const EditTrialForm = Form.create({ name: 'time_related_controls' })(EditTrial)

export default EditTrialForm
