/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getTargets(payload) {
  return apolloClient
    .query({
      query: gql`
        query($masterSessionId: ID!, $date: Date!, $targetId: ID) {
          getsession(id: $masterSessionId) {
            id
            sessionName {
              id
              name
            }
            student {
              id
              firstname
            }
            name
            itemRequired
            preferredItems(isActive: true) {
              edges {
                node {
                  id
                  item
                  isActive
                }
              }
            }
            instruction {
              edges {
                node {
                  id
                  instruction
                }
              }
            }
            therapistHost {
              edges {
                node {
                  id
                  name
                  email
                }
              }
            }
            sessionHost {
              edges {
                node {
                  id
                  memberName
                  timeSpent {
                    edges {
                      node {
                        id
                        sessionName {
                          id
                          name
                        }
                        duration
                      }
                    }
                  }
                  relationship {
                    id
                    name
                  }
                }
              }
            }
            duration
            targets(id: $targetId, excludeWithStatus: ["U3RhdHVzVHlwZTo3", "U3RhdHVzVHlwZTo2"]) {
              edgeCount
              edges {
                node {
                  id
                  targetInstr
                  peakBlocks
                  peakType
                  eqCode
                  activeSdStepCount
                  shortTerm {
                    id
                    goalName
                  }
                  targetStatus {
                    id
                    statusName
                  }
                  recordingLabels {
                    edges {
                      node {
                        id
                        label
                        newLabel
                      }
                    }
                  }
                  targetId {
                    id
                    domain {
                      id
                      domain
                    }
                  }
                  manualAllocateDomain {
                    id
                    domain
                  }
                  behaviourDescription
                  reactiveProcedure
                  antecedentManipulation
                  targetAllcatedDetails {
                    id
                    targetName
                    DailyTrials
                    trialDuration
                    targetType {
                      id
                      typeTar
                    }
                    promptCodes {
                      edges {
                        node {
                          id
                          promptName
                        }
                      }
                    }
                    automaticTimer
                  }
                  videos {
                    edges {
                      node {
                        id
                        url
                      }
                    }
                  }
                  sd {
                    edges {
                      node {
                        id
                        sd
                      }
                    }
                  }
                  steps {
                    edges {
                      node {
                        id
                        step
                      }
                    }
                  }
                  mastery {
                    edges {
                      node {
                        id
                        sd {
                          id
                          sd
                        }
                        step {
                          id
                          step
                        }
                        behPrompts {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                        mastery {
                          id
                          name
                        }
                        status {
                          id
                          statusName
                        }
                      }
                    }
                  }
                  targetDocs {
                    edges {
                      node {
                        id
                        url
                        sd {
                          id
                          sd
                        }
                        step {
                          id
                          step
                        }
                      }
                    }
                  }
                  classes {
                    edges {
                      node {
                        id
                        name
                        stimuluses {
                          edges {
                            node {
                              id
                              option
                              stimulusName
                            }
                          }
                        }
                      }
                    }
                  }
                  r1 {
                    id
                    behaviorName
                  }
                  r2 {
                    id
                    behaviorName
                  }
                  sbtSteps {
                    edges {
                      node {
                        id
                        description
                        orderNo
                        reinforce {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                        status {
                          id
                          statusName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          promptCodes {
            id
            promptName
          }
          getsessionTargetsToExclude(session: $masterSessionId, date: $date)
          getChildSession(sessions: $masterSessionId, date: $date) {
            edges {
              node {
                id
                sessionDate
                createdAt
                duration
                status
                sessions {
                  id
                  itemRequired
                  sessionName {
                    id
                    name
                  }
                  targets {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        masterSessionId: payload.masterSessionId,
        date: payload.date,
        targetId: payload.targetId,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => console.log(error))
}

export async function getChildSessionData(payload) {
  return apolloClient
    .query({
      query: gql`{
            getSessionRecordings(ChildSession:"${payload.id}", sessiondate: "${payload.date}", targets: "${payload.targetId}") {
                edges {
                    node {
                        id,
                        durationStart,
                        durationEnd,
                        ChildSession{
                            id,
                            sessionDate,
                            status,
                            sessions{
                                id,
                                sessionName{
                                    id,
                                    name
                                }
                            }
                        }
                        note
                        targets{
                            id
                            peakType
                            targetAllcatedDetails {
                              id                              
                              targetType {
                                id
                                typeTar
                              }
                            }

                        }
                        status{
                            id,
                            statusName
                        }
                        isBehReduction
                        totalBehRecordingDuration
                        behReduction{
                          edges{
                            node{
                              isActive
                              id
                              trial
                              duration
                              createdAt
                              r1{
                                id
                                behaviorName
                              }
                              r2{
                                id
                                behaviorName
                              }
                              reinforce{
                                id
                                name
                              }
                              sbtStep{
                                id
                                description
                                status{
                                  id
                                  statusName
                                }
                              }
                              prompt{
                                id
                                promptName
                              }
                            }
                          }
                        }
                        isBehRecording
                        behaviourRecording{
                          edges{
                            node{                        
                              id
                              isActive
                              frequency
                              start
                              end
                              createdAt
                              user{
                                id
                              }
                            }
                          }
                        }
                        isPeakEquivalance
                        peakEquivalance{
                            edges{
                                node{
                                    id
                                    isActive
                                    durationStart
                                    durationEnd
                                    recType
                                    score
                                    codeClass{
                                        id
                                        name
                                    }
                                    relationTrain{
                                        id
                                        stimulus1
                                        sign12
                                        stimulus2
                                    }
                                    relationTest{
                                        id
                                        stimulus1
                                        sign12
                                        stimulus2
                                    }
                                }
                            }
                        }
                        peak {
                            edges{
                                node{
                                    id
                                    durationStart
                                    durationEnd
                                    trial{
                                        edges{
                                            node{
                                                isActive
                                                id 
                                                start
                                                end
                                                sd {
                                                    id 
                                                    sd
                                                }
                                                marks
                                                promptCode{
                                                    id
                                                    promptName 
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        sessionRecord{
                            edges{
                                node{
                                    id,
                                    isActive
                                    entryTime,
                                    trial,
                                    durationStart,
                                    durationEnd,
                                    text,
                                    sd{
                                        id,
                                        sd
                                    },
                                    step{
                                        id,
                                        step
                                    }
                                    promptCode{
                                        id,
                                        promptName
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`,
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong loading chlid session data',
          description: item.message,
        })
      })
    })
}

export async function createChildSession(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($id: ID!, $date: Date, $newSession: Boolean, $duration: Int) {
          startSession(
            input: { 
              parentSession: $id, 
              date: $date, 
              status: "progress", 
              duration: $duration,  
              startLatitude: "${payload.startLat}", 
              startLongitude: "${payload.startLong}",
              localStart: "${payload.currentTime}" 
              newSession: $newSession,
            }
          ) {
            details {
              id
              sessionDate
              status
              duration
              sessions {
                id
                sessionName {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        id: payload.masterSessionId,
        date: payload.SessionDate,
        newSession: !!payload.newVersion,
        duration: payload.sessionDuration || 0,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong creating child session',
          description: item.message,
        })
      })
    })
}

export async function updateChildSessionDuration(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            changeSessionStatus(input:{
                pk:"${payload.id}",
                duration:${payload.duration}
            })
            { 
                details{
                    id,
                    sessionDate,
                    status,
                    duration,
                    sessions{
                        id,
                        sessionName{
                            id,
                            name
                        }
                    }
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing want wrong updating child session duration',
          description: item.message,
        })
      })
    })
}

export async function updateTargetNote(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($skillId: ID!, $targetNote: String!) {
          updateSessionTargetNote(input: { skillId: $skillId, note: $targetNote }) {
            details {
              id
              note
              ChildSession {
                id
              }
            }
          }
        }
      `,
      variables: {
        skillId: payload.skillsId,
        targetNote: payload.targetNote ? payload.targetNote : '',
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing want wrong updating target note',
          description: item.message,
        })
      })
    })
}

export async function finishChildSession(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            changeSessionStatus(input:{
                pk:"${payload.id}",
                duration:${payload.duration},
                status:"completed",
                endLatitude: "${payload.endLat}", 
                endLongitude: "${payload.endLong}",
                localEnd: "${payload.currentTime}" 
            })
            { 
                details{
                    id,
                    sessionDate,
                    status,
                    duration,
                    sessions{
                        id,
                        sessionName{
                            id,
                            name
                        }
                    }
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong submitting child session',
          description: item.message,
        })
      })
    })
}

export async function createFirstTragetAndTrialInstance(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            sessionRecording(input:{
                targets:"${payload.targetId}",
                childsession:"${payload.childId}",
                durationStart:0,
                durationEnd:0,
                status:"${payload.targetStatusId}",
                sessionRecord:[],
            })
            { 
                details {
                    id,
                    durationStart,
                    durationEnd,
                    targets {
                        id,
                    },                    
                    sessionRecord {
                        edges {
                            node {
                                id,
                                trial,
                                durationStart,
                                durationEnd,
                                text,
                                sd {
                                    id,
                                    sd
                                },
                                step {
                                    id,
                                    step
                                },
                                promptCode {
                                    id,
                                    promptName
                                }
                            }
                        }
                    }
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong creating first target object',
          description: item.message,
        })
      })
    })
}

export async function recordTargetCorrectTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            sessionRecording(input:{
                targets:"${payload.targetId}",
                childsession:"${payload.childId}",
                status:"${payload.statusId}",
                sessionRecord:[{trial:"${payload.response}", text:"${payload.note}", durationStart:${payload.start}, durationEnd:${payload.end}, prompt:"${payload.promptId}", sd:"", step:""}],
            })
            { 
                details {
                    id,
                    durationStart,
                    durationEnd,
                    targets {
                        id,
                    },                    
                    sessionRecord {
                        edges {
                            node {
                                id,
                                trial,
                                durationStart,
                                durationEnd,
                                text
                                sd {
                                    id,
                                    sd
                                },
                                step {
                                    id,
                                    step
                                },
                                promptCode {
                                    id,
                                    promptName
                                }
                            }
                        }
                    }
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong creating first target trail object',
          description: item.message,
        })
      })
    })
}

export async function recordTargetStimulusTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            sessionRecording(input:{
                targets:"${payload.targetId}",
                childsession:"${payload.childId}",
                status:"${payload.statusId}",
                sessionRecord:[{trial:"${payload.response}", sdStepStatus: "${payload.sdStepStatus}",  text:"${payload.note}", durationStart:${payload.start}, durationEnd:${payload.end}, prompt:"${payload.promptId}", sd:"${payload.sdId}", step:""}],
            })
            { 
                details {
                    id,
                    durationStart,
                    durationEnd,
                    targets {
                        id,
                    },                    
                    sessionRecord {
                        edges {
                            node {
                                id,
                                trial,
                                durationStart,
                                durationEnd,
                                text
                                sd {
                                    id,
                                    sd
                                },
                                step {
                                    id,
                                    step
                                },
                                promptCode {
                                    id,
                                    promptName
                                }
                            }
                        }
                    }
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong recording stimulus trial',
          description: item.message,
        })
      })
    })
}

export async function recordTargetStepTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            sessionRecording(input:{
                targets:"${payload.targetId}",
                childsession:"${payload.childId}",
                status:"${payload.statusId}",
                sessionRecord:[{trial:"${payload.response}", sdStepStatus: "${payload.sdStepStatus}", text:"${payload.note}", durationStart:${payload.start}, durationEnd:${payload.end}, prompt:"${payload.promptId}", sd:"", step:"${payload.stepId}"}],
            })
            { 
                details {
                    id,
                    durationStart,
                    durationEnd,
                    targets {
                        id,
                    },                    
                    sessionRecord {
                        edges {
                            node {
                                id,
                                trial,
                                durationStart,
                                durationEnd,
                                text
                                sd {
                                    id,
                                    sd
                                },
                                step {
                                    id,
                                    step
                                },
                                promptCode {
                                    id,
                                    promptName
                                }
                            }
                        }
                    }
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong recording step trial',
          description: item.message,
        })
      })
    })
}

export async function updateTargetEndTime(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            updateTargetRec(input:{
                pk:"${payload.skillsId}",
                durationEnd:${payload.endTime}
            })
            { 
                details{
                    id,
                    durationStart,
                    durationEnd
                }               
            }
        }`,
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong updating target endtime',
          description: item.message,
        })
      })
    })
}

export async function createNewTargetInstance(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            sessionRecording(input:{
                targets:"${payload.targetId}",
                childsession:"${payload.childId}",
                durationStart:${payload.start},
                durationEnd:0,
                status:"${payload.statusId}",
                sessionRecord:[],
            })
            { 
                details {
                    id,
                    durationStart,
                    durationEnd,
                    targets {
                        id,
                    },
                    sessionRecord {
                        edges {
                            node {
                                id,
                                trial,
                                durationStart,
                                durationEnd,
                                text,
                                sd {
                                    id,
                                    sd
                                },
                                step {
                                    id,
                                    step
                                },
                                promptCode {
                                    id,
                                    promptName
                                }
                            }
                        }
                    }              
                }
            }
        }`,
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong creating new target object',
          description: item.message,
        })
      })
    })
}

export async function updateTargetTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            updateTrial(input:{
                pk:"${payload.object.id}",
                trial:"${payload.response}",
                promptCode:"${payload.promptId}",
                text:"${payload.note}"
            })
            { 
                details {
                    id,
                    trial,
                    durationStart,
                    durationEnd,
                    text,
                    sd {
                        id,
                        sd
                    },
                    step {
                        id,
                        step
                    },
                    promptCode {
                        id,
                        promptName
                    }
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong updating target trial object',
          description: item.message,
        })
      })
    })
}

export async function createTargetBlock(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation{
            peakBlock(
                input:{
                    pk:"${payload.skillsId}"
                    durationStart: ${payload.startTime ? payload.startTime : 0}
                    durationEnd: 0
                }
            ){
                skill{
                    id
                    durationStart
                    durationEnd
                    targets{
                        id
                    }
                    peak{
                        edges{
                            node{
                                id
                                durationStart
                                durationEnd
                                entryTime
                            }
                        }
                    }
                }
                block{
                    id  
                    durationStart
                    durationEnd
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong creating target block',
          description: item.message,
        })
      })
    })
}

export async function updateTargetBlock(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation{
            peakBlock(
                input:{
                    pk:"${payload.skillsId}"
                    block: "${payload.blockId}"
                    durationEnd: ${payload.endTime}
                }
            ){
                skill{
                    id
                    durationStart
                    durationEnd
                    targets{
                        id
                    }
                    peak{
                        edges{
                            node{
                                id
                                durationStart
                                durationEnd
                                entryTime
                            }
                        }
                    }
                }
                block{
                    id  
                    durationStart
                    durationEnd
                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong updating target block',
          description: item.message,
        })
      })
    })
}

export async function recordBlockTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($blockId: ID!, $sd: ID!, $start: Int!, $end: Int!, $marks: Int!, $sdStatus: ID) {
          peakBlockTrials(
            input: {
              block: $blockId
              sdStatus: $sdStatus
              sd: $sd
              start: $start
              end: $end
              marks: $marks
            }
          ) {
            trial {
              id
              start
              end
              marks
              sd {
                id
                sd
              }
            }
          }
        }
      `,
      variables: {
        blockId: payload.blockId,
        sd: payload.sd,
        start: payload.startTime,
        end: payload.endTime,
        marks: payload.marks,
        sdStatus: payload.sdStatus,
      },
    })
    .then(result => result)
    .catch(error => {
      console.error(error)
      return notification.error({
        message: 'Somthing went wrong recording block trial',
      })
    })
}

export async function updateBlockTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($id: ID!, $sd: ID!, $marks: Int!) {
          peakBlockUpdateTrial(input: { pk: $id, sd: $sd, marks: $marks }) {
            trial {
              id
              start
              end
              marks
              sd {
                id
                sd
              }
            }
          }
        }
      `,
      variables: {
        id: payload.responseId,
        sd: payload.sd,
        marks: payload.marks,
      },
    })
    .then(result => result)
    .catch(error => {
      console.error(error)
      return notification.error({
        message: 'Somthing went wrong updating block trial',
      })
    })
}

export async function createNewTargetPeakAutomaticInstance(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation{
        peakBlockAutomatic(
          input:{
            target:"${payload.targetId}"
            childSession:"${payload.childId}"
            durationStart: ${payload.start}
            durationEnd:0
          }
        ){
          skill{
            id
            durationStart
            durationEnd
            ChildSession{
              id
            }
            targets{
              id
              peakBlocks
              targetAllcatedDetails{
                id
                targetName
                dateBaseline
              }
            }
            peak{
              edges{
                node{
                  id
                  durationStart
                  durationEnd
                  entryTime
                  trial{
                    edges{
                      node{
                        id
                        marks
                        sd{
                          id
                          sd
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }`,
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function getCombinationsByCode(payload) {
  return apolloClient
    .query({
      query: gql`query{
        getPeakEquCodes(code:"${payload.code}"){
            edges{
                node{
                    id
                    code
    
                    train{
                        edges{
                            node{
                                id
                                stimulus1
                                sign12
                                stimulus2
                                sign23
                                stimulus3
                            }
                        }
                    }

                    test{
                        edges{
                            node{
                                id
                                stimulus1
                                sign12
                                stimulus2
                                sign23
                                stimulus3
                            }
                        }
                    }
    
                }
            }
        }
    }`,
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong getting stimulus combinations',
          description: item.message,
        })
      })
    })
}

export async function recordEquivalenceTarget(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
        sessionRecording(input:{
          childsession:"${payload.childId}",
          targets:"${payload.targetId}",
          status:"${payload.statusId}",
          sessionRecord:[],
          isPeakEquivalance:true,
          peakEquivalance:[
            {
              score: ${payload.response}
              recType:"${payload.operation}", 
              durationEnd: ${payload.end}, 
              durationStart: ${payload.start}, 
              codeClass:"${payload.classId}", 
              relationTest:"${payload.combinationTest}", 
              relationTrain:"${payload.combinationTrain}",
            },
          ]
        })
           { 
               details{
                   id,
                   durationStart,
                   durationEnd,
                   targets{
                       id
                   },
                   ChildSession{
                       id,
                       sessionDate,
                       status,
                       duration,
                       sessions{
                           id,
                           sessionName{
                               id,
                               name
                           }
                       }
                   }
                   peakEquivalance(last:1){
                       edges{
                           node{
                              id
                               durationStart
                               durationEnd
                               recType
                               score
                               codeClass{
                                  id
                                   name
                               }
                               relationTrain{
                                   stimulus1
                                   sign12
                                   stimulus2
                               }
                               relationTest{
                                   stimulus1
                                   sign12
                                   stimulus2
                               }
                           }
                       }
                   }
               }
           }
      }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong recording equivalence trial',
          description: item.message,
        })
      })
    })
}

export async function recordEquivalenceTargetUpdate(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation{
        updatePeakEquivalanceTrial(input:{
            pk:"${payload.objectId}"
            score: ${payload.response}
        }){
            details{
                id
                durationStart
                durationEnd
                recType
                score
                codeClass{
                    id
                    name
                }
                relationTrain{
                    id
                }
                relationTest{
                    id
                }
            }
        }
    }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong updating equivalence trial',
          description: item.message,
        })
      })
    })
}

export async function recordBehaviorReductionTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
        recordBehaviourReductionRecording(input:{
          targets:"${payload.targetId}"
          childSession:"${payload.childId}"
          status:"${payload.statusId}"
          isBehReduction: true
          totalBehRecordingDuration:0
          isBehRecording:false
          behaviourRecording:[]
          behReduction:[
            {
              trial:"${payload.response}", 
              duration: ${payload.duration}, 
              r1:"${payload.r1}", 
              r2:"${payload.r2}", 
              sbtStep:"${payload.sbtStepId}",
              stepStatus: "${payload.sbtStatusId}"
              prompt:"${payload.promptId}",
              reinforce:"${payload.reinforceId}"
            }
          ]
        }){
            behaviorReduction{
              id
              trial
              duration
              createdAt
              r1{
                id
                behaviorName
              }
              r2{
                id
                behaviorName
              }
              reinforce{
                id
                name
              }
              sbtStep{
                id
                description
                status{
                  id
                  statusName
                }
              }
              prompt{
                id
                promptName
              }
            }
            details{
              id
              durationStart
              durationEnd
              targets{
                  id
              }
              isBehReduction
              totalBehRecordingDuration
              isBehRecording
            }
          }
        }`,
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function updateBehaviorReductionTargetTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation{
        updateBehaviourReductionTrial(input:{
          pk:"${payload.objectId}"
          trial:"${payload.response}"
          r1:"${payload.r1}", 
          r2:"${payload.r2}",
          prompt:"${payload.promptId}",
          reinforce:"${payload.reinforceId}"
        }){
            details{
              id
              trial
              duration
              createdAt
              r1{
                id
                behaviorName
              }
              r2{
                id
                behaviorName
              }
              reinforce{
                id
                name
              }
              sbtStep{
                id
                description
                status{
                  id
                  statusName
                }
              }
              prompt{
                id
                promptName
              }
            }
        }

      }`,
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function recordBehaviorFrequency(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
        recordBehaviourReductionRecording(input:{
          targets:"${payload.targetId}"
          childSession:"${payload.childId}"
          status:"${payload.statusId}"
          isBehReduction: false
          totalBehRecordingDuration: ${payload.totalbehRecordingTime}
          behaviourRecording:[
            {frequency: ${payload.frequency}, start: ${payload.start}, end: ${payload.end}},
          ]
          isBehRecording:${payload.isBehRecording}
          behReduction:[]
        }){
            behaviorRecording{
              id
              frequency
              start
              end
              createdAt
              user{
                id
              }
              isActive
            }
            details{
              id
              durationStart
              durationEnd
              targets{
                  id
              }
              isBehReduction
              totalBehRecordingDuration
              isBehRecording
            }
          }
        }`,
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function updateBehaviorFrequency(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation{
        updateBehaviourRecordingTrial(input:{
          pk: "${payload.objectId}",
          start: ${payload.start},
          end:  ${payload.end},
          frequency:  ${payload.frequency},
        }){
          details{
            id
            frequency
            start
            end
            createdAt
            user{
              id
            }
            isActive
          }
        }
      }`,
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function deleteBehaviorFrequency(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation{
        activeInactiveTrialRecordedData(input:{
          skillId:"${payload.skillId}"
          isActive: false
          trialId: "${payload.objectId}"
       
        }){
          msg
          status
        }
      }`,
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function recordBehaviorTotalTime(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
        recordBehaviourReductionRecording(input:{
          targets:"${payload.targetId}"
          childSession:"${payload.childId}"
          status:"${payload.statusId}"
          isBehReduction: false
          totalBehRecordingDuration: ${payload.totalbehRecordingTime}
          behaviourRecording:[]
          isBehRecording:${payload.isBehRecording}
          behReduction:[]
        }){
            details{
              id
              durationStart
              durationEnd
              targets{
                  id
              }
              isBehReduction
              totalBehRecordingDuration
              isBehRecording
            }
          }
        }`,
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function getTodaysSession(payload) {
  return apolloClient
    .query({
      query: gql`
        query($studentId: ID!, $date: Date) {
          GetStudentSession(studentId: $studentId, date: $date) {
            edges {
              node {
                id
                name
                itemRequired
                duration
                sessionName {
                  id
                  name
                }
                instruction {
                  edges {
                    node {
                      id
                      instruction
                    }
                  }
                }
                childsessionSet {
                  edges {
                    node {
                      id
                      status
                    }
                  }
                }
                sessionHost {
                  edges {
                    node {
                      id
                      memberName
                    }
                  }
                }
                targets {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        studentId: payload.studentId,
        date: payload.date,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function resetTargetData(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation{
        activeInactiveTargetRecordedData(input:{
          skillId: "${payload.skillsId}"
          isActive: false
          isPeakAuto: ${payload.isAutomatic}
        }){
          msg
          status
        }
      }`,
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function getSessionTemplatesNew(payload) {
  const dataTemp = payload.data.map((item, index) => {
    if (item.id && payload.studentId) {
      return apolloClient
        .query({
          query: gql`
            query($session: ID!, $learner: ID!) {
              sessionDataRecordingTemplateNew(learner: $learner, sessionId: $session) {
                sessionRecordingTemplate
              }
            }
          `,
          variables: {
            session: item.id,
            learner: payload.studentId,
          },
        })
        .then(res => {
          return { ...res, sessionId: item.id }
        })
        .catch(err => console.error(err, 'check get excel download link api error'))
    }
    return null
  })

  return Promise.all(dataTemp)
    .then(res => res)
    .catch(err => console.error(err))
}

export async function runSessionEndMasteryCriteria(payload) {
  if (payload.sessionId) {
    return apolloClient
      .mutate({
        mutation: gql`
          mutation($sessionId: ID!) {
            runMasteryCriteria(input: { sessionId: $sessionId }) {
              message
              status
            }
          }
        `,
        variables: {
          sessionId: payload.sessionId,
        },
        fetchPolicy: 'no-cache',
      })
      .then(result => result)
      .catch(error => console.error(error))
  }
}

export async function resetSdStepSbtstep(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($skillsId: ID!, $sdId: ID, $stepId: ID, $sbtStepId: ID) {
          activeInactiveSdStepRecording(
            input: {
              skillId: $skillsId
              stimulasId: $sdId
              stepId: $stepId
              sbtStepId: $sbtStepId
              isActive: false
            }
          ) {
            msg
            status
          }
        }
      `,
      variables: {
        skillsId: payload.skillsId,
        sdId: payload.sdId === '' ? null : payload.sdId,
        stepId: payload.stepId === '' ? null : payload.stepId,
        sbtStepId: payload.sbtStepId,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function resetBlock(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($skillsId: ID!, $blockId: ID, $isAutomatic: Boolean) {
          activeInactiveTrialRecordedData(
            input: {
              skillId: $skillsId
              peakBlockId: $blockId
              isPeakAuto: $isAutomatic
              isActive: false
            }
          ) {
            msg
            status
          }
        }
      `,
      variables: {
        skillsId: payload.skillsId,
        blockId: payload.blockId,
        isAutomatic: payload.isAutomatic,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function resetTrial(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($skillsId: ID!, $trialId: ID, $isAutomatic: Boolean) {
          activeInactiveTrialRecordedData(
            input: {
              skillId: $skillsId
              trialId: $trialId
              isPeakAuto: $isAutomatic
              isActive: false
            }
          ) {
            msg
            status
          }
        }
      `,
      variables: {
        skillsId: payload.skillsId,
        trialId: payload.trialId,
        isAutomatic: payload.isAutomatic,
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => console.error(error))
}
