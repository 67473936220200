import React from 'react'
import StudentAndTestDetails from '../StudentAndTestDetails'
import BarriersGraph from './BarriersGraph'

const BarriersTab = ({ data, onSelectionChange, refetch, areaId }) => (
  <div className="barrierTab" id="barriers">
    <StudentAndTestDetails
      scoreDetails={data.scoreDetails}
      studentDetails={data.studentDetails}
      onSelectionChange={id => onSelectionChange(id)}
      refetch={() => refetch()}
      areaId={areaId}
    />
    <BarriersGraph questions={data.questions} scoreDetails={data.scoreDetails} />
  </div>
)

export default BarriersTab
