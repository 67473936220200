/* eslint-disable import/prefer-default-export */
import moment from 'moment'

const CELL_FORMATS = {
  COUNT: 'count',
  PERCENTAGE: 'percentage',
  COUNT_BY_TOTAL: 'countByTotal',
  BOTH: 'both',
}

const convertToHourMinSecondsStr = milliseconds => {
  const duration = moment.duration(milliseconds)
  const seconds = duration.seconds()
  const minutes = duration.minutes()
  const hours = duration.hours()
  let durationStr = ''
  if (hours > 0) {
    durationStr += `${hours}hr`
  }
  if (minutes > 0) {
    durationStr += `${durationStr.length > 0 ? ' ' : ''}${minutes}min`
  }
  if (seconds >= 0) {
    durationStr += `${durationStr.length > 0 ? ' ' : ''}${seconds}sec`
  }
  return durationStr
}

function getCellFormatter(cellFormat) {
  return function formalCell(text, node) {
    if (!text) {
      return text
    }
    if (!node) {
      return text
    }
    if (cellFormat === CELL_FORMATS.COUNT) {
      return text
    }
    if (cellFormat === CELL_FORMATS.PERCENTAGE) {
      return `${Math.round((Number(text) / Number(node.total)) * 100)}%`
    }
    if (cellFormat === CELL_FORMATS.COUNT_BY_TOTAL) {
      return `${Number(text)} / ${Number(node.total)}`
    }
    return `${text} - ${Math.round((Number(text) / Number(node.total)) * 100)}%`
  }
}

export { CELL_FORMATS, convertToHourMinSecondsStr, getCellFormatter }
