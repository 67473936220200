/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useMutation } from 'react-apollo'
import { COLORS, PLUS_BUTTON } from 'assets/styles/globalStyles'
import { SEARCHABLE_DROPDOWN_OPTIONS } from 'utilities'
import { Form, Input, Button, Select, Modal, notification } from 'antd'
import { CREATE_BEHAVIOR } from '../query'
import { useTargetAlloc } from '../context'

const { TextArea } = Input

const BehaviorFieldCard = ({ form, label, name, decoratorName, edit, initialValue, required }) => {
  const { getFieldDecorator } = form

  const [addBehaviorModal, setAddBehaviorModal] = useState(false)
  const [newBehName, setNewBehName] = useState('')
  const [newBehDefinition, setNewBehDefinition] = useState('')
  const [createNewBehavior, { loading: newBehLoadingg }] = useMutation(CREATE_BEHAVIOR)
  const { behaveData, selectedStudent, behaveLoading, refetchBehave } = useTargetAlloc()

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <Form.Item label={label} name={name}>
          {getFieldDecorator(decoratorName, {
            initialValue: edit
              ? initialValue
              : behaveData?.getBehaviour?.edges.length > 0
              ? behaveData?.getBehaviour?.edges[0]?.id
              : null,
            rules: [{ required, message: 'Please select a behavior' }],
          })(
            <Select name={name} {...SEARCHABLE_DROPDOWN_OPTIONS} loading={behaveLoading}>
              {behaveData?.getBehaviour?.edges.map(({ node }) => (
                <Select.Option key={node.id} value={node.id}>
                  {node.behaviorName}
                </Select.Option>
              ))}
            </Select>,
          )}
          <Button onClick={() => setAddBehaviorModal(true)} style={PLUS_BUTTON}>
            <PlusOutlined />
          </Button>
        </Form.Item>
      </div>

      {/* add new behavior */}
      <Modal
        visible={addBehaviorModal}
        title="Add New Behavior"
        onCancel={() => setAddBehaviorModal(false)}
        zIndex={1001}
        width={800}
        footer={[
          <Button key="behavior-cancel" onClick={() => setAddBehaviorModal(false)}>
            Cancel
          </Button>,
          <Button
            key="behavior-submit"
            type="primary"
            style={{ backgroundColor: COLORS.submitBlue }}
            loading={newBehLoadingg}
            onClick={e => {
              let validationMessage = ''
              if (!selectedStudent?.id) {
                validationMessage = 'Student Id not found'
              } else if (!newBehName) {
                validationMessage = 'Please provide behavior name'
              }

              if (validationMessage) {
                notification.success({
                  message: validationMessage,
                })
              } else {
                createNewBehavior({
                  variables: {
                    name: newBehName,
                    student: selectedStudent?.id,
                    definition: newBehDefinition,
                  },
                })
                  .then(res => {
                    refetchBehave()
                    setAddBehaviorModal(false)
                    notification.success({
                      message: 'New behavior created successfully',
                    })
                  })
                  .catch(err => console.error(err))
              }
            }}
          >
            Create
          </Button>,
        ]}
      >
        <div style={{ fontSize: 14 }}>
          <Form.Item
            label={
              <>
                <span style={{ color: 'red', fontSize: 14, marginRight: 4 }}>*</span>
                <span>Behaviour Name</span>
              </>
            }
            name="Behaviour Name"
          >
            <Input
              value={newBehName}
              onChange={e => setNewBehName(e.target.value)}
              placeholder="Type the new prompt name"
              autoFocus
              required
            />
          </Form.Item>

          <Form.Item label="Behaviour Definition" name="Behaviour Definition">
            <TextArea
              onChange={e => setNewBehDefinition(e.target.value)}
              autoSize={{ minRows: 6, maxRows: 6 }}
              placeholder="Give behaviour definition"
            />
          </Form.Item>
        </div>
      </Modal>
    </div>
  )
}

export default BehaviorFieldCard
