/* eslint-disable import/prefer-default-export */
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import { GET_PROMPTS } from './query'

const Context = React.createContext()

export function useMasterySeq() {
  return useContext(Context)
}

export function MasterySeqProvider({ children }) {
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [phaseSet, setPhaseSet] = useState([])
  const { data: promptCodes, loading: promptLoading, error: promptError } = useQuery(GET_PROMPTS)

  const PROGRESS_TO = [
    'Baseline',
    'Intervention',
    'In-Maintenance',
    'Generalization',
    'Mastered',
    'Next Item in Hierarchy',
    'Previous Item in Hierarchy',
    'Do not progress',
    'Remove from all sessions',
  ]

  const statusMap = [
    { id: 'U3RhdHVzVHlwZTox', status: 'Baseline' },
    { id: 'U3RhdHVzVHlwZToz', status: 'Intervention' },
    { id: 'U3RhdHVzVHlwZToxMA==', status: 'Generalization' },
    { id: 'U3RhdHVzVHlwZTo1', status: 'In-Maintenance' },
    { id: 'U3RhdHVzVHlwZTo0', status: 'Mastered' },
  ]

  useEffect(() => {
    if (promptError) {
      console.error(promptError)
    }
  }, [promptError])

  const value = {
    promptCodes,
    promptLoading,
    phaseSet,
    setPhaseSet,
    statusMap,
    selectedTemplate,
    setSelectedTemplate,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}
