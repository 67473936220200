/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
import { Col, Switch, Form, notification, Row, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SEARCHABLE_DROPDOWN_OPTIONS } from 'utilities'
import LoadingComponent from 'components/LoadingComponent'
import { useMutation, useQuery, useLazyQuery } from 'react-apollo'
import { PeakLabels, NormalTargetLabels } from 'constants/RecordingLabels'
import MasterySequence from './MasterySequence'
import { useTargetAlloc } from './context'
import AntdTag from './antdTag'
import {
  GET_ALLOCATED_TARGET,
  CREATE_TARGET,
  RECORDING_TYPE,
  GET_SBT,
  GET_SBT_STEP_STATUS,
  GET_SBT_STEP_REINFORCERS,
} from './query'
import '../style.scss'
import StudentCard from './FormCards/studentCard'
import PhaseCard from './FormCards/phaseCard'
import AttachmentsCard from './FormCards/attachmentsCard'
import MiscInfoCard from './FormCards/miscInfoCard'
import SubmitButtonCard from './FormCards/submitButtonCard'
import NumberCard from './FormCards/numberCard'
import SdStepCard from './FormCards/sdStepCard'
import DynamicSdStep from './FormCards/dynamicSdStep'
import ClassCard from './FormCards/classCard'
import StgCard from './FormCards/stgCard'
import PromptFieldCard from './FormCards/promptFieldCard'
import BehaviorFieldCard from './FormCards/behaviorFieldCard'
import LabelBlock from './LabelBlock'

const { Option } = Select

const dateFormat = 'YYYY-MM-DD'

const boxCard = { padding: '24px 32px', backgroundColor: 'white', margin: '12px 0' }

function capitalizeFirstLetter(string) {
  if (string) {
    string = string?.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return ''
}

export default Form.create()(({ form, id, closeDrawer, onSuccessTargetAllocation }) => {
  const {
    selectedStudent,
    targetStatusLoading,
    targetOptions,
    targetOptionsLoading,
    defaultShortTermGoalIdForABA,
    mastSeqTemplate,
    manualMastActive,
    setManualMastActive,
    mstSeqObj,
    setMstSeqObj,
    fetchMasteryData,
    profileMstLoading,
    setSelectedTab,
    selectedTab,
    setUseDefaultGoal,
    useDefaultGoal,
  } = useTargetAlloc()

  const { getFieldValue, getFieldDecorator } = form

  const [dailyTrials, setDailyTrials] = useState(0)
  const [peakBlockCount, setPeakBlockCount] = useState(1)
  const [sessionConsecutiveDays, setSessionConsecutiveDays] = useState(0)
  const [trialDuration, setTrialDuration] = useState(30)
  const [tagArray, setTagArray] = useState([])
  const [targetInstructions, setTargetInstructions] = useState()

  const [activeBehave, setActiveBehave] = useState(null)

  const [reinforceMapping, setReinforceMapping] = useState({})

  const [phaseList, setPhaseList] = useState([])
  const [stimulusList, setStimulusList] = useState([])
  const [stepList, setStepList] = useState([])
  const [activeCount, setActiveCount] = useState(0)
  const [inSequence, setInSequence] = useState(true)
  const [sbtStepsList, setSbtStepsList] = useState([])

  const [targetObject, setTargetObject] = useState(null)

  const [manualInitialPrompts, setManualInitialPrompts] = useState([])
  const [classObjects, setClassObjects] = useState([])

  const [sbtStepObjects, setSbtStepObjects] = useState([])

  const [classKey, setClassKey] = useState(0)

  const [targetAtt, setTargetAtt] = useState([])
  const [isCoded, setIsCoded] = useState(false)
  const [recordingLabels, setRecordingLabels] = useState([])
  const [isEditLabels, setIsEditLabels] = useState(false)
  const [isLabelExist, setIsLabelExist] = useState(false)

  const [ft, { data, error, loading }] = useLazyQuery(GET_ALLOCATED_TARGET, {
    fetchPolicy: 'network-only',
  })

  const [allocateTarget, { loading: allocateTargetLoading }] = useMutation(CREATE_TARGET)

  const {
    data: recordingTypeData,
    error: recordingTypeError,
    loading: recordingTypeLoading,
  } = useQuery(RECORDING_TYPE)

  const { data: sbtData } = useQuery(GET_SBT)
  const { data: sbtStatusData, loading: sbtStatusLoading, error: sbtStatusError } = useQuery(
    GET_SBT_STEP_STATUS,
  )
  const {
    data: sbtReinforcersData,
    loading: sbtReinforcersLoading,
    error: sbtReinforcersError,
  } = useQuery(GET_SBT_STEP_REINFORCERS)

  const fieldValue = getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4'

  useEffect(() => {
    if (fieldValue && isEditLabels) {
      setRecordingLabels([...PeakLabels])
    } else if (isEditLabels && activeBehave !== 'recording' && activeBehave !== 'SBT') {
      setRecordingLabels([...NormalTargetLabels])
    } else if (!isLabelExist) {
      setRecordingLabels([])
    }
  }, [fieldValue, isEditLabels])

  useEffect(() => {
    if (id) {
      ft({
        variables: { id },
      })
    }
  }, [id])

  useEffect(() => {
    if (data) {
      const obj = data.targetAllocate
      const recordingObj = obj.recordingType?.edges.map(({ node }) => node.id)
      const masterySeqObj = obj.criteriaTemplate
      setTargetObject({
        default: obj.shortTerm?.isDefault,
        domainName: obj.domainName,
        eqCode: obj.eqCode,
        classes: obj.classes,
        id: obj.id,
        isManual: obj.isManual,
        objective: obj.objective,
        peakBlocks: obj.peakBlocks,
        masteryCriteria: masterySeqObj?.isTargetSpecific ? null : masterySeqObj?.id,
        mastery: obj.mastery,
        targetInstr: obj.targetInstr,
        peakType: obj.peakType,
        targetStatus: obj.targetStatus,
        video: obj.videos.edges[0]?.node.url || null,
        targetDocs: obj.targetDocs,
        shortTerm: obj.shortTerm,
        r1: obj.r1,
        r2: obj.r2,
        behavior: obj.recordingBehavior?.id,
        recordingType: recordingObj,
        domain: obj.targetId?.domain,
        manualAllocateDomain: obj.manualAllocateDomain,
        targetName: obj.targetAllcatedDetails?.targetName,
        DailyTrials: obj.targetAllcatedDetails?.DailyTrials,
        consecutiveDays: obj.targetAllcatedDetails?.consecutiveDays,
        promptCodes: obj.targetAllcatedDetails?.promptCodes,
        targetType: obj.targetAllcatedDetails?.targetType,
        sendEmail: obj.sendEmail,
        reactiveProcedure: obj.reactiveProcedure,
        behaviourDescription: obj.behaviourDescription,
        antecedentManipulation: obj.antecedentManipulation,
        tag: obj.tag,
      })
      setTagArray(obj.tag.edges.map(tg => tg.node.name))

      setUseDefaultGoal(obj.shortTerm?.isDefault)
      setTargetInstructions(obj.targetInstr)
      setDailyTrials(obj.targetAllcatedDetails?.DailyTrials)
      setSessionConsecutiveDays(obj.targetAllcatedDetails?.consecutiveDays)
      setPeakBlockCount(obj.peakBlocks)
      setTrialDuration(obj.targetAllcatedDetails?.trialDuration)

      if (masterySeqObj) {
        setManualMastActive(masterySeqObj.isTargetSpecific)
        const tempPhaseSet = masterySeqObj.criteriaphasesSet.edges.map(item => {
          const tempMast = item.node.criteriaphasetargetsSet.edges.map(mst => {
            return { ...mst.node, key: Math.random() }
          })
          return {
            id: item.node.id,
            key: Math.random(),
            autoLabel: item.node.autoLabel,
            criteriaType: item.node.criteriaType.id,
            criteriaTypeName: item.node.criteriaType.statusName,
            stepNo: item.node.stepNo,
            prompt: item.node.prompts.edges.map(pr => pr.node.id),
            masterySet: tempMast,
          }
        })
        setMstSeqObj({
          id: masterySeqObj.id,
          isTargetSpecific: masterySeqObj.isTargetSpecific,
          description: masterySeqObj.description,
          phaseSet: tempPhaseSet,
        })
      }

      let tempSdObj = []
      let tempStepObj = []
      const tempClass = []

      obj.mastery?.edges.forEach(({ node }, idx) => {
        if (node.criteriaTemplate) {
          const mmt = node.criteriaTemplate?.criteriaphasesSet.edges.map(item => {
            const tempMast = item.node.criteriaphasetargetsSet.edges.map(mst => {
              return { ...mst.node, key: Math.random() }
            })
            return {
              id: item.node.id,
              key: Math.random(),
              autoLabel: item.node.autoLabel,
              criteriaType: item.node.criteriaType.id,
              criteriaTypeName: item.node.criteriaType.statusName,
              stepNo: item.node.stepNo,
              prompt: item.node.prompts.edges.map(pr => pr.node.id),
              masterySet: tempMast,
            }
          })

          if (node.sd) {
            tempSdObj.push({
              name: node.sd.sd,
              id: node.id,
              key: idx,
              status: node.status?.id,
              prompt: node.prompts?.edges.map(item => item.node.id),
              masteryCriteria: node.criteriaTemplate?.isSdSpecific
                ? null
                : node.criteriaTemplate.id,
              manualMastery: {
                id: node.criteriaTemplate.id,
                isTargetSpecific: node.criteriaTemplate.isSdSpecific,
                description: node.criteriaTemplate.description,
                phaseSet: mmt,
              },
            })
          } else if (
            node.step &&
            obj.targetAllcatedDetails?.targetType?.id === 'VGFyZ2V0RGV0YWlsVHlwZTo1'
          ) {
            tempStepObj.push({
              name: node.step.step,
              id: node.id,
              key: idx,
              status: node.status?.id,
              prompt: node.prompts?.edges.map(item => item.node.id),
              behPrompt: node.behPrompts?.edges.map(item => item.node.id),
              masteryCriteria: node.criteriaTemplate.isStepSpecific
                ? null
                : node.criteriaTemplate.id,
              manualMastery: {
                id: node.criteriaTemplate.id,
                isTargetSpecific: node.criteriaTemplate.isSdSpecific,
                description: node.criteriaTemplate.description,
                phaseSet: mmt,
              },
            })
          }
        }
      })

      obj.classes?.edges.forEach(({ node }, idx) => {
        let tt = {}
        node.stimuluses.edges.forEach((it, index) => {
          tt = {
            ...tt,
            [`stimulus${idx}${it.node.option}`]: it.node.stimulusName,
          }
        })
        tempClass.push({
          id: node.id,
          k: idx,
          name: node.name,
          ...tt,
        })
      })

      const tempPrompt = obj.targetAllcatedDetails?.promptCodes?.edges.map(item => item.node)

      const tarTempDocs = []
      const sdTempDocs = []
      const stepTempDocs = []

      obj.targetDocs?.edges.forEach(item => {
        if (item.node.sd === null && item.node.step === null) {
          tarTempDocs.push({
            type: 'target',
            key: item.node.id,
            url: item.node.url,
            id: item.node.id,
          })
        }
      })

      tempSdObj = tempSdObj.map(stimulus => {
        const tt = []
        obj.targetDocs?.edges.forEach(item => {
          if (item.node.sd && item.node.sd.sd === stimulus.name) {
            tt.push({ type: 'sd', key: item.node.id, url: item.node.url, id: item.node.id })
          }
        })

        sdTempDocs.push(tt)
        return { ...stimulus, attachments: tt }
      })

      tempStepObj = tempStepObj.map(step => {
        const tt = []
        obj.targetDocs?.edges.forEach(item => {
          if (item.node.step && item.node.step.step === step.name) {
            tt.push({ type: 'step', key: item.node.id, url: item.node.url, id: item.node.id })
          }
        })
        stepTempDocs.push(tt)
        return {
          ...step,
          attachments: tt,
        }
      })

      const tempPhase = []
      obj.phaset?.edges.forEach(({ node }) => {
        tempPhase.push({ key: node.id, date: moment(node.date), note: node.content })
      })

      if (obj.targetAllcatedDetails?.targetType?.id === 'VGFyZ2V0RGV0YWlsVHlwZToxMA==') {
        setActiveBehave('SBT')
        const tempSbtSteps = []
        obj.sbtSteps?.edges.forEach(({ node }, index) => {
          const tempMast = [
            {
              days: node.mastery?.consecutiveDays,
              minTrial: node.mastery?.minTrial,
              id: node.mastery?.id,
              gte: node.mastery?.gte,
              lte: node.mastery?.lte,
              key: Math.random(),
              masteryType: capitalizeFirstLetter(node.mastery?.masteryType),
              noOfProblemBehavior: node.mastery?.noOfProblemBehavior,
              resKey: node.mastery?.lte === 100 ? 1 : node.mastery?.gte === 0 ? 0 : null,
              responsePercentage: node.mastery?.responsePercentage,
              fromStatus: node.mastery?.fromStatus?.id,
              toStatus: node.mastery?.toStatus?.id,
            },
          ]
          tempSbtSteps.push({
            id: node.id,
            name: node.description,
            orderNo: node.orderNo,
            key: index + 1,
            status: node.status?.id || 'U0JUU3RlcFN0YXR1c1R5cGU6MQ==',
            mastery: null,
            manualMastery: tempMast,
            prompt: node.reinforce ? node.reinforce.edges.map(item => item.node.id) : [],
          })
        })
        setSbtStepObjects(tempSbtSteps)
        setSbtStepsList(tempSbtSteps)
      } else if (obj.targetAllcatedDetails?.targetType?.id === 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==') {
        setActiveBehave('recording')
        setSelectedTab('Behavior Recording Duration')
        if (
          recordingObj &&
          recordingObj.length === 1 &&
          recordingObj[0] === 'UmVjb3JkaW5nVHlwZVR5cGU6MQ=='
        ) {
          setSelectedTab('Behavior Recording Frequency')
        }
      } else {
        setActiveBehave('Regular')
        setSelectedTab('Regular')
      }

      if (obj.targetAllcatedDetails?.targetType.id === 'VGFyZ2V0RGV0YWlsVHlwZTo1') {
        setActiveCount(
          obj.activeSdStepCount > tempStepObj.length ? tempStepObj.length : obj.activeSdStepCount,
        )
      } else {
        setActiveCount(
          obj.activeSdStepCount > tempSdObj.length ? tempSdObj.length : obj.activeSdStepCount,
        )
      }
      setInSequence(!obj.isSdStepRandomize)
      setClassObjects(tempClass)
      setStimulusList(tempSdObj)
      setStepList(tempStepObj)
      setPhaseList(tempPhase)
      setClassKey(tempClass.length)
      setManualInitialPrompts(tempPrompt || [])
      setTargetAtt(tarTempDocs)

      if (obj?.recordingLabels?.edges?.length > 0) {
        const labels = obj.recordingLabels.edges?.map(({ node }) => {
          return {
            label: node.label,
            value: node.value,
            newLabel: node.newLabel,
          }
        })
        if (labels) setIsLabelExist(true)
        setRecordingLabels(labels)
      }
    }
  }, [data, error])

  useEffect(() => {
    const vl = getFieldValue('type')
    if (vl) {
      if (vl === 'VGFyZ2V0RGV0YWlsVHlwZToxMA==') {
        if (sbtStepObjects.length === 0) {
          const tempSbt = []
          sbtData?.getSbtDefaultSteps.forEach((item, index) => {
            if (item.isActive) {
              tempSbt.push({
                id: item.id,
                description: item.description,
                k: index + 1,
                status: item.status || {
                  id: 'U0JUU3RlcFN0YXR1c1R5cGU6MQ==',
                  statusName: 'Intervention',
                },
                mastery: item.mastery,
                reinforce: item.reinforce ? item.reinforce.edges.map(item => item.node.name) : [],
              })
            }
          })
          setSbtStepObjects(tempSbt)
        }

        setActiveBehave('SBT')
      } else if (vl === 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==') {
        if (activeBehave === 'Regular' || activeBehave === 'SBT') {
          setSbtStepObjects([])
        }
        setActiveBehave('recording')
      } else if (activeBehave === 'SBT' || activeBehave === 'recording') {
        setSbtStepObjects([])
        setActiveBehave('Regular')
      } else {
        setActiveBehave('Regular')
      }
    }
  }, [getFieldValue('type'), sbtData])

  useEffect(() => {
    const vl = getFieldValue('type')
    const rType = getFieldValue('recordingType')
    if (vl === 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==') {
      if (rType && rType.length === 1 && rType[0] === 'UmVjb3JkaW5nVHlwZVR5cGU6MQ==') {
        setSelectedTab('Behavior Recording Frequency')
      } else {
        setSelectedTab('Behavior Recording Duration')
      }
      form.setFieldsValue({ masteryCriteriaBehavior: null })
    }
  }, [getFieldValue('recordingType'), targetOptions, sbtData])

  useEffect(() => {
    if (sbtReinforcersData && sbtReinforcersData.getSbtReinforces) {
      const tt = sbtReinforcersData.getSbtReinforces.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: curr.name,
        }),
        {},
      )
      setReinforceMapping(tt)
    }
  }, [sbtReinforcersData])

  const fetchMastery = (mstId, manualMstActive) => {
    if (mstId && !manualMstActive) {
      fetchMasteryData(mstId)
    }
  }

  useEffect(() => {
    if (recordingTypeError) {
      console.error(recordingTypeError, 'error')
    }
  }, [recordingTypeError])

  const onChangeNumber = (type, num) => {
    if (type === 'sdt') setDailyTrials(num)
    else if (type === 'scd') setSessionConsecutiveDays(num)
    else if (type === 'spbc') {
      setPeakBlockCount(num)
    } else if (type === 'trdc') {
      setTrialDuration(num)
    }
  }

  const stepSdResponse = (type, docs) => {
    const tempS = type === 'sd' ? stimulusList : stepList
    const msg = ''
    const resp = tempS.map(st => {
      let manualCriteriaTemplate = null
      if (st.manualMastery.isTargetSpecific) {
        manualCriteriaTemplate = {
          description: st.manualMastery.description,
          templateType: selectedTab,
          criteriaPhases: st.manualMastery.phaseSet.map((item, idx) => {
            return {
              criteriaType: item.criteriaType,
              prompts: item.prompt,
              autoLabel: item.autoLabel,
              stepNo: idx + 1,
              criteriaPhaseTargets: item.masterySet.map(mp => ({
                calculationType: mp.calculationType,
                percentage: mp.percentage,
                calFrameType: mp.calFrameType,
                durationCalType: mp.durationCalType,
                calFrameDurationType: mp.calFrameDurationType,
                calFrameDurationValue: mp.calFrameDurationValue,
                minimumTrial: mp.minimumTrial,
                progressTo: mp.progressTo,
                phaseChangeLabel: mp.phaseChangeLabel,
                duration: mp.duration,
                frequency: mp.frequency,
              })),
            }
          }),
        }
      }

      st.attachments.forEach(dc => {
        docs.push({
          sd: type === 'sd' ? st.name : '',
          step: type === 'step' ? st.name : '',
          url: dc.url,
        })
      })
      if (st.stimulusUrl) {
        docs.push({
          sd: type === 'sd' ? st.name : '',
          step: type === 'step' ? st.name : '',
          url: st.stimulusUrl,
        })
      }

      return {
        [type]: st.name,
        mastery: null,
        status: st.status,
        prompts: st.prompt,
        manualMastery: [],
        criteriaTemplate: st.manualMastery.isTargetSpecific ? null : st.masteryCriteria,
        manualCriteriaTemplate,
      }
    })

    if (msg) {
      return { status: false, msg, data: resp, docs }
    }
    return { status: true, msg: '', data: resp, docs }
  }

  const handleSubmit = e => {
    const selectedAlpha = ['A', 'B']

    e.preventDefault()
    form.validateFieldsAndScroll((error, values) => {
      let validationMessage = null

      const classes = []
      classObjects.forEach(({ k: key }, index) => {
        const sampleStimulus = []
        selectedAlpha.forEach((alpha, alphaIndex) => {
          sampleStimulus.push({
            option: alpha,
            stimulusName: values[`stimulus${key}${alpha}`],
          })
        })
        classes.push({
          name: `Class ${index + 1}`,
          stimuluses: sampleStimulus,
        })
      })

      let targetType = ''
      if (values.type === 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==') {
        targetType = 'behavior'
      } else if (values.type === 'VGFyZ2V0RGV0YWlsVHlwZToxMA==') {
        targetType = 'sbt'
      } else if (values.type === 'VGFyZ2V0RGV0YWlsVHlwZTo4') {
        targetType = 'peak'
      } else if (values.type === 'VGFyZ2V0RGV0YWlsVHlwZTo1') {
        targetType = 'task'
      } else {
        targetType = 'regular'
      }

      let docsResponse = []

      let stimulusResponse =
        targetType === 'behavior' ||
        targetType === 'sbt' ||
        targetType === 'task' ||
        values.category === 'Equivalence'
          ? { status: false, msg: '', data: [], docs: [] }
          : stepSdResponse('sd', docsResponse)

      validationMessage = stimulusResponse.msg
      docsResponse = stimulusResponse.docs
      stimulusResponse = stimulusResponse.data

      let stepResponse =
        targetType === 'task'
          ? stepSdResponse('step', docsResponse)
          : { status: false, msg: '', data: [], docs: [] }

      if (!validationMessage) {
        validationMessage = stepResponse.msg
      }
      docsResponse = stepResponse.docs
      stepResponse = stepResponse.data

      targetAtt?.forEach(item => {
        docsResponse.push({
          sd: '',
          step: '',
          url: item.url,
        })
      })
      if (values.tarUrl) {
        docsResponse.push({
          sd: '',
          step: '',
          url: values.tarUrl,
        })
      }

      const phaseResponse = phaseList.map(item => ({
        date: item.date?.format(dateFormat),
        content: item.note,
      }))

      const sbtStepResponse = sbtStepsList.map((item, index) => {
        return {
          id: item.id,
          orderNo: index + 1,
          description: item.name,
          statusId: item.status,
          reinforce: item.prompt.map(rf => ({ name: reinforceMapping[rf] })),
          mastery: item.manualMastery[0]
            ? {
                gte: item.manualMastery[0].gte,
                lte: item.manualMastery[0].lte,
                isDaily: item.manualMastery[0].masteryType === 'Daily',
                masteryType: item.manualMastery[0].masteryType,
                consecutiveDays: item.manualMastery[0].days || 0,
                noOfProblemBehavior: item.manualMastery[0].noOfProblemBehavior || 0,
                minTrial: item.manualMastery[0].minTrial || 0,
                fromStatus: item.manualMastery[0].fromStatus,
                toStatus: item.manualMastery[0].toStatus,
              }
            : {},
        }
      })

      let manualCriteriaTemplate = null
      if (manualMastActive) {
        manualCriteriaTemplate = {
          description: mstSeqObj.description,
          templateType: selectedTab,
          criteriaPhases: mstSeqObj.phaseSet.map((item, idx) => {
            return {
              criteriaType: item.criteriaType,
              prompts: item.prompt,
              autoLabel: item.autoLabel,
              stepNo: idx + 1,
              criteriaPhaseTargets: item.masterySet.map(mp => ({
                calculationType: mp.calculationType,
                percentage: mp.percentage,
                calFrameType: mp.calFrameType,
                durationCalType: mp.durationCalType,
                calFrameDurationType: mp.calFrameDurationType,
                calFrameDurationValue: mp.calFrameDurationValue,
                minimumTrial: mp.minimumTrial,
                progressTo: mp.progressTo,
                phaseChangeLabel: mp.phaseChangeLabel,
                duration: mp.duration,
                frequency: mp.frequency,
              })),
            }
          }),
        }
      }

      if (!error) {
        // Other custom validations
        if (!useDefaultGoal && !values.stg) {
          validationMessage = 'No short term Goal found.'
        } else if (useDefaultGoal && !defaultShortTermGoalIdForABA) {
          validationMessage = 'No default short term goal found.'
        } else if (!selectedStudent) {
          validationMessage = 'Student not found, Please try again.'
        } else if (!targetObject?.id) {
          validationMessage = 'Target not found.'
        } else if (peakBlockCount < 0) {
          validationMessage = 'Peak block count should be greater than 0'
        } else if (dailyTrials < 0) {
          validationMessage = 'Daily trials should be greater than or equal to 0'
        } else if (!values.masteryCriteria && !manualCriteriaTemplate && targetType !== 'sbt') {
          validationMessage = 'Please select mastery criteria or provide manual mastery condition'
        } else if (
          targetType === 'peak' &&
          values.category === 'Equivalence' &&
          classes.length === 0
        ) {
          validationMessage = 'At least one class is required for type PEAK with Relational.'
        } else if (
          targetType === 'peak' &&
          stimulusResponse.length === 0 &&
          (values.category === 'Generalization' || values.category === 'Direct')
        ) {
          validationMessage =
            'At least one Stimulus required for type PEAK with Generalization/Direct.'
        } else if (targetType === 'task' && stepResponse.length === 0) {
          validationMessage = 'At least one step is required with type Task Analysis.'
        } else if (values.type === 'sbt' && sbtStepResponse.length === 0) {
          validationMessage = 'At least one step is required for type Skill Based Treatment.'
        }

        if (validationMessage) {
          notification.info({
            message: validationMessage,
          })
        } else if (targetObject.id) {
          allocateTarget({
            variables: {
              studentId: selectedStudent?.id,
              shortTerm: useDefaultGoal ? defaultShortTermGoalIdForABA : values.stg,
              targetStatus: values.status,
              date: moment(values.validFrom).format('YYYY-MM-DD'),
              targetName: values.name,
              consecutiveDays: sessionConsecutiveDays,
              dailyTrials,
              trialDuration: targetType === 'behavior' || targetType === 'sbt' ? trialDuration : 0,
              domain: values.domain,
              targetType: values.type,
              tag: tagArray || [],
              sd: stimulusResponse,
              steps: stepResponse,
              peakBlocks: targetType === 'peak' ? peakBlockCount : 0,
              peakType:
                targetType === 'peak' && values.category !== 'Equivalence'
                  ? 'Direct'
                  : values.category
                  ? 'Equivalence'
                  : values.category,
              equiCode:
                targetType === 'peak' && values.category === 'Equivalence' ? values.equiCode : null,
              classes: targetType === 'peak' && values.category === 'Equivalence' ? classes : [],
              recordingBehavior: targetType === 'behavior' ? values.behavior : null,
              recordingType: targetType === 'behavior' ? values.recordingType : [],
              r1: targetType === 'sbt' ? values.r1 : null,
              r2: targetType === 'sbt' ? values.r2 : null,
              sbtSteps: targetType === 'sbt' ? sbtStepResponse : [],
              targetDocs: docsResponse,
              prompts: values.prompt,
              phaset: phaseResponse,
              video: values.video ? [values.video] : [],
              targetInstr:
                targetType !== 'behavior' && targetInstructions ? targetInstructions : '',
              behaviourDescription: targetType === 'behavior' ? values.behaviourDescription : '',
              reactiveProcedure: targetType === 'behavior' ? values.reactiveProcedure : '',
              antecedentManipulation:
                targetType === 'behavior' ? values.antecedentManipulation : '',
              emailMasterStatus: values.emailMasterStatus,
              plotZero: values.plotZero,
              criteriaTemplate:
                targetType === 'sbt' || manualMastActive ? null : values.masteryCriteria,
              manualCriteriaTemplate: targetType !== 'sbt' ? manualCriteriaTemplate : null,
              activeSdStepCount: activeCount,
              sessionAllocation: values.sessions,
              isSdStepRandomize: !inSequence,
              recordingLabels,
            },
          })
            .then(res => {
              notification.success({
                message: 'Target created/copied successfully',
              })
              form.resetFields()
              if (onSuccessTargetAllocation) {
                onSuccessTargetAllocation(res)
              }
              if (closeDrawer) {
                closeDrawer()
              }
            })
            .catch(er => console.error(er))
        } else {
          notification.info({
            message: 'Target id not found',
          })
        }
      }
    })
  }

  const handleTargetLable = labelObj => {
    const tempLabels = [...recordingLabels]
    const objIndex = recordingLabels?.findIndex(item => item.value === labelObj.value)
    tempLabels[objIndex] = labelObj
    setRecordingLabels(tempLabels)
  }

  if (loading || sbtStatusLoading || sbtReinforcersLoading) {
    return (
      <div style={{ height: '100vh' }}>
        <LoadingComponent />
      </div>
    )
  }

  if (targetObject === null || error || sbtReinforcersError || sbtStatusError) {
    return <h1>Oops, Something went wrong</h1>
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <StudentCard copy form={form} selectedStudent={selectedStudent} />

        {/* short goals and target name */}
        <div style={boxCard}>
          <StgCard
            form={form}
            setIsCoded={setIsCoded}
            edit
            targetObject={targetObject}
            activeBehave={activeBehave}
          />

          {/* status and category type */}
          {getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4' ? (
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    initialValue: targetObject?.targetStatus ? targetObject.targetStatus.id : null,
                    rules: [{ required: true, message: 'Please select a target status' }],
                  })(
                    <Select
                      loading={targetOptionsLoading || targetStatusLoading}
                      {...SEARCHABLE_DROPDOWN_OPTIONS}
                    >
                      {targetOptions?.targetStatus.map(({ id, statusName }) => {
                        return (
                          <Select.Option key={id} value={id}>
                            {statusName}
                          </Select.Option>
                        )
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>

              {targetObject.peakType === 'EQUIVALENCE' ? (
                <Col span={8}>
                  <Form.Item label="Category">
                    {getFieldDecorator('category', {
                      initialValue: targetObject && capitalizeFirstLetter(targetObject.peakType),
                      rules: [{ required: true, message: 'Please select a category' }],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Select a category"
                        {...SEARCHABLE_DROPDOWN_OPTIONS}
                        disabled
                      >
                        <Option key="1" value="Direct">
                          Direct
                        </Option>
                        <Option key="2" value="Generalization">
                          Generalization
                        </Option>
                        <Option key="3" value="Transformation">
                          Transformation
                        </Option>
                        <Option key="4" value="Equivalence">
                          Relational
                        </Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              ) : null}

              <Col span={8}>
                <Form.Item label="Tag" name="Tag">
                  <AntdTag
                    changeTagsHandler={e => setTagArray(e)}
                    closeable="true"
                    tagArray={tagArray}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : activeBehave === 'recording' ? (
            <>
              <Row gutter={[24]}>
                <Col span={8}>
                  <Form.Item label="Status">
                    {getFieldDecorator('status', {
                      initialValue: targetObject?.targetStatus
                        ? targetObject.targetStatus.id
                        : null,
                      rules: [{ required: true, message: 'Please select a target status' }],
                    })(
                      <Select
                        loading={targetOptionsLoading || targetStatusLoading}
                        {...SEARCHABLE_DROPDOWN_OPTIONS}
                      >
                        {targetOptions?.targetStatus.map(({ id, statusName }) => {
                          return (
                            <Select.Option key={id} value={id}>
                              {statusName}
                            </Select.Option>
                          )
                        })}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Recording Type" name="Recording Type">
                    {getFieldDecorator('recordingType', {
                      initialValue: targetObject && targetObject.recordingType,
                      rules: [{ required: true, message: 'Please select a recording type' }],
                    })(
                      <Select
                        mode="multiple"
                        name="domain"
                        onChange={() =>
                          form.setFieldsValue({
                            masteryCriteriaBehavior: null,
                          })
                        }
                        {...SEARCHABLE_DROPDOWN_OPTIONS}
                        loading={recordingTypeLoading}
                      >
                        {recordingTypeData?.recordingType?.map(node => (
                          <Select.Option key={node.id} value={node.id}>
                            {node.name}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Tag" name="Tag">
                    <AntdTag
                      changeTagsHandler={e => setTagArray(e)}
                      closeable="true"
                      tagArray={tagArray}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <BehaviorFieldCard
                form={form}
                edit
                initialValue={targetObject?.behavior}
                required
                name="Behavior"
                label="Behavior"
                decoratorName="behavior"
              />
            </>
          ) : (
            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    initialValue: targetObject?.targetStatus ? targetObject.targetStatus.id : null,
                    rules: [{ required: true, message: 'Please select a target status' }],
                  })(
                    <Select
                      loading={targetOptionsLoading || targetStatusLoading}
                      {...SEARCHABLE_DROPDOWN_OPTIONS}
                    >
                      {targetOptions?.targetStatus.map(({ id, statusName }) => {
                        return (
                          <Select.Option key={id} value={id}>
                            {statusName}
                          </Select.Option>
                        )
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tag" name="Tag">
                  <AntdTag
                    changeTagsHandler={e => setTagArray(e)}
                    closeable="true"
                    tagArray={tagArray}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* Behaviour r1 and r2 sbt */}
          {activeBehave === 'SBT' && (
            <Row gutter={[24]}>
              <Col span={12}>
                <BehaviorFieldCard
                  form={form}
                  edit
                  initialValue={targetObject?.r1?.id}
                  required
                  name="Behavior R1"
                  label="Behavior R1"
                  decoratorName="r1"
                />
              </Col>
              <Col span={12}>
                <BehaviorFieldCard
                  form={form}
                  edit
                  initialValue={targetObject?.r2?.id}
                  required
                  name="Behavior R2"
                  label="Behavior R2"
                  decoratorName="r2"
                />
              </Col>
            </Row>
          )}

          {/* Number fields for normal peak and sbt */}
          {getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4' ? (
            <Row gutter={[24]}>
              <Col span={isCoded ? 8 : 12}>
                <NumberCard
                  required
                  label="Peak Blocks"
                  number={peakBlockCount}
                  maxValue={100}
                  setNumber={num => onChangeNumber('spbc', num)}
                />
              </Col>

              {!isCoded && (
                <Col span={8}>
                  <NumberCard
                    label="Daily Trials"
                    required
                    number={dailyTrials}
                    form={form}
                    setNumber={num => onChangeNumber('sdt', num)}
                  />
                </Col>
              )}

              <Col span={isCoded ? 8 : 12}>
                <NumberCard
                  label="Consecutive Days"
                  number={sessionConsecutiveDays}
                  form={form}
                  setNumber={num => onChangeNumber('scd', num)}
                />
              </Col>
            </Row>
          ) : activeBehave === 'SBT' || activeBehave === 'recording' ? (
            <Row gutter={[24]}>
              <Col span={8}>
                <NumberCard
                  required
                  label="Trial Duration"
                  number={trialDuration}
                  form={form}
                  maxValue={300}
                  setNumber={num => onChangeNumber('trdc', num)}
                  addonAfter="sec"
                />
              </Col>
              <Col span={8}>
                <NumberCard
                  required
                  label="Daily Trials"
                  number={dailyTrials}
                  form={form}
                  setNumber={num => onChangeNumber('sdt', num)}
                />
              </Col>
              <Col span={8}>
                <NumberCard
                  label="Consecutive Days"
                  number={sessionConsecutiveDays}
                  form={form}
                  setNumber={num => onChangeNumber('scd', num)}
                />
              </Col>
            </Row>
          ) : (
            <Row gutter={[24]}>
              <Col span={12}>
                <NumberCard
                  required
                  label="Daily Trials"
                  number={dailyTrials}
                  form={form}
                  setNumber={num => onChangeNumber('sdt', num)}
                />
              </Col>

              <Col span={12}>
                <NumberCard
                  label="Consecutive Days"
                  number={sessionConsecutiveDays}
                  form={form}
                  setNumber={num => onChangeNumber('scd', num)}
                />
              </Col>
            </Row>
          )}

          {activeBehave !== 'recording' && (
            <PromptFieldCard form={form} initialValue={manualInitialPrompts} edit />
          )}
        </div>

        {activeBehave !== 'SBT' ? (
          <div style={boxCard}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginBottom: 12,
              }}
            >
              <Form.Item
                label="Mastery Criteria"
                style={{ marginBottom: 0, width: '30%' }}
                name="masteryCriteria"
              >
                {getFieldDecorator('masteryCriteria', {
                  initialValue: targetObject?.masteryCriteria,
                  rules: [
                    { required: !manualMastActive, message: 'Please select a Mastery Criteria' },
                  ],
                })(
                  <Select
                    disabled={manualMastActive}
                    name="masteryCriteria"
                    onSelect={e => fetchMastery(e, manualMastActive)}
                    loading={targetOptionsLoading}
                    {...SEARCHABLE_DROPDOWN_OPTIONS}
                  >
                    {mastSeqTemplate.map(({ id, name }) => {
                      return (
                        <Select.Option key={id} value={id}>
                          {name}
                        </Select.Option>
                      )
                    })}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item>
                <Switch
                  checkedChildren="Manual Mastery"
                  unCheckedChildren="Default Mastery"
                  checked={manualMastActive}
                  onChange={e => {
                    if (e) {
                      form.resetFields('masteryCriteria')
                    } else if (mastSeqTemplate[0]?.id) {
                      form.setFieldsValue({ masteryCriteria: mastSeqTemplate[0]?.id })
                      fetchMastery(mastSeqTemplate[0].id, e)
                    }
                    setManualMastActive(e)
                  }}
                />
              </Form.Item>
            </div>

            {profileMstLoading ? (
              <LoadingComponent />
            ) : (
              <MasterySequence
                mstSeqObj={mstSeqObj}
                setMstSeqObj={setMstSeqObj}
                manualMastActive={manualMastActive}
              />
            )}
          </div>
        ) : null}

        <PhaseCard phaseList={phaseList} setPhaseList={setPhaseList} />

        {/* Peak stimlus steps */}
        {getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4' ? (
          getFieldValue('category') === 'Equivalence' ||
          getFieldValue('category') === 'EQUIVALENCE' ? (
            // classes
            <ClassCard
              form={form}
              setClassObjects={setClassObjects}
              classObjects={classObjects}
              setClassKey={setClassKey}
              classKey={classKey}
            />
          ) : (
            // stimulus for direct/gen/trans
            <DynamicSdStep
              stimulusList={stimulusList}
              form={form}
              setStimulusList={setStimulusList}
              stepList={stepList}
              sd
              activeCount={activeCount}
              setActiveCount={setActiveCount}
              inSequence={inSequence}
              setInSequence={setInSequence}
            />
          )
        ) : // Task Analysis
        getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo1' ? (
          // stimulus and steps both
          <DynamicSdStep
            form={form}
            setStepList={setStepList}
            stepList={stepList}
            step
            activeCount={activeCount}
            setActiveCount={setActiveCount}
            inSequence={inSequence}
            setInSequence={setInSequence}
          />
        ) : activeBehave === 'SBT' ? (
          // sbt steps for sbt
          <SdStepCard
            form={form}
            sbtStepsList={sbtStepsList}
            setSbtStepsList={setSbtStepsList}
            sbtReinforcersData={sbtReinforcersData}
            sbtStatusData={sbtStatusData}
            sbtStep
          />
        ) : activeBehave === 'recording' ? null : (
          <DynamicSdStep
            stimulusList={stimulusList}
            form={form}
            setStepList={setStepList}
            setStimulusList={setStimulusList}
            stepList={stepList}
            sd
            activeCount={activeCount}
            setActiveCount={setActiveCount}
            inSequence={inSequence}
            setInSequence={setInSequence}
          />
        )}

        {/* Documents */}
        <AttachmentsCard form={form} targetAtt={targetAtt} setTargetAtt={setTargetAtt} />

        {/* Misc information */}
        <MiscInfoCard
          form={form}
          targetVideo={targetObject?.targetVideo}
          activeBehave={activeBehave}
        />

        {/* handle lable block */}
        {!isLabelExist && activeBehave !== 'recording' && activeBehave !== 'SBT' && (
          <Switch
            checkedChildren="Recording labels"
            unCheckedChildren="Recording labels"
            onChange={() => setIsEditLabels(!isEditLabels)}
          />
        )}
        {/* Target recording labels */}
        {recordingLabels.length &&
        (getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo4' ||
          getFieldValue('type') === 'VGFyZ2V0RGV0YWlsVHlwZTo1' ||
          (activeBehave !== 'recording' && activeBehave !== 'SBT')) ? (
          <LabelBlock form={form} editObj={recordingLabels} handleTargetLable={handleTargetLable} />
        ) : null}

        <SubmitButtonCard
          form={form}
          closeDrawer={closeDrawer}
          submitLoading={allocateTargetLoading}
        />
      </Form>
    </div>
  )
})
