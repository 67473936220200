import React from 'react'
import { BackTop, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
// import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
// import Footer from 'components/LayoutComponents/Footer'
// import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'
import Settings from 'components/LayoutComponents/Settings'

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
  isMenuVisible: settings.isMenuVisible,
})

const MainLayout = ({
  children,
  isBorderless,
  isSquaredBorders,
  isFixedWidth,
  isMenuShadow,
  isMenuTop,
  isMenuVisible,
}) => {
  return (
    <Layout
      className={classNames({
        settings__borderLess: isBorderless,
        settings__squaredBorders: isSquaredBorders,
        settings__fixedWidth: isFixedWidth,
        settings__menuShadow: isMenuShadow,
        settings__menuTop: isMenuTop,
      })}
    >
      {/* <BackTop /> */}
      {isMenuVisible && <Menu />}

      {/* <Settings /> */}
      <Layout>
        {/* <Layout.Header>
          <TopBar />
        </Layout.Header> */}
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          {/* <Breadcrumbs /> */}
          <div className="" style={{ padding: 0, margin: 0, maxWidth: '100%', height: '100%' }}>
            {children}
          </div>
        </Layout.Content>
        {/* <Layout.Footer>
          <Footer />
        </Layout.Footer> */}
      </Layout>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
