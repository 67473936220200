/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-assign */

import React, { Component, useEffect } from 'react'
import { Modal, Button, Icon, notification, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Coded } from 'constants/RecordingLabels'
import DirectScoreBoard from './DirectScoreBoard'

const { confirm } = Modal

const PeakDirect = () => {
  const dispatch = useDispatch()
  const {
    TargetResponse,
    TargetActiveId,
    TargetActiveIndex,
    MasterSession,
    PeakBlockIndex,
    PeakTrialCount,
    PeakAutomatic,
    EditAfterSessionCompleted,
    SelectedPeakStimulusIndex,
    ResponseLoading,
  } = useSelector(state => state.sessionrecording)

  useEffect(() => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        CurrentPeakBlocks: TargetResponse[TargetActiveId].peak,
      },
    })

    // Setting latest selected trail button color
    const getButton0 = document.getElementById('peakResponseButtonZero')
    const getButton2 = document.getElementById('peakResponseButtonTwo')
    const getButton4 = document.getElementById('peakResponseButtonFour')
    const getButton8 = document.getElementById('peakResponseButtonEight')
    const getButton10 = document.getElementById('peakResponseButtonTen')

    if (
      TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block &&
      TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]?.response
        ?.marks === 0
    ) {
      if (typeof getButton0 != 'undefined' && getButton0 != null) {
        document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
      }
    } else {
      if (typeof getButton0 != 'undefined' && getButton0 != null) {
        document.getElementById('peakResponseButtonZero').style.backgroundColor = '#e4e9f0'
      }
    }

    if (
      TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 1]?.response
        ?.marks === 2
    ) {
      if (typeof getButton2 != 'undefined' && getButton2 != null) {
        document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
      }
    } else {
      if (typeof getButton2 != 'undefined' && getButton2 != null) {
        document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#e4e9f0'
      }
    }
    if (
      TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 1]?.response
        ?.marks === 4
    ) {
      if (typeof getButton4 != 'undefined' && getButton4 != null) {
        document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
      }
    } else {
      if (typeof getButton4 != 'undefined' && getButton4 != null) {
        document.getElementById('peakResponseButtonFour').style.backgroundColor = '#e4e9f0'
      }
    }

    if (
      TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 1]?.response
        ?.marks === 8
    ) {
      if (typeof getButton8 != 'undefined' && getButton8 != null) {
        document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
      }
    } else {
      if (typeof getButton8 != 'undefined' && getButton8 != null) {
        document.getElementById('peakResponseButtonEight').style.backgroundColor = '#e4e9f0'
      }
    }
    if (
      TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 1]?.response
        ?.marks === 10
    ) {
      if (typeof getButton10 != 'undefined' && getButton10 != null) {
        document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
      }
    } else {
      if (typeof getButton10 != 'undefined' && getButton10 != null) {
        document.getElementById('peakResponseButtonTen').style.backgroundColor = '#e4e9f0'
      }
    }
  }, [TargetActiveId, PeakTrialCount, TargetResponse])

  const getLabel = label => {
    let temp = label
    const filtered = MasterSession.targets.edges[
      TargetActiveIndex
    ].node.recordingLabels?.edges.filter(
      ({ node }) => node.label.toLowerCase() === label.toLowerCase(),
    )
    if (filtered.length) {
      temp = filtered[0].node.newLabel.length ? filtered[0].node.newLabel : filtered[0].node.label
    }
    return temp
  }

  const updateSessionClockTime = () => {
    // updatig current clock time to store
    document.getElementById('updateSessionCurrentTimeInStore').click()
  }

  const updateStartTrialClockTime = () => {
    // updatig trial start time to store
    document.getElementById('updateStartTrialTimeInStore').click()
  }

  const resetZero = () => {
    document.getElementById('peakResponseButtonZero').style.color = 'gray'
    document.getElementById('peakResponseButtonZero').style.backgroundColor = '#e4e9f0'
  }

  const resetTwo = () => {
    document.getElementById('peakResponseButtonTwo').style.color = 'gray'
    document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#e4e9f0'
  }

  const resetFour = () => {
    document.getElementById('peakResponseButtonFour').style.color = 'gray'
    document.getElementById('peakResponseButtonFour').style.backgroundColor = '#e4e9f0'
  }

  const resetEight = () => {
    document.getElementById('peakResponseButtonEight').style.color = 'gray'
    document.getElementById('peakResponseButtonEight').style.backgroundColor = '#e4e9f0'
  }

  const resetTen = () => {
    document.getElementById('peakResponseButtonTen').style.color = 'gray'
    document.getElementById('peakResponseButtonTen').style.backgroundColor = '#e4e9f0'
  }

  const peakSelectedStimulusIndexReset = count => {
    let sdList = null
    let ind = 0
    if (MasterSession.targets.edges[TargetActiveIndex].node.sd.edges.length > 0)
      sdList = MasterSession.targets.edges[TargetActiveIndex].node.sd.edges
    if (sdList.length > 0) {
      sdList.map((item, index) => {
        if (
          TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[count - 1].response?.sd?.id ===
          item.node.id
        )
          ind = index
      })
    }

    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        SelectedPeakStimulusIndex: ind,
      },
    })
  }

  const goToNextTrial = () => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()

    if (PeakTrialCount < 10) {
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 1]
          .recordedData === true
      ) {
        updateStartTrialClockTime()
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            PeakTrialCount: PeakTrialCount + 1,
          },
        })

        if (
          TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount].response
            ?.marks === 0
        ) {
          document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
        }
        if (
          TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount].response
            ?.marks === 2
        ) {
          document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount].response
            ?.marks === 4
        ) {
          document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount].response
            ?.marks === 8
        ) {
          document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
        }
        if (
          TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount].response
            ?.marks === 10
        ) {
          document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
        }

        if (!PeakAutomatic) {
          peakSelectedStimulusIndexReset(PeakTrialCount + 1)
        }
      } else {
        notification.warning({
          message: 'Warning!!',
          description: 'Record ' + PeakTrialCount + ' Trial first',
        })
      }
    }
  }

  const goToPreviousTrial = () => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    updateStartTrialClockTime()
    if (PeakTrialCount > 1) {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          PeakTrialCount: PeakTrialCount - 1,
        },
      })

      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 2].response
          .marks === 0
      ) {
        document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'
      }
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 2].response
          .marks === 2
      ) {
        document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 2].response
          .marks === 4
      ) {
        document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 2].response
          .marks === 8
      ) {
        document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
      }
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 2].response
          .marks === 10
      ) {
        document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
      }
      if (!PeakAutomatic) {
        peakSelectedStimulusIndexReset(PeakTrialCount - 1)
      }
    }
  }

  const goToPreviousBlock = () => {
    if (PeakBlockIndex > 0) {
      updateSessionClockTime()
      dispatch({
        type: 'sessionrecording/UPDATE_BLOCK_ENDTIME',
        payload: {
          blockIndex: PeakBlockIndex,
          targetId: TargetActiveId,
        },
      })

      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          PeakBlockIndex: PeakBlockIndex - 1,
        },
      })

      dispatch({
        type: 'sessionrecording/CREATE_NEW_BLOCK',
        payload: {
          blockIndex: PeakBlockIndex - 1,
          targetId: TargetActiveId,
        },
      })
    }
  }

  const goToNextBlock = () => {
    if (PeakBlockIndex < MasterSession.targets.edges[TargetActiveIndex].node.peakBlocks - 1) {
      updateSessionClockTime()
      dispatch({
        type: 'sessionrecording/UPDATE_BLOCK_ENDTIME',
        payload: {
          blockIndex: PeakBlockIndex,
          targetId: TargetActiveId,
        },
      })

      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          PeakBlockIndex: PeakBlockIndex + 1,
        },
      })

      dispatch({
        type: 'sessionrecording/CREATE_NEW_BLOCK',
        payload: {
          blockIndex: PeakBlockIndex + 1,
          targetId: TargetActiveId,
        },
      })
    }
  }

  const responseZero = prompt => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    // document.getElementById('peakResponseButtonZero').style.color = '#FF8080'
    document.getElementById('peakResponseButtonZero').style.backgroundColor = '#FF8080'

    const activeStimulusId =
      MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[SelectedPeakStimulusIndex]?.node
        .id
    let status = ''
    const mastryNode = MasterSession.targets.edges[TargetActiveIndex].node.mastery.edges
    if (mastryNode.length > 0) {
      mastryNode.map(
        masteryItem =>
          masteryItem.node.sd.id === activeStimulusId && (status = masteryItem.node.status?.id),
      )
    }
    updateSessionClockTime()
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]?.recordedData
      ) {
        if (PeakAutomatic) {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 0,
              sd:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.sd.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        } else {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 0,
              sd:
                MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                  SelectedPeakStimulusIndex
                ]?.node.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        }
      } else {
        dispatch({
          type: 'sessionrecording/RECORD_BLOCK_TRIAL',
          payload: {
            marks: 0,
            sd:
              MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                SelectedPeakStimulusIndex
              ]?.node.id,
            promptId: prompt,
            sdStatusId: status,
          },
        })
      }
    }
  }

  const responseTwo = prompt => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    // document.getElementById('peakResponseButtonTwo').style.color = '#FF9C52'
    document.getElementById('peakResponseButtonTwo').style.backgroundColor = '#FF9C52'
    const activeStimulusId =
      MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[SelectedPeakStimulusIndex]?.node
        .id
    let status = ''
    const mastryNode = MasterSession.targets.edges[TargetActiveIndex].node.mastery.edges
    if (mastryNode.length > 0) {
      mastryNode.map(
        masteryItem =>
          masteryItem.node.sd.id === activeStimulusId && (status = masteryItem.node.status?.id),
      )
    }
    updateSessionClockTime()
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]?.recordedData
      ) {
        if (PeakAutomatic) {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 2,
              sd:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.sd.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        } else {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 2,
              sd:
                MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                  SelectedPeakStimulusIndex
                ]?.node.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        }
      } else {
        dispatch({
          type: 'sessionrecording/RECORD_BLOCK_TRIAL',
          payload: {
            marks: 2,
            sd:
              MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                SelectedPeakStimulusIndex
              ]?.node.id,
            promptId: prompt,
            sdStatusId: status,
          },
        })
      }
    }
  }

  const responseFour = prompt => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    // document.getElementById('peakResponseButtonFour').style.color = '#FF9C52'
    document.getElementById('peakResponseButtonFour').style.backgroundColor = '#FF9C52'
    const activeStimulusId =
      MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[SelectedPeakStimulusIndex]?.node
        .id
    let status = ''
    const mastryNode = MasterSession.targets.edges[TargetActiveIndex].node.mastery.edges
    if (mastryNode.length > 0) {
      mastryNode.map(
        masteryItem =>
          masteryItem.node.sd.id === activeStimulusId && (status = masteryItem.node.status?.id),
      )
    }
    updateSessionClockTime()
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]?.recordedData
      ) {
        if (PeakAutomatic) {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 4,
              sd:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.sd.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        } else {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 4,
              sd:
                MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                  SelectedPeakStimulusIndex
                ]?.node.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        }
      } else {
        dispatch({
          type: 'sessionrecording/RECORD_BLOCK_TRIAL',
          payload: {
            marks: 4,
            sd:
              MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                SelectedPeakStimulusIndex
              ]?.node.id,
            promptId: prompt,
            sdStatusId: status,
          },
        })
      }
    }
  }

  const responseEight = prompt => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    // document.getElementById('peakResponseButtonEight').style.color = '#FF9C52'
    document.getElementById('peakResponseButtonEight').style.backgroundColor = '#FF9C52'
    const activeStimulusId =
      MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[SelectedPeakStimulusIndex]?.node
        .id
    let status = ''
    const mastryNode = MasterSession.targets.edges[TargetActiveIndex].node.mastery.edges
    if (mastryNode.length > 0) {
      mastryNode.map(
        masteryItem =>
          masteryItem.node.sd.id === activeStimulusId && (status = masteryItem.node.status?.id),
      )
    }
    updateSessionClockTime()
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]?.recordedData
      ) {
        if (PeakAutomatic) {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 8,
              sd:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.sd.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        } else {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 8,
              sd:
                MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                  SelectedPeakStimulusIndex
                ]?.node.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        }
      } else {
        dispatch({
          type: 'sessionrecording/RECORD_BLOCK_TRIAL',
          payload: {
            marks: 8,
            sd:
              MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                SelectedPeakStimulusIndex
              ]?.node.id,
            promptId: prompt,
            sdStatusId: status,
          },
        })
      }
    }
  }

  const responseTen = prompt => {
    resetZero()
    resetTwo()
    resetFour()
    resetEight()
    resetTen()
    // document.getElementById('peakResponseButtonTen').style.color = '#4BAEA0'
    document.getElementById('peakResponseButtonTen').style.backgroundColor = '#4BAEA0'
    const activeStimulusId =
      MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[SelectedPeakStimulusIndex]?.node
        .id
    let status = ''
    const mastryNode = MasterSession.targets.edges[TargetActiveIndex].node.mastery.edges
    if (mastryNode.length > 0) {
      mastryNode.map(
        masteryItem =>
          masteryItem.node.sd.id === activeStimulusId && (status = masteryItem.node.status?.id),
      )
    }
    updateSessionClockTime()
    if (EditAfterSessionCompleted) {
      if (
        TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]?.recordedData
      ) {
        if (PeakAutomatic) {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 10,
              sd:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.sd.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        } else {
          dispatch({
            type: 'sessionrecording/UPDATE_BLOCK_TRIAL',
            payload: {
              marks: 10,
              sd:
                MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                  SelectedPeakStimulusIndex
                ]?.node.id,
              promptId: prompt,
              responseId:
                TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
                  .response.id,
            },
          })
        }
      } else {
        dispatch({
          type: 'sessionrecording/RECORD_BLOCK_TRIAL',
          payload: {
            marks: 10,
            sd:
              MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[
                SelectedPeakStimulusIndex
              ]?.node.id,
            promptId: prompt,
            sdStatusId: status,
          },
        })
      }
    }
  }

  const changePeakStimulus = (operation, listLen) => {
    if (operation === 'plus') {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SelectedPeakStimulusIndex:
            SelectedPeakStimulusIndex + 2 > listLen ? 0 : SelectedPeakStimulusIndex + 1,
        },
      })
    }
    if (operation === 'minus') {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SelectedPeakStimulusIndex:
            SelectedPeakStimulusIndex - 1 < 0 ? listLen - 1 : SelectedPeakStimulusIndex - 1,
        },
      })
    }
  }

  const resetBlock = blockIndex => {
    confirm({
      title: ' Are you sure you want to delete all records for current Block??',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch({
          type: 'sessionrecording/RESET_BLOCK',
          payload: {
            targetId: TargetActiveId,
            blockIndex,
          },
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const resetTrial = () => {
    if (
      TargetResponse[TargetActiveId].peak[PeakBlockIndex].block[PeakTrialCount - 1]
        .marksRecorded === true
    ) {
      confirm({
        title: ' Are you sure you want to delete the current Trial??',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          dispatch({
            type: 'sessionrecording/RESET_TRIAL',
            payload: {
              targetId: TargetActiveId,
              trialIndex: PeakTrialCount - 1,
              type: 'Coded',
            },
          })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    }
  }

  // const copylist = TargetResponse[TargetActiveId].peak
  let item = null
  if (TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block.length > 0) {
    item = TargetResponse[TargetActiveId].peak[PeakBlockIndex]?.block[PeakTrialCount - 1]
  }

  let sdList = null
  if (MasterSession.targets.edges[TargetActiveIndex].node.sd.edges.length > 0)
    sdList = MasterSession.targets.edges[TargetActiveIndex].node.sd.edges

  // console.log('Item Object =======>', item)
  const recordingButtonStyle = {
    padding: '8px auto',
    width: '52px',
    height: '52px',
    backgroundColor: '#EDEDED',
    border: '1px solid #E1E1E1',
    boxSizing: 'border-box',
    borderRadius: '3px',
    marginTop: '10px',
    marginLeft: '5px',
    marginRight: '5px',
  }

  return (
    <>
      <div className="peak-block-div">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {PeakAutomatic ? (
            <div>
              <p style={{ marginBottom: 0 }}>Stimulus: {item?.sd?.sd}</p>
            </div>
          ) : (
            <>
              <span>
                <Button
                  style={{ border: 'none' }}
                  onClick={() => changePeakStimulus('minus', sdList?.length)}
                >
                  <Icon type="left" />
                </Button>
                Stimulus {SelectedPeakStimulusIndex + 1} / {sdList?.length}
                <Button
                  style={{ border: 'none' }}
                  onClick={() => changePeakStimulus('plus', sdList?.length)}
                >
                  <Icon type="right" />
                </Button>
              </span>

              <div className="title">
                <p style={{ marginBottom: 0, paddingTop: 8 }}>
                  Stimulus: {sdList && sdList[SelectedPeakStimulusIndex]?.node.sd}
                </p>
              </div>
            </>
          )}

          <div style={{ fontSize: 12 }}>
            {PeakBlockIndex === 0 ? (
              <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                <Icon type="left" />
              </Button>
            ) : (
              <Button style={{ border: 'none' }} onClick={goToPreviousBlock}>
                <Icon type="left" />
              </Button>
            )}
            Block {PeakBlockIndex + 1} /{' '}
            {MasterSession.targets.edges[TargetActiveIndex].node.peakBlocks}
            {PeakBlockIndex ===
            MasterSession.targets.edges[TargetActiveIndex].node.peakBlocks - 1 ? (
              <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                <Icon type="right" />
              </Button>
            ) : (
              <Button style={{ border: 'none' }} onClick={goToNextBlock}>
                <Icon type="right" />
              </Button>
            )}
            <Button
              style={{ border: 'none', background: 'transparent', flex: 1 }}
              onClick={() => resetBlock(PeakBlockIndex)}
            >
              <Icon type="delete" />
            </Button>
          </div>
        </div>
      </div>

      <div className="peak-block-div">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <DirectScoreBoard key={TargetResponse} boxWidth="6px" />
          </div>
          <div style={{ fontSize: 12 }}>
            {PeakTrialCount <= 1 ? (
              <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                <Icon type="left" />
              </Button>
            ) : (
              <Button style={{ border: 'none' }} onClick={goToPreviousTrial}>
                <Icon type="left" />
              </Button>
            )}
            Trial {PeakTrialCount} / 10
            {PeakTrialCount >= 10 ? (
              <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                <Icon type="right" />
              </Button>
            ) : (
              <Button style={{ border: 'none' }} onClick={goToNextTrial}>
                <Icon type="right" />
              </Button>
            )}
            <Button
              style={{ border: 'none', background: 'transparent', flex: 1 }}
              onClick={() => resetTrial()}
            >
              <Icon type="delete" />
            </Button>
          </div>
        </div>
        {ResponseLoading ? (
          <Spin>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <Button style={recordingButtonStyle}>{getLabel(Coded.ZERO.label)}</Button>
              <Button style={recordingButtonStyle}>{getLabel(Coded.TWO.label)}</Button>
              <Button style={recordingButtonStyle}>{getLabel(Coded.FOUR.label)}</Button>
              <Button style={recordingButtonStyle}>{getLabel(Coded.EIGHT.label)}</Button>
              <Button style={recordingButtonStyle}>{getLabel(Coded.TEN.label)}</Button>
            </div>
          </Spin>
        ) : (
          <>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <Button
                id="peakResponseButtonZero"
                style={recordingButtonStyle}
                onClick={() => responseZero()}
              >
                {getLabel(Coded.ZERO.label)}
              </Button>
              <Button
                id="peakResponseButtonTwo"
                style={recordingButtonStyle}
                onClick={() => responseTwo()}
              >
                {getLabel(Coded.TWO.label)}
              </Button>
              <Button
                id="peakResponseButtonFour"
                style={recordingButtonStyle}
                onClick={() => responseFour()}
              >
                {getLabel(Coded.FOUR.label)}
              </Button>
              <Button
                id="peakResponseButtonEight"
                style={recordingButtonStyle}
                onClick={() => responseEight()}
              >
                {getLabel(Coded.EIGHT.label)}
              </Button>
              <Button
                id="peakResponseButtonTen"
                style={recordingButtonStyle}
                onClick={() => responseTen()}
              >
                {getLabel(Coded.TEN.label)}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default PeakDirect
