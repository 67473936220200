/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-boolean-value */
import { Button, Col, DatePicker, Dropdown, Menu, notification, Row, Table, Tooltip } from 'antd'
import { gql } from 'apollo-boost'
import { COLORS } from 'assets/styles/globalStyles'
import * as FileSaver from 'file-saver'
import lodash from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import { FaDownload } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import useTimer from '../../components/Timer/timer'
import WorkTime from '../therapistAttendance/attendance'
import './form.scss'
import './table.scss'
import './behavior.scss'
import DropSection from './DropSection'

const { RangePicker } = DatePicker

const filterCardStyle = {
  backgroundColor: COLORS.palleteLight,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
}
const parentDiv = { display: 'flex', margin: '5px 40px 5px 0' }
const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }

const ATTENDANCE = gql`
  query($dateGte: Date!, $dateLte: Date!, $clinic: ID!) {
    attendanceReportByClinic(dateGte: $dateGte, dateLte: $dateLte, clinic: $clinic) {
      staff {
        id
        name
      }
      data {
        date
        hours
      }
    }
  }
`
const therapistDashboard = gql`
  query therapistDashboard {
    lastStatus(first: 1) {
      edges {
        node {
          checkIn
          checkOut
          status
        }
      }
    }
    attendence: attendances(first: 1) {
      edges {
        node {
          id
          checkIn
          checkOut
          checkInLatitude
          checkInLongitude
          status
        }
      }
    }
    timesheet: timesheets(first: 1) {
      edges {
        node {
          id
          start
          end
          title
          note
          location {
            id
            location
          }
          status {
            id
            statusName
          }
        }
      }
    }
  }
`

const therapistAttendanceReport = gql`
  query($dateGte: Date!, $dateLte: Date!, $therapist: ID!) {
    attendanceReport(dateGte: $dateGte, dateLte: $dateLte, therapist: $therapist) {
      staff {
        id
        name
      }
      date
      hours
    }
  }
`

const getDatesBetween = (startDate, endDate) => {
  const now = startDate.clone()
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'))
    now.add(1, 'days')
  }
  return dates
}

export default props => {
  const { selectedStaffId } = props

  const [date, setDate] = useState([moment().subtract(7, 'days'), moment()])
  const [columns, setColumns] = useState()
  const [filteredTableData, setFilteredTableData] = useState()
  const [staffData, setStaffData] = useState(null)
  const [getAttendance, { data: attendance, loading: attLoading, error: attError }] = useLazyQuery(
    ATTENDANCE,
  )
  const staff = useSelector(state => state.user.staffObject)

  const [
    getstaffAttendanceReport,
    {
      data: staffAttendanceReportData,
      loading: staffAttendanceReportDataLoading,
      error: staffAttendanceDataReportError,
    },
  ] = useLazyQuery(therapistAttendanceReport, {
    fetchPolicy: 'network-only',
  })

  const clinicId = localStorage.getItem('userId')
  const role = localStorage.getItem('role')

  useEffect(() => {
    if (clinicId && role === '"school_admin"') {
      getAttendance({
        variables: {
          dateGte: date[0].format('YYYY-MM-DD'),
          dateLte: date[1].format('YYYY-MM-DD'),
          clinic: clinicId,
        },
      })
    }
  }, [date, clinicId])

  useEffect(() => {
    console.log('some selected ID', selectedStaffId)
    if (staff !== null) {
      if (role === '"therapist"') {
        getstaffAttendanceReport({
          variables: {
            dateGte: date[0].format('YYYY-MM-DD'),
            dateLte: date[1].format('YYYY-MM-DD'),
            therapist: selectedStaffId.id,
          },
        })
      }
    }
  }, [staff, date, selectedStaffId])

  useEffect(() => {
    if (staffAttendanceReportData) {
      const finalData = []
      const att = []
      const attobj = { data: null, staff: null }
      if (staffAttendanceReportData?.attendanceReport.length > 0) {
        staffAttendanceReportData?.attendanceReport.map(item => {
          att.push({
            date: item.date,
            hours: item.hours,
          })
        })
        attobj.data = att
        attobj.staff = staffAttendanceReportData?.attendanceReport[0].staff
        finalData.push(attobj)
        setStaffData(finalData)
      }
    }
  }, [staffAttendanceReportData, date, staff])

  useEffect(() => {
    if (attendance) {
      formatTableData(attendance)
    }
  }, [staff, date])

  useEffect(() => {
    if (staffAttendanceReportData) {
      const finalData = []
      const att = []
      const attobj = { data: null, staff: null }
      if (staffAttendanceReportData?.attendanceReport.length > 0) {
        staffAttendanceReportData?.attendanceReport.map(item => {
          att.push({
            date: item.date,
            hours: item.hours,
          })
        })
        attobj.data = att
        attobj.staff = staffAttendanceReportData?.attendanceReport[0].staff
        finalData.push(attobj)
        setStaffData(finalData)
      }
    }
  }, [staffAttendanceReportData, date, staff])

  useEffect(() => {
    if (attendance) {
      formatTableData(attendance)
    }
  }, [attendance, selectedStaffId.id])

  useEffect(() => {
    if (staffAttendanceReportData) {
      staffFormatTableData(staffData)
    }
  }, [staffAttendanceReportData, staffData])

  const staffFormatTableData = data => {
    if (data?.length > 0) {
      const dates = getDatesBetween(date[0], date[1])
      const tableData = []
      data.map((report, index) => {
        const rowData = {}
        rowData.key = index
        rowData.name = report.staff.name
        dates.forEach(date => {
          rowData[report.date] = report.hours
          if (report.data.length > 0) {
            report.data.map(item => {
              if (item.date === date) rowData[item.date] = item.hours
            })
          }
          if (!(date in rowData)) rowData[date] = 0
        })
        tableData.push(rowData)
      })

      moment.updateLocale(moment.locale(), { invalidDate: 'field' })
      const finalGroup = tableData.map(item => {
        const groupedData = lodash.groupBy(
          Object.entries(
            lodash.groupBy(Object.entries(item), item => moment(item[0]).format('MMM')),
          ),
          item => moment(item[1][0][0]).format('YYYY'),
        )
        return groupedData
      })

      setFilteredTableData(tableData)
      filterColumns(finalGroup)
    }
  }

  const formatTableData = ({ attendanceReportByClinic }) => {
    if (attendanceReportByClinic.length > 0) {
      const dates = getDatesBetween(date[0], date[1])
      const tableData = []
      attendanceReportByClinic.map((report, index) => {
        if (report.staff.id === selectedStaffId.id) {
          const rowData = {}
          rowData.key = index
          rowData.name = report.staff.name
          dates.forEach(date => {
            if (report.data.length > 0) {
              report.data.map(item => {
                if (item.date === date) rowData[item.date] = item.hours
              })
            }
            if (!(date in rowData)) rowData[date] = 0
          })
          tableData.push(rowData)
        }
      })

      moment.updateLocale(moment.locale(), { invalidDate: 'field' })
      const finalGroup = tableData.map(item => {
        const groupedData = lodash.groupBy(
          Object.entries(
            lodash.groupBy(Object.entries(item), item => moment(item[0]).format('MMM')),
          ),
          item => moment(item[1][0][0]).format('YYYY'),
        )
        return groupedData
      })
      setFilteredTableData(
        lodash.uniqBy(tableData, item => {
          const { key, ...rest } = item
          return JSON.stringify(rest)
        }),
      )

      if (Object.keys(Object.assign({}, finalGroup)).length) {
        filterColumns(finalGroup)
      }
    }
  }

  const filterColumns = groupedData => {
    const columns = [
      {
        title: 'Name',
        width: 180,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
      },
    ]
    console.log(groupedData)
    const aa = Object.assign({}, groupedData)
    console.log(aa)
    const a = []
    const entries = Object.entries(aa[0])
    entries.pop()
    console.log(entries)
    entries.map(items => {
      items[1].map(item => {
        item[1].map(i => {
          a.push(i)
        })
      })
    })
    const columnLen = a.length

    a.forEach((date, index) => {
      columns.push({
        title: moment(date[0]).format('DD MMM'),
        key: index,
        dataIndex: date[0],
        width: 80,
        align: 'center',
        children: [
          {
            key: index,
            title: moment(date[0]).format('ddd'),
            dataIndex: date[0],
            align: 'center',
            width: columnLen > 5 ? 80 : '',
          },
        ],
      })
    })
    setColumns(columns)
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const exportToCSV = () => {
    const filename = `Attendance_report_${date}`
    const excel = [...filteredTableData]
    delete excel?.key
    delete excel?.undefined
    const ws = XLSX.utils.json_to_sheet(excel)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelData, filename + fileExtension)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => exportToCSV()} type="link" size="small">
          CSV/Excel
        </Button>
      </Menu.Item>
    </Menu>
  )

  const DateChangeDropDown = date => {
    setDate(date)
    // setSDate(
    //   moment(date[0])
    //     .format('YYYY-MM-DD')
    //     .toString(),
    // )
    // setEDate(
    //   moment(date[1])
    //     .format('YYYY-MM-DD')
    //     .toString(),
    // )
  }

  return (
    <>
      {role === '"school_admin"' ? (
        <Row>
          <Col sm={24}>
            <Row>
              <Col span={26}>
                <div style={filterCardStyle}>
                  <div style={parentDiv}>
                    <DropSection dateChange={v => DateChangeDropDown(v)} />
                  </div>
                  <div style={parentDiv}>
                    <Tooltip title="Selecrt Date range">
                      <RangePicker
                        style={{
                          marginLeft: 'auto',
                          width: 250,
                        }}
                        size="default"
                        value={date}
                        onChange={val => DateChangeDropDown(val)}
                      />
                    </Tooltip>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Dropdown overlay={menu} trigger={['hover']}>
                      <Button type="link" size="large">
                        <FaDownload />{' '}
                      </Button>
                    </Dropdown>
                  </div>
                </div>
                <div style={{ margin: '10px 0 10px 10px' }}>
                  <Table
                    className="allReportTable"
                    columns={columns}
                    dataSource={filteredTableData}
                    bordered
                    loading={attLoading}
                    scroll={{ x: 'fit-content' }}
                    size="middle"
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '30', '50'],
                      position: 'top',
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <WorkTime />
      )}
      {role !== '"school_admin"' ? (
        <Row>
          <Col sm={24}>
            <Row>
              <Col span={26}>
                <div style={filterCardStyle}>
                  <div style={parentDiv}>
                    <DropSection dateChange={v => DateChangeDropDown(v)} />
                  </div>
                  <div style={parentDiv}>
                    <span style={parentLabel}>Date :</span>
                    <RangePicker
                      style={{
                        marginLeft: 'auto',
                        width: 250,
                      }}
                      size="default"
                      value={date}
                      onChange={val => DateChangeDropDown(val)}
                    />
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Dropdown overlay={menu} trigger={['hover']}>
                      <Button type="link" size="large">
                        <FaDownload />{' '}
                      </Button>
                    </Dropdown>
                  </div>
                </div>
                <div style={{ margin: '10px 0 10px 10px' }}>
                  <Table
                    columns={columns}
                    dataSource={filteredTableData}
                    bordered
                    loading={attLoading}
                    scroll={{ x: 'fit-content' }}
                    size="middle"
                    className="allReportTable"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </>
  )
}
