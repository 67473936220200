/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { connect, useSelector } from 'react-redux'
import { TargetTypes, PeakTypes } from 'constants/targetConstants'
import PeakDirect from './PeakDirect'
import PeakEquivalence from './PeakEquivalence'
import './style.scss'

const peakId = TargetTypes.PEAK
const equivalence = PeakTypes.EQUIVALENCE

const Peak = () => {
  const { loading, MasterSession, TargetActiveIndex } = useSelector(state => state.sessionrecording)
  return (
    <>
      {MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.targetType.id ===
        peakId && MasterSession.targets.edges[TargetActiveIndex].node.peakType === equivalence ? (
        <>
          <PeakEquivalence key={TargetActiveIndex} />
        </>
      ) : (
        <>
          <PeakDirect key={TargetActiveIndex} />
        </>
      )}
    </>
  )
}

export default Peak
