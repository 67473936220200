/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Button, Badge, Form, DatePicker, Table, Tooltip } from 'antd'
import { FaDownload } from 'react-icons/fa'
import { BiDollar } from 'react-icons/bi'
import { GoCheck, GoPrimitiveDot } from 'react-icons/go'
import { gql } from 'apollo-boost'
import moment from 'moment'
import { useLazyQuery } from 'react-apollo'
import LoadingComponent from 'components/VBMappReport/LoadingComponent'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { COLORS } from 'assets/styles/globalStyles'
import DropSection from './DropSection'

const { RangePicker } = DatePicker

const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
  backgroundColor: COLORS.palleteLight,
}

const parentDiv = { display: 'flex', margin: '5px 40px 5px 5px' }

const TIMESHEETS = gql`
  query($dateGte: Date!, $dateLte: Date!, $staffId: ID!) {
    timesheets(dateGte: $dateGte, dateLte: $dateLte, staffId: $staffId) {
      edges {
        node {
          id
          title
          start
          end
        }
      }
    }
  }
`

const APPOINTMENTS = gql`
  query($dateFrom: Date!, $dateTo: Date!, $therapist: ID!) {
    appointments(dateFrom: $dateFrom, dateTo: $dateTo, therapist: $therapist) {
      edges {
        node {
          id
          title
          start
          end
          isApproved
          appointmentStatus {
            id
            appointmentStatus
          }
        }
      }
    }
  }
`

const getDatesBetween = (startDate, endDate) => {
  const now = startDate.clone()
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'))
    now.add(1, 'days')
  }
  return dates
}

function Att() {
  const [currentTherapistId, setCurrentTherapistId] = useState(null)
  const [tableData, setTableData] = useState([])
  const [columns, setColumns] = useState([])
  const userRole = localStorage.getItem('role')
  const [dateRR, setDateRR] = useState([
    moment()
      .startOf('week')
      .add(1, 'day'),
    moment()
      .endOf('week')
      .add(1, 'day'),
  ])

  const staffs = useSelector(state => state.staffs)

  const [getTimeSheetData, { data: timeSheetData, loading: timeSheetLoading }] = useLazyQuery(
    TIMESHEETS,
  )
  const [getAppointmentData, { data: appointmentData, loading: appointmentLoading }] = useLazyQuery(
    APPOINTMENTS,
  )

  useEffect(() => {
    if (staffs) {
      setCurrentTherapistId(
        userRole === 'therapist' ? localStorage.getItem('student_id') : staffs.StaffProfile?.id,
      )
    }
  }, [staffs])

  useEffect(() => {
    if (timeSheetData && appointmentData && !appointmentLoading && !timeSheetLoading) {
      filterTableData(timeSheetData.timesheets.edges, appointmentData.appointments.edges)
    }
  }, [timeSheetData, appointmentData])

  useEffect(() => {
    if (dateRR[0] && dateRR[1] && currentTherapistId) {
      getTimeSheetData({
        variables: {
          dateGte: moment(dateRR[0]).format('YYYY-MM-DD'),
          dateLte: moment(dateRR[1]).format('YYYY-MM-DD'),
          staffId: currentTherapistId,
        },
      })
      getAppointmentData({
        variables: {
          dateFrom: moment(dateRR[0]).format('YYYY-MM-DD'),
          dateTo: moment(dateRR[1]).format('YYYY-MM-DD'),
          therapist: currentTherapistId,
        },
      })
    }
  }, [dateRR, currentTherapistId, currentTherapistId])

  const filterTableData = (timesheets, appointments) => {
    const filteredData = []
    const newColumns = [
      {
        title: <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Title</span>,
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
        width: '300px',
        render: (text, record) => (
          <Badge color={record.isAppointment ? 'lightpink' : 'lightblue'} text={text} />
        ),
      },
    ]

    timesheets.forEach(item => {
      const start = moment(item.node.start)
      const end = moment(item.node.end)
      const duration = moment.duration(end.diff(start)).as('minutes')

      filteredData.push({
        [moment(item.node.start).format('YYYY-MM-DD')]: duration,
        title: item.node.title,
        id: item.node.id,
        date: moment(item.node.start).format('YYYY-MM-DD'),
        duration,
        badges: { approved: true, billable: false, overtime: false }, // ! Not Integrated
        isWorkLog: true,
      })
    })

    appointments.forEach(item => {
      const start = moment(item.node.start)
      const end = moment(item.node.end)
      const duration = moment.duration(end.diff(start)).as('minutes')

      filteredData.push({
        [moment(item.node.start).format('YYYY-MM-DD')]: duration,
        title: item.node.title,
        id: item.node.id,
        date: moment(item.node.start).format('YYYY-MM-DD'),
        duration,
        badges: {
          approved: item.node.isApproved,
          billable: false,
          overtime: false,
        },
        isAppointment: true,
      })
    })

    const dates = getDatesBetween(dateRR[0], dateRR[1])

    dates.forEach((date, i) => {
      newColumns.push({
        title: (
          <span style={{ fontWeight: 'bold', fontSize: '13px' }}>
            {moment(date).format('DD MMM YYYY, ddd')}
          </span>
        ),
        dataIndex: date,
        key: `${date}_${i}`,
        align: 'center',
        width: '150px',
        render: text => {
          let hrs = 0
          let min = 0
          if (text > 0) {
            hrs = Math.floor(text / 60)
            min = Math.floor(text % 60)
          }
          return hrs > 0 ? `${hrs} Hrs ${min} Min` : `${min} Min`
        },
      })
    })

    newColumns.push({
      title: <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Status</span>,
      dataIndex: 'badges',
      key: 'badges',
      fixed: 'right',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return (
          <div>
            {record?.badges && (
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Tooltip placement="top" title="Approved">
                  <Badge
                    count={
                      <GoCheck
                        style={{
                          fontSize: '18px',
                          color: record?.badges?.approved ? 'limegreen' : 'darkgrey',
                          cursor: 'pointer',
                        }}
                      />
                    }
                  />
                </Tooltip>
                <Tooltip placement="top" title="Billable">
                  <Badge
                    count={<BiDollar />}
                    style={{
                      fontSize: '18px',
                      color: record?.badges?.billable ? 'limegreen' : 'darkgrey',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title="">
                  <Badge
                    count={<GoPrimitiveDot />}
                    style={{
                      fontSize: '18px',
                      color: record?.badges?.overtime ? 'limegreen' : 'darkgrey',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    })

    setColumns(newColumns)
    setTableData(filteredData)
  }

  const exportToCSV = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const dates = getDatesBetween(dateRR[0], dateRR[1])
    const obj = []
    const length = tableData.length

    tableData.forEach((item, i) => {
      if (i !== length - 1) {
        const data = {}
        dates.forEach((date, idx) => {
          if (item.date === date) {
            let hrs = 0
            let min = 0
            if (item[date] > 0) {
              hrs = Math.floor(item[date] / 60)
              min = Math.floor(item[date] % 60)
            }

            data[date] = hrs > 0 ? `${hrs} Hrs ${min} Min` : `${min} Min`
          } else {
            data[date] = `0 Min`
          }
        })
        obj.push({
          data,
          title: item.title,
        })
      } else {
        const notAllowed = ['title']

        const filtered = Object.keys(item)
          .filter(key => !notAllowed.includes(key))
          .reduce((ob, key) => {
            ob[key] = item[key]
            return ob
          }, {})
        const keyfiltered = Object.keys(filtered)
        const newobj = {}
        dates.forEach((date, idx) => {
          if (keyfiltered.includes(date)) {
            let hrs = 0
            let min = 0
            if (filtered[date] > 0) {
              hrs = Math.floor(filtered[date] / 60)
              min = Math.floor(filtered[date] % 60)
            }
            newobj[date] = hrs > 0 ? `${hrs} Hrs ${min} Min` : `${min} Min`
          } else {
            newobj[date] = `0 Min`
          }
        })

        obj.push({
          title: 'Total',
          data: newobj,
        })
      }
    })

    const dataToExport = obj.map(item => {
      return {
        Title: item.title,
        ...item.data,
      }
    })

    const ws = XLSX.utils.json_to_sheet(dataToExport)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })

    const startDate = moment(dateRR[0]).format('YYYY_MM_DD')
    const endDate = moment(dateRR[1]).format('YYYY_MM_DD')
    FileSaver.saveAs(excelData, `Timesheet_${startDate}-${endDate}.xlsx`)
  }

  if (staffs?.loading) {
    return <LoadingComponent />
  }

  const handleDateSelectionChange = date => {
    setDateRR([moment(date[0]), moment(date[1])])
  }

  return (
    <>
      <Row>
        <Col sm={24}>
          <Row>
            <Col span={26}>
              <div style={filterCardStyle}>
                <div style={parentDiv}>
                  <DropSection dateChange={v => handleDateSelectionChange(v)} />
                </div>
                <div style={parentDiv}>
                  <Tooltip title="Select Date range">
                    <RangePicker
                      style={{
                        marginLeft: 'auto',
                        width: 250,
                      }}
                      value={[moment(dateRR[0], 'YYYY-MM-DD'), moment(dateRR[1], 'YYYY-MM-DD')]}
                      format="YYYY-MM-DD"
                      onChange={handleDateSelectionChange}
                    />
                  </Tooltip>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    type="link"
                    size="large"
                    disabled={(appointmentLoading && timeSheetLoading) || tableData.length === 0}
                    onClick={exportToCSV}
                    style={{ padding: '0 8px' }}
                  >
                    <FaDownload fontSize="22px" />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ margin: '10px 0 10px 10px' }}>
            {currentTherapistId === null || currentTherapistId === undefined ? (
              <div>No therapist found </div>
            ) : (
              <Table
                columns={columns}
                dataSource={tableData}
                bordered
                className="goal-table"
                loading={appointmentLoading && timeSheetLoading}
                scroll={{ x: 50 }}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Form.create()(Att)
