/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Tabs, Col, Row, Divider, Button, Tooltip } from 'antd'
import { PRIMARY_ORANGE_BUTTON } from 'assets/styles/globalStyles'
import Authorize from 'components/LayoutComponents/Authorize'
import SkillsTargetAllocation from './skillTargetAllocation'
import BehaviorTargetAllocation from './behaviorTaretAllocation'
import SBTTargetAllocation from './sbtTargetAllocation'
import EditTargetAllocation from './editTargetllocation'
import { TargetAllocProvider, useTargetAlloc } from './context'
import CopyTargetAllocation from './copyTargetAllocation'
import peak_direct from './images/peak_direct.png'
import peak_equ from './images/peak_equ.png'
import regular from './images/regular.png'
import beh_rec from './images/new_behaviour_recording.PNG'
import sbt from './images/sbt.png'
import '../style.scss'

const { TabPane } = Tabs

const imageStyle = { width: '50%', margin: 'auto' }
const imageStyle_70 = { width: '90%', margin: 'auto' }

const TargetAllocation = props => {
  const {
    setSelectedTab,
    setMstSeqObj,
    selectedTab,
    setSelectedProgram,
    setUseDefaultGoal,
  } = useTargetAlloc()
  const { formType, selectedProgram } = props

  const [targetFormType, setTargetFormType] = useState('tab')
  const [showBackButton, setShowBackButton] = useState(true)

  useEffect(() => {
    if (props) {
      if (selectedProgram) {
        setSelectedProgram(selectedProgram)
      }
      if (props.equivalenceEnable) {
        setShowBackButton(false)
        setTargetFormType('rel')
      } else if (props.peakEnable) {
        setTargetFormType('dir/gen')
        setShowBackButton(false)
      } else if (props.sbtEnable) {
        setShowBackButton(false)
        setTargetFormType('sbt')
      }
      setUseDefaultGoal(props.isDefaultGoal || false)
    }
  }, [props])

  const updateTargetFormType = type => {
    if (type === 'tab') {
      setMstSeqObj({ description: '', templateType: selectedTab, phaseSet: [] })
    }
    setTargetFormType(type)
  }

  return (
    <Authorize roles={['school_admin', 'therapist']} redirect to="/dashboard/beta">
      <Row style={{ minHeight: '100%', width: '100%', padding: '20px 32px' }}>
        {formType === 'add' ? (
          <Col span={24}>
            {targetFormType === 'tab' && (
              <Tabs
                type="card"
                destroyInactiveTabPane
                onChange={setSelectedTab}
                className="main-tab"
              >
                {/* Acquisation change to  Restricted Operant */}
                <TabPane tab="Restricted Operant" key="Regular">
                  <div
                    className="allocation-home-divider"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div className="flexCenterJust">
                      <Divider orientation="left">Regular Target</Divider>
                      <Button
                        onClick={() => setTargetFormType('regular')}
                        icon="plus"
                        style={PRIMARY_ORANGE_BUTTON}
                      >
                        Add Target
                      </Button>
                    </div>
                    <img style={imageStyle} src={regular} alt="Regular target" />

                    <div className="flexCenterJust">
                      <Divider orientation="left">Coded Target</Divider>
                      <Button
                        icon="plus"
                        onClick={() => setTargetFormType('dir/gen')}
                        style={PRIMARY_ORANGE_BUTTON}
                      >
                        Add Target
                      </Button>
                    </div>
                    <img
                      style={imageStyle}
                      src={peak_direct}
                      alt="Peak type direct/generalisation target"
                    />
                    {/* <div className="flexCenterJust">
                      <Divider orientation="left">Relational Target</Divider>
                      <Button
                        onClick={() => setTargetFormType('rel')}
                        icon="plus"
                        style={PRIMARY_ORANGE_BUTTON}
                      >
                        Add Target
                      </Button>
                    </div>
                    <img style={imageStyle} src={peak_equ} alt="Peak type equivalence target" /> */}
                  </div>
                </TabPane>
                {/* Behavior Reduction to free operant */}
                <TabPane tab="Free Operant" key="Behavior Recording Frequency">
                  <div
                    className="allocation-home-divider"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div className="flexCenterJust">
                      <Divider orientation="left">Free Operant Target</Divider>
                      <Button
                        onClick={() => setTargetFormType('beh')}
                        icon="plus"
                        style={PRIMARY_ORANGE_BUTTON}
                      >
                        Add Target
                      </Button>
                    </div>
                    <img style={imageStyle_70} src={beh_rec} alt="Behavior recording target" />
                  </div>
                </TabPane>
                <TabPane tab="SBT target" key="SBT">
                  <div
                    className="allocation-home-divider"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div className="flexCenterJust">
                      <Divider orientation="left">Skills Based Treatment Target</Divider>
                      <Button
                        onClick={() => setTargetFormType('sbt')}
                        icon="plus"
                        style={PRIMARY_ORANGE_BUTTON}
                      >
                        Add Target
                      </Button>
                    </div>
                    <img style={imageStyle_70} src={sbt} alt="Behavior recording target" />
                  </div>
                </TabPane>
              </Tabs>
            )}
            {targetFormType === 'dir/gen' && (
              <SkillsTargetAllocation
                key="tar_allocation_dir"
                {...props}
                peakEnable
                showBackButton={showBackButton}
                setTargetFormType={updateTargetFormType}
              />
            )}
            {targetFormType === 'rel' && (
              <SkillsTargetAllocation
                key="tar_allocation_rel"
                {...props}
                peakEnable
                showBackButton={showBackButton}
                equivalenceEnable
                setTargetFormType={updateTargetFormType}
              />
            )}
            {targetFormType === 'regular' && (
              <SkillsTargetAllocation
                key="tar_allocation_regular"
                showBackButton={showBackButton}
                {...props}
                setTargetFormType={updateTargetFormType}
              />
            )}
            {targetFormType === 'beh' && (
              <BehaviorTargetAllocation
                key="tar_allocation_beh"
                {...props}
                showBackButton={showBackButton}
                setTargetFormType={updateTargetFormType}
              />
            )}
            {targetFormType === 'sbt' && (
              <SBTTargetAllocation
                key="tar_allocation_sbt"
                {...props}
                showBackButton={showBackButton}
                setTargetFormType={updateTargetFormType}
              />
            )}
          </Col>
        ) : formType === 'edit' ? (
          <Col span={24}>
            <EditTargetAllocation {...props} />
          </Col>
        ) : formType === 'copy' ? (
          <Col span={24}>
            <CopyTargetAllocation {...props} />
          </Col>
        ) : null}
      </Row>
    </Authorize>
  )
}

export default props => (
  <TargetAllocProvider>
    <TargetAllocation {...props} />
  </TargetAllocProvider>
)
