import gql from 'graphql-tag'

export const DANCLE_STATUS = gql`
  query {
    getDecelStatus {
      id
      statusName
      statusCode
    }
  }
`

export const DANCLE_ENVS = gql`
  query {
    getEnvironment {
      id
      name
      defination
    }
  }
`

export const DANCLE_MEASURMENTS = gql`
  query {
    getBehaviourMeasurings {
      id
      measuringType
      unit
    }
  }
`

export const CREATE_TAMPLET = gql`
  mutation createTemplate(
    $studentId: ID!
    $behaviorId: ID!
    $status: ID!
    $description: String
    $measurments: [ID]
    $envs: [ID]
    $manipulations: String
    $procedures: String
    $remainders: [RemainderInput]
  ) {
    createTemplate(
      input: {
        decelData: {
          student: $studentId
          behavior: $behaviorId
          status: $status
          behaviorDescription: $description
          measurments: $measurments
          environment: $envs
          antecedentManipulations: $manipulations
          reactiveProcedures: $procedures
          remainders: $remainders
        }
      }
    ) {
      details {
        id
        behaviorDef
        behaviorDescription
        reactiveProcedures
        antecedentManipulations
        behavior {
          id
          behaviorName
          definition
        }
        status {
          id
          statusName
        }
        measurments {
          edges {
            node {
              id
            }
          }
        }
        environment {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`

export const BEHAVIORS = gql`
  query getBehaviour($studentId: ID!) {
    getBehaviour(studentId: $studentId) {
      edges {
        node {
          id
          behaviorName
        }
      }
    }
  }
`

export const CREATE_BEHAVIOR = gql`
  mutation createBehaviour($studentId: ID!, $name: String!) {
    createBehaviour(input: { student: $studentId, name: $name, definition: "Test Definition" }) {
      details {
        id
        behaviorName
      }
    }
  }
`

export const CREATE_ENVIRONMENT = gql`
  mutation createEnvironment($name: String!, $studentId: ID!) {
    decelEnvironment(
      input: { name: $name, studentId: $studentId, description: "Env description" }
    ) {
      environment {
        id
        name
      }
    }
  }
`

export const RECORD_DATA = gql`
  mutation createDecel($templateId: ID!) {
    createDecel(input: { template: $templateId }) {
      details {
        id
        date
        irt
        note
        duration
        frequency {
          edges {
            node {
              id
              count
              time
            }
          }
        }
        template {
          id
          behaviorDescription
          behavior {
            behaviorName
          }
          status {
            statusName
          }
          environment {
            edges {
              node {
                id
                name
              }
            }
          }
          measurments {
            edges {
              node {
                id
                measuringType
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_RECORD = gql`
  mutation updateDecel(
    $id: ID!
    $env: ID!
    $irt: Int
    $intensity: String
    $duration: String!
    $date: Date!
  ) {
    updateDecel(
      input: {
        pk: $id
        environment: $env
        irt: $irt
        intensity: $intensity
        duration: $duration
        date: $date
      }
    ) {
      details {
        id
        irt
        note
        duration
        date
        intensity
        environment {
          id
        }
        frequency {
          edges {
            node {
              id
              count
              time
            }
          }
        }
        template {
          behavior {
            id
            behaviorName
          }
          environment {
            edges {
              node {
                id
                name
              }
            }
          }
          measurments {
            edges {
              node {
                id
                measuringType
              }
            }
          }
        }
        status {
          id
          statusName
        }
      }
    }
  }
`

export const UPDATE_FREQUENCY = gql`
  mutation updateDecelFrequency($id: ID!, $count: Int!, $time: Int!) {
    updateDecelFrequency(input: { pk: $id, count: $count, time: $time }) {
      details {
        frequency {
          edges {
            node {
              id
              count
              time
            }
          }
        }
      }
    }
  }
`

export const GET_TEMPLETES = gql`
  query getTemplate($studentId: ID!) {
    getTemplate(student: $studentId) {
      edges {
        node {
          id
          behavior {
            id
            behaviorName
            definition
          }
          createdAt
          status {
            id
            statusName
          }
          environment {
            edges {
              node {
                id
                name
              }
            }
          }
          behaviorDescription
        }
      }
    }
  }
`

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($id: ID!) {
    deleteTemplate(input: { pk: $id }) {
      status
      message
    }
  }
`
export const BEHAVIOR_RECORD_DATA = gql`
  query getDecelData($dateGte: Date!, $dateLte: Date!, $studentId: ID!, $status: ID) {
    getDecelData(
      template_Student: $studentId
      date_Gte: $dateGte
      date_Lte: $dateLte
      status: $status
    ) {
      edges {
        node {
          id
          irt
          note
          duration
          date
          intensity
          environment {
            id
          }
          frequency {
            edges {
              node {
                id
                count
                time
              }
            }
          }
          template {
            id
            behavior {
              id
              behaviorName
            }
            environment {
              edges {
                node {
                  id
                  name
                }
              }
            }
            measurments {
              edges {
                node {
                  id
                  measuringType
                }
              }
            }
          }
          status {
            id
            statusName
          }
        }
      }
    }
  }
`
export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate(
    $behaviorId: ID!
    $behaviorType: [String]!
    $tempId: ID!
    $status: ID!
    $description: String!
    $env: [ID]
    $reminders: [RemainderInput]
    $manipulation: String
    $procedures: String
  ) {
    updateTemplate(
      input: {
        templateData: {
          pk: $tempId
          behavior: $behaviorId
          behaviorType: $behaviorType
          status: $status
          description: $description
          environment: $env
          antecedentManipulation: $manipulation
          reactiveProcedures: $procedures
          reminders: $reminders
        }
      }
    ) {
      details {
        id
        behaviorType
        description
        reactiveProcedures
        antecedentManipulation
        behavior {
          id
          behaviorName
          definition
        }
        status {
          id
          statusName
        }
      }
    }
  }
`

export const DELETE_BEHAVIOR_RECORD = gql`
  mutation($id: ID!) {
    deleteDecel(input: { pk: $id }) {
      status
      msg
    }
  }
`

export const GET_BEHAVIOR_TEMPLATES = gql`
  query getBehaviorTemplates($student: ID!) {
    getBehaviorTemplates(student: $student) {
      edges {
        node {
          id
          behaviorType
          templateName
          behavior {
            id
            behaviorName
            definition
          }
          createdAt
          status {
            id
            statusName
          }
          statusrecordSet {
            edges {
              node {
                id
                status {
                  id
                  statusName
                  createdAt
                }
                statusTime
              }
            }
          }
          # phase {
          #   id
          #   content
          #   date
          # }
          student {
            id
          }
          description
          frequencyratebehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                duration
                frequency
                environment {
                  id
                }
                rate
                rateUnit
              }
            }
          }
          durationbehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                duration
                environment {
                  id
                }
              }
            }
          }
          latencybehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                latency
                environment {
                  id
                }
              }
            }
          }
          partialintervalbehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                observedTime
                intervalLength
                intervals
                frequency
                environment {
                  id
                }
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
          wholeintervalbehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                observedTime
                intervalLength
                intervals
                frequency
                environment {
                  id
                }
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
          momentarytimebehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                observedTime
                intervalLength
                intervals
                frequency
                rangeMin
                rangeMax
                environment {
                  id
                }
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_TEMPLATES_ENVIRONMENTS = gql`
  query getBehaviorTemplatesEnvironments($templates: [String!]!) {
    getBehaviorTemplatesEnvironments(templates: $templates) {
      templateId
      environments {
        id
        name
      }
    }
  }
`

export const ADD_TEMPLATE_TO_STUDENT = gql`
  mutation addStudentBehaviorTemplate($student: ID!, $template: ID!) {
    addStudentBehaviorTemplate(input: { student: $student, template: $template }) {
      studentBehaviorTemplate {
        id
        student {
          id
        }
        template {
          id
        }
      }
    }
  }
`

export const GET_STUDENT_TEMPLATES = gql`
  query getStudentBehaviorTemplates($studentId: ID!) {
    getStudentBehaviorTemplates(student: $studentId) {
      edges {
        node {
          id
          student {
            id
          }
          template {
            id
            behaviorType
            behavior {
              id
              behaviorName
            }
            status {
              id
              statusName
            }
            description
          }
          frequencyratebehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                duration
                frequency
                rate
              }
            }
          }
          durationbehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                duration
              }
            }
          }
          latencybehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                latency
              }
            }
          }
          partialintervalbehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                observedTime
                intervalLength
                intervals
                frequency
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
          wholeintervalbehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                observedTime
                intervalLength
                intervals
                frequency
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
          momentarytimebehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                observedTime
                intervalLength
                intervals
                frequency
                rangeMin
                rangeMax
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const REMOVE_TEMPLATE_FROM_STUDENT = gql`
  mutation removeStudentBehaviorTemplate($student: ID!, $template: ID!) {
    removeStudentBehaviorTemplate(input: { student: $student, template: $template }) {
      status
    }
  }
`

export const REMOVE_BEHAVIOR_TEMPLATE = gql`
  mutation behaviorTemplateDeleteMutation($template: ID!) {
    behaviorTemplateDeleteMutation(input: { template: $template }) {
      id
      status
    }
  }
`

export const REMOVE_STUDENT_BEHAVIOR_RECORD = gql`
  mutation studentBehaviorRecordDeleteMutation($record: ID!, $behaviorType: String!) {
    studentBehaviorRecordDeleteMutation(input: { record: $record, behaviorType: $behaviorType }) {
      status
    }
  }
`
