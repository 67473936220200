/* eslint-disable import/prefer-default-export */
import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { useQuery, useLazyQuery } from 'react-apollo'
import {
  DEFAULT_SHORT_TERM_GOALS,
  GET_DOMAINS,
  PROMPT_CODES,
  SHORT_TERM_GOALS,
  TARGET_ALLOCATIONS_OPTIONS,
  TARGET_STATUS,
  SETTING,
  GET_BEHAVIOUR,
  BEH_PROMPT_CODES,
  GET_LEARNER_SESSIONS,
  GET_MASTERY_SEQ_TEMPLATE,
  GET_TEMPLATE_BY_ID,
  GET_SESSION_NAMES,
} from './query'
import { GET_PATIENT } from '../query'

const Context = React.createContext()

export function useTargetAlloc() {
  return useContext(Context)
}

export function TargetAllocProvider({ children }) {
  let stdId = ''
  if (!(localStorage.getItem('studentId') === null) && localStorage.getItem('studentId')) {
    stdId = JSON.parse(localStorage.getItem('studentId'))
  }

  const school = useSelector(state => state.school)
  const [selectedStudent, setSelectedStudent] = useState(stdId)

  const student = useSelector(state => state.student)
  const [selectedProgram, setSelectedProgram] = useState(null)

  const [programAreas, setProgramAreas] = useState([])
  const [makeDefault, setMakeDefault] = useState(true)
  const [useDefaultGoal, setUseDefaultGoal] = useState(false)

  const [sessionNames, setSessionNames] = useState([])
  const [manualMastActive, setManualMastActive] = useState(false)
  const [selectedTab, setSelectedTab] = useState('Regular')
  const [mastSeqTemplate, setMastSeqTemplate] = useState([])
  const [defaultShortTermGoalIdForABA, setDefaultShortTermGoalIdForABA] = useState()
  const [shortGoalData, setShortGoalData] = useState([])
  const [targetType, setTargetType] = useState([])
  const [targetStatusMapping, setTargetStatusMapping] = useState({})

  const [mstSeqObj, setMstSeqObj] = useState({
    description: '',
    templateType: selectedTab,
    phaseSet: [],
  })

  const [targetMasSeq, setTargetMasSeq] = useState(mstSeqObj)

  const {
    data: promptData,
    error: promptError,
    loading: promptLoading,
    refetch: refetchPromptData,
  } = useQuery(PROMPT_CODES)

  const {
    data: targetOptions,
    error: targetOptionsError,
    loading: targetOptionsLoading,
  } = useQuery(TARGET_ALLOCATIONS_OPTIONS)

  const {
    data: targetStatusData,
    error: targetStatusError,
    loading: targetStatusLoading,
  } = useQuery(TARGET_STATUS)

  const [
    fetchSettings,
    { data: settingData, loading: settingLoading, error: settingError },
  ] = useLazyQuery(SETTING)

  const { data: domainData, error: domainError, loading: isDomainLoading } = useQuery(GET_DOMAINS)

  const { data: behPromptData, error: behPromptError, loading: behPromptLoading } = useQuery(
    BEH_PROMPT_CODES,
  )

  const [fetchProgramArea, { data: prgData, loading: prgLoading, error: prgError }] = useLazyQuery(
    GET_PATIENT,
  )

  const [
    fetchBehaveData,
    { data: behaveData, error: behaveError, loading: behaveLoading, refetch: refetchBehave },
  ] = useLazyQuery(GET_BEHAVIOUR)

  const [
    fetchShortGoals,
    { data: shortGoals, error: shortGoalsError, loading: shortGoalLoading },
  ] = useLazyQuery(SHORT_TERM_GOALS, {
    fetchPolicy: 'network-only',
  })

  const [
    fetchDefaultShortGoals,
    { data: defaultShortGoals, error: defaultShortGoalsError, loading: defaultShortGoalLoading },
  ] = useLazyQuery(DEFAULT_SHORT_TERM_GOALS)

  const [
    fetchLearnerSession,
    { data: sessData, loading: sessDataLoading, error: sessDataError, refetch: refetchSession },
  ] = useLazyQuery(GET_LEARNER_SESSIONS)

  const [
    fetchSessionNames,
    { data: sessNamesData, loading: sessNamesDataLoading, error: sessNamesDataError },
  ] = useLazyQuery(GET_SESSION_NAMES)

  const [
    fetchData,
    { data: profileMst, loading: profileMstLoading, error: profileMstError, refetch },
  ] = useLazyQuery(GET_TEMPLATE_BY_ID)

  const [
    fetchSeqTemplate,
    {
      data: mastSeqTemplateData,
      loading: mastSeqLoading,
      error: mastSeqError,
      refetch: mastRefetch,
    },
  ] = useLazyQuery(GET_MASTERY_SEQ_TEMPLATE, {
    variables: {
      clinicId: school.id,
    },
  })

  useEffect(() => {
    if (student) {
      setSelectedStudent(student.StudentDetails)
    }
  }, [student])

  useEffect(() => {
    if (selectedStudent && selectedStudent.id) {
      fetchProgramArea({
        variables: {
          id: selectedStudent.id,
        },
      })
      fetchSettings({
        variables: {
          studentId: selectedStudent.id,
        },
        fetchPolicy: 'network-only',
      })
      fetchBehaveData({
        variables: { studentId: selectedStudent.id },
      })

      fetchSessionNames({
        variables: {
          studentId: selectedStudent.id,
          date: moment().format('YYYY-MM-DD'),
        },
      })
      fetchLearnerSession({
        variables: {
          studentId: selectedStudent.id,
        },
      })
    }
  }, [selectedStudent])

  useEffect(() => {
    if (selectedStudent && selectedStudent.id) {
      fetchShortGoals({
        variables: { studentId: selectedStudent.id, program: selectedProgram },
      })
      fetchDefaultShortGoals({
        variables: { studentId: selectedStudent.id, program: selectedProgram },
      })
    }
  }, [selectedStudent, selectedProgram])

  useEffect(() => {
    fetchSeqTemplate()
  }, [])

  useEffect(() => {
    if (prgData) {
      setProgramAreas(prgData.student.programArea.edges.map(({ node }) => node))
    } else {
      setProgramAreas([])
    }
  }, [prgData])

  useEffect(() => {
    if (sessNamesData && sessNamesData.GetStudentSession) {
      const tempSess = sessNamesData.GetStudentSession.edges
        .filter(item => item.node.isActive)
        .map(item => item.node)
      setSessionNames(tempSess)
    } else {
      setSessionNames([])
    }
  }, [sessNamesData])

  useEffect(() => {
    if (selectedTab && mastSeqTemplateData) {
      const tempTemplate = mastSeqTemplateData?.getCriteriaSequenceTemplate.filter(
        item => item.templateType === selectedTab,
      )
      setMastSeqTemplate(tempTemplate)
    }
  }, [selectedTab, mastSeqTemplateData])

  useEffect(() => {
    if (defaultShortGoals && defaultShortGoals.shortTerm) {
      if (selectedProgram) {
        defaultShortGoals.shortTerm.edges.forEach(({ node }) => {
          setDefaultShortTermGoalIdForABA(node.id)
        })
      } else {
        let abaAvailable = false
        defaultShortGoals.shortTerm.edges.forEach(({ node }) => {
          if (node.longTerm?.program?.name === 'ABA' && node.longTerm?.program?.isActive) {
            abaAvailable = true
            setDefaultShortTermGoalIdForABA(node.id)
          }
        })
        if (!abaAvailable) {
          defaultShortGoals.shortTerm.edges.forEach(({ node }) => {
            if (node.longTerm?.program?.isActive) setDefaultShortTermGoalIdForABA(node.id)
          })
        }
      }
    }
  }, [defaultShortGoals])

  useEffect(() => {
    if (shortGoals && shortGoals.shortTerm) {
      const tempData = shortGoals.shortTerm.edges.filter(
        ({ node }) => node.longTerm?.program?.isActive,
      )
      setShortGoalData(tempData)
    }
  }, [shortGoals])

  useEffect(() => {
    if (profileMst && profileMst.getCriteriaTemplate) {
      const { getCriteriaTemplate } = profileMst
      if (getCriteriaTemplate.templateType === selectedTab) {
        const tempPhaseSet = getCriteriaTemplate.criteriaphasesSet.edges.map(item => {
          const tempMast = item.node.criteriaphasetargetsSet.edges.map(mst => {
            return { ...mst.node, id: null, key: Math.random() }
          })
          return {
            key: Math.random(),
            autoLabel: item.node.autoLabel,
            criteriaType: item.node.criteriaType.id,
            criteriaTypeName: item.node.criteriaType.statusName,
            stepNo: item.node.stepNo,
            prompt: item.node.prompts.edges.map(pr => pr.node.id),
            masterySet: tempMast,
          }
        })
        setMstSeqObj({
          isTargetSpecific: manualMastActive,
          description: getCriteriaTemplate.description,
          phaseSet: tempPhaseSet,
        })
        setTargetMasSeq({
          isTargetSpecific: manualMastActive,
          description: getCriteriaTemplate.description,
          phaseSet: tempPhaseSet,
        })
      } else {
        setMstSeqObj({
          isTargetSpecific: manualMastActive,
          description: '',
          templateType: selectedTab,
          phaseSet: [],
        })
        setTargetMasSeq({
          isTargetSpecific: manualMastActive,
          description: '',
          templateType: selectedTab,
          phaseSet: [],
        })
      }
    }
  }, [profileMst, manualMastActive])

  useEffect(() => {
    if (targetOptions && targetOptions.types) {
      const tempType = targetOptions.types.filter(
        node =>
          node.id !== 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==' && node.id !== 'VGFyZ2V0RGV0YWlsVHlwZToxMA==',
      )
      setTargetType(tempType)
    }
    if (targetOptions && targetOptions.targetStatus) {
      const tt = targetOptions.targetStatus.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: curr.statusName,
        }),
        {},
      )
      setTargetStatusMapping(tt)
    }
  }, [targetOptions])

  useEffect(() => {
    if (shortGoalsError) {
      notification.error({
        message: 'Failed to load short term goal list',
      })
    }
    if (defaultShortGoalsError) {
      notification.error({
        message: 'Failed to load default short term Goal list',
      })
    }
    if (promptError) {
      console.error(promptError, 'error')
    }

    if (targetStatusError) {
      console.error(targetStatusError, 'error')
    }
    if (mastSeqError) {
      console.error(mastSeqError, ' mast seq error')
    }
    if (sessNamesDataError) {
      console.error(sessNamesDataError)
    }
    if (sessDataError) {
      console.error(sessDataError, ' session data error')
    }
    if (profileMstError) {
      console.error(profileMstError, ' profile mastery error ')
    }
  }, [
    shortGoalsError,
    defaultShortGoalsError,
    behPromptError,
    behaveError,
    promptError,
    targetStatusError,
    domainError,
    settingError,
    targetOptionsError,
    mastSeqError,
    sessNamesDataError,
    profileMstError,
    sessDataError,
  ])

  const fetchMasteryData = mstId => {
    fetchData({
      variables: {
        id: mstId,
      },
    })
  }

  const value = {
    defaultShortTermGoalIdForABA,
    useDefaultGoal,
    setUseDefaultGoal,
    selectedProgram,
    setSelectedProgram,
    selectedStudent,
    setSelectedStudent,
    targetStatusData,
    targetStatusLoading,
    targetOptions,
    targetOptionsLoading,
    promptData,
    promptLoading,
    refetchPromptData,
    behaveData,
    behaveLoading,
    refetchBehave,
    shortGoalData,
    shortGoalLoading,
    domainData,
    isDomainLoading,
    settingData,
    settingLoading,
    behPromptData,
    behPromptLoading,
    sessData,
    sessDataLoading,
    sessionNames,
    sessNamesDataLoading,
    mastSeqTemplate,
    mastSeqLoading,
    manualMastActive,
    setManualMastActive,
    selectedTab,
    setSelectedTab,
    mstSeqObj,
    setMstSeqObj,
    fetchMasteryData,
    profileMstLoading,
    makeDefault,
    setMakeDefault,
    programAreas,
    targetType,
    targetStatusMapping,
    targetMasSeq,
    setTargetMasSeq,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}
