/* eslint-disable no-nested-ternary */
/* eslint no-sparse-arrays: "error" */
/* eslint-disable */

/* eslint no-underscore-dangle:0 */
import React, { useState, useEffect } from 'react'
import { ResponsiveLine } from '@nivo/line'

import { Line } from 'react-chartjs-2'
import { Empty } from 'antd'
import 'chartjs-plugin-annotation'
import { useQuery, useLazyQuery } from 'react-apollo'
import { groupBy } from 'lodash'
import moment from 'moment'
import LoadingComponent from '../staffProfile/LoadingComponent'
// import { DRAWER } from '../../../assets/styles/globalStyles'
// import EditableTable from './EditableTable'

import { GET_BEHAVIOR_TEMPLATES } from '../../components/BehaviourData/queries'

const BehaviorDRChart = ({
  decelData,
  selectedStudentId,
  activeTab,
  keyname,
  startDate,
  endDate,
  selectedBehavior,
  createElement,
}) => {
  const [templateData, setTemplateData] = useState([])
  const [chartDrData, setChartDrData] = useState([])

  const [behName, setBehName] = useState('')
  const [isFRdata, setIsFRdata] = useState(false)
  const [clickedElement, setClickedElement] = useState('')
  const [dataRecord, setDataRecord] = useState([])
  const [phaseLines, setPhaseLines] = useState([])

  startDate = moment(startDate).format('YYYY-MM-DD')
  endDate = moment(endDate).format('YYYY-MM-DD')
  console.log('slected behavior', startDate, endDate, selectedStudentId)

  const {
    data: tempData,
    error: templateError,
    loading: templateLoading,
    refetch: refetchTemplates,
  } = useQuery(GET_BEHAVIOR_TEMPLATES, {
    variables: {
      student: selectedStudentId,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setDataRecord([])
    if (tempData && tempData.getBehaviorTemplates.edges) {
      console.log('tempdata===>', tempData)
      const tempArray = []
      tempData.getBehaviorTemplates.edges.forEach((element, index) => {
        const {
          behavior,
          behaviorType,
          frequencyratebehaviorrecordSet,
          durationbehaviorrecordSet,
          latencybehaviorrecordSet,
          partialintervalbehaviorrecordSet,
          wholeintervalbehaviorrecordSet,
          momentarytimebehaviorrecordSet,
          statusrecordSet,
        } = element.node
        if (behaviorType.includes('DR')) {
          if (durationbehaviorrecordSet) {
            const behaviorRecords = durationbehaviorrecordSet.edges.map(({ node }) => ({
              id: node.id,
              date: node.date,
              startTime: node.startTime,
              endTime: node.endTime,
              duration: node.duration,
              behaviorType: 'DR',
              tempData,
              behavior: behavior.behaviorName,
            }))

            const grouped = groupBy(behaviorRecords, 'date')

            const data = []
            Object.keys(grouped).forEach(item => {
              let duration = 0
              grouped[item].forEach(d => {
                duration += d.duration
              })
              data.push({
                date: item,

                duration,
                behavior,
              })
            })
            console.log('DR>>>>', data)
            tempArray.push({
              behName: behavior.behaviorName,
              data,
            })
            // setDataRecord(olddata => [
            //     ...olddata,
            //     {
            //       behName: behavior.behaviorName,
            //       data,

            //     },
            //   ])
          }
        }
      })
      setDataRecord(tempArray)
    }
  }, [tempData, selectedStudentId, startDate, endDate])

  const generateLabels = () => {
    const daysCnt = Math.abs(moment(endDate).diff(startDate, 'days'))
    const labels = Array(daysCnt + 1)
      .fill(0)
      .map((itemValue, itemIndex) => {
        return moment(startDate)
          .add(itemIndex, 'day')
          .format('YYYY-MM-DD')
      })
    return labels
  }
  const result = generateLabels()

  useEffect(() => {
    const datadr = []
    setChartDrData([])
    // console.log('dataRecord duration===>', dataRecord, selectedStudentId)
    dataRecord.forEach((i, index) => {
      const duration = []

      result.forEach((item, index) => {
        const x = i.data.find(item1 => item === item1.date)
        // console.log('x>>', x)
        if (x) {
          duration.push({ x: moment(item).format('DD'), y: x.duration })
        } else {
          duration.push({ x: moment(item).format('DD'), y: 0 })
        }
      })

      datadr.push({ id: i.behName, data: duration })
    })
    setChartDrData(datadr)
  }, [dataRecord, startDate, endDate, selectedStudentId])
  // 11console.log('chart dr data-->', chartDrData)
  const elem = document.getElementById('DRChart')
  createElement(elem)
  console.log('Element Dr', elem)
  return (
    <div id="DRChart">
      <div style={{ height: 50 }}> </div>
      <div style={{ height: 300 }}>
        {chartDrData.length === 0 ? (
          <Empty />
        ) : (
          <ResponsiveLine
            key="DR"
            data={chartDrData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            enableGridX={false}
            enableSlices="x"
            yFormat=" >-.2f"
            curve="linear"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Date',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'count',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            colors={{ scheme: 'category10' }}
            pointSize={10}
            pointColor={{ from: 'color' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 50,
                itemsSpacing: 0,
                itemDirection: 'right-to-left',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </div>
    </div>
  )
}

export default BehaviorDRChart
