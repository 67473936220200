/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { Tabs, Button, notification, Drawer, Modal, Tooltip } from 'antd'
import { FaDownload } from 'react-icons/fa'
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import domtoimage from 'dom-to-image'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import MilestonesTab from './Milestones/MilestonesTab'
import BarriersTab from './Barriers/BarriersTab'
import TransitionAssessmentTab from './TransitionAssessment/TransitionAssessmentTab'
import TaskAnalysisTab from './TaskAnalysis/TaskAnalysisTab'
import LoadingContextProvider, { LoadingContext } from './context'

import LoadingComponent from './LoadingComponent'
import AssessmentDropDown from './AssessmentDropDown'
import {
  GET_VBMAPP_ASSESMENTS,
  GET_VBMAPP_AREAS,
  GET_VBMAPP_SCORE,
  GET_VBMAPP_REPORT,
} from './queries'
import VBMappReportPDF from './VBMappReportPDF'
import { structurizeData, generateMilestoneLevels } from './helperFunction'
import './Style.scss'

const { TabPane } = Tabs

const VBMappReport = ({
  selectedStudentId,
  studentName,
  showDrawerFilter,
  lastAssessment = false,
}) => {
  const LoadingC = useContext(LoadingContext)
  const { dataLoading, level, setMilestoneLevelData, setMilestoneData } = LoadingC

  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null)
  const [structuredReportData, setStructuredReportData] = useState(null)
  const [activeTab, setActiveTab] = useState('milestones')
  const [pdfDrawer, setPDFDrawer] = useState(false)
  const [imgData, setImgData] = useState({})
  const [pdfLoading, setPdfLoading] = useState(false)

  const [milestonesAreaId, setMilestonesAreaId] = useState()
  const [barriersAreaId, setBarriersAreaId] = useState()
  const [transitionAssessmentAreaId, setTransitionAssessmentAreaId] = useState()
  const [taskAnalysisAreaId, setTaskAnalysisAreaId] = useState()
  const { data: vbmappAreas, error: vbmappAreaErrors, loading: isVbmappAreaLoading } = useQuery(
    GET_VBMAPP_AREAS,
  )

  const {
    data: assessmentData,
    error: assessmentErrors,
    loading: isAssessmentLoading,
    refetch: refetchData,
  } = useQuery(GET_VBMAPP_ASSESMENTS, {
    variables: {
      studentId: selectedStudentId,
    },
    fetchPolicy: 'network-only',
  })

  // const {
  //   data: assessmenttotalScore,
  //   error: assessmenttotalScoreErrors,
  //   loading: isAssessmentTotalScoreLoading,
  //   refetch: refetchScoreData,
  // } = useQuery(GET_VBMAPP_SCORE, {
  //   variables: {
  //     studentId: selectedStudentId,
  //   },
  //   fetchPolicy: 'network-only',
  // })

  const [
    loadReportData,
    {
      data: vbmappReport,
      error: vbmappReportErrors,
      loading: isVbmappReportLoading,
      refetch: refetchvbmapp,
    },
  ] = useMutation(GET_VBMAPP_REPORT)

  useEffect(() => {
    if (assessmentData && assessmentData.vbmappGetAssessments.edges.length > 0 && vbmappReport) {
      setMilestoneLevelData(generateMilestoneLevels())
      const { milestones } = vbmappReport
      setMilestoneData(milestones)
      setStructuredReportData(structurizeData(vbmappReport))
    } else {
      setStructuredReportData(null)
    }
  }, [JSON.stringify(vbmappReport)])

  // useEffect(() => {
  //   if (assessmenttotalScore && assessmenttotalScore.vbmappAssessmentTotalScores) {
  //     console.log('<<<in iff', vbmappReport)
  //     setStructuredReportData(structurizeData(vbmappReport))
  //   } else {
  //     setStructuredReportData(null)
  //   }
  // }, [JSON.stringify(vbmappReport)])

  useEffect(() => {
    if (vbmappAreaErrors && assessmentErrors && vbmappReportErrors) {
      notification.error({
        message: 'Something went wrong while feching data',
      })
    }
  }, [vbmappAreaErrors, assessmentErrors, vbmappReportErrors])

  useEffect(() => {
    if (vbmappAreas && !isVbmappAreaLoading) {
      vbmappAreas.vbmappAreas.forEach(area => {
        if (area.areaName) {
          switch (area.areaName) {
            case 'Milestones':
              setMilestonesAreaId(area.id)
              break
            case 'Barriers':
              setBarriersAreaId(area.id)
              break
            case 'Transition Assessment':
              setTransitionAssessmentAreaId(area.id)
              break
            case 'Task Analysis':
              setTaskAnalysisAreaId(area.id)
              break
            default:
              console.log(`Error:Found unknown VBMAPP Area: ${area?.areaName}`)
          }
        }
      })
    }
  }, [vbmappAreas])

  useEffect(() => {
    let selectedAssessment = null
    if (assessmentData && milestonesAreaId) {
      if (assessmentData?.vbmappGetAssessments?.edges.length > 0) {
        selectedAssessment =
          assessmentData?.vbmappGetAssessments?.edges[
            assessmentData?.vbmappGetAssessments?.edges.length - 1
          ]?.node?.id
        // if (lastAssessment)
        //   selectedAssessment =
        //     assessmentData?.vbmappGetAssessments?.edges[
        //       assessmentData?.vbmappGetAssessments?.edges.length - 1
        //     ]?.node?.id
        // else selectedAssessment = assessmentData?.vbmappGetAssessments?.edges[0]?.node?.id
      } else {
        selectedAssessment = null
      }
      if (selectedAssessment) {
        setSelectedAssessmentId(selectedAssessment)
        loadReportData({
          variables: {
            assessmentId: selectedAssessment,
            milestonesArea: milestonesAreaId,
            barriersArea: barriersAreaId,
            transitionAssessmentArea: transitionAssessmentAreaId,
            taskAnalysisArea: taskAnalysisAreaId,
          },
        })
      } else {
        setSelectedAssessmentId(null)
        setStructuredReportData(null)
      }
    }
  }, [assessmentData, milestonesAreaId, selectedStudentId])

  useEffect(() => {
    if (milestonesAreaId && selectedAssessmentId) {
      loadReportData({
        variables: {
          assessmentId: selectedAssessmentId,
          milestonesArea: milestonesAreaId,
          barriersArea: barriersAreaId,
          transitionAssessmentArea: transitionAssessmentAreaId,
          taskAnalysisArea: taskAnalysisAreaId,
        },
      })
    }
  }, [selectedAssessmentId])

  const handleAssesmentChange = selectedAssessment => {
    setSelectedAssessmentId(selectedAssessment)

    // loadReportData({
    //   variables: {
    //     assessmentId: selectedAssessment,
    //     milestonesArea: milestonesAreaId,
    //     barriersArea: barriersAreaId,
    //     transitionAssessmentArea: transitionAssessmentAreaId,
    //     taskAnalysisArea: taskAnalysisAreaId,
    //   },
    // })
  }
  // useEffect(() => {
  //   console.log('activeTab???>>>', !isAssessmentLoading && !isVbmappReportLoading)
  //   if(!isAssessmentLoading && !isVbmappReportLoading){
  //     console.log('activeTab???>>>',activeTab)
  //     setActiveTab('milestones')
  //     // createImg()
  //   }

  // }, [isAssessmentLoading,isVbmappReportLoading])

  useEffect(() => {
    if (activeTab && !dataLoading && !isAssessmentLoading && !isVbmappAreaLoading) {
      createImg()
    }
  }, [activeTab, dataLoading, isAssessmentLoading, isVbmappAreaLoading, level])

  const createImg = () => {
    const tempObj = []
    const handler = []
    console.log('create Img??')
    setPdfLoading(true)
    const input = document.getElementById(activeTab)
    let title = ''
    if (activeTab === 'milestones') {
      title = 'MileStones'
    }
    if (activeTab === 'barriers') {
      title = 'Barriers'
    }
    if (activeTab === 'transitionAssessment') {
      title = 'Transition Assessment'
    }
    if (activeTab === 'taskAnalysis') {
      title = 'Task Analysis'
    }
    if (activeTab === 'taskAnalysis') {
      if (level) {
        const data = Object.keys(level).map(i => {
          console.log('level>>>>', level[i])
          domtoimage
            .toPng(level[i])
            .then(function(dataUrl) {
              setImgData(temp => {
                return {
                  ...temp,
                  [i]: dataUrl,
                  title,
                }
              })
            })
            .catch(function(error) {
              console.error('oops, something went wrong!', error)
            })
          return false
        })
        setPdfLoading(data.length === 4 && false)
      }
    } else {
      domtoimage
        .toPng(input)
        .then(function(dataUrl) {
          setImgData(temp => {
            return {
              ...temp,
              img: dataUrl,
              title,
            }
          })
          setPdfLoading(false)
        })
        .catch(function(error) {
          console.error('oops, something went wrong!', error)
        })
    }
    // const ff = html2canvas(input).then(canvas => {
    //   return canvas.toDataURL('image/png')
    // })
    // ff.then(res => {})
    // tempObj.push(ff)
    // handler.push(res => {
    //   setImgData(temp => {
    //     return {
    //       ...temp,
    //       img: res,
    //       title,
    //     }
    //   })
    // })
    // Promise.all(tempObj).then(values => {
    //   values.forEach((item, index) => {
    //     handler[index](item)
    //   })
    //   setPdfLoading(false)
    // })
  }
  const exportPDF = () => {
    setPDFDrawer(true)

    // html2canvas(input).then(canvas => {
    // const imgData1 = canvas.toDataURL('image')
    // const pdf = new JsPDF({
    //   format: 'tabloid',
    // })
    // const imgProps = pdf.getImageProperties(imgData1)
    // const pdfWidth = pdf.internal.pageSize.getWidth()
    // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
    // pdf.addImage(imgData1, 'PNG', 0, 10, pdfWidth, pdfHeight)
    // pdf.save(`vbmapp-${activeTab}.pdf`)
    // })
    // setPDFDrawer(true)
  }

  const exportButton = (
    <Tooltip title="Preparing PDF" visible={pdfLoading || !imgData.img || dataLoading}>
      <Button
        type="link"
        size="large"
        onClick={exportPDF}
        disabled={pdfLoading || !imgData.img || dataLoading}
        loading={pdfLoading || !imgData.img || dataLoading}
        style={{
          marginRight: '8px',
          fontWeight: 'bold',
          padding: '0 8px',
        }}
      >
        <FaDownload fontSize="22px" />
      </Button>
    </Tooltip>
  )

  const filterCardStyle = {
    background: '#F1F1F1',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 5,
    margin: 0,
    height: 'fit-content',
    overflow: 'hidden',
    backgroundColor: COLORS.palleteLight,
  }

  const reload = () => {
    refetchData()
    loadReportData({
      variables: {
        assessmentId: selectedAssessmentId,
        milestonesArea: milestonesAreaId,
        barriersArea: barriersAreaId,
        transitionAssessmentArea: transitionAssessmentAreaId,
        taskAnalysisArea: taskAnalysisAreaId,
      },
    })
  }

  if (isVbmappAreaLoading) return <LoadingComponent />
  if (vbmappAreaErrors) return <p>{vbmappAreaErrors}</p>

  return (
    <div className="vbmappReport">
      {/* {!lastAssessment && (
        <div style={{ ...filterCardStyle, backgroundColor: 'white' }}>
          <AssessmentDropDown
            onSelectionChange={handleAssesmentChange}
            assessmentData={assessmentData}
            isAssessmentLoading={isAssessmentLoading}
            selectedAssessment={selectedAssessmentId}
          />
        </div>
      )} */}

      {isVbmappReportLoading && <LoadingComponent />}
      {!isVbmappReportLoading &&
        !isAssessmentLoading &&
        assessmentData &&
        assessmentData.vbmappGetAssessments.edges.length === 0 && (
          <div style={{ textAlign: 'center' }}>
            <p>No assessments are found, Please create your first VBMAPP assessment!!</p>
          </div>
        )}
      {!isVbmappReportLoading &&
        assessmentData &&
        assessmentData.vbmappGetAssessments.edges.length > 0 &&
        structuredReportData && (
          <Tabs
            defaultActiveKey={activeTab}
            type="card"
            className="vbmappReportTabs"
            tabBarExtraContent={exportButton}
            onChange={setActiveTab}
            style={{ backgroundColor: 'white' }}
          >
            {milestonesAreaId && structuredReportData && (
              <TabPane tab="Milestones" key="milestones">
                <MilestonesTab
                  data={structuredReportData.milestonesData}
                  refetchvbmapp={refetchvbmapp}
                  areaId={milestonesAreaId}
                  selectedAssessment={selectedAssessmentId}
                  onSelectionChange={id => handleAssesmentChange(id)}
                  refetch={() => {
                    reload()
                  }}
                />
              </TabPane>
            )}
            {barriersAreaId && structuredReportData && (
              <TabPane tab="Barriers" key="barriers">
                <BarriersTab
                  data={structuredReportData.barriersData}
                  onSelectionChange={id => handleAssesmentChange(id)}
                  refetch={() => {
                    reload()
                  }}
                  areaId={barriersAreaId}
                />
              </TabPane>
            )}
            {transitionAssessmentAreaId && structuredReportData && (
              <TabPane tab="Transition Assessment" key="transitionAssessment">
                <TransitionAssessmentTab
                  data={structuredReportData.transitionsData}
                  onSelectionChange={id => handleAssesmentChange(id)}
                  refetch={() => {
                    reload()
                  }}
                  areaId={transitionAssessmentAreaId}
                />
              </TabPane>
            )}
            {taskAnalysisAreaId && structuredReportData && (
              <TabPane tab="Task Analysis" key="taskAnalysis">
                <TaskAnalysisTab
                  data={structuredReportData.tasksData}
                  onSelectionChange={id => handleAssesmentChange(id)}
                  refetch={() => {
                    reload()
                  }}
                  areaId={taskAnalysisAreaId}
                  selectedAssessment={selectedAssessmentId}
                />
              </TabPane>
            )}
          </Tabs>
        )}
      <Modal
        title={`${studentName}'s VBMAPP Report`}
        visible={pdfDrawer}
        onOk={() => {
          setPDFDrawer(false)
        }}
        onCancel={() => {
          setPDFDrawer(false)
        }}
        okText=""
        cancelText="Cancel"
        footer={[
          <Button
            key="back"
            onClick={() => {
              setPDFDrawer(false)
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <VBMappReportPDF studentId={selectedStudentId} image={imgData} />
      </Modal>
      {/* <Drawer
        title={`${studentName}'s VBMAPP Report`}
        placement="right"
        closable="true"
        onClose={() => {
          setPDFDrawer(false)
        }}
        visible={pdfDrawer}
        width={DRAWER.widthL2}
      >
        <VBMappReportPDF studentId={selectedStudentId} image={imgData} />
      </Drawer> */}
    </div>
  )
}
const temp = props => {
  return (
    <LoadingContextProvider>
      <VBMappReport {...props} />
    </LoadingContextProvider>
  )
}
export default temp
