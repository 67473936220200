import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

const HeaderTitle = ({ showBackButton, title, onBackClick }) => {
  return (
    <>
      {showBackButton && (
        <motion.span
          initial={{ opacity: 0, x: -20 }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          exit={{ opacity: 0, x: -10 }}
          style={{ display: 'inline-block' }}
        >
          <Button type="link" onClick={onBackClick} style={{ padding: 0, marginRight: '1rem' }}>
            <ArrowLeftOutlined style={{ fontSize: '1.5rem', color: 'black' }} />
          </Button>
        </motion.span>
      )}
      <motion.span style={{ display: 'inline-block' }}>{title}</motion.span>
    </>
  )
}

export default HeaderTitle
