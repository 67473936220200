// import { GraphQLClient } from 'graphql-request'
/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */

import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

export async function clinicDetails() {
  return apolloClient
    .query({
      query: gql`
        {
          schoolDetail {
            id
            schoolName
            address
            email
            mlSimilarity
            mlGraph
            mlRecommendation
            country {
              id
              name
            }
            user {
              id
            }
            country {
              name
            }
            schoolMail {
              parentMail
              staffMail
            }
            logo
            currency {
              id
              currency
              symbol
            }
            gst
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.error(error)
    })
}

export async function updateMl(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation(
          $userId: ID!
          $mlSimilarity: Boolean
          $mlGraph: Boolean
          $mlRecommendation: Boolean
        ) {
          changeUserSetting(
            input: {
              user: $userId
              mlSimilarity: $mlSimilarity
              mlRecommendation: $mlRecommendation
              mlGraph: $mlGraph
            }
          ) {
            details {
              id
              mlSimilarity
              mlRecommendation
              mlGraph
              user {
                id
              }
            }
          }
        }
      `,
      variables: {
        userId: payload.userId,
        mlSimilarity: payload.mlSimilarity,
        mlGraph: payload.mlGraph,
        mlRecommendation: payload.mlRecommendation,
      },
    })
    .then(res => res)
    .catch(err => console.error(err))
}
