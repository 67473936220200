// import { GraphQLClient } from 'graphql-request'
/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */

import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

export async function getStudentDetails(payload) {
  const std = localStorage.getItem('studentId')
  let stdId
  if (std) {
    stdId = JSON.parse(std)
  } else {
    stdId = payload?.student
    if (stdId) {
      localStorage.setItem('studentId', JSON.stringify(stdId))
    }
  }

  return apolloClient
    .query({
      query: gql`
        query($student: ID!) {
          student(id: $student) {
            id
            firstname
            mobileno
            email
            lastname
            dob
            image
            gender
            isPeakActive
            isCogActive
            allergicTo {
              edges {
                node {
                  id
                  name
                }
              }
            }
            parent {
              id
              username
            }
            school {
              id
              schoolName
              logo
              address
            }
            tags {
              edges {
                node {
                  id
                  name
                }
              }
            }
            caseManager {
              id
              name
              email
              contactNo
            }
            category {
              id
              category
            }
            programArea(isActive: true) {
              edges {
                node {
                  id
                  name
                  isActive
                }
              }
            }
            family {
              id
              members {
                edges {
                  node {
                    id
                    memberName
                    relationship {
                      id
                      name
                    }
                  }
                }
              }
            }
            email
            parentMobile
            targetAllocateSet {
              edgeCount
            }
            admissionDate
          }
        }
      `,
      variables: {
        student: stdId,
      },
    })
    .then(result => result)
    .catch(error => {
      console.log('Learner Details error ===> ', error)
    })
}

export async function getLearnerSessions(payload) {
  return apolloClient
    .query({
      query: gql`{ 
        getChildSession(sessions_Student:"${payload.studentId}") {
          edges {
            node {
              id
              status
              sessions {
                id
                itemRequired
                duration
                sessionName {
                  id
                  name
                }
                targets {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        GetStudentSession(studentId:"${payload.studentId}") {
          edges {
            node {
              id
              name
              itemRequired
              duration
              sessionName {
                id
                name
              }
              instruction {
                edges {
                  node {
                    id
                    instruction
                  }
                }
              }
              childsessionSet {
                edges {
                  node {
                    id
                    status
                    duration
                  }
                }
              }
              sessionHost {
                edges {
                  node {
                    id
                    memberName
                    relationship {
                      id
                      name
                    }
                  }
                }
              }
              targets {
                edges {
                  node {
                    id                  
                  }
                }
              }
            }
          }
        }
      }`,
    })
    .then(result => result)
    .catch(error => {
      console.log('Learner Sessions error ===> ', error)
    })
}
