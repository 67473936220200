import React, { useEffect, useState } from 'react'

const clr1 = [
  '#7C63D9',
  '#439AE0',
  '#BF4E99',
  '#F9A437',
  '#4CBB9A',
  '#90ED7D',
  '#B3B2B2',
  '#7CB5EC',
  '#439AE0',
  '#E2C033',
  '#91C7BA',
  '#91C7BA',
  '#4CBB9A',
  '#439AE0',
]
function ProgBar({ targetData }) {
  const [renderData, setRenderData] = useState(null)

  useEffect(() => {
    if (targetData && targetData.length > 0) {
      const temp = []
      let total = 0
      targetData.forEach(item => {
        total += Number(item.value)
      })

      console.log(total)

      targetData.forEach((item, index) => {
        temp.push({
          key: Math.random(),
          width: Number((Number(item.value) / total) * 95).toFixed(2),
          text: item.text,
          value: item.value,
          color: clr1[index],
        })
      })

      setRenderData(temp)
    }
  }, [targetData])

  return (
    <div>
      <div style={{ display: 'flex', marginTop: 12 }}>
        {renderData &&
          renderData.map(item => {
            return (
              <div
                key={item.key}
                style={{
                  width: `${item.width}%`,
                  marginRight: 2,
                  height: 6,
                  backgroundColor: item.color,
                }}
              />
            )
          })}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {renderData &&
          renderData.map(item => {
            return (
              <span
                key={item.key}
                style={{
                  marginTop: 10,
                  borderLeft: `5px solid ${item.color}`,
                  padding: '0',
                  lineHeight: '1.18',
                  height: 14,
                  paddingLeft: 4,
                  marginRight: 16,
                }}
              >
                {item.value} {item.text}
              </span>
            )
          })}
      </div>
    </div>
  )
}

export default ProgBar
