/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const SCHOOL_DETAIL = gql`
  query {
    schoolDetail {
      logo
      schoolName
      address
    }
  }
`
export const GET_DATE_RANGE_SETTINGS = gql`
  query($user: ID) {
    getDateRangeSettings(user: $user) {
      edges {
        node {
          id
          clinic {
            id
          }
          therapist {
            id
          }
          rangeTitle
          rangeDescription
          rangeDuration
          rangeUnit
          isCustomRange
          customGte
          customLte
        }
      }
    }
  }
`
export const RESPONSE_RATE = gql`
  query($startDate: Date!, $endDate: Date!, $studentId: ID!) {
    responseRate(studentId: $studentId, dateGte: $startDate, dateLte: $endDate) {
      targetId
      targetName
      targetStatusName
      targetType
      perTar
      perPeakCorrect
      perPeakPrompt
      perPeakError
      sessionDate
      totalSbtIncorrect
      r1SbtIncorrect
      r2SbtIncorrect
      r1Name
      r2Name
      target {
        time
        date
        lastUpdatedBy {
          id
          firstName
          lastName
        }
        createdAt
        sbtSteps {
          edges {
            node {
              id
              description
              status {
                id
                statusName
              }
            }
          }
        }
        mastery {
          edges {
            node {
              id
              sd {
                id
                sd
              }
              step {
                id
                step
              }
              status {
                id
                statusName
              }
              criteriaPhase {
                id
                criteriaType {
                  id
                  statusName
                }
                stepNo
                # criteriaphasetargetsSet {
                #   edges {
                #     node {
                #       id
                #       calculationType
                #       percentage
                #       calFrameType
                #       calFrameDurationType
                #       calFrameDurationValue
                #       minimumTrial
                #       duration
                #       frequency
                #       progressTo
                #       phaseChangeLabel
                #     }
                #   }
                # }
              }
            }
          }
        }
      }
      sessionRecord {
        perSd
        sd
        step
        perStep
      }
      behRecording {
        start
        end
        frequency
      }
      sbtStepsRecording {
        sbtStepDescription
        perStepTotalIncorrect
        perStepR1
        perStepR2
      }
    }
  }
`
export const STUDENT_QUERY = gql`
  query StudentDetails($studentId: ID!) {
    student(id: $studentId) {
      id
      firstname
      lastname
      currentAddress
      dob
      email
      parentMobile
      createdAt
    }
  }
`

export const RESPONSE_RATE_EQUI = gql`
  query($startDate: Date!, $endDate: Date!, $studentId: ID!, $equivalence: Boolean) {
    responseRate(
      studentId: $studentId
      dateGte: $startDate
      dateLte: $endDate
      equivalence: $equivalence
    ) {
      sessionDate
      targetName
      targetStatusName
      targetType
      targetId
    }
  }
`
// where: {exclude: ["U3RhdHVzVHlwZTo3", "U3RhdHVzVHlwZTo2"]}

export const RESPONSE_RATE_FILTER_OPT = gql`
  query {
    types {
      id
      typeTar
    }
    targetStatus {
      id
      statusName
    }
  }
`

export const TARGET_PHASE = gql`
  query($id: ID!) {
    targetAllocate(id: $id) {
      id
      date
      phaset {
        edges {
          node {
            id
            date
            content
          }
        }
      }
      baselineDate
      intherapyDate
      masterDate
      inmaintainenceDate

      targetStatus {
        id
        statusName
      }
      targetAllcatedDetails {
        id
        targetName
        dateBaseline
      }
    }
  }
`

export const MAND_DATA = gql`
  query($studentId: ID!, $startDate: Date!, $endDate: Date!) {
    mandReport(studentId: $studentId, startDate: $startDate, endDate: $endDate) {
      id
      measurments
      data {
        date
        count
      }
    }
  }
`

export const GET_STUDENT = gql`
  query($id: ID!) {
    student(id: $id) {
      id
      firstname
      lastname
    }
  }
`

export const MEDICAL_DATA = gql`
  query($start: Date!, $end: Date!) {
    getMedication(date_Gte: $start, date_Lte: $end) {
      edges {
        node {
          id
          severity {
            id
            name
          }
          date
          lastObservedDate
          condition
          startDate
          endDate
          duration
          drug {
            edges {
              node {
                id
                drugName
                dosage
                times
              }
            }
          }
        }
      }
    }
  }
`

export const TOILET_DATA = gql`
  query {
    getToiletData {
      edges {
        node {
          id
          date
          time
          lastWater
          lastWaterTime
          success
          urination
          bowel
          prompted
          reminders {
            edges {
              node {
                id
                time
                frequency {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const MEAL_DATA = gql`
  query($type: String, $start: Date!, $end: Date!) {
    getFood(mealType: $type, date_Gte: $start, date_Lte: $end) {
      edges {
        node {
          id
          date
          mealName
          mealTime
          mealType
          servingSize
          calories
          waterIntake
          foodType {
            id
            name
          }
          note
          duration
        }
      }
    }
  }
`

export const FOOD_TYPE = gql`
  query {
    getFoodType {
      id
      name
    }
  }
`

export const GET_STUDENT_SESSION_NAME = gql`
  query($studentId: ID!) {
    getActiveSessionNames(studentId: $studentId) {
      id
      name
    }
  }
`

export const SESSION_DATA = gql`
  query($targets_StudentId: ID, $date_Gte: Date, $date_Lte: Date, $session: ID) {
    getSessionDataRecording(
      targets_StudentId: $targets_StudentId
      date_Gte: $date_Gte
      date_Lte: $date_Lte
      ChildSession_Sessions_SessionName: $session
    ) {
      edges {
        node {
          id
          targets {
            id
            peakType
            targetAllcatedDetails {
              id
              targetName
              targetType {
                id
                typeTar
              }
            }
          }
          ChildSession {
            id
            sessionDate
            createdAt
            duration
            feedback
            rating
            sessionNo
            sessions {
              id
              name
              sessionName {
                id
                name
              }
            }
          }
          sessionRecord {
            edges {
              node {
                entryTime
                durationStart
                durationEnd
                sd {
                  id
                  sd
                }
                step {
                  id
                  step
                }
                trial
              }
            }
            totalCorrect
            totalIncorrect
            totalError
            totalPrompt
            totalTrial
            totalNr
            physical
            verbal
            gestural
            textual
          }
          peak {
            totalCorrect
            totalError
            totalPrompt
            totalTrial
            edges {
              node {
                id
                durationStart
                durationEnd
                trial {
                  edges {
                    node {
                      id
                      start
                      end
                      sd {
                        id
                        sd
                      }
                      marks
                    }
                  }
                }
              }
            }
          }

          peakEquivalance {
            totalCorrect
            totalError
            totalPrompt
            totalTrial
            edges {
              node {
                id
                score
                durationStart
                durationEnd
                relationTest {
                  id
                  stimulus1
                  sign12
                  stimulus2
                  sign23
                  stimulus3
                }
                codeClass {
                  id
                  name
                  stimuluses {
                    edges {
                      node {
                        id
                        stimulusName
                        option
                      }
                    }
                  }
                }
              }
            }
          }

          durationStart
          durationEnd
          isPeak
          isPeakEquivalance
          isBehReduction
          behReduction {
            totalCorrect
            totalIncorrect
            totalError
            totalPrompt
            totalTrial
            r1
            r2
            physical
            verbal
            gestural
            textual
            edges {
              node {
                id
                trial
                duration
                sbtStep {
                  id
                  description
                }
              }
            }
          }
          totalBehRecordingDuration
          isBehRecording
          behaviourRecording {
            edges {
              node {
                id
              }
            }
          }
        }
      }
      totalTarget
      edgeCount
      perCorrect
      perError
      perPrompt
      mandCount
      behCount
      toiletData {
        id
        time
        date
      }
      behData {
        id
        date
      }
      mandData {
        id
        data
        promptedCount
        date
      }
    }
  }
`

export const DECEL_DATA = gql`
  query($targets_StudentId: ID, $date_Gte: Date, $date_Lte: Date, $session: ID) {
    getDecelData(
      template_Student: $targets_StudentId
      date_Gte: $date_Gte
      date_Lte: $date_Lte
      session: $session
    ) {
      edges {
        node {
          id
          date
          createdAt
          duration
          template {
            id
            behavior {
              id
              time
              behaviorName
              definition
            }
          }
          session {
            id
            sessionDate
            duration
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const GET_MAND = gql`
  query($targets_StudentId: ID, $date_Gte: Date, $date_Lte: Date, $session: ID) {
    getMandData(
      dailyClick_Student: $targets_StudentId
      dateGte: $date_Gte
      dateLte: $date_Lte
      session: $session
    ) {
      edges {
        node {
          id
          data
          date
          dailyClick {
            id
            measurments
          }
          session {
            id
            sessionDate
            duration
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const TARGET_ALLOCATIONS_OPTIONS = gql`
  query {
    targetStatus {
      id
      statusName
    }
    types {
      id
      typeTar
    }
    promptCodes {
      id
      promptName
    }
    masteryCriteria {
      id
      name
      masteryType
    }
    domain {
      edges {
        node {
          id
          domain
        }
      }
    }
    goalsProgramArea {
      id
      name
    }
  }
`

export const SESSION_TOILET_DATA = gql`
  query($targets_StudentId: ID, $date_Gte: Date, $date_Lte: Date, $session: ID) {
    getToiletData(
      student: $targets_StudentId
      date_Lte: $date_Lte
      date_Gte: $date_Gte
      session: $session
    ) {
      edges {
        node {
          id
          date
          session {
            id
            sessionDate
            duration
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const SESSIONS_SUMMERY = gql`
  query($studentId: ID!, $startDate: Date!, $endDate: Date!) {
    sessionSummary(studentId: $studentId, dateGte: $startDate, dateLte: $endDate) {
      sessionDate
      peakEquCorrect
      peakEquError
      peakEquPrompt
      duration
      correctCount
      errorCount
      promptCount
      peakCorrect
      peakError
      peakPrompt
      behCount
      behaviour
      toiletCount
      toilet
      mand
      id
      sessions {
        id
        sessionName {
          id
          name
        }
      }
    }
  }
`

export const FREQUENCY_DIS_TARGET = gql`
  query($student: ID!, $session: ID!) {
    freqDistriTarget(student: $student, session: $session, duration: 1000) {
      duration
      tarCount
      behRed
    }
  }
`
export const GOAL_STATUS = gql`
  query($studentId: ID!) {
    goalStatus(student: $studentId) {
      id
      status
    }
  }
`

export const ATTANDANCE = gql`
  query($dateGte: Date!, $dateLte: Date!, $therapist: ID!) {
    attendanceReport(dateGte: $dateGte, dateLte: $dateLte, therapist: $therapist) {
      date
      hours
    }
  }
`

export const SESSION_NAME = gql`
  query {
    sessionName {
      id
      name
    }
  }
`

export const TARGET_ALLOCATE = gql`
  query($id: ID!) {
    targetAllocate(id: $id) {
      id
      time
      targetInstr
      date
      targetStatus {
        id
        statusName
      }
      targetId {
        id
        domain {
          id
          domain
        }
      }
      targetAllcatedDetails {
        id
        targetName
        dateBaseline
        DailyTrials
        consecutiveDays
        targetType {
          id
          typeTar
        }
      }
      videos {
        edges {
          node {
            id
            url
          }
        }
      }
      sd {
        edges {
          node {
            id
            sd
          }
        }
      }
      steps {
        edges {
          node {
            id
            step
          }
        }
      }

      mastery {
        edges {
          node {
            id
            sd {
              id
              sd
            }
            step {
              id
              step
            }
            status {
              id
              statusName
            }
            mastery {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const PEAK_BLOCKWISE = gql`
  query($student: ID!, $start: Date, $end: Date, $sessionName: ID) {
    peakBlockWiseReport(student: $student, start: $start, end: $end, sessionName: $sessionName) {
      date
      target {
        id
        time
        targetStatus {
          id
          statusName
        }
        mastery {
          edges {
            node {
              id
              sd {
                id
                sd
              }
              step {
                id
                step
              }
              status {
                id
                statusName
              }
              mastery {
                id
                name
              }
            }
          }
        }
        targetAllcatedDetails {
          id
          targetName
          dateBaseline
        }
        peakType
      }
      blocks {
        id
        totalScore
        trial {
          edges {
            node {
              id
              marks
              sd {
                id
                sd
                sdstepsmasterySet {
                  edges {
                    node {
                      status {
                        id
                        statusName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const PEAK_EQUIVALENCE = gql`
  query($student: ID!, $start: Date, $end: Date, $sessionName: ID, $equivalence: Boolean) {
    peakBlockWiseReport(
      student: $student
      start: $start
      end: $end
      sessionName: $sessionName
      equivalence: $equivalence
    ) {
      date
      target {
        id
        targetStatus {
          id
          statusName
        }
        targetAllcatedDetails {
          id
          targetName
        }
      }
      equBlocks {
        score
        recType
        relationTrain {
          id
          stimulus1
          sign12
          stimulus2
          sign23
          stimulus3
        }
        relationTest {
          id
          stimulus1
          sign12
          stimulus2
          sign23
          stimulus3
        }
        codeClass {
          id
          name
        }
        id
      }
    }
  }
`

export const BLOCKWISE_DETAIL = gql`
  query {
    getPeakBlockDetails(id: "UGVha0Jsb2Nrc1R5cGU6MTIyMw==") {
      id
      durationStart
      durationEnd
      entryTime
      trial {
        edges {
          node {
            id
            marks
            sd {
              id
              sd
            }
          }
        }
      }
    }
  }
`

export const TARGET_RESPONSE_RATE = gql`
  query($student: ID!, $start: Date, $end: Date, $sessionName: ID) {
    targetWiseReportDatewise(
      student: $student
      start: $start
      end: $end
      sessionName: $sessionName
    ) {
      date
      target {
        id
        targetAllcatedDetails {
          id
          targetName
          targetType {
            id
            typeTar
          }
        }
        peakBlocks
        peakType
      }
      session {
        id
        sessionNo
        sessionDate
        sessions {
          id
          sessionName {
            id
            name
          }
        }
      }
      blocks {
        id
        entryTime
        durationStart
        durationEnd
        totalScore
        trial {
          edges {
            node {
              id
              start
              end
              marks
              sd {
                id
                sd
              }
            }
          }
        }
      }
      trials {
        id
        durationStart
        durationEnd
        trial
        sd {
          id
          sd
        }
        step {
          id
          step
        }
        promptCode {
          id
          promptName
        }
      }
    }
  }
`

export const TARGET_EQUI_RESPONSE_RATE = gql`
  query($student: ID!, $start: Date, $end: Date, $sessionName: ID, $equivalence: Boolean) {
    targetWiseReportDatewise(
      student: $student
      start: $start
      end: $end
      sessionName: $sessionName
      equivalence: $equivalence
    ) {
      date
      target {
        id
        targetAllcatedDetails {
          id
          targetName
        }
        peakType
        phaset {
          edges {
            node {
              id
              date
              content
            }
          }
        }
      }
      equBlocks {
        durationStart
        durationEnd
        recType
        score
        relationTrain {
          id
          stimulus1
          sign12
          stimulus2
          sign23
          stimulus3
        }
        relationTest {
          id
          stimulus1
          sign12
          stimulus2
          sign23
          stimulus3
        }
        codeClass {
          id
          name
        }
        id
      }
    }
  }
`

export const GET_TEMPLATES = gql`
  query getBehaviorTemplates($studentId: ID!) {
    getBehaviorTemplates(student: $studentId) {
      edges {
        node {
          id
          behaviorType
          behavior {
            id
            behaviorName
            definition
          }
          createdAt
          status {
            id
            statusName
          }
          student {
            id
          }
          description
        }
      }
    }
  }
`

export const BEHAVIOR_RECORD_DATA = gql`
  query getDecelData($dateGte: Date!, $dateLte: Date!, $studentId: ID!, $status: ID) {
    getDecelData(
      template_Student: $studentId
      date_Gte: $dateGte
      date_Lte: $dateLte
      status: $status
    ) {
      edges {
        node {
          id
          irt
          note
          duration
          date
          intensity
          environment {
            id
          }
          frequency {
            edges {
              node {
                id
                count
                time
              }
            }
          }
          template {
            id
            behavior {
              id
              behaviorName
            }
            environment {
              edges {
                node {
                  id
                  name
                }
              }
            }
            measurments {
              edges {
                node {
                  id
                  measuringType
                }
              }
            }
          }
          status {
            id
            statusName
          }
        }
      }
    }
  }
`

export const GET_BEHAVIOR_TEMPLATES = gql`
  query getBehaviorTemplates($student: ID!) {
    getBehaviorTemplates(student: $student) {
      edges {
        node {
          id
          behaviorType
          behavior {
            id
            behaviorName
            definition
          }
          createdAt
          status {
            id
            statusName
          }
          student {
            id
          }
          description
          frequencyratebehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                duration
                frequency
                rate
              }
            }
          }
          durationbehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                duration
              }
            }
          }
          latencybehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                latency
              }
            }
          }
          partialintervalbehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                intervalLength
                intervals
                frequency
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
          wholeintervalbehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                intervalLength
                intervals
                frequency
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
          momentarytimebehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                intervalLength
                intervals
                frequency
                rangeMin
                rangeMax
                recordingType
                intervalChecks
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_TARGET_ALLOCATE = gql`
  query($id: ID!) {
    targetAllocate(id: $id) {
      id
      date
      peakType
      r1 {
        id
        behaviorName
      }
      r2 {
        id
        behaviorName
      }
      sd {
        edges {
          node {
            id
            sd
          }
        }
      }
      steps {
        edges {
          node {
            id
            step
          }
        }
      }
      sbtSteps {
        edges {
          node {
            id
            description

            status {
              id
              statusName
            }
          }
        }
      }
      targetStatus {
        id
        statusName
      }
      peakBlocks
      peakType
      classes {
        edges {
          node {
            id
            name
            stimuluses {
              edges {
                node {
                  id
                  option
                  stimulusName
                }
              }
            }
          }
        }
      }
      eqCode
      targetAllcatedDetails {
        id
        targetName
        targetType {
          id
          typeTar
        }
      }
    }
  }
`

export const GET_SESSION_RECORDINGS = gql`
  query(
    $targets: ID
    $sessiondate: Date
    $ChildSession: ID
    $ChildSession_Sessions: ID
    $ChildSession_Sessions_SessionName: ID
  ) {
    getSessionRecordings(
      targets: $targets
      sessiondate: $sessiondate
      ChildSession: $ChildSession
      ChildSession_Sessions: $ChildSession_Sessions
      ChildSession_Sessions_SessionName: $ChildSession_Sessions_SessionName
    ) {
      edges {
        node {
          id
          durationStart
          durationEnd
          ChildSession {
            id
            sessionDate
            status
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
          targets {
            id
            targetAllcatedDetails {
              id
              targetName
            }
          }
          status {
            id
            statusName
          }
          isPeak
          isPeakEquivalance
          peakEquivalance {
            edges {
              node {
                id
                durationStart
                durationEnd
                recType
                score
                codeClass {
                  id
                  name
                }
                relationTrain {
                  id
                  stimulus1
                  sign12
                  stimulus2
                }
                relationTest {
                  id
                  stimulus1
                  sign12
                  stimulus2
                }
              }
            }
          }
          peak {
            edges {
              node {
                id
                durationStart
                durationEnd
                trial {
                  edges {
                    node {
                      id
                      start
                      end
                      sd {
                        id
                        sd
                      }
                      marks
                      promptCode {
                        id
                        promptName
                      }
                    }
                  }
                }
              }
            }
          }
          sessionRecord {
            edges {
              node {
                id
                entryTime
                trial
                durationStart
                durationEnd
                text
                sd {
                  id
                  sd
                }
                step {
                  id
                  step
                }
                promptCode {
                  id
                  promptName
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_SESSION_RECORDINGS_IN_RANGE = gql`
  query($targets: ID, $date_Gte: Date, $date_Lte: Date) {
    getSessionDataRecording(targets: $targets, date_Gte: $date_Gte, date_Lte: $date_Lte) {
      edges {
        node {
          id
          durationStart
          durationEnd
          ChildSession {
            id
            sessionDate
            status
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
          targets {
            id
            peakType
            targetAllcatedDetails {
              id
              targetName
              targetType {
                id
                typeTar
              }
            }
          }
          status {
            id
            statusName
          }
          isPeak
          isPeakEquivalance
          peakEquivalance {
            edges {
              node {
                id
                durationStart
                durationEnd
                recType
                score
                codeClass {
                  id
                  name
                }
                relationTrain {
                  id
                  stimulus1
                  sign12
                  stimulus2
                }
                relationTest {
                  id
                  stimulus1
                  sign12
                  stimulus2
                }
              }
            }
          }
          peak {
            edges {
              node {
                id
                durationStart
                durationEnd
                trial {
                  edges {
                    node {
                      id
                      start
                      end
                      sd {
                        id
                        sd
                      }
                      marks
                      promptCode {
                        id
                        promptName
                      }
                    }
                  }
                }
              }
            }
          }
          sessionRecord {
            edges {
              node {
                id
                entryTime
                trial
                durationStart
                durationEnd
                text
                sd {
                  id
                  sd
                }
                step {
                  id
                  step
                }
                promptCode {
                  id
                  promptName
                }
              }
            }
          }
          isBehReduction
          behReduction {
            totalCorrect
            totalIncorrect
            r1
            r2
          }

          totalBehRecordingDuration
          isBehRecording
          behaviourRecording {
            edges {
              node {
                frequency
                start
                end
              }
            }
          }
        }
      }
    }
  }
`

export const GET_BEHAVIOR_SESSION_RECORDINGS = gql`
  query($targets: ID, $sessiondate: Date) {
    getSessionRecordings(targets: $targets, sessiondate: $sessiondate) {
      edges {
        node {
          id
          durationStart
          durationEnd
          ChildSession {
            id
            sessionDate
            status
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
          targets {
            id
          }
          status {
            id
            statusName
          }
          isBehRecording
          totalBehRecordingDuration
          behaviourRecording {
            edges {
              node {
                id
                frequency
                start
                end
                createdAt
                user {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

export const CREATE_SESSION_CHILD_ID = gql`
  mutation($id: ID!, $date: Date) {
    startSession(input: { parentSession: $id, date: $date, status: "progress", duration: 0 }) {
      details {
        id
        sessionDate
        status
        duration
        sessions {
          id
          sessionName {
            id
            name
          }
        }
      }
    }
  }
`

export const CREATE_SIGN_TH = gql`
  mutation($childSessionId: ID, $therapistId: ID, $studentId: ID) {
    createSignDocument(
      input: {
        signDocData: {
          childSessionId: $childSessionId
          therapistId: $therapistId
          studentId: $studentId
        }
      }
    ) {
      message
      signedDoc {
        id
        childSession {
          id
          sessionDate
        }
        student {
          id
          firstname
          lastname
          signature
        }
        therapist {
          id
          name
          surname
          signature
        }
        isSignedByStudent
        isSignedByTherapist
        dateByTherapist
        dateByStudent
        createdAt
        updatedAt
      }
    }
  }
`

export const UPDATE_SIGN_TH = gql`
  mutation($id: ID, $therapistId: ID, $studentId: ID) {
    updateSignDocument(
      input: { signDocData: { id: $id, therapistId: $therapistId, studentId: $studentId } }
    ) {
      message
      signedDoc {
        id
        childSession {
          id
          sessionDate
        }
        student {
          id
          firstname
          lastname
          signature
        }
        therapist {
          id
          name
          surname
          signature
        }
        isSignedByStudent
        isSignedByTherapist
        dateByTherapist
        dateByStudent
        createdAt
        updatedAt
      }
    }
  }
`

export const UPDATE_LEARNER_SIGN = gql`
  mutation($childId: ID!, $signImg: String, $latitude: String, $longitude: String) {
    updateSessionFeedbacks(
      input: {
        pk: $childId
        caregiverSignature: { signature: $signImg, latitude: $latitude, longitude: $longitude }
      }
    ) {
      details {
        id
        caregiverSignature {
          signature
          latitude
          longitude
          createdAt
          user {
            firstName
          }
        }
      }
    }
  }
`
export const UPDATE_THERAPIST_SIGN = gql`
  mutation($childId: ID!, $signImg: String, $latitude: String, $longitude: String) {
    updateSessionFeedbacks(
      input: {
        pk: $childId
        providerSignature: { signature: $signImg, latitude: $latitude, longitude: $longitude }
      }
    ) {
      details {
        id
        providerSignature {
          signature
          latitude
          longitude
          createdAt
          user {
            firstName
          }
        }
      }
    }
  }
`

export const GET_TODAYS_SESSION = gql`
  query($studentId: ID!, $date: Date) {
    GetStudentSession(studentId: $studentId, date: $date) {
      edges {
        node {
          id
          name
          itemRequired
          duration
          sessionName {
            id
            name
          }
          instruction {
            edges {
              node {
                id
                instruction
              }
            }
          }
          childsessionSet {
            edges {
              node {
                id
                status
              }
            }
          }
          sessionHost {
            edges {
              node {
                id
                memberName
                timeSpent {
                  edges {
                    node {
                      id
                      sessionName {
                        id
                        name
                      }
                      duration
                    }
                  }
                }
                relationship {
                  id
                  name
                }
              }
            }
          }
          targets {
            edges {
              node {
                id
                time
                targetInstr
                peakBlocks
                date
                sd {
                  edges {
                    node {
                      id
                      sd
                    }
                  }
                }
                steps {
                  edges {
                    node {
                      id
                      step
                    }
                  }
                }
                targetStatus {
                  id
                  statusName
                }
                mastery {
                  edges {
                    node {
                      id
                      sd {
                        id
                        sd
                      }
                      step {
                        id
                        step
                      }
                      mastery {
                        id
                        name
                      }
                      status {
                        id
                        statusName
                      }
                    }
                  }
                }
                targetAllcatedDetails {
                  id
                  targetName
                  DailyTrials
                  targetType {
                    id
                    typeTar
                  }

                  promptCodes {
                    edges {
                      node {
                        id
                        promptName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const IS_DOC_SIGNED = gql`
  query($childSessionId: ID!) {
    signdocByChildSessionId(childSessionId: $childSessionId) {
      id
      childSession {
        id
        sessionDate
      }
      student {
        id
        firstname
        lastname
        signature
      }
      therapist {
        id
        name
        surname
        signature
      }
      isSignedByStudent
      isSignedByTherapist
      dateByTherapist
      dateByStudent
      createdAt
      updatedAt
    }
  }
`
export const CREATE_BARCHART = gql`
  query domainMastered(
    $studentId: ID
    $start_date: Date
    $end_date: Date
    $selectedprogram: ID
    $statusselected: ID
    $activeDomain: Boolean
    $domainId: ID
  ) {
    domainMastered(
      studentId: $studentId
      dateGte: $start_date
      dateLte: $end_date
      programArea: $selectedprogram
      activeDomain: $activeDomain
      targetStatus: $statusselected
      domainId: $domainId
    ) {
      totalCount
      target {
        id
        domainName
        manualAllocateDomain {
          id
          domain
        }
        targetId {
          id
          domain {
            id
            domain
          }
        }
        targetAllcatedDetails {
          id
          targetName
          dateBaseline
        }
        intherapyDate
        masterDate
        inmaintainenceDate
      }
    }
  }
`

export const GET_STATUS = gql`
  query {
    targetStatus(first: 7) {
      id
      statusName
    }
  }
`
export const GET_PAREA_DOMAIN = gql`
  query {
    programArea(isActive: true) {
      edges {
        node {
          id
          name
        }
      }
    }
    domain(isActive: true) {
      edges {
        node {
          id
          domain
        }
      }
    }
  }
`

export const DOMAIN_MASTERED = gql`
  query domainMastered(
    $studentId: ID!
    $start_date: Date
    $end_date: Date
    $selectedprogram: ID
    $statusselected: ID
    $domainId: ID
  ) {
    domainMastered(
      studentId: $studentId
      dateGte: $start_date
      dateLte: $end_date
      activeDomain: true
      programArea: $selectedprogram
      targetStatus: $statusselected
      domainId: $domainId
    ) {
      totalCount
      target {
        id
        manualAllocateDomain {
          id
          domain
        }
        targetId {
          id
          domain {
            id
            domain
          }
        }
        sd {
          edges {
            node {
              id
              sd
            }
          }
        }
        steps {
          edges {
            node {
              id
              step
            }
          }
        }
        targetAllcatedDetails {
          id
          targetName
          dateBaseline
        }
        intherapyDate
        masterDate
      }
    }
  }
`
