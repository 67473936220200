/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_DATE_RANGE_SETTINGS = gql`
  query($user: ID) {
    getDateRangeSettings(user: $user) {
      edges {
        node {
          id
          clinic {
            id
          }
          therapist {
            id
          }
          rangeTitle
          rangeDescription
          rangeDuration
          rangeUnit
          isCustomRange
          customGte
          customLte
        }
      }
    }
  }
`

export const CREATE_CHART = gql`
  query createChart($start_date: Date, $end_date: Date, $dateGte: ID) {
    target_allocated: targetAllocates(date_Gte: $start_date, date_Lte: $end_date) {
      totalCount
    }
    mastered_targets: dashboardGraph(
      targetStatusId: "U3RhdHVzVHlwZTox"
      dateGte: $dateGte
      dateLte: $end_date
    ) {
      tarCount
    }
    inmaintenance_targets: dashboardGraph(
      targetStatusId: "U3RhdHVzVHlwZTo1"
      dateGte: $dateGte
      dateLte: $end_date
    ) {
      tarCount
    }
  }
`

export const DELETE_DATE_RANGE_SETTING = gql`
  mutation($pk: ID) {
    deleteDateRangeSetting(input: { pk: $pk }) {
      status
      message
    }
  }
`

export const GENERATE_QR_CODE = gql`
  mutation($clinicId: ID!) {
    generateScreeningQr(input: { clinicId: $clinicId }) {
      message
      status
    }
  }
`

export const CREATE_DATE_RANGE_SETTING = gql`
  mutation(
    $clinic: ID
    $therapist: ID
    $rangeTitle: String
    $rangeDescription: String
    $rangeDuration: Int
    $rangeUnit: String
    $isCustomRange: Boolean
    $customGte: DateTime
    $customLte: DateTime
  ) {
    createDateRangeSetting(
      input: {
        data: {
          clinic: $clinic
          therapist: $therapist
          rangeTitle: $rangeTitle
          rangeDescription: $rangeDescription
          rangeDuration: $rangeDuration
          rangeUnit: $rangeUnit
          isCustomRange: $isCustomRange
          customGte: $customGte
          customLte: $customLte
        }
      }
    ) {
      daterangeSetting {
        id
      }
    }
  }
`

export const UPDATE_DATE_RANGE_SETTING = gql`
  mutation(
    $pk: ID!
    $rangeTitle: String
    $rangeDescription: String
    $rangeDuration: Int
    $rangeUnit: String
    $isCustomRange: Boolean
    $customGte: DateTime
    $customLte: DateTime
  ) {
    updateDateRangeSetting(
      input: {
        pk: $pk
        data: {
          rangeTitle: $rangeTitle
          rangeDescription: $rangeDescription
          rangeDuration: $rangeDuration
          rangeUnit: $rangeUnit
          isCustomRange: $isCustomRange
          customGte: $customGte
          customLte: $customLte
        }
      }
    ) {
      daterangeSetting {
        id
      }
    }
  }
`
export const PROMPT_CODES = gql`
  query($isActive: Boolean) {
    promptCodes(isActive: $isActive) {
      id
      school {
        id
        schoolName
      }
      isActive
      promptName
    }
  }
`

export const UPDATE_PROMPT = gql`
  mutation updatePrompt($pk: ID!, $name: String!, $isActive: Boolean) {
    updatePrompt(input: { pk: $pk, name: $name, isActive: $isActive }) {
      promptCode {
        id
        promptName
        isActive
        school {
          id
          schoolName
        }
      }
    }
  }
`

export const CREATE_PROMPT = gql`
  mutation createPrompt($name: String!) {
    createPrompt(input: { name: $name }) {
      promptCode {
        id
        promptName
        school {
          id
          schoolName
        }
      }
    }
  }
`

export const USER_SETTINGS = gql`
  query UserSettings($id: ID!) {
    userSettings(user: $id) {
      edges {
        node {
          id
          default
          morning
          afternoon
          evening
          user {
            id
          }
        }
      }
    }
  }
`

export const UPDATE_DEFAULT_SESSION = gql`
  mutation updateDefaultSession($userId: ID!, $updatedValue: Boolean) {
    changeUserSetting(input: { user: $userId, default: $updatedValue }) {
      details {
        id
        default
        user {
          id
        }
      }
    }
  }
`

export const UPDATE_MORNING_SESSION = gql`
  mutation updateMorningSession($userId: ID!, $updatedValue: Boolean) {
    changeUserSetting(input: { user: $userId, morning: $updatedValue }) {
      details {
        id
        morning
        user {
          id
        }
      }
    }
  }
`

export const UPDATE_AFTERNOON_SESSION = gql`
  mutation updateAfternoonSession($userId: ID!, $updatedValue: Boolean) {
    changeUserSetting(input: { user: $userId, afternoon: $updatedValue }) {
      details {
        id
        afternoon
        user {
          id
        }
      }
    }
  }
`

export const UPDATE_EVENING_SESSION = gql`
  mutation updateEveningSession($userId: ID!, $updatedValue: Boolean) {
    changeUserSetting(input: { user: $userId, evening: $updatedValue }) {
      details {
        id
        evening
        user {
          id
        }
      }
    }
  }
`
export const SESSION_NAME = gql`
  query {
    sessionName {
      id
      name
    }
  }
`

export const CLINIC_QR = gql`
  query {
    getCouponQrCode {
      id
      qrCode
    }
  }
`

export const GET_LEARNER_SESSIONS = gql`
  query($studentId: ID!) {
    learnerSessionSettings(learnerId: $studentId) {
      learner {
        id
        email
      }
      hideSessions
      sessions {
        edges {
          node {
            id
            sessionName {
              id
              name
            }
            isActive
            inMainteinanceDays
            masterDays
          }
        }
      }
    }
  }
`

export const UPDATE_USER_SESSION_SETTING = gql`
  mutation($studentId: ID!, $sessions: [SessionsInputType]) {
    createUpdateLearnerSessionSetting(input: { learnerId: $studentId, sessions: $sessions }) {
      learnerSessionSettings {
        learner {
          id
          email
        }
        sessions {
          edges {
            node {
              id
              sessionName {
                id
                name
              }
              isActive
              inMainteinanceDays
              masterDays
            }
          }
        }
      }
    }
  }
`

export const GET_POSITIONS = gql`
  query getStaffPosition($isActive: Boolean) {
    getStaffPosition(isActive: $isActive) {
      id
      title
      isActive
    }
  }
`

export const ACTIVATE_STAFF_POSITION = gql`
  mutation activatePosition($pk: ID!, $isActive: Boolean!) {
    updateStaffPosition(input: { pk: $pk, isActive: $isActive }) {
      details {
        id
        title
        isActive
      }
    }
  }
`

export const CREATE_STAFF_POSITION = gql`
  mutation createStaffPosition($title: String!) {
    createStaffPosition(input: { title: $title }) {
      details {
        id
        title
      }
    }
  }
`

export const UPDATE_STAFF_POSITION = gql`
  mutation updateStaffPosition($pk: ID!, $title: String!) {
    updateStaffPosition(input: { pk: $pk, title: $title }) {
      details {
        id
        title
      }
    }
  }
`
export const SESSION_PREFERRED_INACTIVE_ITEM = gql`
  query sessionPreferredItems($student: ID) {
    sessionPreferredItems(student: $student, isActive: false) {
      edges {
        node {
          id
          item
          isActive
        }
      }
    }
  }
`
export const SESSION_PREFERRED_ITEM = gql`
  query sessionPreferredItems($student: ID) {
    sessionPreferredItems(student: $student) {
      edges {
        node {
          id
          item
          isActive
        }
      }
    }
  }
`
export const CREATE_SESSION_PREFERRED_ITEM = gql`
  mutation createSessionPreferredItem($studentId: ID!, $item: String!) {
    createSessionPreferredItem(input: { item: $item, studentId: $studentId }) {
      item {
        id
        item
        student {
          firstname
        }
        createdAt
        isActive
      }
    }
  }
`
export const UPDATE_SESSION_PREFERRED_ITEM = gql`
  mutation updateSessionPreferredItem(
    $id: ID!
    $studentId: ID!
    $item: String!
    $isActive: Boolean
  ) {
    updateSessionPreferredItem(
      input: { pk: $id, item: $item, studentId: $studentId, isActive: $isActive }
    ) {
      item {
        id
        item
        student {
          firstname
        }
        createdAt
        isActive
      }
    }
  }
`

export const CLINIC_MENTAL_HEALTH_CENTER = gql`
  query getClinicMentalHealthCenter($clinicID: ID!, $isActive: Boolean) {
    getClinicMentalHealthCenter(clinic: $clinicID, isActive: $isActive) {
      id
      centerName
      centerCode
      address
      isDefault
      clinic {
        id
        schoolName
      }
      isActive
    }
  }
`
export const CREATE_CENTER = gql`
  mutation createClinicMentalHealthCenter(
    $centerName: String!
    $address: String
    $centerCode: String!
  ) {
    createClinicMentalHealthCenter(
      input: { centerName: $centerName, address: $address, centerCode: $centerCode }
    ) {
      message
      status
      details {
        id
        centerName
        centerCode
        address
        clinic {
          schoolName
        }
        isActive
      }
    }
  }
`
export const UPDATE_CENTER = gql`
  mutation updateClinicMentalHealthCenter(
    $centerID: String!
    $centerName: String!
    $address: String
    $centerCode: String!
  ) {
    updateClinicMentalHealthCenter(
      input: { id: $centerID, centerName: $centerName, address: $address, centerCode: $centerCode }
    ) {
      message
      status
      details {
        id
        centerName
        centerCode
        address
        clinic {
          id
          schoolName
        }
        isActive
      }
    }
  }
`

export const ACTIVE_INACTIVE_CENTER = gql`
  mutation activeInactiveClinicMentalHealthCenter($centerID: String!, $isActive: Boolean!) {
    activeInactiveClinicMentalHealthCenter(input: { id: $centerID, isActive: $isActive }) {
      message
      status
      details {
        id
        centerName
        clinic {
          id
          schoolName
        }
        isActive
      }
    }
  }
`
export const LEARNER_SESSION_SHOW_HIDE = gql`
  mutation showOrHideLearnerSessions($studentId: ID!, $hideSessions: Boolean!) {
    showOrHideLearnerSessions(input: { learnerId: $studentId, hideSessions: $hideSessions }) {
      learnerSessionSettings {
        learner {
          id
          email
        }
        hideSessions
      }
    }
  }
`
export const ADD_UPDATE_MAND_INSTRUCTION = gql`
  mutation($studentId: ID!, $mandInstruction: String, $sessions: [SessionsInputType]) {
    createUpdateLearnerSessionSetting(
      input: { learnerId: $studentId, mandInstructions: $mandInstruction, sessions: $sessions }
    ) {
      learnerSessionSettings {
        learner {
          id
          email
        }
        mandInstructions
      }
    }
  }
`

export const GET_MAND_INSTRUCTION = gql`
  query($studentId: ID!) {
    learnerSessionSettings(learnerId: $studentId) {
      learner {
        id
        email
      }
      mandInstructions
    }
  }
`
