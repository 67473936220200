import React, { useState, useEffect } from 'react'
import {
  Form,
  Button,
  Select,
  Row,
  Col,
  Input,
  Popconfirm,
  Table,
  notification,
  Modal,
  Icon,
} from 'antd'
import { useQuery } from 'react-apollo'
import '../style.scss'
import { SUBMITT_BUTTON, COLORS } from 'assets/styles/globalStyles'
import NewMasteryForm from './newMasteryForm'
import { TARGET_ALLOCATIONS_OPTIONS, GET_SBT_STEP_REINFORCERS, GET_SBT_STEP_STATUS } from './query'
import SbtMasteryForm from './sbtMasteryForm'

const { Option } = Select

const searchableDropDownOption = {
  showSearch: true,
  optionFilterProp: 'children',
  filterOption: (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}

function capitalizeFirstLetter(string) {
  if (string) {
    string = string?.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return ''
}

const StimulusModal = ({
  form,
  type,
  stimulusList,
  setStimulusList,
  stimulusObject,
  closeModal,
  defaultValues,
}) => {
  const edit = !!stimulusObject
  const { getFieldDecorator } = form
  const [stimulusModal, setStimulusModal] = useState({ show: false })
  const [listStimulusMastery, setListStimulusMastery] = useState([])
  const [stimulusAtt, setStimulusAtt] = useState([])
  const [sbtMasteryModal, setSbtMasteryModal] = useState(null)

  const {
    data: targetOptions,
    error: targetOptionsError,
    loading: targetOptionsLoading,
  } = useQuery(TARGET_ALLOCATIONS_OPTIONS)

  const {
    data: sbtReinforcersData,
    loading: sbtReinforcersLoading,
    error: sbtReinfError,
  } = useQuery(GET_SBT_STEP_REINFORCERS)

  const { data: sbtStatusData, loading: sbtStatusLoading, error: sbtStatusError } = useQuery(
    GET_SBT_STEP_STATUS,
  )

  useEffect(() => {
    if (edit && stimulusObject) {
      setListStimulusMastery(stimulusObject.manualMastery)
      setStimulusAtt(stimulusObject.attachments)
    }
  }, [edit])

  useEffect(() => {
    if (sbtReinfError) {
      console.error(sbtReinfError)
    }
    if (sbtStatusError) {
      console.error(sbtStatusError)
    }

    if (targetOptionsError) {
      console.error(targetOptionsError)
    }
  }, [sbtReinfError, sbtStatusError, targetOptionsError])

  const stimulusColumns = [
    {
      title: 'From Status',
      dataIndex: 'fromStatus',
      render: id => {
        if (targetOptions?.targetStatus) {
          const st = targetOptions.targetStatus
          for (let i = 0; i < st.length; i += 1) {
            if (st[i].id === id) {
              return st[i].statusName
            }
          }
        }
        return id
      },
      key: 'fromStatus',
    },
    {
      title: 'To Status',
      dataIndex: 'toStatus',
      render: id => {
        if (targetOptions?.targetStatus) {
          const st = targetOptions.targetStatus
          for (let i = 0; i < st.length; i += 1) {
            if (st[i].id === id) {
              return st[i].statusName
            }
          }
        }
        return id
      },
      key: 'toStatus',
    },
    {
      title: 'Response Percentage',
      dataIndex: 'responsePercentage',
      key: 'response',
      render: (text, row) => {
        if (row.lte === 100) {
          return `>= ${text}`
        }
        if (row.gte === 0) {
          return `<= ${text}`
        }
        return text
      },
    },
    {
      title: 'Days',
      dataIndex: 'days',
      key: 'days',
      render: (text, row) =>
        `${text || 0} ${row.masteryType ? ` - ${capitalizeFirstLetter(row.masteryType)}` : ''}`,
    },
    {
      title: 'Mininum Trials',
      dataIndex: 'minTrial',
      key: 'minTrial',
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: obj => (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ padding: 0, margin: 0, marginRight: 24 }}
            type="link"
            onClick={() =>
              setStimulusModal({
                ...stimulusModal,
                show: true,
                add: false,
                type,
                editObj: obj,
              })
            }
          >
            <Icon type="edit" />
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => {
              setListStimulusMastery(state => state.filter(item => item.key !== obj.key))
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" style={{ padding: 0, margin: 0, color: COLORS.danger }}>
              <Icon style={{ margin: 0 }} type="delete" />
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ]

  const stimulusReductionMasteryCol = [
    {
      title: 'From Status',
      dataIndex: 'fromStatus',
      render: id => {
        if (sbtStatusData?.getSbtStepStatus) {
          const st = sbtStatusData.getSbtStepStatus
          for (let i = 0; i < st.length; i += 1) {
            if (st[i].id === id) {
              return st[i].statusName
            }
          }
        }
        return id
      },
      key: 'fromStatus',
    },
    {
      title: 'To Status',
      dataIndex: 'toStatus',
      render: id => {
        if (sbtStatusData?.getSbtStepStatus) {
          const st = sbtStatusData?.getSbtStepStatus
          for (let i = 0; i < st.length; i += 1) {
            if (st[i].id === id) {
              return st[i].statusName
            }
          }
        }
        return id
      },
      key: 'toStatus',
    },
    {
      title: 'Problem Behavior',
      dataIndex: 'noOfProblemBehavior',
      key: 'noOfProblemBehavior',
      render: (text, row) => {
        if (row.lte === 100) {
          return `>= ${text}`
        }
        if (row.gte === 0) {
          return `<= ${text}`
        }
        return text
      },
    },
    {
      title: 'Days',
      dataIndex: 'days',
      key: 'days',
      render: (text, row) =>
        `${text || 0} ${row.masteryType ? ` - ${capitalizeFirstLetter(row.masteryType)}` : ''}`,
    },
    {
      title: 'Min Trials',
      dataIndex: 'minTrial',
      key: 'minTrial',
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: obj => (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ padding: 0, margin: 0, marginRight: 24 }}
            type="link"
            onClick={() =>
              setSbtMasteryModal({
                ...sbtMasteryModal,
                show: true,
                key: obj.key,
                type,
                add: false,
                editObj: obj,
              })
            }
          >
            <Icon type="edit" />
          </Button>
          <Popconfirm
            title="Are you sure you don't want this record?"
            onConfirm={() => {
              setListStimulusMastery(state => state.filter(mt => mt.key !== obj.key))
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" style={{ padding: 0, margin: 0, color: COLORS.danger }}>
              <Icon style={{ margin: 0 }} type="delete" />
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ]

  const SubmitForm = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        let validationMessage = ''
        if (!values.masteryCriteria && listStimulusMastery.length === 0) {
          validationMessage = 'Please select mastery criteria or provide manual mastery'
        }

        if (validationMessage) {
          notification.info({ message: validationMessage })
        } else {
          const k = stimulusList.reduce((prevVal, currVal) => Math.max(prevVal, currVal.key), 0)
          setStimulusList(state => [
            ...state,
            {
              ...values,
              name: values.step,
              manualMastery: listStimulusMastery,
              attachments: values.stimulusUrl
                ? [...stimulusAtt, { type, url: values.stimulusUrl }]
                : stimulusAtt,
              key: k + 1,
            },
          ])
          if (closeModal) {
            closeModal()
          }
        }
      }
    })
  }

  const updateForm = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        let validationMessage = ''
        if (!values.masteryCriteria && listStimulusMastery.length === 0) {
          validationMessage = 'Please select mastery criteria or provide manual mastery'
        }

        if (validationMessage) {
          notification.info({ message: validationMessage })
        } else {
          setStimulusList(state =>
            state.map(item => {
              if (item.key === stimulusObject.key) {
                return {
                  ...item,
                  ...values,
                  name: values.step,
                  manualMastery: listStimulusMastery,
                  attachments: values.stimulusUrl
                    ? [...stimulusAtt, { type, url: values.stimulusUrl }]
                    : stimulusAtt,
                }
              }
              return item
            }),
          )
          if (closeModal) {
            closeModal()
          }
        }
      }
    })
  }

  return (
    <div>
      <Form className="green-calendar-icon" name="sdStepForm" style={{ padding: '0 12px' }}>
        <Row gutter={[24]}>
          {/* name */}
          <Col span={12}>
            <Form.Item label="Step Name" key="stimulus">
              {getFieldDecorator('step', {
                initialValue: edit ? stimulusObject.name : '',
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input Stimulus's name or delete this field.",
                  },
                ],
              })(<Input placeholder="Provide step name" style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
          {/* status */}
          {edit ? (
            <Col span={12}>
              <Form.Item label="Step Status" key="sdStatus">
                {getFieldDecorator('status', {
                  initialValue: stimulusObject.status,
                  rules: [{ required: true, message: 'Please Select Status.' }],
                })(
                  <Select
                    loading={targetOptionsLoading}
                    style={{ width: '100%' }}
                    placeholder="Select status"
                    {...searchableDropDownOption}
                  >
                    {sbtStatusData?.getSbtStepStatus.map(({ id, statusName }) => (
                      <Option key={id} value={id}>
                        {statusName}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          ) : (
            <Col span={12}>
              <Form.Item label="Step Status" key="sdStatus">
                {getFieldDecorator('status', {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please Select Status or delete this field.',
                    },
                  ],
                })(
                  <Select
                    loading={targetOptionsLoading}
                    style={{ width: '100%' }}
                    placeholder="Select status"
                    {...searchableDropDownOption}
                  >
                    {sbtStatusData?.getSbtStepStatus.map(({ id, statusName }) => (
                      <Option key={id} value={id}>
                        {statusName}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          )}
        </Row>

        {edit ? (
          <Form.Item label="Reinforcer" key="sdPromt">
            {getFieldDecorator('prompt', {
              rules: [{ required: true }],
              initialValue: stimulusObject.prompt,
            })(
              <Select
                mode="multiple"
                placeholder="Please select reinforcer"
                loading={sbtReinforcersLoading}
              >
                {sbtReinforcersData?.getSbtReinforces.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        ) : (
          <Form.Item label="Reinforcer" key="sdPrompt">
            {getFieldDecorator('prompt', {
              rules: [{ required: true }],
            })(
              <Select
                mode="multiple"
                placeholder="Please select reinforcer"
                loading={sbtReinforcersLoading}
              >
                {sbtReinforcersData?.getSbtReinforces.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        )}
        {/* <Row gutter={[24]} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Col span={17} />
          <Col span={7} style={{ display: 'flex' }}>
            <Button
              type="default"
              style={{
                marginLeft: 'auto',
                background: '#34A770',
                color: 'white',
                marginBottom: 12,
              }}
              onClick={() => {
                setSbtMasteryModal({ show: true, add: true, type: 'sbt' })
              }}
            >
              <Icon type="plus" /> Add manual mastery
            </Button>
          </Col>
        </Row> */}

        <Row>
          <Col span={24}>
            <div className="mastery-criteria-table" style={{ marginBottom: 18 }}>
              <Table
                size="small"
                bordered
                dataSource={listStimulusMastery}
                columns={type === 'sbt' ? stimulusReductionMasteryCol : stimulusColumns}
                pagination={false}
                rowKey={record => record.id}
              />
            </div>
          </Col>
        </Row>

        <Form.Item style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
          <Button
            type="primary"
            onClick={edit ? updateForm : SubmitForm}
            style={{ ...SUBMITT_BUTTON, margin: 0 }}
          >
            {edit ? 'Update' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>

      {/* Modal for Stimulus Mastery Condition */}
      <Modal
        visible={stimulusModal?.show}
        title="Manual Mastery Criteria"
        onCancel={() => setStimulusModal({ ...stimulusModal, show: false })}
        zIndex={1001}
        width={800}
        destroyOnClose
        footer={null}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <NewMasteryForm
            obj={stimulusModal}
            listMastery={listStimulusMastery}
            setListMastery={setListStimulusMastery}
            setOpen={() => setStimulusModal({ ...stimulusModal, show: false })}
          />
        </div>
      </Modal>

      {/* Modal for Behavior sbt step Mastery Condition */}
      <Modal
        visible={sbtMasteryModal?.show}
        title="Behaviour Reduction Mastery Criteria"
        onCancel={() => setSbtMasteryModal({ ...sbtMasteryModal, show: false })}
        zIndex={1001}
        width={800}
        destroyOnClose
        footer={null}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SbtMasteryForm
            obj={sbtMasteryModal}
            listMastery={listStimulusMastery}
            setListMastery={setListStimulusMastery}
            setOpen={() => setSbtMasteryModal({ ...sbtMasteryModal, show: false })}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Form.create()(StimulusModal)
