/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-var */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */

import React, { useState } from 'react'
import { Button, Dropdown, Modal, Spin, Icon } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Normal } from 'constants/RecordingLabels'
import './style.scss'
import ScoreBoard from './ScoreBoard'

const { confirm } = Modal
const error = 'Error'
const incorrect = 'Incorrect'
const promptCodeButtonStyle = {
  padding: '8px auto',
  width: '200px',
  height: '40px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
}
const leftButtonsStyle = {
  padding: '15px auto',
  width: 250,
  height: '50px',
  background: '#EDEDED',
  border: '1px solid #E1E1E1',
  boxSizing: 'border-box',
  borderRadius: 3,
}
const rightButtonsStyle = {
  padding: '15px auto',
  width: 250,
  height: '50px',
  marginLeft: 10,
  background: '#EDEDED',
  border: '1px solid #E1E1E1',
  boxSizing: 'border-box',
  borderRadius: 3,
}

const Stimulus = () => {
  const [showPromptOptions, setShowPromptOptions] = useState(true)
  const [note, setNote] = useState('')
  const dispatch = useDispatch()
  const {
    MasterSession,
    TargetActiveIndex,
    CorrectCount,
    IncorrectCount,
    TargetResponse,
    TargetActiveId,
    Count,
    EditAfterSessionCompleted,
    PromptCodesList,
    ResponseLoading,
    StimulusActiveIndex,
    StimulusActiveId,
  } = useSelector(state => state.sessionrecording)

  const correctIncorrectDiv = showPromptOptions
    ? { display: 'block', textAlign: 'center', marginTop: '20px' }
    : { display: 'none' }

  const getLabel = label => {
    let temp = label
    const filtered = MasterSession.targets.edges[
      TargetActiveIndex
    ].node.recordingLabels?.edges.filter(
      ({ node }) => node.label.toLowerCase() === label.toLowerCase(),
    )
    if (filtered.length) {
      temp = filtered[0].node.newLabel.length ? filtered[0].node.newLabel : filtered[0].node.label
    }
    return temp
  }

  const showPrompt = () => {
    setShowPromptOptions(false)
  }

  const closePromptOptions = () => {
    setShowPromptOptions(true)
  }

  const updateSessionClockTime = () => {
    // updatig current clock time to store
    document.getElementById('updateSessionCurrentTimeInStore').click()
  }

  const updateStartTrialClockTime = () => {
    // updatig trial start time to store
    document.getElementById('updateStartTrialTimeInStore').click()
  }

  const resetButtonStyle = trial => {
    if (trial === 'CORRECT') {
      document.getElementById('correctResponseButton').style.color = '#4BAEA0'
      document.getElementById('correctResponseButton').style.borderColor = '#4BAEA0'
      document.getElementById('incorrectResponseButton').style.color = 'gray'
      document.getElementById('incorrectResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('noResponseButton').style.color = 'gray'
      document.getElementById('noResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('promptResponseButton').style.color = 'gray'
      document.getElementById('promptResponseButton').style.borderColor = '#e4e9f0'
    } else if (trial === 'INCORRECT') {
      document.getElementById('correctResponseButton').style.color = 'gray'
      document.getElementById('correctResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('incorrectResponseButton').style.color = '#FF8080'
      document.getElementById('incorrectResponseButton').style.borderColor = '#FF8080'
      document.getElementById('noResponseButton').style.color = 'gray'
      document.getElementById('noResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('promptResponseButton').style.color = 'gray'
      document.getElementById('promptResponseButton').style.borderColor = '#e4e9f0'
    } else if (trial === 'PROMPT') {
      document.getElementById('correctResponseButton').style.color = 'gray'
      document.getElementById('correctResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('incorrectResponseButton').style.color = 'gray'
      document.getElementById('incorrectResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('noResponseButton').style.color = 'gray'
      document.getElementById('noResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('promptResponseButton').style.color = '#FF9C52'
      document.getElementById('promptResponseButton').style.borderColor = '#FF9C52'
    } else if (trial === 'ERROR') {
      document.getElementById('correctResponseButton').style.color = 'gray'
      document.getElementById('correctResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('incorrectResponseButton').style.color = 'gray'
      document.getElementById('incorrectResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('noResponseButton').style.color = '#FF8080'
      document.getElementById('noResponseButton').style.borderColor = '#FF8080'
      document.getElementById('promptResponseButton').style.color = 'gray'
      document.getElementById('promptResponseButton').style.borderColor = '#e4e9f0'
    }
  }

  const addCorrectTrailButtonStyle = () => {
    document.getElementById('correctResponseButton').style.color = '#4BAEA0'
    document.getElementById('correctResponseButton').style.borderColor = '#4BAEA0'
    document.getElementById('incorrectResponseButton').style.color = 'gray'
    document.getElementById('incorrectResponseButton').style.borderColor = '#e4e9f0'
  }

  const addPromptTrailButtonStyle = () => {
    document.getElementById('correctResponseButton').style.color = 'gray'
    document.getElementById('correctResponseButton').style.borderColor = '#e4e9f0'
    document.getElementById('incorrectResponseButton').style.color = '#FF9C52'
    document.getElementById('incorrectResponseButton').style.borderColor = '#FF9C52'
  }

  const addInCorrectTrailButtonStyle = () => {
    document.getElementById('correctResponseButton').style.color = 'gray'
    document.getElementById('correctResponseButton').style.borderColor = '#e4e9f0'
    document.getElementById('incorrectResponseButton').style.color = '#FF8080'
    document.getElementById('incorrectResponseButton').style.borderColor = '#FF8080'
  }

  const resetCorrectIncorrectButtonStyle = () => {
    var element = document.getElementById('correctResponseButton')

    // If it isn't "undefined" and it isn't "null", then it exists.
    if (typeof element != 'undefined' && element != null) {
      document.getElementById('correctResponseButton').style.color = 'gray'
      document.getElementById('correctResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('incorrectResponseButton').style.color = 'gray'
      document.getElementById('incorrectResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('noResponseButton').style.color = 'gray'
      document.getElementById('noResponseButton').style.borderColor = '#e4e9f0'
      document.getElementById('promptResponseButton').style.color = 'gray'
      document.getElementById('promptResponseButton').style.borderColor = '#e4e9f0'
    } else {
      console.log('Buttons does not not exits')
    }
  }

  const addCorrectTrail = val => {
    addCorrectTrailButtonStyle()
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        CorrectCount: CorrectCount + 1,
      },
    })
  }

  const addPromptTrail = val => {
    addPromptTrailButtonStyle()
    closePromptOptions()
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        IncorrectCount: IncorrectCount + 1,
      },
    })
  }

  const addInCorrectTrail = val => {
    addInCorrectTrailButtonStyle()
    closePromptOptions()
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        IncorrectCount: IncorrectCount + 1,
      },
    })
  }

  const resetNote = () => {
    setNote('')
  }

  const setNoteText = object => {
    if (object.text) setNote(object.text)
    else setNote('')
  }

  const moveToNextStimulus = () => {
    // storing trial start time
    updateStartTrialClockTime()
    resetCorrectIncorrectButtonStyle()
    dispatch({
      type: 'sessionrecording/CHANGE_STIMULUS',
      payload: {
        stimulusId:
          MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[StimulusActiveIndex + 1].node
            .id,
        stimulusIndex: StimulusActiveIndex + 1,
        targetId: TargetActiveId,
      },
    })
  }

  const moveToPrevStimulus = () => {
    // storing trial start time
    updateStartTrialClockTime()
    resetCorrectIncorrectButtonStyle()
    dispatch({
      type: 'sessionrecording/CHANGE_STIMULUS',
      payload: {
        stimulusId:
          MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[StimulusActiveIndex - 1].node
            .id,
        stimulusIndex: StimulusActiveIndex - 1,
        targetId: TargetActiveId,
      },
    })
  }

  const moveToNextSDTrail = () => {
    if (!(Count > TargetResponse[TargetActiveId].sd[StimulusActiveId].length)) {
      if (Count === TargetResponse[TargetActiveId].sd[StimulusActiveId].length) {
        // user want to perform new trial
        // storing trial start time
        updateStartTrialClockTime()
        // reseting correct incorrect buttons style
        resetCorrectIncorrectButtonStyle()
        // increasing Trial Count
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            Count: Count + 1,
          },
        })
      } else {
        // user updation existing trial
        // increasing Trial Count
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            Count: Count + 1,
          },
        })
        // show previous response
        const object = TargetResponse[TargetActiveId].sd[StimulusActiveId][Count]
        if (object.trial) resetButtonStyle(object.trial)
        setNoteText(object)
      }
    }
  }

  const moveToPrevSDTrail = () => {
    resetCorrectIncorrectButtonStyle()
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        Count: Count - 1,
      },
    })
    // show previous response
    const object = TargetResponse[TargetActiveId].sd[StimulusActiveId][Count - 2]
    if (object.trial) resetButtonStyle(object.trial)
    setNoteText(object)
  }

  const addSDCount = val => {
    let status = ''
    const mastryNode = MasterSession.targets.edges[TargetActiveIndex].node.mastery.edges
    if (mastryNode.length > 0) {
      mastryNode.map(
        masteryItem =>
          masteryItem.node.sd.id === StimulusActiveId && (status = masteryItem.node.status?.id),
      )
    }

    if (EditAfterSessionCompleted) {
      if (TargetResponse[TargetActiveId].sd[StimulusActiveId][Count - 1]) {
        const trialObject = TargetResponse[TargetActiveId].sd[StimulusActiveId][Count - 1]
        addCorrectTrailButtonStyle()
        dispatch({
          type: 'sessionrecording/UPDATE_TARGET_SD_TRIAL',
          payload: {
            object: trialObject,
            response: 'Correct',
            promptId: '',
            note,
          },
        })

        if (!(trialObject.trial === 'CORRECT')) {
          dispatch({
            type: 'sessionrecording/SET_STATE',
            payload: {
              IncorrectCount: IncorrectCount - 1,
              CorrectCount: CorrectCount + 1,
            },
          })
        }
      } else {
        addCorrectTrail(val)
        updateSessionClockTime()

        dispatch({
          type: 'sessionrecording/TARGET_SD_CORRECT',
          payload: {
            response: 'Correct',
            promptId: '',
            sdId: StimulusActiveId,
            note,
            sdStepStatusId: status,
          },
        })
      }
    }
    resetNote()
    // trialAutoMove('sd')
  }

  const promptSDCount = (val, promptObject) => {
    closePromptOptions()
    let status = ''
    const mastryNode = MasterSession.targets.edges[TargetActiveIndex].node.mastery.edges
    if (mastryNode.length > 0) {
      mastryNode.map(
        masteryItem =>
          masteryItem.node.sd.id === StimulusActiveId && (status = masteryItem.node.status?.id),
      )
    }

    if (EditAfterSessionCompleted) {
      if (TargetResponse[TargetActiveId].sd[StimulusActiveId][Count - 1]) {
        const trialObject = TargetResponse[TargetActiveId].sd[StimulusActiveId][Count - 1]
        addPromptTrailButtonStyle()
        dispatch({
          type: 'sessionrecording/UPDATE_TARGET_SD_TRIAL',
          payload: {
            object: trialObject,
            response: 'Prompt',
            promptId: promptObject.id,
            note,
          },
        })

        if (trialObject.trial === 'CORRECT') {
          dispatch({
            type: 'sessionrecording/SET_STATE',
            payload: {
              IncorrectCount: IncorrectCount + 1,
              CorrectCount: CorrectCount - 1,
            },
          })
        }
      } else {
        addPromptTrail(val)
        updateSessionClockTime()

        dispatch({
          type: 'sessionrecording/TARGET_SD_CORRECT',
          payload: {
            response: 'Prompt',
            promptId: promptObject.id,
            sdId: StimulusActiveId,
            note,
            sdStepStatusId: status,
          },
        })
      }
    }
    resetNote()
  }

  const removeSDCount = (val, trialResponse) => {
    closePromptOptions()
    let status = ''
    const mastryNode = MasterSession.targets.edges[TargetActiveIndex].node.mastery.edges
    if (mastryNode.length > 0) {
      mastryNode.map(
        masteryItem =>
          masteryItem.node.sd.id === StimulusActiveId && (status = masteryItem.node.status?.id),
      )
    }

    if (EditAfterSessionCompleted) {
      if (TargetResponse[TargetActiveId].sd[StimulusActiveId][Count - 1]) {
        const trialObject = TargetResponse[TargetActiveId].sd[StimulusActiveId][Count - 1]
        addInCorrectTrailButtonStyle()
        dispatch({
          type: 'sessionrecording/UPDATE_TARGET_SD_TRIAL',
          payload: {
            object: trialObject,
            response: trialResponse,
            promptId: '',
            note,
          },
        })
        if (trialObject.trial === 'CORRECT') {
          dispatch({
            type: 'sessionrecording/SET_STATE',
            payload: {
              IncorrectCount: IncorrectCount + 1,
              CorrectCount: CorrectCount - 1,
            },
          })
        }
      } else {
        addInCorrectTrail(val)
        updateSessionClockTime()

        dispatch({
          type: 'sessionrecording/TARGET_SD_CORRECT',
          payload: {
            response: trialResponse,
            promptId: '',
            sdId: StimulusActiveId,
            note,
            sdStepStatusId: status,
          },
        })
      }
    }
    resetNote()
  }

  const SubMenu = props => {
    return (
      <>
        {MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.promptCodes.edges
          .length > 0 ? (
          <>
            {MasterSession.targets.edges[
              TargetActiveIndex
            ].node.targetAllcatedDetails.promptCodes.edges.map(item => (
              <>
                <Button
                  onClick={() => promptSDCount(Count, item.node)}
                  style={promptCodeButtonStyle}
                >
                  {item.node.promptName}
                </Button>
              </>
            ))}
          </>
        ) : (
          <>
            {PromptCodesList.map(item => (
              <>
                <Button onClick={() => promptSDCount(Count, item)} style={promptCodeButtonStyle}>
                  {item.promptName}
                </Button>
              </>
            ))}
          </>
        )}
      </>
    )
  }

  const getButtonsCounts = type => {
    const recording = TargetResponse[TargetActiveId].sd[StimulusActiveId]
    let correctCount = 0
    let incorrectCount = 0
    let promptCount = 0
    let noResponseCount = 0
    if (recording && recording.length > 0) {
      recording.map(item => {
        if (item.trial === 'INCORRECT') incorrectCount += 1
        if (item.trial === 'ERROR') noResponseCount += 1
        if (item.trial === 'PROMPT') promptCount += 1
        if (item.trial === 'CORRECT') correctCount += 1
      })
    }

    let returnCount = 0
    if (type === 'correct') returnCount = correctCount
    if (type === 'incorrect') returnCount = incorrectCount
    if (type === 'noresponse') returnCount = noResponseCount
    if (type === 'prompt') returnCount = promptCount

    return returnCount
  }

  const resetStimulus = sdId => {
    confirm({
      title: ' Are you sure you want to delete all records for current Stimulus?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch({
          type: 'sessionrecording/RESET_SD_SBT_STEP_DATA',
          payload: {
            targetId: TargetActiveId,
            sdId,
            stepId: '',
            sbtStepId: '',
          },
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const resetTrial = () => {
    if (TargetResponse[TargetActiveId].sd[StimulusActiveId][Count - 1]) {
      confirm({
        title: ' Are you sure you want to delete the current Trial??',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          dispatch({
            type: 'sessionrecording/RESET_TRIAL',
            payload: {
              targetId: TargetActiveId,
              trialIndex: Count - 1,
              type: 'Normal-sd',
            },
          })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    }
  }

  return (
    <>
      <div className="recording-block-div">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            {
              MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[StimulusActiveIndex].node
                .sd
            }
          </div>
          <div style={{ fontSize: 12 }}>
            {StimulusActiveIndex === 0 ? (
              <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                <Icon type="left" />
              </Button>
            ) : (
              <Button style={{ border: 'none' }} onClick={moveToPrevStimulus}>
                <Icon type="left" />
              </Button>
            )}
            Stimulus {StimulusActiveIndex + 1} /{' '}
            {MasterSession.targets.edges[TargetActiveIndex].node.sd.edges.length}
            {StimulusActiveIndex ===
            MasterSession.targets.edges[TargetActiveIndex].node.sd.edges.length - 1 ? (
              <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                <Icon type="right" />
              </Button>
            ) : (
              <Button style={{ border: 'none' }} onClick={moveToNextStimulus}>
                <Icon type="right" />
              </Button>
            )}
            <Button
              style={{ border: 'none', background: 'transparent', flex: 1 }}
              onClick={() => resetStimulus(StimulusActiveId)}
            >
              <Icon type="delete" />
            </Button>
          </div>
        </div>
      </div>

      <div className="recording-block-div">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <ScoreBoard
              key={MasterSession.targets.edges[TargetActiveIndex].node.id}
              id={MasterSession.targets.edges[TargetActiveIndex].node.id}
              sdKey={
                MasterSession.targets.edges[TargetActiveIndex].node.sd.edges[StimulusActiveIndex]
                  ?.node.id
              }
              stepKey=""
              dailyTrails={
                MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails
                  .DailyTrials
              }
              boxWidth="6px"
            />
          </div>
          <div style={{ fontSize: 12 }}>
            {Count === 1 ? (
              <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                <Icon type="left" />
              </Button>
            ) : (
              <Button style={{ border: 'none' }} onClick={moveToPrevSDTrail}>
                <Icon type="left" />
              </Button>
            )}
            Trial {Count} /{' '}
            {MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.DailyTrials}
            {Count ===
            MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails
              .DailyTrials ? (
              <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                <Icon type="right" />
              </Button>
            ) : (
              <Button style={{ border: 'none' }} onClick={moveToNextSDTrail}>
                <Icon type="right" />
              </Button>
            )}
            <Button
              style={{ border: 'none', background: 'transparent', flex: 1 }}
              onClick={() => resetTrial()}
            >
              <Icon type="delete" />
            </Button>
          </div>
        </div>
        <div>
          {ResponseLoading ? (
            <Spin size="small">
              <p style={correctIncorrectDiv}>
                <div>
                  <Button style={leftButtonsStyle}>
                    <CheckOutlined /> {getLabel(Normal.CORRECT.label)} (
                    {getButtonsCounts('correct')})
                  </Button>
                  <Button style={rightButtonsStyle}>
                    <CheckOutlined /> {getLabel(Normal.INCORRECT.label)} (
                    {getButtonsCounts('incorrect')})
                  </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                  <Button style={leftButtonsStyle}>
                    {getLabel(Normal.NO_RESPONSE.label)} ({getButtonsCounts('noresponse')})
                  </Button>
                  <Dropdown placement="bottomRight">
                    <Button style={rightButtonsStyle}>
                      {getLabel(Normal.PROMPT.label)} ({getButtonsCounts('prompt')})
                    </Button>
                  </Dropdown>
                </div>
                <br />
              </p>
            </Spin>
          ) : (
            <p style={correctIncorrectDiv}>
              <div>
                <Button
                  id="correctResponseButton"
                  style={leftButtonsStyle}
                  onClick={() => addSDCount(Count)}
                >
                  <CheckOutlined /> {getLabel(Normal.CORRECT.label)} ({getButtonsCounts('correct')})
                </Button>
                <Button
                  id="incorrectResponseButton"
                  style={rightButtonsStyle}
                  onClick={() => removeSDCount(Count, incorrect)}
                >
                  <CheckOutlined /> {getLabel(Normal.INCORRECT.label)} (
                  {getButtonsCounts('incorrect')})
                </Button>
              </div>
              <div style={{ marginTop: 10 }}>
                <Button
                  id="noResponseButton"
                  onClick={() => removeSDCount(Count, error)}
                  style={leftButtonsStyle}
                >
                  {getLabel(Normal.NO_RESPONSE.label)} ({getButtonsCounts('noresponse')})
                </Button>
                <Dropdown overlay={SubMenu} placement="bottomRight">
                  <Button id="promptResponseButton" style={rightButtonsStyle}>
                    {getLabel(Normal.PROMPT.label)} ({getButtonsCounts('prompt')})
                  </Button>
                </Dropdown>
              </div>
              <br />
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default Stimulus
