/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Form, notification, Switch, Row, Col } from 'antd'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import styled from 'styled-components'
import { COLORS, SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import LoadingComponent from '../staffProfile/LoadingComponent'
import './form.css'

const EMAIL_NOTI_INFO = gql`
  query {
    schoolDetail {
      schoolMail {
        parentMail
        staffMail
      }
    }
  }
`

const CHANGE_SETT = gql`
  mutation schoolMail($parentMail: Boolean!, $staffMail: Boolean!) {
    schoolMail(input: { parentMail: $parentMail, staffMail: $staffMail }) {
      schoolMail {
        id
        parentMail
        staffMail
      }
    }
  }
`

const EmailNotiSett = () => {
  const [parentId, setParentId] = useState()
  const [therapistId, setTherapistId] = useState()
  const [changeData, setChangeData] = useState(false)

  const { data: mailData, error: mailError, loading: mailLoading } = useQuery(EMAIL_NOTI_INFO, {
    fetchPolicy: 'no-cache',
  })

  const [
    updateSett,
    { data: updateSettData, error: updateSettError, loading: updateSettLoading },
  ] = useMutation(CHANGE_SETT)

  useEffect(() => {
    if (mailData) {
      const { parentMail, staffMail } = mailData.schoolDetail.schoolMail
      setParentId(parentMail)
      setTherapistId(staffMail)
    }
  }, [mailData])

  useEffect(() => {
    if (updateSettData) {
      notification.success({
        message: 'Update Email Notificaiton Setting Successfully',
      })
      setChangeData(false)
    }
    if (updateSettError) {
      notification.error({
        message: 'Update Email Notificaiton Setting Faild',
      })
    }
  }, [updateSettData, updateSettError])

  const handelChange = userType => () => {
    setChangeData(true)
    if (userType === 'parent') {
      setParentId(state => !state)
    } else if (userType === 'staff') {
      setTherapistId(state => !state)
    }
  }

  const formItemStyle = {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    fontWeight: 'bold',
    color: 'black',
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {mailLoading ? (
        <LoadingComponent />
      ) : (
        <Row style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
          <Col sm={24} lg={18} md={24}>
            {mailError && <pre>{JSON.stringify(mailError, null, 2)}</pre>}
            {mailData && (
              <div style={{ marginTop: 20 }}>
                <Form style={{ width: '85%' }}>
                  <div style={{ ...formItemStyle }}>
                    <label style={{ fontSize: '16px' }} htmlFor="parent-notification">
                      Parent Email Notification
                    </label>
                    <Switch
                      id="parent-notificaton"
                      checked={parentId}
                      onChange={handelChange('parent')}
                    />
                  </div>
                  <div style={formItemStyle}>
                    <label style={{ fontSize: '16px' }} htmlFor="therapist-notification">
                      Therapist Email Notification
                    </label>
                    <Switch checked={therapistId} onChange={handelChange('staff')} />
                  </div>
                  <Form.Item style={{ display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ backgroundColor: COLORS.submitBlue, color: 'white' }}
                      loading={updateSettLoading}
                      onClick={() =>
                        updateSett({
                          variables: {
                            parentMail: parentId,
                            staffMail: therapistId,
                          },
                        })
                      }
                      disabled={!changeData}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            )}
          </Col>
        </Row>
      )}
    </div>
  )
}

export default EmailNotiSett
