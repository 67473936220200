import React from 'react'
import './styles.scss'
import CollapseComp from './collapseComp'

function Index({ programAreas, selectedStudent }) {
  console.log('programAreas--->', programAreas)
  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', padding: 16, paddingRight: 0 }}>
        {programAreas.map((item, index) => {
          return (
            <CollapseComp
              key={item.id}
              idx={index}
              programArea={item}
              selectedStudent={selectedStudent}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Index
