import React, { useState, useEffect } from 'react'
import StudentAndTestDetails from '../StudentAndTestDetails'
import MilestonesGraph from './MilestonesGraph'

const MilestonesTab = ({ data, onSelectionChange, refetch, areaId, selectedAssessment }) => {
  // const [color, setColor] = useState({})

  // useEffect(() => {
  //   if (data.scoreDetails) {
  //     const temp = {}
  //     data.scoreDetails.forEach(test => {
  //       temp[test.testTitle] = test.color
  //     })
  //     setColor(temp)
  //   }
  // }, [data])
  return (
    <div className="milestoneTab" id="milestones">
      <StudentAndTestDetails
        scoreDetails={data.scoreDetails}
        studentDetails={data.studentDetails}
        onSelectionChange={id => onSelectionChange(id)}
        refetch={() => refetch()}
        areaId={areaId}
        selectedAssessment={selectedAssessment}
      />
      <MilestonesGraph
        level1={data.level1}
        level2={data.level2}
        level3={data.level3}
        scoreDetails={data.scoreDetails}
      />
    </div>
  )
}

export default MilestonesTab
