/* eslint-disable no-unneeded-ternary */
import { Form, Row, Switch, Col, Input, Button, notification, Typography, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { COLORS } from 'assets/styles/globalStyles'
import { useGeolocated } from 'react-geolocated'
import { useMutation, useQuery } from 'react-apollo'
import { CREATE_UPDATE_STAFF_ATTENDANCE_SETTING, GET_STAFF_ATTENDANCE_SETTINGS } from './queries'

const { Text } = Typography

const CenterGeoLoaction = ({ form }) => {
  const { getFieldDecorator, validateFields } = form
  const { isGeolocationAvailable, isGeolocationEnabled, coords } = useGeolocated()
  const [long, setLong] = useState('')
  const [lat, setLat] = useState('')
  const [locationSpecificAttendance, setLocationSpecificAttendance] = useState(false)
  const { data, loading, error } = useQuery(GET_STAFF_ATTENDANCE_SETTINGS, {
    fetchPolicy: 'network-only',
  })

  const [saveCenterGeoData, { data: centerData, loading: centerSaving }] = useMutation(
    CREATE_UPDATE_STAFF_ATTENDANCE_SETTING,
  )
  useEffect(() => {
    if (isGeolocationAvailable) {
      if (isGeolocationEnabled) {
        if (coords) {
          const { latitude, longitude } = coords
          setLat(coords.latitude)
          setLong(coords.longitude)
        }
      } else {
        console.log('Geolocation is not enabled.')
      }
    } else {
      console.log('Geolocation is not available.')
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, coords])

  useEffect(() => {
    if (data && data.getStaffAttendenceSettings) {
      const {
        longitude,
        latitude,
        locationSpecificAttendence,
        distance,
      } = data.getStaffAttendenceSettings
      setLocationSpecificAttendance(locationSpecificAttendence)

      form.setFieldsValue({
        latitude,
        longitude,
        distance,
      })
    }
  }, [data])

  const addLocationSpecific = val => {
    setLocationSpecificAttendance(!locationSpecificAttendance)
    saveCenterGeoData({
      variables: {
        locationSpecificAttendence: !locationSpecificAttendance,
      },
    })
      .then(res => {
        notification.success({
          message: res.data.createUpdateStaffAttendenceSettings.message,
        })
      })
      .catch(er => {
        console.log('error: ', er)
      })
  }

  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        saveCenterGeoData({
          variables: {
            ...values,
            locationSpecificAttendence: locationSpecificAttendance,
          },
        })
          .then(res => {
            notification.success({
              message: res.data.createUpdateStaffAttendenceSettings.message,
            })
          })
          .catch(er => {
            console.log('error: ', er)
          })
      }
    })
  }
  const validateLatitude = (_, value, callback) => {
    const latitude = parseFloat(value)

    if (Number.isNaN(latitude)) {
      callback('Please enter a valid latitude.')
    } else if (latitude < -90 || latitude > 90) {
      callback('Latitude must be between -90 and 90.')
    } else {
      callback()
    }
  }

  const validateLongitude = (_, value, callback) => {
    const longitude = parseFloat(value)

    if (Number.isNaN(longitude)) {
      callback('Please enter a valid longitude.')
    } else if (longitude < -180 || longitude > 180) {
      callback('Longitude must be between -180 and 180.')
    } else {
      callback()
    }
  }
  const formItemStyle = {
    display: 'flex',
    alignItem: 'center',
    // justifyContent: 'space-between',
    marginBottom: '2rem',
    fontWeight: 'bold',
    color: 'black',
  }
  return (
    <>
      <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
        <Col sm={24} md={24} lg={18}>
          {' '}
          <div style={formItemStyle}>
            <Text style={{ fontSize: '16px', marginRight: 5 }}>Location Specific Attendance</Text>
            <Switch
              checked={locationSpecificAttendance}
              onChange={val => addLocationSpecific(val)}
            />
          </div>
        </Col>
      </Row>

      <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
        <Col sm={24} md={24} lg={18}>
          <Form
            style={{ width: '100%' }}
            onSubmit={handleSubmit}
            className="therapistShiftingTab"
            size="small"
            disabled={!locationSpecificAttendance}
            initialValue={{
              distance: 100,
            }}
          >
            <Row
              gutter={[12, 12]}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Col span={10}>
                <Form.Item
                  label="Latitude"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="form-label"
                >
                  {getFieldDecorator('latitude', {
                    rules: [
                      { required: true, message: 'Please enter the latitude.' },
                      { validator: validateLatitude },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="Enter latitude"
                      disabled={!locationSpecificAttendance}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Longitude"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="form-label"
                >
                  {form.getFieldDecorator('longitude', {
                    rules: [
                      { required: true, message: 'Please enter the longitude.' },
                      { validator: validateLongitude },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="Enter longitude"
                      disabled={!locationSpecificAttendance}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={4} style={{ marginTop: 18 }}>
                <Button
                  type="primary"
                  disabled={!locationSpecificAttendance}
                  onClick={() => {
                    form.setFieldsValue({
                      latitude: lat,
                      longitude: long,
                    })
                  }}
                >
                  Get Coordinates
                </Button>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  label="Allowed Distance (in meters)"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="form-label"
                >
                  {getFieldDecorator('distance', {
                    rules: [{ required: true, message: 'Please enter the distance.' }],
                  })(
                    <InputNumber
                      min={0}
                      style={{ width: '100%' }}
                      addonAfter="Meters"
                      placeholder="Enter attendance valid distance"
                      disabled={!locationSpecificAttendance}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                type="primary"
                style={{
                  backgroundColor: !locationSpecificAttendance ? COLORS.grayBackground : '#0B35B3',
                }}
                htmlType="submit"
                loading={centerSaving}
                disabled={!locationSpecificAttendance}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default Form.create()(CenterGeoLoaction)
