import { Button } from 'antd'
import React from 'react'
import { RiFilterOffLine } from 'react-icons/ri'

const ClearFilterButton = props => {
  return (
    <Button {...props} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      Clear Filters
      <RiFilterOffLine />
    </Button>
  )
}

export default ClearFilterButton
