/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react'
import { Button, Tabs, Modal, Icon, Tooltip } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { TargetTypes } from 'constants/index'
import './style.scss'
import { COLORS } from 'assets/styles/globalStyles'

import NormalTarget from './NormalTarget'
import Peak from './Peak'
import SBT from './SBT'
import BehaviorRecording from './BehaviorRecording'

const { TabPane } = Tabs
const { confirm } = Modal

const RecordingArea = ({ from }) => {
  const dispatch = useDispatch()
  const {
    MasterSession,
    TargetActiveIndex,
    TargetActiveId,
    Disabled,
    ViewOnlySession,
  } = useSelector(state => state.sessionrecording)

  const getVideoUrl = index => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        VideoUrl: '',
        VideoLoading: true,
      },
    })
    if (MasterSession.targets.edges[index].node.videos.edges.length > 0) {
      const videoNode = MasterSession.targets.edges[index].node.videos.edges[0]
      const targetVideoUrl = videoNode.node.url
      let finalUrl = ''
      let splitList = []
      let videoId = ''
      splitList = targetVideoUrl.split('/')
      if (splitList.length > 3) {
        if (
          targetVideoUrl.split('/')[2] === 'www.youtube.com' ||
          targetVideoUrl.split('/')[2].includes('youtu')
        ) {
          finalUrl = targetVideoUrl
        } else {
          videoId = targetVideoUrl.split('/')[3]
          finalUrl = `https://player.vimeo.com/video/${videoId}/`
        }
      }

      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          VideoUrl: finalUrl,
          VideoAvailable: true,
          VideoLoading: false,
        },
      })
    } else {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          VideoAvailable: false,
          VideoLoading: false,
        },
      })
    }
  }

  const updateSessionClockTime = () => {
    // updatig current clock time to store
    document.getElementById('updateSessionCurrentTimeInStore').click()
  }

  const updateStartTrialClockTime = () => {
    // updatig trial start time to store
    document.getElementById('updateStartTrialTimeInStore').click()
  }

  const changeTarget = index => {
    if (TargetActiveIndex !== index) {
      if (Disabled && ViewOnlySession) {
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            TargetActiveIndex: index,
            TargetActiveId: MasterSession.targets.edges[index].node.id,
            PeakTrialCount: 1,
            PeakBlockIndex: 0,
            StepActiveIndex: 0,
            StimulusActiveIndex: 0,
            TotalDuration: 0,
            TotalBehDuration: 0,
            Frequency: 0,
          },
        })
        getVideoUrl(index)
      } else if (Disabled && !ViewOnlySession) {
        alert('Please Start Session Clock first')
      } else {
        // scrolling target to top
        // updating start trial time
        updateStartTrialClockTime()
        // updatig current clock time to store
        updateSessionClockTime()
        // reseting response button color
        // resetCorrectIncorrectButtonStyle()
        // updating previous target end time
        dispatch({
          type: 'sessionrecording/TARGET_UPDATE',
          payload: {
            targetId: TargetActiveId,
          },
        })

        // Updating target index and target id to store
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            TargetActiveIndex: index,
            TargetActiveId: MasterSession.targets.edges[index].node.id,
            PeakTrialCount: 1,
            PeakBlockIndex: 0,
            StepActiveIndex: 0,
            StimulusActiveIndex: 0,
            TotalDuration: 0,
            TotalBehDuration: 0,
            Frequency: 0,
          },
        })

        // checking new target recording if not exist creating new target skills model instance
        dispatch({
          type: 'sessionrecording/CREATE_NEW_TARGET_INSTANCE',
          payload: {
            targetId: MasterSession.targets.edges[index].node.id,
            targetIndex: index,
          },
        })

        // load video
        getVideoUrl(index)
      }
    }
  }

  const resetTargetData = () => {
    confirm({
      title: ' Are you sure you want to delete all records for current Target??',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch({
          type: 'sessionrecording/RESET_TARGET_DATA',
          payload: {
            targetId: TargetActiveId,
          },
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const text = <span>Reset Target Recorded Data</span>
  return (
    <>
      <div
        className="recording-area-div"
        style={Disabled ? { pointerEvents: 'none', paddingBottom: 0 } : { paddingBottom: 0 }}
      >
        <p className="title">
          <span style={{ fontStyle: 'normal', fontSize: 16 }}>
            {MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.targetName}
          </span>
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p className="title" style={{ marginBottom: 5, flex: 6 }}>
            Domain :{' '}
            <span style={{ color: '#0b35b3' }}>
              {MasterSession.targets.edges[TargetActiveIndex].node.targetId
                ? MasterSession.targets.edges[TargetActiveIndex].node.targetId?.domain?.domain
                : MasterSession.targets.edges[TargetActiveIndex].node.manualAllocateDomain?.domain}
            </span>
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p className="title" style={{ marginBottom: 5, flex: 6 }}>
            Short Term Goal :{' '}
            <span style={{ color: COLORS.orange }}>
              {MasterSession.targets.edges[TargetActiveIndex].node.shortTerm
                ? MasterSession.targets.edges[TargetActiveIndex].node.shortTerm.goalName
                : ''}
            </span>
          </p>
          <Tooltip placement="top" title={text}>
            <Button
              style={{ border: 'none', background: 'transparent', padding: 0, paddingBottom: 11 }}
              onClick={() => resetTargetData()}
            >
              <Icon type="delete" />
            </Button>
          </Tooltip>
        </div>
      </div>
      {!from && (
        <div className="target-pagination">
          <Tabs
            // key={TargetActiveId}
            defaultActiveKey="0"
            activeKey={String(TargetActiveIndex)}
            onChange={key => changeTarget(parseInt(key, 10))}
          >
            {MasterSession.targets.edges.map((item, index) => (
              <TabPane tab={index + 1 < 10 ? `0${index + 1}` : index + 1} key={index} />
            ))}
          </Tabs>
        </div>
      )}
      <div style={Disabled ? { pointerEvents: 'none' } : {}}>
        {MasterSession?.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.targetType
          .id === TargetTypes.PEAK && <Peak key={TargetActiveIndex} />}
        {MasterSession?.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.targetType
          .id === TargetTypes.SBT && <SBT key={TargetActiveIndex} />}
        {MasterSession?.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.targetType
          .id === TargetTypes.BEHAVIOR_RECORDING && <BehaviorRecording key={TargetActiveIndex} />}
        {MasterSession?.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.targetType
          .id !== TargetTypes.PEAK &&
          MasterSession?.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.targetType
            .id !== TargetTypes.SBT &&
          MasterSession?.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.targetType
            .id !== TargetTypes.BEHAVIOR_RECORDING && <NormalTarget />}
      </div>
    </>
  )
}

export default RecordingArea
