/* eslint-disable no-shadow */
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Upload,
  Checkbox,
  notification,
} from 'antd'
import { CANCEL_BUTTON, SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import { getResponsibilities } from 'components/PayorsAndBilling/Common/utils'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import actions from 'redux/payor/actions'
import client from 'apollo/config'
import { Scrollbars } from 'react-custom-scrollbars'
import DynamicFields from 'components/Form/DynamicField'
import { useSelector, useDispatch } from 'react-redux'
import { CREATE_PAYOR, uploadPayorDocument, GET_BCBA_STAFF, UPDATE_PAYOR } from './query'
import styles from './style.module.scss'

const AddEditPayor = props => {
  const { payorProfile, form } = props
  const dispatch = useDispatch()
  const [plans, setPlans] = useState([])
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const scrollContainer = useRef()
  const [insuranceType, setInsuranceType] = useState({ medicaid: false, private: false })

  const handleInsuranceTypeChange = (value, checked) => {
    const defaultInsuranceType = { medicaid: false, private: false }
    defaultInsuranceType[value] = checked
    setInsuranceType(defaultInsuranceType)
  }

  useEffect(() => {
    const { edit, payorProfile } = props

    if (edit) {
      setFormFields()
    }

    dispatch({
      type: actions.GET_PAYOR_CONTACT_TYPE,
    })

    setPlans(
      payorProfile?.payorplanSet.edges.map(({ node }) => {
        return node.plan
      }) || [],
    )
  }, [dispatch])

  const setFormFields = () => {
    form.setFieldsValue({
      name: payorProfile?.name,
      email: payorProfile?.email,
      phoneNumber: payorProfile?.phoneNumber,
      faxNumber: payorProfile?.faxNumber,
      street: payorProfile?.street,
      city: payorProfile?.city,
      state: payorProfile?.state,
      country: payorProfile?.country,
      zipCode: payorProfile?.zipCode,
      streetAddress: payorProfile?.streetAddress,
    })
    setInsuranceType({ medicaid: payorProfile?.Medicaid, private: payorProfile?.private })
  }

  const scrollToIndex = index => {
    const offsetTop = []
    scrollContainer.current.view.childNodes.forEach(child => offsetTop.push(child.offsetTop))
    scrollContainer.current.scrollTop(offsetTop[index])
  }

  const uploadDocuments = (fileList, payorId) => {
    uploadPayorDocument({
      fileList,
      payorId,
    })
      .then(docsResponse => {
        if (docsResponse && docsResponse.message === 'OK') {
          if (docsResponse.fileUrl.length) {
            notification.success({
              message: 'Document added to Payor successfully.',
            })
          }
        } else {
          notification.error({
            message: 'An error occurred to upload Document.',
            description: docsResponse.message,
          })
        }
      })
      .catch(err => {
        console.error(err, 'doc error')
        notification.error({
          message: 'An error occurred to upload Document.',
        })
      })
  }

  const handleEditPayor = () => {
    const { form, closeDrawer, payorProfile, refetchPayors } = props

    form.validateFields(async (error, values) => {
      values.pk = payorProfile?.id
      values.payorPlan = values.payorPlans?.map(val => ({ plan: val })) || []

      if (!error) {
        setLoading(true)
        client
          .mutate({
            mutation: UPDATE_PAYOR,
            variables: {
              ...values,
              Medicaid: insuranceType.medicaid,
              private: insuranceType.private,
            },
          })
          .then(res => {
            if (res.data && res.data.updatePayor) {
              notification.success({
                message: 'Payor Updated Successfully',
              })

              // Upload Documents
              if (fileList.length > 0) {
                uploadDocuments(fileList, res.data.updatePayor.details.id)
              }
              setLoading(false)
              closeDrawer()
              refetchPayors()
              form.resetFields()
            }
          })
          .catch(err => {
            setLoading(false)
            console.log(err)
          })
      }
    })
  }

  const handleCreatePayor = () => {
    const { form, closeDrawer, refetchPayors } = props

    form.validateFields(async (error, values) => {
      values.payorPlan = values.payorPlans?.map(val => ({ plan: val })) || null

      if (!error) {
        setLoading(true)
        client
          .mutate({
            mutation: CREATE_PAYOR,
            variables: {
              ...values,
              Medicaid: insuranceType.medicaid,
              private: insuranceType.private,
            },
          })
          .then(res => {
            if (res.data && res.data.createPayor?.details) {
              notification.success({
                message: 'Payor Created Successfully',
              })

              // Upload Documents
              if (fileList.length > 0) {
                uploadDocuments(fileList, res.data.createPayor.details.id)
              }
              setLoading(false)
              form.resetFields()
              if (refetchPayors) {
                refetchPayors()
              }
              if (closeDrawer) {
                closeDrawer()
              }
            }
            return res
          })
          .catch(err => {
            setLoading(false)
            console.error(err)
          })
      }
    })
  }

  const handleSubmit = e => {
    const { edit } = props
    e.preventDefault()
    if (edit) {
      handleEditPayor()
    } else {
      handleCreatePayor()
    }
  }

  const onReset = () => {
    const { form } = props
    form.resetFields()
  }

  const { contactTypes, closeDrawer } = props

  const uploadDragerProps = {
    onRemove: file => {
      setFileList(fileList => {
        const index = fileList.indexOf(file)
        const newFileList = fileList.slice()
        newFileList.splice(index, 1)
        return newFileList
      })
    },
    beforeUpload: file => {
      setFileList(fileList => [...fileList, file])
      return false
    },
    fileList,
  }

  return (
    <Form
      style={{ padding: '1rem' }}
      layout="vertical"
      className={styles.formMainContainer}
      onSubmit={handleSubmit}
    >
      <div className={styles.formNavigation}>
        <Button type="link" onClick={() => scrollToIndex(0)}>
          1. Basic Details
        </Button>
        <Button type="link" onClick={() => scrollToIndex(1)}>
          2. Address
        </Button>
        <Button type="link" onClick={() => scrollToIndex(2)}>
          3. Plan Details
        </Button>
        <Button type="link" onClick={() => scrollToIndex(3)}>
          4. Upload Documents
        </Button>
      </div>
      <Scrollbars
        ref={scrollContainer}
        style={{ height: '80vh', scrollBehavior: 'smooth' }}
        className={styles.scrollContainer}
        autoHide
      >
        <div className={styles.formSection}>
          <h3>Basic Details</h3>
          <Row gutter={16}>
            <Col span={12}>
              {/* Name */}
              <Form.Item label="Name">
                {form.getFieldDecorator('name', {
                  initialValue: '',
                  rules: [{ required: true, message: 'Please provide name' }],
                })(<Input placeholder="Enter name" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phone Number">
                {form.getFieldDecorator('phoneNumber', {
                  initialValue: '',
                })(<Input placeholder="Enter phone number" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Fax Number">
                {form.getFieldDecorator('faxNumber', {
                  initialValue: '',
                })(<Input placeholder="Enter fax number" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" align="middle" justify="space-between">
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={insuranceType.medicaid}
                  onChange={e => handleInsuranceTypeChange('medicaid', e.target.checked)}
                >
                  Medicaid
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={insuranceType.private}
                  onChange={e => handleInsuranceTypeChange('private', e.target.checked)}
                >
                  Private Insurance
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className={styles.formSection}>
          <h3>Address</h3>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Street Address">
                {form.getFieldDecorator(
                  'streetAddress',
                  {},
                )(<Input placeholder="Enter Street Address" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Zip Code">
                {form.getFieldDecorator('zipCode', {})(<Input placeholder="Enter Zip Code" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="State">
                {form.getFieldDecorator('state', {})(<Input placeholder="Enter State name" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="City">
                {form.getFieldDecorator('city', {})(<Input placeholder="Enter City name" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Country">
                {form.getFieldDecorator('country', {})(<Input placeholder="Enter Country name" />)}
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className={styles.formSection}>
          <h3>Plan Details</h3>
          <Row type="flex" justify="center">
            <Col span={20}>
              <DynamicFields
                label="Plans"
                form={form}
                required={false}
                values={plans}
                setValues={setPlans}
                fieldName="payorPlans"
                formItem={<Input style={{ width: '60%' }} placeholder="Enter plan name" />}
              />
            </Col>
          </Row>
        </div>

        <div className={styles.formSection}>
          <h3>Upload Documents</h3>
          {/* File Dragger */}
          <Row type="flex" justify="center">
            <Col span={24}>
              <Form.Item>
                <Upload.Dragger multiple {...uploadDragerProps}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Row>
          {payorProfile?.docs?.edges.length > 0 && (
            <>
              <h3>Uploaded Documents</h3>
              <div>
                {payorProfile.docs.edges.map(({ node: file }) => {
                  return (
                    <Button
                      type="link"
                      style={{ textAlign: 'left', display: 'block' }}
                      href={file.path}
                    >
                      {file.path}
                    </Button>
                  )
                })}
              </div>
            </>
          )}
        </div>

        {/* Buttons */}
        <Form.Item type="flex" justify="center">
          <Button type="primary" htmlType="submit" loading={loading} style={SUBMITT_BUTTON}>
            Save
          </Button>
          <Button
            onClick={() => {
              form.resetFields()
              closeDrawer()
            }}
            style={CANCEL_BUTTON}
          >
            Cancel
          </Button>
        </Form.Item>
      </Scrollbars>
    </Form>
  )
}

export default Form.create()(AddEditPayor)
