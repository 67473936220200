/* eslint-disable no-unused-expressions */
import { Col, Form, notification, Row, Select, Switch } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import LoadingComponent from 'components/LoadingComponent'
import { useMutation, useQuery } from 'react-apollo'
import { SEARCHABLE_DROPDOWN_OPTIONS } from 'utilities'
import { useTargetAlloc } from './context'
import MasterySequence from './MasterySequence'
import AntdTag from './antdTag'
import { CREATE_TARGET, RECORDING_TYPE } from './query'
import '../style.scss'
import StudentCard from './FormCards/studentCard'
import AttachmentsCard from './FormCards/attachmentsCard'
import MiscInfoCard from './FormCards/miscInfoCard'
import NumberCard from './FormCards/numberCard'
import PhaseCard from './FormCards/phaseCard'
import StgCard from './FormCards/stgCard'
import SubmitButtonCard from './FormCards/submitButtonCard'
import BehaviorFieldCard from './FormCards/behaviorFieldCard'

const dateFormat = 'YYYY-MM-DD'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

// activeBehave = 'recording'

export default Form.create()(
  ({
    form,
    selectedTargetId = null,
    targetName,
    defaultDomain,
    targetVideo,
    selectedShortTermGoal,
    editable = false,
    closeDrawer,
    onSuccessTargetAllocation,
    addTargetMode,
    editStudent,
    setTargetFormType,
    showBackButton,
  }) => {
    const [dailyTrials, setDailyTrials] = useState(0)
    const [sessionConsecutiveDays, setSessionConsecutiveDays] = useState(0)
    const [trialDuration, setTrialDuration] = useState(30)

    const [phaseList, setPhaseList] = useState([])
    const [tagArray, setTagArray] = useState([])

    const [defaultSettings, setDefaultSettings] = useState(null)

    const [targetAtt, setTargetAtt] = useState([])

    const { getFieldDecorator, getFieldValue } = form

    const {
      useDefaultGoal,
      selectedStudent,
      targetStatusLoading,
      targetOptions,
      targetOptionsLoading,
      isDomainLoading,
      settingData,
      shortGoalLoading,
      mastSeqTemplate,
      manualMastActive,
      defaultShortTermGoalIdForABA,
      setManualMastActive,
      setSelectedTab,
      mstSeqObj,
      setMstSeqObj,
      fetchMasteryData,
      selectedTab,
      selectedProgram,
      profileMstLoading,
    } = useTargetAlloc()

    const {
      data: recordingTypeData,
      error: recordingTypeError,
      loading: recordingTypeLoading,
    } = useQuery(RECORDING_TYPE)

    const [allocateTarget, { loading: allocateTargetLoading }] = useMutation(CREATE_TARGET)

    useEffect(() => {
      const vl = form.getFieldValue('type')
      const rType = form.getFieldValue('recordingType')
      if (vl === 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==') {
        if (rType && rType.length === 1 && rType[0] === 'UmVjb3JkaW5nVHlwZVR5cGU6MQ==') {
          setSelectedTab('Behavior Recording Frequency')
          form.setFieldsValue({ masteryCriteriaBehavior: null })
        } else if (selectedTab !== 'Behavior Recording Duration') {
          setSelectedTab('Behavior Recording Duration')
          form.setFieldsValue({ masteryCriteriaBehavior: null })
        }
      }
    }, [form.getFieldValue('recordingType'), settingData, targetOptions])

    useEffect(() => {
      if (settingData && editable === false) {
        setDailyTrials(settingData.getAllocateTargetSettings.edges[0]?.node.dailyTrials || 5)
        setSessionConsecutiveDays(
          settingData.getAllocateTargetSettings.edges[0]?.node.consecutiveDays || 25,
        )
        setDefaultSettings({
          dailyTrials: settingData.getAllocateTargetSettings.edges[0]?.node.dailyTrials,
          consecutiveDays: settingData.getAllocateTargetSettings.edges[0]?.node.consecutiveDays,
          status: settingData.getAllocateTargetSettings.edges[0]?.node.status,
          masteryCriteria: settingData.getAllocateTargetSettings.edges[0]?.node.masteryCriteria,
          targetType: settingData.getAllocateTargetSettings.edges[0]?.node.targetType,
        })
      }
    }, [settingData])

    useEffect(() => {
      const mstId = getFieldValue('masteryCriteriaBehavior')
      if (mstId && !manualMastActive) {
        fetchMasteryData(mstId)
      }
    }, [getFieldValue('masteryCriteriaBehavior')])

    useEffect(() => {
      if (recordingTypeError) {
        console.error(recordingTypeError)
      }
    }, [recordingTypeError])

    const onChangeNumber = (type, num) => {
      if (type === 'sdt') setDailyTrials(num)
      else if (type === 'scd') setSessionConsecutiveDays(num)
      else if (type === 'trdc') {
        setTrialDuration(num)
      }
    }

    const handleSubmit = e => {
      e.preventDefault()
      form.validateFieldsAndScroll((error, values) => {
        let validationMessage = null

        const docsResponse = []

        targetAtt?.forEach(item => {
          docsResponse.push({
            sd: '',
            step: '',
            url: item.url,
          })
        })
        if (values.tarUrl) {
          docsResponse.push({
            sd: '',
            step: '',
            url: values.tarUrl,
          })
        }

        const phaseResponse = phaseList.map(item => ({
          date: item.date?.format(dateFormat),
          content: item.note,
        }))

        let manualCriteriaTemplate = null
        if (manualMastActive) {
          manualCriteriaTemplate = {
            description: mstSeqObj.description,
            templateType: selectedTab,
            criteriaPhases: mstSeqObj.phaseSet.map((item, idx) => {
              return {
                criteriaType: item.criteriaType,
                prompts: item.prompt,
                autoLabel: item.autoLabel,
                stepNo: idx + 1,
                criteriaPhaseTargets: item.masterySet.map(mp => ({
                  calculationType: mp.calculationType,
                  percentage: mp.percentage,
                  calFrameType: mp.calFrameType,
                  durationCalType: mp.durationCalType,
                  calFrameDurationType: mp.calFrameDurationType,
                  calFrameDurationValue: mp.calFrameDurationValue,
                  minimumTrial: mp.minimumTrial,
                  progressTo: mp.progressTo,
                  phaseChangeLabel: mp.phaseChangeLabel,
                  duration: mp.duration,
                  frequency: mp.frequency,
                })),
              }
            }),
          }
        }

        // console.log(manualCriteriaTemplate)

        if (!error) {
          // Other custom validations
          if (useDefaultGoal && !defaultShortTermGoalIdForABA) {
            validationMessage = 'No default short term Goal found.'
          } else if (editable === false && !selectedStudent?.id) {
            validationMessage = 'Student not found, Please try again.'
          } else if (editable === false && addTargetMode !== 'manually' && !selectedTargetId) {
            validationMessage = 'Target not found.'
          } else if (dailyTrials < 0) {
            validationMessage = 'Daily trials should be greater than or equal to 0'
          } else if (!values.masteryCriteriaBehavior && !manualCriteriaTemplate) {
            validationMessage = 'Please select mastery criteria or provide manual mastery condition'
          }
          if (validationMessage) {
            notification.info({ message: validationMessage })
          } else if (editable === false) {
            allocateTarget({
              variables: {
                studentId: selectedStudent.id,
                programArea: selectedProgram,
                shortTerm: useDefaultGoal ? defaultShortTermGoalIdForABA : values.stg,
                targetId: selectedTargetId,
                targetStatus: values.status,
                targetInstr: '',
                date: moment(values.date).format('YYYY-MM-DD'),
                masteryCriteria: values.masteryCriteriaBehavior,
                targetName: values.name,
                consecutiveDays: sessionConsecutiveDays,
                targetType: values.type,
                sd: [],
                steps: [],
                video: values.video ? [values.video] : [],
                peakBlocks: 1,
                peakType: null,
                equiCode: null,
                domain: values.domain,
                makeDefault: values.makeDefault,
                recordingBehavior: values.behavior,
                dailyTrials,
                classes: [],
                targetDocs: docsResponse,
                manualMastery: [],
                prompts: values.prompt,
                phaset: phaseResponse,
                r1: null,
                r2: null,
                sbtSteps: [],
                recordingType: values.recordingType,
                trialDuration,
                behaviourDescription: values.behaviourDescription,
                reactiveProcedure: values.reactiveProcedure,
                antecedentManipulation: values.antecedentManipulation,
                emailMasterStatus: values.emailMasterStatus,
                emailSend: values.emailSend,
                caseManagerEmail: values.caseManagerEmail,
                plotZero: values.plotZero,
                tag: tagArray || [],
                sessionAllocation: values.sessions,
                criteriaTemplate: manualMastActive ? null : values.masteryCriteriaBehavior,
                manualCriteriaTemplate,
                activeSdStepCount: 0,
                isSdStepRandomize: false,
              },
              errorPolicy: 'all',
            })
              .then(res => {
                if (res.data.createTargetAllocate3) {
                  notification.success({
                    message: 'Target allocated successfully',
                  })
                  form.resetFields()
                  if (onSuccessTargetAllocation) {
                    onSuccessTargetAllocation()
                  }
                  if (closeDrawer) {
                    closeDrawer()
                  }
                }
              })
              .catch(err => console.error(err))
          }
        }
      })
    }

    if (shortGoalLoading || targetOptionsLoading || isDomainLoading) {
      return <LoadingComponent />
    }

    return (
      <div>
        <Form onSubmit={handleSubmit}>
          <StudentCard
            setTargetFormType={setTargetFormType}
            form={form}
            editStudent={editStudent}
            showBackButton={showBackButton}
          />

          <div style={boxCard}>
            {/* short goal, domain, target name and target type */}
            <StgCard
              form={form}
              addTargetMode={addTargetMode}
              targetName={targetName}
              defaultSettings={defaultSettings}
              defaultDomain={defaultDomain}
              activeBehave="recording"
              selectedShortTermGoal={selectedShortTermGoal}
            />
            {/* status, recording type and tag */}
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    initialValue: defaultSettings?.status?.id || targetOptions?.targetStatus[5]?.id,
                    rules: [{ required: true, message: 'Please select a target status' }],
                  })(
                    <Select
                      loading={targetOptionsLoading || targetStatusLoading}
                      {...SEARCHABLE_DROPDOWN_OPTIONS}
                    >
                      {targetOptions?.targetStatus
                        .filter(
                          ({ id }) => !(id === 'U3RhdHVzVHlwZTo3' || id === 'U3RhdHVzVHlwZTo2'),
                        )
                        .map(({ id, statusName }) => {
                          return (
                            <Select.Option key={id} value={id}>
                              {statusName}
                            </Select.Option>
                          )
                        })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              {console.log('Recording types option', recordingTypeData)}

              <Col span={8}>
                <Form.Item label="Recording Type" name="Recording Type">
                  {getFieldDecorator('recordingType', {
                    initialValue: recordingTypeData?.recordingType?.map(node => node.id),
                    rules: [{ required: true, message: 'Please select a recording type' }],
                  })(
                    <Select
                      mode="multiple"
                      name="domain"
                      {...SEARCHABLE_DROPDOWN_OPTIONS}
                      loading={recordingTypeLoading}
                    >
                      {recordingTypeData?.recordingType?.map(node => (
                        <Select.Option key={node.id} value={node.id}>
                          {node.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Tag" name="Tag">
                  <AntdTag
                    changeTagsHandler={e => setTagArray(e)}
                    closeable="true"
                    tagArray={tagArray}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Behavior type */}
            <BehaviorFieldCard
              form={form}
              required
              name="Behavior"
              label="Behavior"
              decoratorName="behavior"
            />

            {/* Number fields */}
            {/* Remove Trial and consecutive from behaviour */}
            {/* <Row gutter={[24]}>
              <Col span={8}>
                <NumberCard
                  required
                  label="Trial Duration"
                  number={trialDuration}
                  form={form}
                  maxValue={300}
                  setNumber={num => onChangeNumber('trdc', num)}
                  addonAfter="sec"
                />
              </Col>
              <Col span={8}>
                <NumberCard
                  required
                  label="Daily Trials"
                  number={dailyTrials}
                  form={form}
                  setNumber={num => onChangeNumber('sdt', num)}
                />
              </Col>

              <Col span={8}>
                <NumberCard
                  label="Consecutive Days"
                  number={sessionConsecutiveDays}
                  form={form}
                  setNumber={num => onChangeNumber('scd', num)}
                />
              </Col>
            </Row> */}
          </div>

          {/* Mastery Sequence */}
          <div style={boxCard}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginBottom: 12,
              }}
            >
              <Form.Item
                label="Mastery Criteria"
                style={{ marginBottom: 0, width: '30%' }}
                name="masteryCriteriaBehavior"
              >
                {getFieldDecorator('masteryCriteriaBehavior', {
                  initialValue: mastSeqTemplate[0] ? mastSeqTemplate[0].id : null,
                  rules: [
                    {
                      required: !manualMastActive,
                      message: 'Please select mastery sequence tmeplate',
                    },
                  ],
                })(
                  <Select
                    disabled={manualMastActive}
                    name="masteryCriteriaBehavior"
                    loading={targetOptionsLoading}
                    {...SEARCHABLE_DROPDOWN_OPTIONS}
                  >
                    {mastSeqTemplate.map(({ id, name }) => {
                      return (
                        <Select.Option key={id} value={id}>
                          {name}
                        </Select.Option>
                      )
                    })}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item>
                <Switch
                  checkedChildren="Manual Mastery"
                  unCheckedChildren="Default Mastery"
                  checked={manualMastActive}
                  onChange={setManualMastActive}
                />
              </Form.Item>
            </div>
            {profileMstLoading ? (
              <LoadingComponent />
            ) : (
              mstSeqObj && (
                <MasterySequence
                  manualMastActive={manualMastActive}
                  mstSeqObj={mstSeqObj}
                  setMstSeqObj={setMstSeqObj}
                />
              )
            )}
          </div>

          {/* phase cards */}
          <PhaseCard phaseList={phaseList} setPhaseList={setPhaseList} />

          <AttachmentsCard form={form} targetAtt={targetAtt} setTargetAtt={setTargetAtt} />

          <MiscInfoCard form={form} targetVideo={targetVideo} activeBehave="recording" />

          <SubmitButtonCard
            form={form}
            closeDrawer={closeDrawer}
            submitLoading={allocateTargetLoading}
          />
        </Form>
      </div>
    )
  },
)
