/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Drawer,
  Table,
  Tooltip,
  notification,
  Popconfirm,
  Divider,
  Form,
  Icon,
  Button,
  Modal,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { DRAWER, COLORS } from 'assets/styles/globalStyles'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useLazyQuery, useMutation } from 'react-apollo'
import { UPDATE_TARGET } from './query'
import _ from 'lodash'
import PhaseModal from 'pages/ProgramBook/TargetAllocation/phaseModal'
const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const card = {
  padding: '12px',
  marginBottom: 10,
  boxShadow: '0 3px 5px rgba(22, 22, 53, 0.16)',
}

const PhaseTable = props => {
  const { selectedTarget, date, phaseData, setPhaseData } = props
  console.log('props>>>', props)
  const [dataSource, setDataSource] = useState([])

  const [addPhaseModal, setAddPhaseModal] = useState(false)
  const [phaseList, setPhaseList] = useState([])
  const [phaseObject, setphaseObject] = useState(null)
  const [submit, setSubmit] = useState(false)

  const [updatePhase, { data, loading, error }] = useMutation(UPDATE_TARGET)

  useEffect(() => {
    if (selectedTarget) {
      console.log('selected Target>>>', selectedTarget.phaset.edges)
      const tempPhase = []
      selectedTarget.phaset?.edges.forEach(({ node }) => {
        tempPhase.push({
          key: node.id,
          date: moment(node.date),
          note: node.content,
        })
      })
      const filtered = tempPhase.filter(
        item => moment(item.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'),
      )
      console.log('filtered>>>', filtered, date, tempPhase)
      setPhaseList(tempPhase)

      setDataSource(selectedTarget?.phaset.edges)
    }
  }, [selectedTarget])

  // useEffect(()=>{

  //   if(phaseList){
  //     console.log("phaseline>>>>>",phaseList);
  //     if(submit){
  //       handleSubmit(submit)
  //     }

  //   }

  // },[phaseList])

  useEffect(() => {
    if (phaseList) {
      console.log('phaseData>>>', phaseData)
      console.log('phaseList>>>', phaseList)
    }
  }, [phaseList])

  const handleSubmit = val => {
    console.log('valll>>>>**', val, phaseList)

    if (val) {
      const tempPhase = []
      // selectedTarget && selectedTarget.phaset?.edges.forEach(({ node }) => {
      //   tempPhase.push({
      //     key: node.id,
      //     date: moment(node.date),
      //     note: node.content,
      //   })
      // })
      // const d=_.unionBy(tempPhase,val,'key');
      // console.log("tempPhase**",tempPhase,d);
      const phaseResponse = val.map(item => ({
        date: item.date?.format('YYYY-MM-DD'),
        content: item.note,
      }))
      updatePhase({
        variables: {
          pk: selectedTarget?.id,
          phaset: phaseResponse,
          shortTerm: selectedTarget?.shortTerm?.id,
        },
      })
        .then(res => {
          console.log('res===>', res.data.updateTargetAllocate2?.target?.phaset?.edges)
          setPhaseData(res.data.updateTargetAllocate2?.target?.phaset?.edges)
          notification.success({
            message: 'Phase Change updated successfully',
          })
        })
        .catch(er => console.error(er))
    }
  }

  const phaseCard = item => {
    return (
      <div key={item.key} style={card}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>{item.note}</span>
          <div style={{ minWidth: 70 }}>
            <Button
              style={{ color: '#46bd84', paddingRight: 0 }}
              type="link"
              onClick={() => {
                setphaseObject(item)
                setAddPhaseModal(true)
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Are you sure you don't want this record?"
              onConfirm={() => {
                const temp = phaseList.filter(ph => ph.key !== item.key)
                setPhaseList(temp)
                handleSubmit(temp)
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                style={{ marginLeft: 'auto', color: COLORS.danger, paddingRight: 0 }}
                type="link"
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        </div>
        <p style={{ fontSize: 12 }}>{moment(item.date).format('YYYY-MM-DD')}</p>
      </div>
    )
  }

  const phaseColumns = [
    {
      title: 'Date',
      dataIndex: 'node.date',
      render: (row, text) => {
        console.log('row>>>', row)
        console.log('text>>>', text)
        return moment(text).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Desciption',
      dataIndex: 'node.content',
    },
    {
      title: 'Action(s)',
      dataIndex: '',
      align: 'center',
      colSpan: 1,
      render: obj => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Tooltip placement="top" title="Edit">
              <EditOutlined
                style={{ fontSize: 18, color: 'blue', marginLeft: 5 }}
              //   onClick={() => {
              //     setEditItemFor(obj)
              //   }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Popconfirm
                title="Are you sure to delete this item？"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                // onConfirm={e => {
                //   //deleteBehaviorTemplate(obj)
                // }}
                okText="Yes"
                cancelText="No"
                onCancel={e => e.stopPropagation()}
              >
                <DeleteOutlined style={{ fontSize: 18, marginLeft: 10, color: 'red' }} />
              </Popconfirm>
            </Tooltip>
          </div>
        )
      },
    },
  ]
  return (
    <div>
      <div style={boxCard}>
        <Divider orientation="left">Phase Change</Divider>
        {phaseList.map(item => phaseCard(item))}
        <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ sm: 24, md: 24 }}>
          <Button
            type="dashed"
            onClick={() => {
              setAddPhaseModal(true)
              setphaseObject(null)
            }}
            style={{ width: '60%' }}
          >
            <Icon type="plus" /> Add Field
          </Button>
        </Form.Item>
      </div>
      {/* <Table columns={phaseColumns} dataSource={dataSource} bordered /> */}

      <Modal
        visible={addPhaseModal}
        title="Add New Phase"
        onCancel={() => {
          setAddPhaseModal(false)
          setphaseObject(null)
        }}
        zIndex={1001}
        footer={null}
        width={700}
        destroyOnClose
      >
        <PhaseModal
          setPhaseList={setPhaseList}
          phaseList={phaseList}
          date={date}
          phaseObject={phaseObject}
          submit={val => handleSubmit(val)}
          closeModal={() => {
            setAddPhaseModal(false)
            setphaseObject(null)
          }}
        />
      </Modal>
    </div>
  )
}

export default PhaseTable
