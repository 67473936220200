import { Button, Form, notification } from 'antd'
import { CANCEL_BUTTON, FORM, SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import React from 'react'
import { useMutation } from 'react-apollo'
import { GENERATE_LINK, PAYMENT_REMINDER } from './query'

const { layout, tailLayout } = FORM

function SendPaymentLinks({ selectedRowKeys, payReminderData, closeDrawer, refetchInvoices }) {
  const [generatePaymentLink, { loading: generatePaymentLinkLoading }] = useMutation(GENERATE_LINK)
  const [sendPaymentReminder, { loading: sendPaymentReminderLoading }] = useMutation(
    PAYMENT_REMINDER,
  )

  // console.log(payReminderData, 'payReminderData')

  const handleSubmitt = () => {
    if (payReminderData && payReminderData.length > 0) {
      const createIds = []
      const sendNotiIds = []
      payReminderData.map(item => (item.linkGenerated === false ? createIds.push(item.key) : null))
      payReminderData.map(item =>
        item.linkGenerated &&
        (item.status === 'Sent' || item.status === 'Partially Paid' || item.status === 'Pending')
          ? sendNotiIds.push(item.key)
          : null,
      )

      // console.log(createIds, 'createIds')
      // console.log(sendNotiIds, 'sendNotiIds')
      try {
        if (createIds.length > 0) {
          generatePaymentLink({
            variables: {
              pk: createIds,
            },
          }).then(res => {
            console.log(res, 'this is res1')
            if (sendNotiIds.length > 0) {
              sendPaymentReminder({
                variables: {
                  invoices: sendNotiIds,
                },
              }).then(resp => {
                console.log(resp, 'this is res2')
                if (
                  resp.data.sendPaymentReminders &&
                  resp.data.sendPaymentReminders.status === true
                ) {
                  // refetchInvoices()
                  // refetchInvoices2()
                  notification.success({
                    message: 'Invoice sent successully',
                  })
                  closeDrawer()
                }
              })
            } else {
              // refetchInvoices()
              // refetchInvoices2()
              notification.success({
                message: 'Invoice sent successully',
              })
              closeDrawer()
            }
          })
        } else if (sendNotiIds.length > 0) {
          sendPaymentReminder({
            variables: {
              invoices: sendNotiIds,
            },
          }).then(resp => {
            if (resp.data.sendPaymentReminders && resp.data.sendPaymentReminders.status === true) {
              // refetchInvoices()
              // refetchInvoices2()
              notification.success({
                message: 'Invoice sent successully',
              })
              closeDrawer()
            }
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <div>
      {payReminderData && payReminderData?.length > 0 ? (
        <>
          <Form.Item>
            <div style={{ width: 500, margin: '0 auto' }}>
              <p style={{ fontSize: 20, fontWeight: 600 }}>Selected Invoices: </p>
              <ol
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  fontSize: 15,
                  fontWeight: 600,
                  paddingLeft: 15,
                }}
              >
                {payReminderData &&
                  payReminderData.map((item, index) => (
                    <li key={item.key} style={{ width: 340 }}>
                      {item.name ? `${item.invNo} - ${item.name}` : `${item.invNo} - ${item.email}`}
                    </li>
                  ))}
              </ol>
              <div style={{ textAlign: 'center' }}>
                <b>Create and send payments links for the selected invoices</b>
              </div>
            </div>
          </Form.Item>
          <Form.Item>
            <div style={{ textAlign: 'center' }}>
              <Button
                type="default"
                loading={generatePaymentLinkLoading || sendPaymentReminderLoading}
                onClick={e => handleSubmitt(e)}
                style={SUBMITT_BUTTON}
              >
                Send Invoices
              </Button>
              <Button onClick={closeDrawer} type="ghost" style={CANCEL_BUTTON}>
                Cancel
              </Button>
            </div>
          </Form.Item>
        </>
      ) : (
        <b>None, Please select at least one invoice</b>
      )}
    </div>
  )
}

export default SendPaymentLinks
