/* eslint-disable react/destructuring-assignment */
/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import { Tag, Input, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const EditableTagGroup = ({ changeTagsHandler, tagArray, closeable }) => {
  const [tags, setTags] = useState(new Array())
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  let input = useRef(null)
  let editInput = useRef(null)

  useEffect(() => {
    if (inputVisible) {
      input.current.focus()
    }
  }, [inputVisible])

  const handleClose = removedTag => {
    const Tags = tagArray?.filter(tag => tag !== removedTag)
    setTags(Tags)
    changeTagsHandler(Tags)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    let Tags = tags ? tags : []

    if (inputValue) {
      if (Tags.length > 0 && tagArray?.length > 0 && Tags?.indexOf(inputValue) === -1) {
        Tags = [...tagArray, inputValue]
      } else if (tags.length === 0 && tagArray?.length > 0) {
        Tags = [...tagArray, inputValue]
      } else if (tags.length === 0) {
        Tags = [inputValue]
      }
    } else {
      Tags = tagArray
    }

    setTags(Tags)
    setInputVisible(false)
    setInputValue('')

    changeTagsHandler(Tags)
  }

  const handleEditInputChange = e => {
    setEditInputValue(e.target.value)
  }
  const saveInputRef = inp => {
    input = inp
  }

  const saveEditInputRef = inp => {
    editInput = inp
  }

  return (
    <div
      style={{
        display: 'flex',
        minHeight: 35,
        flexWrap: 'wrap',
        alignContent: 'center',
      }}
    >
      {tagArray?.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInput}
              key={tag}
              placeholder="Enter tag name"
              style={{ margin: 'auto 2px', minWidth: 100, maxWidth: 160 }}
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          )
        }

        const isLongTag = tag.length > 20

        const tagElem = (
          <Tag
            key={tag}
            style={{ margin: 'auto 2px', fontSize: 14 }}
            closable={closeable}
            onClose={() => handleClose(tag)}
          >
            <span
              onClick={e => {
                if (index !== 0) {
                  setEditInputIndex(index)
                  setEditInputValue(tag)
                  editInput.focus()

                  e.preventDefault()
                }
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
      {inputVisible && (
        <Input
          ref={input}
          placeholder="Enter tag name"
          type="text"
          value={inputValue}
          style={{ margin: 'auto 2px', minWidth: 100, maxWidth: 160 }}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          className="site-tag-plus"
          style={{ margin: 'auto 2px', cursor: 'pointer', fontSize: 14 }}
          onClick={showInput}
        >
          <PlusOutlined />
        </Tag>
      )}
    </div>
  )
}

export default EditableTagGroup
