/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */

import React, { useState, useEffect } from 'react'
import { EditOutlined, FileTextOutlined } from '@ant-design/icons'
import { Tag, Tooltip, Button } from 'antd'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import moment from 'moment'
import './styles.scss'
import ProgBar from './progBar'

function LtgCard({ targetData }) {
  const domain = targetData?.manualAllocateDomain || targetData?.targetId?.domain
  const targetType = targetData?.targetAllcatedDetails?.targetType

  return (
    <div className="ltg-card" style={{ display: 'flex' }}>
      <div style={{ width: 35, height: '100%' }}>
        <FileTextOutlined
          style={{ marginTop: 6, fontSize: 20, fontWeight: 600, color: COLORS.primary }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <div style={{ fontSize: 16, width: '100%' }}>
          {targetData?.targetAllcatedDetails?.targetName}
        </div>
        <div>
          <span>Some Info</span>
          <Button style={{ float: 'right' }} type="link">
            <EditOutlined />
          </Button>
        </div>
        <div>
          {domain?.domain && (
            <Tag style={{ marginTop: 3 }} color="purple">
              {domain?.domain.length > 30 ? (
                <Tooltip title={domain?.domain}>{`${domain?.domain.slice(0, 30)}...`}</Tooltip>
              ) : (
                domain?.domain
              )}
            </Tag>
          )}
          {targetType?.typeTar && (
            <Tag style={{ marginTop: 3 }} color="geekblue">
              {targetType?.typeTar.length > 30 ? (
                <Tooltip title={targetType?.typeTar}>
                  {`${targetType?.typeTar.slice(0, 30)}...`}
                </Tooltip>
              ) : (
                targetType?.typeTar
              )}
            </Tag>
          )}
        </div>
      </div>
    </div>
  )
}

export default LtgCard
