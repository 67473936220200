/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import WrapperDiv from 'components/WrapperCard'
import ML from './ML'

const MLTab = props => {
  const dispatch = useDispatch()
  const selectedLearnerId = useSelector(state => state.learnersprogram.SelectedLearnerId)
  const learners = useSelector(state => state.learnersprogram.Learners)
  const student = useSelector(state => state.student)
  const [studentName, setStudentName] = useState('')

  useEffect(() => {
    dispatch({
      type: 'learnersprogram/LOAD_DATA',
    })
  }, [])

  useEffect(() => {
    if (learners.length !== 0 && student.StudentName.length === 0) {
      let selectedStu
      if (localStorage.getItem('studentId')) {
        const stuId = JSON.parse(localStorage.getItem('studentId'))
        selectedStu = learners.filter(item => item.node.id === stuId)
      } else {
        // eslint-disable-next-line prefer-destructuring
        selectedStu = learners
      }
      setStudentName(selectedStu[0].node.firstname)
    }
  }, [learners])

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}
      className="Assessment-container"
    >
      <ML key={selectedLearnerId} />
    </div>
  )
}
export default MLTab
