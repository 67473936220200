/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_TEMPLATES = gql`
  query getBehaviorTemplates($studentId: ID!, $before: String, $after: String) {
    getBehaviorTemplates(student: $studentId, before: $before, after: $after) {
      edges {
        node {
          id
          behaviorType
          templateName
          behavior {
            id
            behaviorName
            definition
          }
          createdAt
          status {
            id
            statusName
          }
          student {
            id
          }
          description
        }
      }
    }
  }
`

export const BEHAVIOR_RECORD_DATA = gql`
  query getDecelData($dateGte: Date!, $dateLte: Date!, $studentId: ID!, $status: ID) {
    getDecelData(
      template_Student: $studentId
      date_Gte: $dateGte
      date_Lte: $dateLte
      status: $status
    ) {
      edges {
        node {
          id
          irt
          note
          duration
          date
          intensity
          environment {
            id
          }
          frequency {
            edges {
              node {
                id
                count
                time
              }
            }
          }
          template {
            id
            behavior {
              id
              behaviorName
            }
            environment {
              edges {
                node {
                  id
                  name
                }
              }
            }
            measurments {
              edges {
                node {
                  id
                  measuringType
                }
              }
            }
          }
          status {
            id
            statusName
          }
        }
      }
    }
  }
`

export const GET_BEHAVIOR_TEMPLATES = gql`
  query getBehaviorTemplates($student: ID!) {
    getBehaviorTemplates(student: $student) {
      edges {
        node {
          id
          behaviorType
          behavior {
            id
            behaviorName
            definition
          }
          createdAt
          status {
            id
            statusName
          }
          student {
            id
          }
          description
          frequencyratebehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                duration
                frequency
                rate
                rateUnit
              }
            }
          }
          durationbehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                duration
              }
            }
          }
          latencybehaviorrecordSet {
            edges {
              node {
                id
                date
                startTime
                endTime
                latency
              }
            }
          }
          partialintervalbehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                intervalLength
                intervals
                frequency
                recordingType
                intervalChecks
                observedTime
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
          wholeintervalbehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                intervalLength
                intervals
                frequency
                recordingType
                intervalChecks
                observedTime
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
          momentarytimebehaviorrecordSet {
            edges {
              node {
                id
                date
                totalObservationTime
                intervalLength
                intervals
                frequency
                rangeMin
                rangeMax
                recordingType
                intervalChecks
                observedTime
                durationRecords {
                  edges {
                    node {
                      id
                      startTime
                      endTime
                      isSuccessful
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SESSION_DATA = gql`
  query($targets_StudentId: ID, $date_Gte: Date, $date_Lte: Date, $session: ID) {
    getSessionDataRecording(
      targets_StudentId: $targets_StudentId
      date_Gte: $date_Gte
      date_Lte: $date_Lte
      ChildSession_Sessions_SessionName: $session
    ) {
      edges {
        node {
          id
          targets {
            id
            peakType
            targetAllcatedDetails {
              id
              targetName
              targetType {
                id
                typeTar
              }
            }
          }
          ChildSession {
            id
            sessionDate
            createdAt
            duration
            feedback
            rating
            sessionNo
            sessions {
              id
              name
              sessionName {
                id
                name
              }
            }
            frequencyratebehaviorrecordSet {
              edges {
                node {
                  id
                  duration
                  frequency
                  studentBehaviorTemplate {
                    id
                    behavior {
                      id
                      behaviorName
                    }
                  }
                }
              }
            }
          }

          durationStart
          durationEnd
          isPeak
          isPeakEquivalance
          isBehReduction
          behReduction {
            totalCorrect
            totalIncorrect
            totalError
            totalPrompt
            totalTrial
            r1
            r2
            physical
            verbal
            gestural
            textual
            edges {
              node {
                id
                prompt {
                  id
                  promptName
                }
                trial
                duration
                r1 {
                  id
                  behaviorName
                }
                r2 {
                  id
                  behaviorName
                }
                r1Count
                r2Count
                trial
                sbtStep {
                  id
                  description
                }
              }
            }
          }
          totalBehRecordingDuration
          isBehRecording
          behaviourRecording {
            edges {
              node {
                id
              }
            }
          }
        }
      }
      totalTarget
      edgeCount
    }
  }
`
