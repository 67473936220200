import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Divider } from 'antd'
import { COLORS } from 'assets/styles/globalStyles'

const CheckboxGroup = Checkbox.Group

let plainOptions
let defaultCheckedList

export default function GoalsPdfSelect({ saveStatus, fromComponent, loadingGeneratePdf }) {
  useEffect(() => {
    setLoading(true)
    if (fromComponent === 'Goals') {
      plainOptions = ['PieChart', 'Histogram', 'Table']
      defaultCheckedList = ['PieChart', 'Histogram', 'Table']
      setLoading(false)
    }
    if (fromComponent === 'ProgressOverview') {
      plainOptions = ['PieChart', 'Histogram1', 'Histogram2', 'Table', 'Aggregate Graph']
      defaultCheckedList = ['PieChart', 'Histogram1', 'Histogram2', 'Table', 'Aggregate Graph']
      setLoading(false)
    }
    setCheckedList(plainOptions)
  }, [])

  const [checkedList, setCheckedList] = React.useState(defaultCheckedList)
  const [indeterminate, setIndeterminate] = React.useState(false)
  const [checkAll, setCheckAll] = React.useState(true)
  const [loadingBtn, setLoadingBtn] = useState(false)
  // const [clickList, setClickList] = React.useState([defaultCheckedList])
  const [loading, setLoading] = useState('false')
  const clickOnButton = () => {
    setLoadingBtn(true)
    saveStatus('Success', checkedList)
  }

  const onChange = list => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  return (
    <div>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        Check all
      </Checkbox>
      <Divider />
      <CheckboxGroup
        options={plainOptions}
        defaultValue={defaultCheckedList}
        value={checkedList}
        onChange={onChange}
      />
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          type="primary"
          style={{
            backgroundColor: COLORS.submitBlue,
          }}
          onClick={() => clickOnButton()}
          loading={loadingBtn}
        >
          Generate Pdf
        </Button>
      </div>
    </div>
  )
}
