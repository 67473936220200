/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-for */
import { Button, Card, Icon, message, Modal, notification, Spin, Form, Row, Upload } from 'antd'
import gql from 'graphql-tag'
import React, { useEffect, useRef, useState } from 'react'
import AntdImgCrop from 'antd-img-crop'
import { REACT_APP_API_URL } from 'apollo/REACT_APP_URL'
import apolloClient from '../../../../apollo/config'

const UploadLogo = () => {
  const [schoolDetails, setSchoolDetails] = useState(null)
  const [visible, setVisible] = useState(false)
  const [logo, setLogo] = useState(null)

  useEffect(() => {
    apolloClient
      .query({
        query: gql`
          {
            schoolDetail {
              id
              logo
            }
          }
        `,
        fetchPolicy: 'network-only',
      })
      .then(result => {
        setSchoolDetails(result.data.schoolDetail)
        setLogo(result.data.schoolDetail.logo)
      })
      .catch(error => console.error(error))
  }, [])

  const sigAttachment = {
    name: 'logo',
    multiple: false,
    accept: 'image/png, image/jpeg',
    action: `${REACT_APP_API_URL}/upload-logo/`,
    data: { pk: schoolDetails?.id },
    onChange(info) {
      const { status } = info.file
      if (status === 'done' && info.file?.response) {
        const fileList = info.file.response?.fileUrl
        setLogo(fileList)
        notification.success({ message: `${info.file.name} file uploaded successfully.` })
        setVisible(false)
      } else if (status === 'error') {
        notification.error({ message: `File upload failed.` })
      }
    },
    onRemove(info) {
      if (info && info.response && info.response.fileUrl?.length > 0) {
        setLogo(null)
      }
    },
  }

  const showModal = () => {
    setVisible(true)
  }

  return (
    <div
      className="logo-main-container"
      style={{ flexDirection: 'column', alignItems: 'baseline' }}
    >
      {logo !== null ? (
        <>
          <div
            className="logo-div"
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              overflow: logo === 'images/user.jpeg' ? 'visible' : 'hidden',
              height: 170,
              width: 170,
            }}
          >
            <img src={logo} alt="User Profile" style={{ justifyContent: 'center' }} />
          </div>
          <div>
            <div
              className="update_btn"
              style={{ display: 'flex', justifyContent: 'center', minWidth: 170 }}
            >
              <Button type="primary" style={{ backgroundColor: '#0B35B3' }} onClick={showModal}>
                Upload Logo
              </Button>
            </div>
          </div>
          <Modal
            title="Upload Clinic Logo"
            visible={visible}
            footer={[
              <Button type="primary" onClick={() => setVisible(false)}>
                Cancel
              </Button>,
            ]}
            onCancel={() => setVisible(false)}
          >
            <Form.Item disabled>
              <Row>
                <AntdImgCrop
                  rotationSlider
                  showGrid
                  onModalCancel={() => {
                    setVisible(false)
                  }}
                >
                  <Upload.Dragger {...sigAttachment} key={Math.random()}>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </Upload.Dragger>
                </AntdImgCrop>
              </Row>
            </Form.Item>
          </Modal>
        </>
      ) : (
        <Form.Item disabled>
          <Row>
            <AntdImgCrop
              rotationSlider
              showGrid
              onModalCancel={() => {
                setVisible(false)
              }}
            >
              <Upload.Dragger {...sigAttachment} key={Math.random()}>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Upload.Dragger>
            </AntdImgCrop>
          </Row>
        </Form.Item>
      )}
    </div>
  )
}

export default UploadLogo
