import React, { useState } from 'react'
import { Row, Col, Layout } from 'antd'
import Scrollbar from 'react-custom-scrollbars'

const { Content } = Layout

const CustomProfileLayout = ({ leftContent, rightContent }) => {
  return (
    <div>
      <Layout
        style={{
          minHeight: 'calc(100vh - 240px)',
          marginTop: 1,
          backgroundColor: 'white',
          borderRadius: 15,
          padding: 20,
        }}
      >
        <Row>
          <Col span={5}>
            <Scrollbar style={{ height: 'calc(100vh - 120px)' }}>{leftContent}</Scrollbar>
          </Col>
          <Col span={19}>
            <Content style={{ margin: '0 16px' }}>{rightContent}</Content>
          </Col>
        </Row>
      </Layout>
    </div>
  )
}

export default CustomProfileLayout
