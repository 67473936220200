import React from 'react'
import { Form, Button } from 'antd'
import { SUBMITT_BUTTON, CANCEL_BUTTON } from 'assets/styles/globalStyles'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const SubmitButtonCard = ({ form, closeDrawer, submitLoading, editable = false }) => {
  return (
    <div style={boxCard}>
      <Form.Item style={{ display: 'flex', justifyContent: 'center', marginBottom: 0 }}>
        <Button type="primary" htmlType="submit" style={SUBMITT_BUTTON} loading={submitLoading}>
          {editable ? 'Update' : 'Create'}
        </Button>
        {closeDrawer ? (
          <Button
            type="danger"
            style={CANCEL_BUTTON}
            onClick={() => {
              form.resetFields()
              closeDrawer()
            }}
          >
            Cancel
          </Button>
        ) : null}
      </Form.Item>
    </div>
  )
}

export default SubmitButtonCard
