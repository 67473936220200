/* eslint-disable no-shadow */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { gql } from 'apollo-boost'
import { Empty } from 'antd'
import groupObj from '@hunters/group-object'
import moment from 'moment'
import client from '../../../apollo/config'

const DomainTargetGraph = ({
  startDate,
  endDate,
  selectedProgram,
  studentIdSelected,
  domainId,
}) => {
  startDate = moment(startDate).format('YYYY-MM-DD')
  endDate = moment(endDate).format('YYYY-MM-DD')

  const [graphData, setGraphData] = useState([])
  const [keys, setKeys] = useState([])

  useEffect(() => {
    createBarGraph(startDate, endDate, selectedProgram, studentIdSelected, domainId)
  }, [startDate, endDate, createBarGraph, selectedProgram, studentIdSelected, domainId])

  const createBarGraph = useCallback((startDate, endDate, programArea, studentId, domainId) => {
    client
      .query({
        query: gql`
          query(
            $studentId: ID
            $dateGte: Date
            $dateLte: Date
            $programArea: ID
            $activeDomain: Boolean
            $targetStatus: ID
            $domainId: ID
          ) {
            domainMastered(
              studentId: $studentId
              dateGte: $dateGte
              dateLte: $dateLte
              programArea: $programArea
              activeDomain: $activeDomain
              targetStatus: $targetStatus
              domainId: $domainId
            ) {
              totalCount
              target {
                id
                manualAllocateDomain {
                  id
                  domain
                }
                targetId {
                  id
                  domain {
                    id
                    domain
                  }
                }
                targetStatus {
                  id
                  statusName
                }
              }
            }
          }
        `,
        variables: {
          studentId,
          dateGte: startDate,
          dateLte: endDate,
          programArea,
          activeDomain: true,
          targetStatus: null,
          domainId,
        },
        fetchPolicy: 'network-only',
      })
      .then(result => {
        const domainData = []
        console.log('bar graph reslt', result.data)
        result.data.domainMastered.target.forEach(item => {
          return domainData.push({
            domain: item.targetId ? item.targetId.domain.domain : item.manualAllocateDomain.domain,
            targetStatus: item.targetStatus.statusName,
          })
        })

        const groupedData = groupObj.group(domainData, 'domain')
        const domains = Object.keys(groupedData)
        const graphData = []
        const keys = new Set()
        keys.add('Total Targets')
        domains.forEach(key => {
          const totalTargets = groupedData[key].length
          const groups = groupObj.group(groupedData[key], 'targetStatus')
          groupedData[key] = groups
          const dataPoint = { domain: key, 'Total Targets': totalTargets }
          console.log(groups)
          Object.entries(groups).forEach(([key, value]) => {
            dataPoint[key] = value.length
            keys.add(key)
          })
          graphData.push(dataPoint)
        })
        setGraphData(graphData)
        setKeys(Array.from(keys))
      })
      .catch(error => console.error(error, 'err'))
  }, [])

  return (
    <div
      style={{
        width: `max(${graphData.length * 80}px, 700px)`,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {graphData && graphData.length === 0 ? (
        <Empty style={{ marginTop: '100px' }} />
      ) : (
        <ResponsiveBar
          data={graphData}
          keys={keys}
          indexBy="domain"
          margin={{ top: 50, right: 130, bottom: 100, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'paired' }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -19,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Number of Targets',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Nivo bar chart demo"
        />
      )}
    </div>
  )
}

export default DomainTargetGraph
