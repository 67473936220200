const actions = {
  SET_STATE: 'clinic/SET_STATE',
  GET_CLINICS: 'clinic/GET_CLINICS',
  GET_CLINIC_COUNT: 'clinic/GET_CLINIC_COUNT',
  PAGE_CHANGED: 'clinic/PAGE_CHANGED',
  UPDATE_CLINIC: 'clinic/UPDATE_CLINIC',
  REPLACE_CLINIC: 'clinic/REPLACE_CLINIC',

  // clinicLearners
  GET_CLINIC_LEARNERS_PAGE_CHANGED: 'clinic/GET_CLINIC_LEARNERS_PAGE_CHANGED',
}

export default actions
