/* eslint-disable */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

export async function getCurrency(payload) {
  console.log('PAYLOAD', payload)
  return apolloClient
    .query({
      query: gql`
        {
          currency {
            id
            currency
            symbol
          }
        }
      `,
    })
    .then(result => {
      const currency = result.data.currency
      return { currency }
    })
    .catch(error => {
      console.log('THE ERORR ', JSON.stringify(error))
    })
}

export async function getCourseTemplates() {
  return apolloClient
    .query({
      query: gql`
        query {
          getCourseTemplates {
            id
            name
            isPayable
            isActive
            description
            payableAmount
            thumbnailImage
            aboutCourse
            currency {
              id
              currency
            }
            aboutCourse
            isActive
            coursetemplatemodulesSet {
              edges {
                node {
                  id
                  name
                  description
                  daysForCompletion
                  coursemodulescontentSet {
                    edges {
                      node {
                        id
                        sNo
                        name
                        isVideo
                        video
                        isHtml
                        htmlContent
                        description
                      }
                    }
                  }
                  coursemodulestestsSet {
                    edges {
                      node {
                        id
                        title
                        aboutTest
                        testquestionSet {
                          edges {
                            node {
                              id
                              title
                              questionNo
                              questionText
                              testquestionoptionSet {
                                edges {
                                  node {
                                    id
                                    option
                                    score
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get Templates ERORR ', JSON.stringify(error))
    })
}

export async function getPublicCourseTemplates() {
  return apolloClient
    .query({
      query: gql`
        query {
          getPublicCourses {
            id
            name
            isPayable
            isActive
            description
            payableAmount
            thumbnailImage
            aboutCourse
            currency {
              id
              currency
              symbol
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get public Templates ERORR ', JSON.stringify(error))
    })
}
export async function getCourseById(payload) {
  return apolloClient
    .query({
      query: gql`
        query getCourseTemplate($courseId: ID!) {
          getCourseTemplate(id: $courseId) {
            id
            name
            isPayable
            isActive
            description
            payableAmount
            thumbnailImage
            aboutCourse
            currency {
              id
              currency
              symbol
            }
            aboutCourse
            isActive
            coursetemplatemodulesSet {
              edges {
                node {
                  id
                  name
                  description
                  daysForCompletion
                  coursemodulescontentSet {
                    edges {
                      node {
                        id
                        sNo
                        name
                        isVideo
                        video
                        isHtml
                        htmlContent
                      }
                    }
                  }
                  coursemodulestestsSet {
                    edges {
                      node {
                        id
                        title
                        aboutTest
                        testquestionSet {
                          edges {
                            node {
                              id
                              title
                              questionNo
                              questionText
                              testquestionoptionSet {
                                edges {
                                  node {
                                    id
                                    option
                                    score
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get public Templates ERORR ', JSON.stringify(error))
    })
}

export async function createCourseTemplate(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createCourse(
          $name: String!
          $isPayable: Boolean!
          $description: String
          $price: Float
          $currency: ID
          $thumbnailImage: String
          $aboutCourse: String
        ) {
          createCourseTemplate(
            input: {
              name: $name
              isPayable: $isPayable
              description: $description
              payableAmount: $price
              currency: $currency
              aboutCourse: $aboutCourse
              thumbnailImage: $thumbnailImage
            }
          ) {
            courseTemplate {
              id
              name
              isPayable
              isActive
              payableAmount
              description
              aboutCourse
              thumbnailImage
              currency {
                id
                currency
              }
              aboutCourse
              isActive
              coursetemplatemodulesSet {
                edges {
                  node {
                    id
                    name
                    description
                    coursemodulescontentSet {
                      edges {
                        node {
                          id
                          sNo
                          name
                          isVideo
                          video
                          isHtml
                          htmlContent
                        }
                      }
                    }
                    coursemodulestestsSet {
                      edges {
                        node {
                          id
                          title
                          aboutTest
                          testquestionSet {
                            edges {
                              node {
                                id
                                title
                                questionNo
                                questionText
                                testquestionoptionSet {
                                  edges {
                                    node {
                                      id
                                      option
                                      score
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get Templates ERORR ', JSON.stringify(error))
    })
}

export async function updateCourseTemplate(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateCourseTemplate(
          $id: ID!
          $name: String!
          $isPayable: Boolean!
          $description: String
          $price: Float
          $currency: ID
          $thumbnailImage: String
          $aboutCourse: String
        ) {
          updateCourseTemplate(
            input: {
              id: $id
              name: $name
              isPayable: $isPayable
              description: $description
              payableAmount: $price
              currency: $currency
              aboutCourse: $aboutCourse
              thumbnailImage: $thumbnailImage
            }
          ) {
            courseTemplate {
              id
              name
              isPayable
              isActive
              description
              payableAmount
              thumbnailImage
              aboutCourse
              currency {
                id
                currency
              }
              aboutCourse
              isActive
              coursetemplatemodulesSet {
                edges {
                  node {
                    id
                    name
                    description
                    coursemodulescontentSet {
                      edges {
                        node {
                          id
                          sNo
                          name
                          isVideo
                          video
                          isHtml
                          htmlContent
                        }
                      }
                    }
                    coursemodulestestsSet {
                      edges {
                        node {
                          id
                          title
                          aboutTest
                          testquestionSet {
                            edges {
                              node {
                                id
                                title
                                questionNo
                                questionText
                                testquestionoptionSet {
                                  edges {
                                    node {
                                      id
                                      option
                                      score
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('update Course Templates ERORR ', JSON.stringify(error))
    })
}

export async function deleteCourseTemplate(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation activeInactiveCourseTemplate($id: ID!, $active: Boolean!) {
          activeInactiveCourseTemplate(input: { id: $id, activate: $active }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('delete Course Templates ERORR ', JSON.stringify(error))
    })
}

export async function createCourseModule(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createModule(
          $name: String!
          $courseId: ID!
          $description: String
          $daysForCompletion: Int!
        ) {
          createCourseTemplateModule(
            input: {
              name: $name
              courseTemplate: $courseId
              description: $description
              daysForCompletion: $daysForCompletion
            }
          ) {
            templateModule {
              id
              name
              description
              daysForCompletion
              coursemodulescontentSet {
                edges {
                  node {
                    id
                    sNo
                    name
                    isVideo
                    video
                    isHtml
                    htmlContent
                  }
                }
              }
              coursemodulestestsSet {
                edges {
                  node {
                    id
                    title
                    aboutTest
                    testquestionSet {
                      edges {
                        node {
                          id
                          title
                          questionNo
                          questionText
                          testquestionoptionSet {
                            edges {
                              node {
                                id
                                option
                                score
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get Templates ERORR ', JSON.stringify(error))
    })
}
export async function updateCourseModule(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateCourseTemplateModule(
          $name: String!
          $moduleId: ID!
          $description: String
          $daysForCompletion: Int!
        ) {
          updateCourseTemplateModule(
            input: {
              id: $moduleId
              name: $name
              description: $description
              daysForCompletion: $daysForCompletion
            }
          ) {
            templateModule {
              id
              name
              description
              daysForCompletion
              coursemodulescontentSet {
                edges {
                  node {
                    id
                    sNo
                    name
                    isVideo
                    video
                    isHtml
                    htmlContent
                  }
                }
              }
              coursemodulestestsSet {
                edges {
                  node {
                    id
                    title
                    aboutTest
                    testquestionSet {
                      edges {
                        node {
                          id
                          title
                          questionNo
                          questionText
                          testquestionoptionSet {
                            edges {
                              node {
                                id
                                option
                                score
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get Templates ERORR ', JSON.stringify(error))
    })
}
export async function deleteCourseModule(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteCourseTemplateModule($id: ID!) {
          deleteCourseTemplateModule(input: { id: $id }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('delete Course Templates ERORR ', JSON.stringify(error))
    })
}
export async function createModuleContent(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createCourseModuleContent(
          $sNo: Int!
          $name: String!
          $moduleId: ID!
          $isVideo: Boolean!
          $isHtml: Boolean!
          $htmlContent: String
          $videoUrl: String
          $description: String
        ) {
          createCourseModuleContent(
            input: {
              sNo: $sNo
              name: $name
              templateModule: $moduleId
              isVideo: $isVideo
              isHtml: $isHtml
              videoUrl: $videoUrl
              htmlContent: $htmlContent
              description: $description
            }
          ) {
            moduleContent {
              id
              sNo
              name
              isVideo
              isHtml
              video
              description
              htmlContent
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get Templates ERORR ', JSON.stringify(error))
    })
}

export async function updateModuleContent(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateCourseModuleContent(
          $contentId: ID!
          $sNo: Int!
          $name: String!
          $isVideo: Boolean!
          $isHtml: Boolean!
          $htmlContent: String
          $videoUrl: String
          $description: String
        ) {
          updateCourseModuleContent(
            input: {
              sNo: $sNo
              name: $name
              id: $contentId
              isVideo: $isVideo
              isHtml: $isHtml
              videoUrl: $videoUrl
              htmlContent: $htmlContent
              description: $description
            }
          ) {
            moduleContent {
              id
              sNo
              name
              isVideo
              isHtml
              video
              htmlContent
              description
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get Templates ERORR ', JSON.stringify(error))
    })
}

export async function deleteModuleContent(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteCourseModuleContent($id: ID!) {
          deleteCourseModuleContent(input: { id: $id }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('delete Course Templates ERORR ', JSON.stringify(error))
    })
}

export async function createModuleTest(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createCourseModuleTest($name: String!, $moduleId: ID!, $description: String) {
          createCourseModuleTest(
            input: { templateModule: $moduleId, title: $name, aboutTest: $description }
          ) {
            moduleTest {
              id
              title
              aboutTest
              testquestionSet {
                edges {
                  node {
                    id
                    title
                    questionNo
                    questionText
                    testquestionoptionSet {
                      edges {
                        node {
                          id
                          option
                          score
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('create test ERORR ', JSON.stringify(error))
    })
}

export async function updateModuleTest(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateCourseModuleTest($name: String!, $testId: ID!, $description: String) {
          updateCourseModuleTest(input: { id: $testId, title: $name, aboutTest: $description }) {
            moduleTest {
              id
              title
              aboutTest
              testquestionSet {
                edges {
                  node {
                    id
                    title
                    questionNo
                    questionText
                    testquestionoptionSet {
                      edges {
                        node {
                          id
                          option
                          score
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('create test ERORR ', JSON.stringify(error))
    })
}

export async function deleteModuleTest(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteCourseModuleTest($id: ID!) {
          deleteCourseModuleTest(input: { id: $id }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('delete Course test ERORR ', JSON.stringify(error))
    })
}

export async function createModuleTestQuestion(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createModuleTestQuestion(
          $testId: String!
          $questionNo: Int!
          $questionText: String!
          $options: [QuestionOptionInput]!
          $title: String
        ) {
          createModuleTestQuestion(
            input: {
              moduleTest: $testId
              questionNo: $questionNo
              questionText: $questionText
              title: $title
              options: $options
            }
          ) {
            testQuestion {
              id
              title
              questionNo
              questionText
              testquestionoptionSet {
                edges {
                  node {
                    id
                    option
                    score
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('create test question ERORR ', JSON.stringify(error))
    })
}

export async function updateModuleTestQuestion(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateModuleTestQuestion(
          $questionId: ID!
          $questionNo: Int!
          $questionText: String!
          $options: [QuestionOptionInput]!
          $title: String
        ) {
          updateModuleTestQuestion(
            input: {
              id: $questionId
              questionNo: $questionNo
              questionText: $questionText
              title: $title
              options: $options
            }
          ) {
            testQuestion {
              id
              title
              questionNo
              questionText
              testquestionoptionSet {
                edges {
                  node {
                    id
                    option
                    score
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('create test question ERORR ', JSON.stringify(error))
    })
}

export async function deleteModuleTestQuestion(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation deleteModuleTestQuestion($id: ID!) {
          deleteModuleTestQuestion(input: { id: $id }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('delete Course test ERORR ', JSON.stringify(error))
    })
}

export async function getCourseDashboard(payload) {
  return apolloClient
    .query({
      query: gql`
        query getCourseDashboardData($startDate: Date, $endDate: Date) {
          getCourseDashboardData(startDate: $startDate, endDate: $endDate) {
            message
            status
            totalEnrolledUsers
            totalPurchasedCourses
            completedCourses
            inprogressCourses
            pendingCourses
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get Dashboard ERORR ', JSON.stringify(error))
    })
}
