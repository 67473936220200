import reqwest from 'reqwest'
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'
import { REACT_APP_API_URL } from '../apollo/REACT_APP_URL'

const API_URL = `${REACT_APP_API_URL}/graphql`

export async function createPayor(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createPayor(
          $firstname: String!
          $lastname: String
          $email: String!
          $description: String
          $contactType: ID!
          $city: String
          $state: String
          $homePhone: String
          $workPhone: String
          $primaryLocation: String
          $responsibility: String
          $contactPerson: String
          $BCBS: Boolean
          $DMERC: Boolean
          $Medicaid: Boolean
          $Medicare: Boolean
          $TRICARE: Boolean
          $phoneNumber: String
          $payorPlan: [PayorPlanInput]
          $streetAddress: String
          $country: String
          $zipCode: String
        ) {
          createPayor(
            input: {
              firstname: $firstname
              lastname: $lastname
              email: $email
              description: $description
              contactType: $contactType
              city: $city
              state: $state
              homePhone: $homePhone
              workPhone: $workPhone
              primaryLocation: $primaryLocation
              responsibility: $responsibility
              BCBS: $BCBS
              DMERC: $DMERC
              Medicaid: $Medicaid
              Medicare: $Medicare
              TRICARE: $TRICARE
              contactPerson: $contactPerson
              phoneNumber: $phoneNumber
              streetAddress: $streetAddress
              country: $country
              zipCode: $zipCode
              payorPlan: $payorPlan
            }
          ) {
            details {
              id
              firstname
              lastname
              email
              description
              city
              state
              homePhone
              workPhone
              primaryLocation
              responsibility
              contactType {
                id
                name
                __typename
              }
              payorplanSet {
                edges {
                  node {
                    id
                    plan
                    payor {
                      id
                      firstname
                    }
                  }
                }
                __typename
              }
              __typename
            }
            __typename
          }
        }
      `,
      variables: {
        firstname: payload.values.firstname,
        lastname: payload.values.lastname,
        email: payload.values.email,
        description: payload.values.description,
        contactType: payload.values.contactType,
        city: payload.values.city,
        state: payload.values.state,
        homePhone: payload.values.homePhone,
        workPhone: payload.values.workPhone,
        primaryLocation: payload.values.primaryLocation,
        responsibility: payload.values.responsibility,
        BCBS: payload.values.BCBS,
        DMERC: payload.values.DMERC,
        Medicaid: payload.values.Medicaid,
        Medicare: payload.values.Medicare,
        TRICARE: payload.values.TRICARE,
        contactPerson: payload.values.contactPerson,
        phoneNumber: payload.values.phoneNumber,
        country: payload.values.country,
        zipCode: payload.values.zipCode,
        streetAddress: payload.values.streetAddress,
      },
    })
    .then(result => result)
    .catch(err => {
      console.log('errrors', err.graphQLErrors)
      err.graphQLErrors.map(item => {
        console.log('errrors', item)
        return notification.error({
          message: 'Something went wrong while Creating Payor',
          description: item.message,
        })
      })
    })
}

export async function updatePayor(payload) {
  console.log('updatePayor payload')
  console.log(payload)
  console.log(payload.id)
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updatePayor(
          $pk: ID!
          $firstname: String!
          $lastname: String
          $email: String!
          $description: String
          $contactType: ID!
          $city: String
          $state: String
          $homePhone: String
          $workPhone: String
          $primaryLocation: String
          $responsibility: String
          $contactPerson: String
          $BCBS: Boolean
          $DMERC: Boolean
          $Medicaid: Boolean
          $Medicare: Boolean
          $TRICARE: Boolean
          $phoneNumber: String
          $streetAddress: String
          $country: String
          $zipCode: String
        ) {
          updatePayor(
            input: {
              pk: $pk
              firstname: $firstname
              lastname: $lastname
              email: $email
              description: $description
              contactType: $contactType
              city: $city
              state: $state
              homePhone: $homePhone
              workPhone: $workPhone
              primaryLocation: $primaryLocation
              responsibility: $responsibility
              BCBS: $BCBS
              DMERC: $DMERC
              Medicaid: $Medicaid
              Medicare: $Medicare
              TRICARE: $TRICARE
              contactPerson: $contactPerson
              phoneNumber: $phoneNumber
              streetAddress: $streetAddress
              country: $country
              zipCode: $zipCode
            }
          ) {
            details {
              id
              firstname
              lastname
              email
              description
              city
              state
              homePhone
              workPhone
              primaryLocation
              responsibility
              contactType {
                id
                name
              }
              payorplanSet {
                edges {
                  node {
                    id
                    plan
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        pk: payload.values.id,
        firstname: payload.values.firstname,
        lastname: payload.values.lastname,
        email: payload.values.email,
        description: payload.values.description,
        contactType: payload.values.contactType,
        city: payload.values.city,
        state: payload.values.state,
        homePhone: payload.values.homePhone,
        workPhone: payload.values.workPhone,
        primaryLocation: payload.values.primaryLocation,
        responsibility: payload.values.responsibility,
        BCBS: payload.values.BCBS,
        DMERC: payload.values.DMERC,
        Medicaid: payload.values.Medicaid,
        Medicare: payload.values.Medicare,
        TRICARE: payload.values.TRICARE,
        contactPerson: payload.values.contactPerson,
        phoneNumber: payload.values.phoneNumber,
        country: payload.values.country,
        zipCode: payload.values.zipCode,
        streetAddress: payload.values.streetAddress,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong while updating Payor',
          description: item.message,
        })
      })
    })
}

export async function activeInactivePayor(payload) {
  console.log('ppayload', payload)
  return apolloClient
    .mutate({
      mutation: gql`
        mutation activeInactivePayor($id: ID!, $isActive: Boolean!) {
          activeInactivePayor(input: { pk: $id, isActive: $isActive }) {
            details {
              id
              firstname
              isActive
            }
          }
        }
      `,
      variables: {
        id: payload.id,
        isActive: payload.isActive,
      },
    })
    .then(result => result)
    .catch(error => {
      console.log('error', error)
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong while changing state of Payor',
          description: item.message,
        })
      })
    })
}

export async function uploadPayorDocument(payload) {
  console.log('payload uploadPayorDocument----')
  console.log(payload)

  const { payorId, fileList } = payload
  const formData = new FormData()
  formData.append('pk', payorId)
  fileList.forEach(file => {
    formData.append('file', file)
  })

  // console.log(process.env);

  return reqwest({
    url: `${API_URL.replace('graphql', '')}authorization-docs/`,
    method: 'post',
    processData: false,
    data: formData,
  })
    .then(result => result)
    .catch(error => {
      console.log(error)
      // return notification.error({
      //   message: 'An error occurred to upload Document.',
      //   description: error.toString(),
      // })
    })
}
