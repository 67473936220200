/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Drawer, Table } from 'antd'
import { DRAWER } from 'assets/styles/globalStyles'
import { PlusOutlined } from '@ant-design/icons'

import gql from 'graphql-tag'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import C1 from 'pages/SessionDataRecordingNew/CustomSessionScreennew'

// import C1 from 'pages/SessionDataRecording/customSessionScreen'
import { useLazyQuery } from 'react-apollo'
import { GET_SESSION_RECORDINGS, GET_TODAYS_SESSION } from './query'

const EditableTable = ({ date, peakSd, selectedTarget }) => {
  const [dataSource, setDataSource] = useState([])
  const [allSessions, setAllSessions] = useState([])
  const [visibleSession, setVisibleSession] = useState(false)

  const dispatch = useDispatch()

  const [
    getSessionRecording,
    {
      data: sessionData,
      loading: sessionDataLoading,
      error: errorSession,
      refetch: refetchSession,
    },
  ] = useLazyQuery(GET_SESSION_RECORDINGS, {
    fetchPolicy: 'network-only',
  })

  const student = useSelector(state => state.student)
  const [
    getAllSessionsData,
    {
      data: allSessionData,
      refetch: refetchAllSession,
      error: errorSessionData,
      loading: allSessionDataLoading,
    },
  ] = useLazyQuery(GET_TODAYS_SESSION)

  useEffect(() => {
    getAllSessionsData({
      variables: {
        studentId: student.StudentId,
        date: moment(date).format('YYYY-MM-DD'),
        targets: [selectedTarget.id],
      },
    })
    getSessionRecording({
      variables: {
        studentId: student.StudentId,
        sessiondate: moment(date).format('YYYY-MM-DD'),
        targets: selectedTarget.id,
      },
    })
  }, [])

  useEffect(() => {
    if (errorSession) {
      console.log(errorSession)
    }
    if (errorSessionData) {
      console.log(errorSessionData)
    }
  }, [errorSession, errorSessionData])

  useEffect(() => {
    if (allSessionData && allSessionData.GetStudentSession) {
      const { edges } = allSessionData.GetStudentSession
      setAllSessions(edges)
    }
  }, [allSessionData])

  useEffect(() => {
    if (sessionData && allSessions) {
      const {
        getSessionRecordings: { edges },
      } = sessionData

      const tableData = []
      if (edges.length > 0) {
        edges.forEach(({ node }, index) => {
          const {
            behReduction: { edges: behEdges },
            ChildSession: {
              sessions: { sessionName },
            },
          } = node
          if (behEdges.length > 0) {
            const rec = sessionRecordingInstance(node.behReduction, peakSd, sessionName, index)
            tableData.push({
              ...rec,
              targetId: node.targets?.id,
              childSession: node.ChildSession,
              name: `${sessionName.name}-${node.ChildSession.sessionNo}`,
            })
          }
        })
        const rec = createTotalForTable(tableData)
        tableData.push(rec)
        // if (tableData.length < allSessions.length) {
        allSessions.forEach(({ node }) => {
          const check = tableData.filter(item => item.session === node.sessionName.name)
          // if (check.length === 0) {
          tableData.push({
            key: Math.random(),
            id: node.id,
            duration: node.duration,
            date: node.date,
            sessionNameId: node.sessionName?.id,
            session: node.sessionName?.name,
            name: node.sessionName?.name,
            incorrect: 0,
            prompt: 0,
            correct: 0,
            totalTrials: 0,
            percentage: 0,
          })
          // }
        })
        // }

        setDataSource(tableData)
      } else {
        const recordedData = noRecordingInstance(allSessions)
        setDataSource(recordedData)
      }
    }
  }, [sessionData, allSessions])

  const sessionRecordingInstance = useCallback((beh, recordType, sessionName, index) => {
    const { id, name } = sessionName

    return {
      key: `${id + index}`,
      sessionNameId: id,
      session: name,
      correct: beh.totalCorrect,
      incorrect: beh.totalIncorrect + beh.totalError,
      prompt: beh.totalPrompt,
      totalTrials: beh.totalTrial,
      percentage: Number((Number(beh.totalCorrect) / (beh.totalTrial || 1)) * 100).toFixed(0),
    }
  }, [])

  const noRecordingInstance = useCallback(sessions => {
    const tableData = []

    sessions.forEach(({ node }, index) => {
      tableData.push({
        key: `${node.id + index}`,
        sessionNameId: node.sessionName.id,
        session: node.sessionName.name,
        name: node.sessionName.name,
        correct: 0,
        incorrect: 0,
        prompt: 0,
        totalTrials: 0,
        percentage: 0,
      })
    })
    tableData.push({
      key: tableData.length + 10,
      session: 'Total',
      name: 'Total',
      correct: 0,
      incorrect: 0,
      prompt: 0,
      totalTrials: 0,
      percentage: 0,
    })
    return tableData
  }, [])

  const createTotalForTable = useCallback(tableData => {
    const correctNumber = tableData.map(({ correct }) => correct).reduce((a, b) => a + b, 0)
    const incorrectNumber = tableData.map(({ incorrect }) => incorrect).reduce((a, b) => a + b, 0)
    const promptNumber = tableData.map(({ prompt }) => prompt).reduce((a, b) => a + b, 0)
    const totalTrialsNumber = tableData
      .map(({ totalTrials }) => totalTrials)
      .reduce((a, b) => a + b, 0)
    let percentageNumber
    if (totalTrialsNumber) {
      percentageNumber = Math.floor((correctNumber / totalTrialsNumber) * 100)
    } else {
      percentageNumber = 0
    }
    return {
      key: tableData.length + 10,
      session: 'Total',
      name: 'Total',
      correct: correctNumber,
      incorrect: incorrectNumber,
      prompt: promptNumber,
      totalTrials: totalTrialsNumber,
      percentage: percentageNumber,
    }
  }, [])

  const handleStartSessionRecording = rowData => {
    const { sessionNameId, session } = rowData
    if (session === 'Total') {
      return
    }

    const { edges } = allSessionData.GetStudentSession
    const mainSession = edges.find(item => item.node.sessionName.id === sessionNameId)

    if (mainSession) {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SessionId: rowData.childSession ? rowData.childSession.sessions.id : mainSession.node.id,
          SessionDate:
            date !== null ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          NewVersion: rowData.childSession === undefined,
          DefaultChildSession: rowData.childSession ? rowData.childSession : null,
        },
      })
      // dispatch({
      //   type: 'sessionrecording/SET_STATE',
      //   payload: {
      //     SessionId: mainSession.node.id,
      //     SessionDate:
      //       date !== null ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      //   },
      // })
      setVisibleSession(true)
    }
  }

  const handleCloseSessionDrawer = () => {
    refetchSession()
    refetchAllSession()
    setVisibleSession(false)

    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        FetchForSingleTarget: false,
      },
    })
  }

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    }
    if (row.childSession === undefined && row.name !== 'Total') {
      obj.props.colSpan = 0
    }
    return obj
  }

  const columns = [
    {
      title: 'Session',
      key: '1',
      render: row => {
        const { session, name } = row
        if (row.childSession === undefined && name !== 'Total') {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                <Button
                  style={{ fontWeight: 'bold' }}
                  type="primary"
                  onClick={() => handleStartSessionRecording(row)}
                >
                  Start New {name} Session <PlusOutlined />
                </Button>
              </div>
            ),
            props: {
              colSpan: 6,
            },
          }
        }
        return (
          <span
            role="button"
            style={{
              fontWeight: 'bold',
              cursor: 'pointer',
              color: '#4A7AB4',
            }}
            onClick={() => handleStartSessionRecording(row)}
          >
            {name} {''}
          </span>
        )
      },
    },
    {
      title: 'Correct',
      dataIndex: 'correct',
      key: '2',
      render: renderContent,
    },
    {
      title: 'Incorrect',
      dataIndex: 'incorrect',
      key: '3',
      render: renderContent,
    },
    {
      title: 'Prompted',
      dataIndex: 'prompt',
      key: '4',
      render: renderContent,
    },
    {
      title: 'Total Trials ',
      key: '5',
      dataIndex: 'totalTrials',
      render: renderContent,
    },
    {
      title: 'Percentage Correct',
      key: '6',
      dataIndex: 'percentage',
      render: (text, row, index) => {
        return renderContent(`${text}%`, row, index)
      },
    },
  ]

  return (
    <>
      {peakSd && (
        <p style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
          <span> {peakSd.toUpperCase()} </span>
        </p>
      )}
      <p style={{ fontSize: 18, fontWeight: 'bold' }}>
        <span>Date: </span> {moment(date).format('YYYY-MM-DD')}
      </p>
      <Drawer
        title={selectedTarget?.targetAllcatedDetails.targetName}
        placement="right"
        width={DRAWER.widthL1}
        closable
        destroyOnClose
        onClose={handleCloseSessionDrawer}
        visible={visibleSession}
      >
        <C1 selectedTarget={selectedTarget?.id} />
      </Drawer>

      <Table
        loading={sessionDataLoading || allSessionDataLoading}
        columns={columns}
        dataSource={dataSource}
        bordered
      />
    </>
  )
}
export default EditableTable
