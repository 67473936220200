// import { GraphQLClient } from 'graphql-request'
/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */

import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

export async function getClinicLearners(payload) {
  return apolloClient
    .query({
      query: gql`
        query getLearners(
          $isActive: Boolean
          $first: Int
          $last: Int
          $after: String
          $before: String
          $firstname: String
          $clinicLocation: ID
          $staffId: ID
          $tags: [String]
          $lastname: String
          $email: String
          $caseManager: ID
        ) {
          students(
            isActive: $isActive
            first: $first
            last: $last
            after: $after
            before: $before
            clinicLocation: $clinicLocation
            authStaff_Id: $staffId
            tags: $tags
            firstname_Icontains: $firstname
            lastname_Icontains: $lastname
            email_Icontains: $email
            caseManager_Id: $caseManager
          ) {
            clinicTotal
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                firstname
                email
                currentAddress
                streetAddress
                mobileno
                lastname
                researchParticipant
                gender
                dob
                grade
                section
                disabilityArea
                percentage
                parentMobile
                parent {
                  id
                  lastLogin
                }
                clinicLocation {
                  id
                  location
                }
                category {
                  id
                  category
                }
                caseManager {
                  id
                  name
                }
                authStaff {
                  edges {
                    node {
                      id
                      name
                      surname
                    }
                  }
                }
                preferreditemsSet {
                  edges {
                    node {
                      id
                      itemName
                    }
                  }
                }
                sessionpreferreditemSet(isActive: true) {
                  edges {
                    node {
                      id
                      item
                    }
                  }
                }
                school {
                  id
                  schoolName
                  address
                  email
                  logo
                }
                tags {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                isActive
              }
            }
          }
        }
      `,
      variables: {
        isActive: payload.isActive,
        first: payload.first > 100 ? null : payload.first,
        after: payload.after,
        before: payload.before,
        last: payload.last ? payload.last : null,
        firstname: payload.firstname ? payload.firstname : null,
        clinicLocation: payload.clinicLocation ? payload.clinicLocation : null,
        staffId: payload.staffId ? payload.staffId : null,
        tags: payload.tags ? payload.tags : [],
        lastname: payload.lastname ? payload.lastname : null,
        email: payload.email ? payload.email : null,
        caseManager: payload.caseManager ? payload.caseManager : null,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result
    })
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getLearnersDropdown() {
  return apolloClient
    .query({
      query: gql`
        query {
          category {
            id
            category
          }
          languages {
            id
            name
          }
          schoolLocation {
            edges {
              node {
                id
                location
              }
            }
          }
          staffs(isActive: true) {
            edges {
              node {
                id
                name
                surname
              }
            }
          }
        }
      `,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function updateLearner(payload) {
  const authStaffList = []
  if (payload.values.authStaff.length > 0) {
    payload.values.authStaff.map(item => authStaffList.push(`"${item}"`))
  }

  return apolloClient
    .mutate({
      mutation: gql`
        mutation UpdateStudent(
          $id: ID!
          $clientId: String!
          $category: ID!
          $email: String!
          $gender: String!
          $dob: Date!
          $dateOfDiagnosis: Date
          $clinicLocation: ID
          $firstName: String!
          $lastName: String
          $authStaffList: [ID]
          $parentFirstName: String
          $parentMobileNumber: String
          $ssnCard: String
          $mobileNo: String
          $address: String
          $streetAddress: String
          $state: String
          $city: String
          $country: String
          $zipCode: String
          $caseManager: ID
          $learnerLanguage: ID
          $isActive: Boolean
          $researchParticipant: Boolean
          $tags: [String]
          $allergicTo: [String]
          preferredItems: [String]
          $grade: String
          $section: String
          $disabilityArea: String
          $percentage: String
        ) {
          updateStudent(
            input: {
              studentData: {
                id: $id
                clientId: $clientId
                category: $category
                email: $email
                gender: $gender
                dob: $dob
                dateOfDiagnosis: $dateOfDiagnosis
                clinicLocation: $clinicLocation
                caseManager: $caseManager
                firstname: $firstName
                lastname: $lastName
                authStaff: $authStaffList
                parentName: $parentFirstName
                parentMobile: $parentMobileNumber
                ssnAadhar: $ssnCard
                mobileno: $mobileNo
                address: $address
                streetAddress: $streetAddress
                city: $city
                state: $state
                country: $country
                zipCode: $zipCode
                language: $learnerLanguage
                isActive: $isActive
                researchParticipant: $researchParticipant
                tags: $tags
                allergicTo: $allergicTo
                preferredItems: $preferredItems
                grade: $grade
                section: $section
                disabilityArea: $disabilityArea
                percentage: $percentage
              }
            }
          ) {
            student {
              id
              firstname
              email
              dob
              mobileno
              lastname
              gender
              streetAddress
              currentAddress
              clientId
              ssnAadhar
              parentMobile
              parentName
              dateOfDiagnosis
              
              grade
              section
              disabilityArea
              percentage
              diagnoses {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              learnermedicationSet {
                edges {
                  node {
                    id
                    date
                    condition
                  }
                }
              }
              category {
                id
                category
              }
              clinicLocation {
                id
                location
              }
              hybridCommunityLocation
              caseManager {
                id
                name
              }
              language {
                id
                name
              }
              authStaff {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              allergicTo {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              preferreditemsSet {
                edges {
                  node {
                    id
                    itemName
                  }
                }
              }
              sessionpreferreditemSet(isActive: true) {
                edges {
                  node {
                    id
                    item
                  }
                }
              } 
              tags {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              isActive
              researchParticipant
            }
          }
        }
      `,
      variables: {
        id: payload.id,
        clientId: payload.values.clientId,
        category: payload.values.category,
        email: payload.values.email,
        gender: payload.values.gender,
        dob: moment(payload.values.dob).format('YYYY-MM-DD'),
        dateOfDiagnosis: payload.values.dateOfDiagnosis
          ? moment(payload.values.dob).format('YYYY-MM-DD')
          : null,
        clinicLocation: payload.values.clinicLocation,
        firstName: payload.values.firstName,
        lastName: payload.values.lastName,
        authStaffList: payload.values.authStaff ? payload.values.authStaff : [],
        parentFirstName: payload.values.parentFirstName,
        parentMobileNumber: payload.values.parentMobileNumber,
        ssnCard: payload.values.ssnCard,
        mobileNo: payload.values.mobileNo,
        address: payload.values.address,
        caseManager: payload.values.caseManager,
        learnerLanguage: payload.values.learnerLanguage,
        isActive: payload.values.isActive,
        researchParticipant: payload.values.researchParticipant,
        tags: payload.values.tags,
        allergicTo: payload.values.allergicTo,
        preferredItems: payload.values.preferredItems,
        streetAddress: payload.values.street,
        state: payload.values.state,
        country: payload.values.country,
        city: payload.values.city,
        zipCode: payload.values.pincode,
        ...payload.values,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function createLearner(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation CreateLearner(
          $clientId: String!
          $category: ID!
          $email: String!
          $gender: String!
          $dob: Date!
          $dateOfDiagnosis: Date
          $clinicLocation: ID
          $hybridCommunityLocation: String
          $firstName: String!
          $lastName: String
          $authStaffList: [ID]
          $parentFirstName: String
          $parentMobileNumber: String
          $ssnCard: String
          $mobileNo: String
          $address: String
          $caseManager: ID
          $isActive: Boolean
          $defaultProgram: Boolean
          $learnerLanguage: ID
          $researchParticipant: Boolean
          $streetAddress: String
          $state: String
          $city: String
          $country: String
          $zipCode: String
          $tags: [String]
          $allergicTo: [String]
          $preferredItems: [String]
          $parentActivation: Boolean
          $grade: String
          $section: String
          $disabilityArea: String
          $percentage: String
        ) {
          createStudent(
            input: {
              studentData: {
                clientId: $clientId
                category: $category
                email: $email
                gender: $gender
                dob: $dob
                dateOfDiagnosis: $dateOfDiagnosis
                clinicLocation: $clinicLocation
                hybridCommunityLocation: $hybridCommunityLocation
                firstname: $firstName
                lastname: $lastName
                authStaff: $authStaffList
                parentName: $parentFirstName
                parentMobile: $parentMobileNumber
                ssnAadhar: $ssnCard
                mobileno: $mobileNo
                address: $address
                caseManager: $caseManager
                isActive: $isActive
                defaultProgram: $defaultProgram
                language: $learnerLanguage
                researchParticipant: $researchParticipant
                streetAddress: $streetAddress
                city: $city
                state: $state
                country: $country
                zipCode: $zipCode
                tags: $tags
                allergicTo: $allergicTo
                preferredItems: $preferredItems
                parentActivation: $parentActivation
                grade: $grade
                section: $section
                disabilityArea: $disabilityArea
                percentage: $percentage
              }
            }
          ) {
            student {
              id
              firstname
              email
              dob
              mobileno
              lastname
              gender
              streetAddress
              currentAddress
              clientId
              ssnAadhar
              parentMobile
              parentName
              dateOfDiagnosis
              grade
              section
              disabilityArea
              percentage
              diagnoses {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              learnermedicationSet {
                edges {
                  node {
                    id
                    date
                    condition
                  }
                }
              }
              category {
                id
                category
              }
              clinicLocation {
                id
                location
              }
              hybridCommunityLocation
              caseManager {
                id
                name
              }
              language {
                id
                name
              }
              authStaff {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              allergicTo {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              preferreditemsSet {
                edges {
                  node {
                    id
                    itemName
                  }
                }
              }
              sessionpreferreditemSet(isActive: true) {
                edges {
                  node {
                    id
                    item
                  }
                }
              }
              tags {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              isActive
              researchParticipant
            }
          }
        }
      `,
      variables: {
        ...payload.values,
        clientId: payload.values.clientId,
        category: payload.values.category,
        email: payload.values.email,
        gender: payload.values.gender,
        dob: moment(payload.values.dob).format('YYYY-MM-DD'),
        dateOfDiagnosis: payload.values.dateOfDiagnosis
          ? moment(payload.values.dob).format('YYYY-MM-DD')
          : null,
        clinicLocation: payload.values.clinicLocation,
        hybridCommunityLocation: payload.values.hybridCommunityLocation,
        firstName: payload.values.firstName,
        lastName: payload.values.lastName ? payload.values.lastName : '',
        authStaffList: payload.values.authStaff ? payload.values.authStaff : [],
        parentFirstName: payload.values.parentFirstName,
        parentMobileNumber: payload.values.parentMobileNumber,
        ssnCard: payload.values.ssnCard,
        mobileNo: payload.values.mobileNo,
        address: payload.values.address,
        caseManager: payload.values.caseManager,
        learnerLanguage: payload.values.learnerLanguage,
        isActive: payload.values.isActive,
        defaultProgram: payload.values.defaultProgram,
        researchParticipant: payload.values.researchParticipant,
        tags: payload.values.tags,
        streetAddress: payload.values.street,
        state: payload.values.state,
        country: payload.values.country,
        city: payload.values.city,
        zipCode: payload.values.pincode,
        parentActivation: payload.values.parentactivation,
        allergicTo: payload.values.allergicTo,
        preferredItems: payload.values.preferredItems,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}

export async function createLearnersProgram(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation CreateLearnersProgram($id: ID!, $program: String!) {
          createProgramsByLevel(input: { pk: $id, level: $program }) {
            status
            msg
          }
        }
      `,
      variables: {
        id: payload.id,
        program: payload.program,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong creating program',
          description: item.message,
        })
      })
    })
}

export async function learnerActiveInactive(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
            updateStudent(input:{
                studentData:{
                    id:"${payload.id}",
                    isActive: ${payload.checked}
                }
            })
            {
                student {
                    id,
                    isActive

                }
            }
        }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

// activity and call log yuvraj

export async function getLearner(payload) {
  return apolloClient
    .query({
      query: gql`
        query($id: ID!) {
          student(id: $id) {
            id
            admissionNo
            internalNo
            grade
            section
            disabilityArea
            percentage
            school {
              id
              schoolName
            }
            parent {
              id
              lastLogin
            }
            admissionDate
            firstname
            email
            isDefaultProgram
            dob
            image
            files {
              edges {
                node {
                  id
                  file
                  fileName
                  fileDescription
                }
              }
            }
            report
            createdAt
            fatherName
            fatherPhone
            motherName
            motherPhone
            isActive
            mobileno
            lastname
            gender
            currentAddress
            streetAddress
            city
            state
            country
            zipCode
            height
            weight
            clientId
            ssnAadhar
            parentMobile
            parentName
            dateOfDiagnosis
            clinicLocation {
              id
              location
            }
            isPeakActive
            isCogActive
            researchParticipant
            diagnoses {
              edges {
                node {
                  id
                  name
                }
              }
            }

            category {
              id
              category
            }
            clinicLocation {
              id
              location
            }
            hybridCommunityLocation
            caseManager {
              id
              name
            }
            language {
              id
              name
            }
            family {
              id
              members {
                edges {
                  node {
                    id
                    memberName
                    relationship {
                      id
                      name
                    }
                  }
                }
              }
            }
            authStaff {
              edges {
                node {
                  id
                  name
                  surname
                }
              }
            }
            allergicTo {
              edges {
                node {
                  id
                  name
                }
              }
            }
            preferreditemsSet {
              edges {
                node {
                  id
                  itemName
                }
              }
            }
            sessionpreferreditemSet(isActive: true) {
              edges {
                node {
                  id
                  item
                }
              }
            }
            tags {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        id: payload.id,
      },
    })
    .then(result => result)
    .catch(err => {
      err.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getLearnerAttendanceReportByClinic(payload) {
  return apolloClient
    .query({
      query: gql`
        query getLearnerAttendeceReport($start: Date!, $end: Date!) {
          getLearnerAttendeceReport(startDate: $start, endDate: $end) {
            totalPresent
            totalAbsent
            totalLeaves
            learnerAttendenceData {
              totalPresent
              totalAbsent
              totalLeaves
              studentData {
                id
                firstname
                lastname
              }
              learnerAttendence {
                id
                learner {
                  id
                  firstname
                }
                attendenceDate
                status
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(err => {
      err.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getLearnerAttendanceReportByStaff(payload) {
  return apolloClient
    .query({
      query: gql`
        query getLearnerAttendenceReportByStaff($start: Date!, $end: Date!) {
          getLearnerAttendenceReportByStaff(startDate: $start, endDate: $end) {
            totalPresent
            totalAbsent
            totalLeaves
            learnerAttendenceData {
              totalPresent
              totalAbsent
              totalLeaves
              studentData {
                id
                firstname
                lastname
              }
              learnerAttendence {
                id
                learner {
                  id
                  firstname
                }
                attendenceDate
                status
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(err => {
      err.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getLearnerAttendanceReportByLearner(payload) {
  return apolloClient
    .query({
      query: gql`
        query getLearnerAttendenceReportByLearner($start: Date!, $end: Date!) {
          getLearnerAttendenceReportByLearner(startDate: $start, endDate: $end) {
            totalPresent
            totalAbsent
            totalLeaves
            learnerAttendenceData {
              totalPresent
              totalAbsent
              totalLeaves
              studentData {
                id
                firstname
                lastname
              }
              learnerAttendence {
                id
                learner {
                  id
                  firstname
                }
                attendenceDate
                status
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => result.data)
    .catch(err => {
      err.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}
