/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
import { Col, Form, notification, Row, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SEARCHABLE_DROPDOWN_OPTIONS } from 'utilities'
import LoadingComponent from 'components/LoadingComponent'
import { useMutation, useQuery } from 'react-apollo'
import StudentCard from './FormCards/studentCard'
import { useTargetAlloc } from './context'
import AntdTag from './antdTag'
import { CREATE_TARGET, GET_SBT, GET_SBT_STEP_STATUS, GET_SBT_STEP_REINFORCERS } from './query'
import '../style.scss'
import AttachmentsCard from './FormCards/attachmentsCard'
import MiscInfoCard from './FormCards/miscInfoCard'
import NumberCard from './FormCards/numberCard'
import PhaseCard from './FormCards/phaseCard'
import SubmitButtonCard from './FormCards/submitButtonCard'
import SdStepCard from './FormCards/sdStepCard'
import StgCard from './FormCards/stgCard'
import BehaviorFieldCard from './FormCards/behaviorFieldCard'
import PromptFieldCard from './FormCards/promptFieldCard'

const dateFormat = 'YYYY-MM-DD'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

function capitalizeFirstLetter(string) {
  if (string) {
    string = string?.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return ''
}

function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
// activeBehave = 'sbt
export default Form.create()(
  ({
    form,
    selectedTargetId = null,
    targetName,
    defaultDomain,
    targetVideo,
    selectedShortTermGoal,
    targetInstr,
    editable = false,
    closeDrawer,
    onSuccessTargetAllocation,
    addTargetMode,
    setTargetFormType,
    editStudent,
    showBackButton,
  }) => {
    const { getFieldDecorator } = form

    const [dailyTrials, setDailyTrials] = useState(0)
    const [sessionConsecutiveDays, setSessionConsecutiveDays] = useState(0)
    const [trialDuration, setTrialDuration] = useState(30)

    const [reinforceMapping, setReinforceMapping] = useState({})

    const [defaultSettings, setDefaultSettings] = useState(null)
    const [targetInstructions, setTargetInstructions] = useState(
      IsJsonString(targetInstr) ? JSON.parse(targetInstr) : targetInstr,
    )

    const [phaseList, setPhaseList] = useState([])
    const [sbtStepsList, setSbtStepsList] = useState([])
    const [targetAtt, setTargetAtt] = useState([])
    const [tagArray, setTagArray] = useState([])

    const {
      useDefaultGoal,
      selectedStudent,
      targetOptions,
      targetOptionsLoading,
      isDomainLoading,
      settingData,
      shortGoalLoading,
      defaultShortTermGoalIdForABA,
      selectedProgram,
    } = useTargetAlloc()

    const [allocateTarget, { loading: allocateTargetLoading }] = useMutation(CREATE_TARGET)

    const { data: sbtData } = useQuery(GET_SBT)
    const { data: sbtStatusData, loading: sbtStatusLoading } = useQuery(GET_SBT_STEP_STATUS)
    const { data: sbtReinforcersData, loading: sbtReinforcersLoading } = useQuery(
      GET_SBT_STEP_REINFORCERS,
    )

    useEffect(() => {
      if (sbtReinforcersData && sbtReinforcersData.getSbtReinforces) {
        const tt = sbtReinforcersData.getSbtReinforces.reduce(
          (prev, curr) => ({ ...prev, [curr.id]: curr.name }),
          {},
        )
        setReinforceMapping(tt)
      }
    }, [sbtReinforcersData])

    useEffect(() => {
      if (sbtStepsList.length === 0) {
        const tempSbt = []
        sbtData?.getSbtDefaultSteps.forEach((item, index) => {
          if (item.isActive) {
            const tempMast = [
              {
                days: item.mastery?.consecutiveDays,
                minTrial: item.mastery?.minTrial,
                id: item.mastery?.id,
                gte: item.mastery?.gte,
                lte: item.mastery?.lte,
                masteryType: capitalizeFirstLetter(item.mastery?.masteryType),
                noOfProblemBehavior: item.mastery?.noOfProblemBehavior,
                resKey: item.mastery?.lte === 100 ? 1 : item.mastery?.gte === 0 ? 0 : null,
                responsePercentage: item.mastery?.responsePercentage,
                fromStatus: item.mastery?.fromStatus?.id,
                toStatus: item.mastery?.toStatus?.id,
              },
            ]

            tempSbt.push({
              id: item.id,
              name: item.description,
              key: index,
              status: item.status?.id || 'U0JUU3RlcFN0YXR1c1R5cGU6MQ==',
              masteryCriteria: null,
              prompt: item.reinforce ? item.reinforce.edges.map(item => item.node.id) : [],
              manualMastery: tempMast,
            })
          }
        })
        setSbtStepsList(tempSbt)
      }
    }, [settingData, sbtData])

    useEffect(() => {
      if (settingData && editable === false) {
        setDailyTrials(settingData.getAllocateTargetSettings.edges[0]?.node.dailyTrials || 5)
        setSessionConsecutiveDays(
          settingData.getAllocateTargetSettings.edges[0]?.node.consecutiveDays || 25,
        )
        setDefaultSettings({
          dailyTrials: settingData.getAllocateTargetSettings.edges[0]?.node.dailyTrials,
          consecutiveDays: settingData.getAllocateTargetSettings.edges[0]?.node.consecutiveDays,
          status: settingData.getAllocateTargetSettings.edges[0]?.node.status,
          masteryCriteria: settingData.getAllocateTargetSettings.edges[0]?.node.masteryCriteria,
          targetType: settingData.getAllocateTargetSettings.edges[0]?.node.targetType,
          promptCodes: settingData.getAllocateTargetSettings.edges[0]?.node.promptCodes.edges,
        })
      }
    }, [settingData])

    const onChangeNumber = (type, num) => {
      if (type === 'sdt') setDailyTrials(num)
      else if (type === 'scd') setSessionConsecutiveDays(num)
      else if (type === 'trdc') {
        setTrialDuration(num)
      }
    }

    const handleSubmit = e => {
      e.preventDefault()
      form.validateFieldsAndScroll((error, values) => {
        let validationMessage = null
        const docsResponse = []
        targetAtt?.forEach(item => {
          docsResponse.push({
            sd: '',
            step: '',
            url: item.url,
          })
        })
        if (values.tarUrl) {
          docsResponse.push({
            sd: '',
            step: '',
            url: values.tarUrl,
          })
        }

        const phaseResponse = phaseList.map(item => ({
          date: item.date.format(dateFormat),
          content: item.note,
        }))

        const sbtStepResponse = sbtStepsList.map((item, index) => {
          return {
            id: item.id,
            orderNo: index + 1,
            description: item.name,
            statusId: item.status,
            reinforce: item.prompt.map(rf => ({ name: reinforceMapping[rf] })),
            mastery: item.manualMastery[0]
              ? {
                  gte: item.manualMastery[0].gte,
                  lte: item.manualMastery[0].lte,
                  isDaily: item.manualMastery[0].masteryType === 'Daily',
                  masteryType: item.manualMastery[0].masteryType,
                  consecutiveDays: item.manualMastery[0].days || 0,
                  noOfProblemBehavior: item.manualMastery[0].noOfProblemBehavior || 0,
                  minTrial: item.manualMastery[0].minTrial || 0,
                  fromStatus: item.manualMastery[0].fromStatus,
                  toStatus: item.manualMastery[0].toStatus,
                }
              : {},
          }
        })

        if (!error) {
          // Other custom validations
          if (useDefaultGoal && !defaultShortTermGoalIdForABA) {
            validationMessage = 'No short term Goal found.'
          } else if (editable === false && !selectedStudent?.id) {
            validationMessage = 'Student not found, Please try again.'
          } else if (editable === false && addTargetMode !== 'manually' && !selectedTargetId) {
            validationMessage = 'Target not found.'
          } else if (dailyTrials < 0) {
            validationMessage = 'Daily trials should be greater than or equal to 0'
          } else if (
            values.type === 'VGFyZ2V0RGV0YWlsVHlwZToxMA==' &&
            sbtStepResponse.length === 0
          ) {
            validationMessage = 'At least one step is required for type Skill Based Treatment.'
          }
          // else if (!targetInstructions) {
          //   validationMessage = 'Target Instruction is mandatory'
          // }
          if (validationMessage) {
            notification.info({ message: validationMessage })
          } else if (editable === false) {
            allocateTarget({
              variables: {
                studentId: selectedStudent?.id,
                programArea: selectedProgram,
                shortTerm: useDefaultGoal ? defaultShortTermGoalIdForABA : values.stg,
                targetId: selectedTargetId,
                targetStatus: values.status,
                targetInstr: targetInstructions ? targetInstructions : '',
                date: moment(values.date).format('YYYY-MM-DD'),
                masteryCriteria: values.masteryCriteria,
                targetName: values.name,
                consecutiveDays: sessionConsecutiveDays,
                targetType: values.type,
                sd: [],
                steps: [],
                video: values.video ? [values.video] : [],
                peakBlocks: 1,
                peakType: null,
                equiCode: null,
                domain: values.domain,
                makeDefault: values.makeDefault,
                dailyTrials,
                classes: [],
                targetDocs: docsResponse,
                prompts: values.prompt,
                phaset: phaseResponse,
                r1: values.type === 'VGFyZ2V0RGV0YWlsVHlwZToxMA==' ? values.r1 : null,
                r2: values.type === 'VGFyZ2V0RGV0YWlsVHlwZToxMA==' ? values.r2 : null,
                sbtSteps: sbtStepResponse,
                recordingType: [],
                trialDuration,
                behaviourDescription: null,
                reactiveProcedure: null,
                antecedentManipulation: null,
                emailMasterStatus: values.emailMasterStatus,
                emailSend: values.emailSend,
                plotZero: values.plotZero,
                caseManagerEmail: values.caseManagerEmail,
                tag: tagArray || [],
                sessionAllocation: values.sessions,
                recordingBehavior: null,
                activeSdStepCount: 0,
                isSdStepRandomize: false,
              },
              errorPolicy: 'all',
            })
              .then(res => {
                if (res.data.createTargetAllocate3) {
                  notification.success({
                    message: 'Target allocated successfully',
                  })
                  form.resetFields()
                  if (onSuccessTargetAllocation) {
                    onSuccessTargetAllocation()
                  }
                  if (closeDrawer) {
                    closeDrawer()
                  }
                }
              })
              .catch(err => console.error(err))
          }
        }
      })
    }

    if (sbtStatusLoading || sbtReinforcersLoading || shortGoalLoading || isDomainLoading) {
      return <LoadingComponent />
    }

    return (
      <div>
        <Form onSubmit={handleSubmit}>
          {/* Allocate directly and student field */}
          <StudentCard
            showBackButton={showBackButton}
            setTargetFormType={setTargetFormType}
            form={form}
            editStudent={editStudent}
          />

          <div style={boxCard}>
            {/* short goal, domain, target name and target type */}
            <StgCard
              form={form}
              addTargetMode={addTargetMode}
              targetName={targetName}
              defaultDomain={defaultDomain}
              defaultSettings={defaultSettings}
              activeBehave="SBT"
              selectedShortTermGoal={selectedShortTermGoal}
            />

            {/* target status and tag */}
            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    initialValue: defaultSettings?.status?.id || targetOptions?.targetStatus[5]?.id,
                    rules: [{ required: true, message: 'Please select a target status' }],
                  })(
                    <Select loading={targetOptionsLoading} {...SEARCHABLE_DROPDOWN_OPTIONS}>
                      {targetOptions?.targetStatus
                        .filter(
                          ({ id }) => !(id === 'U3RhdHVzVHlwZTo3' || id === 'U3RhdHVzVHlwZTo2'),
                        )
                        .map(({ id, statusName }) => {
                          return (
                            <Select.Option key={id} value={id}>
                              {statusName}
                            </Select.Option>
                          )
                        })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tag" name="Tag">
                  <AntdTag
                    changeTagsHandler={e => setTagArray(e)}
                    closeable="true"
                    tagArray={tagArray}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* behavior r1 and r2 */}
            <Row gutter={[24]}>
              <Col span={12}>
                <BehaviorFieldCard
                  form={form}
                  required
                  name="Behavior R1"
                  label="Behavior R1"
                  decoratorName="r1"
                />
              </Col>
              <Col span={12}>
                <BehaviorFieldCard
                  form={form}
                  required
                  name="Behavior R2"
                  label="Behavior R2"
                  decoratorName="r2"
                />
              </Col>
            </Row>

            {/* Prompt */}
            <PromptFieldCard form={form} defaultSettings={defaultSettings} />

            {/* Number fields */}
            <Row gutter={[24]}>
              <Col span={8}>
                <NumberCard
                  required
                  label="Trial Duration"
                  number={trialDuration}
                  form={form}
                  maxValue={300}
                  setNumber={num => onChangeNumber('trdc', num)}
                  addonAfter="sec"
                />
              </Col>
              <Col span={8}>
                <NumberCard
                  required
                  label="Daily Trials"
                  number={dailyTrials}
                  form={form}
                  setNumber={num => onChangeNumber('sdt', num)}
                />
              </Col>

              <Col span={8}>
                <NumberCard
                  label="Consecutive Days"
                  number={sessionConsecutiveDays}
                  form={form}
                  setNumber={num => onChangeNumber('scd', num)}
                />
              </Col>
            </Row>
          </div>

          {/* phase cards */}
          <PhaseCard phaseList={phaseList} setPhaseList={setPhaseList} />

          {/* sbt steps */}
          <SdStepCard
            form={form}
            sbtStepsList={sbtStepsList}
            setSbtStepsList={setSbtStepsList}
            sbtReinforcersData={sbtReinforcersData}
            sbtStatusData={sbtStatusData}
            sbtStep
          />

          {/* target attachments */}
          <AttachmentsCard form={form} targetAtt={targetAtt} setTargetAtt={setTargetAtt} />

          {/* misc info */}
          <MiscInfoCard
            form={form}
            targetVideo={targetVideo}
            activeBehave="SBT"
            setTargetInstructions={setTargetInstructions}
            targetInstructions={targetInstructions}
          />

          <SubmitButtonCard
            form={form}
            closeDrawer={closeDrawer}
            submitLoading={allocateTargetLoading}
          />
        </Form>
      </div>
    )
  },
)
