import { all } from 'redux-saga/effects'

import user from './user/sagas'
import menu from './menu/sagas'
import tasks from './tasks/sagas'
import tickets from './tickets/sagas'
import goals from './goals/sagas'
import family from './family/sagas'
import staffs from './staffs/sagas'
import student from './student/sagas'
import feedback from './feedback/sagas'
import learners from './learners/sagas'
import leaders from './leads/sagas'
import expenses from './expenses/sagas'
import assets from './assets_redux/sagas'
import settings from './settings/sagas'
import screening from './screening/sagas'
import payor from './payor/sagas'
import authorizationCode from './authorizationCodes/sagas'
import authorization from './authorization/sagas'
import claim from './claim/sagas'
import learnersprogram from './learnersprogram/sagas'
import peakequivalence from './peakequivalence/sagas'
import sessionrecording from './sessionrecording/sagas'
import cogniableassessment from './cogniableassessment/sagas'
import iisaassessment from './iisaassessment/sagas'
import iisca from './IISCA/sagas'
import sessiontargetallocation from './sessiontargetallocation/sagas'
import appointments from './appointments/sagas'
import prescriptions from './prescriptions/sagas'
import copyTarget from './copyTarget/sagas'
import school from './school/sagas'
import learnerDashboard from './learnerDashboard/sagas'
import forms from './forms/sagas'
import recordAssessments from './recordAssessments/sagas'
import marketingModal from './marketingModal/sagas'

import coursecontent from './coursecontent/sagas'

import course from './course/sagas'
import courseUser from './courseUser/sagas'
import coupon from './coupon/sagas'
import ticket from './ticketsSuperAdmin/sagas'
import clinic from './clinic/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    tasks(),
    tickets(),
    goals(),
    staffs(),
    family(),
    student(),
    feedback(),
    learners(),
    // leaders used
    leaders(),
    // expenses uesd
    expenses(),
    // assets used
    assets(),
    settings(),
    screening(),
    payor(),
    authorization(),
    claim(),
    authorizationCode(),
    learnersprogram(),
    peakequivalence(),
    sessionrecording(),
    iisaassessment(),
    cogniableassessment(),
    sessiontargetallocation(),
    appointments(),
    prescriptions(),
    iisca(),
    copyTarget(),
    school(),
    learnerDashboard(),
    forms(),
    recordAssessments(),
    marketingModal(),
    coursecontent(),
    course(),
    courseUser(),
    coupon(),
    ticket(),
    clinic(),
  ])
}
