import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-apollo'
import { SUBMITT_BUTTON, GREY_ICON_BUTTON } from 'assets/styles/globalStyles'
import { Form, Select, Input, Button, Row, Col, notification } from 'antd'
import { useMasterySeq } from './context'
import { CREATE_CRITERIA_PHASE } from './query'

const { Item } = Form
const { Option } = Select

const STATUS_MAPPING = {
  U3RhdHVzVHlwZTox: 'Baseline',
  U3RhdHVzVHlwZToz: 'Intervention',
  'U3RhdHVzVHlwZToxMA==': 'Generalization',
  U3RhdHVzVHlwZTo1: 'In-Maintenance',
  U3RhdHVzVHlwZTo0: 'Mastered',
}
const btnStyle = {
  ...GREY_ICON_BUTTON,
  fontSize: 18,
}
function AddCriteriaForm({ form, onSuccess, closeModal, setShowForm }) {
  const { getFieldDecorator, validateFields, getFieldValue } = form

  const [promptName, setPromptName] = useState([])

  const [createCriteriaPhase, { loading: criteriaPhaseLoading }] = useMutation(
    CREATE_CRITERIA_PHASE,
  )
  const { promptCodes, promptLoading, selectedTemplate, statusMap, phaseSet } = useMasterySeq()

  useEffect(() => {
    const promptTemp = getFieldValue('prompt')
    if (promptTemp) {
      const temp = []
      promptCodes.promptCodes.forEach(pt => {
        for (let i = 0; i < promptTemp.length; i += 1) {
          if (pt.id === promptTemp[i]) {
            temp.push(pt.promptName)
          }
        }
      })
      setPromptName(temp)
    }
  }, [getFieldValue('prompt')])

  const handleSubmit = e => {
    e.preventDefault()
    validateFields((errr, values) => {
      if (!errr && selectedTemplate) {
        createCriteriaPhase({
          variables: {
            criteriaType: values.criteriaType,
            criteriaTemplate: selectedTemplate.id,
            stepNo: phaseSet.length + 1,
            prompts: values.prompt || [],
            autoLabel: values.label,
          },
        })
          .then(res => {
            notification.success({
              message: 'New criteria addedd successfully',
            })
            if (onSuccess) {
              onSuccess(res)
            }
            if (closeModal) {
              closeModal()
            }
          })
          .catch(err => console.error(err))
      }
    })
  }

  return (
    <div className="form-card">
      <div className="mastery-p">
        <div>Add New Phase Criteria</div>&nbsp;&nbsp;&nbsp;&nbsp;
        {promptName.length > 0 && (
          <p>
            Prompt:{' '}
            {promptName.map((pt, index) => {
              if (index === promptName.length - 1) {
                return <span key={`${pt}-span`}>{pt}</span>
              }
              return <span key={`${pt}-span`}>{pt}, </span>
            })}
          </p>
        )}
        <Button
          type="link"
          icon="close"
          onClick={() => setShowForm(false)}
          style={{ ...btnStyle, marginLeft: 'auto' }}
        />
      </div>
      <Form style={{ paddingTop: 12 }} onSubmit={handleSubmit}>
        <Row gutter={[24]}>
          <Col span={12}>
            <Item label="Criteria Type">
              {getFieldDecorator('criteriaType', {
                initialValue: 'U3RhdHVzVHlwZTox',
                rules: [{ required: true, message: 'Please select criteria type' }],
              })(
                <Select placeholder="Select Criteria Type">
                  {statusMap.map(it => (
                    <Option key={it.id} value={it.id}>
                      {it.status}
                    </Option>
                  ))}
                </Select>,
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Label">
              {getFieldDecorator('label')(<Input placeholder="Enter label" />)}
            </Item>
          </Col>
        </Row>
        {selectedTemplate.templateType === 'Regular' && (
          <Item label="Prompt">
            {getFieldDecorator('prompt')(
              <Select placeholder="Select Prompt Codes" loading={promptLoading} mode="multiple">
                {promptCodes?.promptCodes.map(prompt => {
                  return (
                    <Option key={prompt.id} value={prompt.id}>
                      {prompt.promptName}
                    </Option>
                  )
                })}
              </Select>,
            )}
          </Item>
        )}
        <div style={{ display: 'flex' }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={criteriaPhaseLoading}
            style={{ ...SUBMITT_BUTTON, margin: '0 auto' }}
          >
            Add
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default Form.create()(AddCriteriaForm)
