// import { GraphQLClient } from 'graphql-request'
/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */

import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

export async function getLeaders(payload) {
  console.log(payload)
  return apolloClient
    .query({
      query: gql`
        query($dateGte: Date, $dateLte: Date) {
          leads(dateGte: $dateGte, dateLte: $dateLte) {
            edges {
              node {
                id
                name
                surname
                email
                projectName
                leadStatus
                leadType
                phone
                createdAt
                date
                location
                therapist {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                user {
                  id
                  email
                  firstName
                  lastName
                }
              }
            }
          }
        }
      `,
      variables: {
        dateGte: payload?.dateGte ? moment(payload.dateGte).format('YYYY-MM-DD') : null,
        dateLte: payload?.dateLte ? moment(payload.dateLte).format('YYYY-MM-DD') : null,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR ', JSON.stringify(error))
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function createLeader(payload) {
  console.log(payload)
  return apolloClient
    .mutate({
      mutation: gql`
        mutation(
          $name: String!
          $leadStatus: String!
          $phone: String!
          $therapist: [ID]
          $surname: String!
          $date: Date # "2021-04-01"
          $email: String
          $leadType: String!
          $location: String
        ) {
          createLead(
            name: $name
            leadStatus: $leadStatus
            date: $date
            email: $email
            therapist: $therapist
            leadType: $leadType
            phone: $phone
            surname: $surname
            location: $location
          ) {
            details {
              id
              name
              projectName
              leadStatus
              phone
              createdAt
              date
              location
              user {
                id
                email
                firstName
                lastName
              }
            }
          }
        }
      `,
      variables: {
        name: payload.values.firstName,
        surname: payload.values.lastName,
        leadStatus: payload.values.leadStatus,
        therapist: payload.therapistId ? payload.therapistId : payload.values.therapist,
        date: moment(payload.values.date).format('YYYY-MM-DD'),
        email: payload.values.email,
        leadType: payload.values.leadType,
        phone: payload.values.mobileNo,
        location: payload.values.location,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      return error
    })
}

export async function updateLeader(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation(
          $id: ID!
          $leadStatus: String
          $leadType: String
          $phone: String
          $name: String
          $surname: String
          $date: Date # "2021-04-01"
          $email: String
          $location: String
          $therapist: [ID]
        ) {
          updateLead(
            pk: $id
            leadStatus: $leadStatus
            leadType: $leadType
            phone: $phone
            name: $name
            surname: $surname
            date: $date
            email: $email
            location: $location
            therapist: $therapist
          ) {
            details {
              id
              name
              projectName
              leadStatus
              phone
              createdAt
              date
              location
              therapist {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              user {
                id
                email
                firstName
                lastName
              }
            }
          }
        }
      `,
      variables: {
        id: payload.id,
        therapist: payload.values.therapist,
        leadStatus: payload.values.leadStatus,
        leadType: payload.values.leadType,
        phone: payload.values.mobileNo,
        name: payload.values.firstName,
        surname: payload.values.lastName,
        date: moment(payload.values.date).format('YYYY-MM-DD'),
        email: payload.values.email,
        location: payload.values.location,
      },
    })
    .then(result => result)
    .catch(error => {
      return error
    })
}

export async function deleteLeader(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($id: ID!) {
          deleteLead(pk: $id) {
            status
            message
          }
        }
      `,
      variables: {
        id: payload.id,
      },
    })
    .then(result => result)
    .catch(error => console.error(error, 'error'))
}
