/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react'
import { Form, Input, Button, notification } from 'antd'
import { useMutation } from 'react-apollo'
import { CREATE_CENTER, UPDATE_CENTER } from './query'

const CreateCenterForm = ({ form, onSuccess, center }) => {
  const [updateCenter] = useMutation(UPDATE_CENTER)
  const [createCenter] = useMutation(CREATE_CENTER)

  useEffect(() => {
    if (center) {
      form.setFieldsValue({
        centerCode: center.centerCode,
        centerName: center.centerName,
        address: center.address,
      })
    }
  }, [center])

  const handleCreateCenter = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        const variables = {
          centerName: values.centerName,
          address: values.address,
          centerCode: values.centerCode,
        }
        if (center) {
          updateCenter({
            variables: {
              centerID: center.id,
              ...variables,
            },
          })
            .then(res => {
              notification.success({
                message: 'Success',
                description: 'Center updated successfully',
              })
              onSuccess()
            })
            .catch(error => {
              form.resetFields()
              onSuccess()
            })
        } else {
          createCenter({
            variables: {
              ...variables,
            },
          })
            .then(res => {
              notification.success({
                message: 'Success',
                description: 'Center created successfully',
              })
              onSuccess()
            })
            .catch(error => {
              form.resetFields()
              onSuccess()
            })
        }
      }
    })
  }

  return (
    <Form onSubmit={handleCreateCenter}>
      <Form.Item label="Center title">
        {form.getFieldDecorator('centerName', {
          rules: [
            {
              required: true,
              message: 'Please Enter Center Name',
            },
          ],
        })(<Input placeholder="Center title" />)}
      </Form.Item>
      <Form.Item label="Center Code">
        {form.getFieldDecorator('centerCode', {
          rules: [
            {
              required: true,
              message: 'Please Enter Center Code',
            },
          ],
        })(<Input placeholder="Center code" />)}
      </Form.Item>
      <Form.Item label="Center Address">
        {form.getFieldDecorator('address')(<Input placeholder="Center address" />)}
      </Form.Item>
      <Button type="primary" htmlType="submit">
        {center ? 'Edit' : 'Submit and Create'}
      </Button>
    </Form>
  )
}

export default Form.create()(CreateCenterForm)
