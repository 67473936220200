import gql from 'graphql-tag'

export const ALL_STUDENT = gql`
  query {
    students(isActive: true) {
      edges {
        node {
          id
          firstname
          lastname
          internalNo
          insuranceSet {
            edges {
              node {
                id
                payor {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_APPOINTMENT_DETAILS = gql`
  query($id: ID!) {
    appointment(id: $id) {
      id
      therapist {
        id
        name
        surname
      }
      student {
        id
        firstname
        lastname
      }
      attendee {
        edges {
          node {
            id
            name
            surname
          }
        }
      }
      createdBy {
        id
        firstName
        lastName
      }
      appointmentStatus {
        id
        appointmentStatus
      }
      location {
        id
        location
      }
      payor {
        id
        name
      }
      purposeAssignment
      note
      title
      start
      end
      isApproved
      appointmentmodifierSet {
        edges {
          node {
            id
            appointment {
              id
              lengthTime
            }
            authorizationModifier {
              id
              code {
                id
                code
              }
              pendingUnit
              claimedUnit
              rate
              feeSchedule {
                id
                name
              }
              authorizedUnit
              workedUnit
            }
            minutes
            createdBy {
              username
            }
            updatedBy {
              username
            }
          }
        }
      }
    }
  }
`
