import React, { useContext } from 'react'
import { FaRegCircle, FaCircle } from 'react-icons/fa'
import _ from 'lodash'
import { LoadingContext } from '../context'

const MilestoneDots = ({ allQuestions, indexes, scoreDetails, groupName }) => {
  const context = useContext(LoadingContext)
  const { milestoneData } = context
  const groupeddata = _.groupBy(milestoneData.data, item => {
    return item.details.masterRecord.testNo
  })
  const groupByName = data => {
    const group = _.groupBy(data?.details?.records?.edges, ({ node }) => {
      return node.groups?.groupName
    })

    const groupQuestion = {}
    Object.keys(group).forEach(item => {
      group[item].questions = {}
      group[item].forEach(({ node }) => {
        if (node.questionNum) {
          group[item].questions[node.questionNum] = {
            id: node.id,
            questionNum: node.questionNum,
            score: node.score,
            color: data.details.masterRecord.color,
            testName: `Test #${data.details.masterRecord.testNo}`,
          }
        }
      })
    })
    return group
  }

  const getCircleStyle = assesment => {
    const answeredQuestions = []
    let filtered = []
    Object.keys(groupeddata).forEach(item => {
      const test = `Test #${item}`

      if (test === assesment.testTitle) {
        filtered = groupeddata[item]
      }
    })
    const g = filtered.length ? groupByName(filtered[0]) : {}
    const filtergroup = Object.keys(g).filter(item => {
      return item === groupName
    })

    const answered = []
    if (filtergroup.length) {
      indexes.forEach(index => {
        answeredQuestions.push(allQuestions[index])
        answered.push(g[filtergroup[0]].questions[index])
      })
    }

    const isAllAnswersAreWrong =
      answered.length &&
      answered.every(que => que && que.testName === assesment.testTitle && que.score === 0)

    return isAllAnswersAreWrong ? (
      <FaCircle color={assesment.color} />
    ) : (
      <FaRegCircle color={assesment.color === 'trasparant' ? '#c4c4c4' : assesment.color} />
    )
  }

  return (
    <div className="milestoneDots">
      {scoreDetails.map(assesment => (
        <div key={assesment.key} className="dot">
          {getCircleStyle(assesment)}
        </div>
      ))}
    </div>
  )
}
export default MilestoneDots
