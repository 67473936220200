/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
import { all, takeEvery, put, call, select, takeLatest } from 'redux-saga/effects'
import {
  createCourseModule,
  createCourseTemplate,
  createModuleContent,
  createModuleTest,
  createModuleTestQuestion,
  deleteCourseModule,
  deleteCourseTemplate,
  deleteModuleContent,
  deleteModuleTest,
  deleteModuleTestQuestion,
  getCourseById,
  getCourseDashboard,
  getCourseTemplates,
  getCurrency,
  getPublicCourseTemplates,
  updateCourseModule,
  updateCourseTemplate,
  updateModuleContent,
  updateModuleTest,
  updateModuleTestQuestion,
} from 'services/courseWork'
import { notification } from 'antd'
import actions from './actions'

export function* GET_CURRENCY() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingCurrency: true,
    },
  })

  const response = yield call(getCurrency)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        currency: response.currency,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingCurrency: false,
    },
  })
}
export function* GET_COURSE_TEMPLATES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      coursesLoading: true,
    },
  })
  const response = yield call(getCourseTemplates)
  if (response) {
    const moduleCollapsed = []
    const contentCollapsed = []
    const testCollapsed = []
    const collapsed = response.getCourseTemplates.map(item => {
      item.coursetemplatemodulesSet.edges.forEach(mod => {
        moduleCollapsed.push({
          id: mod.node.id,
          name: mod.node.name,
          isCollapsed: false,
        })
        mod.node.coursemodulescontentSet.edges.forEach(cont => {
          contentCollapsed.push({
            id: cont.node.id,
            name: cont.node.name,
            isCollapsed: false,
          })
        })
        mod.node.coursemodulestestsSet.edges.forEach(test => {
          testCollapsed.push({
            id: test.node.id,
            name: test.node.title,
            isCollapsed: false,
          })
        })
      })
      return { id: item.id, isCollapsed: false }
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        courseCollapsed: collapsed,
        contentCollapsed,
        moduleCollapsed,
        testCollapsed,
        courses: response.getCourseTemplates,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      coursesLoading: false,
    },
  })
}

export function* GET_PUBLIC_COURSE_TEMPLATES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      coursesLoading: true,
    },
  })
  const response = yield call(getPublicCourseTemplates)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        publicCourses: response.getPublicCourses,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      coursesLoading: false,
    },
  })
}

export function* GET_COURSE_BY_ID({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      courseLoading: true,
    },
  })
  const response = yield call(getCourseById, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedCourse: response.getCourseTemplate,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      courseLoading: false,
    },
  })
}

export function* CREATE_COURSE_TEMPLATES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      courseSaving: true,
    },
  })
  const response = yield call(createCourseTemplate, payload)
  if (response) {
    yield put({
      type: actions.APPEND_COURSE_TEMPLATE,
      payload: response.createCourseTemplate.courseTemplate,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        addCourseDrawer: false,
      },
    })
    notification.success({
      message: 'Course Successfully Created!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      courseSaving: false,
    },
  })
}

export function* UPDATE_COURSE_TEMPLATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      courseSaving: true,
    },
  })
  const response = yield call(updateCourseTemplate, payload)
  if (response) {
    yield put({
      type: actions.REPLACE_COURSE_TEMPLATE,
      payload: response.updateCourseTemplate.courseTemplate,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        addCourseDrawer: false,
      },
    })
    notification.success({
      message: 'Course Successfully Updated!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      courseSaving: false,
    },
  })
}

export function* DELETE_COURSE_TEMPLATE({ payload }) {
  const response = yield call(deleteCourseTemplate, payload)
  if (response) {
    notification.success({
      message: `${response.activeInactiveCourseTemplate.message}`,
    })
    yield call(GET_COURSE_TEMPLATES)
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      coursesLoading: false,
    },
  })
}
export function* CREATE_COURSE_MODULE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      moduleSaving: true,
    },
  })
  const response = yield call(createCourseModule, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        addModuleDrawer: false,
      },
    })
    yield put({
      type: actions.APPEND_COURSE_MODULE,
      payload: response.createCourseTemplateModule.templateModule,
    })
    notification.success({
      message: 'Course Module Successfully Created!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      moduleSaving: false,
    },
  })
}
export function* UPDATE_COURSE_MODULE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      moduleSaving: true,
    },
  })
  const response = yield call(updateCourseModule, payload)
  const { selectedModule } = yield select(state => state.course)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedModule: {
          ...selectedModule,
          ...payload,
        },
        addModuleDrawer: false,
      },
    })
    yield put({
      type: actions.REPLACE_COURSE_MODULE,
      payload: response.updateCourseTemplateModule.templateModule,
    })

    notification.success({
      message: 'Course Module Successfully Updated!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      moduleSaving: false,
    },
  })
}
export function* DELETE_COURSE_MODULE({ payload }) {
  const response = yield call(deleteCourseModule, payload)
  if (response) {
    yield put({
      type: actions.POP_COURSE_MODULE,
      payload,
    })

    notification.success({
      message: `${response.deleteCourseTemplateModule.message}`,
    })
  }
}

export function* CREATE_MODULE_CONTENT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      contentSaving: true,
    },
  })
  const response = yield call(createModuleContent, payload)
  if (response) {
    yield put({
      type: actions.APPEND_MODULE_CONTENT,
      payload: response.createCourseModuleContent.moduleContent,
    })

    notification.success({
      message: 'Course Content Successfully Created!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      contentSaving: false,
    },
  })
}

export function* UPDATE_MODULE_CONTENT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      contentSaving: true,
    },
  })
  const response = yield call(updateModuleContent, payload)
  if (response) {
    yield put({
      type: actions.REPLACE_MODULE_CONTENT,
      payload: response.updateCourseModuleContent.moduleContent,
    })
    notification.success({
      message: 'Course Content Successfully Updated!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      contentSaving: false,
    },
  })
}

export function* DELETE_MODULE_CONTENT({ payload }) {
  const response = yield call(deleteModuleContent, payload)
  if (response) {
    yield put({
      type: actions.POP_MODULE_CONTENT,
      payload,
    })

    notification.success({
      message: `${response.deleteCourseModuleContent.message}`,
    })
  }
}

export function* CREATE_MODULE_TEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      testSaving: true,
    },
  })
  const response = yield call(createModuleTest, payload)
  if (response) {
    yield put({
      type: actions.APPEND_MODULE_TEST,
      payload: response.createCourseModuleTest.moduleTest,
    })

    notification.success({
      message: 'Course Test Successfully Created!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      testSaving: false,
    },
  })
}

export function* UPDATE_MODULE_TEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      testSaving: true,
    },
  })
  const response = yield call(updateModuleTest, payload)
  if (response) {
    yield put({
      type: actions.REPLACE_MODULE_TEST,
      payload: response.updateCourseModuleTest.moduleTest,
    })

    notification.success({
      message: 'Course Test Successfully Updated!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      testSaving: false,
    },
  })
}

export function* DELETE_MODULE_TEST({ payload }) {
  const response = yield call(deleteModuleTest, payload)
  if (response) {
    yield put({
      type: actions.POP_MODULE_TEST,
      payload,
    })

    notification.success({
      message: `${response.deleteCourseModuleTest.message}`,
    })
  }
}

export function* CREATE_TEST_QUESTION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      questionSaving: true,
    },
  })
  const response = yield call(createModuleTestQuestion, payload)
  if (response) {
    yield put({
      type: actions.APPEND_TEST_QUESTION,
      payload: response.createModuleTestQuestion.testQuestion,
    })

    notification.success({
      message: 'Course Test Question Successfully Created!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      questionSaving: false,
    },
  })
}

export function* UPDATE_TEST_QUESTION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      questionSaving: true,
    },
  })
  const response = yield call(updateModuleTestQuestion, payload)
  if (response) {
    yield put({
      type: actions.REPLACE_TEST_QUESTION,
      payload: response.updateModuleTestQuestion.testQuestion,
    })

    notification.success({
      message: 'Course Test Question Successfully Updated!.',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      questionSaving: false,
    },
  })
}
export function* DELETE_TEST_QUESTION({ payload }) {
  const response = yield call(deleteModuleTestQuestion, payload)
  if (response) {
    yield put({
      type: actions.POP_TEST_QUESTION,
      payload,
    })

    notification.success({
      message: `${response.deleteModuleTestQuestion.message}`,
    })
  }
}

export function* GET_DASHBOARD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adminCourseLoading: true,
    },
  })
  const response = yield call(getCourseDashboard, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        adminCourse: response.getCourseDashboardData,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      adminCourseLoading: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_COURSE_TEMPLATES, GET_COURSE_TEMPLATES),
    takeLatest(actions.GET_PUBLIC_COURSE_TEMPLATES, GET_PUBLIC_COURSE_TEMPLATES),
    takeLatest(actions.GET_COURSE_BY_ID, GET_COURSE_BY_ID),

    takeLatest(actions.GET_CURRENCY, GET_CURRENCY),

    takeLatest(actions.CREATE_COURSE_TEMPLATES, CREATE_COURSE_TEMPLATES),
    takeLatest(actions.UPDATE_COURSE_TEMPLATE, UPDATE_COURSE_TEMPLATE),
    takeLatest(actions.DELETE_COURSE_TEMPLATE, DELETE_COURSE_TEMPLATE),

    takeLatest(actions.CREATE_COURSE_MODULE, CREATE_COURSE_MODULE),
    takeLatest(actions.UPDATE_COURSE_MODULE, UPDATE_COURSE_MODULE),
    takeLatest(actions.DELETE_COURSE_MODULE, DELETE_COURSE_MODULE),

    takeLatest(actions.CREATE_MODULE_CONTENT, CREATE_MODULE_CONTENT),
    takeLatest(actions.UPDATE_MODULE_CONTENT, UPDATE_MODULE_CONTENT),
    takeLatest(actions.DELETE_MODULE_CONTENT, DELETE_MODULE_CONTENT),

    takeLatest(actions.CREATE_MODULE_TEST, CREATE_MODULE_TEST),
    takeLatest(actions.UPDATE_MODULE_TEST, UPDATE_MODULE_TEST),
    takeLatest(actions.DELETE_MODULE_TEST, DELETE_MODULE_TEST),

    takeLatest(actions.CREATE_TEST_QUESTION, CREATE_TEST_QUESTION),
    takeLatest(actions.UPDATE_TEST_QUESTION, UPDATE_TEST_QUESTION),
    takeLatest(actions.DELETE_TEST_QUESTION, DELETE_TEST_QUESTION),

    takeLatest(actions.GET_DASHBOARD, GET_DASHBOARD),
  ])
}
