/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Drawer, Table } from 'antd'
import { DRAWER } from 'assets/styles/globalStyles'
import React, { Children, useCallback, useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'

import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import C1 from 'pages/SessionDataRecordingNew/CustomSessionScreennew'

import { useLazyQuery } from 'react-apollo'
import {
  GET_SESSION_RECORDINGS,
  GET_SESSION_RECORDINGS_IN_RANGE,
  GET_TODAYS_SESSION,
} from './query'

const EditableTable = ({ date, peakSd, selectedTarget }) => {
  const [dataSource, setDataSource] = useState([])
  const [allSessions, setAllSessions] = useState([])
  const [visibleSession, setVisibleSession] = useState(false)

  const dispatch = useDispatch()

  const [
    getSessionRecording,
    {
      data: sessionData,
      loading: sessionDataLoading,
      error: sessionDataError,
      refetch: refetchSession,
    },
  ] = useLazyQuery(GET_SESSION_RECORDINGS, {
    fetchPolicy: 'network-only',
  })

  const [
    getSessionRecordingRange,
    {
      data: sessionDataInRange,
      loading: sessionDataRangeLoading,
      error: sessionDataRangeError,
      refetch: refetchSessionRange,
    },
  ] = useLazyQuery(GET_SESSION_RECORDINGS_IN_RANGE, {
    fetchPolicy: 'network-only',
  })

  const student = useSelector(state => state.student)
  const [
    getAllSessionsData,
    {
      data: allSessionData,
      refetch: refetchAllSession,
      error: allSessionError,
      loading: allSessionDataLoading,
    },
  ] = useLazyQuery(GET_TODAYS_SESSION, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getAllSessionsData({
      variables: {
        studentId: student.StudentId,
        date: moment(date).format('YYYY-MM-DD'),
        targets: [selectedTarget.id],
      },
    })
    getSessionRecording({
      variables: {
        studentId: student.StudentId,
        sessiondate: moment(date).format('YYYY-MM-DD'),
        targets: selectedTarget.id,
      },
    })
    getSessionRecordingRange({
      variables: {
        targets: selectedTarget.id,
        date_Gte: moment(date).format('YYYY-MM-DD'),
        date_Lte: moment(date).format('YYYY-MM-DD'),
      },
    })
  }, [])

  useEffect(() => {
    if (allSessionData && allSessionData.GetStudentSession) {
      const { edges } = allSessionData.GetStudentSession
      setAllSessions(edges)
    }
  }, [allSessionData])

  useEffect(() => {
    if (sessionDataError) {
      console.error(sessionDataError)
    }
    if (allSessionError) {
      console.error(allSessionError)
    }
    if (sessionDataRangeError) {
      console.error(sessionDataRangeError)
    }
  }, [sessionDataError, allSessionError, sessionDataRangeError])

  useEffect(() => {
    if (sessionData && allSessions && sessionDataInRange) {
      // const {
      //   getSessionRecordings: { edges },
      // } = sessionData

      const {
        getSessionDataRecording: { edges },
      } = sessionDataInRange

      const tableData = []
      if (edges.length > 0) {
        edges.forEach(({ node }, index) => {
          const {
            sessionRecord: { edges: sessionRecordEdges },
            peak: { edges: peakRecordEdges },
            peakEquivalance: { edges: peakEq },
            ChildSession: {
              sessionNo,
              sessions: { sessionName },
            },
          } = node
          console.log('node>>>', node)
          if (sessionRecordEdges.length > 0 && peakRecordEdges.length === 0) {
            const rec = sessionRecordingInstance(sessionRecordEdges, peakSd, sessionName, index)
            tableData.push({
              ...rec,
              targetId: node.targets?.id,
              childSession: node.ChildSession,
              name: `${sessionName.name}-${node.ChildSession.sessionNo}`,
            })
          } else if (peakRecordEdges.length > 0 && sessionRecordEdges.length === 0) {
            const rec = peakRecordingInstance(peakRecordEdges, peakSd, sessionName, index)
            tableData.push({
              ...rec,
              targetId: node.targets?.id,
              childSession: node.ChildSession,
              name: `${sessionName.name}-${node.ChildSession.sessionNo}`,
            })
          } else if (peakEq.length > 0) {
            const rec = peakEquRecordingInstance(peakEq, peakSd, sessionName, index)
            tableData.push({
              ...rec,
              targetId: node.targets?.id,
              childSession: node.ChildSession,
              name: `${sessionName.name}-${node.ChildSession.sessionNo}`,
            })
          }
        })

        const rec = createTotalForTable(tableData)
        tableData.push(rec)

        // if (tableData.length <= allSessions.length) {
        allSessions.forEach(({ node }) => {
          console.log('all sessions>>>', node)
          const check = tableData.filter(item => item.session === node.sessionName.name)

          tableData.push({
            key: Math.random(),
            id: node.id,
            duration: node.duration,
            date: node.date,
            sessionNameId: node.sessionName?.id,
            session: node.sessionName?.name,
            name: node.sessionName?.name,
            incorrect: 0,
            prompt: 0,
            correct: 0,
            totalTrials: 0,
            percentage: 0,
          })
        })
        // }

        if (tableData.length === 0) {
          setDataSource([])
        } else {
          setDataSource(tableData)
        }
      } else {
        const recordedData = noRecordingInstance(allSessions)
        setDataSource(recordedData)
      }
    }
  }, [sessionData, allSessions, sessionDataInRange])

  const sessionRecordingInstance = useCallback((trial, recordType, sessionName, index) => {
    const { id, name } = sessionName
    let correctNumber
    let inCorrectNumber
    let promptNumber
    let totalTrials
    if (trial.length === 0) {
      correctNumber = 0
      inCorrectNumber = 0
      promptNumber = 0
      totalTrials = 0
    } else if (recordType) {
      const {
        node: { sd },
      } = trial[0]
      if (sd) {
        const sdSessionTrial = trial.filter(item => item.node?.sd?.sd === recordType)
        if (sdSessionTrial.length > 0) {
          const correct = sdSessionTrial.filter(item => item.node.trial === 'CORRECT')
          correctNumber = correct.length

          const inCorrect = sdSessionTrial.filter(
            item => item.node.trial === 'INCORRECT' || item.node.trial === 'ERROR',
          )
          inCorrectNumber = inCorrect.length

          const prompt = sdSessionTrial.filter(item => item.node.trial === 'PROMPT')
          promptNumber = prompt.length

          totalTrials = correctNumber + inCorrectNumber + promptNumber
        } else {
          totalTrials = 0
          correctNumber = 0
          inCorrectNumber = 0
          promptNumber = 0
        }
      } else {
        const stepSessionTrial = trial.filter(item => item.node?.step?.step === recordType)
        if (stepSessionTrial.length > 0) {
          const correct = stepSessionTrial.filter(item => item.node.trial === 'CORRECT')
          correctNumber = correct.length

          const inCorrect = stepSessionTrial.filter(
            item => item.node.trial === 'INCORRECT' || item.node.trial === 'ERROR',
          )
          inCorrectNumber = inCorrect.length

          const prompt = stepSessionTrial.filter(item => item.node.trial === 'PROMPT')
          promptNumber = prompt.length

          totalTrials = correctNumber + inCorrectNumber + promptNumber
        } else {
          totalTrials = 0
          correctNumber = 0
          inCorrectNumber = 0
          promptNumber = 0
        }
      }
    } else {
      const correct = trial.filter(item => item.node.trial === 'CORRECT')
      correctNumber = correct.length

      const inCorrect = trial.filter(
        item => item.node.trial === 'INCORRECT' || item.node.trial === 'ERROR',
      )
      inCorrectNumber = inCorrect.length

      const prompt = trial.filter(item => item.node.trial === 'PROMPT')
      promptNumber = prompt.length

      totalTrials = correctNumber + inCorrectNumber + promptNumber
    }

    let recordPercentage
    if (totalTrials) {
      recordPercentage = Math.floor((correctNumber / totalTrials) * 100)
    } else {
      recordPercentage = 0
    }
    return {
      key: `${id + index}`,
      sessionNameId: id,
      session: name,
      correct: correctNumber,
      incorrect: inCorrectNumber,
      prompt: promptNumber,
      totalTrials,
      percentage: recordPercentage,
    }
  }, [])

  const peakRecordingInstance = useCallback((blocks, recordType, sessionName, index) => {
    const { id, name } = sessionName
    let marks0Number
    let marks2Number
    let marks4Number
    let marks8Number
    let marks10Number
    let totalTrials
    let recordPercentage

    if (blocks.length === 0) {
      marks0Number = 0
      marks2Number = 0
      marks4Number = 0
      marks8Number = 0
      marks10Number = 0
      totalTrials = 0
      recordPercentage = 0
    } else {
      const sdPeakTrailArray = []
      blocks.forEach(({ node }) => {
        const {
          trial: { edges },
        } = node
        edges.forEach(ed => {
          const sd = ed.node.sd?.sd
          if (peakSd) {
            if (peakSd === sd) {
              sdPeakTrailArray.push(ed)
            }
          } else {
            sdPeakTrailArray.push(ed)
          }
        })
      })
      if (sdPeakTrailArray.length > 0) {
        const marks0 = sdPeakTrailArray.filter(item => item.node.marks === 0)
        marks0Number = marks0.length

        const marks2 = sdPeakTrailArray.filter(item => item.node.marks === 2)
        marks2Number = marks2.length

        const marks4 = sdPeakTrailArray.filter(item => item.node.marks === 4)
        marks4Number = marks4.length

        const marks8 = sdPeakTrailArray.filter(item => item.node.marks === 8)
        marks8Number = marks8.length

        const marks10 = sdPeakTrailArray.filter(item => item.node.marks === 10)
        marks10Number = marks10.length

        totalTrials = marks0Number + marks2Number + marks4Number + marks8Number + marks10Number
      } else {
        totalTrials = 0
        marks0Number = 0
        marks2Number = 0
        marks4Number = 0
        marks8Number = 0
        marks10Number = 0
      }

      if (totalTrials) {
        recordPercentage = ((marks10Number / totalTrials) * 100).toFixed(0)
      } else {
        recordPercentage = 0
      }
    }

    return {
      key: `${id + index}`,
      sessionNameId: id,
      session: name,
      incorrect: marks0Number,
      prompt: marks2Number + marks4Number + marks8Number,
      correct: marks10Number,
      totalTrials,
      percentage: recordPercentage,
    }
  }, [])

  const peakEquRecordingInstance = useCallback((classes, recordType, sessionName, index) => {
    const { id, name } = sessionName

    let marks0Number
    let marks2Number
    let marks4Number
    let marks8Number
    let marks10Number
    let totalTrials
    let recordPercentage

    if (classes.length === 0) {
      marks0Number = 0
      marks2Number = 0
      marks4Number = 0
      marks8Number = 0
      marks10Number = 0
      totalTrials = 0
      recordPercentage = 0
    } else {
      const sdPeakTrailArray = []
      classes.forEach(({ node }) => {
        let sd
        if (node.recType === 'TRAIN') {
          const kk = Object.keys(node.relationTrain)
          sd = `${node.relationTrain[kk[1]]}${node.relationTrain[kk[2]]}${
            node.relationTrain[kk[3]]
          }`
        } else if (node.recType === 'TEST') {
          const kk = Object.keys(node.relationTest)
          sd = `${node.relationTest[kk[1]]}${node.relationTest[kk[2]]}${node.relationTest[kk[3]]}`
        }
        if (peakSd) {
          if (peakSd === sd) {
            sdPeakTrailArray.push(node)
          }
        } else {
          sdPeakTrailArray.push(node)
        }
      })
      if (sdPeakTrailArray.length > 0) {
        totalTrials = sdPeakTrailArray.length
        const marks0 = sdPeakTrailArray.filter(item => item.score === 0)
        marks0Number = marks0.length

        const marks2 = sdPeakTrailArray.filter(item => item.score === 2)
        marks2Number = marks2.length

        const marks4 = sdPeakTrailArray.filter(item => item.score === 4)
        marks4Number = marks4.length

        const marks8 = sdPeakTrailArray.filter(item => item.score === 8)
        marks8Number = marks8.length

        const marks10 = sdPeakTrailArray.filter(item => item.score === 10)
        marks10Number = marks10.length
      } else {
        totalTrials = 0
        marks0Number = 0
        marks2Number = 0
        marks4Number = 0
        marks8Number = 0
        marks10Number = 0
      }

      if (totalTrials) {
        recordPercentage = ((marks10Number / totalTrials) * 100).toFixed(0)
      } else {
        recordPercentage = 0
      }
    }

    return {
      key: `${id + index}`,
      sessionNameId: id,
      session: name,
      incorrect: marks0Number,
      prompt: marks2Number + marks4Number + marks8Number,
      correct: marks10Number,
      totalTrials,
      percentage: recordPercentage,
    }
  }, [])

  const noRecordingInstance = useCallback(sessions => {
    const tableData = []

    if (sessions.length === 0) {
      return tableData
    }

    sessions.forEach(({ node }, index) => {
      tableData.push({
        key: `${node.id + index}`,
        sessionNameId: node.sessionName.id,
        session: node.sessionName.name,
        name: node.sessionName.name,
        correct: 0,
        incorrect: 0,
        prompt: 0,
        totalTrials: 0,
        percentage: 0,
      })
    })
    tableData.push({
      key: tableData.length + 10,
      session: 'Total',
      name: 'Total',
      correct: 0,
      incorrect: 0,
      prompt: 0,
      totalTrials: 0,
      percentage: 0,
    })
    return tableData
  }, [])

  const createTotalForTable = useCallback(tableData => {
    const correctNumber = tableData.map(({ correct }) => correct).reduce((a, b) => a + b, 0)
    const incorrectNumber = tableData.map(({ incorrect }) => incorrect).reduce((a, b) => a + b, 0)
    const promptNumber = tableData.map(({ prompt }) => prompt).reduce((a, b) => a + b, 0)
    const totalTrialsNumber = tableData
      .map(({ totalTrials }) => totalTrials)
      .reduce((a, b) => a + b, 0)
    let percentageNumber
    if (totalTrialsNumber) {
      percentageNumber = Math.floor((correctNumber / totalTrialsNumber) * 100)
    } else {
      percentageNumber = 0
    }
    return {
      key: tableData.length + 10,
      session: 'Total',
      name: 'Total',
      correct: correctNumber,
      incorrect: incorrectNumber,
      prompt: promptNumber,
      totalTrials: totalTrialsNumber,
      percentage: percentageNumber,
    }
  }, [])

  const handleStartSessionRecording = rowData => {
    const { sessionNameId, session } = rowData
    console.log('rowdata>>', rowData)
    if (session === 'Total') {
      return
    }

    const { edges } = allSessionData.GetStudentSession
    const mainSession = edges.find(item => item.node.sessionName.id === sessionNameId)

    if (mainSession) {
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          SessionId: rowData.childSession ? rowData.childSession.sessions.id : mainSession.node.id,
          SessionDate:
            date !== null ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          NewVersion: rowData.childSession === undefined,
          DefaultChildSession: rowData.childSession ? rowData.childSession : null,
        },
      })
      // dispatch({
      //   type: 'sessionrecording/SET_STATE',
      //   payload: {
      //     SessionId: mainSession.node.id,
      //     SessionDate:
      //       date !== null ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      //   },
      // })
      setVisibleSession(true)
    }
  }

  const handleCloseSessionDrawer = () => {
    refetchSession()
    refetchAllSession()
    refetchSessionRange()
    setVisibleSession(false)

    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        FetchForSingleTarget: false,
      },
    })
  }

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    }
    if (row.childSession === undefined && row.name !== 'Total') {
      obj.props.colSpan = 0
    }
    return obj
  }

  const columns = [
    {
      title: 'Session',
      key: '1',

      render: row => {
        const { session, name } = row
        if (row.childSession === undefined && name !== 'Total') {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                <Button
                  style={{ fontWeight: 'bold' }}
                  type="primary"
                  onClick={() => handleStartSessionRecording(row)}
                >
                  Start New {name} Session <PlusOutlined />
                </Button>
              </div>
            ),
            props: {
              colSpan: 6,
            },
          }
        }
        return (
          <span
            role="button"
            style={{
              fontWeight: 'bold',
              cursor: 'pointer',
              color: '#4A7AB4',
            }}
            onClick={() => handleStartSessionRecording(row)}
          >
            {name} {''}
          </span>
        )
      },
    },
    {
      title: 'Correct',
      dataIndex: 'correct',
      key: '2',
      render: renderContent,
    },
    {
      title: 'Incorrect',
      dataIndex: 'incorrect',
      key: '3',
      render: renderContent,
    },
    {
      title: 'Prompted',
      dataIndex: 'prompt',
      key: '4',
      render: renderContent,
    },
    {
      title: 'Total Trials ',
      key: '5',
      dataIndex: 'totalTrials',
      render: renderContent,
    },
    {
      title: 'Percentage Correct',
      key: '6',
      dataIndex: 'percentage',
      render: (text, row, index) => {
        return renderContent(`${text}%`, row, index)
      },
    },
  ]

  console.log('selectedTarget>>>', dataSource)
  return (
    <>
      {peakSd && (
        <p style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
          <span> {peakSd.toUpperCase()} </span>
        </p>
      )}
      <p style={{ fontSize: 18, fontWeight: 'bold' }}>
        <span>Date: </span> {moment(date).format('YYYY-MM-DD')}
      </p>
      <Drawer
        title={selectedTarget?.targetAllcatedDetails.targetName}
        placement="right"
        width={DRAWER.widthL1}
        closable
        destroyOnClose
        onClose={handleCloseSessionDrawer}
        visible={visibleSession}
      >
        {/* <C1 selectedTarget={selectedTarget} /> */}
        <C1 selectedTarget={selectedTarget.id} />
      </Drawer>

      <Table
        loading={sessionDataLoading || allSessionDataLoading}
        columns={columns}
        dataSource={dataSource}
        bordered
      />
    </>
  )
}
export default EditableTable
