/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import groupObj from '@hunters/group-object'
import moment from 'moment'
import { FaDownload } from 'react-icons/fa'
import { COLORS, DRAWER } from 'assets/styles/globalStyles'
import { Row, Col, DatePicker, Button, Table, Drawer, notification, Tooltip, Modal } from 'antd'
import ReactHtmlParser from 'react-html-parser'
import gql from 'graphql-tag'
import client from 'apollo/config'
import { calculateAge } from 'utilities'
import RangeDatePicker from 'components/DatePicker/DatePicker'
import './style.scss'
import '../table.scss'
import LoadingComponent from 'components/LoadingComponent'

import CKEditor from 'react-ckeditor-component'

import {
  GET_GOAL_NEW_DETAILS,
  STUDENT_QUERY,
  GET_COMMENT,
  CREATE_COMMENT,
  IEP_REPORT_DOC,
} from './query'

const LOGO = gql`
  query {
    schoolDetail {
      logo
      schoolName
      address
    }
  }
`

const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '10px 10px',
  margin: 0,
  gap: '1rem',
  height: 'fit-content',
  overflow: 'hidden',
  justifyContent: 'space-between',
  backgroundColor: COLORS.palleteLight,
}

const graphHead = {
  textAlign: 'center',
  padding: 5,
  backgroundColor: COLORS.palleteLight,
  color: 'black',
  fontSize: 25,
  lineHeight: '22px',
}

const Index = ({ showDrawerFilter, selectedStudentId }) => {
  const [metLongGoals, setMetLongGoals] = useState([])
  const [longTableData, setLongTableData] = useState(null)
  const [shortTableData, setShortTableData] = useState(null)
  const [learnerDetails, setLearnerDetails] = useState(null)
  const [studLoading, setStudLoading] = useState(false)
  const [textBoxObj, setTextBoxObj] = useState({
    comment: '',
  })
  const [updateDesLoading, setUpdateDesLoading] = useState(false)

  const [pdfLoading, setPdfLoading] = useState(false)

  const [Logo, setLogo] = useState()
  const [start, setStart] = useState(
    moment()
      .startOf('M')
      .format('YYYY-MM-DD'),
  )
  const [end, setEnd] = useState(moment().format('YYYY-MM-DD'))

  const { data: cmtData, loading: cmtLOading, error: cmtError } = useQuery(GET_COMMENT, {
    variables: {
      student: localStorage.getItem('studentId'),
      start,
      end,
    },
    fetchPolicy: 'network-only',
  })

  const [createComment, { data: createCmtData }] = useMutation(CREATE_COMMENT)

  useEffect(() => {
    setTextBoxObj({
      comment: '',
    })
    if (cmtData && cmtData.getIepReportComment.length !== 0) {
      console.log('cmtData', cmtData)
      setTextBoxObj({
        comment: cmtData.getIepReportComment[0].comment,
      })
    }
  }, [cmtData, selectedStudentId, start, end])

  useEffect(() => {
    if (selectedStudentId) {
      fetchStudentDetails(selectedStudentId)
    }
  }, [selectedStudentId])

  const fetchStudentDetails = studentId => {
    // GET STUDENT DETAILS
    if (studentId) {
      setStudLoading(true)
      client
        .query({
          query: STUDENT_QUERY,
          variables: {
            studentId: localStorage.getItem('studentId'),
          },
        })
        .then(result => {
          setStudLoading(false)
          if (result && result.data) {
            setLearnerDetails(result.data.student)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const { data: longGoalsDetails, loading: longGoalsLoading, error: longGoalsError } = useQuery(
    GET_GOAL_NEW_DETAILS,
    {
      variables: {
        studentId: localStorage.getItem('studentId'),
      },
      fetchPolicy: 'network-only',
    },
  )
  const { data: DataLogo } = useQuery(LOGO, { fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (DataLogo) {
      setLogo(DataLogo.schoolDetail)
    }
  }, [DataLogo])

  const isDateInRange = selectedDate => {
    const selected = moment(selectedDate)
    const st = moment(start)
    const ed = moment(end)
    return selected.isBetween(st, ed) || selected.isSame(st) || selected.isSame(ed)
  }
  const isGoalInProgress = (goalStartDate, goalEndDate) => {
    const goalStart = moment(goalStartDate)
    const goalEnd = moment(goalEndDate)
    const selectedStart = moment(start)
    const selectedEnd = moment(end)

    return goalStart.isSameOrBefore(selectedEnd) && goalEnd.isSameOrAfter(selectedStart)
  }

  const matchName = goalName => {
    const regex = /^Default \((.*?)\)$/ // Regular expression to match "Default (...)"
    return goalName.match(regex) !== null
  }

  useEffect(() => {
    if (longGoalsDetails) {
      let temp = []
      const tempLong = []
      let tempShort = []

      longGoalsDetails.longTerm.edges.map(item => {
        const longGoalName =
          item.node.goalName === 'Default'
            ? `${item.node.goalName} (${item.node.program.name})`
            : item.node.goalName
        if (item.node.dateInitialted && isDateInRange(item.node.dateInitialted)) {
          tempLong.push({
            goal: longGoalName,
            key: Math.random(),
            goalId: item.node.id,
            dateInitiated: item.node.dateInitialted,
            dateEnd: item.node.dateEnd,
            status: item.node.goalStatus.status,
            description: item.node.description,
            // dateMastered: item.dateMastered,
            // masteryDays: item.masteryDays,
          })
        }
        if (
          item.node.goalStatus.id === 'R29hbFN0YXR1c1R5cGU6Mg==' &&
          isGoalInProgress(item.node.dateInitialted, item.node.dateEnd)
        ) {
          tempLong.push({
            goal: longGoalName,
            key: Math.random(),
            goalId: item.node.id,
            dateInitiated: item.node.dateInitialted,
            dateEnd: item.node.dateEnd,
            description: item.node.description,
            status: 'In Progress',
          })
        }
        if (item.dateMastered !== undefined && isDateInRange(item.dateMastered)) {
          tempLong.push({
            goal: longGoalName,
            key: Math.random(),
            goalId: item.node.id,
            dateInitiated: item.node.dateInitialted,
            dateEnd: item.node.dateEnd,
            status: 'Mastered',
            description: item.node.description,
          })
        }

        item.node.shorttermgoalSet?.edges.map(short => {
          const shortGoalName =
            short.node.goalName === 'Default'
              ? `${item.node.goalName} (${item.node.program.name})`
              : short.node.goalName
          const targetLength = short.node.targetAllocateSet.edges.length
          if (short.node.dateInitialted && isDateInRange(short.node.dateInitialted)) {
            tempShort.push({
              key: Math.random(),
              shortGoal: short.node.goalName,
              shortGoalId: short.node.id,
              status: 'Created',
              longGoal: longGoalName,
              longGoalId: item.node.id,
              dateInitiated: short.node.dateInitialted,
              dateEnd: short.node.dateEnd,
              dateMastered: short.node.masterDate,
              description: short.node.description,
            })
          }
          if (
            short.node.goalStatus.id === 'R29hbFN0YXR1c1R5cGU6Mg==' &&
            isGoalInProgress(short.node.dateInitialted, short.node.dateEnd)
          ) {
            tempShort.push({
              key: Math.random(),
              shortGoal: short.node.goalName,
              shortGoalId: short.node.id,
              status: 'In Progress',
              longGoal: longGoalName,
              longGoalId: item.node.id,
              dateInitiated: short.node.dateInitialted,
              dateEnd: short.node.dateEnd,
              dateMastered: short.node.masterDate,
              description: short.node.description,
            })
          }
          if (
            short.node.goalStatus.id === 'R29hbFN0YXR1c1R5cGU6NA==' &&
            short.node.masterDate &&
            isDateInRange(short.node.masterDate)
          ) {
            tempShort.push({
              key: Math.random(),
              shortGoal: short.node.goalName,
              shortGoalId: short.node.id,
              status: 'Mastered',
              longGoal: longGoalName,
              longGoalId: item.node.id,
              dateInitiated: short.node.dateInitialted,
              dateEnd: short.node.dateEnd,
              dateMastered: short.node.masterDate,
              description: short.node.description,
            })
          }

          short.node.targetAllocateSet.edges.forEach(tt => {
            const target = tt.node

            if (
              tt.node.targetAllcatedDetails.dateBaseline &&
              isDateInRange(moment(tt.node.targetAllcatedDetails.dateBaseline).format('YYYY-MM-DD'))
            ) {
              temp.push({
                status: 'Created',
                dateMastered: target.masterDate,
                dateInitiated: target.targetAllcatedDetails?.dateBaseline,
                targetName: target.targetAllcatedDetails.targetName,
                shortGoal: shortGoalName,
                longGoal: longGoalName,
                longGoalId: item.node.id,
                shortGoalId: short.node.id,
                targetId: target.targetAllcatedDetails.id,
                // targetInstr: '',
                targetInstr: target.targetInstr,
                isActive: false,
                key: Math.random(),
                mastery: target.mastery,
                targetAllcatedDetails: target.targetAllcatedDetails,
              })
            }
            if (
              tt.node.targetStatus.id === 'U3RhdHVzVHlwZToz' &&
              moment(tt.node.targetAllcatedDetails?.dateBaseline).isSameOrBefore(
                moment(end).endOf('day'),
              )
            ) {
              temp.push({
                status: 'In Progress',
                dateMastered: target.masterDate,
                dateInitiated: target.targetAllcatedDetails?.dateBaseline,
                targetName: target.targetAllcatedDetails.targetName,
                shortGoal: shortGoalName,
                longGoal: longGoalName,
                longGoalId: item.node.id,
                shortGoalId: short.node.id,
                targetId: target.targetAllcatedDetails.id,
                // targetInstr: '',
                targetInstr: target.targetInstr,
                isActive: false,
                key: Math.random(),
                mastery: target.mastery,
                targetAllcatedDetails: target.targetAllcatedDetails,
              })
            }
            if (
              tt.node.targetStatus.id === 'U3RhdHVzVHlwZTo0' &&
              isDateInRange(tt.node.masterDate)
            ) {
              temp.push({
                status: 'Mastered',
                dateMastered: target.masterDate,
                dateInitiated: target.targetAllcatedDetails?.dateBaseline,
                targetName: target.targetAllcatedDetails.targetName,
                shortGoal: shortGoalName,
                longGoal: longGoalName,
                longGoalId: item.node.id,
                shortGoalId: short.node.id,
                targetId: target.targetAllcatedDetails.id,
                // targetInstr: '',
                targetInstr: target.targetInstr,
                isActive: false,
                key: Math.random(),
                mastery: target.mastery,
                targetAllcatedDetails: target.targetAllcatedDetails,
              })
            }
          })
        })
      })
      // console.log(temp)

      const mergedShortGoalArray = Object.values(
        tempShort.reduce((result, item) => {
          if (!result[item.shortGoalId]) {
            result[item.shortGoalId] = { ...item }
          } else {
            result[item.shortGoalId].status += `, ${item.status}`
          }
          return result
        }, {}),
      )

      const groupedData = groupObj.group(mergedShortGoalArray, 'longGoal')
      const keys = Object.keys(groupedData)
      tempShort = []
      keys.map(key => {
        const tt = groupedData[key]
        let count = 0
        tt.forEach((t, index) => {
          count += 1
          index === 0
            ? tempShort.push({ ...t, count: tt.length })
            : tempShort.push({ ...t, longGoal: null })
        })
      })
      const mergedTargetArray = Object.values(
        temp.reduce((result, item) => {
          if (!result[item.targetId]) {
            result[item.targetId] = { ...item }
          } else {
            result[item.targetId].status += `, ${item.status}`
          }
          return result
        }, {}),
      )
      const groupedData1 = groupObj.group(mergedTargetArray, 'shortGoal')
      const keys1 = Object.keys(groupedData1)
      temp = []
      keys1.map(key => {
        const tt = groupedData1[key]
        let count = 0
        tt.forEach((t, index) => {
          count += 1
          index === 0
            ? temp.push({ ...t, count: tt.length })
            : temp.push({ ...t, count: 0, shortGoal: null })
        })
      })

      setMetLongGoals(temp)
      // setShortTableData(tempShort)
      const mergedLongGoalArray = Object.values(
        tempLong.reduce((result, item) => {
          if (!result[item.goalId]) {
            result[item.goalId] = { ...item }
          } else if (result[item.goalId].status === item.status) {
            result[item.goalId].status = item.status
          } else {
            result[item.goalId].status += `, ${item.status}`
          }
          return result
        }, {}),
      )
      // setLongTableData(mergedLongGoalArray)

      // Step 1: Identify short-term goals meeting the criteria
      const shortGoalsWithDefault = temp.filter(target => {
        return (
          target.shortGoal &&
          matchName(target.shortGoal) &&
          temp.filter(t => t.shortGoalId === target.shortGoalId).length > 1
        )
      })

      // Step 2: Create a new short-term goals array
      const short = shortGoalsWithDefault.map(item => item.shortGoalId)
      const otherShort = tempShort.filter(item => item.shortGoal !== 'Default')

      const filteredShortGoals = tempShort.filter(
        item => item.shortGoal === 'Default' && short.includes(item.shortGoalId),
      )

      // Step 3: Create a new long-term goals array

      const long = filteredShortGoals.map(item => item.longGoalId)
      const otherLong = mergedLongGoalArray.filter(item => !matchName(item.goal))

      const filteredLongGoals = mergedLongGoalArray.filter(
        item => matchName(item.goal) && long.includes(item.goalId),
      )

      setShortTableData(filteredShortGoals.concat(otherShort))

      setLongTableData(filteredLongGoals.concat(otherLong))
    }
  }, [longGoalsDetails, start, end])

  const DateChangeDropDown = date => {
    console.log('dropDown', date)
    setStart(moment(date[0]).format('YYYY-MM-DD'))
    setEnd(moment(date[1]).format('YYYY-MM-DD'))
  }

  const columns = [
    {
      title: 'Short Term Goal',
      dataIndex: 'shortGoal',
      render: (text, row) => {
        if (row.count) {
          return {
            children: text,
            props: {
              rowSpan: row.count,
            },
          }
        } else {
          return {
            children: text,
            props: {
              rowSpan: 0,
            },
          }
        }
      },
    },
    {
      title: 'Target Name',
      dataIndex: 'targetName',
    },
    {
      title: 'Target Status',
      dataIndex: 'status',
    },
    {
      title: 'Date Initiated',
      dataIndex: 'dateInitiated',
      render: (text, record) => moment(record.dateInitiated).format('YYYY-MM-DD'),
    },
    {
      title: 'Date Mastered',
      dataIndex: 'dateMastered',
    },
  ]

  const longCol = [
    {
      title: 'Goal Name',
      dataIndex: 'goal',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Date Initiated',
      dataIndex: 'dateInitiated',
    },
    {
      title: 'Date End',
      dataIndex: 'dateEnd',
    },
  ]

  const shortCol = [
    {
      title: 'Long Term Goal',
      dataIndex: 'longGoal',
      render: (text, row) => {
        if (row.count) {
          return {
            children: text,
            props: {
              rowSpan: row.count,
            },
          }
        } else {
          return {
            children: text,
            props: {
              rowSpan: 0,
            },
          }
        }
      },
    },
    {
      title: 'Short Term Goal',
      dataIndex: 'shortGoal',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Date Initiated',
      dataIndex: 'dateInitiated',
    },
    {
      title: 'Date End',
      dataIndex: 'dateEnd',
    },
  ]

  const generatePDFDoc = () => {
    let variables = {
      studentId: selectedStudentId,
      start,
      end,
      textBoxObj: textBoxObj.comment,
      longGoal: JSON.stringify(longTableData),
      shortGoal: JSON.stringify(shortTableData),
      targets: JSON.stringify(metLongGoals),
    }

    if (selectedStudentId && start && end) {
      setPdfLoading(true)
      client
        .mutate({
          mutation: IEP_REPORT_DOC,
          variables: {
            ...variables,
          },
        })
        .then(res => {
          setPdfLoading(false)
          window.location.href = `${res.data.generateIepReportDocx.report}`
        })
        .catch(err => {
          console.log('Report err', err)
          setPdfLoading(false)
        })
    }
  }

  const onEditorIEP = evt => {
    let tempTextBox = textBoxObj
    tempTextBox['comment'] = evt.editor.getData()
    setTextBoxObj(tempTextBox)
  }
  const updateIEPComment = () => {
    // GET Month Descriptions
    if (selectedStudentId && start && end) {
      setUpdateDesLoading(true)
      createComment({
        variables: {
          student: selectedStudentId,
          start,
          end,
          comment: textBoxObj.comment,
        },
        fetchPolicy: 'no-cache',
      })
        .then(result => {
          if (result) {
            notification.success({
              message: 'Descriptions Updated Successfully!',
            })
            setUpdateDesLoading(false)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const getStimulus = target => {
    const temp = []
    const { mastery, targetAllcatedDetails } = target

    mastery.edges.length && (
      <>
        {mastery.edges.forEach(({ node }) => {
          let obj = {
            type: node.sd ? 'sd' : 'step',
            name: node.sd ? node.sd.sd : node.step.step,
            status: node.status ? node?.status?.statusName : '',
            createdAt: node.createdAt,
            masterDate: targetAllcatedDetails.dateBaseline,
          }
          if (node.targetsdstepstatuschangeSet.edges.length) {
            const len = node.targetsdstepstatuschangeSet.edges.length
            const { node: item } = node.targetsdstepstatuschangeSet.edges[0]
            obj = {
              ...obj,
              // createdAt: item.updatedAt,
              masterDate:
                node.targetsdstepstatuschangeSet.edges[len - 1].node.toStatus &&
                node.targetsdstepstatuschangeSet.edges[
                  len - 1
                ].node.toStatus.statusName.toLowerCase() === 'mastered'
                  ? node.targetsdstepstatuschangeSet.edges[len - 1].node.createdAt
                  : null,
              status: node.targetsdstepstatuschangeSet.edges[len - 1].node.toStatus
                ? node.targetsdstepstatuschangeSet.edges[len - 1].node.toStatus.statusName
                : '',
            }
          }
          temp.push(obj)
        })}
      </>
    )
    const stColumn = [
      {
        title: 'Step/Stimulus',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Initiated Date',
        key: 'initiated',
        render: (text, row) => <span>{moment(row.createdAt).format('YYYY-MM-DD')}</span>,
      },
      {
        title: 'Mastered Date',
        key: 'mastered',
        render: (text, row) => (
          <span>
            {row.status && row.status.toLowerCase() === 'mastered' && row.masterDate
              ? moment(row.masterDate).format('YYYY-MM-DD')
              : null}
          </span>
        ),
      },
    ]

    return temp.length ? (
      <Table columns={stColumn} dataSource={temp} pagination={false} style={{ marginBottom: 10 }} />
    ) : null

    // return temp.map(item => (
    //   <div
    //     style={{
    //       color: 'black',
    //       border: '2px solid',
    //       borderRadius: '5px',
    //       margin: '5px',
    //       padding: '3px',
    //       marginInline: 0,
    //       borderColor: item.type === 'sd' ? '#f1b9d5' : '#f2d1b0',
    //       backgroundColor: item.type === 'sd' ? '#f1b9d5' : '#f2d1b0',
    //       width: '100%',
    //     }}
    //     key={Math.random()}
    //     className="LongTermGoal"
    //   >
    //     <p
    //       style={{
    //         margin: 0,
    //         fontSize: 14,
    //       }}
    //     >
    //       {item.type === 'sd' ? <b>Stimulus :</b> : <b>Step :</b>}
    //       {'  '}
    //       {item.name}
    //     </p>
    //     <div
    //       style={{
    //         margin: 0,
    //         display: 'flex',
    //         justifyContent: 'space-between',
    //       }}
    //     >
    //       <div className="details">
    //         <b>Status:{'  '}</b> {item.status}
    //       </div>
    //       <div className="details">
    //         <b>Initiated Date:{'  '}</b>

    //         {moment(item.createdAt).format('YYYY-MM-DD')}
    //       </div>
    //       {/* {item.status && item.status.toLowerCase() === 'mastered' && item.masterDate && (
    //         <div className="details">
    //           <b>Master Date:{'  '}</b>

    //           {moment(item.masterDate).format(dateFormat)}
    //         </div>
    //       )} */}
    //     </div>
    //   </div>
    // ))
  }
  return (
    <div>
      <Row>
        <Col sm={24}>
          <div style={filterCardStyle}>
            <div style={{ display: 'flex' }}>
              <span>
                <Tooltip trigger={['hover']} title="Select Date range">
                  <RangeDatePicker
                    date={[start, end]}
                    setDate={DateChangeDropDown}
                    from="GOALS_REPORT"
                  />
                </Tooltip>
              </span>
            </div>
            <div>
              <span
                style={{
                  textAlign: 'right',
                  width: '100%',
                }}
              >
                <Button
                  type="link"
                  size="large"
                  style={{
                    padding: '0 8px',
                    marginRight: '5px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  onClick={() => {
                    generatePDFDoc()
                  }}
                  loading={pdfLoading}
                  disabled={studLoading || longGoalsLoading}
                >
                  <FaDownload fontSize="22px" />
                </Button>
              </span>
            </div>
          </div>
        </Col>
        <Col sm={24}>
          <div style={{ width: '800px', margin: '0px auto' }}>
            <p style={graphHead}>Goal Report</p>

            {studLoading ? (
              <LoadingComponent />
            ) : (
              learnerDetails && (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div style={{ fontSize: 22, lineHeight: '24px' }}>
                    <img src={Logo?.logo} width="100px" height="100px" alt="Logo" />
                  </div>
                  <div style={{ marginLeft: '5%' }} className="Heading">
                    <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                      <strong>Name: </strong>{' '}
                      <span>
                        {learnerDetails?.firstname} {learnerDetails?.lastname}
                      </span>
                    </p>
                    <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                      <strong>DOB: </strong>
                      <span>{learnerDetails?.dob}</span>
                    </p>
                    <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                      <strong>Age: </strong>
                      <span>{learnerDetails?.dob && calculateAge(learnerDetails?.dob)}</span>
                    </p>
                    <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                      <strong> Location: </strong>
                      <span>
                        {learnerDetails?.currentAddress &&
                          learnerDetails?.currentAddress.charAt(0).toUpperCase() +
                            learnerDetails?.currentAddress.slice(1)}
                      </span>
                    </p>

                    <p style={{ fontSize: 15, lineHeight: '12px', color: 'black' }}>
                      <strong>Month: </strong>
                      <span>
                        {monthNames[moment(start).format('MM') - 1]} {moment(start).format('YYYY')}
                      </span>
                    </p>
                  </div>
                </div>
              )
            )}
            <div style={{ padding: 10 }}>
              <h2>Comment:</h2>
              <div
                style={{
                  width: 900,
                  padding: 2,
                  marginLeft: 5,
                }}
              >
                <CKEditor
                  name="IEPComment"
                  activeClass="p10"
                  content={textBoxObj['comment']}
                  events={{
                    change: onEditorIEP,
                  }}
                />
                <div style={{ float: 'left', marginTop: 5 }}>
                  <Button
                    type="primary"
                    loading={updateDesLoading}
                    onClick={() => updateIEPComment()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <br />
            <br />

            <div style={{ margin: '20px 0 0 10px' }}>
              <h2>Long Term Goals</h2>
              <Table
                bordered
                columns={longCol}
                className="allReportTable"
                loading={longGoalsLoading}
                dataSource={longTableData}
                expandedRowRender={row => {
                  return (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <div>
                          <b>Description: </b>
                          {ReactHtmlParser(row.description)}
                        </div>
                      </div>
                    </>
                  )
                }}
                pagination={{
                  defaultPageSize: 20,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '30', '50', '100'],
                }}
              />
            </div>

            <div style={{ margin: '20px 0 0 10px' }}>
              <h2>Short Term Goals</h2>
              <Table
                className="allReportTable"
                bordered
                columns={shortCol}
                loading={longGoalsLoading}
                dataSource={shortTableData}
                expandedRowRender={row => {
                  return (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <div>
                          <b>Description: </b>
                          {ReactHtmlParser(row.description)}
                        </div>
                      </div>
                    </>
                  )
                }}
                pagination={{
                  defaultPageSize: 20,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '30', '50', '100'],
                  // position: 'top',
                }}
              />
            </div>

            <div style={{ margin: '10px 0 0 10px' }}>
              <h2>Targets</h2>
              <Table
                bordered
                columns={columns}
                loading={longGoalsLoading}
                dataSource={metLongGoals}
                pagination={{
                  defaultPageSize: 20,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '30', '50', '100'],
                }}
                className="targetTable allReportTable"
                expandedRowRender={row => {
                  return (
                    <>
                      {getStimulus(row)}
                      <div
                        style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: 10 }}
                      >
                        <div>
                          <b>Target Instruction: </b>
                          {ReactHtmlParser(row.targetInstr)}
                        </div>
                      </div>
                    </>
                  )
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Index
