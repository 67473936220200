/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable no-lonely-if */
/* eslint-disable one-var */
/* eslint-disable yoda */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-loop-func */
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
/* eslint-disable no-return-assign */
// import {

// } from 'services/forms'
import actions from './actions'


export function* GET_DATA({ payload }) {
  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      loading: true,
    },
  })


  yield put({
    type: 'sessionrecording/SET_STATE',
    payload: {
      loading: false,
    },
  })
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_DATA, GET_DATA),
  ])
}
