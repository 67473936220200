/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react'
import { Switch, Icon, notification, Button, Form, Input, Row, Col } from 'antd'
import { useMutation, useLazyQuery } from 'react-apollo'
import { useSelector } from 'react-redux'
import WrapperDiv from 'components/WrapperCard'
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import LoadingComponent from 'components/LoadingComponent'
import {
  GET_LEARNER_SESSIONS,
  UPDATE_USER_SESSION_SETTING,
  LEARNER_SESSION_SHOW_HIDE,
} from './query'

const SessionSettingTab = ({ form }) => {
  const [sessionList, setSessionList] = useState([])
  const student = useSelector(state => state.student)
  const [hideSessions, setHideSessions] = useState(false)

  const [
    fetchLearnerSession,
    { data: sessData, loading: sessDataLoading, error: sessDataError, refetch: refetchSession },
  ] = useLazyQuery(GET_LEARNER_SESSIONS)

  const [updateSessionSetting, { loading: updateSessionSettingLoading }] = useMutation(
    UPDATE_USER_SESSION_SETTING,
  )
  const [updateSessionShowHide, { loading: hideSessionSettingLoading }] = useMutation(
    LEARNER_SESSION_SHOW_HIDE,
  )
  const renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] },
      }),
      {},
    )
  useEffect(() => {
    const obj = sessData?.learnerSessionSettings?.sessions?.edges
    const finalObj = []
    if (sessionList) {
      sessionList.map(item => {
        const obje = { master: item.node.masterDays, Maintainance: item.node.inMainteinanceDays }
        const finalObject = renameKeys(
          {
            master: `${item.node.sessionName.name.toLowerCase()}MasteryDays`,
            Maintainance: `${item.node.sessionName.name.toLowerCase()}MainteinanceDays`,
          },
          obje,
        )
        finalObj.push({ ...finalObject })
        return 0
      })
    }
    const allObject = Object.assign({}, ...finalObj)
    if (obj) {
      form.setFieldsValue(allObject)
    }
  }, [sessionList])

  useEffect(() => {
    if (sessData && sessData.learnerSessionSettings) {
      setHideSessions(!sessData.learnerSessionSettings.hideSessions)
      setSessionList(sessData.learnerSessionSettings.sessions?.edges)
    }
  }, [sessData, student?.StudentId])

  useEffect(() => {
    if (student && student.StudentId) {
      fetchLearnerSession({
        variables: {
          studentId: student.StudentId,
        },
      })
    }
  }, [student?.StudentId])

  const tdStyle = {
    border: '1px solid #dddddd',
    textAlign: 'center',
    justifyContent: 'center',
    padding: 2,
  }
  const tddStyle = { border: '1px solid #dddddd', textAlign: 'center' }

  const handleSubmit = e => {
    e.preventDefault()
    form
      .validateFields((err, values) => {
        console.log('Values', values.defaultMasteryDays)
      })
      .then(res => console.log('handle submit', res))
      .catch(err => console.log(err))
  }

  const handleSaveButton = node => {
    const type = node.sessionName.name
    const masterD = `${type.toLowerCase()}MasteryDays`
    const mainD = `${type.toLowerCase()}MainteinanceDays`
    if (student?.StudentId && node.sessionName?.id) {
      updateSessionSetting({
        variables: {
          studentId: student?.StudentId,
          sessions: [
            {
              sessionNameId: node.sessionName.id,
              isActive: node.isActive,
              inMainteinanceDays: form.getFieldValue(`${mainD}`),
              masterDays: form.getFieldValue(`${masterD}`),
            },
          ],
        },
      })
        .then(res => {
          console.log(res, ' res ')
          notification.success({
            message: 'Session setting updated successfully',
          })
          // refetchSession()
        })
        .catch(er => console.error(er))
    }
  }

  const saveHideSession = checked => {
    setHideSessions(checked)
    updateSessionShowHide({
      variables: {
        studentId: student?.StudentId,
        hideSessions: !checked,
      },
    })
      .then(res => {
        notification.success({
          message: 'Session setting updated successfully',
        })
        // refetchSession()
      })
      .catch(er => console.error(er))
  }

  return (
    <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Col sm={24} md={24} lg={24}>
        <WrapperDiv>
          {sessDataLoading ? (
            <LoadingComponent />
          ) : (
            <>
              <table
                style={{
                  paddingTop: '2em',
                  marginBottom: '2em',
                  backgroundColor: 'white',
                  width: '100%',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ ...tdStyle }}>
                      <p
                        style={{
                          fontSize: 15,
                          display: 'block',
                          marginTop: '5px',
                          marginBottom: '5px',
                          textAlign: 'left',
                          padding: 8,
                        }}
                      >
                        Learner can perform sessions?
                      </p>
                    </td>
                    <td style={{ ...tdStyle, width: 200 }}>
                      <Switch
                        checkedChildren={<Icon type="check" />}
                        checked={hideSessions}
                        onChange={saveHideSession}
                        unCheckedChildren={<Icon type="close" />}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Form onSubmit={handleSubmit}>
                <table style={{ paddingTop: '2em', backgroundColor: 'white', width: '100%' }}>
                  <thead>
                    <th style={{ ...tdStyle, width: '20%' }}>Session</th>
                    <th style={{ ...tddStyle, width: '40%' }}>
                      <p style={{ borderBottom: '1px solid #DDD', padding: 8 }}>
                        Targets to appear with below status
                      </p>{' '}
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <p>Mastery Days</p>
                        <p>Maintainance Days</p>
                      </div>
                    </th>
                    <th style={{ ...tdStyle, width: '20%' }}>Active/InActive</th>
                    <th style={{ ...tdStyle, width: '20%' }}>Action</th>
                  </thead>
                  <tbody>
                    {sessionList?.map(({ node }) => {
                      return (
                        <tr key={node.id}>
                          <td style={{ ...tdStyle, width: '20%' }}>
                            {node.sessionName.name} Session
                          </td>
                          <td style={{ ...tdStyle, width: '40%' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                textAlign: 'center',
                              }}
                            >
                              <Form.Item name={`${node.sessionName.name.toLowerCase()}MasteryDays`}>
                                {form.getFieldDecorator(
                                  `${node.sessionName.name.toLowerCase()}MasteryDays`,
                                  {
                                    rules: [{ required: true, message: 'This field is required' }],
                                  },
                                )(<Input type="number" min={0} max={100} />)}{' '}
                              </Form.Item>
                              <Form.Item
                                name={`${node.sessionName.name.toLowerCase()}MainteinanceDays`}
                              >
                                {form.getFieldDecorator(
                                  `${node.sessionName.name.toLowerCase()}MainteinanceDays`,
                                  {
                                    rules: [{ required: true, message: 'This field is required' }],
                                  },
                                )(<Input type="number" min={0} max={100} />)}{' '}
                              </Form.Item>
                            </div>
                          </td>
                          <td style={{ ...tdStyle, width: '20%' }}>
                            <Switch
                              checkedChildren={<Icon type="check" />}
                              checked={node.isActive}
                              unCheckedChildren={<Icon type="close" />}
                              onChange={e => {
                                console.log('response', e)
                                if (student?.StudentId && node.sessionName?.id) {
                                  updateSessionSetting({
                                    variables: {
                                      studentId: student?.StudentId,
                                      sessions: [
                                        { sessionNameId: node.sessionName.id, isActive: e },
                                      ],
                                    },
                                  })
                                    .then(res => {
                                      console.log(res, ' res ')
                                      notification.success({
                                        message: 'Session setting updated successfully',
                                      })
                                      // refetchSession()
                                    })
                                    .catch(er => console.error(er))
                                }
                              }}
                            />
                          </td>
                          <td style={{ ...tdStyle, width: '20%', justifyContent: 'center' }}>
                            <Button
                              style={{ backgroundColor: '#0B35B3' }}
                              type="primary"
                              htmlType="submit"
                              onClick={() => handleSaveButton(node)}
                            >
                              <SaveOutlined />
                              Save
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Form>
            </>
          )}
        </WrapperDiv>
      </Col>
    </Row>
  )
}

export default Form.create()(SessionSettingTab)
