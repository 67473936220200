import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

// targetStatus:"U3RhdHVzVHlwZToz"
// 0: {id: "U3RhdHVzVHlwZToxMA==", statusName: "Generalization", __typename: "StatusType"}
// 1: {id: "U3RhdHVzVHlwZTo3", statusName: "Deleted", __typename: "StatusType"}
// 2: {id: "U3RhdHVzVHlwZTo2", statusName: "On Hold", __typename: "StatusType"}
// 3: {id: "U3RhdHVzVHlwZTo1", statusName: "In-Maintenance", __typename: "StatusType"}
// 4: {id: "U3RhdHVzVHlwZTo0", statusName: "Mastered", __typename: "StatusType"}
// 5: {id: "U3RhdHVzVHlwZToz", statusName: "Intervention", __typename: "StatusType"}
// 6: {id: "U3RhdHVzVHlwZTox", statusName: "Baseline", __typename: "StatusType"}

export async function getAllocatedTargets(payload) {
  return apolloClient
    .query({
      query: gql`
        query($studentId: ID!, $st: [ID]) {
          targetAllocates(studentId: $studentId, targetStatus_In: $st, getAll: true) {
            edges {
              node {
                id
                targetStatus {
                  id
                  statusName
                }
                targetAllcatedDetails {
                  id
                  targetName
                  targetType {
                    id
                    typeTar
                  }
                }
                targetId {
                  id
                  domain {
                    id
                    domain
                  }
                }
                manualAllocateDomain {
                  id
                  domain
                }
                sessionsAllocated {
                  id
                  name
                  sessionName {
                    id
                    name
                  }
                }
                tag {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                date
              }
            }
          }
        }
      `,
      variables: {
        studentId: payload.studentId,
        st: payload.statusList,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function getTargetStatus() {
  return apolloClient
    .query({
      query: gql`
        query {
          targetStatus {
            id
            statusName
          }
          types {
            id
            typeTar
          }
        }
      `,
    })
    .then(res => res)
    .catch(err => console.error(err))
}

export async function getSessionNames(payload) {
  return apolloClient
    .query({
      query: gql`
        query($studentId: ID!, $date: Date) {
          GetStudentSession(studentId: $studentId, date: $date) {
            edges {
              node {
                id
                name
                isCustom
                isActive
                duration
                sessionName {
                  id
                  name
                }
                preferredItems(isActive: true) {
                  edges {
                    node {
                      id
                      item
                      isActive
                    }
                  }
                }
                itemRequired
                sessionHost {
                  edges {
                    node {
                      id
                      memberName
                      timeSpent {
                        edges {
                          node {
                            id
                            sessionName {
                              id
                              name
                            }
                            duration
                          }
                        }
                      }
                      relationship {
                        id
                        name
                      }
                    }
                  }
                }
                childsessionSet {
                  edges {
                    node {
                      id
                      status
                      sessionNo
                    }
                  }
                }
                therapistHost(first: 1) {
                  edges {
                    node {
                      id
                      name
                      surname
                    }
                  }
                }
                parentView
                instruction {
                  edges {
                    node {
                      id
                      instruction
                    }
                  }
                }
                targets(excludeWithStatus: ["U3RhdHVzVHlwZTo3", "U3RhdHVzVHlwZTo2"]) {
                  edgeCount
                }
              }
            }
          }
        }
      `,
      variables: {
        studentId: payload.studentId,
        date: moment().format('YYYY-MM-DD'),
      },
      fetchPolicy: 'network-only',
    })
    .then(res => res)
    .catch(err => console.error(err))
}

export async function getSessionChildSessions(payload) {
  return apolloClient
    .query({
      query: gql`
        query($studentId: ID!, $date: Date!, $sessionNameId: ID) {
          learnerChildSessions(
            sessionDate: $date
            sessionNameId: $sessionNameId
            learnerId: $studentId
          ) {
            id
            sessionDate
            status
            sessionNo
            sessions {
              id
              createdAt
              sessionName {
                id
                name
              }
              preferredItems(isActive: true) {
                edges {
                  node {
                    id
                    item
                    isActive
                  }
                }
              }
              student {
                id
                firstname
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: {
        studentId: payload.studentId,
        date: payload.date,
        sessionNameId: payload.sessionNameId,
      },
    })
    .then(res => res)
    .catch(err => console.error(err))
}

export async function getSessionTargets(payload) {
  return apolloClient
    .query({
      query: gql`
        query($studentId: ID!, $sessionName: ID) {
          GetStudentSession(studentId: $studentId, sessionName: $sessionName) {
            edges {
              node {
                id
                name
                sessionName {
                  id
                  name
                }
                preferredItems(isActive: true) {
                  edges {
                    node {
                      id
                      item
                      isActive
                    }
                  }
                }
                targets(excludeWithStatus: ["U3RhdHVzVHlwZTo3", "U3RhdHVzVHlwZTo2"]) {
                  edgeCount
                  edges {
                    node {
                      id
                      time
                      date
                      targetStatus {
                        id
                        statusName
                      }
                      targetAllcatedDetails {
                        id
                        targetName
                        targetType {
                          id
                          typeTar
                        }
                      }
                      targetId {
                        id
                        domain {
                          id
                          domain
                        }
                      }
                      manualAllocateDomain {
                        id
                        domain
                      }
                      tag {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      date
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        studentId: payload.studentId,
        sessionName: payload.sessionId,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function getAuthFamily(payload) {
  return apolloClient
    .query({
      query: gql`
        query($studentId: ID!) {
          student(id: $studentId) {
            id
            authStaff {
              edges {
                node {
                  id
                  name
                }
              }
            }
            family {
              id
              members {
                edges {
                  node {
                    id
                    memberName
                    timeSpent {
                      edges {
                        node {
                          id
                          sessionName {
                            id
                            name
                          }
                          duration
                        }
                      }
                    }
                    relationship {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        studentId: payload.studentId,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong',
          description: item.message,
        })
      })
    })
}

export async function filterAllocatedTargets(payload) {
  return apolloClient
    .query({
      query: gql`
        query($studentId: ID, $targetStatus: ID) {
          targetAllocates(studentId: $studentId, targetStatus: $targetStatus) {
            edges {
              node {
                id
                targetStatus {
                  id
                  statusName
                }
                targetAllcatedDetails {
                  id
                  targetName
                  targetType {
                    id
                    typeTar
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        studentId: payload.studentId,
        targetStatus: payload.statusId,
      },
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function updateSessionTargets(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($sessionId: ID!, $targetsList: [ID], $sessionDate: Date!) {
          updateSessionTargets(
            input: { sessionId: $sessionId, targetsList: $targetsList, sessionDate: $sessionDate }
          ) {
            status
            session {
              id
              name
              duration
              sessionName {
                id
                name
              }
              preferredItems(isActive: true) {
                edges {
                  node {
                    id
                    item
                    isActive
                  }
                }
              }
              targets(excludeWithStatus: ["U3RhdHVzVHlwZTo3", "U3RhdHVzVHlwZTo2"]) {
                edgeCount
                edges {
                  node {
                    id
                    time
                    date
                    targetStatus {
                      id
                      statusName
                    }
                    targetAllcatedDetails {
                      id
                      targetName
                      targetType {
                        id
                        typeTar
                      }
                    }
                    targetId {
                      id
                      domain {
                        id
                        domain
                      }
                    }
                    manualAllocateDomain {
                      id
                      domain
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        targetsList: payload.targetList,
        sessionId: payload.id,
        sessionDate: moment().format('YYYY-MM-DD'),
      },
      fetchPolicy: 'no-cache',
    })
    .then(result => result)
    .catch(error => console.error(error))
}

export async function updateSessionDetails(objects) {
  const { payload, sessionObject } = objects

  return apolloClient
    .mutate({
      mutation: gql`
        mutation UpdateSessioDetails(
          $id: ID!
          $name: String
          $duration: Int!
          $items: String!
          $instruction: [String]
          $sessionHost: [ID]
          $therapistHost: [ID]
          $parentView: Boolean
        ) {
          updateMasterSession(
            input: {
              pk: $id
              name: $name
              duration: $duration
              itemRequired: $items
              instruction: $instruction
              sessionHost: $sessionHost
              therapistHost: $therapistHost
              parentView: $parentView
            }
          ) {
            details {
              id
              name
              itemRequired
              duration
              sessionName {
                id
                name
              }
              preferredItems(isActive: true) {
                edges {
                  node {
                    id
                    item
                    isActive
                  }
                }
              }
              parentView
              instruction {
                edges {
                  node {
                    id
                    instruction
                  }
                }
              }
              therapistHost {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              sessionHost {
                edges {
                  node {
                    id
                    memberName
                    timeSpent {
                      edges {
                        node {
                          id
                          sessionName {
                            id
                            name
                          }
                          duration
                        }
                      }
                    }
                    relationship {
                      id
                      name
                    }
                  }
                }
              }
              targets(excludeWithStatus: ["U3RhdHVzVHlwZTo3", "U3RhdHVzVHlwZTo2"]) {
                edges {
                  node {
                    id
                    time
                    date
                    targetStatus {
                      id
                      statusName
                    }
                    morning
                    afternoon
                    evening
                    default
                    targetAllcatedDetails {
                      id
                      targetName
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        id: sessionObject.id,
        name: payload.values.sessionName,
        duration: payload.values.duration,
        items: payload.values.items,
        instruction: payload.values.names,
        sessionHost: payload.values.hosts,
        therapistHost: payload.values.therapist,
        parentView: payload.values.isParentView,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        })
      })
    })
}
