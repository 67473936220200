/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'
import client from 'apollo/config'

export const CREATE_GOAL_COMMENT = gql`
  mutation($ltg: ID, $stg: ID, $target: ID, $type: String!, $comment: String!) {
    createGoalsComments(
      input: { ltg: $ltg, stg: $stg, target: $target, type: $type, comment: $comment }
    ) {
      comment {
        id
        comment
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`

export const UPDATE_GOAL_COMMENT = gql`
  mutation($pk: ID!, $comment: String!) {
    updateGoalsComment(input: { pk: $pk, comment: $comment }) {
      comment {
        id
        comment
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`

export const DELETE_GOAL_COMMENT = gql`
  mutation($pk: ID!) {
    deleteGoalsComment(input: { pk: $pk }) {
      status
      msg
    }
  }
`

export const GET_DOMAIN_BY_PROGRAM = gql`
  query($programArea: ID!) {
    programDetails(id: $programArea) {
      id
      name
      domain {
        edges {
          node {
            id
            domain
          }
        }
      }
    }
  }
`

export const GET_TARGETAREA_BY_DOMAIN = gql`
  query($domain: ID) {
    targetArea(domainId: $domain) {
      edges {
        node {
          id
          Area
        }
      }
    }
  }
`

export const SUGGEST_TARGETS = gql`
  query($domain: ID, $targetArea: ID, $student: ID) {
    target(domain: $domain, targetArea: $targetArea, student: $student) {
      edges {
        node {
          id
          allocatedTar
          sortValue
          domain {
            id
            domain
          }
          targetArea {
            id
            Area
          }
          targetInstr
          video
          targetMain {
            id
            targetName
          }
        }
      }
    }
  }
`

export const GET_EQU_CATEGORY = gql`
  query {
    peakEquDomains {
      id
      name
    }
  }
`

export const GET_TARGETS_BY_CATEGORY = gql`
  query($codeType: String) {
    getPeakEquCodes(codetype: $codeType) {
      edges {
        node {
          id
          code
          target {
            id
            maxSd
            domain {
              id
              domain
            }
            video
            targetInstr
            targetMain {
              id
              targetName
            }
          }
          classes {
            edges {
              node {
                id
                name
                stimuluses {
                  edges {
                    node {
                      id
                      option
                      stimulusName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_TARGETS_BY_CODE = gql`
  mutation($code: String) {
    suggestPeakEquivalanceTargets(input: { code: $code }) {
      targets {
        code {
          id
          code
          target {
            id
            status
            targetMain {
              id
              targetName
            }
            domain {
              id
              domain
            }
            targetArea {
              id
              Area
            }
            video
            targetInstr
          }
          classes {
            edges {
              node {
                id
                name
                stimuluses {
                  edges {
                    node {
                      id
                      option
                      stimulusName
                    }
                  }
                }
              }
            }
          }
        }
        target {
          id
          status
          targetMain {
            id
            targetName
          }
          targetArea {
            id
            Area
          }
          video
          targetInstr
        }
      }
    }
  }
`

export const SUGGEST_TARGETS_BY_NAME = gql`
  query($targetName: String, $student: ID) {
    target(targetMain_TargetName_Icontains: $targetName, student: $student, first: 20) {
      edges {
        node {
          id
          allocatedTar
          domain {
            id
            domain
          }
          targetArea {
            id
            Area
          }
          targetInstr
          video
          targetMain {
            id
            targetName
          }
        }
      }
    }
  }
`

export const CREATE_LONG_TERM = gql`
  mutation CreateLongTerm(
    $student: ID!
    $goalName: String!
    $description: String!
    $dateInitialted: Date!
    $dateEnd: Date!
    $responsibility: ID!
    $goalStatus: ID!
    $programArea: ID!
  ) {
    createLongTerm(
      input: {
        goalData: {
          student: $student
          goalName: $goalName
          description: $description
          dateInitialted: $dateInitialted
          dateEnd: $dateEnd
          responsibility: $responsibility
          goalStatus: $goalStatus
          programArea: $programArea
        }
      }
    ) {
      details {
        id
        goalName
        description
        dateInitialted
        dateEnd
        goalStatus {
          id
          status
        }
        responsibility {
          id
          name
        }
        comments {
          edges {
            node {
              id
              comment
            }
          }
        }
      }
    }
  }
`

export const CREATE_COPY_LONG_TERM = gql`
  mutation CreateLongTerm(
    $student: ID!
    $goalName: String!
    $description: String!
    $dateInitialted: Date!
    $dateEnd: Date!
    $responsibility: ID!
    $goalStatus: ID!
    $programArea: ID!
    $std1: ID!
    $std2: ID!
    $longGoal: ID
    $copyShortGoals: Boolean
    $copyTargets: Boolean
  ) {
    createLongTerm(
      input: {
        goalData: {
          student: $student
          goalName: $goalName
          description: $description
          dateInitialted: $dateInitialted
          dateEnd: $dateEnd
          responsibility: $responsibility
          goalStatus: $goalStatus
          programArea: $programArea
        }
        copyData: {
          copyGoal: true
          longGoal: $longGoal
          programArea: $programArea
          copyShortGoals: $copyShortGoals
          copyTargets: $copyTargets
          std2: $std2
          std1: $std1
        }
      }
    ) {
      details {
        id
        goalName
        description
        dateInitialted
        dateEnd
        goalStatus {
          id
          status
        }
        responsibility {
          id
          name
        }
        comments {
          edges {
            node {
              id
              comment
            }
          }
        }
      }
    }
  }
`

export const CREATE_SHORT_TERM = gql`
  mutation CreateShortTerm(
    $longTerm: ID!
    $goalName: String!
    $description: String!
    $dateInitialted: Date!
    $dateEnd: Date!
    $responsibility: ID!
    $goalStatus: ID!
  ) {
    createShortTerm(
      input: {
        goalData: {
          longTerm: $longTerm
          goalName: $goalName
          description: $description
          dateInitialted: $dateInitialted
          dateEnd: $dateEnd
          responsibility: $responsibility
          goalStatus: $goalStatus
        }
      }
    ) {
      details {
        id
        goalName
        dateInitialted
        description
        dateEnd
        longTerm {
          id
          goalName
        }
        goalStatus {
          id
          status
        }
        responsibility {
          id
          name
        }
        comments {
          edges {
            node {
              id
              comment
            }
          }
        }
      }
    }
  }
`

export const CREATE_COPY_SHORT_TERM = gql`
  mutation CreateShortTerm(
    $longTerm: ID!
    $goalName: String!
    $description: String!
    $dateInitialted: Date!
    $dateEnd: Date!
    $responsibility: ID!
    $goalStatus: ID!
    $shortGoal: ID
    $copyTargets: Boolean
    $std2: ID
  ) {
    createShortTerm(
      input: {
        goalData: {
          longTerm: $longTerm
          goalName: $goalName
          description: $description
          dateInitialted: $dateInitialted
          dateEnd: $dateEnd
          responsibility: $responsibility
          goalStatus: $goalStatus
        }
        copy: {
          copyGoal: true
          longGoal: $longTerm
          shortGoal: $shortGoal
          copyTargets: $copyTargets
          std2: $std2
        }
      }
    ) {
      details {
        id
        goalName
        dateInitialted
        description
        dateEnd
        longTerm {
          id
          goalName
        }
        goalStatus {
          id
          status
        }
        responsibility {
          id
          name
        }
        comments {
          edges {
            node {
              id
              comment
            }
          }
        }
      }
      copiedGoal {
        id
        goalName
        dateInitialted
        description
        dateEnd
        longTerm {
          id
          goalName
        }
        goalStatus {
          id
          status
        }

        responsibility {
          id
          name
        }
        comments {
          edges {
            node {
              id
              comment
            }
          }
        }
      }
    }
  }
`

export const UPDATE_LONG_GOAL = gql`
  mutation(
    $id: ID!
    $student: ID
    $goalName: String!
    $description: String!
    $dateInitialted: Date!
    $dateEnd: Date!
    $responsibility: ID!
    $goalStatus: ID!
    $programArea: ID!
  ) {
    updateLongTerm(
      input: {
        goalData: {
          id: $id
          student: $student
          goalName: $goalName
          description: $description
          dateInitialted: $dateInitialted
          dateEnd: $dateEnd
          responsibility: $responsibility
          goalStatus: $goalStatus
          programArea: $programArea
        }
      }
    ) {
      details {
        id
        goalName
        description
        dateInitialted
        dateEnd
        responsibility {
          id
          name
        }
        goalStatus {
          id
          status
        }
        comments {
          edges {
            node {
              id
              comment
            }
          }
        }
      }
    }
  }
`

export const UPDATE_SHORT_GOAL = gql`
  mutation UpdateShortTerm(
    $id: ID!
    $longTerm: ID!
    $goalName: String!
    $description: String!
    $dateInitialted: Date!
    $dateEnd: Date!
    $responsibility: ID!
    $goalStatus: ID!
  ) {
    updateShortTerm(
      input: {
        goalData: {
          id: $id
          longTerm: $longTerm
          goalName: $goalName
          description: $description
          dateInitialted: $dateInitialted
          dateEnd: $dateEnd
          responsibility: $responsibility
          goalStatus: $goalStatus
        }
      }
    ) {
      details {
        id
        goalName
        description
        dateInitialted
        dateEnd
        longTerm {
          id
          goalName
        }
        responsibility {
          id
          name
        }
        goalStatus {
          id
          status
        }
        comments {
          edges {
            node {
              id
              comment
            }
          }
        }
      }
    }
  }
`

export const SHORT_TERM_GOALS = gql`
  query($longTerm: ID) {
    shortTerm(longTerm: $longTerm) {
      edges {
        node {
          id
          goalName
          dateInitialted
          dateEnd
          description
          isDefault
          responsibility {
            id
            name
          }
          goalStatus {
            id
            status
          }
          comments {
            edges {
              node {
                id
                comment
              }
            }
          }
        }
      }
    }
  }
`

export const SHORT_TERM_GOALS_CACHE = gql`
  query($longTerm: ID) {
    shortTerm(longTerm: $longTerm) {
      edges {
        node {
          id
          goalName
          dateInitialted
          dateEnd
          description
          isDefault
          responsibility {
            id
            name
          }
          goalStatus {
            id
            status
          }
        }
      }
    }
  }
`

export const GET_PATIENT = gql`
  query($id: ID!) {
    student(id: $id) {
      id
      programArea(isActive: true) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_LTG_NAME_STATUS = gql`
  query($student: ID!, $program: ID!) {
    longTerm(student: $student, program: $program, isDefault: false) {
      edges {
        node {
          id
          goalName
          goalStatus {
            id
            status
          }
        }
      }
    }
  }
`

export const LONG_TERM_GOALS = gql`
  query($student: ID!, $program: ID!, $status: ID, $isDefault: Boolean) {
    longTerm(student: $student, program: $program, goalStatus: $status, isDefault: $isDefault) {
      edges {
        node {
          id
          goalName
          description
          dateInitialted
          dateEnd
          student {
            id
            firstname
          }
          isDefault
          responsibility {
            id
            name
          }
          goalStatus {
            id
            status
          }
          comments {
            edges {
              node {
                id
                comment
              }
            }
          }
        }
      }
    }
  }
`
export const LONG_TERM_GOALS_NAME_ONLY = gql`
  query($student: ID!, $program: ID!) {
    longTerm(student: $student, program: $program) {
      edges {
        node {
          id
          goalName
          isDefault
          goalStatus {
            id
            status
          }
        }
      }
    }
  }
`

export const TEMP = gql`
  query($shortTerm: ID) {
    targetAllocates(shortTerm: $shortTerm, getAll: true) {
      edges {
        node {
          id
          time
          targetStatus {
            id
            statusName
          }
          targetAllcatedDetails {
            id
            targetName
            targetType {
              id
              typeTar
            }
          }
          targetId {
            id
            domain {
              id
              domain
            }
          }
          tag {
            edges {
              node {
                id
                name
              }
            }
          }
          manualAllocateDomain {
            id
            domain
          }
          comments {
            edges {
              node {
                id
                comment
                createdBy {
                  id
                  firstName
                  lastName
                }
                createdAt
              }
            }
          }
        }
      }
    }
  }
`

export const GOAL_STATUS = gql`
  query {
    goalStatus {
      id
      status
    }
  }
`

export const GOAL_RESPONSIBILYTY = gql`
  query {
    goalResponsibility {
      id
      name
    }
  }
`

export const getTargetDetailsOptions = () => {
  return client
    .query({
      query: gql`
        {
          targetStatus {
            id
            statusName
          }
          types {
            id
            typeTar
          }
          promptCodes {
            id
            promptName
          }
          masteryCriteria {
            id
            name
          }
          domain {
            edges {
              node {
                id
                domain
              }
            }
          }
          goalsProgramArea {
            id
            name
          }
        }
      `,
    })
    .then(result => result)
    .catch(error => error)
}

export const getSearchSd = text => {
  return client
    .query({
      query: gql`
        query GetStimulus($text: String!) {
          targetSd(first: 8, sd_Icontains: $text) {
            edges {
              node {
                id
                sd
              }
            }
          }
        }
      `,
      variables: { text },
    })
    .then(result => result)
    .catch(error => error)
}

export const getSearchSteps = text => {
  return client
    .query({
      query: gql`
        query GetSteps($text: String!) {
          targetStep(first: 8, step_Icontains: $text) {
            edges {
              node {
                id
                step
              }
            }
          }
        }
      `,
      variables: { text },
    })
    .then(result => result)
    .catch(error => error)
}

export const getSearchTargets = text => {
  return client
    .query({
      query: gql`
        query GetTargets($text: String!, $studentId: ID!) {
          target(first: 8, student: $studentId, targetMain_TargetName_Icontains: $text) {
            edges {
              node {
                id
                allocatedTar
                domain {
                  id
                  domain
                }
                targetArea {
                  id
                  Area
                }
                video
                targetInstr
                targetMain {
                  id
                  targetName
                }
              }
            }
          }
        }
      `,
      variables: { text, studentId: localStorage.getItem('studentId') },
    })
    .then(result => result)
    .catch(error => error)
}

export const GET_DEFAULT_GOALS = gql`
  query($studentId: ID!, $program: ID) {
    longTerm(student: $studentId, program: $program, isDefault: true) {
      edges {
        node {
          id
          goalName
          description
          program {
            id
            name
          }
          shorttermgoalSet {
            edges {
              node {
                id
                goalName
                targetAllocateSet {
                  edges {
                    node {
                      id
                      targetStatus {
                        id
                        statusName
                      }
                      shortTerm {
                        id
                        goalName
                      }
                      targetAllcatedDetails {
                        id
                        targetName
                        targetType {
                          id
                          typeTar
                        }
                      }
                      targetId {
                        id
                        domain {
                          id
                          domain
                        }
                      }
                      manualAllocateDomain {
                        id
                        domain
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SET_GOAL_STATUS_DISCONTINUED = gql`
  mutation($goalId: ID!, $goalStatus: ID!, $programArea: ID!) {
    updateLongTerm(
      input: { goalData: { id: $goalId, goalStatus: $goalStatus, programArea: $programArea } }
    ) {
      details {
        id
        goalStatus {
          id
          status
        }
      }
    }
  }
`
export const SET_SHORT_GOAL_STATUS_DISCONTINUED = gql`
  mutation($goalId: ID!, $goalStatus: ID) {
    updateShortTerm(input: { goalData: { id: $goalId, goalStatus: $goalStatus } }) {
      details {
        id
        goalStatus {
          id
          status
        }
      }
    }
  }
`

export const DELETE_TARGET = gql`
  mutation($pk: ID!, $targetStatus: ID) {
    updateTargetAllocate3(input: { pk: $pk, targetData: { targetStatus: $targetStatus } }) {
      target {
        id
        targetStatus {
          id
          statusName
        }
      }
    }
  }
`

export const PASTE_ITEM = gql`
  mutation(
    $std1: ID!
    $std2: ID!
    $programArea: ID
    $longGoal: ID
    $shortGoal: ID
    $allocatedTarget: ID
    $type: String!
  ) {
    copyGoalTarget(
      input: {
        std1: $std1
        std2: $std2
        programArea: $programArea
        longGoal: $longGoal
        shortGoal: $shortGoal
        allocatedTarget: $allocatedTarget
        type: $type
      }
    ) {
      longGoal {
        id
        goalName
        description
        program {
          id
          name
        }
        shorttermgoalSet {
          edges {
            node {
              id
              goalName
              targetAllocateSet {
                edges {
                  node {
                    id
                    targetStatus {
                      id
                      statusName
                    }
                    targetAllcatedDetails {
                      id
                      targetName
                    }
                  }
                }
              }
            }
          }
        }
      }
      shortGoal {
        id
        goalName
        targetAllocateSet {
          edges {
            node {
              id
              targetStatus {
                id
                statusName
              }
              targetAllcatedDetails {
                id
                targetName
              }
            }
          }
        }
      }

      target {
        id
        date
        targetStatus {
          id
          statusName
        }
        shortTerm {
          id
          goalName
        }
        objective
        targetId {
          id
          domain {
            id
            domain
          }
        }
        targetInstr
        masteryCriteria {
          id
          name
        }
        isManual
        manualAllocateDomain {
          id
          domain
        }
        videos {
          edges {
            node {
              id
              url
            }
          }
        }
        targetDocs {
          edges {
            node {
              id
              url
              sd {
                id
                sd
              }
              step {
                id
                step
              }
            }
          }
        }
        peakBlocks
        peakType
        classes {
          edges {
            node {
              id
              name
              stimuluses {
                edges {
                  node {
                    id
                    option
                    stimulusName
                  }
                }
              }
            }
          }
        }
        eqCode
        mastery {
          edges {
            node {
              id
              sd {
                id
                sd
              }
              step {
                id
                step
              }
              status {
                id
                statusName
              }
              mastery {
                id
                name
              }
              prompts {
                edges {
                  node {
                    id
                    promptName
                  }
                }
              }
              manualMastery {
                edges {
                  node {
                    id
                    responsePercentage
                    minTrial
                    consecutiveDays
                    fromStatus {
                      id
                      statusName
                    }
                    toStatus {
                      id
                      statusName
                    }
                  }
                }
              }
            }
          }
        }
        manualMastery {
          edges {
            node {
              id
              responsePercentage
              minTrial
              consecutiveDays
              fromStatus {
                id
                statusName
              }
              toStatus {
                id
                statusName
              }
            }
          }
        }
        targetDocs {
          edges {
            node {
              id
              url
            }
          }
        }
        targetAllcatedDetails {
          id
          time
          targetType {
            id
            typeTar
          }
          targetName
          DailyTrials
          consecutiveDays
          promptCodes {
            edges {
              node {
                id
                promptName
              }
            }
          }
        }
        baselineDate
        domainName
        default
      }
    }
  }
`

export const EDIT_TARGET_TAG = gql`
  mutation($pk: ID!, $tag: [String]!) {
    createUpdateTargetTags(input: { pk: $pk, tags: $tag }) {
      target {
        id
        tag {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`
export const TARGET_OPTIONS = gql`
  query {
    targetStatus {
      id
      statusName
    }
    types {
      id
      typeTar
    }
  }
`

export const GET_VBMAPP_TARGET = gql`
  mutation vbmappTargetSuggest($pk: ID!, $area: ID!) {
    vbmappTargetSuggest(input: { pk: $pk, area: $area }) {
      targets {
        id
        domain {
          id
          domain
        }
        targetArea {
          id
          Area
        }
        targetInstr
        video
        allocatedTar
        targetMain {
          id
          targetName
        }
      }
    }
  }
`

export const VBMAPP_AREAS = gql`
  query {
    vbmappAreas {
      id
      apiArea
      areaName
      description
    }
  }
`
export const VBMAPP_GET_ASSESSMENT = gql`
  query vbmappGetAssessments($student: ID) {
    vbmappGetAssessments(student: $student) {
      edges {
        total
        milestone
        milestonePercent
        milestoneTotalQuestions
        milestoneAttended
        barriers
        barriersPercent
        transition
        transitionPercent
        eesa
        eesaPercent
        node {
          id
          date
          testNo
          color
          createdDate
          student {
            id
            firstname
          }
        }
      }
    }
  }
`
