/* eslint-disable */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

export async function getCourseUsers(payload) {
  return apolloClient
    .query({
      query: gql`
        query getCourseUsers($name: String, $email: String) {
          getCourseUsers(email_Icontains: $email, name_Icontains: $name) {
            edges {
              node {
                id
                name
                email
                phoneNo
                purchasedcoursetemplatesSet {
                  edges {
                    node {
                      id
                      courseTemplate {
                        id
                        name
                      }
                    }
                  }
                }
                user {
                  id
                  firstName
                  username
                  email
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get public Templates ERORR ', JSON.stringify(error))
    })
}

export async function createCourseUser(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation createCourseUser($name: String!, $email: String!, $phoneNo: String) {
          createCourseUser(input: { name: $name, email: $email, phoneNo: $phoneNo }) {
            status
            message
            courseUserDetails {
              id
              name
              email
              phoneNo
              purchasedcoursetemplatesSet {
                edges {
                  node {
                    id
                    courseTemplate {
                      id
                      name
                    }
                  }
                }
              }
              user {
                id
                firstName
                username
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('create Course user ERORR ', JSON.stringify(error))
    })
}

export async function updateCourseUser(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateCourseUser(
          $courseUserId: ID!
          $name: String!
          $email: String!
          $phoneNo: String
        ) {
          updateCourseUser(
            input: { courseUserId: $courseUserId, name: $name, email: $email, phoneNo: $phoneNo }
          ) {
            status
            message
            courseUserDetails {
              id
              name
              email
              phoneNo
              purchasedcoursetemplatesSet {
                edges {
                  node {
                    id
                    courseTemplate {
                      id
                      name
                    }
                  }
                }
              }
              user {
                id
                firstName
                username
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('edit Course user ERORR ', JSON.stringify(error))
    })
}

export async function getCourseTemplates() {
  return apolloClient
    .query({
      query: gql`
        query {
          getPublicCourses {
            id
            name
            isActive
            coursetemplatemodulesSet {
              edges {
                node {
                  id
                  name
                  description
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get Templates ERORR ', JSON.stringify(error))
    })
}

export async function assignCourseToUser(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation assignCourseToUser(
          $courseUser: ID!
          $courseTemplateId: ID!
          $entireCourse: Boolean
          $assignModules: [ID]
        ) {
          assignCourseToUser(
            input: {
              courseUser: $courseUser
              courseTemplate: $courseTemplateId
              assignModules: $assignModules
              entireCourse: $entireCourse
            }
          ) {
            status
            message
            purchasedCourses {
              id
              isActive
              courseTemplate {
                id
                name
                isActive
                coursetemplatemodulesSet {
                  edges {
                    node {
                      id
                      name
                      description
                    }
                  }
                }
              }
              courseUser {
                id
                email
                name
              }
              assignedModules {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('edit Course user ERORR ', JSON.stringify(error))
    })
}

export async function updateAssignedCourse(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateAssignedCourse(
          $purchasedCourse: ID!
          $assignModules: [ID]
          $entireCourse: Boolean
          $removeModules: [ID]
        ) {
          updateAssignedCourse(
            input: {
              purchasedCourse: $purchasedCourse
              assignModules: $assignModules
              removeModules: $removeModules
              entireCourse: $entireCourse
            }
          ) {
            status
            message
            purchasedCourses {
              id
              isActive
              courseTemplate {
                id
                name
                isActive
                coursetemplatemodulesSet {
                  edges {
                    node {
                      id
                      name
                      description
                    }
                  }
                }
              }
              courseUser {
                id
                email
                name
              }
              assignedModules {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('edit Course user ERORR ', JSON.stringify(error))
    })
}
export async function activeInactiveUserCourse(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation activeInactiveUserCourse($id: ID!, $isActive: Boolean!) {
          activeInactiveUserCourse(input: { id: $id, isActive: $isActive }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('active Course user ERORR ', JSON.stringify(error))
    })
}
export async function getPurchasedCourseByCourseUser(payload) {
  return apolloClient
    .query({
      query: gql`
        query getPurchasedCourseByCourseUser($courseUserId: ID!) {
          getPurchasedCourseByCourseUser(courseUserId: $courseUserId) {
            edges {
              node {
                id
                isActive
                courseUser {
                  id
                  email
                  name
                  phoneNo
                  user {
                    id
                  }
                }
                assignedModules {
                  edges {
                    node {
                      id
                      name
                      daysForCompletion
                      usercoursemodulevalidationSet {
                        edges {
                          node {
                            id
                            expireTime
                          }
                        }
                      }
                    }
                  }
                }
                courseTemplate {
                  id
                  name
                  isActive
                  coursetemplatemodulesSet {
                    edges {
                      node {
                        id
                        name
                        description
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('get public Templates ERORR ', JSON.stringify(error))
    })
}

export async function changeModuleAvailability(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation updateUserCourseValidationBySuperadmin(
          $userCourse: ID!
          $moduleId: ID!
          $expireTime: DateTime
        ) {
          updateUserCourseValidationBySuperadmin(
            input: { userCourse: $userCourse, moduleId: $moduleId, expireTime: $expireTime }
          ) {
            message
            status
            userCourseModuleValidation {
              id
              expireTime
              module {
                id
                name
              }
              status
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.log('edit Course user ERORR ', JSON.stringify(error))
    })
}

export async function getUserCourseModuleValidations(payload) {
  return apolloClient
    .query({
      query: gql`
        query getUserCourseModuleValidations($userCourse: ID!) {
          getUserCourseModuleValidations(userCourseId: $userCourse) {
            edges {
              node {
                id
                userCourse {
                  id
                }
                module {
                  id
                  name
                }
                expireTime
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => {
      console.log('THE RESULT', result)
      return result.data
    })
    .catch(error => {
      console.log('get public Templates ERORR ', JSON.stringify(error))
    })
}
