/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable object-shorthand */
/* eslint-disable array-callback-return */
/* eslint-disable no-const-assign */

import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import moment from 'moment'
import {
  getAllocatedTargets,
  updateSessionTargets,
  updateSessionDetails,
  getAuthFamily,
  filterAllocatedTargets,
  getTargetStatus,
  getSessionTargets,
  getSessionNames,
  getSessionChildSessions,
} from 'services/sessiontargetallocation'
import actions from './actions'

export function* GET_ALLOCATED_TARGETS({ payload }) {
  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      AllocateLoading: true,
    },
  })

  const statusList = yield select(state => state.sessiontargetallocation.FetchedStatusList)

  const response = yield call(getAllocatedTargets, { ...payload, statusList })
  const res2 = yield call(getTargetStatus)

  if (response) {
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        AllocatedTargetsList: response.data.targetAllocates.edges,
        AllocatedTargetListClone: response.data.targetAllocates.edges,
        AllocateLoading: false,
      },
    })
  }
  if (res2) {
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        AllocateLoading: false,
        TargetStatusList: res2.data.targetStatus,
        TargetTypes: res2.data.types,
      },
    })
  }
}

function compare(a, b) {
  if (a.sessionName.name < b.sessionName.name) {
    return -1
  }
  if (a.sessionName.name > b.sessionName.name) {
    return 1
  }
  return 0
}

export function* GET_SESSION_CHILDSESSIONS({ payload }) {
  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      SessionNamesLoading: true,
    },
  })
  const { SessionNames } = yield select(state => state.sessiontargetallocation)
  const newSessionNames = []
  if (SessionNames.length > 0) {
    for (let i = 0; i < SessionNames.length; i++) {
      const response = yield call(getSessionChildSessions, {
        date: payload.date,
        sessionNameId: SessionNames[i].sessionName.id,
        studentId: payload.studentId,
      })
      const temp = []
      if (response && response.data) {
        temp = response.data.learnerChildSessions.map(item => {
          return { node: item }
        })
      }
      const newItem = SessionNames[i]
      newItem.childsessionSet = { edges: temp }
      newSessionNames.push(newItem)
    }
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        SessionNames: newSessionNames,
      },
    })

    yield put({
      type: 'sessiontargetallocation/GET_SESSION_TARGETS',
      payload: {
        studentId: payload.studentId,
        sessionId: newSessionNames[0].sessionName?.id,
      },
    })
  }

  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      SessionNamesLoading: false,
    },
  })
}

export function* GET_SESSION_PREV_CHILDSESSIONS({ payload }) {
  const newSessionNames = []
  if (payload.sessions.length > 0) {
    for (let i = 0; i < payload.sessions.length; i++) {
      const response = yield call(getSessionChildSessions, {
        date: payload.date,
        sessionNameId: payload.sessions[i].sessionName.id,
        studentId: payload.studentId,
      })
      const temp = []
      if (response && response.data) {
        temp = response.data.learnerChildSessions.map(item => {
          return { node: item }
        })
      }
      const newItem = payload.sessions[i]
      newItem.childsessionSet = { edges: temp }
      newSessionNames.push(newItem)
    }
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        SessionPrevNames: newSessionNames,
      },
    })
  }
}

export function* GET_SESSION_NAMES({ payload }) {
  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      SessionNamesLoading: true,
    },
  })

  const sessNames = yield call(getSessionNames, payload)
  if (sessNames && sessNames.data) {
    const temp = sessNames.data.GetStudentSession.edges.map(({ node }) => node)
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        SessionNames: temp,
      },
    })

    if (temp.length > 0) {
      yield put({
        type: 'sessiontargetallocation/GET_SESSION_CHILDSESSIONS',
        payload: {
          studentId: payload.studentId,
          date: moment().format('YYYY-MM-DD'),
        },
      })
    }
  }

  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      SessionNamesLoading: false,
    },
  })
}

export function* FILTER_ALLOCATED_TARGETS({ payload }) {
  const statusList = yield select(state => state.sessiontargetallocation.FetchedStatusList)
  let original = yield select(state => state.sessiontargetallocation.AllocatedTargetsList)

  const newStatus = []
  if (payload.selectedStatus) {
    payload.selectedStatus.forEach(item => {
      let i = 0
      let exist = false
      for (i = 0; i < statusList.length; i += 1) {
        if (statusList[i] === item) {
          exist = true
          break
        }
      }
      if (!exist) {
        newStatus.push(item)
      }
    })
  }

  let response = null
  const filteredList = []
  if (newStatus.length > 0) {
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        AllocateLoading: true,
      },
    })

    response = yield call(getAllocatedTargets, { ...payload, statusList: newStatus })
    if (response && response.data) {
      original = [...response.data.targetAllocates.edges, ...original]
    }
  }

  payload.selectedStatus.forEach(item => {
    const temp = original.filter(({ node }) => node.targetStatus.id === item)
    filteredList.push(...temp)
  })

  if (response) {
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        AllocatedTargetsList: original,
        AllocatedTargetListClone: filteredList,
        FetchedStatusList: [...statusList, ...newStatus],
        AllocateLoading: false,
      },
    })
  } else {
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        AllocatedTargetListClone: filteredList,
        AllocateLoading: false,
      },
    })
  }
}

export function* GET_SESSION_TARGETS({ payload }) {
  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(getSessionTargets, payload)
  if (response) {
    let tempTargets = []
    if (response.data.GetStudentSession && response.data.GetStudentSession.edges.length > 0) {
      tempTargets = response.data.GetStudentSession.edges[0].node.targets.edges.filter(
        ({ node }) => node.targetStatus.statusName !== 'Deleted',
      )
    }
    let tempSessionNames = yield select(state => state.sessiontargetallocation.SessionNames)
    tempSessionNames = tempSessionNames.map(item => {
      if (item.sessionName?.id === payload.sessionId) {
        return { ...item, ...response.data.GetStudentSession.edges[0].node, sort: false }
      }
      return { ...item, sort: false }
    })

    console.log('tempSessionNames ===> ', tempSessionNames)

    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        SessionNames: tempSessionNames,
      },
    })
  }

  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_FAMILY_AUTH({ payload }) {
  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      AuthFamilyLoading: true,
    },
  })
  const response = yield call(getAuthFamily, payload)

  if (response) {
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        FamilyMemberList: response.data.student.family,
        AuthStaffList: response.data.student.authStaff,
      },
    })
  }

  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      AuthFamilyLoading: false,
    },
  })
}

export function* UPDATE_SESSION({ payload }) {
  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      UpdateSessionLoading: true,
    },
  })

  const response = yield call(updateSessionTargets, payload)

  if (response?.data && response.data.updateSessionTargets) {
    const obj = response.data.updateSessionTargets.session
    let tempSessionNames = yield select(state => state.sessiontargetallocation.SessionNames)
    tempSessionNames = tempSessionNames.map(item => {
      if (item.sessionName.id === obj.sessionName.id) {
        return { ...item, ...obj, sort: false }
      }
      return { ...item, sort: false }
    })

    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        UpdateSessionLoading: false,
        SessionNames: tempSessionNames,
      },
    })

    notification.success({
      message: 'Session Updated Successfully',
    })
  } else {
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        UpdateSessionLoading: false,
      },
    })
  }
}

export function* FILTER_TARGETS({ payload }) {
  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      loading: false,
    },
  })

  const response = yield call(filterAllocatedTargets, payload)

  if (response && response.data) {
    yield put({
      type: 'sessiontargetallocation/SET_STATE',
      payload: {
        AllocatedTargetsList: response.data.targetAllocates.edges,
        randomKey: Math.random(),
      },
    })
  }

  yield put({
    type: 'sessiontargetallocation/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_TARGET({ payload }) {
  let session = ''
  let sessionId = ''
  const targetList = []
  if (payload.session === 'Morning') {
    session = yield select(state => state.sessiontargetallocation.MorningSession)
  } else if (payload.session === 'Afternoon') {
    session = yield select(state => state.sessiontargetallocation.AfternoonSession)
  } else if (payload.session === 'Evening') {
    session = yield select(state => state.sessiontargetallocation.EveningSession)
  } else if (payload.session === 'Default') {
    session = yield select(state => state.sessiontargetallocation.DefaultSession)
  }

  if (session !== '') {
    sessionId = session.id
    for (let i = 0; i < session.targets.edges.length; i++) {
      if (session.targets.edges[i].node.id !== payload.id) {
        targetList.push(`"${session.targets.edges[i].node.id}"`)
      }
    }
  }
  const response = yield call(updateSessionTargets, { id: sessionId, targetList: targetList })

  if (response && response.data) {
    if (payload.session === 'Morning') {
      yield put({
        type: 'sessiontargetallocation/SET_STATE',
        payload: {
          MorningSession: response.data.updateSessionTargets.session,
        },
      })
    }
    if (payload.session === 'Afternoon') {
      yield put({
        type: 'sessiontargetallocation/SET_STATE',
        payload: {
          AfternoonSession: response.data.updateSessionTargets.session,
        },
      })
    }
    if (payload.session === 'Evening') {
      yield put({
        type: 'sessiontargetallocation/SET_STATE',
        payload: {
          EveningSession: response.data.updateSessionTargets.session,
        },
      })
    }
    if (payload.session === 'Default') {
      yield put({
        type: 'sessiontargetallocation/SET_STATE',
        payload: {
          DefaultSession: response.data.updateSessionTargets.session,
        },
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    // GET_DATA(), // run once on app load to fetch menu data
    takeEvery(actions.GET_ALLOCATED_TARGETS, GET_ALLOCATED_TARGETS),
    takeEvery(actions.FILTER_ALLOCATED_TARGETS, FILTER_ALLOCATED_TARGETS),
    takeEvery(actions.GET_SESSION_TARGETS, GET_SESSION_TARGETS),
    takeEvery(actions.GET_SESSION_NAMES, GET_SESSION_NAMES),
    takeEvery(actions.GET_FAMILY_AUTH, GET_FAMILY_AUTH),
    takeEvery(actions.UPDATE_SESSION, UPDATE_SESSION),
    takeEvery(actions.FILTER_TARGETS, FILTER_TARGETS),
    takeEvery(actions.DELETE_TARGET, DELETE_TARGET),
    takeEvery(actions.GET_SESSION_CHILDSESSIONS, GET_SESSION_CHILDSESSIONS),
    takeEvery(actions.GET_SESSION_PREV_CHILDSESSIONS, GET_SESSION_PREV_CHILDSESSIONS),
  ])
}
