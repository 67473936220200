/* eslint-disable import/prefer-default-export */
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from 'apollo/config'

export async function createRegistration(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation(
          $firstname: String!
          $lastname: String
          $email: String!
          $phone: String!
          $userType: String!
          $clinicSchoolName: String
          $city: String!
          $image: String
        ) {
          addMarketUserInfo(
            input: {
              firstname: $firstname
              lastname: $lastname
              email: $email
              phone: $phone
              userType: $userType
              city: $city
              clinicOrSchoolName: $clinicSchoolName
              image: $image
            }
          ) {
            status
            message
            marketingUser {
              id
              firstname
              lastname
              email
              phoneNo
              city
              userType
              clinicOrSchoolName
              isVerified
              createdAt
              updatedAt
              image
              services {
                edges {
                  node {
                    id
                    serviceName
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        firstname: payload.values.firstname,
        lastname: payload.values.lastname,
        userType: payload.values.type,
        email: payload.values.email,
        city: payload.values.city,
        phone: payload.values.mobileno,
        clinicSchoolName: payload.values.schoolClinicName,
        image: payload.image,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function verifyOtp(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($otp: String!, $objId: ID!) {
          verifyMarketUser(input: { marketUser: $objId, otp: $otp }) {
            status
            message
          }
        }
      `,
      variables: {
        otp: payload.otp,
        objId: payload.objId,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function resendOtp(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($objId: ID!) {
          resendOtpForVerification(input: { marketUser: $objId }) {
            status
            message
          }
        }
      `,
      variables: {
        objId: payload.objId,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function getMarketingServices() {
  return apolloClient
    .query({
      query: gql`
        query {
          usersMarketServices {
            id
            serviceName
          }
        }
      `,
    })
    .then(result => result)
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function saveServices(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($services: [ID], $objId: ID!) {
          updateMarketUserInfo(input: { marketUser: $objId, services: $services }) {
            status
            message
            marketingUser {
              id
              firstname
              lastname
              email
              phoneNo
              city
              userType
              clinicOrSchoolName
              isVerified
              createdAt
              updatedAt
              services {
                edges {
                  node {
                    id
                    serviceName
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        services: payload.services,
        objId: payload.objId,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function getMarketingSearch(payload) {
  return apolloClient
    .query({
      query: gql`
        query($query: String, $city: String, $after: String) {
          allMarketingUsersInfo(
            service: $query
            location: $city
            first: 10
            after: $after
            isApproved: true
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                firstname
                lastname
                email
                phoneNo
                city
                userType
                clinicOrSchoolName
                isVerified
                createdAt
                updatedAt
                isApproved
                image
                marketusersratingSet {
                  averageRating
                  reviewsCount
                }
                services {
                  edges {
                    node {
                      id
                      serviceName
                      isActive
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        query: payload.text,
        city: payload.city,
        after: payload.after,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function emailVerify(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($userId: ID!, $name: String!, $email: String!) {
          sendOtpForReview(input: { marketUser: $userId, email: $email, name: $name }) {
            status
            message
          }
        }
      `,
      variables: {
        userId: payload.userId,
        email: payload.email,
        name: payload.name,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function createReviewMarketUser(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation(
          $userId: ID!
          $name: String!
          $email: String!
          $otp: String!
          $rating: Int!
          $feedback: String
        ) {
          createReviewMarketUser(
            input: {
              marketUser: $userId
              reviewerUsername: $name
              reviewerEmail: $email
              review: $feedback
              rating: $rating
              otp: $otp
            }
          ) {
            status
            message
            marketUserRating {
              id
              reviewerUsername
              reviewerEmail
              review
              rating
              updatedAt
              createdAt
              marketUser {
                id
                email
                image
                marketusersratingSet {
                  averageRating
                  reviewsCount
                }
              }
            }
          }
        }
      `,
      variables: {
        userId: payload.userId,
        email: payload.email,
        name: payload.name,
        feedback: payload.feedback,
        rating: payload.rating,
        otp: payload.otp,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function getComments(payload) {
  return apolloClient
    .query({
      query: gql`
        query($userId: ID!) {
          marketingUserReviews(marketUser: $userId) {
            id
            reviewerUsername
            reviewerEmail
            review
            rating
            updatedAt
            createdAt
            marketUser {
              id
              email
              image
            }
          }
        }
      `,
      variables: {
        userId: payload.userId,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function uploadDocs(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($qualification: [String], $practise: [String], $userId: ID!) {
          addMarketingUserDoc(
            input: {
              marketUser: $userId
              qualificationCertificate: $qualification
              practiseLicense: $practise
            }
          ) {
            status
            message
            marketingUser {
              id
              qualificationCertificate {
                edges {
                  node {
                    doc
                  }
                }
              }
              practiseLicense {
                edges {
                  node {
                    licenceDoc
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function getMarketingUsers(payload) {
  return apolloClient
    .query({
      query: gql`
        query($isApproved: Boolean, $startDate: Date, $endDate: Date) {
          allMarketingUsersInfo(isApproved: $isApproved, startDate: $startDate, endDate: $endDate) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                firstname
                lastname
                email
                phoneNo
                city
                userType
                clinicOrSchoolName
                isVerified
                createdAt
                updatedAt
                isApproved
                image
                marketusersratingSet {
                  averageRating
                  reviewsCount
                }
                services {
                  edges {
                    node {
                      id
                      serviceName
                      isActive
                    }
                  }
                }
                qualificationCertificate {
                  edges {
                    node {
                      id
                      doc
                    }
                  }
                }
                practiseLicense {
                  edges {
                    node {
                      id
                      licenceDoc
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function approveMarketingUser(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($userId: ID!, $approved: Boolean!) {
          approveMarketingUser(input: { marketUser: $userId, isApproved: $approved }) {
            status
            message
            marketingUser {
              id
              isApproved
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function verifyUserSendOtp(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($email: String!) {
          resendOtpForVerificationByEmail(input: { email: $email }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}

export async function verifyUserOtp(payload) {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation($email: String!, $otp: String!) {
          verifyOtpUsingEmail(input: { email: $email, otp: $otp }) {
            status
            message
            markUser {
              id
              firstname
              lastname
              email
              phoneNo
              city
              userType
              clinicOrSchoolName
              isVerified
              createdAt
              updatedAt
              image
              services {
                edges {
                  node {
                    id
                    serviceName
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log('THE ERORR', JSON.stringify(error))
      // return error
    })
}
