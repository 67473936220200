const actions = {
  SET_STATE: 'sessiontargetallocation/SET_STATE',
  GET_ALLOCATED_TARGETS: 'sessiontargetallocation/GET_ALLOCATED_TARGETS',
  GET_SESSION_TARGETS: 'sessiontargetallocation/GET_SESSION_TARGETS',
  UPDATE_SESSION: 'sessiontargetallocation/UPDATE_SESSION',
  FILTER_TARGETS: 'sessiontargetallocation/FILTER_TARGETS',
  DELETE_TARGET: 'sessiontargetallocation/DELETE_TARGET',
  GET_FAMILY_AUTH: 'sessiontargetallocation/GET_FAMILY_AUTH',
  FILTER_ALLOCATED_TARGETS: 'sessiontargetallocation/FILTER_ALLOCATED_TARGETS',
  GET_SESSION_NAMES: 'sessiontargetallocation/GET_SESSION_NAMES',
  GET_SESSION_CHILDSESSIONS: 'sessiontargetallocation/GET_SESSION_CHILDSESSIONS',
  GET_SESSION_PREV_CHILDSESSIONS: 'sessiontargetallocation/GET_SESSION_PREV_CHILDSESSIONS',
}

export default actions
