/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_COMMENT = gql`
  query($student: ID!, $start: Date!, $end: Date!) {
    getIepReportComment(student: $student, start: $start, end: $end) {
      id
      start
      end
      comment
      student {
        id
        firstname
      }
    }
  }
`

export const CREATE_COMMENT = gql`
  mutation($student: ID!, $start: Date!, $end: Date!, $comment: String!) {
    createIepReportComment(
      input: { student: $student, start: $start, end: $end, comment: $comment }
    ) {
      details {
        id
        start
        end
        comment
        student {
          id
          firstname
        }
      }
    }
  }
`

export const SUMMERY = gql`
  query($program: ID!) {
    peakDataSummary(program: $program) {
      total
      totalAttended
      totalCorrect
      totalIncorrect
      totalNoResponse
      totalSkipped
      totalSuggested
      edges {
        node {
          id
          yes {
            edges {
              node {
                id
                code
              }
            }
          }
          no {
            edges {
              node {
                id
                code
              }
            }
          }
        }
      }
    }
  }
`
export const getAllQuestionsCode = gql`
  query($type: String!) {
    peakGetCodes(peakType: $type) {
      edges {
        node {
          id
          peakType
          code
          description
          instructions
          expRes
        }
      }
    }
  }
`
export const getTableDataQuery = gql`
  mutation peakReport($pk: ID!) {
    peakReport(input: { pk: $pk }) {
      fls
      pls
      vcs
      vrm
    }
  }
`
export const lastAssesmentQuery = gql`
  mutation lastFourRecords($pk: ID!) {
    lastFourRecords(input: { pk: $pk }) {
      programs {
        id
        date
        user {
          id
          firstName
          lastName
        }
        student {
          id
          caseManager {
            id
            name
          }
        }
        submitpeakresponsesSet {
          edges {
            node {
              id
              yes {
                edges {
                  node {
                    id
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const getStudentDetails = gql`
  query student($id: ID!) {
    student(id: $id) {
      firstname
      lastname
      dob
      internalNo
      caseManager {
        id
        name
      }
      authStaff {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`
export const GOAL_STATUS = gql`
  query($studentId: ID!) {
    goalStatus(student: $studentId) {
      id
      status
      longtermgoalSet {
        edges {
          node {
            id
            goalName
            description
            dateInitialted
            dateEnd
          }
        }
      }
    }
  }
`

export const STUDENT_QUERY = gql`
  query StudentDetails($studentId: ID!) {
    student(id: $studentId) {
      id
      firstname
      lastname
      currentAddress
      dob
      email
      parentMobile
      createdAt
    }
  }
`

export const TARGET = gql`
  query($studentId: ID) {
    target(student: $studentId, isActive: true) {
      edges {
        node {
          id
          allocatedTar
          domain {
            id
            domain
          }
          targetArea {
            id
            Area
          }
          targetInstr
          video
          targetMain {
            id
            targetName
          }
        }
      }
    }
  }
`

export const GET_GOAL_NEW_DETAILS = gql`
  query($studentId: ID!) {
    longTerm(student: $studentId) {
      edges {
        node {
          id
          program {
            id
            name
          }
          goalName
          description
          dateInitialted
          dateEnd
          description
          student {
            id
            firstname
          }
          responsibility {
            id
            name
          }
          goalStatus {
            id
            status
          }
          shorttermgoalSet {
            edges {
              node {
                id
                goalName
                dateInitialted
                dateEnd
                masterDate
                description
                goalStatus {
                  id
                  status
                }
                targetAllocateSet {
                  edges {
                    node {
                      id
                      targetStatus {
                        id
                        statusName
                      }
                      mastery {
                        edges {
                          node {
                            id
                            sd {
                              id
                              sd
                            }
                            step {
                              id
                              step
                            }
                            status {
                              id
                              statusName
                            }
                            createdAt
                            targetsdstepstatuschangeSet {
                              edges {
                                node {
                                  id
                                  sdStep {
                                    id
                                    sd {
                                      id
                                      sd
                                    }
                                    step {
                                      id
                                      step
                                    }
                                  }
                                  createdAt
                                  updatedAt
                                  toStatus {
                                    id
                                    statusName
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      targetInstr
                      masterDate
                      intherapyDate
                      targetAllcatedDetails {
                        id
                        targetName
                        dateBaseline
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GOALS_DETAILS = gql`
  query LongTermGoalProgress($studentId: ID!, $status: [ID]!, $start: Date!, $end: Date!) {
    goalsLongProgressReport(student: $studentId, status: $status, start: $start, end: $end) {
      masteryDays
      dateMastered
      goal {
        id
        goalName
        dateInitialted
        dateEnd
        masterTar
        totalTar
        goalStatus {
          id
          status
        }
        shorttermgoalSet {
          edges {
            node {
              id
              goalName
              dateInitialted
              dateEnd
              masterDate
              goalStatus {
                id
                status
              }
              targetAllocateSet {
                edges {
                  node {
                    id
                    targetStatus {
                      id
                      statusName
                    }
                    targetInstr
                    masterDate
                    intherapyDate
                    targetAllcatedDetails {
                      id
                      targetName
                      dateBaseline
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const IEP_REPORT_DOC = gql`
  mutation(
    $studentId: ID!
    $start: Date!
    $end: Date!
    $textBoxObj: String
    $longGoal: JSONString!
    $shortGoal: JSONString!
    $targets: String!
  ) {
    generateIepReportDocx(
      input: {
        iepData: {
          studentId: $studentId
          start: $start
          end: $end
          textBoxObj: $textBoxObj
          longGoals: $longGoal
          shortGoals: $shortGoal
          targets: $targets
        }
      }
    ) {
      report
    }
  }
`

export const DOMAIN_MASTERED = gql`
  query($studentId: ID, $dateGte: Date, $dateLte: Date) {
    domainMastered(studentId: $studentId, dateGte: $dateGte, dateLte: $dateLte) {
      totalCount
      target {
        id
        domainName
        targetId {
          id
          domain {
            id
            domain
          }
        }
        targetAllcatedDetails {
          id
          targetName
          dateBaseline
        }
        intherapyDate
        masterDate
        inmaintainenceDate
      }
    }
  }
`

export const DEFAULT_GOALS = gql`
  query($studentId: ID!) {
    longTerm(student: $studentId, isDefault: false, goalStatus: "R29hbFN0YXR1c1R5cGU6NA==") {
      edges {
        node {
          id
          goalName
          description
          dateInitialted
          dateEnd
          student {
            id
            firstname
          }
          goalStatus {
            id
            status
          }
          shorttermgoalSet {
            edges {
              node {
                id
                goalName
                dateInitialted
                dateEnd
                description
                assessment {
                  id
                  name
                }
                goalStatus {
                  id
                  status
                }
                targetAllocateSet {
                  edges {
                    node {
                      id
                      time
                      targetInstr
                      peakBlocks
                      peakType
                      date
                      objective
                      targetStatus {
                        id
                        statusName
                      }
                      targetAllcatedDetails {
                        id
                        targetName
                        dateBaseline
                        DailyTrials
                        consecutiveDays
                        targetType {
                          id
                          typeTar
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
