const actions = {
  SET_STATE: 'screening/SET_STATE',
  LOAD_DATA: 'screening/LOAD_DATA',
  CREATE_ASSESSMENT: 'screening/CREATE_ASSESSMENT',
  RECORD_RESPONSE: 'screening/RECORD_RESPONSE',
  UPDATE_RESPONSE: 'screening/UPDATE_RESPONSE',
  UPDATE_STATUS: 'screening/UPDATE_STATUS',
  GET_AREAS: 'screening/GET_AREAS',
  RECORD_AREA_RESPONSE: 'screening/RECORD_AREA_RESPONSE',
  RECORD_VIDEO: 'screening/RECORD_VIDEO',
  START_NEW_ASSESSMENT: 'screening/START_NEW_ASSESSMENT',
  END_ASSESSMENT: 'screening/END_ASSESSMENT',

  // Report start
  LOAD_REPORT_DATA: 'screening/LOAD_REPORT_DATA',
  GET_LERNER_SCREENINGS: 'screening/GET_LERNER_SCREENINGS',
  RECORD_REPORT_AREA_RESPONSE: 'screening/RECORD_REPORT_AREA_RESPONSE',
  RECORD_ASSESSMENT_RESPONSE: 'screening/RECORD_ASSESSMENT_RESPONSE',
  //
  SCREENING_RESULT: 'screening/SCREENING_RESULT',
  // super admin reports actions
  ALL_SCREENING_COUNT: 'screening/ALL_SCREENING_COUNT',
  GET_ALL_SCREENING: 'screening/GET_ALL_SCREENING',
}

export default actions
