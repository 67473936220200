/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-template */

import React, { useState, useEffect } from 'react'
import { notification, Empty } from 'antd'
import { ResponsivePie } from '@nivo/pie'
import groupObj from '@hunters/group-object'

import moment from 'moment'
import { useQuery } from 'react-apollo'
import { DOMAIN_MASTERED } from './query'

const PieChart = ({
  start_date,
  end_date,
  selectedprogram,
  domainSelected,
  statusselected,
  domainId,
}) => {
  const [GraphData, setGraphData] = useState([])
  // const [statusselected, setStatusselected] = useState(null)
  const studentId = localStorage.getItem('studentId')

  const { data, loading, error } = useQuery(DOMAIN_MASTERED, {
    variables: {
      studentId,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      selectedprogram,
      statusselected,
      domainId,
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (data) {
      let gdata = []
      const domainData = []
      if (data.domainMastered.target) {
        data.domainMastered.target.map(item => {
          return domainData.push({
            domain: item.targetId ? item.targetId.domain.domain : item.manualAllocateDomain.domain,
            target: item.targetAllcatedDetails.targetName,
          })
        })
        let groupedData = groupObj.group(domainData, 'domain')

        let keys = Object.keys(groupedData)
        // if (domainSelected) {
        //   keys = { domainSelected }
        // }
        for (let k = 0; k < keys.length; k++) {
          if (groupedData[keys[k]]?.length > 0) {
            gdata.push({
              id: keys[k],
              label: keys[k],
              value: groupedData[keys[k]].length,
            })
          }
        }
      }
      setGraphData(gdata)
    }
  }, [data])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {GraphData && GraphData.length === 0 ? (
        <Empty style={{ marginTop: '100px' }} />
      ) : (
        <ResponsivePie
          data={GraphData}
          margin={{ top: 40, right: 60, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={2}
          cornerRadius={3}
          colors={{ scheme: 'paired' }}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          radialLabel={function(e) {
            return e.label + ' (' + e.value + ')'
          }}
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor="#333333"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={7}
          radialLabelsLinkHorizontalLength={12}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor={{ from: 'color' }}
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor="#333333"
          // eslint-disable-next-line react/jsx-boolean-value
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
        />
      )}
    </div>
  )
}

export default PieChart
