import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip, Typography } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const { Title } = Typography

const Enquiry = () => {
  const school = useSelector(state => state.school)
  const [copied, setCopied] = useState(false)
  const { id } = school
  const url = `https://${window.location.hostname}/#/enquiry/${id}`

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 10,
        }}
      >
        <Link to={`/enquiry/${id}`}>
          {' '}
          <Title level={4}>{url}</Title>
        </Link>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div>
            <Tooltip title="Copy URL">
              <CopyToClipboard
                text={url}
                onCopy={() => {
                  setCopied(true)
                  setTimeout(() => {
                    setCopied(false)
                  }, 1000)
                }}
                className="ant-btn ant-btn-default"
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              >
                <CopyOutlined />
              </CopyToClipboard>
            </Tooltip>
            {copied && <div style={{ fontSize: 12, textAlign: 'center' }}>copied!</div>}
          </div>

          <Link to={`/enquiry/${id}`} className="ant-btn ant-btn-primary ant-btn-lg">
            Create Enquiry
          </Link>
        </div>
      </div>
    </>
  )
}

export default Enquiry
