/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { COLORS, HEADER_COLOR } from 'assets/styles/globalStyles'
import { useSelector, useDispatch } from 'react-redux'
import { Breadcrumb, Drawer, Button } from 'antd'
import LearnerSelect from 'components/LearnerSelect'
import EyeComponent from 'components/LearnerHeaderComponent/eyeComponent'
import './styles.scss'
import { Link } from 'react-router-dom'
import PropTypes, { string } from 'prop-types'

let clr = ''
function HeaderComponent(props) {
  const {
    title = '',
    extraRightContent = null,
    extraLeftContent = null,
    leftContent = '',
    centerContent,
    rightContent = '',
    pathList = [],
    showHomePath = true,
    learnerCard = false,
    changeLearner = true,
  } = props
  // console.log(pathList)

  const student = useSelector(state => state.student)
  const user = useSelector(state => state.user)
  const learnersprogram = useSelector(state => state.learnersprogram)
  const [goalComp, setGoalComp] = useState(false)
  const [visibleFilter, setvisibleFilter] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const { LearnersFetched } = learnersprogram
    clr = HEADER_COLOR[Math.floor(Math.random() * HEADER_COLOR.length)]

    const std = localStorage.getItem('studentId')
      ? JSON.parse(localStorage.getItem('studentId'))
      : null

    if (!LearnersFetched) {
      dispatch({
        type: 'learnersprogram/LOAD_DATA',
      })
    }

    if (std) {
      dispatch({
        type: 'learnersprogram/SET_STATE',
        payload: {
          SelectedLearnerId: std,
        },
      })
      dispatch({
        type: 'student/STUDENT_DETAILS',
        payload: {
          studentId: std,
        },
      })
    } else {
      dispatch({
        type: 'learnersprogram/LOAD_DATA',
      })
    }
  }, [])

  const showDrawerFilter = () => {
    const { role } = user

    if (role === 'parents') {
      setGoalComp(true)
    } else {
      setvisibleFilter(true)
    }
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className="header-max-width">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            minHeight: 50,
            alignItems: 'center',
            padding: '6px',
            margin: '0 auto',
          }}
        >
          <div style={{ minWidth: 'fit-content', display: 'flex' }}>
            <div>{leftContent}</div>
            <div style={{ minWidth: 'fit-content' }}>
              <div
                id="pageHeader"
                style={{
                  fontSize: '24px',
                  color: '#000000',
                  lineHeight: '30px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {title}
              </div>
              <div style={{ fontSize: 14, fontWeight: 400, color: '#777777' }}>
                <Breadcrumb>
                  {showHomePath && (
                    <Breadcrumb.Item key="default">
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                  )}
                  {pathList
                    ?.filter(path => path !== undefined && path != null)
                    .map((path, ind) => {
                      if (pathList[0] === 'Reports' && pathList[1] === 'Celeration Chart') {
                        return (
                          <Breadcrumb.Item key={path + ind}>
                            {path === 'Reports' ? 'Intervention' : path}
                          </Breadcrumb.Item>
                        )
                      }
                      if (typeof path === 'object') {
                        if (path[1]) {
                          return (
                            <Breadcrumb.Item key={path[0] + ind}>
                              <Link to={path[1]}>{path[0]}</Link>
                            </Breadcrumb.Item>
                          )
                        }
                        return <Breadcrumb.Item key={path[0] + ind}>{path[0]}</Breadcrumb.Item>
                      }
                      return <Breadcrumb.Item key={path + ind}>{path}</Breadcrumb.Item>
                    })}
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '24px',
              color: '#000000',
              lineHeight: '30px',
            }}
          >
            {centerContent}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>{rightContent}</div>
            {learnerCard && (
              <div style={{ marginLeft: 20 }} className="learner-mini-parent-header">
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 12 }}>
                  {student.ShortName && (
                    <div
                      className="name-in-profile-pic-header"
                      style={{ backgroundColor: student?.SelectedColor || clr }}
                    >
                      {student.ShortName}
                    </div>
                  )}
                  <div style={{ marginLeft: 10 }}>
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '17px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {student?.StudentName}
                    </div>
                    <div
                      style={{
                        color: '#595959',
                        fontSize: 12,
                        lineHeight: '15px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {student?.StudentDetails?.email}
                    </div>
                  </div>
                </div>
                {changeLearner && user?.role !== 'parents' && (
                  <div style={{ display: 'flex' }}>
                    <div>
                      <EyeComponent mini showDrawerFilter={showDrawerFilter} />
                    </div>
                    <Button
                      style={{
                        background: '#FAF8F8',
                        border: '1px solid #34A770',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: '#34A770',
                      }}
                      onClick={() => setvisibleFilter(true)}
                    >
                      Change
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Drawer
          visible={visibleFilter}
          onClose={() => setvisibleFilter(false)}
          width={student?.DrawerWidth}
          title="Select Learner"
          placement="right"
          bodyStyle={{ backgroundColor: COLORS.bodyGrey }}
        >
          <LearnerSelect />
        </Drawer>
      </div>
    </div>
  )
}

HeaderComponent.propTypes = {
  pathList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  ),
}

HeaderComponent.defaultProps = {
  pathList: [],
}

export default HeaderComponent
