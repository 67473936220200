import React from 'react'
import { LocaleProvider, ConfigProvider } from 'antd'
import { IntlProvider, addLocaleData } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import english from 'locales/en-US'
import french from 'locales/fr-FR'
import russian from 'locales/ru-RU'
import chinese from 'locales/zh-CN'

addLocaleData(english.localeData)
addLocaleData(french.localeData)
addLocaleData(russian.localeData)
addLocaleData(chinese.localeData)

const locales = {
  'en-US': english,
  'fr-FR': french,
  'ru-RU': russian,
  'zh-CN': chinese,
}
const Localization = ({ children }) => {
  const { locale } = useSelector(state => state.settings)
  const currentLocale = locales[locale]
  return (
    <ConfigProvider locale={currentLocale.antdData}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}
export default Localization
