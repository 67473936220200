/* eslint-disable no-nested-ternary */
import React from 'react'
import { Form, Button, Input, DatePicker } from 'antd'
import moment from 'moment'
import '../style.scss'
import { SUBMITT_BUTTON } from 'assets/styles/globalStyles'

const PhaseModal = ({ form, phaseList, phaseObject, setPhaseList, date, closeModal, submit }) => {
  const edit = !!phaseObject

  function disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  const SubmitForm = e => {
    e.preventDefault()
    form.validateFields((err, value) => {
      if (!err) {
        const k = phaseList.reduce((prevVal, currVal) => Math.max(prevVal, currVal.key), 0)
        const temp = [...phaseList, { ...value, key: k + 1 }]
        setPhaseList(temp)
        if (submit) {
          submit(temp)
        }
        if (closeModal) {
          closeModal()
        }
      }
    })
  }

  const updateForm = e => {
    e.preventDefault()
    form.validateFields((err, value) => {
      if (!err) {
        const temp = phaseList.map(item => {
          if (item.key === phaseObject.key) {
            return { ...item, ...value }
          }
          return item
        })
        setPhaseList(temp)
        if (submit) {
          submit(temp)
        }
        if (closeModal) {
          closeModal()
        }
      }
    })
  }

  return (
    <Form name="phaseForm" layout="vertical" style={{ padding: '12px 48px' }}>
      <Form.Item label="Date" name="Date">
        {form.getFieldDecorator('date', {
          initialValue: date || edit ? (edit ? moment(phaseObject.date) : moment(date)) : moment(),
          rules: [{ required: true, message: 'Please provide date' }],
        })(
          <DatePicker
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            style={{ width: '40%' }}
            placeholder="Date"
          />,
        )}
      </Form.Item>
      <Form.Item label="Note" key="note">
        {form.getFieldDecorator(`note`, {
          initialValue: edit ? phaseObject.note : '',
          rules: [{ required: true, message: 'Please give note or delete this field.' }],
        })(<Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} rows={2} placeholder="Note" />)}
      </Form.Item>
      <Form.Item style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
        <Button
          onClick={edit ? updateForm : SubmitForm}
          type="primary"
          style={{ ...SUBMITT_BUTTON, margin: 0 }}
        >
          {edit ? 'Update' : 'Submit'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create()(PhaseModal)
