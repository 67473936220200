/* eslint-disable react/no-unused-state */

import React, { createRef, useState } from 'react'
import { Icon, Button, Switch, Modal, Typography } from 'antd'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import Timer from 'react-compound-timer'
import './style.scss'

const { Title, Text } = Typography

const Clock = () => {
  const clockRef = createRef()
  const [visible, setVisible] = useState(true)
  const dispatch = useDispatch()
  const { StudentId } = useSelector(state => state.student)
  const { role } = useSelector(state => state.user)
  const {
    SessionStatus,
    ChildSession,
    InitiaModel,
    EndModel,
    MasterSession,
    ViewOnlySession,
  } = useSelector(state => state.sessionrecording)

  const startSession = time => {
    const location = window.navigator && window.navigator.geolocation
    let currentLatitude = ''
    let currentLongitude = ''

    if (location) {
      location.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          currentLatitude = latitude
          currentLongitude = longitude
        },
        error => {
          console.log('error getting logitute and latitude')
        },
      )
    }
    clockRef.current.start()
    dispatch({
      type: 'sessionrecording/START_SESSION',
      payload: {
        duration: time.toFixed(),
        startLat: currentLatitude,
        startLong: currentLongitude,
      },
    })
    // enable target recording block
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        Disabled: false,
        InitiaModel: false,
      },
    })
    setVisible(false)
  }

  const pauseSession = time => {
    dispatch({
      type: 'sessionrecording/PAUSE_SESSION',
      payload: {
        duration: time.toFixed(),
      },
    })
    dispatch({
      type: 'sessionrecording/GET_TODAYS_SESSION',
      payload: {
        studentId: StudentId,
        date: moment().format('YYYY-MM-DD'),
      },
    })
    // disable target recording block
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        Disabled: true,
      },
    })
  }

  const resumeSession = time => {
    dispatch({
      type: 'sessionrecording/RESUME_SESSION',
      payload: {
        duration: time.toFixed(),
      },
    })
    // enable target recording block
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        Disabled: false,
        InitiaModel: false,
      },
    })

    setVisible(false)
  }

  const endSession = time => {
    const location = window.navigator && window.navigator.geolocation
    let currentLatitude = ''
    let currentLongitude = ''
    if (location) {
      location.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          currentLatitude = latitude
          currentLongitude = longitude
        },
        error => {
          console.log('error while getting logitude and latitude')
        },
      )
    }
    dispatch({
      type: 'sessionrecording/END_SESSION',
      payload: {
        duration: time.toFixed(),
        endLat: currentLatitude,
        endLong: currentLongitude,
      },
    })
    dispatch({
      type: 'sessionrecording/GET_TODAYS_SESSION',
      payload: {
        studentId: StudentId,
        date: moment().format('YYYY-MM-DD'),
      },
    })

    // disable target recording block
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        Disabled: true,
      },
    })
  }

  const updateTimeInReduxStore = time => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        CurrentSessionTime: time.toFixed(),
      },
    })
  }

  const updateStartTrialInReduxStore = time => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TrialStartTime: time.toFixed(),
      },
    })
  }

  const editToggle = checked => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        EditAfterSessionCompleted: checked,
        Disabled: false,
      },
    })
  }

  const handleCancel = () => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        InitiaModel: false,
      },
    })
  }

  const handleEndSessionCancel = () => {
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        EndModel: false,
      },
    })
  }

  return (
    <Timer
      id="sessionClockTimer"
      ref={clockRef}
      initialTime={ChildSession && ChildSession.duration ? ChildSession.duration : 0}
      startImmediately={false}
    >
      {({ start, resume, pause, stop, reset, getTime }) => (
        <React.Fragment>
          <div className="clock-box">
            <div className="clock-session-title">
              {MasterSession?.sessionName.name} Session
              <span style={{ textAlign: 'center', marginLeft: 10 }}>
                <Button
                  id="updateSessionCurrentTimeInStore"
                  onClick={() => updateTimeInReduxStore(getTime())}
                  style={{ display: 'hidden', border: 'none', height: 1 }}
                >
                  &nbsp;
                </Button>
                <Button
                  id="updateStartTrialTimeInStore"
                  onClick={() => updateStartTrialInReduxStore(getTime())}
                  style={{ display: 'hidden', border: 'none', height: 1 }}
                >
                  &nbsp;
                </Button>
              </span>
            </div>
            <div className="clock-div">
              <div className="clock-sec-box">
                <Timer.Seconds
                  formatValue={value => (
                    <p className="clock-digit">{value < 10 ? `0${value}` : value}</p>
                  )}
                />
                <p className="clock-digit-text">Sec</p>
              </div>

              <div className="clock-min-box">
                <Timer.Minutes
                  formatValue={value => (
                    <p className="clock-digit">{value < 10 ? `0${value}` : value}</p>
                  )}
                />
                <p className="clock-digit-text">Min</p>
              </div>
              <div className="clock-hr-box">
                <Timer.Hours
                  formatValue={value => (
                    <p className="clock-digit">{value < 10 ? `0${value}` : value}</p>
                  )}
                />
                <p className="clock-digit-text">Hour</p>
              </div>
            </div>
            {!ViewOnlySession && (
              <div style={{ textAlign: 'center' }}>
                {SessionStatus === 'Pending' && (
                  <Button
                    onClick={() => {
                      start()
                      startSession(getTime())
                    }}
                    className="resume-button"
                  >
                    <Icon type="caret-right" />
                    Start Session
                  </Button>
                )}

                {SessionStatus === 'InProgress' && (
                  <>
                    <Button
                      onClick={() => {
                        pauseSession(getTime())
                        stop()
                      }}
                      className="pause-button"
                    >
                      Pause
                    </Button>
                    &nbsp;
                    <Button
                      onClick={() => {
                        endSession(getTime())
                        stop()
                      }}
                      className="end-button"
                    >
                      End
                    </Button>
                  </>
                )}
                {SessionStatus === 'Paused' && (
                  <>
                    <Button
                      onClick={() => {
                        start()
                        resumeSession(getTime())
                      }}
                      className="resume-button"
                    >
                      <Icon type="caret-right" />
                      Resume Session
                    </Button>
                  </>
                )}
                {SessionStatus === 'Completed' && (
                  <>
                    <p style={{ marginTop: 3 }}>Session Completed!!</p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <a href="/#/sessionsummary">Session Summary</a>
                      {role !== 'parents' && (
                        <>
                          <b>Edit Session: </b>&nbsp; &nbsp;
                          <Switch
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />}
                            onChange={checked => editToggle(checked)}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Modal visible={InitiaModel} footer={null} onCancel={handleCancel}>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ textAlign: 'center', fontSize: 50 }}>
                      <Timer.Hours
                        formatValue={value => `${value < 10 ? `0${value}` : value} : `}
                      />
                      <Timer.Minutes
                        formatValue={value => `${value < 10 ? `0${value}` : value} : `}
                      />
                      <Timer.Seconds
                        formatValue={value => `${value < 10 ? `0${value}` : value} `}
                      />
                    </span>
                    <br />
                    <br />
                    {SessionStatus === 'Pending' && (
                      <Button
                        onClick={() => {
                          // start()
                          startSession(getTime())
                        }}
                      >
                        <Icon type="caret-right" />
                        Start Session
                      </Button>
                    )}

                    {SessionStatus === 'Paused' && (
                      <>
                        <Button
                          onClick={() => {
                            start()
                            resumeSession(getTime())
                          }}
                        >
                          <Icon type="caret-right" />
                          Resume Session
                        </Button>
                      </>
                    )}
                  </div>
                </Modal>
                <Modal visible={EndModel} footer={null} onCancel={handleEndSessionCancel}>
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ textAlign: 'center', fontSize: 50 }}>
                      <Timer.Hours
                        formatValue={value => `${value < 10 ? `0${value}` : value} : `}
                      />
                      <Timer.Minutes
                        formatValue={value => `${value < 10 ? `0${value}` : value} : `}
                      />
                      <Timer.Seconds
                        formatValue={value => `${value < 10 ? `0${value}` : value} `}
                      />
                    </span>
                    <br />
                    <br />
                    <p>No targets left in the list..</p>
                    <br />
                    {SessionStatus === 'InProgress' && (
                      <>
                        <Button
                          onClick={() => {
                            endSession(getTime())
                            stop()
                          }}
                        >
                          End Session
                        </Button>
                      </>
                    )}
                  </div>
                </Modal>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </Timer>
  )
}

export default Clock
