/* eslint-disable */
import { ResponsiveLine } from '@nivo/line'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, notification, Tooltip, Switch } from 'antd'
import { DRAWER, COLORS } from 'assets/styles/globalStyles'
import { useSelector, useDispatch } from 'react-redux'
import LoadingComponent from 'components/LoadingComponent'
import _, { uniqBy } from 'lodash'
import html2canvas from 'html2canvas'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaDownload } from 'react-icons/fa'
import EditableSessionTable from './editableSessionTable'
import DailyResponseRatePdf from './DailyResponseRatePdf'
import SbtSessionTable from './sbtSessionTable'
import PhaseTable from './PhaseTable'
import RangeDatePicker from '../../components/DatePicker/DatePicker'
import HeaderComponent from 'components/HeaderComponentNew'
import RangeDatePickerForGraph from 'components/DatePicker/DatePickerForGraph'

const { RangePicker } = DatePicker

const parentDiv = { display: 'flex', margin: '5px 30px 5px 0' }
const dateFormat = 'YYYY-MM-DD'
const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }

const color = {
  master: '#008000', // green
  baseline: '#FF0000', // red
  therapy: '#FFA500', // orange
  inmaintainence: '#DC143C', // dark red
}
const status = {
  master: 'Master',
  baseline: 'Baseline',
  therapy: 'Therapy',
  inmaintainence: 'In-Maintainence',
}
const position = {
  master: 'top',
  baseline: 'top-left',
  therapy: 'center',
  inmaintainence: 'top-right',
}

const posMastery = ['top-right', 'top-left', 'bottom-left', 'bottom-right']

function ResponseRateGraph({
  graphData,
  dateRange,
  setDateRange,
  defaultRange,
  setDefaulRange,
  selectedTarget,
  loading,
  marker,
  getNextTarget,
  getPrevTarget,
  nextDisable,
  prevDisable,
  closeDrawer,
  mastery,
  sdPeak,
}) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { defaultDateRange, defaultDateDRR } = user
  let i = 0
  const [peakSd, setPeakSd] = useState(null)
  const [date, setDate] = useState(moment())
  const [showSessionTableDrawer, setShowSessionTableDrawer] = useState(false)
  const [allocatedDate, setAllocatedDate] = useState(null)
  const [lineChartImage, setLineChartImage] = useState('')
  const [isResponsePdf, setIsResponsePdf] = useState(false)
  const [phaseLine, setPhaseLine] = useState(true)
  const [phaseData, setPhaseData] = useState([])
  const [markers, setMarker] = useState([])
  const [masteryMarker, setMasteryMarker] = useState([])
  const [clickedPoint, setClickedPoint] = useState(null)
  const [clickedPointMarker, setClickedPointMarker] = useState([])
  const [hiddenIds, setHiddenIds] = useState([])
  const [colorsArray, setColorsArray] = useState([
    '#774dd7',
    '#028ee6',
    '#00CED1',
    '#2E5894',
    '#3F00FF',
    '#6495ED',
    '#1560bd',
    '#00308F',
    '#48D1CC',
  ])

  useEffect(() => {
    if (marker) {
      setMarker(marker)
    }
  }, [marker])

  useEffect(() => {
    if (mastery) {
      const temp = []
      const {
        masteryType,
        noOfProblemBehavior,
        gte,
        lte,
        consecutiveDays,
        minTrial,
        fromStatus,
        toStatus,
      } = mastery
      const obj = {
        axis: 'y',
        value: noOfProblemBehavior,
        lineStyle: { stroke: COLORS.danger, strokeWidth: 3 },
        legend: `No of ProblemBehavior >=${gte} over ${consecutiveDays} Days with Min.Trial : ${minTrial}, From ${fromStatus?.statusName} to ${toStatus?.statusName}`,
        legendPosition: posMastery[Math.floor(Math.random() * posMastery.length)],
        textStyle: { fill: COLORS.danger, fontSize: 16, fontWeight: 'bold' },
      }
      temp.push(obj)
      // const status = mastery?.criteriaType?.statusName
      // mastery.criteriaphasetargetsSet.edges.map(({ node }) => {
      //   const {
      //     calculationType,
      //     percentage,
      //     calFrameType,
      //     calFrameDurationType,
      //     calFrameDurationValue,
      //     phaseChangeLabel,
      //     minimumTrial,
      //   } = node
      //   const obj = {
      //     axis: 'y',
      //     value: percentage,
      //     lineStyle: { stroke: COLORS.danger, strokeWidth: 3 },
      //     legend: `Response(%)${calculationType} ${percentage}% over ${calFrameDurationValue} ${calFrameType} ${calFrameDurationType},${status} to ${phaseChangeLabel},Min. Trial:${minimumTrial}`,
      //     legendPosition: 'top-right',
      //     textStyle: { fill: COLORS.danger, fontSize: 16, fontWeight: 'bold' },
      //   }
      //   temp.push(obj)
      // })
      setMasteryMarker(temp)
    }
  }, [mastery])

  const handlePointClick = pt => {
    if (pt && pt.data) {
      const clickedDate = moment(pt.data.date).format(dateFormat)
      setClickedPoint(clickedDate)
      if (clickedDate >= allocatedDate && clickedDate <= moment().format(dateFormat)) {
        setDate(moment(pt.data.date))
        setPeakSd(pt.data.type === 'sd' ? pt.data.target : null)
        setShowSessionTableDrawer(true)
      } else {
        notification.info({
          message: `No session found on ${clickedDate}!`,
          description:
            'Date shuold be not greater then today or not less then the target valid from date!! ',
        })
      }
    }
  }

  useEffect(() => {
    if (selectedTarget && selectedTarget.date) {
      if (moment(selectedTarget.date)) {
        setAllocatedDate(moment(selectedTarget.date).format('YYYY-MM-DD'))
      }
    }
  }, [selectedTarget])

  const getLineChartImage = () => {
    const item = document.getElementById('lineGraph')
    if (item) {
      const promises = []
      const ff = html2canvas(item).then(canvas => canvas.toDataURL('image/png'))
      ff.then(res => {
        setLineChartImage(res)
        setIsResponsePdf(true)
      })
    }
  }

  const handleLineChartPdf = () => {
    getLineChartImage()
  }

  const handlePhaseLine = checked => {
    setPhaseLine(checked)
  }

  const hidePdfDrawer = () => {
    setIsResponsePdf(false)
    setLineChartImage('')
  }

  const handleData = data => {
    const temp = { ...markers, phaseSet: data }
    setMarker(temp)
  }

  const disabledDate = current => {
    if (allocatedDate) {
      if (
        current.format(dateFormat) <
        moment(allocatedDate)
          .subtract(1, 'd')
          .format(dateFormat)
      ) {
        return true
      }
    }
    return false
  }

  const toggle = d => {
    if (hiddenIds.includes(d.id)) {
      const f = hiddenIds.filter(id => id !== d.id)
      setHiddenIds(f)
    } else {
      setHiddenIds([...hiddenIds, d.id])
    }
  }

  const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
    <g>
      <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
      {/* <circle
        r={size / 5}
        strokeWidth={borderWidth}
        stroke={borderColor}
        fill={color}
        fillOpacity={0.35}
      /> */}
    </g>
  )

  const CustomSymbolShape = ({ x, y, size, fill, borderWidth, borderColor }) => (
    <rect
      x={x}
      y={y}
      transform={`rotate(45 ${size / 2} ${size / 2})`}
      fill={fill}
      strokeWidth={borderWidth}
      stroke={borderColor}
      width={size}
      height={size}
      style={{ pointerEvents: 'none' }}
    />
  )

  // const colorsArray = [
  //   '#774dd7',
  //   '#028ee6',
  //   '#00CED1',
  //   '#2E5894',
  //   '#3F00FF',
  //   '#6495ED',
  //   '#1560bd',
  //   '#00308F',
  //   '#48D1CC',
  // ]

  const DateChangeDropDown = date => {
    setDateRange(date)
  }

  if (loading || graphData === null) {
    return <LoadingComponent />
  }
  return (
    <div>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ ...parentDiv }}>
          <span style={parentLabel}>Date:</span>
          <RangeDatePickerForGraph
            date={dateRange}
            disabledDate={disabledDate}
            disableRedux
            rangeType={defaultDateRange}
            setDate={v => {
              setDateRange(v)
            }}
            from="DRR_GRAPH"
          />
          <span
            style={{
              ...parentLabel,
              color: phaseLine ? '#1890FF' : '#BDBDBD',
              fontWeight: '600',
            }}
          >
            Manual PhaseLines:
          </span>
          <Switch style={{ marginTop: 5 }} checked={phaseLine} onChange={handlePhaseLine} />
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Previous Target">
            <Button disabled={prevDisable} onClick={() => getPrevTarget(selectedTarget)}>
              <LeftOutlined /> Prev
            </Button>
          </Tooltip>
          <Tooltip title="Next Target">
            <Button disabled={nextDisable} onClick={() => getNextTarget(selectedTarget)}>
              Next <RightOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Download Graph">
            <Button onClick={handleLineChartPdf} type="link">
              <FaDownload fontSize="22px" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <span
        style={{
          ...parentLabel,
          color: '#000',
          fontWeight: '600',
          margin: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: 'white',
          padding: 10,
        }}
      >
        Target Valid From : {selectedTarget && moment(selectedTarget?.date).format('YYYY-MM-DD')}
      </span>
      <div id="lineGraph" style={{}}>
        {graphData &&
          graphData.map(item => {
            i += 1
            const temp = []
            const clicked = []
            const selectedRangeStart = moment(dateRange[0]).format('YYYY-MM-DD')
            const selectedRangeEnd = moment(dateRange[1]).format('YYYY-MM-DD')
            markers.dateStatus &&
              markers.dateStatus.edges.length &&
              markers.dateStatus.edges.map(({ node }) => {
                if (
                  item.length > 0 &&
                  moment(node.date).format('MMM') === item[0].month &&
                  moment(node.date).format('YYYY') === item[0].year
                ) {
                  const pos = _.lowerCase(node.targetStatusId?.statusName)
                  const obj = {
                    axis: 'x',
                    value: moment(node.date).format('DD'),
                    lineStyle: { stroke: COLORS[node.targetStatusId?.colorCode], strokeWidth: 2 },
                    legend: node.targetStatusId?.statusName,
                    legendPosition: position[pos],
                    legendOrientation: 'vertical',

                    textStyle: {
                      fill: COLORS[node.targetStatusId?.colorCode],
                      fontSize: 16,
                      fontWeight: 'bold',
                    },
                  }
                  if (
                    moment(node.date).isBetween(selectedRangeStart, selectedRangeEnd, null, '[]')
                  ) {
                    temp.push(obj)
                  }
                  // setPhaseData(old=>[...old,obj])
                }
              })
            _.forIn(marker, (value, key) => {
              if (value !== undefined) {
                if (item.length > 0 && key === 'phaseSet' && phaseLine && value.length !== 0) {
                  value.map(p => {
                    if (
                      item.length > 0 &&
                      moment(p.node.date).format('MMM') === item[0].month &&
                      moment(p.node.date).format('YYYY') === item[0].year
                    ) {
                      const obj = {
                        axis: 'x',
                        value: moment(p.node.date).format('DD'),
                        lineStyle: { stroke: '#000000', strokeWidth: 2 },
                        legend: p.node.content,
                        legendPosition: 'top',
                        textStyle: { fill: 'black', fontSize: 16, fontWeight: 'bold' },
                      }
                      if (
                        moment(p.node.date).isBetween(
                          selectedRangeStart,
                          selectedRangeEnd,
                          null,
                          '[]',
                        )
                      ) {
                        temp.push(obj)
                      }
                    }
                    return p
                  })
                }
                // if (
                //   value &&
                //   item.length > 0 &&
                //   (key === 'inmaintainence' ||
                //     key === 'baseline' ||
                //     key === 'therapy' ||
                //     key === 'master') &&
                //   moment(value).format('MMM') === item[0].month &&
                //   moment(value).format('YYYY') === item[0].year
                // ) {
                //   const obj = {
                //     axis: 'x',
                //     value: moment(value).format('DD'),
                //     lineStyle: { stroke: color[key], strokeWidth: 2 },
                //     legend: status[key],
                //     legendPosition: position[key],
                //   }

                //   temp.push(obj)
                // }
              }
              if (
                item.length > 0 &&
                clickedPoint &&
                moment(clickedPoint).format('MMM') === item[0].month &&
                moment(clickedPoint).format('YYYY') === item[0].year
              ) {
                const obj = {
                  axis: 'x',
                  value: moment(clickedPoint).format('DD'),
                  lineStyle: {
                    stroke: COLORS.lightGreen,
                    strokeWidth: 4,
                    strokeDasharray: '2,4',
                    fill: 'none',
                  },
                  legend: `Clicked`,
                  legendOrientation: 'vertical',
                  legendPosition: 'top-right',
                  textStyle: { fill: 'green', fontSize: 12, fontWeight: 'bold' },
                }

                // setPhaseData(old=>[...old,obj])

                clicked.push(obj)
              }
            })

            const hiddenFilter = item.map(x => {
              if (!hiddenIds.includes(x.id)) {
                return x
              } else {
                return {
                  ...x,
                  data: [],
                }
              }
            })
            return (
              <div
                key={`item ${i}`}
                style={{
                  height: 550,
                  marginBottom: 30,
                  paddingBottom: 10,
                  backgroundColor: 'white',
                }}
              >
                <div style={{ width: '100%', height: '100%' }}>
                  <ResponsiveLine
                    data={hiddenFilter}
                    margin={{ top: 40, right: 300, bottom: 200, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                      type: 'linear',
                      min: 'auto',
                      max: 'auto',
                      stacked: false,
                      reverse: false,
                    }}
                    // enableSlices="x"
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    onClick={point => handlePointClick(point)}
                    axisBottom={{
                      orient: 'bottom',
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: `Date (${item[0].month} ${item[0].year})`,
                      legendOffset: 36,
                      legendPosition: 'middle',
                    }}
                    axisLeft={{
                      orient: 'left',
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Percentage',
                      legendOffset: -40,
                      legendPosition: 'middle',
                    }}
                    colors={{ scheme: 'category10' }}
                    lineWidth={3}
                    pointSize={10}
                    // pointSymbol={CustomSymbol}
                    pointBorderWidth={3}
                    pointBorderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.3]],
                    }}
                    pointLabelYOffset={-12}
                    enableGridX={false}
                    useMesh
                    markers={[...temp, ...masteryMarker, ...clicked]}
                    // sliceTooltip={({ slice }) => {
                    //   return (
                    //     <div
                    //       style={{
                    //         background: 'white',
                    //         padding: '9px 12px',
                    //         border: '1px solid #ccc',
                    //       }}
                    //     >
                    //       {slice.points.map(point => (
                    //         <>
                    //           <div
                    //             key={point.id}
                    //             style={{
                    //               // color: point.serieColor,
                    //               padding: '3px 0',
                    //               display: 'flex',
                    //             }}
                    //           >
                    //             <div
                    //               style={{
                    //                 backgroundColor: point.serieColor,
                    //                 width: 12,
                    //                 height: 12,
                    //                 marginTop: 5,
                    //                 marginRight: 5,
                    //               }}
                    //             ></div>
                    //             <strong>
                    //               {point.serieId}: {point.data.y}%, Total Trials: {point.data.total}
                    //             </strong>
                    //           </div>
                    //         </>
                    //       ))}
                    //     </div>
                    //   )
                    // }}

                    legends={[
                      {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateY: 0,
                        translateX: 25,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 10,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        onClick: data => {
                          toggle(data)
                          // const colors = hiddenFilter.map(series => (series.id === data.id ? '#ff3344' : '#DDD'));
                          // setColorsArray(colors)
                        },
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemBackground: 'rgba(0, 0, 0, .03)',
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </div>
            )
          })}
      </div>

      <Drawer
        title={selectedTarget?.targetAllcatedDetails?.targetName}
        placement="right"
        width={DRAWER.widthL2}
        closable
        destroyOnClose
        visible={showSessionTableDrawer}
        onClose={() => {
          if (closeDrawer) {
            closeDrawer()
          }
          setShowSessionTableDrawer(false)
        }}
      >
        <HeaderComponent title={''} showHomePath={false} learnerCard changeLearner={false} />
        <SbtSessionTable date={date} peakSd={peakSd} selectedTarget={selectedTarget} />
        <PhaseTable
          date={date}
          phaseData={phaseData}
          setPhaseData={data => handleData(data)}
          selectedTarget={selectedTarget}
        />
      </Drawer>

      <Drawer
        title={`${graphData && graphData[0] && graphData[0][0].id} Pdf`}
        placement="right"
        width={DRAWER.widthL2}
        closable
        onClose={hidePdfDrawer}
        visible={isResponsePdf}
      >
        {lineChartImage && (
          <DailyResponseRatePdf
            dateRange={dateRange}
            peakType={selectedTarget?.peakType}
            type={selectedTarget?.targetAllcatedDetails.targetType?.typeTar}
            targetStatus={selectedTarget?.targetStatus?.statusName}
            lineChartImage={lineChartImage}
            subTitle={sdPeak}
            target={selectedTarget}
          />
        )}
      </Drawer>
    </div>
  )
}

export default ResponseRateGraph
