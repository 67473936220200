import React from 'react'
import { FORM, SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import { Form, Input, Select, Button, notification } from 'antd'
import { useMutation } from 'react-apollo'
import { CREATE_MASTERY_SEQ_TEMPLATE, UPDATE_MASTERY_SEQ_TEMPLATE } from './query'

const { layout2 } = FORM
const { Item } = Form
const { Option } = Select
const { TextArea } = Input

function CreateSeqTemplate({ edit, currentTemplate, form, schoolId, closeModal, onSuccess }) {
  const { getFieldDecorator, validateFields, resetFields } = form

  const [createSeqTemplate, { loading: createLoading }] = useMutation(CREATE_MASTERY_SEQ_TEMPLATE)
  const [updateSeqTemplate, { loading: updateLoading }] = useMutation(UPDATE_MASTERY_SEQ_TEMPLATE)

  console.log('current Template>>>', currentTemplate, edit)

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((error, values) => {
      if (!error && schoolId) {
        createSeqTemplate({
          variables: {
            name: values.name,
            templateType: values.templateType,
            description: values.description,
            clinicId: schoolId,
          },
        })
          .then(res => {
            notification.success({
              message: 'Success',
              description: 'Template created successfully.',
            })
            if (onSuccess) {
              onSuccess()
            }
            resetFields()
            if (closeModal) {
              closeModal()
            }
          })
          .catch(err => console.error(err))
      }
    })
  }

  const handleEdit = e => {
    e.preventDefault()

    validateFields((error, values) => {
      if (!error && currentTemplate?.id) {
        updateSeqTemplate({
          variables: {
            id: currentTemplate.id,
            name: values.name,
            description: values.description,
          },
        })
          .then(res => {
            notification.success({
              message: 'Success',
              description: 'Template updated successfully.',
            })
            if (onSuccess) {
              onSuccess()
            }
            resetFields()
            if (closeModal) {
              closeModal()
            }
          })
          .catch(err => console.error(err))
      }
    })
  }

  return (
    <div>
      <Form onSubmit={edit ? handleEdit : handleSubmit} {...layout2}>
        <Item label="Template Name">
          {getFieldDecorator('name', {
            initialValue: edit ? currentTemplate.name : '',
            rules: [{ required: true, message: 'Template name is required' }],
          })(<Input allowClear placeholder="Enter template name" />)}
        </Item>
        <Item label="Template Type">
          {getFieldDecorator('templateType', {
            initialValue: edit ? currentTemplate.templateType : 'Regular',
            rules: [{ required: true, message: 'Please provide template type' }],
          })(
            <Select disabled={edit} placeholder="Select template type">
              <Option key="Regular" value="Regular">
                Acquisition
              </Option>
              <Option key="Behavior Recording Frequency" value="Behavior Recording Frequency">
                Behavior Recording Frequency
              </Option>
              <Option key="Behavior Recording Duration" value="Behavior Recording Duration">
                Behavior Recording Duration
              </Option>
            </Select>,
          )}
        </Item>
        <Item label="Template Description">
          {getFieldDecorator('description', {
            initialValue: edit ? currentTemplate.description : '',
          })(<TextArea rows={4} allowClear placeholder="Enter template description" />)}
        </Item>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            loading={createLoading || updateLoading}
            htmlType="submit"
            style={{ ...SUBMITT_BUTTON, margin: 0 }}
          >
            {edit ? 'Update' : 'Submit'}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default Form.create()(CreateSeqTemplate)
