const actions = {
  SET_STATE: 'course/SET_STATE',
  GET_COURSE_TEMPLATES: 'course/GET_COURSE_TEMPLATES',
  GET_COURSE_BY_ID: 'course/GET_COURSE_BY_ID',
  GET_CURRENCY: 'course/GET_CURRENCY',
  CREATE_COURSE_TEMPLATES: 'course/CREATE_COURSE_TEMPLATES',
  UPDATE_COURSE_TEMPLATE: 'course/UPDATE_COURSE_TEMPLATE',
  DELETE_COURSE_TEMPLATE: 'course/DELETE_COURSE_TEMPLATE',
  APPEND_COURSE_TEMPLATE: 'course/APPEND_COURSE_TEMPLATE',
  REPLACE_COURSE_TEMPLATE: 'course/REPLACE_COURSE_TEMPLATE',
  POP_COURSE_TEMPLATE: 'course/POP_COURSE_TEMPLATE',

  GET_PUBLIC_COURSE_TEMPLATES: 'course/GET_PUBLIC_COURSE_TEMPLATES',

  CREATE_COURSE_MODULE: 'course/CREATE_COURSE_MODULE',
  UPDATE_COURSE_MODULE: 'course/UPDATE_COURSE_MODULE',
  DELETE_COURSE_MODULE: 'course/DELETE_COURSE_MODULE',
  APPEND_COURSE_MODULE: 'course/APPEND_COURSE_MODULE',
  REPLACE_COURSE_MODULE: 'course/REPLACE_COURSE_MODULE',
  POP_COURSE_MODULE: 'course/POP_COURSE_MODULE',

  CREATE_MODULE_CONTENT: 'course/CREATE_MODULE_CONTENT',
  UPDATE_MODULE_CONTENT: 'course/UPDATE_MODULE_CONTENT',
  DELETE_MODULE_CONTENT: 'course/DELETE_MODULE_CONTENT',
  APPEND_MODULE_CONTENT: 'course/APPEND_MODULE_CONTENT',
  REPLACE_MODULE_CONTENT: 'course/REPLACE_MODULE_CONTENT',
  POP_MODULE_CONTENT: 'course/POP_MODULE_CONTENT',

  CREATE_MODULE_TEST: 'course/CREATE_MODULE_TEST',
  UPDATE_MODULE_TEST: 'course/UPDATE_MODULE_TEST',
  DELETE_MODULE_TEST: 'course/DELETE_MODULE_TEST',
  APPEND_MODULE_TEST: 'course/APPEND_MODULE_TEST',
  REPLACE_MODULE_TEST: 'course/REPLACE_MODULE_TEST',
  POP_MODULE_TEST: 'course/POP_MODULE_TEST',

  CREATE_TEST_QUESTION: 'course/CREATE_TEST_QUESTION',
  UPDATE_TEST_QUESTION: 'course/UPDATE_TEST_QUESTION',
  DELETE_TEST_QUESTION: 'course/DELETE_TEST_QUESTION',
  APPEND_TEST_QUESTION: 'course/APPEND_TEST_QUESTION',
  REPLACE_TEST_QUESTION: 'course/REPLACE_TEST_QUESTION',
  POP_TEST_QUESTION: 'course/POP_TEST_QUESTION',

  GET_DASHBOARD: 'course/GET_DASHBOARD',
}

export default actions
