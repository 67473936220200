import actions from './actions'

const initialState = {
  Loading: false,
  Learners: [],
  InActiveLearners: [],
  LearnersStatusFilter: 'active',
  CloneLearners: [],
  ProgramAreas: [],
  SelectedLearnerId: '',
  TabCheck: 'Assessments',
  LearnerDetailsLoading: false,
  LearnersFetched: false,
  LearnerDetails: null,
  LearnerSessionLoading: false,
  LearnerSessions: [],
  LearnerChildSessions: [],
}

export default function goalsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
