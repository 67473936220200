import React, { useState, useEffect } from 'react'
import { Switch, Icon } from 'antd'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-apollo'
import { useSelector } from 'react-redux'

const USER_SETTINGS = gql`
  query UserSettings($id: ID!) {
    userSettings(user: $id) {
      edges {
        node {
          id
          peakAutomaticBlocks
          taskAnalysisStatuswise
          user {
            id
          }
        }
      }
    }
  }
`

const UPDATE_PEAK_AUTOMATIC = gql`
  mutation updatePeakAutomatic($userId: ID!, $updatedValue: Boolean) {
    changeUserSetting(input: { user: $userId, peakAutomaticBlocks: $updatedValue }) {
      details {
        id
        peakAutomaticBlocks
        user {
          id
        }
      }
    }
  }
`

const UPDATE_TA_STATUSWISE = gql`
  mutation updatePeakAutomatic($userId: ID!, $updatedValue: Boolean) {
    changeUserSetting(input: { user: $userId, taskAnalysisStatuswise: $updatedValue }) {
      details {
        id
        taskAnalysisStatuswise
        user {
          id
        }
      }
    }
  }
`

const PeakAutomaticTab = () => {
  const [peakAutomatic, setPeakAutomatic] = useState(false)
  const [TAStatusWise, setTAStatusWise] = useState(false)
  const reduxUser = useSelector(state => state.user)

  const { data: userDetails, loading } = useQuery(USER_SETTINGS, {
    variables: {
      id: reduxUser?.id,
    },
  })

  const [updatePeakAutomatic] = useMutation(UPDATE_PEAK_AUTOMATIC)
  const [updateTAStatuswise] = useMutation(UPDATE_TA_STATUSWISE)

  useEffect(() => {
    console.log(userDetails, 'userDetails')
    if (userDetails && userDetails.userSettings?.edges.length > 0) {
      const settings = userDetails.userSettings.edges[0]?.node
      setPeakAutomatic(settings.peakAutomaticBlocks)
      setTAStatusWise(settings.taskAnalysisStatuswise)
    }
  }, [userDetails])

  const savePeakAutomatic = checked => {
    setPeakAutomatic(checked)
    updatePeakAutomatic({
      variables: {
        userId: reduxUser?.id,
        updatedValue: checked,
      },
    })
  }

  const saveTAStatusWise = checked => {
    setTAStatusWise(checked)
    updateTAStatuswise({
      variables: {
        userId: reduxUser?.id,
        updatedValue: checked,
      },
    })
  }

  const tdStyle = { border: '1px solid #dddddd', padding: 8, textAlign: 'center' }

  return (
    <div className="miscConfigTab" style={{ marginTop: 20 }}>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <table style={{ paddingTop: '2em', backgroundColor: 'white' }}>
          <tbody>
            <tr>
              <td style={{ ...tdStyle, width: 200 }}>
                <p
                  style={{
                    color: '#1C8FFA',
                    fontSize: 15,
                    display: 'block',
                    marginTop: '5px',
                    marginBottom: '5px',
                  }}
                >
                  Coded Automatic
                </p>
              </td>
              <td style={{ ...tdStyle, width: 100 }}>
                <Switch
                  checkedChildren={<Icon type="check" />}
                  checked={peakAutomatic}
                  onChange={savePeakAutomatic}
                  unCheckedChildren={<Icon type="close" />}
                />
              </td>
              <td style={tdStyle}>
                <i>
                  By enabling this, the system will be able to record a random stimulus during a
                  session of coded type target recording.
                </i>
              </td>
            </tr>
            <tr>
              <td style={{ ...tdStyle, width: 200 }}>
                <p
                  style={{
                    color: '#1C8FFA',
                    fontSize: 15,
                    display: 'block',
                    marginTop: '5px',
                    marginBottom: '5px',
                  }}
                >
                  Task Analysis Statuswise
                </p>
              </td>
              <td style={{ ...tdStyle, width: 100 }}>
                <Switch
                  checkedChildren={<Icon type="check" />}
                  checked={TAStatusWise}
                  onChange={saveTAStatusWise}
                  unCheckedChildren={<Icon type="close" />}
                />
              </td>
              <td style={tdStyle}>
                <i>
                  If this is enabled, the system will be able to move on to the next target if the
                  current step is not mastered.
                </i>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}

export default PeakAutomaticTab
