/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-array-constructor */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react'
import { Page, Text, View, Document, Image, Font, PDFViewer } from '@react-pdf/renderer'
import { ToWords } from 'to-words'
import { useQuery } from 'react-apollo'
import moment from 'moment'
import LoadingComponent from 'components/VBMappReport/LoadingComponent'
import QRCode from 'qrcode.react'
import s1 from 'assets/fonts/SourceSerifPro/SourceSerifPro-Regular.ttf'
import s2 from 'assets/fonts/SourceSerifPro/SourceSerifPro-SemiBold.ttf'
import { GET_PAYMENT_DETAILS, GET_INVOICE_DETAIL } from './query'
import logo from '../../images/CogniableLogo.jpeg'

Font.register({
  family: 'Source Serif Pro',
  fonts: [
    { src: s1, fontStyle: 'normal', fontWeight: 'light' },
    { src: s2, fontStyle: 'normal', fontWeight: 'bold' },
  ],
})

// font-family: , serif;
// <link rel="preconnect" href="https://fonts.gstatic.com">
// <link href="https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700&display=swap" rel="stylesheet"></link>

const general = {
  fontSize: '12px',
  fontFamily: 'Source Serif Pro',
  fontWeight: 'normal',
  padding: '5px 8px',
  color: 'black',
}

const sectionMain = {
  width: '100%',
  border: '1px solid black',
  color: 'black',
}
const section = {
  padding: '8px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}
const flexSection = {
  width: '100%',
  display: 'flex',
  color: 'black',
  flexDirection: 'row',
  padding: '5px 8px',
  textAlign: 'left',
}
const dateSection = {
  width: '40%',
  color: 'black',
  fontSize: 12,
  alignSelf: 'flex-start',
  textAlign: 'left',
  fontWeight: '500',
}

const rowStyle = {
  borderBottom: '1px solid black',
  display: 'flex',
  float: 'left',
  color: 'black',
  width: '100%',
  flexDirection: 'row',
}
const qtyCol = {
  ...general,
  display: 'flex',
  width: '18%',
  borderLeft: '1px solid black',
}
const serviceCol = {
  ...general,
  width: '35%',
  borderLeft: '1px solid black',
}
const rightText = { width: '100%', margin: 'auto 0', textAlign: 'right' }
const taxSection = {
  ...general,
  padding: '0',
  width: '40%',
  maxWidth: '50%',
}

function getTotal(subTotal, discount = 0, cgst = 0, sgst = 0, tax = 0) {
  return Number(
    subTotal -
      (subTotal / 100) * parseFloat(discount || 0) +
      (subTotal / 100) * parseFloat(cgst || 0) +
      (subTotal / 100) * parseFloat(sgst || 0) +
      (subTotal / 100) * parseFloat(tax || 0),
  ).toFixed(2)
}

function PrintableInvoice({ invoiceId }) {
  const [subTotal, setSubtotal] = useState(0)
  const [currencyName, setCurrencyName] = useState('INR')
  const [invoice, setInvoice] = useState(null)

  const { data, loading, error } = useQuery(GET_PAYMENT_DETAILS)
  const { data: invoiceData, loading: isInvoiceDataLoading, error: invoiceDataErrors } = useQuery(
    GET_INVOICE_DETAIL,
    {
      variables: {
        id: invoiceId,
      },
      fetchPolicy: 'network-only',
    },
  )

  useEffect(() => {
    if (invoiceData) {
      setInvoice(invoiceData?.invoiceDetail)
      let tempTotal = 0
      invoiceData?.invoiceDetail.invoiceFee.edges.forEach(item => {
        const am = Number(Number(item.node.quantity * item.node.rate).toFixed(2))
        tempTotal += am
        return null
      })
      setSubtotal(Number(Number(tempTotal).toFixed(2)))
      if (invoiceData?.invoiceDetail?.currency) {
        setCurrencyName(invoiceData.invoiceDetail.currency.currency)
      }
    }
  }, [invoiceData])

  useEffect(() => {
    if (error || invoiceDataErrors) {
      return notification.error({
        message: 'Something went wrong',
        description: 'Unable to fetch invoice data',
      })
    }
  }, [error, invoiceDataErrors])

  const toWords = new ToWords({
    localeCode: currencyName === 'INR' ? 'en-IN' : 'en-US',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    },
  })

  if (isInvoiceDataLoading || loading || !invoice) return <LoadingComponent />
  if (error || invoiceDataErrors || !invoiceData)
    return (
      <div style={{ marginTop: 80, marginLeft: 60, fontWeight: 700, fontSize: 18 }}>
        Opps, something went wrong
      </div>
    )

  const total = getTotal(subTotal, invoice.discount, invoice.cgst, invoice.sgst, invoice.tax)

  if (loading) {
    return <LoadingComponent />
  }

  const {
    institutionName,
    streetAddress,
    city,
    state,
    country,
    pincode,
    accountHolderName,
    ifscCode,
    gpay,
    paytm,
    upi,
    bankName,
    gstin,
    accountNo,
  } = data.recievingPaymentDetails

  return (
    <PDFViewer style={{ width: '100%', height: '1200px' }}>
      <Document>
        <Page
          size="A4"
          style={{
            flexDirection: 'row',
            backgroundColor: '#fff',
            padding: 10,
          }}
          wrap={false}
          scale={5}
        >
          <View style={{ width: '100%', height: '100%', margin: 'auto' }}>
            <View style={sectionMain}>
              <View style={{ ...section, height: '120px' }}>
                <Image src={logo} style={{ width: '30%', alignSelf: 'center' }} />
                <View
                  style={{
                    textAlign: 'center',
                    width: '250px',
                    alignSelf: 'center',
                    marginLeft: '20px',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      marginBottom: '4px',
                      width: '100%',
                      alignSelf: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    {institutionName}
                  </Text>
                  <Text
                    style={{
                      marginBottom: '4px',
                      fontSize: 12,
                      width: '100%',
                      alignSelf: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    {streetAddress} {city}, {state} {country?.name}, {pincode}
                  </Text>
                  <Text
                    style={{
                      marginBottom: '4px',
                      fontSize: 12,
                      width: '100%',
                      alignSelf: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    GSTIN {gstin}
                  </Text>
                </View>
                <View
                  style={{
                    textAlign: 'center',
                    width: '200px',
                    alignSelf: 'center',
                  }}
                >
                  <Text>{invoice.invoiceNo}</Text>
                </View>
              </View>
              <View style={{ ...section, height: '120px', padding: '0' }}>
                <View style={{ width: '50%', height: '100%', borderRight: '1px solid black' }}>
                  <View style={{ ...flexSection, paddingBottom: '0' }}>
                    <Text style={{ ...dateSection, fontWeight: 'bold' }}> Bill To</Text>
                    <Text style={{ ...dateSection, width: '60%' }}>
                      {' '}
                      : {invoice.clinic.schoolName}, {invoice.address}
                    </Text>
                  </View>
                  <View style={{ ...flexSection, paddingBottom: '0' }}>
                    <Text style={{ ...dateSection, fontWeight: 'bold' }}>Email</Text>
                    <Text style={{ ...dateSection, width: '60%' }}> : {invoice.email}</Text>
                  </View>
                  <View style={{ ...flexSection, paddingBottom: '0' }}>
                    <Text style={{ ...dateSection, fontWeight: 'bold' }}> Place of supply</Text>
                    <Text style={{ ...dateSection, width: '60%' }}> : {invoice.address}</Text>
                  </View>
                </View>
                <View style={{ width: '50%' }}>
                  <View style={{ ...flexSection, paddingBottom: '0' }}>
                    <Text style={{ ...dateSection, fontWeight: 'bold' }}> #INV</Text>
                    <Text style={{ ...dateSection, width: '60%' }}> : {invoice.invoiceNo}</Text>
                  </View>
                  <View style={{ ...flexSection, paddingBottom: '0' }}>
                    <Text style={{ ...dateSection, fontWeight: 'bold' }}> Issue Date</Text>
                    <Text style={{ ...dateSection, width: '60%' }}> : {invoice.issueDate}</Text>
                  </View>
                  <View style={{ ...flexSection, paddingBottom: '0' }}>
                    <Text style={{ ...dateSection, fontWeight: 'bold' }}> Due Date</Text>
                    <Text style={{ ...dateSection, width: '60%' }}> : {invoice.dueDate}</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  borderBottom: '1px solid black',
                  height: '65px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    ...general,
                    alignSelf: 'flex-start',
                    width: '100px',
                    fontWeight: 'bold',
                  }}
                >
                  Subject
                </Text>
                <Text style={{ ...general, alignSelf: 'flex-start', width: '300px' }}>
                  : Cogniable Service Invoice for &nbsp;
                  {moment(invoice.issueDate).format('MMMM YYYY')}
                </Text>
              </View>
              <View style={{ ...rowStyle, backgroundColor: '#fafafa' }}>
                <Text
                  style={{
                    ...qtyCol,
                    width: '30px',
                    alignSelf: 'flex-start',
                    borderLeft: 'none',
                  }}
                >
                  #
                </Text>
                <Text style={{ ...serviceCol, fontWeight: 'bold' }}>Service</Text>
                <View style={{ ...qtyCol, fontWeight: 'bold' }}>
                  <Text style={rightText}>Quantity</Text>
                </View>
                <View style={{ ...qtyCol, fontWeight: 'bold' }}>
                  <Text style={rightText}>Rate ({currencyName})</Text>
                </View>
                <View style={{ ...qtyCol, fontWeight: 'bold', width: '24%' }}>
                  <Text style={rightText}>Amount ({currencyName})</Text>
                </View>
              </View>
              {invoice.invoiceFee.edges.map((item, index) => {
                const tempTotal = Number(item.node.quantity * item.node.rate).toFixed(2)
                return (
                  <View key={item.node.schoolServices.name} style={rowStyle}>
                    <View
                      style={{
                        ...qtyCol,
                        width: '30px',
                        borderLeft: 'none',
                      }}
                    >
                      <Text style={{ margin: 'auto' }}>{index + 1}. </Text>
                    </View>
                    <Text style={serviceCol}>{item.node.schoolServices.name}</Text>
                    <View style={qtyCol}>
                      <Text style={rightText}>{item.node.quantity}</Text>
                    </View>
                    <View style={qtyCol}>
                      <Text style={rightText}>{item.node.rate}</Text>
                    </View>
                    <View style={{ ...qtyCol, width: '24%' }}>
                      <Text style={rightText}>{tempTotal}</Text>
                    </View>
                  </View>
                )
              })}
              <View
                style={{
                  ...rowStyle,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  backgroundColor: '#fafafa',
                }}
              >
                <Text style={{ ...general }}>Subtotal :</Text>
                <View style={{ ...qtyCol, width: '18.8%', borderLeft: 'none' }}>
                  <Text style={rightText}>
                    {subTotal} {currencyName}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...section,
                  padding: '0',
                  height: '260px',
                  borderBottom: '1px solid black',
                }}
              >
                <View style={{ width: '50%' }}>
                  <Text style={{ ...general, alignSelf: 'flex-start', width: '100%' }}>
                    {toWords.convert(total)}
                  </Text>
                  <View
                    style={{
                      ...general,
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>Razor Pay: </Text>
                    <Text style={{ color: 'blue' }}>{invoice.paymentLink}</Text>
                  </View>

                  <View
                    style={{
                      ...general,
                      paddingBottom: '2px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>Bank A/C No: </Text>
                    <Text>{accountNo}</Text>
                  </View>
                  <View
                    style={{
                      ...general,
                      padding: '2px 8px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>IFSC Code: </Text>
                    <Text>{ifscCode}</Text>
                  </View>
                  <View
                    style={{
                      ...general,
                      display: 'flex',
                      padding: '2px 8px',
                      flexDirection: 'row',
                      alignSelf: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>Branch: </Text>
                    <Text>{bankName}</Text>
                  </View>
                  <View
                    style={{
                      ...general,
                      display: 'flex',
                      padding: '2px 8px',
                      flexDirection: 'row',
                      alignSelf: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>A/C Holder Name: </Text>
                    <Text style={{ width: 170 }}>{accountHolderName}</Text>
                  </View>
                  {upi ? (
                    <View
                      style={{
                        ...general,
                        display: 'flex',
                        padding: '6px 8px 2px',
                        flexDirection: 'row',
                        alignSelf: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>UPI: </Text>
                      <Text>{upi}</Text>
                    </View>
                  ) : null}
                  {gpay ? (
                    <View
                      style={{
                        ...general,
                        display: 'flex',
                        padding: '2px 8px',
                        flexDirection: 'row',
                        alignSelf: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>Google Pay: </Text>
                      <Text>{gpay}</Text>
                    </View>
                  ) : null}
                  {paytm ? (
                    <View
                      style={{
                        ...general,
                        display: 'flex',
                        padding: '2px 8px 6px',
                        flexDirection: 'row',
                        alignSelf: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>Paytm: </Text>
                      <Text>{paytm}</Text>
                    </View>
                  ) : null}
                </View>
                <View
                  style={{
                    width: '50%',
                    borderLeft: '1px solid black',
                  }}
                >
                  <View
                    style={{
                      ...flexSection,
                      flexDirection: 'row-reverse',
                    }}
                  >
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>
                        {Number((subTotal / 100) * parseFloat(invoice.discount || 0)).toFixed(2)}{' '}
                        {currencyName}
                      </Text>
                    </View>
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>Discount ({invoice.discount || 0}%) :</Text>
                    </View>
                  </View>
                  <View style={{ ...flexSection, flexDirection: 'row-reverse' }}>
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>
                        {Number((subTotal / 100) * parseFloat(invoice.cgst || 0)).toFixed(2)}{' '}
                        {currencyName}
                      </Text>
                    </View>
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>CGST ({invoice.cgst || 0}%) :</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      ...flexSection,
                      flexDirection: 'row-reverse',
                    }}
                  >
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>
                        {Number((subTotal / 100) * parseFloat(invoice.sgst || 0)).toFixed(2)}{' '}
                        {currencyName}
                      </Text>
                    </View>
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>SGST ({invoice.sgst || 0}%) :</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      ...flexSection,
                      flexDirection: 'row-reverse',
                    }}
                  >
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>
                        {Number((subTotal / 100) * parseFloat(invoice.tax || 0)).toFixed(2)}{' '}
                        {currencyName}
                      </Text>
                    </View>
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>Taxes ({invoice.tax || 0}%) :</Text>
                    </View>
                  </View>
                  <View style={{ ...flexSection, flexDirection: 'row-reverse' }}>
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>
                        {total} {currencyName}
                      </Text>
                    </View>
                    <View style={taxSection}>
                      <Text style={{ ...rightText }}>Total :</Text>
                    </View>
                  </View>
                </View>
              </View>
              {currencyName === 'INR' && (
                <View style={{ padding: 10 }}>
                  <Text>Scan the QRCode to pay your bill.</Text>
                </View>
              )}
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default PrintableInvoice
