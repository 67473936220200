/* eslint-disable prefer-destructuring */
import actions from './actions'

const initialState = {
  templates: [],
  templatesLoading: false,
  studentTemplatesLoading: false,
  assignModal: false,
  viewerEmail: '',
  staffEmail: '',
  assessmentResponses: [],
  studentTemplates: [],
  selectedTemplate: null,
  recordingTemplateAssessment: null,
  selectedTemplateId: null,
  showTab: 1,
  selectedTemplateConsent: null,
  addedParentInfo: null,
  therapistAssignModal: false,
  savingStudentAssessment: false,
  saveParentInfo: false,
  saveConsentInfo: false,
  assessmentLoading: false,
  answerSaving: false,
  responseLoading: false,
  assessmentSaved: false,
  parentSaved: false,
  centerLoading: false,
  centers: [],
  instructionModal: false,
  finished: false,
  lastActiveQuestionId: null,
  allQuestions: [],
  studentAssessment: null,
  prevModuleType: { name: '', description: '' },
  currModuleType: { name: '', description: '' },
  isModuleChange: false,
  fromReport: false,
  detailsLoading: [],
  selectedQuestion: null,
  selectedModule: null,
  selectedSubmodule: null,
  teacherCount: null,
  isTeacherCountLoading: false,
  parentCount: null,
  isParentCountLoading: false,
  languageList: [],
  languageLoading: false,
}

const getActiveQuestionId = (newAssessmentResponses, allQuestions, studentAssessment) => {
  const temp = newAssessmentResponses.find(
    response => response.assessmentID === studentAssessment?.id,
  )
  const questions = allQuestions
  const findQue = questions.find(
    item =>
      item.subModuleTitle &&
      item.subModuleTitle.toLowerCase().trim() === 'family structure' &&
      item.questionNo === 1,
  )
  let filtered = allQuestions
  if (findQue) {
    const tempId =
      temp.responses.length && temp.responses.find(item => item.question.id === findQue.id)
    if (tempId) {
      // const removeQue = questions.filter(
      //   item =>
      //     item.subModuleTitle &&
      //     item.subModuleTitle.toLowerCase().trim() === 'family structure' &&
      //     item.questionNo !== tempId.answer.scoreValue &&
      //     item.questionNo !== 1,
      // )
      const removeQue =
        tempId.answer.scoreValue === 0
          ? questions.filter(
              item =>
                item.subModuleTitle &&
                item.subModuleTitle.toLowerCase().trim() === 'family structure' &&
                item.questionNo !== 5 &&
                item.questionNo !== 1 &&
                item.questionNo !== 6,
            )
          : questions.filter(
              item =>
                item.subModuleTitle &&
                item.subModuleTitle.toLowerCase().trim() === 'family structure' &&
                item.questionNo !== 2 &&
                item.questionNo !== 1 &&
                item.questionNo !== 3 &&
                item.questionNo !== 4,
            )
      filtered = questions.filter(item => !removeQue.includes(item))
    }
  }
  let findIdx = filtered.length - 1

  if (filtered.length !== temp.responses?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < filtered.length; i++) {
      // eslint-disable-next-line no-loop-func
      const idx = temp.responses.findIndex(item => item.question.id === filtered[i].id)
      if (idx === -1) {
        findIdx = i
        break
      }
    }
  }

  let findId = temp.responses.length && temp.responses[temp.responses.length - 1].question.id

  findId = filtered[findIdx].id
  return findId
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_DATA:
      return { ...state, ...action.payload }
    case actions.SET_STUDENT_DATA:
      return { ...state, ...action.payload }
    case actions.GET_TEMPLATES:
      return { ...state, templatesLoading: true }
    case actions.GET_STUDENT_TEMPLATES:
      return { ...state, studentTemplatesLoading: true }
    case actions.SET_VIEWER_EMAIL:
      return { ...state, viewerEmail: action.payload }
    case actions.SET_STAFF_EMAIL:
      return { ...state, staffEmail: action.payload }
    case actions.APPEND_TEMPLATE_ASSESSMENT: {
      const tempIndex = state.studentTemplates.findIndex(
        temp => temp.id === action.payload.templateID,
      )
      const newData = state.studentTemplates
      newData[tempIndex] = {
        ...newData[tempIndex],
        filterStudentAssessment: [
          action.payload.data,
          ...newData[tempIndex].filterStudentAssessment,
        ],
      }
      return {
        ...state,
        studentTemplates: newData,
      }
    }
    case actions.POP_TEMPLATE_ASSESSMENT: {
      const tempIndex = state.studentTemplates.findIndex(
        temp => temp.id === action.payload.templateID,
      )
      const newData = state.studentTemplates
      const newAssessments = newData[tempIndex].filterStudentAssessment.filter(
        assessment => assessment.id !== action.payload.assessmentID,
      )

      newData[tempIndex] = {
        ...newData[tempIndex],
        filterStudentAssessment: newAssessments,
      }
      return {
        ...state,
        studentTemplates: newData,
      }
    }
    // Module Cases
    case actions.APPEND_MODULE: {
      const index = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const newData = state.templates
      newData[index] = {
        ...newData[index],
        submoduleSet: [...newData[index].submoduleSet, action.payload.data],
      }
      return {
        ...state,
        templates: newData,
        selectedModule: action.payload.data.id,
        selectedQuestion: null,
        selectedSubmodule: null,
      }
    }
    case actions.POP_MODULE: {
      const index = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const newData = state.templates
      const newModules = newData[index].submoduleSet.filter(
        mod => mod.id !== action.payload.moduleID,
      )
      newData[index] = {
        ...newData[index],
        submoduleSet: newModules,
      }
      return {
        ...state,
        templates: newData,
      }
    }
    case actions.REPLACE_MODULE: {
      const index = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const newData = state.templates
      const newModules = newData[index].submoduleSet.map(module => {
        if (module.id === action.payload.moduleID) {
          return {
            ...module,
            title: action.payload.data.title,
            description: action.payload.data.description,
            moduleType: action.payload.data.moduleType,
          }
        }
        return module
      })
      newData[index] = {
        ...newData[index],
        submoduleSet: newModules,
      }
      return {
        ...state,
        templates: newData,
      }
    }
    // SubModule Cases
    case actions.APPEND_SUBMODULE: {
      const tempIndex = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const moduleIndex = state.templates[tempIndex].submoduleSet.findIndex(
        module => module.id === action.payload.moduleID,
      )

      const newData = state.templates
      const newModulesData = newData[tempIndex].submoduleSet
      newModulesData[moduleIndex].submoduleSet = [
        ...newModulesData[moduleIndex].submoduleSet,
        action.payload.data,
      ]
      newData[tempIndex] = {
        ...newData[tempIndex],
        submoduleSet: newModulesData,
      }
      return {
        ...state,
        templates: newData,
        selectedModule: null,
        selectedQuestion: null,
        selectedSubmodule: action.payload.data.id,
      }
    }
    case actions.POP_SUBMODULE: {
      const tempIndex = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const moduleIndex = state.templates[tempIndex].submoduleSet.findIndex(
        module => module.id === action.payload.moduleID,
      )
      const newData = state.templates
      const newModulesData = newData[tempIndex].submoduleSet
      newModulesData[moduleIndex].submoduleSet = newModulesData[moduleIndex].submoduleSet.filter(
        submodule => submodule.id !== action.payload.submoduleID,
      )
      newData[tempIndex] = {
        ...newData[tempIndex],
        submoduleSet: newModulesData,
      }
      return {
        ...state,
        templates: newData,
      }
    }
    case actions.REPLACE_SUBMODULE: {
      const tempIndex = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const moduleIndex = state.templates[tempIndex].submoduleSet.findIndex(
        module => module.id === action.payload.moduleID,
      )

      const newData = state.templates
      const newModulesData = newData[tempIndex].submoduleSet
      const newSubModules = newModulesData[moduleIndex].submoduleSet.map(submodule => {
        if (submodule.id === action.payload.submoduleID) {
          return {
            ...submodule,
            title: action.payload.data.title,
            description: action.payload.data.description,
            subModuleType: action.payload.data.subModuleType,
          }
        }
        return submodule
      })
      newModulesData[moduleIndex].submoduleSet = [...newSubModules]
      newData[tempIndex] = {
        ...newData[tempIndex],
        submoduleSet: newModulesData,
      }
      return {
        ...state,
        templates: newData,
      }
    }
    // Question Cases
    case actions.APPEND_QUESTION: {
      const tempIndex = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const moduleIndex = state.templates[tempIndex].submoduleSet.findIndex(
        module => module.id === action.payload.moduleID,
      )

      const newData = state.templates
      // if question is added to questionSet of module
      if (action.payload.addQuestionTo.to === 'module') {
        const newModulesData = newData[tempIndex].submoduleSet
        newModulesData[moduleIndex].questionSet = [
          ...newModulesData[moduleIndex].questionSet,
          action.payload.data,
        ]
        newData[tempIndex] = {
          ...newData[tempIndex],
          submoduleSet: newModulesData,
        }
        return {
          ...state,
          templates: newData,
          selectedModule: null,
          selectedQuestion: action.payload.data.id,
          selectedSubmodule: null,
        }
      }
      // else question is added to questionSet of submodule
      const subModuleIndex = state.templates[tempIndex].submoduleSet[
        moduleIndex
      ].submoduleSet.findIndex(submodule => submodule.id === action.payload.addQuestionTo.id)
      const newModulesData = newData[tempIndex].submoduleSet
      const newSubModulesData = newModulesData[moduleIndex].submoduleSet
      newSubModulesData[subModuleIndex].questionSet = [
        ...newSubModulesData[subModuleIndex].questionSet,
        action.payload.data,
      ]
      newModulesData[moduleIndex] = {
        ...newModulesData[moduleIndex],
        submoduleSet: newSubModulesData,
      }
      newData[tempIndex] = {
        ...newData[tempIndex],
        submoduleSet: newModulesData,
      }
      return {
        ...state,
        templates: newData,
        selectedModule: null,
        selectedQuestion: action.payload.data.id,
        selectedSubmodule: null,
      }
    }
    case actions.POP_QUESTION: {
      const tempIndex = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const moduleIndex = state.templates[tempIndex].submoduleSet.findIndex(
        module => module.id === action.payload.moduleID,
      )

      const newData = state.templates
      // if question is deleted from questionSet of module
      if (action.payload.deleteFrom.from === 'module') {
        const newModulesData = newData[tempIndex].submoduleSet
        newModulesData[moduleIndex].questionSet = newModulesData[moduleIndex].questionSet.filter(
          question => question.id !== action.payload.questionID,
        )
        newData[tempIndex] = {
          ...newData[tempIndex],
          submoduleSet: newModulesData,
        }
        return {
          ...state,
          templates: newData,
        }
      }

      // else question is deleted from questionSet of submodule
      const subModuleIndex = state.templates[tempIndex].submoduleSet[
        moduleIndex
      ].submoduleSet.findIndex(submodule => submodule.id === action.payload.deleteFrom.id)
      const newModulesData = newData[tempIndex].submoduleSet
      const newSubModulesData = newModulesData[moduleIndex].submoduleSet
      newSubModulesData[subModuleIndex].questionSet = newSubModulesData[
        subModuleIndex
      ].questionSet.filter(question => question.id !== action.payload.questionID)
      newModulesData[moduleIndex] = {
        ...newModulesData[moduleIndex],
        submoduleSet: newSubModulesData,
      }
      newData[tempIndex] = {
        ...newData[tempIndex],
        submoduleSet: newModulesData,
      }
      return {
        ...state,
        templates: newData,
      }
    }
    case actions.REPLACE_QUESTION: {
      const tempIndex = state.templates.findIndex(temp => temp.id === action.payload.templateID)
      const moduleIndex = state.templates[tempIndex].submoduleSet.findIndex(
        module => module.id === action.payload.moduleID,
      )

      const newData = state.templates
      // if question is edited from questionSet of module
      if (action.payload.addQuestionTo.to === 'module') {
        const newModulesData = newData[tempIndex].submoduleSet
        const newQuestions = newModulesData[moduleIndex].questionSet.map(question => {
          if (question.id === action.payload.questionID) {
            return action.payload.data
          }
          return question
        })
        newModulesData[moduleIndex].questionSet = [...newQuestions]
        newData[tempIndex] = {
          ...newData[tempIndex],
          submoduleSet: newModulesData,
        }
        return {
          ...state,
          templates: newData,
        }
      }

      // else question is edited from questionSet of submodule
      const subModuleIndex = state.templates[tempIndex].submoduleSet[
        moduleIndex
      ].submoduleSet.findIndex(submodule => submodule.id === action.payload.addQuestionTo.id)
      const newModulesData = newData[tempIndex].submoduleSet
      const newSubModulesData = newModulesData[moduleIndex].submoduleSet
      const newQuestions = newSubModulesData[subModuleIndex].questionSet.map(question => {
        if (question.id === action.payload.questionID) {
          return action.payload.data
        }
        return question
      })
      newSubModulesData[subModuleIndex].questionSet = [...newQuestions]
      newModulesData[moduleIndex] = {
        ...newModulesData[moduleIndex],
        submoduleSet: newSubModulesData,
      }
      newData[tempIndex] = {
        ...newData[tempIndex],
        submoduleSet: newModulesData,
      }
      return {
        ...state,
        templates: newData,
      }
    }
    // Assessment Response Cases
    case actions.SET_ASSESSMENT_RESPONSES: {
      const newAssessmentResponses = state.assessmentResponses
      const allQuestions = state.allQuestions
      const studentAssessment = state.studentAssessment
      console.log('newAssessmentResponses===>', newAssessmentResponses)

      if (
        newAssessmentResponses.find(
          assessmentResponse => assessmentResponse.assessmentID === action.payload.assessmentID,
        )
      ) {
        const tempIndex = newAssessmentResponses.findIndex(
          assessmentResponse => assessmentResponse.assessmentID === action.payload.assessmentID,
        )
        newAssessmentResponses[tempIndex] = {
          ...newAssessmentResponses[tempIndex],
          responses: [...action.payload.data],
        }

        return {
          ...state,
          assessmentResponses: newAssessmentResponses,
          lastActiveQuestionId: !state.fromReport
            ? getActiveQuestionId(newAssessmentResponses, allQuestions, studentAssessment)
            : null,
          responseLoading: false,
        }
      }

      newAssessmentResponses.push({
        assessmentID: action.payload.assessmentID,
        responses: [...action.payload.data],
        lastResults: [],
      })

      return {
        ...state,
        assessmentResponses: newAssessmentResponses,
        lastActiveQuestionId: !state.fromReport
          ? getActiveQuestionId(newAssessmentResponses, allQuestions, studentAssessment)
          : null,
        responseLoading: false,
      }
    }
    case actions.APPEND_ASSESSMENT_RESPONSE: {
      const newAssessmentResponses = state.assessmentResponses
      const tempIndex = newAssessmentResponses.findIndex(
        assessmentResponse => assessmentResponse.assessmentID === action.payload.assessmentID,
      )

      const newResponses = newAssessmentResponses[tempIndex].responses
      const resIndex = newResponses.findIndex(response => response.id === action.payload.data.id)
      if (resIndex !== -1) {
        newResponses[resIndex] = action.payload.data
        newAssessmentResponses[tempIndex] = {
          ...newAssessmentResponses[tempIndex],
          responses: newResponses,
        }
        return {
          ...state,
          answerSaving: false,
          assessmentResponses: newAssessmentResponses,
        }
      }

      newResponses.push(action.payload.data)
      newAssessmentResponses[tempIndex] = {
        ...newAssessmentResponses[tempIndex],
        responses: newResponses,
      }
      return {
        ...state,
        answerSaving: false,
        assessmentResponses: newAssessmentResponses,
      }
    }
    case actions.APPEND_LAST_ASSESSMENT_RESULTS: {
      const newAssessmentResponses = state.assessmentResponses
      const tempIndex = newAssessmentResponses.findIndex(
        assessmentResponse => assessmentResponse.assessmentID === action.payload.assessmentID,
      )

      newAssessmentResponses[tempIndex] = {
        ...newAssessmentResponses[tempIndex],
        lastResults: action.payload.data,
      }
      return {
        ...state,
        assessmentResponses: newAssessmentResponses,
        assessmentLoading: false,
      }
    }
    default:
      return state
  }
}
