import React from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const LastUpdateCard = ({ targetObject }) => {
  return (
    <>
      <div>
        <div style={{ ...boxCard, fontSize: 14, fontWeight: 600, color: 'black' }}>
          <Row style={{ marginTop: 5 }} type="flex">
            <Col span={12}>
              <div style={{ width: '100%', color: 'black', fontSize: 14, display: 'flex' }}>
                <span>Last Updated At:&nbsp;</span> {'  '}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                >
                  <span>{moment(targetObject.updatedAt).format('YYYY-MM-DD h:mm:ss a')}</span>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontSize: 14,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <span>Updated By:&nbsp;</span> {'  '}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                >
                  <span>
                    {targetObject.lastUpdatedBy ? targetObject.lastUpdatedBy.firstName : 'System'}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }} type="flex">
            <Col span={12}>
              <div style={{ width: '100%', color: 'black', fontSize: 14, display: 'flex' }}>
                <span>Status Updated at:&nbsp;</span> {'  '}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                >
                  <span>
                    {targetObject.lastStatusChange
                      ? moment(targetObject.lastStatusChange.updatedAt).format(
                          'YYYY-MM-DD h:mm:ss a',
                        )
                      : targetObject.baselineDate}
                  </span>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  width: '100%',
                  color: 'black',
                  fontSize: 14,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <span>Status Updated By:&nbsp;</span> {'  '}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                >
                  <span>
                    {targetObject.lastStatusChange && targetObject.lastStatusChange.userId
                      ? targetObject.lastStatusChange.userId.firstName
                      : 'System'}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default LastUpdateCard
