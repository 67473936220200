/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Dropdown,
  Menu,
  Typography,
  Row,
  Icon,
  Card,
  Select,
} from 'antd'
import { gql } from 'apollo-boost'
import client from 'apollo/config'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ArrowLeftOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { random, cloneDeep } from 'lodash'
import React, { Component, useState, useRef } from 'react'
import Timer from 'react-compound-timer'
import './style.scss'
import ScoreBoard from './ScoreBoard'

const { Title, Text } = Typography
const { confirm } = Modal

const SBT = () => {
  const timerSBTRef = useRef()
  const [autoRecordNone, setAutoRecordNone] = useState(true)
  const dispatch = useDispatch()
  const {
    TargetResponse,
    TargetActiveId,
    Count,
    IncorrectCount,
    MasterSession,
    TargetActiveIndex,
    SBTStepActiveId,
    EditAfterSessionCompleted,
    Disabled,
    StepActiveId,
    SBTStepActiveIndex,
    PromptCodesList,
    ResponseLoading,
  } = useSelector(state => state.sessionrecording)

  const updateSessionClockTime = () => {
    // updatig current clock time to store
    document.getElementById('updateSessionCurrentTimeInStore').click()
  }

  const updateStartTrialClockTime = () => {
    // updatig trial start time to store
    document.getElementById('updateStartTrialTimeInStore').click()
  }

  const recordResponse = (response, r1 = '', r2 = '', promptId = '', reinforceId = '') => {
    if (EditAfterSessionCompleted && !Disabled) {
      if (TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId][Count - 1]) {
        const trialObject = TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId][Count - 1]

        if (response) {
          dispatch({
            type: 'sessionrecording/UPDATE_SBT_TRIAL',
            payload: {
              object: trialObject,
              sbtStepId: SBTStepActiveId,
              response,
              r1,
              r2,
              promptId,
              reinforceId,
            },
          })
        }
      } else {
        updateSessionClockTime()
        // write recordReponse dispatch request
        if (
          Count <=
          MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.DailyTrials
        ) {
          if (response) {
            dispatch({
              type: 'sessionrecording/RECORD_SBT_TRIAL',
              payload: {
                sbtStepId: SBTStepActiveId,
                response,
                r1,
                r2,
                promptId,
                reinforceId,
              },
            })
          }
        }
      }
    }
  }

  const moveToNextSTEPTrail = () => {
    if (!(Count > TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId].length)) {
      if (Count === TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId].length) {
        // user want to perform new trial
        // storing trial start time
        updateStartTrialClockTime()
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            Count: Count + 1,
          },
        })
      } else {
        dispatch({
          type: 'sessionrecording/SET_STATE',
          payload: {
            Count: Count + 1,
          },
        })
      }
    }
  }

  const moveToPrevSTEPTrail = () => {
    // this.resetCorrectIncorrectButtonStyle()
    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        Count: Count > 1 ? Count - 1 : 1,
      },
    })
  }

  const moveToNextStep = () => {
    // storing trial start time
    updateStartTrialClockTime()
    dispatch({
      type: 'sessionrecording/CHANGE_SBT_STEP',
      payload: {
        sbtStepId:
          MasterSession.targets.edges[TargetActiveIndex].node.sbtSteps.edges[SBTStepActiveIndex + 1]
            .node.id,
        sbtStepIndex: SBTStepActiveIndex + 1,
        targetId: TargetActiveId,
      },
    })
  }

  const moveToPrevStep = () => {
    // storing trial start time
    updateStartTrialClockTime()

    dispatch({
      type: 'sessionrecording/CHANGE_SBT_STEP',
      payload: {
        sbtStepId:
          MasterSession.targets.edges[TargetActiveIndex].node.sbtSteps.edges[SBTStepActiveIndex - 1]
            .node.id,
        sbtStepIndex: SBTStepActiveIndex - 1,
        targetId: TargetActiveId,
      },
    })
  }

  const getReinforces = () => {
    const sbtStep =
      MasterSession.targets.edges[TargetActiveIndex]?.node.sbtSteps.edges[SBTStepActiveIndex]
    const reinforcesButtons = []

    if (sbtStep?.node.reinforce.edges.length > 0) {
      return (
        <>
          {sbtStep?.node.reinforce.edges.map(i => (
            <Button
              onClick={() =>
                recordResponse(
                  'Correct',
                  '', // r1 null
                  '', // r2 null
                  '', // promptId null
                  i.node.id, // reinforcerId
                )
              }
            >
              {i.node.name}
            </Button>
          ))}
        </>
      )
    }

    // return reinforcesButtons
  }

  const getButtonsStyle = type => {
    const item = TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId]?.[Count - 1]
    let r1Style = { backgroundColor: '#EDEDED', borderColor: '#d9d9d9', color: 'rgba(0,0,0,0.65)' }
    let r2Style = { backgroundColor: '#EDEDED', borderColor: '#d9d9d9', color: 'rgba(0,0,0,0.65)' }
    let noneStyle = {
      backgroundColor: '#EDEDED',
      borderColor: '#d9d9d9',
      color: 'rgba(0,0,0,0.65)',
    }
    let promptStyle = {
      backgroundColor: '#EDEDED',
      borderColor: '#d9d9d9',
      color: 'rgba(0,0,0,0.65)',
    }
    let reinforceStyle = {
      backgroundColor: '#EDEDED',
      borderColor: '#d9d9d9',
      color: 'rgba(0,0,0,0.65)',
    }
    if (item) {
      if (item.trial === 'INCORRECT') {
        if (item.r1) r1Style = { backgroundColor: '#2457c7', borderColor: '#d9d9d9', color: '#fff' }
        if (item.r2) r2Style = { backgroundColor: '#2457c7', borderColor: '#d9d9d9', color: '#fff' }
      }
      if (item.trial === 'ERROR')
        noneStyle = { backgroundColor: '#4BAEA0', borderColor: '#d9d9d9', color: '#fff' }
      if (item.trial === 'PROMPT')
        promptStyle = { backgroundColor: '#FF9C52', borderColor: '#d9d9d9', color: '#fff' }
      if (item.trial === 'CORRECT')
        reinforceStyle = { backgroundColor: '#4BAEA0', borderColor: '#d9d9d9', color: '#fff' }
    }

    let returnStyle = { backgroundColor: '#fff', borderColor: '#d9d9d9', color: 'rgba(0,0,0,0.65)' }
    if (type === 'r1') returnStyle = r1Style
    if (type === 'r2') returnStyle = r2Style
    if (type === 'none') returnStyle = noneStyle
    if (type === 'prompt') returnStyle = promptStyle
    if (type === 'reinforce') returnStyle = reinforceStyle

    return returnStyle
  }

  const getButtonsCounts = type => {
    const recording = TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId]
    let r1Count = 0
    let r2Count = 0
    let noneCount = 0
    let promptCount = 0
    let reinforceCount = 0
    if (recording && recording.length > 0) {
      recording.map(item => {
        if (item.trial === 'INCORRECT') {
          if (item.r1) r1Count += 1
          if (item.r2) r2Count += 1
        }
        if (item.trial === 'ERROR') noneCount += 1
        if (item.trial === 'PROMPT') promptCount += 1
        if (item.trial === 'CORRECT') reinforceCount += 1
      })
    }

    let returnCount = 0
    if (type === 'r1') returnCount = r1Count
    if (type === 'r2') returnCount = r2Count
    if (type === 'none') returnCount = noneCount
    if (type === 'prompt') returnCount = promptCount
    if (type === 'reinforce') returnCount = reinforceCount

    return returnCount
  }

  const SubMenu1 = props => {
    return (
      <>
        {MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.promptCodes.edges
          .length > 0 ? (
          <>
            {MasterSession.targets.edges[
              TargetActiveIndex
            ].node.targetAllcatedDetails.promptCodes.edges.map(item => (
              <>
                <Button
                  onClick={() =>
                    recordResponse(
                      'Prompt',
                      '', // r1 null
                      '', // r2 null
                      item.node.id, // promptId
                      '', // reinforcer null
                    )
                  }
                >
                  {item.node.promptName}
                </Button>
              </>
            ))}
          </>
        ) : (
          <>
            {PromptCodesList.map(item => (
              <>
                <Button
                  onClick={() =>
                    recordResponse(
                      'Prompt',
                      '', // r1 null
                      '', // r2 null
                      item.id, // promptId
                      '', // reinforcer null
                    )
                  }
                >
                  {item.promptName}
                </Button>
              </>
            ))}
          </>
        )}
      </>
    )
  }

  const resetStep = sbtStepId => {
    confirm({
      title: ' Are you sure you want to delete all records for current Step??',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch({
          type: 'sessionrecording/RESET_SD_SBT_STEP_DATA',
          payload: {
            targetId: TargetActiveId,
            sdId: '',
            stepId: '',
            sbtStepId,
          },
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const resetTrial = () => {
    if (TargetResponse[TargetActiveId].sbtStep[SBTStepActiveId][Count - 1]) {
      confirm({
        title: ' Are you sure you want to delete the current Trial??',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          dispatch({
            type: 'sessionrecording/RESET_TRIAL',
            payload: {
              targetId: TargetActiveId,
              trialIndex: Count - 1,
              type: 'Normal-sbtStep',
            },
          })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    }
  }

  if (timerSBTRef.current) {
    if (Disabled || !EditAfterSessionCompleted) {
      timerSBTRef.current.stop()
    }
    // else {
    //   this.timerSBTRef.current.start()
    // }
  }

  const trialDurationObject =
    MasterSession?.targets.edges[TargetActiveIndex].node.targetAllcatedDetails.trialDuration
  let trialDuration = 30 * 1000
  if (trialDurationObject && !isNaN(trialDurationObject)) {
    trialDuration = trialDurationObject * 1000
  }

  return (
    <>
      {MasterSession.targets.edges[TargetActiveIndex].node.sbtSteps.edges.length > 0 && (
        <>
          <div className="sbt-block-div">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <p className="title" style={{ marginBottom: 0, marginTop: 8 }}>
                  {
                    MasterSession.targets.edges[TargetActiveIndex].node.sbtSteps.edges[
                      SBTStepActiveIndex
                    ].node.description
                  }
                </p>
              </div>

              <div style={{ fontSize: 12 }}>
                {SBTStepActiveIndex === 0 ? (
                  <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                    <Icon type="left" />
                  </Button>
                ) : (
                  <Button style={{ border: 'none' }} onClick={moveToPrevStep}>
                    <Icon type="left" />
                  </Button>
                )}
                Step {SBTStepActiveIndex + 1} /{' '}
                {MasterSession.targets.edges[TargetActiveIndex].node.sbtSteps.edges.length}
                {SBTStepActiveIndex ===
                MasterSession.targets.edges[TargetActiveIndex].node.sbtSteps.edges.length - 1 ? (
                  <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                    <Icon type="right" />
                  </Button>
                ) : (
                  <Button style={{ border: 'none' }} onClick={moveToNextStep}>
                    <Icon type="right" />
                  </Button>
                )}
                <Button
                  style={{ border: 'none', background: 'transparent', flex: 1 }}
                  onClick={() => resetStep(SBTStepActiveId)}
                >
                  <Icon type="delete" />
                </Button>
              </div>
            </div>
          </div>

          <div className="sbt-block-div">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <ScoreBoard
                  key={MasterSession.targets.edges[TargetActiveIndex].node.id}
                  id={MasterSession.targets.edges[TargetActiveIndex].node.id}
                  sdKey=""
                  stepKey=""
                  sbtStepKey={
                    MasterSession.targets.edges[TargetActiveIndex].node.sbtSteps.edges[
                      SBTStepActiveIndex
                    ]?.node.id
                  }
                  dailyTrails={
                    MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails
                      .DailyTrials
                  }
                  boxWidth="6px"
                />
              </div>

              <div style={{ fontSize: 12 }}>
                {Count === 0 ? (
                  <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                    <Icon type="left" />
                  </Button>
                ) : (
                  <Button style={{ border: 'none' }} onClick={moveToPrevSTEPTrail}>
                    <Icon type="left" />
                  </Button>
                )}
                Trial {Count} /{' '}
                {
                  MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails
                    .DailyTrials
                }
                {Count ===
                MasterSession.targets.edges[TargetActiveIndex].node.targetAllcatedDetails
                  .DailyTrials ? (
                  <Button style={{ border: 'none', backgroundColor: '#fff' }} disabled>
                    <Icon type="right" />
                  </Button>
                ) : (
                  <Button style={{ border: 'none' }} onClick={moveToNextSTEPTrail}>
                    <Icon type="right" />
                  </Button>
                )}
                <Button
                  style={{ border: 'none', background: 'transparent', flex: 1 }}
                  onClick={() => resetTrial()}
                >
                  <Icon type="delete" />
                </Button>
              </div>
            </div>

            <div
              style={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
              }}
            >
              <div className="test-details active" style={{ flex: 2, padding: 10 }}>
                <Card
                  style={{ padding: 0, border: 'none', textAlign: 'center' }}
                  loading={ResponseLoading}
                >
                  <Timer
                    key={Count + Math.random()}
                    ref={timerSBTRef}
                    initialTime={trialDuration}
                    direction="backward"
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          // console.log('Checkpoint 30 sec - call record none response query')
                          autoRecordNone && recordResponse('Error', '', '', '', '')
                        },
                      },
                    ]}
                  >
                    {() => (
                      <React.Fragment>
                        <Timer.Minutes /> mins <Timer.Seconds /> sec
                      </React.Fragment>
                    )}
                  </Timer>
                  <div>
                    <Button
                      size="large"
                      // disabled={isManual || stepIndex + 1 === trials}
                      style={{
                        padding: '8px auto',
                        width: '160px',
                        height: '37px',
                        marginTop: '10px',
                        marginLeft: 5,
                        border: '1px solid #E1E1E1',
                        borderRadius: '3px',
                        ...getButtonsStyle('r1'),
                      }}
                      onClick={() =>
                        recordResponse(
                          'Incorrect',
                          MasterSession.targets.edges[TargetActiveIndex].node.r1?.id,
                          '', // r2 null
                          '', // prompt null
                          '', // reinforcer null
                        )
                      }
                    >
                      R1: {MasterSession.targets.edges[TargetActiveIndex].node.r1?.behaviorName} (
                      {getButtonsCounts('r1')})
                    </Button>
                    <Button
                      size="large"
                      // disabled={}
                      style={{
                        ...getButtonsStyle('r2'),
                        padding: '8px auto',
                        width: '160px',
                        height: '37px',
                        marginTop: '10px',
                        marginLeft: 5,
                        border: '1px solid #E1E1E1',
                        borderRadius: '3px',
                      }}
                      onClick={() =>
                        recordResponse(
                          'Incorrect',
                          '', // r1 null
                          MasterSession.targets.edges[TargetActiveIndex].node.r2?.id,
                          '', // prompt null
                          '', // reinforcer null
                        )
                      }
                    >
                      R2: {MasterSession.targets.edges[TargetActiveIndex].node.r2?.behaviorName} (
                      {getButtonsCounts('r2')})
                    </Button>
                    <Dropdown
                      overlay={SubMenu1}
                      trigger={['hover', 'click']}
                      placement="bottomRight"
                    >
                      <Button
                        style={{
                          ...getButtonsStyle('prompt'),
                          padding: '8px auto',
                          width: '160px',
                          height: '37px',
                          marginTop: '10px',
                          marginLeft: 5,
                          border: '1px solid #E1E1E1',
                          borderRadius: '3px',
                        }}
                      >
                        Prompt ({getButtonsCounts('prompt')})
                      </Button>
                    </Dropdown>
                    <Button
                      size="large"
                      style={{
                        ...getButtonsStyle('none'),
                        padding: '8px auto',
                        width: '160px',
                        height: '37px',
                        marginTop: '10px',
                        marginLeft: 5,
                        border: '1px solid #E1E1E1',
                        borderRadius: '3px',
                      }}
                      onClick={() => recordResponse('Error', '', '', '', '')}
                    >
                      None ({getButtonsCounts('none')})
                    </Button>

                    {/* </div>
                          <div style={{ boxSizing: 'border-box' }}> */}

                    <Dropdown
                      overlay={getReinforces}
                      trigger={['click', 'hover']}
                      placement="bottomRight"
                    >
                      <Button
                        style={{
                          ...getButtonsStyle('reinforce'),
                          padding: '8px auto',
                          width: '160px',
                          height: '37px',
                          marginTop: '10px',
                          marginLeft: 5,
                          border: '1px solid #E1E1E1',
                          borderRadius: '3px',
                        }}
                      >
                        Correct ({getButtonsCounts('reinforce')})
                      </Button>
                    </Dropdown>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SBT
