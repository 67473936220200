/* eslint-disable */
import React from 'react'
import { Tooltip } from 'antd'

const MilestoneQuestion = ({ index, question }) => {
  const getStyleBasedOnScore = (que, obj = 0) => {
    const colorForFalse = 'transparent'
    const colorForSeparation = 'rgba(16, 17, 16, 0.1)'

    // If question exist then choose based on that color & score
    if (que) {
      const { score, color: colorForTrue, first, second } = que
      // if (score === 0.5) {
      //   return {
      //     background: `linear-gradient(to top, ${colorForTrue} 0%, ${colorForTrue} 49%, ${colorForSeparation} 49%, ${colorForSeparation} 51%, ${colorForFalse} 51%, ${colorForFalse} 100%)`,
      //   }
      // }
      // if (score === 1) {
      //   return {
      //     background: `linear-gradient(to top, ${colorForTrue} 0%, ${colorForTrue} 49%, ${colorForSeparation} 49%, ${colorForSeparation} 51%, ${colorForTrue} 51%, ${colorForTrue} 100%)`,
      //   }
      // }
      if (obj === 1) {
        return {
          // background: `linear-gradient(to top, ${first.color} 0%, ${first.color} 49%, ${colorForSeparation} 49%, ${colorForSeparation} 51%, ${first.color} 51%, ${first.color} 100%)`,
          backgroundColor: first.color,
        }
      }
      if (obj === 2) {
        return {
          // background: `linear-gradient(to top, ${second.color} 0%, ${second.color} 49%, ${colorForSeparation} 49%, ${colorForSeparation} 51%, ${second.color} 51%, ${second.color} 100%)`,
          backgroundColor: second.color,
        }
      }
    }

    // If question does not exist or score is 0 then
    return {
      // background: `linear-gradient(to top, ${colorForFalse} 0%, ${colorForFalse} 49%, ${colorForSeparation} 49%, ${colorForSeparation} 51%, ${colorForFalse} 51%, ${colorForFalse} 100%)`,
      backgroundColor: '#fff',
    }
  }

  const getTooltip = (que, obj) => (
    <div>
      <div>
        <b>Que: </b>
        {que.questionNum}
      </div>
      <div>
        <b>Score: </b>
        {obj.score}
      </div>
      <div>
        <b>Test: </b>
        {obj.testName}
      </div>
    </div>
  )

  if (!question)
    return (
      <>
        <div style={{ border: '2px solid #c4c4c4' }}>&nbsp;</div>
        <div style={{ border: '2px solid #c4c4c4' }}>&nbsp;</div>
      </>
    )

  return (
    <>
      {/* <Tooltip placement="top" title={getTooltip(question, question.first)}> */}
      <div
        style={{
          backgroundColor: question.first.color,
          border: question.first.borderColor
            ? `2px solid ${question.first.borderColor}`
            : `2px solid #c4c4c4`,
        }}
      >
        &nbsp;
        {/* {question.first.borderColor ? (
            <div
              style={{
                padding:2,
                backgroundColor: 'white',
                border: question.first.borderColor
                  ? `2px solid ${question.first.borderColor}`
                  : `2px solid #c4c4c4`,
              }}
            >
              &nbsp;
            </div>
          ):<>&nbsp;</>} */}
      </div>
      {/* </Tooltip> */}
      {/* <Tooltip placement="top" title={getTooltip(question, question.second)}> */}
      <div
        style={{
          backgroundColor: question.second.color,
          border: question.second.borderColor
            ? `2px solid ${question.second.borderColor}`
            : `2px solid #c4c4c4`,
        }}
      >
        &nbsp;
        {/* {question.second.borderColor ?(
            <div
              style={{
                backgroundColor: 'white',
                border: question.second.borderColor
                  ? `2px solid ${question.second.borderColor}`
                  : `2px solid #c4c4c4`,
              }}
            >
              &nbsp;
            </div>
          ):<>&nbsp;</>} */}
      </div>
      {/* </Tooltip> */}
    </>
  )
}

export default MilestoneQuestion
