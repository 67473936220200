/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
import React, { useState } from 'react'
import {
  Form,
  Input,
  TreeSelect,
  Button,
  Select,
  InputNumber,
  notification,
  Row,
  Col,
  Tooltip,
  Popover,
  Typography,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import { useMutation } from 'react-apollo'
import { useMasterySeq } from './context'
import { CREATE_CRITERIA_PHASE_TARGET, UPDATE_CRITERIA_PHASE_TARGET } from './query'
import PhaseExplanation from './PhaseExplanation'

const { Option } = Select
const { TreeNode } = TreeSelect
const { Text } = Typography

const BEHAVIOR_RECORDING_FREQ = 'Behavior Recording Frequency'
const BEHAVIOR_RECORDING_DUR = 'Behavior Recording Duration'

const masteryCriteriaObject = {
  calculationType: {
    title: 'Calculation Type',
    explanation:
      'This field determines how the system will calculate mastery based on learner performance for target while session recording.',
    example: 'Examples: "Greater Than," "Less Than," "Equal To," "Between," etc.',
  },
  responsePercentage: {
    title: 'Response Percentage',
    explanation: 'Defines the percentage of correct responses required to achieve mastery.',
    example: 'Example: If set to 45%, the learner must answer correctly at least 45% of the time.',
  },
  duration: {
    title: 'Duration',
    explanation: 'Specifies the duration of the target.',
    example:
      'Example: If set to 60 seconds, the system evaluates based on a target duration of 60 seconds.',
  },
  frequency: {
    title: 'Frequency',
    explanation: 'Specifies the frequency of the target.',
    example:
      'Example: If set to 3 times, the system evaluates mastery based on a target frequency of 3 times.',
  },
  calculationTypeDayOrAverage: {
    title: 'Calculation Type',
    explanation:
      'Specifies whether the calculation is based on daily performance or an average over a period.',
    example: 'Examples: "Daily," or "Average."',
  },
  frameType: {
    title: 'Frame Type',
    explanation:
      "Determines how the system considers the target's progress within a specific timeframe.",
    example:
      'Examples: "Every" (considering progress in each frame), "Cumulative" (accumulated progress), "Consecutive" (continuous progress).',
  },
  frameValue: {
    title: 'Frame Value',
    explanation: 'Specifies the numerical value associated with the selected frame type.',
    example:
      'Example: If Frame Type is "Every" and Frame Value is 5, the system evaluates progress every 5 sessions/days.',
  },
  frameDurationType: {
    title: 'Frame Duration Type',
    explanation: 'Indicates whether the frame duration is measured in days or sessions.',
    example: 'Examples: "Day," "Session."',
  },
  minTrialsPerDay: {
    title: 'Minimum Trials',
    explanation:
      'Sets the minimum number of trials/activities a target must complete in a day/session.',
    example:
      'Example: If set to 15, the target needs to be completed by at least 15 trials each day/session.',
  },
  progressToNextStatus: {
    title: 'Progress to Next Status',
    explanation: 'Defines the criteria for a target to progress to the next status or phase.',
    example:
      'Example: If set to Intervention, the target moves to the next phase after meeting this criterion.',
  },
}

const MasteryForm = ({ form, edit, criteriaTargetObj, details, onSuccess, closeModal }) => {
  const [createCriteriaPhaseTarget, { loading: createCriteriaPhaseTargetLoading }] = useMutation(
    CREATE_CRITERIA_PHASE_TARGET,
  )

  const [updateCriteriaPhaseTarget, { loading: updateCriteriaPhaseTargetLooading }] = useMutation(
    UPDATE_CRITERIA_PHASE_TARGET,
  )

  const { getFieldValue, getFieldDecorator, validateFields, resetFields } = form

  const {
    selectedTemplate: { templateType },
    phaseSet,
  } = useMasterySeq()

  const [selectedkey, setSelectedkey] = useState(null)

  const SubmitForm = e => {
    e.preventDefault()
    validateFields((error, values) => {
      if (!error && details?.id) {
        createCriteriaPhaseTarget({
          variables: {
            criteriaPhase: details.id,
            calculationType: values.calculationType,
            percentage: values.percentage,
            calFrameType: values.calFrameType,
            durationCalType: values.durationCalType,
            calFrameDurationType: values.calFrameDurationType,
            calFrameDurationValue: values.calFrameDurationValue,
            minimumTrial: values.minimumTrial || 0,
            progressTo: values.progressTo,
            phaseChangeLabel: values.phaseChangeLabel,
            duration: values.duration,
            frequency: values.frequency,
          },
        })
          .then(res => {
            notification.success({
              message: 'Create child mastery succesfully',
            })
            if (onSuccess) {
              onSuccess()
            }
            resetFields()
            if (closeModal) {
              closeModal()
            }
          })
          .catch(err => console.error(err))
      }
    })
  }

  const handleUpdate = e => {
    e.preventDefault()
    validateFields((error, values) => {
      if (!error && criteriaTargetObj?.id) {
        updateCriteriaPhaseTarget({
          variables: {
            pk: criteriaTargetObj.id,
            calculationType: values.calculationType,
            percentage: values.percentage,
            durationCalType: values.durationCalType,
            calFrameType: values.calFrameType,
            calFrameDurationType: values.calFrameDurationType,
            calFrameDurationValue: values.calFrameDurationValue,
            minimumTrial: values.minimumTrial,
            progressTo: values.progressTo,
            phaseChangeLabel: values.phaseChangeLabel,
            duration: values.duration,
            frequency: values.frequency,
          },
        })
          .then(res => {
            notification.success({
              message: 'Create child mastery succesfully',
            })
            if (onSuccess) {
              onSuccess()
            }
            resetFields()
            if (closeModal) {
              closeModal()
            }
          })
          .catch(err => console.error(err))
      }
    })
  }

  const getProgessToOptions = statusWise => {
    if (statusWise) {
      const sbd = new Set(
        phaseSet
          .filter(it => it.node.criteriaType.statusName !== details.criteriaType.statusName)
          .map(it => it.node.criteriaType.statusName),
      )

      return [...sbd]
    }

    const idx = phaseSet.reduce((acc, currVal, currIdx) => {
      if (currVal.node.id === details.id) {
        return currIdx
      }
      return acc
    }, 0)
    const sbd = []

    if (phaseSet.length > 1) {
      if (idx === 0) {
        sbd.push('Next Item in Hierarchy')
      } else if (idx === phaseSet.length - 1) {
        sbd.push('Previous Item in Hierarchy')
      } else {
        sbd.push('Next Item in Hierarchy', 'Previous Item in Hierarchy')
      }
    }
    sbd.push('Do not progress', 'Remove from all sessions')
    return sbd
  }

  return (
    <>
      <Row gutter={24}>
        <Col span={18}>
          <Form name="targetForm" onSubmit={edit ? handleUpdate : SubmitForm}>
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <span>
                      Calculation Type&nbsp;
                      <Popover
                        content={
                          <div style={{ width: 400 }}>
                            <div>
                              <Text>{masteryCriteriaObject.calculationType.explanation}</Text>{' '}
                            </div>
                            <Text>{masteryCriteriaObject.calculationType.example}</Text>
                          </div>
                        }
                        title={masteryCriteriaObject.calculationType.title}
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator('calculationType', {
                    initialValue: edit ? criteriaTargetObj?.calculationType : '>',
                    rules: [{ required: true, message: 'Please provide calculation type' }],
                  })(
                    <Select placeholder="Calculation Type">
                      <Option value=">">If Greater Than</Option>
                      <Option value="<">If Less Than</Option>
                      <Option value="==">If Equal</Option>
                      <Option value=">=">If Greater Than or Equal To</Option>
                      <Option value="<=">If Less Than or Equal To</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                {templateType === 'Regular' && (
                  <Form.Item
                    label={
                      <span>
                        Response Percentage&nbsp;
                        <Popover
                          content={
                            <div style={{ width: 400 }}>
                              <div>
                                <Text>{masteryCriteriaObject.responsePercentage.explanation}</Text>{' '}
                              </div>
                              <Text>{masteryCriteriaObject.responsePercentage.example}</Text>
                            </div>
                          }
                          title={masteryCriteriaObject.responsePercentage.title}
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </span>
                    }
                  >
                    {getFieldDecorator('percentage', {
                      initialValue: edit ? criteriaTargetObj?.percentage : 45,
                      rules: [{ required: true, message: 'Please enter percentage' }],
                    })(
                      <InputNumber
                        placeholder="Enter percentage ex - 45"
                        style={{ width: '100%' }}
                        min={0}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                      />,
                    )}
                  </Form.Item>
                )}
                {templateType === BEHAVIOR_RECORDING_FREQ && (
                  <Form.Item
                    label={
                      <span>
                        Frequency&nbsp;
                        <Popover
                          content={
                            <div style={{ width: 400 }}>
                              <div>
                                <Text>{masteryCriteriaObject.frequency.explanation}</Text>{' '}
                              </div>
                              <Text>{masteryCriteriaObject.frequency.example}</Text>
                            </div>
                          }
                          title={masteryCriteriaObject.frequency.title}
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </span>
                    }
                  >
                    {getFieldDecorator('frequency', {
                      initialValue: edit ? criteriaTargetObj?.frequency : 10,
                      rules: [{ required: true, message: 'Please enter fequency' }],
                    })(
                      <InputNumber
                        placeholder="Enter frequency ex - 10"
                        style={{ width: '100%' }}
                        min={0}
                      />,
                    )}
                  </Form.Item>
                )}
                {templateType === BEHAVIOR_RECORDING_DUR && (
                  <Form.Item
                    label={
                      <span>
                        Duration (in seconds)&nbsp;
                        <Popover
                          content={
                            <div style={{ width: 400 }}>
                              <div>
                                <Text>{masteryCriteriaObject.duration.explanation}</Text>{' '}
                              </div>
                              <Text>{masteryCriteriaObject.duration.example}</Text>
                            </div>
                          }
                          title={masteryCriteriaObject.duration.title}
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </span>
                    }
                  >
                    {getFieldDecorator('duration', {
                      initialValue: edit ? criteriaTargetObj?.duration : 30,
                      rules: [{ required: true, message: 'Please enter duration (in seconds)' }],
                    })(
                      <InputNumber
                        placeholder="Enter percentage ex - 45 sec"
                        style={{ width: '100%' }}
                        min={0}
                      />,
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <span>
                      Calculation Type&nbsp;
                      <Popover
                        content={
                          <div style={{ width: 400 }}>
                            <div>
                              <Text>
                                {masteryCriteriaObject.calculationTypeDayOrAverage.explanation}
                              </Text>{' '}
                            </div>
                            <Text>{masteryCriteriaObject.calculationTypeDayOrAverage.example}</Text>
                          </div>
                        }
                        title={masteryCriteriaObject.calculationTypeDayOrAverage.title}
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator('durationCalType', {
                    initialValue: edit ? criteriaTargetObj?.durationCalType : 'Per',
                    rules: [
                      { required: true, message: 'Please provide duration calculation type' },
                    ],
                  })(
                    <Select
                      placeholder="Duration Calculation Type"
                      onFocus={() => {
                        setSelectedkey('calculationTypeDayOrAverage')
                      }}
                    >
                      <Option value="Per">Per Day</Option>
                      <Option value="Average">Average</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <span>
                      Frame Type&nbsp;
                      <Popover
                        content={
                          <div style={{ width: 400 }}>
                            <div>
                              <Text>{masteryCriteriaObject.frameType.explanation}</Text>{' '}
                            </div>
                            <Text>{masteryCriteriaObject.frameType.example}</Text>
                          </div>
                        }
                        title={masteryCriteriaObject.frameType.title}
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator('calFrameType', {
                    initialValue: edit ? criteriaTargetObj?.calFrameType : 'Cumulative',
                    rules: [{ required: true, message: 'Please provide frame type' }],
                  })(
                    <Select
                      placeholder="Frame Type"
                      onFocus={() => {
                        setSelectedkey('frameType')
                      }}
                    >
                      <Option value="Every">Every</Option>
                      <Option value="Consecutive">Consecutive</Option>
                      <Option value="Cumulative">Cumulative</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <span>
                      Frame Value&nbsp;
                      <Popover
                        content={
                          <div style={{ width: 400 }}>
                            <div>
                              <Text>{masteryCriteriaObject.frameValue.explanation}</Text>{' '}
                            </div>
                            <Text>{masteryCriteriaObject.frameValue.example}</Text>
                          </div>
                        }
                        title={masteryCriteriaObject.frameValue.title}
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator('calFrameDurationValue', {
                    initialValue:
                      getFieldValue('calFrameType') === 'Every'
                        ? 1
                        : edit
                        ? criteriaTargetObj?.calFrameDurationValue
                        : 5,
                    rules: [{ required: true, message: 'Required field' }],
                  })(
                    <InputNumber
                      disabled={getFieldValue('calFrameType') === 'Every'}
                      min={1}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <span>
                      Frame Duration Type&nbsp;
                      <Popover
                        content={
                          <div style={{ width: 400 }}>
                            <div>
                              <Text>{masteryCriteriaObject.frameDurationType.explanation}</Text>{' '}
                            </div>
                            <Text>{masteryCriteriaObject.frameDurationType.example}</Text>
                          </div>
                        }
                        title={masteryCriteriaObject.frameDurationType.title}
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator('calFrameDurationType', {
                    initialValue: edit ? criteriaTargetObj?.calFrameDurationType : 'Day',
                    rules: [{ required: true, message: 'Please provide frame type' }],
                  })(
                    <Select placeholder="Frame Type">
                      <Option value="Day">Day</Option>
                      {/* <Option value="Week">Week</Option>
                <Option value="Month">Month</Option> */}
                      <Option value="Session">Session</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      {`Minimum Trials / ${getFieldValue('calFrameDurationType')}`}
                      <Popover
                        content={
                          <div style={{ width: 400 }}>
                            <div>
                              <Text>{masteryCriteriaObject.minTrialsPerDay.explanation}</Text>{' '}
                            </div>
                            <Text>{masteryCriteriaObject.minTrialsPerDay.example}</Text>
                          </div>
                        }
                        title={`Minimum Trials / ${getFieldValue('calFrameDurationType')}`}
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator('minimumTrial', {
                    initialValue: edit
                      ? criteriaTargetObj?.minimumTrial || 0
                      : templateType === 'Regular'
                      ? 15
                      : 0,
                    rules: [{ required: templateType === 'Regular', message: 'Required field' }],
                  })(<InputNumber min={0} style={{ width: '100%' }} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Progress To&nbsp;
                      <Popover
                        content={
                          <div style={{ width: 400 }}>
                            <div>
                              <Text>{masteryCriteriaObject.progressToNextStatus.explanation}</Text>{' '}
                            </div>
                            <Text>{masteryCriteriaObject.progressToNextStatus.example}</Text>
                          </div>
                        }
                        title={masteryCriteriaObject.progressToNextStatus.title}
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator('progressTo', {
                    initialValue: edit && criteriaTargetObj?.progressTo,
                    rules: [{ required: true, message: 'Please provide destination criteria' }],
                  })(
                    <TreeSelect selectable={false} placeholder="Please select" treeDefaultExpandAll>
                      <TreeNode
                        value="mastery_parent"
                        selectable={false}
                        title="Status Select"
                        key="mastery_parent_1"
                      >
                        {getProgessToOptions(true).map(item => {
                          return <TreeNode value={item} title={item} key={item} />
                        })}
                      </TreeNode>
                      <TreeNode selectable={false} title="Other Values" key="mastery_parent_2">
                        {getProgessToOptions().map(item => {
                          return <TreeNode value={item} title={item} key={item} />
                        })}
                      </TreeNode>
                    </TreeSelect>,
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Phase Line Label">
              {getFieldDecorator('phaseChangeLabel', {
                initialValue: edit
                  ? criteriaTargetObj?.phaseChangeLabel
                  : getFieldValue('progressTo'),
                rules: [{ required: true, message: 'Please provide phase line label' }],
              })(<Input placeholder="Enter phase line label" />)}
            </Form.Item>

            <div className="d-flex justify-content-center">
              <Button
                type="primary"
                htmlType="submit"
                style={SUBMITT_BUTTON}
                loading={createCriteriaPhaseTargetLoading || updateCriteriaPhaseTargetLooading}
              >
                {edit ? 'Update' : 'Add'}
              </Button>
            </div>
          </Form>
        </Col>
        <Col span={6}>
          <PhaseExplanation
            templateType={templateType}
            formData={{
              calculationType: getFieldValue('calculationType'),
              responsePercentage: templateType === 'Regular' ? getFieldValue('percentage') : null,
              duration: templateType === BEHAVIOR_RECORDING_DUR ? getFieldValue('duration') : null,
              frequency:
                templateType === BEHAVIOR_RECORDING_FREQ ? getFieldValue('frequency') : null,
              durationCalType: getFieldValue('durationCalType'),
              calFrameType: getFieldValue('calFrameType'),
              calFrameDurationValue: getFieldValue('calFrameDurationValue'),
              calFrameDurationType: getFieldValue('calFrameDurationType'),
              minimumTrial: getFieldValue('minimumTrial'),
              progressTo: getFieldValue('progressTo'),
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default Form.create()(MasteryForm)
