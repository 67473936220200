/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { useSelector } from 'react-redux'
import Stimulus from './Stimulus'
import Default from './Default'
import Step from './Step'

const NormalTarget = ({ updateStartTrialClockTime, updateSessionClockTime }) => {
  const { MasterSession, TargetActiveIndex } = useSelector(state => state.sessionrecording)

  return (
    <>
      {MasterSession.targets.edges[TargetActiveIndex].node.sd.edges.length > 0 ? (
        <>
          <Stimulus
            updateStartTrialClockTime={updateStartTrialClockTime}
            updateSessionClockTime={updateSessionClockTime}
          />
        </>
      ) : MasterSession.targets.edges[TargetActiveIndex].node.steps.edges.length > 0 ? (
        <>
          <Step
            updateStartTrialClockTime={updateStartTrialClockTime}
            updateSessionClockTime={updateSessionClockTime}
          />
        </>
      ) : (
        <>
          <Default
            updateStartTrialClockTime={updateStartTrialClockTime}
            updateSessionClockTime={updateSessionClockTime}
          />
        </>
      )}
    </>
  )
}

export default NormalTarget
