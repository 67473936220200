// import { GraphQLClient } from 'graphql-request'
/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */

import { notification } from 'antd'
import { gql } from 'apollo-boost'
import moment from 'moment'
import apolloClient from '../apollo/config'

export async function getClinicStaffs(payload) {
  return apolloClient
    .query({
      query: gql`
        query(
          $isActive: Boolean
          $before: String
          $after: String
          $first: Int
          $last: Int
          $designation: String
          $email: String
          $clinicLocation: ID
          $tagsList: [String]
          $firstname: String
          $lastname: String
        ) {
          staffs(
            isActive: $isActive
            before: $before
            after: $after
            first: $first
            last: $last
            designation_Icontains: $designation
            clinicLocation: $clinicLocation
            tagsList: $tagsList
            name_Icontains: $firstname
            surname_Icontains: $lastname
            email_Icontains: $email
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                name
                email
                gender
                designation
                dob
                image
                surname
                contactNo
                image
                clinicLocation {
                  id
                  location
                }
                qualification
                workExp
                city
                state
                country
                user {
                  id
                  lastLogin
                }
                position {
                  id
                  title
                }
                userRole {
                  id
                  name
                }
                isActive
                tags {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                credentials {
                  id
                  name
                  goals
                  shortGoal
                  curriculum
                  targetAllocation
                }
              }
            }
          }
        }
      `,
      variables: {
        isActive: payload.isActive,
        before: payload.before,
        after: payload.after,
        first: payload.first,
        last: payload.last,
        designation: payload.designation ? payload.designation : null,
        email: payload.email ? payload.email : null,
        clinicLocation: payload.clinicLocation ? payload.clinicLocation : null,
        tagsList: payload.tagsList ? payload.tagsList : [],
        firstname: payload.firstname ? payload.firstname : null,
        lastname: payload.lastname ? payload.lastname : null,
      },
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong fetching Staffs',
          description: item.message,
        })
      })
    })
}

export async function staffActiveInactive(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation {
        updateStaff(input:{
          staffData:{
            id:"${payload.id}", 
            isActive: ${payload.checked}
          }
        })
        { 
          staff {
            id,
            isActive                    
          }
        }
      }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getStaffDropdown() {
  return apolloClient
    .query({
      query: gql`
        query {
          userRole {
            id
            name
          }
          getStaffPosition {
            id
            title
            createDate
          }
          schoolLocation {
            edges {
              node {
                id
                location
              }
            }
          }
          getStaffCredentials {
            id
            name
            goals
            shortGoal
            targetAllocation
            curriculum
            clinic {
              id
              schoolName
            }
          }
        }
      `,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong fetching staff dropdowns',
          description: item.message,
        })
      })
    })
}

export async function createStaff(payload) {
  console.log('payload staff--->', payload.values, 'data--->', payload.data)

  const variable = {
    staffId: payload.values.staffId,
    doj: payload.values.dateOfJoining
      ? moment(payload.values.dateOfJoining).format('YYYY-MM-DD')
      : null,
    designation: payload.values.designation,
    role: payload.values.role,
    position: payload.values.position,
    location: payload.values.clinicLocation,
    name: payload.values.firstname,
    lastname: payload.values.lastname,
    email: payload.values.email,
    gender: payload.values.gender,
    mobile: payload.values.contactNumber,
    dob: moment(payload.values.dob).format('YYYY-MM-DD'),
    qualification: payload.values.qualification,
    emergencyName: payload.values.emergencyName,
    emergencyContact: payload.values.emergencyContactNumber,
    tags: payload.values.tags,
    country: payload.values.country,
    state: payload.values.state,
    streetAddress: payload.values.street,
    zipCode: payload.values.pincode,
    city: payload.values.city,
    credentials: {
      id: payload.values.credentials,
      name: payload.values.credValue.name,
      goals: payload.values.credValue.longGoal,
      shortGoal: payload.values.credValue.shortGoal,
      targetAllocation: payload.values.credValue.target,
      curriculum: payload.values.credValue.curriculum,
    },
  }

  return apolloClient
    .mutate({
      mutation: gql`
        mutation CreateStaff(
          $staffId: String!
          $doj: Date
          $designation: String
          $role: ID!
          $position: ID
          $location: ID
          $name: String!
          $lastname: String
          $email: String!
          $gender: String
          $mobile: String
          $dob: Date
          $credentials: StaffCredentialsForStaffInput
          $qualification: String
          $emergencyName: String
          $emergencyContact: String
          $tags: [String]
          $city: String
          $country: String
          $state: String
          $streetAddress: String
          $zipCode: String
        ) {
          createStaff(
            input: {
              staffData: {
                staffId: $staffId
                dateOfJoining: $doj
                designation: $designation
                role: $role
                position: $position
                clinicLocation: $location
                firstname: $name
                surname: $lastname
                email: $email
                gender: $gender
                mobile: $mobile
                dob: $dob
                qualification: $qualification
                emergencyName: $emergencyName
                emergencyContact: $emergencyContact
                authLearner: []
                tags: $tags
                city: $city
                country: $country
                state: $state
                streetAddress: $streetAddress
                zipCode: $zipCode
                credentials: $credentials
              }
            }
          ) {
            staff {
              id
              name
              email
              gender
              localAddress
              designation
              empType
              salutation
              qualification
              dateOfJoining
              dob
              surname
              contactNo
              emergencyContact
              emergencyName
              employeeId
              staffId
              credentials {
                id
                name
                goals
                targetAllocation
                curriculum
                shortGoal
              }
              clinicLocation {
                id
                location
              }
              userRole {
                id
                name
              }
              isActive
              tags {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...variable,
      },
    })
    .then(result => {
      console.log('MY RESU', result)
      return result
    })
    .catch(err => {
      err.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function updateStaff(payload) {
  return apolloClient
    .mutate({
      mutation: gql`mutation UpdateStaff (
        $id: ID
        $empId:String
        $designation: String
        $role: ID
        $email: ID
        $firstname: String
        $surname: String
        $gender: String
        $mobile: String
        $address: String
        $dob: Date
        $authLearner: [ID]
        $ssnAadhar: String
        $qualification: String
        $salutation: String
        $emergencyName: String
        $emergencyContact: String
        $shiftStart: String
        $shiftEnd: String
        $taxId: String
        $npi: String
        $maritalStatus: String
        $workExp: String
        $dateOfJoining: Date
        $clinicLocation: ID
        $isActive: Boolean
        $streetAddress: String
        $city: String
        $state: String
        $country: String
        $zipCode: String
        $tags: [String]
        $credentials:ID
      ) {
        updateStaff(input:{
          staffData:{
            id: $id
            empId: $empId
            designation: $designation
            $role: $role
            email: $email
            firstname: $firstname
            surname: $surname
            gender: $gender
            mobile: $mobile
            address: $address
            dob: $dob
            authLearner: $authLearner
            ssnAddhar: $ssnAadhar
            qualification: $qualification
            salutation: $salutation
            emergencyName: $emergencyName
            shiftStart: $shiftStart
            shiftEnd: $shiftEnd
            taxId: $raxId
            noi: $npi
            maritalStatus: $maritalStatus
            workExp: $workExp
            dateOfJoining: $dateOfJoining
            clinicLocation: $clinicLocation
            isActive: $isActive
            streetAddress: $streetAddress
            city: $city
            state: $state
            country: $country
            zipCode: $zipCode
            tags: $tags
            credentials:$credentials
          }
        })
        { 
          staff {
            id
            employeeId
            staffId
            name
            surname
            fatherName
            motherName
            contactNo
            email
            gender
            dob
            dateOfJoining
            credentials {
              id
              name
              goals
              targetAllocation
              curriculum
              shortGoal
            }
            localAddress
            qualification
            designation
            emergencyName
            emergencyContact
            emergencyRelation
            maritalStatus
            workExp
            image
            resume
            joiningLetter
            files {
              edges {
                node {
                  id
                  file
                  fileName
                  fileDescription
                }
              }
            }
            isActive
            school {
              id
              schoolName
            }
            empType
            shiftStart
            shiftEnd
            ssnAadhar
            taxId
            npi
            streetAddress
            city
            state
            country
            zipCode
            salutation
            clinicLocation {
              id
              location
            }
            attendanceSet{
              edges {
                node {
                  id
                  checkIn
                  checkOut
                }
              }
            }
            userRole {
              id
              name
            }
            shift {
              id
              startTime
              endTime
              days {
               edges {
                 node {
                   id
                   name
                 }
               } 
              }
            }
            tags {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }`,
      variables: {
        ...payload.values,
      },
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Something went wrong',
          description: item.message,
        })
      })
    })
}

export async function getStaffProfile(payload) {
  return apolloClient
    .query({
      query: gql`
        query($id: ID!) {
          staff(id: $id) {
            id
            staffId
            employeeId
            name
            surname
            fatherName
            motherName
            contactNo
            email
            gender
            dob
            hrLeavesThisYear
            dateOfJoining
            localAddress
            qualification
            designation
            emergencyName
            emergencyContact
            emergencyRelation
            contactRelation
            maritalStatus
            workExp
            image
            resume
            joiningLetter
            position {
              id
              title
            }
            credentials {
              id
              name
              goals
              shortGoal
              curriculum
              targetAllocation
            }
            files {
              edges {
                node {
                  id
                  fileName
                  fileDescription
                  file
                }
              }
            }
            isActive
            school {
              id
              schoolName
            }
            empType
            shiftStart
            shiftEnd
            ssnAadhar
            taxId
            npi
            streetAddress
            city
            state
            country
            zipCode
            salutation
            clinicLocation {
              id
              location
            }
            attendanceSet {
              edges {
                node {
                  id
                  checkIn
                  checkOut
                }
              }
            }
            userRole {
              id
              name
            }
            user {
              id
              lastLogin
            }
            shift {
              id
              startTime
              endTime
              days {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            tags {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        id: payload.id,
      },
    })
    .then(response => response)
    .catch(error => {
      console.log(error)
    })
}
