import React from 'react'

const RequiredLabel = ({ label, required = false }) => {
  return (
    <>
      {required && <span style={{ color: 'red', fontSize: 14, marginRight: 4 }}>*</span>}
      <span>{label}</span>
    </>
  )
}

export default RequiredLabel
