/* eslint-disable prefer-template */
/* eslint-disable  react-hooks/rules-of-hooks */
/* eslint-disable no-array-constructor */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import { STUDENT_QUERY } from './monthly-reports/monthlyReport.query'
import gql from 'graphql-tag'
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Font } from '@react-pdf/renderer'
import { Typography, Row, Layout, Col, Tabs, Card, Table, Tag, Select, Button } from 'antd'
import moment from 'moment'
import { calculateAge } from '../../utilities'
import { COLORS } from 'assets/styles/globalStyles'
import PdfHeader from './pdf/PdfHeader/PdfHeader'
const LOGO = gql`
  query {
    schoolDetail {
      logo
      schoolName
      address
    }
  }
`

const dateFormat = 'YYYY-MM-DD'
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const page = {
  padding: '20px 15px',
}
const section = {
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
  // justifyContent:"space-between"
}
const sectionTwo = {
  padding: '10px',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}
const flexSection = {
  width: '100%',
  display: 'flex',
  color: 'black',
  flexDirection: 'row',
  padding: '5px 8px',
  textAlign: 'left',
  height: '20px',
}
const dateSection = {
  width: '40%',
  color: 'black',
  fontSize: 12,
  alignSelf: 'flex-start',
  textAlign: 'left',
  fontWeight: '500',
  padding: 2,
  height: '60px',
}

const NetworkPDF = ({ title, start, end, studentId, image, selectedprogram, statusselected }) => {
  console.log('goalsImages>>>', image, start, end, studentId, selectedprogram, statusselected)
  const { data, error, loading } = useQuery(LOGO, { fetchPolicy: 'no-cache' })
  const { data: stData } = useQuery(STUDENT_QUERY, {
    query: STUDENT_QUERY,
    variables: {
      studentId,
    },
    fetchPolicy: 'network-only',
  })
  const [logo, setLogo] = useState()
  console.log(logo)
  const [learnerDetails, setLearnerDetails] = useState()
  useEffect(() => {
    if (data) {
      console.log('logo-->', data)
      setLogo(data.schoolDetail)
    }
  }, [data])

  useEffect(() => {
    if (stData) {
      console.log('stdata>>', stData)
      setLearnerDetails(stData.student)
    }
  }, [stData])

  return (
    <PDFViewer style={{ margin: '0 auto', width: '100%', height: '750px' }}>
      <Document>
        <Page size="A4" wrap={true} style={{ ...page, border: '1px solid black' }} scale={1}>
          <View
            style={{
              display: 'flex',
              textAlign: 'center',
              backgroundColor: COLORS.palleteLight,
              padding: '8px',
              alignItems: 'center',
            }}
          >
            <Text style={{ margin: 'auto' }}>{title} Report</Text>
          </View>

          <View style={{ ...section, height: '100px', padding: '5px' }}>
            <View
              style={{
                width: '60%',
                height: '100%',
              }}
            >
              <View style={{ ...flexSection, paddingBottom: '0' }}>
                <Text style={{ ...dateSection, fontWeight: 'bold' }}>Name:</Text>
                <Text style={{ ...dateSection, width: '60%' }}>
                  {learnerDetails?.firstname} {learnerDetails?.lastname}
                </Text>
              </View>
              <View style={{ ...flexSection, paddingBottom: '0' }}>
                <Text style={{ ...dateSection, fontWeight: 'bold' }}> Date of Birth: </Text>
                <Text style={{ ...dateSection, width: '60%' }}> {learnerDetails?.dob}</Text>
              </View>
              <View style={{ ...flexSection, paddingBottom: '0' }}>
                <Text style={{ ...dateSection, fontWeight: 'bold' }}> Age: </Text>
                <Text style={{ ...dateSection, width: '60%' }}>
                  {' '}
                  {learnerDetails?.dob && calculateAge(learnerDetails?.dob)}
                </Text>
              </View>
              <View style={{ ...flexSection, paddingBottom: '0' }}>
                <Text style={{ ...dateSection, fontWeight: 'bold' }}> Location: </Text>
                <Text style={{ ...dateSection, width: '60%' }}>
                  {' '}
                  {learnerDetails?.currentAddress}
                </Text>
              </View>
              <View style={{ ...flexSection, paddingBottom: '0' }}>
                <Text style={{ ...dateSection, fontWeight: 'bold' }}> From Date: </Text>
                <Text style={{ ...dateSection, width: '60%' }}> {start}</Text>
              </View>
              <View style={{ ...flexSection, paddingBottom: '0' }}>
                <Text style={{ ...dateSection, fontWeight: 'bold' }}> End Date: </Text>
                <Text style={{ ...dateSection, width: '60%' }}> {end}</Text>
              </View>
            </View>
            <View>
              {logo?.logo && (
                <Image
                  src={logo?.logo}
                  style={{ marginLeft: '50px', height: '100px', width: '120px' }}
                ></Image>
              )}
            </View>
          </View>

          <View style={{ ...section, padding: '5px' }}>
            <View
              style={{
                width: '60%',
                height: '100%',
              }}
            >
              <View style={{ ...flexSection, paddingBottom: '0' }}>
                <Text style={{ ...dateSection, fontWeight: 'bold' }}> Status: </Text>
                <Text style={{ ...dateSection, width: '60%' }}> {statusselected}</Text>
              </View>
              <View style={{ ...flexSection, paddingBottom: '0' }}>
                <Text style={{ ...dateSection, fontWeight: 'bold' }}> ProgramArea:</Text>
                <Text style={{ ...dateSection, width: '60%' }}> {selectedprogram}</Text>
              </View>
            </View>
          </View>

          {/* <View
            style={{
              margin: '4px',
              fontSize: 12,
              width: '100%',
              alignSelf: 'flex-start',
              textAlign: 'left',
            }}
          >
            <Text>Status: {statusselected}</Text>
            <Text>ProgramArea: {selectedprogram}</Text>
          </View> */}
          <View>
            <View>
              {image.imageData && (
                <Image
                  src={image.imageData}
                  style={{
                    width: '100%',
                    alignSelf: 'center',
                    margin: '10px auto',
                    height: '70%',
                    marginTop: '40px',
                  }}
                ></Image>
              )}
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default NetworkPDF
