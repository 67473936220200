import actions from './actions'

const initialState = {
  AllocatedTargetsList: [],
  AllocatedTargetListClone: [],
  FetchedStatusList: ['U3RhdHVzVHlwZTox', 'U3RhdHVzVHlwZToz'],
  FamilyMemberList: null,
  AuthStaffList: null,
  AuthFamilyLoading: false,
  MorningSession: null,
  AfternoonSession: null,
  EveningSession: null,
  DefaultSession: null,
  CurrentSession: '',
  loading: true,
  updateSessionLoading: true,
  AllocateLoading: true,
  TargetStatusList: [],
  TargetTypes: [],
  randomKey: Math.random(),
  SessionNames: [],
  SessionNamesLoading: false,
  SessionPrevNames: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
