import actions from './actions'

const initialState = {
  loading: false,
  userCourses: [],
  currentCourseTemplate: null,
  selectedContent: null,
  isVideoContent: true,
  selectedContentTitle: '',
  selectedContentId: null,
  selectedModuleId: null,
  isTest: false,
  selectedTestId: null,
  selectedTestIndex: 0,
  selectedQuestionIndex: 0,
  selectedModuleIndex: 0,
  responseLoading: false,
  testResponses: [],
  userCourseLoading: false,
  allUserCourses: [],
  description: '',
  isAvailabilityLoading: false,
  selectedModuleAvailability: null,
}

export default function goalsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_TEST_RESPONSES: {
      console.log('action--->', action.payload)
      const newTestResponses = state.testResponses
      if (newTestResponses.find(testResponse => testResponse.testId === action.payload.testId)) {
        const tempIndex = newTestResponses.findIndex(
          testResponse => testResponse.testId === action.payload.testId,
        )
        newTestResponses[tempIndex] = {
          ...newTestResponses[tempIndex],
          responses: [...action.payload.data],
        }
        return {
          ...state,
          testResponses: newTestResponses,
        }
      }

      newTestResponses.push({
        testId: action.payload.testId,
        responses: [...action.payload.data],
      })
      return {
        ...state,
        testResponses: newTestResponses,
      }
    }
    case actions.APPEND_TEST_RESPONSES: {
      const newTestResponses = state.testResponses
      const tempIndex = newTestResponses.findIndex(
        testResponse => testResponse.testId === action.payload.courseTest,
      )

      console.log('tempIndex', tempIndex, newTestResponses)
      const newResponses = newTestResponses[tempIndex].responses
      console.log('newResponses', newResponses)

      const resIndex = newResponses.findIndex(response => response.id === action.payload.data.id)
      if (resIndex !== -1) {
        newResponses[resIndex] = action.payload.data
        newTestResponses[tempIndex] = {
          ...newTestResponses[tempIndex],
          responses: newResponses,
        }
        return {
          ...state,
          testResponses: newTestResponses,
        }
      }

      newResponses.push(action.payload.data)
      newTestResponses[tempIndex] = {
        ...newTestResponses[tempIndex],
        responses: newResponses,
      }
      return {
        ...state,
        testResponses: newTestResponses,
      }
    }
    default:
      return state
  }
}
