/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

// export const CREATE_PHASE = gql`
//  mutation createTemplate($studentId: ID, $behavior:ID, $phase:[PhaseInput]) {
// #    createTemplate(input:{templateData:{studentId:$studentId,behavior:$behavior,phase:$phase}){
// #     template{
// #       id
// #       behavior
// #       phase{
// #         content
// #         date
// #       }
// #     }
// #   }
// }`

export const CREATE_PHASE = gql`
  mutation updateTemplate($pk: ID, $studentId: ID, $behavior: ID, $phase: [PhaseInput]) {
    updateTemplate(
      input: {
        templateData: { pk: $pk, studentId: $studentId, behavior: $behavior, phase: $phase }
      }
    ) {
      details {
        id
        behavior {
          id
          behaviorName
          definition
        }
        phase {
          edges {
            node {
              id
              date
              content
            }
          }
        }
      }
    }
  }
`
export const GET_BEHAVIOR_TEMPLATES = gql`
  query getBehaviorTemplates($student: ID!) {
    getBehaviorTemplates(student: $student) {
      edges {
        node {
          id
          templateName
          behavior {
            id
            behaviorName
            definition
          }
          createdAt
          status {
            id
            statusName
          }
          statusrecordSet {
            edges {
              node {
                id
                status {
                  id
                  statusName
                  createdAt
                }
                statusTime
              }
            }
          }
          phase {
            edges {
              node {
                id
                date
                content
              }
            }
          }
          student {
            id
          }
          description
        }
      }
    }
  }
`
