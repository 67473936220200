/* eslint-disable react-hooks/rules-of-hooks */
import { Document, Font, Image, Page, PDFViewer, Text, View } from '@react-pdf/renderer'
import s1 from 'assets/fonts/SourceSerifPro/SourceSerifPro-Regular.ttf'
import s2 from 'assets/fonts/SourceSerifPro/SourceSerifPro-SemiBold.ttf'
import { COLORS } from 'assets/styles/globalStyles'
import LoadingComponent from 'components/VBMappReport/LoadingComponent'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import client from '../../../apollo/config'
import PdfHeader from './PdfHeader/PdfHeader'

Font.register({
  family: 'Source Serif Pro',
  fonts: [
    { src: s1, fontStyle: 'normal', fontWeight: 'light' },
    { src: s2, fontStyle: 'normal', fontWeight: 'bold' },
  ],
})

const sectionMain = {
  width: '100%',
  border: '1px solid black',
  color: 'black',
  height: '100%',
}
const section = {
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}

const dateSection = {
  width: '40%',
  color: 'black',
  fontSize: 12,
  fontWeight: '500',
  padding: 2,
}

const optionFlexSection = {
  width: '100%',
  display: 'flex',
  color: 'black',
  flexDirection: 'row',
  padding: '5px 8px',
}

function ProgressOverView(props) {
  const {
    data,

    graphenddate,
    graphstartdate,
    targetStatus,
    statusselected,
    programArea,
    selectedprogram,
    domainObj,
    domainSelected,
    domainId,
    pdfList,
    allImgObj,
  } = props

  console.log('>>>>>>', data)
  useEffect(() => {
    const startDate = moment(graphstartdate).format('YYYY-MM-DD')
    const endDate = moment(graphenddate).format('YYYY-MM-DD')
    const studentId = localStorage.getItem('studentId')
    console.log(
      'some load in useEffect',
      startDate,
      endDate,
      studentId,
      selectedprogram,
      statusselected,
    )

    if (studentId && startDate && endDate && selectedProgram && statusselected) {
      client
        .query({
          query: gql`
            query domainMastered(
              $studentId: ID
              $startDate: Date
              $endDate: Date
              $selectedprogram: ID
              $statusselected: ID
              $domainId: ID
            ) {
              domainMastered(
                studentId: $studentId
                dateGte: $startDate
                dateLte: $endDate
                programArea: $selectedprogram
                targetStatus: $statusselected
                domainId: $domainId
              ) {
                totalCount
                target {
                  id
                  manualAllocateDomain {
                    id
                    domain
                  }
                  targetId {
                    id
                    domain {
                      id
                      domain
                    }
                  }
                  sd {
                    edges {
                      node {
                        id
                        sd
                      }
                    }
                  }
                  steps {
                    edges {
                      node {
                        id
                        step
                      }
                    }
                  }
                  targetAllcatedDetails {
                    id
                    targetName
                    dateBaseline
                  }
                  intherapyDate
                  masterDate
                }
              }
            }
          `,
          variables: {
            studentId,
            startDate,
            endDate,
            selectedprogram,
            statusselected,
            domainId,
          },
          fetchPolicy: 'network-only',
        })
        .then(result => {
          console.log('result inside pdf', result)
          let resTableData = []
          if (domainSelected && result.data.domainMastered.target) {
            result.data.domainMastered.target.map(item => {
              if (item.targetId && item.targetId.domain.domain === domainSelected) {
                resTableData.push(item)
              }
              return resTableData
            })
          } else {
            resTableData = result.data.domainMastered.target
          }

          setTableData(resTableData)
          setloading(false)
        })
        .catch(error => console.log(error))
    }
  }, [domainSelected, graphenddate, graphstartdate, selectedprogram, statusselected])

  // domainSelected, graphenddate, graphstartdate, selectedprogram, statusselected

  const [tableData, setTableData] = useState([])
  const [loading, setloading] = useState(true)
  const selectedStatus = targetStatus.find(item => item.id === statusselected)
  const selectedProgram = programArea.find(item => item.node.id === selectedprogram)
  const selectedDomain = domainSelected
    ? domainObj.find(item => item.node.domain === domainSelected)
    : null

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div style={{ display: 'flex', marginTop: 20 }}>
      <PDFViewer style={{ margin: 'auto', width: '98%', height: '1200px' }}>
        <Document>
          <Page
            size="A4"
            style={{
              flexDirection: 'row',
              backgroundColor: '#fff',
              padding: 10,
            }}
            wrap
          >
            <View style={{ width: '100%', height: '100%', margin: 'auto' }}>
              <View style={sectionMain}>
                <PdfHeader data={data} type="Progress" />

                {/* <View style={{ ...optionFlexSection, paddingBottom: 0 }}>


                  <Text style={{ ...dateSection, fontWeight: 'bold' }}> Domain : </Text>
                  <Text style={{ ...dateSection, width: '60%' }}>
                    {selectedDomain ? selectedDomain.node.domain : 'ALL'}
                  </Text>
                </View> */}

                <View style={{ ...section, height: '150px', padding: 0 }}>
                  <View
                    style={{
                      width: '60%',
                      height: '100%',
                    }}
                  >
                    <View style={{ ...optionFlexSection }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}>Date:</Text>
                      <Text style={{ ...dateSection, width: '60%' }}>
                        {graphstartdate} - {graphenddate}
                      </Text>
                    </View>
                    <View style={{ ...optionFlexSection }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Status: </Text>
                      <Text style={{ ...dateSection, width: '60%' }}>
                        {selectedStatus.statusName}
                      </Text>
                    </View>
                    <View style={{ ...optionFlexSection }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Program Area: </Text>
                      <Text style={{ ...dateSection, width: '60%' }}>
                        {selectedProgram.node.name}
                      </Text>
                    </View>
                    <View style={{ ...optionFlexSection }}>
                      <Text style={{ ...dateSection, fontWeight: 'bold' }}> Domain : </Text>
                      <Text style={{ ...dateSection, width: '60%' }}>
                        {selectedDomain ? selectedDomain.node.domain : 'ALL'}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{ width: '100%' }}>
                  <View
                    style={{
                      margin: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {allImgObj && allImgObj.pieChart && pdfList.includes('PieChart') && (
                      <Image src={allImgObj?.pieChart} />
                    )}

                    {allImgObj && allImgObj.barChartCanvas && pdfList.includes('Histogram1') && (
                      <Image style={{ width: '50%' }} src={allImgObj?.barChartCanvas} />
                    )}
                  </View>
                </View>
              </View>
            </View>
          </Page>
          <Page
            style={{
              flexDirection: 'row',
              backgroundColor: '#fff',
              padding: 10,
            }}
          >
            <View style={sectionMain}>
              <View>
                {allImgObj && allImgObj.graph && pdfList.includes('Histogram2') && (
                  <Image style={{ width: '100%' }} src={allImgObj.graph} />
                )}
              </View>
              <View>
                {allImgObj && allImgObj.aggregateData && pdfList.includes('Aggregate Graph') && (
                  <Image
                    src={allImgObj.aggregateData}
                    style={{
                      width: '100%',
                      alignSelf: 'center',
                      margin: '10px auto',
                    }}
                  />
                )}
              </View>
            </View>
          </Page>

          {pdfList.includes('Table') && (
            <Page size="A4" style={{ padding: '10px' }}>
              <View
                style={{
                  width: '100%',
                  height: 'auto',
                  border: '1px solid #000',
                  marginTop: '10px',
                  padding: '5px',
                }}
              >
                <View
                  style={{
                    ...section,
                    height: 'auto',
                    padding: 0,
                    justifyContent: 'space-between',
                    borderBottomColor: '#E8E8E8',
                    borderTop: '1px solid #E8E8E8',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10px',
                      width: '15%',
                      borderRight: '1px solid #E8E8E8',
                      borderLeft: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Domain
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      width: '15%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Target Name
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      width: '25%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Stimulus
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      width: '10%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Steps
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      width: '15%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Baseline Date
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      width: '10%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    In-Therapy Date
                  </Text>
                  <Text
                    style={{
                      fontSize: '10px',
                      width: '10%',
                      borderRight: '1px solid #E8E8E8',
                      textAlign: 'center',
                    }}
                  >
                    Mastery date
                  </Text>
                </View>
                {tableData &&
                  tableData.map(item => {
                    console.log(item)
                    console.log(item?.sd?.edges[0]?.node?.sd)
                    return (
                      <View
                        key={item.id}
                        style={{
                          ...section,
                          height: 'auto',
                          padding: 0,
                          justifyContent: 'space-between',
                          borderBottomColor: '#E8E8E8',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: '10px',
                            width: '15%',
                            borderRight: '1px solid #E8E8E8',
                            borderLeft: '1px solid #E8E8E8',
                            padding: '5px',
                          }}
                        >
                          {item.targetId ? item.targetId.domain.domain : 'Other'}
                        </Text>
                        <Text
                          style={{
                            fontSize: '10px',
                            width: '15%',
                            borderRight: '1px solid #E8E8E8',
                            padding: '5px',
                          }}
                        >
                          {item?.targetAllcatedDetails?.targetName}
                        </Text>
                        <Text
                          style={{
                            fontSize: '10px',
                            width: '25%',
                            borderRight: '1px solid #E8E8E8',
                            padding: '5px',
                          }}
                        >
                          {item?.sd?.edges &&
                            item?.sd?.edges.map(sdItem => (
                              <span key={sdItem.node.id}> {sdItem.node.sd} </span>
                            ))}
                        </Text>
                        <Text
                          style={{
                            fontSize: '10px',
                            width: '10%',
                            borderRight: '1px solid #E8E8E8',
                            padding: '5px',
                          }}
                        >
                          {item?.steps?.edges
                            ? item?.steps?.edges.map(stItem => (
                                // eslint-disable-next-line react/jsx-indent
                                <span key={stItem.node.id}> {stItem.node.step} </span>
                              ))
                            : ''}
                        </Text>
                        <Text
                          style={{
                            fontSize: '10px',
                            width: '15%',
                            borderRight: '1px solid #E8E8E8',
                            padding: '5px',
                          }}
                        >
                          {item?.targetAllcatedDetails?.dateBaseline
                            ? moment(item.targetAllcatedDetails.dateBaseline).format('YYYY-MM-DD')
                            : ''}
                        </Text>
                        <Text
                          style={{
                            fontSize: '10px',
                            width: '10%',
                            borderRight: '1px solid #E8E8E8',
                            padding: '5px',
                          }}
                        >
                          {item.intherapyDate ? item.intherapyDate : ''}
                        </Text>
                        <Text
                          style={{
                            fontSize: '10px',
                            width: '10%',
                            borderRight: '1px solid #E8E8E8',
                            padding: '5px',
                          }}
                        >
                          {item.masterDate ? item.masterDate : ''}
                        </Text>
                      </View>
                    )
                  })}
              </View>
            </Page>
          )}
        </Document>
      </PDFViewer>
    </div>
  )
}

export default ProgressOverView
