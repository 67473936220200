import React, { useEffect } from 'react'
import LoadingComponent from 'components/LoadingComponent'
import { useMutation, useQuery, useLazyQuery } from 'react-apollo'
import { SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import { Form, Select, Input, Button, Row, Col, notification } from 'antd'
import { useMasterySeq } from './context'
import { UPDATE_CRITERIA_PHASE, GET_CRITERIA_PHASE_BY_ID } from './query'

const { Item } = Form
const { Option } = Select

const statusMap = [
  { id: 'U3RhdHVzVHlwZTox', status: 'Baseline' },
  { id: 'U3RhdHVzVHlwZToz', status: 'Intervention' },
  { id: 'U3RhdHVzVHlwZToxMA==', status: 'Generalization' },
  { id: 'U3RhdHVzVHlwZTo1', status: 'In-Maintenance' },
  { id: 'U3RhdHVzVHlwZTo0', status: 'Mastered' },
]

function EditCriteriaForm({ form, edit = false, objForEdit, onSuccess, closeModal }) {
  const { getFieldDecorator, validateFields } = form

  const [fetchData, { data: ftData, loading, error }] = useLazyQuery(GET_CRITERIA_PHASE_BY_ID)

  const [updateCriteriaPhase, { loading: criteriaPhaseLoading }] = useMutation(
    UPDATE_CRITERIA_PHASE,
  )
  const { selectedTemplate, promptCodes, promptLoading } = useMasterySeq()

  useEffect(() => {
    if (objForEdit && edit) {
      fetchData({
        variables: {
          id: objForEdit.id,
        },
      })
    }
  }, [objForEdit])

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  const handleSubmit = e => {
    e.preventDefault()
    validateFields((errr, values) => {
      if (!errr && objForEdit?.id) {
        updateCriteriaPhase({
          variables: {
            id: objForEdit.id,
            prompts: values.prompt || [],
            autoLabel: values.label,
          },
        })
          .then(res => {
            notification.success({
              message: 'New criteria addedd successfully',
            })
            if (onSuccess) {
              onSuccess(res)
            }
            if (closeModal) {
              closeModal()
            }
          })
          .catch(err => console.error(err))
      }
    })
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div className="mastery-p">
      <Form style={{ paddingTop: 12 }} onSubmit={handleSubmit}>
        <Row gutter={[24]}>
          <Col span={12}>
            <Item label="Criteria Type">
              {getFieldDecorator('criteriaType', {
                initialValue: ftData?.getCriteriaPhase.criteriaType.id,
                rules: [{ required: true, message: 'Please select criteria type' }],
              })(
                <Select disabled placeholder="Select Criteria Type">
                  {statusMap.map(it => (
                    <Option key={it.id} value={it.id}>
                      {it.status}
                    </Option>
                  ))}
                </Select>,
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Label">
              {getFieldDecorator('label', {
                initialValue: ftData?.getCriteriaPhase.autoLabel || '',
              })(<Input placeholder="Enter label" />)}
            </Item>
          </Col>
        </Row>
        {selectedTemplate.templateType === 'Regular' && (
          <Item label="Prompt">
            {getFieldDecorator('prompt', {
              initialValue: ftData?.getCriteriaPhase.prompts.edges.map(item => item.node.id),
            })(
              <Select loading={promptLoading} placeholder="Select Prompt Codes" mode="multiple">
                {promptCodes?.promptCodes.map(prompt => {
                  return (
                    <Option key={prompt.id} value={prompt.id}>
                      {prompt.promptName}
                    </Option>
                  )
                })}
              </Select>,
            )}
          </Item>
        )}
        <div style={{ display: 'flex' }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={criteriaPhaseLoading}
            style={{ ...SUBMITT_BUTTON, margin: '0 auto' }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default Form.create()(EditCriteriaForm)
