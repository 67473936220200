import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import {
  Button,
  Card,
  Input,
  List,
  Modal,
  Skeleton,
  Form,
  Switch,
  Popconfirm,
  message,
  Radio,
  Row,
  Col,
} from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import LoadingComponent from 'components/LoadingComponent'
import { CREATE_PROMPT, UPDATE_PROMPT, PROMPT_CODES } from './query'
import './Style.scss'

const Prompt = ({ form, InactivePrompts = false }) => {
  const [isActivePrompt, setIsActivePrompt] = useState(true)
  const [editableObj, setEditableObj] = useState(null)
  const [editDrawer, setEditDrawer] = useState(false)
  const [createDrawer, setCreateDrawer] = useState(false)

  const { data, loading, refetch } = useQuery(PROMPT_CODES, {
    variables: { isActive: isActivePrompt },
  })

  useEffect(() => {
    if (InactivePrompts) {
      setIsActivePrompt(false)
    }
  }, [InactivePrompts])

  const [createPrompt] = useMutation(CREATE_PROMPT)
  const [updatePrompt] = useMutation(UPDATE_PROMPT)

  useEffect(() => {
    refetch()
  }, [isActivePrompt])

  const closeEditModal = () => {
    setEditDrawer(false)
    setEditableObj(null)
  }

  const updatePromptHandler = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (err) {
        console.log('====error====', err)
      } else {
        updatePrompt({
          variables: {
            pk: editableObj.id,
            name: values.name,
          },
        })
          .then(res => {
            closeEditModal()
          })
          .catch(error => {
            console.log('error', error)
            closeEditModal()
          })
      }
    })
  }

  const createPromptHandler = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (err) {
        console.log('====error====', err)
      } else {
        createPrompt({
          variables: {
            name: values.name,
          },
        })
          .then(res => {
            setCreateDrawer(false)
            refetch()
          })
          .catch(error => {
            console.log('error', error)
            setCreateDrawer(false)
          })
      }
    })
  }

  const propmptActivationHandler = e => {
    e.preventDefault()
    updatePrompt({
      variables: {
        pk: editableObj.id,
        name: editableObj.promptName,
        isActive: !editableObj.isActive,
      },
    })
      .then(res => {
        setEditableObj(null)
        refetch()
      })
      .catch(error => {
        console.log('error', error)
        setEditableObj(null)
      })
  }

  const switchHandler = item => {
    if (item.school) {
      setEditableObj(item)
    } else {
      message.warning("It's not editable.")
    }
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'right',
        }}
      >
        {/* <h4>Prompt</h4> */}
        {!InactivePrompts && (
          <div>
            <Radio.Group
              size="medium"
              buttonStyle="solid"
              value={isActivePrompt ? 'active' : 'in-active'}
              style={{ margin: 'auto 10px' }}
              onChange={() => {
                setIsActivePrompt(!isActivePrompt)
              }}
            >
              <Radio.Button value="active">Active</Radio.Button>
              <Radio.Button value="in-active">In-active</Radio.Button>
            </Radio.Group>
            <Button
              type="primary"
              style={{ marginBottom: 10, backgroundColor: '#0B35B3' }}
              onClick={() => setCreateDrawer(true)}
            >
              Add Prompt
              <PlusOutlined />
            </Button>
          </div>
        )}
      </div>
      <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Col sm={24} md={24} lg={24}>
          {loading && <LoadingComponent />}
          {!loading && (
            <div className={InactivePrompts ? 'prompt-card' : ''}>
              <Card
                style={{ marginTop: InactivePrompts ? 0 : 20 }}
                title={
                  InactivePrompts ? (
                    <div
                      style={{
                        margin: 'auto',
                        float: 'right',
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}
                    >
                      In-Active Prompts
                    </div>
                  ) : (
                    ''
                  )
                }
              >
                <List
                  style={{ zoom: 1.1 }}
                  size="small"
                  loading={loading}
                  itemLayout="horizontal"
                  dataSource={data?.promptCodes}
                  renderItem={item => (
                    <List.Item
                      key={item.id}
                      actions={[
                        <Button
                          type="link"
                          disabled={!item.school}
                          onClick={() => {
                            setEditDrawer(true)
                            setEditableObj(item)
                          }}
                        >
                          <EditOutlined />
                        </Button>,
                        <Popconfirm
                          title={`Are you sure to ${
                            item.isActive ? 'inactivate' : 'activate'
                          } this prompt?`}
                          onConfirm={propmptActivationHandler}
                          disabled={!item.school}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Switch
                            checked={item.isActive}
                            onClick={() => {
                              switchHandler(item)
                            }}
                          />
                        </Popconfirm>,
                      ]}
                    >
                      <Skeleton avatar title={false} loading={loading} active>
                        <List.Item.Meta title={item.promptName} />
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </Card>
            </div>
          )}
        </Col>
      </Row>

      <Modal
        title="Edit Prompt"
        width={600}
        visible={editDrawer}
        onCancel={() => closeEditModal()}
        footer={null}
        destroyOnClose
      >
        <div>
          <Form onSubmit={updatePromptHandler} style={{ marginTop: 15 }}>
            {form.getFieldDecorator('name', {
              initialValue: editableObj?.promptName,
            })(<Input size="large" placeholder="Prompt name" />)}

            <div style={{ marginTop: 20, textAlign: 'end' }}>
              <Button
                type="primary"
                style={{ backgroundColor: '#0B35B3' }}
                size="large"
                htmlType="submit"
              >
                Update
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Add new Prompt"
        width={600}
        onCancel={() => setCreateDrawer(false)}
        visible={createDrawer}
        destroyOnClose
        footer={null}
      >
        <div>
          <Form onSubmit={createPromptHandler} style={{ marginTop: 15 }}>
            {form.getFieldDecorator('name', {
              initialValue: editableObj?.promptName,
            })(<Input size="large" placeholder="Prompt name" />)}

            <div style={{ marginTop: 20, textAlign: 'end' }}>
              <Button
                type="primary"
                style={{ backgroundColor: '#0B35B3' }}
                size="large"
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const PromptSetting = Form.create()(Prompt)

export default PromptSetting
