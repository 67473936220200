import React, { useState, useEffect } from 'react'
import { Button, Input, notification, Row, Form, Col, Radio } from 'antd'
import gql from 'graphql-tag'
import { useMutation, useLazyQuery } from 'react-apollo'
import { useSelector } from 'react-redux'
import './clinicProfile.scss'
import { EditOutlined } from '@ant-design/icons'
import LoadingComponent from 'components/LoadingComponent'

const GET_USER_SETTINGS = gql`
  query($user: ID) {
    userSettings(user: $user) {
      edges {
        node {
          id
          runDayEndMastery
        }
      }
    }
  }
`

const CHANGE_USER_SETTINGS = gql`
  mutation($user: ID!, $runDayEndMastery: Boolean) {
    changeUserSetting(input: { user: $user, runDayEndMastery: $runDayEndMastery }) {
      details {
        id
        runDayEndMastery
      }
    }
  }
`

const UserRunMasterySetting = ({ form }) => {
  const reduxUser = useSelector(state => state.user)
  const [fetchUser, { data, loading, error }] = useLazyQuery(GET_USER_SETTINGS)
  const [updateUserSettings, { loading: updateLoading }] = useMutation(CHANGE_USER_SETTINGS)

  const [sessionEnd, setSessionEnd] = useState(null)
  useEffect(() => {
    if (reduxUser && reduxUser.id) {
      fetchUser({
        variables: {
          user: reduxUser.id,
        },
      })
    }
  }, [reduxUser])

  useEffect(() => {
    if (data && data.userSettings?.edges) {
      if (data.userSettings?.edges.length > 0) {
        const obj = data.userSettings.edges[0].node
        console.log(obj, ' obj ')
        if (obj.runDayEndMastery) {
          setSessionEnd('day')
        } else {
          setSessionEnd('session')
        }
      }
    }
  }, [data])

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err && reduxUser?.id) {
        updateUserSettings({
          variables: {
            user: reduxUser?.id,
            defaultMasteryDays: values.defaultMasteryDays,
          },
        })
          .then(res => {
            notification.success({
              message: 'Session settings updated successfully',
            })
          })
          .catch(er => console.log(er))
      }
    })
  }

  const tdStyle = { border: '1px solid #dddddd', padding: 8, textAlign: 'center' }

  if (error) {
    return <div>Oops something went wrong</div>
  }

  return (
    <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Col sm={24} md={24} lg={24} style={{ paddingTop: 20 }}>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <Form onSubmit={handleSubmit}>
              <table
                style={{
                  paddingTop: '2em',
                  backgroundColor: 'white',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ ...tdStyle, width: '50%' }}>Run mastery criteria on</td>
                    <td style={{ ...tdStyle, width: '50%' }}>
                      <Radio.Group
                        onChange={e => {
                          console.log(e, ' active ')
                          setSessionEnd(e.target.value)
                          updateUserSettings({
                            variables: {
                              user: reduxUser?.id,
                              runDayEndMastery: e.target.value === 'day',
                            },
                          })
                            .then(res => {
                              console.log(res, ' res')
                              notification.success({
                                message: 'Setting updated successfully',
                              })
                            })
                            .catch(er => console.error(er))
                        }}
                        value={sessionEnd}
                      >
                        <Radio value="day">Day End</Radio>
                        <Radio value="session">Session End</Radio>
                      </Radio.Group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Form>
          </>
        )}
      </Col>
    </Row>
  )
}

export default Form.create()(UserRunMasterySetting)
