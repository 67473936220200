/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { ConsoleSqlOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import { gql } from 'apollo-boost'
import apolloClient from '../apollo/config'

export async function getData(payload) {
  return apolloClient
    .query({
      query: gql`{
        targetAllocates(
          studentId: ${payload.studentId},
          date_Gte: "${payload.startDate}",
          date_Lte: "${payload.endDate}",
          # getAll: true,
        ) {
            totalCount
            masteredCount
            edgeCount
            interventionCount
            edges{
              node{
                id
                targetAllcatedDetails{
                  id
                  targetName
                }
                goalName
                targetStatus{
                  id
                  statusName
                }
                shortTerm{
                  id
                  goalName
                }
                date
              }
            }
        }
        
    }`,
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing went wrong loading Data',
          description: item.message,
        })
      })
    })
}

export async function getSessions(payload) {
  return apolloClient
    .query({
      query: gql`query {
        studentDateRangeSessionData(
          studentId: ${payload.studentId}, 
          startDate: "${payload.startDate}", 
          endDate: "${payload.endDate}"
        ) {
          date
          data {
            id
            name
            itemRequired
            duration
            sessionName {
              id
              name
            }
            instruction {
              edges {
                node {
                  id
                  instruction
                }
              }
            }
            childsessionSet {
              edges {
                node {
                  id
                  status
                }
              }
            }
            sessionHost {
              edges {
                node {
                  id
                  memberName
                }
              }
            }
            targets {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }`,
      fetchPolicy: 'no-cache',
    })
    .then(result => {
      console.log("result -- > sessions for week", result)
      return result
    })
    .catch(error => {
      console.log("Error in week session", error)
    })
}

export async function getAppointments(payload) {
  return apolloClient
    .query({
      query: gql`query {
        appointments(last: 10, student: ${payload.studentId}  ) {
          edges {
            node {
              id
              start
              end
              student {
                id
                firstname
                lastname
                image
                mobileno
              }
              isApproved
              purposeAssignment
              title
              note
              therapist {
                id
                name
                surname
              }
              appointmentStatus {
                id
                appointmentStatus
              }
              location {
                id
                location
              }
            }
          }
        }
      }`,
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      console.log("Error in week session", error)
    })
}

export async function getTasks() {
  return apolloClient
    .query({
      query: gql`
        query {
          tasks(last: 10) {
            edges {
              node {
                id
                taskName
                description
                startDate
                dueDate
                status {
                  id
                  taskStatus
                }
                priority {
                  id
                  name
                }
                taskType {
                  id
                  taskType
                }
                assignWork {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                remainders {
                  edges {
                    node {
                      id
                      time
                      frequency {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
                students {
                  edges {
                    node {
                      id
                      firstname
                    }
                  }
                }
                createdAt
                taskcounterSet {
                  edges {
                    node {
                      id
                      count
                      date
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(result => result)
    .catch(error => {
      error.graphQLErrors.map(item => {
        return notification.error({
          message: 'Somthing want wrong',
          description: item.message,
        })
      })
    })
}
