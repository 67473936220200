import React, { useEffect, useState } from 'react'
import { Select, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useQuery } from 'react-apollo'
import { GET_DATE_RANGE_SETTINGS } from './query'

const { Option } = Select

export default function DropSection(props) {
  const user = useSelector(state => state.user)

  const { dateChange, from, defaultRange = 'Week', setDefaultRange } = props

  const [defaultDate, setDefaultDate] = useState(null)
  const [date, setDate] = useState([moment().subtract(7, 'days'), moment()])
  const { data, loading, error, refetch } = useQuery(GET_DATE_RANGE_SETTINGS, {
    variables: {
      user: user?.role === 'therapist' ? user?.staffObject?.school?.user?.id : user?.id,
    },
  })

  useEffect(() => {
    console.log('initial', date)
    getThis(defaultRange)
  }, [defaultRange])

  useEffect(() => {
    if (data) {
      if (from) {
        const dateFiltered = data.getDateRangeSettings.edges.filter(
          ({ node }) => node.rangeTitle === from,
        )
        setDefaultDate(...dateFiltered)
      }
    }
  }, [data])

  useEffect(() => {
    console.log('effect dateR>>>>-----', date, defaultRange)
    dateChange(date)
  }, [date])

  const getThis = getType => {
    const dateR = []
    console.log('iniside GetType>>>', getType)
    const getQuater = moment(moment()).quarter()
    console.log(`Get quater`, getQuater)
    if (getType === 'Today') {
      const dateRFrom = moment()
      dateR.push(dateRFrom)
      dateR.push(moment())
      setDate(dateR)
      console.log(dateR)
    } else if (getType === 'Week') {
      const dateRFrom = moment().subtract(7, 'd')
      dateR.push(dateRFrom)
      dateR.push(moment())
      setDate(dateR)
      console.log(dateR, ' in value of week ')
    } else if (getType === 'Month') {
      const dateFrom = moment(moment()).subtract(1, 'months')
      dateR.push(dateFrom)
      dateR.push(moment())
      setDate(dateR)
      console.log(dateR)
    } else if (getType === 'Year') {
      const dateFrom = moment().subtract(1, 'year')
      dateR.push(dateFrom)
      dateR.push(moment())
      setDate(dateR)
      console.log(dateR)
    } else if (getType === 'Q1') {
      const dateStart = moment(
        moment()
          .year()
          .toString(),
        'YYYY',
      )
        .quarter(1)
        .startOf('quarter')
      const dateEnd = moment(
        moment()
          .year()
          .toString(),
        'YYYY',
      )
        .quarter(1)
        .endOf('quarter')
      dateR.push(dateStart)
      dateR.push(dateEnd)
      setDate(dateR)
      console.log(dateR)
    } else if (getType === 'Q2') {
      let dateStart
      let dateEnd
      if (getQuater < 2) {
        dateStart = moment((moment().year() - 1).toString(), 'YYYY')
          .quarter(2)
          .startOf('quarter')
        dateEnd = moment((moment().year() - 1).toString(), 'YYYY')
          .quarter(2)
          .endOf('quarter')
      } else {
        dateStart = moment(
          moment()
            .year()
            .toString(),
          'YYYY',
        )
          .quarter(2)
          .startOf('quarter')
        dateEnd = moment(
          moment()
            .year()
            .toString(),
          'YYYY',
        )
          .quarter(2)
          .endOf('quarter')
      }
      dateR.push(dateStart)
      dateR.push(dateEnd)
      setDate(dateR)
      console.log(dateR)
    } else if (getType === 'Q3') {
      let dateStart
      let dateEnd
      if (getQuater < 3) {
        dateStart = moment((moment().year() - 1).toString(), 'YYYY')
          .quarter(3)
          .startOf('quarter')
        dateEnd = moment((moment().year() - 1).toString(), 'YYYY')
          .quarter(3)
          .endOf('quarter')
      } else {
        dateStart = moment(
          moment()
            .year()
            .toString(),
          'YYYY',
        )
          .quarter(3)
          .startOf('quarter')
        dateEnd = moment(
          moment()
            .year()
            .toString(),
          'YYYY',
        )
          .quarter(3)
          .endOf('quarter')
      }
      dateR.push(dateStart)
      dateR.push(dateEnd)
      setDate(dateR)
      console.log(dateR)
    } else if (getType === 'Q4') {
      let dateStart
      let dateEnd
      if (getQuater < 4) {
        dateStart = moment((moment().year() - 1).toString(), 'YYYY')
          .quarter(4)
          .startOf('quarter')
        dateEnd = moment((moment().year() - 1).toString(), 'YYYY')
          .quarter(4)
          .endOf('quarter')
      } else {
        dateStart = moment(
          moment()
            .year()
            .toString(),
          'YYYY',
        )
          .quarter(4)
          .startOf('quarter')
        dateEnd = moment(
          moment()
            .year()
            .toString(),
          'YYYY',
        )
          .quarter(4)
          .endOf('quarter')
      }
      dateR.push(dateStart)
      dateR.push(dateEnd)
      setDate(dateR)
      console.log(dateR)
    } else if (getType === 'default') {
      if (defaultDate) {
        const { isCustomRange, rangeDuration, rangeUnit, customGte, customLte } = defaultDate.node
        if (isCustomRange) {
          console.log('rangeDuration,rangeUnit>>', moment(customGte))
          dateR.push(moment(customGte))
          dateR.push(moment(customLte))
          setDate(dateR)
        } else {
          console.log(
            'rangeDuration,rangeUnit>>',
            rangeDuration,
            rangeUnit,
            rangeUnit.toLowerCase(),
          )
          const dateFrom = moment().subtract(rangeDuration, rangeUnit.toLowerCase())
          dateR.push(dateFrom)
          dateR.push(moment())
          setDate(dateR)
          console.log(dateR)
        }
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = value => {
    getThis(value)
  }

  return (
    <div>
      <Tooltip title="Select Range">
        <Select
          defaultValue={defaultRange}
          style={{
            width: 160,
            borderRadius: 4,
          }}
          onChange={handleChange}
          loading={loading}
        >
          {from && defaultDate && (
            <Option value="default">Default {defaultDate?.node?.rangeTitle}</Option>
          )}
          <Option value="Today">Today</Option>
          <Option value="Week">Last 7 days</Option>
          <Option value="Month">Last 30 days</Option>
          <Option value="Year">This Year</Option>
          <Option value="Q1">First Quater(Q1)</Option>
          <Option value="Q2">Second Quater(Q2)</Option>
          <Option value="Q3">Third Quater(Q3)</Option>
          <Option value="Q4">Fourth Quater(Q4)</Option>
        </Select>
      </Tooltip>
    </div>
  )
}
