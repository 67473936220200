/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_SESSION_RECORDINGS_IN_RANGE = gql`
  query($targets: ID, $date_Gte: Date, $date_Lte: Date) {
    getSessionDataRecording(targets: $targets, date_Gte: $date_Gte, date_Lte: $date_Lte) {
      edges {
        node {
          id
          durationStart
          durationEnd
          ChildSession {
            id
            sessionDate
            sessionNo
            status
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
          targets {
            id
            peakType
            plotZero
            criteriaTemplate {
              id
              name
            }
            mastery {
              edges {
                node {
                  id
                  criteriaPhase {
                    id
                  }
                }
              }
            }
            targetAllcatedDetails {
              id
              targetName
              targetType {
                id
                typeTar
              }
            }
          }
          status {
            id
            statusName
          }
          isPeak
          isPeakEquivalance
          peakEquivalance {
            edges {
              node {
                id
                user {
                  id
                  username
                  firstName
                  lastName
                }
                durationStart
                durationEnd
                recType
                score
                codeClass {
                  id
                  name
                }
                relationTrain {
                  id
                  stimulus1
                  sign12
                  stimulus2
                }
                relationTest {
                  id
                  stimulus1
                  sign12
                  stimulus2
                }
              }
            }
            totalCorrect
            totalError
            totalPrompt
            totalTrial
          }
          peak {
            edges {
              node {
                id
                user {
                  id
                  username
                  firstName
                  lastName
                }
                durationStart
                durationEnd
                trial {
                  edges {
                    node {
                      id
                      start
                      end
                      sd {
                        id
                        sd
                      }
                      marks
                      promptCode {
                        id
                        promptName
                      }
                    }
                  }
                }
              }
            }
            totalCorrect
            totalError
            totalPrompt
            totalTrial
          }
          sessionRecord {
            edges {
              node {
                id
                user {
                  id
                  username
                  firstName
                  lastName
                }
                entryTime
                trial
                durationStart
                durationEnd
                text
                sd {
                  id
                  sd
                }
                step {
                  id
                  step
                }
                promptCode {
                  id
                  promptName
                }
              }
            }
            totalCorrect
            totalIncorrect
            totalError
            totalPrompt
            totalTrial
          }
          isBehReduction
          behReduction {
            totalCorrect
            totalIncorrect
            r1
            r2
            totalError
            totalPrompt
            totalTrial
            edges {
              node {
                id
                user {
                  id
                  username
                  firstName
                  lastName
                }
                trial
                r1Count
                r2Count
                r1 {
                  id
                  behaviorName
                }
                r2 {
                  id
                  behaviorName
                }
              }
            }
          }

          totalBehRecordingDuration
          isBehRecording
          behaviourRecording {
            edges {
              node {
                id
                user {
                  id
                  username
                  firstName
                  lastName
                }
                frequency
                start
                end
              }
            }
          }
        }
      }
    }
  }
`

export const GET_SESSION_RECORDINGS = gql`
  query(
    $targets: ID
    $sessiondate: Date
    $ChildSession: ID
    $ChildSession_Sessions: ID
    $ChildSession_Sessions_SessionName: ID
  ) {
    getSessionRecordings(
      targets: $targets
      sessiondate: $sessiondate
      ChildSession: $ChildSession
      ChildSession_Sessions: $ChildSession_Sessions
      ChildSession_Sessions_SessionName: $ChildSession_Sessions_SessionName
    ) {
      edges {
        node {
          id
          durationStart
          durationEnd
          ChildSession {
            id
            sessionDate
            status
            sessionNo
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
          targets {
            id
            targetAllcatedDetails {
              id
              targetName
            }
          }
          status {
            id
            statusName
          }
          isPeak
          isPeakEquivalance
          peakEquivalance {
            edges {
              node {
                id
                durationStart
                durationEnd
                recType
                score
                codeClass {
                  id
                  name
                }
                relationTrain {
                  id
                  stimulus1
                  sign12
                  stimulus2
                }
                relationTest {
                  id
                  stimulus1
                  sign12
                  stimulus2
                }
              }
            }
          }
          peak {
            edges {
              node {
                id
                durationStart
                durationEnd
                trial {
                  edges {
                    node {
                      id
                      start
                      end
                      sd {
                        id
                        sd
                      }
                      marks
                      promptCode {
                        id
                        promptName
                      }
                    }
                  }
                }
              }
            }
          }
          sessionRecord {
            edges {
              node {
                id
                entryTime
                trial
                durationStart
                durationEnd
                text
                sd {
                  id
                  sd
                }
                step {
                  id
                  step
                }
                promptCode {
                  id
                  promptName
                }
              }
            }
          }
          isBehReduction
          behReduction {
            edges {
              node {
                id
              }
            }
            totalCorrect
            totalIncorrect
            totalError
            totalPrompt
            totalTrial
            r1
            r2
          }
        }
      }
    }
  }
`

export const GET_BEHAVIOR_SESSION_RECORDINGS = gql`
  query($targets: ID, $sessiondate: Date, $ChildSession: ID) {
    getSessionRecordings(
      targets: $targets
      sessiondate: $sessiondate
      ChildSession: $ChildSession
    ) {
      edges {
        node {
          id
          durationStart
          durationEnd
          ChildSession {
            id
            sessionNo
            sessionDate
            status
            sessions {
              id
              sessionName {
                id
                name
              }
            }
          }
          targets {
            id
            targetAllcatedDetails {
              id
              targetName
            }
          }
          status {
            id
            statusName
          }
          isBehRecording
          behaviourRecording {
            edges {
              node {
                id
                frequency
                start
                end
              }
            }
          }
        }
      }
    }
  }
`

export const GET_SD_COMBINATIONS = gql`
  query($code: String) {
    getPeakEquCodes(code: $code) {
      edges {
        node {
          id
          code
          train {
            edges {
              node {
                id
                stimulus1
                sign12
                stimulus2
                sign23
                stimulus3
              }
            }
          }
          test {
            edges {
              node {
                id
                stimulus1
                sign12
                stimulus2
                sign23
                stimulus3
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ALLOCATED_TARGET = gql`
  query($id: ID!) {
    targetAllocate(id: $id) {
      id
      date
      createdAt
      peakType
      r1 {
        id
        behaviorName
      }
      r2 {
        id
        behaviorName
      }
      targetStatus {
        id
        statusName
      }
      sd {
        edges {
          node {
            id
            sd
          }
        }
      }
      steps {
        edges {
          node {
            id
            step
          }
        }
      }
      sbtSteps {
        edges {
          node {
            id
            description
            status {
              id
              statusName
            }
            mastery {
              id
              masteryType
              noOfProblemBehavior
              gte
              lte
              consecutiveDays
              minTrial
              fromStatus {
                id
                statusName
              }
              toStatus {
                id
                statusName
              }
            }
          }
        }
      }
      peakBlocks
      peakType
      classes {
        edges {
          node {
            id
            name
            stimuluses {
              edges {
                node {
                  id
                  option
                  stimulusName
                }
              }
            }
          }
        }
      }
      eqCode
      criteriaPhase {
        id
        criteriaType {
          statusName
          id
        }
        criteriaphasetargetsSet {
          edges {
            node {
              id
              calculationType
              percentage
              duration
              calFrameType
              minimumTrial
              calFrameDurationType
              calFrameDurationValue
              phaseChangeLabel
            }
          }
        }
      }
      mastery {
        edges {
          node {
            id
            sd {
              id
              sd
            }
            step {
              id
              step
            }
            status {
              id
              statusName
            }
            criteriaPhase {
              id
              criteriaType {
                id
                statusName
              }
              stepNo
              criteriaphasetargetsSet {
                edges {
                  node {
                    id
                    calculationType
                    percentage
                    calFrameType
                    calFrameDurationType
                    calFrameDurationValue
                    minimumTrial
                    duration
                    frequency
                    progressTo
                    phaseChangeLabel
                  }
                }
              }
            }
          }
        }
      }
      targetAllcatedDetails {
        id
        targetName
        DailyTrials
        targetType {
          id
          typeTar
        }
      }
      targetStatusDateSet {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            date
            targetStatusId {
              id
              statusName
              colorCode
            }
          }
        }
      }
      phaset {
        edges {
          node {
            id
            date
            content
          }
        }
      }
      baselineDate
      intherapyDate
      masterDate
      inmaintainenceDate
      date
      time
    }
  }
`

export const GET_TODAYS_SESSION = gql`
  query($studentId: ID!, $date: Date, $targets: [ID]) {
    GetStudentSession(studentId: $studentId, date: $date, targets: $targets) {
      edges {
        node {
          id
          name
          duration
          sessionName {
            id
            name
          }
          childsessionSet {
            edges {
              node {
                id
                sessionNo
                status
              }
            }
          }
        }
      }
    }
  }
`

export const GET_SESSION_BEHAVIOR_RECORD = gql`
  query($targets: ID, $date: Date, $ChildSession_Sessions_SessionName: ID) {
    getSessionDataRecording(
      targets: $targets
      date: $date
      ChildSession_Sessions_SessionName: $ChildSession_Sessions_SessionName
    ) {
      edges {
        node {
          id
          isBehRecording
          behaviourRecording {
            edges {
              node {
                id
                frequency
                start
                end
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_TARGET = gql`
  mutation($pk: ID!, $phaset: [PhaseInput], $shortTerm: ID) {
    updateTargetAllocate2(
      input: { pk: $pk, phaset: $phaset, targetData: { shortTerm: $shortTerm } }
    ) {
      targetName
      target {
        id
        date
        phaset {
          edges {
            node {
              id
              date
              content
            }
          }
        }
      }
    }
  }
`
