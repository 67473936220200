/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'

import {
  Tabs,
  Input,
  Button,
  Drawer,
  Layout,
  Modal,
  Select,
  Popconfirm,
  notification,
  Tooltip,
  Form,
  Row,
  Col,
} from 'antd'
import { gql } from 'apollo-boost'
import { ALL_STUDENT } from './query'
import style from './style.module.scss'

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
// import "react-tabs/style/react-tabs.css";

const { TabPane } = Tabs
const { Option } = Select

const GET_STUDENT = gql`
  query {
    student {
      id
      name
    }
  }
`

const GET_VIDEO_URL = gql`
  query VideoUrl($videoid: ID) {
    videoUrl(id: $videoid) {
      url
      name
    }
  }
`

const AWS_VIDEO_LIST = gql`
  query {
    videos(isAws: false) {
      id
      name
      url
    }
  }
`

const CREATE_VIDEO = gql`
  mutation AnnotationVideo($student: ID!, $url: String!, $name: String) {
    createVideoAnnotation(input: { learner: $student, url: $url, name: $name }) {
      video {
        id
        url
        name
      }
    }
  }
`

const AddVideo = ({ form, setAnnotationVideo, refetch }) => {
  const { getFieldDecorator } = form

  const [student, setStudent] = useState('')

  const annotationVideo = JSON.parse(localStorage.getItem('annotationVideo'))

  let videoUrl = ''
  try {
    videoUrl = annotationVideo.url
  } catch {
    console.log('err')
  }

  const [videourl, setvideourl] = useState(videoUrl)
  const [videoname, videonameChange] = useState('')
  const [awsvideo, setAwsvideo] = useState(annotationVideo?.id)
  const [tabSelected, settabSelected] = useState('IBM Cloud')

  const { loading, error, data } = useQuery(ALL_STUDENT)

  const {
    loading: videoLoading,
    error: AwsvideoError,
    data: AwsvideoData,
    refetch: refetchVideos,
  } = useQuery(AWS_VIDEO_LIST, {
    fetchPolicy: 'network-only',
  })

  const [
    awsVideoUrl,
    { loading: videoUrlLoading, error: AwsvideoUrlError, data: AwsvideoUrlData },
  ] = useLazyQuery(GET_VIDEO_URL)

  const [
    videoVariableData,
    { data: videoData, error: videoError, loading: newVideoLoading },
  ] = useMutation(CREATE_VIDEO)

  const setSelectedStudent = e => {
    setStudent(e.target.value)
  }

  const urlChange = e => {
    setvideourl(e.target.value)
  }
  const videoNameChange = e => {
    videonameChange(e.target.value)
  }

  useEffect(() => {
    awsVideoUrl({
      variables: { videoid: awsvideo },
    })
  }, [awsvideo])

  useEffect(() => {
    if (AwsvideoUrlData && awsvideo) {
      localStorage.setItem(
        'annotationVideo',
        JSON.stringify({
          id: awsvideo,
          url: AwsvideoUrlData.videoUrl.url,
          name: AwsvideoUrlData.videoUrl.name,
        }),
      )
      setAnnotationVideo(awsvideo, AwsvideoUrlData.videoUrl.url, AwsvideoUrlData.videoUrl.name)
    }
  }, [AwsvideoUrlData])

  const submitAwsVideo = e => {
    e.preventDefault()
    form.validateFields(['ibmVideo'], async (err, values) => {
      console.log('Values>>>', values, AwsvideoUrlData)
      if (!err) {
        setAwsvideo(values.ibmVideo)
        refetch()
      }
    })
  }

  const submitVideo = e => {
    e.preventDefault()
    form.validateFields(['student', 'videoName', 'videoUrl'], async (err, values) => {
      console.log('Values>>>', values)
      if (!err) {
        videoVariableData({
          variables: {
            student: values.student,
            url: values.videoUrl,
            name: values.videoName,
          },
        }).then(res => {
          refetch()
          refetchVideos()
        })
      }
    })
  }

  useEffect(() => {
    if (videoData) {
      console.log('Videodata>>>', videoData)
      localStorage.setItem(
        'annotationVideo',
        JSON.stringify({
          id: videoData.createVideoAnnotation.video.id,
          url: videoData.createVideoAnnotation.video.url,
          name: videoData.createVideoAnnotation.video.name,
        }),
      )
      setAnnotationVideo(
        videoData.createVideoAnnotation.video.id,
        videoData.createVideoAnnotation.video.url,
      )

      notification.success({
        message: 'Video add sucessfully',
      })
    }

    if (videoError) {
      notification.error({
        message: 'Adding video failed',
      })
    }
  }, [videoData, videoError])

  // if (loading) return 'Loading...'
  // if (error) return `Error! ${error.message}`

  // if (videoUrlLoading) return 'Loading...'
  // if (AwsvideoUrlError) return `Error! ${AwsvideoUrlError.message}`

  // if (videoLoading) return 'Loading...'
  // if (AwsvideoError) return `Error! ${AwsvideoError.message}`
  console.log('awsvideo>>>', AwsvideoData)
  return (
    <div className={style.cardDiv}>
      <Tabs
        type="card"
        className="prefereditems"
        activeKey={tabSelected}
        style={{
          fontSize: 18,
        }}
        onChange={index => settabSelected(index)}
      >
        <TabPane tab="IBM Cloud" key="IBM Cloud">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={style.content} style={{ width: '80%', margin: 0 }}>
              <Form.Item label="Select IBM Video :">
                {getFieldDecorator(`ibmVideo`, {
                  initialValue: annotationVideo?.id,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please Select IBM video.',
                    },
                  ],
                })(
                  <Select
                    placeholder="Select IBM Video"
                    value={awsvideo}
                    loading={videoLoading}
                    showSearch
                    optionFilterProp="name"
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    {AwsvideoData &&
                      AwsvideoData.videos.map(number => (
                        <Option value={number.id}>{number.name}</Option>
                      ))}
                  </Select>,
                )}
              </Form.Item>
            </div>
            <div
              className={style.centeredDiv}
              style={{ margin: 'auto', padding: 13, width: '20%' }}
            >
              <Button
                type="primary"
                size="large"
                onClick={submitAwsVideo}
                style={{
                  borderRadius: 6,
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </TabPane>
        <TabPane tab="From URL" key="From URL">
          <Form onSubmit={submitVideo}>
            <Row gutter={[10, 10]}>
              <Col span={7}>
                <Form.Item label="Student:">
                  {getFieldDecorator(`student`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please Select Student.',
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select Student"
                      loading={loading}
                      showSearch
                      optionFilterProp="name"
                      style={{ width: '100%', margin: 5 }}
                    >
                      {data &&
                        data.students.edges.map(({ node }) => (
                          <Option key={node.id} value={node.id} name={node.firstname}>
                            {`${node.firstname} ${node.lastname || ''}`}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Video Name:">
                  {getFieldDecorator(`videoName`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please Enter Video Name',
                      },
                    ],
                  })(
                    <Input
                      placeholder="Video Name"
                      // value={videoname}
                      onChange={videoNameChange}
                      className={style.inputField}
                      style={{ width: '100%', margin: 5 }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Video URL:">
                  {getFieldDecorator(`videoUrl`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please Select VideoURL.',
                      },
                    ],
                  })(
                    <Input
                      placeholder="Video URL"
                      className={style.inputField}
                      // value={videourl}
                      onChange={urlChange}
                      style={{ width: '100%', margin: 5 }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={3} style={{ display: 'flex', justifyContent: 'center', top: 20 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={newVideoLoading}
                  size="large"
                  style={{
                    borderRadius: 6,
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Form.create()(AddVideo)
