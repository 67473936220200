/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import groupObj from '@hunters/group-object'
import { Drawer, Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { groupBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from 'react-apollo'
import AnimateFromRight from 'components/ScreenChangeAnimation/AnimateFromRight'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import BehaviorGraph from './behaviorGraph'
import { GET_ALLOCATED_TARGET, GET_SESSION_RECORDINGS_IN_RANGE } from './query'

import DailyResponseRateGraph from './responseRateGraph'
import SbtGraph from './sbtGraph'

function TargetResponseRate({
  visible,
  setVisible,
  selectedTarget,
  defaultDate,
  defaultRange,
  setDefaultRange,
  setDefaultDate,
  peakSd,
  refetchData,
  getNextTarget,
  getPrevTarget,
  nextDisable,
  prevDisable,
  from,
  setSelectedRowKeys,
  selectedRowKeys,
}) {
  const student = useSelector(state => state.student)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [date, setDate] = useState(
    defaultDate && defaultDate.length === 2
      ? [moment(defaultDate[0]), moment(defaultDate[1])]
      : [moment(Date.now()).subtract(12, 'days'), moment(Date.now()).subtract(0, 'days')],
  )
  const { data, loading, error, refetch } = useQuery(GET_SESSION_RECORDINGS_IN_RANGE, {
    fetchPolicy: 'network-only',
    variables: {
      targets: selectedTarget?.id,
      date_Gte: date[0].format('YYYY-MM-DD'),
      date_Lte: date[1].format('YYYY-MM-DD'),
    },
  })

  const [
    getTarget,
    { data: targetData, loading: targetLoading, error: errorTarget },
  ] = useLazyQuery(GET_ALLOCATED_TARGET)

  const [mainTargetType, setMainTargetType] = useState(null)

  const [daysList, setDaysList] = useState([])
  const [tableData, setTableData] = useState([])
  const [graphAxixData, setGraphAxixData] = useState(null)
  const [behaviorGraphData, setBehaviorGraphData] = useState([])
  const [behTherapistData, setBehTherapistData] = useState(null)
  const [sbtGraphData, setsbtGraphData] = useState([])
  const [baseline, setBaseline] = useState(null)
  const [mainTargetTrial, setMainTargetTrial] = useState([])
  const [storyRegular, setStoryRegular] = useState(null)
  const [byTherapist, setByTherapist] = useState(null)
  const [mastery, setMastery] = useState(null)

  useEffect(() => {
    if (selectedTarget?.id && date) {
      getTarget({
        variables: {
          id: selectedTarget.id,
        },
        fetchPolicy: 'network-only',
      })
    }
  }, [selectedTarget, date])

  useEffect(() => {
    if (error) {
      console.error(error)
    }
    if (errorTarget) {
      console.error(errorTarget)
    }
  }, [error, errorTarget])

  useEffect(() => {
    if (data && data.getSessionDataRecording) {
      const tempDateElement = []
      for (let i = 0; i < data.getSessionDataRecording.edges.length; i += 1) {
        const dateElement = data.getSessionDataRecording.edges[i].node
        tempDateElement.push({
          date: dateElement.ChildSession.sessionDate,
          behaviourRecording: dateElement.behaviourRecording,
        })
      }

      const groupedDateElement = groupBy(tempDateElement, 'date')

      const dateKeys = Object.keys(groupedDateElement)

      const tempMainBehRecording = []
      dateKeys.forEach(dateK => {
        const tempDateBehRecording = []
        groupedDateElement[dateK].forEach(dateValue => {
          const {
            behaviourRecording: { edges: dateBehEdges },
          } = dateValue
          if (dateBehEdges.length > 0) {
            dateBehEdges.forEach(dateBeh => {
              tempDateBehRecording.push(dateBeh)
            })
          }
        })
        tempMainBehRecording.push({ date: dateK, behaviourRecording: tempDateBehRecording })
      })
      const d = []
      let day = date[0]
      while (day <= date[1]) {
        d.push(day.format('YYYY-MM-DD'))
        day = day.clone().add(1, 'd')
      }

      const dl = d.map(dateStr => ({
        monthYear: moment(dateStr)
          .format('MMM')
          .concat(moment(dateStr).format('YYYY')),
        date: moment(dateStr).format('YYYY-MM-DD'),
        month: moment(dateStr).format('MMM'),
        dayDate: moment(dateStr).format('DD'),
        day: moment(dateStr).format('dddd'),
        year: moment(dateStr).format('YYYY'),
      }))

      const groupedData = groupObj.group(dl, 'monthYear')

      let keys = []
      keys = Object.keys(groupedData)
      keys.reverse()
      const mainTempTrial = []
      keys.forEach(monthYear => {
        const testTemp2 = []

        groupedData[monthYear].forEach(item => {
          const tempTrial = {}
          tempMainBehRecording.forEach(({ date: behDate, behaviourRecording }) => {
            if (item.date === behDate) {
              tempTrial.day = behDate
              behaviourRecording.forEach(({ node: edENode }, edIndex) => {
                const { end, start } = edENode
                tempTrial[`trial-${edIndex + 1}`] = ((end - start) / 1000).toFixed()
              })
            } else {
              tempTrial.day = item.date
            }
          })
          testTemp2.push(tempTrial)
        })
        mainTempTrial.push(testTemp2)
      })
      setMainTargetTrial(mainTempTrial)
    }
  }, [data, date])

  useEffect(() => {
    if (data && data.getSessionDataRecording && targetData) {
      const d = []
      let day = date[0]
      while (day <= date[1]) {
        d.push(day.format('YYYY-MM-DD'))
        day = day.clone().add(1, 'd')
      }

      const dl = d.map(dateStr => ({
        monthYear: moment(dateStr)
          .format('MMM')
          .concat(moment(dateStr).format('YYYY')),
        date: moment(dateStr).format('YYYY-MM-DD'),
        month: moment(dateStr).format('MMM'),
        dayDate: moment(dateStr).format('DD'),
        day: moment(dateStr).format('dddd'),
        year: moment(dateStr).format('YYYY'),
      }))

      setDaysList(dl)

      if (data.getSessionDataRecording.edges?.length > 0) {
        const targetType =
          data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetType
            ?.typeTar
        const peakType = data.getSessionDataRecording.edges[0].node.targets?.peakType

        let tempData = { children: [] }
        let therapist = { children: [] }
        if (targetType && targetType === 'Behavior Recording') {
          tempData = getBehaviorData(dl)
          therapist = getByTherapistBehavior(dl)
          generateTherapistBehaviorGraph(therapist, dl)
          generateBehaviorGraph(tempData, dl)
        } else if (targetType && targetType === 'Skill Based Treatment') {
          tempData = getSbtData(dl)
          // therapist=getByTherapistSBT(dl)
          // generateTherapistSBTGraph(therapist, dl)
          generateSbtGraph(tempData, dl)
        } else {
          if (targetType && targetType === 'Coded') {
            tempData = getPeakData(d)
            if (peakType.toLowerCase() !== 'equivalence') {
              therapist = getByTherapistPeak(d)
            }
          } else {
            tempData = getRegularData(d)
            therapist = getByTherapistRegular(d)
          }
          d.forEach(tempDate => {
            for (let i = 0; i < tempData.children.length; i += 1) {
              const childObj = tempData.children[i]
              if (childObj[tempDate]) {
                if (tempData[tempDate]) {
                  tempData[tempDate] = {
                    correct: tempData[tempDate].correct + childObj[tempDate].correct,
                    incorrect: tempData[tempDate].incorrect + childObj[tempDate].incorrect,
                    prompt: tempData[tempDate].prompt + childObj[tempDate].prompt,
                    total: tempData[tempDate].total + childObj[tempDate].total,
                  }
                } else {
                  tempData = {
                    ...tempData,
                    user: childObj.sessionRecord,
                    [tempDate]: {
                      correct: childObj[tempDate].correct,
                      incorrect: childObj[tempDate].incorrect,
                      prompt: childObj[tempDate].prompt,
                      total: childObj[tempDate].total,
                    },
                  }
                }
              } else {
                tempData.children[i][tempDate] = {
                  correct: null,
                  incorrect: null,
                  prompt: null,
                  total: null,
                }
                if (!tempData[tempDate]) {
                  tempData = {
                    ...tempData,
                    [tempDate]: {
                      correct: null,
                      incorrect: null,
                      prompt: null,
                      total: null,
                    },
                  }
                }
              }
            }
          })
          d.forEach(tempDate => {
            for (let i = 0; i < therapist.length; i += 1) {
              for (let j = 0; j < therapist[i].children.length; j += 1) {
                const childObj = therapist[i].children[j]
                if (childObj[tempDate]) {
                  if (therapist[i][tempDate]) {
                    therapist[i][tempDate] = {
                      correct: therapist[i][tempDate].correct + childObj[tempDate].correct,
                      incorrect: therapist[i][tempDate].incorrect + childObj[tempDate].incorrect,
                      prompt: therapist[i][tempDate].prompt + childObj[tempDate].prompt,
                      total: therapist[i][tempDate].total + childObj[tempDate].total,
                    }
                  } else {
                    therapist[i] = {
                      ...therapist[i],
                      user: childObj.therapist,
                      [tempDate]: {
                        correct: childObj[tempDate].correct,
                        incorrect: childObj[tempDate].incorrect,
                        prompt: childObj[tempDate].prompt,
                        total: childObj[tempDate].total,
                      },
                    }
                  }
                } else {
                  therapist[i].children[j][tempDate] = {
                    correct: null,
                    incorrect: null,
                    prompt: null,
                    total: null,
                  }
                  if (!therapist[i][tempDate]) {
                    therapist[i] = {
                      ...therapist[i],
                      [tempDate]: {
                        correct: null,
                        incorrect: null,
                        prompt: null,
                        total: null,
                      },
                    }
                  }
                }
              }
            }
          })
          generatePeakTherapistGraph(therapist, dl)
          generatePeakGraph(tempData, dl)
        }
        setTableData(tempData)
      } else {
        setTableData({ children: [] })
        generatePeakGraph({ children: [] }, dl)
        generateSbtGraph({ children: [] }, dl)
        generateBehaviorGraph({ children: [] }, dl)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, date, targetData])

  useEffect(() => {
    if (targetData && targetData.targetAllocate) {
      if (peakSd) {
        if (
          targetData.targetAllocate.targetAllcatedDetails?.targetType?.typeTar ===
          'Skill Based Treatment'
        ) {
          const mastery =
            targetData.targetAllocate.sbtSteps.edges.length > 0 &&
            targetData.targetAllocate.sbtSteps.edges.filter(
              ({ node }) => node.description === peakSd,
            )
          mastery.length > 0 ? setMastery(mastery[0].node.mastery) : setMastery(null)
        } else {
          const mastery =
            targetData.targetAllocate.mastery.edges.length > 0 &&
            targetData.targetAllocate.mastery.edges.filter(
              ({ node }) => node?.sd?.sd === peakSd || node?.step?.step === peakSd,
            )
          mastery.length > 0 ? setMastery(mastery[0].node.criteriaPhase) : setMastery(null)
        }
      } else {
        setMastery(targetData.targetAllocate.criteriaPhase)
      }
      setMainTargetType(targetData.targetAllocate.targetAllcatedDetails?.targetType?.typeTar)
      setBaseline({
        baseline: targetData.targetAllocate.baselineDate,
        master: targetData.targetAllocate.masterDate,
        therapy: targetData.targetAllocate.intherapyDate,
        inmaintainence: targetData.targetAllocate.inmaintainenceDate,
        phaseSet: targetData.targetAllocate.phaset ? targetData.targetAllocate.phaset.edges : [],
        dateStatus: targetData.targetAllocate.targetStatusDateSet,
      })
    } else {
      setBaseline({
        baseline: '',
        master: '',
        therapy: '',
        inmaintainence: '',
        phaseSet: [],
        dateStatus: null,
      })
      setMastery(null)
    }
  }, [targetData, data])

  const getBehaviorData = d => {
    const tempData = {
      target: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
      targetId: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.id,
      type: 'target',
      plotZero: data.getSessionDataRecording.edges[0].node.targets?.plotZero,
      children: [],
    }
    data.getSessionDataRecording.edges?.forEach(({ node: nd }) => {
      const tempDate = nd.ChildSession?.sessionDate
      let freq = 0
      let duration = 0
      nd.behaviourRecording.edges.forEach(({ node }) => {
        duration += Math.abs(Number(node.end) - Number(node.start))
        freq += node.frequency
      })
      if (tempData[tempDate]) {
        tempData[tempDate] = {
          frequency: tempData[tempDate].frequency + freq,
          duration: tempData[tempDate].duration + duration,
        }
      } else {
        tempData[tempDate] = {
          frequency: freq,
          duration,
        }
      }
    })

    d.forEach(tempDate => {
      if (!tempData[tempDate]) {
        tempData[tempDate] = {
          frequency: null,
          duration: null,
        }
      }
    })
    return tempData
  }

  const getSbtData = d => {
    let tempData = {
      target: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
      targetId: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.id,
      type: 'target',
      children: [],
      r1Name: null,
      r2Name: null,
    }

    data.getSessionDataRecording.edges?.forEach(({ node: nd }) => {
      if (nd.behReduction?.edges.length > 0) {
        const tempDate = nd.ChildSession?.sessionDate
        const { r1, r2, totalCorrect, totalIncorrect, totalTrial } = nd.behReduction

        nd.behReduction.edges.forEach(item => {
          tempData = {
            ...tempData,
            r1Name: item.node.r1?.behaviorName || tempData.r1Name,
            r2Name: item.node.r2?.behaviorName || tempData.r2Name,
          }
        })
        if (tempData[tempDate]) {
          tempData[tempDate] = {
            r1: tempData[tempDate].r1 + r1,
            r2: tempData[tempDate].r2 + r2,
            totalIncorrect: tempData[tempDate].totalIncorrect + totalIncorrect,
            totalCorrect: tempData[tempDate].totalCorrect + totalCorrect,
            totalTrial: tempData[tempDate].totalTrial + totalTrial,
          }
        } else {
          tempData[tempDate] = {
            r1,
            r2,
            totalCorrect,
            totalIncorrect,
            totalTrial,
          }
        }
      }
    })

    d.forEach(tempDate => {
      if (!tempData[tempDate]) {
        tempData[tempDate] = {
          r1: 0,
          r2: 0,
          totalCorrect: 0,
          totalIncorrect: 0,
          totalTrial: 0,
        }
      }
    })

    return tempData
  }

  // const getByTherapistSBT = d => {
  //   const sessionRecord = []
  //   let behName={
  //     r1Name: null,
  //     r2Name: null,
  //   }
  //   data.getSessionDataRecording.edges.forEach(({ node }) => {
  //     console.log('*****getsessionitem>>>', node.sessionRecord)
  //     const { r1, r2, totalCorrect, totalIncorrect, totalTrial } = node.behReduction

  //     node.behReduction.edges.length > 0 &&
  //       node.behReduction.edges.forEach(({ node: nd }) => {
  //         console.log('<<<BehItem>>>', nd)
  //         behName = {
  //           ...behName,
  //           r1Name: nd.r1?.behaviorName || behName.r1Name,
  //           r2Name: nd.r2?.behaviorName || behName.r2Name,
  //         }
  //         sessionRecord.push({
  //           ...nd,
  //           sessionDate: node.ChildSession.sessionDate,
  //         })
  //       })
  //   })
  //   console.log('<<<<SBTsessionRecord>>>', sessionRecord,behName)
  //   const t1 = []
  //   const targetName =
  //     data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName
  //   const group = groupBy(sessionRecord, node => node.user?.id)
  //   console.log('<<<BehgroupbyTherapist>>>', group)
  //   Object.keys(group).map((it, index) => {
  //     console.log('<<Behit>>>>', it)
  //     let tempData = {
  //       target:
  //         data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
  //       targetId: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.id,
  //       // therapist:group[it][0].user.firstName,
  //       plotZero: data.getSessionDataRecording.edges[0].node.targets?.plotZero,
  //       type: 'target',
  //       record: false,
  //       children: [],
  //     }
  //     let freq = 0
  //     let duration = 0
  //     group[it].map(item => {
  //       tempData = {
  //         ...tempData,
  //         r1Name: behName.r1Name,
  //         r2Name: behName.r2Name,
  //         therapist: `${item.user?.firstName} ${item.user?.lastName || ''}`,
  //       }
  //       const tempDate = moment(item.sessionDate).format('YYYY-MM-DD')
  //       // duration+=item.duration
  //       // freq+=1
  //       if (tempData[tempDate]) {
  //         console.log(">>>>>item.tempDate>>>",item,tempData[tempDate],tempDate);
  //         tempData[tempDate] = {
  //           r1: tempData[tempDate].r1 + item.r1Count,
  //           r2: tempData[tempDate].r2 + item.r2Count,
  //           totalIncorrect: tempData[tempDate].totalIncorrect + (item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null),
  //           totalCorrect: tempData[tempDate].totalCorrect +(item.trial === 'CORRECT' ? 1 : null),
  //           totalTrial: tempData[tempDate].totalTrial + 1,
  //         }
  //       } else {
  //         tempData[tempDate] = {
  //           r1:item.r1Count,
  //           r2:item.r2Count,
  //           totalCorrect: item.trial === 'CORRECT' ? 1 : null,
  //           totalIncorrect: item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null,
  //           // totalPrompt: item.trial === 'PROMPT' ? 1 : null,
  //           totalTrial: 1,
  //         }
  //       }
  //     })
  //     d.forEach(tDate => {
  //       if (!tempData[tDate]) {
  //         tempData[tDate] = {
  //           frequency: null,
  //           duration: null,
  //         }
  //       }
  //     })
  //     t1.push(tempData)
  //   })
  //   console.log('<<<SBTBehgrouped by therapist>>>', t1)
  //   return t1
  // }

  const getPeakData = () => {
    const tempData = {
      target: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
      targetId: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.id,
      type: 'target',
      plotZero: data.getSessionDataRecording.edges[0].node.targets?.plotZero,
      children: [],
    }
    tempData.children[0] = {
      correct: 0,
      incorrect: 0,
      prompt: 0,
      total: 0,
      target: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
    }
    const targetName =
      data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName
    data.getSessionDataRecording.edges?.forEach(({ node: nd }) => {
      const peakType = nd.targets?.peakType
      const tempDate = nd.ChildSession?.sessionDate

      if (peakType && peakType.toLowerCase() === 'equivalence') {
        if (nd.peakEquivalance.edges.length !== 0) {
          nd.peakEquivalance.edges?.forEach(({ node }) => {
            let sd
            if (node.recType === 'TRAIN') {
              const kk = Object.keys(node.relationTrain)
              sd = `${node.relationTrain[kk[1]]}${node.relationTrain[kk[2]]}${
                node.relationTrain[kk[3]]
              }`
            } else if (node.recType === 'TEST') {
              const kk = Object.keys(node.relationTest)
              sd = `${node.relationTest[kk[1]]}${node.relationTest[kk[2]]}${
                node.relationTest[kk[3]]
              }`
            }

            if (peakSd && peakSd !== sd) {
              return
            }

            let check = false
            let idx = -1
            for (let i = 0; i < tempData.children.length; i += 1) {
              if (tempData.children[i].target === sd) {
                check = true
                idx = i
              }
            }

            if (check) {
              if (tempData.children[idx][tempDate]) {
                tempData.children[idx][tempDate] = {
                  correct:
                    tempData.children[idx][tempDate].correct + (node.score === 10 ? 1 : null),
                  incorrect:
                    tempData.children[idx][tempDate].incorrect + (node.score === 0 ? 1 : null),
                  prompt:
                    tempData.children[idx][tempDate].prompt +
                    (node.score === 2 || node.score === 4 || node.score === 8 ? 1 : null),
                  total: tempData.children[idx][tempDate].total + 1,
                }
              } else {
                tempData.children[idx][tempDate] = {
                  correct: node.score === 10 ? 1 : null,
                  incorrect: node.score === 0 ? 1 : null,
                  prompt: node.score === 2 || node.score === 4 || node.score === 8 ? 1 : null,
                  total: 1,
                }
              }

              tempData.children[idx] = {
                ...tempData.children[idx],
                correct: tempData.children[idx].correct + (node.score === 10 ? 1 : null),
                incorrect: tempData.children[idx].incorrect + (node.score === 0 ? 1 : null),
                prompt:
                  tempData.children[idx].prompt +
                  (node.score === 2 || node.score === 4 || node.score === 8 ? 1 : null),
                total: tempData.children[idx].total + 1,
              }
            } else {
              if (tempData.children.length !== 0) {
                const index = tempData.children.findIndex(item => item.target === targetName)
              }
              tempData.children.push({
                target: sd,
                key: Math.random(),
                type: 'sd',

                [tempDate]: {
                  correct: node.score === 10 ? 1 : null,
                  incorrect: node.score === 0 ? 1 : null,
                  prompt: node.score === 2 || node.score === 4 || node.score === 8 ? 1 : null,
                  total: 1,
                },
                correct: node.score === 10 ? 1 : null,
                prompt: node.score === 2 || node.score === 4 || node.score === 8 ? 1 : null,
                incorrect: node.score === 0 ? 1 : null,
                total: 1,
                sessionRecord: nd.peakEquivalance,
              })
            }
          })
        } else if (data.getSessionDataRecording.edges[0].node.targets?.plotZero) {
          tempData.children[0] = {
            ...tempData.children[0],
            key: Math.random(),
            [tempDate]: {
              correct: 0,
              incorrect: 0,
              prompt: 0,
              total: 0,
            },
          }
        }
      } else {
        nd.peak?.edges.forEach(({ node }) => {
          if (node.trial?.edges?.length !== 0) {
            node.trial?.edges?.forEach(({ node: item }) => {
              if (item.marks !== null && item.marks !== undefined) {
                let check = false
                let idx = -1

                if (peakSd && peakSd !== item.sd?.sd) {
                  return
                }

                for (let i = 0; i < tempData.children.length; i += 1) {
                  if (tempData.children[i].target === item.sd?.sd) {
                    check = true
                    idx = i
                    break
                  }
                }
                if (check) {
                  if (tempData.children[idx][tempDate]) {
                    tempData.children[idx][tempDate] = {
                      correct:
                        tempData.children[idx][tempDate].correct + (item.marks === 10 ? 1 : null),
                      incorrect:
                        tempData.children[idx][tempDate].incorrect + (item.marks === 0 ? 1 : null),
                      prompt:
                        tempData.children[idx][tempDate].prompt +
                        (item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null),
                      total: tempData.children[idx][tempDate].total + 1,
                    }
                  } else {
                    tempData.children[idx][tempDate] = {
                      correct: item.marks === 10 ? 1 : null,
                      incorrect: item.marks === 0 ? 1 : null,
                      prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                      total: 1,
                    }
                  }

                  tempData.children[idx] = {
                    ...tempData.children[idx],
                    correct: tempData.children[idx].correct + (item.marks === 10 ? 1 : null),
                    incorrect: tempData.children[idx].incorrect + (item.marks === 0 ? 1 : null),
                    prompt:
                      tempData.children[idx].prompt +
                      (item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null),
                    total: tempData.children[idx].total + 1,
                  }
                } else if (item.sd?.sd) {
                  tempData.children.push({
                    key: Math.random(),
                    target: item.sd?.sd,
                    targetId: item.sd?.id,
                    type: 'sd',
                    [tempDate]: {
                      correct: item.marks === 10 ? 1 : null,
                      incorrect: item.marks === 0 ? 1 : null,
                      prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                      total: 1,
                    },
                    correct: item.marks === 10 ? 1 : null,
                    incorrect: item.marks === 0 ? 1 : null,
                    prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                    total: item.marks !== null ? 1 : 0,
                    sessionRecord: nd.peak,
                  })
                } else {
                  tempData.children.push({
                    key: Math.random(),
                    target: item.sd?.sd,
                    targetId: item.sd?.id,
                    type: 'target',
                    [tempDate]: {
                      correct: item.marks === 10 ? 1 : null,
                      incorrect: item.marks === 0 ? 1 : null,
                      prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                      total: item.marks !== null ? 1 : 0,
                    },
                    correct: item.marks === 10 ? 1 : null,
                    incorrect: item.marks === 0 ? 1 : null,
                    prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                    total: item.marks !== null ? 1 : 0,
                    sessionRecord: nd.peak,
                  })
                }
              }
            })
          } else if (data.getSessionDataRecording.edges[0].node.targets?.plotZero) {
            tempData.children[0] = {
              ...tempData.children[0],
              key: Math.random(),
              target: targetName,
              [tempDate]: {
                ...tempData.children[0][tempDate],
                correct: 0,
                incorrect: 0,
                prompt: 0,
                total: 0,
              },
            }
          }
        })
      }
    })

    return tempData
  }

  const getByTherapistRegular = () => {
    const sessionRecord = []
    data.getSessionDataRecording.edges.forEach(({ node }) => {
      node.sessionRecord.edges.length > 0 &&
        node.sessionRecord.edges.forEach(({ node: nd }) => {
          sessionRecord.push({
            ...nd,
            sessionDate: node.ChildSession.sessionDate,
          })
        })
    })
    const t1 = []
    const targetName =
      data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName
    const group = groupBy(sessionRecord, node => node.user?.id)
    Object.keys(group).forEach((it, index) => {
      const tempData = {
        target:
          data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
        targetId: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.id,
        // therapist:group[it][0].user.firstName,
        plotZero: data.getSessionDataRecording.edges[0].node.targets?.plotZero,
        type: 'target',
        record: false,
        children: [],
      }
      group[it].forEach(item => {
        if (item.trial !== null && item.trial !== undefined) {
          const tempDate = moment(item.sessionDate).format('YYYY-MM-DD')

          let checkSd = `${item.user?.firstName} ${item.user?.lastName || ''}`
          let type = 'sd'
          if (item.sd) {
            type = 'sd'
            checkSd = item.sd?.sd
          } else if (item.step) {
            type = 'step'
            checkSd = item.step?.step
          }

          if (peakSd && peakSd !== checkSd) {
            return
          }

          let check = false
          let idx = -1

          for (let i = 0; i < tempData.children.length; i += 1) {
            if (tempData.children[i].target === checkSd) {
              check = true
              idx = i
              break
            }
          }

          if (check) {
            if (tempData.children[idx][tempDate]) {
              tempData.children[idx][tempDate] = {
                correct:
                  tempData.children[idx][tempDate].correct + (item.trial === 'CORRECT' ? 1 : null),
                incorrect:
                  tempData.children[idx][tempDate].incorrect +
                  (item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null),
                prompt:
                  tempData.children[idx][tempDate].prompt + (item.trial === 'PROMPT' ? 1 : null),
                total: tempData.children[idx][tempDate].total + 1,
              }
            } else {
              tempData.children[idx][tempDate] = {
                correct: item.trial === 'CORRECT' ? 1 : null,
                incorrect: item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null,
                prompt: item.trial === 'PROMPT' ? 1 : null,
                total: 1,
              }
            }

            tempData.children[idx] = {
              ...tempData.children[idx],
              correct: tempData.children[idx].correct + (item.trial === 'CORRECT' ? 1 : null),
              incorrect:
                tempData.children[idx].incorrect +
                (item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null),
              prompt: tempData.children[idx].prompt + (item.trial === 'PROMPT' ? 1 : null),
              total: tempData.children[idx].total + 1,
            }
          } else {
            tempData.children.push({
              key: Math.random(),
              target: checkSd,
              therapist: `${item.user?.firstName} ${item.user?.lastName || ''}`,
              targetId: type === 'sd' ? item.sd?.id : item.step?.id,
              type,
              [tempDate]: {
                correct: item.trial === 'CORRECT' ? 1 : null,
                incorrect: item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null,
                prompt: item.trial === 'PROMPT' ? 1 : null,
                total: 1,
                record: true,
              },
              correct: item.trial === 'CORRECT' ? 1 : null,
              incorrect: item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null,
              prompt: item.trial === 'PROMPT' ? 1 : null,
              total: 1,
            })
          }
        }
      })
      t1.push(tempData)
    })
    return t1
  }

  const getByTherapistPeak = () => {
    const sessionRecord = []
    data.getSessionDataRecording.edges.forEach(({ node }) => {
      const peakType = node.targets?.peakType
      if (peakType && peakType.toLowerCase() === 'equivalence') {
        node.peakEquivalance.edges.length > 0 &&
          node.peakEquivalance.edges.forEach(({ node: nd }) => {
            sessionRecord.push({
              ...nd,
              sessionDate: node.ChildSession.sessionDate,
            })
          })
      } else {
        node.peak.edges.length > 0 &&
          node.peak.edges.forEach(({ node: nd }) => {
            nd.trial.edges.length > 0 &&
              sessionRecord.push({
                ...nd,
                sessionDate: node.ChildSession.sessionDate,
              })
          })
      }
    })
    const t1 = []
    const targetName =
      data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName
    const group = groupBy(sessionRecord, node => node.user?.id)
    Object.keys(group).forEach((it, index) => {
      const tempData = {
        target:
          data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
        targetId: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.id,
        // therapist:group[it][0].user.firstName,
        plotZero: data.getSessionDataRecording.edges[0].node.targets?.plotZero,
        type: 'target',
        record: false,
        children: [],
      }
      group[it].forEach(tr => {
        const tempDate = moment(tr.sessionDate).format('YYYY-MM-DD')

        tr.trial?.edges.forEach(({ node: item }) => {
          if (item.marks !== null && item.marks !== undefined) {
            let check = false
            let idx = -1
            const checkSd = `${tr.user?.firstName} ${tr.user?.lastName || ''}`

            if (peakSd && peakSd !== item.sd?.sd) {
              return
            }

            for (let i = 0; i < tempData.children.length; i += 1) {
              if (tempData.children[i].target === checkSd) {
                check = true
                idx = i
                break
              }
            }
            if (check) {
              if (tempData.children[idx][tempDate]) {
                tempData.children[idx][tempDate] = {
                  correct:
                    tempData.children[idx][tempDate].correct + (item.marks === 10 ? 1 : null),
                  incorrect:
                    tempData.children[idx][tempDate].incorrect + (item.marks === 0 ? 1 : null),
                  prompt:
                    tempData.children[idx][tempDate].prompt +
                    (item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null),
                  total: tempData.children[idx][tempDate].total + 1,
                }
              } else {
                tempData.children[idx][tempDate] = {
                  correct: item.marks === 10 ? 1 : null,
                  incorrect: item.marks === 0 ? 1 : null,
                  prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                  total: 1,
                }
              }

              tempData.children[idx] = {
                ...tempData.children[idx],
                correct: tempData.children[idx].correct + (item.marks === 10 ? 1 : null),
                incorrect: tempData.children[idx].incorrect + (item.marks === 0 ? 1 : null),
                prompt:
                  tempData.children[idx].prompt +
                  (item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null),
                total: tempData.children[idx].total + 1,
              }
            } else if (item.sd?.sd) {
              tempData.children.push({
                key: Math.random(),
                target: checkSd,
                targetId: item.sd?.id,
                therapist: `${tr.user?.firstName} ${tr.user?.lastName || ''}`,

                type: 'sd',
                [tempDate]: {
                  correct: item.marks === 10 ? 1 : null,
                  incorrect: item.marks === 0 ? 1 : null,
                  prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                  total: 1,
                },
                correct: item.marks === 10 ? 1 : null,
                incorrect: item.marks === 0 ? 1 : null,
                prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                total: item.marks !== null ? 1 : 0,
              })
            } else {
              tempData.children.push({
                key: Math.random(),
                target: checkSd,
                targetId: item.sd?.id,
                therapist: `${tr.user?.firstName} ${tr.user?.lastName || ''}`,

                type: 'target',
                [tempDate]: {
                  correct: item.marks === 10 ? 1 : null,
                  incorrect: item.marks === 0 ? 1 : null,
                  prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                  total: item.marks !== null ? 1 : 0,
                },
                correct: item.marks === 10 ? 1 : null,
                incorrect: item.marks === 0 ? 1 : null,
                prompt: item.marks === 2 || item.marks === 4 || item.marks === 8 ? 1 : null,
                total: item.marks !== null ? 1 : 0,
              })
            }
          }
        })
      })
      t1.push(tempData)
    })
    return t1
  }

  const getByTherapistBehavior = d => {
    const sessionRecord = []
    data.getSessionDataRecording.edges.forEach(({ node }) => {
      node.behaviourRecording.edges.length > 0 &&
        node.behaviourRecording.edges.forEach(({ node: nd }) => {
          sessionRecord.push({
            freq: 1,
            user: nd.user,
            duration: Math.abs(Number(nd.end) - Number(nd.start)),
            sessionDate: node.ChildSession.sessionDate,
          })
        })
    })
    const t1 = []
    const targetName =
      data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName
    const group = groupBy(sessionRecord, node => node.user?.id)
    Object.keys(group).forEach((it, index) => {
      let tempData = {
        target:
          data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
        targetId: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.id,
        // therapist:group[it][0].user.firstName,
        plotZero: data.getSessionDataRecording.edges[0].node.targets?.plotZero,
        type: 'target',
        record: false,
        children: [],
      }
      group[it].forEach(item => {
        tempData = {
          ...tempData,
          therapist: `${item.user?.firstName} ${item.user?.lastName || ''}`,
        }
        const tempDate = moment(item.sessionDate).format('YYYY-MM-DD')
        // duration+=item.duration
        // freq+=1
        if (tempData[tempDate]) {
          tempData[tempDate] = {
            frequency: tempData[tempDate].frequency + item.freq,
            duration: tempData[tempDate].duration + item.duration,
          }
        } else {
          tempData[tempDate] = {
            frequency: item.freq,
            duration: item.duration,
          }
        }
      })
      d.forEach(tDate => {
        if (!tempData[tDate]) {
          tempData[tDate] = {
            frequency: null,
            duration: null,
          }
        }
      })
      t1.push(tempData)
    })
    return t1
  }

  const generateTherapistBehaviorGraph = (target, dl) => {
    const tempGraphAxixData = []

    const allocatedDate = moment(targetData?.targetAllocate.date).format('YYYY-MM-DD')

    const groupedData = groupObj.group(dl, 'monthYear')
    let keys = []
    const demo = []

    for (let i = 0; i < target.length; i += 1) {
      keys = Object.keys(groupedData)
      keys.reverse()
      const tt = []

      keys.forEach(monthYear => {
        const d1 = new Date(moment(monthYear, 'MMMYYYY').format('YYYY-MM-DD'))
        const d2 = new Date(moment(allocatedDate, 'MMMYYYY').format('YYYY-MM-DD'))
        if (d1 >= d2) {
          const tempData = {
            month: groupedData[monthYear][0].month,
            year: groupedData[monthYear][0].year,
            key: `DailyResponseRate ${groupedData[monthYear][0].month} ${groupedData[monthYear][0].year}`,
            data: [],
            a: 'a',
          }

          groupedData[monthYear].forEach(item => {
            if (
              moment(item.date).format('YYYY-MM-DD') >= moment(allocatedDate).format('YYYY-MM-DD')
            ) {
              const idx = demo.findIndex(it => it.id === target[i].therapist)
              if (idx !== -1) {
                const targetPer = target[i][item.date]
                  ? [target[i][item.date].duration, target[i][item.date].frequency]
                  : [null, null]
                demo[idx].data.push({
                  x: item.date,
                  y: targetPer,
                  key: item.date,
                  date: item.date,
                  target: target[i].target,
                  plotZero: target[i].plotZero,
                  record: target[i].record,
                })
              } else {
                demo.push({
                  id: target[i].therapist,
                  data: [],
                  key: target[i].therapist,
                })
              }
            }
          })
        }
      })
    }

    demo.length > 0 ? setBehTherapistData(demo) : setBehTherapistData(null)
    return tempGraphAxixData
  }

  const getRegularData = () => {
    const tempData = {
      target: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName,
      targetId: data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.id,
      plotZero: data.getSessionDataRecording.edges[0].node.targets?.plotZero,
      type: 'target',
      record: false,
      children: [],
    }
    const targetName =
      data.getSessionDataRecording.edges[0].node.targets?.targetAllcatedDetails?.targetName

    tempData.children[0] = {
      correct: 0,
      incorrect: 0,
      prompt: 0,
      total: 0,
      target: targetName,
    }
    data.getSessionDataRecording.edges?.forEach(({ node: nd }) => {
      const tempDate = nd.ChildSession?.sessionDate
      if (nd.sessionRecord.edges.length !== 0) {
        const totalCorrect = nd.sessionRecord.totalCorrect
        const totalTrial = nd.sessionRecord.totalTrial
        const pr = Number((totalCorrect / totalTrial) * 100)

        nd.sessionRecord.edges?.forEach(({ node: item }) => {
          if (item.trial !== null && item.trial !== undefined) {
            let checkSd = targetName
            let type = 'sd'
            if (item.sd) {
              type = 'sd'
              checkSd = item.sd?.sd
            } else if (item.step) {
              type = 'step'
              checkSd = item.step?.step
            }

            if (peakSd && peakSd !== checkSd) {
              return
            }

            let check = false
            let idx = -1

            for (let i = 0; i < tempData.children.length; i += 1) {
              if (tempData.children[i].target === checkSd) {
                check = true
                idx = i
                break
              }
            }

            if (check) {
              if (tempData.children[idx][tempDate]) {
                tempData.children[idx][tempDate] = {
                  correct:
                    tempData.children[idx][tempDate].correct +
                    (item.trial === 'CORRECT' ? 1 : null),
                  incorrect:
                    tempData.children[idx][tempDate].incorrect +
                    (item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null),
                  prompt:
                    tempData.children[idx][tempDate].prompt + (item.trial === 'PROMPT' ? 1 : null),
                  total: tempData.children[idx][tempDate].total + 1,
                }
              } else {
                tempData.children[idx][tempDate] = {
                  correct: item.trial === 'CORRECT' ? 1 : null,
                  incorrect: item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null,
                  prompt: item.trial === 'PROMPT' ? 1 : null,
                  total: 1,
                }
              }

              tempData.children[idx] = {
                ...tempData.children[idx],
                correct: tempData.children[idx].correct + (item.trial === 'CORRECT' ? 1 : null),
                incorrect:
                  tempData.children[idx].incorrect +
                  (item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null),
                prompt: tempData.children[idx].prompt + (item.trial === 'PROMPT' ? 1 : null),
                total: tempData.children[idx].total + 1,
              }
            } else {
              tempData.children.push({
                key: Math.random(),
                target: checkSd,
                targetId: type === 'sd' ? item.sd?.id : item.step?.id,
                type,
                [tempDate]: {
                  correct: item.trial === 'CORRECT' ? 1 : null,
                  incorrect: item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null,
                  prompt: item.trial === 'PROMPT' ? 1 : null,
                  total: 1,
                  record: true,
                },
                correct: item.trial === 'CORRECT' ? 1 : null,
                incorrect: item.trial === 'INCORRECT' || item.trial === 'ERROR' ? 1 : null,
                prompt: item.trial === 'PROMPT' ? 1 : null,
                total: 1,
                sessionRecord: item.user,
                pr,
              })
            }
          }
        })
      } else if (data.getSessionDataRecording.edges[0].node.targets?.plotZero) {
        tempData.children[0] = {
          ...tempData.children[0],
          record: false,
          key: Math.random(),
          [tempDate]: {
            correct: 0,
            incorrect: 0,
            prompt: 0,
            total: 0,
            record: false,
          },
        }
      }
    })
    return tempData
  }

  const generateSbtGraph = (target, dl) => {
    const tempGraphAxixData = []
    const groupedData = groupObj.group(dl, 'monthYear')
    const allocatedDate = targetData?.targetAllocate.date
    const dataKeys = ['r1', 'r2', 'totalCorrect', 'totalIncorrect']
    let keys = []
    keys = Object.keys(groupedData)

    keys.reverse()
    keys.forEach(monthYear => {
      const tt = []
      const d1 = new Date(moment(monthYear, 'MMMYYYY').format('YYYY-MM-DD'))
      const d2 = new Date(moment(allocatedDate, 'MMMYYYY').format('YYYY-MM-DD'))
      if (d1 >= d2) {
        dataKeys.forEach(dataKey => {
          const tempData = {
            month: groupedData[monthYear][0].month,
            year: groupedData[monthYear][0].year,
            key: `DailyResponseRate ${groupedData[monthYear][0].month} ${groupedData[monthYear][0].year}`,
            data: [],
            a: 'a',
          }
          groupedData[monthYear].forEach(item => {
            const targetPer = target[item.date] ? target[item.date][dataKey] : 0

            tempData.data.push({
              x: item.dayDate,
              y: targetPer,
              key: item.dayDate,
              date: item.date,
              target:
                dataKey === 'r1' ? target.r1Name : dataKey === 'r2' ? target.r2Name : target.target,
              type: 'target',
              targetId: target.targetId,
              total: (target[item.date] && target[item.date].totalTrial) || 0,
            })

            if (dataKey === 'r1') {
              tempData.id = target.r1Name
            } else if (dataKey === 'r2') {
              tempData.id = target.r2Name
            } else if (dataKey === 'totalIncorrect') {
              tempData.id = 'Total Incorrect'
            } else {
              tempData.id = `${target.target} - Correct`
            }
          })

          tt.push(tempData)
        })
        tempGraphAxixData.push(tt)
      }
    })
    tempGraphAxixData.length > 0 ? setsbtGraphData(tempGraphAxixData) : setsbtGraphData(null)
    return tempGraphAxixData
  }

  const generateBehaviorGraph = (target, dl) => {
    const tempGraphAxixData = []

    const allocatedDate = moment(targetData?.targetAllocate.date).format('YYYY-MM-DD')

    const groupedData = groupObj.group(dl, 'monthYear')
    let keys = []
    keys = Object.keys(groupedData)
    keys.reverse()
    const tt = []

    keys.forEach(monthYear => {
      const d1 = new Date(moment(monthYear, 'MMMYYYY').format('YYYY-MM-DD'))
      const d2 = new Date(moment(allocatedDate, 'MMMYYYY').format('YYYY-MM-DD'))
      if (d1 >= d2) {
        const tempData = {
          month: groupedData[monthYear][0].month,
          year: groupedData[monthYear][0].year,
          key: `DailyResponseRate ${groupedData[monthYear][0].month} ${groupedData[monthYear][0].year}`,
          data: [],
          a: 'a',
        }

        groupedData[monthYear].forEach(item => {
          const targetPer = target[item.date]
            ? [target[item.date].duration, target[item.date].frequency]
            : [null, null]
          if (
            moment(item.date).format('YYYY-MM-DD') >= moment(allocatedDate).format('YYYY-MM-DD')
          ) {
            tempData.data.push({
              x: item.dayDate,
              y: targetPer,
              key: item.dayDate,
              date: item.date,
              target: target.target,
              plotZero: target.plotZero,
              type: 'target',
              targetId: target.targetId,
            })
            tempData.id = target.target
          }
        })

        tt.push(tempData)
      }
    })

    tt.length > 0 ? setBehaviorGraphData(tt) : setBehaviorGraphData(null)
    return tempGraphAxixData
  }

  const generatePeakTherapistGraph = (target, dl) => {
    const tempGraphAxixData = []
    const allocatedDate = moment(targetData?.targetAllocate.date).format('YYYY-MM-DD')

    const groupedData = groupObj.group(dl, 'monthYear')

    let keys = []
    const demo = []
    for (let i = 0; i < target.length; i += 1) {
      const obj = {
        id: target[i].user,
        data: [],
        key: target[i].user,
      }
      demo.push(obj)
      keys = Object.keys(groupedData)
      keys.reverse()
      keys.forEach(monthYear => {
        const tt = []
        const d1 = new Date(moment(monthYear, 'MMMYYYY').format('YYYY-MM-DD'))
        const d2 = new Date(moment(allocatedDate, 'MMMYYYY').format('YYYY-MM-DD'))

        //   (moment(monthYear).format('YYYY') === moment(allocatedDate).format('YYYY') &&
        //   moment(monthYear).format('MM') >= moment(allocatedDate).format('MM')) ||
        // moment(monthYear).format('YYYY') > moment(allocatedDate).format('YYYY')
        if (d1 >= d2) {
          groupedData[monthYear].forEach(item => {
            if (
              moment(item.date).format('YYYY-MM-DD') >= moment(allocatedDate).format('YYYY-MM-DD')
            ) {
              const idx = demo.findIndex(it => it.id === target[i].user)
              if (idx !== -1) {
                const targetPer =
                  target[i][item.date] && target[i][item.date].correct !== null
                    ? (target[i][item.date].correct / (target[i][item.date].total || 1)) * 100
                    : null
                demo[idx].data.push({
                  x: item.date,
                  y: targetPer !== null ? targetPer.toFixed(2) : null,
                  key: item.date,
                  date: item.date,
                  target: target[i].target,
                  plotZero: target[i].plotZero,
                  total:
                    (target[i][item.date] &&
                      target[i][item.date].correct !== null &&
                      target[i][item.date].total) ||
                    1,
                  ...target[i][item.date],

                  record: target[i].record,
                  // type: target.children.length > 1 ? 'target with children' : 'target',
                  // targetId: target.targetId,
                })
              }
            }
          })
        }
      })
    }

    // tempGraphAxixData.length > 0 ? setGraphAxixData(tempGraphAxixData) : setGraphAxixData(null)
    demo.length > 0 ? setByTherapist(demo) : setByTherapist(null)

    return demo
  }

  const generatePeakGraph = (target, dl) => {
    const tempGraphAxixData = []
    const allocatedDate = moment(targetData?.targetAllocate.date).format('YYYY-MM-DD')

    const groupedData = groupObj.group(dl, 'monthYear')

    let keys = []
    const demo = []
    const obj = {
      id: target.target,
      data: [],
      key: target.target,
    }
    if (peakSd === null || peakSd === undefined) {
      demo.push(obj)
    }
    keys = Object.keys(groupedData)
    keys.reverse()
    keys.forEach(monthYear => {
      const tt = []
      const d1 = new Date(moment(monthYear, 'MMMYYYY').format('YYYY-MM-DD'))
      const d2 = new Date(moment(allocatedDate, 'MMMYYYY').format('YYYY-MM-DD'))

      //   (moment(monthYear).format('YYYY') === moment(allocatedDate).format('YYYY') &&
      //   moment(monthYear).format('MM') >= moment(allocatedDate).format('MM')) ||
      // moment(monthYear).format('YYYY') > moment(allocatedDate).format('YYYY')
      if (d1 >= d2) {
        let tempData = {
          month: groupedData[monthYear][0].month,
          year: groupedData[monthYear][0].year,
          key: `DailyResponseRate ${groupedData[monthYear][0].month} ${groupedData[monthYear][0].year}`,
          data: [],
          id: target.target,
          a: 'a',
        }

        if (peakSd === null || peakSd === undefined) {
          groupedData[monthYear].forEach(item => {
            const targetPer =
              target[item.date] && target[item.date].correct !== null
                ? (target[item.date].correct / (target[item.date].total || 1)) * 100
                : null

            if (
              moment(item.date).format('YYYY-MM-DD') >= moment(allocatedDate).format('YYYY-MM-DD')
            ) {
              const idx = demo.findIndex(it => it.id === target.target)
              if (idx !== -1) {
                demo[idx].data.push({
                  x: item.date,
                  y: targetPer !== null ? targetPer.toFixed(2) : null,
                  key: item.date,
                  date: item.date,
                  target: target.target,
                  plotZero: target.plotZero,
                  record: target.record,
                  total:
                    (target[item.date] &&
                      target[item.date].correct !== null &&
                      target[item.date].total) ||
                    1,
                  ...target[item.date],
                  type: target.children.length > 1 ? 'target with children' : 'target',
                  targetId: target.targetId,
                })
              }
              tempData.data.push({
                x: item.dayDate,
                y: targetPer !== null ? targetPer.toFixed(2) : null,
                key: item.date,
                date: item.date,
                target: target.target,
                plotZero: target.plotZero,
                record: target.record,
                total:
                  (target[item.date] &&
                    target[item.date].correct !== null &&
                    target[item.date].total) ||
                  1,
                ...target[item.date],
                type: target.children.length > 1 ? 'target with children' : 'target',
                targetId: target.targetId,
              })

              tempData.id = target.target
            }
          })

          tt.push(tempData)
        }

        target.children.forEach(child => {
          if (child.target !== target.target) {
            tempData = {
              month: groupedData[monthYear][0].month,
              year: groupedData[monthYear][0].year,
              key: `DailyResponseRate ${groupedData[monthYear][0].month} ${groupedData[monthYear][0].year}`,
              data: [],
              a: 'a',
            }
            groupedData[monthYear].forEach(item => {
              const targetPer =
                child[item.date] && child[item.date].correct !== null
                  ? (child[item.date].correct / (child[item.date].total || 1)) * 100
                  : null

              if (
                moment(item.date).format('YYYY-MM-DD') >= moment(allocatedDate).format('YYYY-MM-DD')
              ) {
                const idx2 = demo.findIndex(it => it.id === child.target)
                if (idx2 !== -1) {
                  demo[idx2].data.push({
                    x: item.date,
                    y: targetPer !== null ? targetPer.toFixed(2) : null,
                    key: item.date,
                    date: item.date,
                    target: target.target,
                    plotZero: target.plotZero,
                    record: target.record,
                    total:
                      child[item.date] &&
                      child[item.date].correct !== null &&
                      child[item.date].total,
                    type: target.children.length > 1 ? 'target with children' : 'target',
                    targetId: target.targetId,
                    ...child[item.date],
                  })
                } else {
                  demo.push({
                    key: child.target,
                    id: child.target,
                    data: [
                      {
                        x: item.date,
                        y: targetPer !== null ? targetPer.toFixed(2) : null,
                        key: item.date,
                        date: item.date,
                        target: target.target,
                        plotZero: target.plotZero,
                        record: target.record,
                        total:
                          child[item.date] &&
                          child[item.date].correct !== null &&
                          child[item.date].total,
                        type: target.children.length > 1 ? 'target with children' : 'target',
                        targetId: target.targetId,
                        ...child[item.date],
                      },
                    ],
                  })
                }
                tempData.data.push({
                  x: item.dayDate,
                  y: targetPer !== null ? targetPer.toFixed(2) : null,
                  key: item.dayDate,
                  date: item.date,
                  target: child.target,
                  plotZero: target.plotZero,
                  record: child.record,
                  type: 'sd',
                  total:
                    child[item.date] && child[item.date].correct !== null && child[item.date].total,
                  targetId: target.targetId,
                  ...child[item.date],
                })

                tempData.id = child.target
              }
            })
            tt.push(tempData)
          }
        })
        // if (tt.length === 0) {
        //   tt.push(tempData)
        // }
        tempGraphAxixData.push(tt)
      }
    })

    tempGraphAxixData.length > 0 ? setGraphAxixData(tempGraphAxixData) : setGraphAxixData(null)
    demo.length > 0 ? setStoryRegular(demo) : setStoryRegular(null)

    return tempGraphAxixData
  }

  const handleCloseDrawer = () => {
    refetch()
    // setVisible(false)
  }

  const handleDateRange = e => {
    setDate(e)
  }
  return (
    <div>
      {from && (
        <Drawer
          title={`${student?.StudentName} - ${targetData?.targetAllocate.targetAllcatedDetails
            ?.targetName || ''}`}
          visible={visible}
          width="80%"
          onClose={() => {
            if (refetchData) {
              refetchData()
            }
            setVisible(false)
          }}
          closable
          destroyOnClose
        >
          {mainTargetType === 'Behavior Recording' ? (
            <BehaviorGraph
              selectedTarget={targetData?.targetAllocate}
              loading={loading || targetLoading}
              dateRange={date}
              marker={baseline}
              setDateRange={handleDateRange}
              graphData={behaviorGraphData}
              closeDrawer={handleCloseDrawer}
              daysList={daysList}
              mainTargetTrial={mainTargetTrial}
              behaviorData={data}
              behaviorRefetch={refetch}
              getNextTarget={getNextTarget}
              getPrevTarget={getPrevTarget}
              nextDisable={nextDisable}
              prevDisable={prevDisable}
              therapist={behTherapistData}
              mastery={mastery}
              sdPeak={peakSd}

              // selectedTarget={selectedTarget}
            />
          ) : mainTargetType === 'Skill Based Treatment' ? (
            <SbtGraph
              selectedTarget={targetData?.targetAllocate}
              loading={loading || targetLoading}
              dateRange={date}
              marker={baseline}
              setDateRange={handleDateRange}
              graphData={sbtGraphData}
              closeDrawer={handleCloseDrawer}
              daysList={daysList}
              getNextTarget={getNextTarget}
              getPrevTarget={getPrevTarget}
              nextDisable={nextDisable}
              prevDisable={prevDisable}
              mastery={mastery}
              sdPeak={peakSd}
            />
          ) : (
            <DailyResponseRateGraph
              selectedTarget={targetData?.targetAllocate}
              loading={loading || targetLoading}
              dateRange={date}
              marker={baseline}
              story={storyRegular}
              setDateRange={handleDateRange}
              graphData={graphAxixData}
              closeDrawer={handleCloseDrawer}
              getNextTarget={getNextTarget}
              getPrevTarget={getPrevTarget}
              nextDisable={nextDisable}
              prevDisable={prevDisable}
              therapist={byTherapist}
              mastery={mastery}
              sdPeak={peakSd}
            />
          )}
        </Drawer>
      )}
      {visible && !from && (
        <AnimateFromRight>
          <div
            style={{
              backgroundColor: 'white',
              textAlign: 'center',
              fontSize: 24,
              lineHeight: '30px',
              color: 'black',
              padding: 10,
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Button
              type="link"
              onClick={() => {
                handleCloseDrawer()
                setSelectedRowKeys(selectedRowKeys)
                setVisible(false)
              }}
              style={{ padding: 0, marginRight: '1rem' }}
            >
              <ArrowLeftOutlined style={{ fontSize: '1.5rem', color: 'black' }} />
            </Button>
            {`${targetData?.targetAllocate.targetAllcatedDetails?.targetName || ''} `}{' '}
            {peakSd && peakSd !== null && `(${peakSd.toUpperCase()})`}
          </div>
          {mainTargetType === 'Behavior Recording' ? (
            <BehaviorGraph
              selectedTarget={targetData?.targetAllocate}
              loading={loading || targetLoading}
              dateRange={date}
              marker={baseline}
              setDateRange={handleDateRange}
              graphData={behaviorGraphData}
              closeDrawer={handleCloseDrawer}
              daysList={daysList}
              mainTargetTrial={mainTargetTrial}
              behaviorData={data}
              behaviorRefetch={refetch}
              getNextTarget={getNextTarget}
              getPrevTarget={getPrevTarget}
              nextDisable={nextDisable}
              prevDisable={prevDisable}
              therapist={behTherapistData}
              mastery={mastery}
              sdPeak={peakSd}

              // selectedTarget={selectedTarget}
            />
          ) : mainTargetType === 'Skill Based Treatment' ? (
            <SbtGraph
              selectedTarget={targetData?.targetAllocate}
              loading={loading || targetLoading}
              dateRange={date}
              marker={baseline}
              setDateRange={handleDateRange}
              graphData={sbtGraphData}
              closeDrawer={handleCloseDrawer}
              daysList={daysList}
              getNextTarget={getNextTarget}
              getPrevTarget={getPrevTarget}
              nextDisable={nextDisable}
              prevDisable={prevDisable}
              mastery={mastery}
              sdPeak={peakSd}
            />
          ) : (
            <DailyResponseRateGraph
              selectedTarget={targetData?.targetAllocate}
              loading={loading || targetLoading}
              dateRange={date}
              marker={baseline}
              story={storyRegular}
              setDateRange={handleDateRange}
              graphData={graphAxixData}
              closeDrawer={handleCloseDrawer}
              getNextTarget={getNextTarget}
              getPrevTarget={getPrevTarget}
              nextDisable={nextDisable}
              prevDisable={prevDisable}
              therapist={byTherapist}
              mastery={mastery}
              sdPeak={peakSd}
            />
          )}
        </AnimateFromRight>
      )}
    </div>
  )
}

export default TargetResponseRate
