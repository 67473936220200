import React, { useState, useEffect } from 'react'

const LoadingContext = React.createContext()
const LoadingContextProvider = ({ children }) => {
  const [dataLoading, setDataLoading] = useState(false)
  const [level, setLevel] = useState({})
  const [milestoneLevelData, setMilestoneLevelData] = useState(null)
  const [milestoneData, setMilestoneData] = useState(null)

  const value = {
    dataLoading,
    setDataLoading,
    level,
    setLevel,
    milestoneLevelData,
    setMilestoneLevelData,
    milestoneData,
    setMilestoneData,
  }

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
}

export default LoadingContextProvider
export { LoadingContext }
