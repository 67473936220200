const COURSEUSER = {
  SET_STATE: 'courseuser/SET_STATE',
  GET_COURSE_USERS: 'courseuser/GET_COURSE_USERS',
  CREATE_COURSE_USER: 'courseuser/CREATE_COURSE_USER',
  APPEND_COURSE_USER: 'courseuser/APPEND_COURSE_USER',
  UPDATE_COURSE_USER: 'courseuser/UPDATE_COURSE_USER',
  REPLACE_COURSE_USER: 'courseuser/REPLACE_COURSE_USER',

  ASSIGN_COURSE_TO_USER: 'courseuser/ASSIGN_COURSE_TO_USER',
  APPEND_COURSE: 'courseuser/APPEND_COURSE',
  UPDATE_ASSIGNED_COURSE: 'courseuser/UPDATE_ASSIGNED_COURSE',
  UPDATE_ASSIGNED_MODULES: 'courseuser/UPDATE_ASSIGNED_MODULES',
  UPDATE_ACTIVE_INACTIVE_COURSE: 'courseuser/UPDATE_ACTIVE_INACTIVE_COURSE',
  ACTIVE_INACTIVE_USER_COURSE: 'courseuser/ACTIVE_INACTIVE_USER_COURSE',
  GET_COURSE_BY_USER: 'courseuser/GET_COURSE_BY_USER',

  GET_ALL_COURSES: 'courseuser/GET_ALL_COURSES',
  CHANGE_MODULE_AVAILABILITY: 'courseuser/CHANGE_MODULE_AVAILABILITY',
  GET_USER_COURSE_MODULE_VALIDATION_BY_SUPERADMIN:
    'coursecontent/GET_USER_COURSE_MODULE_VALIDATION_BY_SUPERADMIN',
}

export default COURSEUSER
