/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import { Button, Icon, notification, Switch } from 'antd'
import gql from 'graphql-tag'
import { set } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { useSelector, useDispatch } from 'react-redux'

const ML = () => {
  const [mlSimilarity, setMlSimilarity] = useState()
  const [mlGraph, setMlGraph] = useState()
  const [mlRecommendation, setMlRecommendation] = useState()
  const schoolObj = useSelector(state => state.school)
  const dispatch = useDispatch()

  useEffect(() => {
    if (schoolObj && !schoolObj.updateMlLoading) {
      setMlGraph(schoolObj.mlGraph)
      setMlRecommendation(schoolObj.mlRecommendation)
      setMlSimilarity(schoolObj.mlSimilarity)
    }
  }, [schoolObj])

  const tdStyle = { border: '1px solid #dddddd', padding: 8, textAlign: 'center' }
  return (
    <>
      <table style={{ borderCollapse: 'collapse', width: '100%', lineHeight: 1.5 }}>
        <tbody>
          <tr>
            <td style={{ ...tdStyle }}>
              <Button type="link">
                <p style={{ display: 'block', marginTop: '5px', marginBottom: '-5px' }}>
                  SIMILARITY SCORE
                </p>
              </Button>
            </td>
            <td style={{ ...tdStyle }}>
              <Switch
                checkedChildren={<Icon type="check" />}
                checked={mlSimilarity}
                unCheckedChildren={<Icon type="close" />}
                onChange={event => {
                  if (schoolObj && schoolObj.user?.id) {
                    dispatch({
                      type: 'school/UPDATE_ML',
                      payload: {
                        id: schoolObj.id,
                        mlSimilarity: !mlSimilarity,
                        userId: schoolObj.user.id,
                      },
                    })
                  }
                }}
              />
            </td>
            {/* <td style={tdStyle}>
              <i>
                An evidence-based tool that assesses and teaches language and cognitive skills
                starting from basic foundational abilities to generalizing and higher-order
                abilities.
              </i>
            </td> */}
          </tr>
          <tr>
            <td style={{ ...tdStyle, width: 200 }}>
              <Button type="link">
                <p style={{ display: 'block', marginTop: '5px', marginBottom: '-5px' }}>
                  GRAPH MODEL
                </p>
              </Button>
            </td>
            <td style={{ ...tdStyle, width: 100 }}>
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={mlGraph}
                onChange={event => {
                  dispatch({
                    type: 'school/UPDATE_ML',
                    payload: {
                      id: schoolObj.id,
                      mlGraph: !mlGraph,
                      userId: schoolObj.user.id,
                    },
                  })
                }}
              />
            </td>
            {/* <td style={tdStyle}>
              <i>
                VBMAPP allows the learner to be assessed on key areas such as language, pre
                academics, social and group skills to identify any gaps and needs in overall
                development in each skill area.{' '}
              </i>
            </td> */}
          </tr>
          <tr>
            <td style={{ ...tdStyle, width: 200 }}>
              <Button type="link">
                <p style={{ display: 'block', marginTop: '5px', marginBottom: '-5px' }}>
                  RECOMMENDAITON MODEL
                </p>
              </Button>
            </td>
            <td style={{ ...tdStyle, width: 100 }}>
              <Switch
                checkedChildren={<Icon type="check" />}
                checked={mlRecommendation}
                unCheckedChildren={<Icon type="close" />}
                onChange={event => {
                  dispatch({
                    type: 'school/UPDATE_ML',
                    payload: {
                      id: schoolObj.id,
                      mlRecommendation: !mlRecommendation,
                      userId: schoolObj.user.id,
                    },
                  })
                }}
              />
            </td>
            {/* <td style={tdStyle}>
              <i>
                CogniAble assessment corresponds to CDC milestones to give an accurate developmental
                age of a learner ranging from 2 to 8 years in five major domains.
              </i>
            </td> */}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default ML
