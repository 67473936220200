/* eslint-disable no-nested-ternary */
/* eslint no-underscore-dangle:0 */

import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import { useQuery, useLazyQuery } from 'react-apollo'
import { Drawer } from 'antd'
import { groupBy } from 'lodash'
import moment from 'moment'
import LoadingComponent from '../../staffProfile/LoadingComponent'
import { DRAWER } from '../../../assets/styles/globalStyles'
import EditableTable from './EditableTable'
import { GET_BEHAVIOR_TEMPLATES } from '../../../components/BehaviourData/queries'

const BehaviorDRChart = ({
  selectedStudentId,
  startDate,
  endDate,
  selectedBehavior,
  setStateOfParent,
}) => {
  const [isDRdata, setIsDRdata] = useState(false)
  const [dataRecord, setDataRecord] = useState([])
  const [phaseLines, setPhaseLines] = useState([])
  const [drawer, setDrawer] = useState(false)
  const [pointValue, setPointValue] = useState(null)
  const [clickedElement, setClickedElement] = useState('')
  const [behName, setBehName] = useState('')

  let annotation = []

  startDate = moment(startDate).format('YYYY-MM-DD')
  endDate = moment(endDate).format('YYYY-MM-DD')

  const {
    data: tempData,
    error: templateError,
    loading: templateLoading,
    refetch: refetchTemplates,
  } = useQuery(GET_BEHAVIOR_TEMPLATES, {
    variables: {
      student: selectedStudentId,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (tempData && tempData.getBehaviorTemplates.edges) {
      const dataNodesList = []
      const phaseLineData = []
      tempData.getBehaviorTemplates.edges.forEach((element, index) => {
        const { behavior, behaviorType, statusrecordSet, durationbehaviorrecordSet } = element.node
        if (behaviorType.includes('DR')) {
          if (durationbehaviorrecordSet) {
            const behaviorRecords = durationbehaviorrecordSet.edges.map(({ node }) => ({
              id: node.id,
              date: node.date,
              startTime: node.startTime,
              endTime: node.endTime,
              duration: node.duration,
              behaviorType: 'DR',
              tempData,
              behavior: behavior.behaviorName,
            }))
            let status = {}
            if (statusrecordSet) {
              status = statusrecordSet.edges.map(({ node }) => {
                return {
                  date: moment(node.statusTime).format('YYYY-MM-DD'),
                  status: node.status.statusName,
                }
              })
              phaseLineData.push({
                status,
                behavior,
              })
            }

            const grouped = groupBy(behaviorRecords, 'date')
            const data = []
            Object.keys(grouped).forEach(item => {
              let duration = 0
              grouped[item].forEach(d => {
                duration += d.duration
              })
              data.push({
                date: item,
                duration,
                behavior,
              })
            })

            dataNodesList.push({
              behName: behavior.behaviorName,
              data,
              status,
              behavior,
            })
          }
        }
      })
      setDataRecord(dataNodesList)
      setPhaseLines(phaseLineData)
    }
  }, [tempData, selectedStudentId, selectedBehavior])

  useEffect(() => {
    if (tempData && tempData.getBehaviorTemplates.edges.length !== 0) {
      dataRecord.forEach(data => {
        data.data.map(item1 => {
          if (selectedBehavior && item1.behavior.id === selectedBehavior) {
            setIsDRdata(true)
            setBehName(item1.behavior.behaviorName)
          }
          return item1
        })
      })
    } else {
      setIsDRdata(false)
    }
  }, [dataRecord, phaseLines, tempData, selectedBehavior])

  useEffect(() => {
    if (pointValue) setClickedElement(pointValue)
  }, [pointValue])

  const getChartOptions = () => ({
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label',
    },
    annotation: {
      annotations: annotation,
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          id: 'x-axis-0',
          display: true,
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Dates',
          },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: true,
          },
          labels: {
            show: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Duration (in seconds)',
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  })

  const generateLabels = () => {
    const daysCnt = Math.abs(moment(endDate).diff(startDate, 'days'))
    const labels = Array(daysCnt + 1)
      .fill(0)
      .map((itemValue, itemIndex) => {
        return moment(startDate)
          .add(itemIndex, 'day')
          .format('YYYY-MM-DD')
      })
    return labels
  }
  const result = generateLabels()
  let adata = {}
  phaseLines.forEach(item => {
    if (item.behavior.id === selectedBehavior) {
      adata = item.status.map(i => {
        return {
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: i.date,
          borderWidth: 1,
          borderColor: '#483D8B',
          text: i.status,
          label: {
            content: i.status,
            enabled: true,
            position: 'top',
            rotation: '90',
          },
        }
      })

      annotation = adata
    }
  })

  const getChartData = () => {
    const duration = new Array(result.length).fill(null)

    result.forEach((item, index) => {
      dataRecord.forEach(data => {
        data.data.map(item1 => {
          if (selectedBehavior && item1.behavior.id === selectedBehavior && item === item1.date) {
            duration[index] = duration[index] ? item1.duration + duration[index] : item1.duration
          } else if (!duration[index]) {
            duration[index] = null
          }
          return null
        })
      })
    })

    const data = {
      labels: result,
      datasets: [
        {
          type: 'line',
          label: 'Duration ',
          fill: false,
          borderColor: '#EC932F',
          backgroundColor: '#EC932F',
          radius: 4,
          hitRadius: 5,
          hoverRadius: 7,
          hoverBorderWidth: 2,
          pointBorderColor: '#EC932F',
          pointBackgroundColor: '#EC932F',
          pointHoverBackgroundColor: '#EC932F',
          pointHoverBorderColor: '#EC932F',
          lineTension: 0,
          yAxisID: 'y-axis-1',
          data: duration,
        },
      ],
    }
    return data
  }

  const getElementAtEvent = element => {
    if (!element.length) return

    const { _index } = element[0]

    setPointValue(chartData.labels[_index])
    setDrawer(true)
  }
  const chartData = getChartData()

  const showDrawerHandler = () => {
    setDrawer(true)
  }
  const hideDrawerHandler = () => {
    setDrawer(false)
  }
  useEffect(() => {
    const elem = document.getElementById('DR')
    if (elem) {
      setStateOfParent(elem)
    }
  }, [])
  return (
    <div id="DR">
      {!templateLoading ? (
        isDRdata ? (
          <div style={{ backgroundColor: 'white' }}>
            <div>
              <Line
                options={getChartOptions()}
                data={getChartData()}
                getElementAtEvent={getElementAtEvent}
                height={400}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              margin: '20px auto',
              textAlign: 'center',
              padding: 5,
              backgroundColor: 'white',
            }}
          >
            No Records found,Please Start Recording!!
          </div>
        )
      ) : (
        <LoadingComponent />
      )}

      <Drawer
        title={`Behavior-${behName}`}
        placement="right"
        width={DRAWER.widthL2}
        closable="true"
        onClose={hideDrawerHandler}
        visible={drawer}
      >
        <EditableTable
          behType="DR"
          showDrawer={drawer}
          date={pointValue}
          selectedBehavior={selectedBehavior}
          selectedBaheviorName={behName}
          selectedStudentId={selectedStudentId}
        />
      </Drawer>
    </div>
  )
}

export default BehaviorDRChart
