import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { COLORS } from 'assets/styles/globalStyles'
import UsingProps from 'components/LearnerSelect/usingProps'
import { Switch, Icon, Drawer, Select, Button, Row, Col, DatePicker, Form } from 'antd'
import { EditOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useTargetAlloc } from '../context'

const boxCard = {
  padding: '24px 36px',
  backgroundColor: 'white',
  margin: '12px 0',
}

const StudentCard = ({
  form,
  edit = false,
  copy = false,
  selectedStudent,
  targetObject,
  editStudent = false,
  setTargetFormType,
  showBackButton,
}) => {
  const student = useSelector(state => state.student)
  const [visibleFilter, setVisibleFilter] = useState(false)

  const { getFieldDecorator } = form
  const {
    programAreas,
    setSelectedStudent,
    selectedProgram,
    setSelectedProgram,
    useDefaultGoal,
    setUseDefaultGoal,
  } = useTargetAlloc()

  const disabledDate = current => {
    const dt = edit ? moment(targetObject?.date) : moment()
    return current && current > dt.endOf('day')
  }

  const changeStudent = e => {
    if (e) {
      setSelectedStudent(e)
    }
  }

  return (
    <div>
      <div style={{ ...boxCard, fontSize: 14, fontWeight: 600, color: 'black' }}>
        <Row gutter={[24]} style={{ marginBottom: 8 }}>
          <Col span={copy ? 12 : 24}>
            <div className="flexCenter">
              {showBackButton && setTargetFormType && (
                <Button
                  type="link"
                  onClick={() => setTargetFormType('tab')}
                  style={{ padding: 0, marginRight: '1rem' }}
                >
                  <ArrowLeftOutlined style={{ fontSize: '1.5rem', color: 'black' }} />
                </Button>
              )}
              {student?.StudentDetails && (
                <div
                  className="flexCenterJust"
                  style={{ width: '100%', color: 'black', fontSize: 16 }}
                >
                  {copy ? <span>From:&nbsp;</span> : <span>Student:&nbsp;</span>}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontWeight: 500,
                      width: '100%',
                    }}
                  >
                    <span>
                      {student.StudentDetails.firstname}{' '}
                      {student.StudentDetails.lastname ? student.StudentDetails.lastname : ''}
                    </span>
                    {!copy && editStudent && (
                      <Button
                        type="link"
                        style={{ color: '#46bd84', paddingRight: 0 }}
                        onClick={() => setVisibleFilter(true)}
                      >
                        <EditOutlined />
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
          {copy && (
            <Col span={12}>
              {selectedStudent && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className="flexCenterJust"
                    style={{ width: '100%', color: 'black', fontSize: 16 }}
                  >
                    <span>
                      <b>To: </b> {selectedStudent.firstname}{' '}
                      {selectedStudent.lastname ? selectedStudent.lastname : ''}
                    </span>
                    <Button
                      type="link"
                      style={{ color: '#46bd84', paddingRight: 0 }}
                      onClick={() => setVisibleFilter(true)}
                    >
                      <EditOutlined />
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          )}
        </Row>

        <Row gutter={[24]}>
          <Col span={8}>
            <Form.Item label="Program Area" style={{ marginBottom: 0 }}>
              <Select
                placeholder="Select Program Area"
                value={programAreas.filter(prg => prg.id === selectedProgram)[0]?.id || ''}
                onSelect={e => setSelectedProgram(e)}
                showSearch
                style={{ width: 250 }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {programAreas &&
                  programAreas.map(item => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Valid From" style={{ marginBottom: 0 }}>
              {getFieldDecorator('validFrom', {
                initialValue: edit ? moment(targetObject?.date) : moment(),
                rules: [{ required: true, message: 'Please select date' }],
              })(<DatePicker disabledDate={disabledDate} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Allocate target directly" style={{ marginBottom: 0 }}>
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={!!useDefaultGoal}
                defaultChecked={!!useDefaultGoal}
                onChange={e => {
                  form.setFieldsValue({
                    stg: '',
                  })
                  setUseDefaultGoal(e)
                }}
                style={{ marginLeft: 'auto' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Drawer
        visible={visibleFilter}
        onClose={() => setVisibleFilter(false)}
        width={student?.DrawerWidth}
        title="Select Learner"
        placement="right"
        bodyStyle={{ backgroundColor: COLORS.bodyGrey }}
      >
        <UsingProps closeDrawer={() => setVisibleFilter(false)} setStudent={changeStudent} />
      </Drawer>
    </div>
  )
}

export default StudentCard
