/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useMutation } from 'react-apollo'
import { Button, DatePicker, Row, Col, Input, Select, Form, Switch, notification } from 'antd'
import { SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { CREATE_DATE_RANGE_SETTING, UPDATE_DATE_RANGE_SETTING } from './query'

const TITLE_LIST = [
  { title: 'DRR', text: 'DRR Report' },
  { title: 'DRR_GRAPH', text: 'DRR Graph' },
  { title: 'SESSION_REPORT', text: 'Session Report' },
  { title: 'BEHAVIOR_REPORT', text: 'Behavior Report' },
  { title: 'PROGRESS_OVERVIEW', text: 'Progress Overview Report' },
  { title: 'GOALS_REPORT', text: 'Goals Report' },
  { title: 'TARGET_RESPONSE', text: 'Target Response Report' },
]

const { Option } = Select
const { RangePicker } = DatePicker

function DateRangeForm({ form, editableObj, edit = false, onSuccess, closeModal }) {
  const [isCustom, setIsCustom] = useState(edit ? editableObj?.isCustomRange : false)
  const [rangeUnit, setRangeUnit] = useState(edit ? editableObj?.rangeUnit : 'DAYS')

  const user = useSelector(state => state.user)
  const school = useSelector(state => state.school)

  const [createDateRange, { loading: createSettingLoading }] = useMutation(
    CREATE_DATE_RANGE_SETTING,
  )
  const [updateDateRangeSetting, { loading: updateDateRangeSettingLoading }] = useMutation(
    UPDATE_DATE_RANGE_SETTING,
  )

  const selectAfter = (
    <Select value={rangeUnit} onChange={setRangeUnit} style={{ minWidth: 85 }}>
      <Option key="range_day" value="DAYS">
        Days
      </Option>
      <Option key="range_week" value="WEEK">
        Week
      </Option>
      <Option key="range_month" value="MONTH">
        Month
      </Option>
      <Option key="range_year" value="YEAR">
        Year
      </Option>
    </Select>
  )

  const handleCreate = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        let errorMessage = ''

        if (user?.role === 'school_admin' && !school?.id) {
          errorMessage = 'School id is not available'
        } else if (user?.role === 'therapist' && !user.staffObject?.id) {
          errorMessage = 'Therapist id is not available'
        } else if (isCustom && values.customGte > values.customLte) {
          errorMessage = 'Start date must be less then end date'
        }

        if (errorMessage) {
          notification.error({
            message: errorMessage,
          })
        } else {
          createDateRange({
            variables: {
              clinic: school.id,
              therapist: user.staffObject?.id,
              rangeTitle: values.rangeTitle,
              rangeDescription: values.rangeDescription,
              rangeDuration: isCustom ? null : values.rangeDuration,
              rangeUnit: isCustom ? null : rangeUnit,
              isCustomRange: isCustom,
              customGte: isCustom ? new Date(moment(values.customGte).format('YYYY-MM-DD')) : null,
              customLte: isCustom ? new Date(moment(values.customLte).format('YYYY-MM-DD')) : null,
            },
          })
            .then(res => {
              notification.success({
                message: 'Success',
                description: 'Default date range setting create successfully',
              })

              form.resetFields()
              if (onSuccess) {
                onSuccess()
              }

              if (closeModal) {
                closeModal()
              }
            })
            .catch(er => console.error(er))
        }
      }
    })
  }

  const handleUpdate = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        let errorMessage = ''
        console.log('values>>>>', values)

        // if (isCustom && values.customRange.length < 2) {
        //   errorMessage = 'Please select custom date range'
        // } else
        if (!editableObj?.id) {
          errorMessage = 'Object id not found'
        }

        if (errorMessage) {
          notification.error({
            message: 'Something went wrong',
            description: errorMessage,
          })
        } else {
          updateDateRangeSetting({
            variables: {
              pk: editableObj.id,
              rangeTitle: values.rangeTitle,
              rangeDescription: values.rangeDescription,
              rangeDuration: isCustom ? null : values.rangeDuration,
              rangeUnit: isCustom ? null : rangeUnit,
              isCustomRange: isCustom,
              customGte: isCustom ? new Date(moment(values.customGte).format('YYYY-MM-DD')) : null,
              customLte: isCustom ? new Date(moment(values.customLte).format('YYYY-MM-DD')) : null,
            },
          })
            .then(res => {
              notification.success({
                message: 'Success',
                description: 'Default date range setting updated successfully',
              })

              form.resetFields()
              if (onSuccess) {
                onSuccess()
              }

              if (closeModal) {
                closeModal()
              }
            })
            .catch(er => console.error(er))
        }
      }
    })
  }

  return (
    <Form onSubmit={edit ? handleUpdate : handleCreate}>
      <Form.Item label="Title">
        {form.getFieldDecorator('rangeTitle', {
          initialValue: edit ? editableObj?.rangeTitle : '',
          rules: [{ required: true, message: 'Please enter title' }],
        })(
          <Select placeholder="Please select title">
            {TITLE_LIST.map(item => {
              return (
                <Option key={item.title} value={item.title}>
                  {item.text}
                </Option>
              )
            })}
          </Select>,
        )}
      </Form.Item>

      <Form.Item label="Description">
        {form.getFieldDecorator('rangeDescription', {
          initialValue: edit ? editableObj?.rangeDescription : '',
        })(
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 2 }}
            allowClear
            placeholder="Enter description"
          />,
        )}
      </Form.Item>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="Custom Date Range">
            <Switch checked={isCustom} onChange={setIsCustom} />
          </Form.Item>
        </Col>
        {isCustom ? (
          <>
            <Col span={8}>
              <Form.Item label="Start Date">
                {form.getFieldDecorator('customGte', {
                  initialValue: edit
                    ? editableObj?.customGte
                      ? moment(editableObj.customGte)
                      : moment().subtract(15, 'd')
                    : moment().subtract(15, 'd'),
                  rules: [{ required: false, message: 'Please select date range' }],
                })(<DatePicker />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="End Date">
                {form.getFieldDecorator('customLte', {
                  initialValue: edit
                    ? editableObj?.customLte
                      ? moment(editableObj.customLte)
                      : moment()
                    : moment(),
                  rules: [{ required: true, message: 'Please select date range' }],
                })(<DatePicker />)}
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col span={16}>
            <Form.Item label="Range Value">
              {form.getFieldDecorator('rangeDuration', {
                initialValue: edit ? editableObj?.rangeDuration : 15,
                rules: [{ required: true, message: 'Please provide duration value' }],
              })(<Input addonBefore="Last" addonAfter={selectAfter} />)}
            </Form.Item>
          </Col>
        )}
      </Row>

      <div style={{ margin: '20px auto', display: 'flex', justifyContent: 'center' }}>
        <Button
          loading={createSettingLoading || updateDateRangeSettingLoading}
          type="primary"
          style={SUBMITT_BUTTON}
          htmlType="submit"
        >
          {edit ? 'Update' : 'Submit'}
        </Button>
      </div>
    </Form>
  )
}

export default Form.create()(DateRangeForm)
