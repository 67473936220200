import React from 'react'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import { useMutation, useQuery } from 'react-apollo'
import LoadingComponent from 'components/LoadingComponent'
import { COLORS } from 'assets/styles/globalStyles'
import { UPDATE_CLINIC, SCHOOL_CURRENCY } from './query'

const OtherPaymentMethods = ({ form }) => {
  const schoolId = localStorage.getItem('userId')
  const { data, error, loading, refetch } = useQuery(SCHOOL_CURRENCY, {
    variables: {
      id: schoolId,
    },
    fetchPolicy: 'no-cache',
  })

  const [updateInvoiceDetails, { loading: updateInvoiceDetailLoading }] = useMutation(UPDATE_CLINIC)

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err && values) {
        updateInvoiceDetails({
          variables: {
            upi: values.upi,
            gpay: values.gpay,
            paytm: values.paytm,
            paymentLink: values.paymentLink,
          },
        })
          .then(res => {
            notification.success({
              message: 'Details updated successfully',
            })
          })
          .catch(errDt => {
            console.log(errDt, 'err')
          })
      }
    })
  }

  if (loading) {
    return <LoadingComponent />
  }
  if (error) {
    return <div>Oops! Something went wrong</div>
  }

  return (
    <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Col sm={24} md={24} lg={18}>
        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Form.Item
            label="UPI"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ paddingTop: '2em' }}
          >
            {form.getFieldDecorator('upi', {
              initialValue: data?.school?.upi,
            })(<Input style={{ width: '100%' }} />)}
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item label="Paytm Number" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                {form.getFieldDecorator('paytm', {
                  initialValue: data?.school?.paytm,
                })(<Input style={{ width: '100%' }} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="GPay Number" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                {form.getFieldDecorator('gpay', {
                  initialValue: data?.school?.gpay,
                })(<Input style={{ width: '100%' }} />)}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Payment Link"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            // style={{ paddingTop: '2em' }}
          >
            {form.getFieldDecorator('paymentLink', {
              initialValue: data?.school?.paymentLink,
              // rules: [{ type: 'url' }],
            })(<Input style={{ width: '100%' }} placeholder="Enter Payment Link" />)}
          </Form.Item>

          <div>
            <Button
              style={{ backgroundColor: COLORS.submitBlue }}
              loading={updateInvoiceDetailLoading}
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  )
}

export default Form.create()(OtherPaymentMethods)
