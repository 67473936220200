import { Button, Form, Input, notification } from 'antd'
import LoadingComponent from 'components/LoadingComponent'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import { useSelector } from 'react-redux'
import { ADD_UPDATE_MAND_INSTRUCTION, GET_MAND_INSTRUCTION } from './query'

const { TextArea } = Input

const MandInstruction = ({ form, closeModal }) => {
  const student = useSelector(state => state.student)
  const [updateMandInstruction, { loading: updateSessionSettingLoading }] = useMutation(
    ADD_UPDATE_MAND_INSTRUCTION,
  )
  const [
    fetchMandInstruction,
    { data, loading, error: mandError, refetch: refetchMandInstructions },
  ] = useLazyQuery(GET_MAND_INSTRUCTION)

  useEffect(() => {
    if (student && student.StudentId) {
      fetchMandInstruction({
        variables: {
          studentId: student.StudentId,
        },
      })
    }
  }, [student?.StudentId])

  useEffect(() => {
    if (data && data.learnerSessionSettings) {
      form.setFieldsValue({
        mandInstruction: data.learnerSessionSettings.mandInstructions,
      })
    }
  }, [data])

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        updateMandInstruction({
          variables: {
            studentId: student?.StudentId,
            sessions: [],
            ...values,
          },
        }).then(res => {
          refetchMandInstructions()
          notification.success({
            message: 'Mand Instruction is successfully saved!',
          })
          if (closeModal) {
            closeModal()
          }
        })
      }
    })
  }

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Form onSubmit={handleSubmit} style={{ paddingRight: 10 }}>
          <Form.Item>
            {form.getFieldDecorator('mandInstruction')(
              <TextArea placeholder="Add Mand Instructions Here" rows={5} />,
            )}
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      )}
    </>
  )
}

export default Form.create()(MandInstruction)
