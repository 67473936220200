/* eslint-disable react/jsx-indent */
/* eslint-disable no-shadow */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react'
import { gql } from 'apollo-boost'
import { Switch } from 'antd'

import moment from 'moment'
import { ResponsiveLine } from '@nivo/line'
import LoadingComponent from '../../../components/LoadingComponent'
import client from '../../../apollo/config'

const fetchData1 = [
  {
    id: 'Graph',
  },
]
const fetchData2 = [
  {
    id: 'Cummulative',
  },
]
const resData = []
const AggregateGraph = ({
  startDate,
  endDate,
  selectedProgram,
  studentIdSelected,
  statusSelected,
  domainSelected,
  domainObj,
  domainId,
}) => {
  startDate = moment(startDate).format('YYYY-MM-DD')
  endDate = moment(endDate).format('YYYY-MM-DD')

  const [graphData, setGraphData] = useState([])
  const [keys, setKeys] = useState([])
  const [loading, setLoading] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const [gData, setGData] = useState([])
  const [cData, setCData] = useState([])
  useEffect(() => {
    setLoading(true)
    createBarGraph(startDate, endDate, selectedProgram, studentIdSelected, statusSelected, domainId)
  }, [
    startDate,
    endDate,
    createBarGraph,
    selectedProgram,
    studentIdSelected,
    isChecked,
    statusSelected,
    domainId,
  ])

  const createBarGraph = useCallback(
    (startDate, endDate, programArea, studentId, statusSelected, domainId) => {
      client
        .query({
          query: gql`
            query(
              $studentId: ID
              $dateGte: Date
              $dateLte: Date
              $programArea: ID
              $activeDomain: Boolean
              $targetStatus: ID
              $domainId: ID
            ) {
              domainMonthwiseTargetCount(
                studentId: $studentId
                dateGte: $dateGte
                dateLte: $dateLte
                programArea: $programArea
                activeDomain: $activeDomain
                targetStatus: $targetStatus
                domainId: $domainId
              ) {
                totalCount
                dateGte
                dateLte
              }
            }
          `,
          variables: {
            studentId,
            dateGte: startDate,
            dateLte: endDate,
            programArea,
            activeDomain: true,
            targetStatus: statusSelected,
            domainId,
          },
          fetchPolicy: 'network-only',
        })
        .then(result => {
          resData.splice(0, resData.length)
          setLoading(false)
          result.data.domainMonthwiseTargetCount.map(item => {
            resData.push({ x: moment(item.dateLte).format('MMM YY'), y: item.totalCount })
            return 0
          })
          const cummulativeData = JSON.parse(JSON.stringify(resData))
          setGraphData(resData)
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i < cummulativeData.length; i++) {
            cummulativeData[i].y = cummulativeData[i - 1].y + cummulativeData[i].y
          }
          setCData(cummulativeData)

          fetchData1[0].data = resData
          fetchData2[0].data = cummulativeData
        })
        .catch(error => console.error(error, 'err'))
    },
    [],
  )
  const colorsArray = [
    '#774dd7',
    '#028ee6',
    '#00CED1',
    '#2E5894',
    '#3F00FF',
    '#6495ED',
    '#1560bd',
    '#00308F',
    '#48D1CC',
  ]

  const handleSwitchChange = checked => {
    setIsChecked(checked)
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div
      id="aggregateGraph"
      style={{
        width: '1200px',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <div>
        <span>Graph </span>
        <Switch checked={isChecked} onChange={handleSwitchChange} />
        <span>Cummulative</span>
      </div>
      <div
        style={{
          height: 500,
          marginBottom: 0,
          width: '100%',
          paddingBottom: 10,
          backgroundColor: 'white',
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          {isChecked
            ? fetchData2[0].data?.length > 0 && (
                <ResponsiveLine
                  key={Math.random()}
                  data={fetchData2} // Add check
                  curve="monotoneX"
                  margin={{ top: 40, right: 250, bottom: 200, left: 60 }}
                  xScale={{ type: 'point' }}
                  yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
                  axisTop={null}
                  axisRight={null}
                  enableGridX={false}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 2,
                    tickRotation: -45,
                    legend: 'Month',
                    legendOffset: 36,
                    legendPosition: 'middle',
                  }}
                  // eslint-disable-next-line react/jsx-indent-props
                  axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 2,
                    tickRotation: 0,
                    legend: 'Month Wise Target Count',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  colors={colorsArray}
                  pointSize={10}
                  lineWidth={3}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={3}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabel="y"
                  pointLabelYOffset={-12}
                  useMesh
                  // eslint-disable-next-line react/jsx-indent-props
                  legends={[
                    {
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              )
            : fetchData1[0].data?.length > 0 && (
                <ResponsiveLine
                  key={Math.random()}
                  data={fetchData1} // Add check
                  curve="monotoneX"
                  margin={{ top: 40, right: 250, bottom: 200, left: 60 }}
                  xScale={{ type: 'point' }}
                  yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    reverse: false,
                  }}
                  axisTop={null}
                  axisRight={null}
                  enableGridX={false}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 2,
                    tickRotation: -45,
                    legend: 'Month',
                    legendOffset: 36,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 2,
                    tickRotation: 0,
                    legend: 'Month Wise Target Count',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  colors={colorsArray}
                  pointSize={10}
                  lineWidth={3}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={3}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabel="y"
                  pointLabelYOffset={-12}
                  useMesh
                  legends={[
                    {
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
        </div>
      </div>
    </div>
  )
}

export default AggregateGraph
