import React, { useReducer } from 'react'
import reducer from './reducer'

const PayorContext = React.createContext()

const PayorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    addFormOpen: false,
    editFormOpen: false,
    title: 'Payors',
  })

  const value = {
    state,
    dispatch,
  }

  return <PayorContext.Provider value={value}>{children}</PayorContext.Provider>
}

export default PayorProvider
export { PayorContext }
