import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { COLORS } from 'assets/styles/globalStyles'
import { Button, Card, Row, Col, List, Modal, notification } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import LoadingComponent from 'components/LoadingComponent'
import { GET_DATE_RANGE_SETTINGS, DELETE_DATE_RANGE_SETTING } from './query'
import DateRangeForm from './dateRangeForm'
import './Style.scss'

const WrapperDiv = styled.div`
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`

function Index() {
  const user = useSelector(state => state.user)
  const [editableObj, setEditableObj] = useState(null)
  const [editDrawer, setEditDrawer] = useState(false)
  const [createDrawer, setCreateDrawer] = useState(false)

  const { data, loading, error, refetch } = useQuery(GET_DATE_RANGE_SETTINGS, {
    variables: { user: user?.id },
  })

  const [deleteDateRangeSetting] = useMutation(DELETE_DATE_RANGE_SETTING)

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <>
      <div className="date-range-setting">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            icon="plus"
            type="primary"
            style={{ marginBottom: 10, backgroundColor: '#0B35B3' }}
            onClick={() => setCreateDrawer(true)}
          >
            Add Date Range
          </Button>
        </div>

        {loading && <LoadingComponent />}
        {!loading && (
          <Card style={{ marginTop: 20 }}>
            <List
              style={{ zoom: 1.1 }}
              size="small"
              loading={loading}
              itemLayout="horizontal"
              dataSource={data?.getDateRangeSettings.edges || []}
              renderItem={item => (
                <List.Item
                  key={item.node.id}
                  style={{ width: '100%' }}
                  actions={[
                    <Button
                      icon="edit"
                      type="link"
                      onClick={() => {
                        setEditableObj(item.node)
                        setEditDrawer(true)
                      }}
                    />,
                    <Button
                      type="link"
                      style={{ color: COLORS.danger }}
                      icon="delete"
                      onClick={() => {
                        if (item.node.id) {
                          deleteDateRangeSetting({
                            variables: {
                              pk: item.node.id,
                            },
                          })
                            .then(res => {
                              if (res.data.deleteDateRangeSetting.status) {
                                notification.success({
                                  message: 'Success',
                                  description: 'Date setting object delete successfully',
                                })
                                refetch()
                              } else {
                                notification.error({
                                  message: 'Something went wrong',
                                  description: res.data.deleteDateRangeSetting.message,
                                })
                              }
                            })
                            .catch(err => console.error(err))
                        }
                      }}
                    />,
                  ]}
                >
                  <div style={{ width: '100%' }}>
                    <div className="flexCenterJust">
                      <div>
                        <div style={{ fontSize: 14, fontWeight: 600 }}>{item.node.rangeTitle}</div>
                        <span>{item.node.rangeDescription}</span>
                      </div>
                      <div style={{ minWidth: 'fit-content' }}>
                        {item.node.isCustomRange ? (
                          <div>
                            {moment(item.node.customGte).format('YYYY-MM-DD')} &nbsp;-&nbsp;{' '}
                            {moment(item.node.customLte).format('YYYY-MM-DD')}
                          </div>
                        ) : (
                          <div>
                            Last {item.node.rangeDuration} {item.node.rangeUnit}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </Card>
        )}

        <Modal
          title="Add New Date Setting"
          width={800}
          onCancel={() => setCreateDrawer(false)}
          visible={createDrawer}
          footer={null}
          destroyOnClose
        >
          <DateRangeForm closeModal={() => setCreateDrawer(false)} onSuccess={refetch} />
        </Modal>

        <Modal
          title="Edit Date  Setting"
          width={800}
          onCancel={() => setEditDrawer(false)}
          visible={editDrawer}
          footer={null}
          destroyOnClose
        >
          <DateRangeForm
            edit
            editableObj={editableObj}
            closeModal={() => setEditDrawer(false)}
            onSuccess={refetch}
          />
        </Modal>
      </div>
    </>
  )
}

export default Index
