/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable  react/jsx-closing-bracket-location */
/* eslint-disable no-unused-expressions */
import { Button, Card, Col, Form, Input, Row, Select, Switch } from 'antd'
import React, { Component, createRef, useState } from 'react'
import Timer from 'react-compound-timer'
import { connect, useDispatch, useSelector } from 'react-redux'
import ManualBehaviorRecording from './ManualBehaviorRecording'
import EditTrialsForm from './EditTrial'
import './style.scss'

const { Meta } = Card

const BehaviorRecording = ({ form }) => {
  const timerRef = createRef()
  const timerBehaviorRef = createRef()

  const [isStop, setIsStop] = useState(true)
  const [isBehaviorStart, setIsBehaviorStart] = useState(false)
  const [startBeaviorTime, setStartBeaviorTime] = useState(0)
  const [timerRunning, setTimerRunning] = useState(false)
  const [behvaiorTimerRunning, setBehvaiorTimerRunning] = useState(false)
  const [isManual, setIsManual] = useState(false)
  const dispatch = useDispatch()
  const {
    EditAfterSessionCompleted,
    TotalBehDuration,
    Frequency,
    TargetActiveId,
    TargetActiveIndex,
    isBehaviorEdit,
    BehEditTrialCount,
    TotalDuration,
  } = useSelector(state => state.sessionrecording)

  const handleSubmit = e => {
    e.preventDefault()
  }

  const startBehavior = () => {
    if (EditAfterSessionCompleted) {
      setIsStop(false)
      timerRef.current.start()
      timerBehaviorRef.current.start()
      setIsBehaviorStart(true)
      setBehvaiorTimerRunning(true)
      setStartBeaviorTime(timerRef.current?.getTime().toFixed())
    }
  }

  const stopBehavior = () => {
    if (EditAfterSessionCompleted) {
      const endTime = timerRef.current.getTime().toFixed()
      setIsBehaviorStart(false)
      setBehvaiorTimerRunning(false)
      timerBehaviorRef.current.stop()
      timerBehaviorRef.current.reset()
      // new and working code
      dispatch({
        type: 'sessionrecording/SET_STATE',
        payload: {
          Frequency: Frequency + 1,
          TotalBehDuration: TotalBehDuration + (endTime - startBeaviorTime),
          TotalDuration: endTime,
        },
      })

      dispatch({
        type: 'sessionrecording/RECORD_BEHAVIOR_FREQUENCY',
        payload: {
          frequency: 1,
          totalbehRecordingTime: endTime,
          start: startBeaviorTime,
          end: endTime,
        },
      })
    }
  }

  const updateTotalClockTime = () => {
    let totalTime = timerRef.current?.getTime()
    if (totalTime) {
      totalTime = totalTime.toFixed()
    }
    timerRef.current?.stop()
    setIsStop(true)

    dispatch({
      type: 'sessionrecording/SET_STATE',
      payload: {
        TotalDuration: totalTime,
      },
    })

    dispatch({
      type: 'sessionrecording/RECORD_BEHAVIOR_TOTAL_TIME',
      payload: {
        TotalTime: totalTime,
        targetId: TargetActiveId,
        targetIndex: TargetActiveIndex,
      },
    })
  }

  const manualAutomaticSwitch = checked => {
    setIsManual(checked)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    style: { textAlign: 'center' },
  }

  return (
    <>
      <Switch
        checkedChildren="MANUAL"
        unCheckedChildren="AUTOMATIC"
        defaultChecked={isManual}
        onChange={manualAutomaticSwitch}
        style={{ marginTop: 8 }}
      />
      {isManual && !isBehaviorEdit && <ManualBehaviorRecording />}
      {isBehaviorEdit && <EditTrialsForm key={BehEditTrialCount} />}
      <div
        className="behavior-recording-div"
        style={isManual ? { pointerEvents: 'none', opacity: 0.4 } : { opacity: 1 }}
      >
        <Form {...formItemLayout} onSubmit={handleSubmit} layout="vertical">
          <div>
            <Timer
              ref={timerRef}
              initialTime={TotalDuration}
              startImmediately={false}
              onStart={() => setTimerRunning(true)}
              onResume={() => console.log('onResume hook')}
              onPause={() => console.log('onPause hook')}
              onStop={() => {
                timerBehaviorRef.current.stop()
                timerBehaviorRef.current.reset()
                setTimerRunning(false)
                setBehvaiorTimerRunning(false)

                const element = document.getElementById('updateBehaviorRecordingTotalTime')
                // If it isn't "undefined" and it isn't "null", then it exists.
                if (typeof element !== 'undefined' && element !== null) {
                  element.click()
                } else {
                  console.log('Buttons does not not exits')
                }
                // handleChange(timerRef.current.getTime())
              }}
              onReset={() => {
                setTimerRunning(false)
                // form.setFieldsValue({ frequency: 0, duration: 0 })
              }}
            >
              {({ start, resume, pause, stop, reset, timerState }) => (
                <React.Fragment>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>&nbsp;</span>
                    <Meta
                      title={
                        <>
                          Behavior Observation Time :
                          <span style={{ fontSize: 22 }}>
                            <Timer.Minutes />
                          </span>
                          <span>min </span>
                          <span style={{ fontSize: 22 }}>
                            <Timer.Seconds />
                          </span>
                          <span>sec</span>
                        </>
                      }
                    />
                    <div>
                      {!isStop && (
                        <Button
                          size="small"
                          icon="pause-circle"
                          disabled={isStop}
                          style={{ display: 'inline-block', margin: 5 }}
                          onClick={() => {
                            setIsStop(true)
                            stop()
                          }}
                          type="danger"
                        >
                          Stop
                        </Button>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Timer>

            <br />

            <span style={{ display: 'block' }}>
              <Meta
                title={
                  <>
                    <Timer
                      ref={timerBehaviorRef}
                      initialTime={0 * 60 * 1000}
                      startImmediately={false}
                      onStart={() => console.log('onStart hook')}
                      onResume={() => console.log('onResume hook')}
                      onPause={() => console.log('onPause hook')}
                      onStop={() => console.log('onStop hook')}
                      onReset={() => console.log('onReset hook')}
                    >
                      {({ start, resume, pause, stop, reset, timerState }) => (
                        <React.Fragment>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div
                              style={{
                                width: '100%',
                                textAlign: 'center',
                              }}
                            >
                              Record Behavior &nbsp;
                              {behvaiorTimerRunning && (
                                <>
                                  <span style={{ fontSize: 20 }}>
                                    <Timer.Minutes />
                                  </span>
                                  <span>min </span>
                                  <span style={{ fontSize: 20 }}>
                                    <Timer.Seconds />
                                  </span>
                                  <span>sec</span>
                                </>
                              )}
                              {!isBehaviorStart && (
                                <Button
                                  style={{
                                    height: '75px',
                                    width: '75px',
                                    border: '2px solid green',
                                    borderRadius: '50px',
                                    color: 'green',
                                  }}
                                  disabled={timerRunning && isBehaviorStart}
                                  onClick={() => startBehavior()}
                                >
                                  Start
                                </Button>
                              )}
                              {isBehaviorStart && (
                                <Button
                                  style={{
                                    height: '75px',
                                    width: '75px',
                                    border: '2px solid Red',
                                    borderRadius: '50px',
                                    color: 'red',
                                    marginLeft: 20,
                                  }}
                                  disabled={!timerRunning || !isBehaviorStart}
                                  onClick={() => stopBehavior()}
                                >
                                  Stop
                                </Button>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </Timer>
                  </>
                }
              />
            </span>

            <Form.Item style={{ display: 'inline-block', width: '200px' }}>
              <Button
                id="updateBehaviorRecordingTotalTime"
                style={{ display: 'hidden', border: 'none' }}
                onClick={() => updateTotalClockTime()}
              >
                &nbsp;
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  )
}

const FrequencyRate = Form.create({ name: 'time_related_controls' })(BehaviorRecording)

export default FrequencyRate
