/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable dot-notation */
import React, { useState, useEffect, useMemo } from 'react'
import { Row, Col, Button, Badge, Form, DatePicker, Table, Tooltip } from 'antd'
import { FaDownload } from 'react-icons/fa'
import { BiDollar } from 'react-icons/bi'
import { GoCheck, GoPrimitiveDot } from 'react-icons/go'
import { gql } from 'apollo-boost'
import { groupBy } from 'lodash'
import moment from 'moment'
import { useLazyQuery } from 'react-apollo'
import * as XLSX from 'xlsx'
import { COLORS } from 'assets/styles/globalStyles'
import DropSection from './DropSection'
import './table.scss'

const { RangePicker } = DatePicker

const filterCardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
  backgroundColor: COLORS.palleteLight,
}

const parentDiv = { display: 'flex', margin: '5px 40px 5px 5px' }

const TIMESHEETS = gql`
  query($dateGte: Date!, $dateLte: Date!, $staffId: ID) {
    timesheets(dateGte: $dateGte, dateLte: $dateLte, staffId: $staffId) {
      edges {
        node {
          user {
            id
            firstName
            lastName
          }
          id
          title
          start
          end
          isApproved
          isBillable
        }
      }
    }
  }
`

const APPOINTMENTS = gql`
  query($dateFrom: Date!, $dateTo: Date!, $therapist: ID) {
    appointments(dateFrom: $dateFrom, dateTo: $dateTo, therapist: $therapist) {
      edges {
        node {
          therapist {
            id
            name
            surname
          }
          id
          title
          start
          end
          isApproved
          appointmentStatus {
            id
            appointmentStatus
          }
        }
      }
    }
  }
`

const getDatesBetween = (startDate, endDate) => {
  const now = startDate.clone()
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'))
    now.add(1, 'days')
  }
  return dates
}

function Timesheet() {
  const [columns, setColumns] = useState([])
  const [dateRR, setDateRR] = useState([
    moment()
      .startOf('week')
      .add(1, 'day'),
    moment()
      .endOf('week')
      .add(1, 'day'),
  ])

  const [getTimeSheetData, { data: timeSheetData, loading: timeSheetLoading }] = useLazyQuery(
    TIMESHEETS,
  )
  const [getAppointmentData, { data: appointmentData, loading: appointmentLoading }] = useLazyQuery(
    APPOINTMENTS,
  )

  useEffect(() => {
    if (timeSheetData && appointmentData && !appointmentLoading && !timeSheetLoading) {
      filterTableDataColumns(timeSheetData?.timesheets.edges, appointmentData?.appointments.edges)
    }
  }, [timeSheetData, appointmentData])

  useEffect(() => {
    if (dateRR) {
      getTimeSheetData({
        variables: {
          dateGte: moment(dateRR[0]).format('YYYY-MM-DD'),
          dateLte: moment(dateRR[1]).format('YYYY-MM-DD'),
        },
      })
      getAppointmentData({
        variables: {
          dateFrom: moment(dateRR[0]).format('YYYY-MM-DD'),
          dateTo: moment(dateRR[1]).format('YYYY-MM-DD'),
        },
      })
    }
  }, [dateRR])

  // Accumulate duration by Date
  const accumulateDuration = data => {
    const duration = data.reduce((acc, curr) => {
      const d = acc + curr.duration
      return d
    }, 0)
    return duration
  }

  // Accumulate duration by Status
  const accumulateDurations = data => {
    const approvedDuration = data.reduce((acc, curr) => {
      let d = 0
      if (curr.badges.approved) {
        d = acc + curr.duration
        return d
      }
      return d
    }, 0)

    const notApprovedDuration = data.reduce((acc, curr) => {
      let d = 0
      if (!curr.badges.approved) {
        d = acc + curr.duration
        return d
      }
      return d
    }, 0)
    return { approvedDuration, notApprovedDuration }
  }

  // Data mapping for table
  const dataList = useMemo(() => {
    const newTimeSheetData = []
    const newAppointmentData = []
    if (timeSheetData) {
      timeSheetData?.timesheets.edges.forEach(({ node }) => {
        const start = moment(node.start)
        const end = moment(node.end)
        const duration = moment.duration(end.diff(start)).as('minutes')
        newTimeSheetData.push({
          id: node.id,
          therapistId: node.user.id,
          name: node.title,
          therapist: node.user.firstName + ' ' + node.user.lastName,
          start: node.start,
          end: node.end,
          duration,
          approvedDuration: node.isApproved ? duration : 0,
          notApprovedDuration: !node.isApproved ? duration : 0,
          [moment(node.start).format('YYYY-MM-DD')]: duration,
          date: moment(node.start).format('YYYY-MM-DD'),
          badges: { approved: node.isApproved, billable: node.isBillable, overtime: false },
          isWorkLog: true,
        })
      })
    }

    if (appointmentData) {
      appointmentData?.appointments.edges.forEach(({ node }) => {
        const start = moment(node.start)
        const end = moment(node.end)
        const duration = moment.duration(end.diff(start)).as('minutes')
        newAppointmentData.push({
          id: node.id,
          therapistId: node.therapist.id,
          therapist: node.therapist.name + ' ' + node.therapist.surname,
          name: node.title,
          duration,
          start: node.start,
          end: node.end,
          approvedDuration: node.isApproved ? duration : 0,
          notApprovedDuration: !node.isApproved ? duration : 0,
          [moment(node.start).format('YYYY-MM-DD')]: duration,
          date: moment(node.start).format('YYYY-MM-DD'),
          badges: {
            approved: node.isApproved,
            billable: false,
            overtime: false,
          },
          isAppointment: true,
        })
      })
    }

    return {
      newTimeSheetData: groupBy(newTimeSheetData, 'therapistId'),
      newAppointmentData: groupBy(newAppointmentData, 'therapistId'),
    }
  }, [timeSheetData, appointmentData])

  const dataView = useMemo(() => {
    const newData = []
    Object.keys(dataList?.newAppointmentData)?.forEach(key => {
      const nodes = dataList?.newAppointmentData[key]
      const groupedByDate = groupBy(nodes, 'date')
      const durationsByDate = {}

      // accumulate durations by date
      Object.keys(groupedByDate).forEach(childKey => {
        durationsByDate[childKey] = accumulateDuration(groupedByDate[childKey])
      })

      // Accumulated Approved and Not Approved durations
      const statusDaurations = accumulateDurations(nodes)

      const tempObj = {
        id: key,
        name: nodes[0].therapist,
        date: nodes[0].date,
        isAppointment: true,
        parent: null,
        ...durationsByDate,
        ...statusDaurations,
      }
      tempObj.children = nodes
      newData.push(tempObj)
    })

    Object.keys(dataList?.newTimeSheetData)?.forEach(key => {
      const nodes = dataList?.newTimeSheetData[key]
      const groupedByDate = groupBy(nodes, 'date')
      const durationsByDate = {}
      Object.keys(groupedByDate).forEach(childKey => {
        durationsByDate[childKey] = accumulateDuration(groupedByDate[childKey])
      })
      const tempObj = {
        id: key,
        name: nodes[0].therapist,
        date: nodes[0].date,
        isWorkLog: true,
        parent: null,
        ...durationsByDate,
      }
      tempObj.children = nodes
      newData.push(tempObj)
    })
    return newData
  }, [dataList])

  function filterTableDataColumns() {
    const newColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: '200px',
        render: (text, record) => (
          <Badge color={record.isAppointment ? 'lightpink' : 'lightblue'} text={text} />
        ),
      },
      {
        title: 'Approved',
        dataIndex: 'approvedDuration',
        key: 'approvedDuration',
        fixed: 'left',
        width: '120px',
        render: (text, row) => {
          let hrs = 0
          let min = 0
          if (text > 0) {
            hrs = Math.floor(text / 60)
            min = Math.floor(text % 60)
          }
          return hrs > 0 ? `${hrs} Hrs ${min} Min` : `${min} Min`
        },
      },
      {
        title: 'Not Approved',
        dataIndex: 'notApprovedDuration',
        key: 'notApprovedDuration',
        fixed: 'left',
        width: '130px',
        render: (text, row) => {
          let hrs = 0
          let min = 0
          if (text > 0) {
            hrs = Math.floor(text / 60)
            min = Math.floor(text % 60)
          }
          return hrs > 0 ? `${hrs} Hrs ${min} Min` : `${min} Min`
        },
      },
    ]

    const dates = getDatesBetween(dateRR[0], dateRR[1])

    dates.forEach((date, i) => {
      newColumns.push({
        title: (
          <span style={{ fontWeight: 'bold', fontSize: '13px' }}>
            {moment(date).format('DD MMM YYYY, ddd')}
          </span>
        ),
        dataIndex: date,
        key: `${date}_${i}`,
        align: 'center',
        width: '150px',
        render: (text, row) => {
          let hrs = 0
          let min = 0
          if (text > 0) {
            hrs = Math.floor(text / 60)
            min = Math.floor(text % 60)
          }
          return hrs > 0 ? `${hrs} Hrs ${min} Min` : `${min} Min`
        },
      })
    })

    newColumns.push({
      title: <span style={{ fontWeight: 'bold', fontSize: '13px' }}>Status</span>,
      dataIndex: 'badges',
      key: 'badges',
      fixed: 'right',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return (
          <div>
            {record?.badges && (
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Tooltip placement="top" title="Approved">
                  <Badge
                    count={
                      <GoCheck
                        style={{
                          fontSize: '18px',
                          color: record?.badges?.approved ? 'limegreen' : 'darkgrey',
                          cursor: 'pointer',
                        }}
                      />
                    }
                  />
                </Tooltip>
                <Tooltip placement="top" title="Billable">
                  <Badge
                    count={<BiDollar />}
                    style={{
                      fontSize: '18px',
                      color: record?.badges?.billable ? 'limegreen' : 'darkgrey',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title="Overtime">
                  <Badge
                    count={<GoPrimitiveDot />}
                    style={{
                      fontSize: '18px',
                      color: record?.badges?.overtime ? 'limegreen' : 'darkgrey',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    })

    setColumns(newColumns)
  }

  const exportToCSV = () => {
    const tableElt = document.getElementById('timesheet_table')
    const workbook = XLSX.utils.table_to_book(tableElt)
    const ws = workbook.Sheets['Sheet1']
    XLSX.utils.sheet_add_aoa(ws, [['Created ' + new Date().toISOString()]], { origin: -1 })

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, 'Report.xlsx')
  }

  const handleDateSelectionChange = date => {
    setDateRR([moment(date[0]), moment(date[1])])
  }

  return (
    <>
      <Row>
        <Col sm={24}>
          <Row>
            <Col span={26}>
              <div style={filterCardStyle}>
                <div style={parentDiv}>
                  <DropSection dateChange={v => handleDateSelectionChange(v)} />
                </div>
                <div style={parentDiv}>
                  <Tooltip title="Select Date range">
                    <RangePicker
                      style={{
                        marginLeft: 'auto',
                        width: 250,
                      }}
                      value={[moment(dateRR[0]), moment(dateRR[1])]}
                      format="YYYY-MM-DD"
                      onChange={handleDateSelectionChange}
                    />
                  </Tooltip>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    type="link"
                    size="large"
                    disabled={appointmentLoading && timeSheetLoading && dataView.length === 0}
                    onClick={exportToCSV}
                    style={{ padding: '0 8px' }}
                  >
                    <FaDownload fontSize="22px" />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ margin: '10px 0 10px 10px' }}>
            <Table
              id="timesheet_table"
              rowKey="id"
              columns={columns}
              dataSource={dataView}
              bordered
              className="goal-table allReportTable"
              loading={appointmentLoading && timeSheetLoading}
              scroll={{ x: 50 }}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Form.create()(Timesheet)
