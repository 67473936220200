import { uniqBy } from 'lodash'
import ACTIONS from './actions'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_INITIAL_STATE: {
      const { behaviorTemplatesEdges } = action.payload
      const behaviorList = behaviorTemplatesEdges.map(({ node }) => node.behavior)

      return {
        ...state,
        behaviorList: uniqBy(behaviorList, 'id'),
        selectedBehavior: behaviorTemplatesEdges.at(0)?.node.behavior.id,
        behaviorName: behaviorTemplatesEdges.at(0)?.node.behavior.behaviorName,
        behaviorTemplate: behaviorTemplatesEdges,
        loading: false,
      }
    }

    case ACTIONS.CHANGE_DATE_RANGE: {
      return { ...state, ...action.payload }
    }
    case ACTIONS.SELECT_BEHAVIOR: {
      return { ...state, ...action.payload }
    }
    case ACTIONS.CLOSE_PDF_DRAWER: {
      return { ...state, loadingPdf: false, pdfDrawer: false }
    }
    case ACTIONS.OPEN_PDF_DRAWER: {
      return { ...state, loadingPdf: true, pdfExporting: false, pdfDrawer: true }
    }
    case ACTIONS.SET_IMAGE: {
      return { ...state, image: { ...state.image, ...action.payload } }
    }
    case ACTIONS.START_PDF_EXPORT: {
      return { ...state, pdfExporting: true }
    }
    case ACTIONS.SET_SESSION_DATA: {
      const { sessionsData } = action.payload
      return { ...state, sessionsData }
    }
    default: {
      return state
    }
  }
}

export default reducer
