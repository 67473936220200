import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import ReactPlayer from 'react-player'
import './player.scss'
import moment from 'moment'

const Player = forwardRef(({ videoObject, playAnnotation, getVideoDuration }, ref) => {
  const [url, setUrl] = useState('')
  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [lastval, setLastval] = useState(null)
  const [seeking, setSeeking] = useState(null)
  const [playedSeconds, setPlayedSeconds] = useState(0)
  const [loadedSeconds, setLoadedSeconds] = useState(0)
  const [loaded, setLoaded] = useState(0)
  const player = useRef()

  useEffect(() => {
    setUrl(videoObject?.url)
  }, [])

  useImperativeHandle(ref, () => ({
    handleSeekChange,
  }))

  const onReadyVideo = e => {
    if (playAnnotation) {
      player.current.seekTo(parseFloat(playAnnotation.start))
      setPlaying(true)
    }
  }

  const handleProgress = e => {
    if (!seeking) {
      setPlayedSeconds(e.playedSeconds)
      setPlayed(e.played)
      setLoaded(e.loaded)
      setLoadedSeconds(e.loadedSeconds)
    }
    if (playAnnotation && e.playedSeconds >= playAnnotation.end) {
      setPlaying(false)
    }
  }

  const handleSeekChange = e => {
    if (lastval == null) {
      player.current.seekTo(parseFloat(e[e.length - 1]))
    } else {
      if (lastval[0] === e[e.length - 2]) {
        player.current.seekTo(parseFloat(e[e.length - 1]))
        setPlayed(parseFloat(e[e.length - 1]))
      }
      if (lastval[1] === e[e.length - 1]) {
        player.current.seekTo(parseFloat(e[e.length - 2]))
        setPlayed(parseFloat(e[e.length - 2]))
      }
    }
    const plyState = e.slice(e.length - 2, e.length)
    setLastval(plyState)
    setPlaying(true)
  }

  const handleDuration = duration => {
    getVideoDuration(duration)
  }

  return (
    <>
      {url && (
        <ReactPlayer
          ref={player}
          url={url}
          onReady={onReadyVideo}
          width="100%"
          height={400}
          playing={playing}
          progressInterval={100}
          controls
          onDuration={handleDuration}
          onProgress={handleProgress}
        />
      )}
      <div>
        Time:{' '}
        {playedSeconds && playedSeconds !== undefined
          ? moment(moment.duration(playedSeconds.toFixed(2), 'seconds')).format('hh:mm:ss')
          : 0}
        s
      </div>
    </>
  )
})

export default Player
