/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import { all, put, takeEvery, call, select } from 'redux-saga/effects'
import { notification, message } from 'antd'
import { updateFeedbackSubmit } from 'services/copyTarget'
import actions from './actions'

export function* COPY_ITEM({ payload }) {
  yield put({
    type: 'copyTarget/SET_STATE',
    payload: {
      ...payload,
      ItemCopied: true,
    },
  })
  message.success(`${payload.Type} copied to clipboard`)
}

export function* PASTE_ITEM({ payload }) {
  yield put({
    type: 'copyTarget/SET_STATE',
    payload: {
      SubmitLoading: true,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.COPY_ITEM, COPY_ITEM), takeEvery(actions.PASTE_ITEM, PASTE_ITEM)])
}
