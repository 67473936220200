import { Image, Text, View } from '@react-pdf/renderer'
import { COLORS } from 'assets/styles/globalStyles'
import React, { useEffect, useState } from 'react'
// import Dlogo from '../../../../images/CogniableLogo.jpeg'

const section = {
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}
const flexSection = {
  width: '100%',
  display: 'flex',
  color: 'black',
  flexDirection: 'row',
  padding: '5px 8px',

  textAlign: 'left',
}
const dateSection = {
  width: '40%',
  color: 'black',
  fontSize: 12,
  fontWeight: '500',
  padding: 2,

  // height: '60px',
}

const PdfHeader = ({ data, type }) => {
  const [school, setSchool] = useState({})
  const [cData, setCData] = useState({})
  useEffect(() => {
    if (type === 'Progress') {
      if (data && data.school) {
        setSchool(data.school)
        setCData(data)
      }
    }

    if (type === 'Goals') {
      if (data && data[0].school) {
        setSchool(data[0].school)
        setCData(data[0])
      }
    }
  }, [data])

  const { logo, schoolName, address } = school || {}

  return (
    <>
      <View
        style={{
          display: 'flex',
          textAlign: 'center',
          backgroundColor: COLORS.palleteLight,
          padding: '8px',
          alignItems: 'center',
        }}
      >
        <Text style={{ margin: 'auto' }}>{type} Report</Text>
        {/* <Image src={logo}></Image> */}
      </View>
      {/* NEED TO SOLVED CORS ERROR FROM BACKEND TO SHOW CLINIC LOGO & Albi Implemented temporary code for now.  */}

      <View style={{ ...section, height: '150px', justifyContent: 'space-between' }}>
        <View
          style={{
            textAlign: 'center',
            width: '250px',
            alignSelf: 'center',
            marginLeft: '20px',
            height: 'auto',
          }}
        >
          <Text
            style={{
              fontSize: 15,
              marginBottom: '4px',
              width: '100%',
              alignSelf: 'flex-start',
              textAlign: 'left',
            }}
          >
            {schoolName}
          </Text>
          <Text
            style={{
              marginBottom: '4px',
              fontSize: 12,
              width: '100%',
              alignSelf: 'flex-start',
              textAlign: 'left',
            }}
          >
            {address}
          </Text>
        </View>
        <View style={{ width: '30%', alignSelf: 'center' }}>
          {logo && <Image src={logo} style={{ width: '70%', alignSelf: 'center' }} />}
        </View>
      </View>
      <View style={{ ...section, height: 150, padding: 0 }}>
        <View
          style={{
            width: '60%',
            height: '100%',
          }}
        >
          <View style={{ ...flexSection, paddingBottom: 0 }}>
            <Text style={{ ...dateSection, fontWeight: 'bold' }}>Name:</Text>
            <Text style={{ ...dateSection, width: '60%' }}>
              {cData?.firstname} {cData?.lastname}
            </Text>
          </View>

          <View style={{ ...flexSection, paddingBottom: 0 }}>
            <Text style={{ ...dateSection, fontWeight: 'bold' }}> Gender: </Text>
            <Text style={{ ...dateSection, width: '60%' }}> {cData?.gender} </Text>
          </View>
          <View style={{ ...flexSection, paddingBottom: 0 }}>
            <Text style={{ ...dateSection, fontWeight: 'bold' }}> Email: </Text>
            <Text style={{ ...dateSection, width: '60%' }}> {cData?.email} </Text>
          </View>
          <View style={{ ...flexSection }}>
            <Text style={{ ...dateSection, fontWeight: 'bold' }}> Mobile: </Text>
            <Text style={{ ...dateSection, width: '60%' }}> {cData?.mobileno} </Text>
          </View>
        </View>
      </View>
    </>
  )
}

export default PdfHeader
