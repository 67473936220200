/* eslint-disable prefer-template */
/* eslint-disable  react-hooks/rules-of-hooks */
/* eslint-disable no-array-constructor */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import { STUDENT_QUERY } from '../monthly-reports/monthlyReport.query'
import gql from 'graphql-tag'
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Font } from '@react-pdf/renderer'
import { Typography, Row, Layout, Col, Tabs, Card, Table, Tag, Select, Button } from 'antd'
import moment from 'moment'
import { calculateAge } from '../../../utilities'
import { COLORS } from 'assets/styles/globalStyles'
import PdfHeader from '../pdf/PdfHeader/PdfHeader'
const LOGO = gql`
  query {
    schoolDetail {
      logo
      schoolName
      address
    }
  }
`
const sectionMain = {
  width: '100%',
  border: '1px solid black',
  color: 'black',
  height: '100%',
}

const flexSection = {
  width: '100%',
  display: 'flex',
  color: 'black',
  flexDirection: 'row',
  padding: '5px 8px',

  textAlign: 'left',
}
const dateSection = {
  width: '40%',
  color: 'black',
  fontSize: 12,
  fontWeight: '500',
  padding: 2,

  // height: '60px',
}

const dateFormat = 'YYYY-MM-DD'
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const page = {
  padding: '20px 15px',
}
const section = {
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  color: 'black',
  width: '100%',
  borderBottom: '1px solid black',
}

const BehaviorPDF = ({ title, start, end, studentId, image, behavior }) => {
  console.log('goalsImages>>>', image, start, end, studentId, behavior)
  const { data, error, loading } = useQuery(LOGO, { fetchPolicy: 'no-cache' })
  const { data: stData } = useQuery(STUDENT_QUERY, {
    query: STUDENT_QUERY,
    variables: {
      studentId,
    },
    fetchPolicy: 'network-only',
  })
  const [logo, setLogo] = useState()
  const [learnerDetails, setLearnerDetails] = useState()
  useEffect(() => {
    if (data) {
      console.log('logo-->', data)
      setLogo(data.schoolDetail)
    }
  }, [data])

  useEffect(() => {
    if (stData) {
      console.log('stdata>>', stData)
      setLearnerDetails(stData.student)
    }
  }, [stData])

  return (
    <PDFViewer style={{ margin: '0 auto', width: '100%', height: '750px' }}>
      <Document>
        <Page size="A4" wrap={true} style={page} scale={1}>
          <View style={sectionMain}>
            <View
              style={{
                display: 'flex',
                textAlign: 'center',
                backgroundColor: COLORS.palleteLight,
                padding: '8px',
                alignItems: 'center',
              }}
            >
              {console.log('learnerDetails', learnerDetails)}
              <Text style={{ margin: 'auto' }}>{title} Report</Text>
              {/* <Image src={logo}></Image> */}
            </View>
            <View style={{ ...section, justifyContent: 'space-between' }}>
              <View style={{ fontSize: '11px', padding: '15px 0' }}>
                <Text style={{ marginBottom: '5px' }}>
                  Name: {learnerDetails?.firstname} {learnerDetails?.lastname}
                </Text>
                <Text style={{ marginBottom: '5px' }}>Date of Birth: {learnerDetails?.dob}</Text>
                <Text style={{ marginBottom: '5px' }}>
                  Age: {learnerDetails?.dob && calculateAge(learnerDetails?.dob)}
                </Text>
                <Text style={{ marginBottom: '5px' }}>
                  Location: {learnerDetails?.currentAddress}
                </Text>
                <Text style={{ marginBottom: '5px' }}>
                  From Date: {moment(start).format('YYYY-MM-DD')}
                </Text>
                <Text style={{ marginBottom: '5px' }}>
                  End Date: {moment(end).format('YYYY-MM-DD')}
                </Text>
              </View>
              <View style={{ width: '20%', alignSelf: 'center' }}>
                {/* NEED TO SOLVED CORS ERROR FROM BACKEND TO SHOW CLINIC LOGO & Albi Implemented temporary code for now.  */}
                {logo && logo.logo && (
                  <Image src={logo.logo} style={{ width: '100%', alignSelf: 'center' }} />
                )}
              </View>
            </View>

            <View
              style={{
                margin: '4px',
                fontSize: 12,
                width: '100%',
                alignSelf: 'flex-start',
                textAlign: 'left',
              }}
            >
              <Text>Behavior: {behavior}</Text>
            </View>
            <View>
              <View>
                {image.imageFRData && (
                  <>
                    <View
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        marginTop: '10px',
                        fontSize: '11px',
                        backgroundColor: COLORS.palleteLight,
                        padding: 8,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Text style={{ margin: 'auto' }}>Freqency And Rate Recording</Text>
                    </View>
                    <Image
                      src={image.imageFRData}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                  </>
                )}
              </View>

              <View>
                {image.imageDRData && (
                  <>
                    <View
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        marginTop: '10px',
                        fontSize: '11px',
                        backgroundColor: COLORS.palleteLight,
                        padding: 8,
                      }}
                    >
                      <Text style={{ margin: 'auto' }}>Duration Recording</Text>
                    </View>
                    <Image
                      src={image.imageDRData}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                  </>
                )}
              </View>
              <View>
                {image.imageLRData && (
                  <>
                    <View
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        marginTop: '10px',
                        backgroundColor: COLORS.palleteLight,
                        padding: 8,
                        fontSize: '11px',
                      }}
                    >
                      <Text style={{ margin: 'auto' }}>Latency Recording</Text>
                    </View>
                    <Image
                      src={image.imageLRData}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                  </>
                )}
              </View>
              <View>
                {image.imagePIData && (
                  <>
                    <View
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        marginTop: '10px',
                        backgroundColor: COLORS.palleteLight,
                        padding: 8,
                        fontSize: '11px',
                      }}
                    >
                      <Text style={{ margin: 'auto' }}>Partial Interval</Text>
                    </View>
                    <Image
                      src={image.imagePIData}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                  </>
                )}
              </View>
              <View>
                {image.imageWIData && (
                  <>
                    <View
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        marginTop: '10px',
                        backgroundColor: COLORS.palleteLight,
                        padding: 8,
                        fontSize: '11px',
                      }}
                    >
                      <Text style={{ margin: 'auto' }}>Whole Interval</Text>
                    </View>
                    <Image
                      src={image.imageWIData}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                  </>
                )}
              </View>
              <View>
                {image.imageMTData && (
                  <>
                    <View
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        marginTop: '10px',
                        backgroundColor: COLORS.palleteLight,
                        padding: 8,
                        fontSize: '11px',
                      }}
                    >
                      <Text style={{ margin: 'auto' }}>Momentary Time Sample</Text>
                    </View>
                    <Image
                      src={image.imageMTData}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '10px auto',
                      }}
                    ></Image>
                  </>
                )}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default BehaviorPDF
