import React, { useRef, useState } from 'react'
import { Button, notification, Progress } from 'antd'
import Webcam from 'react-webcam'
import axios from 'axios'
import './webcam.scss' // Import the CSS file for styling
import { REACT_APP_API_URL } from 'apollo/REACT_APP_URL'
import { COLORS } from 'assets/styles/globalStyles'

const WebcamCapture = ({ setSelfieUrl }) => {
  const webcamRef = useRef(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [imageSrc, setImageSrc] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const convertBase64ToImage = base64Image => {
    const base64Data = base64Image.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    const binaryData = Buffer.from(base64Data, 'base64')

    const blob = new Blob([binaryData], { type: 'image/png' }) // Adjust the type accordingly

    const file = new File([blob], 'image.png', { type: 'image/png' }) // Adjust the filename and type accordingly

    return file
  }
  const captureImage = async () => {
    const imageSrc1 = webcamRef.current.getScreenshot()

    // Create a new form data object and append the image file
    const imageFile = convertBase64ToImage(imageSrc1)
    const imageUrl = URL.createObjectURL(imageFile)
    setImageSrc(imageUrl)
    const formData = new FormData()
    formData.append('image', imageFile)

    setIsSaving(true)
    // Configure the Axios request to track the upload progress
    axios
      .post(`${REACT_APP_API_URL}/upload-staff-attendance-selfie/`, formData, {
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          console.log('[progress==>', progress)
          setUploadProgress(progress)
          setIsSaving(true)
        },
      })
      .then(response => {
        setIsSaving(false)
        console.log('Upload response:', response.data)
        notification.success({
          message: 'Selfie uploaded successfully.',
        })
        setSelfieUrl(response.data.image_url)
        setUploadProgress(0)
      })
      .catch(error => {
        console.error('Failed to upload image:', error)
        setUploadProgress(0)
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Webcam
        ref={webcamRef}
        style={{ width: '300px', height: '300px' }}
        audio={false}
        screenshotFormat="image/png"
      />

      <Button
        onClick={captureImage}
        loading={isSaving}
        type="primary"
        style={{
          backgroundColor: COLORS.steps,
          borderColor: COLORS.steps,
        }}
      >
        Capture Image
      </Button>
      {/* {uploadProgress > 0 && <Progress percent={uploadProgress} size="small" />} */}
    </div>
  )
}

export default WebcamCapture
