/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { Button, Modal, notification, Popconfirm } from 'antd'
import LoadingComponent from 'components/LoadingComponent'
import { useSelector } from 'react-redux'
import { COLORS, PRIMARY_ORANGE_BUTTON } from 'assets/styles/globalStyles'
import { GET_MASTERY_SEQ_TEMPLATE, DELETE_MASTERY_SEQ_TEMPLATE } from './query'
import CreateSeqTemplate from './createSeqTemplate'
import { MasterySeqProvider, useMasterySeq } from './context'
import Templateform from './templateForm'
import './styles.scss'

const Index = () => {
  const school = useSelector(state => state.school)

  const user = useSelector(state => state.user)

  const [templates, setTemplates] = useState([])
  const [createTemplateModal, setCreateTemplateModal] = useState(false)
  const [editTemplate, setEditTemplate] = useState(null)
  const { selectedTemplate, setSelectedTemplate } = useMasterySeq()

  const [
    fetchSeqTemplates,
    { data: seqTemplates, loading: seqTemplateLoading, refetch: refetchTemplates },
  ] = useLazyQuery(GET_MASTERY_SEQ_TEMPLATE)

  const [deleteSeqTemplate] = useMutation(DELETE_MASTERY_SEQ_TEMPLATE)

  useEffect(() => {
    if (school?.id) {
      fetchSeqTemplates({
        variables: {
          clinicId: school.id,
        },
      })
    }
  }, [school?.id])

  useEffect(() => {
    if (user?.role === 'therapist' && user?.staffObject?.school) {
      fetchSeqTemplates({
        variables: {
          clinicId: user.staffObject.school.id,
        },
      })
    }
  }, [user])

  useEffect(() => {
    if (seqTemplates) {
      setTemplates(seqTemplates?.getCriteriaSequenceTemplate)
    }
  }, [seqTemplates])

  return (
    <div className="profileTab-container" style={{ width: '100%', margin: '0 auto', padding: 2 }}>
      {selectedTemplate ? (
        <Templateform edit={user?.role === 'school_admin'} setEditTemplate={setEditTemplate} />
      ) : (
        <div>
          {user?.role === 'school_admin' && (
            <div style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => setCreateTemplateModal(true)}
                style={PRIMARY_ORANGE_BUTTON}
              >
                <PlusOutlined />
                Add New
              </Button>
            </div>
          )}
          {seqTemplateLoading ? (
            <LoadingComponent />
          ) : (
            <div>
              {templates?.map(temp => {
                return (
                  <div
                    key={temp.id}
                    className="seq-template-card"
                    onClick={() => {
                      setSelectedTemplate(temp)
                    }}
                  >
                    <div className="flexCenterJust">
                      <span>{temp.name}</span>
                      {user?.role === 'school_admin' && (
                        <div>
                          <Button
                            onClick={e => {
                              e.stopPropagation()
                              setEditTemplate(temp)
                            }}
                            style={{ padding: '0 8px' }}
                            type="link"
                          >
                            <EditOutlined style={{ color: COLORS.primary }} />
                          </Button>
                          <Popconfirm
                            trigger="click"
                            title="Are you sure to delete this template ?"
                            onCancel={e => e.stopPropagation()}
                            onConfirm={e => {
                              e.stopPropagation()
                              if (temp.id) {
                                deleteSeqTemplate({
                                  variables: {
                                    pk: temp.id,
                                  },
                                })
                                  .then(res => {
                                    refetchTemplates()
                                    notification.success({
                                      message: 'Mastery sequence template deleted successfully.',
                                    })
                                  })
                                  .catch(ert => console.error(ert))
                              }
                            }}
                          >
                            <Button
                              onClick={e => e.stopPropagation()}
                              style={{ padding: '0 8px' }}
                              type="link"
                            >
                              <DeleteOutlined style={{ color: COLORS.danger }} />
                            </Button>
                          </Popconfirm>
                        </div>
                      )}
                    </div>
                    <p>{temp.description}</p>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}

      <Modal
        title="Create Mastery Sequence Template"
        visible={createTemplateModal}
        width={1000}
        footer={null}
        destroyOnClose
        onCancel={() => setCreateTemplateModal(false)}
      >
        <CreateSeqTemplate
          schoolId={school.id}
          onSuccess={() => refetchTemplates()}
          closeModal={() => {
            setCreateTemplateModal(false)
          }}
        />
      </Modal>
      <Modal
        title="Edit Mastery Sequence Template"
        visible={!!editTemplate}
        width={1000}
        footer={null}
        destroyOnClose
        onCancel={() => setEditTemplate(null)}
      >
        <CreateSeqTemplate
          schoolId={school.id}
          onSuccess={() => refetchTemplates()}
          closeModal={() => {
            setEditTemplate(null)
          }}
          edit
          currentTemplate={editTemplate}
        />
      </Modal>
    </div>
  )
}

export default props => {
  return (
    <MasterySeqProvider>
      <Index {...props} />
    </MasterySeqProvider>
  )
}
