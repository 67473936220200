import React from 'react'
import StudentAndTestDetails from '../StudentAndTestDetails'
import TransitionGraph from './TransitionGraph'

const TransitionAssessmentTab = ({ data, onSelectionChange, refetch, areaId }) => (
  <div className="transitionTab" id="transitionAssessment">
    <StudentAndTestDetails
      scoreDetails={data.scoreDetails}
      studentDetails={data.studentDetails}
      onSelectionChange={id => onSelectionChange(id)}
      refetch={() => refetch()}
      areaId={areaId}
    />
    <TransitionGraph questions={data.questions} scoreDetails={data.scoreDetails} />
  </div>
)

export default TransitionAssessmentTab
