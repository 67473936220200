/* eslint-disable camelcase */
import { Button, Icon, notification, Switch, Popconfirm } from 'antd'

import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  GET_STUDENT_DETAILS,
  ISACTIVE,
  Purchase_VBMAPP_Subscription,
  VBMAPP_Activate_Student,
  VBMAPP_Check_Subscription,
} from './query'

const StudentDrawer = props => {
  const user = useSelector(state => state.user)
  const student = useSelector(state => state.student)

  const dispatch = useDispatch()
  const [isVBMAPPActive, setIsVBMAPPActive] = useState(false)
  const [studentDetails, setStudentDetails] = useState(null)
  const [isVBMAPPSubscribed, setIsVBMAPPSubscribed] = useState(false)
  const [isVBMAPPExpired, setIsVBMAPPExpired] = useState(false)
  const [validFrom, setValidFrom] = useState(null)
  const [validUpto, setValidUpto] = useState(null)

  const [getDetails, { data, loading }] = useLazyQuery(GET_STUDENT_DETAILS, {
    fetchPolicy: 'network-only',
  })

  const [getStatus, { data: statusData }] = useMutation(ISACTIVE)
  const [checkSubscription] = useMutation(VBMAPP_Check_Subscription)
  const [purchaseSubscription] = useMutation(Purchase_VBMAPP_Subscription)
  const [activateStudent] = useMutation(VBMAPP_Activate_Student)

  useEffect(() => {
    if (student.StudentId) {
      getDetails({
        variables: {
          studentId: student.StudentId,
        },
      })
      handleVbmapp(isVBMAPPActive, student.StudentId)
      getStatus({
        variables: {
          studentId: student.StudentId,
        },
      })
    } else {
      dispatch({
        type: 'learnersprogram/LOAD_DATA',
      })
    }
  }, [dispatch, getDetails, getStatus, handleVbmapp, isVBMAPPActive, student])

  useEffect(() => {
    if (data) {
      setStudentDetails(data.student)
    }
  }, [data])

  useEffect(() => {
    if (statusData) {
      setIsVBMAPPActive(statusData.vbmappIsActive.status)
    }
  }, [statusData])

  const generateNotification = text => {
    notification.warning({
      message: 'Warning',
      description: text,
    })
  }

  const redirectToAssessment = text => {
    if (text === 'VB-MAPP') {
      window.location.href = '/#/therapy/vbmapps/list'
    }
    if (text === 'CogniAble') {
      window.location.href = '/#/cogniableAssessment'
    }
    if (text === 'PEAK') {
      window.location.href = '/#/peak'
    }
  }

  const handleVbmapp = (isActive, std) => {
    checkSubscription({
      variables: {
        student: std,
      },
    })
      .then(result => {
        console.log('result>>>', result)
        if (result.data.vbmappIsSubscribed) {
          const {
            msg,
            status,
            isSubscribed,
            isExpired,
            validUpto: validto,
          } = result.data.vbmappIsSubscribed
          console.log('var>>>', msg, status, isSubscribed, isExpired)
          setValidUpto(validto)
          setIsVBMAPPSubscribed(isSubscribed)
          setIsVBMAPPExpired(isExpired)
        }
      })
      .catch(error => console.error(error))
  }

  const onlyPurchase = () => {
    const std = student?.StudentId
    purchaseSubscription({
      variables: {
        student: std,
      },
    })
      .then(res => {
        if (res.data.purchaseVbMappSubscription) {
          const { message, validUpto: validto } = res.data.purchaseVbMappSubscription
          notification.success({
            message,
          })
          setValidUpto(validto)
          setIsVBMAPPSubscribed(true)
          setIsVBMAPPExpired(false)
        }
      })
      .catch(e => {
        setIsVBMAPPActive(true)
      })
  }

  const purchaseVbmappSubScription = () => {
    const std = student?.StudentId
    if (!isVBMAPPActive && validUpto && !isVBMAPPExpired) {
      activateStudent({
        variables: {
          student: std,
          activate: !isVBMAPPActive,
        },
      }).then(res => {
        if (res.data.vbmappActivateStudent) {
          notification.success({
            message: res.data.vbmappActivateStudent.msg,
          })
          setIsVBMAPPActive(!isVBMAPPActive)
        }
      })
    } else {
      activateStudent({
        variables: {
          student: std,
          activate: !isVBMAPPActive,
        },
      }).then(r => {
        purchaseSubscription({
          variables: {
            student: std,
          },
        })
          .then(res => {
            if (res.data.purchaseVbMappSubscription) {
              const {
                message,
                status,
                validUpto: upto,
                validFrom: from,
              } = res.data.purchaseVbMappSubscription
              notification.success({
                message,
              })
              setIsVBMAPPActive(true)
              setValidUpto(upto)
              setValidFrom(from === 'NULL' ? null : from)
              setIsVBMAPPSubscribed(status)
              setIsVBMAPPExpired(false)
            }
          })
          .catch(e => {
            setIsVBMAPPActive(true)
          })
      })
    }
  }

  const handleAssessmentSwitch = (isActive, type) => {
    const std = student?.StudentId

    if (user.role === 'parent') {
      notification.info({
        message: 'Attention!',
        description: 'Please check with your clinic administrator this will be chargeable',
        duration: 5,
      })
    }

    if (std === null || std === undefined || std === '') {
      return notification.error({
        message: 'No student found! Please select a student first.',
      })
    }

    switch (type) {
      case 'Vbmapp':
        activateStudent({
          variables: {
            student: std,
            activate: !isActive,
          },
        })
          .then(res => {
            if (res.data.vbmappActivateStudent) {
              notification.success({
                message: res.data.vbmappActivateStudent.msg,
              })
              setIsVBMAPPActive(!isActive)
            }
          })
          .catch(error => console.error(error))
        break
      default:
        notification.info({
          message: 'Please provide valid assessment type',
        })
    }

    return ''
  }

  const tdStyle = { border: '1px solid #dddddd', padding: 8, textAlign: 'center' }

  return (
    <>
      <table style={{ borderCollapse: 'collapse', width: '100%', lineHeight: 2.5, marginTop: 20 }}>
        <tbody>
          <tr>
            <td style={{ ...tdStyle, width: 200 }}>
              <Button
                type="link"
                onClick={
                  isVBMAPPActive
                    ? () => {
                        redirectToAssessment('VB-MAPP')
                      }
                    : () => {
                        generateNotification('VB-MAPP assessment is not activated')
                      }
                }
              >
                <p style={{ display: 'block', marginTop: '5px', marginBottom: '-5px' }}>VB-MAPP</p>
              </Button>
              {validUpto && (
                <p
                  style={{
                    color: isVBMAPPExpired ? 'red' : 'grey',
                    fontWeight: 600,
                    fontSize: 13,
                    margin: 'initial',
                    marginTop: 10,
                  }}
                >
                  Valid Upto : {validUpto}
                </p>
              )}
            </td>
            <td style={{ ...tdStyle, width: 100 }}>
              {(!isVBMAPPActive && !isVBMAPPSubscribed && !isVBMAPPExpired) ||
              (!isVBMAPPActive && isVBMAPPExpired) ? (
                <Popconfirm
                  title={`Are you sure You want to ${
                    isVBMAPPExpired ? 'Renew' : 'Purchase'
                  } Vbmapp Subscription ?`}
                  onConfirm={e => {
                    e.stopPropagation()
                    purchaseVbmappSubScription()
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Switch
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    checked={isVBMAPPActive}
                  />
                </Popconfirm>
              ) : (
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  checked={isVBMAPPActive}
                  onChange={event => {
                    handleAssessmentSwitch(isVBMAPPActive, 'Vbmapp')
                  }}
                />
              )}
            </td>

            <td style={tdStyle}>
              <i>
                VBMAPP allows the learner to be assessed on key areas such as language, pre
                academics, social and group skills to identify any gaps and needs in overall
                development in each skill area.{' '}
              </i>
            </td>
            {((!isVBMAPPSubscribed && !isVBMAPPExpired && isVBMAPPActive) ||
              (isVBMAPPActive && isVBMAPPExpired)) && (
              <td style={{ ...tdStyle, padding: 0, width: 100 }}>
                <Popconfirm
                  title={`Are you sure You want to ${
                    isVBMAPPExpired ? 'Renew' : 'Purchase'
                  } Vbmapp Subscription ?`}
                  onConfirm={e => {
                    e.stopPropagation()
                    onlyPurchase()
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="link"
                    onClick={e => e.stopPropagation()}
                    style={{ fontWeight: 'bold' }}
                  >
                    {isVBMAPPExpired ? 'Renew' : 'Purchase'}
                  </Button>
                </Popconfirm>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default StudentDrawer
