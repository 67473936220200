import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Authorize from 'components/LayoutComponents/Authorize'
import { useDispatch, useSelector } from 'react-redux'

import {
  BulbOutlined,
  DashboardOutlined,
  ExperimentOutlined,
  FieldTimeOutlined,
  FileOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  RobotOutlined,
} from '@ant-design/icons'
import { faCogs, faStethoscope, faVial, faArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Scrollbar from 'react-custom-scrollbars'
import { Icon, Layout, Menu, Breadcrumb, Row, Col } from 'antd'
import DisabledCurriculum from 'pages/ClinicProfile/DisabledCurriculum'
import InvCurrency from 'pages/ClinicProfile/InvoiceRelated'
import HeaderComponent from 'components/HeaderComponentNew'
// import MasterCriteria from 'pages/ClinicProfile/Mastery'
import TherapistSetting from '../../ClinicProfile/TherapistShifting/landing'
import AssessmentsTab from '../../../components/AssessmentsProfileTab'
import SessionSettingLayout from '../SessionSettingLayout'
import OthersLanding from '../OthersLanding'
import 'antd/dist/antd.css'
import './styles.scss'
import '../Style.scss'
import ProfileTab from './ProfileTab'

const { Header, Content } = Layout

const tabs = [
  {
    key: 'profile',
    title: 'Profile Details',
    component: <ProfileTab />,
    iconName: <ProfileOutlined />,
  },
  {
    key: 'invoice-currency',
    title: 'Payment Details',
    component: <InvCurrency />,
    iconName: <FileOutlined />,
  },
  {
    key: 'session-setting',
    title: 'Session & Mastery Settings',
    component: <SessionSettingLayout />,
    iconName: <FieldTimeOutlined />,
  },

  {
    key: 'assessments',
    title: 'Assessments',
    component: <AssessmentsTab />,
    iconName: <Icon component={() => <FontAwesomeIcon icon={faVial} />} />,
  },
  {
    key: 'therapist-setting',
    title: 'Therapist Setting',
    component: <TherapistSetting />,
    iconName: <Icon component={() => <FontAwesomeIcon icon={faStethoscope} />} />,
  },
  {
    key: 'daterange-setting',
    title: 'Other Settings',
    component: <OthersLanding />,
    iconName: <FieldTimeOutlined />,
  },
  {
    key: 'disabled-curriculum',
    title: 'Archive',
    component: <DisabledCurriculum />,
    iconName: <Icon component={() => <FontAwesomeIcon icon={faArchive} />} />,
  },
]

const ClinicDashboard = () => {
  const school = useSelector(state => state.school)
  const dispatch = useDispatch()

  const [rightComponent, setRightComponent] = useState(<ProfileTab />)
  const [panelHeading, setPanelHeading] = useState('Profile Details')

  useEffect(() => {
    dispatch({
      type: 'learnersprogram/LOAD_DATA',
    })
    dispatch({
      type: 'staffs/GET_STAFFS',
      payload: {
        isActive: true,
      },
    })
    if (!school || !school?.id)
      dispatch({
        type: 'school/SCHOOL_DETAILS',
      })
  }, [])

  const handleMenuChange = item => {
    const selectedItem = tabs.find(tab => tab.key === item.key)
    setPanelHeading(selectedItem.title)
    if (selectedItem) setRightComponent(selectedItem.component)
  }

  return (
    <Authorize roles={['school_admin']} redirect to="/dashboard">
      <Helmet title="Clinic Settings" />
      <HeaderComponent title="Settings" pathList={['Settings']} />
      <Layout style={{ minHeight: 'calc(100vh - 140px)', marginTop: 1 }}>
        <Row>
          <Col span={5}>
            <Scrollbar style={{ height: 'calc(100vh - 120px)' }}>
              <Menu
                style={{ height: 'calc(100vh - 120px)' }}
                onSelect={handleMenuChange}
                mode="inline"
                defaultSelectedKeys={['profile']}
              >
                {tabs.map(tab => {
                  if (!tab.submenu) {
                    return (
                      <Menu.Item key={tab.key}>
                        {/* {tab.iconName} */}
                        <span>{tab.title}</span>
                      </Menu.Item>
                    )
                  }
                  return (
                    <Menu.SubMenu
                      title={
                        <span>
                          {tab.iconName}
                          <span>{tab.title}</span>
                        </span>
                      }
                      key={tab.key}
                      onTitleClick={() => handleMenuChange({ key: tab.key })}
                    >
                      {tab.submenu.map(label => (
                        <Menu.Item key={label[0]}>
                          {label[1]} <span>{label[0]}</span>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  )
                })}
              </Menu>
            </Scrollbar>
          </Col>
          <Col span={19}>
            <Content style={{ margin: '0 16px' }}>
              <div style={{ margin: '16px 0' }}>
                <Breadcrumb>
                  {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
                  <Breadcrumb.Item>Settings</Breadcrumb.Item>
                  <Breadcrumb.Item>{panelHeading}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div style={{ padding: 0, minHeight: 360 }}>
                <div>{rightComponent}</div>
              </div>
            </Content>
          </Col>
        </Row>
      </Layout>
    </Authorize>
  )
}
export default ClinicDashboard
