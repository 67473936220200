/* eslint no-underscore-dangle:0 */
import { Drawer } from 'antd'
import 'chartjs-plugin-annotation'
import { groupBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import { Line } from 'react-chartjs-2'
import { DRAWER } from '../../../assets/styles/globalStyles'
import { GET_BEHAVIOR_TEMPLATES } from '../../../components/BehaviourData/queries'
import LoadingComponent from '../../staffProfile/LoadingComponent'
import EditableTable from './EditableTable'
import PhaseTable from './PhaseTable'

const BehaviorFRChart = ({
  decelData,
  selectedStudentId,
  activeTab,
  keyname,
  startDate,
  endDate,
  selectedBehavior,
  setStateOfParent,
}) => {
  const [behName, setBehName] = useState('')
  const [isFRdata, setIsFRdata] = useState(false)
  const [clickedElement, setClickedElement] = useState('')
  const [dataRecord, setDataRecord] = useState([])
  const [phaseLines, setPhaseLines] = useState([])
  const [drawer, setDrawer] = useState(false)
  const [pointValue, setPointValue] = useState(null)
  let annotation = []

  startDate = moment(startDate).format('YYYY-MM-DD')
  endDate = moment(endDate).format('YYYY-MM-DD')

  const {
    data: tempData,
    error: templateError,
    loading: templateLoading,
    refetch: refetchTemplates,
  } = useQuery(GET_BEHAVIOR_TEMPLATES, {
    variables: {
      student: selectedStudentId,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (tempData && tempData.getBehaviorTemplates.edges) {
      const dataNodesList = []
      const phaseLineData = []
      tempData.getBehaviorTemplates.edges.forEach((element, index) => {
        const {
          templateName,
          behavior,
          behaviorType,
          frequencyratebehaviorrecordSet,
          statusrecordSet,
        } = element.node
        if (behaviorType.includes('FR')) {
          if (frequencyratebehaviorrecordSet) {
            const behaviorRecords = frequencyratebehaviorrecordSet.edges.map(({ node }) => ({
              id: node.id,
              date: node.date,
              startTime: node.startTime,
              endTime: node.endTime,
              duration: node.duration,
              frequency: node.frequency,
              environment: node.environment?.id,
              rate: node.rate,
              behaviorType: 'FR',
              tempData,
              behavior: behavior.behaviorName,
            }))

            if (statusrecordSet) {
              const status = statusrecordSet.edges.map(({ node }) => {
                return {
                  date: moment(node.statusTime).format('YYYY-MM-DD'),
                  status: node.status.statusName,
                  tempName: templateName,
                }
              })
              phaseLineData.push({
                status,
                behavior,
              })
            }
            const grouped = groupBy(behaviorRecords, 'date')

            const data = []

            Object.keys(grouped).forEach(item => {
              let duration = 0
              let frequency = 0
              let rate = 0
              grouped[item].forEach(d => {
                duration += d.duration
                frequency += d.frequency
                rate += d.rate
              })
              data.push({
                date: item,
                frequency,
                duration,
                rate,
                behavior,
              })
            })
            dataNodesList.push({
              behName: behavior.behaviorName,
              data,
            })
          }
        }
      })
      setDataRecord(dataNodesList)
      setPhaseLines(phaseLineData)
    }
  }, [tempData, selectedStudentId, selectedBehavior])

  useEffect(() => {
    if (tempData && tempData.getBehaviorTemplates.edges.length !== 0) {
      dataRecord.forEach(data => {
        data.data.map(item1 => {
          if (selectedBehavior && item1.behavior.id === selectedBehavior) {
            setIsFRdata(true)
            setBehName(item1.behavior.behaviorName)
          }
          return item1
        })
      })
    } else {
      setIsFRdata(false)
    }
  }, [dataRecord, tempData, selectedBehavior])

  useEffect(() => {
    if (pointValue) setClickedElement(pointValue)
  }, [pointValue])

  const getChartOptions = () => ({
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label',
    },
    annotation: {
      annotations: annotation,
    },
    elements: {
      line: {
        fill: false,
      },
    },

    scales: {
      xAxes: [
        {
          id: 'x-axis-0',
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Dates',
          },
          gridLines: {
            display: false,
          },
          // type: 'time',
          // time: {
          //   unit: 'day',
          // },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-2',
          gridLines: {
            display: true,
          },
          labels: {
            show: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Frequency',
          },
          ticks: {
            beginAtZero: true,
          },
        },

        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-1',
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Duration (in seconds)',
          },
        },
      ],
    },
  })

  const getOptionForRate = () => ({
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label',
    },
    onClick: (e, element) => {},
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          id: 'x-axis-0',
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Dates',
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: true,
          },
          labels: {
            show: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Rate',
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  })

  const generateLabels = () => {
    const daysCnt = Math.abs(moment(endDate).diff(startDate, 'days'))
    const labels = Array(daysCnt + 1)
      .fill(0)
      .map((itemValue, itemIndex) => {
        return moment(startDate)
          .add(itemIndex, 'day')
          .format('YYYY-MM-DD')
      })
    return labels
  }
  const result = generateLabels()

  let adata = {}

  phaseLines.forEach(item => {
    if (item.behavior.id === selectedBehavior) {
      adata = item.status.map(i => {
        return {
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: i.date,
          borderWidth: 2,
          borderColor: '#483D8B',
          text: i.status,
          label: {
            content: i.status,
            enabled: true,
            position: 'center',
            yAdjust: 6,
            rotation: 90,
          },
        }
      })
      annotation = adata
    }
  })

  const getChartData = () => {
    const frequency = new Array(result.length).fill(0)
    const duration = new Array(result.length).fill(0)

    result.forEach((item, index) => {
      dataRecord.forEach(data => {
        data.data.forEach(item1 => {
          if (
            selectedBehavior !== undefined &&
            item1.behavior.id === selectedBehavior &&
            item === item1.date
          ) {
            frequency[index] = item1.frequency + frequency[index]
            duration[index] = item1.duration + duration[index]
          }
          if (!frequency[index]) {
            frequency[index] = null
          }
          if (!duration[index]) {
            duration[index] = null
          }
        })
      })
    })

    const data = {
      labels: result,
      datasets: [
        {
          label: 'Frequency ',
          type: 'line',
          fill: false,
          borderColor: '#EC932F',
          backgroundColor: '#EC932F',
          radius: 4,
          hitRadius: 5,
          hoverRadius: 7,
          hoverBorderWidth: 2,
          pointBorderColor: '#EC932F',
          pointBackgroundColor: '#EC932F',
          pointHoverBackgroundColor: '#EC932F',
          pointHoverBorderColor: '#EC932F',
          lineTension: 0,
          yAxisID: 'y-axis-2',
          data: frequency,
        },
        {
          type: 'bar',
          label: 'Duration ',
          fill: false,
          backgroundColor: '#a6cee3',
          borderColor: '#a6cee3',
          hoverBackgroundColor: '#a6cee3',
          hoverBorderColor: '#a6cee3',
          yAxisID: 'y-axis-1',
          data: duration,
        },
      ],
    }
    return data
  }
  const chartData = getChartData()

  const getElementAtEvent = element => {
    if (!element.length) return

    const { _index } = element[0]

    setPointValue(chartData.labels[_index])
    setDrawer(true)
  }
  const getChartDataforRate = () => {
    const rate = new Array(result.length).fill(null)
    result.forEach((item, index) => {
      dataRecord.forEach(data => {
        data.data.forEach(item1 => {
          if (
            selectedBehavior !== undefined &&
            item1.behavior.id === selectedBehavior &&
            item === item1.date
          ) {
            rate[index] = rate[index] ? item1.rate + rate[index] : item1.rate
          } else if (!rate[index]) {
            rate[index] = null
          }
        })
      })
    })
    const data = {
      labels: result,
      datasets: [
        {
          label: 'Rate ',
          type: 'line',
          fill: false,
          borderColor: '#EC932F',
          backgroundColor: '#EC932F',
          radius: 4,
          hitRadius: 5,
          hoverRadius: 7,
          hoverBorderWidth: 2,
          pointBorderColor: '#EC932F',
          pointBackgroundColor: '#EC932F',
          pointHoverBackgroundColor: '#EC932F',
          pointHoverBorderColor: '#EC932F',
          lineTension: 0,
          yAxisID: 'y-axis-1',
          data: rate,
        },
      ],
    }
    return data
  }

  const showDrawerHandler = () => {
    setDrawer(true)
  }
  const hideDrawerHandler = () => {
    setDrawer(false)
  }

  useEffect(() => {
    const elem = document.getElementById('FR')
    if (elem) {
      setStateOfParent(elem)
    }
  }, [])

  return (
    <div id="FR">
      {templateLoading && <LoadingComponent />}
      {!templateLoading &&
        (isFRdata ? (
          <div style={{ backgroundColor: 'white' }}>
            <div style={{ margin: '10px' }}>
              <h4>1)Frequency and Duration Recording</h4>
            </div>
            <div>
              <Line
                options={getChartOptions()}
                data={getChartData()}
                getElementAtEvent={getElementAtEvent}
                height={400}
              />
            </div>
            <div style={{ margin: '10px' }}>
              <h4>2)Rate Recording</h4>
            </div>
            <div>
              <Line
                options={getOptionForRate()}
                data={getChartDataforRate()}
                getElementAtEvent={getElementAtEvent}
                height={400}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              margin: '20px auto',
              textAlign: 'center',
              padding: 5,
              backgroundColor: 'white',
            }}
          >
            No Records found,Please Start Recording!!
          </div>
        ))}
      <Drawer
        title={`Behavior-${behName}`}
        placement="right"
        width={DRAWER.widthL2}
        closable="true"
        onClose={hideDrawerHandler}
        visible={drawer}
      >
        <EditableTable
          behType="FR"
          showDrawer={drawer}
          date={pointValue}
          selectedBaheviorName={behName}
          selectedBehavior={selectedBehavior}
          selectedStudentId={selectedStudentId}
        />
        {/* <PhaseTable selectedBehavior={selectedBehavior} date={pointValue} /> */}
      </Drawer>
    </div>
  )
}

export default BehaviorFRChart
