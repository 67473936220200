/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { all, put, takeEvery, call, select } from 'redux-saga/effects'
import { createCoupon, getCoupons, updateCoupon } from 'services/coupon'
import { closeTicket, getTickets, updateTicket } from 'services/tickets'
import actions from './actions'

export function* GET_TICKETS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTicketsLoading: true,
    },
  })
  const response = yield call(getTickets, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        tickets: response.tickets.edges,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTicketsLoading: false,
    },
  })
}

export function* PAGE_CHANGED({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTicketsLoading: true,
    },
  })
  let { first, pageNo } = payload
  const { pageInfo, type } = yield select(state => state.ticketsSuperAdmin)

  let after = null
  let before = null
  let last = null

  if (pageNo === 1) {
    after = null
    before = null
  }
  if (type === 'next') {
    after = pageInfo?.endCursor
  }
  if (type === 'prev') {
    before = pageInfo?.startCursor
    last = first
    first = null
  }

  const response = yield call(getTickets, { ...payload, after, before, first, last })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        tickets: response.tickets.edges,
        pageInfo: response.tickets.pageInfo,
        ticketCount: response.tickets.totalCount,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTicketsLoading: false,
    },
  })
}

export function* UPDATE_TICKET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTicketSaving: true,
    },
  })
  const response = yield call(updateTicket, payload)
  if (response && response.updateTicket) {
    notification.success({
      message: 'Ticket updated successfully!',
    })
    yield put({
      type: actions.REPLACE_TICKET,
      payload: {
        data: response.updateTicket.ticket,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTicketSaving: false,
    },
  })
}

export function* CLOSE_TICKET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTicketSaving: true,
    },
  })
  const response = yield call(closeTicket, payload)
  if (response && response.updateTicket) {
    notification.success({
      message: 'Ticket closed successfully!',
    })
    yield put({
      type: actions.REPLACE_TICKET,
      payload: {
        data: response.updateTicket.ticket,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isTicketSaving: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TICKETS, GET_TICKETS),
    takeEvery(actions.PAGE_CHANGED, PAGE_CHANGED),
    takeEvery(actions.UPDATE_TICKET, UPDATE_TICKET),
    takeEvery(actions.CLOSE_TICKET, CLOSE_TICKET),
  ])
}
