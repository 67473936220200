/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import { Form, Button, Select, Col, Row, InputNumber, Typography } from 'antd'
import gql from 'graphql-tag'
import { SUBMITT_BUTTON } from 'assets/styles/globalStyles'
import { useQuery } from 'react-apollo'
import LoadingComponent from 'components/LoadingComponent'
import { GET_SBT_STEP_STATUS } from './query'

const { Text } = Typography

const { Option } = Select

const TARGET_STATUS = gql`
  query {
    targetStatus {
      id
      statusName
    }
  }
`
const TARGET_STATUS2 = gql`
  query {
    getSbtStepStatus {
      id
      statusName
    }
  }
`

const IncrimentCard = ({ style, title, name, form, init, childData }) => (
  <div
    style={{
      background: '#FFFFFF',
      border: '1px solid #E4E9F0',
      boxShadow: '0px 0px 4px rgba(53, 53, 53, 0.1)',
      borderRadius: 4,
      padding: '18px 19px',
      display: 'flex',
      alignItems: 'center',
      ...style,
    }}
  >
    <Text
      style={{
        fontSize: 16,
        fontWeight: 600,
        color: '#63686E',
        margin: 0,
        lineHeight: '22px',
      }}
    >
      {title}
    </Text>

    <Form.Item style={{ marginLeft: 'auto', marginBottom: 0 }}>
      {form.getFieldDecorator(name, {
        initialValue: init || 0,
        rules: [{ required: true, message: 'Please enter From Status' }],
      })(<InputNumber min={0} style={{ width: 160 }} />)}
    </Form.Item>
  </div>
)

const SbtMasteryForm = ({ form, obj, isStep, listMastery, setListMastery, setOpen }) => {
  const { add, editObj, type, key } = obj

  const { data: data2, err: err2, loading: loading2 } = useQuery(TARGET_STATUS2)

  const SubmitForm = e => {
    e.preventDefault()
    if (type === 'step') {
      form.validateFields((error, values) => {
        if (!error) {
          if (listMastery) {
            const tt = listMastery
            tt.push({
              ...values,
              gte: values.resKey === 0 ? 0 : values.noOfProblemBehavior,
              lte: values.resKey === 1 ? 100 : values.noOfProblemBehavior,
              key: Math.random(),
            })
            setListMastery(tt)
          } else {
            setListMastery(state => {
              const oldArr = state
              const toChangeArr = oldArr && oldArr[key]
              let changedArr = []
              if (toChangeArr) {
                changedArr.push({
                  ...values,
                  gte: values.resKey === 0 ? 0 : values.noOfProblemBehavior,
                  lte: values.resKey === 1 ? 100 : values.noOfProblemBehavior,
                  id: Math.random(),
                })
              } else {
                changedArr = [
                  {
                    ...values,
                    gte: values.resKey === 0 ? 0 : values.noOfProblemBehavior,
                    lte: values.resKey === 1 ? 100 : values.noOfProblemBehavior,
                    id: Math.random(),
                  },
                ]
              }
              return [...state, changedArr]
            })
          }
          form.resetFields()
          setOpen()
        }
      })
    } else {
      form.validateFields((error, values) => {
        if (!error) {
          setListMastery(state => {
            return [
              ...state,
              {
                ...values,
                gte: values.resKey === 0 ? 0 : values.noOfProblemBehavior,
                lte: values.resKey === 1 ? 100 : values.noOfProblemBehavior,
                id: Math.random(),
              },
            ]
          })
          form.resetFields()
          setOpen()
        }
      })
    }
  }

  const updateForm = e => {
    e.preventDefault()
    if (type === 'step') {
      form.validateFields((error, values) => {
        if (!error) {
          const tt = listMastery.map((item, idx) => {
            if (item.key === editObj.key) {
              return {
                ...values,
                gte: values.resKey === 0 ? 0 : values.noOfProblemBehavior,
                lte: values.resKey === 1 ? 100 : values.noOfProblemBehavior,
                id: item.id,
              }
            }
            return item
          })
          setListMastery(tt)
          form.resetFields()
          setOpen()
        }
      })
    } else {
      form.validateFields((error, values) => {
        if (!error) {
          const tt = listMastery.map(item => {
            if (item.id === editObj.id) {
              return {
                ...values,
                gte: values.resKey === 0 ? 0 : values.noOfProblemBehavior,
                lte: values.resKey === 1 ? 100 : values.noOfProblemBehavior,
                id: item.id,
              }
            }
            return item
          })
          setListMastery(tt)
          form.resetFields()
          setOpen()
        }
      })
    }
  }

  if (err2) {
    return <div>Oops something went wrong</div>
  }

  if (loading2) {
    return <LoadingComponent />
  }

  console.log(editObj, 'edit obj')
  console.log(isStep, data2, 'ff')

  return (
    <Form layout="vertical" style={{ padding: '12px 48px', width: '100%' }}>
      {add ? (
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item label="From Status">
              {form.getFieldDecorator('fromStatus', {
                rules: [{ required: true, message: 'Please enter From Status' }],
              })(
                <Select placeholder="From Status" allowClear size="large" loading={loading2}>
                  {data2.getSbtStepStatus &&
                    data2.getSbtStepStatus.map(question => (
                      <Option key={Math.random()} value={question.id}>
                        {question.statusName}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="To Status">
              {form.getFieldDecorator('toStatus', {
                rules: [{ required: true, message: 'Please enter To Status' }],
              })(
                <Select placeholder="To Status" allowClear size="large" loading={loading2}>
                  {data2.getSbtStepStatus &&
                    data2.getSbtStepStatus.map(question => (
                      <Option key={Math.random()} value={question.id}>
                        {question.statusName}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item label="From Status">
              {form.getFieldDecorator('fromStatus', {
                initialValue: editObj?.fromStatus,
                rules: [{ required: true, message: 'Please enter From Status' }],
              })(
                <Select placeholder="From Status" allowClear size="large" loading={loading2}>
                  {data2.getSbtStepStatus &&
                    data2.getSbtStepStatus.map(question => (
                      <Option key={Math.random()} value={question.id}>
                        {question.statusName}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="To Status">
              {form.getFieldDecorator('toStatus', {
                initialValue: editObj?.toStatus,
                rules: [{ required: true, message: 'Please enter To Status' }],
              })(
                <Select placeholder="To Status" allowClear size="large" loading={loading2}>
                  {data2.getSbtStepStatus &&
                    data2.getSbtStepStatus.map(question => (
                      <Option key={Math.random()} value={question.id}>
                        {question.statusName}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      <div
        style={{
          background: '#FFFFFF',
          border: '1px solid #E4E9F0',
          boxShadow: '0px 0px 4px rgba(53, 53, 53, 0.1)',
          borderRadius: 4,
          padding: '18px 19px',
          display: 'flex',
          marginTop: 0,
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: 600,
            color: '#63686E',
            margin: 0,
            lineHeight: '22px',
          }}
        >
          No Of Problem Behavior
        </Text>

        <Form.Item style={{ marginLeft: 'auto', marginBottom: 0, marginRight: 5 }}>
          {form.getFieldDecorator('resKey', {
            initialValue: add ? 1 : editObj.resKey,
            rules: [{ required: true, message: 'Required field' }],
          })(
            <Select style={{ width: 160 }}>
              <Option key={Math.random()} value={0}>
                {'<='}
              </Option>
              <Option key={Math.random()} value={1}>
                {'>='}
              </Option>
            </Select>,
          )}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          {form.getFieldDecorator('noOfProblemBehavior', {
            initialValue: add ? 0 : editObj.noOfProblemBehavior,
            rules: [{ required: true, message: 'Required field' }],
          })(<InputNumber min={0} style={{ width: 160 }} />)}
        </Form.Item>
      </div>

      <div
        style={{
          background: '#FFFFFF',
          border: '1px solid #E4E9F0',
          boxShadow: '0px 0px 4px rgba(53, 53, 53, 0.1)',
          borderRadius: 4,
          padding: '18px 19px',
          display: 'flex',
          marginTop: 20,
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: 600,
            color: '#63686E',
            margin: 0,
            lineHeight: '22px',
          }}
        >
          Days
        </Text>

        <Form.Item style={{ marginLeft: 'auto', marginBottom: 0, marginRight: 5 }}>
          {form.getFieldDecorator('masteryType', {
            initialValue: add ? 'Consecutive' : editObj.masteryType,
            rules: [{ required: true, message: 'Required field' }],
          })(
            <Select
              style={{ width: 160 }}
              onChange={e => (e === 'Daily' ? form.setFieldsValue({ days: 1 }) : e)}
            >
              <Option key={Math.random()} value="Consecutive">
                Consecutive
              </Option>
              <Option key={Math.random()} value="Daily">
                Daily
              </Option>
              <Option key={Math.random()} value="Cumulative">
                Cumulative
              </Option>
            </Select>,
          )}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          {form.getFieldDecorator('days', {
            initialValue:
              form.getFieldValue('masteryType') === 'Daily' ? 1 : add ? 5 : editObj.days,
            rules: [{ required: true, message: 'Required field' }],
          })(
            <InputNumber
              disabled={form.getFieldValue('masteryType') === 'Daily'}
              min={0}
              style={{ width: 160 }}
            />,
          )}
        </Form.Item>
      </div>
      <IncrimentCard
        form={form}
        init={add ? 0 : editObj.minTrial}
        title="Minimum Trials"
        name="minTrial"
        style={{ marginTop: 20 }}
      />
      <Form.Item style={{ display: 'flex', justifyContent: 'center', margin: 0, marginTop: 20 }}>
        <Button
          type="primary"
          onClick={add ? SubmitForm : updateForm}
          style={{ ...SUBMITT_BUTTON, margin: 0 }}
        >
          {add ? 'Add Criteria' : 'Update Criteria'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create()(SbtMasteryForm)
