/* eslint-disable camelcase */
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Table } from 'antd'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'
import { useQuery } from 'react-apollo'
import { DOMAIN_MASTERED } from './query'
import './table.scss'

const columns = [
  {
    title: 'Domain',
    dataIndex: 'targetId',
    render: (text, row) => (
      <span>{row.targetId ? row.targetId.domain.domain : row.manualAllocateDomain.domain}</span>
    ),
  },
  {
    title: 'Target Name',
    ellipsis: true,
    width: '320px',
    render: (text, row) => (
      <span>{row.targetAllcatedDetails ? row.targetAllcatedDetails.targetName : ''}</span>
    ),
  },
  {
    title: 'Stimulus',
    dataIndex: 'sd',
    render: (text, row) => (
      <span>
        {row.sd.edges
          ? row.sd.edges.map(tag => {
              return tag.node.sd
            })
          : ''}
      </span>
    ),
  },
  {
    title: 'Steps',
    dataIndex: 'steps',
    render: (text, row) => (
      <span>
        {row.steps.edges
          ? row.steps.edges.map(tag => {
              return tag.node.step
            })
          : ''}
      </span>
    ),
  },
  {
    title: 'Baseline Date',
    render: (text, row) => (
      <span>
        {row.targetAllcatedDetails
          ? moment(row.targetAllcatedDetails.dateBaseline).format('YYYY-MM-DD')
          : ''}
      </span>
    ),
    width: '130px',
  },
  {
    title: 'In-Therapy Date',
    dataIndex: 'intherapyDate',
    render: (text, row) => <span>{row.intherapyDate ? row.intherapyDate : ''}</span>,
    width: '130px',
  },
  {
    title: 'Mastery date',
    dataIndex: 'masterDate',
    render: (text, row) => <span>{row.masterDate ? row.masterDate : ''}</span>,
    width: '110px',
  },
]

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

const Report = forwardRef((props, ref) => {
  const { start_date, end_date, selectedprogram, domainSelected, statusselected, domainId } = props
  const [data, setData] = useState([])
  const studentId = localStorage.getItem('studentId')

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })
  const { data: domainData, loading, error } = useQuery(DOMAIN_MASTERED, {
    variables: {
      studentId,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      selectedprogram,
      statusselected,
      domainId,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (domainData) {
      let tableData = []
      // if (domainSelected && domainData.domainMastered.target) {
      //   domainData.domainMastered.target.map(item => {
      //     if (item.targetId && item.targetId.domain.domain === domainSelected) {
      //       tableData.push(item)
      //     }
      //     return tableData
      //   })
      // } else {
      tableData = domainData.domainMastered.target
      // }
      setData(tableData)
      setPagination(prev => ({
        ...prev,
        total: domainData.domainMastered.totalCount,
      }))
    }
  }, [domainData])

  useImperativeHandle(ref, () => ({
    exportToCSV,
  }))

  const exportToCSV = studentName => {
    const filename = '_progress_overview_excel'
    // eslint-disable-next-line no-unused-expressions

    const formattedData = data.map(function(e) {
      const stim = e.sd.edges
        ? e.sd.edges.map(tag => {
            return tag.node.sd
          })
        : ''
      const step = e.steps.edges
        ? e.steps.edges.map(tag => {
            return tag.node.step
          })
        : ''
      return {
        Domain: e.targetId ? e.targetId.domain.domain : 'Other',
        Target_Name: e.targetAllcatedDetails.targetName,
        Stimulus: stim.toString(),
        Steps: step.toString(),
        Baseline_Date: e.targetAllcatedDetails
          ? moment(e.targetAllcatedDetails.dateBaseline).format('YYYY-MM-DD')
          : '',
        In_Therapy_Date: e.intherapyDate ? e.intherapyDate : '',
        Mastery_date: e.masterDate ? e.masterDate : '',
      }
    })

    const ws = XLSX.utils.json_to_sheet(formattedData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelData, studentName + filename + fileExtension)
  }

  return (
    <>
      <Table
        className="allReportTable"
        columns={columns}
        dataSource={data}
        rowKey={record => record.id}
        bordered
        loading={loading}
        scroll={{ x: 1200 }}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50', '100', '200', '500'],
          position: 'top',
        }}
      />
    </>
  )
})

export default Report
