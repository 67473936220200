import ACTIONS from './actions'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ADD_PAYOR: {
      console.log('add pauyor', state)
      return { ...state, showBackButton: true, addFormOpen: true, title: 'Add Payor' }
    }
    case ACTIONS.CLOSE_ADD_FORM: {
      return { ...state, showBackButton: false, addFormOpen: false, title: 'Payors' }
    }
    case ACTIONS.EDIT_PAYOR: {
      return { ...state, showBackButton: true, editFormOpen: true, title: 'Edit Payor' }
    }
    case ACTIONS.CLOSE_EDIT_FORM: {
      return { ...state, showBackButton: false, editFormOpen: false, title: 'Payors' }
    }
    case ACTIONS.BACK_CLICK: {
      return {
        ...state,
        showBackButton: false,
        editFormOpen: false,
        addFormOpen: false,
        title: 'Payors',
      }
    }
    default:
      return state
  }
}

export default reducer
