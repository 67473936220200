import React, { useState, useEffect } from 'react'
import { Select, Form } from 'antd'
import { useQuery } from 'react-apollo'
import moment from 'moment'

const { Option } = Select

const AssessmentDropDown = ({
  form,
  onSelectionChange,
  assessmentData,
  isAssessmentLoading,
  selectedAssessment,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      stdId: selectedAssessment,
    })
  }, [selectedAssessment])

  const [selectedAssessmentId, setSelectedAssessment] = useState()

  const handleSelectionChange = selectedItem => {
    // setSelectedAssessment(selectedItem)
    onSelectionChange(selectedItem)
  }
  return (
    <Form.Item label="Select an assessment" style={{ display: 'flex', marginBottom: 0 }}>
      {form.getFieldDecorator('stdId', {
        initialValue: selectedAssessment,
      })(
        <Select
          showSearch
          style={{ width: 235 }}
          value={selectedAssessment}
          // optionFilterProp="testNo"
          placeholder="Select an assessment"
          loading={isAssessmentLoading}
          onChange={handleSelectionChange}
          // filterOption={(input, option) =>{
          //   console.log("input>>",input,option);
          //   return  String(option.props.children[0]) === input || option.props.children[2] === input
          // }

          // }
        >
          {assessmentData?.vbmappGetAssessments?.edges.map(({ node }) => {
            // console.log("nodeid>>", node.id === selectedAssessment, node.id, selectedAssessment);
            return (
              <Option key={node.id} value={node.id}>
                {node.testNo} - {moment(node.date).format('YYYY-MM-DD')}
              </Option>
            )
          })}
        </Select>,
      )}
    </Form.Item>
  )
}

export default Form.create()(AssessmentDropDown)
