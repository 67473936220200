import gql from 'graphql-tag'

export const LOAD_CURRICULUM_DATA = gql`
  query {
    programArea(isActive: false) {
      edges {
        node {
          id
          name
        }
      }
    }
    domain(isActive: false) {
      edges {
        node {
          id
          domain
        }
      }
    }
    targetArea(isActive: false) {
      edges {
        node {
          id
          Area
        }
      }
    }
    target(isActive: false) {
      edges {
        node {
          id
          targetMain {
            id
            targetName
          }
        }
      }
    }
  }
`
export const ACTIVATE_PROGRAM_AREA = gql`
  mutation ActivateProgramArea($objectId: ID!) {
    disableProgramArea(input: { programArea: $objectId, isActive: true }) {
      status
      msg
    }
  }
`

export const ACTIVATE_DOMAIN = gql`
  mutation ActivateDomain($objectId: ID!) {
    disableDomain(input: { pk: $objectId, isActive: true }) {
      status
      msg
    }
  }
`
export const ACTIVATE_TARGET_AREA = gql`
  mutation ActivateTargetArea($objectId: ID!) {
    disableTargetArea(input: { pk: $objectId, isActive: true }) {
      status
      msg
    }
  }
`
export const ACTIVATE_TARGET = gql`
  mutation ActivateTargetArea($objectId: ID!) {
    disableTarget(input: { pk: $objectId, isActive: true }) {
      status
      msg
    }
  }
`

export const CONTACT_DETAILS = gql`
  query {
    schoolDetail {
      id
      schoolName
      address
      email
      contactNo
      country {
        name
      }
    }
  }
`

export const UPDATE_CLINIC_DASH = gql`
  mutation updateClinic($email: String!, $contactNo: String!, $address: String!) {
    updateClinic(input: { email: $email, contactNo: $contactNo, address: $address }) {
      school {
        id
      }
    }
  }
`

export const CLINIC_QUERY = gql`
  query {
    schooldetail: schoolDetail {
      id
      schoolName
      address
    }
  }
`

export const SCHOOL_INFO = gql`
  query($id: ID!) {
    users(id: $id) {
      id
      school {
        id
      }
    }
  }
`

export const CREATE_MODULE = gql`
  mutation($clinic: ID!, $name: String!, $description: String!) {
    addClinicVideoLibrary(input: { clinic: $clinic, name: $name, description: $description }) {
      details {
        id
        name
        description
        clinic {
          id
          schoolName
        }
        videos {
          edges {
            node {
              id
              url
              name
              description
            }
          }
        }
      }
    }
  }
`

export const UPDATE_MODULE = gql`
  mutation($id: ID!, $name: String!, $description: String) {
    updateClinicVideoLibrary(input: { pk: $id, name: $name, description: $description }) {
      details {
        id
        name
        description
        clinic {
          id
          schoolName
        }
        videos {
          edges {
            node {
              id
              url
            }
          }
        }
      }
    }
  }
`

export const DELETE_MODULE = gql`
  mutation($id: ID!) {
    deleteClinicVideoLibrary(input: { pk: $id }) {
      status
      msg
    }
  }
`

export const CLINIC_MODULES = gql`
  query($clinicId: ID!) {
    getClinicLibrary(clinic: $clinicId) {
      edges {
        node {
          id
          name
          description
          clinic {
            id
            schoolName
          }
          videos {
            edges {
              node {
                id
                url
                name
                description
              }
            }
          }
        }
      }
    }
  }
`

export const ADD_VIDEO_LIBERARY = gql`
  mutation($liberaryId: ID!, $name: String!, $description: String, $url: String!) {
    addVideoToLibrary(
      input: { pk: $liberaryId, name: $name, description: $description, url: $url }
    ) {
      details {
        videos {
          edges {
            node {
              id
              url
              name
              description
            }
          }
        }
      }
    }
  }
`

export const EDIT_VIDEO = gql`
  mutation($videoId: ID!, $name: String!, $description: String, $url: String!) {
    editLibraryvideo(input: { pk: $videoId, name: $name, description: $description, url: $url }) {
      details {
        id
        name
        description
        url
      }
    }
  }
`

export const REMOVE_VIDEO = gql`
  mutation($liberaryID: ID!, $videoId: ID!) {
    removeVideoToLibrary(input: { pk: $liberaryID, videoId: $videoId }) {
      details {
        id
      }
    }
  }
`
export const UPDATE_CLINIC = gql`
  mutation(
    $bankName: String
    $bankAccountNo: String
    $ifscCode: String
    $accountHolderName: String
    $billingName: String
    $currency: ID
  ) {
    updateClinic(
      input: {
        bankName: $bankName
        bankAccountNo: $bankAccountNo
        ifscCode: $ifscCode
        accountHolderName: $accountHolderName
        billingName: $billingName
        currency: $currency
      }
    ) {
      school {
        id
        schoolName
        bankName
        bankAccountNo
        ifscCode
        accountHolderName
        billingName
        currency {
          id
          currency
          symbol
        }
      }
    }
  }
`

export const SCHOOL_CURRENCY = gql`
  query($id: ID!) {
    school(id: $id) {
      id
      schoolName
      bankAccountNo
      bankName
      ifscCode
      accountHolderName
      billingName
      currency {
        id
        currency
        symbol
      }
    }
  }
`

export const CURRENCY = gql`
  query {
    currency {
      id
      currency
      symbol
    }
  }
`

export const ADD_CURRENCY = gql`
  mutation addCurrency($id: ID!) {
    addCurrency(input: { currencyId: $id }) {
      currency {
        id
        currency
        symbol
      }
    }
  }
`
