/* eslint-disable no-nested-ternary */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import { FormattedMessage } from 'react-intl'
import { HiOutlineLogout } from 'react-icons/hi'
import styles from './style.module.scss'

const ProfileMenu = props => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />
          ,&nbsp;
          {user.role === 'parents'
            ? user.parentName
            : user.role === 'therapist'
            ? user.staffName
            : user.role === 'school_admin'
            ? user.clinicName
            : 'Anonymous'}
        </strong>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.role === 'parents' ? 'Parent' : ''}
          {user.role === 'therapist' ? 'Therapist' : ''}
          {user.role === 'school_admin' ? 'Clinic' : ''}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="/#/clinicSupportTicket">
          <strong>Tickets</strong>
        </a>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item onClick={logout}>
        <a>
          <i className={`${styles.menuIcon} icmn-exit`} />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu}>
      <div className={styles.dropdown}>
        <HiOutlineLogout style={{ fontSize: 20 }} />
        <p style={{ fontSize: 10, lineHeight: '13px', marginTop: -14, marginBottom: 3 }}>Logout</p>
      </div>
    </Dropdown>
  )
}

export default ProfileMenu
