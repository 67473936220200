const actions = {
  SET_STATE: 'ticketsSuperAdmin/SET_STATE',
  GET_TICKETS: 'ticketsSuperAdmin/GET_TICKETS',
  UPDATE_TICKET: 'ticketsSuperAdmin/UPDATE_TICKET',
  REPLACE_TICKET: 'ticketsSuperAdmin/REPLACE_TICKET',
  PAGE_CHANGED: 'ticketsSuperAdmin/PAGE_CHANGED',
  CLOSE_TICKET: 'ticketsSuperAdmin/CLOSE_TICKET',
  REPLACE_CLOSE_TICKET: 'ticketsSuperAdmin/REPLACE_CLOSE_TICKET',
}

export default actions
