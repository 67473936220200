/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const ALL_THERAPIST = gql`
  query($isActive: Boolean) {
    staffs(userRole: "Therapist", isActive: $isActive) {
      edges {
        node {
          id
          name
          surname
        }
      }
    }
  }
`

export const UPDATE_SHIFT = gql`
  mutation updateShift(
    $therapistIds: [String]!
    $startTime: String!
    $endTime: String!
    $workingDays: [String]!
  ) {
    createShift(
      input: { startTime: $startTime, endTime: $endTime, staffs: $therapistIds, days: $workingDays }
    ) {
      details {
        id
        name
        # shift {
        #   startTime
        #   endTime
        #   days {
        #     edges {
        #       node {
        #         id
        #         name
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`
export const CREATE_UPDATE_STAFF_ATTENDANCE_SETTING = gql`
  mutation createUpdateStaffAttendenceSettings(
    $longitude: String
    $latitude: String
    $locationSpecificAttendence: Boolean!
    $distance: Int
  ) {
    createUpdateStaffAttendenceSettings(
      input: {
        longitude: $longitude
        latitude: $latitude
        locationSpecificAttendence: $locationSpecificAttendence
        distance: $distance
      }
    ) {
      message
      status
      attendenceSettings {
        id
        longitude
        latitude
        locationSpecificAttendence
        distance
      }
    }
  }
`
export const GET_STAFF_ATTENDANCE_SETTINGS = gql`
  query {
    getStaffAttendenceSettings {
      id
      longitude
      latitude
      locationSpecificAttendence
      distance
    }
  }
`
