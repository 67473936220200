// eslint-disable-next-line import/prefer-default-export
export const TargetTypes = {
  PEAK: 'VGFyZ2V0RGV0YWlsVHlwZTo4',
  SBT: 'VGFyZ2V0RGV0YWlsVHlwZToxMA==',
  BEHAVIOR_RECORDING: 'VGFyZ2V0RGV0YWlsVHlwZToxMQ==',
  PRECIOUS_TEACHING: 'VGFyZ2V0RGV0YWlsVHlwZTo3',
  TASK_ANALYSIS: 'VGFyZ2V0RGV0YWlsVHlwZTo1',
}

export const PeakTypes = {
  DIRECT: 'DIRECT',
  EQUIVALENCE: 'EQUIVALENCE',
}

export const TargetSatus = {
  MASTERED: 'U3RhdHVzVHlwZTo0',
}
