import ChangePassword from 'components/changePassword/form'
import ContactDetails from 'pages/ClinicProfile/ContactDetails'
import EmailNotiSett from 'pages/ClinicProfile/EmailNotiSett'
import LocationSett from 'pages/ClinicProfile/LocationSett'
import React, { useState } from 'react'
import { Collapse, Row, Col, Menu, Layout } from 'antd'
import Scrollbar from 'react-custom-scrollbars'
import HeaderComponent from 'components/HeaderComponentNew'
import SMHPCenter from '../SMHPCenter'
import PromptSetting from '../PromptSetting'
import StaffPositions from '../StaffPositions'
import UploadLogo from './UploadLogo/UploadLogo'
import './styles.scss'
import QrCode from './QrCode'
import Enquiry from './enquiry'

const { Panel } = Collapse
const { Header, Content } = Layout

const LogoAndDetails = () => {
  return (
    <Row>
      <Col sm={24} lg={8}>
        <UploadLogo />
      </Col>
      <Col sm={24} lg={16}>
        <ContactDetails />
      </Col>
    </Row>
  )
}

const tabs = [
  {
    key: 'Update-Logo',
    title: 'Update Clinic Info',
    component: <LogoAndDetails />,
    // iconName: <ProfileOutlined />,
  },
  {
    key: 'Change-Password',
    title: 'Change Password',
    component: <ChangePassword />,
    // iconName: <FieldTimeOutlined />,
  },
  {
    key: 'Email-Notificaiton',
    title: 'Email Notificaiton',
    component: <EmailNotiSett />,
    // iconName: <ProfileOutlined />,
  },
  {
    key: 'Locations',
    title: 'Locations',
    component: <LocationSett />,
    // iconName: <FileOutlined />,
  },
  {
    key: 'Prompt',
    title: 'Prompt',
    component: <PromptSetting />,
    // iconName: <FieldTimeOutlined />,
  },
  {
    key: 'Positions',
    title: 'Positions',
    component: <StaffPositions />,
    // iconName: <FieldTimeOutlined />,
  },
  {
    key: 'SMHPCenter',
    title: 'SMHP Centers',
    component: <SMHPCenter />,
    // iconName: <FieldTimeOutlined />,
  },
  {
    key: 'Screening-QR',
    title: 'Screening QR',
    component: <QrCode />,
    // iconName: <FieldTimeOutlined />,
  },
  {
    key: 'Enquiry',
    title: 'Enquiry',
    component: <Enquiry />,
    // iconName: <FieldTimeOutlined />,
  },
]

const ProfileTab = () => {
  const [rightComponent, setRightComponent] = useState(<LogoAndDetails />)
  const [panelHeading, setPanelHeading] = useState('Update Clinic Info')

  const handleMenuChange = item => {
    const selectedItem = tabs.find(tab => tab.key === item.key)
    setPanelHeading(selectedItem.title)
    if (selectedItem) setRightComponent(selectedItem.component)
  }

  return (
    <div>
      <Layout
        style={{
          minHeight: 'calc(100vh - 240px)',
          marginTop: 1,
          backgroundColor: 'white',
          borderRadius: 15,
          padding: 20,
        }}
      >
        <Row>
          <Col span={5}>
            <Scrollbar style={{ height: 'calc(100vh - 120px)' }}>
              <Menu
                style={{ height: 'calc(100vh - 175px)' }}
                onSelect={handleMenuChange}
                mode="inline"
                defaultSelectedKeys={['Update-Logo']}
              >
                {tabs.map(tab => {
                  if (!tab.submenu) {
                    return (
                      <Menu.Item key={tab.key}>
                        {/* {tab.iconName} */}
                        <span>{tab.title}</span>
                      </Menu.Item>
                    )
                  }
                  return (
                    <Menu.SubMenu
                      title={
                        <span>
                          {tab.iconName}
                          <span>{tab.title}</span>
                        </span>
                      }
                      key={tab.key}
                      onTitleClick={() => handleMenuChange({ key: tab.key })}
                    >
                      {tab.submenu.map(label => (
                        <Menu.Item key={label[0]}>
                          {label[1]} <span>{label[0]}</span>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  )
                })}
              </Menu>
            </Scrollbar>
          </Col>
          <Col span={19}>
            <Content style={{ margin: '0 16px' }}>
              <HeaderComponent
                title={panelHeading}
                showHomePath={false}
                pathList={['Profile Details']}
              />
              <div style={{ padding: '0px 24px', minHeight: 360, margin: '0 10px' }}>
                <Scrollbar style={{ height: 'calc(100vh - 150px)' }}>
                  <div>{rightComponent}</div>
                </Scrollbar>
              </div>
            </Content>
          </Col>
        </Row>
      </Layout>
    </div>
  )
}

export default ProfileTab
