/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import {
  Button,
  notification,
  Table,
  Menu,
  Icon,
  Dropdown,
  Drawer,
  Form,
  Input,
  Select,
  Modal,
} from 'antd'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import { PlusOutlined, MailOutlined, EditOutlined } from '@ant-design/icons'
import { DRAWER, SUBMITT_BUTTON, CANCEL_BUTTON } from 'assets/styles/globalStyles'
import AdvanceInvoiceForm from './advanceInvoiceForm'
import UpdateClinicRates from '../allClinicData/updateClinicRates'
import { CLINIC_QUERY, PAYMENT_REMINDER, UPDATE_RESPONSIBLE } from './query'
import InvoiceTable from '../allClinicData/invoiceTable'

function CustomerList() {
  const [getData, { data, loading, error }] = useLazyQuery(CLINIC_QUERY, {
    fetchPolicy: 'network-only',
  })
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [advanceInvoiceModal, setAdvanceInvoiceModal] = useState(false)
  const [payReminderModal, setPayReminderModal] = useState(false)
  const [invoiceDrawer, setInvoiceDrawer] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [drawerTitle, setDrawerTitle] = useState(null)
  const [selectedClinicsName, setSelectedClinicsName] = useState(null)
  const [currentClinicRow, setCurrentClinicRow] = useState()
  const [invoiceType, setInvoiceType] = useState('advance')
  const [ratesDrawer, setRatesDrawer] = useState(false)
  const [filterCustomer, setFilterCustomer] = useState('')
  const [sendPaymentReminder, { loading: sendPaymentReminderLoading }] = useMutation(
    PAYMENT_REMINDER,
  )
  const [updateResponsible] = useMutation(UPDATE_RESPONSIBLE)
  const [filterResponsible, setFilterResponsible] = useState('')
  const [modalSelect, setModalSelect] = useState('')
  const [editResponsibleModal, setEditResponsibleModal] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    getData({
      variables: {
        first: pageSize,
        offset: pageNo * pageSize - pageSize,
        schoolName: filterCustomer,
      },
    })
  }, [pageSize, pageNo, filterCustomer])

  useEffect(() => {
    if (data) {
      const tempTable = []
      data.clinicAllDetails.map(item => {
        tempTable.push({
          details: item.details,
          key: item.details.id,
          invoice: item.invoice,
        })
      })
      setTableData(tempTable)
    }
    if (error) {
      notification.error({
        message: 'Something went wrong',
        description: error.message,
      })
    }
  }, [data, error])

  let filteredList = tableData || []

  filteredList = filteredList.filter(item =>
    String(item.details?.responsible)
      .toLowerCase()
      .includes(filterResponsible.toLowerCase()),
  )
  const columns = [
    {
      title: 'Name',
      dataIndex: 'details.schoolName',
      width: 200,
    },
    {
      title: 'Responsible',
      dataIndex: 'details.responsible',
      width: 200,
      render: (e, row) => {
        const arr = e?.split('_')
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{arr?.length ? `${arr[0]} ${arr[1]}` : ''}</span>
            <Button
              style={{
                padding: '0',
                border: '0',
                alignSelf: 'flex-end',
                backgroundColor: 'inherit',
              }}
              onClick={() => {
                setEditResponsibleModal(true)
                setCurrentClinicRow(row)
              }}
            >
              <EditOutlined />
            </Button>
          </div>
        )
      },
    },
    {
      title: 'Billing Name',
    },
    {
      title: 'Debit',
    },
    {
      title: 'Credit',
    },
    {
      title: 'Invoices',
      dataIndex: 'invoice',
      render: (e, row) => (
        <Button
          onClick={() => {
            setCurrentRow(row)
            setDrawerTitle(row.details.schoolName)
            setInvoiceDrawer(true)
          }}
          type="link"
          style={{ padding: '0px', fontWeight: 'bold', fontSize: '13px' }}
        >
          {e}
        </Button>
      ),
    },
    {
      title: 'Action',
      render: (text, row) => (
        <Button
          onClick={() => {
            setCurrentClinicRow(row)
            setDrawerTitle(row.details.schoolName)
            setRatesDrawer(true)
          }}
          type="link"
          style={{ padding: '0px', fontWeight: 'bold', fontSize: '13px' }}
        >
          Maintain Rates
        </Button>
      ),
    },
  ]

  const onSelectChange = selectedKeys => {
    setSelectedRowKeys(selectedKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const handlePaymentReminder = () => {
    const clinicIds = selectedClinicsName.map(item => item.key)
    sendPaymentReminder({
      variables: {
        clinics: clinicIds,
      },
    })
      .then(res => {
        notification.success({
          message: 'Payment reminders send successfully',
        })
      })
      .catch(err => console.error(err))
  }

  const handleMenuActions = e => {
    const names = []
    filteredList.map(item =>
      selectedRowKeys.map(key =>
        key === item.key ? names.push({ name: item.details?.schoolName, key: item.key }) : null,
      ),
    )
    setSelectedClinicsName(names)
    if (!names?.length) {
      notification.warning({
        message: 'Clinic Not Selected!',
        description: 'Please select at least one clinic',
      })
    } else if (e.key === 'advanceInvoice') {
      setInvoiceType('advance')
      setAdvanceInvoiceModal(true)
    } else if (e.key === 'monthlyInvoice') {
      setInvoiceType('monthly')
      setAdvanceInvoiceModal(true)
    } else if (e.key === 'payReminder') {
      setPayReminderModal(true)
    }
  }

  const menu = (
    <Menu onClick={e => handleMenuActions(e)}>
      <Menu.Item key="advanceInvoice">
        <PlusOutlined /> Advance invoice
      </Menu.Item>
      <Menu.Item key="monthlyInvoice">
        <PlusOutlined /> Monthly invoice
      </Menu.Item>
      <Menu.Item key="payReminder">
        <MailOutlined /> Send Reminder
      </Menu.Item>
    </Menu>
  )

  const filterHeader = (
    <div
      style={{
        minHeight: '20px',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Dropdown overlay={menu}>
        <Button>
          Actions <Icon type="down" />
        </Button>
      </Dropdown>
      <Select
        value={filterResponsible}
        onSelect={value => setFilterResponsible(value)}
        style={{ width: 170, margin: '0px 10px 0 6px' }}
      >
        <Select.Option value="">All</Select.Option>
        <Select.Option value="COGNIABLE_INDIA">Cogniable India</Select.Option>
        <Select.Option value="COGNIABLE_US">Cogniable US</Select.Option>
      </Select>
      <Input
        placeholder="Search Customer"
        value={filterCustomer}
        onChange={e => setFilterCustomer(e.target.value)}
        style={{ width: 200, margin: '0px 10px 0 15px' }}
      />
    </div>
  )

  const handleSave = () => {
    updateResponsible({
      variables: {
        pk: currentClinicRow.details.id,
        responsible: modalSelect.toLowerCase(),
      },
    })
      .then(res => {
        notification.success({
          message: 'Field updated successfully.',
        })
        setEditResponsibleModal(false)
      })
      .catch(err => console.log(err))
  }

  return (
    <div style={{ marginTop: 10 }}>
      <Table
        columns={columns}
        dataSource={filteredList}
        rowSelection={rowSelection}
        loading={loading}
        bordered
        className="invoiceTable"
        title={() => {
          return filterHeader
        }}
        pagination={{
          simple: true,
          defaultPageSize: 10,
          showSizeChanger: true,
          total: data?.allClinics?.totalCount || 0,
          onShowSizeChange: (curr, size) => {
            setPageSize(size)
            setPageNo(curr)
          },
          onChange: (page, size) => {
            setPageSize(size)
            setPageNo(page)
          },

          position: 'bottom',
        }}
      />

      <Drawer
        title={`Create ${invoiceType === 'advance' ? 'advance' : 'monthly'} invoice`}
        width={DRAWER.widthL2}
        visible={advanceInvoiceModal}
        closable
        destroyOnClose
        onClose={() => setAdvanceInvoiceModal(false)}
      >
        <div style={{ padding: 20 }}>
          <AdvanceInvoiceForm
            selectedClinicsName={selectedClinicsName}
            selectedRowKeys={selectedRowKeys}
            invoiceType={invoiceType}
            closeDrawer={() => setAdvanceInvoiceModal(false)}
          />
        </div>
      </Drawer>

      <Drawer
        title="Send pay reminder"
        visible={payReminderModal}
        closable
        destroyOnClose
        onClose={() => {
          setPayReminderModal(false)
        }}
        width={DRAWER.widthL2}
      >
        <div>
          {selectedClinicsName && selectedClinicsName?.length > 0 ? (
            <>
              <Form.Item>
                <div style={{ marginLeft: 30 }}>
                  <p style={{ fontSize: 20, fontWeight: 600 }}>Selected clinics:</p>
                  <ol
                    style={{ display: 'grid', gridTemplateColumns: 'auto auto', paddingLeft: 15 }}
                  >
                    {selectedClinicsName &&
                      selectedClinicsName.map((item, index) => <li key={item.id}>{item.name}</li>)}
                  </ol>
                </div>
                <div style={{ marginLeft: 25 }}>
                  <div>
                    <b>Send Pay reminder to the selected clinics for the last invoice </b>
                  </div>
                </div>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={sendPaymentReminderLoading}
                  type="default"
                  onClick={() => handlePaymentReminder()}
                  style={SUBMITT_BUTTON}
                >
                  Send Reminder
                </Button>
                <Button
                  onClick={() => setPayReminderModal(false)}
                  type="ghost"
                  style={CANCEL_BUTTON}
                >
                  Cancel
                </Button>
              </Form.Item>
            </>
          ) : (
            <b>None, Please select at least one clinic</b>
          )}
        </div>
      </Drawer>

      <Drawer
        title={`${drawerTitle}: Maintain Rates`}
        width={DRAWER.widthL3}
        placement="right"
        closable="true"
        onClose={() => setRatesDrawer(false)}
        visible={ratesDrawer}
        destroyOnClose="true"
      >
        <UpdateClinicRates
          rowData={currentClinicRow}
          ratesDrawer={ratesDrawer}
          closeDrawer={() => setRatesDrawer(false)}
        />
      </Drawer>
      <Drawer
        title={`${drawerTitle}: Invoices`}
        width={DRAWER.widthL1}
        visible={invoiceDrawer}
        onClose={() => setInvoiceDrawer(false)}
        placement="right"
        closable="true"
        destroyOnClose="true"
      >
        <InvoiceTable
          rowData={currentRow}
          invoiceFormDrawer={invoiceDrawer}
          setInvoiceFormDrawer={setInvoiceDrawer}
        />
      </Drawer>

      <Modal
        visible={editResponsibleModal}
        title="Edit"
        okText="Save"
        onOk={() => {
          handleSave()
          setModalSelect('')
        }}
        okButtonProps={{
          disabled: modalSelect === '',
        }}
        cancelText="Cancel"
        onCancel={() => {
          setModalSelect('')
          setEditResponsibleModal(false)
        }}
      >
        <span style={{ marginRight: '1em' }}>Select Responsible: </span>
        <Select
          value={modalSelect}
          onSelect={value => setModalSelect(value)}
          style={{ width: 170, margin: '0px 10px 0 6px' }}
        >
          <Select.Option value="">Select</Select.Option>
          <Select.Option value="COGNIABLE_INDIA">Cogniable India</Select.Option>
          <Select.Option value="COGNIABLE_US">Cogniable US</Select.Option>
        </Select>
      </Modal>
    </div>
  )
}

export default CustomerList
