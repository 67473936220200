const actions = {
  SET_STATE: 'marketingModal/SET_STATE',
  SUBMIT_FORM: 'marketingModal/SUBMIT_FORM',
  VERIFY_OTP: 'marketingModal/VERIFY_OTP',
  RESEND_OTP: 'marketingModal/RESEND_OTP',
  GET_SERVICES: 'marketingModal/GET_SERVICES',
  SAVE_SERVICES: 'marketingModal/SAVE_SERVICES',
  SEARCH: 'marketingModal/SEARCH',
  VERIFY_EMAIL: 'marketingModal/VERIFY_EMAIL',
  SAVE_FEEDBACK: 'marketingModal/SAVE_FEEDBACK',
  GET_COMMENTS: 'marketingModal/GET_COMMENTS',
  UPLOAD_DOCS: 'marketingModal/UPLOAD_DOCS',
  VERIFY_USER: 'marketingModal/VERIFY_USER',
  // Super admin actions
  GET_ALL_USERS: 'marketingModal/GET_ALL_USERS',
  APPROVE_USER: 'marketingModal/APPROVE_USER',
  VERIFY_USER_OTP: 'marketingModal/VERIFY_USER_OTP',
}

export default actions
