/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */

import React from 'react'
import { Divider, Form, Input } from 'antd'
import { PeakLabels, NormalTargetLabels } from 'constants/RecordingLabels'

const Label = ({ index, item, handleTargetLable, form }) => {
  const { label, value, newLabel = '' } = item
  const [inputValue, setInputValue] = React.useState(newLabel)

  const onBlur = () => {
    const newObj = {
      ...item,
      newLabel: inputValue,
    }

    handleTargetLable(newObj)
  }

  return (
    <div key={value}>
      <div
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 20px',
          border: '1px solid #ececec',
        }}
      >
        <p style={{ marginBottom: 0, whiteSpace: 'nowrap' }}>{label}</p>
        <Form.Item label={`Label ${index + 1}`}>
          {form.getFieldDecorator(`${value}`, {
            initialValue: newLabel,
            rules: [{ required: true, message: 'Please input new label!' }],
          })(
            <Input
              addonBefore="New Label"
              onChange={e => setInputValue(e.target.value)}
              onBlur={onBlur}
              placeholder="Enter new label"
              style={{ width: 400 }}
            />,
          )}
        </Form.Item>
      </div>
    </div>
  )
}

const LabelBlock = props => {
  const { type, editObj = null, handleTargetLable, form } = props
  const labelData = editObj ? editObj : type === 'Peak' ? PeakLabels : NormalTargetLabels
  return (
    <div style={{ backgroundColor: '#ffffff', padding: '24px 36px', margin: '12px 0' }}>
      <Divider orientation="left">Session Recording Labels</Divider>
      {labelData.map((item, index) => (
        <Label
          key={item.value}
          index={index}
          form={form}
          item={item}
          handleTargetLable={handleTargetLable}
        />
      ))}
    </div>
  )
}

export default LabelBlock
