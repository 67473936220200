/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
import { all, put, takeEvery, select, call, takeLatest } from 'redux-saga/effects'
import {
  getData,
  getCourseTemplate,
  getCourseTemplate2,
  updateCourseProgress,
  updateCourseModuleProgress,
  updateTestQuestionResponse,
  getTestResponses,
  updateCourseModuleTestProgress,
  getUserPublicCourseTemplates,
  addUpdateUserCourseModuleValidation,
  getUserCourseModuleValidations,
} from 'services/coursecontent'
import { notification } from 'antd'
import actions from './actions'

export function* LOAD_DATA() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { courseUser } = yield select(state => state.user)
  const response = yield call(getData)
  if (response && response.data) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        userCourses: response.data.getUserEnrollCourses,
      },
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        courseUser: {
          ...courseUser,
          phoneNo: response.data.getUserEnrollCourses[0].courseUser.phoneNo,
        },
      },
    })
    yield call(GET_USER_COURSE_TEMPLATES, {
      payload: {
        courseUser: response.data.getUserEnrollCourses[0].courseUser.id,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_COURSE_TEMPLATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCourseTemplate, payload)
  if (response && response.data.getUserEnrollCourses.length > 0) {
    const obj = response.data.getUserEnrollCourses[0]

    const { onModule, onTest, onContent, courseTemplate, assignedModules } = obj
    // console.log(courseTemplate.coursetemplatemodulesSet, assignedModules)

    // add code for remove the unwanted modules
    obj.courseTemplate.coursetemplatemodulesSet = assignedModules
    courseTemplate.coursetemplatemodulesSet = assignedModules // comment this line to remove module specific

    let selectedModuleId = onModule ? onModule.id : null
    let selectedContentId = onContent ? onContent.id : null
    let selectedTestId = onTest ? onTest.id : null
    let selectedContent = null
    let selectedContentTitle = ''
    let isVideo = false
    let selectedTestIndex = 0
    let selectedQuestionIndex = 0
    let selectedModuleIndex = 0
    let isTest = false
    let description = ''
    if (obj.status && obj.status === 'PENDING') {
      if (
        courseTemplate.coursetemplatemodulesSet.edges.length &&
        courseTemplate.coursetemplatemodulesSet.edges[0].node
      ) {
        const {
          coursemodulescontentSet,
          coursemodulestestsSet,
          // eslint-disable-next-line no-shadow
          id,
        } = courseTemplate.coursetemplatemodulesSet.edges[0].node
        selectedModuleId = id
        if (coursemodulescontentSet.edges.length) {
          selectedContentId = coursemodulescontentSet.edges[0].node.id
        } else {
          selectedTestId = coursemodulestestsSet.edges[0].node.id
        }
      }
    }
    // console.log('onIds===>', selectedModuleId, selectedContentId, selectedTestId)

    if (obj && obj.courseTemplate.coursetemplatemodulesSet.edges.length > 0) {
      const selectedModule = obj.courseTemplate.coursetemplatemodulesSet.edges.find(
        item => item.node.id === selectedModuleId,
      )
      selectedModuleIndex = obj.courseTemplate.coursetemplatemodulesSet.edges.findIndex(
        item => item.node.id === selectedModuleId,
      )
      // console.log('selectedModuleId===>', selectedModule, selectedContentId)
      if (selectedContentId) {
        const selectedCont =
          selectedModule && selectedModule !== undefined
            ? selectedModule.node.coursemodulescontentSet.edges.find(
                item => item.node.id === selectedContentId,
              )
            : null
        selectedContentTitle =
          selectedCont && selectedCont !== undefined ? selectedCont.node.name : ''
        description =
          selectedCont && selectedCont !== undefined ? selectedCont.node.description : ''
        // console.log('selectedCont', selectedCont)
        if (selectedCont && selectedCont !== undefined && selectedCont.node.isVideo) {
          selectedContent = selectedCont.node.video
          isVideo = true
        } else {
          selectedContent = selectedCont?.node?.htmlContent
        }
      }
      if (selectedTestId) {
        isTest = true
        const selectedTest =
          selectedModule && selectedModule !== undefined
            ? selectedModule.node.coursemodulestestsSet.edges.find(
                item => item.node.id === selectedTestId,
              )
            : null
        selectedTestIndex =
          selectedModule && selectedModule !== undefined
            ? selectedModule.node.coursemodulestestsSet.edges.findIndex(
                item => item.node.id === selectedTestId,
              )
            : 0
        selectedQuestionIndex = 0
        selectedContentTitle =
          selectedTest && selectedTest !== undefined ? selectedTest.node.title : ''

        yield call(GET_TEST_RESPONSES, {
          payload: {
            testId: selectedTestId,
            courseId: obj.id,
          },
        })
      }
    }

    // call update module progress api
    if (obj.status && obj.status === 'PENDING') {
      yield call(updateCourseProgress, {
        userCourse: obj.id,
        status: 'inprogress',
        courseTemplate: obj.courseTemplate.id,
        courseModule: courseTemplate.coursetemplatemodulesSet.edges.length
          ? courseTemplate.coursetemplatemodulesSet.edges[0].node.id
          : selectedModuleId,
        moduleContent: selectedContentId,
        moduleTest: selectedTestId,
      })
      yield call(addUpdateUserCourseModuleValidation, {
        userCourse: obj.id,
        selectedModuleId: courseTemplate.coursetemplatemodulesSet.edges.length
          ? courseTemplate.coursetemplatemodulesSet.edges[0].node.id
          : selectedModuleId,
        status: 'inprogress',
      })
    }

    yield put({
      type: actions.GET_USER_COURSE_MODULE_VALIDATION,
      payload: {
        userCourse: obj.id,
      },
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        currentCourseTemplate: obj,
        selectedModuleId,
        selectedContentId,
        selectedTestId,
        selectedContent,
        isVideoContent: isVideo,
        selectedContentTitle,
        description,
        selectedQuestionIndex,
        selectedTestIndex,
        selectedModuleIndex: selectedModuleIndex !== -1 ? selectedModuleIndex : 0,
        isTest,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_TRACK_COURSE({ payload }) {
  const { selectedContentId, selectedModuleId, selectedTestId, userCourse, status } = payload
  const { currentCourseTemplate } = yield select(state => state.coursecontent)
  let courseStatus = 'inprogress'
  let moduleStatus = 'inprogress'
  const moduleLength = currentCourseTemplate.assignedModules.edges.length
  const lastModule = currentCourseTemplate.assignedModules.edges[moduleLength - 1].node
  const currentModule = currentCourseTemplate.assignedModules.edges.find(
    ({ node }) => node.id === selectedModuleId,
  )

  if (currentModule && currentModule !== undefined) {
    const contentLength = currentModule.node.coursemodulescontentSet.edges.length
    const testLength = currentModule.node.coursemodulestestsSet.edges.length
    if (
      selectedContentId &&
      contentLength > 0 &&
      testLength === 0 &&
      status === 'completed' &&
      currentModule.node.coursemodulescontentSet.edges[contentLength - 1].node.id ===
        selectedContentId
    ) {
      moduleStatus = 'completed'
    } else if (
      selectedTestId &&
      testLength > 0 &&
      status === 'completed' &&
      currentModule.node.coursemodulestestsSet.edges[testLength - 1].node.id === selectedTestId
    ) {
      moduleStatus = 'completed'
    } else if (contentLength === 0 && testLength === 0) {
      moduleStatus = 'completed'
    }
  }

  if (lastModule.id === selectedModuleId) {
    const contentLength = lastModule.coursemodulescontentSet.edges.length
    const testLength = lastModule.coursemodulestestsSet.edges.length
    if (
      selectedTestId &&
      testLength > 0 &&
      selectedTestId === lastModule.coursemodulestestsSet.edges[testLength - 1].node.id
    ) {
      courseStatus = 'completed'
    }

    if (
      testLength === 0 &&
      contentLength > 0 &&
      selectedContentId === lastModule.coursemodulescontentSet.edges[contentLength - 1].node.id
    ) {
      courseStatus = 'completed'
    }

    if (contentLength === 0 && testLength === 0) {
      courseStatus = 'completed'
    }
  }

  yield put({
    type: actions.ADD_UPDATE_USER_COURSE_MODULE_VALIDATION,
    payload: {
      ...payload,
      status: moduleStatus,
    },
  })
  yield call(updateCourseProgress, {
    moduleContent: selectedContentId,
    userCourse,
    courseModule: selectedModuleId,
    status: courseStatus,
    moduleTest: selectedTestId,
  })
}

export function* ADD_UPDATE_USER_COURSE_MODULE_VALIDATION({ payload }) {
  const { selectedContentId, selectedModuleId, selectedTestId, userCourse, status } = payload

  const res = yield call(addUpdateUserCourseModuleValidation, {
    userCourse,
    selectedModuleId,
    status,
  })
  if (res) {
    yield put({
      type: actions.GET_USER_COURSE_MODULE_VALIDATION,
      payload: {
        userCourse,
      },
    })
  }
}

export function* ON_VIDEO_PAUSE({ payload }) {
  const response = yield call(updateCourseModuleProgress, payload)

  yield call(UPDATE_TRACK_COURSE, { payload })
  const { currentCourseTemplate } = yield select(state => state.coursecontent)
  if (response && response.data.createUpdateCourseModuleProgress) {
    const details = response.data.createUpdateCourseModuleProgress.details

    // if (response.data.createUpdateCourseModuleProgress.status) {
    //   notification.success({
    //     message: response.data.createUpdateCourseModuleProgress.message,
    //   })
    // }

    if (details) {
      const newArray = []
      let updated = false
      const progressPercentage = details.userCourse.progressPercentage
      const progressObj = currentCourseTemplate.userscoursemodulesprogressSet.edges
      if (progressObj.length > 0) {
        for (let i = 0; i < progressObj.length; i++) {
          if (
            progressObj[i] &&
            progressObj[i].node.content &&
            progressObj[i].node.content.id === details.content.id
          ) {
            newArray.push({ node: details })
            updated = true
          } else {
            newArray.push(progressObj[i])
          }
        }

        if (!updated) {
          newArray.push({ node: details })
        }
      } else {
        newArray.push({ node: details })
      }
      currentCourseTemplate.userscoursemodulesprogressSet.edges = newArray
      currentCourseTemplate.progressPercentage = progressPercentage
      yield put({
        type: actions.SET_STATE,
        payload: {
          currentCourseTemplate,
        },
      })
    }
  }
}

export function* ON_QUESTION_RESPONSE({ payload }) {
  const response = yield call(updateTestQuestionResponse, payload)
  const { currentCourseTemplate } = yield select(state => state.coursecontent)
  if (response && response.data.addUserCourseTestResponse) {
    const details = response.data.addUserCourseTestResponse.response

    if (response.data.addUserCourseTestResponse.status) {
      yield put({
        type: actions.APPEND_TEST_RESPONSES,
        payload: {
          ...payload,
          data: response.data.addUserCourseTestResponse.response,
        },
      })
      // notification.success({
      //   message: response.data.addUserCourseTestResponse.message,
      // })
    }

    // if (details) {
    //   const newArray = []
    //   let updated = false
    //   const progressObj = currentCourseTemplate.courseTemplate.userscoursemodulesprogressSet.edges
    //   if (progressObj.length > 0) {
    //     for (let i = 0; i < progressObj.length; i++) {
    //       if (progressObj[i] && progressObj[i].node.content.id === details.content.id) {
    //         newArray.push({ node: details })
    //         updated = true
    //       } else {
    //         newArray.push(progressObj[i])
    //       }
    //     }

    //     if (!updated) {
    //       newArray.push({ node: details })
    //     }
    //   } else {
    //     newArray.push({ node: details })
    //   }
    //   currentCourseTemplate.courseTemplate.userscoursemodulesprogressSet.edges = newArray

    //   yield put({
    //     type: actions.SET_STATE,
    //     payload: {
    //       currentCourseTemplate,
    //     },
    //   })
    // }
  }
}

export function* GET_TEST_RESPONSES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      responseLoading: true,
    },
  })
  const response = yield call(getTestResponses, payload)
  if (response) {
    yield put({
      type: actions.SET_TEST_RESPONSES,
      payload: {
        testId: payload.testId,
        courseId: payload.courseId,
        data: response.data.getUserCourseTestResponse,
      },
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        responseLoading: false,
        // instructionModal: !response.length,
      },
    })
  }
}

export function* ON_FINISH_TEST({ payload }) {
  const response = yield call(updateCourseModuleTestProgress, payload)
  yield call(UPDATE_TRACK_COURSE, {
    payload,
  })
  // const response2 = yield call(updateCourseProgress, {
  //   moduleContent: null,
  //   userCourse: payload.userCourse,
  //   courseModule: payload.selectedModuleId,
  //   status: payload.status,
  //   moduleTest: payload.selectedTestId,
  // })

  const { currentCourseTemplate } = yield select(state => state.coursecontent)
  if (response && response.data.createUpdateCourseModuleProgress) {
    const details = response.data.createUpdateCourseModuleProgress.details

    // if (response.data.createUpdateCourseModuleProgress.status) {
    //   notification.success({
    //     message: response.data.createUpdateCourseModuleProgress.message,
    //   })
    // }
    if (details) {
      const newArray = []
      let updated = false
      const progressObj = currentCourseTemplate.userscoursemodulesprogressSet.edges
      if (progressObj.length > 0) {
        for (let i = 0; i < progressObj.length; i++) {
          if (
            progressObj[i] &&
            progressObj[i].node.test &&
            progressObj[i].node.test.id === details.test.id
          ) {
            newArray.push({ node: details })
            updated = true
          } else {
            newArray.push(progressObj[i])
          }
        }

        if (!updated) {
          newArray.push({ node: details })
        }
      } else {
        newArray.push({ node: details })
      }
      const progressPercentage = details.userCourse.progressPercentage

      currentCourseTemplate.userscoursemodulesprogressSet.edges = newArray
      currentCourseTemplate.progressPercentage = progressPercentage

      yield put({
        type: actions.SET_STATE,
        payload: {
          currentCourseTemplate,
        },
      })
    }
  }
}

export function* GET_USER_COURSE_TEMPLATES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      userCourseLoading: true,
    },
  })
  const response = yield call(getUserPublicCourseTemplates, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        allUserCourses: response.getPublicCourses,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      userCourseLoading: false,
    },
  })
}

export function* GET_USER_COURSE_MODULE_VALIDATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAvailabilityLoading: true,
    },
  })
  const response = yield call(getUserCourseModuleValidations, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedModuleAvailability: response.getUserCourseModuleValidations.edges.length
          ? response.getUserCourseModuleValidations.edges[
              response.getUserCourseModuleValidations.edges.length - 1
            ].node
          : null,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isAvailabilityLoading: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_DATA, LOAD_DATA),
    takeLatest(actions.GET_COURSE_TEMPLATE, GET_COURSE_TEMPLATE),
    takeEvery(actions.ON_VIDEO_PAUSE, ON_VIDEO_PAUSE),
    takeEvery(actions.ON_QUESTION_RESPONSE, ON_QUESTION_RESPONSE),
    takeEvery(actions.GET_TEST_RESPONSES, GET_TEST_RESPONSES),
    takeEvery(actions.ON_FINISH_TEST, ON_FINISH_TEST),
    takeEvery(actions.UPDATE_TRACK_COURSE, UPDATE_TRACK_COURSE),
    takeEvery(actions.GET_USER_COURSE_TEMPLATES, GET_USER_COURSE_TEMPLATES),
    takeEvery(actions.GET_USER_COURSE_MODULE_VALIDATION, GET_USER_COURSE_MODULE_VALIDATION),
    takeEvery(
      actions.ADD_UPDATE_USER_COURSE_MODULE_VALIDATION,
      ADD_UPDATE_USER_COURSE_MODULE_VALIDATION,
    ),
  ])
}
