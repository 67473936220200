import actions from './actions'

const initialState = {
  isCouponLoading: false,
  coupons: [],
  selectedCoupon: null,
  isCouponSaving: false,
  isAddCouponDrawer: false,
  // pagination
  couponCount: 800,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',
}

export default function couponReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.APPEND_COUPON: {
      return {
        ...state,
        isAddCouponDrawer: false,
        coupons: [{ node: action.payload.data }, ...state.coupons],
      }
    }
    case actions.REPLACE_COUPON: {
      return {
        ...state,
        selectedCoupon: null,
        isAddCouponDrawer: false,
        coupons: [
          ...state.coupons.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    default:
      return state
  }
}
