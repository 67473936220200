/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable  prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable prefer-const */
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  notification,
  Popover,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { gql } from 'apollo-boost'
import { COLORS } from 'assets/styles/globalStyles'
import LoadingComponent from 'components/LoadingComponent'
import * as FileSaver from 'file-saver'
import lodash from 'lodash'
import axios from 'axios'
import { MinuteToHourMinute } from 'utilities'

import { withRouter } from 'react-router'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import { FaDownload } from 'react-icons/fa'
import { useSelector, connect } from 'react-redux'
import * as XLSX from 'xlsx'
import useTimer from '../../components/Timer/timer'
import './form.scss'
import './table.scss'
import DropSection from './DropSection'

const { RangePicker } = DatePicker
const { Search } = Input
const { Text } = Typography

const filterCardStyle = {
  backgroundColor: COLORS.palleteLight,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '5px 10px',
  margin: 0,
  height: 'fit-content',
  overflow: 'hidden',
}

const parentDiv = { display: 'flex', margin: '5px 40px 5px 0' }
const parentLabel = { fontSize: '15px', color: '#000', margin: 'auto 8px auto' }

const ATTENDANCE = gql`
  query($dateGte: Date!, $dateLte: Date!, $clinic: ID!) {
    attendanceReportByClinic(dateGte: $dateGte, dateLte: $dateLte, clinic: $clinic) {
      staff {
        id
        name
      }
      data {
        date
        hours
        mins
        attendance {
          id
          checkIn
          checkOut
          status
          checkInLongitude
          checkInLatitude
          checkInLocation
          checkOutLatitude
          checkOutLongitude
          checkOutLocation
          # checkInSelfie
          # checkOutSelfie
        }
      }
    }
  }
`

const therapistAttendanceReport = gql`
  query($dateGte: Date!, $dateLte: Date!, $therapist: ID!) {
    attendanceReport(dateGte: $dateGte, dateLte: $dateLte, therapist: $therapist) {
      staff {
        id
        name
      }
      date
      hours
      mins
      attendance {
        id
        checkIn
        checkOut
        status
        checkInLongitude
        checkInLatitude
        checkInLocation
        checkOutLatitude
        checkOutLongitude
        checkOutLocation
        # checkInSelfie
        # checkOutSelfie
      }
    }
  }
`

const getDatesBetween = (startDate, endDate) => {
  const now = startDate.clone()
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'))
    now.add(1, 'days')
  }
  return dates
}

const AttendanceReport = ({ dispatch }) => {
  const [date, setDate] = useState([moment().subtract(7, 'days'), moment()])
  const [columns, setColumns] = useState()
  const [filteredTableData, setFilteredTableData] = useState()
  const [copyfilteredTableData, setCopyfilteredTableData] = useState()

  const [searchTherapistFilter, setSearchTherapistFilter] = useState('')
  const [getAttendance, { data: attendance, loading: attLoading, error: attError }] = useLazyQuery(
    ATTENDANCE,
    {
      fetchPolicy: 'network-only',
    },
  )
  const staffList = useSelector(state => state.staffs.StaffList)

  const staff = useSelector(state => state.user.staffObject)

  const [
    getstaffAttendanceReport,
    {
      data: staffAttendanceReportData,
      loading: staffAttendanceReportDataLoading,
      error: staffAttendanceDataReportError,
    },
  ] = useLazyQuery(therapistAttendanceReport)
  const [staffData, setStaffData] = useState(null)

  const clinicId = localStorage.getItem('userId')
  const role = localStorage.getItem('role')
  useEffect(() => {
    if (clinicId && role === '"school_admin"') {
      dispatch({
        type: 'staffs/GET_STAFFS',
        payload: {
          isActive: true,
        },
      })
      getAttendance({
        variables: {
          dateGte: date[0].format('YYYY-MM-DD'),
          dateLte: date[1].format('YYYY-MM-DD'),
          clinic: clinicId,
        },
      })
    }
  }, [date, clinicId])

  useEffect(() => {
    if (staff !== null) {
      if (role === '"therapist"') {
        getstaffAttendanceReport({
          variables: {
            dateGte: date[0].format('YYYY-MM-DD'),
            dateLte: date[1].format('YYYY-MM-DD'),
            therapist: staff?.id,
          },
        })
      }
    }
  }, [staff, date])

  useEffect(() => {
    if (staffAttendanceReportData) {
      const finalData = []
      const att = []
      const attobj = { data: null, staff: null }
      if (staffAttendanceReportData?.attendanceReport.length > 0) {
        staffAttendanceReportData?.attendanceReport.map(item => {
          att.push({
            date: item.date,
            hours: item.hours,
            attendance: item.attendance,
          })
        })
        attobj.data = att
        attobj.staff = staffAttendanceReportData?.attendanceReport[0].staff
        finalData.push(attobj)
        setStaffData(finalData)
      }
    }
  }, [staffAttendanceReportData, date, staff])

  useEffect(() => {
    let dataFinal
    if (filteredTableData) {
      dataFinal = filteredTableData.map(item => {
        if (item.name.toLowerCase().includes(searchTherapistFilter.toLowerCase())) {
          return item
        }
        return null
      })

      setFilteredTableData(dataFinal.filter(item => item !== null))
    }
    if (searchTherapistFilter === '') {
      setFilteredTableData(copyfilteredTableData)
    }
  }, [searchTherapistFilter])

  useEffect(() => {
    if (attendance && staffList.length) {
      formatTableData(attendance)
    }
  }, [attendance, staffList])

  useEffect(() => {
    if (staffAttendanceReportData) {
      staffFormatTableData(staffData)
    }
  }, [staffAttendanceReportData, staffData])

  const staffFormatTableData = data => {
    if (data?.length > 0) {
      const dates = getDatesBetween(date[0], date[1])
      const tableData = []
      data.map((report, index) => {
        const rowData = {}
        rowData.key = index
        rowData.name = report.staff.name
        dates.forEach(date => {
          // rowData[report.date] = report.hours
          if (report.data.length > 0) {
            report.data.map(item => {
              if (item.date === date)
                rowData[item.date] = {
                  hours: item.hours,
                  mins: item.mins,
                  attendance: item.attendance ? item.attendance : [],
                }
            })
          }
          if (!(date in rowData))
            rowData[date] = {
              hours: 0,
              attendance: [],
              status: 0,
              mins: 0,
            }
        })
        tableData.push(rowData)
      })

      moment.updateLocale(moment.locale(), { invalidDate: 'field' })
      const finalGroup = tableData.map(item => {
        const groupedData = lodash.groupBy(
          Object.entries(
            lodash.groupBy(Object.entries(item), item => moment(item[0]).format('MMM')),
          ),
          item => moment(item[1][0][0]).format('YYYY'),
        )
        return groupedData
      })
      setFilteredTableData(tableData)
      const finalDataTT = JSON.parse(JSON.stringify(tableData))
      setCopyfilteredTableData(finalDataTT)
      filterColumns(finalGroup)
    }
  }

  const formatTableData = ({ attendanceReportByClinic }) => {
    const tableData = []
    const dates = getDatesBetween(date[0], date[1])
    staffList?.map(item => {
      const rowData = {}
      if (attendanceReportByClinic.length > 0) {
        attendanceReportByClinic.map(report => {
          if (report.staff.id === item.id) {
            rowData.key = report.staff.id
            rowData.name = report.staff.name
            dates.forEach(date => {
              // rowData[report.date] = report.hours
              if (report.data.length > 0) {
                report.data.map(item => {
                  if (item.date === date) {
                    rowData[item.date] = {
                      hours: item.hours,
                      mins: item.mins,
                      attendance: item.attendance ? item.attendance : [],
                    }
                  }
                })
              }
              if (!(date in rowData)) {
                rowData[date] = {
                  hours: 0,
                  attendance: [],
                  status: 0,
                  mins: 0,
                }
              }
            })
            tableData.push(rowData)
          }
        })
      }

      if (Object.keys(rowData).length === 0) {
        rowData.key = item.id
        rowData.name = item.name
        dates.forEach(date => {
          rowData[moment(date).format('YYYY-MM-DD')] = {
            hours: 0,
            attendance: [],
            mins: 0,
          }
        })
      }
      tableData.push(rowData)
    })

    moment.updateLocale(moment.locale(), { invalidDate: 'field' })
    const finalGroup = tableData.map(item => {
      const groupedData = lodash.groupBy(
        Object.entries(lodash.groupBy(Object.entries(item), item => moment(item[0]).format('MMM'))),
        item => moment(item[1][0][0]).format('YYYY'),
      )
      return groupedData
    })

    setFilteredTableData(
      lodash.uniqBy(tableData, item => {
        const { key, ...rest } = item
        return JSON.stringify(rest)
      }),
    )
    setCopyfilteredTableData(
      lodash.uniqBy(tableData, item => {
        const { key, ...rest } = item
        return JSON.stringify(rest)
      }),
    )

    filterColumns(finalGroup)
  }

  const filterColumns = groupedData => {
    const columns = [
      {
        title: 'Name',
        width: 180,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
      },
      {
        title: 'Total Attendance',
        width: 130,
        dataIndex: 'totalAttendance',
        key: 'totalAttendance',
        fixed: 'left',
        render: (row, text) => {
          // eslint-disable-next-line no-restricted-syntax
          let sum = 0
          Object.entries(text).map(item => {
            if (item[0] === 'key' || item[0] === 'name' || item[1] === undefined) {
              console.log('abc')
            } else {
              // eslint-disable-next-line radix
              sum += parseInt(item[1].mins)
            }
          })
          return <div>{MinuteToHourMinute(sum)}</div>
        },
      },
    ]
    const aa = Object.assign({}, groupedData)
    const a = []
    const entries = Object.entries(aa[0])
    entries.pop()
    entries.map(items => {
      items[1].map(item => {
        item[1].map(i => {
          a.push(i)
        })
      })
    })
    const columnLen = a.length
    a.forEach((date, index) => {
      columns.push({
        title: moment(date[0]).format('DD MMM'),
        key: index,
        dataIndex: date[0],
        width: 80,
        align: 'center',
        children: [
          {
            key: index,
            title: moment(date[0]).format('ddd'),
            align: 'center',
            width: columnLen > 5 ? 350 : '',
            children: [
              {
                title: 'Total',
                key: Math.random(),

                width: 50,
                align: 'center',
                render: (text, row) => {
                  const count = 0
                  let mins = 0
                  Object.entries(text).map(item => {
                    if (item[0] === date[0]) {
                      mins = item[1].mins
                    }
                  })
                  return <div>{MinuteToHourMinute(mins)}</div>
                },
              },
              {
                title: 'Time',
                key: Math.random(),
                align: 'center',
                width: 300,
                render: (text, row) => {
                  let attendance = []

                  Object.entries(text).map(item => {
                    if (item[0] === date[0]) {
                      attendance = item[1].attendance || []
                    }
                  })

                  return attendance.map(i => {
                    const {
                      checkIn,
                      checkOutLocation,
                      checkInLocation,
                      checkOut,
                      status,
                      // checkInSelfie,
                      // checkOutSelfie,
                    } = i
                    console.log('i==>', i)
                    return (
                      <>
                        {/* <Popover
                          title={`${moment(checkIn).format('LT')} -
                            ${checkOut && status !== 0 ? moment(checkOut).format('LT') : ''}`}
                          content={
                            <>
                              <Row justify="center" style={{ width: 300 }}>
                                <Col span={12}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Text strong>CheckIn Selfie</Text>
                                    {checkInSelfie ? (
                                      <img
                                        src={checkInSelfie}
                                        alt="no Selfie"
                                        style={{ width: 100, height: 100 }}
                                      />
                                    ) : (
                                      <Text>No selfie found</Text>
                                    )}
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Text strong>Checkout Selfie</Text>
                                    {checkOutSelfie ? (
                                      <img
                                        src={checkOutSelfie}
                                        alt="no Selfie"
                                        style={{ width: 100, height: 100 }}
                                      />
                                    ) : (
                                      <Text>No selfie found</Text>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </>
                          }
                        > */}
                        <div style={{ color: COLORS.success }}>
                          {moment(checkIn).format('LT')} -{' '}
                          {checkOut && status !== 0 ? moment(checkOut).format('LT') : ''}
                        </div>
                        <div style={{ color: COLORS.orange }}>
                          {/* {stLoc && stLoc.loading && <SyncOutlined spin />} */}
                          {checkInLocation} <b style={{ color: COLORS.sucess }}>-</b>{' '}
                          {checkOutLocation ? ` ${checkOutLocation}` : ''}
                        </div>
                        {/* </Popover> */}
                      </>
                    )
                  })
                },
              },
            ],
          },
        ],
      })
    })
    setColumns(columns)
  }
  const DateChangeDropDown = date => {
    setDate(date)
  }

  const flattenTableHeaders = tableColumns => {
    const headerRow1 = []
    const headerRow2 = []
    const headerRow3 = []
    tableColumns.forEach(col => {
      const { title } = col

      if (col.children) {
        headerRow1.push(title)
        col.children.forEach((val, ind) => {
          headerRow2.push({ dataIndex: val.dataIndex, title: `${val.title}` })
          if (ind !== 0) {
            headerRow1.push('')
          }
          if (val.children) {
            headerRow2.push(title)
            val.children.forEach((v, i) => {
              headerRow3.push({ dataIndex: v.dataIndex, title: `${v.title}` })
              if (i !== 0) {
                headerRow1.push('')
                // headerRow2.push('')
              }
            })
          } else {
            headerRow2.push(val.title)
            headerRow3.push({ title: '' })
          }
        })
      } else {
        headerRow1.push(title)
        headerRow2.push({ title: '', dataIndex: col.dataIndex })
      }
    })
    return [headerRow1, headerRow2, headerRow3]
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const exportToCSV = () => {
    const filename = `Attendance_report_${date}`
    const reportData = filteredTableData.map(i => {
      let t = []
      t[0] = i.name
      let sum = 0
      Object.entries(i).map(item => {
        if (item[0] === 'key' || item[0] === 'name' || item[1] === undefined) {
          console.log('abc')
        } else {
          // eslint-disable-next-line radix
          sum += parseInt(item[1].hours)

          let str = ''
          item[1].attendance.map(at => {
            const { checkIn, checkOutLocation, checkInLocation, checkOut, status } = at
            const s = `(${moment(checkIn).format('LT')} - ${
              checkOut && status !== 0 ? moment(checkOut).format('LT') : ''
            } ${checkInLocation || ''}- ${checkOutLocation ? ` ${checkOutLocation}` : ''}) `

            str += s
          })
          t.push(str)
          t.push(item[1].hours)
        }
      })
      t[1] = sum

      return t
    })
    let [headerRow1, heads, headerRow3] = flattenTableHeaders(columns)
    heads = [{ title: '', dataIndex: 'name' }, ...heads.slice(1)]
    headerRow1 = ['Name', ...headerRow1.slice(1)]
    headerRow3 = [
      { title: '', dataIndex: 'name' },
      { title: '', dataIndex: 'totalAttendance' },
      ...headerRow3,
    ]

    const headerRow = heads.map(col => col.title)
    const header2 = headerRow3.map(col => col.title)
    const ws = XLSX.utils.aoa_to_sheet([headerRow1, headerRow, header2, ...reportData])
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelData, filename + fileExtension)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => exportToCSV()} type="link" size="small">
          CSV/Excel
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {role === '"school_admin"' ? (
        <Row>
          <Col sm={24}>
            <Row>
              <Col span={26}>
                <div style={filterCardStyle}>
                  <div style={parentDiv}>
                    <span style={parentLabel}>Date :</span>
                    <DropSection dateChange={v => DateChangeDropDown(v)} />
                  </div>
                  <div style={parentDiv}>
                    <RangePicker
                      style={{
                        marginLeft: 'auto',
                        width: 250,
                      }}
                      size="default"
                      value={date}
                      onChange={val => DateChangeDropDown(val)}
                    />
                  </div>
                  <div style={parentDiv}>
                    <Search
                      placeholder="Search Therapists"
                      onChange={e => setSearchTherapistFilter(e.target.value)}
                    />
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Dropdown overlay={menu} trigger={['hover']}>
                      <Button type="link" size="large">
                        <FaDownload />{' '}
                      </Button>
                    </Dropdown>
                  </div>
                </div>
                <div style={{ margin: '10px 0 10px 10px' }}>
                  <Table
                    columns={columns}
                    className="allReportTable"
                    dataSource={filteredTableData}
                    bordered
                    loading={attLoading}
                    scroll={{ x: 'fit-content', y: 'fit-content' }}
                    size="middle"
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '30', '50'],
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={24}>
            <Row>
              <Col span={26}>
                <div style={filterCardStyle}>
                  <div style={parentDiv}>
                    <DropSection dateChange={v => DateChangeDropDown(v)} />
                  </div>
                  <div style={parentDiv}>
                    <span style={parentLabel}>Date :</span>
                    <RangePicker
                      style={{
                        marginLeft: 'auto',
                        width: 250,
                      }}
                      size="default"
                      value={date}
                      onChange={val => DateChangeDropDown(val)}
                    />
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Dropdown overlay={menu} trigger={['hover']}>
                      <Button type="link" size="large">
                        <FaDownload />{' '}
                      </Button>
                    </Dropdown>
                  </div>
                </div>
                <div style={{ margin: '10px 0 10px 10px' }}>
                  <Table
                    className="allReportTable"
                    columns={columns}
                    dataSource={filteredTableData}
                    bordered
                    loading={attLoading}
                    scroll={{ x: 'fit-content', y: 'fit-content' }}
                    size="middle"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  }
}

export default withRouter(connect(null, mapDispatchToProps)(AttendanceReport))
