import styled from 'styled-components'

const WrapperDiv = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`

export default WrapperDiv
