/* eslint-disable import/prefer-default-export */
/* eslint-disable */
import moment from 'moment'
import barriersQueTitles from './Barriers/barriersQueTitles'
import transitionQueTitles from './TransitionAssessment/transitionQueTitles'
import milestoneLevels from './Milestones/milestoneLevels'
import taskLevels from './TaskAnalysis/taskLevels'
import { calculateAge } from '../../utilities'
import _ from 'lodash'

export const structurizeData = graphqlData => {
  if (graphqlData) {
    const reportData = {
      milestonesData: generateMilestoneData(graphqlData),
      barriersData: generateBarrierseData(graphqlData),
      tasksData: generateTasksData(graphqlData),
      transitionsData: generateTransitionsData(graphqlData),
    }

    return reportData
  }

  return null
}

const generateMilestoneData = ({ milestones }) => {
  const milestonesData = generateMilestoneLevels()
  milestonesData.studentDetails = generateStudentData(milestones)
  milestonesData.scoreDetails = generateScoreData(milestones)
  if (milestones.data.length !== 0) {
    for (let i = milestones.data.length - 1; i >= 0; i--) {
      groupMilestoneData(milestones.data[i].details, milestonesData)
    }
    // for (let i = 0; i < milestones.data.length; i++) {
    //   groupMilestoneData(milestones.data[i].details, milestonesData)
    // }
  }

  // if (milestones.data[0]) groupMilestoneData(milestones.data[0].details, milestonesData)
  // if (milestones.data[1]) groupMilestoneData(milestones.data[1].details, milestonesData)
  // if (milestones.data[2]) groupMilestoneData(milestones.data[2].details, milestonesData)
  // if (milestones.data[3]) groupMilestoneData(milestones.data[3].details, milestonesData)

  return milestonesData
}

const generateBarrierseData = ({ barriers }) => {
  const barriersData = {}

  barriersData.studentDetails = generateStudentData(barriers)
  barriersData.scoreDetails = generateScoreData(barriers)

  barriersData.questions = generateBarrierQuestions()

  if (barriers.data.length !== 0) {
    const block = get4Block(barriers.data[0].details.masterRecord.testNo)
    for (let i = barriers.data.length - 1; i >= 0; i--) {
      if (block.includes(barriers.data[i].details.masterRecord.testNo)) {
        let testNo = _.findIndex(
          block,
          item => item === barriers.data[i].details.masterRecord.testNo,
        )
        groupBarrierData(barriers.data[i].details, barriersData.questions, testNo + 1)
      }
    }
  }
  // if (barriers.data[0]) groupBarrierData(barriers.data[0].details, barriersData.questions, 1)
  // if (barriers.data[1]) groupBarrierData(barriers.data[1].details, barriersData.questions, 2)
  // if (barriers.data[2]) groupBarrierData(barriers.data[2].details, barriersData.questions, 3)
  // if (barriers.data[3]) groupBarrierData(barriers.data[3].details, barriersData.questions, 4)

  return barriersData
}

const generateTasksData = ({ taskAnalysis, milestones }) => {
  const tasksData = generateTaskLevels()

  tasksData.studentDetails = generateStudentData(taskAnalysis)
  tasksData.scoreDetails = generateScoreData(taskAnalysis)

  if (taskAnalysis.data.length !== 0) {
    const block = get4Block(taskAnalysis.data[0].details.masterRecord.testNo)
    for (let i = taskAnalysis.data.length - 1; i >= 0; i--) {
      if (block.includes(taskAnalysis.data[i].details.masterRecord.testNo)) {
        let testNo = _.findIndex(
          block,
          item => item === taskAnalysis.data[i].details.masterRecord.testNo,
        )
        groupTaskAnalysisData(taskAnalysis.data[i].details, tasksData)
      }
    }
  }

  if (milestones.data.length !== 0) {
    for (let i = milestones.data.length - 1; i >= 0; i--) {
      if (milestones.data[i].details) {
        if (milestones.data[0].details.records.edges.length) {
          milestones.data[i].details.records.edges.map(({ node }) => {
            const level = `level${getLevelFromQue(node.questionNum)}`
            let existingGroup = tasksData[level][node.groups.groupName]
            let color = milestones.data[i].details.masterRecord.color
            let score = node.score
            const include = milestones.data[0].details.records.edges.filter(
              ({ node: item }) =>
                item.questionNum === node.questionNum &&
                item.groups.groupName === node.groups.groupName,
            )
            if (existingGroup && Object.entries(existingGroup.questions).length) {
              const { questions } = existingGroup

              Object.keys(existingGroup.questions).map(item => {
                const t = milestones.data[i].details.records.edges.filter(
                  ({ node: item2 }) =>
                    item2.questionNum === questions[item].questionNum &&
                    item2.groups.groupName === node.groups.groupName,
                )
                if (t.length === 0) {
                  tasksData[level][node.groups.groupName].questions[item] = {
                    id: node.id,
                    questionNum: item,
                    codes: {},
                    // mScoreColor: '#FFF',
                    mScore: '',
                  }
                }
              })
            }
            if (
              existingGroup !== undefined &&
              !existingGroup.questions[node.questionNum] &&
              include.length
            ) {
              // Create question in group
              const obj = tasksData[level][node.groups.groupName].questions[node.questionNum]
              if (obj) {
                if (obj.mScore === 1) {
                  if (node.score === 0 || node.score === 0.5) {
                    tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                      id: node.id,
                      questionNum: node.questionNum,
                      codes: {},
                      mScoreColor: '#FFF',
                      mScore: score,
                    }
                  }
                  if (node.score === 1) {
                    tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                      id: node.id,
                      questionNum: node.questionNum,
                      codes: {},
                      mScoreColor: obj.mScoreColor,
                      mScore: score,
                    }
                  }
                }
                if (obj.mScore === 0 || obj.mScore === 0.5) {
                  if (node.score === 0 || node.score === 0.5) {
                    tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                      id: node.id,
                      questionNum: node.questionNum,
                      codes: {},
                      mScoreColor: '#FFF',
                      mScore: score,
                    }
                  }
                  if (node.score === 1) {
                    tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                      id: node.id,
                      questionNum: node.questionNum,
                      codes: {},
                      mScoreColor: color,
                      mScore: score,
                    }
                  }
                }
                if (obj.mScore === '') {
                  tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                    id: node.id,
                    questionNum: node.questionNum,
                    codes: {},
                    mScoreColor: color,
                    mScore: score,
                  }
                }
              } else {
                if (!node.score || node.score === 0 || node.score === 0.5) {
                  tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                    id: node.id,
                    questionNum: node.questionNum,
                    codes: {},
                    mScoreColor: '#FFF',
                    mScore: score,
                  }
                }
                if (node.score === 1) {
                  tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                    id: node.id,
                    questionNum: node.questionNum,
                    codes: {},
                    mScoreColor: color,
                    mScore: score,
                  }
                }
              }
            } else if (tasksData[level][node.groups.groupName] !== undefined && include.length) {
              // ravi's code start
              const obj = tasksData[level][node.groups.groupName].questions[node.questionNum]
              if (obj.mScore === 1) {
                if (node.score === 0 || node.score === 0.5) {
                  tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                    id: node.id,
                    questionNum: node.questionNum,
                    codes: {},
                    mScoreColor: '#FFF',
                    mScore: score,
                  }
                }
                if (node.score === 1) {
                  tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                    id: node.id,
                    questionNum: node.questionNum,
                    codes: {},
                    mScoreColor: obj.mScoreColor,
                    mScore: score,
                  }
                }
              }
              if (obj.mScore === 0 || obj.mScore === 0.5) {
                if (node.score === 0 || node.score === 0.5) {
                  tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                    id: node.id,
                    questionNum: node.questionNum,
                    codes: {},
                    mScoreColor: '#FFF',
                    mScore: score,
                  }
                }
                if (node.score === 1) {
                  tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                    id: node.id,
                    questionNum: node.questionNum,
                    codes: {},
                    mScoreColor: color,
                    mScore: score,
                  }
                }
              }
              if (obj.mScore === '') {
                tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                  id: node.id,
                  questionNum: node.questionNum,
                  codes: {},
                  mScoreColor: color,
                  mScore: score,
                }
              }
            } else {
              console.log('in else>>>', node.questionNum)
              if (tasksData[level][node.groups.groupName]) {
                tasksData[level][node.groups.groupName].questions[node.questionNum] = {
                  id: node.id,
                  questionNum: node.questionNum,
                  codes: {},
                  mScoreColor: '#FFF',
                  mScore: score,
                }
              }
            }
          })
        }
      }
    }
  }
  // if (taskAnalysis.data[0]) groupTaskAnalysisData(taskAnalysis.data[0].details, tasksData)
  // if (taskAnalysis.data[1]) groupTaskAnalysisData(taskAnalysis.data[1].details, tasksData)
  // if (taskAnalysis.data[2]) groupTaskAnalysisData(taskAnalysis.data[2].details, tasksData)
  // if (taskAnalysis.data[3]) groupTaskAnalysisData(taskAnalysis.data[3].details, tasksData)

  return tasksData
}

const generateTransitionsData = ({ transitionAssessment }) => {
  const transitionsData = {}

  transitionsData.studentDetails = generateStudentData(transitionAssessment)
  transitionsData.scoreDetails = generateScoreData(transitionAssessment)

  transitionsData.questions = generateTransitionQuestions()
  if (transitionAssessment.data.length !== 0) {
    const block = get4Block(transitionAssessment.data[0].details.masterRecord.testNo)
    for (let i = transitionAssessment.data.length - 1; i >= 0; i--) {
      if (block.includes(transitionAssessment.data[i].details.masterRecord.testNo)) {
        let testNo = _.findIndex(
          block,
          item => item === transitionAssessment.data[i].details.masterRecord.testNo,
        )
        groupTransitionsData(
          transitionAssessment.data[i].details,
          transitionsData.questions,
          testNo + 1,
        )
      }
    }
  }
  // if (transitionAssessment.data.length !== 0) {
  //   for (let i = transitionAssessment.data.length - 1; i >= 0; i--) {
  //     groupTransitionsData(transitionAssessment.data[i].details, transitionsData.questions, i + 1)
  //   }
  // }
  // if (transitionAssessment.data[0])
  //   groupTransitionsData(transitionAssessment.data[0].details, transitionsData.questions, 1)
  // if (transitionAssessment.data[1])
  //   groupTransitionsData(transitionAssessment.data[1].details, transitionsData.questions, 2)
  // if (transitionAssessment.data[2])
  //   groupTransitionsData(transitionAssessment.data[2].details, transitionsData.questions, 3)
  // if (transitionAssessment.data[3])
  //   groupTransitionsData(transitionAssessment.data[3].details, transitionsData.questions, 4)

  return transitionsData
}

const generateStudentData = areaData => {
  const { student, createdDate } = areaData.data[0].details.masterRecord
  const studentData = {
    id: student.id,
    name: `${student.firstname} ${student.lastname ?? ''}`,
    dob: student.dob,
    ageAtTest: [1, 2, 3, 4].map(index => ({
      id: index,
      text: '',
    })),
  }

  areaData.data.forEach(({ details }, index) => {
    console.log('indes>>', index)
    if (index < 4) {
      console.log('inside>>', index)
      studentData.ageAtTest[index].text = calculateTestingAge(
        details.masterRecord.createdDate,
        student.dob,
      )
    }
  })

  return studentData
}

export const calculateTestingAge = (date, birthday) => {
  // birthday is a date
  const a = moment(date)
  const b = moment(birthday)
  const days = a.diff(b, 'days')
  return calculateTimimg(days)
}

const calculateTimimg = d => {
  let months = 0,
    years = 0,
    days = 0,
    weeks = 0
  while (d) {
    if (d >= 365) {
      years++
      d -= 365
    } else if (d >= 30) {
      months++
      d -= 30
    } else if (d >= 7) {
      weeks++
      d -= 7
    } else {
      days++
      d--
    }
  }
  // return {
  //    years, months, weeks, days
  // };
  return years + ' years ' + months + ' months'
}

const generateScoreData = areaData => {
  const scoreData = [1, 2, 3, 4].map(index => ({
    key: index,
    testTitle: '',
    id: '',
    score: '',
    date: '',
    color: 'trasparant',
    tester: '',
    testNo: 99999,
  }))

  // Update Details as per Response
  if (areaData.data.length) {
    let block = get4Block(areaData.data[0].details.masterRecord.testNo)
    areaData.data.forEach(({ details: { masterRecord }, total }, index) => {
      let i = index
      if (block.includes(masterRecord.testNo)) {
        let pos = Math.floor(masterRecord.testNo % 4)
        if (pos === 0) {
          i = 3
        } else {
          i = pos - 1
        }
        scoreData[i].id = masterRecord.id
        scoreData[i].testTitle = `Test #${masterRecord.testNo}`
        scoreData[i].score = total
        scoreData[i].testNo = masterRecord.testNo

        scoreData[i].date = moment(masterRecord.createdDate).format('YYYY-MM-DD')
        scoreData[i].color = masterRecord.color
        scoreData[i].tester = `${masterRecord.user.firstName} ${masterRecord.user.lastName ?? ''}`
      }
    })
  }

  return scoreData
}

export const generateMilestoneLevels = () => {
  const returnData = { level1: {}, level2: {}, level3: {} }

  milestoneLevels.forEach(item => {
    if (item.isInLevel1)
      returnData.level1[item.groupName] = { groupName: item.groupName, questions: {} }
    if (item.isInLevel2)
      returnData.level2[item.groupName] = { groupName: item.groupName, questions: {} }
    if (item.isInLevel3)
      returnData.level3[item.groupName] = { groupName: item.groupName, questions: {} }
  })
  console.log('returnData =======>', returnData)
  return returnData
}

const groupMilestoneData = (data, milestonesData) => {
  console.log('score data===> ', data)
  data.records.edges.forEach(({ node }) => {
    const level = `level${getLevelFromQue(node.questionNum)}`
    const group = milestonesData[level][node.groups?.groupName]
    console.log('Datata>>>>>', data.masterRecord.testNo)
    if (group && group !== undefined) {
      if (group.questions[node.questionNum]) {
        const obj = group.questions[node.questionNum]

        console.log('obj>>>----', obj)
        console.log('node>>----', node)
        if (obj.score === 0) {
          if (node.score === 0) {
            console.log('Compare>>>>---0 0')
            group.questions[node.questionNum] = {
              id: node.id,
              questionNum: node.questionNum,
              score: 0,
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,

              first: {
                ...obj.first,
                color: '#fff',
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
              },
              second: {
                ...obj.second,
                color: '#fff',
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
              },
            }
          } else if (node.score === 0.5) {
            console.log('Compare>>>>---0 0.5')

            group.questions[node.questionNum] = {
              id: node.id,
              questionNum: node.questionNum,
              score: 0.5,
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,

              second: {
                ...obj.second,
                color: data.masterRecord.color,
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
                // borderColor: obj.second.borderColor
                //   ? obj.second.borderColor
                //   : data.masterRecord.color,
              },
              first: { ...obj.first, testName: obj.testName, color: '#fff', score: 0 },
            }
          } else if (node.score === 1) {
            console.log('Compare>>>>---0 1')

            group.questions[node.questionNum] = {
              id: node.id,
              questionNum: node.questionNum,
              score: 1,
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,

              first: {
                color: data.masterRecord.color,
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
              },
              second: {
                color: data.masterRecord.color,
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
              },
            }
          }
        }

        if (obj.score === 0.5) {
          if (node.score === 0) {
            console.log('Compare>>>>---0.5 0', obj)

            group.questions[node.questionNum] = {
              id: node.id,
              questionNum: node.questionNum,
              score: 0,
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,

              first: {
                ...obj.first,
                color: '#fff',
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
              },
              second: {
                ...obj.second,
                color: '#fff',
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
                borderColor: obj.second.borderColor
                  ? obj.second.borderColor
                  : data.masterRecord.color,
              },
            }
          } else if (node.score === 0.5) {
            console.log('Compare>>>>---0.5 0.5', obj)

            group.questions[node.questionNum] = {
              id: node.id,
              questionNum: node.questionNum,
              score: 0.5,
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,

              // second: { color: obj.color, testName: obj.testName, score: obj.score },
              second: { ...obj.second },
              first: { ...obj.first },

              // first: {
              //   color: data.masterRecord.color,
              //   testName: `Test #${data.masterRecord.testNo}`,
              //   score: node.score,
              // },
            }
          } else if (node.score === 1) {
            console.log('Compare>>>>---0.5 1', obj)

            group.questions[node.questionNum] = {
              id: node.id,
              questionNum: node.questionNum,
              score: node.score,
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,

              second: {
                // ...obj.second,
                color: obj.second?.color,
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
              },
              first: {
                color: data.masterRecord.color,
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
              },
            }
          }
        }
        if (obj.score === 1) {
          if (node.score === 0) {
            console.log('Compare>>>>---1 0')

            group.questions[node.questionNum] = {
              id: node.id,
              questionNum: node.questionNum,
              score: node.score,
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,

              first: {
                color: '#fff',
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
                borderColor: data.masterRecord.color,
              },
              second: {
                color: '#fff',
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
                borderColor: data.masterRecord.color,
              },
            }
          } else if (node.score === 0.5) {
            console.log('Compare>>>>---1 0.5')

            group.questions[node.questionNum] = {
              id: node.id,
              questionNum: node.questionNum,
              score: node.score,
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,

              second: {
                ...obj.second,
                borderColor: data.masterRecord.color,
              },
              first: {
                color: '#fff',
                testName: `Test #${data.masterRecord.testNo}`,
                score: node.score,
                borderColor: data.masterRecord.color,
              },
            }
          }
        }
      } else {
        if (!node.score) {
          console.log('hello node>>>--', node)
          group.questions[node.questionNum] = {
            id: node.id,
            questionNum: node.questionNum,
            score: node.score,
            color: data.masterRecord.color,
            testName: `Test #${data.masterRecord.testNo}`,

            second: {
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,
              score: node.score,
              borderColor: data.masterRecord.color,
            },
            first: {
              color: '#fff',
              testName: `Test #${data.masterRecord.testNo}`,
              score: node.score,
            },
          }
        }
        if (node.score === 0) {
          console.log('Compare>>>>---0')

          group.questions[node.questionNum] = {
            id: node.id,
            questionNum: node.questionNum,
            score: node.score,
            color: data.masterRecord.color,
            testName: `Test #${data.masterRecord.testNo}`,

            first: {
              color: '#fff',
              testName: `Test #${data.masterRecord.testNo}`,
              score: node.score,
              // borderColor: data.masterRecord.color,
            },
            second: {
              color: '#fff',
              testName: `Test #${data.masterRecord.testNo}`,
              score: node.score,
              // borderColor: data.masterRecord.color,
            },
          }
        } else if (node.score === 0.5) {
          console.log('Compare>>>>---0.5')

          group.questions[node.questionNum] = {
            id: node.id,
            questionNum: node.questionNum,
            score: node.score,
            color: data.masterRecord.color,
            testName: `Test #${data.masterRecord.testNo}`,

            second: {
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,
              score: node.score,
              // borderColor: data.masterRecord.color,
            },
            first: {
              color: '#fff',
              testName: `Test #${data.masterRecord.testNo}`,
              score: node.score,
            },
          }
        } else if (node.score === 1) {
          console.log('Compare>>>>--- 1')

          group.questions[node.questionNum] = {
            id: node.id,
            questionNum: node.questionNum,
            score: node.score,
            color: data.masterRecord.color,
            testName: `Test #${data.masterRecord.testNo}`,

            first: {
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,
              score: node.score,
            },
            second: {
              color: data.masterRecord.color,
              testName: `Test #${data.masterRecord.testNo}`,
              score: node.score,
            },
          }
        }

        // group.questions[node.questionNum] = {
        //   id: node.id,
        //   questionNum: node.questionNum,
        //   score: node.score,

        //   color: data.masterRecord.color,
        //   testName: `Test #${data.masterRecord.testNo}`,
        // }
      }
    }

    console.log('group===>', group)
  })
}

const generateTaskLevels = () => {
  const returnData = { level1: {}, level2: {}, level3: {} }

  taskLevels.forEach(item => {
    if (item.isInLevel1)
      returnData.level1[item.groupName] = { groupName: item.groupName, questions: {} }
    if (item.isInLevel2)
      returnData.level2[item.groupName] = { groupName: item.groupName, questions: {} }
    if (item.isInLevel3)
      returnData.level3[item.groupName] = { groupName: item.groupName, questions: {} }
  })

  return returnData
}

const groupTaskAnalysisData = (data, taskData) => {
  console.log('***data>>>', data, taskData)
  data.records.edges.forEach(({ node }) => {
    console.log('***group>>>', node)
    const level = `level${getLevelFromQue(node.questionNum)}`
    let existingGroup = taskData[level][node.groups.groupName]

    if (existingGroup !== undefined && !existingGroup.questions[node.questionNum]) {
      // Create question in group
      existingGroup.questions[node.questionNum] = {
        id: node.id,
        questionNum: node.questionNum,
        codes: {},
      }

      // add score and score color
    }

    // Push code in question
    if (existingGroup !== undefined) {
      existingGroup.questions[node.questionNum].codes[node.code] = {
        code: node.code,
        color: data.masterRecord.color,
        testName: `Test #${data.masterRecord.testNo}`,
      }
    }
  })
}

const generateBarrierQuestions = () => {
  return Object.getOwnPropertyNames(barriersQueTitles).map(queNo => ({
    queNo: Number(queNo),
    title: barriersQueTitles[queNo],
    test1: 0,
    test2: 0,
    test3: 0,
    test4: 0,
  }))
}

const groupBarrierData = (data, questions, testNo) => {
  data.records.edges.forEach(({ node }) => {
    // Update score for that question & test
    const existingQuestion = questions.find(question => node.questionNum === question.queNo)
    existingQuestion[`test${testNo}`] = node.score
  })
}

const generateTransitionQuestions = () => {
  return Object.getOwnPropertyNames(transitionQueTitles).map(queNo => ({
    queNo: Number(queNo),
    title: transitionQueTitles[queNo],
    test1: 0,
    test2: 0,
    test3: 0,
    test4: 0,
  }))
}

const groupTransitionsData = (data, questions, testNo) => {
  data.records.edges.forEach(({ node }) => {
    // Update score for that question & test
    const existingQuestion = questions.find(question => node.questionNum === question.queNo)
    existingQuestion[`test${testNo}`] = node.score
  })
}

export const getLevelFromQue = que => {
  let level = 0
  if (que >= 1 && que <= 5) level = 1
  else if (que >= 6 && que <= 10) level = 2
  else if (que >= 11 && que <= 15) level = 3
  return level
}

export const get4Block = test_no => {
  const assess_numbers = []
  const reminder = test_no % 4
  let assess_number = 1
  let first_ass = 1
  if (reminder === 0) assess_number = Math.floor(test_no / 4) - 1
  else assess_number = Math.floor(test_no / 4)
  first_ass = assess_number * 4 + 1
  for (let i = 0; i < 4; i = i + 1) {
    assess_numbers.push(first_ass + i)
  }

  return assess_numbers
}
const getDateDiff = (date1, date2) => {
  const d1 = moment(date1)
  const d2 = moment(date2)
  const years = d1.diff(d2, 'years')
  d2.add(years, 'years')
  const months = d1.diff(d2, 'months')
  return `${years}y ${months}m`
}
