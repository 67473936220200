const actions = {
  SET_STATE: 'coursecontent/SET_STATE',
  LOAD_DATA: 'coursecontent/LOAD_DATA',
  GET_COURSE_TEMPLATE: 'coursecontent/GET_COURSE_TEMPLATE',
  ON_VIDEO_PAUSE: 'coursecontent/ON_VIDEO_PAUSE',
  ON_QUESTION_RESPONSE: 'coursecontent/ON_QUESTION_RESPONSE',
  GET_TEST_RESPONSES: 'coursecontent/GET_TEST_RESPONSES',
  SET_TEST_RESPONSES: 'coursecontent/SET_TEST_RESPONSES',
  APPEND_TEST_RESPONSES: 'coursecontent/APPEND_TEST_RESPONSES',
  ON_FINISH_TEST: 'coursecontent/ON_FINISH_TEST',
  UPDATE_TRACK: 'coursecontent/UPDATE_TRACK',
  UPDATE_TRACK_COURSE: 'coursecontent/UPDATE_TRACK_COURSE',
  GET_USER_COURSE_TEMPLATES: 'coursecontent/GET_USER_COURSE_TEMPLATES',
  ADD_UPDATE_USER_COURSE_MODULE_VALIDATION:
    'coursecontent/ADD_UPDATE_USER_COURSE_MODULE_VALIDATION',
  GET_USER_COURSE_MODULE_VALIDATION: 'coursecontent/GET_USER_COURSE_MODULE_VALIDATION',
}

export default actions
