/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag'

export const GET_TARGET_STATUS = gql`
  query {
    targetStatus(first: 7) {
      id
      statusName
    }
  }
`
export const ACTIVE_PROGRAM_AREA = gql`
  query {
    programArea(isActive: true) {
      edges {
        node {
          id
          name
          domain {
            edges {
              node {
                id
                domain
              }
            }
          }
        }
      }
    }
    domain(isActive: true) {
      edges {
        node {
          id
          domain
        }
      }
    }
  }
`
