const ACTIONS = {
  SET_INITIAL_STATE: 'set initial state',
  SET_BEHAVIOR_TEMPLATES: 'set behavior templates',
  SET_ELEMENT: 'set element',
  SET_SESSION_DATA: 'set session data',
  CHANGE_DATE_RANGE: 'date range change',
  SELECT_BEHAVIOR: 'select behavior',
  CLOSE_PDF_DRAWER: 'close pdf drawer',
  OPEN_PDF_DRAWER: 'open pdf drawer',
  SET_IMAGE: 'set image',
  START_PDF_EXPORT: 'start pdf export',
  FINISH_PDF_EXPORT: 'finish pdf exprt',
}

export default ACTIONS
