/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { Card, Row, Col, Layout, Table } from 'antd'
import ReactPlayer from 'react-player'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import './timeslider.scss'
import LoadingComponent from 'components/LoadingComponent'

import Authorize from 'components/LayoutComponents/Authorize'
import TableTr from './EditTableTr'

import '../../../node_modules/video-react/dist/video-react.css'
import Player from './player'
import style from './style.module.scss'
import AddVideo from './addVideo'

const VIDEO_CLASS = gql`
  {
    videoClass {
      id
      name
      videosubclassSet {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

const VIDEO_OBJECT = gql`
  query VideoObject($id: ID!) {
    videoUrl(id: $id) {
      id
      name
      date
      url
      videoClips {
        edges {
          node {
            id
            endTime
            startTime
            response {
              id
              name
            }
            feature {
              id
              name
            }
            stimulus {
              id
              name
            }
          }
        }
      }
    }
  }
`
const { Content } = Layout

const Index = ({ match }) => {
  const history = useHistory()
  const id = match?.params?.id
  const [videoDuration, setVideoDuration] = useState(null)
  const [startEndTime, setstartEndTime] = useState(0)
  const [playannotation, setplayannotation] = useState(null)
  const [videoplayerKey, setVideoplayerKey] = useState(0)

  const annotationVideo = JSON.parse(localStorage.getItem('annotationVideo'))

  const [videoObject, setVideoObject] = useState(annotationVideo)
  const [playerKey, setplayerKey] = useState(null)
  const { loading, error, data, refetch: refetchVideoClass } = useQuery(VIDEO_CLASS, {
    fetchPolicy: 'network-only',
  })

  const [
    getVideo,
    {
      loading: getVideoLoading,
      error: getVideoError,
      data: getVideoData,
      refetch: refetchVideoClips,
    },
  ] = useLazyQuery(VIDEO_OBJECT)

  useEffect(() => {
    if (id) {
      getVideo({
        variables: {
          id,
        },
      })
    }
  }, [id])

  const getVideoDuration = val => {
    setVideoDuration(val)
  }
  const playerRef = useRef()

  const startEndVideoTime = val => {
    setstartEndTime(val)
    playerRef.current.handleSeekChange(val)
  }

  const playAnnotation = (start, end) => {
    setplayannotation({ start, end })
    setVideoplayerKey(videoplayerKey + 1)
  }

  useEffect(() => {
    if (getVideoData && !getVideoLoading) {
      console.log('getVideodata>>>', getVideoData)
      if (ReactPlayer.canPlay(getVideoData?.videoUrl?.url)) {
        setVideoObject({
          id: getVideoData.videoUrl?.id,
          url: getVideoData.videoUrl?.url,
          name: getVideoData.videoUrl?.name,
        })
        setVideoplayerKey(videoplayerKey + 1)
      } else {
        alert("Video Can't be played")
      }
    }
  }, [getVideoData, getVideoLoading])

  const setAnnotationVideo = (i, url, name) => {
    if (ReactPlayer.canPlay(url)) {
      setVideoObject({ id: i, url, name })
      setVideoplayerKey(videoplayerKey + 1)
    } else {
      alert("Video Can't be played")
    }
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>
  console.log('videoObject>>>', videoObject)
  return (
    <Authorize roles={['school_admin', 'therapist', 'parents']} redirect to="/">
      {getVideoLoading && <LoadingComponent />}
      {!getVideoLoading && (
        <Content
          style={{
            padding: '10px',
            margin: '0px auto',
            overflow: 'auto',
          }}
          className="header-max-width"
        >
          <Row>
            {/* <Col>{!id.match.params.id && <AddVideo setAnnotationVideo={setAnnotationVideo} />}</Col> */}
            <Col>
              <AddVideo
                setAnnotationVideo={setAnnotationVideo}
                refetch={() => {
                  refetchVideoClass()
                  // refetchVideoClips()
                }}
              />
            </Col>
          </Row>
          {videoObject && (
            <Row>
              <Col>
                <div className={style.cardDiv}>
                  <Player
                    ref={playerRef}
                    key={videoplayerKey}
                    playAnnotation={playannotation}
                    videoObject={videoObject}
                    getVideoDuration={getVideoDuration}
                    startEndTime={startEndTime}
                  />
                </div>
                <div className={style.cardDiv}>
                  <div style={{ width: '100%' }}>
                    <table className="table small">
                      <thead className="bg-light">
                        <tr>
                          <th
                            scope="col"
                            className="border-0"
                            style={{ width: '10%', fontSize: 18 }}
                          >
                            Class
                          </th>
                          <th
                            scope="col"
                            className="border-0"
                            style={{ width: '15%', fontSize: 18 }}
                          >
                            Sub Class
                          </th>
                          <th
                            scope="col"
                            className="border-0"
                            style={{ width: '60%', fontSize: 18 }}
                          >
                            Range
                          </th>
                          <th
                            scope="col"
                            className="border-0"
                            style={{ width: '15%', fontSize: 18 }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!videoDuration && <LoadingComponent />}
                        {videoDuration &&
                          !getVideoLoading &&
                          data &&
                          data.videoClass.map(dataIns =>
                            dataIns.videosubclassSet.edges.map(({ node }, j) => (
                              <TableTr
                                startEndVideoTime={startEndVideoTime}
                                playAnnotation={playAnnotation}
                                videoObject={videoObject}
                                subdata={node}
                                videoDuration={videoDuration}
                                dataIns={dataIns}
                                j={j}
                              />
                            )),
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Content>
      )}
    </Authorize>
  )
}

export default Index
